import { createContext,useState, useContext, useReducer, useEffect } from "react";
import { useProductContext } from "./flightcontext";
import reducer from "../reducer/filterReducer";

const FilterContext = createContext();

const initialState = {
  filter_products: [],
  all_products: [],
  sorting_value: "lowest",
  selectedFilters:[],
  stopFilters:[],
  filters: {
    price: 0,
    minPrice: 0,
    maxPrice: 0,
    nonStop:true,
    oneStop:true,
    twoStop:true,
    IsRefundable:true,
    Refundable:true,
    MarketingAirlineName: "All",
    layowerAirports: [],
    layowerFlags:[], 
  },
};

export const FilterContextProvider = ({ children }) => {
  const { flightResults } = useProductContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [active, setActive] = useState(null);
  // update the filter values

  const nonStop = (event)=>{
    let value =  event.target.checked
    let name = 'nonStop'
    dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
   }
   const oneStop = (event)=>{
     let value =  event.target.checked
     let name = 'oneStop'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
    const twoStop = (event)=>{
    let value =  event.target.checked
    let name = 'twoStop'
    dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
   }
   
   const IsRefundable = (event)=>{
     let value =  event.target.checked
     let name = 'IsRefundable'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
    const Refundable = (event)=>{
     let value =  event.target.checked
     let name = 'Refundable'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
   
    // const navigate = (event)=>{
    //   console.log(event)
    //   let value =  event.target.checked
    //   let name = 'Refundable'
    //   dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    //  }

  //   const NavLink = ({id,tabProperty, tabTitle, imgSrc, isActive}) => { 
    
  //   return (
  //     <div onClick={()=>{navigate({id,tabProperty, tabTitle, imgSrc, isActive})}} className={isActive ? "active" : ""}>
  //       <img src={imgSrc} alt="Layover" />
  //       <label>{tabTitle}  </label>
  //     </div>
  //   );  
  // };
  
   const navigate = (event) => {
   console.log("navigate id",event)
    setActive(event.id);
    let value =   event.tabProperty
    let name = "timeRange"
    dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
  };

  const navigateArrival = (event) => {
    console.log("navigateArrival id",event)
     setActive(event.id);
     let value =   event.tabProperty
     let name = "timeRangeArrive"
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
   };
  
  
  const updateFilterValue = (event) => { 
    console.log("selcted check box",event.target.name,event.target.checked)
    let value =  event.target.checked;
    let name = event.target.name;
    if (state.selectedFilters.includes(name)) {
      let filters = state.selectedFilters.filter((el) => el !== name);
      state.selectedFilters = filters; 
    } else {
      state.selectedFilters = [...state.selectedFilters, name];
    }
    dispatch({ type: "UPDATE_FILTERS_VALUE", payload: state.selectedFilters ,value  });
    
  };



  const updateFilterIsRefundable  = (event) => { 
    let value  
    let name 
    if(event.target.checked){
      if(event.target.value === "yes"){
        value =  event.target.value
        name = event.target.name
      }else if(event.target.value === "no"){
        value =  event.target.value
        name = event.target.name
      }
    }else{
      if(event.target.value === "yes"){
        value =  "no"
        name = event.target.name
      }else if(event.target.value === "no"){
        value =  "yes"
        name = event.target.name
      }
    }
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    
  };

  const FilterValuePrice = (event)=>{
     let value = event
     let name = "price"
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  }); 
  }

  const flghtStopFilter = (event)=>{
    console.log("selcted check box",event.target.name,event.target.checked)
    //let value =  event.target.checked;
    let name = event.target.name;
    if (state.stopFilters.includes(name)) {
      console.log(name)
      let filters = state.stopFilters.filter((el) => el !== name);
      state.stopFilters = filters; 
    } else {
      state.stopFilters = [...state.stopFilters, name];
    }
    dispatch({ type: "UPDATE_FILTERS_STOP", payload: state.stopFilters  });
    
  }

  
  // to clear the filter
  const clearFilters = () => {
    dispatch({ type: "CLEAR_FILTERS" });
  };

   // sorting function
   const sorting = (event) => {
    let userValue = event.target.value;
    dispatch({ type: "GET_SORT_VALUE", payload: userValue });
  };

  // to sort the product
  useEffect(() => {
    //dispatch({ type: "FILTER_PRODUCTS" });
    dispatch({ type: "SORTING_PRODUCTS" });
  }, [flightResults, state.sorting_value]);

  
  useEffect(() => {
    dispatch({ type: "FILTER_PRODUCTS" });
   // dispatch({ type: "SORTING_PRODUCTS" });
  }, [flightResults,state.selectedFilters, state.filters.IsRefundable,state.stopFilters,state.filters, state.oneStop]);

  

  // to load all the products for grid and list view
  useEffect(() => {
    dispatch({ type: "LOAD_FILTER_PRODUCTS", payload: flightResults });
  },[flightResults]);

  return (
    <FilterContext.Provider
      value={{
        ...state,
        sorting,
        updateFilterValue,
        updateFilterIsRefundable,
        flghtStopFilter,
        FilterValuePrice,
        clearFilters,
        nonStop,
        oneStop,
        twoStop,
        IsRefundable,
        Refundable,
        navigate,
        navigateArrival,
       // NavLink,
        active
        // setairlines
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};