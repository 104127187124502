import React from 'react'
import { FaStar, } from "react-icons/fa6";
function Rating({ rating }) {
  if (rating === 1) {
    return <div><FaStar /></div>
  } else if (rating === 2) {
    return <div><FaStar /><FaStar /></div>
  } else if (rating === 3) {
    return <div><FaStar /><FaStar /><FaStar /></div>
  } else if (rating === 4) {
    return <div><FaStar /><FaStar /><FaStar /><FaStar /></div>
  } else if (rating === 5) {
    return <div><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
  }

}

export default Rating
