import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
    <div className='footer-links'>
          <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
              <h2>Contact Info</h2>
              <Link to='/'><i className="fas fa-phone"></i> +1 234 567 890</Link>
              <Link to='/'><i className="fas fa-envelope"></i> info@trawex.com</Link>
             
            </div>
            <div className='footer-link-items'>
              <h2>Company</h2>
              <Link to='/about'>About us</Link>
              <Link to='/contact'>Contact us</Link>
              <Link to='/faq'>FAQ's</Link>
            </div>
           
            <div className='footer-link-items'>
              <h2>Policies & Terms</h2>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              <Link to='/terms-conditions'>Terms & Conditions</Link>
              <Link to='/payment'>Payment Security</Link>
            </div>
         
            
            <div className='footer-link-items'>
              <h2>Social Media</h2>
              <Link to='/'>Instagram</Link>
              <Link to='/'>Facebook</Link>
              <Link to='/'>Youtube</Link>
              <Link to='/'>Twitter</Link>
            </div>
          </div>
        </div>

        <p className="copy-right-text">© 2023 trawex.com. All rights reserved.</p>
        </div>
  )
}

export default Footer
