import React from 'react';
import './BookingFeatures.css';


function BookingFeatures() {
  return (
    <div className='booking-features-section'>
      <div className='booking-features-container'>
            <h2>Booking Features</h2>

            <div className="booking-card">
                <div className="booking-card-item">
                    <div className='icon'>
                        <img src="/assets/images/plane.png" alt="icon-img"/>
                    </div>
                    <h5>Over 500</h5>
                    <h6 className="text-gray">Airlines</h6>
                </div>
                <div className="booking-card-item">
                    <div className='icon'>
                        <img src="/assets/images/hotel.png" alt="icon-img"/>
                    </div>
                    <h5>Over 1 Million</h5>
                    <h6 className="text-gray">Hotels Worldwide</h6>
                </div>
                <div className="booking-card-item">
                    <div className='icon'>
                        <img src="/assets/images/calculator.png" alt="icon-img"/>
                    </div>
                    <h5>Inclusive Prices</h5>
                    <h6 className="text-gray">Taxes & Fees</h6>
                </div>
                <div className="booking-card-item">
                    <div className='icon'>
                        <img src="/assets/images/atm-card.png" alt="icon-img"/>
                    </div>
                    <h5>Safe & Secure</h5>
                    <h6 className="text-gray">Payment Options</h6>
                </div>
            </div>
      </div>
    </div>
  )
}

export default BookingFeatures
