import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Button, Card, Form, InputGroup} from 'react-bootstrap';
// import { FaUser, FaChild, FaMap, FaCheck, FaHandPointRight, FaArrowLeftLong, FaStar } from "react-icons/fa6";
import { Link, useLocation,useNavigate } from 'react-router-dom'
//import BackToResult from '../components/hotel_checkout/BackToResult'; 
import { useHotelState } from '../../context/hotelContext';
import '../Hotel/Css/HotelCheckout.css'
import Rating from './Rating';
import { isd } from '../../json/isd';

function HotelCheckout() {
    const { get_rate_rules, roomsRules, isLoading, isError } = useHotelState();
    const location = useLocation();
    const navigate = useNavigate();
    const { Selectedrooms, hotelDetail, hotelResults,  session_id, inputs } = location.state
    const [validated, setValidated] = useState(false);
    const [totalAdultCount, setTotalAdultCount] = useState(0);
    const [totalChildCount, setTotalChildCount] = useState(0);
    const [nights, setNights] = useState(0);
    const [days, setDays] = useState(0);
    const [guestDetails, setGuestDetails] = useState([]);
    const [user, setuser] = useState({ emailid: "", mobile: "", country_code: isd[101].alphaTwo });
    const rooms = inputs.rooms 
    console.log("check out page ", roomsRules)
    useEffect(() => {
        getRoomsRules()
        calculateNightsAndDays()
        const items = JSON.parse(localStorage.getItem('user'));
        console.log("user data",items)
        if (items) {
            setuser(items.user_details);
        }
        setuser(state => ({
            ...state,
            country_code: isd[101].unNum
        }));
       }, [])
    const calculateNightsAndDays = () => {
        // const checkIn = new Date(inputs.checkInDate);
        // const checkOut = new Date(inputs.checkoutDate);
        // console.log(inputs.checkInDate)
        // if (inputs.checkoutDate > inputs.checkInDate) {
        //   const timeDiff = inputs.checkoutDate.getTime() - inputs.checkInDate.getTime();
        //   const nightCount = Math.floor(timeDiff / (1000 * 3600 * 24));
        //   const dayCount = nightCount + 1; // Assuming check-in and check-out dates are on different days
    
        //   setNights(nightCount);
        //   setDays(dayCount);
        // } else {
        //   // Handle invalid date range
        //   alert('Invalid date range. Check-out date must be after check-in date.');
        // }
      };

      function getNightStayDays(){
        // if(inputs&&inputs.checkInDate){
            // let one_day=1000*60*60*24;
            // let date1=inputs.checkInDate.getTime();
            // let date2=inputs.checkoutDate.getTime();
            // return Math.round((date2-date1)/one_day);
        // }
      }
    

    useEffect(() => {
        const calculateGuestDetails = () => {
          let updatedTotalAdultCount = 0;
          let updatedTotalChildCount = 0;
          const updatedGuestDetails = [];
          rooms.forEach(room => {
            updatedTotalAdultCount += room.adult;
            updatedTotalChildCount += room.child;
            const adults = Array.from({ length: room.adult }, () => ({ title: "Mr", firstName: '', lastName: '' }));
            const children = room.child_age.map(age => ({ title: "Master", firstName: '', lastName: '' }));
            const roomNoo = Array.from({ length: room.room_no }, (_, index) =>index + 1);
            const obj = { adults, children, room_no: roomNoo };
            updatedGuestDetails.push(obj);
          });
          setTotalAdultCount(updatedTotalAdultCount);
          setTotalChildCount(updatedTotalChildCount);
          setGuestDetails(updatedGuestDetails);
        };
        calculateGuestDetails();
      }, [rooms]);
    
     
   
       const handleUser = (e)=>{
        setuser(Values => ({
            ...Values,
            [e.target.name]: e.target.value,
          }))
      }
      const handleInput = (e,Index, personIndex, field)=>{
        const newFormData = [...guestDetails];
        newFormData[Index][field][personIndex][e.target.name] = e.target.value;
        setGuestDetails(newFormData);
      }
      const renderAdultFields = (index) => {
        return guestDetails[index].adults.map((adult, adul) => (
            <Row key={adul}>
            <Col md={4}>
            <Form.Select className='mb-3' value={adult.title} onChange={(e) => handleInput(e, index,adul, 'adults')} name="title" size="sm">
                <option>Mr</option>
                <option>Mrs</option>
                <option>Ms</option>
            </Form.Select>
            </Col>
            <Col md={4}>
            <Form.Group  controlId="validationCustomFname">
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder="First Name"
                    className='mb-3'
                    required
                    value={adult.firstName}
                    name="firstName"
                    onChange={(e) => handleInput(e, index,adul, 'adults')}
                    />
                   
                    <Form.Control.Feedback type="invalid">
                    Please enter your first name
                    </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
            </Col>
            <Col md={4}>
            <Form.Group  controlId="validationCustomLname">
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder="Last Name"
                    className='mb-3'
                    required
                    value={adult.lastName}
                    name="lastName"
                    onChange={(e) => handleInput(e, index,adul, 'adults')}
                    />
                    <Form.Control.Feedback type="invalid">
                    Please enter your last name
                    </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
            </Col>
            </Row>
        ));
      };
    
      const renderChildFields = (index) => {
        return guestDetails[index].children.map((child, chil) => (
            <Row key={chil} >
            <Col md={4}>
            <Form.Select size="sm"  className='mb-3' value={child.title} onChange={(e) => handleInput(e,index,chil, 'children')} name="title">
                <option>Master</option>
                <option>Miss</option>
            </Form.Select>
            </Col>
            <Col md={4}>
            <Form.Group  controlId="validationCustomFname">
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder="First Name"
                    className='mb-3'
                    required
                    value={child.firstName}
                    name="firstName"
                    onChange={(e) => handleInput(e,index,chil, 'children')}/>
                    <Form.Control.Feedback type="invalid">
                    Please enter your first name
                    </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
            </Col>
            <Col md={4}>
            <Form.Group  controlId="validationCustomLname">
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder="Last Name"
                    className='mb-3'
                    required
                    value={child.lastName}
                    name="lastName"
                    onChange={(e) => handleInput(e,index,chil, 'children')}/>
                    <Form.Control.Feedback type="invalid">
                    Please enter your last name
                    </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
            </Col>
            </Row>
        ));
      };
    
    // const handleSubmit = (event) => {
    //   const form = event.currentTarget;
    //   event.preventDefault();
    //   if (form.checkValidity() === false) {
        
    //     event.stopPropagation();
    //   }
  
    //   setValidated(true);
    // };

    
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson, form.checkValidity());
            navigate("/hotelVoucher", {
                state: {
                    guestDetails: guestDetails,
                    user: user,
                    roomsRules:roomsRules,
                    hotelResults: hotelResults,
                    hotelDetail:hotelDetail,
                    session_id: session_id,
                    inputs:inputs
                }
            })

        }
    };

    
 

    const getRoomsRules = async () => {
    let json = {
        "sessionId": session_id,
        "productId": Selectedrooms.productId,
        "tokenId": hotelResults.tokenId,
        "rateBasisId": Selectedrooms.rateBasisId
    }
    await get_rate_rules(json);
    }
    const [time, setTime] = useState(300);

    // useEffect(() => {
    //   let timer = setInterval(() => {
    //     setTime((time) => {
    //       if (time === 0) {
    //         clearInterval(timer);
    //         return 0;
    //       } else return time - 1;
    //     });
    //   }, 1000);
    // }, []);

    function dateFormatter(dateString) {
        if (dateString) {
          let localTime = dateString.getTime();
          let localOffset = dateString.getTimezoneOffset() * 60000;
          let utc = localTime + localOffset;
          let offset = 5.5;
          let bombay = utc + (3600000 * offset);
          let nd = new Date(bombay);
          return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate())).slice(-2));
        } else {
          return null;
        }
      }
  
  return (
    <div>
      <div className='page-content page-bg pt-0'>


      <div className='hotel-checkout'>
       {/* <BackToResult /> */}

       <Container>
            <Row>
                <Col md={8}>
                    {/* card booking info */}
                    <Card className="card-booking-info card-common my-4">
                        <Card.Body>
                            <h2 className='title-lg'>Booking Info {nights&&nights}   </h2>
                            <div className='hotel-profile'>
                                <div className='d-flex'>
                                    <div className='hotel-img-thumb-holder'>
                                    <img src={hotelResults&&hotelResults.thumbNailUrl} onError={(e) => {e.target.src = '/assets/images/hotel.png'}} className='hotel-img-thumb'></img>
                                    </div>
                                    <div className='info-holder'>
                                        <h2 className='name'>{hotelDetail&&hotelDetail.name}<span className='rating'>
                                            <Rating rating={hotelResults&&hotelResults.hotelRating} /></span></h2>
                                        <p className='adrs'>{hotelDetail&&hotelDetail.address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='ch-in-out-info'>
                                <Row>
                                    <Col md={4}>
                                        <lable className="ch-lable">Check in</lable>
                                        <p className='ch-date'>{inputs&&dateFormatter(inputs.checkinDate)}</p>
                                    </Col>
                                    <Col md={2} className='d-flex align-items-center'>
                                        <span className='night-count'>{getNightStayDays()} Nights</span>
                                    </Col>
                                    <Col md={4}>
                                       <lable className="ch-lable">Check out</lable>
                                        <p className='ch-date'>{inputs&&dateFormatter(inputs.checkoutDate)}</p>
                                    </Col>
                                    <Col md={2} className='d-flex align-items-center'>
                                        <p className='guest-count'>{totalAdultCount&&totalAdultCount}   Adult | {totalChildCount&&totalChildCount} Child</p>
                                    </Col>
                                </Row>
                            </div>
                            {roomsRules && roomsRules.roomRates.perBookingRates.map((results, index) => (
                            <div className='price-inc' key={index}>
                                <h2 className='title'>{results.roomType}</h2>
                                <p className='sub-title'>Your price includes:</p>
                                <div className='fac'>
                               {results.facilities.map((faci)=>(
                                <span className='item'>{faci}</span>
                                ))}
                                    
                                    <span className='item'>Extra low price! ({results.fareType})</span>
                                </div>
                            </div>
                             ))}
                             {roomsRules && roomsRules.roomRates.perBookingRates.map((results, index) => (
                            <div className='cancell-plcy' key={index} >
                                <h2 className='title'>Cancellation Policy</h2>
                                <p className='text'>{results.cancellationPolicy}</p>
                            </div>
                           ))}
                        </Card.Body>
                    </Card>
                    {/* card booking info:end */}

                    {/* card guest-details */}
                    <Card className="card-guest-details card-common my-4">
                        <Card.Body>
                            <h2 className='title-lg mb-4'>Guest Details</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className='mb-3'>
                                <Col md={3}>
                                    <label className='group-label'>Contact Details</label>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                        <Form.Group  controlId="validationCustomEmail">
                                        <InputGroup hasValidation>
                                            <Form.Control type="email" placeholder="Email" className='mb-3' required   value={user.emailid} name="emailid"onChange={(e) => handleUser(e,  'emailid')}/>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid email
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md={5}>
                                    <Form.Select size="sm" value={user.country_code} name="country_code" onChange={(e) => handleUser(e, 'country_code')} >
                                            {isd.map((cntry) => (
                                                <option key={cntry.id} value={cntry.unNum} >{cntry.name}</option>
                                            ))}
                                        </Form.Select>
                                       
                                    </Col>
                                    <Col md={7}>
                                    <Form.Group  controlId="validationCustomMobile">
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Mobile Number" className='mb-3' required value={user.mobile} name="mobile" onChange={(e) => handleUser(e,  'mobile')}/>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your mobile number
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                    </Col>
                                        <p className='info'>Your booking details will be sent to this email address.</p>
                                    </Row>
                                </Col>                         
                            </Row>
                          
                            {guestDetails.map((room,roomIndex)=>(
                                <Row className='mb-3' key={roomIndex} >
                                <Col md={3}>
                                    <label className='group-label'>Room {roomIndex+1}</label>
                                </Col>
                                <Col md={9}>
                                <h6>Adults</h6>
                                {renderAdultFields(roomIndex)}
                                 {renderChildFields(roomIndex).length>0?(<h6 className="mt-3">Children</h6>):""} 
                                {renderChildFields(roomIndex)}
                                </Col>                         
                            </Row>
                            ))}
                            

                            <Row>
                                <Col md={3}>
                                    <label className='group-label'>Special Request</label>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Example textarea</Form.Label>
                                            <Form.Control as="textarea" rows={3} />
                                        </Form.Group>
                                        </Col>
                                        <p className='info'>Special requests can't be guaranteed. These requests will be submitted to the accommodation.</p>
                                    </Row>
                                </Col>                         
                            </Row>
                            <Row>
                            <Col sm={12} className='text-end'>
                            <Button type="submit" className='btn-proceed'>Proceed</Button>
                            </Col>
                            </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    {/* card guest details:end */}

                </Col>
                <Col md={4}>
                    {/* card price breakup */}
                    <Card className="card-price-breakup card-common my-4">
                    {roomsRules && roomsRules.roomRates.perBookingRates.map((results, index) => (
                        <Card.Body key={index}>
                            <h2 className='title-lg'>Price Breakup</h2>
                            <div className='sec-exp'>
                                <p className='msg'>Section will expire in <span>{`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)}</span></p>
                            </div>
                            <div className='break-up'>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>Total length of stay:</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>5 Nights </p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>You selected:</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{results.roomType}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>Guests:</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{totalAdultCount&&totalAdultCount} Adults, {totalChildCount&&totalChildCount} Child</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>Base Fare: <span>(for all guests)</span></label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{totalAdultCount&&totalAdultCount} Adults, {totalChildCount&&totalChildCount} Child</p>
                                    </Col>
                                </Row>
                            </div>
                            </div>

                            <div className='summary'>
                            <h2 className='title'>Your Price Summary</h2>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>Hotel Charges</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{results.currency} {results.netPrice} </p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='item'>
                                <Row>
                                    <Col sm={6}>
                                        <label className='label'>Services Amount:</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{results.currency} 0</p>
                                    </Col>
                                </Row>
                            </div>
                            </div>

                            <div className='sub-total'>
                            <Row>
                                    <Col sm={6}>
                                        <label className='label'>Sub Total:</label>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <p className='value'>{results.currency} {results.netPrice}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    ))}
                    </Card>
                    {/* card price breakup:end */}
                </Col>
            </Row>
        </Container>

      </div>


      </div>
    </div>
  )
}

export default HotelCheckout
