import React, { useState } from 'react';
import { useFilterContext } from '../../context/filterContext';

function DepartureOnwardDuration(props) {
  const {navigate,active} =useFilterContext()
  const destFrom = props.destFrom
 // const [active, setActive] = useState(null);
  const [dataTabs, setDataTabs] = useState([
    {
      id: 1,
      tabTitle: "Before 6AM",
      imgSrc: "/assets/images/early-morning.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isDepartEarlyMorning"
    },
    {
      id: 2,
      tabTitle: "6AM - 12PM",
      imgSrc: "/assets/images/day.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isDepartDay"
    },
    {
      id: 3,
      tabTitle: "12PM - 6PM",
      imgSrc: "/assets/images/evening.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isDepartEvening"
    },
    {
      id: 4,
      tabTitle: "After 6PM",
      imgSrc: "/assets/images/night.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isDepartNight"
    }
  ]);

  const NavLink = ({ id, tabTitle,tabProperty, imgSrc, isActive, onClick }) => {
    
    return (
      <div onClick={() => navigate({id, tabTitle,tabProperty, imgSrc, isActive })} className={isActive ? "active" : ""}>
        <img src={imgSrc} alt="Layover" />
        <label>{tabTitle}  </label>
      </div>
    );
  };

  // const navigate = (id) => {
  //   //console.log(id,isActive)
  //   setActive(id);
  // };

  return (
    <>
      <div className="journey-duration-sec">
        <h6>Departure from {destFrom.City}   </h6>
        <ul className="travel-ul">
          {dataTabs.map((item) => (
            <li key={item.id}>
              <NavLink {...item} isActive={active === item.id} onClick={navigate} />
            </li>
          ))}
        </ul>
      </div>
    </>
  )



}

export default DepartureOnwardDuration
