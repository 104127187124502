import React,{useEffect,useState} from 'react'
import { Link, useLocation,useNavigate } from 'react-router-dom'
import axios from "axios";

function MyhotelVoucher() {
    const location = useLocation();
    const navigate = useNavigate();
    // const { bookingid} = location.state
    const [voucher, setVoucher] = useState();

    // const urlParams = new URLSearchParams(window.location.search);
    // const rawData = urlParams.get('data');
    // const data = JSON.parse(decodeURIComponent(rawData));
    
    // console.log("voucher data",data);

    const getDataFromURL = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get('data');
        if (encodedData) {
          const decodedData = JSON.parse(window.atob(encodedData));
          return decodedData;
        }
        return null;
      };
      
      const receivedData = getDataFromURL();
      
      console.log("voucher data",receivedData);

    const getVoucher = ()=>{
        let url = 'https://groupy.live/reactApp/flights/getflightbookingdetails'
        let json = {
            "booking_id" : "BK01236542345"
        }
        axios.post(url,JSON.stringify(json))
        .then((response) => {
            setVoucher(response.data)
            console.log("respo",JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
    } 

    useEffect(() => {
        getVoucher()
    }, []);
        
  
  return (
    <>


      <div style={{border:"1px solid #ccc",padding:"15px",margin:"30px auto",maxWidth:"800px"}}>
        <table style={{width:"100%",marginBottom:"15px"}}>
           <tbody>
                <tr>
                        <td style={{width:"50%",verticalAlign:"middle"}}>
                            <h2 style={{fontSize:"22px",fontWeight:"bold",marginBottom:"5px",textAlign:"left"}}>BOOKING VOUCHER</h2>
                            <p style={paragraphText}>Date: Jun Wed 07, 2023</p>
                        </td>
                        <td style={{width:"50%",textAlign:"right",verticalAlign:"middle"}}>
                        <div>
                        <img style={{width:"170px"}} src='../../assets/images/logo.png' alt=""/>
                        </div>
                            <button style={voucherBtn}>
                                Print Voucher
                            </button>
                        </td>
                    </tr>
           </tbody>
        </table>

        <p style={paragraphText}>Hi User,</p>
        <p style={paragraphText}>Thank you for booking with Trawex.</p>
        <p style={paragraphText}>Reference number : ST12612518647074</p>
        <p style={paragraphText}><b>Your reservation is Confirmed.</b></p>

        <div style={panelGroup}>
            <div style={panelHead}>
                <h3 style={panelHeading}>Your reservation details are below. </h3>
            </div>
            <div style={panelBody}>
                <p style={paragraphText}><b>Customer name :</b>&nbsp; &nbsp; &nbsp;Mr. test tewst </p>
                <p style={paragraphText}><b>Customer email :</b>&nbsp; &nbsp; &nbsp;vayshakkm@gmail.com</p>
                <p style={paragraphText}><b>Itinerary Number :</b>&nbsp; &nbsp; &nbsp;050722</p>
                <p style={paragraphText}>Please refer to your itinerary number if you contact customer service for any reason. </p>
            </div>
        </div>

        <div style={panelGroup}>
            <div style={panelHead}>
                <h3 style={panelHeading}>Hotel</h3>
            </div>
            <div style={panelBody}>
                <table style={{width:"100%",textAlign:"left"}}>
                    <tbody>
                        <tr>
                            <td style={{fontSize:"13px",padding:"0px 5px"}}>
                            <img style={{width:"70px"}} src='../../assets/images/hotel-image.jpg' alt=""/>
                            </td>
                            <td>
                               <h5><b>Nine Hotel</b></h5>
                            </td>
                        </tr>
                        <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Address:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}>Naif St ., United Arab Emirates</td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Check-in:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}> 	Sep 21, 2023 </td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Check-out</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}> Sep 22, 2023  </td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Number of nights:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}> 1</td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Number of guests:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}> 2 Adult </td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Contact:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}>+97148834337, +97148834337, +97148834337 </td>
                      </tr>

                      <tr>
                          <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Email:</b></td>
                          <td style={{fontSize:"13px",padding:"0px 5px"}}>reservations@ninehotel.ae </td>
                      </tr>
 
                    </tbody>
                </table>
            </div>
        </div>

        <div>
      
        <div style={panelHead}>
                <h3 style={panelHeading}>Room Details</h3>
            </div>
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th style={tableHead}>#</th>
                    <th style={tableHead}>Room Type</th>
                    <th style={tableHead}>Board Type</th>
                    <th style={tableHead}>Status</th>
                    <th style={tableHead}>Ikanopii_Test.com Reference </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={tableData}>Room 1</td>
                    <td style={tableData}>Deluxe double or twin room</td>
                    <td style={tableData}>Room only </td>
                    <td style={tableData}>Confirmed </td>
                    <td style={tableData}>IKI14651912485038 </td>
                </tr>
            </tbody>
        </table>
        </div>

        <div style={{marginTop:"15px"}}>
        <h4 style={{fontSize:"14px",fontWeight:"bold",marginBottom:"10px"}}>Pax Details</h4>
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th style={tableHead}>#</th>
                    <th style={tableHead}>Type</th>
                    <th style={tableHead}>Name</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={tableData}>1</td>
                    <td style={tableData}>Adult </td>
                    <td style={tableData}>Mr Test Tewst </td>
                </tr>

                <tr>
                    <td style={tableData}>2</td>
                    <td style={tableData}>Adult </td>
                    <td style={tableData}>Mr Test Tewst </td>
                </tr>

            </tbody>
        </table>
        <p style={paragraphText}>*Please note: Preferences and special requests cannot be guaranteed. Special requests are subject to availability upon check-in and may incur additional charges. </p>
        </div>

        
        <div style={{border:"1px solid #ccc", padding:"8px"}}>
          <h6 style={{textAlign:"left"}}>Total cost for entire stay ( Including tax recovery charges and service fees ) </h6>
          <div style={{marginTop:"15px",marginBottom:"15px"}}>
            
            <table style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th style={tableHead}>
                        Payment status
                        </th>
                        <th style={tableHead}>
                        Total cost of stay 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={tableData}>
                          Paid
                        </td>
                        <td style={tableData}>
                        NGN 428538.61
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div> 


        <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
      <div style={panelHead}>
              <h3 style={panelHeading}>Cancellation Policy</h3>
          </div>
           <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}>If cancelled from Wed 20 Sep 2023 01:29 AM Amount NGN 33,905.62 will charge! Cancellation policy is based on the date and time of destination.</p>
      </div>

      <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
      <div style={panelHead}>
              <h3 style={panelHeading}>Rate Comments</h3>
          </div>
          <h6 style={{textAlign:"left", padding: "5px"}}><b>Supplier</b></h6>
           <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}>If cancelled from Wed 20 Sep 2023 01:29 AM Amount NGN 33,905.62 will charge! Cancellation policy is based on the date and time of destination.</p>
        </div>

        <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
        <div style={panelHead}>
              <h3 style={panelHeading}>Customer Support Contact Information</h3>
          </div>
           <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}>
           For any queries and help Please contact us:<br></br>
           Email: info@Ikanopii_Test.com<br></br>
           Phone : +0000.000.0000<br></br>
           Whatsapp : +0000.000.0000
           </p>
        </div>

      
      

        

      </div>
    </>
  )
}

const panelGroup = {
    border:"1px solid #001e36",
    marginBottom:"20px",
};

const panelHead = {
    backgroundColor:"#060e9f",
    padding:"10px"
};

const panelHeading = {
    color:"#fff",
    fontSize:"14px",
    fontWeight:"bold",
    margin:"0px",
    textAlign:"left",
}

const panelBody = {
    padding:"10px",
}

const paragraphText = {
    marginTop:"0px",
    marginBottom:"10px",
    fontSize:"13px",
    textAlign:"left"
}

const voucherBtn = {
    backgroundColor:"#060e9f",
    color:"#fff",
    fontSize:"14px",
    padding:"5px 10px",
    marginTop:"5px",
    border:"1px solid #060e9f"
}

const tableHead = {
    backgroundColor:"#060e9f",
    padding:"5px",
    color:"#fff",
    fontSize:"12px",
    fontWeight:"normal",
    border:"1px solid #fff",
    borderCollapse:"collapse"
}




const tableData = {
    fontSize:"12px",
    padding:"5px",
    color:"#333",
    border:"1px solid #ccc",
    borderCollapse:"collapse"
}


export default MyhotelVoucher
