import React, { useState } from 'react'
import { Row, Col, Form, Button, Container, } from 'react-bootstrap';

function AddTraveller(props) {
    //console.log("gfgf",props)
    //const flightclasses = ["Economy", "Business", "First", "Premium"];
    const [personDetail, setpersonDetail] = useState(props)
    const decreaseAdults = (ev) => {
        console.log(ev)
        if (props.personDetail.adults > 1) {
            props.personDetail.adults--;
            if (props.personDetail.adults > props.personDetail.adults) {
                props.personDetail.adults--;
            }
            setpersonDetail(existingValues => ({
                ...existingValues,
                adults: props.personDetail.adults
            }))
        }
    }

    const increaseAdults = () => {
        if (props.personDetail.adults < 9) {
            if (props.personDetail.adults + props.personDetail.children < 9) {
                props.personDetail.adults++;
            }
        }
        setpersonDetail(existingValues => ({
            ...existingValues,
            adults: props.personDetail.adults
        }))

    }
    const decreaseChildren = () => {
        if (props.personDetail.children > 0) {
            props.personDetail.children--;
        }
        setpersonDetail(existingValues => ({
            ...existingValues,
            children: props.personDetail.children
        }))
    }
    const increaseChildren = () => {
        if (props.personDetail.adults + props.personDetail.children < 9) {
            props.personDetail.children++;
        }
        setpersonDetail(existingValues => ({
            ...existingValues,
            children: props.personDetail.children
        }))
    }
    const decreaseInfants = () => {
        if (props.personDetail.infants > 0) {
            props.personDetail.infants--;
        }
        setpersonDetail(existingValues => ({
            ...existingValues,
            infants: props.personDetail.infants
        }))
    }
    const increaseInfants = () => {
        if (props.personDetail.infants < props.personDetail.adults) {
            props.personDetail.infants++;
        }
        setpersonDetail(existingValues => ({
            ...existingValues,
            infants: props.personDetail.infants
        }))
    }
  
    return (
        <div className="hovermenu" >
                <Row>
                    <Col xs={12}>Adults</Col>
                    <Col xs={5}><p>Ages 13 or above</p></Col>
                    <Col xs={7}>
                        <Row>
                            <Col><Button size="sm" variant="primary" onClick={decreaseAdults} >-</Button></Col>
                            <Col><h4 >{props.personDetail.adults}</h4></Col>
                            <Col><Button  size="sm"variant="primary" onClick={increaseAdults} >+</Button></Col>
                        </Row>
                    </Col>
                <Col xs={12}>Children</Col>
                <Col xs={5}><p>Ags 2-12</p></Col>
                    <Col xs={7}>
                        <Row>
                            <Col><Button size="sm" variant="primary" onClick={decreaseChildren} >-</Button></Col>
                            <Col><h4>{props.personDetail.children}</h4></Col>
                            <Col><Button size="sm" variant="primary" onClick={increaseChildren} >+</Button></Col>
                        </Row>
                    </Col>
                <Col  xs={12}>Infants</Col>
                    <Col xs={5}><p>Under 2</p></Col>
                    <Col xs={7}>
                        <Row>
                        <Col><Button size="sm" variant="primary" onClick={decreaseInfants} >-</Button></Col>
                        <Col><h4>{props.personDetail.infants}</h4></Col>
                        <Col><Button size="sm" variant="primary" onClick={increaseInfants} >+</Button></Col>                          
                        </Row>
                    </Col>
                </Row>
                {/* <Form type="radio" className="mt-3">
                    {flightclasses.map((type) => (
                        <div key={type} className="mb-3">
                            <Form.Check type="radio">
                                <Form.Check.Input value={type} />
                                <Form.Check.Label>{type}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    ))}
                </Form> */}
               
            
        </div>
    )
}

export default AddTraveller
