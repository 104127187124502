const filterReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_FILTER_PRODUCTS":
      let priceArr = action.payload.map((curElem) => curElem.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount);
      let maxPrice;
      if (Math.max(...priceArr) === -Infinity) {
        maxPrice = null
      } else {
        maxPrice = Math.max(...priceArr);
      }
      return {
        ...state,
        filter_products: [...action.payload],
        all_products: [...action.payload],
        filters: { ...state.filters, maxPrice: maxPrice, price: maxPrice },
      };
    case "FILTER_PRODUCTS":
      let { all_products, selectedFilters, stopFilters, } = state;
      let tempFilterProduct = [...all_products];
      const { price, nonStop, oneStop, twoStop, IsRefundable, Refundable, MarketingAirlineName, timeRange, timeRangeArrive } = state.filters;
      tempFilterProduct = tempFilterProduct.filter((flight) => {
        if (oneStop && flight.FareItinerary.OriginDestinationOptions[0].TotalStops === 1) {
          return true;
        }
        if (twoStop && flight.FareItinerary.OriginDestinationOptions[0].TotalStops === 2) {
          return true;
        }
        if (nonStop && flight.FareItinerary.OriginDestinationOptions[0].TotalStops === 0) {
          return true;
        }
        return false;
      });
      tempFilterProduct = tempFilterProduct.filter((flight) => {
        if (IsRefundable && flight.FareItinerary.AirItineraryFareInfo.IsRefundable === "Yes") {
          return true;
        }
        if (Refundable && flight.FareItinerary.AirItineraryFareInfo.IsRefundable === "No") {
          return true;
        }
        return false;
      });
      tempFilterProduct = tempFilterProduct.filter((flight) => {
        if (IsRefundable && flight.FareItinerary.AirItineraryFareInfo.IsRefundable === "Yes") {
          return true;
        }
        if (Refundable && flight.FareItinerary.AirItineraryFareInfo.IsRefundable === "No") {
          return true;
        }
        return false;
      });
      if (timeRange) {
        tempFilterProduct = tempFilterProduct.filter((item) => {
          const timestamp = new Date(item.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.DepartureDateTime);
          const hours = timestamp.getHours();
          if (timeRange === 'isDepartEarlyMorning') {
            return hours >= 1 && hours < 6;
          } else if (timeRange === 'isDepartDay') {
            return hours >= 6 && hours < 12;
          } else if (timeRange === 'isDepartEvening') {
            return hours >= 12 && hours < 18;
          } else if (timeRange === 'isDepartNight') {
            return hours >= 18 && hours < 23;
          }
          return false;
        });
      }
      if (timeRangeArrive) {
        tempFilterProduct = tempFilterProduct.filter((results) => {
          const timestamp = new Date(results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1].FlightSegment.ArrivalDateTime);
          const hours = timestamp.getHours();
          if (timeRangeArrive === 'isArriveEarlyMorning') {
            return hours >= 1 && hours < 6;
          } else if (timeRangeArrive === 'isArriveDay') {
            return hours >= 6 && hours < 12;
          } else if (timeRangeArrive === 'isArriveEvening') {
            return hours >= 12 && hours < 18;
          } else if (timeRangeArrive === 'isArriveNight') {
            return hours >= 18 && hours < 23;
          }
          return false;
        });
      }
      if (selectedFilters.length > 0) {
        let tempItems = selectedFilters.map((value) => {
          let temp = tempFilterProduct.filter((item) =>
            item.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName.toLowerCase() === value.toLowerCase());
          console.log(temp)
          return temp;
        });
        tempFilterProduct = tempItems.flat()
      }
      if (stopFilters.length > 0) {
        let tempItems = stopFilters.map((value) => {
          let temp = tempFilterProduct.filter((item) =>
            item.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length === parseInt(value));
          // console.log(temp)
          return temp;
        });
        tempFilterProduct = tempItems.flat()
      }
      if (MarketingAirlineName !== "All") {
        tempFilterProduct = tempFilterProduct.filter(
          (curElem) => curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName.toLowerCase() === MarketingAirlineName.toLowerCase());
      }
      if (price === 0) {
        tempFilterProduct = tempFilterProduct.filter((curElem) => curElem.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount === price);
      } else {
        tempFilterProduct = tempFilterProduct.filter((curElem) => curElem.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount <= price);
      }
      return {
        ...state,
        filter_products: tempFilterProduct,
      };
    case "UPDATE_FILTERS_VALUE":
      return {
        ...state,
        selectedFilters: action.payload,
      };

    case "UPDATE_FILTERS":
      const { name, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    case "UPDATE_FILTERS_STOP":
      return {
        ...state,
        stopFilters: action.payload,
      };
    case "GET_SORT_VALUE":
      // let userSortValue = document.getElementById("sort");
      // let sort_value = userSortValue.options[userSortValue.selectedIndex].value;
      return {
        ...state,
        sorting_value: action.payload,
      };
    case "SORTING_PRODUCTS":
      let newSortData;
      // let tempSortProduct = [...action.payload];
      const { filter_products, sorting_value } = state;
      let tempSortProduct = [...filter_products];
      const sortingProducts = (a, b) => {
        if (sorting_value === "lowest") {
          return parseInt(a.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount) - parseInt(b.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount);
        }

        if (sorting_value === "highest") {
          return parseInt(b.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount) - parseInt(a.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount);
        }

        if (sorting_value === "a-z") {
          return a.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName.localeCompare(b.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName);
        }

        if (sorting_value === "z-a") {
          return b.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName.localeCompare(a.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName);
        }
      };
      newSortData = tempSortProduct.sort(sortingProducts);
      return {
        ...state,
        filter_products: newSortData,
      };
    case "CLEAR_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          MarketingAirlineName: "All",
          IsRefundable: "All",
          price: state.filters.maxPrice,
          minPrice: state.filters.maxPrice,
          timeRange: false,
          timeRangeArrive: false
        },
      };
    default:
      return state;
  }
};

export default filterReducer;