import React from 'react'
function getFareBreakDownDetail(farebreakdown, code) {
    let amount = 0;
    for (let i = 0; i < farebreakdown.length; i++) {
        if (farebreakdown[i].PassengerTypeQuantity.Code === code) {
            amount = farebreakdown[i].PassengerFare.EquivFare.Amount;
            break;
        }
    }
    return amount;
}

function getFareBreakDownTax(farebreakdown, code) {
    let amount = 0;
    for (let i = 0; i < farebreakdown.length; i++) {
        if (farebreakdown[i].PassengerTypeQuantity.Code === code) {
            amount = farebreakdown[i].PassengerFare.ServiceTax.Amount;
            break;
        }
    }
    return amount;
}

function FareSummary({flightInfo,personDetail}) {
  return (
    <div className='price-breakup-section'>
    <h5>Fare Summary </h5>
    <div className="price-breakup-div">
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>ADT x {personDetail.adults}</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'ADT')}</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>Base Fare</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'ADT')}</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>Taxes & Fee</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownTax(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'ADT')}</p>
            </div>
        </div>
    </div>

    {personDetail.children > 0 ?
        <div className="price-breakup-div">
            <div className='row'>
                <div className='col-7'>
                    <p className='left-text'>CHD x {personDetail.children}</p>
                </div>
                <div className='col-5 text-right'>
                    <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'CHD')}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-7'>
                    <p className='left-text'>Base Fare</p>
                </div>
                <div className='col-5 text-right'>
                    <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'CHD')}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-7'>
                    <p className='left-text'>Taxes & Fee</p>
                </div>
                <div className='col-5 text-right'>
                    <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownTax(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'CHD')}</p>
                </div>
            </div>
        </div>
        : ''
    }

    {personDetail.infants > 0 ? <div className="price-breakup-div">
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>INF x {personDetail.infants}</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'INF')}</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>Base Fare</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownDetail(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'INF')}</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>Taxes & Fee</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {getFareBreakDownTax(flightInfo.FareItinerary.AirItineraryFareInfo.FareBreakdown, 'INF')}</p>
            </div>
        </div>
    </div> : ''}


    <div className='total-fare-div'>
        <div className='row'>
            <div className='col-7'>
                <p className='left-text'>Total Fare</p>
            </div>
            <div className='col-5 text-right'>
                <p className='right-text'>{(flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount}</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default FareSummary
