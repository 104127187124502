import React, {useState} from 'react';



function DepartureLayover() {

    const [active, setActive] = useState(null);

    const [dataTabs, setDataTabs] = useState([
        {
          id: 1,
          tabTitle: "00h-05h",
          tabClass: "myCustomClass",
          tabClicked: false
        },
        {
          id: 2,
          tabTitle: "05h-10h",
          tabClass: "myCustomClass",
          tabClicked: false
        },
        {
            id: 3,
            tabTitle: "10h-15h",
            tabClass: "myCustomClass",
            tabClicked: false
          },
          {
            id: 4,
            tabTitle: "15h+",
            tabClass: "myCustomClass",
            tabClicked: false
          }
      ]);

      const NavLink = ({ id, tabTitle, isActive, onClick }) => {
        return (
          <label onClick={() => navigate(id)} className={isActive ? "active" : ""}>
            {tabTitle}
          </label>
        );
      };

      const navigate = (id) => {
        setActive(id);
      };

  return (
    <div>
       <ul className="layover-ul">
              {dataTabs.map((item) => (
        <li key={item.id}>
          <NavLink {...item} isActive={active === item.id} onClick={navigate} />
        </li>
      ))}
            </ul>
    </div>
  )
}

export default DepartureLayover
