import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Card, Form, Pagination } from "react-bootstrap";
import { FaStar, FaUser, FaMagnifyingGlass, FaCalendarDay, Flight} from "react-icons/fa6";
import { FaCar, FaLocationArrow, FaMap, FaTripadvisor, FaSnowflake } from "react-icons/fa";
import { RiFlightTakeoffFill, RiFlightLandFill, RiGasStationFill, RiSpeedUpFill } from "react-icons/ri";
import { GiGearStickPattern } from "react-icons/gi";
import Spineer from '../Flight/Spineer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons';


import { useNavigate, BrowserRouter, Router, Routes, Route, Link } from 'react-router-dom';
import Header from '../Header/Header';
import styles from './CarResults.module.css';
import { useCarContext } from '../../context/Car/carContext';
import dateFormat from "dateformat";
import { useCarFilterContext } from '../../context/Car/carFilterContext';



function CarResults({filterCarData}) {
  //console.log("searchCar", SearchCar)
  const navigate=  useNavigate(); 
  const location = useLocation();
  const { inputs, pickup_id, dropoff_id, pickup_date, pickup_time, dropoff_date, dropoff_time, currency, driver_age, country_res } = location.state
  //console.log("results", location.state)

  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
    setIsActive(current => !current);
  };

  const { getCarSearch, carResults, isLoading, isError } = useCarContext();
  const { filter_cars, all_cars } = useCarFilterContext();
  const [carRefItems, setCarRefItems] = useState([]);

  console.log("all_cars", carResults);

  function dateFormatter(checkinDate) {
    if (checkinDate) {
      let dateString = checkinDate
      let localTime = dateString.getTime();
      let localOffset = dateString.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let offset = 5.5;
      let bombay = utc + (3600000 * offset);
      let nd = new Date(bombay);
      return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate())).slice(-2));
    } else {
      return null;
    }
  }
  //console.log("date", dateFormat(inputs.checkinDate, "yyyy-mm-dd"))
  const checkindate = inputs.checkinDate;
  const checkoutdate = inputs.checkoutDate;
  

 console.log("stringfy", filterCarData);

  return (
    <>
     
     {isLoading ? (<Spineer />) : (
      <div>
      <div className={styles.resultsInfo}>
        <h3>{filterCarData?.length} cars available Intl Airport</h3>
      </div>
      { filterCarData?.map((results, index) => (
        <div className={styles.carResultBox}>
            <Row key={index}>
              <Col md={3}> 
                  <div className="car-image">
                    <img src={results.carDetails.carImage} alt="car-image" />
                  </div>
              </Col>
              <Col md={6} className={styles.carMiddleSection}>
                <h4 className={styles.carName}>
                    {results.carDetails.carModel} <small>{results.carDetails.sizeName}</small>
                </h4>
                <ul className={styles.supplierInfo}>
                       <li className={styles.supplierLogo}>
                         <img src={results.vendor.vendorImage} alt='supplier'/>
                       </li> 
                       <li className={styles.supplierRating}>
                         <FontAwesomeIcon icon={faStar}/> {results.vendor.reviewsOverall}
                       </li>
                       {results.vendor.reviewsOverall === 2? 
                       <li className={styles.supplierReview}>
                        Average
                       </li> : ''}
                       {results.vendor.reviewsOverall === 3? 
                       <li className={styles.supplierReview}>
                        Good
                       </li> : ''}
                       {results.vendor.reviewsOverall === 4? 
                       <li className={styles.supplierReview}>
                        Very Good
                       </li> : ''}
                       {results.vendor.reviewsOverall === 5? 
                       <li className={styles.supplierReview}>
                        Excellent
                       </li> : ''}
                       

                </ul>
                <div className={styles.carFacilities}>
                    <ul>
                        <li>
                            <span className={styles.seatIcon}></span>{results.carDetails.passengerQuantity}
                        </li>
                        <li>
                            <span className={styles.bagIcon}></span>{results.carDetails.baggageQuantity}
                        </li>
                        <li>
                            <span className={styles.doorIcon}></span>{results.carDetails.vehicleDoor} Doors
                        </li>
                        <li>
                            <span className={styles.fuelIcon}></span>{results.carDetails.fuelType}
                        </li>
                        {results.carDetails.ac == "true" ?
                        <li>
                            <span className={styles.acIcon}></span> Air Conditioning
                        </li>: ''
                        }
                        <li>
                            <span className={styles.transmissionIcon}></span>{results.carDetails.transmissionType}
                        </li>
                    </ul>
                </div>
                <div className="car-box text-start">
                  
                  
                  <div className="car-location">
                    
                    <div className="w-100" style={{marginTop:'5px'}}>
                      <RiFlightTakeoffFill />
                      <span style={{ fontWeight: 'bold' }}>Pick-up:</span>
                      <span style={{marginLeft:'3px'}}>{results.pickup.address}</span>
                    </div>
                    <div className="w-100" style={{marginTop:'5px'}}>
                      <RiFlightLandFill />
                      <span style={{ fontWeight: 'bold' }}>Dropp-off:</span>
                      <span style={{marginLeft:'3px'}}>{results.dropoff.address}</span>
                    </div>
            
                  </div>
                
                  <div className="car-refund">
                    <span></span>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className={styles.car_price_section}>
                  
                  
                  <h4>{results.fees.currencyCode} {parseFloat((results.fees.rateTotalAmount / results.duration).toFixed(2))} / Day</h4>
                  <p>Total   {results.fees.currencyCode} {results.fees.rateTotalAmount}</p>
                  
                  <button className={styles.selectBtn} onClick={() => {setCarRefItems(results.referenceId); navigate("/car-details/"+results.referenceId, {
                      state: {
                        session_id: carResults.sessionId,
                        carDataDetails:filter_cars[0], 
                        driverAge: inputs.driverAge,
                        country_res: inputs.country
                    }
                    })}}>Select</button>

                   
                 
                </div>
              </Col>
            </Row>
            </div>
      ))} 
     </div>

      )}
    </>
  )
}

export default CarResults
