import React, { useState } from 'react'
import FlightFareRules from './FlightFareRules'
import { airport } from '../../json/airport';
function FlightContant({ flightInformation, flightInfo,flightFareRules,searchData }) {
    const [modalShow, setModalShow] = useState(false);
    function getLayowerTime(i, k) {
        let layowerTime = new Date(
            flightInformation.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[i + 1].FlightSegment.DepartureDateTime).getTime() - new Date(
                flightInformation.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[i].FlightSegment.ArrivalDateTime).getTime();
        return (Math.floor(layowerTime / (3600 * 1000)) + " hrs " + (layowerTime % (3600 * 1000)) / 60000 + " mins ");
    }
    function getLayowerTimeRound(i, k) {
        let layowerTime = new Date(
            flightInformation.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[i + 1].FlightSegment.DepartureDateTime).getTime() - new Date(
                flightInformation.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[i].FlightSegment.ArrivalDateTime).getTime();
        return (Math.floor(layowerTime / (3600 * 1000)) + " hrs " + (layowerTime % (3600 * 1000)) / 60000 + " mins ");
    }
    function formatTime(date) {
        const formattedDate = new Date(date)
        const hours = String(formattedDate.getHours()).padStart(2, '0');
        const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
        return ` ${hours}:${minutes}`;
    }
    function getTripDuration(destDetail) {
        let tripDuration = new Date(destDetail.ArrivalDateTime).getTime() - new Date(destDetail.DepartureDateTime).getTime();
        return (Math.floor(tripDuration / (3600 * 1000)) + " hrs " +
            (tripDuration % (3600 * 1000)) / 60000 + " mins ");
    }
    function getTotalDuration(stopDetail) {
        let totalTime = new Date(stopDetail[stopDetail.length - 1].FlightSegment.ArrivalDateTime).getTime() - new Date(stopDetail[0].FlightSegment.DepartureDateTime).getTime();
        return (Math.floor(totalTime / (3600 * 1000)) + " hrs " + (totalTime % (3600 * 1000)) / 60000 + " mins ");
    }
    function getAirportDetail(airportCode) {
        return airport.filter(airport => { return airport.AirportCode === airportCode; })[0];
    }
    function getAirportName(airportCode) {
        return getAirportDetail(airportCode).AirportName;
    }
    function getAirportCity(airportCode) {
        return getAirportDetail(airportCode).City;
    }
    function formatDate(date) {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [month, day, year] = formattedDate.split(' ');
        return `  ${month} ${day} ${year}`;
    }
    function dateFormatter(date) {
        if (new Date(date)) {
            let dateString = new Date(date)
            let localTime = dateString.getTime();
            let localOffset = dateString.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let offset = 5.5;
            let bombay = utc + (3600000 * offset);
            let nd = new Date(bombay);
            return (("0" + (nd.getDate())).slice(-2) + '-' + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + nd.getFullYear());
        } else {
           
        }
    }
    return (
        <div>
            <div className="flight-details-box">
                <div className='flight-info-heading'>
                    <div className="row">
                        <div className='col-md-8'>
                            <h5>{searchData.destFrom.airport_city}<i className="fa fa-long-arrow-right mr-2 ml-2"></i>{searchData.destTo.airport_city}  </h5>
                            <ul className="dep-ul">
                                <li>
                                    <div className="travel-date-div">
                                        {dateFormatter(searchData.departureDate)}
                                    </div>
                                </li>
                                <li>
                                    <div className='travel-duration-div'>
                                        {flightInfo.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length > 1 ? (flightInformation.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1) + ' Stop ' : ' Non Stop '} - {getTotalDuration(flightInfo.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption)}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-4 text-md-right xs-mt-3'>
                            <div className="refundable-text">
                                {flightInfo.FareItinerary.AirItineraryFareInfo.IsRefundable != 'Yes' ? 'Refundable' : 'Non-Refundable'}
                            </div>
                            <div>
                                <a onClick={() => setModalShow(true)}>View Fare Rules</a>
                                <FlightFareRules flightFareRules={flightFareRules} show={modalShow} onHide={() => setModalShow(false)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flight-info-content">
                    {flightInfo.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.map((results, index) => (
                        <div className='flight-info-content-div' key={index}>
                            <div className='flight-logo-div'>
                                <ul>
                                    <li>
                                        <img src={"/assets/airlines/" + results.FlightSegment.MarketingAirlineCode + ".gif"} alt="airline-logo" />
                                    </li>
                                    <li>
                                        <div className='airline-name'>
                                            {results.FlightSegment.MarketingAirlineName}
                                        </div>
                                    </li>
                                    <li>
                                        <div className='airline-code'>
                                            {results.FlightSegment.MarketingAirlineCode}-{results.FlightSegment.FlightNumber}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='flightItenary'>
                                <div className='itineraryContent'>
                                    <div className='flight-info-item'>
                                        <div className='flight-time-div'>
                                            <span className='flight-time'>
                                                {formatTime(results.FlightSegment.DepartureDateTime)}
                                            </span>
                                            <span className='layoverCircle'></span>
                                        </div>
                                        <div className='flight-info'>
                                            <span className="travel-date">{dateFormatter(results.FlightSegment.DepartureDateTime)}</span><span className='mr-2 ml-2'>-</span><span className='airport-name'>{getAirportName(results.FlightSegment.DepartureAirportLocationCode)}</span>
                                        </div>
                                    </div>
                                    <div className='layover'>
                                        <ul>
                                            <li>{getTripDuration(results.FlightSegment)}</li>
                                            <li>|</li>
                                            <li>Economy</li>
                                        </ul>
                                    </div>
                                    <div className='flight-info-item'>
                                        <div className='flight-time-div'>
                                            <span className='flight-time'>
                                                {formatTime(results.FlightSegment.ArrivalDateTime)}
                                            </span>
                                            <span className='layoverCircle'></span>
                                        </div>
                                        <div className='flight-info'>
                                            <span className="travel-date">{dateFormatter(results.FlightSegment.ArrivalDateTime)}</span><span className='mr-2 ml-2'>-</span><span className='airport-name'>{getAirportName(results.FlightSegment.ArrivalAirportLocationCode)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(index < flightInformation.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1) ? (<div className='conn-airport-divider' >
                                <span>Change planes at <b>{getAirportCity(results.FlightSegment.ArrivalAirportLocationCode)} </b>, Connecting Time :<b> {getLayowerTime(index, flightInformation.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1)}</b></span>
                            </div>) : ('')}
                        </div>
                    ))}
                </div>
            </div>
            {flightInfo.FareItinerary.OriginDestinationOptions.length === 2 ? (<div className="flight-details-box">
                <div className='flight-info-heading'>
                    <div className="row">
                        <div className='col-md-8'>
                            <h5>{searchData.destTo.airport_city}<i className="fa fa-long-arrow-right mr-2 ml-2"></i>{searchData.destFrom.airport_city}</h5>
                            <ul className="dep-ul">
                                <li>
                                    <div className="travel-date-div">
                                        {dateFormatter(searchData.returnDate)}
                                    </div>
                                </li>
                                <li>
                                    <div className='travel-duration-div'>
                                        {flightInfo.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length > 1 ? (flightInfo.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1) + ' Stop ' : ' Non Stop '} - {getTotalDuration(flightInfo.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption)}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-4 text-md-right xs-mt-3'>
                            <div className="refundable-text">
                                {flightInfo.FareItinerary.AirItineraryFareInfo.IsRefundable != 'Yes' ? 'Refundable' : 'Non-Refundable'}
                            </div>
                            <div>
                                <a onClick={() => setModalShow(true)}>View Fare Rules</a>
                            </div>
                        </div>
                    </div>
                </div>
                {flightInfo.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.map((results, index) => (
                    <div className="flight-info-content" key={index} >
                        <div className='flight-info-content-div'>
                            <div className='flight-logo-div'>
                                <ul>
                                    <li>
                                        <img src={"/assets/airlines/" + results.FlightSegment.MarketingAirlineCode + ".gif"} alt="airline-logo" />
                                    </li>
                                    <li>
                                        <div className='airline-name'>
                                            {results.FlightSegment.MarketingAirlineName}
                                        </div>

                                    </li>
                                    <li>
                                        <div className='airline-code'>
                                            {results.FlightSegment.MarketingAirlineCode}-{results.FlightSegment.FlightNumber}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='flightItenary'>
                                <div className='itineraryContent'>
                                    <div className='flight-info-item'>
                                        <div className='flight-time-div'>
                                            <span className='flight-time'>
                                                {formatTime(results.FlightSegment.DepartureDateTime)}
                                            </span>
                                            <span className='layoverCircle'></span>
                                        </div>
                                        <div className='flight-info'>
                                            <span className="travel-date">{dateFormatter(results.FlightSegment.DepartureDateTime)}</span><span className='mr-2 ml-2'>-</span><span className='airport-name'>{getAirportName(results.FlightSegment.DepartureAirportLocationCode)}</span>
                                        </div>
                                    </div>
                                    <div className='layover'>
                                        <ul>
                                            <li>{getTripDuration(results.FlightSegment)}</li>
                                            <li>|</li>
                                            <li>Economy</li>
                                        </ul>
                                    </div>
                                    <div className='flight-info-item'>
                                        <div className='flight-time-div'>
                                            <span className='flight-time'>
                                                {formatTime(results.FlightSegment.ArrivalDateTime)}
                                            </span>
                                            <span className='layoverCircle'></span>
                                        </div>
                                        <div className='flight-info'>
                                            <span className="travel-date">{dateFormatter(results.FlightSegment.ArrivalDateTime)}</span><span className='mr-2 ml-2'>-</span><span className='airport-name'>{getAirportName(results.FlightSegment.ArrivalAirportLocationCode)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(index < flightInformation.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length - 1) ? (<div className='conn-airport-divider' >
                            <span>Change planes at <b>{getAirportCity(results.FlightSegment.ArrivalAirportLocationCode)} </b>, Connecting Time :<b> {getLayowerTimeRound(index, flightInformation.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length - 1)}</b></span>
                        </div>) : ('')}
                    </div>
                ))}
            </div>) : ''}
        </div>
    )
}

export default FlightContant
