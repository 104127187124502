import React from 'react'

function Facilities({facilities}) {
    const fruitSlice = facilities.slice(0, 5);
    return (
        <div>
            <ul>
                {fruitSlice.map((facy, ind) => (
                    <li key={ind} >{facy}</li>
                ))}
            </ul>
        </div>
    )
}

export default Facilities
