import React, { useEffect, useState } from 'react'
import 'bootstrap-daterangepicker/daterangepicker.css';
// import hotelFilterStyle from '../components/assets/css/hotelFilterStyle.css';
import ReactSlider from 'react-slider';
import styles from './CarResults.module.css';
import PassengerFilters from './PassengerFilters';
import BaggageFilters from './BaggageFilters';
import { FaStar, FaRegStar, FaUser, FaMagnifyingGlass, FaCalendarDay, Flight, FaLocationDot, FaGear, FaIndustry} from "react-icons/fa6";
import { FaCar, FaLocationArrow, FaMap, FaTripadvisor, FaSnowflake } from "react-icons/fa";
import { RiPriceTag3Fill, RiFlightTakeoffFill, RiFlightLandFill, RiGasStationFill, RiSpeedUpFill, RiTeamFill, RiLuggageCartFill } from "react-icons/ri";
import { GiGearStickPattern } from "react-icons/gi";
import { MdPriceChange } from "react-icons/md";
import { useCarFilterContext } from '../../context/Car/carFilterContext';


function CarLeftFilter({filterCarData}) {
    
  const [ value, setValue ] = useState(0); 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const {filters:{ price, maxPrice, minPrice },FilterTransmission, FilterPickup, FilterDropoff, FilterCarRate, 
    FilterSupplier, FilterCustomRating,  FilterFuelType,FilterCarSpec, FilterCarPassenger, clearFilters, FilterBaggage, FilterValuePrice} =useCarFilterContext()

    let supplierType = [...new Set(filterCarData?.map((carRefId) => carRefId.vendor.name))];

    let transmissionTypeFilter = [...new Set(filterCarData?.map((carRefId) => carRefId.carDetails.transmissionType))];

    let pickupLocation = [...new Set(filterCarData?.map((carRefId) => carRefId.pickup.locationName))];

    let dropoffLocation = [...new Set(filterCarData?.map((carRefId) => carRefId.dropoff.locationName))];

    const customRating = [...new Set(filterCarData?.map((carRefId) => carRefId.vendor.reviewsOverall))];

    let ratePolicyFilter = [...new Set(filterCarData?.map((carRefId) => carRefId.fees.rateQualifier))];

    let fuelTypeFilter = [...new Set(filterCarData?.map((carRefId) => carRefId.carDetails.fuelType))];



    customRating.sort((a,b)=> b - a);


      console.log("filter page", customRating)





  return (
    <>
      <div className={styles.leftFilterSection}>
        <h3>Filter</h3>
        <button  onClick={clearFilters} className={styles.resetBtn}>Reset</button>
        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiPriceTag3Fill />  Price</h5>
            <div className={styles.filterBody}> 
              <h5>{maxPrice} </h5>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={[minPrice, maxPrice]}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={10}
                max={maxPrice}
                onChange={FilterValuePrice}
                value={price}
              />
            </div>
        </div>


        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><GiGearStickPattern />  Transmission</h5>
          <div className={styles.filterBody}>
              <ul className={styles.checkbox_List}>
              {transmissionTypeFilter.map((transmissionTypeFilter) =>
                <li>
                    <div className="row">
                      <div className="col-8">
                        <label className="price-label">
                          <input defaultChecked={false}  name="transmission"    type="checkbox"  onChange={FilterTransmission} value="Manual" /> {transmissionTypeFilter}
                        </label>
                      </div>
                    </div>
                </li>
              )}
              </ul>
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><FaLocationDot/>  Pick-up Location</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
               {pickupLocation.map((pickupLocation) =>
              <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                        <input defaultChecked={false}  name="pickup_location" onChange={FilterPickup}  value={pickupLocation}   type="checkbox"/> {pickupLocation}
                      </label>
                    </div>
                  </div>
              </li>
            )}
               
            </ul>
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><FaLocationDot/>  Drop-off Location</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
            {dropoffLocation.map((dropoffLocation) =>
              <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                        <input defaultChecked={false}  name="dropoff_location" onChange={FilterDropoff}  value={dropoffLocation}   type="checkbox"/> {dropoffLocation}
                      </label>
                    </div>
                  </div>
              </li>
            )}
            </ul>
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiGasStationFill />  Fuel Type</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
              {fuelTypeFilter.map((fuelTypeFilter ) =>
                <li>
                  <div className="row">
                      <div className="col-8">
                        <label className="price-label">
                          <input defaultChecked={false}  name="fuelTypeFilter" onChange={FilterFuelType}  value={fuelTypeFilter}   type="checkbox"/> {fuelTypeFilter}
                        </label>
                      </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiSpeedUpFill />  Mileage</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
              <li>
                <label>
                  <input  defaultChecked={false} value="3"  name="3"  type="checkbox"/>Coimbra
                  {/* <input onClick={updateFilterIsRefundable} defaultChecked={true}  name="IsRefundable"    type="checkbox"  value="yes"/> Refundable */}
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><MdPriceChange />  Rate Policy</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
            {ratePolicyFilter.map((ratePolicyFilter ) =>
              <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                        <input defaultChecked={false}  name="supplier" onChange={FilterCarRate}  value={ratePolicyFilter}   type="checkbox"/> {ratePolicyFilter}
                      </label>
                    </div>
                  </div>
              </li>
            )}
            </ul>
         
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><FaGear /> Car Specifications</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
                <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                        <input defaultChecked={false}  name="air_condition" onChange={FilterCarSpec}  value="ac"   type="checkbox"/> Air Conditioning
                      </label>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div>

        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><FaStar />  Custom Ratings</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
            {customRating.map((customRatings) =>
              <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                      {customRatings === 2? 
                       <li className={styles.supplierReview}>
                         <input defaultChecked={false}  name="custom_rating" onChange={FilterCustomRating}  value={customRatings}   type="checkbox"/> <FaStar /><FaStar /><FaRegStar/><FaRegStar/><FaRegStar/> Average
                       </li> : ''}
                       {customRatings === 3? 
                       <li className={styles.supplierReview}>
                         <input defaultChecked={false}  name="custom_rating" onChange={FilterCustomRating}  value={customRatings}   type="checkbox"/> <FaStar /><FaStar /><FaStar /><FaRegStar/><FaRegStar/> Good
                       </li> : ''}
                       {customRatings === 4? 
                       <li className={styles.supplierReview}>
                         <input defaultChecked={false}  name="custom_rating" onChange={FilterCustomRating}  value={customRatings}   type="checkbox"/> <FaStar /><FaStar /><FaStar /><FaStar /><FaRegStar/> Very Good
                       </li> : ''}
                       {customRatings === 5? 
                       <li className={styles.supplierReview}>
                         <input defaultChecked={false}  name="custom_rating" onChange={FilterCustomRating}  value={customRatings}   type="checkbox"/> <FaStar /><FaStar /><FaStar /><FaStar /><FaStar /> Excellent
                       </li> : ''}
                      </label>
                    </div>
                  </div>
              </li>
            )}
            </ul>
          </div>
        </div>
        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><FaIndustry/>  Supplier</h5>
          <div className={styles.filterBody}>
            <ul className={styles.checkbox_List}>
            {supplierType.map((supplierDet) =>
              <li>
                  <div className="row">
                    <div className="col-8">
                      <label className="price-label">
                        <input defaultChecked={false}  name="supplier" onChange={FilterSupplier}  value={supplierDet}   type="checkbox"/> {supplierDet}
                      </label>
                    </div>
                  </div>
              </li>
            )}
           

            </ul>
          </div>
        </div>
        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiTeamFill/> Passangers</h5>
          <div className={styles.filterBody}>
            <div className="row">
              <div className="col-8">
                <label className="price-label"><RiTeamFill/>1-4
                  <input defaultChecked={false}  name="passenger" onChange={FilterCarPassenger}  value="4"   type="checkbox"/> 
                </label>
              </div>
              <div className="col-8">
                <label className="price-label"><RiTeamFill/>5
                  <input defaultChecked={false}  name="passenger" onChange={FilterCarPassenger}  value="5"   type="checkbox"/> 
                </label>
              </div>
              <div className="col-8">
                <label className="price-label"><RiTeamFill/>6+
                  <input defaultChecked={false}  name="passenger" onChange={FilterCarPassenger}  value="6"   type="checkbox"/> 
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiLuggageCartFill />  Baggage</h5>
          <div className={styles.filterBody}>
            <div className="row">
              <div className="col-8">
                <label className="price-label"><RiLuggageCartFill/>1-3
                  <input defaultChecked={false}  name="baggage" onChange={FilterBaggage}  value="3"   type="checkbox"/> 
                </label>
              </div>
              <div className="col-8">
                <label className="price-label"><RiLuggageCartFill/>4
                  <input defaultChecked={false}  name="baggage" onChange={FilterBaggage}  value="4"   type="checkbox"/> 
                </label>
              </div>
              <div className="col-8">
                <label className="price-label"><RiLuggageCartFill/>5+
                  <input defaultChecked={false}  name="baggage" onChange={FilterBaggage}  value="5"   type="checkbox"/> 
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.filterBox}>
          <h5 className={styles.filterHeading}><RiLuggageCartFill />  Baggage</h5>
          <div className={styles.filterBody}>
            <div className="row">
              <BaggageFilters/>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default CarLeftFilter
