import React from 'react';
import { Row, Col, Button,  Form, InputGroup } from 'react-bootstrap';
import { isd } from '../../json/isd';
function FlightTraveller({user, setuser,adults, setAdults,children, setChildren,infants, setInfants,searchData, validated, personDetail, handleSubmit }) {
    const currentDate = new Date(searchData.departureDate);
    const minDate = new Date(currentDate);
    const maxDate = new Date(currentDate);
    minDate.setFullYear(currentDate.getFullYear() - 60); // 60 years ago
    maxDate.setFullYear(currentDate.getFullYear() - 18); // 16 years ago
    const adutMinDate = minDate.toISOString().split('T')[0];
    const adutMaxDate = maxDate.toISOString().split('T')[0];

    const childminDate = new Date(currentDate);
    const childmaxDate = new Date(currentDate);
    childminDate.setFullYear(currentDate.getFullYear() - 12); // 60 years ago
    childmaxDate.setFullYear(currentDate.getFullYear() - 2); // 16 years ago
    const childMinDates = childminDate.toISOString().split('T')[0];
    const childMaxDates = childmaxDate.toISOString().split('T')[0];

    const infantminDate = new Date(currentDate);
    const infantmaxDate = new Date(currentDate);
    infantminDate.setFullYear(currentDate.getFullYear() - 2); // 60 years ago
    infantmaxDate.setMonth(currentDate.getMonth() - 1); // 16 years ago
    const infantMinDates = infantminDate.toISOString().split('T')[0];
    const infantMaxDates = infantmaxDate.toISOString().split('T')[0];

    const passportDate = new Date(currentDate);
    passportDate.setFullYear(currentDate.getFullYear() + 18);
    const passportMinDate = new Date().toISOString().split('T')[0];
    const passportMaxDate = passportDate.toISOString().split('T')[0];
    React.useEffect(() => {
        let adultArray = [];
        let childArray = [];
        let infantArray = [];
        for (let i = 0; i < searchData.personDetail.adults; i++) {
            adultArray.push({ title: "Mr", firstName: "", lastName: "", dob: "", gender: "M", nationality: isd[101].alphaOne, passportExpiryDate: "", passportNo: "", passportIssueCountry: isd[101].alphaOne ,frequent_travel_number: "", seat:"", meal:""});
        }
        setAdults(adultArray);
        for (let i = 0; i < searchData.personDetail.children; i++) {
            childArray.push({ title: "Master", firstName: "", lastName: "", dob: "", gender: "M", nationality: isd[101].alphaOne, passportExpiryDate: "", passportNo: "", passportIssueCountry: isd[101].alphaOne ,frequent_travel_number:"", seat:"", meal:""});
        }
        setChildren(childArray);
        for (let i = 0; i < searchData.personDetail.infants; i++) {
            infantArray.push({ title: "Master", firstName: "", lastName: "", dob: "", gender: "M", nationality: isd[101].alphaOne, passportExpiryDate: "", passportNo: "", passportIssueCountry: isd[101].alphaOne, frequent_travel_number:"" , seat:"", meal:""});
        }
        setInfants(infantArray);
    }, [searchData.personDetail.adults, searchData.personDetail.children, searchData.personDetail.infants]);


    const handleUser = (e) => {
        setuser(Values => ({
            ...Values,
            [e.target.name]: e.target.value,
        }))
    }

    const handleadults = (e, index, field) => {
        const newFormData = [...adults];
        newFormData[index][field] = e.target.value;
        setAdults(newFormData);
    };

    const handleChild = (e, index, field) => {
        const newFormData = [...children];
        newFormData[index][field] = e.target.value;
        setChildren(newFormData);
    };

    const handleInfant = (e, index, field) => {
        const newFormData = [...infants];
        newFormData[index][field] = e.target.value;
        setInfants(newFormData);

    };

    
    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='passenger-section'>
                    <h5>Enter Traveller Details</h5>
                    <div className="passenger-div">
                        <h6>Contact Details <span>(Your booking details will be sent to this email address)</span></h6>
                        <Row className='mb-3'>
                            <Col md={5}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="validationCustomEmail">
                                            <Form.Label>Email</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control type="email" placeholder="Email" className='mb-3' required value={user.emailid} name="emailid" onChange={(e) => handleUser(e, 'emailid')} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={7}>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label>Country Code </Form.Label>
                                        <Form.Select size="lg" value={user.country_code} name="country_code" onChange={(e) => handleUser(e, 'country_code')} >
                                            {isd.map((cntry) => (
                                                <option key={cntry.id} value={cntry.unNum} >{cntry.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group controlId="validationCustomMobile">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control type="number" placeholder="Mobile Number" className='mb-3' required value={user.mobile} name="mobile" onChange={(e) => handleUser(e, 'mobile')} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="validationCustomEmail">
                                    <Form.Label>City</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control type="text" placeholder="City" className='mb-3' required value={user.city} name="city" onChange={(e) => handleUser(e, 'city')} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="validationCustomEmail">
                                    <Form.Label>Address</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control type="text" placeholder="Address" className='mb-3' required value={user.address} name="address" onChange={(e) => handleUser(e, 'address')} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>


                    </div>
                    {adults.map((adult, index) =>
                        <div className="passenger-div" key={index}>
                            <h6>Adult {index + 1}</h6>
                            <Row>
                                <Col md={2}>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Select size="sm" name="title" value={adult.title} onChange={(e) => handleadults(e, index, 'title')}  >
                                        <option value="Mr" >Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Miss">Ms.</option>
                                    </Form.Select>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationFname">
                                        <Form.Label>First Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="First Name" className='mb-3' required
                                                name="firstName"
                                                value={adult.firstName || ''}
                                                onChange={(e) => handleadults(e, index, 'firstName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationLname">
                                        <Form.Label>Last Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Last Name" className='mb-3' required
                                                name="lastName" value={adult.lastName || ''} onChange={(e) => handleadults(e, index, 'lastName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} >
                                    <Form.Group controlId="validationdob">
                                        <Form.Label>Date of Birth*</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="DOB" className='mb-3' required
                                                name="dob" value={adult.dob}
                                                min={adutMinDate}
                                                max={adutMaxDate}
                                                onChange={(e) => handleadults(e, index, 'dob')} />
                                        </InputGroup>
                                    </Form.Group>

                                </Col>
                                <Col md={4}>
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select size="sm" value={adult.gender || 'M'} onChange={(e) => handleadults(e, index, 'gender')}>
                                        <option  value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationfln">
                                        <Form.Label>Frequent Flyer Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Frequent Flyer Number" className='mb-3'
                                                name="frequent_travel_number" value={adult.frequent_travel_number} onChange={(e) => handleadults(e, index, 'frequent_travel_number')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Seat Preference</Form.Label>
                                    <Form.Select size="sm" value={adult.seat} onChange={(e) => handleadults(e, index, 'seat')}>
                                        <option value="">Seat Pref</option>
                                        <option value="A">Aisle</option>
                                        <option value="W">Window</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Meal Request</Form.Label>
                                    <Form.Select size="sm" value={adult.meal} onChange={(e) => handleadults(e, index, 'meal')}  >
                                        {meal.map((me) => (
                                            <option key={me.id} value={me.value}>{me.meal}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Nationality</Form.Label>
                                    <Form.Select size="sm" value={adult.nationality} name='nationality' onChange={(e) => handleadults(e, index, 'nationality')}>
                                        {isd.map((cntry) => (
                                            <option key={cntry.id} value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportNumber">
                                        <Form.Label>Passport Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Passport Number" className='mb-3' required
                                                name="passportNo" value={adult.passportNo} onChange={(e) => handleadults(e, index, 'passportNo')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Issue Country*</Form.Label>
                                    <Form.Select size="sm" value={adult.passportIssueCountry} onChange={(e) => handleadults(e, index, 'passportIssueCountry')} >
                                        {isd.map((cntry) => (
                                            <option key={cntry.id}  value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportExpiryDate">
                                        <Form.Label>Passport Expiry Date</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="Passport Expiry Date" className='mb-3' required
                                                name="passportExpiryDate" min={passportMinDate} max={passportMaxDate} value={adult.passportExpiryDate} onChange={(e) => handleadults(e, index, 'passportExpiryDate')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div>
                    )}

                    {children.map((child, index) =>

                        <div className="passenger-div" key={index}>
                            <h6>Child {index + 1}</h6>
                            <Row>
                                <Col md={2}>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Select size="sm" name="title" value={child.title} onChange={(e) => handleChild(e, index, 'title')}  >
                                        <option value="Master">Master.</option>
                                        <option value="Mis">Mis.</option>
                                    </Form.Select>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationFname">
                                        <Form.Label>First Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="First Name" className='mb-3' required
                                                name="firstName"
                                                value={child.firstName || ''}
                                                onChange={(e) => handleChild(e, index, 'firstName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationLname">
                                        <Form.Label>Last Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Last Name" className='mb-3' required
                                                name="lastName" value={child.lastName || ''} onChange={(e) => handleChild(e, index, 'lastName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} >
                                    <Form.Group controlId="validationdob">
                                        <Form.Label>Date of Birth*</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="DOB" className='mb-3' required
                                                name="dob" value={child.dob}
                                                min={childMinDates}
                                                max={childMaxDates}
                                                onChange={(e) => handleChild(e, index, 'dob')} />
                                        </InputGroup>
                                    </Form.Group>

                                </Col>
                                <Col md={4}>
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select size="sm" value={child.gender || 'M'} onChange={(e) => handleChild(e, index, 'gender')}>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationfln">
                                        <Form.Label>Frequent Flyer Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Frequent Flyer Number" className='mb-3'
                                                name="frequent_travel_number" value={child.frequent_travel_number} onChange={(e) => handleChild(e, index, 'frequent_travel_number')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Seat Preference</Form.Label>
                                    <Form.Select size="sm" value={child.nationality} name='seat' onChange={(e) => handleChild(e, index, 'seat')} >
                                        <option value="">Seat Pref</option>
                                        <option value="A">Aisle</option>
                                        <option value="W">Window</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Meal Request</Form.Label>
                                    <Form.Select size="sm" value={child.nationality} name='meal' onChange={(e) => handleChild(e, index, 'meal')} >
                                        {meal.map((me) => (
                                            <option key={me.id} value={me.value}>{me.meal}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Nationality</Form.Label>
                                    <Form.Select size="sm" value={child.nationality} name='nationality' onChange={(e) => handleChild(e, index, 'nationality')}>
                                        {isd.map((cntry) => (
                                            <option key={cntry.id} value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportNumber">
                                        <Form.Label>Passport Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Passport Number" className='mb-3' required
                                                name="passportNo" value={child.passportNo} onChange={(e) => handleChild(e, index, 'passportNo')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Issue Country*</Form.Label>
                                    <Form.Select size="sm" value={child.passportIssueCountry} onChange={(e) => handleChild(e, index, 'passportIssueCountry')} >
                                        {isd.map((cntry) => (
                                            <option key={cntry.id}  value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportExpiryDate">
                                        <Form.Label>Passport Expiry Date</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="Passport Expiry Date" className='mb-3' required
                                                name="passportExpiryDate" min={passportMinDate} max={passportMaxDate} value={child.passportExpiryDate} onChange={(e) => handleChild(e, index, 'passportExpiryDate')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div>


                    )}

                    {infants.map((infant, index) =>

                        <div className="passenger-div" key={index}>
                            <h6>Infant {index + 1}</h6>
                            <Row>
                                <Col md={2}>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Select size="sm" name="title" value={infant.title} onChange={(e) => handleInfant(e, index, 'title')}  >
                                        <option value="Master" >Master.</option>
                                        <option value="Mis">Mis.</option>
                                    </Form.Select>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationFname">
                                        <Form.Label>First Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="First Name" className='mb-3' required
                                                name="firstName"
                                                value={infant.firstName || ''}
                                                onChange={(e) => handleInfant(e, index, 'firstName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="validationLname">
                                        <Form.Label>Last Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Last Name" className='mb-3' required
                                                name="lastName" value={infant.lastName || ''} onChange={(e) => handleInfant(e, index, 'lastName')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} >
                                    <Form.Group controlId="validationdob">
                                        <Form.Label>Date of Birth*</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="DOB" className='mb-3' required
                                                name="dob" value={infant.dob}
                                                min={infantMinDates}
                                                max={infantMaxDates}
                                                onChange={(e) => handleInfant(e, index, 'dob')} />
                                        </InputGroup>
                                    </Form.Group>

                                </Col>
                                <Col md={4}>
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select size="sm" value={infant.gender || 'M'} onChange={(e) => handleInfant(e, index, 'gender')}>
                                        <option  value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationfln">
                                        <Form.Label>Frequent Flyer Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Frequent Flyer Number" className='mb-3'
                                                name="frequent_travel_number" value={infant.frequent_travel_number} onChange={(e) => handleInfant(e, index, 'frequent_travel_number')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Seat Preference</Form.Label>
                                    <Form.Select size="sm" value={infant.nationality} name='seat' onChange={(e) => handleInfant(e, index, 'seat')} >
                                        <option value="">Seat Pref</option>
                                        <option value="A">Aisle</option>
                                        <option value="W">Window</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Meal Request</Form.Label>
                                    <Form.Select size="sm" value={infant.nationality} name='meal' onChange={(e) => handleInfant(e, index, 'meal')} >
                                        {meal.map((me) => (
                                            <option key={me.id} value={me.value}>{me.meal}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Nationality</Form.Label>
                                    <Form.Select size="sm" value={infant.nationality} name='nationality' onChange={(e) => handleInfant(e, index, 'nationality')}>
                                        {isd.map((cntry) => (
                                            <option key={cntry.id} value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportNumber">
                                        <Form.Label>Passport Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="Passport Number" className='mb-3' required
                                                name="passportNo" value={infant.passportNo} onChange={(e) => handleInfant(e, index, 'passportNo')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Issue Country*</Form.Label>
                                    <Form.Select size="sm" value={infant.passportIssueCountry} onChange={(e) => handleInfant(e, index, 'passportIssueCountry')} >
                                        {isd.map((cntry) => (
                                            <option key={cntry.id}  value={cntry.alphaOne} >{cntry.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="validationPassportExpiryDate">
                                        <Form.Label>Passport Expiry Date</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="date" placeholder="Passport Expiry Date" className='mb-3' required
                                                name="passportExpiryDate" min={passportMinDate} max={passportMaxDate} value={infant.passportExpiryDate} onChange={(e) => handleInfant(e, index, 'passportExpiryDate')} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div>
                    )}

                    <label className='check-label'>
                        <input type="checkbox" /> I confirm that I have read, understood and agree with the Rate Details and provided Terms & Conditions
                    </label>
                    <Row>
                        <Col>
                            <Button type='submit' >Proceed to Book</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default FlightTraveller
export const meal = [
    {
        "id": "1",
        "meal": "Vegetarian Meal (AVML)",
        "value": "AVML"
    },
    {
        "id": "2",
        "meal": "BabyInfant Meal (BBML)",
        "value": "BBML"
    },
    {
        "id": "3",
        "meal": "Bland Meal (BLML)",
        "value": "BLML"
    },
    {
        "id": "4",
        "meal": "Child Meal (CHML)",
        "value": "CHML"
    }, {
        "id": "5",
        "meal": "Diabetic Meal (DBML)",
        "value": "DBML"
    }, {
        "id": "6",
        "meal": "Frequent Flyer Meal (FFML)",
        "value": "FFML"
    }, {
        "id": "7",
        "meal": "Frequent Flyer Meal (FFML)",
        "value": "FFML"
    }, {
        "id": "8",
        "meal": "Fruit Plate Meal (FPML)",
        "value": "FPML"
    }, {
        "id": "9",
        "meal": "Gluten Free Meal  (GFML)",
        "value": "GFML"
    }, {
        "id": "10",
        "meal": "High Fiber Meal (HFML)",
        "value": "HFML"
    }, {
        "id": "11",
        "meal": "Hindu Meal (HNML)",
        "value": "HNML"
    },
    {
        "id": "12",
        "meal": "Jain Meal (VJML)",
        "value": "VJML"
    },
    {
        "id": "13",
        "meal": "Kosher Meal (KSML)",
        "value": "KSML"
    },
    {
        "id": "14",
        "meal": "Low Calorie Meal (LCML)",
        "value": "LCML"
    },
    {
        "id": "15",
        "meal": "Low Cholesterol Low Fat Meal (LFML)",
        "value": "LFML"
    },
    {
        "id": "16",
        "meal": "Low Sodium Low Salt Meal (LSML)",
        "value": "LSML"
    },
    {
        "id": "17",
        "meal": "Low Protein Meal (LPML)",
        "value": "LPML"
    },
    {
        "id": "18",
        "meal": "Muslim Meal (MOML)",
        "value": "MOML"
    },
    {
        "id": "19",
        "meal": "Non-Lactose Meal (NLML)",
        "value": "NLML"
    },
    {
        "id": "20",
        "meal": "Asian Meal (ORML)",
        "value": "ORML"
    },
    {
        "id": "21",
        "meal": "Low Purin Meal (PRML)",
        "value": "PRML"
    },
    {
        "id": "22",
        "meal": "Raw Vegetarian Meal (RVML)",
        "value": "RVML"
    },
    {
        "id": "23",
        "meal": "Special Meal (SPML)",
        "value": "SPML"
    },
    {
        "id": "24",
        "meal": "Japanese Meal  (SPMLJ)",
        "value": "SPMLJ"
    },
    {
        "id": "25",
        "meal": "Sea Food Meal (SFML)",
        "value": "SFML"
    },
    {
        "id": "26",
        "meal": "Vegetarian Vegan Meal (VGML)",
        "value": "VGML"
    },
    {
        "id": "27",
        "meal": "OvoLacto Vegetarian Meal (VLML)",
        "value": "VLML"
    },
    {
        "id": "28",
        "meal": "Vegetarian Oriental Meal (VOML)",
        "value": "VOML"
    }
] 