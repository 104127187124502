import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { useHotelState } from '../../context/hotelContext';
import  '../Hotel/Css/HotelSort.css'
function HotelShort() {
  const { filterResults, session_id } = useHotelState();
  const [price, priceDescending] = useState(true);
  const [rating, ratingDescending] = useState(true);
  const [name, nameDescending] = useState(true);
  const [distance, distanceOrder] = useState(true);

  const hotelSorting = async (data) => {
    let sorting
    if (data === 'name') {
      nameDescending(!name);
      if (name) {
        sorting = 'alpha-A-Z'
      } else {
        sorting = 'alpha-Z-A'
      }
    } else if (data === 'rating') {
      ratingDescending(!rating);
      if (rating) {
        sorting = 'rating-low-high'
      } else {
        sorting = 'rating-high-low'
      }
    } else if (data === 'distane') {
      distanceOrder(!distance)
      if (distance) {
        sorting = 'distance-low-high'
      } else {
        sorting = 'distance-high-low'
      }
    } else {
      priceDescending(!price)
      if (price) {
        sorting = 'price-low-high'
      } else {
        sorting = 'price-high-low'
      }

    }
    console.log(sorting)
    let json = {
      "sessionId": session_id,
      "maxResult": 20,
      "filters": {
        "sorting": sorting,
      }
    }
    await filterResults(json)
  };


 

  // useEffect(() => {
  //   getHotelsRusults()
  // }, [inputs])


  return (
    <>
      <Row>
        <Col md={2} className="pr-0">
          <div className="sort-btn" >
            <span>Sort By</span>
          </div>
        </Col>
        <Col md={10}>
          <Row>
            <Col md={3} xs={6} className="pe-1 pe-md-3" >
              <div className="sort-btn" onClick={() => hotelSorting('name')}>
                <span>Name A-Z  {name ? <FaArrowDown /> : <FaArrowUp />}</span>
              </div>
            </Col>
            <Col md={2} xs={6} className="ps-1 ps-md-3">
              <div className="sort-btn" onClick={() => hotelSorting('rating')}>
                <span>Rating {rating ? <FaArrowDown /> : <FaArrowUp />}</span>
              </div>
            </Col>
            <Col md={3} xs={6} className="pr-1 pe-0 pe-md-3">
              <div className="sort-btn" onClick={() => hotelSorting('distane')}>
                <span>Distance {distance ? <FaArrowDown /> : <FaArrowUp />}</span>
              </div>
            </Col>
            <Col md={3} xs={6} className="ps-1 ps-md-3">
              <div className="sort-btn" onClick={() => hotelSorting('price')}>
                <span>Price Lowest Price {price ? <FaArrowDown /> : <FaArrowUp />}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default HotelShort
