import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useHotelState } from '../../context/hotelContext';
import axios from 'axios';
function HotelVoucher() {
  const { hotel_book, hotelBook, setHotelBook, hotelBooking, isLoading, isError } = useHotelState();
  const location = useLocation();
  const { hotelResults, guestDetails, hotelDetail, user, roomsRules, inputs, session_id } = location.state
  console.log("booking data ", hotelBook, hotelBooking)
  const totalAdults = inputs.rooms.reduce((acc, room) => acc + room.adult, 0);
  const totalChildren = inputs.rooms.reduce((acc, room) => acc + room.child, 0);
  const originalArray = guestDetails;
  const bookingDate = new Date()


  const generateRandomString = () => {
    const randomNumber = Math.floor(Math.random() * (99 - 10 + 1) + 10);
    const randomString = String(randomNumber) + '16161235679';
    return 'TXW'+randomString;
  };
  const booking_id = generateRandomString();
  function transformData(originalArray) {
    const transformedArray = [];
    let rooms = 1
    originalArray.forEach((room) => {
      const roomObject = {
        room_no: rooms++,
        adult: {
          title: room.adults.map((adult) => adult.title),
          firstName: room.adults.map((adult) => adult.firstName),
          lastName: room.adults.map((adult) => adult.lastName),
        },
      };

      if (room.children.length > 0) {
        roomObject.child = {
          title: room.children.map((child) => child.title),
          firstName: room.children.map((child) => child.firstName),
          lastName: room.children.map((child) => child.lastName),
        };
      }

      transformedArray.push(roomObject);
    });

    return transformedArray;
  }

  function dateFormatter(dateString) {
    if (dateString) {
      let localTime = dateString.getTime();
      let localOffset = dateString.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let offset = 5.5;
      let bombay = utc + (3600000 * offset);
      let nd = new Date(bombay);
      return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate())).slice(-2));
    } else {
      return null;
    }
  }
  const transformedArray = transformData(originalArray);

  const [firstApiResponse, setFirstApiResponse] = useState(null);
  const [secondApiResponse, setSecondApiResponse] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const apiCalledRef = useRef(false);
  useEffect(() => {
    // Function to fetch data from the first API
    const fetchDataFromFirstApi = async () => {
      try {
        let json = {
          "sessionId": roomsRules.sessionId,
          "productId": roomsRules.roomRates.perBookingRates[0].productId,
          "tokenId": roomsRules.tokenId,
          "rateBasisId": roomsRules.roomRates.perBookingRates[0].rateBasisId,
          "clientRef": booking_id,
          "customerEmail": user.emailid,
          "customerPhone": user.mobile,
          "bookingNote": "Remark",
          "paxDetails": transformedArray
        }
        const response = await axios.post('http://127.0.0.1:3008/hotel_book',json);
        setFirstApiResponse(response.data);
        console.log("booking API respim",response.data)
        // Once the first API call is successful, make the second API call
        await fetchDataFromSecondApi(response.data); // Pass the necessary data from the first API response
      } catch (error) {
        console.error('Error fetching data from the first API', error);
      }
    };

    // Function to fetch data from the second API
    const fetchDataFromSecondApi = async (someValue) => {
      console.log("booking API res",someValue)
      // try {
      //   let json = {
      //     "user_password": user.emailid,
      //     "customer_email": user.emailid,
      //     "email": user.emailid,
      //     "user_id": user.id,
      //     "phone": user.mobile,
      //     "serv_provider": roomsRules.roomRates.perBookingRates[0].productId,
      //     "country_code": roomsRules.roomRates.perBookingRates[0].currency,
      //     "sale_Amount": roomsRules.roomRates.perBookingRates[0].netPrice,
      //     "currency": roomsRules.roomRates.perBookingRates[0].currency,
      //     "net_price": roomsRules.roomRates.perBookingRates[0].netPrice,
      //     "room_price": roomsRules.roomRates.perBookingRates[0].netPrice,
      //     "roomtype_name": roomsRules.roomRates.perBookingRates[0].roomType,
      //     "boardType_name": roomsRules.roomRates.perBookingRates[0].boardType,
      //     "cancel_policy": roomsRules.roomRates.perBookingRates[0].cancellationPolicy,
      //     "latitude": hotelDetail.latitude,
      //     "longitude": hotelDetail.longitude,
      //     "hotel_id": hotelDetail.hotelId,
      //     "hotel_name": hotelDetail.name,
      //     "city": hotelDetail.city,
      //     "hotel_country": hotelDetail.hotelId,
      //     "cin": dateFormatter(inputs.checkinDate),
      //     "cout": dateFormatter(inputs.checkoutDate),
      //     "travel_date": dateFormatter(inputs.checkinDate),
      //     "room_count": inputs.rooms.length,
      //     "noOf_passenger": totalAdults && totalAdults + totalChildren && totalChildren,
      //     "child_count": totalChildren && totalChildren,
      //     "adult_count": totalAdults && totalAdults,
      //     "session_id": session_id,
      //     "cust_name": transformedArray[0].adult.firstName[0],
      //     "image": hotelResults.thumbNailUrl,
      //     "booked_by": "customer",
      //     "booking_id": hotelBook&&hotelBook.clientRefNum,
      //     "status": hotelBook&&hotelBook.status?hotelBook.status:"pending",
      //     "state": "karnataka",
      //     "country": "india",
      //     "zipcode": "5698741",
      //     "ip_address": "178.398.2",
      //     "address": "",
      //     "nights": "1",
      //     "noOf_days": "2",
      //     "admin_markup": "",
      //     "agent_markup": "",
      //     "subagent_markup": "",
      //     "agent_id": "",
      //     "markupAmount": "",
      //     "markupAmountAgent": "",
      //     "markupAmountAgentToSub": "",
      //     "currencyconvert": "0",
      //     "staff_Id": "",
      //     "branch_id": "",
      //     "triptags": "",
      //     "meal_name": "",
      //     "paxDetails": transformedArray
    
      //   }
        
      //   const response = await axios.post("https://groupy.live/reactApp/hotels/hotel_booking". json);

      //   setSecondApiResponse(response.data);
      //   console.log("seconf APi",JSON.stringify(json), response.data)
      // } catch (error) {
      //   console.error('Error fetching data from the second API', error);
      // }
    };

    // Call the function to fetch data from the first API when the component mounts
    if (!apiCalledRef.current) {
      fetchDataFromFirstApi();
      apiCalledRef.current = true;
    }
  }, [apiCalledRef]);

 

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Call the first function (hotel_book)
  //     try {
  //       let json = {
  //         "sessionId": roomsRules.sessionId,
  //         "productId": roomsRules.roomRates.perBookingRates[0].productId,
  //         "tokenId": roomsRules.tokenId,
  //         "rateBasisId": roomsRules.roomRates.perBookingRates[0].rateBasisId,
  //         "clientRef": booking_id,
  //         "customerEmail": user.emailid,
  //         "customerPhone": user.mobile,
  //         "bookingNote": "Remark",
  //         "paxDetails": transformedArray
  //       }
  //        await hotel_book(json);
  //       console.log('Response from hotel_book:', hotelBook);
  //       // Now call the second function (setHotelBook) with the data from the first function
  //       try {
  //         let json = {
  //           "user_password": user.emailid,
  //           "customer_email": user.emailid,
  //           "email": user.emailid,
  //           "user_id": user.id,
  //           "phone": user.mobile,
  //           "serv_provider": roomsRules.roomRates.perBookingRates[0].productId,
  //           "country_code": roomsRules.roomRates.perBookingRates[0].currency,
  //           "sale_Amount": roomsRules.roomRates.perBookingRates[0].netPrice,
  //           "currency": roomsRules.roomRates.perBookingRates[0].currency,
  //           "net_price": roomsRules.roomRates.perBookingRates[0].netPrice,
  //           "room_price": roomsRules.roomRates.perBookingRates[0].netPrice,
  //           "roomtype_name": roomsRules.roomRates.perBookingRates[0].roomType,
  //           "boardType_name": roomsRules.roomRates.perBookingRates[0].boardType,
  //           "cancel_policy": roomsRules.roomRates.perBookingRates[0].cancellationPolicy,
  //           "latitude": hotelDetail.latitude,
  //           "longitude": hotelDetail.longitude,
  //           "hotel_id": hotelDetail.hotelId,
  //           "hotel_name": hotelDetail.name,
  //           "city": hotelDetail.city,
  //           "hotel_country": hotelDetail.hotelId,
  //           "cin": dateFormatter(inputs.checkinDate),
  //           "cout": dateFormatter(inputs.checkoutDate),
  //           "travel_date": dateFormatter(inputs.checkinDate),
  //           "room_count": inputs.rooms.length,
  //           "noOf_passenger": totalAdults && totalAdults + totalChildren && totalChildren,
  //           "child_count": totalChildren && totalChildren,
  //           "adult_count": totalAdults && totalAdults,
  //           "session_id": session_id,
  //           "cust_name": transformedArray[0].adult.firstName[0],
  //           "image": hotelResults.thumbNailUrl,
  //           "booked_by": "customer",
  //           "booking_id": hotelBook&&hotelBook.clientRefNum,
  //           "status": hotelBook&&hotelBook.status?hotelBook.status:"pending",
  //           "state": "karnataka",
  //           "country": "india",
  //           "zipcode": "5698741",
  //           "ip_address": "178.398.2",
  //           "address": "",
  //           "nights": "1",
  //           "noOf_days": "2",
  //           "admin_markup": "",
  //           "agent_markup": "",
  //           "subagent_markup": "",
  //           "agent_id": "",
  //           "markupAmount": "",
  //           "markupAmountAgent": "",
  //           "markupAmountAgentToSub": "",
  //           "currencyconvert": "0",
  //           "staff_Id": "",
  //           "branch_id": "",
  //           "triptags": "",
  //           "meal_name": "",
  //           "paxDetails": transformedArray
      
  //         }
  //         console.log(JSON.stringify(json))
  //         await setHotelBook(JSON.stringify(json));
  //         console.log('Response from setHotelBook:', hotelBooking);
  //         // If needed, you can dispatch additional actions or update the state here
  //       } catch (setHotelBookError) {
  //         console.error('Error in setHotelBook:', setHotelBookError);
  //         // Handle the error from setHotelBook
  //       }
  //     } catch (hotelBookError) {
  //       console.error('Error in hotel_book:', hotelBookError);
  //       // Handle the error from hotel_book
  //       // You may choose to dispatch an action or set state here based on the error
  //     }
  //   };
  //   if (!apiCalledRef.current) {
  //     fetchData();
  //     apiCalledRef.current = true;
  //   }
  // }, [apiCalledRef]);

return (
<>


  <div style={{border:"1px solid #ccc",padding:"15px",margin:"30px auto",maxWidth:"800px"}}>
    <table style={{width:"100%",marginBottom:"15px"}}>
       <tbody>
            <tr>
                    <td style={{width:"50%",verticalAlign:"middle"}}>
                        <h2 style={{fontSize:"22px",fontWeight:"bold",marginBottom:"5px",textAlign:"left"}}>BOOKING VOUCHER</h2>
                        <p style={paragraphText}>Date: {dateFormatter(bookingDate)}</p>
                    </td>
                    <td style={{width:"50%",textAlign:"right",verticalAlign:"middle"}}>
                    <div>
                    <img style={{width:"70px"}}  src="../../assets/images/logo.png"/>
                    </div>
                        <button style={voucherBtn}>
                            Print Voucher
                        </button>
                    </td>
                </tr>
       </tbody>
    </table>

    <p style={paragraphText}>Hi User,</p>
    <p style={paragraphText}>Thank you for booking with Trawex.</p>
    <p style={paragraphText}>Reference number :{hotelBook&&hotelBook.clientRefNum}</p>
    <p style={paragraphText}><b>Your reservation is {hotelBook&&hotelBook.status}.</b></p>

    <div style={panelGroup}>
        <div style={panelHead}>
            <h3 style={panelHeading}>Your reservation details are below. </h3>
        </div>
        <div style={panelBody}>
            <p style={paragraphText}><b>Customer name :</b>&nbsp; &nbsp; &nbsp; {user.title} {user.fname} {user.lname} </p>
            <p style={paragraphText}><b>Customer email :</b>&nbsp; &nbsp; &nbsp;{user.emailid}</p>
            <p style={paragraphText}><b>Itinerary Number :</b>&nbsp; &nbsp; &nbsp; {hotelBook&&hotelBook.referenceNum}</p>
            <p style={paragraphText}>Please refer to your itinerary number if you contact customer service for any reason. </p>
        </div>
    </div>

    <div style={panelGroup}>
        <div style={panelHead}>
            <h3 style={panelHeading}>Hotel</h3>
        </div>
        <div style={panelBody}>
            <table style={{width:"100%",textAlign:"left"}}>
                <tbody>
                    <tr>
                        <td style={{fontSize:"13px",padding:"0px 5px"}}>
                        <img style={{width:"70px"}} src={hotelResults.thumbNailUrl} alt=""/>
                        </td>
                        <td>
                           <h5><b>{hotelDetail.name}</b></h5>
                        </td>
                    </tr>
                    <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Address:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}>{hotelDetail.address}</td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Check-in:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}>  {hotelBook&&hotelBook.roomBookDetails.checkIn}</td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Check-out</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}> 	{hotelBook&&hotelBook.roomBookDetails.checkOut}</td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Number of nights:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}> {hotelBook&&hotelBook.roomBookDetails.days}</td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Number of guests:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}> {totalAdults} Adult {totalChildren} Child </td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Contact:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}> {hotelDetail.phone} </td>
                  </tr>

                  <tr>
                      <td style={{fontSize:"14px",padding:"5px 5px"}}><b>Email:</b></td>
                      <td style={{fontSize:"13px",padding:"0px 5px"}}>{hotelResults.email}</td>
                  </tr>

                </tbody>
            </table>
        </div>
    </div>

    <div>
  
    <div style={panelHead}>
            <h3 style={panelHeading}>Room Details</h3>
        </div>
    <table style={{width:"100%"}}>
        <thead>
            <tr>
                <th style={tableHead}>#</th>
                <th style={tableHead}>Room Type</th>
                <th style={tableHead}>Board Type</th>
                <th style={tableHead}>Status</th>
                <th style={tableHead}>Ikanopii_Test.com Reference </th>
            </tr>
        </thead>
        <tbody>
        {hotelBook&&hotelBook.roomBookDetails.rooms.map((room,ind)=>(
          <tr key={ind} >
                <td style={tableData}>Room {ind+1}</td>
                <td style={tableData}>{room.boardType}</td>
                <td style={tableData}>{room.description} </td>
                <td style={tableData}>{hotelBook&&hotelBook.status} </td>
                <td style={tableData}>{hotelBook&&hotelBook.clientRefNum} </td>
            </tr>
        ))}
            
        </tbody>
    </table>
    </div>

    <div style={{marginTop:"15px"}}>
    <h4 style={{fontSize:"14px",fontWeight:"bold",marginBottom:"10px"}}>Pax Details</h4>
    <table style={{width:"100%"}}>
        <thead>
            <tr>
                <th style={tableHead}>#</th>
                <th style={tableHead}>Type</th>
                <th style={tableHead}>Name</th>
            </tr>
        </thead>
        {hotelBook&&hotelBook.roomBookDetails.rooms.map((room,ind)=>(
        <tbody key={ind} >
          {room.paxDetails.name.map((pax,i)=>(
            <tr key={i} >
                <td style={tableData}>{i+1}</td>
                <td style={tableData}>Adult </td>
                <td style={tableData}>{pax} </td>
            </tr>
          ))}
        </tbody>
        ))}
    </table>
    <p style={paragraphText}>*Please note: Preferences and special requests cannot be guaranteed. Special requests are subject to availability upon check-in and may incur additional charges. </p>
    </div>
    <div style={{border:"1px solid #ccc", padding:"8px"}}>
      <h6 style={{textAlign:"left"}}>Total cost for entire stay ( Including tax recovery charges and service fees ) </h6>
      <div style={{marginTop:"15px",marginBottom:"15px"}}>
        
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th style={tableHead}>
                    Payment status
                    </th>
                    <th style={tableHead}>
                    Total cost of stay 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={tableData}>
                      Paid
                    </td>
                    <td style={tableData}>
                    {hotelBook&&hotelBook.roomBookDetails.currency}  {hotelBook&&hotelBook.roomBookDetails.NetPrice} 
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div> 
    <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
  <div style={panelHead}>
          <h3 style={panelHeading}>Cancellation Policy</h3>
      </div>
       <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}> {hotelBook&&hotelBook.roomBookDetails.cancellationPolicy} </p>
  </div>
  <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
  <div style={panelHead}>
          <h3 style={panelHeading}>Rate Comments</h3>
      </div>
      <h6 style={{textAlign:"left", padding: "5px"}}><b>Supplier</b></h6>
       <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}>{hotelBook&&hotelBook.roomBookDetails.cancellationPolicy} </p>
    </div>
    <div style={{marginTop:"15px", border:"1px solid #ccc"}}>
    <div style={panelHead}>
          <h3 style={panelHeading}>Customer Support Contact Information</h3>
      </div>
       <p style={{textAlign:"left", padding: "5px", fontSize:"14px"}}>
       For any queries and help Please contact us:<br></br>
       Email: info@Ikanopii_Test.com<br></br>
       Phone : +0000.000.0000<br></br>
       Whatsapp : +0000.000.0000
       </p>
    </div>
  </div>
</>
)
}


const panelGroup = {
  border:"1px solid #001e36",
  marginBottom:"20px",
};

const panelHead = {
  backgroundColor:"#060e9f",
  padding:"10px"
};

const panelHeading = {
  color:"#fff",
  fontSize:"14px",
  fontWeight:"bold",
  margin:"0px",
  textAlign:"left",
}

const panelBody = {
  padding:"10px",
}

const paragraphText = {
  marginTop:"0px",
  marginBottom:"10px",
  fontSize:"13px",
  textAlign:"left"
}

const voucherBtn = {
  backgroundColor:"#060e9f",
  color:"#fff",
  fontSize:"14px",
  padding:"5px 10px",
  marginTop:"5px",
  border:"1px solid #060e9f"
}

const flightTable = {
  border:"1px solid #060e9f",
  borderCollapse:"collapse"
}



const rightAlignHead = {
  backgroundColor:"#060e9f",
  padding:"5px",
  color:"#fff",
  fontSize:"12px",
  fontWeight:"normal",
  border:"1px solid #fff",
  borderCollapse:"collapse",
  textAlign:"right"
}

const rightAlignData ={
  fontSize:"12px",
  padding:"5px",
  color:"#333",
  border:"1px solid #ccc",
  borderCollapse:"collapse",
  textAlign:"right"
}

const tableHead = {
  backgroundColor:"#060e9f",
  padding:"5px",
  color:"#fff",
  fontSize:"12px",
  fontWeight:"normal",
  border:"1px solid #fff",
  borderCollapse:"collapse"
}
const tableData = {
  fontSize:"12px",
  padding:"5px",
  color:"#333",
  border:"1px solid #ccc",
  borderCollapse:"collapse"
}



export default HotelVoucher
