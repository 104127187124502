import React from 'react';
import './HeroSection.css';
import SearchEngine from '../Home/SearchEngine';

function HeroSection() {
  return (
    <div className='hero-banner'>
      <div className="hero-banner-overlay"></div>
      <div className='hero-banner-container'>
            <div className='search-engine-section'>
                <div className='banner-text'>
                    <h1>EXPLORE AND TRAVEL</h1>
                    <h6>Explore the world's most beautiful destinations and reefs</h6>
                </div>
                <div className='search-box'>
                    <div className="search-box-tab-content">
                      <SearchEngine/>    
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}

export default HeroSection
