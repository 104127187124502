import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isd } from '../../json/isd';
import { useProductContext } from '../../context/flightcontext';
import Spineer from './Spineer';
import FlightTraveller from './FlightTraveller';
import FareSummary from './FareSummary';
import FlightContant from './FlightContant';
import '../../App.css';
import './FlightDetails.css';

function FlightDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isSingleLoading, getFLightDetails, flightInfo, getFareRules, flightFareRules, isError } = useProductContext()
    const { flightInformation, session_id, destFrom, destTo, journey_type, personDetail,departureDate,returnDate, class_type } = location.state
    const [user, setuser] = useState({ emailid: "", mobile: "", country_code: "", pincode: "", address: "", city: "" });
    const [validated, setValidated] = useState(false);
    const [adults, setAdults] = useState([]);
    const [children, setChildren] = useState([]);
    const [infants, setInfants] = useState([]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson, form.checkValidity());
            gotoPreview()
        }
    };

    const gotoPreview = () => {
        navigate("/previewBooking", {
            state: {
                flightInformation: flightInformation,
                flightFareRules: flightFareRules,
                flightInfo: flightInfo,
                session_id: session_id,
                searchData:location.state,
                user: user,
                adults: adults,
                children: children,
                infants: infants
            }
        })
    }


    const gotoRevidate = async () => {
        let json = {
            "session_id": session_id,
            "fare_source_code": flightInformation.FareItinerary.AirItineraryFareInfo.FareSourceCode
        }
        console.log(JSON.stringify(json))
        try {
            await getFLightDetails(json);

        } catch (err) {
            console.log("error", err);
        }
    }



    const gotoFareRules = async () => {
        let json = {
            "session_id": session_id,
            "fare_source_code": flightInformation.FareItinerary.AirItineraryFareInfo.FareSourceCode
        }
        console.log(JSON.stringify(json))
        try {
            await getFareRules(json);
        } catch (err) {
            console.log("error", err);
        }
    }

    useEffect(() => {
        gotoFareRules()
        gotoRevidate()
        const items = JSON.parse(localStorage.getItem('user'));
        console.log("user data", items)
        if (items) {
            setuser(items.user_details);
        }
        setuser(state => ({
            ...state,
            country_code: isd[101].unNum
        }));
    }, [])

    const handlePreview = () => {
        navigate("/previewBooking", {
            state: {
            }
        })
    }

    return (
        <>
            <div className="search-detail-content">
                <div className="container">
                    <div className='search-strip'>
                        <div className='row'>
                            <div className='col-md-9 col-sm-8'>
                                <h5>Complete Your Booking</h5>
                            </div>
                            <div className='col-sm-4 col-md-3 text-sm-right xs-mt-3'>
                                <button className="back-btn" onClick={handlePreview} >Back to results</button>
                            </div>
                        </div>
                    </div>
                    {isSingleLoading ? (<Spineer />) : (
                        <div className="row">
                            <div className="col-lg-8">
                                <FlightContant
                                 flightInformation={flightInformation} 
                                 flightInfo={flightInfo} 
                                 flightFareRules={flightFareRules}
                                 searchData={location.state} />
                            </div>
                            <div className="col-lg-4">
                                <FareSummary
                                    flightInfo={flightInfo}
                                    personDetail={location.state.personDetail} />
                            </div>
                            <div className="col-lg-8">
                            <FlightTraveller 
                                    validated={validated}
                                    user={user} 
                                    searchData={location.state}
                                    adults={adults} 
                                    children={children} 
                                    infants={infants} 
                                    setAdults={setAdults} 
                                    setChildren={setChildren} 
                                    setInfants={setInfants} 
                                    handleSubmit={handleSubmit} 
                                    setuser={setuser} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default FlightDetails;
