import React from 'react'
import Footer from '../Footer/Footer'
import DOMPurify from 'dompurify'
import {Container,Row, Col} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
function CmsPage(props) {
  const { data } = props
  console.log("fd",data)
  // const YourComponent = (rawHTML) => (
  //   <div>
  //     { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHTML) }} /> }
  //   </div>
  // )
  return (
    <>
    <Card className="bg-light text-white ">
      <Card.Img src="/assets/images/about-img.jpg" alt="Card image" />
      <Card.ImgOverlay>
        <Card.Title style={{margin:'50px'}} >{data.content_heading}</Card.Title>
        {/* <Card.Text>Last updated 3 mins ago</Card.Text> */}
      </Card.ImgOverlay>
    </Card>
    <Container>
      <p className='p-5' > {data.content_title} </p>  
      <p> {data.content} </p> 
    </Container>
    <Footer/>
    </>
  )
}

export default CmsPage
