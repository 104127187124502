const FlightReducer = (state, action) => {
   
    switch (action.type) {
      case "SET_DEST_LOADING":
        return {
          ...state,
          isLoading: true,
        };
  
      case "SET_FLIGHT_DEST":
        return {
          ...state,
          isLoading: false,
          flightDest: action.payload,
        };
     
      case "DEST_ERROR":
        
        return {
          ...state,
          isLoading: false,
          isError: true,
        };

        
      case "SET_RESULTS_LOADING":
        return {
          ...state,
          isLoading: true,
        };
  
      case "SET_FLIGHT_RESULTS":
        return {
          ...state,
          isLoading: false,
          flightResults: action.payload,
        };
      case "SET_SESSION_DATA":
      return {
        ...state,
        session_id: action.payload,
      };
      case "FLIGHT_RESULTS_ERROR":
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
  

      case "SET_REVIDATE_LOADING":
        return {
          ...state,
          isSingleLoading: true,
        };
  
      case "SET_REVIDATE":
        return {
          ...state,
          isSingleLoading: false,
          flightInfo: action.payload,
        };
  
      case "SET_REVIDATE_ERROR":
        return {
          ...state,
          isSingleLoading: false,
          isError: true,
        };

        case "SET_RULES_LOADING":
        return {
          ...state,
        //  isSingleLoading: true,
        };
  
      case "SET_FARE_RULES":
        return {
          ...state,
        //  isSingleLoading: false,
          flightFareRules: action.payload,
        };
  
      case "SET_FARE_ERROR":
        return {
          ...state,
         // isSingleLoading: false,
          isError: true,
        };
      case "SET_BOOKING":
        return {
          ...state,
          flightbooking: action.payload,
        };
      case "SET_SAVE_BOOKING":
        return {
          ...state,
          savbookingdata: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default FlightReducer;