import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, InputGroup, Form, Button, Overlay, Popover, } from 'react-bootstrap';
import { FaCalendarDay, FaUser, FaLocationDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import AddTraveller from './AddTraveller';
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../App.css';
import '../Flight/FlightResults.css';

function FlightSearchEngine({ searchinputs }) {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  // const [inputs, setInputs] = useState({ departure: "", arrival: "", departure_date: "", return_date: "", traveller: "1", class_type: "" });
  const [traveller, setTraveller] = useState(searchinputs ? searchinputs.traveller : 1);
  const [journey_type, setJourneyType] = React.useState("Return");
  const [class_type, setClassType] = React.useState("Economy");
  const [destFrom, setDestFrom] = useState(searchinputs ? searchinputs.destFrom : '');
  const [destTo, setDestTo] = useState(searchinputs ? searchinputs.destTo : '');
  const [departureDate, setdepartureDate] = useState(searchinputs ? searchinputs.departureDate : '');
  const [returnDate, setreturnDate] = useState(searchinputs ? searchinputs.returnDate : '');

  const [departure, setdeparture] = useState(searchinputs?searchinputs.departure:'');
  const [departuredata, setdeparturedata] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [arrival, setarrival] = useState(searchinputs?searchinputs.arrival:'');
  const [arrivaldata, setarrivaldata] = useState([]);
  const [showArravalDropdown, setSshowArravalDropdown] = useState(false);
  const [personDetail, setpersonDetail] = useState({ adults: 1, children: 0, infants: 0 })


  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const addTravell = () => {
    setpersonDetail(personDetail)
    setShow(!show);
    setTraveller(personDetail.adults + personDetail.children + personDetail.infants)
    // setInputs(prevState => ({
    //   ...prevState,
    //   traveller: personDetail.adults + personDetail.children + personDetail.infants
    // }));
  }

  const journeyChange = (event) => {
    setJourneyType(event.target.value);
  }

  const classChange = (event) => {
    setClassType(event.target.value);
    // setInputs(existingValues => ({
    //   ...existingValues,
    //   class_type: event.target.value,
    // }))
  }




  const ChangeTraveller = (event) => {
    setShow(!show);
    setTarget(event.target);
    // console.log(event.target)
  }

  
  function dateFormatter(dateString) {
    if (dateString) {
      let localTime = dateString.getTime();
      let localOffset = dateString.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let offset = 5.5;
      let bombay = utc + (3600000 * offset);
      let nd = new Date(bombay);
      return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate())).slice(-2));
    } else {
      return null;
    }
  }
  const searchResults = async (event) => {
    const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            // console.log(formJson, form.checkValidity());
            navigate("/filghtResult", {
              state: {
                destFrom: destFrom,
                destTo: destTo,
                personDetail: personDetail,
                journey_type: journey_type,
                class_type: class_type,
                departure:departure,
                arrival:arrival,
                departureDate: departureDate,
                returnDate : returnDate,
                traveller:traveller
              }
            })

        }
  }

  const getFromAirport = async (e) => {
    const searchTerm = e.target.value;
    setdeparture(searchTerm);
    if (searchTerm.trim() === '') {
      setdeparturedata([]);
      setShowDropdown(false);
      return;
    }
    try {
      let json = {
        "destination": searchTerm
      }
      const response = await axios.post(`http://127.0.0.1:3008/airport_suggestion`, json);
      console.log("respose of destinaton", response.data)
      setdeparturedata(response.data);
      setShowDropdown(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fromairportSelect = (item) => {
    setdeparture(item.airport_name);
    setDestFrom(item);
    setShowDropdown(false);
  };




  const getToAirport = async (e) => {
    const searchTerm = e.target.value;
    setarrival(searchTerm);
    if (searchTerm.trim() === '') {
      setarrivaldata([]);
      setSshowArravalDropdown(false);
      return;
    }
    try {
      let json = {
        "destination": searchTerm
      }
      const response = await axios.post(`http://127.0.0.1:3008/airport_suggestion`, json);
      setarrivaldata(response.data);
      setSshowArravalDropdown(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const toairportSelect = (item) => {
    setarrival(item.airport_name);
    setDestTo(item);
    setSshowArravalDropdown(false);
  };


  function PopoverComponent({ onClose }) {
    const onChange = (dates) => {
      const [start, end] = dates;
      setdepartureDate(start);
      setreturnDate(end);
      if (end) {
        onClose(true);
      }
    };
    return (
      <div>
        <DatePicker
          selected={departureDate}
          onChange={onChange}
          minDate={new Date()}
          startDate={departureDate}
          endDate={returnDate}
          selectsRange
          selectsDisabledDaysInRange
          inline />
      </div>
    );
  }
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(null);

  const openPopover = (event) => {
    setPopoverOpen(!isPopoverOpen);
    setDataFromChild(event.target);
    setdepartureDate('');
    setreturnDate('');
  };

  const closePopover = (data) => {
    console.log(data)
    setPopoverOpen(false);
  };



  function OneWayCalanderComponent({ onClose }) {
    const onChange = (dates) => {
      console.log(dates)
      setdepartureDate(dates);
      // setreturnDate(end);
      if (dates) {
        onClose(true);
      }
    };
    return (
      <div>
        <DatePicker
          selected={departureDate}
          onChange={onChange}
          minDate={new Date()}
          startDate={departureDate}
          inline />
      </div>
    );
  }
    const openOneWatDate = (event) => {
    setPopoverOpen(!isPopoverOpen);
    setDataFromChild(event.target);
    setdepartureDate('');
    setreturnDate('');
  }

  return (
    <div className="modify-section">
      <Form noValidate validated={validated} onSubmit={searchResults}  >
        <div className="trip_class">
          <ul>
            <li>
              <select name="journey_type" onChange={journeyChange} >
                <option value="Return">Round-trip</option>
                <option value="OneWay">One-way</option>
                {/* <option value="Circle">Multi-city</option> */}
              </select>
            </li>
            <li>
              <select name="class_type" onChange={classChange}>
                <option value="Economy">Economy</option>
                <option value="Business">Business</option>
                <option value="First">First</option>
                <option value="Premium">Premium</option>
              </select>
            </li>
            <li>
              <p onClick={ChangeTraveller} >Traveller {traveller}</p>
            </li>
          </ul>
        </div>
        <Overlay show={show} target={target} placement="bottom" >
          <Popover id="popover-contained">
            <Popover.Body>
              <AddTraveller personDetail={personDetail} />
              <div className="d-grid gap-2 mt-3">
                <Button variant="primary" size="lg" onClick={addTravell}>Submit</Button>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>

        <Row>
          <Col md={10}>
            <Row>
              <Col md={3} >
                <Form.Group controlId="validationCustomLocation" className="search-dropdown mb-3" >
                  <InputGroup hasValidation>
                    <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                    <Form.Control ref={ref} type="text" placeholder="City or Airport" aria-describedby="inputGroupPrepend" required value={departure} name="departure" onChange={getFromAirport} onFocus={() => { setdeparture('') }} />
                    <Form.Control.Feedback type="invalid">Please choose a Departure Airport.</Form.Control.Feedback>
                  </InputGroup>
                  {showDropdown && (<ul className="dropdown-list">
                    {departuredata.airport_list && departuredata.airport_list.map((item, index) => (
                      <li key={index} onClick={() => fromairportSelect(item)}>
                        <FaLocationDot /> {item.airport_name} ({item.airport_code})
                      </li>
                    ))}
                  </ul>)}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="validationCustomLocation" className="search-dropdown mb-3" >
                  <InputGroup hasValidation>
                    <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                    <Form.Control ref={ref} type="text" placeholder="City or Airport" aria-describedby="inputGroupPrepend" required value={arrival} name="arrival" onChange={getToAirport} onFocus={() => { setarrival('') }} />
                    <Form.Control.Feedback type="invalid">Please choose a Arrival Airport.</Form.Control.Feedback>
                  </InputGroup>
                  {showArravalDropdown && (<ul className="dropdown-list">
                    {arrivaldata.airport_list && arrivaldata.airport_list.map((item, index) => (
                      <li key={index} onClick={() => toairportSelect(item)}>
                        <FaLocationDot /> {item.airport_name} ({item.airport_code})
                      </li>
                    ))}
                  </ul>)}
                </Form.Group>
              </Col>

              <Col md={6}>
              {journey_type === "Return" ? (<Row>
                  <Col xs={12}md={6} >
                    <Form.Group onClick={openPopover} className="mb-3" controlId="validationCustomcheckinDate">
                      <InputGroup hasValidation>
                        <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                        <Form.Control type="text" value={dateFormatter(departureDate)} name="departureDate" placeholder="Departure Date" aria-describedby="inputGroupPrepend" required />
                        <Form.Control.Feedback type="invalid"> Please choose Departure Date</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group onClick={openPopover} className="mb-3" controlId="validationCustomcheckoutDate">
                      <InputGroup hasValidation>
                        <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                        <Form.Control type="text" value={dateFormatter(returnDate)}  name="returnDate" placeholder="Return Date" aria-describedby="inputGroupPrepend" required />
                        <Form.Control.Feedback type="invalid"> Please choose Return Date</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                <Popover id="popover-contained">
                  <Popover.Body>
                    {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                  </Popover.Body>
                </Popover>
              </Overlay>
                </Row>
                
              ):(
                <Row>
                  <Col>
                    <Form.Group  onClick={openOneWatDate} className="mb-3" controlId="validationCustomcheckinDate">
                      <InputGroup hasValidation>
                        <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                        <Form.Control type="text" value={dateFormatter(departureDate)} name="departureDate" placeholder="Departure Date" aria-describedby="inputGroupPrepend" required />
                        <Form.Control.Feedback type="invalid"> Please choose Departure Date</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                <Popover id="popover-contained">
                  <Popover.Body>
                    {isPopoverOpen && (<OneWayCalanderComponent onClose={closePopover} />)}
                  </Popover.Body>
                </Popover>
              </Overlay>
                </Row>)}
              </Col>
              
            </Row>
          </Col>
          <Col md={2} lg={2} className='align-items-center' >
            <Button type="submit" className='btn-submit'>Search</Button>
          </Col>
        </Row>
      </Form>
    </div>

  )
}

export default FlightSearchEngine;
