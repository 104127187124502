import { createContext, useContext, useReducer } from "react";
import axios from "axios";
import CarReducer from "../../reducer/Car/carReducer";

const CarContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,
  carDest:[],
  flightResults:[],
  flightInfo: [],
  flightFareRules: '',
  session_id:'',
  
};

const CarProvider = ({children}) => {
  const [state, dispatch] = useReducer(CarReducer, initialState);
  
  const getCarDestination = async () => {
    dispatch({ type: "SET_CAR_DEST_LOADING" });
    try {
      const res = await axios.get('http://localhost:3008/car-destination-api');
      const carDest = await res.data;
      dispatch({ type: "SET_CAR_DEST", payload: carDest  })
    } catch (error) {
      dispatch({ type: "DEST_ERROR" });
    }
  };

  const getCarSearch = async (json) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    try {
      const res = await axios.post("http://localhost:3008/car-search-api", json);
      const carResults = await res.data;
      console.log("object of context",json, carResults)
      dispatch({ type: "SET_CAR_RESULTS", payload: carResults})
    } catch (error) {
      console.log(error)
      dispatch({ type: "CAR_ERROR" });
    }
  };


  const getCarDetails = async (json) => {
    dispatch({ type: "SET_DETAILS_RESULTS_LOADING" });
    try {
      const res = await axios.post("http://localhost:3008/car-rental-details-api", json);
      console.log("object of context",json)
      const carDetailResults = await res.data;
      dispatch({ type: "SET_CAR_DETAILS_RESULTS", payload: carDetailResults})
    } catch (error) {
      console.log(error)
      dispatch({ type: "CAR_DETAILS_ERROR" });
    }
  };

  const getCarBookingConfirm = async (json) => {
    dispatch({ type: "SET_CAR_BOOKING_CONFIRM_LOADING" });
    try {
      const res = await axios.post("http://localhost:3008/car-confirm-booking-api", json);
      console.log("object of car booking",json)
      const carBookingResults = await res.data;
      dispatch({ type: "SET_CAR_BOOKING_DETAILS_RESULTS", payload: carBookingResults})
    } catch (error) {
      console.log(error)
      dispatch({ type: "CAR_BOOKING_DETAILS_ERROR" });
    }
  };

  return (
    <CarContext.Provider value={{ ...state, getCarDestination, getCarSearch, getCarDetails, getCarBookingConfirm}}>
        {children}
    </CarContext.Provider>
  );
};

const useCarContext = () => {
    return useContext(CarContext);
};

export { CarProvider, CarContext, useCarContext };
