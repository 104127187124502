import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/flightcontext';
import { airport } from '../../json/airport';
function Voucher() {
    const location = useLocation();
    const { flightInfo, user, adults, children, infants,session_id,transformedData,searchData } = location.state
    const {  booking, flightbooking,saveBooking, savbookingdata  } = useProductContext()
    const  CurrencyCode  = flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode
    const  TotalTax  = flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.EquivFare.Amount
    const  BaseFare  = flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount
    const  TotalFare  = flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount
    const bookingDate = new Date()
    console.log( savbookingdata)

    const flightBook = async () => {
        let json = {
            "flightBookingInfo": {
                "flight_session_id": session_id,
                "fare_source_code": flightInfo.FareItinerary.AirItineraryFareInfo.FareSourceCode,
                "IsPassportMandatory": flightInfo.FareItinerary.IsPassportMandatory,
                "fareType": flightInfo.FareItinerary.AirItineraryFareInfo.FareType,
                "areaCode": user.country_code,
                "countryCode": user.country_code
            },
            "paxInfo": {
                "clientRef": "TWX96261134",
                "postCode": user.pincode,
                "customerEmail": user.emailid,
                "customerPhone": user.mobile,
                "bookingNote": "this is booking note",
                "paxDetails": [transformedData]
            }
        }
        console.log(JSON.stringify(json))
       await booking(json);
      }

      const saveinlocalbooking = async () => {
        let json ={
            "user_id": user.id,
            "customer_id": user.id,
            "user_password": user.emailid,
            "passanger_email": user.emailid,
            "mobile_no": user.mobile,
            "flight_session": session_id,
            "fare_type": flightInfo.FareItinerary.AirItineraryFareInfo.FareType,
            "refundable": flightInfo.FareItinerary.AirItineraryFareInfo.IsRefundable,
            "airport_from": searchData.departure,
            "airport_to": searchData.arrival,
            "departure_date": searchData.departureDate,
            "return_date": searchData.returnDate,
            "journey_type": searchData.journey_type,
            "adult_flight": searchData.personDetail.adults, 	
            "child_flight": searchData.personDetail.children,
            "infant_flight": searchData.personDetail.infants,
            "country_code": user.country_code,
            "currency": flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode,
            "type_of_booking": flightInfo.FareItineraryTicketType,
            "is_passport_mandatory": flightInfo.FareItinerary.IsPassportMandatory,
            "created_on": bookingDate,
            "travel_date": searchData.departureDate,
            "zipcode":user.pincode,
            "city": user.city,

            "country": "india",
            "company_Id": "1",
            "module": "Flight",
            "status": "pending",
            "booking_id": "BK0123654",
            "booked_by": "customer",

            "net_price": flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount,
            "sale_Amount": flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount,
            "total_Amount":flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount,
            "servicetax_amount": flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.ServiceTax.Amount,
            "totaltax_amount": flightInfo.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalTax.Amount,
            "currencyconvert": "0",
            "trawex_Markup": "5",
            "markupAmountAgent": "10",
            "markupAmount": "20",

            "pass_quantity": searchData.personDetail.adults+searchData.personDetail.children+searchData.personDetail.infants,
            "noOf_passenger": searchData.personDetail.adults+searchData.personDetail.children+searchData.personDetail.infants,
            "noOf_days": "2",
            
            "Eticket_return": "1",
            "Eticket": "1",
            "baggage": "50kg",

            "extra_baggage_amount": 0,
            "staff_Id": "",
            "branch_id": "",
            "co_id": "",
            "parent_agent_id": "",
            "serv_provider": "",
            "agent_id": "",
            "markupAmountAgentToSub": null,
            "flight_origin_country": null,
            "flight_dest_country": null,
            "cust_address": null,
            "triptags": null,
            "direction_ind": null,
            "FlightNumber_return": null,
            "class": null,
            "target": null,
            "TktTimeLimit": null,
            "UniqueID": null,
            "OriginDestinationOptions": flightInfo.FareItinerary.OriginDestinationOptions,
            "paxDetails": [transformedData]
        }
        console.log(JSON.stringify(json))
       await saveBooking(json);
      }
      useEffect(() => {
        flightBook()
        saveinlocalbooking()
        console.log('useEffect called ')
      },[])

      function getAirportDetail(airportCode) {
        return airport.filter(airport => { return airport.AirportCode === airportCode; })[0];
    }
    function getAirportName(airportCode) {
        return getAirportDetail(airportCode).AirportName;
    }
    function getAirportCity(airportCode) {
        return getAirportDetail(airportCode).City;
    }
    function formatDate(date) {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [month, day, year] = formattedDate.split(' ');
        return `  ${month} ${day} ${year}`;
    }
  return (
    <>
      <div style={{border:"1px solid #ccc",padding:"15px",margin:"30px auto",maxWidth:"800px"}}>
        <table style={{width:"100%",marginBottom:"15px"}}>
           <tbody>
                <tr>
                        <td style={{width:"50%",verticalAlign:"middle"}}>
                            <h2 style={{fontSize:"22px",fontWeight:"bold",marginBottom:"5px"}}>BOOKING VOUCHER</h2>
                            <p style={paragraphText}>Date: Jun Wed 07, 2023</p>
                        </td>
                        <td style={{width:"50%",textAlign:"right",verticalAlign:"middle"}}>
                        <div>
                        <img src="../../assets/images/logo.png"/>
                        </div>
                            <button style={voucherBtn}>
                                Print Voucher
                            </button>
                        </td>
                    </tr>
           </tbody>
        </table>

        <p style={paragraphText}>Hi User,</p>
        <p style={paragraphText}>Thank you for booking with Trawex.</p>
        <p style={paragraphText}>Reference number : ST12612518647074</p>
        <p style={paragraphText}>Please print and take this booking confirmation with you to the airport. It may speed up your check-in experience.</p>
        <p style={paragraphText}>For any concerns / queries related to this booking, please mention this reference number in all your future communications with us.</p>
        <p style={paragraphText}>Booking Status : <b>Ticket in Process</b></p>

        <div style={panelGroup}>
            <div style={panelHead}>
                <h3 style={panelHeading}>Travellers Information:</h3>
            </div>
            <div style={panelBody}>
               {adults.map((adult, index)=>(
                <p style={paragraphText} key={index} > {index+1}. {adult.title} {adult.firstName} {adult.lastName}</p>
               ))}
               {children.map((adult, index)=>(
                <p style={paragraphText} key={index} > {index+1}. {adult.title} {adult.firstName} {adult.lastName}</p>
               ))}
               {infants.map((adult, index)=>(
                <p style={paragraphText} key={index} > {index+1}. {adult.title} {adult.firstName} {adult.lastName}</p>
               ))}
            </div>
        </div>

        <div style={panelGroup}>
            <div style={panelHead}>
                <h3 style={panelHeading}>Booking Information:</h3>
            </div>
            <div style={panelBody}>
                <table style={{width:"100%"}}>
                    <tbody>
                        <tr>
                            <td style={{fontSize:"13px",padding:"0px 5px"}}>
                                <b>PNR :</b> UARZJ26
                            </td>
                            <td style={{fontSize:"13px",padding:"0px 5px"}}>
                                <b>Reservation Number :</b> ST12612518647074
                            </td>
                            <td style={{fontSize:"13px",padding:"0px 5px"}}>
                                <b>Ticket Number : 1234567890</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div>
        <h4 style={{fontSize:"14px",fontWeight:"bold",marginBottom:"10px"}}>Departure Airlines</h4>
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th style={tableHead}>Dep. Date</th>
                    <th style={tableHead}>Dep. Time</th>
                    <th style={tableHead}>From</th>
                    <th style={tableHead}>To</th>
                    <th style={tableHead}>Arv. Date</th>
                    <th style={tableHead}>Arv. Time</th>
                    <th style={tableHead}>Flight No.</th>
                    <th style={tableHead}>Baggage</th>
                    <th style={tableHead}>Airline</th>
                    <th style={tableHead}></th>
                </tr>
            </thead>
            {flightInfo.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.map((results, index) => (
            <tbody key={index} >
                <tr>
                    <td style={tableData}>{formatDate(results.FlightSegment.DepartureDateTime)}</td>
                    <td style={tableData}>{formatDate(results.FlightSegment.DepartureDateTime)}</td>
                    <td style={tableData}>{getAirportName(results.FlightSegment.DepartureAirportLocationCode)}({results.FlightSegment.DepartureAirportLocationCode})</td>
                    <td style={tableData}>{getAirportName(results.FlightSegment.ArrivalAirportLocationCode)} ({results.FlightSegment.ArrivalAirportLocationCode})</td>
                    <td style={tableData}>{formatDate(results.FlightSegment.ArrivalDateTime)}</td>
                    <td style={tableData}>{formatDate(results.FlightSegment.ArrivalDateTime)}</td>
                    <td style={tableData}>{results.FlightSegment.MarketingAirlineCode}-{results.FlightSegment.FlightNumber}</td>
                    <td style={tableData}>ADT : 20KG</td>
                    <td style={tableData}>
                        {/* <img src="../../assets/images/6E.svg"/> */}
                        <img src={"/assets/airlines/" + results.FlightSegment.MarketingAirlineCode + ".gif"} alt="airline-logo" />
                        </td>
                    <td style={tableData}>{results.FlightSegment.MarketingAirlineName}</td>
                </tr>
            </tbody>
             ))}
        </table>
        </div>

        <div style={{marginTop:"15px"}}>
        <h4 style={{fontSize:"14px",fontWeight:"bold",marginBottom:"10px"}}>Return Airlines</h4>
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th style={tableHead}>Dep. Date</th>
                    <th style={tableHead}>Dep. Time</th>
                    <th style={tableHead}>From</th>
                    <th style={tableHead}>To</th>
                    <th style={tableHead}>Arv. Date</th>
                    <th style={tableHead}>Arv. Time</th>
                    <th style={tableHead}>Flight No.</th>
                    <th style={tableHead}>Baggage</th>
                    <th style={tableHead}>Airline</th>
                    <th style={tableHead}></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={tableData}>Jun 22</td>
                    <td style={tableData}>11:40</td>
                    <td style={tableData}>Changi Airport, Singapore(SIN)</td>
                    <td style={tableData}>Chennai Airport, Chennai(MAA)</td>
                    <td style={tableData}>Jun 22</td>
                    <td style={tableData}>13:05</td>
                    <td style={tableData}>6E-1004</td>
                    <td style={tableData}>ADT : 20KG</td>
                    <td style={tableData}><img src="../../assets/images/6E.svg"/></td>
                    <td style={tableData}>Indigo Airlines</td>
                </tr>
            </tbody>
        </table>
        </div>

        <div style={{marginTop:"15px",marginBottom:"15px"}}>
            <table style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th style={tableHead}>
                         Pax
                        </th>
                        <th style={tableHead}>
                        Base Fare	
                        </th>
                        <th style={tableHead}>
                        Tax & Charges  
                        </th>
                        <th style={tableHead}>
                        Extra Baggage	
                        </th>
                        <th style={rightAlignHead}>
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={tableData}>
                          Adult X {searchData.personDetail.adults}, Child X {searchData.personDetail.children}, Infant X {searchData.personDetail.infants}	
                        </td>
                        <td style={tableData}>
                        {CurrencyCode} {BaseFare}	
                        </td>
                        <td style={tableData}>
                        {CurrencyCode} {TotalTax}	
                        </td>
                        <td style={tableData}>
                        {CurrencyCode} 0.00	
                        </td>
                        <td style={rightAlignData}>
                          <b>{CurrencyCode} {TotalFare}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" style={{textAlign:"right",fontSize:"16px",color:"#fa2a00",padding:"5px",fontWeight:"bold",border:"1px solid #ccc",borderCollapse:"collapse"}}>
                          NET PAYABLE : {CurrencyCode} {TotalFare}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p style={paragraphText}>
        + BAGGAGE DISCOUNTS MAY APPLY BASED ON FREQUENT FLYER STATUS/ONLINE CHECKIN/FORM OF PAYMENT/MILITARY/ETC.
        </p>
        <p style={paragraphText}>
        * If Terminal number is blank in voucher, then no information available about that.
        </p>

        <table style={{width:"100%"}}>
            <tbody>
                <tr>
                    <td style={{fontSize:"13px"}}>
                      <b>Email :</b> info@jestiloholiday.com
                    </td>
                    <td style={{fontSize:"13px"}}>
                     <b>Mobile :</b> +443316300400
                    </td>
                </tr>
            </tbody>
        </table>

        

      </div>
    </>
  )
}

const panelGroup = {
    border:"1px solid #001e36",
    marginBottom:"20px",
};

const panelHead = {
    backgroundColor:"#060e9f",
    padding:"10px"
};

const panelHeading = {
    color:"#fff",
    fontSize:"14px",
    fontWeight:"bold",
    margin:"0px",
}

const panelBody = {
    padding:"10px",
}

const paragraphText = {
    marginTop:"0px",
    marginBottom:"10px",
    fontSize:"13px"
}

const voucherBtn = {
    backgroundColor:"#060e9f",
    color:"#fff",
    fontSize:"14px",
    padding:"5px 10px",
    marginTop:"5px",
    border:"1px solid #060e9f"
}

const flightTable = {
    border:"1px solid #060e9f",
    borderCollapse:"collapse"
}

const tableHead = {
    backgroundColor:"#060e9f",
    padding:"5px",
    color:"#fff",
    fontSize:"12px",
    fontWeight:"normal",
    border:"1px solid #fff",
    borderCollapse:"collapse"
}

const rightAlignHead = {
    backgroundColor:"#060e9f",
    padding:"5px",
    color:"#fff",
    fontSize:"12px",
    fontWeight:"normal",
    border:"1px solid #fff",
    borderCollapse:"collapse",
    textAlign:"right"
}

const rightAlignData ={
    fontSize:"12px",
    padding:"5px",
    color:"#333",
    border:"1px solid #ccc",
    borderCollapse:"collapse",
    textAlign:"right"
}

const tableData = {
    fontSize:"12px",
    padding:"5px",
    color:"#333",
    border:"1px solid #ccc",
    borderCollapse:"collapse"
}

export default Voucher
