import React, { useState } from 'react';
import { Tab ,Tabs} from 'react-bootstrap';
import '../../App.css';
import '../Flight/FlightResults.css';
import HotelSearchEngine from '../Hotel/HotelSearchEngine';
import FlightSearchEngine from '../Flight/FlightSearchEngine';
import Searchcar from '../Car/SearchCar';
function FlightSearch() {
  const [key, setKey] = useState('flight');
  return (
    <div>
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-1">
        <Tab eventKey="flight" title="Flight">
          <FlightSearchEngine />
        </Tab>
        <Tab eventKey="hotel" title="Hotel" className="mb-1">
          <HotelSearchEngine />
        </Tab>
        <Tab eventKey="car" title="Car">
           <Searchcar/>
        </Tab>
      </Tabs>
    </div>
  )
}

export default FlightSearch