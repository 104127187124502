import React, { useEffect, useState } from 'react';
import '../../App.css';
import './PreviewBooking.css';
import { useLocation, useNavigate } from 'react-router-dom';
import FlightContant from './FlightContant';

function PreviewBooking() {
  const location = useLocation();
  const navigate = useNavigate();
  const { flightInformation, flightInfo, flightFareRules, user, adults, children, infants, session_id} = location.state
  const [transformedData, setTransformedData] = useState([]);
  useEffect(() => {
    // Transform data function
    const transformData = () => {
   
      const adult = {
        "title": [],
        "firstName": [],
        "lastName": [],
        "dob": [],
        "nationality": [],
        "passportNo": [],
        "passportIssueCountry": [],
        "passportExpiryDate": [],
        "ExtraServiceOutbound": [],
        "ExtraServiceInbound": []
      };
      
        const child = {
        "title": [],
        "firstName": [],
        "lastName": [],
        "dob": [],
        "nationality": [],
        "passportNo": [],
        "passportIssueCountry": [],
        "passportExpiryDate": [],
        "ExtraServiceOutbound": [],
        "ExtraServiceInbound": []
      };
    
    
      const  infant = {
        "title": [],
        "firstName": [],
        "lastName": [],
        "dob": [],
        "nationality": [],
        "passportNo": [],
        "passportIssueCountry": [],
        "passportExpiryDate": [],
        "ExtraServiceOutbound": [],
        "ExtraServiceInbound": []
      };
    
      adults.forEach((item) => {
        adult["title"].push(item.title);
        adult["firstName"].push(item.firstName);
        adult["lastName"].push(item.lastName);
        adult["dob"].push(item.dob);
        adult["nationality"].push(item.nationality.trim().toUpperCase());
        adult["passportNo"].push(item.passportNo);
        adult["passportIssueCountry"].push(item.passportIssueCountry.trim().toUpperCase());
        adult["passportExpiryDate"].push(item.passportExpiryDate);
        // Assuming ExtraServiceOutbound and ExtraServiceInbound are arrays in the original data
        adult["ExtraServiceOutbound"].push([]);
        adult["ExtraServiceInbound"].push([]);
      });
      if (children.length > 0) children.forEach((item) => {
        child["title"].push(item.title);
        child["firstName"].push(item.firstName);
        child["lastName"].push(item.lastName);
        child["dob"].push(item.dob);
        child["nationality"].push(item.nationality.trim().toUpperCase());
        child["passportNo"].push(item.passportNo);
        child["passportIssueCountry"].push(item.passportIssueCountry.trim().toUpperCase());
        child["passportExpiryDate"].push(item.passportExpiryDate);
        // Assuming ExtraServiceOutbound and ExtraServiceInbound are arrays in the original data
        child["ExtraServiceOutbound"].push([]);
        child["ExtraServiceInbound"].push([]);
      });
    
      if (infants.length > 0){
        infants.forEach((item) => {
          infant["title"].push(item.title);
          infant["firstName"].push(item.firstName);
          infant["lastName"].push(item.lastName);
          infant["dob"].push(item.dob);
          infant["nationality"].push(item.nationality.trim().toUpperCase());
          infant["passportNo"].push(item.passportNo);
          infant["passportIssueCountry"].push(item.passportIssueCountry.trim().toUpperCase());
          infant["passportExpiryDate"].push(item.passportExpiryDate);
          // Assuming ExtraServiceOutbound and ExtraServiceInbound are arrays in the original data
          infant["ExtraServiceOutbound"].push([]);
          infant["ExtraServiceInbound"].push([]);
        });
      } else{
       
      }
      setTransformedData({"adult":adult,"child":child,"infant":infant});
    };

    // Call the transformData function
    transformData();
  }, []);


  const gotoVouvher = () => {
    navigate("/voucher", {
      state: {
        flightInformation: flightInformation,
        flightFareRules: flightFareRules,
        flightInfo: flightInfo,
        session_id: session_id,
        user: user,
        adults: adults,
        children: children,
        infants: infants,
        transformedData:transformedData,
        searchData:location.state
      }
    })
  }

  return (
    <>
      <div className="search-detail-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8" style={{ 'margin': 'auto' }}>
              <div className='search-strip'>
                <h5>Verify Your Booking Details</h5>
              </div>
              <FlightContant
                flightInformation={flightInformation} 
                flightInfo={flightInfo} 
                flightFareRules={flightFareRules}
                searchData={location.state} />
              <div className='passenger-section'>
                <h5>Contact Details</h5>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>City</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{user.emailid}</td>
                        <td>+{user.country_code} {user.mobile}</td>
                        <td>{user.city}</td>
                        <td>{user.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='passenger-section'>
                <h5>Passenger Details</h5>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <tr>
                        <th>Sl. No</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>DOB</th>
                        <th>Gender</th>
                        <th>Passport No.</th>
                        <th>Issue Country</th>
                        <th>Passport Expiry Date</th>
                      </tr>
                    </thead>
                    <tbody >
                      {adults && adults.map((adult, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{adult.title} {adult.firstName} {adult.lastName}</td>
                          <td>Adult</td>
                          <td>{adult.dob}</td>
                          <td>{adult.gender}</td>
                          <td>{adult.passportNo}</td>
                          <td>{adult.passportIssueCountry}</td>
                          <td>{adult.passportExpiryDate}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tbody >
                      {children && children.map((adult, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{adult.title} {adult.firstName} {adult.lastName}</td>
                          <td>Child</td>
                          <td>{adult.dob}</td>
                          <td>{adult.gender}</td>
                          <td>{adult.passportNo}</td>
                          <td>{adult.passportIssueCountry}</td>
                          <td>{adult.passportExpiryDate}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tbody >
                      {infants && infants.map((adult, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{adult.title} {adult.firstName} {adult.lastName}</td>
                          <td>Infants</td>
                          <td>{adult.dob}</td>
                          <td>{adult.gender}</td>
                          <td>{adult.passportNo}</td>
                          <td>{adult.passportIssueCountry}</td>
                          <td>{adult.passportExpiryDate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <button className='edit-btn mr-2'>Edit</button>
              <button className='edit-btn' onClick={() => gotoVouvher()}>Confirm Booking</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewBooking
