import { createContext,useState, useContext, useReducer, useEffect } from "react";
import { useCarContext } from "../Car/carContext";
import reducer from "../../reducer/Car/carFilterReducer";


const CarFilterContext = createContext();

const initialState = {
  filter_cars: [],
  all_cars: [],
  sorting_value: "lowest",
  selectedFilters:[],
  stopFilters:[],
  filters: {
    price: 0,
    minPrice: 0,
    maxPrice: 0,
    nonStop:true,
    oneStop:true,
    twoStop:true,
    IsRefundable:true,
    Refundable:true,
    MarketingAirlineName: "All",
    layowerAirports: [],
    layowerFlags:[], 
  },
};

export const CarFilterContextProvider = ({ children }) => {
  const { carResults } = useCarContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [active, setActive] = useState(null);
  // update the filter values

  const nonStop = (event)=>{
    let value =  event.target.checked
    let name = 'nonStop'
    dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
   }
   const oneStop = (event)=>{
     let value =  event.target.checked
     let name = 'oneStop'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
    const twoStop = (event)=>{
    let value =  event.target.checked
    let name = 'twoStop'
    dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
   }
   
   const IsRefundable = (event)=>{
     let value =  event.target.checked
     let name = 'IsRefundable'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
    const Refundable = (event)=>{
     let value =  event.target.checked
     let name = 'Refundable'
     dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    }
   
    // const navigate = (event)=>{
    //   console.log(event)
    //   let value =  event.target.checked
    //   let name = 'Refundable'
    //   dispatch({ type: "UPDATE_FILTERS", payload: { name, value }  });
    //  }

  //   const NavLink = ({id,tabProperty, tabTitle, imgSrc, isActive}) => { 
    
  //   return (
  //     <div onClick={()=>{navigate({id,tabProperty, tabTitle, imgSrc, isActive})}} className={isActive ? "active" : ""}>
  //       <img src={imgSrc} alt="Layover" />
  //       <label>{tabTitle}  </label>
  //     </div>
  //   );  
  // };
  
   const navigate = (event) => {
   console.log("navigate id",event)
    setActive(event.id);
    let value =   event.tabProperty
    let name = "timeRange"
    dispatch({ type: "UPDATE_CAR_FILTERS", payload: { name, value }  });
  };

  const navigateArrival = (event) => {
     console.log("navigateArrival id",event)
     setActive(event.id);
     let value =   event.tabProperty
     let name = "timeRangeArrive"
     dispatch({ type: "UPDATE_CAR_FILTERS", payload: { name, value }  });
   };
  
  

  const FilterValuePrice = (event)=>{
    let value = event
    let name = "price"
    dispatch({ type: "UPDATE_CAR_FILTERS", payload: { name, value }  }); 
  }

  const FilterTransmission = (event)=>{
    console.log("selcted check box",event.target.name,event.target.value, event.target.checked)
    let tvalue = event.target.value
    let tname = event.target.name
    let tchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_TRANSMISSION_FILTERS", payload: { tname, tvalue, tchecked }  }); 
  }

  const FilterPickup = (event)=>{
    console.log("selcted check box",event.target.name,event.target.value, event.target.checked)
    let cpfvalue = event.target.value
    let cpfname = event.target.name
    let cpfchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_PICKUP_FILTERS", payload: { cpfname, cpfvalue, cpfchecked }  }); 
  }

  const FilterDropoff = (event)=>{
    console.log("selcted check box",event.target.name,event.target.value, event.target.checked)
    let cdfvalue = event.target.value
    let cdfname = event.target.name
    let cdfchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_DROPOFF_FILTERS", payload: { cdfname, cdfvalue, cdfchecked }  }); 
  }

  
  const FilterCarRate = (event)=>{
    console.log("selcted check box",event.target.name,event.target.value, event.target.checked)
    let crvalue = event.target.value
    let crname = event.target.name
    let crchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_RATE_FILTERS", payload: { crname, crvalue, crchecked }  }); 
  }


  

  const FilterFuelType = (event)=>{
    console.log("selcted check box",event.target.name,event.target.value, event.target.checked)
    let ftvalue = event.target.value
    let ftname = event.target.name
    let ftchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_FUEL_TYPE_FILTERS", payload: { ftname, ftvalue, ftchecked }  }); 
  }


  const FilterCarSpec = (event)=>{
    console.log("selcted car specification check box",event.target.name,event.target.value, event.target.checked)
    let csvalue = event.target.value
    let csname = event.target.name
    let cschecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_SPEC_FILTERS", payload: { csname, csvalue, cschecked }  }); 
  }

  const FilterCarPassenger = (event)=>{
    console.log("selcted car passenger check box",event.target.name,event.target.value, event.target.checked)
    let cpvalue = event.target.value
    let cpname = event.target.name
    let cpchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_PASSENGER_FILTERS", payload: { cpname, cpvalue, cpchecked }  }); 
  }



  const FilterSupplier = (event)=>{
    console.log("selcted car passenger check box",event.target.name,event.target.value, event.target.checked)
    let csfvalue = event.target.value
    let csfname = event.target.name
    let csfchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_SUPPLIER_FILTERS", payload: { csfname, csfvalue, csfchecked }  }); 
  }


  

  const FilterCustomRating = (event)=>{
    console.log("selcted car rating check box",event.target.name,event.target.value, event.target.checked)
    let crfvalue = event.target.value
    let crfname = event.target.name
    let crfchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_CUSTOM_RATING_FILTERS", payload: { crfname, crfvalue, crfchecked }  }); 
  }



  
  const FilterBaggage = (event)=>{
    console.log("selcted car rating check box",event.target.name,event.target.value, event.target.checked)
    let cbfvalue = event.target.value
    let cbfname = event.target.name
    let cbfchecked = event.target.checked
    dispatch({ type: "UPDATE_CAR_BAGGAGE_FILTERS", payload: { cbfname, cbfvalue, cbfchecked }  }); 
  }

  const clearFilters = () => {
    dispatch({ type: "CLEAR_FILTERS" });
  };



 useEffect(() => {
  dispatch({ type: "FILTER_CARS" });
 // dispatch({ type: "SORTING_PRODUCTS" });
}, [carResults]);



  // to load all the products for grid and list view
  useEffect(() => {
    dispatch({ type: "LOAD_FILTER_CARS", payload: carResults });
  },[carResults]);

  return (
    <CarFilterContext.Provider
      value={{
        ...state,
            FilterValuePrice,
            FilterTransmission,
            FilterPickup,
            FilterDropoff,
            FilterSupplier,
            FilterCustomRating,
            FilterBaggage,
            FilterFuelType,
            FilterCarRate,
            FilterCarSpec,
            FilterCarPassenger,
            clearFilters,
            nonStop,
            oneStop,
            twoStop,
            IsRefundable,
            Refundable,
            navigate,
            navigateArrival,
          // NavLink,
            active
        // setairlines
      }}>
      {children}
    </CarFilterContext.Provider>
  );
};

export const useCarFilterContext = () => {
  return useContext(CarFilterContext);
};