import React from 'react'
import loading from '../../assets/images/loading.gif'
import { Row, Col, InputGroup, Form, Button, Overlay, Popover, } from 'react-bootstrap';
function Spineer() {
  return (
    <Row>
      <Col style={{textAlign: 'center'}} >
      <img src={loading} width={200} alt="icon-img"/>
      </Col>
    </Row>
  )
}

export default Spineer
