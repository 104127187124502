import React, { useState ,useEffect} from 'react'
import { NavDropdown,Navbar,Nav,Container,Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaFileLines, FaGear, FaFile, } from 'react-icons/fa6';
import axios from "axios";
import './Header.css';

function Header() {
    const [currency, setCurrency] = useState({"id": "1","image": "/assets/currency/eur.png","currency": "USD","value": "United States Dollar"});
    const [validated, setValidated] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [loginmgs, setlogiMgs] = useState(false);
    const [show, setShow] = useState(false);
    const [errorMgs, seterrorMgs] = useState('');
    const [user, setUser] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () =>{
        setShow(true) 
        setlogiMgs(false);
        setValidated(false);
    } 

    const handleShowModalOne = () => {
      setModalState("modal-one")
      setShow(false) 
    }
    const handleCloseNew = () => {
      setModalState("close")
    }
    const handleCurrency = (currency)=>{
        setCurrency(currency)
    }

    const handleLogin = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson, form.checkValidity());
            let login = "https://groupy.live/reactApp/home/login"
            axios.post(login,JSON.stringify(formJson))
            .then((response) => {
                console.log("respo",JSON.stringify(response.data));
                if(response.data.code!==0){
                    setShow(false)
                    getuserdetails(formJson)
                }else{
                    setShow(true)
                    setlogiMgs(true)
                    seterrorMgs(response.data.msg)
                }
            })
            .catch((error) => {
                console.log(error);
            });

        }
    };

    const getuserdetails = (formJson)=>{
        let url = 'https://groupy.live/reactApp/home/getuserdetails'
        let json = {"email":formJson.email}
        axios.post(url,JSON.stringify(json))
        .then((response) => {
            console.log("respo",JSON.stringify(response.data));
            if(response.data){
                setUser(response.data)
                localStorage.setItem('user', JSON.stringify(response.data));
            }else{
               
            }
        })
        .catch((error) => {
            console.log(error);
        });
    } 

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
            setUser(items);
        }
        }, []);
       const logout = ()=>{
        localStorage.removeItem("user")
        setUser(null)
       }
    const handleSignup = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson, form.checkValidity());
            let login = "https://groupy.live/reactApp/home/sign_up"
            axios.post(login,JSON.stringify(formJson))
            .then((response) => {
                console.log("respo",JSON.stringify(response.data));
                if(response.data.code===1){
                    setModalState("close")
                     setShow(true)
                     setlogiMgs(false)
                     setValidated(true)
                }else{
                    seterrorMgs(response.data.msg)
                    setlogiMgs(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

  
    
    const gotoReview = async () => {
        console.log("regis")
        let login = "https://groupy.live/reactApp/home/login"
        let sign_up = "https://groupy.live/reactApp/home/sign_up"
        let forgotpassword = "https://groupy.live/reactApp/home/forgotpassword"
        let getuserdetails = "https://groupy.live/reactApp/home/getuserdetails"
        let update_user = "https://groupy.live/reactApp/home/update_user"
        let json = {
            "email": "suniot@gmail.com",
            "fname": "xyz",
            "lname": "abc",
            "password": "xyz@123",
            "phone_number": "9685741258",
            "user_password": "suniot@gmail.com"
        }
        let logindata = {
            "email": "xyz@gmail.com",
            "password": "xyz@123"
        }
        let updateuser = {
            "email": "subbu@gmail.com",
            "fname": "abc",
            "lname": "xyz",
            "phone_number": "9685741258",
            "user_title": "MR"
        }
        let userdetails = {
            "email": "siddhu9440@gmail.com"
        }
        let forgot = {
            "forgot_email": "siddhu9440@gmail.com"
        }
        let data = JSON.stringify({
            "email": "xz@gmail.com",
            "password": "xyz@123"
        });
        let datama = JSON.stringify({
            "booking_id":"HT256987"
        });
        let details = "https://groupy.live/reactApp/hotels/gethotelbookingdetails"
        let datam = JSON.stringify({
            "user_id" : "3",
            "status" : "pending"
        });
        let voucher = "https://groupy.live/reactApp/flights/getallflightbooking"
        let hoturl = "https://groupy.live/reactApp/hotels/get_hotel_details"
        let hotelboo = JSON.stringify({
            "insert_id":"",
            "session_id":"AYUIKKJHHYTFRS",
            "hotelCode":"12345",
            "hotelName":"Hotel Test",
            "room_description":"sampel room",
            "boardBasis":"sample",
            "room_price":"8765",
            "currency":"USD",
            "meal_code":"SY123",
            "booking_id":"HK256966",
            "cancellation_policy":"Your Cancellation amount is 0",
            "booked_by":"Customer",
            "mailto:customer_email":"xyz@gmail.com",
            "address":"123 xyz street",
            "latitude":"18.29",
            "city_name":"ABC",
            "country":"india",
            "org_cin":"2023-11-23",
            "org_cout":"2023-11-30",
            "room_count":"2",
            "child_count":"2",
            "nights":"3",
            "days":"4",
            "adult_count":"4",
            "admin_markup":"xyz",
            "agent_amrkup":"abc",
            "sub_agent_markup":"qww",
            "net_price":"12000",
            "phone":"9876098765",
            "mailto:email":"qwe@gmail.com"
        })

        let hotelBooking ="https://groupy.live/reactApp/hotels/hotel_booking"
        let hotelBoo = JSON.stringify({
            "sessionId": "QWERSAA",
            "hotelId": "882885",
            "tokenId": "HTB0zd1QyPEeR3oIpmVn",
            "booking_id":"123456",
          "productId": "trx101",
          "roomType": "STANDARD ROOM",
          "description": "STANDARD ROOM",
          "roomCode": "116574885",
          "fareType": "",
          "rateBasisId": "MTU3",
          "currency": "INR",
          "netPrice": "9961.28",
          "boardType": "Room Only",
          "maxOccupancyPerRoom": "4",
          "inventoryType": "prepaid",
          "cancellationPolicy": "room_code",
          "facilities": "TV"
          }
          )
            axios.post(hotelBooking,hotelBoo)
            .then((response) => {
                console.log("respo",JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });


    }
    return (
        <>

<Modal className="login-modal" show={modalState === "modal-one"} onHide={handleCloseNew}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className='modal-wrapper'>
                <div className='w-100'>
                  <h4 className='mb-3'>SIGN UP</h4>
                  <Form noValidate validated={validated} onSubmit={handleSignup}>
                    <Form.Group className="mb-3" controlId="formBasicfname">
                      <Form.Control type="text" required placeholder="First Name"  name='fname'   />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasiclname">
                      <Form.Control type="text" required placeholder="Last Name" name='lname' />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicemail">
                      <Form.Control type="email" required placeholder="Email" name='email' />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicphonenumber">
                      <Form.Control type="number" required placeholder="Phone Number" name='phone_number'/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="password" required placeholder="Password" name='password' />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                      <Form.Control type="password" required placeholder="Confirm Password" name='user_password'/>
                    </Form.Group>
                    {loginmgs?<p>{errorMgs}</p>:''}
                    <div className="text-center">
                      <Button variant="primary" type="submit" className="login-btn"> SIGN UP </Button>
                    </div>
                  </Form>
                  
                </div>
              </div>
            </Col>
            <Col md={6}>
              <img className="img-fluid" src='/assets/images/login.jpg' alt="" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>


      <Modal className="login-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <Row>
            <Col md={6}>
              <img className="img-fluid login-img" src='/assets/images/login.jpg' alt="" />
              <div className='text-center w-100'>
                <button type="submit" className="login-btn btn btn-primary mb-5" onClick={handleShowModalOne}> Register</button>
              </div>
            </Col>
            <Col md={6}>
              <div className='modal-wrapper'>
                <div className='w-100'>
                  <h4 className='mb-3'>SIGN IN</h4>
                  <Form noValidate validated={validated} onSubmit={handleLogin}> 
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" name='email'  required placeholder="Enter email" />
                      {/* <Form.Control.Feedback type="invalid">
                         Please enter a valid email
                     </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password"  name='password'  required placeholder="Password" />
                      {/* <Form.Control.Feedback type="invalid">
                         Please enter a valid password
                     </Form.Control.Feedback> */}
                    </Form.Group>
                    {loginmgs?<p>{errorMgs}</p>:''}
                    <div className="text-center">
                      <Button variant="primary" type="submit" className="login-btn"> SIGN IN</Button>
                    </div>

                    <div className="text-center pt-3">
                      <a href='#'>Forgot Password ?</a>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>


            <Navbar expand="lg" className="b2b-header">
                <Container>
                    <Navbar.Brand as={Link} to="/" >
                        <img className='logo' src='/assets/images/logo.png' alt='' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/" >Home</Nav.Link>
                            <Nav.Link as={Link} to="/about" >About us</Nav.Link>
                            <Nav.Link as={Link} to="/contact" >Contact us</Nav.Link>
                            <NavDropdown title={<span><img className='flagIco'src={currency.image} alt='' />{currency.currency}</span>} id="basic-nav-dropdown" className='ms-4'>
                                {currencyes.map((curr, ind) => (
                                    <NavDropdown.Item key={ind}  onClick={() => handleCurrency(curr)} >
                                        <img className='flagIco' src={curr.image} alt='curr' />{curr.currency} - {curr.value}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            {!user&&<Nav.Link onClick={handleShow}>SIgh In</Nav.Link>}
                            {user&&<NavDropdown title={<span><FaUserAlt />Hi.. {user&&user.user_details.fname}</span>} id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to={"/bookings"}><FaFile />Bookings</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={"/manage-bookings"}><FaFileLines />Manage Booking</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={"/settings"}><FaGear />Settings</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={"/"} onClick={logout} ><FaSignOutAlt />Logout</NavDropdown.Item>
                            </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;

export const currencyes = [
    {
        "id": "0",
        "image": "/assets/currency/eur.png",
        "currency": "EUR",
        "value": "EURO"
    },
    {
        "id": "1",
        "image": "/assets/currency/usd.png",
        "currency": "USD",
        "value": "United States Dollar"
    },
    {
        "id": "2",
        "image": "/assets/currency/ils.png",
        "currency": "ILS",
        "value": "Israeli new shekel"
    },
    {
        "id": "3",
        "image": "/assets/currency/gbp.png",
        "currency": "GPB",
        "value": "British Pound"
    },
    {
        "id": "4",
        "image": "/assets/currency/aoa.png",
        "currency": "AOA",
        "value": "Angolan Kwanza"
    },
    {
        "id": "5",
        "image": "/assets/currency/jpy.png",
        "currency": "JPY",
        "value": "Japanese Yen"
    },
] 