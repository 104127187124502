import React from 'react'
import './FlightSection.css';


function FlightSection() {
  return (
    <div className='top-flight-section'>
        <div className='flight-container'>
            <h2>Popular Flight Routes</h2>
        </div>
    </div>
  )
}

export default FlightSection
