import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Modal, ListGroup, InputGroup } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../Hotel/Css/HotelFilterStyle.css'
import { Link } from 'react-router-dom';
import { FaUser, FaMagnifyingGlass, FaBuilding, } from "react-icons/fa6";
import { FaStar, FaTripadvisor } from "react-icons/fa";
import { useHotelState } from '../../context/hotelContext';


function HotelFilter({ inputs }) {
  const { locality, propertyType, allfacilities, city, filterResults, session_id, minPrice, maxPrice } = useHotelState();
  const facilities = allfacilities && allfacilities.slice(0, 15);
  const [value, setPrice] = useState(maxPrice);
  const [show, setShow] = useState(false);
  const [hotleName, sethotleName] = useState('');
  const [facilitiesFilter, setfacilities] = useState([]);
  const [updatefacilities, setUpdatefacilities] = useState({});

  const [popularFIlter, setpopular] = useState([]);
  const [updatePopular, setUpdatePopular] = useState({});


  const [propertyFIlter, setpropertyFIlter] = useState([]);
  const [updatepPoperty, setUpdateProperty] = useState({});

  const [ratingFIlter, setratingFIlter] = useState([]);
  const [updatepRating, setUpdateRating] = useState({});

  const [tripFIlter, setTripFIlter] = useState([]);
  const [updatepTrip, setUpdateTrip] = useState({});


  const Restefilters = () => {
    setUpdatefacilities(null)
    setpopular(null)
    setfacilities(null)
  }

  const handleFacilitiesFilter = (item) => {
    const updatedSelectedData = [...facilitiesFilter];
    const updatedSelectedCheckbox = { ...updatefacilities };
    if (updatedSelectedCheckbox[item]) {
      // Item was checked, uncheck it
      updatedSelectedData.splice(updatedSelectedData.indexOf(item), 1);
      delete updatedSelectedCheckbox[item];
    } else {
      // Item was unchecked, check it
      updatedSelectedData.push(item);
      updatedSelectedCheckbox[item] = true;
    }
    setfacilities(updatedSelectedData);
    setUpdatefacilities(updatedSelectedCheckbox);
  };
  const handlePopularfilters = (item) => {
    const updatedSelectedData = [...popularFIlter];
    const updatedSelectedCheckbox = { ...updatePopular };
    if (updatedSelectedCheckbox[item]) {
      // Item was checked, uncheck it
      updatedSelectedData.splice(updatedSelectedData.indexOf(item), 1);
      delete updatedSelectedCheckbox[item];
    } else {
      // Item was unchecked, check it
      updatedSelectedData.push(item);
      updatedSelectedCheckbox[item] = true;
    }
    setpopular(updatedSelectedData);
    setUpdatePopular(updatedSelectedCheckbox);
  };
  const handlePropertyfilters = (item) => {
    const updatedSelectedData = [...propertyFIlter];
    const updatedSelectedCheckbox = { ...updatepPoperty };
    if (updatedSelectedCheckbox[item]) {
      // Item was checked, uncheck it
      updatedSelectedData.splice(updatedSelectedData.indexOf(item), 1);
      delete updatedSelectedCheckbox[item];
    } else {
      // Item was unchecked, check it
      updatedSelectedData.push(item);
      updatedSelectedCheckbox[item] = true;
    }
    setpropertyFIlter(updatedSelectedData);
    setUpdateProperty(updatedSelectedCheckbox);
  };
  const handleStarRating = (item)=>{
    const updatedSelectedData = [...ratingFIlter];
    const updatedSelectedCheckbox = { ...updatepRating };
    if (updatedSelectedCheckbox[item]) {
      // Item was checked, uncheck it
      updatedSelectedData.splice(updatedSelectedData.indexOf(item), 1);
      delete updatedSelectedCheckbox[item];
    } else {
      // Item was unchecked, check it
      updatedSelectedData.push(item);
      updatedSelectedCheckbox[item] = true;
    }
    setratingFIlter(updatedSelectedData);
    setUpdateRating(updatedSelectedCheckbox);
  }
  const handleTripAdvisor = (item)=>{
    console.log(item)
    const updatedSelectedData = [...tripFIlter];
    const updatedSelectedCheckbox = { ...updatepTrip };
    if (updatedSelectedCheckbox[item]) {
      // Item was checked, uncheck it
      updatedSelectedData.splice(updatedSelectedData.indexOf(item), 1);
      delete updatedSelectedCheckbox[item];
    } else {
      // Item was unchecked, check it
      updatedSelectedData.push(item);
      updatedSelectedCheckbox[item] = true;
    }
    setTripFIlter(updatedSelectedData);
    setUpdateTrip(updatedSelectedCheckbox);
  }

  

  const hotelNamefilters = (event) => {
    sethotleName(event.target.value)
  }
  const getPrice = (event)=>{
    console.log(event)
    setPrice(event)
  }
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default form submission
      let json = {
        "sessionId": session_id,
        "maxResult": 20,
        "filters": {
          "hotelName": hotleName.trim().length !== 0 ? hotleName : undefined
        }
      }
      console.log(JSON.stringify(json))
      await filterResults(json)
    }
  };


  const hotelSorting = async () => {
    let facility = facilitiesFilter.toString();
    let locality = popularFIlter.toString();
    let propertyType = propertyFIlter.toString();
    let rating = ratingFIlter.toString();
    let tripadvisorRating = tripFIlter.toString();
    if (facility || locality || propertyType || maxPrice || rating || tripadvisorRating) {
      let json = {
        "sessionId": session_id,
        "maxResult": 20,
        "filters": {
          "price": {
            "min": minPrice,
            "max": maxPrice
          },
          "tripadvisorRating":tripadvisorRating.trim().length !== 0 ?tripadvisorRating:undefined,
          "rating": rating.trim().length !== 0 ? rating:undefined,
          "facility": facility.trim().length !== 0 ? facility : undefined,
          "locality": locality.trim().length !== 0 ? locality.toLowerCase() : undefined,
          "propertyType": propertyType.trim().length !== 0 ? propertyType : undefined,
          "hotelName": hotleName.trim().length !== 0 ? hotleName : undefined
        }
      }
      console.log(JSON.stringify(json))
      await filterResults(JSON.stringify(json))
    } else {

    }
  };

 
  useEffect(() => {
    hotelSorting()
  }, [facilitiesFilter, popularFIlter, propertyFIlter, maxPrice, ratingFIlter, tripFIlter]);

  return (
    <>
      <div className="hotel-filter d-none d-xl-block">
        <Button className="session-exp bg-transparent">
          Session Expired
        </Button>

        <div className="filter-card w-100">
          <div className="f-content">
            <Row className="align-items-center">
              <Col xs={9} className="text-start">
                <label>
                  <FaBuilding className="fa-icon" />Home
                </label>
              </Col>
              <Col xs={3} >
                <BootstrapSwitchButton checked={true} onstyle="outline-success" offstyle="outline-danger" />
              </Col>
            </Row>
            <Row className="align-items-center pt-3">
              <Col xs={9} className="text-start">
                <label>
                  <FaUser className="fa-icon bg-warning" />Guest House
                </label>
              </Col>
              <Col xs={3}>
                <BootstrapSwitchButton checked={true} onstyle="outline-primary" offstyle="outline-secondary" />
              </Col>
            </Row>
          </div>
        </div>

        <div className="filter-card w-100">
          <div className="filter-content">
            <h5>Filter</h5>
            <Button className="filter-reset-btn w-100" onClick={Restefilters}>Reset</Button>
            <h6 className="text-start mt-3">Hotel Name</h6>
            <InputGroup className="mb-3">
              <InputGroup.Text id="Search"><FaMagnifyingGlass /></InputGroup.Text>
              <Form.Control placeholder="Type & Press to Search"
                value={hotleName}
                type='text'
                name="hotleName"
                onChange={hotelNamefilters} onKeyDown={handleKeyDown} />
            </InputGroup>

            <div className="popular-filter filter-innerdiv  pt-3 text-start">
              <h6>Popular filters for {inputs.hotelCity.city}</h6>
              {city && city.map((results, index) => (
                <Form key={index}  >
                  <Form.Check className="w-100" inline label={results}
                    type="checkbox"
                    checked={updatePopular[results] || false}
                    onChange={() => handlePopularfilters(results)} />
                </Form>
              ))}
            </div>
            <div className="total-price-filter filter-innerdiv pt-3 text-start">
              <h6>Total Price</h6>
              <RangeSlider className="w-100" min={minPrice} max={maxPrice} value={value} onChange={changeEvent => getPrice(changeEvent.target.value)} />
            </div>

            <div className="total-price-filter filter-innerdiv pt-3 text-start">
              <h6>Star Rating</h6>
              <>
                <ListGroup horizontal='sm' className="my-1">
                  {[1, 2, 3, 4, 5].map((results) => (
                    <ListGroup.Item key={results} onClick={()=> handleStarRating(results)} >
                      {results} <FaStar />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            </div>

            <div className="trip-advisor-filter filter-innerdiv pt-3 text-start">
              <h6>Trip Advisor Rating</h6>
              <>
                  <ListGroup  horizontal='sm' className="my-1">
                  {[{"data":'0-1', "value" : '1,1.5'},
                  {"data":'1-2', "value" : '2,2.5'},
                  {"data":'2-3', "value" : '3,3.5'},
                  {"data":'3-4', "value" : '4,4.5'},
                  {"data":'4-5', "value" : '4.5,5'}].map((results, index) => (
                    <ListGroup.Item key={index}  onClick={()=> handleTripAdvisor(results.value)}>
                      {results.data}<FaTripadvisor /></ListGroup.Item>
                    ))}
                  </ListGroup>
                
              </>
            </div>

            <div className="landmark-price-filter filter-innerdiv pt-3 text-start">
              <h6>Land Marks</h6>
              {locality && locality.map((results, index) => (
                <Form key={index} >
                  <Form.Check className="w-100" inline label={results} name="group1"
                    checked={updatePopular[results] || false}
                    onChange={() => handlePopularfilters(results)} />
                </Form>
              ))}
              <div className="w-100 text-end">
                <Link className="filter-all-link  text-dark" variant="primary" onClick={() => setShow(true)}>View All</Link>
              </div>
            </div>

            <div className="landmark-price-filter filter-innerdiv pt-3 text-start">
              <h6>Facilities</h6>
              {facilities && facilities.map((results, index) => (
                <Form key={index}  >
                  <Form.Check className="w-100" inline label={results} type="checkbox"
                    checked={updatefacilities[results] || false} onChange={() => handleFacilitiesFilter(results)} />
                </Form>
              ))}
              <div className="w-100 text-end">
                <Link className="filter-all-link  text-dark" variant="primary" onClick={() => setShow(true)}>View All</Link>
              </div>
            </div>


            <div className="landmark-price-filter filter-innerdiv pt-3 text-start">
              <h6>Property Type</h6>
              {propertyType && propertyType.map((results, index) => (
                <Form key={index}  >
                  <Form.Check className="w-100" inline label={results} type="checkbox"
                    checked={updatepPoperty[results] || false}
                    onChange={() => handlePropertyfilters(results)} />
                </Form>
              ))}
            </div>


          </div>
        </div>

      </div>
      <Modal size="lg" show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Land Marks
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            {/* {['checkbox'].map((type) => (
           <Row>
          <Col sm={12}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" placeholder="Filter by Location" />
            </Form.Group>
            </Col>
            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Academic City"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
            </Col>
            
            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Airport"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>

            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Ajman"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>

            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Badaa"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>

            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Barsha"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>


            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Habtoor City"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>


            <Col md={4}>

            <Form.Check className="w-100"
            inline
            label="Al Hudaba"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
            </Col>


            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Karama"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>

            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Karama"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
            </Col>

            <Col md={4}>
            <Form.Check className="w-100"
            inline
            label="Al Mamzer"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          </Col>
        </Row>

    ))} */}
          </Form>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default HotelFilter
