import React, { useState, useRef } from 'react';
import LeftFilters from './LeftFilters';
import FlightResults from './FlightResults';
import {useLocation} from 'react-router-dom';
import FlightSearchEngine from './FlightSearchEngine';
import '../../App.css';
import './FlightResults.css';
import   '../Header/Header';

function Results() {  
  const location = useLocation();
  // const { inputs} = location.state

  return (
    <>
      <div className="search-result-content">
      <FlightSearchEngine searchinputs={location.state} />
      <div className='container'>
      <div className="row">
          <div className='col-lg-3'>
            <LeftFilters searchData={location.state} />
          </div>
          <div className='col-lg-9'>
          <div className='destination-head'>
          <h3>Choose your flight to {location.state.departure} to {location.state.arrival} </h3>
          <p>Prices are shown in US dollars and are per person inclusive of taxes and fees with the exception of baggage fees.</p>
          </div>
            <FlightResults searchData={location.state}/>
          </div>
      </div>
      </div>
    </div>
    </>
  )
}

export default Results;
