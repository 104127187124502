import React from 'react'
import { useState, useEffect } from 'react';
import {Container, Row, Col, Nav, Tab, Button, Card, Form, InputGroup} from 'react-bootstrap';
import { FaUser, FaChild, FaMap, FaCheck, FaFile, FaDeleteLeft, FaHandPointRight, FaArrowLeftLong, FaStar } from "react-icons/fa6";
import './MyAccount.css';

function ManageBookings() {
    return (
        <div>
          <div className='page-content page-bg pt-0'>
        <div className='my-account'>
        <Container>
                <div className='manage-booking'>
                    <div className='title-block'>
                        <h2 className='title'>Manage Bookings</h2>
                    </div>
                    <div className='form-block'>
                        <h4 className="form-title">Lost Your Confirmation Email?</h4>
                        <Form>
                            <Row>
                                <Col md={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>BOOKING ID</Form.Label>
                                    <Form.Control type="text" placeholder="Booking ID" />
                                </Form.Group>
                                </Col>
                                <Col md={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" />
                                </Form.Group>
                                </Col>
                            </Row>
                            <Button className='btn btn-primary btn-form-submit'>Send Voucher</Button>
                        </Form>
                    </div>
                </div>
        </Container>
    
        
          </div>
    
    
          </div>
        </div>
      )
}

export default ManageBookings
