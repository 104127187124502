import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './context/flightcontext';
import { FilterContextProvider } from './context/filterContext';
import { HotleProvider } from './context/hotelContext';
import { CarFilterContextProvider } from './context/Car/carFilterContext';
import { CarProvider } from './context/Car/carContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <FilterContextProvider>
        <HotleProvider>
          <CarProvider>
            <CarFilterContextProvider>
              <App />
            </CarFilterContextProvider>
          </CarProvider>
        </HotleProvider>
      </FilterContextProvider>
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
