const carFilterReducer = (state, action) => {
    let { all_cars, filter_cars, selectedFilters, stopFilters, } = state;

   
    switch (action.type) {
      
      case "LOAD_FILTER_CARS":
        
        const carLoad = action.payload ? action.payload : '';
        //console.log("action loads", carLoad? carLoad.data?.map((carsDet, index) => carsDet.fees.rateTotalAmount) : 0);
      //   if(carLoad != null || carLoad !== ''){

        let maxValue = Math.max.apply(null, 
        carLoad.data?.map(function (o) { return o.fees.rateTotalAmount; }));

        let priceArr = carLoad.data?.map((carsDet, index) => carsDet.fees.rateTotalAmount);

        let filterCarData = carLoad;
        //let priceArr = maxValue
        console.log("priceArr",priceArr);
        let maxPrice;
       
        // if(Math.max(...maxValue)===-Infinity){
        //   maxPrice = null
        // }else{
        //   maxPrice = Math.max(...maxValue);
        // }

        if(Math.max(...[maxValue])===-Infinity){
          maxPrice = null
        }else{
          maxPrice = Math.max(...[maxValue]);
        }

        const all_result =JSON.parse(JSON.stringify(filterCarData))

        
        return {
          ...state,
          filter_cars: [all_result?.data],
          all_cars: [...[action?.payload]],
          filters: { ...state.filters, maxPrice:maxPrice, price: maxPrice },
        };


      

        case "UPDATE_CAR_FILTERS_VALUE":  
        console.log("selected filters",action.payload)  
          return {
            ...state,
            selectedFilters: action.payload,   
          };
               
        case "UPDATE_CAR_FILTERS":
          const { name, value } = action.payload;
          console.log("name and vale daya",action.payload)
          let tempFilterCar = [...filter_cars];
        
          if (value === 0) {
            tempFilterCar = tempFilterCar[0]?.filter((carsDet) => carsDet.fees.rateTotalAmount === value);
          } else {
            tempFilterCar =  tempFilterCar[0]?.filter((carsDet) => carsDet.fees.rateTotalAmount <= value);
          }
          
          console.log("filter new", tempFilterCar)
  
          return {
            ...state,
            filters: {
              ...state.filters,
              [name]: value,
            },
            filter_cars: [tempFilterCar],
          };

        
        case "UPDATE_CAR_TRANSMISSION_FILTERS":

          const { tname, tvalue, tchecked } = action.payload;
          let tempTansFilterCar = [...filter_cars];
          console.log("transmission",filter_cars)
            
            if (tvalue !== '') {
              tempTansFilterCar = tempTansFilterCar[0]?.filter((carsDet) => carsDet.carDetails.transmissionType === tvalue);
            }
    
            return {
              ...state,
              filters: {
                ...state.filters,
                [tname]: tvalue,
              },
              filter_cars: [tempTansFilterCar],
              
            };

          
        
         
            case "UPDATE_CAR_PICKUP_FILTERS":

            const { cpfname, cpfvalue, cpfchecked } = action.payload;
            let tempCarPickupFilterCar = [...filter_cars];
            console.log("pickup",filter_cars)
              
              if (cpfvalue !== '') {
                tempCarPickupFilterCar = tempCarPickupFilterCar[0]?.filter((carsDet) => carsDet.pickup.locationName === cpfvalue);
              }
      
              return {
                ...state,
                filters: {
                  ...state.filters,
                  [cpfname]: cpfvalue,
                },
                filter_cars: [tempCarPickupFilterCar],
                
              };



              case "UPDATE_CAR_DROPOFF_FILTERS":

              const { cdfname, cdfvalue, cdfchecked } = action.payload;
              let tempCarDropoffFilterCar = [...filter_cars];
              console.log("dropoff",filter_cars)
                
                if (cdfvalue !== '') {
                  tempCarDropoffFilterCar = tempCarDropoffFilterCar[0]?.filter((carsDet) => carsDet.dropoff.locationName === cdfvalue);
                }
        
                return {
                  ...state,
                  filters: {
                    ...state.filters,
                    [cdfname]: cdfvalue,
                  },
                  filter_cars: [tempCarDropoffFilterCar],
                  
                };


                case "UPDATE_CAR_RATE_FILTERS":

                const { crname, crvalue, crchecked } = action.payload;
                let  tempCarRateFilterCar = [...filter_cars];
                console.log("car rate",filter_cars)
                  
                  if (crvalue !== '') {
                    tempCarRateFilterCar =  tempCarRateFilterCar[0]?.filter((carsDet) => carsDet.carDetails.transmissionType === crvalue);
                  }
          
                  return {
                    ...state,
                    filters: {
                      ...state.filters,
                      [crname]: crvalue,
                    },
                    filter_cars: [tempCarRateFilterCar],
                    
                  };
  



          
        case "UPDATE_CAR_FUEL_TYPE_FILTERS":
            const { ftname, ftvalue, ftchecked } = action.payload;
            let tempFuelFilterCar = [...filter_cars];
            console.log("fuel type",action.payload)
              
              if (ftvalue !== '') {
                tempFuelFilterCar = tempFuelFilterCar[0]?.filter((carsDet) => carsDet.carDetails.fuelType === ftvalue);
              }
      
              return {
                ...state,
                filters: {
                  ...state.filters,
                  [ftname]: ftvalue,
                },
                filter_cars: [tempFuelFilterCar],
                
              };


            case "UPDATE_CAR_SPEC_FILTERS":
                const { csname, csvalue, cschecked } = action.payload;
                let tempSepcFilterCar = [...filter_cars];
                console.log("fuel type",action.payload)
                  
                  if (cschecked === true) {
                    tempSepcFilterCar = tempSepcFilterCar[0]?.filter((carsDet) => carsDet.carDetails.ac == 'true');
                  }else if(cschecked === false){
                    tempSepcFilterCar = tempSepcFilterCar[0]?.filter((carsDet) => carsDet.carDetails.ac == 'false');
                  }
          
                  return {
                    ...state,
                    filters: {
                      ...state.filters,
                      [csname]: csvalue,
                    },
                     filter_cars: [tempSepcFilterCar],
                    
                  };


                  case "UPDATE_CAR_SUPPLIER_FILTERS":
                    const { csfname, csfvalue, csfchecked } = action.payload;
                    let tempSuplFilterCar = [...filter_cars];
                    console.log("car supplier",action.payload)
                      
                    if (csfvalue !== '') {
                      tempSuplFilterCar = tempSuplFilterCar[0]?.filter((carsDet) => carsDet.vendor.name === csfvalue);
                    }
              
                      return {
                        ...state,
                        filters: {
                          ...state.filters,
                          [csfname]: csfvalue,
                        },
                         filter_cars: [tempSuplFilterCar],
                        
                      };

                  

                   case "UPDATE_CAR_CUSTOM_RATING_FILTERS":
                        const { crfname, crfvalue, crfchecked } = action.payload;
                        let tempCustomRatingFilterCar = [...filter_cars];
                          
                        if(crfchecked){
                          if (crfvalue !== '') {
                            tempCustomRatingFilterCar =  tempCustomRatingFilterCar[0]?.filter((carsDet) => carsDet.vendor.reviewsOverall == crfvalue);
                          }
                        }else{
                          tempCustomRatingFilterCar =  tempCustomRatingFilterCar[0]                        

                        }
                        
                        console.log("car custom rating", tempCustomRatingFilterCar)

                          return {
                            ...state,
                            filters: {
                              ...state.filters,
                              [crfname]: crfvalue,
                            },
                             filter_cars: [tempCustomRatingFilterCar],
                            
                          };





              case "UPDATE_CAR_PASSENGER_FILTERS":
                    const { cpname, cpvalue, cpchecked } = action.payload;
                    let tempPassFilterCar = [...filter_cars];
                    console.log("passenger type",action.payload)
                      
                      if (cpvalue == 4) {
                        tempPassFilterCar = tempPassFilterCar[0]?.filter((carsDet) => carsDet.carDetails.passengerQuantity >= 4);
                      }else if(cpvalue == 5){
                        tempPassFilterCar = tempPassFilterCar[0]?.filter((carsDet) => carsDet.carDetails.passengerQuantity == 5);
                      }else if(cpvalue == 6){
                        tempPassFilterCar = tempPassFilterCar[0]?.filter((carsDet) => carsDet.carDetails.passengerQuantity <= 6);
                      }
              
                      return {
                        ...state,
                        filters: {
                          ...state.filters,
                          [cpname]: cpvalue,
                        },
                         filter_cars: [tempPassFilterCar],
                        
                      };

              

              case "UPDATE_CAR_BAGGAGE_FILTERS":
                        const { cbfname, cbfvalue, cbfchecked } = action.payload;
                        let tempCarBaggageFilterCar = [...filter_cars];
                        console.log("passenger type",action.payload)
                          
                          if (cbfvalue == 3) {
                            tempCarBaggageFilterCar = tempCarBaggageFilterCar[0]?.filter((carsDet) => carsDet.carDetails.baggageQuantity >= 3);
                          }else if(cbfvalue == 4){
                            tempCarBaggageFilterCar = tempCarBaggageFilterCar[0]?.filter((carsDet) => carsDet.carDetails.baggageQuantity == 4);
                          }else if(cbfvalue == 5){
                            tempCarBaggageFilterCar = tempCarBaggageFilterCar[0]?.filter((carsDet) => carsDet.carDetails.baggageQuantity <= 5);
                          }
                  
                          return {
                            ...state,
                            filters: {
                              ...state.filters,
                              [cbfname]: cbfvalue,
                            },
                             filter_cars: [tempCarBaggageFilterCar],
                            
                          };


        case "UPDATE_CAR_FILTERS_STOP":
         
            return {
              ...state,
              stopFilters: action.payload,
            }; 
       
          return {
            ...state,
            grid_view: false,
          };
  
          case "CLEAR_FILTERS":
            return {
              ...state,
              filters: {
                ...state.filters,
                price: state.filters.maxPrice,
                minPrice: state.filters.maxPrice,
                timeRange:false,
                timeRangeArrive:false,
                
              },
              
      };
      default:
        return state;
    }
  };
  
  export default carFilterReducer;