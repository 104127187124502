
import React,{ useState, useEffect } from 'react';
import {Container, Row, Col, Nav, Tab, Button, Card, Form, InputGroup} from 'react-bootstrap';
import { FaUser, FaChild, FaMap, FaCheck, FaFile, FaDeleteLeft, FaHandPointRight, FaArrowLeftLong, FaStar } from "react-icons/fa6";
import './MyAccount.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
function Bookings() {
    const navigate = useNavigate();
    const [user, setuser] = useState();
    const [booking, setBooking] = useState();
    const [bookingdata, setBookingdata] = useState(true);

    const getbooking = ()=>{
        let url = 'https://groupy.live/reactApp/flights/getallflightbooking'
        let json = {
            "user_id" : user&&user.id,
            // "user_id" : "46",
            "status" : "pending"
            // "status" : "CONFIRMED"
            
        }

        console.log(JSON.stringify(json))
        axios.post(url,JSON.stringify(json))
        .then((response) => {
            
            if(response.data){
                setBooking(response.data)
            }else{
                setBookingdata(false)
            }
            console.log("respo",JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
    } 

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('user'));
        console.log("user data",items)
        if (items.user_details) {
            setuser(items.user_details);      
        }  
        },[]);
        

        const gotoDetail = (booing)=>{
            navigate("/myhotelVoucher", {
              state: {
               bookingid: booing
              }
            })
          }

    return (
        <div>
          <div className='page-content page-bg pt-0'>
        <div className='my-account'>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="bk-confirmed">
                <Row>
                <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                    <Nav.Link eventKey="bk-confirmed" onClick={()=> getbooking()}><FaFile/>Confirmed Bookings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="bk-pending"    ><FaFile/>Pending Bookings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="bk-cancelled"><FaDeleteLeft/>Cancelled Booking</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col sm={9}>
                <Tab.Content>
                    <Tab.Pane eventKey="bk-confirmed">
                        {!booking?<div className='booking-empty'>
                                    <Row>
                                        <Col sm={2}>
                                            <div className='empty-icon'>
                                            {/* <img src={require('../assets/suit.png')} className='icon'></img> */}
                                            </div>
                                        </Col>
                                        <Col sm={10}>
                                            <div className="empty-content">
                                                <h2>Looking Empty You dont have Completed Bookings</h2>
                                                <Button className='btn btn-primary'>Plan a Trip</Button>
                                            </div>
                                        </Col>
                                    </Row>
                            </div>:''}
                            

                            

                    <table style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th style={tableHead}>Name</th>
                                    <th style={tableHead}>Module</th>
                                    <th style={tableHead}>Booking Id</th>
                                    <th style={tableHead}>Booking Date</th>
                                    <th style={tableHead}>Travel Date</th>
                                    <th style={tableHead}>Total Fare</th>
                                    <th style={tableHead}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {booking&&booking.map((book)=>(
                            <tr key={book.id} onClick={()=> gotoDetail(book)}  >
                                    <td style={tableData}>{book.cust_name} </td>
                                    <td style={tableData}>{book.module}</td>
                                    <td style={tableData}>{book.booking_id} </td>
                                    <td style={tableData}>{book.created_on} </td>
                                    <td style={tableData}>{book.travel_date} </td>
                                    <td style={tableData}>{book.net_price} </td>
                                    <td style={tableData}>{book.status} </td>
                                </tr>
                            ))}
                                
                            </tbody>
                        </table>
                            
                    </Tab.Pane>
                    <Tab.Pane eventKey="bk-pending">
                    <div className='booking-empty'>
                                    <Row>
                                        <Col sm={2}>
                                            <div className='empty-icon'>
                                            {/* <img src={require('../assets/suit.png')} className='icon'></img> */}
                                            </div>
                                        </Col>
                                        <Col sm={10}>
                                            <div className="empty-content">
                                                <h2>Looking Empty You dont have Upcoming Bookings</h2>
                                                <Button className='btn btn-primary'>Plan a Trip</Button>
                                            </div>
                                        </Col>
                                    </Row>
                            </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bk-cancelled">
                    <div className='booking-empty'>
                                    <Row>
                                        <Col sm={2}>
                                            <div className='empty-icon'>
                                            {/* <img src='../assets/cancel-ticket.png' className='icon'></img> */}
                                            </div>
                                        </Col>
                                        <Col sm={10}>
                                            <div className="empty-content">
                                                <h2>Looking Empty You dont have Cancelled Bookings</h2>
                                                <Button className='btn btn-primary'>Plan a Trip</Button>
                                            </div>
                                        </Col>
                                    </Row>
                            </div>
                    </Tab.Pane>
                </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
            </Container>
    
        
          </div>
    
    
          </div>
        </div>
      )
}

const tableHead = {
    backgroundColor:"#060e9f",
    padding:"5px",
    color:"#fff",
    fontSize:"12px",
    fontWeight:"normal",
    border:"1px solid #fff",
    borderCollapse:"collapse"
  }
  const tableData = {
    fontSize:"12px",
    padding:"5px",
    color:"#333",
    border:"1px solid #ccc",
    borderCollapse:"collapse"
  }

export default Bookings
