const HotelReducer = (state, action) => {
  switch (action.type) {
    case "SET_HOTEL_DEST_LOADING":
      return {
        ...state,
        destLoading: true,
      };
    case "SET_HOTEL_DEST":
      return {
        ...state,
        destLoading: false,
        hotelDest: action.payload,
      };
    case "DEST_ERROR":
      return {
        ...state,
        destLoading: false,
        destError: true,
      };

    case "SET_RESULTS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SET_HOTEL_RESULTS":
      return {
        ...state,
        loading: false,
        hotelResults: action.payload,
      };
    case "SET_SESSION_DATA":
      return {
        ...state,
        session_id: action.payload,
      };
    case "SET_FILTER_DATA":
      return {
        ...state,
        city: action.city,
        allfacilities: action.allfacilities,
        propertyType: action.propertyType,
        locality: action.locality,
        maxPrice: action.maxPrice,
        minPrice: action.minPrice
      }
    case "SET_HOTEL_Filter_RESULTS":
      return {
        ...state,
        hotelResults: action.payload,
      };
    case "SET_HOTEL_DETAILS":
      return {
        ...state,
        hotelDetail: action.payload,
      };
    case "SET_ROOMS_RATE":
      return {
        ...state,
        roomLoading: false,
        roomsRate: action.payload,
      };
    case "SET_NEARBY_PLACES":
      return {
        ...state,
        nearLoading: false,
        nearByPlaces: action.payload,
      };
    case "SET_ROOMS_RULES":
      return {
        ...state,
        rulesLoading: false,
        roomsRules: action.payload,
      };
    case "HOTEL_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
        hotelResults: ''
      };
    case "SET_BOOKING_LOADING":
      return {
        ...state,
        bookLoading: true,
        hotelBook: action.payload,
      };
    case "SET_HOTEL_BOOK":
      return {
        ...state,
        bookLoading: false,
        hotelBook: action.payload,
      };
    case "SET_BOOK_ERROR":
      return {
        ...state,
        bookLoading: false,
        hotelBook: '',
      };
      case "SET_SAVE_LOADING":
        return {
          ...state,
          saveLoading: true,
          hotelBooking: action.payload,
        };
      case "SET_HOTEL_SAVE":
        return {
          ...state,
        saveLoading: false,
        hotelBooking: action.payload,
        };
      case "SET_SAVE_ERROR":
        return {
          ...state,
          saveLoading: false,
          hotelBooking: '',
        };
    default:
      return state;
  }


};

export default HotelReducer;