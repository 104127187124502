const CarReducer = (state, action) => {
    switch (action.type) {
      case "SET_CAR_DEST_LOADING":
        return {
          ...state,
          isLoading: true,
        };
  
      case "SET_CAR_DEST":
        return {
          ...state,
          isLoading: false,
          carDest: action.payload,
        };
     
      case "DEST_ERROR":
        
        return {
          ...state,
          isLoading: false,
          isError: true,
        };

        case "SET_RESULTS_LOADING":
          return {
            ...state,
            isLoading: true,
          };
        case "SET_CAR_RESULTS":
          return {
            ...state,
            isLoading: false,
            carResults: action.payload,
          };
        case "CAR_ERROR":
          return {
            ...state,
            isLoading: false,
            isError: true,
          };

          case "SET_DETAILS_RESULTS_LOADING":
            return {
              ...state,
              isLoading: true,
            };
          case "SET_CAR_DETAILS_RESULTS":
            return {
              ...state,
              isLoading: false,
              carDetailResults: action.payload,
            };
          case "CAR_DETAILS_ERROR":
            return {
              ...state,
              isLoading: false,
              isError: true,
            };


            case "SET_CAR_BOOKING_CONFIRM_LOADING":
              return {
                ...state,
                isLoading: true,
              };
            case "SET_CAR_BOOKING_DETAILS_RESULTS":
              return {
                ...state,
                isLoading: false,
                carBookingResults: action.payload,
              };
            case "CAR_BOOKING_DETAILS_ERROR":
              return {
                ...state,
                isLoading: false,
                isError: true,
              };
      
    
      default:
        return state;
    }

    
  };
  
  export default CarReducer;