import { Row, Col, InputGroup, Form, Button, Overlay, Popover, } from 'react-bootstrap';
import { FaCalendarDay, FaUser, FaLocationDot } from "react-icons/fa6";
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import AddRoom from './AddRoom';
import '../Hotel/Css/HotelSearchEngine.css'
import { useNavigate } from 'react-router-dom';
// import { useHotelContext } from '../../context/hotelContext';

function HotelSearchEngine({ inputs }) {
    const navigate = useNavigate();    
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [destination, setdestination] = useState(inputs ? inputs.hotelCity.city + ' ' + inputs.hotelCity.country : '');
    const [checkinDate, setcheckinDate] = useState(inputs ? inputs.checkinDate : '');
    const [checkoutDate, setcheckoutDate] = useState(inputs ? inputs.checkoutDate : '');
    const [results, setResults] = useState([]);
    const [hotelCity, sethotelCity] = useState(inputs ? inputs.hotelCity : '');
    const [showDropdown, setShowDropdown] = useState(false);
    
    const [rooms, setRooms] = useState([{ room_no: 1, adult: 1, child: 1, child_age: [2], roomSize: 1 }]);
    const [guestCount, setGuestCount] = useState(2);
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [dataFromChild, setDataFromChild] = useState(null);


    const openPopover = (event) => {
        setPopoverOpen(!isPopoverOpen);
        setDataFromChild(event.target);
        setcheckinDate('');
        setcheckoutDate('');
    };

    const closePopover = (data) => {
        console.log(data)
        setPopoverOpen(false);
    };


    function PopoverComponent({ onClose }) {
        const onChange = (dates) => {
            const [start, end] = dates;
            setcheckinDate(start);
            setcheckoutDate(end);
            if (end) {
                onClose(true);
            }
        };
        return (
            <div>
                <DatePicker
                    selected={checkinDate}
                    onChange={onChange}
                    minDate={new Date()}
                    startDate={checkinDate}
                    endDate={checkoutDate}
                    selectsRange
                    selectsDisabledDaysInRange
                    inline />
            </div>
        );
    }

    function dateFormatter(date) {
        if (new Date(date) === 'Invalid Date' || date === '') {
            return 'Add date'
        } else {
            let dateString = new Date(date)
            let localTime = dateString.getTime();
            let localOffset = dateString.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let offset = 5.5;
            let bombay = utc + (3600000 * offset);
            let nd = new Date(bombay);
            return (("0" + (nd.getDate())).slice(-2) + '/' + ("0" + (nd.getMonth() + 1)).slice(-2) + "/" + nd.getFullYear());
        }
    }


    const handleInputChange = async (e) => {
        const searchTerm = e.target.value;
        setdestination(searchTerm);
        if (searchTerm.trim() === '') {
            setResults([]);
            setShowDropdown(false);
            return;
        }
        try {
            const response = await axios.get(`https://travelnext.works/api/hotel_trawexv6/hotel_dest?dest=${searchTerm}`);
            setResults(response.data);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const selectDestination = (item) => {
        setdestination(item.city + ' ' + item.country);
        sethotelCity(item);
        setShowDropdown(false);
    };

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    }

    const handleDone = () => {
        setShow(false)
        const totalAdults = rooms.reduce((acc, room) => acc + room.adult, 0);
        const totalChildren = rooms.reduce((acc, room) => acc + room.child, 0);
        setGuestCount(totalAdults + totalChildren)
        //  console.log(rooms,totalAdults+totalChildren)
        console.log(console.log(JSON.stringify(rooms)))
    };
    const addGuest = () => { };

    const searchHotels = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson, form.checkValidity());
            navigate("/hotelResult", {
                state: {
                    inputs: formJson,
                    hotelCity: hotelCity,
                    rooms: rooms,
                    destination: destination,
                    checkinDate: checkinDate,
                    checkoutDate: checkoutDate
                }
            })

        }
    };
   
    return (
        <>
            <div className='modify-section'>
                <Form noValidate validated={validated} onSubmit={searchHotels}  >
                    <Row>
                        <Col md={4} lg={4}  >
                            <Form.Group controlId="validationCustomLocation" className="search-dropdown mb-3" >
                                {/* <Form.Label>Location</Form.Label> */}
                                <InputGroup hasValidation>
                                    <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                                    <Form.Control ref={ref}
                                        type="text" placeholder="Enter City Name" aria-describedby="inputGroupPrepend" required
                                        value={destination}
                                        name="destination"
                                        onChange={handleInputChange}
                                        onFocus={() => { setdestination('') }} />
                                    <Form.Control.Feedback type="invalid">Please choose a city name.</Form.Control.Feedback>
                                </InputGroup>
                                {showDropdown && (<ul className="dropdown-list">
                                    {results.map((item, index) => (
                                        <li key={index} onClick={() => selectDestination(item)}>
                                            <FaLocationDot /> {item.city} {item.country}
                                        </li>
                                    ))}
                                </ul>)}
                            </Form.Group>
                        </Col>
                        <Col md={8} lg={8}>
                            <Row>
                                <Col md={10} lg={10} >
                                    <Row >
                                        <Col md={4} lg={4} >
                                            <Form.Group onClick={openPopover} className="mb-3" controlId="validationCustomcheckinDate">
                                                {/* <Form.Label>Check-in Date</Form.Label> */}
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                                    <Form.Control type="text" value={dateFormatter(checkinDate)} onChange={openPopover} name="checkinDate" placeholder="Check-in" aria-describedby="inputGroupPrepend" required />
                                                    <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} lg={4} >
                                            <Form.Group onClick={openPopover}  className="mb-3" controlId="validationCustomcheckoutDate">
                                                {/* <Form.Label>Check-out Date</Form.Label> */}
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                                    <Form.Control type="text" value={dateFormatter(checkoutDate)} onChange={openPopover} name="checkoutDate" placeholder="Check-out" aria-describedby="inputGroupPrepend" required />
                                                    <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                                                <Popover id="popover-contained">
                                                    <Popover.Body>
                                                        {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                                                    </Popover.Body>
                                                </Popover>
                                            </Overlay>
                                        </Col>
                                        <Col md={4} lg={4} >
                                            <Form.Group className="mb-3" controlId="validationCustomGuests">
                                                {/* <Form.Label>Guests</Form.Label> */}
                                                <InputGroup hasValidation onClick={handleClick} >
                                                    <InputGroup.Text><FaUser /></InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="2 Guests"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        value={guestCount + ' Guest'}
                                                        name="guest"
                                                        onChange={addGuest}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose the number of guests
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Overlay show={show} target={target} placement="bottom" >
                                                <Popover id="popover-contained">
                                                    <Popover.Body>
                                                        <AddRoom rooms={rooms} setRooms={setRooms} handleDone={handleDone} />
                                                    </Popover.Body>
                                                </Popover>
                                            </Overlay>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={2} lg={2} className='align-items-center' >
                                    <Button type="submit" className='btn-submit'>Search</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}


export default HotelSearchEngine
