import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";

import { Container, Row, Col, InputGroup, Form, Button, Overlay, Popover, Alert, Card, } from 'react-bootstrap';
import { FaCalendarDay, FaUser, FaLocationDot, FaPlane, FaHotel, FaCar, FaClock, FaPlaneDeparture, FaPlaneArrival, TiDelete } from "react-icons/fa6";
import styles from './CarResults.module.css';
import countryData from '../../json/country.json';
import dateFormat, { masks } from "dateformat";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { json, useNavigate } from 'react-router-dom';


function ModifySearch({modifyData, inputs }) {

  const [ApiDestination, setApiDestination] = useState();
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
 // const [destination, setdestination] = useState('');
  const [destinationpickup, setdestinationpickup] = useState(inputs ? inputs.destinationpickup : modifyData.destinationpickup);
  const [destinationdropoff, setdestinationdropoff] = useState(inputs ? inputs.destinationdropoff : modifyData.destinationdropoff);
  const [destinationpickupValue, setdestinationpickupValue] = useState(inputs ? inputs.destinationpickupValue : modifyData.pickuplocationvalue);
  const [destinationdropoffValue, setdestinationdropoffValue] = useState(inputs ? inputs.destinationdropoffValue : modifyData.dropofflocationvalue);
  const [checkinDate, setcheckinDate] = useState(inputs ? inputs.checkinDate : '');
  const [pickupTime, setPickupTime] = useState(inputs ? inputs.pickupTime : '' );
  const [checkoutDate, setcheckoutDate] = useState(inputs ? inputs.checkoutDate :'');
  const [dropoffTime, setDropoffTime] = useState(inputs ? inputs.dropoffTime : '');
  const [resultsPickup, setResultsPicup] = useState([]);
  const [resultsDropoff, setResultsDropoff] = useState([]);
  const [driverAge, setDriverAge] = useState([]);
  const [country, setCountry] = useState([]);

  const [showDropdownPickup, setShowDropdownPickup] = useState(false);
  const [showDropdownDropoff, setShowDropdownDropoff] = useState(false);
  const navigate = useNavigate();

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(null);
  const [isChecked, setIsChecked] = useState(true)
  
  
    // Function to open the popover
    const openPopover = (event) => {
        setPopoverOpen(!isPopoverOpen);
        setDataFromChild(event.target);
        setcheckinDate('');
        setcheckoutDate('');
    };


    const checkHandler = () => {
        setIsChecked(!isChecked)
        setdestinationdropoff(!isChecked ? destinationpickup : '')
        setdestinationdropoffValue(!isChecked ? destinationpickupValue : '')

    }



    const handleInputChangePickup = async (e) => {
        const searchTerm = e.target.value;
        setdestinationpickup(searchTerm);
        if (searchTerm.trim() === '') {
            setResultsPicup([]);
            setShowDropdownPickup(false);
            return;
        }
        try {
            let json = {
                "destination": searchTerm
            }
            const response = await axios.post("http://localhost:3008/destinations", json);
            setResultsPicup(response.data);
            setShowDropdownPickup(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };





    // Function to close the popover and receive data from the child
    const closePopover = (data) => {
        console.log(data)
        setPopoverOpen(false);
        //setDataFromChild(data);
    };


    function PopoverComponent({ onClose }) {
        const onChange = (dates) => {
            // console.log("Dates selected",dates)
            const [start, end] = dates;
            setcheckinDate(start);
            setcheckoutDate(end);
            if (end) {
                onClose(true);
            }
        };
        return (
            <div>
                <DatePicker
                    selected={checkinDate}
                    onChange={onChange}
                    minDate={new Date()}
                    startDate={checkinDate}
                    endDate={checkoutDate}
                    // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                    selectsRange
                    selectsDisabledDaysInRange
                    inline />
            </div>
        );
    }

    function dateFormatter(date) {
        if (new Date(date) == 'Invalid Date') {
            return date
        } else {
            let dateString = new Date(date)
            let localTime = dateString.getTime();
            let localOffset = dateString.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let offset = 5.5;
            let bombay = utc + (3600000 * offset);
            let nd = new Date(bombay);
            return (("0" + (nd.getDate())).slice(-2) + '-' + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + nd.getFullYear());
        }
    }




    const handleInputChangeDropOff = async (e) => {
        const searchTerm = e.target.value;
        setdestinationdropoff(searchTerm);
        if (searchTerm.trim() === '') {
            setResultsDropoff([]);
            setShowDropdownDropoff(false);
            return;
        }
        try {
            const filteredItems = ApiDestination.filter((car_dest) =>
                car_dest.location_name.toLowerCase().includes(searchTerm.toLowerCase())
            );


            setResultsDropoff(filteredItems);
            setShowDropdownDropoff(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const selectDestinationPickup = (item) => {
        setdestinationpickup(item.location_name);
        setdestinationpickupValue(item.id);
        setdestinationdropoff(isChecked ? item.location_name : '')
        setdestinationdropoffValue(isChecked ? item.id : '')
        setShowDropdownPickup(false);
    };

    const selectDestinationDropoff = (item) => {
        setdestinationdropoff(item.location_name);
        setdestinationdropoffValue(item.id)
        setShowDropdownDropoff(false);
    };



const searchCars = async (event) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        console.log("form validation modify", formJson);
        
        navigate("/CarList", {
            state: {
                inputs: formJson,
                pickup_id: destinationpickupValue,
                dropoff_id: destinationdropoffValue,
                pickup_date:dateFormat(checkinDate, "yyyy-mm-dd"),
                pickup_time: pickupTime,
                dropoff_date: dateFormat(checkoutDate, "yyyy-mm-dd"),
                dropoff_time: dropoffTime,
                currency: "USD",
                driver_age: driverAge,
                country_res: country
                        
            }
        })
        window.location.reload();
    }
};



  return (
    <div className={styles.modifySection}>
        
        <div className="container">
            <Form noValidate validated={validated} onSubmit={searchCars}  >
            
                <div className="row">
                    <div className="col-md-6 col-lg-3 md-pr-1 lg-pr-1 xl-pr-1 mb-3">
                    <label>Pick up Location</label>
                        <Form.Group controlId="validationCustomLocation"  className="search-dropdown" > 
                                    
                                    <InputGroup hasValidation>
                                        <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                                        <Form.Control ref={ref} name="destinationpickup"
                                            onFocus={() => { setdestinationpickup('') }}
                                            value={destinationpickup}
                                            // defaultValue={destination}
                                            onChange={handleInputChangePickup}
                                            type="text" placeholder="City or Airport" aria-describedby="inputGroupPrepend" required  autoComplete='off' readonly/>
                                        <Form.Control.Feedback type="invalid">Please choose a city or airport name.</Form.Control.Feedback>
                                    

                                    </InputGroup>

                                    {showDropdownPickup && (<ul className="dropdown-list">
                                        {resultsPickup.map((item, index) => (
                                            <li key={index} onClick={() => selectDestinationPickup(item)}>
                                                <FaLocationDot /> {item.location_name}<br/>
                                                                {item.city} 
                                            </li>
                                        ))}
                                    </ul>)}
                                </Form.Group>
                                <input type="hidden" name="pickuplocationvalue" value={destinationpickupValue} />

                    </div>
                    <div className="col-md-6 col-lg-3 md-pl-1 lg-pl-1 xl-pl-1 lg-pr-1 xl-pr-1 mb-3">
                    <label>Drop off Location</label>
                    <Form.Group controlId="validationCustomLocation"  className="search-dropdown" > 
                                    <InputGroup hasValidation>
                                        <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                                        <Form.Control ref={ref} name="destinationdropoff"
                                            onFocus={() => { setdestinationdropoff('') }}
                                            value={destinationdropoff}
                                            // defaultValue={destination}
                                            onChange={handleInputChangeDropOff}
                                            type="text" placeholder="City or Airport" aria-describedby="inputGroupPrepend" required  autoComplete='off' readonly/>
                                        <Form.Control.Feedback type="invalid">Please choose a city or airport name.</Form.Control.Feedback>
                                    </InputGroup>
                                    {showDropdownDropoff && (<ul className="dropdown-list">
                                        {resultsDropoff.map((item, index) => (
                                            <li key={index} onClick={() => selectDestinationDropoff(item)}>
                                                <FaLocationDot /> {item.location_name}<br/>
                                                {item.city}
                                            </li>
                                        ))}
                                    </ul>)}
                                </Form.Group>
                                <input type="hidden" name="dropofflocationvalue" value={destinationdropoffValue} />
                    </div>
                    <div className="col-md-6 col-lg-3 md-pr-1 lg-pr-1 xl-pr-1 lg-pl-1 xl-pl-1 mb-3">
                    <label>Pick up Date & Time</label>
                        <div className="row">
                            <div className="col-7 pr-0">

                            <Form.Group onClick={openPopover}>
                                                    <InputGroup >
                                                        <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                                        <Form.Control autoComplete='off' readonly type="text" value={checkinDate? dateFormatter(checkinDate)  : modifyData.checkinDate} onChange={openPopover} name="checkinDate" placeholder="Check-in" aria-describedby="inputGroupPrepend" required />
                                                        {/* <DatePicker selected={checkinDate} type="text" name="checkinDate" onChange={openPopover} minDate={new Date()} dateFormat="dd/MM/yyyy" aria-describedby="inputGroupPrepend" required className={`form-control ${isCheckInValid ? "is-valid" : "is-invalid"}`} /> */}
                                                        <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                                                    <Popover id="popover-contained">
                                                        <Popover.Body>
                                                            {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                                                        </Popover.Body>
                                                    </Popover>
                                                </Overlay>

                            </div>
                            <div className="col-5 pl-0">
                                <select className={styles.timesel} name="pickupTime" autoComplete='off' readonly  onChange={(event) => setPickupTime(event.target.value)}>
                                <option defaultChecked value={modifyData.pickupTime}>{modifyData.pickupTime}</option>
                                <option value="00:00">00:00</option>

                                    <option value="00:30">00:30</option>

                                    <option value="01:00">01:00</option>

                                    <option value="01:30">01:30</option>

                                    <option value="02:00">02:00</option>

                                    <option value="02:30">02:30</option>

                                    <option value="03:00">03:00</option>

                                    <option value="03:30">03:30</option>

                                    <option value="04:00">04:00</option>

                                    <option value="04:30">04:30</option>

                                    <option value="05:00">05:00</option>

                                    <option value="05:30">05:30</option>

                                    <option value="06:00">06:00</option>

                                    <option value="06:30">06:30</option>

                                    <option value="07:00">07:00</option>

                                    <option value="07:30">07:30</option>

                                    <option value="08:00">08:00</option>

                                    <option value="08:30">08:30</option>

                                    <option value="09:00">09:00</option>

                                    <option value="09:30">09:30</option>

                                    <option value="10:00" >10:00</option>

                                    <option value="10:30">10:30</option>

                                    <option value="11:00">11:00</option>

                                    <option value="11:30">11:30</option>

                                    <option value="12:00">12:00</option>

                                    <option value="12:30">12:30</option>

                                    <option value="13:00">13:00</option>

                                    <option value="13:30">13:30</option>

                                    <option value="14:00">14:00</option>

                                    <option value="14:30">14:30</option>

                                    <option value="15:00">15:00</option>

                                    <option value="15:30">15:30</option>

                                    <option value="16:00">16:00</option>

                                    <option value="16:30">16:30</option>

                                    <option value="17:00">17:00</option>

                                    <option value="17:30">17:30</option>

                                    <option value="18:00">18:00</option>

                                    <option value="18:30">18:30</option>

                                    <option value="19:00">19:00</option>

                                    <option value="19:30">19:30</option>

                                    <option value="20:00">20:00</option>

                                    <option value="20:30">20:30</option>

                                    <option value="21:00">21:00</option>

                                    <option value="21:30">21:30</option>

                                    <option value="22:00">22:00</option>

                                    <option value="22:30">22:30</option>

                                    <option value="23:00">23:00</option>

                                    <option value="23:30">23:30</option>

                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 md-pl-1 lg-pl-1 xl-pl-1 lg-pr-1 xl-pr-1 mb-3">
                    <label>Drop off Date & Time</label>
                    <div className="row">
                            <div className="col-7 pr-0">
                            <Form.Group onClick={openPopover} >
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                                        <Form.Control type="text" value={checkoutDate?  dateFormatter(checkoutDate) :modifyData.checkoutDate} onChange={openPopover} name="checkoutDate" placeholder="Check-in" aria-describedby="inputGroupPrepend" required />
                                                        {/* <DatePicker selected={checkinDate} type="text" name="checkinDate" onChange={openPopover} minDate={new Date()} dateFormat="dd/MM/yyyy" aria-describedby="inputGroupPrepend" required className={`form-control ${isCheckInValid ? "is-valid" : "is-invalid"}`} /> */}
                                                        <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                                                    <Popover id="popover-contained">
                                                        <Popover.Body>
                                                            {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                                                        </Popover.Body>
                                                    </Popover>
                                                </Overlay>

                            </div>
                            <div className="col-5 pl-0">
                            <select className={styles.timesel}name="dropoffTime" autoComplete='off' readonly onChange={(event) => setDropoffTime(event.target.value)} >
                            <option defaultChecked value={modifyData.dropoffTime}>{modifyData.dropoffTime}</option>
                                <option value="00:00">00:00</option>

                                    <option value="00:30">00:30</option>

                                    <option value="01:00">01:00</option>

                                    <option value="01:30">01:30</option>

                                    <option value="02:00">02:00</option>

                                    <option value="02:30">02:30</option>

                                    <option value="03:00">03:00</option>

                                    <option value="03:30">03:30</option>

                                    <option value="04:00">04:00</option>

                                    <option value="04:30">04:30</option>

                                    <option value="05:00">05:00</option>

                                    <option value="05:30">05:30</option>

                                    <option value="06:00">06:00</option>

                                    <option value="06:30">06:30</option>

                                    <option value="07:00">07:00</option>

                                    <option value="07:30">07:30</option>

                                    <option value="08:00">08:00</option>

                                    <option value="08:30">08:30</option>

                                    <option value="09:00">09:00</option>

                                    <option value="09:30">09:30</option>

                                    <option value="10:00" >10:00</option>

                                    <option value="10:30">10:30</option>

                                    <option value="11:00">11:00</option>

                                    <option value="11:30">11:30</option>

                                    <option value="12:00">12:00</option>

                                    <option value="12:30">12:30</option>

                                    <option value="13:00">13:00</option>

                                    <option value="13:30">13:30</option>

                                    <option value="14:00">14:00</option>

                                    <option value="14:30">14:30</option>

                                    <option value="15:00">15:00</option>

                                    <option value="15:30">15:30</option>

                                    <option value="16:00">16:00</option>

                                    <option value="16:30">16:30</option>

                                    <option value="17:00">17:00</option>

                                    <option value="17:30">17:30</option>

                                    <option value="18:00">18:00</option>

                                    <option value="18:30">18:30</option>

                                    <option value="19:00">19:00</option>

                                    <option value="19:30">19:30</option>

                                    <option value="20:00">20:00</option>

                                    <option value="20:30">20:30</option>

                                    <option value="21:00">21:00</option>

                                    <option value="21:30">21:30</option>

                                    <option value="22:00">22:00</option>

                                    <option value="22:30">22:30</option>

                                    <option value="23:00">23:00</option>

                                    <option value="23:30">23:30</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                <div className="col-md-6 col-lg-3 md-pr-1 lg-pr-1 xl-pr-1 mb-3">
                <label>Driver's Age</label>
                <select name='driverAge' autoComplete='off' readonly onChange={(event) => setDriverAge(event.target.value)}>
                        <option  defaultChecked value={modifyData.driverAge}>Age {modifyData.driverAge}</option>
                        <option value="18">Age 18</option>

                        <option value="19">Age 19</option>

                        <option value="20">Age 20</option>

                        <option value="21">Age 21</option>

                        <option value="22">Age 22</option>

                        <option value="23">Age 23</option>

                        <option value="24">Age 24</option>

                        <option value="25">Age 25</option>

                        <option value="26">Age 26</option>

                        <option value="27">Age 27</option>

                        <option value="28">Age 28</option>

                        <option value="29">Age 29</option>

                        <option value="30">Age 30</option>

                        <option value="31">Age 31</option>

                        <option value="32">Age 32</option>

                        <option value="33">Age 33</option>

                        <option value="34">Age 34</option>

                        <option value="35">Age 35</option>

                        <option value="36">Age 36</option>

                        <option value="37">Age 37</option>

                        <option value="38">Age 38</option>

                        <option value="39">Age 39</option>

                        <option value="40">Age 40</option>

                        <option value="41">Age 41</option>

                        <option value="42">Age 42</option>

                        <option value="43">Age 43</option>

                        <option value="44">Age 44</option>

                        <option value="45">Age 45</option>

                        <option value="46">Age 46</option>

                        <option value="47">Age 47</option>

                        <option value="48">Age 48</option>

                        <option value="49">Age 49</option>

                        <option value="50">Age 50</option>

                        <option value="51">Age 51</option>

                        <option value="52">Age 52</option>

                        <option value="53">Age 53</option>

                        <option value="54">Age 54</option>

                        <option value="55">Age 55</option>

                        </select>
                </div>
                <div className="col-md-6 col-lg-3 md-pl-1 lg-pl-1 xl-pl-1 lg-pr-1 xl-pr-1 mb-3">
                    <label>Country of Residence</label>
                    <select autoComplete='off' name="country" readonly onChange={(event) => setCountry(event.target.value)}>
                    {/* {countryData.countryData.map((item, i) => (
                        <option value={item.code} key={i} selected={item.code === 'US'}>{item.name}</option>
                    ))} */}
                     {countryData.countryData.map(item => (
                        item.code === modifyData.country  ?
                            <option value={item.code}  selected >{item.name}</option> :
                            <option value={item.code} >{item.name}</option>                
                    ))}
                    
                    </select>
                </div>
                <div className="col-lg-2 lg-pl-1 xl-pl-1 mb-3">
                <label style={{display:"inline-block"}} className="hidden-xs hidden-sm hidden-md">&nbsp;</label>
                    <button className={styles.modifybtn}>Search</button>
                </div>
                </div>
            </Form>
        </div>
    </div>
  )
}

export default ModifySearch;
