import React,{ useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import DepartureLayover from './DepartureLayover';
import ReturnLayover from './ReturnLayover';
import DepartureOnwardDuration from './DepartureOnwardDuration';
import ArrivalOnwardDuration from './ArrivalOnwardDuration';
import DepartureReturnDuration from './DepartureReturnDuration';
import ArrivalReturnDuration from './ArrivalReturnDuration';
import { useFilterContext } from '../../context/filterContext';

function LeftFilters(props) {
  const {destFrom ,destTo,journey_type,inputs,personDetail,class_type} = props.searchData
 const {filters:{ MarketingAirlineName, layowerFlags, layowerAirports, price, maxPrice, minPrice },nonStop,oneStop,twoStop, IsRefundable,Refundable,FilterValuePrice, flghtStopFilter, updateFilterIsRefundable, updateFilterValue, all_products, clearFilters} =useFilterContext()
 
 const getUniqueData = (data, attr) => { 
    let newVal = data.map((curElem) => {
      return {
        "value":curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment[attr],
        "amount":curElem.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount,
        "CurrencyCode":curElem.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode
      }
    });

    if(attr === "layowerAirports"){
      let newVal = data.map((curElem) => { 
        if(curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length > 1)
        for (let j = 0;j <curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1; j++) {
          if (!layowerFlags[curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[j].FlightSegment.ArrivalAirportLocationCode ]) {
            layowerAirports.push(curElem.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[j].FlightSegment.ArrivalAirportLocationCode);
          }
        }
      });
      return (newVal = [ ...new Set(layowerAirports)]);
    }
      return  newVal = newVal.filter((obj, index) => {
      return index === newVal.findIndex(o => obj.value === o.value);
    })
  };
  const airlines = getUniqueData(all_products, "MarketingAirlineName")
  const layowerAirport = getUniqueData(all_products, "layowerAirports");  
  return (
    <>
      <div className="left-filter-section">
        <h3>Popular Filters</h3>
        <button onClick={clearFilters} className="reset-btn btn-danger">Reset</button>
        <div className='filter-box'>
          <h5 className='filter-heading'>Price</h5>
          <div className='filter-body'> 
          <h5>{maxPrice} </h5>
          <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={[minPrice, maxPrice]}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={10}
                max={maxPrice}
                onChange={FilterValuePrice}
                value={price}
            />
          </div>

        <div className="filter_price">
        {/* <p>{price} </p>
        <input type="range" name="price" min={minPrice} max={maxPrice} value={price} /> */}
      </div>
        </div>
        <div className='filter-box'>
          <h5 className='filter-heading'>No. of Stops</h5>
          <div className='filter-body'>
              <ul className="checkbox-ul">
                <li>
                  <div className="row">
                  <div className="col-8">
                    <label className="label-text">
                      <input defaultChecked={true}  name="1" value="1" onChange={nonStop}  type="checkbox"/> Non Stop
                    </label>
                  </div>
                  <div className="col-4 text-right">
                    <label className="price-label">USD 424</label>
                  </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                  <div className="col-8">
                    <label className="label-text">
                      <input   defaultChecked={true}  name="2" value="2" onChange={oneStop} type="checkbox"/> 1 Stop
                    </label>
                  </div>
                  <div className="col-4 text-right">
                    <label className="price-label">USD 415</label>
                  </div>   
                  </div>
                </li>
                <li>
                  <div className="row">
                  <div className="col-8">
                    <label className="label-text">
                      <input  defaultChecked={true} value="3"  name="3" onChange={twoStop} type="checkbox"/> 2+ Stops
                    </label>
                  </div>
                  <div className="col-4 text-right">
                    <label className="price-label">USD 943</label>
                  </div> 
                  </div>
                </li>
              </ul>
          </div>
        </div>

        <div className='filter-box'>
          <h5 className='filter-heading'>Fare Type</h5>
          <div className='filter-body'>
            <ul className="checkbox-ul">
              <li>
                <label>
                <input  defaultChecked={true} value="3"  name="3" onChange={Refundable} type="checkbox"/>Refundable
                  {/* <input onClick={updateFilterIsRefundable} defaultChecked={true}  name="IsRefundable"    type="checkbox"  value="yes"/> Refundable */}
                </label>
              </li>
              <li>
                <label>
                <input  defaultChecked={true} value="3"  name="3" onChange={IsRefundable} type="checkbox"/>Non-Refundable
                  {/* <input onClick={updateFilterIsRefundable}  defaultChecked={true} name="IsRefundable"  type="checkbox" value="no"/> Non-Refundable */}
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div className='filter-box'>
          <h5 className='filter-heading'>Layover Time</h5>
          <div className='filter-body'>
           <DepartureLayover/>
          </div>
        </div>
        <div className='filter-box'>
          <h5 className='filter-heading'>Layover Time Return</h5>
          <div className='filter-body'>
          <ReturnLayover/>
          </div>
        </div>

        <div className='filter-box'>
          <h5 className='filter-heading'>Onward Journey</h5>
          <div className='filter-body'>
          <DepartureOnwardDuration destFrom={destFrom}  />
          <ArrivalOnwardDuration destTo={destTo} />       
          </div>
        </div>

        <div className='filter-box'>
          <h5 className='filter-heading'>Return Journey</h5>
          <div className='filter-body'>
            <DepartureReturnDuration   destTo={destTo}/>
            <ArrivalReturnDuration destFrom={destFrom} />
          </div>
        </div>

        <div className='filter-box'>
          <h5 className='filter-heading'>Airlines</h5>
          <div className='filter-body'>
            <ul className="checkbox-ul">
              <li>
                <form className="form w-100">
                <div className="form-check">
                <input type="checkbox" className="form-check-input" name="allSelect"
                  // checked={
                  //   users.filter((user) => user?.isChecked !== true).length < 1
                  // }
                  checked={airlines.filter((user) => user.isChecked !== true).length<1} onChange={updateFilterValue}
                />
                <label className="form-check-label ms-2">All Select</label>
              </div>
                  {airlines.map((user,index)=>{ return <div className="row" key={index}>  
                  <div className="col-8">
                    <div className="form-check" >
                    <input type="checkbox" className="form-check-input" name={user.value} checked={user.isChecked} onChange={updateFilterValue}/>
                    <label className="form-check-label ms-2">{user.value} {user.amount} </label>
                  </div>
                  </div>
                  <div className="col-4 text-right">
                    <label className="price-label">{user.CurrencyCode} {user.amount}</label>
                  </div> 
                  </div>
                })}
                </form>
              </li>

            </ul>
          </div>
        </div>
        <div className='filter-box'>
          <h5 className='filter-heading'>Layover Airports</h5>
          <div className='filter-body'>
          <ul className="checkbox-ul">
            <form className="form w-100">
              {layowerAirport.map((air,index)=>{return <div className="form-check" key={index} >
                <input type="checkbox" className="form-check-input" name="allSelect" checked={air.isChecked} onChange={updateFilterValue}/>
                <label className="form-check-label ms-2">{air}</label>
              </div>
              })}
              </form>   
              </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeftFilters;
