import React from 'react';
import './Newsletter.css';
import { Button } from '../button/Button';

function Newsletter() {
  return (
    <div className='newsletter-section'>
    <div className="cover"></div>
        <div className='newsletter-container'>
            <h2 className="newsletter-heading">Newsletter</h2>
            <h6 className='newsletter-subheading'>Subscribe to our Newsletter and get our latest updates</h6>
            <div className='input-areas'>
            <form>
              <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
              />
              <Button buttonStyle='subscribe-btn'>Subscribe</Button>
            </form>
            </div>
        </div>
      
    </div>
  )
}

export default Newsletter
