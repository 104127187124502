import { Container, Row, Col, InputGroup, Form, Button, Overlay, Popover, } from 'react-bootstrap';
import { FaCalendarDay, FaLocationDot, } from "react-icons/fa6";
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import axios from "axios";
import 'react-datepicker/dist/react-datepicker.css';
import countryData from '../../json/country.json';
import dateFormat, { masks } from "dateformat";
import { addDays, isBefore } from "date-fns";
import { json, useNavigate } from 'react-router-dom';
import { useCarContext } from '../../context/Car/carContext';
import '../Car/Css/SearchCar.css'
function Searchcar({ inputs }) {
    const [ApiDestination, setApiDestination] = useState();
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [destinationpickupValue, setdestinationpickupValue] = useState([]);
    const [destinationdropoffValue, setdestinationdropoffValue] = useState([]);
    const [destinationpickup, setdestinationpickup] = useState(inputs ? inputs.destinationpickup : '');
    const [destinationdropoff, setdestinationdropoff] = useState(inputs ? inputs.destinationdropoff : '');
    const [checkinDate, setcheckinDate] = useState(inputs ? inputs.checkinDate : '');
    const [pickupTime, setPickupTime] = useState(inputs ? inputs.pickupTime : '');
    const [checkoutDate, setcheckoutDate] = useState(inputs ? inputs.checkoutDate : '');
    const [dropoffTime, setDropoffTime] = useState(inputs ? inputs.dropoffTime : '');
    const [resultsPickup, setResultsPicup] = useState([]);
    const [resultsDropoff, setResultsDropoff] = useState([]);
    const [driverAge, setDriverAge] = useState([]);
    const [country, setCountry] = useState([]);
    const [showDropdownPickup, setShowDropdownPickup] = useState(false);
    const [showDropdownDropoff, setShowDropdownDropoff] = useState(false);
    const navigate = useNavigate();
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [dataFromChild, setDataFromChild] = useState(null);
    const [isChecked, setIsChecked] = useState(true)


    // Function to open the popover
    const openPopover = (event) => {
        setPopoverOpen(!isPopoverOpen);
        setDataFromChild(event.target);
        setcheckinDate('');
        setcheckoutDate('');
    };


    const checkHandler = () => {
        setIsChecked(!isChecked)
        setdestinationdropoff(!isChecked ? destinationpickup : '')
        setdestinationdropoffValue(!isChecked ? destinationpickupValue : '')

    }



    const handleInputChangePickup = async (e) => {
        const searchTerm = e.target.value;
        setdestinationpickup(searchTerm);
        if (searchTerm.trim() === '') {
            setResultsPicup([]);
            setShowDropdownPickup(false);
            return;
        }
        try {
            let json = {
                "destination": searchTerm
            }
            const response = await axios.post("http://localhost:3008/destinations", json);
            setResultsPicup(response.data);
            setShowDropdownPickup(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };





    // Function to close the popover and receive data from the child
    const closePopover = (data) => {
        console.log(data)
        setPopoverOpen(false);
        //setDataFromChild(data);
    };
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
   

    function PopoverComponent({ onClose }) {
        const onChange = (dates) => {
            // console.log("Dates selected",dates)
            const [start, end] = dates;
            setcheckinDate(start);
            setcheckoutDate(end);
            if (end) {
                onClose(true);
            }
        };
        return (
            <div>
                <DatePicker
                    selected={checkinDate}
                    onChange={onChange}
                    minDate={new Date()}
                    startDate={checkinDate}
                    endDate={checkoutDate}
                    // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                    selectsRange
                    selectsDisabledDaysInRange
                    inline />
            </div>   
        );
    }

    function dateFormatter(date) {
        if (new Date(date) == 'Invalid Date') {
            return date
        } else {
            let dateString = new Date(date)
            let localTime = dateString.getTime();
            let localOffset = dateString.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let offset = 5.5;
            let bombay = utc + (3600000 * offset);
            let nd = new Date(bombay);
            return (("0" + (nd.getDate())).slice(-2) + '-' + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + nd.getFullYear());
        }
    }




    const handleInputChangeDropOff = async (e) => {
        const searchTerm = e.target.value;
        setdestinationdropoff(searchTerm);
        if (searchTerm.trim() === '') {
            setResultsDropoff([]);
            setShowDropdownDropoff(false);
            return;
        }
        try {
            const filteredItems = ApiDestination.filter((car_dest) =>
                car_dest.location_name.toLowerCase().includes(searchTerm.toLowerCase())
            );


            setResultsDropoff(filteredItems);
            setShowDropdownDropoff(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const selectDestinationPickup = (item) => {
        setdestinationpickup(item.location_name);
        setdestinationpickupValue(item.id);
        setdestinationdropoff(isChecked ? item.location_name : '')
        setdestinationdropoffValue(isChecked ? item.id : '')
        setShowDropdownPickup(false);
    };

    const selectDestinationDropoff = (item) => {
        setdestinationdropoff(item.location_name);
        setdestinationdropoffValue(item.id)
        setShowDropdownDropoff(false);
    };



    const searchCars = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            //console.log(formJson, form.checkValidity());

            console.log("search car",);
            navigate("/CarList", {
                state: {
                    inputs: formJson,
                    pickup_id: destinationpickupValue,
                    dropoff_id: destinationdropoffValue,
                    pickup_date: dateFormat(checkinDate, "yyyy-mm-dd"),
                    pickup_time: pickupTime,
                    dropoff_date: dateFormat(checkoutDate, "yyyy-mm-dd"),
                    dropoff_time: dropoffTime,
                    currency: "USD",
                    driver_age: driverAge,
                    country_res: country
                }
            })

        }
    };



    return (
        <>
            <div className='modify-section'>
                <Form noValidate validated={validated} onSubmit={searchCars}  >
                    <Row>
                        <Col md={6} sm={6} lg={6} >
                            <Form.Group controlId="validationCustomLocation" className="search-dropdown mb-3" >
                                {/* <Form.Label>Pickup Location</Form.Label> */}
                                <InputGroup hasValidation>
                                    <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                                    <Form.Control ref={ref}
                                        type="text" placeholder="Pickup Location" aria-describedby="inputGroupPrepend" required autoComplete='off'
                                        name="destinationpickup"
                                        value={destinationpickup}
                                        onChange={handleInputChangePickup}
                                        onFocus={() => { setdestinationpickup('') }} />
                                    <Form.Control.Feedback type="invalid">Please choose a Pickup Location.</Form.Control.Feedback>
                                </InputGroup>

                                {showDropdownPickup && (<ul className="dropdown-list">
                                    {resultsPickup.map((item, index) => (
                                        <li key={index} onClick={() => selectDestinationPickup(item)}>
                                            <FaLocationDot /> {item.location_name}<br /> {item.city}
                                        </li>
                                    ))}
                                </ul>)}
                            </Form.Group>
                            <input type="hidden" name="pickuplocationvalue" value={destinationpickupValue} />
                        </Col>
                        <Col md={6} sm={6} lg={6}  >
                            <Form.Group controlId="validationCustomLocation" className="search-dropdown mb-3 " >
                                {/* <Form.Label>Drop-Off Location</Form.Label> */}
                                <InputGroup hasValidation>
                                    <InputGroup.Text><FaLocationDot /></InputGroup.Text>
                                    <Form.Control ref={ref}
                                        type="text" placeholder="Drop-Off Location" aria-describedby="inputGroupPrepend" required autoComplete='off'
                                        name="destinationdropoff"
                                        onFocus={() => { setdestinationdropoff('') }}
                                        value={destinationdropoff}
                                        onChange={handleInputChangeDropOff} />
                                    <Form.Control.Feedback type="invalid">Please choose a Drop-Off Location.</Form.Control.Feedback>
                                </InputGroup>
                                {showDropdownDropoff && (<ul className="dropdown-list">
                                    {resultsDropoff.map((item, index) => (
                                        <li key={index} onClick={() => selectDestinationDropoff(item)}>
                                            <FaLocationDot /> {item.location_name}<br /> {item.city}
                                        </li>
                                    ))}
                                </ul>)}
                            </Form.Group>
                            <input type="hidden" name="dropofflocationvalue" value={destinationdropoffValue} />
                        </Col>
                        <Col md={12} lg={12} >
                            <Form.Check inline className="mb-3" label="Same as pickup location" type="checkbox" name="group1" id="inline-checkbox-1"></Form.Check>
                        </Col>
                    </Row>
                    
                    <Row >
                        <Col md={12} lg={12}>
                            <Row>
                                <Col md={4} sm={6}  lg={4}>
                                    <Form.Group onClick={openPopover} controlId="validationCustomcheckoutDate" className="mb-3">
                                        {/* <Form.Label>Pickup Date</Form.Label> */}
                                        <InputGroup hasValidation>
                                            <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                            <Form.Control autoComplete='off' type="text" value={dateFormatter(checkinDate)} onChange={openPopover} name="checkinDate" placeholder="Check-in" aria-describedby="inputGroupPrepend" required />
                                            <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                                        <Popover id="popover-contained">
                                            <Popover.Body>
                                                {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                                            </Popover.Body>
                                        </Popover>
                                    </Overlay>
                                </Col>
                                <Col md={2} sm={6}  lg={2}>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Time</Form.Label> */}
                                        <Form.Select aria-label="Default select example" name="pickupTime" autoComplete='off' onChange={(event) => setPickupTime(event.target.value)}>
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00" selected>10:00</option>
                                            <option value="10:30">10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option value="12:00">12:00</option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4} sm={6} lg={4}>
                                    <Form.Group onClick={openPopover} className="mb-3" controlId="validationCustomcheckoutDate">
                                        {/* <Form.Label>Drop-off Date</Form.Label> */}
                                        <InputGroup hasValidation>
                                            <InputGroup.Text><FaCalendarDay /></InputGroup.Text>
                                            <Form.Control type="text" value={dateFormatter(checkoutDate)} onChange={openPopover} name="checkoutDate" placeholder="Drop-off Date" aria-describedby="inputGroupPrepend" required />
                                            <Form.Control.Feedback type="invalid"> Please choose checking date</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Overlay show={isPopoverOpen} target={dataFromChild} placement="bottom" >
                                        <Popover id="popover-contained">
                                            <Popover.Body>
                                                {isPopoverOpen && (<PopoverComponent onClose={closePopover} />)}
                                            </Popover.Body>
                                        </Popover>
                                    </Overlay>
                                </Col>
                                <Col md={2} sm={6} lg={2}>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Time</Form.Label> */}
                                        <Form.Select aria-label="Default select example" name="dropoffTime" autoComplete='off' onChange={(event) => setDropoffTime(event.target.value)}>
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:30" selected>10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option value="12:00">12:00</option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4} sm={6} lg={4}>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Driver Age</Form.Label> */}
                                        <Form.Select aria-label="Default select example" name='driverAge' autoComplete='off' onChange={(event) => setDriverAge(event.target.value)}>
                                            <option disabled>Driver Age</option>
                                            <option value="25" selected>25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                            <option value="32">32</option>
                                            <option value="33">33</option>
                                            <option value="34">34</option>
                                            <option value="35">35</option>
                                            <option value="36">36</option>
                                            <option value="37">37</option>
                                            <option value="38">38</option>
                                            <option value="39">39</option>
                                            <option value="40">40</option>
                                            <option value="41">41</option>
                                            <option value="42">42</option>
                                            <option value="43">43</option>
                                            <option value="44">44</option>
                                            <option value="45">45</option>
                                            <option value="46">46</option>
                                            <option value="47">47</option>
                                            <option value="48">48</option>
                                            <option value="49">49</option>
                                            <option value="50">50</option>
                                            <option value="51">51</option>
                                            <option value="52">52</option>
                                            <option value="53">53</option>
                                            <option value="54">54</option>
                                            <option value="55">55</option>
                                            <option value="56">56</option>
                                            <option value="57">57</option>
                                            <option value="58">58</option>
                                            <option value="59">59</option>
                                            <option value="60">60</option>
                                            <option value="61">61</option>
                                            <option value="62">62</option>
                                            <option value="63">63</option>
                                            <option value="64">64</option>
                                            <option value="65">65</option>
                                            <option value="66">66</option>
                                            <option value="67">67</option>
                                            <option value="68">68</option>
                                            <option value="69">69</option>
                                            <option value="70">70</option>
                                            <option value="71">71</option>
                                            <option value="72">72</option>
                                            <option value="73">73</option>
                                            <option value="74">74</option>
                                            <option value="75">75</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4} sm={6} lg={4}  >
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Country</Form.Label> */}
                                        <Form.Select aria-label="Default select example" autoComplete='off' name="country" onChange={(event) => setCountry(event.target.value)}>
                                            {/* <option disabled>Select Country</option> */}
                                            {countryData.countryData.map((item, i) => (
                                                <option value={item.code} key={i} selected={item.code === 'US'}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2} lg={2} className='align-items-center' >
                                    <Button type="submit" className='btn-submit'>Search</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}


export default Searchcar
