import {Container, Form, Row, Col} from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from "../Footer/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import CarResults from "./CarResults";
import SearchCar from './SearchCar';
import CarLeftFilter from "./CarLeftFilter";
import CarDetailCard from "./CarDetailCard";
import dateFormat from "dateformat";

import countryData from '../../json/country.json';
import paymentData from '../../json/payment.json';


import { useParams } from 'react-router-dom';
import { useCarContext } from '../../context/Car/carContext';
import styles from './CarDetails.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faLocationDot, faCalendarDays, faStar, faGasPump, faMapLocationDot, faCircleCheck  } from '@fortawesome/free-solid-svg-icons';
import { FaStar, FaUser, FaMagnifyingGlass, FaCalendarDay, Flight, FaCirclePlus, FaCircleMinus} from "react-icons/fa6";

function CarDetails(inputs) {
    const navigate = useNavigate();

    const location = useLocation();
    const { session_id, carDataDetails, driverAge, country_res } = location.state
    const [validated, setValidated] = useState(false);

    
    const { getCarDetails, carDetailResults, isLoading, isError } = useCarContext();
    const { id } = useParams();
    const rareDetail = carDataDetails?.filter(obj => obj.referenceId == id);
    const [carExtraDetails, setCarExtraDetails] = useState([]);
    const [rentalAmount, setReantalAmount] = useState(0.00);
    const [totalAmount, setTotalAmount] = useState(0.00);
    const [extraAmount, setExtraAmount] = useState(0);

    const [additionalInfoIsShown, setAdditionalInfoIsShown] = useState(false);




    let nextId = 0;

    // car detail filter by id
    let carObj = Object.assign({}, rareDetail);
    let carDetail = "";
    
        if(carObj != ''){

            carDetail = Object.values(carObj).find((obj) => {
                if (obj.referenceId === id) console.log('found!');
                return (obj.referenceId === id);
            });

         }
         else{
            carDetail = '';
         }


         console.log("car data item",carDetail);
        
        const getCarDataRusults = async () => {
            let json = {
                "session_id": session_id,
                "reference_id": id
            }
            console.log("car Details engine", json)
            await getCarDetails(json);
           
        }

        useEffect(() => {
            setReantalAmount(carDetail.fees.rateTotalAmount)
            setTotalAmount(carDetail.fees.rateTotalAmount)
            getCarDataRusults()
        }, [])
        // end filter by id 
        
        //console.log("car detail from rental",carDetailResults);

        console.log("car details",carExtraDetails)          

         // car extra charge event 
         const carExtrasEvent = (event, param1)=>{
            console.log("selcted values",event.target.name,event.target.value,param1 )
            let evnname = event.target.name
            let evnvalue = event.target.value
            let extraCharge = ''
            let totalCharge = ''

            let exAddCharge = evnvalue * param1;
           
           
            let  result = '';

            if (Array.isArray(carExtraDetails) && carExtraDetails.length){

                 let filterDuplicate = carExtraDetails.filter((carsDet) => carsDet.exname == evnname);
                
                    if(Array.isArray(filterDuplicate) &&  filterDuplicate.length){
                        const nextCounters = carExtraDetails.map((c, i) => {
                            
                            if (c.exname == evnname) {
                                return { ...c, exprice: exAddCharge, excount: evnvalue };
                            } else {
                            
                                return c;
                            }
                           
                        });

                        setCarExtraDetails(nextCounters);
                        result = nextCounters.reduce((total, currentValue) => total = total + currentValue.exprice,0);

                        totalCharge=  parseFloat(rentalAmount)+parseFloat(result);  
                        extraCharge = parseFloat(result);
                        setExtraAmount(extraCharge);
                        setTotalAmount(totalCharge);
                       
                    }else {
                        carExtraDetails.push({
                                id: nextId++,
                                exname: evnname,
                                exprice:exAddCharge,
                                excount: evnvalue
                            })
                            result = carExtraDetails.reduce((total, currentValue) => total = total + currentValue.exprice,0);
                            totalCharge=  parseFloat(rentalAmount)+parseFloat(result);  
                            extraCharge = parseFloat(result);
                            setExtraAmount(extraCharge);
                            setTotalAmount(totalCharge);

                    }
            
            
            }
            else {
                carExtraDetails.push({
                    id: nextId++,
                    exname: evnname,
                    exprice:exAddCharge,
                    excount: evnvalue
                })
                result = carExtraDetails.reduce((total, currentValue) => total = total + currentValue.exprice,0);

                totalCharge=  parseFloat(rentalAmount)+parseFloat(result);  
                extraCharge = parseFloat(result);
                setExtraAmount(extraCharge);
                setTotalAmount(totalCharge);
            }
 

        }
        // end car extra charge event






        // car rental details 
        let   commonData = '';

        if(carDetailResults){
            console.log("error log")

            if(carDetailResults.Errors){
                console.log("error log"); 

            }else if(carDetailResults.data.pricedEquipments){

                console.log("error log2", carDetailResults.data.pricedEquipments); 
              
                commonData = 
                    carDetailResults.data.pricedEquipments.map((pricedEq, i) => {
                        console.log("error log", pricedEq.description);

                        return(
                    <tr key={i}>
                        <td>
                            <p className={styles.heading}>
                            {pricedEq.description} <span>(USD  {pricedEq.amount} each per rental) (incl. taxes)</span>
                            </p>
                            <p>
                            This is an Optional Extra
                            </p>
                        </td>
                        <td>
                            <select name={pricedEq.description}  onChange={event => carExtrasEvent(event, pricedEq.amount)}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </td>

                       
                        <td>
                        {carExtraDetails.map(detAmount => (
                            pricedEq.description == detAmount.exname ? 
                                <p>USD {detAmount.exprice ?parseFloat(detAmount.exprice).toFixed(2) : 0.00}</p>
                            :''
                        )
                        )}
                        </td>
                    
                    </tr>)
                    })

            }
         
        }
        // end car rental details 



        // passenger detail form 
        const passengerDetailForm = async (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            setValidated(true);
            
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                event.preventDefault();
                const formData = new FormData(form);
                const formJson = Object.fromEntries(formData.entries());
                //console.log(formJson, form.checkValidity());
                
                console.log("passenger Entries", formJson )

                navigate(`/car-details/${id}/preview_booking`, {
                    state: {
                       car_id:id,
                       formData: formJson,
                       carDetails:carDetail, 
                       bookingPrice:{
                        rentalCost: carDetail.fees.rateTotalAmount,
                        extra : extraAmount, 
                        paynow:totalAmount,
                        totalPrice: totalAmount,                        
                       },
                       session_id:session_id,
                       extraDetails: carExtraDetails,
                    }
        
                })

                
            }
        };
        // end passenger detail form


        // additional info event
        const additional_view = event => {
            // ️ toggle shown state
            setAdditionalInfoIsShown(current => !current);
        
            // ️ or simply set it to true
            // setIsShown(true);
          };
        // end additional info event




    return(
        <>
        <Header/>
        <div className={styles.detailsContent}>
        <div className='container'>
            <div className={styles.carAlert}>
                <h2>
                    <FontAwesomeIcon icon={faBell}/>
                </h2>
                <p>
                    <span className={styles.colorText}>This is a 'Value for Money' rental vehicle available for {carDetail.pickup.locationName}</span>
                <br></br>
                    <span className={styles.darkText}>We offer free cancellation up to 48 hours before pickup.</span>
                </p>
                
            </div>

            <div className={styles.carRentalDetails}>
                <h2>Your Car Rental Details</h2>
                <Row>
                    <Col md={3}>
                        <div className={styles.carImage}>
                            <img src={carDetail.carDetails.carImage} alt=""/>
                        </div>
                    </Col>
                    <Col md={9}>
                        <h3 className={styles.carName}> {carDetail.carDetails.carModel} 
                        <small>{carDetail.carDetails.sizeName}</small>
                        </h3>

                        <Row>
                            <Col md={6}>
                                <div className={styles.carDestInfo}>
                                    <h5><FontAwesomeIcon icon={faLocationDot} className='mr-2'/>{carDetail.pickup.address}</h5>
                                    <p><FontAwesomeIcon icon={faCalendarDays} className='mr-2'/>{dateFormat(carDetail.pickup.date, "mmmm dS, yyyy")} - {carDetail.pickup.time}</p>
                                </div>
                            </Col>
                            <Col md={6}>
                            <div className={styles.carDestInfo}>
                                    <h5><FontAwesomeIcon icon={faLocationDot} className='mr-2'/>{carDetail.dropoff.address}</h5>
                                    <p><FontAwesomeIcon icon={faCalendarDays} className='mr-2'/>{dateFormat(carDetail.dropoff.date, "mmmm dS, yyyy")} - {carDetail.dropoff.time}</p>
                                </div>
                            </Col>
                        </Row>

                        <h4>
                            {carDetail.fees.currencyCode} {carDetail.fees.rateTotalAmount} <span>for one day - {carDetail.fees.currencyCode} {parseFloat((carDetail.fees.rateTotalAmount / carDetail.duration).toFixed(2))}/per day</span>
                        </h4>

                        <div className={styles.carFacilities}>
                            <ul>
                                <li>
                                    <span className={styles.seatIcon}></span>{carDetail.carDetails.passengerQuantity} Seats
                                </li>
                                <li>
                                    <span className={styles.bagIcon}></span>{carDetail.carDetails.baggageQuantity} Bags
                                </li>
                                <li>
                                    <span className={styles.doorIcon}></span>{carDetail.carDetails.vehicleDoor} Doors
                                </li>
                                <li>
                                    <span className={styles.fuelIcon}></span>{carDetail.carDetails.fuelType}
                                </li>
                                {carDetail.carDetails.ac == "true" ?
                                <li>
                                    <span className={styles.acIcon}></span>Air Conditioning
                                </li>
                                :''}
                                <li>
                                    <span className={styles.transmissionIcon}></span>{carDetail.carDetails.transmissionType}
                                </li>
                            </ul>
                        </div>   

                        <ul className={styles.supplierInfo}>
                            <li className={styles.supplierLogo}>
                                <img src={carDetail.vendor.vendorImage} alt='supplier'/>
                            </li> 
                            <li className={styles.supplierRating}>
                                <FontAwesomeIcon icon={faStar}/> {carDetail.vendor.reviewsOverall}
                            </li>
                            {carDetail.vendor.reviewsOverall === 2? 
                                <li className={styles.supplierReview}>
                                    Average
                                </li> : ''}
                                {carDetail.vendor.reviewsOverall === 3? 
                                <li className={styles.supplierReview}>
                                    Good
                                </li> : ''}
                                {carDetail.vendor.reviewsOverall === 4? 
                                <li className={styles.supplierReview}>
                                    Very Good
                                </li> : ''}
                                {carDetail.vendor.reviewsOverall === 5? 
                                <li className={styles.supplierReview}>
                                    Excellent
                                </li> : ''
                            }
                        </ul>  
                    </Col>
                </Row>
            </div>

            <div className={styles.addressInfo}>
                <Row>
                    <Col md={6}>
                        <div className={styles.addressDiv}>
                            <h6><FontAwesomeIcon icon={faLocationDot} className='mr-2'/>Pickup Address</h6>
                            <p>
                                {carDetail.pickup.address}
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={styles.addressDiv}>
                            <h6><FontAwesomeIcon icon={faLocationDot} className='mr-2'/>Dropoff Address</h6>
                            <p>
                                {carDetail.dropoff.address}
                            </p>
                        </div>
                    </Col>
                </Row>

                <h5>
                  <FontAwesomeIcon icon={faGasPump} className='mr-2'/>Fuel Policy : <span>Pick up and return full.</span>
                </h5>
                <div class="mb-2">
                    <a href=""><FontAwesomeIcon icon={faMapLocationDot} className='mr-2'/>View map</a>
                </div>

                <Row>
                    <Col md={6}>
                        <div className={styles.carIncludes}>
                            <h6>We give you the following for free</h6>
                            <ul>
                                <li>
                                  <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>Free Cancellation
                                </li>
                                <li>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>24/7 Customer Support
                                </li>
                                <li>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>Manage Booking
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={styles.carIncludes}>
                            <h6>Plus your rental includes</h6>
                            <ul>
                                <li>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>Collision damage waiver (CDW)
                                </li>
                                <li>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>Theft waiver (TW)
                                </li>
                                <li>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-success'/>Third party liability protection (TP)
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                


            </div>

           

            <div className={styles.carExtras}>
                <h2>Car Extras</h2>

                <div className='table-responsive'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Extras Description</th>
                                <th>Quantity</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            {commonData}
                        </tbody>
                    </table>
                </div>
            </div>


            <Form noValidate validated={validated} onSubmit={passengerDetailForm}  >
            <div className={styles.passengerSection}>
                <h2>Passenger Details</h2>

                <div className={styles.passengerDiv}>
                    
                        <Row>
                            <Col md={3}>
                                <label>Title</label>
                                <select autoComplete='off' name="title" >
                                    <option value="Mr">Mr.</option>
                                    <option value="Mrs">Mrs.</option>
                                    <option value="Miss">Ms.</option>
                                </select>
                            </Col>
                            <Col md={3}>
                                <label>First Name</label>
                                <input type="text" placeholder="First Name"  name="first_name" required></input>
                            </Col>
                            <Col md={3}>
                                <label>Last Name</label>
                                <input type="text" placeholder="Last Name"  name="last_name" required></input>
                            </Col>
                            <Col md={3}>
                                <label>License Number</label>
                                <input type="text" placeholder="License Number"  name="license_number" required></input>
                            </Col>
                            <Col md={3}>
                                <label>Email</label>
                                <input type="text" placeholder="Email"  name="email" required></input>
                            </Col>
                            <Col md={3}>
                                <label>Phone Number</label>
                                <input type="text" placeholder="Phone Number"  name="phone_number" required></input>
                            </Col>
                            
                            <Col md={3}>
                                <label>Driver's Age</label>
                                <select placeholder="Select age" autoComplete='off' name="driver_age" required>
                                    <option  defaultChecked value={driverAge}>Age {driverAge}</option>
                                    <option value="25" > 25</option>
                                                                            <option value="26"> 26</option>
                                                                            <option value="27"> 27</option>
                                                                            <option value="28"> 28</option>
                                                                            <option value="29"> 29</option>
                                                                            <option value="30"> 30</option>
                                                                            <option value="31"> 31</option>
                                                                            <option value="32"> 32</option>
                                                                            <option value="33"> 33</option>
                                                                            <option value="34"> 34</option>
                                                                            <option value="35"> 35</option>
                                                                            <option value="36"> 36</option>
                                                                            <option value="37"> 37</option>
                                                                            <option value="38"> 38</option>
                                                                            <option value="39"> 39</option>
                                                                            <option value="40"> 40</option>
                                                                            <option value="41"> 41</option>
                                                                            <option value="42"> 42</option>
                                                                            <option value="43"> 43</option>
                                                                            <option value="44"> 44</option>
                                                                            <option value="45"> 45</option>
                                                                            <option value="46"> 46</option>
                                                                            <option value="47"> 47</option>
                                                                            <option value="48"> 48</option>
                                                                            <option value="49"> 49</option>
                                                                            <option value="50"> 50</option>
                                                                            <option value="51"> 51</option>
                                                                            <option value="52"> 52</option>
                                                                            <option value="53"> 53</option>
                                                                            <option value="54"> 54</option>
                                                                            <option value="55"> 55</option>
                                                                            <option value="56"> 56</option>
                                                                            <option value="57"> 57</option>
                                                                            <option value="58"> 58</option>
                                                                            <option value="59"> 59</option>
                                                                            <option value="60"> 60</option>
                                                                            <option value="61"> 61</option>
                                                                            <option value="62"> 62</option>
                                                                            <option value="63"> 63</option>
                                                                            <option value="64"> 64</option>
                                                                            <option value="65"> 65</option>
                                                                            <option value="66"> 66</option>
                                                                            <option value="67"> 67</option>
                                                                            <option value="68"> 68</option>
                                                                            <option value="69"> 69</option>
                                                                            <option value="70"> 70</option>
                                                                            <option value="71"> 71</option>
                                                                            <option value="72"> 72</option>
                                                                            <option value="73"> 73</option>
                                                                            <option value="74"> 74</option>
                                                                            <option value="75"> 75</option>
                                </select>
                            </Col>
                            <Col md={3}>
                                <label>Country of Residence</label>
                                <select placeholder="select country" autoComplete='off' name="country" readonly  required>
                                    {/* {countryData.countryData.filter(counCode => counCode.code == country_res  ).map(item => (
                                        <option value={item.code}  defaultChecked>{item.name}</option>
                                    ))} */}
                                    {countryData.countryData.map(item => (
                                        item.code === country_res ?
                                            <option value={item.code}  selected >{item.name}</option> :
                                            <option value={item.code} >{item.name}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col md={6}>
                                <label>Address</label>
                                <input type="text" placeholder="Address"  name="address" required></input>
                            </Col>
                            <Col md={5}>
                                <label>City</label>
                                <input type="text" placeholder="City"  name="city" required></input>
                            </Col>
                            <Col md={3}>
                                <label>State</label>
                                <input type="text" placeholder="State"  name="state" required></input>
                            </Col>
                            <Col md={3}>
                                <label>Pincode</label>
                                <input type="text" placeholder="Pincode"  name="pincode" required></input>
                            </Col>
                            <Col md={12}>
                            <div class="mb-2">
                                <a href="javascript:void(0);" className='mr-2' onClick={additional_view}>{additionalInfoIsShown ? <FaCircleMinus/> : <FaCirclePlus/>} Additional Info</a>
                            </div>
                            { additionalInfoIsShown && (
                           
                             <input type="text"   name="additional_info" ></input>
                            )
                            } 
                            </Col>
                            <Col md={3}>
                                <label>Flight Airline Code (Optional)</label>
                                <select  placeholder="Select Option" autoComplete='off' name="airline_code">
                                <option defaultChecked >Select Option</option>
                                <option value="1T0">1Time</option>
                                                        <option value="9G">9G Rail Ltd</option>
                                                        <option value="1B">Abacus</option>
                                                        <option value="4O">ABC Aerolineas</option>
                                                        <option value="5U">Ac Challenge Aero</option>
                                                        <option value="9B">Accesrail</option>
                                                        <option value="6U">Acg Air Cargo Germany</option>
                                                        <option value="KI">Adam Air</option>
                                                        <option value="JP">Adria Airways</option>
                                                        <option value="A3">Aegean Airlines</option>
                                                        <option value="RE">Aer Arann</option>
                                                        <option value="EI">Aer Lingus</option>
                                                        <option value="EE">Aero Airlines</option>
                                                        <option value="EM">Aero Benin</option>
                                                        <option value="M0">Aero Mongolia</option>
                                                        <option value="P7">Aero Regional Paraguaya</option>
                                                        <option value="2D">Aero VIP</option>

                                                        <option value="NJ">Aero Vip</option>
                                                        <option value="DW">Aero-Charter</option>
                                                        <option value="HC">Aero-Tropics</option>
                                                        <option value="7L">Aerocaribbean</option>
                                                        <option value="A4">Aerocomercial Oriente Norte</option>
                                                        <option value="AJ">Aerocontractors</option>
                                                        <option value="VB">Aeroenlaces Nacionales</option>
                                                        <option value="SU">Aeroflot</option>
                                                        <option value="KG">Aerogaviota</option>
                                                        <option value="5P">Aerolinea Principal Chile</option>
                                                        <option value="AR">Aerolineas Argentinas</option>
                                                        <option value="DF">Aerolineas De Baleares</option>
                                                        <option value="3I">Aerolineas Del Sur</option>
                                                        <option value="2K">Aerolineas Galapagos</option>
                                                        <option value="N3">Aerolineas Mas</option>
                                                        <option value="AM">Aeromexico</option>
                                                        <option value="5D">Aeromexico Connect</option>
                                                        <option value="OT">Aeropelican Air Services</option>
                                                        <option value="VH">Aeropostal</option>
                                                        <option value="P5">Aerorepublica</option>
                                                        <option value="AS2">Aerostar</option>
                                                        <option value="5L">Aerosur</option>
                                                        <option value="PQ">Aerosur Paraguay</option>
                                                        <option value="6F">Afrijet Airlines</option>
                                                        <option value="8U">Afriqiyah Airways</option>
                                                        <option value="AH">Air Algerie</option>
                                                        <option value="A6">Air Alps Aviation</option>
                                                        <option value="3S">Air Antilles Express</option>
                                                        <option value="G9">Air Arabia</option>
                                                        <option value="3O">Air Arabia Maroc</option>
                                                        <option value="QN">Air Armenia</option>
                                                        <option value="I5">Air Asia</option>
                                                        <option value="D7">Air Asia X</option>
                                                        <option value="KC">Air Astana</option>
                                                        <option value="UU">Air Austral</option>
                                                        <option value="BT">Air Baltic</option>
                                                        <option value="BM">Air Bee Spa</option>
                                                        <option value="AB">Air Berlin</option>
                                                        <option value="KR">Air Bishkek</option>
                                                        <option value="PA">Air Blue</option>
                                                        
                                                        <option value="BP">Air Botswana</option>
                                                        <option value="2J">Air Burkina</option>
                                                        <option value="8Y">Air Burundi</option>
                                                        <option value="BX">Air Busan</option>
                                                        <option value="TY">Air Caledonie</option>
                                                        <option value="AC">Air Canada</option>
                                                        <option value="QK">Air Canada Jazz</option>
                                                        <option value="TX">Air Caraibes</option>
                                                        <option value="NV">Air Central</option>
                                                        <option value="CV">Air Chathams</option>
                                                        <option value="CA">Air China</option>
                                                        <option value="4F">Air City</option>
                                                        <option value="A7">Air Comet</option>
                                                        <option value="TI">Air Continental Africa</option>
                                                        <option value="EN">Air Dolomiti</option>
                                                        <option value="ED">Air Dominicana</option>
                                                        <option value="ER">Air East</option>
                                                        <option value="N5">Air Ecuador Airecu</option>
                                                        <option value="UX">Air Europa</option>
                                                        <option value="OF">Air Finland</option>
                                                        <option value="AF">Air France</option>
                                                        <option value="5I">Air G</option>
                                                        <option value="GN">Air Gabon</option>
                                                        <option value="DA">Air Georgia</option>
                                                        <option value="GL">Air Greenland</option>
                                                        <option value="NY">Air Iceland</option>
                                                        <option value="AI">Air India</option>
                                                        <option value="IX">Air India Express</option>
                                                        <option value="3H">Air Inuit</option>
                                                        <option value="I9">Air Italy</option>
                                                        <option value="NQ">Air Japan Company Ltd</option>
                                                        <option value="JS">Air Koryo</option>
                                                        <option value="DR">Air Link</option>
                                                        <option value="L8">Air Luxor Gb</option>
                                                        <option value="NX">Air Macau</option>
                                                        <option value="MD">Air Madagascar</option>
                                                        <option value="QM">Air Malawi</option>
                                                        <option value="KM">Air Malta</option>
                                                        <option value="6T">Air Mandalay</option>
                                                        <option value="CW">Air Marshall Islands</option>
                                                        <option value="MV">Air Mascareignes</option>
                                                        <option value="MR">Air Mauritanie</option>
                                                        <option value="MK">Air Mauritius</option>
                                                        <option value="6M">Air Minas Linhas Aereas</option>
                                                        <option value="MC">Air Mobility Command</option>
                                                        <option value="9U">Air Moldova</option>
                                                        <option value="SW">Air Namibia</option>
                                                        <option value="NZ">Air New Zealand</option>
                                                        <option value="7A">Air Next</option>
                                                        <option value="EL">Air Nippon</option>
                                                        <option value="EH">Air Nippon Network</option>
                                                        <option value="PX">Air Niugini</option>
                                                        <option value="4N">Air North</option>
                                                        <option value="AP">Air One</option>
                                                        <option value="CT">Air One Cityliner</option>
                                                        <option value="FJ">Air Pacific</option>
                                                        <option value="PT">Air Paradise</option>
                                                        <option value="P4">Air Peace</option>
                                                        <option value="2P">Air Philippines</option>
                                                        <option value="GZ">Air Rarotonga</option>
                                                        <option value="PJ">Air Saint Pierre</option>
                                                        <option value="6O">Air Satellite</option>
                                                        <option value="HM">Air Seychelles</option>
                                                        <option value="4D">Air Sinai</option>
                                                        <option value="GM">Air Slovakia</option>
                                                        <option value="08">Air Southwest</option>
                                                        <option value="SZ">Air Southwest</option>
                                                        <option value="SL">Air Sylhet Ag</option>
                                                        <option value="TN">Air Tahiti Nui</option>
                                                        <option value="TC">Air Tanzania</option>
                                                        <option value="TZ">Air Taxi Europe</option>
                                                        <option value="8T">Air Tindi Ltd</option>
                                                        <option value="YT">Air Togo</option>
                                                        <option value="TS">Air Transat</option>
                                                        <option value="JY">Air Turks And Caicos</option>
                                                        <option value="U7">Air Uganda</option>
                                                        <option value="3N">Air Urga</option>
                                                        <option value="DO">Air Vallee</option>
                                                        <option value="NF">Air Vanuatu</option>
                                                        <option value="UM">Air Zimbabwe</option>
                                                        <option value="AK">Airasia</option>
                                                        <option value="Z2">AirAsia Philippines</option>
                                                        <option value="SB">Aircalin</option>
                                                        <option value="KO">Aircompany Khors</option>
                                                        <option value="0K">Aircompany Kokshetau</option>
                                                        <option value="4C">Aires</option>
                                                        <option value="QP">Airkenya Aviation</option>
                                                        <option value="P2">Airkenya Express</option>
                                                        <option value="A5">Airlinair</option>
                                                        <option value="QU">Airline Utair Ukraine</option>
                                                        <option value="CG">Airlines PNG</option>
                                                        <option value="ND">Airlink</option>
                                                        <option value="TL">Airnorth Regional</option>
                                                        <option value="FL">Airtran Airways</option>
                                                        <option value="6L">Aklak Air</option>
                                                        <option value="6N">Al Naser Airlines</option>
                                                        <option value="9A">Aladia Airlines</option>
                                                        <option value="AS">Alaska Airlines</option>
                                                        <option value="J5">Alaska Seaplane Service</option>
                                                        <option value="LV">Albanian Airlines</option>
                                                        <option value="F4">Albarka Air Services Ltd</option>
                                                        <option value="D4">Alidaunia S.R.L.</option>
                                                        <option value="AZ">Alitalia</option>
                                                        <option value="NH">All Nippon Airways</option>
                                                        <option value="G4">Allegiant Air</option>
                                                        <option value="CD">Alliance Air</option>
                                                        <option value="QQ">Alliance Airlines</option>
                                                        <option value="C4">Alma De Mexico</option>
                                                        <option value="UJ">Almasria Universal Airlines</option>
                                                        <option value="AQ">Aloha Airlines</option>
                                                        <option value="N6">Alpine Air</option>
                                                        <option value="K4">Als Ltd.</option>
                                                        <option value="1A">Amadeus</option>
                                                        <option value="8X">Amadeus Eight</option>
                                                        <option value="2Y">Amadeus Pdf 2Y</option>
                                                        <option value="9S">Amadeus Pdf 9S</option>
                                                        <option value="7X">Amadeus Seven</option>
                                                        <option value="6X">Amadeus Six</option>
                                                        <option value="2X">Amadeus Two</option>
                                                        <option value="0A">Amber Air</option>
                                                        <option value="HP">America West Airlines</option>
                                                        <option value="AA">American Airlines</option>
                                                        <option value="AX">American Connection</option>
                                                        <option value="MQ">American Eagle</option>
                                                        <option value="2V">Amtrak</option>
                                                        <option value="AJ0">Anadolu Jet</option>
                                                        <option value="OY">Andes Lineas Aereas</option>
                                                        <option value="G6">Angkor Airways</option>
                                                        <option value="O4">Antrak Air</option>
                                                        <option value="7S">Arctic Transportation</option>
                                                        <option value="FG">Ariana Afghan Airlines</option>
                                                        <option value="Q9">Arik Niger</option>
                                                        <option value="K7">Arizona Express Airlines</option>
                                                        <option value="OR">Arkefly</option>
                                                        <option value="IZ">Arkia</option>
                                                        <option value="U8">Armavia</option>
                                                        <option value="R7">Aserca</option>
                                                        <option value="KJ">Asian Air</option>
                                                        <option value="6K">Asian Spirit</option>
                                                        <option value="OZ">Asiana Airlines</option>
                                                        <option value="KP">Asky</option>
                                                        <option value="5W">Astraeus</option>
                                                        <option value="TZ0">ATA Airlines</option>
                                                        <option value="7B">Atlant-Soyuz</option>
                                                        <option value="3G">Atlant-Soyuz Airlines</option>
                                                        <option value="RC">Atlantic Airways</option>
                                                        <option value="4X">Atlantic Express Airline</option>
                                                        <option value="EV">Atlantic Southeast</option>
                                                        <option value="TD">Atlantis European Airway</option>
                                                        <option value="8A">Atlas Blue</option>
                                                        <option value="KK">Atlasjet Airlines</option>
                                                        <option value="ML">Attico</option>
                                                        <option value="IP">Atyrau Aue Joly</option>
                                                        <option value="IQ">Augsburg Airways</option>
                                                        <option value="GR">Aurigny Air Services</option>
                                                        <option value="AU">Austral Lineas Aereas</option>
                                                        <option value="OS">Austrian</option>
                                                        <option value="6A">Aviacsa</option>
                                                        <option value="7U">Aviaenergo</option>
                                                        <option value="4A">Aviana Airways</option>
                                                        <option value="AV">Avianca</option>
                                                        <option value="AO">Avianova Lcc</option>
                                                        <option value="GU">Aviateca</option>
                                                        <option value="Q4">Aviation Starlink</option>
                                                        <option value="U3">Avies</option>
                                                        <option value="9V">Avior</option>
                                                        <option value="2Q">Avitrans Nordic</option>
                                                        <option value="L9">Awsaj Aviation Services</option>
                                                        <option value="1J">Axess International</option>
                                                        <option value="O8">Axis International Lines</option>
                                                        <option value="J2">Azerbaijan Airlines</option>
                                                        <option value="AD">Azul Linhas Aereas Brasileiras</option>
                                                        <option value="JA">B H Airlines</option>
                                                        <option value="CJ">BA Cityflyer</option>
                                                        <option value="TH">BA Connect</option>
                                                        <option value="UP">Bahamasair</option>
                                                        <option value="2B">Bahrain Air</option>
                                                        <option value="8Q">Baker Aviation</option>
                                                        <option value="QH">Bamboo Airways</option>
                                                        <option value="PG">Bangkok Airways</option>
                                                        <option value="8N">Barents Airlink</option>
                                                        <option value="7P">Batavia Air</option>
                                                        <option value="ID">Batik Air</option>
                                                        <option value="JV">Bearskin Airlines</option>
                                                        <option value="4T">Belair Airlines</option>
                                                        <option value="B2">Belavia</option>
                                                        <option value="LZ">Belle Air Company</option>
                                                        <option value="B3">Bellview Airlines</option>
                                                        <option value="O3">Bellview Airlines Sl</option>
                                                        <option value="CH">Bemidji Airlines</option>
                                                        <option value="A8">Benin Golf Air</option>
                                                        <option value="8E">Bering Air</option>
                                                        <option value="J8">Berjaya Air</option>
                                                        <option value="5Q">Best Aviation</option>
                                                        <option value="BG">Biman Bangladesh Airlines</option>
                                                        <option value="NT">Binter Canarias</option>
                                                        <option value="1R">Bird Information Systems</option>
                                                        <option value="5Z">Bismillah Airlines</option>
                                                        <option value="2Q3">Blekinge Flyg</option>
                                                        <option value="BV1">Blu Express</option>
                                                        <option value="0B">Blue Air</option>
                                                        <option value="SI">Blue Islands</option>
                                                        <option value="BV">Blue Panorama Airlines</option>
                                                        <option value="QW">Blue Wings</option>
                                                        <option value="KF">Blue1</option>
                                                        <option value="BD">BMI</option>
                                                        <option value="OB">Boliviana De Aviacion</option>
                                                        <option value="4B">Boutique Air</option>
                                                        <option value="FQ">Brindabella Airlines</option>
                                                        <option value="E6">Bringer Air Cargo</option>
                                                        <option value="DB">Brit Air</option>
                                                        <option value="BA">British Airways</option>
                                                        <option value="SN">Brussels Airlines</option>
                                                        <option value="BQ">Bukovyna Airlines</option>
                                                        <option value="FB">Bulgaria Air</option>
                                                        <option value="UZ">Buraq Air</option>
                                                        <option value="8B">Business Air</option>
                                                        <option value="MO">Calm Air International</option>
                                                        <option value="QC">Camair-Co</option>
                                                        <option value="K6">Cambodia Angkor Air</option>
                                                        <option value="UY">Cameroon Airlines</option>
                                                        <option value="5T">Canadian North</option>
                                                        <option value="C6">Canjet Airlines</option>
                                                        <option value="9K">Cape Air</option>
                                                        <option value="3Q">Carib Aviation Limited</option>
                                                        <option value="BW">Caribbean Airlines</option>
                                                        <option value="V3">Carpatair</option>
                                                        <option value="RV">Caspian Airlines</option>
                                                        <option value="CX">Cathay Pacific</option>
                                                        <option value="0C">Catovair</option>
                                                        <option value="KX">Cayman Airways</option>
                                                        <option value="5J">Cebu Air</option>
                                                        <option value="C2">Ceiba Intercontinental</option>
                                                        <option value="6C">Centrafrique Air Express</option>
                                                        <option value="9M">Central Mountain Air</option>
                                                        <option value="C0">Centralwings</option>
                                                        <option value="J7">Centre-Avia Airlines</option>
                                                        <option value="CE">Chalair</option>
                                                        <option value="MG">Champion Air</option>
                                                        <option value="C5">Champlain Enterprises</option>
                                                        <option value="5B">Chanchangi Airlines</option>
                                                        <option value="RP">Chautauqua Airlines</option>
                                                        <option value="CI">China Airlines</option>
                                                        <option value="CK">China Cargo Airlines</option>
                                                        <option value="MU">China Eastern Airlines</option>
                                                        <option value="G5">China Express Airlines</option>
                                                        <option value="CZ">China Southern Airlines</option>
                                                        <option value="KN">China United Airlines</option>
                                                        <option value="PN">China West Air</option>
                                                        <option value="OQ">Chongqing Airlines</option>
                                                        <option value="3A">Chu Kong Passenger Transport</option>
                                                        <option value="A2">Cielos Del Peru</option>
                                                        <option value="QI">Cimber Sterling</option>
                                                        <option value="B1">Cions Software</option>
                                                        <option value="C9">Cirrus Airlines</option>
                                                        <option value="CF">City Airline</option>
                                                        <option value="WX">CityJet</option>
                                                        <option value="QG">Citylink</option>
                                                        <option value="QA">Click Grupo Mexicana</option>
                                                        <option value="DQ">Coastal Air Transport</option>
                                                        <option value="9L">Colgan Air</option>
                                                        <option value="MN">Comair</option>
                                                        <option value="OH">Comair Inc.</option>
                                                        <option value="O5">Comores Aviation International</option>
                                                        <option value="CP">Compass Airlines</option>
                                                        <option value="DE">Condor</option>
                                                        <option value="C3">Contact Air</option>
                                                        <option value="CO">Continental Airlines</option>
                                                        <option value="PC1">Continental Airways</option>
                                                        <option value="CS">Continental Micronesia</option>
                                                        <option value="V0">Conviasa</option>
                                                        <option value="CM">Copa Airlines</option>
                                                        <option value="SS">Corsair</option>
                                                        <option value="F5">Cosmic Air</option>
                                                        <option value="N8">CR Airways Limited</option>
                                                        <option value="OU">Croatia Airlines</option>
                                                        <option value="J6">Cruiser Linhas Aereas</option>
                                                        <option value="I1">Cts Viaggi</option>
                                                        <option value="CU">Cubana De Aviacion</option>
                                                        <option value="CY">Cyprus Airways</option>
                                                        <option value="OK">Czech Airlines</option>
                                                        <option value="CQ">Czech Connect Airlines</option>
                                                        <option value="D3">Daallo Airlines</option>
                                                        <option value="N2">Dagestan Airlines</option>
                                                        <option value="H8">Dalavia</option>
                                                        <option value="9J">Dana Airlines</option>
                                                        <option value="DX">Danish Air Transport</option>
                                                        <option value="R6">Danu Oro Transportas</option>
                                                        <option value="0D">Darwin Airline</option>
                                                        <option value="F7">Darwin Airlines Sa</option>
                                                        <option value="D5">Dauair</option>
                                                        <option value="DI">DBA</option>
                                                        <option value="JD">Deer Jet</option>
                                                        <option value="DL">Delta Air Lines</option>
                                                        <option value="3D">Denim Air</option>
                                                        <option value="2A">Deutsche Bahn</option>
                                                        <option value="E3">Domodedovo Airlines</option>
                                                        <option value="7D">Donbassaero Airlines</option>
                                                        <option value="KA">Dragonair</option>
                                                        <option value="KB">Druk Air</option>
                                                        <option value="9H">Dutch Antilles Express</option>
                                                        <option value="L1">E-Savtravel</option>
                                                        <option value="H7">Eagle Air</option>
                                                        <option value="B5">East African Safari Air</option>
                                                        <option value="8C">East Star Airlines</option>
                                                        <option value="T3">Eastern Airways</option>
                                                        <option value="JI">Eastern Caribbean Airlines</option>
                                                        <option value="DK">Eastland Air</option>
                                                        <option value="U2">easyJet</option>
                                                        <option value="DS">Easyjet Switzerland</option>
                                                        <option value="1C">EDS Information Business</option>
                                                        <option value="MS">Egyptair</option>
                                                        <option value="LY">El Al Israel Airlines</option>
                                                        <option value="6S">El Sol De America</option>
                                                        <option value="1Y">Electronic Data Systems</option>
                                                        <option value="E4">Elysian Airlines</option>
                                                        <option value="EK">Emirates</option>
                                                        <option value="E0">Eos Airlines</option>
                                                        <option value="7H">Era Aviation</option>
                                                        <option value="B8">Eritrean Airlines</option>
                                                        <option value="K9">Esen Air</option>
                                                        <option value="OV">Estonian Air</option>
                                                        <option value="ET">Ethiopian Airlines</option>
                                                        <option value="EY">Etihad Airways</option>
                                                        <option value="UI">Eurocypria Airlines</option>
                                                        <option value="GJ">Eurofly</option>
                                                        <option value="4L">Euroline</option>
                                                        <option value="K2">Eurolot</option>
                                                        <option value="3W">EuroManx</option>
                                                        <option value="5O">Europe Airpost</option>
                                                        <option value="QY">European Air Transport</option>
                                                        <option value="RY">European Exec Express</option>
                                                        <option value="9F">Eurostar</option>
                                                        <option value="EW">Eurowings</option>
                                                        <option value="BR">Eva Air</option>
                                                        <option value="3Z">Everts Air</option>
                                                        <option value="OW">Executive Airlines</option>
                                                        <option value="5Y">Express Rail Link</option>
                                                        <option value="IH">Falcon Air</option>
                                                        <option value="1Z">Fantasia Info Network Lt</option>
                                                        <option value="EF">Far Eastern Air Transport</option>
                                                        <option value="F1">Farelogix</option>
                                                        <option value="F6">Faroejet</option>
                                                        <option value="QE">Fars Qeshm Airlines</option>
                                                        <option value="FX">Fedex</option>
                                                        <option value="FO">Felix Airways</option>
                                                        <option value="AY">Finnair</option>
                                                        <option value="FC">Finncomm Airlines</option>
                                                        <option value="FY">FireFly</option>
                                                        <option value="7F">First Air</option>
                                                        <option value="DP">First Choice Airways</option>
                                                        <option value="5H">Five Fourty Aviation</option>
                                                        <option value="W2">Flexflight</option>
                                                        <option value="F2">Fly Air</option>
                                                        <option value="E7">Fly European</option>
                                                        <option value="3B2">Fly Lappeenranta</option>
                                                        <option value="2Q10">Fly Smaland</option>
                                                        <option value="F3">flyadeal</option>
                                                        <option value="X1">Flyadeal</option>
                                                        <option value="F70">Flybaboo</option>
                                                        <option value="BE">Flybe</option>
                                                        <option value="FZ">Flydubai</option>
                                                        <option value="7Y">Flying Carpet</option>
                                                        <option value="TE">FlyLAL</option>
                                                        <option value="XY">Flynas</option>
                                                        <option value="LF">Flynordic</option>
                                                        <option value="5F">Flyone</option>
                                                        <option value="FA">FlySafair</option>
                                                        <option value="Q5">Forty Mile Air</option>
                                                        <option value="FP">Freedom Air</option>
                                                        <option value="SJ0">Freedom Air</option>
                                                        <option value="F8">Freedom Airlines</option>
                                                        <option value="F9">Frontier Airlines</option>
                                                        <option value="2F">Frontier Flying Service</option>
                                                        <option value="JH">Fuji Dream Airlines</option>
                                                        <option value="FH">Futura International</option>
                                                        <option value="A1">G2 Switch Works</option>
                                                        <option value="GY">Gabon Airlines</option>
                                                        <option value="1X">Gabriel</option>
                                                        <option value="GP">Gadair European Airlines</option>
                                                        <option value="7O">Galaxy Air</option>
                                                        <option value="1G">Galileo International</option>
                                                        <option value="1V">Galileo International</option>
                                                        <option value="GC">Gambia Intl Airlines</option>
                                                        <option value="GA">Garuda Indonesia</option>
                                                        <option value="4G">Gazpromavia</option>
                                                        <option value="GT">GB Airways</option>
                                                        <option value="A9">Georgian Airways</option>
                                                        <option value="ST">Germania</option>
                                                        <option value="4U">Germanwings</option>
                                                        <option value="0G">Ghadames Air Transport</option>
                                                        <option value="G0">Ghana Intl Airlines</option>
                                                        <option value="8G">Girjet</option>
                                                        <option value="GH">Globus Llc</option>
                                                        <option value="GO">Go</option>
                                                        <option value="G8">Go Airlines</option>
                                                        <option value="G7">Gojet Airlines</option>
                                                        <option value="G31">Gol</option>
                                                        <option value="DC">Golden Air</option>
                                                        <option value="2Q4">Gotlands Flyg</option>
                                                        <option value="CN">Grand China Air</option>
                                                        <option value="GV">Grant Aviation</option>
                                                        <option value="6P">Gryphon Airline</option>
                                                        <option value="GF">Gulf Air</option>
                                                        <option value="6G">Gulfstream Air Charter</option>
                                                        <option value="3M">Gulfstream International</option>
                                                        <option value="H6">Hageland Aviation</option>
                                                        <option value="H1">Hahn Air</option>
                                                        <option value="HR">Hahn Air</option>
                                                        <option value="HU">Hainan Airlines</option>
                                                        <option value="7Z">Halcyon Air Cabo Verde</option>
                                                        <option value="4R">Hamburg International</option>
                                                        <option value="2H">Hamsal Air Services</option>
                                                        <option value="HF">Hapagfly</option>
                                                        <option value="H3">Harbour Air Seaplanes</option>
                                                        <option value="HQ">Harmony Airways</option>
                                                        <option value="HA">Hawaiian Airlines</option>
                                                        <option value="BH">Hawkair</option>
                                                        <option value="H4">Heli Securite</option>
                                                        <option value="UV">Helicopteros Del Sureste</option>
                                                        <option value="JB">Helijet International</option>
                                                        <option value="9I">Helitrans As</option>
                                                        <option value="HJ">Hellas Jet</option>
                                                        <option value="HT">Hellenic Imperial Airways</option>
                                                        <option value="2L">Helvetic Airways</option>
                                                        <option value="DU">Hemus Air</option>
                                                        <option value="VD">Hennan Airlines</option>
                                                        <option value="IU">Hevi Lift</option>
                                                        <option value="EO">Hewa Bora Airways</option>
                                                        <option value="UD">Hex Air</option>
                                                        <option value="5K">Hi Fly Transportes Aereo</option>
                                                        <option value="8H">Highland Airways</option>
                                                        <option value="HD">Hokkaido International</option>
                                                        <option value="H5">Hola Airlines</option>
                                                        <option value="HB">Homer Air</option>
                                                        <option value="HX">Hong Kong Airlines</option>
                                                        <option value="UO">Hong Kong Express Airways</option>
                                                        <option value="QX">Horizon Air</option>
                                                        <option value="BN">Horizon Airlines</option>
                                                        <option value="II">IBC Airways Inc</option>
                                                        <option value="IB">Iberia</option>
                                                        <option value="I2">Iberia Express</option>
                                                        <option value="FW">Ibex Airlines</option>
                                                        <option value="V1">Ibs Software Services Americas</option>
                                                        <option value="HW0">Iceland Express</option>
                                                        <option value="FI">Icelandair</option>
                                                        <option value="V8">Iliamna Air</option>
                                                        <option value="IK">Imair Airline</option>
                                                        <option value="DH">Independence Air</option>
                                                        <option value="IC">Indian Airline</option>
                                                        <option value="6E">IndiGo</option>
                                                        <option value="IO">Indochina Airlines</option>
                                                        <option value="VP">Indochina Airlines</option>
                                                        <option value="I8">Indonesia Air Transport</option>
                                                        <option value="1F">Infini Travel Informatio</option>
                                                        <option value="7N">Inland Aviation Services</option>
                                                        <option value="7I">Insel Air International</option>
                                                        <option value="D6">Inter Air</option>
                                                        <option value="RS2">Intercontinental de Aviacion</option>
                                                        <option value="4O1">InterJet</option>
                                                        <option value="3L">Intersky Luftfahrt</option>
                                                        <option value="I4">Interstate Airlines</option>
                                                        <option value="6I">Intl Business Airlines</option>
                                                        <option value="IR">Iran Air</option>
                                                        <option value="B9">Iran Air Tours</option>
                                                        <option value="EP">Iran Aseman Airlines</option>
                                                        <option value="IA">Iraqi Airways</option>
                                                        <option value="IS">Island Airlines</option>
                                                        <option value="Q2">Island Aviation Services</option>
                                                        <option value="IF">Islas Airways</option>
                                                        <option value="6H">Israir Airlines</option>
                                                        <option value="SK3">Istanbul</option>
                                                        <option value="1U">ITA Software</option>
                                                        <option value="FS">Itali Airlines</option>
                                                        <option value="OI">Italia Tour Airlines</option>
                                                        <option value="GI">Itek Air</option>
                                                        <option value="I3">Ivoirienne De Transport</option>
                                                        <option value="4I">Izmir Havayollari</option>
                                                        <option value="JC">Jal Express</option>
                                                        <option value="JO">Jalways</option>
                                                        <option value="JM">Jambojet</option>
                                                        <option value="3X">Japan Air Commuter</option>
                                                        <option value="JL">Japan Airlines</option>
                                                        <option value="EG">Japan Asia Airways</option>
                                                        <option value="NU">Japan Transocean Air</option>
                                                        <option value="JU">Jat Airways</option>
                                                        <option value="J9">Jazeera Airways</option>
                                                        <option value="7C">Jeju Air</option>
                                                        <option value="O2">Jet Air</option>
                                                        <option value="9W">Jet Airways</option>
                                                        <option value="PP">Jet Aviation Business</option>
                                                        <option value="J4">Jet For You</option>
                                                        <option value="S2">Jet Lite</option>
                                                        <option value="LS">Jet2.Com</option>
                                                        <option value="8J">Jet4You.Com</option>
                                                        <option value="TB">Jetairfly</option>
                                                        <option value="B6">JetBlue</option>
                                                        <option value="J0">Jetlink Express</option>
                                                        <option value="JQ">Jetstar</option>
                                                        <option value="3K">Jetstar Asia</option>
                                                        <option value="GK">Jetstar Japan</option>
                                                        <option value="BL">Jetstar Pacific Airlines</option>
                                                        <option value="LJ">Jin Air</option>
                                                        <option value="3B">Job Air</option>
                                                        <option value="1Q">Joint-Stock Company.Ital</option>
                                                        <option value="R5">Jordan Aviation</option>
                                                        <option value="JR">Joy Air</option>
                                                        <option value="DV">Jsc Aircompany Scat</option>
                                                        <option value="D9">Jsc Donavia</option>
                                                        <option value="5N">Jsc Nordavia</option>
                                                        <option value="E8">Jsc Semeyavia</option>
                                                        <option value="DZ">JSC Starline</option>
                                                        <option value="2Z">JSC Voronezh Aircraft</option>
                                                        <option value="6J">Jubba Airways</option>
                                                        <option value="HO">Juneyao Airlines</option>
                                                        <option value="N9">Kabo Airlines</option>
                                                        <option value="RQ">Kam Air</option>
                                                        <option value="E2">Kampuchea Airlines</option>
                                                        <option value="5R">Karthago Airlines</option>
                                                        <option value="3Y">Kartika Airlines</option>
                                                        <option value="KT">Katmai Air Llc</option>
                                                        <option value="KV">Kavminvodyavia Airlines</option>
                                                        <option value="KD">KD Avia</option>
                                                        <option value="FK">Keewatin Air</option>
                                                        <option value="M5">Kenmore Air</option>
                                                        <option value="4K">Kenn Borek Air</option>
                                                        <option value="KQ">Kenya Airways</option>
                                                        <option value="BZ">Keystone Air Service</option>
                                                        <option value="IT">Kingfisher Airlines</option>
                                                        <option value="KL">KLM Royal Dutch Airlines</option>
                                                        <option value="7K">Kogalymavia Airlines</option>
                                                        <option value="KE">Korean Air</option>
                                                        <option value="GW">Kuban Airlines</option>
                                                        <option value="MN0">Kulula.Com</option>
                                                        <option value="KY">Kunming Airlines</option>
                                                        <option value="KU">Kuwait Airways</option>
                                                        <option value="KH">Kyrgyz Airways</option>
                                                        <option value="R8">Kyrgyzstan Airlines</option>
                                                        <option value="A0">L'Avion</option>
                                                        <option value="JF">L.A.B. Flying Service</option>
                                                        <option value="4V">Lacbravo</option>
                                                        <option value="LR">Lacsa</option>
                                                        <option value="N7">Lagun Air</option>
                                                        <option value="TM">Lam Mozambique</option>
                                                        <option value="LA">Lan Airlines</option>
                                                        <option value="UC">Lan Chile Cargo</option>
                                                        <option value="LU">Lan Express</option>
                                                        <option value="4M">Lanargentina</option>
                                                        <option value="IL">Lankair Private Limited</option>
                                                        <option value="LP">Lanperu</option>
                                                        <option value="QV">Lao Airlines</option>
                                                        <option value="QJ">Latpass Airlines</option>
                                                        <option value="NG">Lauda Air</option>
                                                        <option value="HE">LGW Luftfahrtges Walter</option>
                                                        <option value="LI">Liat</option>
                                                        <option value="LN">Libyan Airlines</option>
                                                        <option value="QL">Linea Aerea De Servicio</option>
                                                        <option value="JT">Lion Airlines</option>
                                                        <option value="LM">Livingston</option>
                                                        <option value="LB">Lloyd Aereo Boliviano</option>
                                                        <option value="LO">LOT Polish Airlines</option>
                                                        <option value="LT">LTU Intl Airways</option>
                                                        <option value="8L">Lucky Air</option>
                                                        <option value="LH">Lufthansa</option>
                                                        <option value="CL">Lufthansa Cityline</option>
                                                        <option value="L5">Lufttransport As</option>
                                                        <option value="L7">Lugansk Airlines</option>
                                                        <option value="LG">Luxair</option>
                                                        <option value="5V">Lviv Airlines</option>
                                                        <option value="L3">Lynx Aviation</option>
                                                        <option value="R0">M/S Royal Airlines</option>
                                                        <option value="CC">Macair Airlines</option>
                                                        <option value="VM">Macair Jet S.A.</option>
                                                        <option value="IN">Macedonian Airlines</option>
                                                        <option value="M2">Mahfooz Aviation</option>
                                                        <option value="MH">Malaysia Airlines</option>
                                                        <option value="MA">Malev Hungarian Airlines</option>
                                                        <option value="OD">Malindo Air</option>
                                                        <option value="TF">Malmo Aviation</option>
                                                        <option value="RI">Mandala Airlines</option>
                                                        <option value="AE">Mandarin Airlines</option>
                                                        <option value="JE0">Mango</option>
                                                        <option value="NM">Manx2</option>
                                                        <option value="6V">Mars RK</option>
                                                        <option value="M7">Marsland Aviation</option>
                                                        <option value="MP">Martinair</option>
                                                        <option value="L4">Max Aviation</option>
                                                        <option value="MY">Maya Island Air</option>
                                                        <option value="7M">Mayair</option>
                                                        <option value="9Y">Mdlr Airlines</option>
                                                        <option value="VL">Med View Airlines</option>
                                                        <option value="IM">Menajet Lebanon</option>
                                                        <option value="IG">Meridiana</option>
                                                        <option value="MZ">Merpati</option>
                                                        <option value="MX">Mexicana</option>
                                                        <option value="I6">Mexicana Link</option>
                                                        <option value="OM">Miat Mongolian Airlines</option>
                                                        <option value="ME">Middle East Airlines</option>
                                                        <option value="AL">Midwest Connect</option>
                                                        <option value="MJ">Mihin Lanka</option>
                                                        <option value="6R">Mirny Air</option>
                                                        <option value="MB">MNG Airlines Cargo</option>
                                                        <option value="MW">Mokulele Flight Service</option>
                                                        <option value="2M">Moldavian Airlines</option>
                                                        <option value="5M">Montserrat Airways</option>
                                                        <option value="3R">Moskovia Airlines Jsc</option>
                                                        <option value="M9">Motor-Sich JSC</option>
                                                        <option value="3E">Multi Aero</option>
                                                        <option value="8I">MyAir.Com</option>
                                                        <option value="8M">Myanmar Airways Intl</option>
                                                        <option value="C8">Nacair Lp</option>
                                                        <option value="T2">Nakina Air Service</option>
                                                        <option value="UE">Nasair</option>
                                                        <option value="9O">National Airways Cameroon</option>
                                                        <option value="NC">National Jet Systems</option>
                                                        <option value="5C">Nature Air</option>
                                                        <option value="1N">Navitaire New Skies</option>
                                                        <option value="1L">Navitaire Open Skies</option>
                                                        <option value="M4">Nayzak Air Transport</option>
                                                        <option value="NO">Neos Spa</option>
                                                        <option value="9X">New Axis Airways</option>
                                                        <option value="EJ">New England Airlines</option>
                                                        <option value="2N">Nextjet</option>
                                                        <option value="JX">Nice Helicopteres</option>
                                                        <option value="HG">Niki</option>
                                                        <option value="NP">Nile Air</option>
                                                        <option value="DD">Nok Air</option>
                                                        <option value="NA">North American Airlines</option>
                                                        <option value="M3">North Flying As</option>
                                                        <option value="HW">North Wright Air</option>
                                                        <option value="NS">Northeastern Airlines</option>
                                                        <option value="NW">Northwest Airlines</option>
                                                        <option value="2G">Northwest Seaplanes</option>
                                                        <option value="J3">Northwestern Air Lease</option>
                                                        <option value="D8">Norwegian Air</option>
                                                        <option value="DY">Norwegian Air Shuttle</option>
                                                        <option value="BJ">Nouvelair</option>
                                                        <option value="O9">Nova Airlines</option>
                                                        <option value="S8">Nyasa Express</option>
                                                        <option value="CR">Oag Worldwide</option>
                                                        <option value="O6">Oceanair Linhas Aereas</option>
                                                        <option value="BK">Okay Airways</option>
                                                        <option value="OL">Olt Ostfriesische Lufttr</option>
                                                        <option value="OA">OLYMPIC AIR</option>
                                                        <option value="OP">Olympic Airlines</option>
                                                        <option value="WY">Oman Air</option>
                                                        <option value="OG">One Two Go Airlines</option>
                                                        <option value="T6">Onetime Airline</option>
                                                        <option value="EC">Openskies</option>
                                                        <option value="EA">Operador Aereo Andalus</option>
                                                        <option value="RF">Ord Air Charter Pty Ltd</option>
                                                        <option value="R2">Orenair</option>
                                                        <option value="OX">Orient Thai Airlines</option>
                                                        <option value="OC">Oriental Air Bridge</option>
                                                        <option value="2Q12">Ostersunds Flyg</option>
                                                        <option value="ON">Our Airline</option>
                                                        <option value="OJ">Overland Airways</option>
                                                        <option value="O7">Ozjet</option>
                                                        <option value="3F">Pacific Airways</option>
                                                        <option value="8P">Pacific Coastal Airlines</option>
                                                        <option value="LW">Pacific Wings</option>
                                                        <option value="PK">Pakistan International</option>
                                                        <option value="0P">Palau Asia Pacific Air</option>
                                                        <option value="9P">Palau National Airlines</option>
                                                        <option value="PF">Palestinian Airlines</option>
                                                        <option value="NR">Pamir Air</option>
                                                        <option value="PM">Pamir Air</option>
                                                        <option value="E9">Pan Am Clipper Conx</option>
                                                        <option value="7Q">Pan Am World Airways Dominicana</option>
                                                        <option value="P8">Pantanal Linhas Aereas</option>
                                                        <option value="HI">Papillon Airways</option>
                                                        <option value="I7">Paramount Airways</option>
                                                        <option value="P6">Pascan Aviation Inc</option>
                                                        <option value="P3">Passaredo</option>
                                                        <option value="9Q">PB Air</option>
                                                        <option value="H9">Pegasus Airlines</option>
                                                        <option value="PC">Pegasus Airlines</option>
                                                        <option value="7V">Pelican Air Service</option>
                                                        <option value="KS">Penair</option>
                                                        <option value="P9">Peruvian Air Lines</option>
                                                        <option value="PR">Philippine Airlines</option>
                                                        <option value="PE">Phoebus Apollo Aviation</option>
                                                        <option value="1O">Phoenix Systems Dertours</option>
                                                        <option value="9E">Pinnacle Airlines</option>
                                                        <option value="PU">Pluna</option>
                                                        <option value="U4">PMT Air</option>
                                                        <option value="PD">Porter Airlines</option>
                                                        <option value="NI">Portugalia</option>
                                                        <option value="PW">Precision Air</option>
                                                        <option value="FE">Primaris Airlines</option>
                                                        <option value="GX">Primera Air</option>
                                                        <option value="8W">Private Wings</option>
                                                        <option value="P0">Proflight Commuter</option>
                                                        <option value="HN">Proteus Helicopteres</option>
                                                        <option value="PB">Provincial Airlines</option>
                                                        <option value="QZ">PT Indonesia Airasia</option>
                                                        <option value="SJ">Pt Sriwijaya Air</option>
                                                        <option value="P1">Publiccharters.Com</option>
                                                        <option value="EB">Pullmantur Air</option>
                                                        <option value="QF">Qantas Airways</option>
                                                        <option value="QR">Qatar Airways</option>
                                                        <option value="QO">Quantum Air</option>
                                                        <option value="Q0">Quebecair Express</option>
                                                        <option value="1D">Raddix Solutions Intl</option>
                                                        <option value="01">RailEasy</option>
                                                        <option value="RT">Rak Airways</option>
                                                        <option value="2O">Redemption</option>
                                                        <option value="RK">Region Airline</option>
                                                        <option value="FN">Regional Air Lines</option>
                                                        <option value="QT">Regional Pacific</option>
                                                        <option value="3C">Regionsair</option>
                                                        <option value="RW">Republic Airlines</option>
                                                        <option value="C7">Rla Rico Linhas Areas</option>
                                                        <option value="RH">Robin Hood Aviation</option>
                                                        <option value="FV">Rossiya-Russian Airlines</option>
                                                        <option value="RR">Royal Air Force</option>
                                                        <option value="AT">Royal Air Maroc</option>
                                                        <option value="BI">Royal Brunei</option>
                                                        <option value="6D">Royal Daisy Airlines</option>
                                                        <option value="RL">Royal Falcon</option>
                                                        <option value="RJ">Royal Jordanian</option>
                                                        <option value="RA">Royal Nepal Airlines</option>
                                                        <option value="7R">Rusline</option>
                                                        <option value="RM">Rutaca</option>
                                                        <option value="AW">Rutas Aereas De Venezuela Rav</option>
                                                        <option value="WB">RwandAir</option>
                                                        <option value="RD">Ryan International</option>
                                                        <option value="FR">Ryanair</option>
                                                        <option value="S7">S7 Airlines</option>
                                                        <option value="1S">Sabre</option>
                                                        <option value="1W">Sabre</option>
                                                        <option value="4Q">Safi Airways</option>
                                                        <option value="PV">Saint Barth Commuter</option>
                                                        <option value="S6">Salmon Air</option>
                                                        <option value="MM">Sam</option>
                                                        <option value="E5">Samara Airlines</option>
                                                        <option value="RZ">Sansa</option>
                                                        <option value="S3">Santa Barbara Airlines</option>
                                                        <option value="6W">Saratov Airlines</option>
                                                        <option value="BU">SAS Norway</option>
                                                        <option value="HZ">SAT Airlines</option>
                                                        <option value="SP">SATA Air Acores</option>
                                                        <option value="S4">SATA International</option>
                                                        <option value="9N">Satena</option>
                                                        <option value="9R">Satena</option>
                                                        <option value="SV">Saudi Arabian Airlines</option>
                                                        <option value="SK">Scandinavian Airlines</option>
                                                        <option value="TR">Scoot</option>
                                                        <option value="CB">Scotairways</option>
                                                        <option value="BB">Seaborne Airlines</option>
                                                        <option value="DN">Senegal Airlines</option>
                                                        <option value="8D">Servant Air</option>
                                                        <option value="5S">Servicios Aereos Profes</option>
                                                        <option value="UG">Sevenair</option>
                                                        <option value="D2">Severstal Aircompany</option>
                                                        <option value="NL">Shaheen Air Intl</option>
                                                        <option value="6Q">Sham Wings Airlines</option>
                                                        <option value="SC">Shandong Airlines</option>
                                                        <option value="FM">Shanghai Airlines</option>
                                                        <option value="SH">Sharp Aviation</option>
                                                        <option value="ZH">Shenzhen Air</option>
                                                        <option value="8S">Shun Tak China</option>
                                                        <option value="S5">Shuttle America</option>
                                                        <option value="5E">Siam Ga</option>
                                                        <option value="3U">Sichuan Airlines</option>
                                                        <option value="FT">Siem Reap Airways Intl</option>
                                                        <option value="MI">Silkair</option>
                                                        <option value="SQ">Singapore Airlines</option>
                                                        <option value="1M">Sirena Jsc</option>
                                                        <option value="1H">Sirena-Travel</option>
                                                        <option value="JW">Skippers Aviation</option>
                                                        <option value="S9">Sky Air World</option>
                                                        <option value="H2">Sky Airline</option>
                                                        <option value="GQ">Sky Express</option>
                                                        <option value="QB">Sky Georgia</option>
                                                        <option value="UQ">Sky Jet Aviation</option>
                                                        <option value="Q7">Skybahamas Airlines</option>
                                                        <option value="SX">Skybus Airlines</option>
                                                        <option value="NE">Skyeurope Airlines</option>
                                                        <option value="BC">Skymark Airlines</option>
                                                        <option value="LQ">Skynet Asia Airways</option>
                                                        <option value="5G">Skyservice Airlines</option>
                                                        <option value="Q6">Skytrans Regional</option>
                                                        <option value="JZ">Skyways Ab</option>
                                                        <option value="OO">Skywest Airlines</option>
                                                        <option value="S0">Slok Air International</option>
                                                        <option value="QS">Smart Wings</option>
                                                        <option value="6Y">Smartlynx Airlines</option>
                                                        <option value="2E">Smokey Bay Air</option>
                                                        <option value="2C">SNCF</option>
                                                        <option value="8R">Sol Lineas Aereas</option>
                                                        <option value="IE">Solomon Airlines</option>
                                                        <option value="4J">Somon Air</option>
                                                        <option value="SO">Sosoliso Airlines</option>
                                                        <option value="4Z">South African Airlink</option>
                                                        <option value="SA">South African Airways</option>
                                                        <option value="DG">South East Asian Airline</option>
                                                        <option value="G2">South East European Air</option>
                                                        <option value="PL">Southern Air Charter</option>
                                                        <option value="WN">Southwest Airlines</option>
                                                        <option value="JK">Spanair</option>
                                                        <option value="SG">Spicejet</option>
                                                        <option value="NK">Spirit Airlines</option>
                                                        <option value="SM">Spirit Of Manila Airlines</option>
                                                        <option value="9C">Spring Airlines</option>
                                                        <option value="IJ">Spring Airlines Japan</option>
                                                        <option value="Q1">Sqiva Sistem</option>
                                                        <option value="UL">Srilankan Airlines</option>
                                                        <option value="0O">STA Travel</option>
                                                        <option value="4S">Star Airways</option>
                                                        <option value="2S">Star Equatorial Airlines</option>
                                                        <option value="7G">Star Flyer</option>
                                                        <option value="2I">Star Peru</option>
                                                        <option value="V9">Star1 Airlines</option>
                                                        <option value="1T">Start</option>
                                                        <option value="NB">Sterling</option>
                                                        <option value="DM">Sterling Blue</option>
                                                        <option value="8F">STP Airways</option>
                                                        <option value="SD">Sudan Airways</option>
                                                        <option value="RN">Sum Air Services</option>
                                                        <option value="EZ">Sun Air of Scandinavia</option>
                                                        <option value="SY">Sun Country</option>
                                                        <option value="2U">Sun D'Or Intl Airlines</option>
                                                        <option value="2Q8">Sundsvalls Flyg</option>
                                                        <option value="PY">Surinam Airways</option>
                                                        <option value="1K">Sutra Inc.</option>
                                                        <option value="HS">Svenska Direktflyg Ab</option>
                                                        <option value="LX">Swiss</option>
                                                        <option value="7E">Sylt Air Gmbh</option>
                                                        <option value="RB">Syrian Arab Airlines</option>
                                                        <option value="PI">Syrian Pearl Airlines</option>
                                                        <option value="DT">Taag</option>
                                                        <option value="HH">Taban Airlines</option>
                                                        <option value="TA">Taca Intl Airlines</option>
                                                        <option value="T0">Taca Peru</option>
                                                        <option value="VR">Tacv Cabo Verde Airlines</option>
                                                        <option value="R9">Taf Linhas Aereas</option>
                                                        <option value="7J">Tajik Air</option>
                                                        <option value="L6">Tam Air</option>
                                                        <option value="JJ">Tam Linhas Aereas</option>
                                                        <option value="PZ">Tam Mercosur</option>
                                                        <option value="EQ">Tame</option>
                                                        <option value="4E">Tanana Air Service</option>
                                                        <option value="TQ">Tandem Aero</option>
                                                        <option value="TP">TAP Portugal</option>
                                                        <option value="K3">Taquan Air Services</option>
                                                        <option value="RO">Tarom</option>
                                                        <option value="SF">Tassili Airlines</option>
                                                        <option value="U9">Tatarstan Air</option>
                                                        <option value="RU">TCI Skyking Ltd</option>
                                                        <option value="C1">Tectimes Sudamerica</option>
                                                        <option value="FD">Thai Airasia</option>
                                                        <option value="TG">Thai Airways Intl</option>
                                                        <option value="WE">THAI Smile Airways</option>
                                                        <option value="T9">Thai Star Airlines</option>
                                                        <option value="MT">Thomas Cook Airlines</option>
                                                        <option value="BY">Thomsonfly</option>
                                                        <option value="GS">Tianjin Airlines</option>
                                                        <option value="3P">Tiara Air</option>
                                                        <option value="TV">Tibet Airlines</option>
                                                        <option value="TT">Tiger Airways Australia</option>
                                                        <option value="T1">Tik Systems</option>
                                                        <option value="3V">TNT Airways</option>
                                                        <option value="9D">Toumai Air Tchad</option>
                                                        <option value="TJ">Tradewind Aviation</option>
                                                        <option value="N4">Trans Air Benin</option>
                                                        <option value="Q8">Trans Air Congo</option>
                                                        <option value="M8">Trans Maldivian Airways</option>
                                                        <option value="7T">Trans North Aviation</option>
                                                        <option value="UN">Transaero Airlines</option>
                                                        <option value="GE">Transasia Airways</option>
                                                        <option value="HV">Transavia Airlines</option>
                                                        <option value="PH">Transavia Denmark</option>
                                                        <option value="TO">Transavia France</option>
                                                        <option value="1E">Travelsky Technology Ltd</option>
                                                        <option value="9T">Travelspan Gt Inc</option>
                                                        <option value="T4">Trip</option>
                                                        <option value="2T">TruJet</option>
                                                        <option value="6B">TUIFly Nordic</option>
                                                        <option value="JE">Tulca</option>
                                                        <option value="R4">Tulpar Avia Service</option>
                                                        <option value="TU">Tunisair</option>
                                                        <option value="3T">Turan Air</option>
                                                        <option value="TK">Turkish Airlines</option>
                                                        <option value="T5">Turkmenistan Airlines</option>
                                                        <option value="T7">Twin Jet</option>
                                                        <option value="VO">Tyrolean Airways</option>
                                                        <option value="UR">Uganda Airlines</option>
                                                        <option value="PS">Ukraine Intl Airlines</option>
                                                        <option value="UF">UM Air</option>
                                                        <option value="B7">UNI Airways</option>
                                                        <option value="UA">United Airlines</option>
                                                        <option value="4H">United Airways Bangladesh</option>
                                                        <option value="EU">United Eagle Airlines</option>
                                                        <option value="UB">United Myanmar Air</option>
                                                        <option value="UW">Universal Airlines</option>
                                                        <option value="U6">Ural Airlines</option>
                                                        <option value="US">US Airways</option>
                                                        <option value="UH">US Helicopter Corp</option>
                                                        <option value="BS">US-Bangla Airlines</option>
                                                        <option value="U5">USA 3000</option>
                                                        <option value="UT">Utair Aviation JSC</option>
                                                        <option value="HY">Uzbekistan Airways</option>
                                                        <option value="VA">V Australia</option>
                                                        <option value="V4">Valair</option>
                                                        <option value="VF">Valuair</option>
                                                        <option value="6Z">Van Air Europe</option>
                                                        <option value="2R">Via Rail Canada Inc</option>
                                                        <option value="U1">Videcom International</option>
                                                        <option value="VJ">VietJetAir</option>
                                                        <option value="0V">Vietnam Air Service</option>
                                                        <option value="VN">Vietnam Airlines</option>
                                                        <option value="4P">Viking Airlines</option>
                                                        <option value="VQ">Viking Hellas Airlines</option>
                                                        <option value="NN">VIM Airlines</option>
                                                        <option value="BF">Vincent Aviation</option>
                                                        <option value="V5">Vip Wings</option>
                                                        <option value="VX">Virgin America</option>
                                                        <option value="VS">Virgin Atlantic</option>
                                                        <option value="DJ">Virgin Australia</option>
                                                        <option value="VK">Virgin Nigeria</option>
                                                        <option value="V2">Vision Airlines</option>
                                                        <option value="UK">Vistara</option>
                                                        <option value="VB1">ViVa Aerobus</option>
                                                        <option value="VG">VLM Airlines</option>
                                                        <option value="VE">Volare Spa</option>
                                                        <option value="Y4">Volaris</option>
                                                        <option value="VI">Volga Dnepr Airline</option>
                                                        <option value="V7">Volotea Airlines</option>
                                                        <option value="VC">Voyageur Airways</option>
                                                        <option value="G3">Vrg Linhas Aereas</option>
                                                        <option value="RG">VRG Linhas Aereas Sa</option>
                                                        <option value="VY">Vueling</option>
                                                        <option value="V6">Vuelos Internos Privados</option>
                                                        <option value="0W">W Caribbean Costa Rica</option>
                                                        <option value="4W">Warbelows Air Ventures</option>
                                                        <option value="KW">Wataniya Airways</option>
                                                        <option value="2W">Welcome Air</option>
                                                        <option value="8O">West Coast Air</option>
                                                        <option value="WS">WestJet</option>
                                                        <option value="WF">Wideroes Flyveselskap</option>
                                                        <option value="IV">Wind Jet</option>
                                                        <option value="7W">Wind Rose Aviation</option>
                                                        <option value="IW">Wings Air</option>
                                                        <option value="K5">Wings Of Alaska</option>
                                                        <option value="2Q11">Wings of Bornholm</option>
                                                        <option value="W6">Wizzair</option>
                                                        <option value="1P">Worldspan</option>
                                                        <option value="WW">WOW Air</option>
                                                        <option value="8V">Wright Air Services</option>
                                                        <option value="MF">Xiamen Airlines</option>
                                                        <option value="JN">XL Airways</option>
                                                        <option value="SE">XL Airways France</option>
                                                        <option value="R3">Yakutia Air Company</option>
                                                        <option value="HK">Yangon Airways Limited</option>
                                                        <option value="IY">Yemenia Yemen Airways</option>
                                                        <option value="OE">Yeongnam Air</option>
                                                        <option value="0Y">Yeti Airlines</option>
                                                        <option value="4Y">Yute Air Alaska</option>
                                                        <option value="K8">Zambia Skyways</option>
                                                        <option value="Q3">Zambian Airways</option>
                                                        <option value="B4">Zanair</option>
                                                        <option value="3J">Zip Air</option>
                                </select>
                            </Col>
                            <Col md={3}>
                                <label>Flight Number (Optional)</label>
                                <input type="text" placeholder="Flight Number"  name="airline_number" ></input>
                            </Col>
                        </Row>
                
                </div>
            </div>

            <Row>
                <Col lg={8}>
                <div className={styles.cardInfo}>
                <h2>Card Info</h2>
                <div className={styles.cardDiv}>
                    <Row>
                        <Col md={6}>
                            <label>Card Type</label>
                            <select placeholder="card type" autoComplete='off' name="card_type">
                            <option value="" disabled>Select</option>
                                    {
                                        paymentData.card_type_json.map((cardDet) => (
                                            <option value={cardDet.value} >{cardDet.label}</option>
                                       
                                        ))
                                    }
                            </select>
                        </Col>
                        <Col md={6}>
                            <label>Payment Network</label>
                            <select placeholder="Payment Network" autoComplete='off' name="payment_network">
                                    <option value="" disabled>Select</option>
                                    {
                                        paymentData.payment_network_json.map((paymentDet) =>(
                                            <option value={paymentDet.value} >{paymentDet.label}</option>
                                       
                                        ))
                                    }                         
                            </select>
                        </Col>
                        <Col md={6}>
                            <label>Card Number</label>
                            <input type="text" placeholder="Card Number"  name="card_number"></input>
                        </Col>
                        <Col md={6}>
                            <label>Card Holder Name</label>
                            <input type="text" placeholder="Card Holder Name" name="card_holder_name"></input>
                        </Col>
                        <Col md={4}>
                            <label>Expiry Date</label>
                            <select  autoComplete='off' name="expiry_date">
                            <option value="">Years</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2031">2031</option>
                            <option value="2032">2032</option>
                            <option value="2033">2033</option>
                            </select>
                        </Col>
                        <Col md={4}>
                            <label>Months</label>
                            <select placeholder="Months" autoComplete='off' name="month">
                            <option value="">Months</option>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            </select>
                        </Col>
                        <Col md={4}>
                            <label>CVV/CVC</label>
                            <input type="text" placeholder="Enter CVV/CVC"  autoComplete='off' name="card_secret"></input>
                        </Col>
                    </Row>
                </div>
            </div>
                </Col>
                <Col lg={4}>
                    <div className={styles.fareSummary}>
                        <h2>Payment Summary</h2>

                        <div className={styles.paymentDiv}>
                            <Row>
                                <Col xs={7}>
                                    <p className={styles.leftText}>Rental Cost</p>
                                </Col>
                                <Col xs={5}>
                                <p className={styles.rightText}>USD {carDetail.fees.rateTotalAmount}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={7}>
                                    <p className={styles.leftText}>Extras</p>
                                </Col>
                                <Col xs={5}>
                                <p className={styles.rightText}>USD {parseFloat(extraAmount.toFixed(2))}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={7}>
                                    <p className={styles.leftText}>At Rental Desk</p>
                                </Col>
                                <Col xs={5}>
                                <p className={styles.rightText}>USD {carDetail.fees.remainingAmount}</p>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.fareDiv}>
                            <Row>
                                <Col xs={7}>
                                    <p className={styles.leftText}>Pay Now</p>
                                </Col>
                                <Col xs={5}>
                                <p className={styles.rightText}>USD {parseFloat(totalAmount).toFixed(2)}</p>
                                </Col>
                            </Row>
                        </div>

                        <div>
                        <label className={styles.checkLabel}>
                            <input type="checkbox"/> Remember me on this device
                                        
                        </label>
                        </div>
                        <div>
                        <label className={styles.checkLabel}>
                            <input type="checkbox"/> Subscribe me to the promotional emails
                                        
                        </label>
                        </div>
                        <div>
                        <label className={styles.checkLabel}>
                            <input type="checkbox"/> I confirm that I have read, understood and agree with the Booking Engine Terms & Conditions provided
                                        
                        </label>
                        </div>

                        <button className={styles.bookBtn}>Proceed to Book</button>
                    </div>
                </Col>
            </Row>

            </Form>
        </div>
        </div>
        <Footer/>
       </>
    )
    

}


export default CarDetails;