
import React from 'react';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Results from './components/Flight/Results';
import FlightDetails from './components/Flight/FlightDetails';
import Voucher from './components/Flight/Voucher';
import PreviewBooking from './components/Flight/PreviewBooking';
import HotelList from './components/Hotel/HotelList';
import HotelDetails from './components/Hotel/HotelDetails';
import HotelCheckout from './components/Hotel/HotelCheckout';
import CarList from './components/Car/CarList';
import CarDetails from './components/Car/CarDetails';
import Bookings from './components/Pages/Bookings';
import ManageBookings from './components/Pages/ManageBookings';
import Settings from './components/Pages/Settings';
import Header from './components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import HotelVoucher from './components/Hotel/HotelVoucher';
import PrivacyPolicy from './components/CMS/PrivacyPolicy';
import TermsConditions from './components/CMS/TermsConditions';
import Contactus from './components/CMS/Contactus';
import Faq from './components/CMS/Faq';
import PaymentSecurity from './components/CMS/PaymentSecurity';
import Aboutus from './components/CMS/Aboutus';
import MyhotelVoucher from './components/Pages/MyhotelVoucher';
function App() {
  return(
    <>
    <BrowserRouter>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route exact path="/filghtResult" element={<Results />}/>
        <Route exact path="/flightDetails" element={<FlightDetails />}/>
        <Route exact path="/previewBooking" element={<PreviewBooking />}/>
        <Route exact path="/voucher" element={<Voucher />}/>
        <Route exact path="/hotelResult" element={<HotelList />}/>
        <Route exact path="/hotelDetails" element={<HotelDetails />}/>
        <Route exact path="/hotelCheckout" element={<HotelCheckout />}/>
        <Route exact path="/hotelVoucher" element={<HotelVoucher />}/>
        <Route exact path='/bookings'  element={<Bookings />} />
        <Route exact path='/myhotelVoucher'  element={<MyhotelVoucher />} />
        <Route exact path='/manage-bookings'  element={<ManageBookings />} />
        <Route exact path='/settings'  element={<Settings />} />
        <Route exact path="/CarList" element={<CarList />}/>
        <Route exact path="/car-details/:id" element={<CarDetails />}/>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route exact path="/terms-conditions" element={<TermsConditions />}/>
        <Route exact path="/about" element={<Aboutus />}/>
        <Route exact path="/contact" element={<Contactus />}/>
        <Route exact path="/faq" element={<Faq />}/>
        <Route exact path="/payment" element={<PaymentSecurity />}/>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
