import React, {useState, useEffect} from 'react'
import axios from "axios";
import CmsPage from './CmsPage'

function PaymentSecurity() {
  const [data, setData] = useState('');
  const fetchDest = async () => {
    let url = 'https://groupy.live/reactApp/customer/getcms'
    let json ={
      "content_heading": "Payment Security"
    }
      const res = await axios.post(url,JSON.stringify(json));
      console.log(res.data)
      const result = res.data;
      setData(result)
    }
    useEffect(()=>{
      fetchDest()
  },[])
  return (
    <div>
      <CmsPage data={data} />
    </div>
  )
}
export default PaymentSecurity
