import React, { useState } from 'react';
import { useFilterContext } from '../../context/filterContext';
function ArrivalOnwardDuration(props) {
  const destTo = props.destTo
  // const [active, setActive] = useState(null);
  const { navigateArrival, active } = useFilterContext()

  const [dataTabs, setDataTabs] = useState([
    {
      id: 5,
      tabTitle: "Before 6AM",
      imgSrc: "/assets/images/early-morning.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isArriveEarlyMorning"
    },
    {
      id: 6,
      tabTitle: "6AM - 12PM",
      imgSrc: "/assets/images/day.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isArriveDay"
    },
    {
      id: 7,
      tabTitle: "12PM - 6PM",
      imgSrc: "/assets/images/evening.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isArriveEvening"
    },
    {
      id: 8,
      tabTitle: "After 6PM",
      imgSrc: "/assets/images/night.png",
      tabClass: "myCustomClass",
      tabClicked: false,
      tabProperty: "isArriveNight"
    }
  ]);

  const NavLink = ({ id, tabTitle, tabProperty, imgSrc, isActive, onClick }) => {
    return (
      <div onClick={() => navigateArrival({ id, tabTitle, tabProperty, imgSrc, isActive })} className={isActive ? "active" : ""}>
        <img src={imgSrc} alt="Layover" />
        <label>{tabTitle}</label>
      </div>
    );
  };

  // const navigate = (id) => {
  //   setActive(id);
  // };

  return (
    <>
      <div className="journey-duration-sec">
        <h6>Arrival at {destTo.City}</h6>
        <ul className="travel-ul">
          {dataTabs.map((item) => (
            <li key={item.id}>
              <NavLink {...item} isActive={active === item.id} onClick={navigateArrival} />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default ArrivalOnwardDuration
