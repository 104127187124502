import React, { useEffect, useState } from 'react'
import {  Row, Col, Button, } from "react-bootstrap";
import { FaHotel, FaLocationArrow, FaMap,  } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../Hotel/Css/HotelResult.css'
import { useHotelState } from '../../context/hotelContext';
import Spineer from '../Flight/Spineer';
import Facilities from './Facilities';
import Rating from './Rating';
import { useNavigate } from 'react-router-dom';
function HotelResults({ inputs }) {
  const { getHotelSearch, hotelResults, loading,session_id, isError } = useHotelState();
  const navigate = useNavigate();
  //console.log("hotelof results",)
  useEffect(() => {
    getHotelsRusults()
  },[inputs])
  
  const getHotelsRusults = async () => {
    let json = {
      // "user_id": "TrawexAPI2023",
      // "user_password": "TrawexAPI2023PWD",
      // "access": "Test",
      // "ip_address": "49.36.177.39",
      "checkin": dateFormatter(inputs.checkinDate),
      "checkout": dateFormatter(inputs.checkoutDate),
      "city_name": inputs.hotelCity.city,
      "country_name": inputs.hotelCity.country,
      "requiredCurrency": "INR",
      "maxResult" : 20,
      "occupancy": inputs.rooms
    }
    console.log("hotel search request",JSON.stringify(json))
    await getHotelSearch(json);
  }

  function dateFormatter(dateString) {
    if (dateString) {
      let localTime = dateString.getTime();
      let localOffset = dateString.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let offset = 5.5;
      let bombay = utc + (3600000 * offset);
      let nd = new Date(bombay);
      return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate())).slice(-2));
    } else {
      return null;
    }
  }

  function getReviewDetail(rate) {
    if (parseFloat(rate) > 4) {
      return "Excellent";
    } else if (parseFloat(rate) > 3) {
      return "Very Good";
    } else if (parseFloat(rate) > 2) {
      return "Average";
    } else if (parseFloat(rate) > 1) {
      return "Worst";
    } else return "Terible";
  }

  const gotoDetail = (results)=>{
    navigate("/hotelDetails", {
      state: {
        hotelResults:results,
        session_id:session_id,
        inputs:inputs
      }
    })
  }
 
  return (
    <> 



    {/* <p> {hotelResults && hotelResults.status.totalResults} </p> */}
      {loading ? (<Spineer />) : (<div>
        {hotelResults && hotelResults.itineraries.map((results, index) => (
          <div className="hotel-row" key={index} >
            <Row className="mt-0 mt-md-5">
              <Col md={4}>
                <div className="hotel-image">
                  <img src={results.thumbNailUrl} onError={(e) => {e.target.src = '/assets/images/hotel.png'}} alt="" />
                </div>
              </Col>
              <Col md={5}>
                <div className="hotel-box text-start">
                  <h5>{results.hotelName}</h5>
                  <div className="hotel-rating">
                    <Rating rating={results.hotelRating} />
                  </div>
                  <div className="hotel-location">
                    <FaLocationArrow />
                    <span>{results.city} {results.country}</span>
                    <Link>View on map</Link>
                    <div className="w-100">
                      <FaMap /> (from cneter {results.distanceValue} km)
                    </div>
                    <div className="w-100">
                      <FaHotel /> {results.propertyType}
                    </div>
                  </div>
                  <div className="hotel-list">
                    <Facilities facilities={results.facilities}  />
                  </div>
                  <div className="hotel-refund">
                    <span>{results.fareType}</span>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="hotel-payment-card">
                  <div className="compare-hotel">
                    <span>Add to Compare</span>
                  </div>
                  {results.tripAdvisorReview&&<div className="hotel-rating-div">
                    <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-34880-5.svg"></img>
                    <label className="w-100"> {getReviewDetail(results.tripAdvisorRating)}</label>
                    <Link>{results.tripAdvisorReview} Reviews</Link>
                  </div>}
                  <div className="hotel-total">
                    <h4>{results.currency} {results.total}</h4>
                    <label className="w-100">per night</label>
                    <Button className="choose-btn" onClick={()=> gotoDetail(results)} >Choose Room</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
      )}

    </>
   
  )
}

export default HotelResults
