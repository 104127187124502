import React from 'react';
import '../../App.css';
import HeroSection from '../Home/HeroSection';
import BookingFeatures from '../Home/BookingFeatures';
import FlightSection from '../Home/FlightSection';
import Newsletter from '../Home/Newsletter';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function Home() {
  return (
    <>
      {/* <Header/> */}
      <HeroSection/>
      <BookingFeatures/>
      <FlightSection/>
      <Newsletter/>
      <Footer/> 
    </>
  )
}

export default Home;
