import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function FlightFareRules(props) {
    const { flightFareRules } = props
   // console.log("fare reles modal",props,flightFareRules)
    function gettrimdata(stopDetail) {
      const regex = /(<([^>]+)>)/gi;
      return stopDetail.replace(regex, "");  
    }
  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Fare Rules
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {flightFareRules.FareRules.map((results,index)=>(
          <div key={index} >
          <h1>{results.FareRule.Category}</h1>
          <p>{gettrimdata(results.FareRule.Rules)}</p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}
export default FlightFareRules
