import { Container, Row, Col } from "react-bootstrap";
import React from 'react'
import Header from '../Header/Header'
import HotelShort from './HotelShort'
import HotelResults from './HotelResults'
import Footer from "../Footer/Footer";
import { useLocation } from 'react-router-dom';
import HotelSearchEngine from "./HotelSearchEngine";
import HotelFilter from "./HotelFilter";
function HotelList() {
 const location = useLocation();
  return (
    <>
     {/* <Header/> */}
       < HotelSearchEngine inputs={location.state} />
       <div className="pb-5 position-relative">
       <Container fluid="lg">
          <Row>
            <Col xl={3}>
              <HotelFilter inputs={location.state} />
              {/*<HotelRecommandation/> */}
            </Col>
            <Col xl={9}>
              <div className="mt-4 w-100">
                <HotelShort/>
              </div>
              <div className="mt-4 w-100 float-start">
                {/* <HotelPagination/> */}
              </div>
              <HotelResults inputs={location.state}  />             
            </Col>
          </Row>
       </Container>
       </div>
      <Footer/>
    </>
  )
}

export default HotelList
