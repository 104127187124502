import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Card, Form, Pagination } from "react-bootstrap";
import { FaStar, FaUser, FaMagnifyingGlass, FaCalendarDay, Flight} from "react-icons/fa6";
import { FaCar, FaLocationArrow, FaMap, FaTripadvisor, FaSnowflake } from "react-icons/fa";
import { RiFlightTakeoffFill, RiFlightLandFill, RiGasStationFill, RiSpeedUpFill } from "react-icons/ri";
import { GiGearStickPattern, GiCarDoor, GiCarSeat } from "react-icons/gi";
import { MdLuggage } from "react-icons/md";


import { BrowserRouter, Router, Routes, Route, Link } from 'react-router-dom';
import Header from '../Header/Header';
import '../Car/Css/CarResult.css'
import { useCarContext } from '../../context/Car/carContext';
import dateFormat from "dateformat";
import { useCarFilterContext } from '../../context/Car/carFilterContext';
import Table from 'react-bootstrap/Table';



function CarDetailCard({carDetail, rentailDetails}) {

    //const titleArray = rentailDetails.data.rentalConditions.replace(/[^A-Z0-9]/ig, "_");
    let title  =  rentailDetails ?  rentailDetails.data.rentalConditions : '';

   

    console.log('detail',title);

    
    
    return(
        <>
            <div className='car-container'>
                <div className="car-row"  >
                   
                    <Row className="mt-0 mt-md-5">
                            <span style={{marginLeft:'18px'}}></span>
                        <Col md={4}>
                        
                            <div className="car-image">
                                <img src={carDetail.carDetails.carImage} alt="car-image" />
                            </div>
                            <div style={{marginLeft:"20px"}}>   
                                <div className="w-100">
                                    <GiCarSeat />
                                    <span style={{marginLeft:'3px'}}>Seats: {carDetail.carDetails.passengerQuantity}</span><br/>
                                </div>
                                <div className="w-100">
                                    <GiCarDoor />
                                    <span style={{marginLeft:'3px'}}>Doors: {carDetail.carDetails.vehicleDoor}</span><br/>
                                </div>
                                <div className="w-100">
                                    <MdLuggage />
                                    <span style={{marginLeft:'3px'}}>Small Suitecase: x {carDetail.carDetails.baggageQuantity}</span><br/>
                                </div>
                                <div className="w-100">
                                    <RiGasStationFill />
                                    <span style={{marginLeft:'3px'}}>{carDetail.carDetails.fuelType}</span><br/>
                                </div>
                                <div className="w-100">
                                    <FaSnowflake />
                                    <span style={{marginLeft:'3px'}}>{carDetail.carDetails.ac == "true" ? "Air Conditioning":''}</span><br/>
                                </div>
                                <div className="w-100">
                                    <GiGearStickPattern />
                                    <span style={{marginLeft:'3px'}}>{carDetail.carDetails.transmissionType}</span><br/>
                                </div>
                            </div>
                       
                        </Col>
                        <Col md={4}>
                            <div className="text-start">
                                <h5 style={{marginTop:'14px'}}>{carDetail.carDetails.carModel}</h5>  
                                <span style={{marginLeft:'18px'}}>{carDetail.carDetails.sizeName}</span>
                                
                                   
                                        <div className="car-location">
                                            <div className="w-100" style={{marginTop:'5px'}}>
                                                <RiFlightTakeoffFill />
                                                <span style={{ fontWeight: 'bold' }}>Pickup Address:</span>
                                                <span style={{marginLeft:'3px'}}>{carDetail.pickup.address}</span><br/>
                                            </div>
                                            <div className="w-100" style={{marginTop:'5px'}}>
                                                <RiFlightLandFill />
                                                <span style={{ fontWeight: 'bold' }}>Droppoff Address:</span>
                                                <span style={{marginLeft:'3px'}}>{carDetail.dropoff.address}</span><br/>
                                            </div>
                                        </div>                          
                            
                                <div className="car-refund">
                                <span></span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="text-start">
                             
                                <h5 style={{marginTop:'14px'}}>{carDetail.fees.rateQualifier}</h5>  
                                <div className="car-location">
                                
                                    <div className="w-100" style={{marginTop:'5px'}}>
                                        <RiGasStationFill />
                                        <span style={{ fontWeight: 'bold' }}>Fuel Policy:</span><br/>
                                        <span style={{marginLeft:'20px'}}>{carDetail.carDetails.fuelPolicy.description}</span><br/>
                                    </div>
                                    
                                </div>
                            
                                <div className="car-refund">
                                <span></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 mt-md-5">
                        <Table striped bordered  >
                            <thead>
                                <tr>
                                <th>Extra description</th>
                                <th>quantity</th>
                                <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                           
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                </tr>
                            
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </div>
        </>
    )

}


export default CarDetailCard;