import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/flightcontext';
import { useFilterContext } from '../../context/filterContext';
import Sorting from './sorting';
import Spineer from './Spineer';

function FlightResults({searchData}) {
  console.log("results page from search engine",searchData )
  const { getFLightSearch, isLoading, flightResults, session_id, isError } = useProductContext();
  const { filter_products, all_products } = useFilterContext();
  const navigate = useNavigate();
 


  const flightSearch = async () => {
    //console.log("object",event)
    if (searchData.journey_type === "Return") {
      let json = {
        "requiredCurrency": "USD",
        "journeyType": searchData.journey_type,
        "OriginDestinationInfo": [{
          "departureDate": dateFormatter(searchData.departureDate),
          "returnDate": dateFormatter(searchData.returnDate),
          "airportOriginCode": searchData.destFrom.airport_code,
          "airportDestinationCode": searchData.destTo.airport_code
        }],
        "class": searchData.class_type,
        "adults": searchData.personDetail.adults,
        "childs": searchData.personDetail.children,
        "infants": searchData.personDetail.infants
      }
      console.log(JSON.stringify(json))
      await getFLightSearch(json);

    } else {
      let json = {
        "requiredCurrency": "USD",
        "journeyType": searchData.journey_type,
        "OriginDestinationInfo": [{
          "departureDate": searchData.departureDate,
          "returnDate": "",
          "airportOriginCode": searchData.destFrom.airport_code,
          "airportDestinationCode": searchData.destTo.airport_code
        }],
        "class": searchData.class_type,
        "adults": searchData.personDetail.adults,
        "childs": searchData.personDetail.children,
        "infants": searchData.personDetail.infants
      }
      console.log(JSON.stringify(json))
      await getFLightSearch(json);


    }


  }

  useEffect(() => {
    flightSearch()
  }, [searchData])


  const gotoDetails = (event) => {
    navigate("/flightDetails", {
      state: {
        flightInformation: event,
        session_id: session_id,
        destFrom: searchData.destFrom,
        destTo: searchData.destTo,
        personDetail: searchData.personDetail,
        journey_type: searchData.journey_type,
        class_type: searchData.class_type,
        departureDate: searchData.departureDate,
        returnDate : searchData.returnDate,
      }
    })
  }

  function getTotalDuration(stopDetail) {
    let totalTime = new Date(stopDetail[stopDetail.length - 1].FlightSegment.ArrivalDateTime).getTime() - new Date(stopDetail[0].FlightSegment.DepartureDateTime).getTime();
    let hour = Math.floor(totalTime / (3600 * 1000));
    let min = (totalTime % (3600 * 1000)) / 60000;
    return (((hour < 10 ? '0' : '') + hour) + "h " + ((min < 10 ? '0' : '') + min) + "m");
  }

  function dateFormatter(date) {
    if (date) {
      let dateString = new Date(date)
      let localTime = dateString.getTime();
      let localOffset = dateString.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let offset = 5.5;   
      let bombay = utc + (3600000*offset);
      let nd = new Date(bombay); 
        return (nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + (nd.getDate() )).slice(-2));
      } else {
        return null;
      }
  }

  function timeFormatter(dateString) {
    let date = new Date(dateString)
    // const day = String(date.getDate()).padStart(2, '0');
    // const month = String(date.getMonth() + 1).padStart(2, '0');
    // const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
   // const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}`;
  }


 

  return (
    <div>
      <Sorting />
      {isLoading ? (<Spineer />) : (<div>
        {filter_products.map((results) => (
          <div className="flightResultbox" key={results.FareItinerary.AirItineraryFareInfo.ResultIndex} >
            <div className="row">
              <div className="col-md-9">
                <div className="flight-info">
                  <div className='row'>
                    <div className="col-md-3 text-center">
                      <div className="airline-logo">
                      <img src={'/assets/airlines/'+results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineCode+'.gif'} alt="airline-logo" />
                        <p className='airline-name'>{(results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.MarketingAirlineName)}</p>
                      </div>
                    </div>
                    <div className='col-md-9 middle-section'>
                      <div className='row'>
                        <div className='col-3 text-right xs-pr-0'>
                          <h5>{timeFormatter(results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.DepartureDateTime)}</h5>
                          <p>{results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[0].FlightSegment.DepartureAirportLocationCode}</p>
                        </div>
                        <div className='col-6 text-center'>
                          <p className="duration">{getTotalDuration(results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption)}</p>
                          <div className="airline-brdr"></div>
                          <p className='stop-text'>{results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length > 1 ? (results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1) + ' Stop' : ' Non Stop'} {results.FareItinerary.AirItineraryFareInfo.IsRefundable != 'Yes' ? 'Refundable' : 'Non-Refundable'}</p>

                        </div>
                        <div className='col-3 text-left xs-pl-0'>
                          <h5>{timeFormatter(results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1].FlightSegment.ArrivalDateTime)} </h5>
                          <p>{results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption[results.FareItinerary.OriginDestinationOptions[0].OriginDestinationOption.length - 1].FlightSegment.ArrivalAirportLocationCode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {results.FareItinerary.OriginDestinationOptions.length === 2 ? (
                    <div className='row' style={{ marginTop: '5px', paddingTop: '10px', borderTop: '1px dashed #ccc' }}>
                      <div className="col-md-3 text-center">
                        <div className="airline-logo">
                        <img src={require('../../assets/airlines/'+results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[0].FlightSegment.MarketingAirlineCode+'.gif').default}/>
                        <p>{results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[0].FlightSegment.MarketingAirlineCode}-{results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[0].FlightSegment.FlightNumber}</p>
                        </div>
                      </div>
                      <div className='col-md-9 middle-section'>
                        <div className='row'>
                          <div className='col-3 text-right xs-pr-0'>
                            <h5>{timeFormatter(results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[0].FlightSegment.DepartureDateTime)}</h5>
                            <p>{results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[0].FlightSegment.DepartureAirportLocationCode}</p>
                          </div>
                          <div className='col-6 text-center'>
                            <p className="duration">{getTotalDuration(results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption)}</p>
                            <div className="airline-brdr"></div>
                            <p className='stop-text'>{results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length > 1 ? (results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length - 1) + ' Stop' : ' Non Stop'}</p>
                          </div>
                          <div className='col-3 text-left xs-pl-0'>
                            <h5>{timeFormatter(results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length - 1].FlightSegment.ArrivalDateTime)}</h5>
                            <p>{results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption[results.FareItinerary.OriginDestinationOptions[1].OriginDestinationOption.length - 1].FlightSegment.ArrivalAirportLocationCode}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ''}


                </div>

              </div>
              <div className="col-md-3 flight_price_slider text-center xs-mt-3 sm-mt-3">
                <h5>{(results.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.CurrencyCode)} {(results.FareItinerary.AirItineraryFareInfo.ItinTotalFares.TotalFare.Amount)}</h5>
                <p>Per traveller</p>
                <button className="book-btn" onClick={() => gotoDetails(results)}>Select</button>
              </div>
            </div>

          </div>
        ))}
      </div>
      )}

    </div>
  )
}

export default FlightResults;
