import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button, Card, Form, Overlay, Popover, ProgressBar, Carousel } from 'react-bootstrap';
import { FaUser, FaChild, FaMap, FaCheck, FaHandPointRight } from "react-icons/fa6";
import { BiAward, BiCheckShield, BiSprayCan, BiBadgeCheck } from "react-icons/bi";
import '../Hotel/Css/HotelDetails.css'
import FsLightbox from "fslightbox-react";
import { useHotelState } from '../../context/hotelContext';
import DOMPurify from 'dompurify'
function HotelDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { hotelResults, session_id, inputs } = location.state
  const { gethotelDetails, hotelDetail, get_room_rates, roomsRate, getNearByPlaces, nearByPlaces, isLoading, isError } = useHotelState();
  const YourComponent = (rawHTML) => (
    <div>
      { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHTML) }} /> }
    </div>
  )

  useEffect(() => {
    getHotelDetail()
    getRoomsRate()
    getNearBy()
  }, [])

  const getHotelDetail = async () => {
    await gethotelDetails(session_id, hotelResults.hotelId, hotelResults.productId, hotelResults.tokenId);
  }
  const getRoomsRate = async () => {
    let json = {
      "sessionId": session_id,
      "productId": hotelResults.productId,
      "tokenId": hotelResults.tokenId,
      "hotelId": hotelResults.hotelId
    }
    await get_room_rates(json);
  }

  const getNearBy = async () => {
    await getNearByPlaces(hotelResults.latitude, hotelResults.longitude, 2000);
  }
 // console.log(hotelDetail)

  const facilities = []
  hotelDetail && hotelDetail.hotelImages.forEach((curElem) => {
    facilities.push(curElem.url)
  });


  function getReviewDetail(rate) {
    if (parseFloat(rate) > 4) {
      return "Excellent";
    } else if (parseFloat(rate) > 3) {
      return "Very Good";
    } else if (parseFloat(rate) > 2) {
      return "Average";
    } else if (parseFloat(rate) > 1) {
      return "Worst";
    } else return "Terible";
  }

  function getmiles(data) {
    var num = parseFloat(data);
    var offer = num / 1609.344
    var n = offer.toFixed(2);
    return n
  }

  const [toggler, setToggler] = useState(false);
  const twoHalf = 50;
  const threeFull = 60;
  const fourHalf = 5;
  const fiveFull = 100;
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };


  const gotoReview = (results)=>{
    navigate("/hotelCheckout", {
      state: {
        Selectedrooms: results,
        hotelResults: hotelResults,
        hotelDetail:hotelDetail,
        session_id: session_id,
        inputs:inputs
      }
    })
  }
  const totalAdults = inputs.rooms.reduce((acc, room) => acc + room.adult, 0);
  const totalChildren = inputs.rooms.reduce((acc, room) => acc + room.child, 0);
  return (
    <div>
      <div className='page-content page-bg pt-0'>
        {/* <Header /> */}
        <div className='hotel-info'>
          <Container>
            <Row>
              <Col md={6}>
                <div className="hotel-img-gal">
                  <Row>
                    <Col md={6} className='pe-md-2'>
                      <div className='img-master'>
                        <Carousel slide={true}  data-bs-theme="light">
                          {hotelDetail && hotelDetail.hotelImages.map((results, index) => (
                            results.caption === 'GEN'||results.caption === "Reception"  ? (<Carousel.Item key={index} >
                              <img className="hotel-img-thumb" src={results.url} alt="First slide" />
                            </Carousel.Item>) : null
                          ))}
                        </Carousel>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='img-sub'>
                        <Row>
                          <Col md={12} className='mb-3'>
                            <Carousel data-bs-theme="light">
                              {hotelDetail && hotelDetail.hotelImages.map((results, index) => (
                                results.caption === 'HAB' || results.caption==="Lobby"||results.caption==="Room" ? (<Carousel.Item key={index} >
                                  <img className='img-sub-big' src={results.url} alt="First slide" />
                                </Carousel.Item>) : null
                              ))}
                            </Carousel>
                          </Col>
                          <Col md={6} className='pe-md-2' >
                            <Carousel fade data-bs-theme="light">
                              {hotelDetail && hotelDetail.hotelImages.map((results, index) => (
                                results.caption === 'COM' || results.caption==="Room" ? (<Carousel.Item key={index} >
                                  <img className='img-sub-small' src={results.url} alt="First slide" />
                                </Carousel.Item>) : null
                              ))}
                            </Carousel>
                          </Col>
                          <Col md={6} className='ps-md-2'>
                            <div className='sub-img-lst-holder'>
                              <Carousel fade data-bs-theme="light">
                                {hotelDetail && hotelDetail.hotelImages.map((results, index) => (
                                  results.caption === 'COM' || results.caption==="Room"? (<Carousel.Item key={index} >
                                    <img className='img-sub-small' src={results.url} alt="First slide" />
                                    <button className='btn-show-imgs' onClick={() => setToggler(!toggler)}>See All 5 Photos</button>
                                  </Carousel.Item>) : null
                                ))}
                              </Carousel>
                            </div>
                          </Col>
                        </Row>
                        <FsLightbox toggler={toggler} sources={facilities}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <Card className="hotel-info-card card-common">
                  <Card.Body>
                    <h2 className='hotel-name'>{hotelDetail && hotelDetail.name} </h2>
                    <p className='hotel-adrs'>{hotelDetail && hotelDetail.city}</p>
                    <div className='fac-badge'>
                      <span className='badge badge-rating'>5-star Serviced Apartment {totalAdults&&totalAdults} </span>
                      {/*<span className='badge badge-wifi'>Free Wi-Fi</span> */}
                    </div>
                    <Row>
                      <Col md={6} className='info-left'>
                        {hotelResults.tripAdvisorReview && <div className='trip-adv-rating' >
                          <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-34880-5.svg"></img>
                          <p>{getReviewDetail(hotelResults && hotelResults.tripAdvisorRating)}<span>({hotelResults.tripAdvisorReview} reviews)</span></p>
                        </div>}
                        <div className='map-view'>
                          <button><FaMap />See a map view</button>
                        </div>
                      </Col>
                      <Col md={6} className='info-right text-md-end'>
                        <h3 className="price">{hotelResults.currency} {hotelResults.total}</h3>
                        <p className="per-night">Per night</p>
                        <h4 className="price-per-night">{hotelResults.currency} {hotelResults.total} for 5 nights</h4>
                        <p className="inc-tax">(Includes Taxes and Fees)</p>
                        <Button variant="primary" className='btn-ch-room'>Choose Room</Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col md={6}>
              <Card className="prop-highlite card-common">
                <Card.Body>
                  <h2 className='title'>Property Highlights</h2>
                  <div className='hlt-item'>
                    <BiAward />
                    <h4 className='name'>Exceptional reputation</h4>
                    <p className='desc'>116 of 208 hotels in Sydney</p>
                  </div>
                  <div className='hlt-item'>
                    <BiSprayCan />
                    <h4 className='name'>Very good cleanliness</h4>
                    <p className='desc'>4.5 rating for cleanliness</p>
                  </div>
                  <div className='hlt-item'>
                    <BiBadgeCheck />
                    <h4 className='name'>Very good service</h4>
                    <p className='desc'>4.5 rating for service</p>
                  </div>
                  <div className='hlt-item'>
                    <BiCheckShield />
                    <h4 className='name'>Very good facilities on premise</h4>
                    <p className='desc'>4.0 facilities on premise</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="nrb-landmarks card-common">
                <Card.Body>
                  <h2 className='title'>Nearby Landmarks</h2>
                  <div className='scroll-container'>
                    {nearByPlaces && nearByPlaces.map((near, inx) => (
                      <div className='landmark-grp'>
                        <div className='heading' key={inx} >
                          <h2 className='title'>{near.category}</h2>
                        </div>
                        <ul className='list'>
                          {near.places.map((place, plac) => (
                            <li className='item' key={plac}>
                              <Row>
                                <Col xs={8}>
                                  {place.title}
                                </Col>
                                <Col xs={4} className='text-end'>
                                  {getmiles(place.distance)} KM
                                </Col>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>


        {/* room options section */}
        <div className='section-room-options'>
          <Container>
            <Card className="card-common mt-4 mb-4">
              <Card.Body>
                <div className='room-option-filter'>
                  <h2 className='title'>Filter room options by:</h2>
                  <div className='filter-inputs'>
                    {/* <Form>
                      {['checkbox'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Form.Check
                            inline
                            label="Room Only"
                            name="rm-filter-input"
                            type={type}
                            id={`inline-${type}-1`}
                          />
                          <Form.Check
                            inline
                            label="Bed and Breakfast"
                            name="rm-filter-input"
                            type={type}
                            id={`inline-${type}-2`}
                          />
                          <Form.Check
                            inline
                            label="Half Board"
                            name="rm-filter-input"
                            type={type}
                            id={`inline-${type}-3`}
                          />
                          <Form.Check
                            inline
                            label="Full Board"
                            name="rm-filter-input"
                            type={type}
                            id={`inline-${type}-4`}
                          />
                        </div>
                      ))}
                    </Form> */}
                  </div>
                </div>

                <div className='room-listing mt-4'>
                  <h4 className='title'>Select your room</h4>
                  <Row>
                    {roomsRate && roomsRate.roomRates.perBookingRates.map((results, index) => (
                      <Col md={4} key={index}>
                        <div className='room-item mb-4'>
                          <div className='img-thumb-holder'>
                            <img className='img-thumb' src='/assets/images/img-hotel-01.jpeg'></img>
                          </div>
                          <div className='room-info-holder'>
                            <h2 className='title'>{results.roomType}</h2>
                            <div className='guest-count'>
                              <FaChild /><FaChild />
                            </div>
                            <div className='price-inc'>
                              <h4 className='title'>Your price includes:</h4>
                              <ul className='list'>
                                {results.facilities.map((facility, fac) => (
                                  <li className='item' key={fac} ><FaCheck />{facility}</li>
                                ))}
                              </ul>
                            </div>
                            <div className='item-footer'>
                              <Row>
                                <Col sm={6}>
                                  <h3 className='old-price'>{results.currency} {results.netPrice}</h3>
                                  <h2 className='price'>{results.currency} {results.netPrice}</h2>
                                </Col>
                                <Col sm={6} className='text-end'>
                                  <Button variant="primary" className='btn-book-room' onClick={()=> gotoReview(results)} >Book Now</Button>
                                </Col>
                                <Col sm="12" className='text-end'>
                                  <p className='cancell-plcy' onClick={handleClick}>Cancellation Policy</p>
                                  <Overlay show={show} target={target} placement="bottom" containerPadding={20}>
                                    <Popover id="popover-contained">
                                      <Popover.Body>
                                        {results.cancellationPolicy}
                                      </Popover.Body>
                                    </Popover>
                                  </Overlay>

                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}


                  </Row>
                </div>

              </Card.Body>
            </Card>
          </Container>
        </div>
        {/* room options section:end */}


        {/* section more about hotel */}
        <Container>
          <div className='detail-info-section'>
            <Card className="detail-info-card card-common mt-4 mb-4">
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <div className='border-box'>
                      <div className='rating-holder'>
                        <h2 className='title-rating'>Overall rating:</h2>
                        <div className='d-flex'>
                          <div className='box-rating'>
                            <p>{hotelDetail && hotelDetail.hotel_review.rating}<span>/5</span></p>
                          </div>
                          <div className='text-rating'>
                            <h4>{getReviewDetail(hotelResults && hotelResults.tripAdvisorRating)}</h4>
                            <p>{hotelDetail && hotelDetail.hotel_review.num_reviews} reviews</p>
                          </div>
                        </div>
                        <div className='progress-holder'>
                          <div className='progress-item'>
                            <Row>
                              <Col sm={4}>
                                <p className='text'>Cleanliness</p>
                              </Col>
                              <Col sm={6}>
                                <ProgressBar now={fourHalf} label={`${fourHalf}%`} visuallyHidden />
                              </Col>
                              <Col sm={2}>
                                <p className='value'>{hotelDetail && hotelDetail.hotel_review.rate_cleanliness}</p>
                              </Col>
                            </Row>
                          </div>
                          <div className='progress-item'>
                            <Row>
                              <Col sm={4}>
                                <p className='text'>Facilities</p>
                              </Col>
                              <Col sm={6}>
                                <ProgressBar now={hotelDetail && hotelDetail.hotel_review.rate_room} label={`${fourHalf}%`} visuallyHidden />
                              </Col>
                              <Col sm={2}>
                                <p className='value'>{hotelDetail && hotelDetail.hotel_review.rate_room}</p>
                              </Col>
                            </Row>
                          </div>
                          <div className='progress-item'>
                            <Row>
                              <Col sm={4}>
                                <p className='text'>Location</p>
                              </Col>
                              <Col sm={6}>
                                <ProgressBar now={hotelDetail && hotelDetail.hotel_review.rate_location} label={`${fiveFull}%`} visuallyHidden />
                              </Col>
                              <Col sm={2}>
                                <p className='value'>{hotelDetail && hotelDetail.hotel_review.rate_location}</p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='border-box'>
                      <div className='hotel-info-brief'>
                        <div className='hotel-img-thumb-holder'>
                          <Carousel data-bs-theme="light">
                            {hotelDetail && hotelDetail.hotelImages.map((results, index) => (
                              <Carousel.Item key={index} >
                                <img className="hotel-img-thumb" src={results.url} alt="First slide" />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                          <div className='thumb-info'>
                            <h4 className='about'>More about</h4>
                            <h2 className='name'>{hotelDetail && hotelDetail.name}</h2>
                          </div>
                        </div>
                        <div className='description'>
                          <p>{hotelDetail && YourComponent(hotelDetail.description.content)}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Container>
        {/* section more about hotel:end */}

        {/* facitiles section */}
        <div className='fac-section'>
          <Container>
            <Card className="fac-card card-common mt-4 mb-4">
              <Card.Body>
                <h2 className='fac-title'>Nearest Essentials</h2>
                <div className='fac-row'>
                {nearByPlaces && nearByPlaces.map((near, inx) => (
                  near.category==="Public Transport"||near.category==="Taxi Stand"?(
                  <Row key={inx} >
                    <Col xs={2} className='fac-ico-col'>
                      <div className='fac-icon'>
                        <img src='/assets/images/transportation.png' alt='Facilities Icon'></img>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <h2 className='fac-item-title'>{near.category}</h2>
                      <Row>
                      {near.places.map((place, plac) => (
                        <Col md={3} key={plac} >
                          <div className='fac-item mb-3'>
                            <h3 className='fac-item-text'>{place.title}</h3>
                            <p className='fac-item-sub-text'>{getmiles(place.distance)} KM </p>
                          </div>
                        </Col>
                      ))}
                      </Row>
                    </Col>
                  </Row>):null                  
                ))}
                </div>
              </Card.Body>
            </Card>
           
            <Card className="fac-card card-common mt-4 mb-4">
              <Card.Body>
                <h2 className='fac-title'>Food and Dining</h2>
                <div className='fac-row'>
                {nearByPlaces && nearByPlaces.map((near, inx) => (near.category==="Food & Drink"||near.category==="Coffee/Tea"||near.category==="Restaurant"?(
                  <Row key={inx} >
                    <Col xs={2} className='fac-ico-col'>
                      <div className='fac-icon'>
                        <img src='/assets/images/dining-table.png' alt='Facilities Icon'></img>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <h2 className='fac-item-title'>{near.category}</h2>
                      <Row>
                      {near.places.map((place, plac) => (
                        <Col md={3} key={plac} >
                          <div className='fac-item mb-3'>
                            <h3 className='fac-item-text'>{place.title}</h3>
                            <p className='fac-item-sub-text'>{getmiles(place.distance)} KM </p>
                          </div>
                        </Col>
                      ))}
                      </Row>
                    </Col>
                  </Row>):null                  
                ))}
                </div>
              </Card.Body>
            </Card>

          </Container>
        </div>
        {/* facitiles section:end */}

        {/* reviews section */}
        <div className='reviews-section'>
          <Container>
            <Card className="card-common mt-4 mb-4">
              <Card.Body>
                <h2 className='title'>Reviews</h2>
                <div className='trip-avd-review'>
                  <h3 className='title-trip-adv'>Tripadvisor Review</h3>
                  <div className='content'>
                    <Row>
                      <Col md={7}>
                        <Row>
                          <Col md={2}>
                            <div className='rating'>
                              <div className="box">
                                <p>4.5<span>/5</span></p>
                              </div>
                              <p className='text'>Very Good</p>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className='progress-info'>
                              <ProgressBar now={fourHalf} label={`${fourHalf}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Cleanliness</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>4.5</p>
                                </Col>
                              </Row>
                            </div>
                            <div className='progress-info'>
                              <ProgressBar now={fiveFull} label={`${fiveFull}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Location</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>5</p>
                                </Col>
                              </Row>
                            </div>
                            <div className='progress-info'>
                              <ProgressBar now={threeFull} label={`${threeFull}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Service</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>3</p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className='progress-info'>
                              <ProgressBar now={twoHalf} label={`${twoHalf}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Sleep</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>2.5</p>
                                </Col>
                              </Row>
                            </div>
                            <div className='progress-info'>
                              <ProgressBar now={fiveFull} label={`${fiveFull}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Room Comfort & Quality</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>5</p>
                                </Col>
                              </Row>
                            </div>
                            <div className='progress-info'>
                              <ProgressBar now={fourHalf} label={`${fourHalf}%`} visuallyHidden />
                              <Row>
                                <Col sm={8}>
                                  <p className='text'>Value for money</p>
                                </Col>
                                <Col sm={4} className='text-end'>
                                  <p className='value'>4.5</p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={5} className='list-info-col'>
                        <div className='list-info'>
                          <Row>
                            <Col md={6}>
                              <div className='list-holder'>
                                <h2 className='title'>Rating</h2>
                                <ul className='list'>
                                  <li className='item'><FaHandPointRight />9+ Exceptional (71)</li>
                                  <li className='item'><FaHandPointRight />8-9 Excellent (110)</li>
                                  <li className='item'><FaHandPointRight />7-8 Very Good (215)</li>
                                  <li className='item'><FaHandPointRight />6 Below Expectation (2631)</li>
                                </ul>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className='list-holder'>
                                <h2 className='title'>Time of the year</h2>
                                <ul className='list'>
                                  <li className='item'><FaHandPointRight />Mar - May</li>
                                  <li className='item'><FaHandPointRight />Jun - Aug</li>
                                  <li className='item'><FaHandPointRight />Sep - Nov</li>
                                  <li className='item'><FaHandPointRight />Dec - Feb</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
        {/* reviews section:end */}



      </div>
    </div>
  )
}

export default HotelDetails
