import React, { useState } from 'react';
import { Row, Col, InputGroup, Form, Button, Overlay, Popover, Alert, Card, Container, } from 'react-bootstrap';
import { FaTrash, FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
function AddRoom({ rooms,setRooms ,handleDone}) {
    
   
    const addRoom = () => {
        if (rooms.length < 3) {
            const newRoom = {
                room_no: rooms.length + 1,
                adult: 1,
                child: 0,
                child_age: [],
                roomSize: 1,
            };
            setRooms([...rooms, newRoom]);
        }
    };
    // Function to remove a room by index
    const removeRoom = (index) => {
        const updatedRooms = [...rooms];
        updatedRooms.splice(index, 1);
        setRooms(updatedRooms);
    };


    const handleAddAdult = (i) => {
        if (rooms[i].adult < 4 && rooms[i].adult + rooms[i].child < 6) {
            const updatedRooms = [...rooms];
            updatedRooms[i].adult += 1;
            setRooms(updatedRooms);
        }

    };
    const handleRemoveAdult = (i) => {
        if (rooms[i].adult > 1) {
            const updatedRooms = [...rooms];
            updatedRooms[i].adult -= 1;
            setRooms(updatedRooms);
        }
    };

    const handleAddChild = (i) => {
        if (rooms[i].child < 4 && rooms[i].adult + rooms[i].child < 6) {
            const updatedRooms = [...rooms];
            updatedRooms[i].child += 1;
            updatedRooms[i].child_age.push(2); // Default child age, you can change it.
            setRooms(updatedRooms);
        }

    };
    const handleRemoveChild = (i) => {
        if (rooms[i].child > 0) {
            const updatedRooms = [...rooms];
            updatedRooms[i].child -= 1;
            updatedRooms[i].child_age.pop();
            setRooms(updatedRooms);
        }
    };


    const handleChildAgeChange = (roomIndex, childIndex, age) => {
        // Update the age of a specific child in a specific room
        const updatedRooms = [...rooms];
        updatedRooms[roomIndex].child_age[childIndex] = age;
        setRooms(updatedRooms);
    };
    
    const addRooms = (e) => {
        console.log(e.target.value)
        const newRoom = {
            room_no: e.target.value,
            adult: 1,
            child: 0,
            child_age: [],
            roomSize: 1,
        };
        setRooms([...rooms, newRoom]);

    }



    return (
        <div>
            <Button onClick={addRoom}>Add Room</Button>
            {/* <select style={{ margin: "5px", borderRadius: '5px' }}  onChange={(e) => addRooms(e)}>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
            </select> */}
            {rooms.map((room, index) => (
                <div key={index}>
                    <Row>
                        <Col xs={10}><h4>Room {room.room_no}</h4></Col>
                        <Col xs={2} onClick={() => removeRoom(index)} ><FaTrash /></Col>
                        <Col xs={4}><p>Adults: </p></Col>
                        <Col xs={8}>
                            <Row>
                                <Col><Button variant="primary" size="sm" onClick={() => handleRemoveAdult(index)}><FaCircleMinus /></Button></Col>
                                <Col><h4>{room.adult}</h4></Col>
                                <Col><Button variant="primary" size="sm" onClick={() => handleAddAdult(index)}><FaCirclePlus /></Button></Col>
                            </Row>
                        </Col>
                        <Col xs={4}><p>Children:: </p></Col>
                        <Col xs={8}>
                            <Row>
                                <Col><Button variant="primary" size="sm" onClick={() => handleRemoveChild(index)}><FaCircleMinus /></Button></Col>
                                <Col><h4>{room.child}</h4></Col>
                                <Col><Button variant="primary" size="sm" onClick={() => handleAddChild(index)}><FaCirclePlus /></Button></Col>
                            </Row>
                        </Col>

                    </Row>
                    {room.child > 0 && (
                        <div>
                            Child Ages:
                            {room.child_age.map((age, childIndex) => (
                                <select style={{ margin: "5px", borderRadius: '5px' }} key={childIndex} value={age} onChange={(e) => handleChildAgeChange(index, childIndex, e.target.value)}>
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((age) => (
                                        <option key={age} value={age}>{age}</option>
                                    ))}
                                </select>
                            ))}
                        </div>
                    )}
                </div>
            ))
            }

            <div className="d-grid gap-2 mt-3">
                <Button variant="primary" onClick={handleDone} size="sm" >Submit</Button>
            </div>
        </div>
    );
};
export default AddRoom
