export const airport =[
    {
        "id": "1",
        "AirportCode": "AAA",
        "AirportName": "Anaa Airport",
        "City": "Anaa",
        "Country": "French Polynesia"
    },
    {
        "id": "2",
        "AirportCode": "AAB",
        "AirportName": "Arrabury Airport",
        "City": "Arrabury",
        "Country": "Australia"
    },
    {
        "id": "3",
        "AirportCode": "AAC",
        "AirportName": "Al Arish Airport",
        "City": "Al Arish",
        "Country": "Egypt"
    },
    {
        "id": "4",
        "AirportCode": "AAD",
        "AirportName": "Ad-Dabbah Airport",
        "City": "Ad-Dabbah",
        "Country": "Sudan"
    },
    {
        "id": "5",
        "AirportCode": "AAE",
        "AirportName": "Les Salines Airport",
        "City": "Annaba",
        "Country": "Algeria"
    },
    {
        "id": "6",
        "AirportCode": "AAF",
        "AirportName": "Apalachicola Municipal Airport",
        "City": "Apalachicola",
        "Country": "USA Florida"
    },
    {
        "id": "7",
        "AirportCode": "AAG",
        "AirportName": "Arapoti Airport",
        "City": "Arapoti",
        "Country": "Brazil"
    },
    {
        "id": "8",
        "AirportCode": "AAH",
        "AirportName": "Aachen/Merzbruck Airport",
        "City": "Aachen",
        "Country": "Germany"
    },
    {
        "id": "9",
        "AirportCode": "AAI",
        "AirportName": "Arraias Airport",
        "City": "Arraias",
        "Country": "Brazil"
    },
    {
        "id": "10",
        "AirportCode": "AAJ",
        "AirportName": "Cayana Airstrip",
        "City": "Awaradam",
        "Country": "Suriname"
    },
    {
        "id": "11",
        "AirportCode": "AAK",
        "AirportName": "Aranuka Airport",
        "City": "Aranuka",
        "Country": "Kiribati"
    },
    {
        "id": "12",
        "AirportCode": "AAL",
        "AirportName": "Aalborg Airport",
        "City": "Aalborg",
        "Country": "Denmark"
    },
    {
        "id": "13",
        "AirportCode": "AAM",
        "AirportName": "Mala Mala Airport",
        "City": "Mala Mala",
        "Country": "South Africa"
    },
    {
        "id": "14",
        "AirportCode": "AAN",
        "AirportName": "Al Ain Airport",
        "City": "Al Ain",
        "Country": "UAE"
    },
    {
        "id": "15",
        "AirportCode": "AAO",
        "AirportName": "Anaco Airport",
        "City": "Anaco",
        "Country": "Venezuela"
    },
    {
        "id": "16",
        "AirportCode": "AAP",
        "AirportName": "Andrau Airpark",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "17",
        "AirportCode": "AAQ",
        "AirportName": "Anapa Airport",
        "City": "Anapa",
        "Country": "Russia"
    },
    {
        "id": "18",
        "AirportCode": "AAR",
        "AirportName": "Tirstrup Airport",
        "City": "Aarhus",
        "Country": "Denmark"
    },
    {
        "id": "19",
        "AirportCode": "AAS",
        "AirportName": "Apalapsili Airport",
        "City": "Apalapsili",
        "Country": "Indonesia"
    },
    {
        "id": "20",
        "AirportCode": "AAT",
        "AirportName": "Altay Airport",
        "City": "Altay",
        "Country": "China"
    },
    {
        "id": "21",
        "AirportCode": "AAU",
        "AirportName": "Asau Airport",
        "City": "Asau",
        "Country": "Samo"
    },
    {
        "id": "22",
        "AirportCode": "AAV",
        "AirportName": "Alah Airport",
        "City": "Alah",
        "Country": "Philippines"
    },
    {
        "id": "23",
        "AirportCode": "AAW",
        "AirportName": "Abbottabad Airport",
        "City": "Abbottabad",
        "Country": "Pakistan"
    },
    {
        "id": "24",
        "AirportCode": "AAX",
        "AirportName": "Araxa Airport",
        "City": "Araxa",
        "Country": "Brazil"
    },
    {
        "id": "25",
        "AirportCode": "AAY",
        "AirportName": "Al Ghaydah Airport",
        "City": "Al Ghaydah",
        "Country": "Yemen"
    },
    {
        "id": "26",
        "AirportCode": "ABA",
        "AirportName": "Abakan Airport",
        "City": "Abakan (ABA)",
        "Country": "Russia"
    },
    {
        "id": "27",
        "AirportCode": "ABB",
        "AirportName": "Royal Air Force Station",
        "City": "Abingdon",
        "Country": "United Kingdom"
    },
    {
        "id": "28",
        "AirportCode": "ABC",
        "AirportName": "Los Llanos Airport",
        "City": "Albacete",
        "Country": "Spain"
    },
    {
        "id": "29",
        "AirportCode": "ABD",
        "AirportName": "Abadan Airport",
        "City": "Abadan",
        "Country": "Iran"
    },
    {
        "id": "30",
        "AirportCode": "ABE",
        "AirportName": "Lehigh Valley International Airport",
        "City": "Allentown",
        "Country": "USA Philadelphia"
    },
    {
        "id": "31",
        "AirportCode": "ABF",
        "AirportName": "Abaiang Airport",
        "City": "Abaiang",
        "Country": "Kiribati"
    },
    {
        "id": "32",
        "AirportCode": "ABG",
        "AirportName": "Abingdon Airport",
        "City": "Abingdon",
        "Country": "Australia"
    },
    {
        "id": "33",
        "AirportCode": "ABH",
        "AirportName": "Alpha Airport",
        "City": "Alpha",
        "Country": "Australia"
    },
    {
        "id": "34",
        "AirportCode": "ABI",
        "AirportName": "Abilene Municipal Airport",
        "City": "Abilene",
        "Country": "USA Texas"
    },
    {
        "id": "35",
        "AirportCode": "ABK",
        "AirportName": "Kabri Dar Airport",
        "City": "Kabri Dar",
        "Country": "Ethiopia"
    },
    {
        "id": "36",
        "AirportCode": "ABL",
        "AirportName": "Ambler Airport",
        "City": "Ambler",
        "Country": "USA Alaska"
    },
    {
        "id": "37",
        "AirportCode": "ABM",
        "AirportName": "Bamaga Airport",
        "City": "Bamaga",
        "Country": "Australia"
    },
    {
        "id": "38",
        "AirportCode": "ABN",
        "AirportName": "Albina Airport",
        "City": "Albina",
        "Country": "Suriname"
    },
    {
        "id": "39",
        "AirportCode": "ABP",
        "AirportName": "Atkamba Airport",
        "City": "Atkamba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "40",
        "AirportCode": "ABQ",
        "AirportName": "Albuquerque International Airport",
        "City": "Albuquerque",
        "Country": "USA New Mexico"
    },
    {
        "id": "41",
        "AirportCode": "ABR",
        "AirportName": "Aberdeen Municipal Airport",
        "City": "Aberdeen",
        "Country": "USA South Dakota"
    },
    {
        "id": "42",
        "AirportCode": "ABS",
        "AirportName": "Abu Simbel Airport",
        "City": "Abu Simbel",
        "Country": "Egypt"
    },
    {
        "id": "43",
        "AirportCode": "ABT",
        "AirportName": "Al-Aqiq Airport",
        "City": "Al-Baha",
        "Country": "Saudi Arabia"
    },
    {
        "id": "44",
        "AirportCode": "ABU",
        "AirportName": "Atambua Airport",
        "City": "Atambua",
        "Country": "Indonesia"
    },
    {
        "id": "45",
        "AirportCode": "ABV",
        "AirportName": "International Airport",
        "City": "Abuja",
        "Country": "Nigeria"
    },
    {
        "id": "46",
        "AirportCode": "ABW",
        "AirportName": "Abau Airport",
        "City": "Abau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "47",
        "AirportCode": "ABX",
        "AirportName": "Albury Airport",
        "City": "Albury",
        "Country": "Australia"
    },
    {
        "id": "48",
        "AirportCode": "ABY",
        "AirportName": "Dougherty County Airport",
        "City": "Albany",
        "Country": "USA Georgia"
    },
    {
        "id": "49",
        "AirportCode": "ABZ",
        "AirportName": "Dyce Airport",
        "City": "Aberdeen",
        "Country": "United Kingdom"
    },
    {
        "id": "50",
        "AirportCode": "ACA",
        "AirportName": "Alvarez International Airport",
        "City": "Acapulco",
        "Country": "Mexico"
    },
    {
        "id": "51",
        "AirportCode": "ACB",
        "AirportName": "Antrim County Airport",
        "City": "Bellaire",
        "Country": "USA Michigan"
    },
    {
        "id": "52",
        "AirportCode": "ACC",
        "AirportName": "Airport",
        "City": "Accra",
        "Country": "Ghana"
    },
    {
        "id": "53",
        "AirportCode": "ACD",
        "AirportName": "Acandi Airport",
        "City": "Acandi",
        "Country": "Colombia"
    },
    {
        "id": "54",
        "AirportCode": "ACE",
        "AirportName": "Lanzarote Airport",
        "City": "Lanzarote",
        "Country": "Spain"
    },
    {
        "id": "55",
        "AirportCode": "ACH",
        "AirportName": "Altenrhein Airport",
        "City": "Altenrhein",
        "Country": "Switzerland"
    },
    {
        "id": "56",
        "AirportCode": "ACI",
        "AirportName": "The Blaye Airport",
        "City": "Alderney",
        "Country": "United Kingdom"
    },
    {
        "id": "57",
        "AirportCode": "ACJ",
        "AirportName": "Anuradhapura Airport",
        "City": "Anuradhapura",
        "Country": "Sri Lanka"
    },
    {
        "id": "58",
        "AirportCode": "ACK",
        "AirportName": "Nantucket Memorial Airport",
        "City": "Nantucket",
        "Country": "USA Massachusetts"
    },
    {
        "id": "59",
        "AirportCode": "ACL",
        "AirportName": "Aguaclara Airport",
        "City": "Aguaclara",
        "Country": "Colombia"
    },
    {
        "id": "60",
        "AirportCode": "ACM",
        "AirportName": "Arica Airport",
        "City": "Arica",
        "Country": "Colombia"
    },
    {
        "id": "61",
        "AirportCode": "ACN",
        "AirportName": "Ciudad Acuna International Airport",
        "City": "Ciudad Acuna",
        "Country": "Mexico"
    },
    {
        "id": "62",
        "AirportCode": "ACO",
        "AirportName": "Ascona Airport",
        "City": "Ascona",
        "Country": "Switzerland"
    },
    {
        "id": "63",
        "AirportCode": "ACP",
        "AirportName": "Sahand Airport",
        "City": "Sahand",
        "Country": "Iran"
    },
    {
        "id": "64",
        "AirportCode": "ACR",
        "AirportName": "Araracuara Airport",
        "City": "Araracuara",
        "Country": "Colombia"
    },
    {
        "id": "65",
        "AirportCode": "ACS",
        "AirportName": "Achinsk Airport",
        "City": "Achinsk",
        "Country": "Russia"
    },
    {
        "id": "66",
        "AirportCode": "ACT",
        "AirportName": "Waco Municipal Airport",
        "City": "Waco",
        "Country": "USA Texas"
    },
    {
        "id": "67",
        "AirportCode": "ACU",
        "AirportName": "Achutupo Airport",
        "City": "Achutupo",
        "Country": "Panama"
    },
    {
        "id": "68",
        "AirportCode": "ACV",
        "AirportName": "Arcata Airport",
        "City": "Arcata",
        "Country": "USA California"
    },
    {
        "id": "69",
        "AirportCode": "ACX",
        "AirportName": "Xingyi Airport",
        "City": "Xingyi",
        "Country": "China"
    },
    {
        "id": "70",
        "AirportCode": "ACY",
        "AirportName": "Atlantic City International Airport",
        "City": "Atlantic City",
        "Country": "USA New Jersey"
    },
    {
        "id": "71",
        "AirportCode": "ACZ",
        "AirportName": "Zabol Airport",
        "City": "Zabol",
        "Country": "Iran"
    },
    {
        "id": "72",
        "AirportCode": "ADA",
        "AirportName": "Adana Airport",
        "City": "Adana",
        "Country": "Turkey"
    },
    {
        "id": "73",
        "AirportCode": "ADB",
        "AirportName": "Adnan Menderes Airport",
        "City": "Izmir",
        "Country": "Turkey"
    },
    {
        "id": "74",
        "AirportCode": "ADC",
        "AirportName": "Andakombe Airport",
        "City": "Andakombe",
        "Country": "Papua New Guinea"
    },
    {
        "id": "75",
        "AirportCode": "ADD",
        "AirportName": "Airport",
        "City": "Addis Ababa",
        "Country": "Ethiopia"
    },
    {
        "id": "76",
        "AirportCode": "ADE",
        "AirportName": "Aden International Airport",
        "City": "Aden",
        "Country": "Yemen"
    },
    {
        "id": "77",
        "AirportCode": "ADF",
        "AirportName": "Adiyaman Airport",
        "City": "Adiyaman",
        "Country": "Turkey"
    },
    {
        "id": "78",
        "AirportCode": "ADG",
        "AirportName": "Lenawee County Airport",
        "City": "Adrian",
        "Country": "USA Michigan"
    },
    {
        "id": "79",
        "AirportCode": "ADH",
        "AirportName": "Aldan Airport",
        "City": "Aldan",
        "Country": "Russia"
    },
    {
        "id": "80",
        "AirportCode": "ADI",
        "AirportName": "Arandis Airport",
        "City": "Arandis",
        "Country": "Namibia"
    },
    {
        "id": "81",
        "AirportCode": "ADJ",
        "AirportName": "Civil - Marka Airport",
        "City": "Amman (AMM)",
        "Country": "Jordan"
    },
    {
        "id": "82",
        "AirportCode": "ADK",
        "AirportName": "Adak Island Naval Station",
        "City": "Adak Island",
        "Country": "USA Alaska"
    },
    {
        "id": "83",
        "AirportCode": "ADL",
        "AirportName": "Adelaide Airport",
        "City": "Adelaide",
        "Country": "Australia"
    },
    {
        "id": "84",
        "AirportCode": "ADM",
        "AirportName": "Ardmore Municipal Airport",
        "City": "Ardmore",
        "Country": "USA Oklahoma"
    },
    {
        "id": "85",
        "AirportCode": "ADN",
        "AirportName": "Andes Airport",
        "City": "Andes",
        "Country": "Colombia"
    },
    {
        "id": "86",
        "AirportCode": "ADO",
        "AirportName": "Andamooka Airport",
        "City": "Andamooka",
        "Country": "Australia"
    },
    {
        "id": "87",
        "AirportCode": "ADP",
        "AirportName": "Ampara Airport",
        "City": "Ampara",
        "Country": "Sri Lanka"
    },
    {
        "id": "88",
        "AirportCode": "ADQ",
        "AirportName": "Kodiak Airport",
        "City": "Kodiak",
        "Country": "USA Alaska"
    },
    {
        "id": "89",
        "AirportCode": "ADR",
        "AirportName": "Andrews Airport",
        "City": "Andrews",
        "Country": "USA South Carolina"
    },
    {
        "id": "90",
        "AirportCode": "ADS",
        "AirportName": "Addison Airport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "91",
        "AirportCode": "ADT",
        "AirportName": "Ada Airport",
        "City": "Ada",
        "Country": "USA Oklahoma"
    },
    {
        "id": "92",
        "AirportCode": "ADU",
        "AirportName": "Ardabil Airport",
        "City": "Ardabil",
        "Country": "Iran"
    },
    {
        "id": "93",
        "AirportCode": "ADV",
        "AirportName": "Andover Airport",
        "City": "Andover",
        "Country": "United Kingdom"
    },
    {
        "id": "94",
        "AirportCode": "ADW",
        "AirportName": "Andrews Air Force Base",
        "City": "Camp Springs",
        "Country": "USA Maryland"
    },
    {
        "id": "95",
        "AirportCode": "ADX",
        "AirportName": "Leuchars Airport",
        "City": "Saint Andrews",
        "Country": "United Kingdom"
    },
    {
        "id": "96",
        "AirportCode": "ADY",
        "AirportName": "Alldays Airport",
        "City": "Alldays",
        "Country": "South Africa"
    },
    {
        "id": "97",
        "AirportCode": "ADZ",
        "AirportName": "San Andres Island Airport",
        "City": "San Andres Island",
        "Country": "Colombia"
    },
    {
        "id": "98",
        "AirportCode": "AEA",
        "AirportName": "Abemama Atoll Airport",
        "City": "Abemama Atoll",
        "Country": "Kiribati"
    },
    {
        "id": "99",
        "AirportCode": "AED",
        "AirportName": "Aleneva Airport",
        "City": "Aleneva",
        "Country": "USA Alaska"
    },
    {
        "id": "100",
        "AirportCode": "AEG",
        "AirportName": "Aek Godang Airport",
        "City": "Aek Godang",
        "Country": "Indonesia"
    },
    {
        "id": "101",
        "AirportCode": "AEH",
        "AirportName": "Abecher Airport",
        "City": "Abecher",
        "Country": "Mali"
    },
    {
        "id": "102",
        "AirportCode": "AEI",
        "AirportName": "Algeciras Airport",
        "City": "Algeciras",
        "Country": "Spain"
    },
    {
        "id": "103",
        "AirportCode": "AEK",
        "AirportName": "Aseki Airport",
        "City": "Aseki",
        "Country": "Papua New Guinea"
    },
    {
        "id": "104",
        "AirportCode": "AEL",
        "AirportName": "Albert Lea Airport",
        "City": "Albert Lea",
        "Country": "USA Minnesota"
    },
    {
        "id": "105",
        "AirportCode": "AEO",
        "AirportName": "Aioun El Atrouss Airport",
        "City": "Aioun El Atrouss",
        "Country": "Mauritania"
    },
    {
        "id": "106",
        "AirportCode": "AEP",
        "AirportName": "Jorge Newbery Airport",
        "City": "Buenos Aires",
        "Country": "Argentina"
    },
    {
        "id": "107",
        "AirportCode": "AER",
        "AirportName": "Adler/Sochi Airport",
        "City": "Adler/Sochi",
        "Country": "Russia"
    },
    {
        "id": "108",
        "AirportCode": "AES",
        "AirportName": "Vigra Airport",
        "City": "Aalesund",
        "Country": "Norway"
    },
    {
        "id": "109",
        "AirportCode": "AET",
        "AirportName": "Allakaket Airport",
        "City": "Allakaket",
        "Country": "USA Alaska"
    },
    {
        "id": "110",
        "AirportCode": "AEU",
        "AirportName": "Abu Musa Airport",
        "City": "Abu Musa",
        "Country": "Iran"
    },
    {
        "id": "111",
        "AirportCode": "AEX",
        "AirportName": "Alexandria International Airport",
        "City": "Alexandria",
        "Country": "USA Louisiana"
    },
    {
        "id": "112",
        "AirportCode": "AEY",
        "AirportName": "Akureyri Airport",
        "City": "Akureyri",
        "Country": "Iceland"
    },
    {
        "id": "113",
        "AirportCode": "AFA",
        "AirportName": "San Rafael Airport",
        "City": "San Rafael",
        "Country": "Argentina"
    },
    {
        "id": "114",
        "AirportCode": "AFD",
        "AirportName": "Port Alfred Airport",
        "City": "Port Alfred",
        "Country": "South Africa"
    },
    {
        "id": "115",
        "AirportCode": "AFF",
        "AirportName": "United States Air Force Academy Airport",
        "City": "Colorado Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "116",
        "AirportCode": "AFI",
        "AirportName": "Amalfi Airport",
        "City": "Amalfi",
        "Country": "Colombia"
    },
    {
        "id": "117",
        "AirportCode": "AFL",
        "AirportName": "Alta Floresta Airport",
        "City": "Alta Floresta",
        "Country": "Brazil"
    },
    {
        "id": "118",
        "AirportCode": "AFN",
        "AirportName": "Jaffrey Municipal Airport",
        "City": "Jaffrey",
        "Country": "USA New Hampshire"
    },
    {
        "id": "119",
        "AirportCode": "AFO",
        "AirportName": "Afton Municipal Airport",
        "City": "Afton",
        "Country": "USA Wyoming"
    },
    {
        "id": "120",
        "AirportCode": "AFR",
        "AirportName": "Afore Airport",
        "City": "Afore",
        "Country": "Papua New Guinea"
    },
    {
        "id": "121",
        "AirportCode": "AFS",
        "AirportName": "Zarafshan Airport",
        "City": "Zarafshan",
        "Country": "Uzbekistan"
    },
    {
        "id": "122",
        "AirportCode": "AFT",
        "AirportName": "Afutara Aerodrome",
        "City": "Afutara",
        "Country": "Solomon Islands"
    },
    {
        "id": "123",
        "AirportCode": "AFW",
        "AirportName": "Fort Worth Alliance Airport",
        "City": "Fort Worth",
        "Country": "USA Texas"
    },
    {
        "id": "124",
        "AirportCode": "AFY",
        "AirportName": "Afyon Airport",
        "City": "Afyon",
        "Country": "Turkey"
    },
    {
        "id": "125",
        "AirportCode": "AFZ",
        "AirportName": "Sabzevar Airport",
        "City": "Sabzevar",
        "Country": "Iran"
    },
    {
        "id": "126",
        "AirportCode": "AGA",
        "AirportName": "Agadir Almassira Airport",
        "City": "Agadir",
        "Country": "Morocco"
    },
    {
        "id": "127",
        "AirportCode": "AGB",
        "AirportName": "Augsburg - Muehlhausen Airport",
        "City": "Munich",
        "Country": "Germany"
    },
    {
        "id": "128",
        "AirportCode": "AGC",
        "AirportName": "Allegheny County Airport",
        "City": "Pittsburgh",
        "Country": "USA Philadelphia"
    },
    {
        "id": "129",
        "AirportCode": "AGD",
        "AirportName": "Anggi Airport",
        "City": "Anggi",
        "Country": "Indonesia"
    },
    {
        "id": "130",
        "AirportCode": "AGE",
        "AirportName": "Flugplatz Airport",
        "City": "Wangerooge",
        "Country": "Germany"
    },
    {
        "id": "131",
        "AirportCode": "AGF",
        "AirportName": "La Garenne Airport",
        "City": "Agen",
        "Country": "France"
    },
    {
        "id": "132",
        "AirportCode": "AGG",
        "AirportName": "Angoram Airport",
        "City": "Angoram",
        "Country": "Papua New Guinea"
    },
    {
        "id": "133",
        "AirportCode": "AGH",
        "AirportName": "Angelholm Airport",
        "City": "Angelholm/Helsingborg",
        "Country": "Sweden"
    },
    {
        "id": "134",
        "AirportCode": "AGI",
        "AirportName": "Wageningen Airport",
        "City": "Wageningen",
        "Country": "Suriname"
    },
    {
        "id": "135",
        "AirportCode": "AGJ",
        "AirportName": "Aguni Airport",
        "City": "Aguni",
        "Country": "Japan"
    },
    {
        "id": "136",
        "AirportCode": "AGK",
        "AirportName": "Kagua Airport",
        "City": "Kagua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "137",
        "AirportCode": "AGL",
        "AirportName": "Wanigela Airport",
        "City": "Wanigela",
        "Country": "Papua New Guinea"
    },
    {
        "id": "138",
        "AirportCode": "AGM",
        "AirportName": "Tasiilaq Airport",
        "City": "Tasiilaq",
        "Country": "Greenland"
    },
    {
        "id": "139",
        "AirportCode": "AGN",
        "AirportName": "Angoon Airport",
        "City": "Angoon",
        "Country": "USA Alaska"
    },
    {
        "id": "140",
        "AirportCode": "AGO",
        "AirportName": "Magnolia Municipal Airport",
        "City": "Magnolia",
        "Country": "USA Arkansas"
    },
    {
        "id": "141",
        "AirportCode": "AGP",
        "AirportName": "Malaga Airport",
        "City": "Malaga",
        "Country": "Spain"
    },
    {
        "id": "142",
        "AirportCode": "AGQ",
        "AirportName": "Agrinion Airport",
        "City": "Agrinion",
        "Country": "Greece"
    },
    {
        "id": "143",
        "AirportCode": "AGR",
        "AirportName": "Kheria Airport",
        "City": "Agra",
        "Country": "India"
    },
    {
        "id": "144",
        "AirportCode": "AGS",
        "AirportName": "Bush Field",
        "City": "Augusta",
        "Country": "USA Georgia"
    },
    {
        "id": "145",
        "AirportCode": "AGT",
        "AirportName": "Alejo Garcia Airport",
        "City": "Ciudad del Este",
        "Country": "Paraguay"
    },
    {
        "id": "146",
        "AirportCode": "AGU",
        "AirportName": "Aguascalients Airport",
        "City": "Aguascalientes",
        "Country": "Mexico"
    },
    {
        "id": "147",
        "AirportCode": "AGV",
        "AirportName": "Acarigua Airport",
        "City": "Acarigua",
        "Country": "Venezuela"
    },
    {
        "id": "148",
        "AirportCode": "AGW",
        "AirportName": "Agnew Airport",
        "City": "Agnew",
        "Country": "Australia"
    },
    {
        "id": "149",
        "AirportCode": "AGX",
        "AirportName": "Agatti Island Airport",
        "City": "Agatti Island",
        "Country": "India"
    },
    {
        "id": "150",
        "AirportCode": "AGY",
        "AirportName": "Argyle Downs Airport",
        "City": "Argyle Downs",
        "Country": "Australia"
    },
    {
        "id": "151",
        "AirportCode": "AGZ",
        "AirportName": "Aggeneys Airport",
        "City": "Aggeneys",
        "Country": "South Africa"
    },
    {
        "id": "152",
        "AirportCode": "AHA",
        "AirportName": "Naha Air Force Base",
        "City": "Okinawa",
        "Country": "Japan"
    },
    {
        "id": "153",
        "AirportCode": "AHB",
        "AirportName": "Abha Airport",
        "City": "Abha",
        "Country": "Saudi Arabia"
    },
    {
        "id": "154",
        "AirportCode": "AHC",
        "AirportName": "Amedee Army Air Field",
        "City": "Herlong",
        "Country": "USA California"
    },
    {
        "id": "155",
        "AirportCode": "AHD",
        "AirportName": "Ardmore Downtown Airport",
        "City": "Ardmore",
        "Country": "USA Oklahoma"
    },
    {
        "id": "156",
        "AirportCode": "AHE",
        "AirportName": "Ahe Airport",
        "City": "Ahe",
        "Country": "French Polynesia"
    },
    {
        "id": "157",
        "AirportCode": "AHF",
        "AirportName": "Arapahoe Municipal Airport",
        "City": "Arapahoe",
        "Country": "USA Nebraska"
    },
    {
        "id": "158",
        "AirportCode": "AHH",
        "AirportName": "Amery Municipal Airport",
        "City": "Amery",
        "Country": "USA Wisconsin"
    },
    {
        "id": "159",
        "AirportCode": "AHI",
        "AirportName": "Amahai Airport",
        "City": "Amahai",
        "Country": "Indonesia"
    },
    {
        "id": "160",
        "AirportCode": "AHL",
        "AirportName": "Aishalton Airport",
        "City": "Aishalton",
        "Country": "Guyana"
    },
    {
        "id": "161",
        "AirportCode": "AHN",
        "AirportName": "Athens Airport",
        "City": "Athens",
        "Country": "USA Georgia"
    },
    {
        "id": "162",
        "AirportCode": "AHO",
        "AirportName": "Fertilia Airport",
        "City": "Alghero",
        "Country": "Italy"
    },
    {
        "id": "163",
        "AirportCode": "AHS",
        "AirportName": "Ahuas Airport",
        "City": "Ahuas",
        "Country": "Honduras"
    },
    {
        "id": "164",
        "AirportCode": "AHU",
        "AirportName": "Charif Al Idrissi Airport",
        "City": "Al Hoceima",
        "Country": "Morocco"
    },
    {
        "id": "165",
        "AirportCode": "AHY",
        "AirportName": "Ambatolahy Airport",
        "City": "Ambatolahy",
        "Country": "Madagascar"
    },
    {
        "id": "166",
        "AirportCode": "AHZ",
        "AirportName": "Alpe D Huez Airport",
        "City": "Alpe D Huez",
        "Country": "France"
    },
    {
        "id": "167",
        "AirportCode": "AIA",
        "AirportName": "Alliance Airport",
        "City": "Alliance",
        "Country": "USA Nebraska"
    },
    {
        "id": "168",
        "AirportCode": "AID",
        "AirportName": "Anderson Municipal Airport",
        "City": "Anderson",
        "Country": "USA Indiana"
    },
    {
        "id": "169",
        "AirportCode": "AIE",
        "AirportName": "Aiome Airport",
        "City": "Aiome",
        "Country": "Papua New Guinea"
    },
    {
        "id": "170",
        "AirportCode": "AIF",
        "AirportName": "Assis Airport",
        "City": "Assis",
        "Country": "Brazil"
    },
    {
        "id": "171",
        "AirportCode": "AIG",
        "AirportName": "Yalinga Airport",
        "City": "Yalinga",
        "Country": "Central African Republic"
    },
    {
        "id": "172",
        "AirportCode": "AIH",
        "AirportName": "Aiambak Airport",
        "City": "Aiambak",
        "Country": "Papua New Guinea"
    },
    {
        "id": "173",
        "AirportCode": "AII",
        "AirportName": "Ali-Sabieh Airport",
        "City": "Ali-Sabieh",
        "Country": "Djibouti"
    },
    {
        "id": "174",
        "AirportCode": "AIK",
        "AirportName": "Aiken Municipal Airport",
        "City": "Aiken",
        "Country": "USA South Carolina"
    },
    {
        "id": "175",
        "AirportCode": "AIL",
        "AirportName": "Ailigandi Airport",
        "City": "Ailigandi",
        "Country": "Panama"
    },
    {
        "id": "176",
        "AirportCode": "AIN",
        "AirportName": "Wainwright Airport",
        "City": "Wainwright",
        "Country": "USA Alaska"
    },
    {
        "id": "177",
        "AirportCode": "AIO",
        "AirportName": "Atlantic Municipal Airport",
        "City": "Atlantic",
        "Country": "USA Iowa"
    },
    {
        "id": "178",
        "AirportCode": "AIP",
        "AirportName": "Ailinglapalap Island Airport",
        "City": "Ailinglapalap Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "179",
        "AirportCode": "AIR",
        "AirportName": "Aripuana Airport",
        "City": "Aripuana",
        "Country": "Brazil"
    },
    {
        "id": "180",
        "AirportCode": "AIS",
        "AirportName": "Arorae Island Airport",
        "City": "Arorae Island",
        "Country": "Kiribati"
    },
    {
        "id": "181",
        "AirportCode": "AIT",
        "AirportName": "Aitutaki Airport",
        "City": "Aitutaki",
        "Country": "Cook Islands"
    },
    {
        "id": "182",
        "AirportCode": "AIU",
        "AirportName": "Atiu Island Airport",
        "City": "Atiu Island",
        "Country": "Cook Islands"
    },
    {
        "id": "183",
        "AirportCode": "AIV",
        "AirportName": "George Downer Airport",
        "City": "Aliceville",
        "Country": "USA Alabama"
    },
    {
        "id": "184",
        "AirportCode": "AIW",
        "AirportName": "Ai-Ais Airport",
        "City": "Ai-Ais",
        "Country": "Namibia"
    },
    {
        "id": "185",
        "AirportCode": "AIY",
        "AirportName": "Bader Field",
        "City": "Atlantic City",
        "Country": "USA New Jersey"
    },
    {
        "id": "186",
        "AirportCode": "AIZ",
        "AirportName": "Lee C Fine Memorial Airport",
        "City": "Kaiser/Lake Ozark",
        "Country": "USA Missouri"
    },
    {
        "id": "187",
        "AirportCode": "AJA",
        "AirportName": "Campo Dell Oro Airport",
        "City": "Ajaccio",
        "Country": "France"
    },
    {
        "id": "188",
        "AirportCode": "AJF",
        "AirportName": "Jouf Airport",
        "City": "Jouf",
        "Country": "Saudi Arabia"
    },
    {
        "id": "189",
        "AirportCode": "AJI",
        "AirportName": "Agri Airport",
        "City": "Agri",
        "Country": "Turkey"
    },
    {
        "id": "190",
        "AirportCode": "AJJ",
        "AirportName": "Akjoujt Airport",
        "City": "Akjoujt",
        "Country": "Mauritania"
    },
    {
        "id": "191",
        "AirportCode": "AJK",
        "AirportName": "Araak Airport",
        "City": "Araak",
        "Country": "Iran"
    },
    {
        "id": "192",
        "AirportCode": "AJL",
        "AirportName": "Aizawl Airport",
        "City": "Aizawl",
        "Country": "India"
    },
    {
        "id": "193",
        "AirportCode": "AJN",
        "AirportName": "Ouani Airport",
        "City": "Anjouan",
        "Country": "Comoros"
    },
    {
        "id": "194",
        "AirportCode": "AJO",
        "AirportName": "Aljouf Airport",
        "City": "Aljouf",
        "Country": "Yemen"
    },
    {
        "id": "195",
        "AirportCode": "AJR",
        "AirportName": "Arvidsjaur Airport",
        "City": "Arvidsjaur",
        "Country": "Sweden"
    },
    {
        "id": "196",
        "AirportCode": "AJS",
        "AirportName": "Abreojos Airport",
        "City": "Abreojos",
        "Country": "Mexico"
    },
    {
        "id": "197",
        "AirportCode": "AJU",
        "AirportName": "Aracaju Airport",
        "City": "Aracaju",
        "Country": "Brazil"
    },
    {
        "id": "198",
        "AirportCode": "AJY",
        "AirportName": "Agades Airport",
        "City": "Agades",
        "Country": "Niger"
    },
    {
        "id": "199",
        "AirportCode": "AKA",
        "AirportName": "Ankang Airport",
        "City": "Ankang",
        "Country": "China"
    },
    {
        "id": "200",
        "AirportCode": "AKB",
        "AirportName": "Atka Airport",
        "City": "Atka",
        "Country": "USA Alaska"
    },
    {
        "id": "201",
        "AirportCode": "AKC",
        "AirportName": "Fulton International Airport",
        "City": "Akron/Canton",
        "Country": "USA Ohio"
    },
    {
        "id": "202",
        "AirportCode": "AKD",
        "AirportName": "Akola Airport",
        "City": "Akola",
        "Country": "India"
    },
    {
        "id": "203",
        "AirportCode": "AKF",
        "AirportName": "Kufrah Airport",
        "City": "Kufrah",
        "Country": "Libya"
    },
    {
        "id": "204",
        "AirportCode": "AKG",
        "AirportName": "Anguganak Airport",
        "City": "Anguganak",
        "Country": "Papua New Guinea"
    },
    {
        "id": "205",
        "AirportCode": "AKH",
        "AirportName": "Prince Sultan Air Base",
        "City": "Al Kharj",
        "Country": "Saudi Arabia"
    },
    {
        "id": "206",
        "AirportCode": "AKI",
        "AirportName": "Akiak Airport",
        "City": "Akiak",
        "Country": "USA Alaska"
    },
    {
        "id": "207",
        "AirportCode": "AKJ",
        "AirportName": "Asahikawa Airport",
        "City": "Asahikawa",
        "Country": "Japan"
    },
    {
        "id": "208",
        "AirportCode": "AKK",
        "AirportName": "Akhiok Sea Plane Base",
        "City": "Akhiok",
        "Country": "USA Alaska"
    },
    {
        "id": "209",
        "AirportCode": "AKL",
        "AirportName": "Auckland International Airport",
        "City": "Auckland (AKL)",
        "Country": "New Zealand"
    },
    {
        "id": "210",
        "AirportCode": "AKM",
        "AirportName": "Zakouma Airport",
        "City": "Zakouma",
        "Country": "Mali"
    },
    {
        "id": "211",
        "AirportCode": "AKN",
        "AirportName": "King Salmon Airport",
        "City": "King Salmon",
        "Country": "USA Alaska"
    },
    {
        "id": "212",
        "AirportCode": "AKO",
        "AirportName": "Washington County Airport",
        "City": "Akron",
        "Country": "USA Colorado"
    },
    {
        "id": "213",
        "AirportCode": "AKP",
        "AirportName": "Anaktuvuk Airport",
        "City": "Anaktuvuk",
        "Country": "USA Alaska"
    },
    {
        "id": "214",
        "AirportCode": "AKQ",
        "AirportName": "Gunung Batin Airport",
        "City": "Astraksetra",
        "Country": "Indonesia"
    },
    {
        "id": "215",
        "AirportCode": "AKR",
        "AirportName": "Akure Airport",
        "City": "Akure",
        "Country": "Nigeria"
    },
    {
        "id": "216",
        "AirportCode": "AKT",
        "AirportName": "Akrotiri Royal Air Force",
        "City": "Akrotiri",
        "Country": "Cyprus"
    },
    {
        "id": "217",
        "AirportCode": "AKU",
        "AirportName": "Aksu Airport",
        "City": "Aksu",
        "Country": "China"
    },
    {
        "id": "218",
        "AirportCode": "AKV",
        "AirportName": "Akulivik Airport",
        "City": "Akulivik",
        "Country": "Canada"
    },
    {
        "id": "219",
        "AirportCode": "AKW",
        "AirportName": "Aghajari Airport",
        "City": "Aghajari",
        "Country": "Iran"
    },
    {
        "id": "220",
        "AirportCode": "AKX",
        "AirportName": "Aktyubinsk Airport",
        "City": "Aktyubinsk",
        "Country": "Kazakhstan"
    },
    {
        "id": "221",
        "AirportCode": "AKY",
        "AirportName": "Civil Airport",
        "City": "Sittwe",
        "Country": "Burma"
    },
    {
        "id": "222",
        "AirportCode": "ALA",
        "AirportName": "Almaty Airport",
        "City": "Almaty (ALA)",
        "Country": "Kazakhstan"
    },
    {
        "id": "223",
        "AirportCode": "ALB",
        "AirportName": "Albany International Airport",
        "City": "Albany",
        "Country": "USA New York"
    },
    {
        "id": "224",
        "AirportCode": "ALC",
        "AirportName": "Alicante Airport",
        "City": "Alicante",
        "Country": "Spain"
    },
    {
        "id": "225",
        "AirportCode": "ALD",
        "AirportName": "Alerta Airport",
        "City": "Alerta",
        "Country": "Peru"
    },
    {
        "id": "226",
        "AirportCode": "ALE",
        "AirportName": "Alpine Airport",
        "City": "Alpine",
        "Country": "USA Texas"
    },
    {
        "id": "227",
        "AirportCode": "ALF",
        "AirportName": "Alta Airport",
        "City": "Alta",
        "Country": "Norway"
    },
    {
        "id": "228",
        "AirportCode": "ALG",
        "AirportName": "Houari Boumediene Airport",
        "City": "Algiers",
        "Country": "Algeria"
    },
    {
        "id": "229",
        "AirportCode": "ALH",
        "AirportName": "Albany Airport",
        "City": "Albany",
        "Country": "Australia"
    },
    {
        "id": "230",
        "AirportCode": "ALI",
        "AirportName": "Alice International Airport",
        "City": "Alice",
        "Country": "USA Texas"
    },
    {
        "id": "231",
        "AirportCode": "ALJ",
        "AirportName": "Kortdoorn Airport",
        "City": "Alexander Bay",
        "Country": "South Africa"
    },
    {
        "id": "232",
        "AirportCode": "ALK",
        "AirportName": "Asela Airport",
        "City": "Asela",
        "Country": "Ethiopia"
    },
    {
        "id": "233",
        "AirportCode": "ALL",
        "AirportName": "Albenga Airport",
        "City": "Albenga",
        "Country": "Italy"
    },
    {
        "id": "234",
        "AirportCode": "ALM",
        "AirportName": "Alamogordo Municipal Airport",
        "City": "Alamogordo",
        "Country": "USA New Mexico"
    },
    {
        "id": "235",
        "AirportCode": "ALN",
        "AirportName": "Alton Airport",
        "City": "Alton",
        "Country": "USA Illinois"
    },
    {
        "id": "236",
        "AirportCode": "ALO",
        "AirportName": "Waterloo Airport",
        "City": "Waterloo",
        "Country": "USA Iowa"
    },
    {
        "id": "237",
        "AirportCode": "ALP",
        "AirportName": "Aleppo International Airport",
        "City": "Aleppo",
        "Country": "Syria"
    },
    {
        "id": "238",
        "AirportCode": "ALQ",
        "AirportName": "Federal Airport",
        "City": "Alegrete",
        "Country": "Brazil"
    },
    {
        "id": "239",
        "AirportCode": "ALR",
        "AirportName": "Alexandra Airport",
        "City": "Alexandra",
        "Country": "New Zealand"
    },
    {
        "id": "240",
        "AirportCode": "ALS",
        "AirportName": "Alamosa Municipal Airport",
        "City": "Alamosa",
        "Country": "USA Colorado"
    },
    {
        "id": "241",
        "AirportCode": "ALT",
        "AirportName": "Alenquer Airport",
        "City": "Alenquer",
        "Country": "Brazil"
    },
    {
        "id": "242",
        "AirportCode": "ALU",
        "AirportName": "Alula Airport",
        "City": "Alula",
        "Country": "Somalia"
    },
    {
        "id": "243",
        "AirportCode": "ALV",
        "AirportName": "Andorra La Vella Heliport",
        "City": "Andorra La Vella",
        "Country": "Andorra"
    },
    {
        "id": "244",
        "AirportCode": "ALW",
        "AirportName": "Walla Walla Airport",
        "City": "Walla Walla",
        "Country": "USA Washington"
    },
    {
        "id": "245",
        "AirportCode": "ALX",
        "AirportName": "Thomas C Russell Field",
        "City": "Alexander City",
        "Country": "USA Alabama"
    },
    {
        "id": "246",
        "AirportCode": "ALY",
        "AirportName": "El Nohza Airport",
        "City": "Alexandria",
        "Country": "Egypt"
    },
    {
        "id": "247",
        "AirportCode": "ALZ",
        "AirportName": "Alitak Sea Plane Base",
        "City": "Alitak",
        "Country": "USA Alaska"
    },
    {
        "id": "248",
        "AirportCode": "AMA",
        "AirportName": "Amarillo International Airport",
        "City": "Amarillo",
        "Country": "USA Texas"
    },
    {
        "id": "249",
        "AirportCode": "AMB",
        "AirportName": "Ambilobe Airport",
        "City": "Ambilobe",
        "Country": "Madagascar"
    },
    {
        "id": "250",
        "AirportCode": "AMC",
        "AirportName": "Am Timan Airport",
        "City": "Am Timan",
        "Country": "Mali"
    },
    {
        "id": "251",
        "AirportCode": "AMD",
        "AirportName": "Ahmedabad Airport",
        "City": "Ahmedabad",
        "Country": "India"
    },
    {
        "id": "252",
        "AirportCode": "AME",
        "AirportName": "Alto Molocue Airport",
        "City": "Alto Molocue",
        "Country": "Mozambique"
    },
    {
        "id": "253",
        "AirportCode": "AMF",
        "AirportName": "Ama Airport",
        "City": "Ama",
        "Country": "Papua New Guinea"
    },
    {
        "id": "254",
        "AirportCode": "AMG",
        "AirportName": "Amboin Airport",
        "City": "Amboin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "255",
        "AirportCode": "AMH",
        "AirportName": "Arba Mintch Airport",
        "City": "Arba Mintch",
        "Country": "Ethiopia"
    },
    {
        "id": "256",
        "AirportCode": "AMI",
        "AirportName": "Selaparang Airport",
        "City": "Mataram",
        "Country": "Indonesia"
    },
    {
        "id": "257",
        "AirportCode": "AMJ",
        "AirportName": "Almenara Airport",
        "City": "Almenara",
        "Country": "Brazil"
    },
    {
        "id": "258",
        "AirportCode": "AMK",
        "AirportName": "Animas Airpark",
        "City": "Durango",
        "Country": "USA Colorado"
    },
    {
        "id": "259",
        "AirportCode": "AML",
        "AirportName": "Puerto Armuelles Airport",
        "City": "Puerto Armuelles",
        "Country": "Panama"
    },
    {
        "id": "260",
        "AirportCode": "AMM",
        "AirportName": "Queen Alia International Airport",
        "City": "Amman (AMM)",
        "Country": "Jordan"
    },
    {
        "id": "261",
        "AirportCode": "AMN",
        "AirportName": "Gratiot Community Airport",
        "City": "Alma",
        "Country": "USA Michigan"
    },
    {
        "id": "262",
        "AirportCode": "AMO",
        "AirportName": "Mao Airport",
        "City": "Mao",
        "Country": "Mali"
    },
    {
        "id": "263",
        "AirportCode": "AMP",
        "AirportName": "Ampanihy Airport",
        "City": "Ampanihy",
        "Country": "Madagascar"
    },
    {
        "id": "264",
        "AirportCode": "AMQ",
        "AirportName": "Pattimura Airport",
        "City": "Ambon",
        "Country": "Indonesia"
    },
    {
        "id": "265",
        "AirportCode": "AMR",
        "AirportName": "Arno Airport",
        "City": "Arno",
        "Country": "Marshall Islands"
    },
    {
        "id": "266",
        "AirportCode": "AMS",
        "AirportName": "Amsterdam-Schiphol Airport",
        "City": "Amsterdam",
        "Country": "Netherlands"
    },
    {
        "id": "267",
        "AirportCode": "AMT",
        "AirportName": "Amata Airport",
        "City": "Amata",
        "Country": "Australia"
    },
    {
        "id": "268",
        "AirportCode": "AMU",
        "AirportName": "Amanab Airport",
        "City": "Amanab",
        "Country": "Papua New Guinea"
    },
    {
        "id": "269",
        "AirportCode": "AMV",
        "AirportName": "Amderma Airport",
        "City": "Amderma",
        "Country": "Russia"
    },
    {
        "id": "270",
        "AirportCode": "AMW",
        "AirportName": "Ames Airport",
        "City": "Ames",
        "Country": "USA Iowa"
    },
    {
        "id": "271",
        "AirportCode": "AMX",
        "AirportName": "Ammaroo Airport",
        "City": "Ammaroo",
        "Country": "Australia"
    },
    {
        "id": "272",
        "AirportCode": "AMY",
        "AirportName": "Ambatomainty Airport",
        "City": "Ambatomainty",
        "Country": "Madagascar"
    },
    {
        "id": "273",
        "AirportCode": "AMZ",
        "AirportName": "Ardmore Airport",
        "City": "Ardmore",
        "Country": "New Zealand"
    },
    {
        "id": "274",
        "AirportCode": "ANA",
        "AirportName": "Anaheim Airport",
        "City": "Anaheim",
        "Country": "USA California"
    },
    {
        "id": "275",
        "AirportCode": "ANB",
        "AirportName": "County Airport",
        "City": "Anniston",
        "Country": "USA Alabama"
    },
    {
        "id": "276",
        "AirportCode": "ANC",
        "AirportName": "Anchorage International Airport",
        "City": "Anchorage",
        "Country": "USA Alaska"
    },
    {
        "id": "277",
        "AirportCode": "AND",
        "AirportName": "Anderson Airport",
        "City": "Anderson",
        "Country": "USA South Carolina"
    },
    {
        "id": "278",
        "AirportCode": "ANE",
        "AirportName": "Arville Airport",
        "City": "Angers",
        "Country": "France"
    },
    {
        "id": "279",
        "AirportCode": "ANF",
        "AirportName": "Cerro Moreno Airport",
        "City": "Antofagasta",
        "Country": "Chile"
    },
    {
        "id": "280",
        "AirportCode": "ANG",
        "AirportName": "Brie-Champniers Airport",
        "City": "Angouleme",
        "Country": "France"
    },
    {
        "id": "281",
        "AirportCode": "ANH",
        "AirportName": "Anuha Island Resort Airport",
        "City": "Anuha Island Resort",
        "Country": "Solomon Islands"
    },
    {
        "id": "282",
        "AirportCode": "ANI",
        "AirportName": "Aniak Airport",
        "City": "Aniak",
        "Country": "USA Alaska"
    },
    {
        "id": "283",
        "AirportCode": "ANJ",
        "AirportName": "Zanaga Airport",
        "City": "Zanaga",
        "Country": "Congo"
    },
    {
        "id": "284",
        "AirportCode": "ANK",
        "AirportName": "Etimesgut Airport",
        "City": "Ankara",
        "Country": "Turkey"
    },
    {
        "id": "285",
        "AirportCode": "ANM",
        "AirportName": "Antsirabato",
        "City": "Antalaha",
        "Country": "Madagascar"
    },
    {
        "id": "286",
        "AirportCode": "ANO",
        "AirportName": "Angoche Airport",
        "City": "Angoche",
        "Country": "Mozambique"
    },
    {
        "id": "287",
        "AirportCode": "ANP",
        "AirportName": "Lee Airport",
        "City": "Annapolis",
        "Country": "USA Maryland"
    },
    {
        "id": "288",
        "AirportCode": "ANQ",
        "AirportName": "Tri-State Steuben County Airport",
        "City": "Angola",
        "Country": "USA Indiana"
    },
    {
        "id": "289",
        "AirportCode": "ANR",
        "AirportName": "Deurne Airport",
        "City": "Antwerp",
        "Country": "Belgium"
    },
    {
        "id": "290",
        "AirportCode": "ANS",
        "AirportName": "Andahuaylas Airport",
        "City": "Andahuaylas",
        "Country": "Peru"
    },
    {
        "id": "291",
        "AirportCode": "ANT",
        "AirportName": "St. Anton Airport",
        "City": "Saint Anton",
        "Country": "Austria"
    },
    {
        "id": "292",
        "AirportCode": "ANU",
        "AirportName": "V.C. Bird International Airport",
        "City": "Antigua",
        "Country": "Antigua & Barbuda"
    },
    {
        "id": "293",
        "AirportCode": "ANV",
        "AirportName": "Anvik Airport",
        "City": "Anvik",
        "Country": "USA Alaska"
    },
    {
        "id": "294",
        "AirportCode": "ANW",
        "AirportName": "Ainsworth Airport",
        "City": "Ainsworth",
        "Country": "USA Nebraska"
    },
    {
        "id": "295",
        "AirportCode": "ANX",
        "AirportName": "Andenes Airport",
        "City": "Andenes",
        "Country": "Norway"
    },
    {
        "id": "296",
        "AirportCode": "ANY",
        "AirportName": "Anthony Airport",
        "City": "Anthony",
        "Country": "USA Kansas"
    },
    {
        "id": "297",
        "AirportCode": "ANZ",
        "AirportName": "Angus Downs Airport",
        "City": "Angus Downs",
        "Country": "Australia"
    },
    {
        "id": "298",
        "AirportCode": "AOA",
        "AirportName": "Aroa Airport",
        "City": "Aroa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "299",
        "AirportCode": "AOB",
        "AirportName": "Annanberg Airport",
        "City": "Annanberg",
        "Country": "Papua New Guinea"
    },
    {
        "id": "300",
        "AirportCode": "AOC",
        "AirportName": "Altenburg Nobitz Airport",
        "City": "Altenburg",
        "Country": "Germany"
    },
    {
        "id": "301",
        "AirportCode": "AOD",
        "AirportName": "Abou Deia Airport",
        "City": "Abou Deia",
        "Country": "Mali"
    },
    {
        "id": "302",
        "AirportCode": "AOE",
        "AirportName": "Anadolu University Airport",
        "City": "Eskisehir",
        "Country": "Turkey"
    },
    {
        "id": "303",
        "AirportCode": "AOG",
        "AirportName": "Anshan Airport",
        "City": "Anshan",
        "Country": "China"
    },
    {
        "id": "304",
        "AirportCode": "AOH",
        "AirportName": "Allen County Airport",
        "City": "Lima (LIM)",
        "Country": "USA Ohio"
    },
    {
        "id": "305",
        "AirportCode": "AOI",
        "AirportName": "Falconara Airport",
        "City": "Ancona",
        "Country": "Italy"
    },
    {
        "id": "306",
        "AirportCode": "AOJ",
        "AirportName": "Aomori Airport",
        "City": "Aomori",
        "Country": "Japan"
    },
    {
        "id": "307",
        "AirportCode": "AOK",
        "AirportName": "Karpathos Airport",
        "City": "Karpathos",
        "Country": "Greece"
    },
    {
        "id": "308",
        "AirportCode": "AOL",
        "AirportName": "Paso de los Libres Airport",
        "City": "Paso de los Libres",
        "Country": "Argentina"
    },
    {
        "id": "309",
        "AirportCode": "AON",
        "AirportName": "Arona Airport",
        "City": "Arona",
        "Country": "Papua New Guinea"
    },
    {
        "id": "310",
        "AirportCode": "AOO",
        "AirportName": "Altoona Airport",
        "City": "Altoona",
        "Country": "USA Philadelphia"
    },
    {
        "id": "311",
        "AirportCode": "AOR",
        "AirportName": "Alor Setar Airport",
        "City": "Alor Setar",
        "Country": "Malaysia"
    },
    {
        "id": "312",
        "AirportCode": "AOS",
        "AirportName": "Amook Airport",
        "City": "Amook",
        "Country": "USA Alaska"
    },
    {
        "id": "313",
        "AirportCode": "AOT",
        "AirportName": "Corrado Gex Airport",
        "City": "Aosta",
        "Country": "Italy"
    },
    {
        "id": "314",
        "AirportCode": "AOU",
        "AirportName": "Attopeu Airport",
        "City": "Attopeu",
        "Country": "Laos"
    },
    {
        "id": "315",
        "AirportCode": "AOY",
        "AirportName": "Asaloyeh Airport",
        "City": "Asaloyeh",
        "Country": "Iran"
    },
    {
        "id": "316",
        "AirportCode": "APA",
        "AirportName": "Arapahoe County Airport",
        "City": "Denver",
        "Country": "USA Colorado"
    },
    {
        "id": "317",
        "AirportCode": "APB",
        "AirportName": "Apolo Airport",
        "City": "Apolo",
        "Country": "Bolivia"
    },
    {
        "id": "318",
        "AirportCode": "APC",
        "AirportName": "Napa County Airport",
        "City": "Napa",
        "Country": "USA California"
    },
    {
        "id": "319",
        "AirportCode": "APE",
        "AirportName": "San Juan Aposento Airport",
        "City": "San Juan Aposento",
        "Country": "Peru"
    },
    {
        "id": "320",
        "AirportCode": "APF",
        "AirportName": "Naples Airport",
        "City": "Naples",
        "Country": "USA Florida"
    },
    {
        "id": "321",
        "AirportCode": "APG",
        "AirportName": "Phillips Army Air Field",
        "City": "Aberdeen",
        "Country": "USA Maryland"
    },
    {
        "id": "322",
        "AirportCode": "APH",
        "AirportName": "Camp A P Hill Airport",
        "City": "Bowling Green",
        "Country": "USA Virginia"
    },
    {
        "id": "323",
        "AirportCode": "API",
        "AirportName": "Apiay Airport",
        "City": "Apiay",
        "Country": "Colombia"
    },
    {
        "id": "324",
        "AirportCode": "APK",
        "AirportName": "Apataki Airport",
        "City": "Apataki",
        "Country": "French Polynesia"
    },
    {
        "id": "325",
        "AirportCode": "APL",
        "AirportName": "Nampula Airport",
        "City": "Nampula",
        "Country": "Mozambique"
    },
    {
        "id": "326",
        "AirportCode": "APN",
        "AirportName": "Alpena County Regional Airport",
        "City": "Alpena",
        "Country": "USA Michigan"
    },
    {
        "id": "327",
        "AirportCode": "APO",
        "AirportName": "Apartado Airport",
        "City": "Apartado",
        "Country": "Colombia"
    },
    {
        "id": "328",
        "AirportCode": "APP",
        "AirportName": "Asapa Airport",
        "City": "Asapa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "329",
        "AirportCode": "APQ",
        "AirportName": "Arapiraca Airport",
        "City": "Arapiraca",
        "Country": "Brazil"
    },
    {
        "id": "330",
        "AirportCode": "APR",
        "AirportName": "April River Airport",
        "City": "April River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "331",
        "AirportCode": "APS",
        "AirportName": "Anapolis Airport",
        "City": "Anapolis",
        "Country": "Brazil"
    },
    {
        "id": "332",
        "AirportCode": "APT",
        "AirportName": "Marion County Airport",
        "City": "Jasper",
        "Country": "USA Tennessee"
    },
    {
        "id": "333",
        "AirportCode": "APU",
        "AirportName": "Apucarana Airport",
        "City": "Apucarana",
        "Country": "Brazil"
    },
    {
        "id": "334",
        "AirportCode": "APV",
        "AirportName": "Apple Valley Airport",
        "City": "Apple Valley",
        "Country": "USA California"
    },
    {
        "id": "335",
        "AirportCode": "APW",
        "AirportName": "Faleolo Airport",
        "City": "Apia",
        "Country": "Samoa"
    },
    {
        "id": "336",
        "AirportCode": "APX",
        "AirportName": "Arapongas Airport",
        "City": "Arapongas",
        "Country": "Brazil"
    },
    {
        "id": "337",
        "AirportCode": "APY",
        "AirportName": "Alto Parnaiba Airport",
        "City": "Alto Parnaiba",
        "Country": "Brazil"
    },
    {
        "id": "338",
        "AirportCode": "APZ",
        "AirportName": "Zapala Airport",
        "City": "Zapala",
        "Country": "Argentina"
    },
    {
        "id": "339",
        "AirportCode": "AQA",
        "AirportName": "Araraquara Airport",
        "City": "Araraquara",
        "Country": "Brazil"
    },
    {
        "id": "340",
        "AirportCode": "AQG",
        "AirportName": "Anqing Airport",
        "City": "Anqing",
        "Country": "China"
    },
    {
        "id": "341",
        "AirportCode": "AQI",
        "AirportName": "Qaisumah Airport",
        "City": "Qaisumah",
        "Country": "Saudi Arabia"
    },
    {
        "id": "342",
        "AirportCode": "AQJ",
        "AirportName": "King Hussein International Airport",
        "City": "Aqaba",
        "Country": "Jordan"
    },
    {
        "id": "343",
        "AirportCode": "AQM",
        "AirportName": "Ariquemes Airport",
        "City": "Ariquemes",
        "Country": "Brazil"
    },
    {
        "id": "344",
        "AirportCode": "AQP",
        "AirportName": "Rodriguez Ballon Airport",
        "City": "Arequipa",
        "Country": "Peru"
    },
    {
        "id": "345",
        "AirportCode": "AQS",
        "AirportName": "Saqani Airport",
        "City": "Saqani",
        "Country": "Fiji"
    },
    {
        "id": "346",
        "AirportCode": "ARA",
        "AirportName": "Acadiana Regional Airport",
        "City": "New Iberia",
        "Country": "USA Louisiana"
    },
    {
        "id": "347",
        "AirportCode": "ARB",
        "AirportName": "Ann Arbor Municipal Airport",
        "City": "Ann Arbor",
        "Country": "USA Michigan"
    },
    {
        "id": "348",
        "AirportCode": "ARC",
        "AirportName": "Arctic Village Airport",
        "City": "Arctic Village",
        "Country": "USA Alaska"
    },
    {
        "id": "349",
        "AirportCode": "ARD",
        "AirportName": "Alor Island Airport",
        "City": "Alor Island",
        "Country": "Indonesia"
    },
    {
        "id": "350",
        "AirportCode": "ARE",
        "AirportName": "Arecibo Airport",
        "City": "Arecibo",
        "Country": "Puerto Rico"
    },
    {
        "id": "351",
        "AirportCode": "ARF",
        "AirportName": "Acaricuara Airport",
        "City": "Acaricuara",
        "Country": "Colombia"
    },
    {
        "id": "352",
        "AirportCode": "ARG",
        "AirportName": "Walnut Ridge Airport",
        "City": "Walnut Ridge",
        "Country": "USA Arkansas"
    },
    {
        "id": "353",
        "AirportCode": "ARH",
        "AirportName": "Arkhangelsk Airport",
        "City": "Arkhangelsk",
        "Country": "Russia"
    },
    {
        "id": "354",
        "AirportCode": "ARI",
        "AirportName": "Chacalluta Airport",
        "City": "Arica",
        "Country": "Chile"
    },
    {
        "id": "355",
        "AirportCode": "ARJ",
        "AirportName": "Arso Airport",
        "City": "Arso",
        "Country": "Indonesia"
    },
    {
        "id": "356",
        "AirportCode": "ARK",
        "AirportName": "Arusha Airport",
        "City": "Arusha",
        "Country": "Tanzania"
    },
    {
        "id": "357",
        "AirportCode": "ARL",
        "AirportName": "Arly Airport",
        "City": "Arly",
        "Country": "Burkina Faso"
    },
    {
        "id": "358",
        "AirportCode": "ARM",
        "AirportName": "Armidale Airport",
        "City": "Armidale",
        "Country": "Australia"
    },
    {
        "id": "359",
        "AirportCode": "ARN",
        "AirportName": "Arlanda Airport",
        "City": "Stockholm",
        "Country": "Sweden"
    },
    {
        "id": "360",
        "AirportCode": "ARO",
        "AirportName": "Arboletas Airport",
        "City": "Arboletas",
        "Country": "Colombia"
    },
    {
        "id": "361",
        "AirportCode": "ARP",
        "AirportName": "Aragip Airport",
        "City": "Aragip",
        "Country": "Papua New Guinea"
    },
    {
        "id": "362",
        "AirportCode": "ARQ",
        "AirportName": "Arauquita Airport",
        "City": "Arauquita",
        "Country": "Colombia"
    },
    {
        "id": "363",
        "AirportCode": "ARR",
        "AirportName": "Alto Rio Senguerr Airport",
        "City": "Alto Rio Senguerr",
        "Country": "Argentina"
    },
    {
        "id": "364",
        "AirportCode": "ARS",
        "AirportName": "Aragarcas Airport",
        "City": "Aragarcas",
        "Country": "Brazil"
    },
    {
        "id": "365",
        "AirportCode": "ART",
        "AirportName": "Watertown Airport",
        "City": "Watertown",
        "Country": "USA New York"
    },
    {
        "id": "366",
        "AirportCode": "ARU",
        "AirportName": "Aracatuba Airport",
        "City": "Aracatuba",
        "Country": "Brazil"
    },
    {
        "id": "367",
        "AirportCode": "ARV",
        "AirportName": "Noble F. Lee Airport",
        "City": "Minocqua",
        "Country": "USA Wisconsin"
    },
    {
        "id": "368",
        "AirportCode": "ARW",
        "AirportName": "Arad Airport",
        "City": "Arad",
        "Country": "Romania"
    },
    {
        "id": "369",
        "AirportCode": "ARX",
        "AirportName": "Asbury Park Airport",
        "City": "Asbury Park",
        "Country": "USA New Jersey"
    },
    {
        "id": "370",
        "AirportCode": "ARY",
        "AirportName": "Ararat Airport",
        "City": "Ararat",
        "Country": "Australia"
    },
    {
        "id": "371",
        "AirportCode": "ASA",
        "AirportName": "Assab Airport",
        "City": "Assab",
        "Country": "Eritrea"
    },
    {
        "id": "372",
        "AirportCode": "ASB",
        "AirportName": "Ashgabat Airport",
        "City": "Ashgabat",
        "Country": "Turkmenistan"
    },
    {
        "id": "373",
        "AirportCode": "ASC",
        "AirportName": "Ascension Airport",
        "City": "Ascension",
        "Country": "Bolivia"
    },
    {
        "id": "374",
        "AirportCode": "ASD",
        "AirportName": "Andros Town Airport",
        "City": "Andros Town",
        "Country": "Bahamas"
    },
    {
        "id": "375",
        "AirportCode": "ASE",
        "AirportName": "Aspen Airport",
        "City": "Aspen",
        "Country": "USA Colorado"
    },
    {
        "id": "376",
        "AirportCode": "ASF",
        "AirportName": "Astrakhan Airport",
        "City": "Astrakhan",
        "Country": "Russia"
    },
    {
        "id": "377",
        "AirportCode": "ASG",
        "AirportName": "Ashburton Airport",
        "City": "Ashburton",
        "Country": "New Zealand"
    },
    {
        "id": "378",
        "AirportCode": "ASH",
        "AirportName": "Boire Field",
        "City": "Nashua",
        "Country": "USA New Hampshire"
    },
    {
        "id": "379",
        "AirportCode": "ASI",
        "AirportName": "Wideawake Field",
        "City": "Georgetown",
        "Country": "St Helena"
    },
    {
        "id": "380",
        "AirportCode": "ASJ",
        "AirportName": "Amami O Shima Airport",
        "City": "Amami O Shima",
        "Country": "Japan"
    },
    {
        "id": "381",
        "AirportCode": "ASL",
        "AirportName": "Harrison County Airport",
        "City": "Marshall",
        "Country": "USA Texas"
    },
    {
        "id": "382",
        "AirportCode": "ASM",
        "AirportName": "Asmara International Airport",
        "City": "Asmara",
        "Country": "Eritrea"
    },
    {
        "id": "383",
        "AirportCode": "ASN",
        "AirportName": "Talladega Airport",
        "City": "Talladega",
        "Country": "USA Alabama"
    },
    {
        "id": "384",
        "AirportCode": "ASO",
        "AirportName": "Asosa Airport",
        "City": "Asosa",
        "Country": "Ethiopia"
    },
    {
        "id": "385",
        "AirportCode": "ASP",
        "AirportName": "Alice Springs Airport",
        "City": "Alice Springs",
        "Country": "Australia"
    },
    {
        "id": "386",
        "AirportCode": "ASQ",
        "AirportName": "Austin Airport",
        "City": "Austin",
        "Country": "USA Nevada"
    },
    {
        "id": "387",
        "AirportCode": "ASR",
        "AirportName": "Kayseri Airport",
        "City": "Kayseri",
        "Country": "Turkey"
    },
    {
        "id": "388",
        "AirportCode": "AST",
        "AirportName": "Astoria Airport",
        "City": "Astoria",
        "Country": "USA Oregon"
    },
    {
        "id": "389",
        "AirportCode": "ASU",
        "AirportName": "Silvio Pettirossi Airport",
        "City": "Asuncion (ASU)",
        "Country": "Paraguay"
    },
    {
        "id": "390",
        "AirportCode": "ASV",
        "AirportName": "Amboseli Airport",
        "City": "Amboseli",
        "Country": "Kenya"
    },
    {
        "id": "391",
        "AirportCode": "ASW",
        "AirportName": "Aswan Airport",
        "City": "Aswan",
        "Country": "Egypt"
    },
    {
        "id": "392",
        "AirportCode": "ASX",
        "AirportName": "Ashland Airport",
        "City": "Ashland",
        "Country": "USA Wisconsin"
    },
    {
        "id": "393",
        "AirportCode": "ASY",
        "AirportName": "Ashley Airport",
        "City": "Ashley",
        "Country": "USA North Dakota"
    },
    {
        "id": "394",
        "AirportCode": "ASZ",
        "AirportName": "Asirim Airport",
        "City": "Asirim",
        "Country": "Papua New Guinea"
    },
    {
        "id": "395",
        "AirportCode": "ATA",
        "AirportName": "Anta Airport",
        "City": "Anta",
        "Country": "Peru"
    },
    {
        "id": "396",
        "AirportCode": "ATB",
        "AirportName": "Atbara Airport",
        "City": "Atbara",
        "Country": "Sudan"
    },
    {
        "id": "397",
        "AirportCode": "ATD",
        "AirportName": "Atoifi Airport",
        "City": "Atoifi",
        "Country": "Solomon Islands"
    },
    {
        "id": "398",
        "AirportCode": "ATE",
        "AirportName": "Antlers Airport",
        "City": "Antlers",
        "Country": "USA Oklahoma"
    },
    {
        "id": "399",
        "AirportCode": "ATF",
        "AirportName": "Chachoan Airport",
        "City": "Ambato",
        "Country": "Ecuador"
    },
    {
        "id": "400",
        "AirportCode": "ATG",
        "AirportName": "Attock Airport",
        "City": "Attock",
        "Country": "Pakistan"
    },
    {
        "id": "401",
        "AirportCode": "ATH",
        "AirportName": "Eleftherios Venizelos International Airport",
        "City": "Athens",
        "Country": "Greece"
    },
    {
        "id": "402",
        "AirportCode": "ATI",
        "AirportName": "Artigas Airport",
        "City": "Artigas",
        "Country": "Uruguay"
    },
    {
        "id": "403",
        "AirportCode": "ATJ",
        "AirportName": "Antsirabe Airport",
        "City": "Antsirabe",
        "Country": "Madagascar"
    },
    {
        "id": "404",
        "AirportCode": "ATK",
        "AirportName": "Atqasuk Airport",
        "City": "Atqasuk",
        "Country": "USA Alaska"
    },
    {
        "id": "405",
        "AirportCode": "ATL",
        "AirportName": "Hartsfield-Jackson Atlanta International Airport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "406",
        "AirportCode": "ATM",
        "AirportName": "Altamira Airport",
        "City": "Altamira",
        "Country": "Brazil"
    },
    {
        "id": "407",
        "AirportCode": "ATN",
        "AirportName": "Namatanai Airport",
        "City": "Namatanai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "408",
        "AirportCode": "ATO",
        "AirportName": "Ohio University Airport",
        "City": "Athens",
        "Country": "USA Ohio"
    },
    {
        "id": "409",
        "AirportCode": "ATP",
        "AirportName": "Aitape Airstrip",
        "City": "Aitape",
        "Country": "Papua New Guinea"
    },
    {
        "id": "410",
        "AirportCode": "ATQ",
        "AirportName": "Raja Sansi Airport",
        "City": "Amritsar",
        "Country": "India"
    },
    {
        "id": "411",
        "AirportCode": "ATR",
        "AirportName": "Mouakchott Airport",
        "City": "Atar",
        "Country": "Mauritania"
    },
    {
        "id": "412",
        "AirportCode": "ATS",
        "AirportName": "Artesia Airport",
        "City": "Artesia",
        "Country": "USA New Mexico"
    },
    {
        "id": "413",
        "AirportCode": "ATT",
        "AirportName": "Atmautluak Airport",
        "City": "Atmautluak",
        "Country": "USA Alaska"
    },
    {
        "id": "414",
        "AirportCode": "ATV",
        "AirportName": "Ati Airport",
        "City": "Ati",
        "Country": "Mali"
    },
    {
        "id": "415",
        "AirportCode": "ATW",
        "AirportName": "Outagamie County Airport",
        "City": "Appleton",
        "Country": "USA Wisconsin"
    },
    {
        "id": "416",
        "AirportCode": "ATX",
        "AirportName": "Atbasar Airport",
        "City": "Atbasar",
        "Country": "Kazakhstan"
    },
    {
        "id": "417",
        "AirportCode": "ATY",
        "AirportName": "Watertown Airport",
        "City": "Watertown",
        "Country": "USA South Dakota"
    },
    {
        "id": "418",
        "AirportCode": "ATZ",
        "AirportName": "Assiut Airport",
        "City": "Assiut",
        "Country": "Egypt"
    },
    {
        "id": "419",
        "AirportCode": "AUA",
        "AirportName": "Reina Beatrix Airport",
        "City": "Aruba",
        "Country": "Aruba"
    },
    {
        "id": "420",
        "AirportCode": "AUB",
        "AirportName": "Itauba Airport",
        "City": "Itauba",
        "Country": "Brazil"
    },
    {
        "id": "421",
        "AirportCode": "AUC",
        "AirportName": "Arauca Airport",
        "City": "Arauca",
        "Country": "Colombia"
    },
    {
        "id": "422",
        "AirportCode": "AUD",
        "AirportName": "Augustus Downs Airport",
        "City": "Augustus Downs",
        "Country": "Australia"
    },
    {
        "id": "423",
        "AirportCode": "AUE",
        "AirportName": "Abu Rudeis Airport",
        "City": "Abu Rudeis",
        "Country": "Egypt"
    },
    {
        "id": "424",
        "AirportCode": "AUF",
        "AirportName": "Aeroport Auxerre Branches",
        "City": "Auxerre",
        "Country": "France"
    },
    {
        "id": "425",
        "AirportCode": "AUG",
        "AirportName": "Augusta Airport",
        "City": "Augusta",
        "Country": "USA Maine"
    },
    {
        "id": "426",
        "AirportCode": "AUH",
        "AirportName": "Abu Dhabi International Airport",
        "City": "Abu Dhabi",
        "Country": "UAE"
    },
    {
        "id": "427",
        "AirportCode": "AUI",
        "AirportName": "Aua Island Airport",
        "City": "Aua Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "428",
        "AirportCode": "AUJ",
        "AirportName": "Ambunti Airport",
        "City": "Ambunti",
        "Country": "Papua New Guinea"
    },
    {
        "id": "429",
        "AirportCode": "AUK",
        "AirportName": "Alakanuk Airport",
        "City": "Alakanuk",
        "Country": "USA Alaska"
    },
    {
        "id": "430",
        "AirportCode": "AUM",
        "AirportName": "Austin Airport",
        "City": "Austin",
        "Country": "USA Minnesota"
    },
    {
        "id": "431",
        "AirportCode": "AUN",
        "AirportName": "Auburn Airport",
        "City": "Auburn",
        "Country": "USA California"
    },
    {
        "id": "432",
        "AirportCode": "AUO",
        "AirportName": "Auburn-Opelika Airport",
        "City": "Auburn",
        "Country": "USA Alabama"
    },
    {
        "id": "433",
        "AirportCode": "AUP",
        "AirportName": "Agaun Airport",
        "City": "Agaun",
        "Country": "Papua New Guinea"
    },
    {
        "id": "434",
        "AirportCode": "AUQ",
        "AirportName": "Atuona Airport",
        "City": "Atuona",
        "Country": "French Polynesia"
    },
    {
        "id": "435",
        "AirportCode": "AUR",
        "AirportName": "Aurillac Airport",
        "City": "Aurillac",
        "Country": "France"
    },
    {
        "id": "436",
        "AirportCode": "AUS",
        "AirportName": "Austin-Bergstrom International Airport",
        "City": "Austin",
        "Country": "USA Texas"
    },
    {
        "id": "437",
        "AirportCode": "AUT",
        "AirportName": "Atauro Airport",
        "City": "Atauro",
        "Country": "Indonesia"
    },
    {
        "id": "438",
        "AirportCode": "AUU",
        "AirportName": "Aurukun Mission Airport",
        "City": "Aurukun Mission",
        "Country": "Australia"
    },
    {
        "id": "439",
        "AirportCode": "AUV",
        "AirportName": "Aumo Airport",
        "City": "Aumo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "440",
        "AirportCode": "AUW",
        "AirportName": "Wausau Municipal Airport",
        "City": "Wausau",
        "Country": "USA Wisconsin"
    },
    {
        "id": "441",
        "AirportCode": "AUX",
        "AirportName": "Araguaina Airport",
        "City": "Araguaina",
        "Country": "Brazil"
    },
    {
        "id": "442",
        "AirportCode": "AUY",
        "AirportName": "Aneityum Airport",
        "City": "Aneityum",
        "Country": "Vanuatu"
    },
    {
        "id": "443",
        "AirportCode": "AUZ",
        "AirportName": "Aurora Municipal Airport",
        "City": "Aurora",
        "Country": "USA Illinois"
    },
    {
        "id": "444",
        "AirportCode": "AVA",
        "AirportName": "An Shun/Huang Guo Shu Airport",
        "City": "An Shun",
        "Country": "China"
    },
    {
        "id": "445",
        "AirportCode": "AVB",
        "AirportName": "Aviano Airport",
        "City": "Aviano",
        "Country": "Italy"
    },
    {
        "id": "446",
        "AirportCode": "AVF",
        "AirportName": "Avoriaz Airport",
        "City": "Avoriaz",
        "Country": "France"
    },
    {
        "id": "447",
        "AirportCode": "AVG",
        "AirportName": "Auvergne Airport",
        "City": "Auvergne",
        "Country": "Australia"
    },
    {
        "id": "448",
        "AirportCode": "AVI",
        "AirportName": "Maximo Gomez Airport",
        "City": "Ciego De Avila",
        "Country": "Cuba"
    },
    {
        "id": "449",
        "AirportCode": "AVK",
        "AirportName": "Arvaikheer Airport",
        "City": "Arvaikheer",
        "Country": "Mongolia"
    },
    {
        "id": "450",
        "AirportCode": "AVL",
        "AirportName": "Asheville Regional Airport",
        "City": "Asheville",
        "Country": "USA North Carolina"
    },
    {
        "id": "451",
        "AirportCode": "AVN",
        "AirportName": "Avignon-Caum Airport",
        "City": "Avignon",
        "Country": "France"
    },
    {
        "id": "452",
        "AirportCode": "AVO",
        "AirportName": "Avon Park Municipal Airport",
        "City": "Avon Park",
        "Country": "USA Florida"
    },
    {
        "id": "453",
        "AirportCode": "AVP",
        "AirportName": "Wilkes-Barre International Airport",
        "City": "Wilkes-Barre",
        "Country": "USA Philadelphia"
    },
    {
        "id": "454",
        "AirportCode": "AVU",
        "AirportName": "Avu Avu Airport",
        "City": "Avu Avu",
        "Country": "Solomon Islands"
    },
    {
        "id": "455",
        "AirportCode": "AVV",
        "AirportName": "Avalon Airport",
        "City": "Avalon",
        "Country": "Australia"
    },
    {
        "id": "456",
        "AirportCode": "AVW",
        "AirportName": "Avra Valley Airport",
        "City": "Tucson",
        "Country": "USA Arizona"
    },
    {
        "id": "457",
        "AirportCode": "AVX",
        "AirportName": "Avalon Bay Airport",
        "City": "Catalina Island",
        "Country": "USA California"
    },
    {
        "id": "458",
        "AirportCode": "AWA",
        "AirportName": "Awasa Airport",
        "City": "Awasa",
        "Country": "Ethiopia"
    },
    {
        "id": "459",
        "AirportCode": "AWB",
        "AirportName": "Awaba Airport",
        "City": "Awaba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "460",
        "AirportCode": "AWD",
        "AirportName": "Aniwa Airport",
        "City": "Aniwa",
        "Country": "Vanuatu"
    },
    {
        "id": "461",
        "AirportCode": "AWE",
        "AirportName": "Alowe Airport",
        "City": "Alowe",
        "Country": "Gabon"
    },
    {
        "id": "462",
        "AirportCode": "AWH",
        "AirportName": "Awareh Airport",
        "City": "Awareh",
        "Country": "Ethiopia"
    },
    {
        "id": "463",
        "AirportCode": "AWK",
        "AirportName": "Wake Island Airport",
        "City": "Wake Island",
        "Country": "Wake Island"
    },
    {
        "id": "464",
        "AirportCode": "AWM",
        "AirportName": "West Memphis Municipal Airport",
        "City": "West Memphis",
        "Country": "USA Arkansas"
    },
    {
        "id": "465",
        "AirportCode": "AWN",
        "AirportName": "Alton Downs Airport",
        "City": "Alton Downs",
        "Country": "Australia"
    },
    {
        "id": "466",
        "AirportCode": "AWP",
        "AirportName": "Austral Downs Airport",
        "City": "Austral Downs",
        "Country": "Australia"
    },
    {
        "id": "467",
        "AirportCode": "AWR",
        "AirportName": "Awar Airport",
        "City": "Awar",
        "Country": "Papua New Guinea"
    },
    {
        "id": "468",
        "AirportCode": "AWZ",
        "AirportName": "Ahwaz Airport",
        "City": "Ahwaz",
        "Country": "Iran"
    },
    {
        "id": "469",
        "AirportCode": "AXA",
        "AirportName": "Wallblake Airport",
        "City": "Anguilla",
        "Country": "Anguilla"
    },
    {
        "id": "470",
        "AirportCode": "AXB",
        "AirportName": "Alexandria Bay Airport",
        "City": "Alexandria Bay",
        "Country": "USA New York"
    },
    {
        "id": "471",
        "AirportCode": "AXC",
        "AirportName": "Aramac Airport",
        "City": "Aramac",
        "Country": "Australia"
    },
    {
        "id": "472",
        "AirportCode": "AXD",
        "AirportName": "Demokritos Airport",
        "City": "Alexandroupolis",
        "Country": "Greece"
    },
    {
        "id": "473",
        "AirportCode": "AXE",
        "AirportName": "Xanxere Airport",
        "City": "Xanxere",
        "Country": "Brazil"
    },
    {
        "id": "474",
        "AirportCode": "AXG",
        "AirportName": "Algona Airport",
        "City": "Algona",
        "Country": "USA Iowa"
    },
    {
        "id": "475",
        "AirportCode": "AXK",
        "AirportName": "Ataq Airport",
        "City": "Ataq",
        "Country": "Yemen"
    },
    {
        "id": "476",
        "AirportCode": "AXL",
        "AirportName": "Alexandria Airport",
        "City": "Alexandria",
        "Country": "Australia"
    },
    {
        "id": "477",
        "AirportCode": "AXM",
        "AirportName": "El Eden Airport",
        "City": "Armenia",
        "Country": "Colombia"
    },
    {
        "id": "478",
        "AirportCode": "AXN",
        "AirportName": "Alexandria Airport",
        "City": "Alexandria",
        "Country": "USA Minnesota"
    },
    {
        "id": "479",
        "AirportCode": "AXP",
        "AirportName": "Springpoint Airport",
        "City": "Spring Point",
        "Country": "Bahamas"
    },
    {
        "id": "480",
        "AirportCode": "AXR",
        "AirportName": "Arutua Airport",
        "City": "Arutua",
        "Country": "French Polynesia"
    },
    {
        "id": "481",
        "AirportCode": "AXS",
        "AirportName": "Altus Municipal Airport",
        "City": "Altus",
        "Country": "USA Oklahoma"
    },
    {
        "id": "482",
        "AirportCode": "AXT",
        "AirportName": "Akita Airport",
        "City": "Akita",
        "Country": "Japan"
    },
    {
        "id": "483",
        "AirportCode": "AXU",
        "AirportName": "Axum Airport",
        "City": "Axum",
        "Country": "Ethiopia"
    },
    {
        "id": "484",
        "AirportCode": "AXV",
        "AirportName": "Neil Armstrong Airport",
        "City": "Wapakoneta",
        "Country": "USA Ohio"
    },
    {
        "id": "485",
        "AirportCode": "AXX",
        "AirportName": "Angel Fire Airport",
        "City": "Angel Fire",
        "Country": "USA New Mexico"
    },
    {
        "id": "486",
        "AirportCode": "AYA",
        "AirportName": "Ayapel Airport",
        "City": "Ayapel",
        "Country": "Colombia"
    },
    {
        "id": "487",
        "AirportCode": "AYC",
        "AirportName": "Ayacucho Airport",
        "City": "Ayacucho",
        "Country": "Colombia"
    },
    {
        "id": "488",
        "AirportCode": "AYD",
        "AirportName": "Alroy Downs Airport",
        "City": "Alroy Downs",
        "Country": "Australia"
    },
    {
        "id": "489",
        "AirportCode": "AYE",
        "AirportName": "Army Air Field Heliport",
        "City": "Fort Devens",
        "Country": "USA Massachusetts"
    },
    {
        "id": "490",
        "AirportCode": "AYG",
        "AirportName": "Yaguara Airport",
        "City": "Yaguara",
        "Country": "Colombia"
    },
    {
        "id": "491",
        "AirportCode": "AYH",
        "AirportName": "Royal Air Force Station",
        "City": "Alconbury",
        "Country": "United Kingdom"
    },
    {
        "id": "492",
        "AirportCode": "AYI",
        "AirportName": "Yari Airport",
        "City": "Yari",
        "Country": "Colombia"
    },
    {
        "id": "493",
        "AirportCode": "AYK",
        "AirportName": "Arkalyk Airport",
        "City": "Arkalyk",
        "Country": "Kazakhstan"
    },
    {
        "id": "494",
        "AirportCode": "AYL",
        "AirportName": "Anthony Lagoon Airport",
        "City": "Anthony Lagoon",
        "Country": "Australia"
    },
    {
        "id": "495",
        "AirportCode": "AYN",
        "AirportName": "Anyang Airport",
        "City": "Anyang",
        "Country": "China"
    },
    {
        "id": "496",
        "AirportCode": "AYO",
        "AirportName": "Ayolas Airport",
        "City": "Ayolas",
        "Country": "Paraguay"
    },
    {
        "id": "497",
        "AirportCode": "AYP",
        "AirportName": "Yanamilla Airport",
        "City": "Ayacucho",
        "Country": "Peru"
    },
    {
        "id": "498",
        "AirportCode": "AYQ",
        "AirportName": "Connellan Airport",
        "City": "Ayers Rock",
        "Country": "Australia"
    },
    {
        "id": "499",
        "AirportCode": "AYR",
        "AirportName": "Ayr Airport",
        "City": "Ayr",
        "Country": "Australia"
    },
    {
        "id": "500",
        "AirportCode": "AYS",
        "AirportName": "Ware County Airport",
        "City": "Waycross",
        "Country": "USA Georgia"
    },
    {
        "id": "501",
        "AirportCode": "AYT",
        "AirportName": "Antalya Airport",
        "City": "Antalya",
        "Country": "Turkey"
    },
    {
        "id": "502",
        "AirportCode": "AYU",
        "AirportName": "Aiyura Airport",
        "City": "Aiyura",
        "Country": "Papua New Guinea"
    },
    {
        "id": "503",
        "AirportCode": "AYW",
        "AirportName": "Ayawasi Airport",
        "City": "Ayawasi",
        "Country": "Indonesia"
    },
    {
        "id": "504",
        "AirportCode": "AYX",
        "AirportName": "Arnold Afb",
        "City": "Tullahoma",
        "Country": "USA Tennessee"
    },
    {
        "id": "505",
        "AirportCode": "AYZ",
        "AirportName": "Zahns Airport",
        "City": "Amityville",
        "Country": "USA New York"
    },
    {
        "id": "506",
        "AirportCode": "AZA",
        "AirportName": "Phoenix-Mesa Gateway Airport",
        "City": "Chandler",
        "Country": "USA Arizona"
    },
    {
        "id": "507",
        "AirportCode": "AZA",
        "AirportName": "Phoenix-Mesa Gateway Airport",
        "City": "Mesa",
        "Country": "USA Arizona"
    },
    {
        "id": "508",
        "AirportCode": "AZB",
        "AirportName": "Amazon Bay Airport",
        "City": "Amazon Bay",
        "Country": "Papua New Guinea"
    },
    {
        "id": "509",
        "AirportCode": "AZD",
        "AirportName": "Yazd Airport",
        "City": "Yazd",
        "Country": "Iran"
    },
    {
        "id": "510",
        "AirportCode": "AZG",
        "AirportName": "Apatzingan Airport",
        "City": "Apatzingan",
        "Country": "Mexico"
    },
    {
        "id": "511",
        "AirportCode": "AZI",
        "AirportName": "Bateen Airport",
        "City": "Abu Dhabi",
        "Country": "UAE"
    },
    {
        "id": "512",
        "AirportCode": "AZN",
        "AirportName": "Andizhan Airport",
        "City": "Andizhan",
        "Country": "Uzbekistan"
    },
    {
        "id": "513",
        "AirportCode": "AZO",
        "AirportName": "Battle Creek International Airport",
        "City": "Kalamazoo",
        "Country": "USA Michigan"
    },
    {
        "id": "514",
        "AirportCode": "AZP",
        "AirportName": "Atizapan Airport",
        "City": "Mexico City",
        "Country": "Mexico"
    },
    {
        "id": "515",
        "AirportCode": "AZR",
        "AirportName": "Adrar Airport",
        "City": "Adrar",
        "Country": "Algeria"
    },
    {
        "id": "516",
        "AirportCode": "AZS",
        "AirportName": "El Catey Airport",
        "City": "El Catey/Samana",
        "Country": "Dominican Republic"
    },
    {
        "id": "517",
        "AirportCode": "BAA",
        "AirportName": "Bialla Airport",
        "City": "Bialla",
        "Country": "Papua New Guinea"
    },
    {
        "id": "518",
        "AirportCode": "BAB",
        "AirportName": "Beale Air Force Base",
        "City": "Marysville",
        "Country": "USA California"
    },
    {
        "id": "519",
        "AirportCode": "BAC",
        "AirportName": "Barranca De Upia Airport",
        "City": "Barranca De Upia",
        "Country": "Colombia"
    },
    {
        "id": "520",
        "AirportCode": "BAD",
        "AirportName": "Barksdale Air Force Base",
        "City": "Shreveport",
        "Country": "USA Louisiana"
    },
    {
        "id": "521",
        "AirportCode": "BAE",
        "AirportName": "Barcelonnette Airport",
        "City": "Barcelonnette",
        "Country": "France"
    },
    {
        "id": "522",
        "AirportCode": "BAF",
        "AirportName": "Barnes Airport",
        "City": "Westfield",
        "Country": "USA Massachusetts"
    },
    {
        "id": "523",
        "AirportCode": "BAG",
        "AirportName": "Loakan Airport",
        "City": "Baguio",
        "Country": "Philippines"
    },
    {
        "id": "524",
        "AirportCode": "BAH",
        "AirportName": "Bahrain International Airport",
        "City": "Bahrain",
        "Country": "Bahrain"
    },
    {
        "id": "525",
        "AirportCode": "BAI",
        "AirportName": "Buenos Aires Airport",
        "City": "Buenos Aires",
        "Country": "Costa Rica"
    },
    {
        "id": "526",
        "AirportCode": "BAJ",
        "AirportName": "Bali Airport",
        "City": "Bali",
        "Country": "Papua New Guinea"
    },
    {
        "id": "527",
        "AirportCode": "BAK",
        "AirportName": "Metropolitan Area",
        "City": "Baku",
        "Country": "Azerbaijan"
    },
    {
        "id": "528",
        "AirportCode": "BAL",
        "AirportName": "Batman Airport",
        "City": "Batman",
        "Country": "Turkey"
    },
    {
        "id": "529",
        "AirportCode": "BAM",
        "AirportName": "Lander County Airport",
        "City": "Battle Mountain",
        "Country": "USA Nevada"
    },
    {
        "id": "530",
        "AirportCode": "BAN",
        "AirportName": "Basongo Airport",
        "City": "Basongo",
        "Country": "Congo, DR"
    },
    {
        "id": "531",
        "AirportCode": "BAO",
        "AirportName": "Udorn Airport",
        "City": "Ban Mak Khaen",
        "Country": "Thailand"
    },
    {
        "id": "532",
        "AirportCode": "BAP",
        "AirportName": "Baibara Airport",
        "City": "Baibara",
        "Country": "Papua New Guinea"
    },
    {
        "id": "533",
        "AirportCode": "BAQ",
        "AirportName": "E Cortissoz Airport",
        "City": "Barranquilla",
        "Country": "Colombia"
    },
    {
        "id": "534",
        "AirportCode": "BAS",
        "AirportName": "Balalae Airport",
        "City": "Balalae",
        "Country": "Solomon Islands"
    },
    {
        "id": "535",
        "AirportCode": "BAT",
        "AirportName": "Barretos Airport",
        "City": "Barretos",
        "Country": "Brazil"
    },
    {
        "id": "536",
        "AirportCode": "BAU",
        "AirportName": "Bauru Airport",
        "City": "Bauru",
        "Country": "Brazil"
    },
    {
        "id": "537",
        "AirportCode": "BAV",
        "AirportName": "Baotou Airport",
        "City": "Baotou",
        "Country": "China"
    },
    {
        "id": "538",
        "AirportCode": "BAW",
        "AirportName": "Biawonque Airport",
        "City": "Biawonque",
        "Country": "Gabon"
    },
    {
        "id": "539",
        "AirportCode": "BAX",
        "AirportName": "Barnaul Airport",
        "City": "Barnaul",
        "Country": "Russia"
    },
    {
        "id": "540",
        "AirportCode": "BAY",
        "AirportName": "Baia Mare Airport",
        "City": "Baia Mare",
        "Country": "Romania"
    },
    {
        "id": "541",
        "AirportCode": "BAZ",
        "AirportName": "Barbelos Airport",
        "City": "Barbelos",
        "Country": "Brazil"
    },
    {
        "id": "542",
        "AirportCode": "BBA",
        "AirportName": "Teniente Vidal Airport",
        "City": "Balmaceda",
        "Country": "Chile"
    },
    {
        "id": "543",
        "AirportCode": "BBB",
        "AirportName": "Benson Municipal Airport",
        "City": "Benson",
        "Country": "USA Minnesota"
    },
    {
        "id": "544",
        "AirportCode": "BBC",
        "AirportName": "Bay City Airport",
        "City": "Bay City",
        "Country": "USA Texas"
    },
    {
        "id": "545",
        "AirportCode": "BBD",
        "AirportName": "Curtis Field",
        "City": "Brady",
        "Country": "USA Texas"
    },
    {
        "id": "546",
        "AirportCode": "BBE",
        "AirportName": "Big Bell Airport",
        "City": "Big Bell",
        "Country": "Australia"
    },
    {
        "id": "547",
        "AirportCode": "BBF",
        "AirportName": "Burlington Airport",
        "City": "Burlington",
        "Country": "USA Massachusetts"
    },
    {
        "id": "548",
        "AirportCode": "BBG",
        "AirportName": "Butaritari Airport",
        "City": "Butaritari",
        "Country": "Kiribati"
    },
    {
        "id": "549",
        "AirportCode": "BBH",
        "AirportName": "Barth Airport",
        "City": "Barth",
        "Country": "Germany"
    },
    {
        "id": "550",
        "AirportCode": "BBI",
        "AirportName": "Bhubaneswar Airport",
        "City": "Bhubaneswar",
        "Country": "India"
    },
    {
        "id": "551",
        "AirportCode": "BBJ",
        "AirportName": "Bitburg Air Base",
        "City": "Bitburg",
        "Country": "Germany"
    },
    {
        "id": "552",
        "AirportCode": "BBK",
        "AirportName": "Kasane Airport",
        "City": "Kasane",
        "Country": "Botswana"
    },
    {
        "id": "553",
        "AirportCode": "BBL",
        "AirportName": "Babolsar Airport",
        "City": "Babolsar",
        "Country": "Iran"
    },
    {
        "id": "554",
        "AirportCode": "BBM",
        "AirportName": "Battambang Airport",
        "City": "Battambang",
        "Country": "Cambodia"
    },
    {
        "id": "555",
        "AirportCode": "BBN",
        "AirportName": "Bario Airport",
        "City": "Bario",
        "Country": "Malaysia"
    },
    {
        "id": "556",
        "AirportCode": "BBO",
        "AirportName": "Berbera Airport",
        "City": "Berbera",
        "Country": "Somalia"
    },
    {
        "id": "557",
        "AirportCode": "BBP",
        "AirportName": "Bembridge Airport",
        "City": "Bembridge",
        "Country": "United Kingdom"
    },
    {
        "id": "558",
        "AirportCode": "BBQ",
        "AirportName": "Barbuda Airport",
        "City": "Barbuda",
        "Country": "Antigua & Barbuda"
    },
    {
        "id": "559",
        "AirportCode": "BBR",
        "AirportName": "Baillif Airport",
        "City": "Basse Terre",
        "Country": "Guadeloupe"
    },
    {
        "id": "560",
        "AirportCode": "BBS",
        "AirportName": "Blackbush Airport",
        "City": "Blackbush",
        "Country": "United Kingdom"
    },
    {
        "id": "561",
        "AirportCode": "BBT",
        "AirportName": "Berberati Airport",
        "City": "Berberati",
        "Country": "Central African Republic"
    },
    {
        "id": "562",
        "AirportCode": "BBU",
        "AirportName": "Baneasa Airport",
        "City": "Bucharest",
        "Country": "Romania"
    },
    {
        "id": "563",
        "AirportCode": "BBW",
        "AirportName": "Broken Bow Airport",
        "City": "Broken Bow",
        "Country": "USA Nebraska"
    },
    {
        "id": "564",
        "AirportCode": "BBX",
        "AirportName": "Wings Field",
        "City": "Blue Bell",
        "Country": "USA Philadelphia"
    },
    {
        "id": "565",
        "AirportCode": "BBY",
        "AirportName": "Bambari Airport",
        "City": "Bambari",
        "Country": "Central African Republic"
    },
    {
        "id": "566",
        "AirportCode": "BBZ",
        "AirportName": "Zambezi Airport",
        "City": "Zambezi",
        "Country": "Zambia"
    },
    {
        "id": "567",
        "AirportCode": "BCA",
        "AirportName": "Baracoa Airport",
        "City": "Baracoa",
        "Country": "Cuba"
    },
    {
        "id": "568",
        "AirportCode": "BCB",
        "AirportName": "Virginia Tech Airport",
        "City": "Blacksburg",
        "Country": "USA Virginia"
    },
    {
        "id": "569",
        "AirportCode": "BCD",
        "AirportName": "Bacolod Airport",
        "City": "Bacolod",
        "Country": "Philippines"
    },
    {
        "id": "570",
        "AirportCode": "BCE",
        "AirportName": "Bryce Airport",
        "City": "Bryce",
        "Country": "USA Utah"
    },
    {
        "id": "571",
        "AirportCode": "BCF",
        "AirportName": "Bouca Airport",
        "City": "Bouca",
        "Country": "Central African Republic"
    },
    {
        "id": "572",
        "AirportCode": "BCG",
        "AirportName": "Bemichi Airport",
        "City": "Bemichi",
        "Country": "Guyana"
    },
    {
        "id": "573",
        "AirportCode": "BCH",
        "AirportName": "English Madeira Airport",
        "City": "Baucau",
        "Country": "Indonesia"
    },
    {
        "id": "574",
        "AirportCode": "BCI",
        "AirportName": "Barcaldine Airport",
        "City": "Barcaldine",
        "Country": "Australia"
    },
    {
        "id": "575",
        "AirportCode": "BCJ",
        "AirportName": "Baca Grande Airport",
        "City": "Baca Grande",
        "Country": "USA Colorado"
    },
    {
        "id": "576",
        "AirportCode": "BCK",
        "AirportName": "Bolwarra Airport",
        "City": "Bolwarra",
        "Country": "Australia"
    },
    {
        "id": "577",
        "AirportCode": "BCL",
        "AirportName": "Barra Colorado Airport",
        "City": "Barra Colorado",
        "Country": "Costa Rica"
    },
    {
        "id": "578",
        "AirportCode": "BCM",
        "AirportName": "Bacau Airport",
        "City": "Bacau",
        "Country": "Romania"
    },
    {
        "id": "579",
        "AirportCode": "BCN",
        "AirportName": "Barcelona Airport",
        "City": "Barcelona",
        "Country": "Spain"
    },
    {
        "id": "580",
        "AirportCode": "BCO",
        "AirportName": "Jinka Airport",
        "City": "Jinka",
        "Country": "Ethiopia"
    },
    {
        "id": "581",
        "AirportCode": "BCP",
        "AirportName": "Bambu Airport",
        "City": "Bambu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "582",
        "AirportCode": "BCQ",
        "AirportName": "Brack Airport",
        "City": "Brack",
        "Country": "Libya"
    },
    {
        "id": "583",
        "AirportCode": "BCR",
        "AirportName": "Boca Do Acre Airport",
        "City": "Boca Do Acre",
        "Country": "Brazil"
    },
    {
        "id": "584",
        "AirportCode": "BCS",
        "AirportName": "Southern Sea Plane",
        "City": "Belle Chasse",
        "Country": "USA Louisiana"
    },
    {
        "id": "585",
        "AirportCode": "BCT",
        "AirportName": "Boca Raton Public Airport",
        "City": "Boca Raton",
        "Country": "USA Florida"
    },
    {
        "id": "586",
        "AirportCode": "BCU",
        "AirportName": "Bauchi Airport",
        "City": "Bauchi",
        "Country": "Nigeria"
    },
    {
        "id": "587",
        "AirportCode": "BCV",
        "AirportName": "Belmopan Airport",
        "City": "Belmopan",
        "Country": "Belize"
    },
    {
        "id": "588",
        "AirportCode": "BCW",
        "AirportName": "Benguera Island Airport",
        "City": "Benguera Island",
        "Country": "Mozambique"
    },
    {
        "id": "589",
        "AirportCode": "BCX",
        "AirportName": "Beloreck Airport",
        "City": "Beloreck",
        "Country": "Russia"
    },
    {
        "id": "590",
        "AirportCode": "BCY",
        "AirportName": "Bulchi Airport",
        "City": "Bulchi",
        "Country": "Ethiopia"
    },
    {
        "id": "591",
        "AirportCode": "BCZ",
        "AirportName": "Bickerton Island Airport",
        "City": "Bickerton Island",
        "Country": "Australia"
    },
    {
        "id": "592",
        "AirportCode": "BDA",
        "AirportName": "Bermuda International Airport",
        "City": "Bermuda",
        "Country": "Bermuda"
    },
    {
        "id": "593",
        "AirportCode": "BDB",
        "AirportName": "Bundaberg Airport",
        "City": "Bundaberg",
        "Country": "Australia"
    },
    {
        "id": "594",
        "AirportCode": "BDC",
        "AirportName": "Barra Do Corda Airport",
        "City": "Barra Do Corda",
        "Country": "Brazil"
    },
    {
        "id": "595",
        "AirportCode": "BDD",
        "AirportName": "Badu Island Airport",
        "City": "Badu Island",
        "Country": "Australia"
    },
    {
        "id": "596",
        "AirportCode": "BDE",
        "AirportName": "Baudette Airport",
        "City": "Baudette",
        "Country": "USA Minnesota"
    },
    {
        "id": "597",
        "AirportCode": "BDF",
        "AirportName": "Rinkenberger Airport",
        "City": "Bradford",
        "Country": "USA Illinois"
    },
    {
        "id": "598",
        "AirportCode": "BDG",
        "AirportName": "Blanding Airport",
        "City": "Blanding",
        "Country": "USA Utah"
    },
    {
        "id": "599",
        "AirportCode": "BDH",
        "AirportName": "Bandar Lengeh Airport",
        "City": "Bandar Lengeh",
        "Country": "Iran"
    },
    {
        "id": "600",
        "AirportCode": "BDI",
        "AirportName": "Bird Island Airport",
        "City": "Bird Island",
        "Country": "Seychelles"
    },
    {
        "id": "601",
        "AirportCode": "BDJ",
        "AirportName": "Sjamsudin Noor Airport",
        "City": "Banjarmasin",
        "Country": "Indonesia"
    },
    {
        "id": "602",
        "AirportCode": "BDL",
        "AirportName": "Bradley International Airport",
        "City": "Hartford",
        "Country": "USA Connecticut"
    },
    {
        "id": "603",
        "AirportCode": "BDM",
        "AirportName": "Bandirma Airport",
        "City": "Bandirma",
        "Country": "Turkey"
    },
    {
        "id": "604",
        "AirportCode": "BDN",
        "AirportName": "Talhar Airport",
        "City": "Badin",
        "Country": "Pakistan"
    },
    {
        "id": "605",
        "AirportCode": "BDO",
        "AirportName": "Husein Sastranegara Airport",
        "City": "Bandung",
        "Country": "Indonesia"
    },
    {
        "id": "606",
        "AirportCode": "BDP",
        "AirportName": "Bhadrapur Airport",
        "City": "Bhadrapur",
        "Country": "Nepal"
    },
    {
        "id": "607",
        "AirportCode": "BDQ",
        "AirportName": "Vadodara Airport",
        "City": "Vadodara",
        "Country": "India"
    },
    {
        "id": "608",
        "AirportCode": "BDR",
        "AirportName": "Igor I. Sikorsky Memorial Airport",
        "City": "Bridgeport",
        "Country": "USA Connecticut"
    },
    {
        "id": "609",
        "AirportCode": "BDS",
        "AirportName": "Papola Casale Airport",
        "City": "Brindisi",
        "Country": "Italy"
    },
    {
        "id": "610",
        "AirportCode": "BDT",
        "AirportName": "Gbadolite Airport",
        "City": "Gbadolite",
        "Country": "Congo, DR"
    },
    {
        "id": "611",
        "AirportCode": "BDU",
        "AirportName": "Bardufoss Airport",
        "City": "Bardufoss",
        "Country": "Norway"
    },
    {
        "id": "612",
        "AirportCode": "BDV",
        "AirportName": "Moba Airport",
        "City": "Moba",
        "Country": "Congo, DR"
    },
    {
        "id": "613",
        "AirportCode": "BDW",
        "AirportName": "Bedford Downs Airport",
        "City": "Bedford Downs",
        "Country": "Australia"
    },
    {
        "id": "614",
        "AirportCode": "BDX",
        "AirportName": "Broadus Airport",
        "City": "Broadus",
        "Country": "USA Montana"
    },
    {
        "id": "615",
        "AirportCode": "BDY",
        "AirportName": "State Airport",
        "City": "Bandon",
        "Country": "USA Oregon"
    },
    {
        "id": "616",
        "AirportCode": "BDZ",
        "AirportName": "Baindoung Airport",
        "City": "Baindoung",
        "Country": "Papua New Guinea"
    },
    {
        "id": "617",
        "AirportCode": "BEA",
        "AirportName": "Bereina Airport",
        "City": "Bereina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "618",
        "AirportCode": "BEB",
        "AirportName": "Benbecula Airport",
        "City": "Benbecula",
        "Country": "United Kingdom"
    },
    {
        "id": "619",
        "AirportCode": "BEC",
        "AirportName": "Beech Airport",
        "City": "Wichita",
        "Country": "USA Kansas"
    },
    {
        "id": "620",
        "AirportCode": "BED",
        "AirportName": "Hanscom Field",
        "City": "Bedford/Hanscom",
        "Country": "USA Massachusetts"
    },
    {
        "id": "621",
        "AirportCode": "BEE",
        "AirportName": "Beagle Bay Airport",
        "City": "Beagle Bay",
        "Country": "Australia"
    },
    {
        "id": "622",
        "AirportCode": "BEF",
        "AirportName": "Bluefields Airport",
        "City": "Bluefields",
        "Country": "Nicaragua"
    },
    {
        "id": "623",
        "AirportCode": "BEG",
        "AirportName": "Beograd Airport",
        "City": "Belgrade",
        "Country": "Serbia"
    },
    {
        "id": "624",
        "AirportCode": "BEH",
        "AirportName": "Ross Field",
        "City": "Benton Harbor",
        "Country": "USA Michigan"
    },
    {
        "id": "625",
        "AirportCode": "BEI",
        "AirportName": "Beica Airport",
        "City": "Beica",
        "Country": "Ethiopia"
    },
    {
        "id": "626",
        "AirportCode": "BEJ",
        "AirportName": "Berau Airport",
        "City": "Berau",
        "Country": "Indonesia"
    },
    {
        "id": "627",
        "AirportCode": "BEK",
        "AirportName": "Bareli Airport",
        "City": "Bareli",
        "Country": "India"
    },
    {
        "id": "628",
        "AirportCode": "BEL",
        "AirportName": "Val De Cans Airport",
        "City": "Belem",
        "Country": "Brazil"
    },
    {
        "id": "629",
        "AirportCode": "BEM",
        "AirportName": "Bossembele Airport",
        "City": "Bossembele",
        "Country": "Central African Republic"
    },
    {
        "id": "630",
        "AirportCode": "BEN",
        "AirportName": "Benina International Airport",
        "City": "Benghazi",
        "Country": "Libya"
    },
    {
        "id": "631",
        "AirportCode": "BEO",
        "AirportName": "Belmont Airport",
        "City": "Newcastle",
        "Country": "Australia"
    },
    {
        "id": "632",
        "AirportCode": "BEP",
        "AirportName": "Bellary Airport",
        "City": "Bellary",
        "Country": "India"
    },
    {
        "id": "633",
        "AirportCode": "BEQ",
        "AirportName": "Honington Airport",
        "City": "Bury St. Edmunds",
        "Country": "United Kingdom"
    },
    {
        "id": "634",
        "AirportCode": "BES",
        "AirportName": "Guipavas Airport",
        "City": "Brest",
        "Country": "France"
    },
    {
        "id": "635",
        "AirportCode": "BET",
        "AirportName": "Bethel Airport",
        "City": "Bethel",
        "Country": "USA Alaska"
    },
    {
        "id": "636",
        "AirportCode": "BEU",
        "AirportName": "Bedourie Airport",
        "City": "Bedourie",
        "Country": "Australia"
    },
    {
        "id": "637",
        "AirportCode": "BEW",
        "AirportName": "Beira Airport",
        "City": "Beira",
        "Country": "Mozambique"
    },
    {
        "id": "638",
        "AirportCode": "BEX",
        "AirportName": "Royal Air Force Station",
        "City": "Benson",
        "Country": "United Kingdom"
    },
    {
        "id": "639",
        "AirportCode": "BEY",
        "AirportName": "Beirut International Airport",
        "City": "Beirut",
        "Country": "Lebanon"
    },
    {
        "id": "640",
        "AirportCode": "BEZ",
        "AirportName": "Beru Airport",
        "City": "Beru",
        "Country": "Kiribati"
    },
    {
        "id": "641",
        "AirportCode": "BFA",
        "AirportName": "Bahia Negra Airport",
        "City": "Bahia Negra",
        "Country": "Paraguay"
    },
    {
        "id": "642",
        "AirportCode": "BFC",
        "AirportName": "Bloomfield Airport",
        "City": "Bloomfield",
        "Country": "Australia"
    },
    {
        "id": "643",
        "AirportCode": "BFD",
        "AirportName": "Bradford Airport",
        "City": "Bradford",
        "Country": "USA Philadelphia"
    },
    {
        "id": "644",
        "AirportCode": "BFE",
        "AirportName": "Bielefeld Airport",
        "City": "Bielefeld",
        "Country": "Germany"
    },
    {
        "id": "645",
        "AirportCode": "BFF",
        "AirportName": "Scotts Bluff County Airport",
        "City": "Scottsbluff",
        "Country": "USA Nebraska"
    },
    {
        "id": "646",
        "AirportCode": "BFG",
        "AirportName": "Bullfrog Basin Airport",
        "City": "Bullfrog Basin",
        "Country": "USA Utah"
    },
    {
        "id": "647",
        "AirportCode": "BFH",
        "AirportName": "Bacacheri Airport",
        "City": "Curitiba",
        "Country": "Brazil"
    },
    {
        "id": "648",
        "AirportCode": "BFI",
        "AirportName": "Boeing Field International Airport",
        "City": "Seattle",
        "Country": "USA Washington"
    },
    {
        "id": "649",
        "AirportCode": "BFJ",
        "AirportName": "Ba Airport",
        "City": "Ba",
        "Country": "Fiji"
    },
    {
        "id": "650",
        "AirportCode": "BFK",
        "AirportName": "Buckley Air National Guard Base",
        "City": "Denver",
        "Country": "USA Colorado"
    },
    {
        "id": "651",
        "AirportCode": "BFL",
        "AirportName": "Meadows Field",
        "City": "Bakersfield",
        "Country": "USA California"
    },
    {
        "id": "652",
        "AirportCode": "BFM",
        "AirportName": "Mobile Aerospace Field",
        "City": "Mobile",
        "Country": "USA Alabama"
    },
    {
        "id": "653",
        "AirportCode": "BFN",
        "AirportName": "Bloemfontein International Airport",
        "City": "Bloemfontein",
        "Country": "South Africa"
    },
    {
        "id": "654",
        "AirportCode": "BFO",
        "AirportName": "Buffalo Range Airport",
        "City": "Buffalo Range",
        "Country": "Zimbabwe"
    },
    {
        "id": "655",
        "AirportCode": "BFP",
        "AirportName": "Beaver Falls Airport",
        "City": "Beaver Falls",
        "Country": "USA Philadelphia"
    },
    {
        "id": "656",
        "AirportCode": "BFQ",
        "AirportName": "Bahia Pinas Airport",
        "City": "Bahia Pinas",
        "Country": "Panama"
    },
    {
        "id": "657",
        "AirportCode": "BFR",
        "AirportName": "Virgil I Grissom Municipal Airport",
        "City": "Bedford",
        "Country": "USA Indiana"
    },
    {
        "id": "658",
        "AirportCode": "BFS",
        "AirportName": "Belfast International Airport",
        "City": "Belfast",
        "Country": "United Kingdom"
    },
    {
        "id": "659",
        "AirportCode": "BFT",
        "AirportName": "County Airport",
        "City": "Beaufort",
        "Country": "USA South Carolina"
    },
    {
        "id": "660",
        "AirportCode": "BFU",
        "AirportName": "Bengbu Airport",
        "City": "Bengbu",
        "Country": "China"
    },
    {
        "id": "661",
        "AirportCode": "BFV",
        "AirportName": "Buri Ram Airport",
        "City": "Buri Ram",
        "Country": "Thailand"
    },
    {
        "id": "662",
        "AirportCode": "BFW",
        "AirportName": "Sidi Belabbes Airport",
        "City": "Sidi Belabbes",
        "Country": "Algeria"
    },
    {
        "id": "663",
        "AirportCode": "BFX",
        "AirportName": "Bafoussam Airport",
        "City": "Bafoussam",
        "Country": "Cameroon"
    },
    {
        "id": "664",
        "AirportCode": "BGA",
        "AirportName": "Palo Negro Airport",
        "City": "Bucaramanga",
        "Country": "Colombia"
    },
    {
        "id": "665",
        "AirportCode": "BGB",
        "AirportName": "Booue Airport",
        "City": "Booue",
        "Country": "Gabon"
    },
    {
        "id": "666",
        "AirportCode": "BGC",
        "AirportName": "Braganca Airport",
        "City": "Braganca",
        "Country": "Portugal"
    },
    {
        "id": "667",
        "AirportCode": "BGD",
        "AirportName": "Borger Airport",
        "City": "Borger",
        "Country": "USA Texas"
    },
    {
        "id": "668",
        "AirportCode": "BGE",
        "AirportName": "Decatur County Airport",
        "City": "Bainbridge",
        "Country": "USA Georgia"
    },
    {
        "id": "669",
        "AirportCode": "BGF",
        "AirportName": "Bangui Airport",
        "City": "Bangui",
        "Country": "Central African Republic"
    },
    {
        "id": "670",
        "AirportCode": "BGH",
        "AirportName": "Abbaye Airport",
        "City": "Boghe",
        "Country": "Mauritania"
    },
    {
        "id": "671",
        "AirportCode": "BGI",
        "AirportName": "Grantley Adams International Airport",
        "City": "Bridgetown",
        "Country": "Barbados"
    },
    {
        "id": "672",
        "AirportCode": "BGJ",
        "AirportName": "Borgarfjordur Eystri Airport",
        "City": "Borgarfjordur Eystri",
        "Country": "Iceland"
    },
    {
        "id": "673",
        "AirportCode": "BGK",
        "AirportName": "Big Creek Airport",
        "City": "Big Creek",
        "Country": "Belize"
    },
    {
        "id": "674",
        "AirportCode": "BGL",
        "AirportName": "Baglung Airport",
        "City": "Baglung",
        "Country": "Nepal"
    },
    {
        "id": "675",
        "AirportCode": "BGM",
        "AirportName": "Binghamton Airport",
        "City": "Binghamton",
        "Country": "USA New York"
    },
    {
        "id": "676",
        "AirportCode": "BGN",
        "AirportName": "Royal Air Force",
        "City": "Brueggen",
        "Country": "Germany"
    },
    {
        "id": "677",
        "AirportCode": "BGO",
        "AirportName": "Flesland Airport",
        "City": "Bergen",
        "Country": "Norway"
    },
    {
        "id": "678",
        "AirportCode": "BGP",
        "AirportName": "Bongo Airport",
        "City": "Bongo",
        "Country": "Gabon"
    },
    {
        "id": "679",
        "AirportCode": "BGQ",
        "AirportName": "Big Lake Airport",
        "City": "Big Lake",
        "Country": "USA Alaska"
    },
    {
        "id": "680",
        "AirportCode": "BGR",
        "AirportName": "Bangor International Airport",
        "City": "Bangor",
        "Country": "USA Maine"
    },
    {
        "id": "681",
        "AirportCode": "BGS",
        "AirportName": "Webb Air Force Base",
        "City": "Big Spring",
        "Country": "USA Texas"
    },
    {
        "id": "682",
        "AirportCode": "BGT",
        "AirportName": "Bagdad Airport",
        "City": "Bagdad",
        "Country": "USA Arizona"
    },
    {
        "id": "683",
        "AirportCode": "BGU",
        "AirportName": "Bangassou Airport",
        "City": "Bangassou",
        "Country": "Central African Republic"
    },
    {
        "id": "684",
        "AirportCode": "BGV",
        "AirportName": "Bento Goncalves Airport",
        "City": "Bento Goncalves",
        "Country": "Brazil"
    },
    {
        "id": "685",
        "AirportCode": "BGW",
        "AirportName": "Al Muthana Airport",
        "City": "Baghdad",
        "Country": "Iraq"
    },
    {
        "id": "686",
        "AirportCode": "BGX",
        "AirportName": "Bage Airport",
        "City": "Bage",
        "Country": "Brazil"
    },
    {
        "id": "687",
        "AirportCode": "BGY",
        "AirportName": "Orio Al Serio Airport",
        "City": "Milan",
        "Country": "Italy"
    },
    {
        "id": "688",
        "AirportCode": "BGZ",
        "AirportName": "Braga Airport",
        "City": "Braga",
        "Country": "Portugal"
    },
    {
        "id": "689",
        "AirportCode": "BHA",
        "AirportName": "Bahia De Caraquez Airport",
        "City": "Bahia De Caraquez",
        "Country": "Ecuador"
    },
    {
        "id": "690",
        "AirportCode": "BHB",
        "AirportName": "Bar Harbor Airport",
        "City": "Bar Harbor",
        "Country": "USA Maine"
    },
    {
        "id": "691",
        "AirportCode": "BHC",
        "AirportName": "Bhurban Heliport",
        "City": "Bhurban",
        "Country": "Pakistan"
    },
    {
        "id": "692",
        "AirportCode": "BHD",
        "AirportName": "Belfast City Airport",
        "City": "Belfast",
        "Country": "United Kingdom"
    },
    {
        "id": "693",
        "AirportCode": "BHE",
        "AirportName": "Blenheim Airport",
        "City": "Blenheim",
        "Country": "New Zealand"
    },
    {
        "id": "694",
        "AirportCode": "BHF",
        "AirportName": "Bahia Cupica Airport",
        "City": "Bahia Cupica",
        "Country": "Colombia"
    },
    {
        "id": "695",
        "AirportCode": "BHG",
        "AirportName": "Brus Laguna Airport",
        "City": "Brus Laguna",
        "Country": "Honduras"
    },
    {
        "id": "696",
        "AirportCode": "BHH",
        "AirportName": "Bisha Airport",
        "City": "Bisha",
        "Country": "Saudi Arabia"
    },
    {
        "id": "697",
        "AirportCode": "BHI",
        "AirportName": "Comandante Airport",
        "City": "Bahia Blanca",
        "Country": "Argentina"
    },
    {
        "id": "698",
        "AirportCode": "BHJ",
        "AirportName": "Rudra Mata Airport",
        "City": "Bhuj",
        "Country": "India"
    },
    {
        "id": "699",
        "AirportCode": "BHK",
        "AirportName": "Bukhara Airport",
        "City": "Bukhara",
        "Country": "Uzbekistan"
    },
    {
        "id": "700",
        "AirportCode": "BHL",
        "AirportName": "Bahai Angeles Airport",
        "City": "Bahai Angeles",
        "Country": "Mexico"
    },
    {
        "id": "701",
        "AirportCode": "BHM",
        "AirportName": "Birmingham Airport",
        "City": "Birmingham",
        "Country": "USA Alabama"
    },
    {
        "id": "702",
        "AirportCode": "BHN",
        "AirportName": "Beihan Airport",
        "City": "Beihan",
        "Country": "Yemen"
    },
    {
        "id": "703",
        "AirportCode": "BHO",
        "AirportName": "Bhopal Airport",
        "City": "Bhopal",
        "Country": "India"
    },
    {
        "id": "704",
        "AirportCode": "BHP",
        "AirportName": "Bhojpur Airport",
        "City": "Bhojpur",
        "Country": "Nepal"
    },
    {
        "id": "705",
        "AirportCode": "BHQ",
        "AirportName": "Broken Hill Airport",
        "City": "Broken Hill",
        "Country": "Australia"
    },
    {
        "id": "706",
        "AirportCode": "BHR",
        "AirportName": "Bharatpur Airport",
        "City": "Bharatpur",
        "Country": "Nepal"
    },
    {
        "id": "707",
        "AirportCode": "BHS",
        "AirportName": "Raglan Airport",
        "City": "Bathurst",
        "Country": "Australia"
    },
    {
        "id": "708",
        "AirportCode": "BHT",
        "AirportName": "Brighton Downs Airport",
        "City": "Brighton Downs",
        "Country": "Australia"
    },
    {
        "id": "709",
        "AirportCode": "BHU",
        "AirportName": "Bhavnagar Airport",
        "City": "Bhavnagar",
        "Country": "India"
    },
    {
        "id": "710",
        "AirportCode": "BHV",
        "AirportName": "Bahawalpur Airport",
        "City": "Bahawalpur",
        "Country": "Pakistan"
    },
    {
        "id": "711",
        "AirportCode": "BHW",
        "AirportName": "Bhagatanwala Airport",
        "City": "Sargodha",
        "Country": "Pakistan"
    },
    {
        "id": "712",
        "AirportCode": "BHX",
        "AirportName": "Birmingham International Airport",
        "City": "Birmingham",
        "Country": "United Kingdom"
    },
    {
        "id": "713",
        "AirportCode": "BHY",
        "AirportName": "Beihai Airport",
        "City": "Beihai",
        "Country": "China"
    },
    {
        "id": "714",
        "AirportCode": "BIA",
        "AirportName": "Poretta Airport",
        "City": "Bastia",
        "Country": "France"
    },
    {
        "id": "715",
        "AirportCode": "BIB",
        "AirportName": "Baidoa Airport",
        "City": "Baidoa",
        "Country": "Somalia"
    },
    {
        "id": "716",
        "AirportCode": "BIC",
        "AirportName": "Big Creek Airport",
        "City": "Big Creek",
        "Country": "USA Alaska"
    },
    {
        "id": "717",
        "AirportCode": "BID",
        "AirportName": "Block Island Airport",
        "City": "Block Island",
        "Country": "USA Rhode Island"
    },
    {
        "id": "718",
        "AirportCode": "BIE",
        "AirportName": "Beatrice Airport",
        "City": "Beatrice",
        "Country": "USA Nebraska"
    },
    {
        "id": "719",
        "AirportCode": "BIF",
        "AirportName": "Biggs Army Air Field",
        "City": "El Paso",
        "Country": "USA Texas"
    },
    {
        "id": "720",
        "AirportCode": "BIH",
        "AirportName": "Bishop Airport",
        "City": "Bishop",
        "Country": "USA California"
    },
    {
        "id": "721",
        "AirportCode": "BIJ",
        "AirportName": "Biliau Airport",
        "City": "Biliau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "722",
        "AirportCode": "BIK",
        "AirportName": "Mokmer Airport",
        "City": "Biak",
        "Country": "Indonesia"
    },
    {
        "id": "723",
        "AirportCode": "BIL",
        "AirportName": "Billings Airport",
        "City": "Billings",
        "Country": "USA Montana"
    },
    {
        "id": "724",
        "AirportCode": "BIM",
        "AirportName": "Bimini International Airport",
        "City": "Bimini",
        "Country": "Bahamas"
    },
    {
        "id": "725",
        "AirportCode": "BIN",
        "AirportName": "Bamiyan Airport",
        "City": "Bamiyan",
        "Country": "Afghanistan"
    },
    {
        "id": "726",
        "AirportCode": "BIO",
        "AirportName": "Bilbao Airport",
        "City": "Bilbao",
        "Country": "Spain"
    },
    {
        "id": "727",
        "AirportCode": "BIP",
        "AirportName": "Bulimba Airport",
        "City": "Bulimba",
        "Country": "Australia"
    },
    {
        "id": "728",
        "AirportCode": "BIQ",
        "AirportName": "Biarritz Parme Airport",
        "City": "Biarritz",
        "Country": "France"
    },
    {
        "id": "729",
        "AirportCode": "BIR",
        "AirportName": "Biratnagar Airport",
        "City": "Biratnagar",
        "Country": "Nepal"
    },
    {
        "id": "730",
        "AirportCode": "BIS",
        "AirportName": "Bismarck Airport",
        "City": "Bismarck",
        "Country": "USA North Dakota"
    },
    {
        "id": "731",
        "AirportCode": "BIT",
        "AirportName": "Baitadi Airport",
        "City": "Baitadi",
        "Country": "Nepal"
    },
    {
        "id": "732",
        "AirportCode": "BIU",
        "AirportName": "Bildudalur Airport",
        "City": "Bildudalur",
        "Country": "Iceland"
    },
    {
        "id": "733",
        "AirportCode": "BIV",
        "AirportName": "Bria Airport",
        "City": "Bria",
        "Country": "Central African Republic"
    },
    {
        "id": "734",
        "AirportCode": "BIW",
        "AirportName": "Billiluna Airport",
        "City": "Billiluna",
        "Country": "Australia"
    },
    {
        "id": "735",
        "AirportCode": "BIX",
        "AirportName": "Keesler Air Force Base",
        "City": "Biloxi",
        "Country": "USA Mississippi"
    },
    {
        "id": "736",
        "AirportCode": "BIY",
        "AirportName": "Bisho Airport",
        "City": "Bisho",
        "Country": "South Africa"
    },
    {
        "id": "737",
        "AirportCode": "BIZ",
        "AirportName": "Bimin Airport",
        "City": "Bimin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "738",
        "AirportCode": "BJA",
        "AirportName": "Bejaia Airport",
        "City": "Bejaia",
        "Country": "Algeria"
    },
    {
        "id": "739",
        "AirportCode": "BJB",
        "AirportName": "Bojnord Airport",
        "City": "Bojnord",
        "Country": "Iran"
    },
    {
        "id": "740",
        "AirportCode": "BJC",
        "AirportName": "Jeffco Airport",
        "City": "Broomfield",
        "Country": "USA Colorado"
    },
    {
        "id": "741",
        "AirportCode": "BJD",
        "AirportName": "Bakkafjordur Airport",
        "City": "Bakkafjordur",
        "Country": "Iceland"
    },
    {
        "id": "742",
        "AirportCode": "BJF",
        "AirportName": "Batsfjord Airport",
        "City": "Batsfjord",
        "Country": "Norway"
    },
    {
        "id": "743",
        "AirportCode": "BJG",
        "AirportName": "Bolaang Airport",
        "City": "Bolaang",
        "Country": "Indonesia"
    },
    {
        "id": "744",
        "AirportCode": "BJH",
        "AirportName": "Bajhang Airport",
        "City": "Bajhang",
        "Country": "Nepal"
    },
    {
        "id": "745",
        "AirportCode": "BJI",
        "AirportName": "Bemidji Airport",
        "City": "Bemidji",
        "Country": "USA Minnesota"
    },
    {
        "id": "746",
        "AirportCode": "BJJ",
        "AirportName": "Wayne County Airport",
        "City": "Wooster",
        "Country": "USA Ohio"
    },
    {
        "id": "747",
        "AirportCode": "BJK",
        "AirportName": "Benjina Airport",
        "City": "Benjina",
        "Country": "Indonesia"
    },
    {
        "id": "748",
        "AirportCode": "BJL",
        "AirportName": "Yundum International Airport",
        "City": "Banjul",
        "Country": "Gambia"
    },
    {
        "id": "749",
        "AirportCode": "BJM",
        "AirportName": "Bujumbura International Airport",
        "City": "Bujumbura",
        "Country": "Burundi"
    },
    {
        "id": "750",
        "AirportCode": "BJN",
        "AirportName": "Bajone Airport",
        "City": "Bajone",
        "Country": "Mozambique"
    },
    {
        "id": "751",
        "AirportCode": "BJO",
        "AirportName": "Bermejo Airport",
        "City": "Bermejo",
        "Country": "Bolivia"
    },
    {
        "id": "752",
        "AirportCode": "BJP",
        "AirportName": "Braganca Paulista Airport",
        "City": "Braganca Paulista",
        "Country": "Brazil"
    },
    {
        "id": "753",
        "AirportCode": "BJR",
        "AirportName": "Bahar Dar Airport",
        "City": "Bahar Dar",
        "Country": "Ethiopia"
    },
    {
        "id": "754",
        "AirportCode": "BJT",
        "AirportName": "Bentota River Airport",
        "City": "Bentota River",
        "Country": "Sri Lanka"
    },
    {
        "id": "755",
        "AirportCode": "BJU",
        "AirportName": "Bajura Airport",
        "City": "Bajura",
        "Country": "Nepal"
    },
    {
        "id": "756",
        "AirportCode": "BJV",
        "AirportName": "Milas Airport",
        "City": "Bodrum",
        "Country": "Turkey"
    },
    {
        "id": "757",
        "AirportCode": "BJW",
        "AirportName": "Bajawa Airport",
        "City": "Bajawa",
        "Country": "Indonesia"
    },
    {
        "id": "758",
        "AirportCode": "BJX",
        "AirportName": "Del Bajio Airport",
        "City": "Leon/Guanajuato",
        "Country": "Mexico"
    },
    {
        "id": "759",
        "AirportCode": "BJY",
        "AirportName": "Batajnica Airport",
        "City": "Belgrade",
        "Country": "Serbia"
    },
    {
        "id": "760",
        "AirportCode": "BJZ",
        "AirportName": "Talaveral La Real Airport",
        "City": "Badajoz",
        "Country": "Spain"
    },
    {
        "id": "761",
        "AirportCode": "BKA",
        "AirportName": "Bykovo Airport",
        "City": "Moscow",
        "Country": "Russia"
    },
    {
        "id": "762",
        "AirportCode": "BKB",
        "AirportName": "Bikaner Airport",
        "City": "Bikaner",
        "Country": "India"
    },
    {
        "id": "763",
        "AirportCode": "BKC",
        "AirportName": "Buckland Airport",
        "City": "Buckland",
        "Country": "USA Alaska"
    },
    {
        "id": "764",
        "AirportCode": "BKD",
        "AirportName": "Stephens County Airport",
        "City": "Breckenridge",
        "Country": "USA Texas"
    },
    {
        "id": "765",
        "AirportCode": "BKE",
        "AirportName": "Baker Airport",
        "City": "Baker",
        "Country": "USA Oregon"
    },
    {
        "id": "766",
        "AirportCode": "BKH",
        "AirportName": "Barking Sands Airport",
        "City": "Kekaha",
        "Country": "USA Hawaii"
    },
    {
        "id": "767",
        "AirportCode": "BKI",
        "AirportName": "Kota Kinabalu Airport",
        "City": "Kota Kinabalu",
        "Country": "Malaysia"
    },
    {
        "id": "768",
        "AirportCode": "BKJ",
        "AirportName": "Boke Airport",
        "City": "Boke",
        "Country": "Guinea"
    },
    {
        "id": "769",
        "AirportCode": "BKK",
        "AirportName": "Airport",
        "City": "Bangkok",
        "Country": "Thailand"
    },
    {
        "id": "770",
        "AirportCode": "BKL",
        "AirportName": "Burke Lakefront Airport",
        "City": "Cleveland",
        "Country": "USA Ohio"
    },
    {
        "id": "771",
        "AirportCode": "BKM",
        "AirportName": "Bakalalan Airport",
        "City": "Bakalalan",
        "Country": "Malaysia"
    },
    {
        "id": "772",
        "AirportCode": "BKN",
        "AirportName": "Birni Nkoni Airport",
        "City": "Birni Nkoni",
        "Country": "Niger"
    },
    {
        "id": "773",
        "AirportCode": "BKO",
        "AirportName": "Bamako Airport",
        "City": "Bamako",
        "Country": "Mali"
    },
    {
        "id": "774",
        "AirportCode": "BKP",
        "AirportName": "Barkly Downs Airport",
        "City": "Barkly Downs",
        "Country": "Australia"
    },
    {
        "id": "775",
        "AirportCode": "BKQ",
        "AirportName": "Blackall Airport",
        "City": "Blackall",
        "Country": "Australia"
    },
    {
        "id": "776",
        "AirportCode": "BKR",
        "AirportName": "Bokoro Airport",
        "City": "Bokoro",
        "Country": "Mali"
    },
    {
        "id": "777",
        "AirportCode": "BKS",
        "AirportName": "Padangkemiling Airport",
        "City": "Bengkulu",
        "Country": "Indonesia"
    },
    {
        "id": "778",
        "AirportCode": "BKT",
        "AirportName": "Blackstone Army Air Field",
        "City": "Blackstone",
        "Country": "USA Virginia"
    },
    {
        "id": "779",
        "AirportCode": "BKU",
        "AirportName": "Betioky Airport",
        "City": "Betioky",
        "Country": "Madagascar"
    },
    {
        "id": "780",
        "AirportCode": "BKW",
        "AirportName": "Beckley Airport",
        "City": "Beckley",
        "Country": "USA West Virginia"
    },
    {
        "id": "781",
        "AirportCode": "BKX",
        "AirportName": "Brookings Airport",
        "City": "Brookings",
        "Country": "USA South Dakota"
    },
    {
        "id": "782",
        "AirportCode": "BKY",
        "AirportName": "Kamenbe",
        "City": "Bukavu",
        "Country": "Congo, DR"
    },
    {
        "id": "783",
        "AirportCode": "BKZ",
        "AirportName": "Bukoba Airport",
        "City": "Bukoba",
        "Country": "Tanzania"
    },
    {
        "id": "784",
        "AirportCode": "BLA",
        "AirportName": "Gen J A Anzoategui Airport",
        "City": "Barcelona",
        "Country": "Venezuela"
    },
    {
        "id": "785",
        "AirportCode": "BLB",
        "AirportName": "Balboa Airport",
        "City": "Balboa",
        "Country": "Panama"
    },
    {
        "id": "786",
        "AirportCode": "BLC",
        "AirportName": "Bali Airport",
        "City": "Bali",
        "Country": "Cameroon"
    },
    {
        "id": "787",
        "AirportCode": "BLD",
        "AirportName": "Boulder City Airport",
        "City": "Boulder City",
        "Country": "USA Nevada"
    },
    {
        "id": "788",
        "AirportCode": "BLE",
        "AirportName": "Dala Airport",
        "City": "Borlange/Falun",
        "Country": "Sweden"
    },
    {
        "id": "789",
        "AirportCode": "BLF",
        "AirportName": "Mercer County Airport",
        "City": "Bluefield",
        "Country": "USA West Virginia"
    },
    {
        "id": "790",
        "AirportCode": "BLG",
        "AirportName": "Belaga Airport",
        "City": "Belaga",
        "Country": "Malaysia"
    },
    {
        "id": "791",
        "AirportCode": "BLH",
        "AirportName": "Blythe Airport",
        "City": "Blythe",
        "Country": "USA California"
    },
    {
        "id": "792",
        "AirportCode": "BLI",
        "AirportName": "Bellingham Airport",
        "City": "Bellingham",
        "Country": "USA Washington"
    },
    {
        "id": "793",
        "AirportCode": "BLJ",
        "AirportName": "Batna Airport",
        "City": "Batna",
        "Country": "Algeria"
    },
    {
        "id": "794",
        "AirportCode": "BLK",
        "AirportName": "Blackpool Airport",
        "City": "Blackpool",
        "Country": "United Kingdom"
    },
    {
        "id": "795",
        "AirportCode": "BLL",
        "AirportName": "Billund Airport",
        "City": "Billund",
        "Country": "Denmark"
    },
    {
        "id": "796",
        "AirportCode": "BLM",
        "AirportName": "Monmouth County Airport",
        "City": "Belmar",
        "Country": "USA New Jersey"
    },
    {
        "id": "797",
        "AirportCode": "BLN",
        "AirportName": "Benalla Airport",
        "City": "Benalla",
        "Country": "Australia"
    },
    {
        "id": "798",
        "AirportCode": "BLO",
        "AirportName": "Blonduos Airport",
        "City": "Blonduos",
        "Country": "Iceland"
    },
    {
        "id": "799",
        "AirportCode": "BLP",
        "AirportName": "Bellavista Airport",
        "City": "Bellavista",
        "Country": "Peru"
    },
    {
        "id": "800",
        "AirportCode": "BLQ",
        "AirportName": "Guglielmo Marconi Airport",
        "City": "Bologna",
        "Country": "Italy"
    },
    {
        "id": "801",
        "AirportCode": "BLR",
        "AirportName": "Bengaluru International Airport",
        "City": "Bangalore",
        "Country": "India"
    },
    {
        "id": "802",
        "AirportCode": "BLS",
        "AirportName": "Bollon Airport",
        "City": "Bollon",
        "Country": "Australia"
    },
    {
        "id": "803",
        "AirportCode": "BLT",
        "AirportName": "Blackwater Airport",
        "City": "Blackwater",
        "Country": "Australia"
    },
    {
        "id": "804",
        "AirportCode": "BLU",
        "AirportName": "Blue Canyon Airport",
        "City": "Blue Canyon",
        "Country": "USA California"
    },
    {
        "id": "805",
        "AirportCode": "BLV",
        "AirportName": "Scott AFB/Mid America",
        "City": "Belleville",
        "Country": "USA Illinois"
    },
    {
        "id": "806",
        "AirportCode": "BLW",
        "AirportName": "Bellows Field",
        "City": "Waimanalo",
        "Country": "USA Hawaii"
    },
    {
        "id": "807",
        "AirportCode": "BLX",
        "AirportName": "Belluno Airport",
        "City": "Belluno",
        "Country": "Italy"
    },
    {
        "id": "808",
        "AirportCode": "BLY",
        "AirportName": "Belmullet Airport",
        "City": "Belmullet",
        "Country": "Ireland"
    },
    {
        "id": "809",
        "AirportCode": "BLZ",
        "AirportName": "Chileka Airport",
        "City": "Blantyre",
        "Country": "Malawi"
    },
    {
        "id": "810",
        "AirportCode": "BMA",
        "AirportName": "Bromma Airport",
        "City": "Stockholm",
        "Country": "Sweden"
    },
    {
        "id": "811",
        "AirportCode": "BMB",
        "AirportName": "Bumba Airport",
        "City": "Bumba",
        "Country": "Congo, DR"
    },
    {
        "id": "812",
        "AirportCode": "BMC",
        "AirportName": "Brigham City Airport",
        "City": "Brigham City",
        "Country": "USA Utah"
    },
    {
        "id": "813",
        "AirportCode": "BMD",
        "AirportName": "Belo Airport",
        "City": "Belo",
        "Country": "Madagascar"
    },
    {
        "id": "814",
        "AirportCode": "BME",
        "AirportName": "Broome Airport",
        "City": "Broome",
        "Country": "Australia"
    },
    {
        "id": "815",
        "AirportCode": "BMF",
        "AirportName": "Bakouma Airport",
        "City": "Bakouma",
        "Country": "Central African Republic"
    },
    {
        "id": "816",
        "AirportCode": "BMG",
        "AirportName": "Bloomington Airport",
        "City": "Bloomington",
        "Country": "USA Indiana"
    },
    {
        "id": "817",
        "AirportCode": "BMH",
        "AirportName": "Bomai Airport",
        "City": "Bomai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "818",
        "AirportCode": "BMI",
        "AirportName": "Bloomington-Normal Airport",
        "City": "Bloomington-Normal",
        "Country": "USA Illinois"
    },
    {
        "id": "819",
        "AirportCode": "BMJ",
        "AirportName": "Baramita Airport",
        "City": "Baramita",
        "Country": "Guyana"
    },
    {
        "id": "820",
        "AirportCode": "BMK",
        "AirportName": "Borkum Airport",
        "City": "Borkum",
        "Country": "Germany"
    },
    {
        "id": "821",
        "AirportCode": "BML",
        "AirportName": "Berlin Airport",
        "City": "Berlin",
        "Country": "USA New Hampshire"
    },
    {
        "id": "822",
        "AirportCode": "BMM",
        "AirportName": "Airport",
        "City": "Bitam",
        "Country": "Gabon"
    },
    {
        "id": "823",
        "AirportCode": "BMN",
        "AirportName": "Bamerny Airport",
        "City": "Bamerny",
        "Country": "Iraq"
    },
    {
        "id": "824",
        "AirportCode": "BMO",
        "AirportName": "Bhamo Airport",
        "City": "Bhamo",
        "Country": "Burma"
    },
    {
        "id": "825",
        "AirportCode": "BMP",
        "AirportName": "Brampton Island Airport",
        "City": "Brampton Island",
        "Country": "Australia"
    },
    {
        "id": "826",
        "AirportCode": "BMQ",
        "AirportName": "Bamburi Airport",
        "City": "Bamburi",
        "Country": "Kenya"
    },
    {
        "id": "827",
        "AirportCode": "BMR",
        "AirportName": "Baltrum Airport",
        "City": "Baltrum",
        "Country": "Germany"
    },
    {
        "id": "828",
        "AirportCode": "BMS",
        "AirportName": "Brumado Airport",
        "City": "Brumado",
        "Country": "Brazil"
    },
    {
        "id": "829",
        "AirportCode": "BMT",
        "AirportName": "Beaumont Municipal Airport",
        "City": "Beaumont",
        "Country": "USA Texas"
    },
    {
        "id": "830",
        "AirportCode": "BMU",
        "AirportName": "Bima Airport",
        "City": "Bima",
        "Country": "Indonesia"
    },
    {
        "id": "831",
        "AirportCode": "BMV",
        "AirportName": "Phung-Duc Airport",
        "City": "Banmethuot",
        "Country": "Vietnam"
    },
    {
        "id": "832",
        "AirportCode": "BMW",
        "AirportName": "Bordj Badji Mokhtar Airport",
        "City": "Bordj Badji Mokhtar",
        "Country": "Algeria"
    },
    {
        "id": "833",
        "AirportCode": "BMY",
        "AirportName": "Belep Island Airport",
        "City": "Belep Island",
        "Country": "New Caledonia"
    },
    {
        "id": "834",
        "AirportCode": "BMZ",
        "AirportName": "Bamu Airport",
        "City": "Bamu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "835",
        "AirportCode": "BNA",
        "AirportName": "Nashville Metropolitan Airport",
        "City": "Nashville",
        "Country": "USA Tennessee"
    },
    {
        "id": "836",
        "AirportCode": "BNB",
        "AirportName": "Boende Airport",
        "City": "Boende",
        "Country": "Congo, DR"
    },
    {
        "id": "837",
        "AirportCode": "BNC",
        "AirportName": "Beni Airport",
        "City": "Beni",
        "Country": "Congo, DR"
    },
    {
        "id": "838",
        "AirportCode": "BND",
        "AirportName": "Bandar Abbas Airport",
        "City": "Bandar Abbas",
        "Country": "Iran"
    },
    {
        "id": "839",
        "AirportCode": "BNE",
        "AirportName": "Brisbane International Airport",
        "City": "Brisbane",
        "Country": "Australia"
    },
    {
        "id": "840",
        "AirportCode": "BNG",
        "AirportName": "Banning Airport",
        "City": "Banning",
        "Country": "USA California"
    },
    {
        "id": "841",
        "AirportCode": "BNH",
        "AirportName": "Barnes Airport",
        "City": "Hartford",
        "Country": "USA Connecticut"
    },
    {
        "id": "842",
        "AirportCode": "BNI",
        "AirportName": "Benin City Airport",
        "City": "Benin City",
        "Country": "Nigeria"
    },
    {
        "id": "843",
        "AirportCode": "BNK",
        "AirportName": "Ballina Byron Airport",
        "City": "Ballina",
        "Country": "Australia"
    },
    {
        "id": "844",
        "AirportCode": "BNL",
        "AirportName": "Barnwell County Airport",
        "City": "Barnwell",
        "Country": "USA South Carolina"
    },
    {
        "id": "845",
        "AirportCode": "BNM",
        "AirportName": "Bodinumu Airport",
        "City": "Bodinumu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "846",
        "AirportCode": "BNN",
        "AirportName": "Bronnoy Airport",
        "City": "Bronnoysund",
        "Country": "Norway"
    },
    {
        "id": "847",
        "AirportCode": "BNO",
        "AirportName": "Burns Airport",
        "City": "Burns",
        "Country": "USA Oregon"
    },
    {
        "id": "848",
        "AirportCode": "BNP",
        "AirportName": "Bannu Airport",
        "City": "Bannu",
        "Country": "Pakistan"
    },
    {
        "id": "849",
        "AirportCode": "BNQ",
        "AirportName": "Baganga Airport",
        "City": "Baganga",
        "Country": "Philippines"
    },
    {
        "id": "850",
        "AirportCode": "BNR",
        "AirportName": "Banfora Airport",
        "City": "Banfora",
        "Country": "Burkina Faso"
    },
    {
        "id": "851",
        "AirportCode": "BNS",
        "AirportName": "Barinas Airport",
        "City": "Barinas",
        "Country": "Venezuela"
    },
    {
        "id": "852",
        "AirportCode": "BNT",
        "AirportName": "Bundi Airport",
        "City": "Bundi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "853",
        "AirportCode": "BNU",
        "AirportName": "Blumenau Airport",
        "City": "Blumenau",
        "Country": "Brazil"
    },
    {
        "id": "854",
        "AirportCode": "BNV",
        "AirportName": "Boana Airport",
        "City": "Boana",
        "Country": "Papua New Guinea"
    },
    {
        "id": "855",
        "AirportCode": "BNW",
        "AirportName": "Boone Airport",
        "City": "Boone",
        "Country": "USA Iowa"
    },
    {
        "id": "856",
        "AirportCode": "BNX",
        "AirportName": "Banja Luka Airport",
        "City": "Banja Luka",
        "Country": "Bosnia & Herzegovina"
    },
    {
        "id": "857",
        "AirportCode": "BNY",
        "AirportName": "Bellona Airport",
        "City": "Bellona",
        "Country": "Solomon Islands"
    },
    {
        "id": "858",
        "AirportCode": "BNZ",
        "AirportName": "Banz Airport",
        "City": "Banz",
        "Country": "Papua New Guinea"
    },
    {
        "id": "859",
        "AirportCode": "BOA",
        "AirportName": "Boma Airport",
        "City": "Boma",
        "Country": "Congo, DR"
    },
    {
        "id": "860",
        "AirportCode": "BOB",
        "AirportName": "Motu-mute Airport",
        "City": "Bora Bora",
        "Country": "French Polynesia"
    },
    {
        "id": "861",
        "AirportCode": "BOC",
        "AirportName": "Bocas Del Toro Airport",
        "City": "Bocas Del Toro",
        "Country": "Panama"
    },
    {
        "id": "862",
        "AirportCode": "BOD",
        "AirportName": "Bordeaux Airport",
        "City": "Bordeaux",
        "Country": "France"
    },
    {
        "id": "863",
        "AirportCode": "BOE",
        "AirportName": "Boundji Airport",
        "City": "Boundji",
        "Country": "Congo"
    },
    {
        "id": "864",
        "AirportCode": "BOF",
        "AirportName": "Bolling Air Force Base",
        "City": "Washington",
        "Country": "USA DC"
    },
    {
        "id": "865",
        "AirportCode": "BOG",
        "AirportName": "Eldorado Airport",
        "City": "Bogota",
        "Country": "Colombia"
    },
    {
        "id": "866",
        "AirportCode": "BOH",
        "AirportName": "Bournemouth Airport",
        "City": "Bournemouth",
        "Country": "United Kingdom"
    },
    {
        "id": "867",
        "AirportCode": "BOI",
        "AirportName": "Boise Air Terminal (Gowen Field)",
        "City": "Boise",
        "Country": "USA Idaho"
    },
    {
        "id": "868",
        "AirportCode": "BOJ",
        "AirportName": "Airport",
        "City": "Bourgas",
        "Country": "Bulgaria"
    },
    {
        "id": "869",
        "AirportCode": "BOK",
        "AirportName": "Brookings State Airport",
        "City": "Brookings",
        "Country": "USA Oregon"
    },
    {
        "id": "870",
        "AirportCode": "BOL",
        "AirportName": "Bally Kelly Airport",
        "City": "Bally Kelly",
        "Country": "United Kingdom"
    },
    {
        "id": "871",
        "AirportCode": "BOM",
        "AirportName": "Chhatrapati Shivaji International Airport",
        "City": "Mumbai",
        "Country": "India"
    },
    {
        "id": "872",
        "AirportCode": "BON",
        "AirportName": "Flamingo International Airport",
        "City": "Bonaire",
        "Country": "Netherlands Antilles"
    },
    {
        "id": "873",
        "AirportCode": "BOO",
        "AirportName": "Bodo Airport",
        "City": "Bodo",
        "Country": "Norway"
    },
    {
        "id": "874",
        "AirportCode": "BOP",
        "AirportName": "Bouar Airport",
        "City": "Bouar",
        "Country": "Central African Republic"
    },
    {
        "id": "875",
        "AirportCode": "BOQ",
        "AirportName": "Boku Airport",
        "City": "Boku",
        "Country": "Papua New Guinea"
    },
    {
        "id": "876",
        "AirportCode": "BOR",
        "AirportName": "Fontaine Airport",
        "City": "Belfort",
        "Country": "France"
    },
    {
        "id": "877",
        "AirportCode": "BOS",
        "AirportName": "Logan International Airport",
        "City": "Boston",
        "Country": "USA Massachusetts"
    },
    {
        "id": "878",
        "AirportCode": "BOT",
        "AirportName": "Boset Airport",
        "City": "Boset",
        "Country": "Papua New Guinea"
    },
    {
        "id": "879",
        "AirportCode": "BOU",
        "AirportName": "Bourges Airport",
        "City": "Bourges",
        "Country": "France"
    },
    {
        "id": "880",
        "AirportCode": "BOV",
        "AirportName": "Boang Airport",
        "City": "Boang",
        "Country": "Papua New Guinea"
    },
    {
        "id": "881",
        "AirportCode": "BOW",
        "AirportName": "Bartow Airport",
        "City": "Bartow",
        "Country": "USA Florida"
    },
    {
        "id": "882",
        "AirportCode": "BOX",
        "AirportName": "Borroloola Airport",
        "City": "Borroloola",
        "Country": "Australia"
    },
    {
        "id": "883",
        "AirportCode": "BOY",
        "AirportName": "Borgo Airport",
        "City": "Bobo Dioulasso",
        "Country": "Burkina Faso"
    },
    {
        "id": "884",
        "AirportCode": "BOZ",
        "AirportName": "Bozoum Airport",
        "City": "Bozoum",
        "Country": "Central African Republic"
    },
    {
        "id": "885",
        "AirportCode": "BPA",
        "AirportName": "Grumman Airport",
        "City": "Bethpage",
        "Country": "USA New York"
    },
    {
        "id": "886",
        "AirportCode": "BPB",
        "AirportName": "Boridi Airport",
        "City": "Boridi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "887",
        "AirportCode": "BPC",
        "AirportName": "Bamenda Airport",
        "City": "Bamenda",
        "Country": "Cameroon"
    },
    {
        "id": "888",
        "AirportCode": "BPD",
        "AirportName": "Bapi Airport",
        "City": "Bapi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "889",
        "AirportCode": "BPE",
        "AirportName": "Bagan Airport",
        "City": "Bagan",
        "Country": "Burma"
    },
    {
        "id": "890",
        "AirportCode": "BPF",
        "AirportName": "Batuna Airport",
        "City": "Batuna",
        "Country": "Solomon Islands"
    },
    {
        "id": "891",
        "AirportCode": "BPG",
        "AirportName": "Barra Do Garcas Airport",
        "City": "Barra Do Garcas",
        "Country": "Brazil"
    },
    {
        "id": "892",
        "AirportCode": "BPH",
        "AirportName": "Bislig Airport",
        "City": "Bislig",
        "Country": "Philippines"
    },
    {
        "id": "893",
        "AirportCode": "BPI",
        "AirportName": "Big Piney-Marbleton Airport",
        "City": "Big Piney",
        "Country": "USA Wyoming"
    },
    {
        "id": "894",
        "AirportCode": "BPK",
        "AirportName": "Biangabip Airport",
        "City": "Biangabip",
        "Country": "Papua New Guinea"
    },
    {
        "id": "895",
        "AirportCode": "BPN",
        "AirportName": "Sepingan Airport",
        "City": "Balikpapan",
        "Country": "Indonesia"
    },
    {
        "id": "896",
        "AirportCode": "BPS",
        "AirportName": "Porto Seguro Airport",
        "City": "Porto Seguro",
        "Country": "Brazil"
    },
    {
        "id": "897",
        "AirportCode": "BPT",
        "AirportName": "Jefferson County Airport",
        "City": "Beaumont",
        "Country": "USA Texas"
    },
    {
        "id": "898",
        "AirportCode": "BPU",
        "AirportName": "Beppu Airport",
        "City": "Beppu",
        "Country": "Japan"
    },
    {
        "id": "899",
        "AirportCode": "BPX",
        "AirportName": "Bangda Airport",
        "City": "Bangda",
        "Country": "China"
    },
    {
        "id": "900",
        "AirportCode": "BPY",
        "AirportName": "Besalampy Airport",
        "City": "Besalampy",
        "Country": "Madagascar"
    },
    {
        "id": "901",
        "AirportCode": "BQA",
        "AirportName": "Baler Airport",
        "City": "Baler",
        "Country": "Philippines"
    },
    {
        "id": "902",
        "AirportCode": "BQB",
        "AirportName": "Bussellton Airport",
        "City": "Bussellton",
        "Country": "Australia"
    },
    {
        "id": "903",
        "AirportCode": "BQE",
        "AirportName": "Bubaque Airport",
        "City": "Bubaque",
        "Country": "Guinea-Bissau"
    },
    {
        "id": "904",
        "AirportCode": "BQH",
        "AirportName": "Biggin Hill Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "905",
        "AirportCode": "BQI",
        "AirportName": "Bagani Airport",
        "City": "Bagani",
        "Country": "Namibia"
    },
    {
        "id": "906",
        "AirportCode": "BQK",
        "AirportName": "Glynco Jetport",
        "City": "Brunswick",
        "Country": "USA Georgia"
    },
    {
        "id": "907",
        "AirportCode": "BQL",
        "AirportName": "Boulia Airport",
        "City": "Boulia",
        "Country": "Australia"
    },
    {
        "id": "908",
        "AirportCode": "BQN",
        "AirportName": "Borinquen Airport",
        "City": "Aguadilla",
        "Country": "Puerto Rico"
    },
    {
        "id": "909",
        "AirportCode": "BQQ",
        "AirportName": "Barra Airport",
        "City": "Barra",
        "Country": "Brazil"
    },
    {
        "id": "910",
        "AirportCode": "BQS",
        "AirportName": "Blagoveschensk Airport",
        "City": "Blagoveschensk",
        "Country": "Russia"
    },
    {
        "id": "911",
        "AirportCode": "BQT",
        "AirportName": "Brest Airport",
        "City": "Brest",
        "Country": "Belarus"
    },
    {
        "id": "912",
        "AirportCode": "BQU",
        "AirportName": "Bequia Airport",
        "City": "Port Elizabeth",
        "Country": "St Vincent"
    },
    {
        "id": "913",
        "AirportCode": "BQW",
        "AirportName": "Balgo Hills Airport",
        "City": "Balgo Hills",
        "Country": "Australia"
    },
    {
        "id": "914",
        "AirportCode": "BRA",
        "AirportName": "Barreiras Airport",
        "City": "Barreiras",
        "Country": "Brazil"
    },
    {
        "id": "915",
        "AirportCode": "BRB",
        "AirportName": "Barreirinha",
        "City": "Barreirinha",
        "Country": "Brazil"
    },
    {
        "id": "916",
        "AirportCode": "BRC",
        "AirportName": "San Carlos de Bariloche International Airport",
        "City": "San Carlos de Bariloche",
        "Country": "Argentina"
    },
    {
        "id": "917",
        "AirportCode": "BRD",
        "AirportName": "Crow Wing County Airport",
        "City": "Brainerd",
        "Country": "USA Minnesota"
    },
    {
        "id": "918",
        "AirportCode": "BRE",
        "AirportName": "Bremen Airport",
        "City": "Bremen",
        "Country": "Germany"
    },
    {
        "id": "919",
        "AirportCode": "BRF",
        "AirportName": "Bradford Airport",
        "City": "Bradford",
        "Country": "United Kingdom"
    },
    {
        "id": "920",
        "AirportCode": "BRG",
        "AirportName": "Whitesburg Municipal Airport",
        "City": "Whitesburg",
        "Country": "USA Kentucky"
    },
    {
        "id": "921",
        "AirportCode": "BRH",
        "AirportName": "Brahman Airport",
        "City": "Brahman",
        "Country": "Papua New Guinea"
    },
    {
        "id": "922",
        "AirportCode": "BRI",
        "AirportName": "Palese Airport",
        "City": "Bari",
        "Country": "Italy"
    },
    {
        "id": "923",
        "AirportCode": "BRJ",
        "AirportName": "Bright Airport",
        "City": "Bright",
        "Country": "Australia"
    },
    {
        "id": "924",
        "AirportCode": "BRK",
        "AirportName": "Bourke Airport",
        "City": "Bourke",
        "Country": "Australia"
    },
    {
        "id": "925",
        "AirportCode": "BRL",
        "AirportName": "Burlington Airport",
        "City": "Burlington",
        "Country": "USA Iowa"
    },
    {
        "id": "926",
        "AirportCode": "BRM",
        "AirportName": "Barquisimeto Airport",
        "City": "Barquisimeto",
        "Country": "Venezuela"
    },
    {
        "id": "927",
        "AirportCode": "BRN",
        "AirportName": "Belp Airport",
        "City": "Berne",
        "Country": "Switzerland"
    },
    {
        "id": "928",
        "AirportCode": "BRO",
        "AirportName": "South Padre Island International Airport",
        "City": "Brownsville",
        "Country": "USA Texas"
    },
    {
        "id": "929",
        "AirportCode": "BRP",
        "AirportName": "Biaru Airport",
        "City": "Biaru",
        "Country": "Papua New Guinea"
    },
    {
        "id": "930",
        "AirportCode": "BRQ",
        "AirportName": "Turany Airport",
        "City": "Brno",
        "Country": "Czech Republic"
    },
    {
        "id": "931",
        "AirportCode": "BRR",
        "AirportName": "North Bay Airport",
        "City": "Barra",
        "Country": "United Kingdom"
    },
    {
        "id": "932",
        "AirportCode": "BRS",
        "AirportName": "Bristol Airport",
        "City": "Bristol",
        "Country": "United Kingdom"
    },
    {
        "id": "933",
        "AirportCode": "BRT",
        "AirportName": "Bathurst Island Airport",
        "City": "Bathurst Island",
        "Country": "Australia"
    },
    {
        "id": "934",
        "AirportCode": "BRU",
        "AirportName": "National Airport",
        "City": "Brussels",
        "Country": "Belgium"
    },
    {
        "id": "935",
        "AirportCode": "BRV",
        "AirportName": "Bremerhaven Airport",
        "City": "Bremerhaven",
        "Country": "Germany"
    },
    {
        "id": "936",
        "AirportCode": "BRW",
        "AirportName": "Wiley Post/Will Rogers Memorial Airport",
        "City": "Barrow",
        "Country": "USA Alaska"
    },
    {
        "id": "937",
        "AirportCode": "BRX",
        "AirportName": "Barahona Airport",
        "City": "Barahona",
        "Country": "Dominican Republic"
    },
    {
        "id": "938",
        "AirportCode": "BRY",
        "AirportName": "Samuels Field",
        "City": "Bardstown",
        "Country": "USA Kentucky"
    },
    {
        "id": "939",
        "AirportCode": "BSA",
        "AirportName": "Bossaso Airport",
        "City": "Bossaso",
        "Country": "Somalia"
    },
    {
        "id": "940",
        "AirportCode": "BSB",
        "AirportName": "Brasilia International Airport",
        "City": "Brasilia",
        "Country": "Brazil"
    },
    {
        "id": "941",
        "AirportCode": "BSC",
        "AirportName": "Bahia Solano Airport",
        "City": "Bahia Solano",
        "Country": "Colombia"
    },
    {
        "id": "942",
        "AirportCode": "BSD",
        "AirportName": "Baoshan Airport",
        "City": "Baoshan",
        "Country": "China"
    },
    {
        "id": "943",
        "AirportCode": "BSE",
        "AirportName": "Sematan Airport",
        "City": "Sematan",
        "Country": "Malaysia"
    },
    {
        "id": "944",
        "AirportCode": "BSF",
        "AirportName": "Bradshaw Army Air Field",
        "City": "Pohakuloa",
        "Country": "USA Hawaii"
    },
    {
        "id": "945",
        "AirportCode": "BSG",
        "AirportName": "Bata Airport",
        "City": "Bata",
        "Country": "Equatorial Guinea"
    },
    {
        "id": "946",
        "AirportCode": "BSH",
        "AirportName": "Brighton Airport",
        "City": "Brighton",
        "Country": "United Kingdom"
    },
    {
        "id": "947",
        "AirportCode": "BSI",
        "AirportName": "Blairsville Airport",
        "City": "Blairsville",
        "Country": "USA Philadelphia"
    },
    {
        "id": "948",
        "AirportCode": "BSJ",
        "AirportName": "Bairnsdale Airport",
        "City": "Bairnsdale",
        "Country": "Australia"
    },
    {
        "id": "949",
        "AirportCode": "BSK",
        "AirportName": "Biskra Airport",
        "City": "Biskra",
        "Country": "Algeria"
    },
    {
        "id": "950",
        "AirportCode": "BSL",
        "AirportName": "EuroAirport Swiss",
        "City": "Basel, Switzerland/Mulhouse",
        "Country": "France"
    },
    {
        "id": "951",
        "AirportCode": "BSM",
        "AirportName": "Bishe-Kola Airport",
        "City": "Bishe-Kola",
        "Country": "Iran"
    },
    {
        "id": "952",
        "AirportCode": "BSN",
        "AirportName": "Bossangoa Airport",
        "City": "Bossangoa",
        "Country": "Central African Republic"
    },
    {
        "id": "953",
        "AirportCode": "BSO",
        "AirportName": "Basco Airport",
        "City": "Basco",
        "Country": "Philippines"
    },
    {
        "id": "954",
        "AirportCode": "BSP",
        "AirportName": "Bensbach Airport",
        "City": "Bensbach",
        "Country": "Papua New Guinea"
    },
    {
        "id": "955",
        "AirportCode": "BSQ",
        "AirportName": "Bisbee Municipal Airport",
        "City": "Bisbee",
        "Country": "USA Arizona"
    },
    {
        "id": "956",
        "AirportCode": "BSR",
        "AirportName": "Basra International Airport",
        "City": "Basra",
        "Country": "Iraq"
    },
    {
        "id": "957",
        "AirportCode": "BSS",
        "AirportName": "Balsas Airport",
        "City": "Balsas",
        "Country": "Brazil"
    },
    {
        "id": "958",
        "AirportCode": "BST",
        "AirportName": "Bost Airport",
        "City": "Bost",
        "Country": "Afghanistan"
    },
    {
        "id": "959",
        "AirportCode": "BSU",
        "AirportName": "Basankusu Airport",
        "City": "Basankusu",
        "Country": "Congo, DR"
    },
    {
        "id": "960",
        "AirportCode": "BSV",
        "AirportName": "Besakoa Airport",
        "City": "Besakoa",
        "Country": "Madagascar"
    },
    {
        "id": "961",
        "AirportCode": "BSX",
        "AirportName": "Bassein Airport",
        "City": "Bassein",
        "Country": "Burma"
    },
    {
        "id": "962",
        "AirportCode": "BSY",
        "AirportName": "Bardera Airport",
        "City": "Bardera",
        "Country": "Somalia"
    },
    {
        "id": "963",
        "AirportCode": "BSZ",
        "AirportName": "Bartletts Airport",
        "City": "Bartletts",
        "Country": "USA Alaska"
    },
    {
        "id": "964",
        "AirportCode": "BTA",
        "AirportName": "Bertoua Airport",
        "City": "Bertoua",
        "Country": "Cameroon"
    },
    {
        "id": "965",
        "AirportCode": "BTB",
        "AirportName": "Betou Airport",
        "City": "Betou",
        "Country": "Congo"
    },
    {
        "id": "966",
        "AirportCode": "BTC",
        "AirportName": "Batticaloa Airport",
        "City": "Batticaloa",
        "Country": "Sri Lanka"
    },
    {
        "id": "967",
        "AirportCode": "BTD",
        "AirportName": "Brunette Downs Airport",
        "City": "Brunette Downs",
        "Country": "Australia"
    },
    {
        "id": "968",
        "AirportCode": "BTE",
        "AirportName": "Bonthe Airport",
        "City": "Bonthe",
        "Country": "Sierra Leone"
    },
    {
        "id": "969",
        "AirportCode": "BTF",
        "AirportName": "Salt Lake Skypark",
        "City": "Bountiful",
        "Country": "USA Utah"
    },
    {
        "id": "970",
        "AirportCode": "BTG",
        "AirportName": "Batangafo Airport",
        "City": "Batangafo",
        "Country": "Central African Republic"
    },
    {
        "id": "971",
        "AirportCode": "BTH",
        "AirportName": "Hang Nadim Airport",
        "City": "Batam",
        "Country": "Indonesia"
    },
    {
        "id": "972",
        "AirportCode": "BTI",
        "AirportName": "Barter Island Airport",
        "City": "Barter Island",
        "Country": "USA Alaska"
    },
    {
        "id": "973",
        "AirportCode": "BTJ",
        "AirportName": "Blang Bintang Airport",
        "City": "Banda Aceh",
        "Country": "Indonesia"
    },
    {
        "id": "974",
        "AirportCode": "BTK",
        "AirportName": "Bratsk Airport",
        "City": "Bratsk",
        "Country": "Russia"
    },
    {
        "id": "975",
        "AirportCode": "BTL",
        "AirportName": "WK Kellogg Regional Airport",
        "City": "Battle Creek",
        "Country": "USA Michigan"
    },
    {
        "id": "976",
        "AirportCode": "BTM",
        "AirportName": "Butte Airport",
        "City": "Butte",
        "Country": "USA Montana"
    },
    {
        "id": "977",
        "AirportCode": "BTN",
        "AirportName": "Bennettsville Airport",
        "City": "Bennettsville",
        "Country": "USA South Carolina"
    },
    {
        "id": "978",
        "AirportCode": "BTO",
        "AirportName": "Botopasie Airport",
        "City": "Botopasie",
        "Country": "Suriname"
    },
    {
        "id": "979",
        "AirportCode": "BTP",
        "AirportName": "Graham Field",
        "City": "Butler",
        "Country": "USA Philadelphia"
    },
    {
        "id": "980",
        "AirportCode": "BTQ",
        "AirportName": "Butare Airport",
        "City": "Butare",
        "Country": "Rwanda"
    },
    {
        "id": "981",
        "AirportCode": "BTR",
        "AirportName": "Ryan Airport",
        "City": "Baton Rouge",
        "Country": "USA Louisiana"
    },
    {
        "id": "982",
        "AirportCode": "BTS",
        "AirportName": "Ivanka Airport",
        "City": "Bratislava (BTS)",
        "Country": "Slovakia"
    },
    {
        "id": "983",
        "AirportCode": "BTT",
        "AirportName": "Bettles Airport",
        "City": "Bettles",
        "Country": "USA Alaska"
    },
    {
        "id": "984",
        "AirportCode": "BTU",
        "AirportName": "Bintulu Airport",
        "City": "Bintulu",
        "Country": "Malaysia"
    },
    {
        "id": "985",
        "AirportCode": "BTV",
        "AirportName": "Burlington International Airport",
        "City": "Burlington",
        "Country": "USA Vermont"
    },
    {
        "id": "986",
        "AirportCode": "BTW",
        "AirportName": "Batulicin Airport",
        "City": "Batulicin",
        "Country": "Indonesia"
    },
    {
        "id": "987",
        "AirportCode": "BTX",
        "AirportName": "Betoota Airport",
        "City": "Betoota",
        "Country": "Australia"
    },
    {
        "id": "988",
        "AirportCode": "BTY",
        "AirportName": "Beatty Airport",
        "City": "Beatty",
        "Country": "USA Nevada"
    },
    {
        "id": "989",
        "AirportCode": "BTZ",
        "AirportName": "Bursa Airport",
        "City": "Bursa",
        "Country": "Turkey"
    },
    {
        "id": "990",
        "AirportCode": "BUA",
        "AirportName": "Buka Airport",
        "City": "Buka",
        "Country": "Papua New Guinea"
    },
    {
        "id": "991",
        "AirportCode": "BUB",
        "AirportName": "Burwell Municipal Airport",
        "City": "Burwell",
        "Country": "USA Nebraska"
    },
    {
        "id": "992",
        "AirportCode": "BUC",
        "AirportName": "Burketown Airport",
        "City": "Burketown",
        "Country": "Australia"
    },
    {
        "id": "993",
        "AirportCode": "BUD",
        "AirportName": "Ferihegy Airport",
        "City": "Budapest (BUD)",
        "Country": "Hungary"
    },
    {
        "id": "994",
        "AirportCode": "BUF",
        "AirportName": "Buffalo Niagara International Airport",
        "City": "Buffalo",
        "Country": "USA New York"
    },
    {
        "id": "995",
        "AirportCode": "BUI",
        "AirportName": "Bokondini Airport",
        "City": "Bokondini",
        "Country": "Indonesia"
    },
    {
        "id": "996",
        "AirportCode": "BUJ",
        "AirportName": "Ain Eddis Airport",
        "City": "Boussaada",
        "Country": "Algeria"
    },
    {
        "id": "997",
        "AirportCode": "BUK",
        "AirportName": "Albuq Airport",
        "City": "Albuq",
        "Country": "Yemen"
    },
    {
        "id": "998",
        "AirportCode": "BUL",
        "AirportName": "Bulolo Airport",
        "City": "Bulolo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "999",
        "AirportCode": "BUM",
        "AirportName": "Butler Airport",
        "City": "Butler",
        "Country": "USA Missouri"
    },
    {
        "id": "1000",
        "AirportCode": "BUN",
        "AirportName": "Buenaventura Airport",
        "City": "Buenaventura",
        "Country": "Colombia"
    },
    {
        "id": "1001",
        "AirportCode": "BUO",
        "AirportName": "Burao Airport",
        "City": "Burao",
        "Country": "Somalia"
    },
    {
        "id": "1002",
        "AirportCode": "BUP",
        "AirportName": "Bhatinda Airport",
        "City": "Bhatinda",
        "Country": "India"
    },
    {
        "id": "1003",
        "AirportCode": "BUQ",
        "AirportName": "Bulawayo Airport",
        "City": "Bulawayo",
        "Country": "Zimbabwe"
    },
    {
        "id": "1004",
        "AirportCode": "BUR",
        "AirportName": "Bob Hope Airport",
        "City": "Burbank",
        "Country": "USA California"
    },
    {
        "id": "1005",
        "AirportCode": "BUS",
        "AirportName": "Batumi Airport",
        "City": "Batumi",
        "Country": "Georgia"
    },
    {
        "id": "1006",
        "AirportCode": "BUT",
        "AirportName": "Burtonwood Airport",
        "City": "Burtonwood",
        "Country": "United Kingdom"
    },
    {
        "id": "1007",
        "AirportCode": "BUV",
        "AirportName": "Bella Union Airport",
        "City": "Bella Union",
        "Country": "Uruguay"
    },
    {
        "id": "1008",
        "AirportCode": "BUW",
        "AirportName": "Baubau Airport",
        "City": "Baubau",
        "Country": "Indonesia"
    },
    {
        "id": "1009",
        "AirportCode": "BUX",
        "AirportName": "Bunia Airport",
        "City": "Bunia",
        "Country": "Congo, DR"
    },
    {
        "id": "1010",
        "AirportCode": "BUY",
        "AirportName": "Bunbury Airport",
        "City": "Bunbury",
        "Country": "Australia"
    },
    {
        "id": "1011",
        "AirportCode": "BUZ",
        "AirportName": "Bushehr Airport",
        "City": "Bushehr",
        "Country": "Iran"
    },
    {
        "id": "1012",
        "AirportCode": "BVA",
        "AirportName": "Beauvais-Tille Airport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "1013",
        "AirportCode": "BVB",
        "AirportName": "Boa Vista Airport",
        "City": "Boa Vista",
        "Country": "Brazil"
    },
    {
        "id": "1014",
        "AirportCode": "BVC",
        "AirportName": "Airport",
        "City": "Boa Vista",
        "Country": "Cape Verde"
    },
    {
        "id": "1015",
        "AirportCode": "BVE",
        "AirportName": "Laroche Airport",
        "City": "Brive-La-Gaillarde",
        "Country": "France"
    },
    {
        "id": "1016",
        "AirportCode": "BVF",
        "AirportName": "Dama Airport",
        "City": "Bua",
        "Country": "Fiji"
    },
    {
        "id": "1017",
        "AirportCode": "BVG",
        "AirportName": "Berlevag Airport",
        "City": "Berlevag",
        "Country": "Norway"
    },
    {
        "id": "1018",
        "AirportCode": "BVH",
        "AirportName": "Vilhena Airport",
        "City": "Vilhena",
        "Country": "Brazil"
    },
    {
        "id": "1019",
        "AirportCode": "BVI",
        "AirportName": "Birdsville Airport",
        "City": "Birdsville",
        "Country": "Australia"
    },
    {
        "id": "1020",
        "AirportCode": "BVK",
        "AirportName": "Huacaraje Airport",
        "City": "Huacaraje",
        "Country": "Bolivia"
    },
    {
        "id": "1021",
        "AirportCode": "BVL",
        "AirportName": "Baures Airport",
        "City": "Baures",
        "Country": "Bolivia"
    },
    {
        "id": "1022",
        "AirportCode": "BVM",
        "AirportName": "Belmonte Airport",
        "City": "Belmonte",
        "Country": "Brazil"
    },
    {
        "id": "1023",
        "AirportCode": "BVO",
        "AirportName": "Bartlesville Airport",
        "City": "Bartlesville",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1024",
        "AirportCode": "BVP",
        "AirportName": "Bolovip Airport",
        "City": "Bolovip",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1025",
        "AirportCode": "BVR",
        "AirportName": "Esperadinha Airport",
        "City": "Brava",
        "Country": "Cape Verde"
    },
    {
        "id": "1026",
        "AirportCode": "BVS",
        "AirportName": "Breves Airport",
        "City": "Breves",
        "Country": "Brazil"
    },
    {
        "id": "1027",
        "AirportCode": "BVW",
        "AirportName": "Batavia Downs Airport",
        "City": "Batavia Downs",
        "Country": "Australia"
    },
    {
        "id": "1028",
        "AirportCode": "BVX",
        "AirportName": "Batesville Municipal Airport",
        "City": "Batesville",
        "Country": "USA Arkansas"
    },
    {
        "id": "1029",
        "AirportCode": "BVY",
        "AirportName": "Beverly Airport",
        "City": "Beverly",
        "Country": "USA Massachusetts"
    },
    {
        "id": "1030",
        "AirportCode": "BVZ",
        "AirportName": "Beverley Springs Airport",
        "City": "Beverley Springs",
        "Country": "Australia"
    },
    {
        "id": "1031",
        "AirportCode": "BWA",
        "AirportName": "Bhairawa Airport",
        "City": "Bhairawa",
        "Country": "Nepal"
    },
    {
        "id": "1032",
        "AirportCode": "BWB",
        "AirportName": "Barrow Island Airport",
        "City": "Barrow Island",
        "Country": "Australia"
    },
    {
        "id": "1033",
        "AirportCode": "BWC",
        "AirportName": "Brawley Airport",
        "City": "Brawley",
        "Country": "USA California"
    },
    {
        "id": "1034",
        "AirportCode": "BWD",
        "AirportName": "Brownwood Airport",
        "City": "Brownwood",
        "Country": "USA Texas"
    },
    {
        "id": "1035",
        "AirportCode": "BWE",
        "AirportName": "Braunschweig Airport",
        "City": "Braunschweig",
        "Country": "Germany"
    },
    {
        "id": "1036",
        "AirportCode": "BWF",
        "AirportName": "Walney Island Airport",
        "City": "Barrow-In-Furness",
        "Country": "United Kingdom"
    },
    {
        "id": "1037",
        "AirportCode": "BWG",
        "AirportName": "Warren County Airport",
        "City": "Bowling Green",
        "Country": "USA Kentucky"
    },
    {
        "id": "1038",
        "AirportCode": "BWH",
        "AirportName": "Butterworth Airport",
        "City": "Butterworth",
        "Country": "Malaysia"
    },
    {
        "id": "1039",
        "AirportCode": "BWI",
        "AirportName": "Balt./Wash. International Airport",
        "City": "Baltimore",
        "Country": "USA Maryland"
    },
    {
        "id": "1040",
        "AirportCode": "BWJ",
        "AirportName": "Bawan Airport",
        "City": "Bawan",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1041",
        "AirportCode": "BWK",
        "AirportName": "Bol Airport",
        "City": "Bol",
        "Country": "Croatia"
    },
    {
        "id": "1042",
        "AirportCode": "BWL",
        "AirportName": "Blackwell Airport",
        "City": "Blackwell",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1043",
        "AirportCode": "BWM",
        "AirportName": "Bowman Airport",
        "City": "Bowman",
        "Country": "USA North Dakota"
    },
    {
        "id": "1044",
        "AirportCode": "BWN",
        "AirportName": "Brunei International Airport",
        "City": "Bandar Seri Begawan",
        "Country": "Brunei"
    },
    {
        "id": "1045",
        "AirportCode": "BWO",
        "AirportName": "Balakovo Airport",
        "City": "Balakovo",
        "Country": "Russia"
    },
    {
        "id": "1046",
        "AirportCode": "BWP",
        "AirportName": "Bewani Airport",
        "City": "Bewani",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1047",
        "AirportCode": "BWQ",
        "AirportName": "Brewarrina Airport",
        "City": "Brewarrina",
        "Country": "Australia"
    },
    {
        "id": "1048",
        "AirportCode": "BWS",
        "AirportName": "Blaine Airport",
        "City": "Blaine",
        "Country": "USA Washington"
    },
    {
        "id": "1049",
        "AirportCode": "BWT",
        "AirportName": "Burnie Wynyard Airport",
        "City": "Burnie",
        "Country": "Australia"
    },
    {
        "id": "1050",
        "AirportCode": "BWU",
        "AirportName": "Bankstown Airport",
        "City": "Bankstown",
        "Country": "Australia"
    },
    {
        "id": "1051",
        "AirportCode": "BWY",
        "AirportName": "Bentwaters Street Airport",
        "City": "Woodbridge",
        "Country": "United Kingdom"
    },
    {
        "id": "1052",
        "AirportCode": "BXA",
        "AirportName": "George R. Carr Airport",
        "City": "Bogalusa",
        "Country": "USA Louisiana"
    },
    {
        "id": "1053",
        "AirportCode": "BXB",
        "AirportName": "Babo Airport",
        "City": "Babo",
        "Country": "Indonesia"
    },
    {
        "id": "1054",
        "AirportCode": "BXC",
        "AirportName": "Boxborough Airport",
        "City": "Boxborough",
        "Country": "USA Massachusetts"
    },
    {
        "id": "1055",
        "AirportCode": "BXD",
        "AirportName": "Bade Airport",
        "City": "Bade",
        "Country": "Indonesia"
    },
    {
        "id": "1056",
        "AirportCode": "BXE",
        "AirportName": "Bakel Airport",
        "City": "Bakel",
        "Country": "Senegal"
    },
    {
        "id": "1057",
        "AirportCode": "BXF",
        "AirportName": "Belburn Airport",
        "City": "Belburn",
        "Country": "Australia"
    },
    {
        "id": "1058",
        "AirportCode": "BXG",
        "AirportName": "Bendigo Airport",
        "City": "Bendigo",
        "Country": "Australia"
    },
    {
        "id": "1059",
        "AirportCode": "BXH",
        "AirportName": "Balhash Airport",
        "City": "Balhash",
        "Country": "Kazakhstan"
    },
    {
        "id": "1060",
        "AirportCode": "BXJ",
        "AirportName": "Burundai Airport",
        "City": "Burundai",
        "Country": "Kazakhstan"
    },
    {
        "id": "1061",
        "AirportCode": "BXK",
        "AirportName": "Buckeye Airport",
        "City": "Buckeye",
        "Country": "USA Arizona"
    },
    {
        "id": "1062",
        "AirportCode": "BXL",
        "AirportName": "Blue Lagoon Airport",
        "City": "Blue Lagoon",
        "Country": "Fiji"
    },
    {
        "id": "1063",
        "AirportCode": "BXM",
        "AirportName": "Batom Airport",
        "City": "Batom",
        "Country": "Indonesia"
    },
    {
        "id": "1064",
        "AirportCode": "BXN",
        "AirportName": "Imsik Airport",
        "City": "Bodrum",
        "Country": "Turkey"
    },
    {
        "id": "1065",
        "AirportCode": "BXO",
        "AirportName": "Buochs Airport",
        "City": "Buochs",
        "Country": "Switzerland"
    },
    {
        "id": "1066",
        "AirportCode": "BXR",
        "AirportName": "Bam Airport",
        "City": "Bam",
        "Country": "Iran"
    },
    {
        "id": "1067",
        "AirportCode": "BXS",
        "AirportName": "Borrego Springs Airport",
        "City": "Borrego Springs",
        "Country": "USA California"
    },
    {
        "id": "1068",
        "AirportCode": "BXT",
        "AirportName": "Bontang Airport",
        "City": "Bontang",
        "Country": "Indonesia"
    },
    {
        "id": "1069",
        "AirportCode": "BXU",
        "AirportName": "Butuan Airport",
        "City": "Butuan",
        "Country": "Philippines"
    },
    {
        "id": "1070",
        "AirportCode": "BXV",
        "AirportName": "Breiddalsvik Airport",
        "City": "Breiddalsvik",
        "Country": "Iceland"
    },
    {
        "id": "1071",
        "AirportCode": "BXX",
        "AirportName": "Borama Airport",
        "City": "Borama",
        "Country": "Somalia"
    },
    {
        "id": "1072",
        "AirportCode": "BXZ",
        "AirportName": "Bunsil Airport",
        "City": "Bunsil",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1073",
        "AirportCode": "BYA",
        "AirportName": "Boundary Airport",
        "City": "Boundary",
        "Country": "USA Alaska"
    },
    {
        "id": "1074",
        "AirportCode": "BYC",
        "AirportName": "Yacuiba Airport",
        "City": "Yacuiba",
        "Country": "Bolivia"
    },
    {
        "id": "1075",
        "AirportCode": "BYD",
        "AirportName": "Beidah Airport",
        "City": "Beidah",
        "Country": "Yemen"
    },
    {
        "id": "1076",
        "AirportCode": "BYG",
        "AirportName": "Buffalo Municipal Airport",
        "City": "Buffalo",
        "Country": "USA Wyoming"
    },
    {
        "id": "1077",
        "AirportCode": "BYH",
        "AirportName": "Blytheville Air Force Base",
        "City": "Blytheville",
        "Country": "USA Arkansas"
    },
    {
        "id": "1078",
        "AirportCode": "BYI",
        "AirportName": "Rupert Airport",
        "City": "Rupert",
        "Country": "USA Idaho"
    },
    {
        "id": "1079",
        "AirportCode": "BYL",
        "AirportName": "Bella Yella Airport",
        "City": "Bella Yella",
        "Country": "Liberia"
    },
    {
        "id": "1080",
        "AirportCode": "BYM",
        "AirportName": "C.M. de Cespedes Airport",
        "City": "Bayamo",
        "Country": "Cuba"
    },
    {
        "id": "1081",
        "AirportCode": "BYN",
        "AirportName": "Bayankhongor Airport",
        "City": "Bayankhongor",
        "Country": "Mongolia"
    },
    {
        "id": "1082",
        "AirportCode": "BYO",
        "AirportName": "Bonito Airport",
        "City": "Bonito",
        "Country": "Brazil"
    },
    {
        "id": "1083",
        "AirportCode": "BYQ",
        "AirportName": "Bunyu Airport",
        "City": "Bunyu",
        "Country": "Indonesia"
    },
    {
        "id": "1084",
        "AirportCode": "BYR",
        "AirportName": "Laeso Airport",
        "City": "Laeso Island",
        "Country": "Denmark"
    },
    {
        "id": "1085",
        "AirportCode": "BYS",
        "AirportName": "Bicycle Lake Army Air Field",
        "City": "Fort Irwin",
        "Country": "USA California"
    },
    {
        "id": "1086",
        "AirportCode": "BYT",
        "AirportName": "Bantry Airport",
        "City": "Bantry",
        "Country": "Ireland"
    },
    {
        "id": "1087",
        "AirportCode": "BYU",
        "AirportName": "Bindlacher-Berg Airport",
        "City": "Bayreuth",
        "Country": "Germany"
    },
    {
        "id": "1088",
        "AirportCode": "BYW",
        "AirportName": "Blakely Island Airport",
        "City": "Blakely Island",
        "Country": "USA Washington"
    },
    {
        "id": "1089",
        "AirportCode": "BYX",
        "AirportName": "Baniyala Airport",
        "City": "Baniyala",
        "Country": "Australia"
    },
    {
        "id": "1090",
        "AirportCode": "BZA",
        "AirportName": "San Pedro Airport",
        "City": "Bonanza",
        "Country": "Nicaragua"
    },
    {
        "id": "1091",
        "AirportCode": "BZB",
        "AirportName": "Bazaruto Island Airport",
        "City": "Bazaruto Island",
        "Country": "Mozambique"
    },
    {
        "id": "1092",
        "AirportCode": "BZC",
        "AirportName": "Buzios Airport",
        "City": "Buzios",
        "Country": "Brazil"
    },
    {
        "id": "1093",
        "AirportCode": "BZD",
        "AirportName": "Balranald Airport",
        "City": "Balranald",
        "Country": "Australia"
    },
    {
        "id": "1094",
        "AirportCode": "BZE",
        "AirportName": "Philip S.W. Goldson International Airport",
        "City": "Belize City",
        "Country": "Belize"
    },
    {
        "id": "1095",
        "AirportCode": "BZG",
        "AirportName": "Bydgoszcz Airport",
        "City": "Bydgoszcz",
        "Country": "Poland"
    },
    {
        "id": "1096",
        "AirportCode": "BZH",
        "AirportName": "Bumi Hills Airport",
        "City": "Bumi Hills",
        "Country": "Zimbabwe"
    },
    {
        "id": "1097",
        "AirportCode": "BZI",
        "AirportName": "Balikesir Airport",
        "City": "Balikesir",
        "Country": "Turkey"
    },
    {
        "id": "1098",
        "AirportCode": "BZK",
        "AirportName": "Briansk Airport",
        "City": "Briansk",
        "Country": "Russia"
    },
    {
        "id": "1099",
        "AirportCode": "BZL",
        "AirportName": "Barisal Airport",
        "City": "Barisal",
        "Country": "Bangladesh"
    },
    {
        "id": "1100",
        "AirportCode": "BZM",
        "AirportName": "Woensdrecht Airport",
        "City": "Bergen Op Zoom",
        "Country": "Netherlands"
    },
    {
        "id": "1101",
        "AirportCode": "BZN",
        "AirportName": "Gallatin Field",
        "City": "Bozeman",
        "Country": "USA Montana"
    },
    {
        "id": "1102",
        "AirportCode": "BZO",
        "AirportName": "Bolzano Airport",
        "City": "Bolzano",
        "Country": "Italy"
    },
    {
        "id": "1103",
        "AirportCode": "BZP",
        "AirportName": "Bizant Airport",
        "City": "Bizant",
        "Country": "Australia"
    },
    {
        "id": "1104",
        "AirportCode": "BZR",
        "AirportName": "Beziers Vias Airport",
        "City": "Beziers",
        "Country": "France"
    },
    {
        "id": "1105",
        "AirportCode": "BZS",
        "AirportName": "Buzzards Point S Airport",
        "City": "Washington",
        "Country": "USA DC"
    },
    {
        "id": "1106",
        "AirportCode": "BZT",
        "AirportName": "Hinkles Ferry",
        "City": "Brazoria",
        "Country": "USA Texas"
    },
    {
        "id": "1107",
        "AirportCode": "BZU",
        "AirportName": "Buta Airport",
        "City": "Buta",
        "Country": "Congo, DR"
    },
    {
        "id": "1108",
        "AirportCode": "BZV",
        "AirportName": "Maya Maya Airport",
        "City": "Brazzaville",
        "Country": "Congo"
    },
    {
        "id": "1109",
        "AirportCode": "BZY",
        "AirportName": "Beltsy Airport",
        "City": "Beltsy",
        "Country": "Moldova"
    },
    {
        "id": "1110",
        "AirportCode": "BZZ",
        "AirportName": "RAF Station",
        "City": "Brize Norton",
        "Country": "United Kingdom"
    },
    {
        "id": "1111",
        "AirportCode": "CAA",
        "AirportName": "Catacamas Airport",
        "City": "Catacamas",
        "Country": "Honduras"
    },
    {
        "id": "1112",
        "AirportCode": "CAB",
        "AirportName": "Cabinda Airport",
        "City": "Cabinda",
        "Country": "Angola"
    },
    {
        "id": "1113",
        "AirportCode": "CAC",
        "AirportName": "Cascavel Airport",
        "City": "Cascavel",
        "Country": "Brazil"
    },
    {
        "id": "1114",
        "AirportCode": "CAD",
        "AirportName": "Cadillac Airport",
        "City": "Cadillac",
        "Country": "USA Michigan"
    },
    {
        "id": "1115",
        "AirportCode": "CAE",
        "AirportName": "Columbia Metropolitan Airport",
        "City": "Columbia",
        "Country": "USA South Carolina"
    },
    {
        "id": "1116",
        "AirportCode": "CAF",
        "AirportName": "Carauari Airport",
        "City": "Carauari",
        "Country": "Brazil"
    },
    {
        "id": "1117",
        "AirportCode": "CAG",
        "AirportName": "Elmas Airport",
        "City": "Cagliari",
        "Country": "Italy"
    },
    {
        "id": "1118",
        "AirportCode": "CAH",
        "AirportName": "Ca Mau Airport",
        "City": "Ca Mau",
        "Country": "Vietnam"
    },
    {
        "id": "1119",
        "AirportCode": "CAI",
        "AirportName": "Cairo International Airport",
        "City": "Cairo",
        "Country": "Egypt"
    },
    {
        "id": "1120",
        "AirportCode": "CAJ",
        "AirportName": "Canaima Airport",
        "City": "Canaima",
        "Country": "Venezuela"
    },
    {
        "id": "1121",
        "AirportCode": "CAK",
        "AirportName": "Akron/Canton Regional Airport",
        "City": "Akron/Canton",
        "Country": "USA Ohio"
    },
    {
        "id": "1122",
        "AirportCode": "CAL",
        "AirportName": "Machrihanish Airport",
        "City": "Campbeltown",
        "Country": "United Kingdom"
    },
    {
        "id": "1123",
        "AirportCode": "CAM",
        "AirportName": "Camiri Airport",
        "City": "Camiri",
        "Country": "Bolivia"
    },
    {
        "id": "1124",
        "AirportCode": "CAN",
        "AirportName": "Baiyun Airport",
        "City": "Guangzhou",
        "Country": "China"
    },
    {
        "id": "1125",
        "AirportCode": "CAO",
        "AirportName": "Clayton Airport",
        "City": "Clayton",
        "Country": "USA New Mexico"
    },
    {
        "id": "1126",
        "AirportCode": "CAP",
        "AirportName": "Cap Haitien Airport",
        "City": "Cap Haitien",
        "Country": "Haiti"
    },
    {
        "id": "1127",
        "AirportCode": "CAQ",
        "AirportName": "Caucasia Airport",
        "City": "Caucasia",
        "Country": "Colombia"
    },
    {
        "id": "1128",
        "AirportCode": "CAR",
        "AirportName": "Caribou Municipal Airport",
        "City": "Caribou",
        "Country": "USA Maine"
    },
    {
        "id": "1129",
        "AirportCode": "CAS",
        "AirportName": "Anfa Airport",
        "City": "Casablanca",
        "Country": "Morocco"
    },
    {
        "id": "1130",
        "AirportCode": "CAT",
        "AirportName": "Cat Island Airport",
        "City": "Cat Island",
        "Country": "Bahamas"
    },
    {
        "id": "1131",
        "AirportCode": "CAU",
        "AirportName": "Caruaru Airport",
        "City": "Caruaru",
        "Country": "Brazil"
    },
    {
        "id": "1132",
        "AirportCode": "CAW",
        "AirportName": "Bartolomeu Lisandro Airport",
        "City": "Campos",
        "Country": "Brazil"
    },
    {
        "id": "1133",
        "AirportCode": "CAX",
        "AirportName": "Carlisle Airport",
        "City": "Carlisle",
        "Country": "United Kingdom"
    },
    {
        "id": "1134",
        "AirportCode": "CAY",
        "AirportName": "Rochambeau Airport",
        "City": "Cayenne",
        "Country": "French Guiana"
    },
    {
        "id": "1135",
        "AirportCode": "CAZ",
        "AirportName": "Cobar Airport",
        "City": "Cobar",
        "Country": "Australia"
    },
    {
        "id": "1136",
        "AirportCode": "CBB",
        "AirportName": "J Wilsterman Airport",
        "City": "Cochabamba",
        "Country": "Bolivia"
    },
    {
        "id": "1137",
        "AirportCode": "CBC",
        "AirportName": "Cherrabun Airport",
        "City": "Cherrabun",
        "Country": "Australia"
    },
    {
        "id": "1138",
        "AirportCode": "CBD",
        "AirportName": "Car Nicobar Airport",
        "City": "Car Nicobar",
        "Country": "India"
    },
    {
        "id": "1139",
        "AirportCode": "CBE",
        "AirportName": "Wiley Ford Airport",
        "City": "Cumberland",
        "Country": "USA Maryland"
    },
    {
        "id": "1140",
        "AirportCode": "CBF",
        "AirportName": "Council Bluffs Municipal Airport",
        "City": "Council Bluffs",
        "Country": "USA Iowa"
    },
    {
        "id": "1141",
        "AirportCode": "CBG",
        "AirportName": "Cambridge Airport",
        "City": "Cambridge",
        "Country": "United Kingdom"
    },
    {
        "id": "1142",
        "AirportCode": "CBH",
        "AirportName": "Leger Airport",
        "City": "Bechar",
        "Country": "Algeria"
    },
    {
        "id": "1143",
        "AirportCode": "CBI",
        "AirportName": "Cape Barren Island Airport",
        "City": "Cape Barren Island",
        "Country": "Australia"
    },
    {
        "id": "1144",
        "AirportCode": "CBJ",
        "AirportName": "Cabo Rojo Airport",
        "City": "Cabo Rojo",
        "Country": "Dominican Republic"
    },
    {
        "id": "1145",
        "AirportCode": "CBK",
        "AirportName": "Colby Municipal Airport",
        "City": "Colby",
        "Country": "USA Kansas"
    },
    {
        "id": "1146",
        "AirportCode": "CBL",
        "AirportName": "Ciudad Bolivar Airport",
        "City": "Ciudad Bolivar",
        "Country": "Venezuela"
    },
    {
        "id": "1147",
        "AirportCode": "CBM",
        "AirportName": "Columbus Air Force Base",
        "City": "Columbus",
        "Country": "USA Mississippi"
    },
    {
        "id": "1148",
        "AirportCode": "CBN",
        "AirportName": "Penggung Airport",
        "City": "Cirebon",
        "Country": "Indonesia"
    },
    {
        "id": "1149",
        "AirportCode": "CBO",
        "AirportName": "Awang Airport",
        "City": "Cotabato",
        "Country": "Philippines"
    },
    {
        "id": "1150",
        "AirportCode": "CBP",
        "AirportName": "Coimbra Airport",
        "City": "Coimbra",
        "Country": "Portugal"
    },
    {
        "id": "1151",
        "AirportCode": "CBQ",
        "AirportName": "Calabar Airport",
        "City": "Calabar",
        "Country": "Nigeria"
    },
    {
        "id": "1152",
        "AirportCode": "CBR",
        "AirportName": "Canberra Airport",
        "City": "Canberra",
        "Country": "Australia"
    },
    {
        "id": "1153",
        "AirportCode": "CBS",
        "AirportName": "Oro Negro Airport",
        "City": "Cabimas",
        "Country": "Venezuela"
    },
    {
        "id": "1154",
        "AirportCode": "CBT",
        "AirportName": "Catumbela Airport",
        "City": "Catumbela",
        "Country": "Angola"
    },
    {
        "id": "1155",
        "AirportCode": "CBU",
        "AirportName": "Cottbus Airport",
        "City": "Cottbus",
        "Country": "Germany"
    },
    {
        "id": "1156",
        "AirportCode": "CBV",
        "AirportName": "Coban Airport",
        "City": "Coban",
        "Country": "Guatemala"
    },
    {
        "id": "1157",
        "AirportCode": "CBW",
        "AirportName": "Campo Mourao Airport",
        "City": "Campo Mourao",
        "Country": "Brazil"
    },
    {
        "id": "1158",
        "AirportCode": "CBX",
        "AirportName": "Condobolin Airport",
        "City": "Condobolin",
        "Country": "Australia"
    },
    {
        "id": "1159",
        "AirportCode": "CBY",
        "AirportName": "Canobie Airport",
        "City": "Canobie",
        "Country": "Australia"
    },
    {
        "id": "1160",
        "AirportCode": "CCA",
        "AirportName": "Chaffee Air Force Base",
        "City": "Fort Chaffee",
        "Country": "USA Arkansas"
    },
    {
        "id": "1161",
        "AirportCode": "CCB",
        "AirportName": "Cable Airport",
        "City": "Upland",
        "Country": "USA California"
    },
    {
        "id": "1162",
        "AirportCode": "CCC",
        "AirportName": "Cayo Coco Airport",
        "City": "Cayo Coco",
        "Country": "Cuba"
    },
    {
        "id": "1163",
        "AirportCode": "CCD",
        "AirportName": "Century City Airport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "1164",
        "AirportCode": "CCE",
        "AirportName": "Grand Case Airport",
        "City": "Saint Martin",
        "Country": "Guadeloupe"
    },
    {
        "id": "1165",
        "AirportCode": "CCF",
        "AirportName": "Salvaza Airport",
        "City": "Carcassonne",
        "Country": "France"
    },
    {
        "id": "1166",
        "AirportCode": "CCG",
        "AirportName": "Crane County Airport",
        "City": "Crane",
        "Country": "USA Texas"
    },
    {
        "id": "1167",
        "AirportCode": "CCH",
        "AirportName": "Chile Chico Airport",
        "City": "Chile Chico",
        "Country": "Chile"
    },
    {
        "id": "1168",
        "AirportCode": "CCI",
        "AirportName": "Concordia Airport",
        "City": "Concordia",
        "Country": "Brazil"
    },
    {
        "id": "1169",
        "AirportCode": "CCJ",
        "AirportName": "Calicut Airport",
        "City": "Kozhikode",
        "Country": "India"
    },
    {
        "id": "1170",
        "AirportCode": "CCK",
        "AirportName": "Cocos Islands Airport",
        "City": "Cocos Islands",
        "Country": "Cocos & Keeling Islands"
    },
    {
        "id": "1171",
        "AirportCode": "CCL",
        "AirportName": "Chinchilla Airport",
        "City": "Chinchilla",
        "Country": "Australia"
    },
    {
        "id": "1172",
        "AirportCode": "CCM",
        "AirportName": "Criciuma Airport",
        "City": "Criciuma",
        "Country": "Brazil"
    },
    {
        "id": "1173",
        "AirportCode": "CCN",
        "AirportName": "Chakcharan Airport",
        "City": "Chakcharan",
        "Country": "Afghanistan"
    },
    {
        "id": "1174",
        "AirportCode": "CCO",
        "AirportName": "Carimagua Airport",
        "City": "Carimagua",
        "Country": "Colombia"
    },
    {
        "id": "1175",
        "AirportCode": "CCP",
        "AirportName": "Carriel Sur Airport",
        "City": "Concepcion",
        "Country": "Chile"
    },
    {
        "id": "1176",
        "AirportCode": "CCQ",
        "AirportName": "Cachoeira Airport",
        "City": "Cachoeira",
        "Country": "Brazil"
    },
    {
        "id": "1177",
        "AirportCode": "CCR",
        "AirportName": "Buchanan Field",
        "City": "Concord",
        "Country": "USA California"
    },
    {
        "id": "1178",
        "AirportCode": "CCS",
        "AirportName": "Simon Bolivar Airport",
        "City": "Caracas (CCS)",
        "Country": "Venezuela"
    },
    {
        "id": "1179",
        "AirportCode": "CCT",
        "AirportName": "Colonia Catriel Airport",
        "City": "Colonia Catriel",
        "Country": "Argentina"
    },
    {
        "id": "1180",
        "AirportCode": "CCU",
        "AirportName": "Netaji Subhas Chandra Airport",
        "City": "Kolkata",
        "Country": "India"
    },
    {
        "id": "1181",
        "AirportCode": "CCV",
        "AirportName": "Craig Cove Airport",
        "City": "Craig Cove",
        "Country": "Vanuatu"
    },
    {
        "id": "1182",
        "AirportCode": "CCW",
        "AirportName": "Cowell Airport",
        "City": "Cowell",
        "Country": "Australia"
    },
    {
        "id": "1183",
        "AirportCode": "CCX",
        "AirportName": "Caceres Airport",
        "City": "Caceres",
        "Country": "Brazil"
    },
    {
        "id": "1184",
        "AirportCode": "CCY",
        "AirportName": "Charles City Municipal Airport",
        "City": "Charles City",
        "Country": "USA Iowa"
    },
    {
        "id": "1185",
        "AirportCode": "CCZ",
        "AirportName": "Chub Cay Airport",
        "City": "Chub Cay",
        "Country": "Bahamas"
    },
    {
        "id": "1186",
        "AirportCode": "CDA",
        "AirportName": "Cooinda Airport",
        "City": "Cooinda",
        "Country": "Australia"
    },
    {
        "id": "1187",
        "AirportCode": "CDB",
        "AirportName": "Cold Bay Airport",
        "City": "Cold Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "1188",
        "AirportCode": "CDC",
        "AirportName": "Cedar City Airport",
        "City": "Cedar City",
        "Country": "USA Utah"
    },
    {
        "id": "1189",
        "AirportCode": "CDD",
        "AirportName": "Cauquira Airport",
        "City": "Cauquira",
        "Country": "Honduras"
    },
    {
        "id": "1190",
        "AirportCode": "CDE",
        "AirportName": "Caledonia Airport",
        "City": "Caledonia",
        "Country": "Panama"
    },
    {
        "id": "1191",
        "AirportCode": "CDG",
        "AirportName": "Charles De Gaulle Airport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "1192",
        "AirportCode": "CDH",
        "AirportName": "Harrell Field",
        "City": "Camden",
        "Country": "USA Arkansas"
    },
    {
        "id": "1193",
        "AirportCode": "CDI",
        "AirportName": "Cachoeiro de Itapemirim Airport",
        "City": "Cachoeiro de Itapemirim",
        "Country": "Brazil"
    },
    {
        "id": "1194",
        "AirportCode": "CDJ",
        "AirportName": "Conceicao Do Araguaia Airport",
        "City": "Conceicao Do Araguaia",
        "Country": "Brazil"
    },
    {
        "id": "1195",
        "AirportCode": "CDK",
        "AirportName": "Lewis Airport",
        "City": "Cedar Key",
        "Country": "USA Florida"
    },
    {
        "id": "1196",
        "AirportCode": "CDL",
        "AirportName": "Candle Airport",
        "City": "Candle",
        "Country": "USA Alaska"
    },
    {
        "id": "1197",
        "AirportCode": "CDN",
        "AirportName": "Woodward Field",
        "City": "Camden",
        "Country": "USA South Carolina"
    },
    {
        "id": "1198",
        "AirportCode": "CDO",
        "AirportName": "Cradock Airport",
        "City": "Cradock",
        "Country": "South Africa"
    },
    {
        "id": "1199",
        "AirportCode": "CDP",
        "AirportName": "Cuddapah Airport",
        "City": "Cuddapah",
        "Country": "India"
    },
    {
        "id": "1200",
        "AirportCode": "CDQ",
        "AirportName": "Croydon Airport",
        "City": "Croydon",
        "Country": "Australia"
    },
    {
        "id": "1201",
        "AirportCode": "CDR",
        "AirportName": "Maliron Airport",
        "City": "Maliron",
        "Country": "USA Nebraska"
    },
    {
        "id": "1202",
        "AirportCode": "CDS",
        "AirportName": "Childress Airport",
        "City": "Childress",
        "Country": "USA Texas"
    },
    {
        "id": "1203",
        "AirportCode": "CDU",
        "AirportName": "Camden Airport",
        "City": "Camden",
        "Country": "Australia"
    },
    {
        "id": "1204",
        "AirportCode": "CDV",
        "AirportName": "Mudhole Smith Airport",
        "City": "Cordova",
        "Country": "USA Alaska"
    },
    {
        "id": "1205",
        "AirportCode": "CDW",
        "AirportName": "Caldwell Wright Airport",
        "City": "Caldwell",
        "Country": "USA New Jersey"
    },
    {
        "id": "1206",
        "AirportCode": "CDY",
        "AirportName": "Cagayan De Sulu Airport",
        "City": "Cagayan De Sulu",
        "Country": "Philippines"
    },
    {
        "id": "1207",
        "AirportCode": "CDZ",
        "AirportName": "Cadiz Airport",
        "City": "Cadiz",
        "Country": "Spain"
    },
    {
        "id": "1208",
        "AirportCode": "CEA",
        "AirportName": "Cessna Aircraft Field",
        "City": "Wichita",
        "Country": "USA Kansas"
    },
    {
        "id": "1209",
        "AirportCode": "CEB",
        "AirportName": "Mactan International Airport",
        "City": "Cebu",
        "Country": "Philippines"
    },
    {
        "id": "1210",
        "AirportCode": "CEC",
        "AirportName": "Mc Namara Field",
        "City": "Crescent City",
        "Country": "USA California"
    },
    {
        "id": "1211",
        "AirportCode": "CED",
        "AirportName": "Ceduna Airport",
        "City": "Ceduna",
        "Country": "Australia"
    },
    {
        "id": "1212",
        "AirportCode": "CEE",
        "AirportName": "Cherepovets Airport",
        "City": "Cherepovets",
        "Country": "Russia"
    },
    {
        "id": "1213",
        "AirportCode": "CEF",
        "AirportName": "Westover Metro Airport",
        "City": "Springfield",
        "Country": "USA Massachusetts"
    },
    {
        "id": "1214",
        "AirportCode": "CEG",
        "AirportName": "Chester Airport",
        "City": "Chester",
        "Country": "United Kingdom"
    },
    {
        "id": "1215",
        "AirportCode": "CEH",
        "AirportName": "Chelinda Airport",
        "City": "Chelinda",
        "Country": "Malawi"
    },
    {
        "id": "1216",
        "AirportCode": "CEI",
        "AirportName": "Chiang Rai Airport",
        "City": "Chiang Rai",
        "Country": "Thailand"
    },
    {
        "id": "1217",
        "AirportCode": "CEJ",
        "AirportName": "Chernigov Airport",
        "City": "Chernigov",
        "Country": "Ukraine"
    },
    {
        "id": "1218",
        "AirportCode": "CEK",
        "AirportName": "Chelyabinsk Airport",
        "City": "Chelyabinsk",
        "Country": "Russia"
    },
    {
        "id": "1219",
        "AirportCode": "CEM",
        "AirportName": "Central Airport",
        "City": "Central",
        "Country": "USA Alaska"
    },
    {
        "id": "1220",
        "AirportCode": "CEN",
        "AirportName": "Ciudad Obregon Airport",
        "City": "Ciudad Obregon",
        "Country": "Mexico"
    },
    {
        "id": "1221",
        "AirportCode": "CEP",
        "AirportName": "Concepcion Airport",
        "City": "Concepcion",
        "Country": "Bolivia"
    },
    {
        "id": "1222",
        "AirportCode": "CEQ",
        "AirportName": "Mandelieu Airport",
        "City": "Cannes",
        "Country": "France"
    },
    {
        "id": "1223",
        "AirportCode": "CER",
        "AirportName": "Maupertus Airport",
        "City": "Cherbourg",
        "Country": "France"
    },
    {
        "id": "1224",
        "AirportCode": "CES",
        "AirportName": "Cessnock Airport",
        "City": "Cessnock",
        "Country": "Australia"
    },
    {
        "id": "1225",
        "AirportCode": "CET",
        "AirportName": "Le Pontreau Airport",
        "City": "Cholet",
        "Country": "France"
    },
    {
        "id": "1226",
        "AirportCode": "CEU",
        "AirportName": "Oconee County Airport",
        "City": "Clemson",
        "Country": "USA South Carolina"
    },
    {
        "id": "1227",
        "AirportCode": "CEV",
        "AirportName": "Mettle Field",
        "City": "Connersville",
        "Country": "USA Indiana"
    },
    {
        "id": "1228",
        "AirportCode": "CEW",
        "AirportName": "Bob Sikes Airport",
        "City": "Crestview",
        "Country": "USA Florida"
    },
    {
        "id": "1229",
        "AirportCode": "CEY",
        "AirportName": "Calloway County Airport",
        "City": "Murray",
        "Country": "USA Kentucky"
    },
    {
        "id": "1230",
        "AirportCode": "CEZ",
        "AirportName": "Montezuma County Airport",
        "City": "Cortez",
        "Country": "USA Colorado"
    },
    {
        "id": "1231",
        "AirportCode": "CFA",
        "AirportName": "Coffee Point Airport",
        "City": "Coffee Point",
        "Country": "USA Alaska"
    },
    {
        "id": "1232",
        "AirportCode": "CFB",
        "AirportName": "Cabo Frio Airport",
        "City": "Cabo Frio",
        "Country": "Brazil"
    },
    {
        "id": "1233",
        "AirportCode": "CFC",
        "AirportName": "Cacador Airport",
        "City": "Cacador",
        "Country": "Brazil"
    },
    {
        "id": "1234",
        "AirportCode": "CFD",
        "AirportName": "Coulter Field",
        "City": "Bryan",
        "Country": "USA Texas"
    },
    {
        "id": "1235",
        "AirportCode": "CFE",
        "AirportName": "Aulnat Airport",
        "City": "Clermont-Ferrand",
        "Country": "France"
    },
    {
        "id": "1236",
        "AirportCode": "CFG",
        "AirportName": "Cienfuegos Airport",
        "City": "Cienfuegos",
        "Country": "Cuba"
    },
    {
        "id": "1237",
        "AirportCode": "CFH",
        "AirportName": "Clifton Hills Airport",
        "City": "Clifton Hills",
        "Country": "Australia"
    },
    {
        "id": "1238",
        "AirportCode": "CFI",
        "AirportName": "Camfield Airport",
        "City": "Camfield",
        "Country": "Australia"
    },
    {
        "id": "1239",
        "AirportCode": "CFK",
        "AirportName": "Chlef Airport",
        "City": "Chlef",
        "Country": "Algeria"
    },
    {
        "id": "1240",
        "AirportCode": "CFN",
        "AirportName": "Donegal Airport",
        "City": "Donegal",
        "Country": "Ireland"
    },
    {
        "id": "1241",
        "AirportCode": "CFO",
        "AirportName": "Confreza Airport",
        "City": "Confreza",
        "Country": "Brazil"
    },
    {
        "id": "1242",
        "AirportCode": "CFP",
        "AirportName": "Carpentaria Downs Airport",
        "City": "Carpentaria Downs",
        "Country": "Australia"
    },
    {
        "id": "1243",
        "AirportCode": "CFQ",
        "AirportName": "Creston Airport",
        "City": "Creston",
        "Country": "Canada"
    },
    {
        "id": "1244",
        "AirportCode": "CFR",
        "AirportName": "Carpiquet Airport",
        "City": "Caen",
        "Country": "France"
    },
    {
        "id": "1245",
        "AirportCode": "CFS",
        "AirportName": "Coffs Harbour Airport",
        "City": "Coffs Harbour",
        "Country": "Australia"
    },
    {
        "id": "1246",
        "AirportCode": "CFT",
        "AirportName": "Morenci Airport",
        "City": "Clifton",
        "Country": "USA Arizona"
    },
    {
        "id": "1247",
        "AirportCode": "CFU",
        "AirportName": "I. Kapodistrias Airport",
        "City": "Kerkyra",
        "Country": "Greece"
    },
    {
        "id": "1248",
        "AirportCode": "CFV",
        "AirportName": "Coffeyville Municipal Airport",
        "City": "Coffeyville",
        "Country": "USA Kansas"
    },
    {
        "id": "1249",
        "AirportCode": "CGA",
        "AirportName": "Craig Sea Plane Base",
        "City": "Craig",
        "Country": "USA Alaska"
    },
    {
        "id": "1250",
        "AirportCode": "CGB",
        "AirportName": "M. Rondon Airport",
        "City": "Cuiaba",
        "Country": "Brazil"
    },
    {
        "id": "1251",
        "AirportCode": "CGC",
        "AirportName": "Cape Gloucester Airport",
        "City": "Cape Gloucester",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1252",
        "AirportCode": "CGD",
        "AirportName": "Changde Airport",
        "City": "Changde",
        "Country": "China"
    },
    {
        "id": "1253",
        "AirportCode": "CGE",
        "AirportName": "Cambridge Airport",
        "City": "Cambridge",
        "Country": "USA Maryland"
    },
    {
        "id": "1254",
        "AirportCode": "CGF",
        "AirportName": "Cuyahoga County Airport",
        "City": "Cleveland",
        "Country": "USA Ohio"
    },
    {
        "id": "1255",
        "AirportCode": "CGG",
        "AirportName": "Casiguran Airport",
        "City": "Casiguran",
        "Country": "Philippines"
    },
    {
        "id": "1256",
        "AirportCode": "CGH",
        "AirportName": "Congonhas Airport",
        "City": "Sao Paulo",
        "Country": "Brazil"
    },
    {
        "id": "1257",
        "AirportCode": "CGI",
        "AirportName": "Cape Girardeau Airport",
        "City": "Cape Girardeau",
        "Country": "USA Missouri"
    },
    {
        "id": "1258",
        "AirportCode": "CGJ",
        "AirportName": "Chingola Airport",
        "City": "Chingola",
        "Country": "Zambia"
    },
    {
        "id": "1259",
        "AirportCode": "CGK",
        "AirportName": "Soekarno-Hatta International Airport",
        "City": "Jakarta",
        "Country": "Indonesia"
    },
    {
        "id": "1260",
        "AirportCode": "CGM",
        "AirportName": "Mambajao Airport",
        "City": "Camiguin",
        "Country": "Philippines"
    },
    {
        "id": "1261",
        "AirportCode": "CGN",
        "AirportName": "Koeln/Bonn Airport",
        "City": "Cologne",
        "Country": "Germany"
    },
    {
        "id": "1262",
        "AirportCode": "CGO",
        "AirportName": "Zhengzhou Airport",
        "City": "Zhengzhou",
        "Country": "China"
    },
    {
        "id": "1263",
        "AirportCode": "CGP",
        "AirportName": "Patenga Airport",
        "City": "Chittagong",
        "Country": "Bangladesh"
    },
    {
        "id": "1264",
        "AirportCode": "CGQ",
        "AirportName": "Changchun Airport",
        "City": "Changchun",
        "Country": "China"
    },
    {
        "id": "1265",
        "AirportCode": "CGR",
        "AirportName": "Campo Grande Internacional Airport",
        "City": "Campo Grande",
        "Country": "Brazil"
    },
    {
        "id": "1266",
        "AirportCode": "CGS",
        "AirportName": "College Park Airport",
        "City": "College Park",
        "Country": "USA Maryland"
    },
    {
        "id": "1267",
        "AirportCode": "CGT",
        "AirportName": "Chinguitti Airport",
        "City": "Chinguitti",
        "Country": "Mauritania"
    },
    {
        "id": "1268",
        "AirportCode": "CGU",
        "AirportName": "Ciudad Guayana Airport",
        "City": "Ciudad Guayana",
        "Country": "Venezuela"
    },
    {
        "id": "1269",
        "AirportCode": "CGV",
        "AirportName": "Caiguna Airport",
        "City": "Caiguna",
        "Country": "Australia"
    },
    {
        "id": "1270",
        "AirportCode": "CGX",
        "AirportName": "Merrill C Meigs Field",
        "City": "Chicago",
        "Country": "USA Illinois"
    },
    {
        "id": "1271",
        "AirportCode": "CGY",
        "AirportName": "Lumbia Airport",
        "City": "Cagayan De Oro",
        "Country": "Philippines"
    },
    {
        "id": "1272",
        "AirportCode": "CGZ",
        "AirportName": "Casa Grande Municipal Airport",
        "City": "Casa Grande",
        "Country": "USA Arizona"
    },
    {
        "id": "1273",
        "AirportCode": "CHA",
        "AirportName": "Lovell Field",
        "City": "Chattanooga",
        "Country": "USA Tennessee"
    },
    {
        "id": "1274",
        "AirportCode": "CHB",
        "AirportName": "Chilas Airport",
        "City": "Chilas",
        "Country": "Pakistan"
    },
    {
        "id": "1275",
        "AirportCode": "CHC",
        "AirportName": "Christchurch International Airport",
        "City": "Christchurch",
        "Country": "New Zealand"
    },
    {
        "id": "1276",
        "AirportCode": "CHE",
        "AirportName": "Reenroe Airport",
        "City": "Caherciveen",
        "Country": "Ireland"
    },
    {
        "id": "1277",
        "AirportCode": "CHF",
        "AirportName": "Jinhae Airport",
        "City": "Jinhae",
        "Country": "Korea"
    },
    {
        "id": "1278",
        "AirportCode": "CHG",
        "AirportName": "Chaoyang Airport",
        "City": "Chaoyang",
        "Country": "China"
    },
    {
        "id": "1279",
        "AirportCode": "CHH",
        "AirportName": "Chachapoyas Airport",
        "City": "Chachapoyas",
        "Country": "Peru"
    },
    {
        "id": "1280",
        "AirportCode": "CHJ",
        "AirportName": "Chipinge Airport",
        "City": "Chipinge",
        "Country": "Zimbabwe"
    },
    {
        "id": "1281",
        "AirportCode": "CHK",
        "AirportName": "Chickasha Municipal Airport",
        "City": "Chickasha",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1282",
        "AirportCode": "CHL",
        "AirportName": "Challis Airport",
        "City": "Challis",
        "Country": "USA Idaho"
    },
    {
        "id": "1283",
        "AirportCode": "CHM",
        "AirportName": "Chimbote Airport",
        "City": "Chimbote",
        "Country": "Peru"
    },
    {
        "id": "1284",
        "AirportCode": "CHN",
        "AirportName": "Jeonju Airport",
        "City": "Jeonju",
        "Country": "Korea"
    },
    {
        "id": "1285",
        "AirportCode": "CHO",
        "AirportName": "Albemarle Airport",
        "City": "Charlottesville",
        "Country": "USA Virginia"
    },
    {
        "id": "1286",
        "AirportCode": "CHP",
        "AirportName": "Circle Hot Springs Airport",
        "City": "Circle Hot Springs",
        "Country": "USA Alaska"
    },
    {
        "id": "1287",
        "AirportCode": "CHQ",
        "AirportName": "Souda Airport",
        "City": "Chania",
        "Country": "Greece"
    },
    {
        "id": "1288",
        "AirportCode": "CHR",
        "AirportName": "Chateauroux Airport",
        "City": "Chateauroux",
        "Country": "France"
    },
    {
        "id": "1289",
        "AirportCode": "CHS",
        "AirportName": "Charleston International Airport",
        "City": "Charleston",
        "Country": "USA South Carolina"
    },
    {
        "id": "1290",
        "AirportCode": "CHT",
        "AirportName": "Karewa Airport",
        "City": "Chatham Island",
        "Country": "New Zealand"
    },
    {
        "id": "1291",
        "AirportCode": "CHU",
        "AirportName": "Chuathbaluk Airport",
        "City": "Chuathbaluk",
        "Country": "USA Alaska"
    },
    {
        "id": "1292",
        "AirportCode": "CHV",
        "AirportName": "Chaves Airport",
        "City": "Chaves",
        "Country": "Portugal"
    },
    {
        "id": "1293",
        "AirportCode": "CHW",
        "AirportName": "Jiuquan Airport",
        "City": "Jiuquan",
        "Country": "China"
    },
    {
        "id": "1294",
        "AirportCode": "CHX",
        "AirportName": "Changuinola Airport",
        "City": "Changuinola",
        "Country": "Panama"
    },
    {
        "id": "1295",
        "AirportCode": "CHY",
        "AirportName": "Choiseul Bay Airport",
        "City": "Choiseul Bay",
        "Country": "Solomon Islands"
    },
    {
        "id": "1296",
        "AirportCode": "CHZ",
        "AirportName": "State Airport",
        "City": "Chiloquin",
        "Country": "USA Oregon"
    },
    {
        "id": "1297",
        "AirportCode": "CIA",
        "AirportName": "Ciampino Airport",
        "City": "Rome",
        "Country": "Italy"
    },
    {
        "id": "1298",
        "AirportCode": "CIB",
        "AirportName": "Ap In The Sky Airport",
        "City": "Catalina Island",
        "Country": "USA California"
    },
    {
        "id": "1299",
        "AirportCode": "CIC",
        "AirportName": "Chico Airport",
        "City": "Chico",
        "Country": "USA California"
    },
    {
        "id": "1300",
        "AirportCode": "CID",
        "AirportName": "Cedar Rapids Airport",
        "City": "Cedar Rapids",
        "Country": "USA Iowa"
    },
    {
        "id": "1301",
        "AirportCode": "CIE",
        "AirportName": "Collie Airport",
        "City": "Collie",
        "Country": "Australia"
    },
    {
        "id": "1302",
        "AirportCode": "CIF",
        "AirportName": "Chifeng Airport",
        "City": "Chifeng",
        "Country": "China"
    },
    {
        "id": "1303",
        "AirportCode": "CIG",
        "AirportName": "Craig-Moffat Airport",
        "City": "Craig",
        "Country": "USA Colorado"
    },
    {
        "id": "1304",
        "AirportCode": "CIH",
        "AirportName": "Changzhi Airport",
        "City": "Changzhi",
        "Country": "China"
    },
    {
        "id": "1305",
        "AirportCode": "CIJ",
        "AirportName": "E. Beltram Airport",
        "City": "Cobija",
        "Country": "Bolivia"
    },
    {
        "id": "1306",
        "AirportCode": "CIK",
        "AirportName": "Chalkyitsik Airport",
        "City": "Chalkyitsik",
        "Country": "USA Alaska"
    },
    {
        "id": "1307",
        "AirportCode": "CIM",
        "AirportName": "Cimitarra Airport",
        "City": "Cimitarra",
        "Country": "Colombia"
    },
    {
        "id": "1308",
        "AirportCode": "CIN",
        "AirportName": "Carroll Airport",
        "City": "Carroll",
        "Country": "USA Iowa"
    },
    {
        "id": "1309",
        "AirportCode": "CIO",
        "AirportName": "MCAL Lopez Airport",
        "City": "Concepcion",
        "Country": "Paraguay"
    },
    {
        "id": "1310",
        "AirportCode": "CIP",
        "AirportName": "Chipata Airport",
        "City": "Chipata",
        "Country": "Zambia"
    },
    {
        "id": "1311",
        "AirportCode": "CIQ",
        "AirportName": "Chiquimula Airport",
        "City": "Chiquimula",
        "Country": "Guatemala"
    },
    {
        "id": "1312",
        "AirportCode": "CIR",
        "AirportName": "Cairo Airport",
        "City": "Cairo",
        "Country": "USA Illinois"
    },
    {
        "id": "1313",
        "AirportCode": "CIS",
        "AirportName": "Canton Island Airport",
        "City": "Canton Island",
        "Country": "Kiribati"
    },
    {
        "id": "1314",
        "AirportCode": "CIT",
        "AirportName": "Shimkent Airport",
        "City": "Shimkent",
        "Country": "Kazakhstan"
    },
    {
        "id": "1315",
        "AirportCode": "CIU",
        "AirportName": "Chippewa County Airport",
        "City": "Sault Ste. Marie",
        "Country": "USA Michigan"
    },
    {
        "id": "1316",
        "AirportCode": "CIW",
        "AirportName": "Canouan Island Airport",
        "City": "Canouan Island",
        "Country": "St Vincent"
    },
    {
        "id": "1317",
        "AirportCode": "CIX",
        "AirportName": "Cornel Ruiz Airport",
        "City": "Chiclayo",
        "Country": "Peru"
    },
    {
        "id": "1318",
        "AirportCode": "CIY",
        "AirportName": "Comiso Airport",
        "City": "Comiso",
        "Country": "Italy"
    },
    {
        "id": "1319",
        "AirportCode": "CIZ",
        "AirportName": "Coari Airport",
        "City": "Coari",
        "Country": "Brazil"
    },
    {
        "id": "1320",
        "AirportCode": "CJA",
        "AirportName": "Cajamarca Airport",
        "City": "Cajamarca",
        "Country": "Peru"
    },
    {
        "id": "1321",
        "AirportCode": "CJB",
        "AirportName": "Peelamedu Airport",
        "City": "Coimbatore",
        "Country": "India"
    },
    {
        "id": "1322",
        "AirportCode": "CJC",
        "AirportName": "El Loa Airport",
        "City": "Calama",
        "Country": "Chile"
    },
    {
        "id": "1323",
        "AirportCode": "CJD",
        "AirportName": "Candilejas Airport",
        "City": "Candilejas",
        "Country": "Colombia"
    },
    {
        "id": "1324",
        "AirportCode": "CJH",
        "AirportName": "Chilko Lake Airport",
        "City": "Chilko Lake",
        "Country": "Canada"
    },
    {
        "id": "1325",
        "AirportCode": "CJI",
        "AirportName": "Ciudad Real Airport",
        "City": "Ciudad Real",
        "Country": "Spain"
    },
    {
        "id": "1326",
        "AirportCode": "CJJ",
        "AirportName": "Cheongju Airport",
        "City": "Cheongju",
        "Country": "Korea"
    },
    {
        "id": "1327",
        "AirportCode": "CJL",
        "AirportName": "Chitral Airport",
        "City": "Chitral",
        "Country": "Pakistan"
    },
    {
        "id": "1328",
        "AirportCode": "CJM",
        "AirportName": "Chumphon Airport",
        "City": "Chumphon",
        "Country": "Thailand"
    },
    {
        "id": "1329",
        "AirportCode": "CJN",
        "AirportName": "El Cajon Airport",
        "City": "El Cajon",
        "Country": "USA California"
    },
    {
        "id": "1330",
        "AirportCode": "CJS",
        "AirportName": "Abraham Gonzalez International Airport",
        "City": "Ciudad Juarez",
        "Country": "Mexico"
    },
    {
        "id": "1331",
        "AirportCode": "CJT",
        "AirportName": "Copalar Airport",
        "City": "Comitan",
        "Country": "Mexico"
    },
    {
        "id": "1332",
        "AirportCode": "CJU",
        "AirportName": "Jeju Airport",
        "City": "Jeju",
        "Country": "Korea"
    },
    {
        "id": "1333",
        "AirportCode": "CKA",
        "AirportName": "Kegelman Air Field",
        "City": "Cherokee",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1334",
        "AirportCode": "CKB",
        "AirportName": "Benedum Airport",
        "City": "Clarksburg",
        "Country": "USA West Virginia"
    },
    {
        "id": "1335",
        "AirportCode": "CKC",
        "AirportName": "Cherkassy Airport",
        "City": "Cherkassy",
        "Country": "Ukraine"
    },
    {
        "id": "1336",
        "AirportCode": "CKD",
        "AirportName": "Crooked Creek Airport",
        "City": "Crooked Creek",
        "Country": "USA Alaska"
    },
    {
        "id": "1337",
        "AirportCode": "CKE",
        "AirportName": "Clear Lake Airport",
        "City": "Clear Lake",
        "Country": "USA California"
    },
    {
        "id": "1338",
        "AirportCode": "CKG",
        "AirportName": "Chongqing Airport",
        "City": "Chongqing",
        "Country": "China"
    },
    {
        "id": "1339",
        "AirportCode": "CKH",
        "AirportName": "Chokurdah Airport",
        "City": "Chokurdah",
        "Country": "Russia"
    },
    {
        "id": "1340",
        "AirportCode": "CKI",
        "AirportName": "Croker Island Airport",
        "City": "Croker Island",
        "Country": "Australia"
    },
    {
        "id": "1341",
        "AirportCode": "CKK",
        "AirportName": "Cherokee Village Airport",
        "City": "Cherokee Village",
        "Country": "USA Arkansas"
    },
    {
        "id": "1342",
        "AirportCode": "CKL",
        "AirportName": "Chkalovsky Airport",
        "City": "Chkalovsky",
        "Country": "Russia"
    },
    {
        "id": "1343",
        "AirportCode": "CKM",
        "AirportName": "Fletcher Field",
        "City": "Clarksdale",
        "Country": "USA Mississippi"
    },
    {
        "id": "1344",
        "AirportCode": "CKN",
        "AirportName": "Crookston Municipal Airport",
        "City": "Crookston",
        "Country": "USA Minnesota"
    },
    {
        "id": "1345",
        "AirportCode": "CKO",
        "AirportName": "Cornelio Procopio Airport",
        "City": "Cornelio Procopio",
        "Country": "Brazil"
    },
    {
        "id": "1346",
        "AirportCode": "CKR",
        "AirportName": "Crane Island Airport",
        "City": "Crane Island",
        "Country": "USA Washington"
    },
    {
        "id": "1347",
        "AirportCode": "CKS",
        "AirportName": "Carajas Airport",
        "City": "Carajas",
        "Country": "Brazil"
    },
    {
        "id": "1348",
        "AirportCode": "CKT",
        "AirportName": "Sarakhs Airport",
        "City": "Sarakhs",
        "Country": "Iran"
    },
    {
        "id": "1349",
        "AirportCode": "CKV",
        "AirportName": "Outlaw Field",
        "City": "Clarksville",
        "Country": "USA Tennessee"
    },
    {
        "id": "1350",
        "AirportCode": "CKX",
        "AirportName": "Chicken Airport",
        "City": "Chicken",
        "Country": "USA Alaska"
    },
    {
        "id": "1351",
        "AirportCode": "CKY",
        "AirportName": "Conakry Airport",
        "City": "Conakry",
        "Country": "Guinea"
    },
    {
        "id": "1352",
        "AirportCode": "CKZ",
        "AirportName": "Canakkale Airport",
        "City": "Canakkale",
        "Country": "Turkey"
    },
    {
        "id": "1353",
        "AirportCode": "CLA",
        "AirportName": "Comilla Airport",
        "City": "Comilla",
        "Country": "Bangladesh"
    },
    {
        "id": "1354",
        "AirportCode": "CLB",
        "AirportName": "Castlebar Airport",
        "City": "Castlebar",
        "Country": "Ireland"
    },
    {
        "id": "1355",
        "AirportCode": "CLC",
        "AirportName": "Metroport Airport",
        "City": "Clear Lake City",
        "Country": "USA Texas"
    },
    {
        "id": "1356",
        "AirportCode": "CLD",
        "AirportName": "Carlsbad Airport",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "1357",
        "AirportCode": "CLE",
        "AirportName": "Hopkins International Airport",
        "City": "Cleveland",
        "Country": "USA Ohio"
    },
    {
        "id": "1358",
        "AirportCode": "CLF",
        "AirportName": "Coltishall Ab",
        "City": "Coltishall",
        "Country": "United Kingdom"
    },
    {
        "id": "1359",
        "AirportCode": "CLG",
        "AirportName": "Coalinga Airport",
        "City": "Coalinga",
        "Country": "USA California"
    },
    {
        "id": "1360",
        "AirportCode": "CLH",
        "AirportName": "Coolah Airport",
        "City": "Coolah",
        "Country": "Australia"
    },
    {
        "id": "1361",
        "AirportCode": "CLI",
        "AirportName": "Clintonville Airport",
        "City": "Clintonville",
        "Country": "USA Wisconsin"
    },
    {
        "id": "1362",
        "AirportCode": "CLJ",
        "AirportName": "Napoca Airport",
        "City": "Cluj",
        "Country": "Romania"
    },
    {
        "id": "1363",
        "AirportCode": "CLK",
        "AirportName": "Clinton Municipal Airport",
        "City": "Clinton",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1364",
        "AirportCode": "CLL",
        "AirportName": "Easterwood Field",
        "City": "College Station",
        "Country": "USA Texas"
    },
    {
        "id": "1365",
        "AirportCode": "CLM",
        "AirportName": "Fairchild International Airport",
        "City": "Port Angeles",
        "Country": "USA Washington"
    },
    {
        "id": "1366",
        "AirportCode": "CLN",
        "AirportName": "Carolina Airport",
        "City": "Carolina",
        "Country": "Brazil"
    },
    {
        "id": "1367",
        "AirportCode": "CLO",
        "AirportName": "Alfonso B. Aragon Airport",
        "City": "Cali",
        "Country": "Colombia"
    },
    {
        "id": "1368",
        "AirportCode": "CLP",
        "AirportName": "Clarks Point Airport",
        "City": "Clarks Point",
        "Country": "USA Alaska"
    },
    {
        "id": "1369",
        "AirportCode": "CLQ",
        "AirportName": "Colima Airport",
        "City": "Colima",
        "Country": "Mexico"
    },
    {
        "id": "1370",
        "AirportCode": "CLR",
        "AirportName": "Calipatria Airport",
        "City": "Calipatria",
        "Country": "USA California"
    },
    {
        "id": "1371",
        "AirportCode": "CLS",
        "AirportName": "Centralia Airport",
        "City": "Chehalis",
        "Country": "USA Washington"
    },
    {
        "id": "1372",
        "AirportCode": "CLT",
        "AirportName": "Douglas Airport",
        "City": "Charlotte",
        "Country": "USA North Carolina"
    },
    {
        "id": "1373",
        "AirportCode": "CLU",
        "AirportName": "Columbus Municipal Airport",
        "City": "Columbus",
        "Country": "USA Indiana"
    },
    {
        "id": "1374",
        "AirportCode": "CLV",
        "AirportName": "Caldas Novas Airport",
        "City": "Caldas Novas",
        "Country": "Brazil"
    },
    {
        "id": "1375",
        "AirportCode": "CLW",
        "AirportName": "Executive Airport",
        "City": "Clearwater",
        "Country": "USA Florida"
    },
    {
        "id": "1376",
        "AirportCode": "CLX",
        "AirportName": "Clorinda Airport",
        "City": "Clorinda",
        "Country": "Argentina"
    },
    {
        "id": "1377",
        "AirportCode": "CLY",
        "AirportName": "Sainte Catherine Airport",
        "City": "Calvi",
        "Country": "France"
    },
    {
        "id": "1378",
        "AirportCode": "CLZ",
        "AirportName": "Calabozo Airport",
        "City": "Calabozo",
        "Country": "Venezuela"
    },
    {
        "id": "1379",
        "AirportCode": "CMA",
        "AirportName": "Cunnamulla Airport",
        "City": "Cunnamulla",
        "Country": "Australia"
    },
    {
        "id": "1380",
        "AirportCode": "CMB",
        "AirportName": "Bandaranayake International Airport",
        "City": "Colombo",
        "Country": "Sri Lanka"
    },
    {
        "id": "1381",
        "AirportCode": "CMC",
        "AirportName": "Camocim Airport",
        "City": "Camocim",
        "Country": "Brazil"
    },
    {
        "id": "1382",
        "AirportCode": "CMD",
        "AirportName": "Cootamundra Airport",
        "City": "Cootamundra",
        "Country": "Australia"
    },
    {
        "id": "1383",
        "AirportCode": "CME",
        "AirportName": "Ciudad Del Carmen Airport",
        "City": "Ciudad Del Carmen",
        "Country": "Mexico"
    },
    {
        "id": "1384",
        "AirportCode": "CMF",
        "AirportName": "Chambery Airport",
        "City": "Chambery",
        "Country": "France"
    },
    {
        "id": "1385",
        "AirportCode": "CMG",
        "AirportName": "Corumba Internacional Airport",
        "City": "Corumba",
        "Country": "Brazil"
    },
    {
        "id": "1386",
        "AirportCode": "CMH",
        "AirportName": "Port Columbus International Airport",
        "City": "Columbus",
        "Country": "USA Ohio"
    },
    {
        "id": "1387",
        "AirportCode": "CMI",
        "AirportName": "Willard University Airport",
        "City": "Champaign",
        "Country": "USA Illinois"
    },
    {
        "id": "1388",
        "AirportCode": "CMJ",
        "AirportName": "Chi Mei Airport",
        "City": "Chi Mei",
        "Country": "Taiwan"
    },
    {
        "id": "1389",
        "AirportCode": "CMK",
        "AirportName": "Club Makokola Airport",
        "City": "Club Makokola",
        "Country": "Malawi"
    },
    {
        "id": "1390",
        "AirportCode": "CML",
        "AirportName": "Camooweal Airport",
        "City": "Camooweal",
        "Country": "Australia"
    },
    {
        "id": "1391",
        "AirportCode": "CMM",
        "AirportName": "Carmelita Airport",
        "City": "Carmelita",
        "Country": "Guatemala"
    },
    {
        "id": "1392",
        "AirportCode": "CMN",
        "AirportName": "Mohamed V Airport",
        "City": "Casablanca",
        "Country": "Morocco"
    },
    {
        "id": "1393",
        "AirportCode": "CMO",
        "AirportName": "Obbia Airport",
        "City": "Obbia",
        "Country": "Somalia"
    },
    {
        "id": "1394",
        "AirportCode": "CMP",
        "AirportName": "Campo Alegre Airport",
        "City": "Santana Do Araguaia",
        "Country": "Brazil"
    },
    {
        "id": "1395",
        "AirportCode": "CMQ",
        "AirportName": "Clermont Airport",
        "City": "Clermont",
        "Country": "Australia"
    },
    {
        "id": "1396",
        "AirportCode": "CMR",
        "AirportName": "Colmar-Houssen Airport",
        "City": "Colmar",
        "Country": "France"
    },
    {
        "id": "1397",
        "AirportCode": "CMS",
        "AirportName": "Scusciuban Airport",
        "City": "Scusciuban",
        "Country": "Somalia"
    },
    {
        "id": "1398",
        "AirportCode": "CMT",
        "AirportName": "Cameta Airport",
        "City": "Cameta",
        "Country": "Brazil"
    },
    {
        "id": "1399",
        "AirportCode": "CMU",
        "AirportName": "Chimbu Airport",
        "City": "Kundiawa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1400",
        "AirportCode": "CMV",
        "AirportName": "Coromandel Airport",
        "City": "Coromandel",
        "Country": "New Zealand"
    },
    {
        "id": "1401",
        "AirportCode": "CMW",
        "AirportName": "Ign Agramonte International Airport",
        "City": "Camaguey",
        "Country": "Cuba"
    },
    {
        "id": "1402",
        "AirportCode": "CMX",
        "AirportName": "Houghton County Airport",
        "City": "Hancock",
        "Country": "USA Michigan"
    },
    {
        "id": "1403",
        "AirportCode": "CMY",
        "AirportName": "Camp McCoy Army Air Field",
        "City": "Sparta",
        "Country": "USA Wisconsin"
    },
    {
        "id": "1404",
        "AirportCode": "CMZ",
        "AirportName": "Caia Airport",
        "City": "Caia",
        "Country": "Mozambique"
    },
    {
        "id": "1405",
        "AirportCode": "CNA",
        "AirportName": "Cananea Airport",
        "City": "Cananea",
        "Country": "Mexico"
    },
    {
        "id": "1406",
        "AirportCode": "CNB",
        "AirportName": "Coonamble Airport",
        "City": "Coonamble",
        "Country": "Australia"
    },
    {
        "id": "1407",
        "AirportCode": "CNC",
        "AirportName": "Coconut Island Airport",
        "City": "Coconut Island",
        "Country": "Australia"
    },
    {
        "id": "1408",
        "AirportCode": "CND",
        "AirportName": "Kogalniceanu Airport",
        "City": "Constanta",
        "Country": "Romania"
    },
    {
        "id": "1409",
        "AirportCode": "CNE",
        "AirportName": "Canon City Airport",
        "City": "Canon City",
        "Country": "USA Colorado"
    },
    {
        "id": "1410",
        "AirportCode": "CNF",
        "AirportName": "Tancredo Neves International Airport",
        "City": "Belo Horizonte",
        "Country": "Brazil"
    },
    {
        "id": "1411",
        "AirportCode": "CNG",
        "AirportName": "Parvaud Airport",
        "City": "Cognac",
        "Country": "France"
    },
    {
        "id": "1412",
        "AirportCode": "CNH",
        "AirportName": "Claremont Municipal Airport",
        "City": "Claremont",
        "Country": "USA New Hampshire"
    },
    {
        "id": "1413",
        "AirportCode": "CNI",
        "AirportName": "Changhai Airport",
        "City": "Changhai",
        "Country": "China"
    },
    {
        "id": "1414",
        "AirportCode": "CNJ",
        "AirportName": "Cloncurry Airport",
        "City": "Cloncurry",
        "Country": "Australia"
    },
    {
        "id": "1415",
        "AirportCode": "CNK",
        "AirportName": "Blosser Municipal Airport",
        "City": "Concordia",
        "Country": "USA Kansas"
    },
    {
        "id": "1416",
        "AirportCode": "CNL",
        "AirportName": "Sindal Airport",
        "City": "Sindal",
        "Country": "Denmark"
    },
    {
        "id": "1417",
        "AirportCode": "CNM",
        "AirportName": "Carlsbad Airport",
        "City": "Carlsbad",
        "Country": "USA New Mexico"
    },
    {
        "id": "1418",
        "AirportCode": "CNN",
        "AirportName": "Chulman Airport",
        "City": "Chulman",
        "Country": "Russia"
    },
    {
        "id": "1419",
        "AirportCode": "CNO",
        "AirportName": "Chino Airport",
        "City": "Chino",
        "Country": "USA California"
    },
    {
        "id": "1420",
        "AirportCode": "CNP",
        "AirportName": "Neerlerit Inaat Airport",
        "City": "Neerlerit Inaat",
        "Country": "Greenland"
    },
    {
        "id": "1421",
        "AirportCode": "CNQ",
        "AirportName": "Camba Punta Airport",
        "City": "Corrientes",
        "Country": "Argentina"
    },
    {
        "id": "1422",
        "AirportCode": "CNR",
        "AirportName": "Chanaral Airport",
        "City": "Chanaral",
        "Country": "Chile"
    },
    {
        "id": "1423",
        "AirportCode": "CNS",
        "AirportName": "Cairns Airport",
        "City": "Cairns",
        "Country": "Australia"
    },
    {
        "id": "1424",
        "AirportCode": "CNT",
        "AirportName": "Charata Airport",
        "City": "Charata",
        "Country": "Argentina"
    },
    {
        "id": "1425",
        "AirportCode": "CNU",
        "AirportName": "Martin Johnson Airport",
        "City": "Chanute",
        "Country": "USA Kansas"
    },
    {
        "id": "1426",
        "AirportCode": "CNV",
        "AirportName": "Canavieiras Airport",
        "City": "Canavieiras",
        "Country": "Brazil"
    },
    {
        "id": "1427",
        "AirportCode": "CNW",
        "AirportName": "James Connall Airport",
        "City": "Waco",
        "Country": "USA Texas"
    },
    {
        "id": "1428",
        "AirportCode": "CNX",
        "AirportName": "Chiang Mai International Airport",
        "City": "Chiang Mai",
        "Country": "Thailand"
    },
    {
        "id": "1429",
        "AirportCode": "CNY",
        "AirportName": "Canyonlands Field",
        "City": "Moab",
        "Country": "USA Utah"
    },
    {
        "id": "1430",
        "AirportCode": "COA",
        "AirportName": "Columbia Airport",
        "City": "Columbia",
        "Country": "USA California"
    },
    {
        "id": "1431",
        "AirportCode": "COB",
        "AirportName": "Coolibah Airport",
        "City": "Coolibah",
        "Country": "Australia"
    },
    {
        "id": "1432",
        "AirportCode": "COC",
        "AirportName": "Concordia Airport",
        "City": "Concordia",
        "Country": "Argentina"
    },
    {
        "id": "1433",
        "AirportCode": "COD",
        "AirportName": "Yellowstone Regional Airport",
        "City": "Cody",
        "Country": "USA Wyoming"
    },
    {
        "id": "1434",
        "AirportCode": "COF",
        "AirportName": "Patrick Air Force Base",
        "City": "Cocoa",
        "Country": "USA Florida"
    },
    {
        "id": "1435",
        "AirportCode": "COG",
        "AirportName": "Mandinga Airport",
        "City": "Condoto",
        "Country": "Colombia"
    },
    {
        "id": "1436",
        "AirportCode": "COH",
        "AirportName": "Cooch Behar Airport",
        "City": "Cooch Behar",
        "Country": "India"
    },
    {
        "id": "1437",
        "AirportCode": "COI",
        "AirportName": "Merritt Island Airport",
        "City": "Cocoa",
        "Country": "USA Florida"
    },
    {
        "id": "1438",
        "AirportCode": "COJ",
        "AirportName": "Coonabarabran Airport",
        "City": "Coonabarabran",
        "Country": "Australia"
    },
    {
        "id": "1439",
        "AirportCode": "COK",
        "AirportName": "Kochi Airport",
        "City": "Kochi",
        "Country": "India"
    },
    {
        "id": "1440",
        "AirportCode": "COL",
        "AirportName": "Coll Island Airport",
        "City": "Coll Island",
        "Country": "United Kingdom"
    },
    {
        "id": "1441",
        "AirportCode": "COM",
        "AirportName": "Coleman Airport",
        "City": "Coleman",
        "Country": "USA Texas"
    },
    {
        "id": "1442",
        "AirportCode": "CON",
        "AirportName": "Concord Airport",
        "City": "Concord",
        "Country": "USA New Hampshire"
    },
    {
        "id": "1443",
        "AirportCode": "COO",
        "AirportName": "Cotonou Airport",
        "City": "Cotonou",
        "Country": "Benin"
    },
    {
        "id": "1444",
        "AirportCode": "COP",
        "AirportName": "Cooperstown Airport",
        "City": "Cooperstown",
        "Country": "USA New York"
    },
    {
        "id": "1445",
        "AirportCode": "COQ",
        "AirportName": "Choibalsan Airport",
        "City": "Choibalsan",
        "Country": "Mongolia"
    },
    {
        "id": "1446",
        "AirportCode": "COR",
        "AirportName": "Pajas Blancas Airport",
        "City": "Cordoba",
        "Country": "Argentina"
    },
    {
        "id": "1447",
        "AirportCode": "COS",
        "AirportName": "Colorado Springs Airport",
        "City": "Colorado Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "1448",
        "AirportCode": "COT",
        "AirportName": "Cotulla Airport",
        "City": "Cotulla",
        "Country": "USA Texas"
    },
    {
        "id": "1449",
        "AirportCode": "COU",
        "AirportName": "Columbia Regional Airport",
        "City": "Columbia",
        "Country": "USA Missouri"
    },
    {
        "id": "1450",
        "AirportCode": "COV",
        "AirportName": "Covilha Airport",
        "City": "Covilha",
        "Country": "Portugal"
    },
    {
        "id": "1451",
        "AirportCode": "COW",
        "AirportName": "Coquimbo Airport",
        "City": "Coquimbo",
        "Country": "Chile"
    },
    {
        "id": "1452",
        "AirportCode": "COX",
        "AirportName": "Congo Town Airport",
        "City": "Congo Town",
        "Country": "Bahamas"
    },
    {
        "id": "1453",
        "AirportCode": "COY",
        "AirportName": "Coolawanyah Airport",
        "City": "Coolawanyah",
        "Country": "Australia"
    },
    {
        "id": "1454",
        "AirportCode": "COZ",
        "AirportName": "Constanza Airport",
        "City": "Constanza",
        "Country": "Dominican Republic"
    },
    {
        "id": "1455",
        "AirportCode": "CPA",
        "AirportName": "A. Tubman Airport",
        "City": "Cape Palmas",
        "Country": "Liberia"
    },
    {
        "id": "1456",
        "AirportCode": "CPB",
        "AirportName": "Capurgana Airport",
        "City": "Capurgana",
        "Country": "Colombia"
    },
    {
        "id": "1457",
        "AirportCode": "CPC",
        "AirportName": "Chapelco Airport",
        "City": "San Martin De Los Andes",
        "Country": "Argentina"
    },
    {
        "id": "1458",
        "AirportCode": "CPD",
        "AirportName": "Coober Pedy Airport",
        "City": "Coober Pedy",
        "Country": "Australia"
    },
    {
        "id": "1459",
        "AirportCode": "CPE",
        "AirportName": "Campeche International Airport",
        "City": "Campeche",
        "Country": "Mexico"
    },
    {
        "id": "1460",
        "AirportCode": "CPF",
        "AirportName": "Cepu Airport",
        "City": "Cepu",
        "Country": "Indonesia"
    },
    {
        "id": "1461",
        "AirportCode": "CPG",
        "AirportName": "Carmen De Patagones Airport",
        "City": "Carmen De Patagones",
        "Country": "Argentina"
    },
    {
        "id": "1462",
        "AirportCode": "CPH",
        "AirportName": "Airport",
        "City": "Copenhagen",
        "Country": "Denmark"
    },
    {
        "id": "1463",
        "AirportCode": "CPI",
        "AirportName": "Cape Orford Airport",
        "City": "Cape Orford",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1464",
        "AirportCode": "CPL",
        "AirportName": "Chaparral Airport",
        "City": "Chaparral",
        "Country": "Colombia"
    },
    {
        "id": "1465",
        "AirportCode": "CPM",
        "AirportName": "Compton Airport",
        "City": "Compton",
        "Country": "USA California"
    },
    {
        "id": "1466",
        "AirportCode": "CPN",
        "AirportName": "Cape Rodney Airport",
        "City": "Cape Rodney",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1467",
        "AirportCode": "CPO",
        "AirportName": "Chamonate Airport",
        "City": "Copiapo",
        "Country": "Chile"
    },
    {
        "id": "1468",
        "AirportCode": "CPQ",
        "AirportName": "Campinas International Airport",
        "City": "Campinas",
        "Country": "Brazil"
    },
    {
        "id": "1469",
        "AirportCode": "CPR",
        "AirportName": "Casper Airport",
        "City": "Casper",
        "Country": "USA Wyoming"
    },
    {
        "id": "1470",
        "AirportCode": "CPS",
        "AirportName": "Bi-State Parks Airport",
        "City": "Saint Louis",
        "Country": "USA Missouri"
    },
    {
        "id": "1471",
        "AirportCode": "CPT",
        "AirportName": "Cape Town International Airport",
        "City": "Cape Town",
        "Country": "South Africa"
    },
    {
        "id": "1472",
        "AirportCode": "CPU",
        "AirportName": "Cururupu Airport",
        "City": "Cururupu",
        "Country": "Brazil"
    },
    {
        "id": "1473",
        "AirportCode": "CPV",
        "AirportName": "Joao Suassuna Airport",
        "City": "Campina Grande",
        "Country": "Brazil"
    },
    {
        "id": "1474",
        "AirportCode": "CPX",
        "AirportName": "Culebra Airport",
        "City": "Culebra",
        "Country": "Puerto Rico"
    },
    {
        "id": "1475",
        "AirportCode": "CQA",
        "AirportName": "Canarana Airport",
        "City": "Canarana",
        "Country": "Brazil"
    },
    {
        "id": "1476",
        "AirportCode": "CQD",
        "AirportName": "Shahre-Kord Airport",
        "City": "Shahre-Kord",
        "Country": "Iran"
    },
    {
        "id": "1477",
        "AirportCode": "CQF",
        "AirportName": "Calais Airport",
        "City": "Calais",
        "Country": "France"
    },
    {
        "id": "1478",
        "AirportCode": "CQP",
        "AirportName": "Cape Flattery Airport",
        "City": "Cape Flattery",
        "Country": "Australia"
    },
    {
        "id": "1479",
        "AirportCode": "CQS",
        "AirportName": "Costa Marques Airport",
        "City": "Costa Marques",
        "Country": "Brazil"
    },
    {
        "id": "1480",
        "AirportCode": "CQT",
        "AirportName": "Caquetania Airport",
        "City": "Caquetania",
        "Country": "Colombia"
    },
    {
        "id": "1481",
        "AirportCode": "CRA",
        "AirportName": "Craiova Airport",
        "City": "Craiova",
        "Country": "Romania"
    },
    {
        "id": "1482",
        "AirportCode": "CRB",
        "AirportName": "Collarenebri Airport",
        "City": "Collarenebri",
        "Country": "Australia"
    },
    {
        "id": "1483",
        "AirportCode": "CRC",
        "AirportName": "Cartago Airport",
        "City": "Cartago",
        "Country": "Colombia"
    },
    {
        "id": "1484",
        "AirportCode": "CRD",
        "AirportName": "Comodoro Rivadavia Airport",
        "City": "Comodoro Rivadavia",
        "Country": "Argentina"
    },
    {
        "id": "1485",
        "AirportCode": "CRE",
        "AirportName": "Grand Strand Airport",
        "City": "Myrtle Beach",
        "Country": "USA South Carolina"
    },
    {
        "id": "1486",
        "AirportCode": "CRF",
        "AirportName": "Carnot Airport",
        "City": "Carnot",
        "Country": "Central African Republic"
    },
    {
        "id": "1487",
        "AirportCode": "CRG",
        "AirportName": "Craig Municipal Airport",
        "City": "Jacksonville",
        "Country": "USA Florida"
    },
    {
        "id": "1488",
        "AirportCode": "CRH",
        "AirportName": "Cherribah Airport",
        "City": "Cherribah",
        "Country": "Australia"
    },
    {
        "id": "1489",
        "AirportCode": "CRI",
        "AirportName": "Crooked Island Airport",
        "City": "Crooked Island",
        "Country": "Bahamas"
    },
    {
        "id": "1490",
        "AirportCode": "CRJ",
        "AirportName": "Coorabie Airport",
        "City": "Coorabie",
        "Country": "Australia"
    },
    {
        "id": "1491",
        "AirportCode": "CRK",
        "AirportName": "Clark Field",
        "City": "Luzon Island",
        "Country": "Philippines"
    },
    {
        "id": "1492",
        "AirportCode": "CRL",
        "AirportName": "Charleroi Brussels South Airport",
        "City": "Brussels",
        "Country": "Belgium"
    },
    {
        "id": "1493",
        "AirportCode": "CRM",
        "AirportName": "National Airport",
        "City": "Catarman",
        "Country": "Philippines"
    },
    {
        "id": "1494",
        "AirportCode": "CRN",
        "AirportName": "Cromarty Airport",
        "City": "Cromarty",
        "Country": "United Kingdom"
    },
    {
        "id": "1495",
        "AirportCode": "CRO",
        "AirportName": "Corcoran Airport",
        "City": "Corcoran",
        "Country": "USA California"
    },
    {
        "id": "1496",
        "AirportCode": "CRP",
        "AirportName": "Corpus Christi International Airport",
        "City": "Corpus Christi",
        "Country": "USA Texas"
    },
    {
        "id": "1497",
        "AirportCode": "CRQ",
        "AirportName": "Caravelas Airport",
        "City": "Caravelas",
        "Country": "Brazil"
    },
    {
        "id": "1498",
        "AirportCode": "CRR",
        "AirportName": "Ceres Airport",
        "City": "Ceres",
        "Country": "Argentina"
    },
    {
        "id": "1499",
        "AirportCode": "CRS",
        "AirportName": "Corsicana Airport",
        "City": "Corsicana",
        "Country": "USA Texas"
    },
    {
        "id": "1500",
        "AirportCode": "CRT",
        "AirportName": "Crossett Municipal Airport",
        "City": "Crossett",
        "Country": "USA Arkansas"
    },
    {
        "id": "1501",
        "AirportCode": "CRU",
        "AirportName": "Carriacou Island Airport",
        "City": "Carriacou Island",
        "Country": "Grenada"
    },
    {
        "id": "1502",
        "AirportCode": "CRV",
        "AirportName": "Crotone Airport",
        "City": "Crotone",
        "Country": "Italy"
    },
    {
        "id": "1503",
        "AirportCode": "CRW",
        "AirportName": "Yeager Airport",
        "City": "Charleston",
        "Country": "USA West Virginia"
    },
    {
        "id": "1504",
        "AirportCode": "CRX",
        "AirportName": "Roscoe Turner Airport",
        "City": "Corinth",
        "Country": "USA Mississippi"
    },
    {
        "id": "1505",
        "AirportCode": "CRY",
        "AirportName": "Carlton Hill Airport",
        "City": "Carlton Hill",
        "Country": "Australia"
    },
    {
        "id": "1506",
        "AirportCode": "CRZ",
        "AirportName": "Turkmenabad Airport",
        "City": "Turkmenabad",
        "Country": "Turkmenistan"
    },
    {
        "id": "1507",
        "AirportCode": "CSA",
        "AirportName": "Colonsay Island Airport",
        "City": "Colonsay Island",
        "Country": "United Kingdom"
    },
    {
        "id": "1508",
        "AirportCode": "CSB",
        "AirportName": "Caransebes Airport",
        "City": "Caransebes",
        "Country": "Romania"
    },
    {
        "id": "1509",
        "AirportCode": "CSC",
        "AirportName": "Canas Airport",
        "City": "Canas",
        "Country": "Costa Rica"
    },
    {
        "id": "1510",
        "AirportCode": "CSD",
        "AirportName": "Cresswell Downs Airport",
        "City": "Cresswell Downs",
        "Country": "Australia"
    },
    {
        "id": "1511",
        "AirportCode": "CSE",
        "AirportName": "Crested Butte Airport",
        "City": "Crested Butte",
        "Country": "USA Colorado"
    },
    {
        "id": "1512",
        "AirportCode": "CSF",
        "AirportName": "Creil Airport",
        "City": "Creil",
        "Country": "France"
    },
    {
        "id": "1513",
        "AirportCode": "CSG",
        "AirportName": "Metropolitan Area",
        "City": "Columbus",
        "Country": "USA Georgia"
    },
    {
        "id": "1514",
        "AirportCode": "CSH",
        "AirportName": "Solovetsky Airport",
        "City": "Solovetsky",
        "Country": "Russia"
    },
    {
        "id": "1515",
        "AirportCode": "CSI",
        "AirportName": "Casino Airport",
        "City": "Casino",
        "Country": "Australia"
    },
    {
        "id": "1516",
        "AirportCode": "CSJ",
        "AirportName": "Cape St. Jacques Airport",
        "City": "Cape St. Jacques",
        "Country": "Vietnam"
    },
    {
        "id": "1517",
        "AirportCode": "CSK",
        "AirportName": "Cap Skirring Airport",
        "City": "Cap Skirring",
        "Country": "Senegal"
    },
    {
        "id": "1518",
        "AirportCode": "CSM",
        "AirportName": "Sherman Airport",
        "City": "Clinton",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1519",
        "AirportCode": "CSN",
        "AirportName": "Carson City Airport",
        "City": "Carson City",
        "Country": "USA Nevada"
    },
    {
        "id": "1520",
        "AirportCode": "CSO",
        "AirportName": "Cochstedt Airport",
        "City": "Cochstedt",
        "Country": "Germany"
    },
    {
        "id": "1521",
        "AirportCode": "CSQ",
        "AirportName": "Creston Municipal Airport",
        "City": "Creston",
        "Country": "USA Iowa"
    },
    {
        "id": "1522",
        "AirportCode": "CSR",
        "AirportName": "Casuarito Airport",
        "City": "Casuarito",
        "Country": "Colombia"
    },
    {
        "id": "1523",
        "AirportCode": "CSS",
        "AirportName": "Cassilandia Airport",
        "City": "Cassilandia",
        "Country": "Brazil"
    },
    {
        "id": "1524",
        "AirportCode": "CST",
        "AirportName": "Castaway Airport",
        "City": "Castaway",
        "Country": "Fiji"
    },
    {
        "id": "1525",
        "AirportCode": "CSU",
        "AirportName": "Santa Cruz Do Sul Airport",
        "City": "Santa Cruz Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "1526",
        "AirportCode": "CSV",
        "AirportName": "Crossville Memorial Airport",
        "City": "Crossville",
        "Country": "USA Tennessee"
    },
    {
        "id": "1527",
        "AirportCode": "CSW",
        "AirportName": "Colorado do Oeste Airport",
        "City": "Colorado do Oeste",
        "Country": "Brazil"
    },
    {
        "id": "1528",
        "AirportCode": "CSX",
        "AirportName": "Changsha Airport",
        "City": "Changsha",
        "Country": "China"
    },
    {
        "id": "1529",
        "AirportCode": "CSY",
        "AirportName": "Cheboksary Airport",
        "City": "Cheboksary",
        "Country": "Russia"
    },
    {
        "id": "1530",
        "AirportCode": "CSZ",
        "AirportName": "Brigadier Hector Ruiz",
        "City": "Coronel Suarez",
        "Country": "Argentina"
    },
    {
        "id": "1531",
        "AirportCode": "CTA",
        "AirportName": "Fontanarossa Airport",
        "City": "Catania",
        "Country": "Italy"
    },
    {
        "id": "1532",
        "AirportCode": "CTB",
        "AirportName": "Cut Bank Airport",
        "City": "Cut Bank",
        "Country": "USA Montana"
    },
    {
        "id": "1533",
        "AirportCode": "CTC",
        "AirportName": "Catamarca Airport",
        "City": "Catamarca",
        "Country": "Argentina"
    },
    {
        "id": "1534",
        "AirportCode": "CTD",
        "AirportName": "Chitre Airport",
        "City": "Chitre",
        "Country": "Panama"
    },
    {
        "id": "1535",
        "AirportCode": "CTE",
        "AirportName": "Carti Airport",
        "City": "Carti",
        "Country": "Panama"
    },
    {
        "id": "1536",
        "AirportCode": "CTF",
        "AirportName": "Coatepeque Airport",
        "City": "Coatepeque",
        "Country": "Guatemala"
    },
    {
        "id": "1537",
        "AirportCode": "CTG",
        "AirportName": "Rafael Nunez Airport",
        "City": "Cartagena",
        "Country": "Colombia"
    },
    {
        "id": "1538",
        "AirportCode": "CTH",
        "AirportName": "Chestercounty Carlson Airport",
        "City": "Coatesville",
        "Country": "USA Philadelphia"
    },
    {
        "id": "1539",
        "AirportCode": "CTK",
        "AirportName": "Canton Airport",
        "City": "Canton",
        "Country": "USA South Dakota"
    },
    {
        "id": "1540",
        "AirportCode": "CTL",
        "AirportName": "Charleville Airport",
        "City": "Charleville",
        "Country": "Australia"
    },
    {
        "id": "1541",
        "AirportCode": "CTM",
        "AirportName": "Chetumal Airport",
        "City": "Chetumal",
        "Country": "Mexico"
    },
    {
        "id": "1542",
        "AirportCode": "CTN",
        "AirportName": "Cooktown Airport",
        "City": "Cooktown",
        "Country": "Australia"
    },
    {
        "id": "1543",
        "AirportCode": "CTO",
        "AirportName": "Peconic River Airport",
        "City": "Calverton",
        "Country": "USA New York"
    },
    {
        "id": "1544",
        "AirportCode": "CTP",
        "AirportName": "Carutapera Airport",
        "City": "Carutapera",
        "Country": "Brazil"
    },
    {
        "id": "1545",
        "AirportCode": "CTQ",
        "AirportName": "Do Palmar Airport",
        "City": "Santa Vitoria",
        "Country": "Brazil"
    },
    {
        "id": "1546",
        "AirportCode": "CTR",
        "AirportName": "Cattle Creek Airport",
        "City": "Cattle Creek",
        "Country": "Australia"
    },
    {
        "id": "1547",
        "AirportCode": "CTS",
        "AirportName": "Chitose Airport",
        "City": "Sapporo",
        "Country": "Japan"
    },
    {
        "id": "1548",
        "AirportCode": "CTT",
        "AirportName": "Le Castellet Airport",
        "City": "Le Castellet",
        "Country": "France"
    },
    {
        "id": "1549",
        "AirportCode": "CTU",
        "AirportName": "Chengdu Airport",
        "City": "Chengdu",
        "Country": "China"
    },
    {
        "id": "1550",
        "AirportCode": "CTW",
        "AirportName": "Cottonwood Airport",
        "City": "Cottonwood",
        "Country": "USA Arizona"
    },
    {
        "id": "1551",
        "AirportCode": "CTX",
        "AirportName": "Cortland Airport",
        "City": "Cortland",
        "Country": "USA New York"
    },
    {
        "id": "1552",
        "AirportCode": "CTY",
        "AirportName": "Cross City Airport",
        "City": "Cross City",
        "Country": "USA Florida"
    },
    {
        "id": "1553",
        "AirportCode": "CTZ",
        "AirportName": "Sampson County Airport",
        "City": "Clinton",
        "Country": "USA North Carolina"
    },
    {
        "id": "1554",
        "AirportCode": "CUA",
        "AirportName": "Ciudad Constitucion Airport",
        "City": "Ciudad Constitucion",
        "Country": "Mexico"
    },
    {
        "id": "1555",
        "AirportCode": "CUB",
        "AirportName": "Owens Field",
        "City": "Columbia",
        "Country": "USA South Carolina"
    },
    {
        "id": "1556",
        "AirportCode": "CUC",
        "AirportName": "Camilo Dazo Airport",
        "City": "Cucuta",
        "Country": "Colombia"
    },
    {
        "id": "1557",
        "AirportCode": "CUD",
        "AirportName": "Caloundra Airport",
        "City": "Caloundra",
        "Country": "Australia"
    },
    {
        "id": "1558",
        "AirportCode": "CUE",
        "AirportName": "Cuenca Airport",
        "City": "Cuenca",
        "Country": "Ecuador"
    },
    {
        "id": "1559",
        "AirportCode": "CUF",
        "AirportName": "Levaldigi Airport",
        "City": "Cuneo",
        "Country": "Italy"
    },
    {
        "id": "1560",
        "AirportCode": "CUG",
        "AirportName": "Cudal Airport",
        "City": "Cudal",
        "Country": "Australia"
    },
    {
        "id": "1561",
        "AirportCode": "CUH",
        "AirportName": "Cushing Municipal Airport",
        "City": "Cushing",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1562",
        "AirportCode": "CUI",
        "AirportName": "Currillo Airport",
        "City": "Currillo",
        "Country": "Colombia"
    },
    {
        "id": "1563",
        "AirportCode": "CUJ",
        "AirportName": "Culion Airport",
        "City": "Culion",
        "Country": "Philippines"
    },
    {
        "id": "1564",
        "AirportCode": "CUK",
        "AirportName": "Caye Caulker Airport",
        "City": "Caye Caulker",
        "Country": "Belize"
    },
    {
        "id": "1565",
        "AirportCode": "CUL",
        "AirportName": "Fedl De Bachigualato Airport",
        "City": "Culiacan",
        "Country": "Mexico"
    },
    {
        "id": "1566",
        "AirportCode": "CUM",
        "AirportName": "Cumana Airport",
        "City": "Cumana",
        "Country": "Venezuela"
    },
    {
        "id": "1567",
        "AirportCode": "CUN",
        "AirportName": "Cancun Airport",
        "City": "Cancun",
        "Country": "Mexico"
    },
    {
        "id": "1568",
        "AirportCode": "CUO",
        "AirportName": "Caruru Airport",
        "City": "Caruru",
        "Country": "Colombia"
    },
    {
        "id": "1569",
        "AirportCode": "CUP",
        "AirportName": "Carupano Airport",
        "City": "Carupano",
        "Country": "Venezuela"
    },
    {
        "id": "1570",
        "AirportCode": "CUQ",
        "AirportName": "Coen Airport",
        "City": "Coen",
        "Country": "Australia"
    },
    {
        "id": "1571",
        "AirportCode": "CUR",
        "AirportName": "Aeropuerto Hato",
        "City": "Curacao",
        "Country": "Netherlands Antilles"
    },
    {
        "id": "1572",
        "AirportCode": "CUS",
        "AirportName": "Columbus Municipal Airport",
        "City": "Columbus",
        "Country": "USA New Mexico"
    },
    {
        "id": "1573",
        "AirportCode": "CUT",
        "AirportName": "Cutral Airport",
        "City": "Cutral",
        "Country": "Argentina"
    },
    {
        "id": "1574",
        "AirportCode": "CUU",
        "AirportName": "Gen Fierro Villalobos Airport",
        "City": "Chihuahua",
        "Country": "Mexico"
    },
    {
        "id": "1575",
        "AirportCode": "CUV",
        "AirportName": "Casigua Airport",
        "City": "Casigua",
        "Country": "Venezuela"
    },
    {
        "id": "1576",
        "AirportCode": "CUX",
        "AirportName": "Cuddihy Field",
        "City": "Corpus Christi",
        "Country": "USA Texas"
    },
    {
        "id": "1577",
        "AirportCode": "CUY",
        "AirportName": "Cue Airport",
        "City": "Cue",
        "Country": "Australia"
    },
    {
        "id": "1578",
        "AirportCode": "CUZ",
        "AirportName": "Velazco Astete Airport",
        "City": "Cuzco",
        "Country": "Peru"
    },
    {
        "id": "1579",
        "AirportCode": "CVA",
        "AirportName": "Civic Ar Heliport",
        "City": "Pittsburgh",
        "Country": "USA Philadelphia"
    },
    {
        "id": "1580",
        "AirportCode": "CVB",
        "AirportName": "Chungribu Airport",
        "City": "Chungribu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1581",
        "AirportCode": "CVC",
        "AirportName": "Cleve Airport",
        "City": "Cleve",
        "Country": "Australia"
    },
    {
        "id": "1582",
        "AirportCode": "CVE",
        "AirportName": "Covenas Airport",
        "City": "Covenas",
        "Country": "Colombia"
    },
    {
        "id": "1583",
        "AirportCode": "CVF",
        "AirportName": "Courchevel Airport",
        "City": "Courchevel",
        "Country": "France"
    },
    {
        "id": "1584",
        "AirportCode": "CVG",
        "AirportName": "Cincinnati/Northern Kentucky Airport",
        "City": "Hebron",
        "Country": "USA Kentucky"
    },
    {
        "id": "1585",
        "AirportCode": "CVH",
        "AirportName": "Caviahue Airport",
        "City": "Caviahue",
        "Country": "Argentina"
    },
    {
        "id": "1586",
        "AirportCode": "CVI",
        "AirportName": "Caleta Olivia Airport",
        "City": "Caleta Olivia",
        "Country": "Argentina"
    },
    {
        "id": "1587",
        "AirportCode": "CVJ",
        "AirportName": "Cuernavaca Airport",
        "City": "Cuernavaca",
        "Country": "Mexico"
    },
    {
        "id": "1588",
        "AirportCode": "CVL",
        "AirportName": "Cape Vogel Airport",
        "City": "Cape Vogel",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1589",
        "AirportCode": "CVM",
        "AirportName": "Ciudad Victoria Airport",
        "City": "Ciudad Victoria",
        "Country": "Mexico"
    },
    {
        "id": "1590",
        "AirportCode": "CVN",
        "AirportName": "Clovis Municipal Airport",
        "City": "Clovis",
        "Country": "USA New Mexico"
    },
    {
        "id": "1591",
        "AirportCode": "CVO",
        "AirportName": "Corvallis Municipal Airport",
        "City": "Albany",
        "Country": "USA Oregon"
    },
    {
        "id": "1592",
        "AirportCode": "CVQ",
        "AirportName": "Carnarvon Airport",
        "City": "Carnarvon",
        "Country": "Australia"
    },
    {
        "id": "1593",
        "AirportCode": "CVR",
        "AirportName": "Hughes Airport",
        "City": "Culver City",
        "Country": "USA California"
    },
    {
        "id": "1594",
        "AirportCode": "CVS",
        "AirportName": "Cannon Air Force Base",
        "City": "Clovis",
        "Country": "USA New Mexico"
    },
    {
        "id": "1595",
        "AirportCode": "CVT",
        "AirportName": "Baginton Airport",
        "City": "Coventry",
        "Country": "United Kingdom"
    },
    {
        "id": "1596",
        "AirportCode": "CVU",
        "AirportName": "Corvo Island (Azores) Airport",
        "City": "Corvo Island (Azores)",
        "Country": "Azores"
    },
    {
        "id": "1597",
        "AirportCode": "CWA",
        "AirportName": "Central Wisconsin Airport",
        "City": "Wausau",
        "Country": "USA Wisconsin"
    },
    {
        "id": "1598",
        "AirportCode": "CWB",
        "AirportName": "Afonso Pena Airport",
        "City": "Curitiba",
        "Country": "Brazil"
    },
    {
        "id": "1599",
        "AirportCode": "CWC",
        "AirportName": "Chernovtsy Airport",
        "City": "Chernovtsy",
        "Country": "Ukraine"
    },
    {
        "id": "1600",
        "AirportCode": "CWG",
        "AirportName": "Callaway Gardens Airport",
        "City": "Callaway Gardens",
        "Country": "USA Georgia"
    },
    {
        "id": "1601",
        "AirportCode": "CWI",
        "AirportName": "Clinton Airport",
        "City": "Clinton",
        "Country": "USA Iowa"
    },
    {
        "id": "1602",
        "AirportCode": "CWL",
        "AirportName": "Cardiff-Wales Airport",
        "City": "Cardiff",
        "Country": "United Kingdom"
    },
    {
        "id": "1603",
        "AirportCode": "CWO",
        "AirportName": "Ft. Wolter Air Force Base",
        "City": "Mineral Wells",
        "Country": "USA Texas"
    },
    {
        "id": "1604",
        "AirportCode": "CWP",
        "AirportName": "Campbellpore Airport",
        "City": "Campbellpore",
        "Country": "Pakistan"
    },
    {
        "id": "1605",
        "AirportCode": "CWR",
        "AirportName": "Cowarie Airport",
        "City": "Cowarie",
        "Country": "Australia"
    },
    {
        "id": "1606",
        "AirportCode": "CWS",
        "AirportName": "Center Island Airport",
        "City": "Center Island",
        "Country": "USA Washington"
    },
    {
        "id": "1607",
        "AirportCode": "CWT",
        "AirportName": "Cowra Airport",
        "City": "Cowra",
        "Country": "Australia"
    },
    {
        "id": "1608",
        "AirportCode": "CWW",
        "AirportName": "Corowa Airport",
        "City": "Corowa",
        "Country": "Australia"
    },
    {
        "id": "1609",
        "AirportCode": "CXA",
        "AirportName": "Caicara De Oro Airport",
        "City": "Caicara De Oro",
        "Country": "Venezuela"
    },
    {
        "id": "1610",
        "AirportCode": "CXB",
        "AirportName": "Coxs Bazar Airport",
        "City": "Coxs Bazar",
        "Country": "Bangladesh"
    },
    {
        "id": "1611",
        "AirportCode": "CXH",
        "AirportName": "Coal Harbour Sea Plane Base",
        "City": "Vancouver",
        "Country": "Canada"
    },
    {
        "id": "1612",
        "AirportCode": "CXI",
        "AirportName": "Island Airport",
        "City": "Christmas Island",
        "Country": "Kiribati"
    },
    {
        "id": "1613",
        "AirportCode": "CXJ",
        "AirportName": "Campo Dos Bugres Airport",
        "City": "Caxias Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "1614",
        "AirportCode": "CXL",
        "AirportName": "Calexico International Airport",
        "City": "Calexico",
        "Country": "USA California"
    },
    {
        "id": "1615",
        "AirportCode": "CXN",
        "AirportName": "Candala Airport",
        "City": "Candala",
        "Country": "Somalia"
    },
    {
        "id": "1616",
        "AirportCode": "CXO",
        "AirportName": "Montgomery County Airport",
        "City": "Conroe",
        "Country": "USA Texas"
    },
    {
        "id": "1617",
        "AirportCode": "CXP",
        "AirportName": "Tunggul Wulung Airport",
        "City": "Cilacap",
        "Country": "Indonesia"
    },
    {
        "id": "1618",
        "AirportCode": "CXQ",
        "AirportName": "Christmas Creek Airport",
        "City": "Christmas Creek",
        "Country": "Australia"
    },
    {
        "id": "1619",
        "AirportCode": "CXR",
        "AirportName": "Cam Rahn Airport",
        "City": "Nha Trang",
        "Country": "Vietnam"
    },
    {
        "id": "1620",
        "AirportCode": "CXT",
        "AirportName": "Charters Towers Airport",
        "City": "Charters Towers",
        "Country": "Australia"
    },
    {
        "id": "1621",
        "AirportCode": "CYA",
        "AirportName": "Les Cayes Airport",
        "City": "Les Cayes",
        "Country": "Haiti"
    },
    {
        "id": "1622",
        "AirportCode": "CYB",
        "AirportName": "Gerrard-Smith Airport",
        "City": "Cayman Brac Island",
        "Country": "Cayman Islands"
    },
    {
        "id": "1623",
        "AirportCode": "CYC",
        "AirportName": "Caye Chapel Airport",
        "City": "Caye Chapel",
        "Country": "Belize"
    },
    {
        "id": "1624",
        "AirportCode": "CYE",
        "AirportName": "Crystal Lake Airport",
        "City": "Crystal Lake",
        "Country": "USA Philadelphia"
    },
    {
        "id": "1625",
        "AirportCode": "CYF",
        "AirportName": "Chefornak Sea Plane Base",
        "City": "Chefornak",
        "Country": "USA Alaska"
    },
    {
        "id": "1626",
        "AirportCode": "CYG",
        "AirportName": "Corryong Airport",
        "City": "Corryong",
        "Country": "Australia"
    },
    {
        "id": "1627",
        "AirportCode": "CYI",
        "AirportName": "Chiayi Airport",
        "City": "Chiayi",
        "Country": "Taiwan"
    },
    {
        "id": "1628",
        "AirportCode": "CYL",
        "AirportName": "Coyoles Airport",
        "City": "Coyoles",
        "Country": "Honduras"
    },
    {
        "id": "1629",
        "AirportCode": "CYO",
        "AirportName": "Cayo Largo Del Sur Airport",
        "City": "Cayo Largo Del Sur",
        "Country": "Cuba"
    },
    {
        "id": "1630",
        "AirportCode": "CYP",
        "AirportName": "Calbayog Airport",
        "City": "Calbayog",
        "Country": "Philippines"
    },
    {
        "id": "1631",
        "AirportCode": "CYR",
        "AirportName": "Colonia Airport",
        "City": "Colonia",
        "Country": "Uruguay"
    },
    {
        "id": "1632",
        "AirportCode": "CYS",
        "AirportName": "Cheyenne Airport",
        "City": "Cheyenne",
        "Country": "USA Wyoming"
    },
    {
        "id": "1633",
        "AirportCode": "CYU",
        "AirportName": "Cuyo Airport",
        "City": "Cuyo",
        "Country": "Philippines"
    },
    {
        "id": "1634",
        "AirportCode": "CYW",
        "AirportName": "Celaya",
        "City": "Celaya",
        "Country": "Mexico"
    },
    {
        "id": "1635",
        "AirportCode": "CYX",
        "AirportName": "Cherskiy Airport",
        "City": "Cherskiy",
        "Country": "Russia"
    },
    {
        "id": "1636",
        "AirportCode": "CYZ",
        "AirportName": "Cauayan Airport",
        "City": "Cauayan",
        "Country": "Philippines"
    },
    {
        "id": "1637",
        "AirportCode": "CZA",
        "AirportName": "Chichen Itza Airport",
        "City": "Chichen Itza",
        "Country": "Mexico"
    },
    {
        "id": "1638",
        "AirportCode": "CZB",
        "AirportName": "Carlos Ruhl Airport",
        "City": "Cruz Alta",
        "Country": "Brazil"
    },
    {
        "id": "1639",
        "AirportCode": "CZE",
        "AirportName": "Coro Airport",
        "City": "Coro",
        "Country": "Venezuela"
    },
    {
        "id": "1640",
        "AirportCode": "CZH",
        "AirportName": "Corozal Airport",
        "City": "Corozal",
        "Country": "Belize"
    },
    {
        "id": "1641",
        "AirportCode": "CZJ",
        "AirportName": "Corazon De Jesus Airport",
        "City": "Corazon De Jesus",
        "Country": "Panama"
    },
    {
        "id": "1642",
        "AirportCode": "CZK",
        "AirportName": "Cascade Locks/Stevens Airport",
        "City": "Cascade Locks",
        "Country": "USA Oregon"
    },
    {
        "id": "1643",
        "AirportCode": "CZL",
        "AirportName": "Ain El Bey Airport",
        "City": "Constantine",
        "Country": "Algeria"
    },
    {
        "id": "1644",
        "AirportCode": "CZM",
        "AirportName": "Cozumel Airport",
        "City": "Cozumel",
        "Country": "Mexico"
    },
    {
        "id": "1645",
        "AirportCode": "CZN",
        "AirportName": "Chisana Field",
        "City": "Chisana",
        "Country": "USA Alaska"
    },
    {
        "id": "1646",
        "AirportCode": "CZS",
        "AirportName": "Campo Internacional Airport",
        "City": "Cruzeiro Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "1647",
        "AirportCode": "CZT",
        "AirportName": "Carrizo Springs Airport",
        "City": "Carrizo Springs",
        "Country": "USA Texas"
    },
    {
        "id": "1648",
        "AirportCode": "CZU",
        "AirportName": "Corozal Airport",
        "City": "Corozal",
        "Country": "Colombia"
    },
    {
        "id": "1649",
        "AirportCode": "CZW",
        "AirportName": "Czestochowa Airport",
        "City": "Czestochowa",
        "Country": "Poland"
    },
    {
        "id": "1650",
        "AirportCode": "CZX",
        "AirportName": "Changzhou Airport",
        "City": "Changzhou",
        "Country": "China"
    },
    {
        "id": "1651",
        "AirportCode": "CZY",
        "AirportName": "Cluny Airport",
        "City": "Cluny",
        "Country": "Australia"
    },
    {
        "id": "1652",
        "AirportCode": "CZZ",
        "AirportName": "Campo Airport",
        "City": "Campo",
        "Country": "USA California"
    },
    {
        "id": "1653",
        "AirportCode": "DAA",
        "AirportName": "Davison Army Air Field",
        "City": "Fort Belvoir",
        "Country": "USA Virginia"
    },
    {
        "id": "1654",
        "AirportCode": "DAB",
        "AirportName": "Daytona Beach Regional Airport",
        "City": "Daytona Beach",
        "Country": "USA Florida"
    },
    {
        "id": "1655",
        "AirportCode": "DAC",
        "AirportName": "Zia International Airport",
        "City": "Dhaka",
        "Country": "Bangladesh"
    },
    {
        "id": "1656",
        "AirportCode": "DAD",
        "AirportName": "Da Nang Airport",
        "City": "Da Nang",
        "Country": "Vietnam"
    },
    {
        "id": "1657",
        "AirportCode": "DAE",
        "AirportName": "Daparizo Airport",
        "City": "Daparizo",
        "Country": "India"
    },
    {
        "id": "1658",
        "AirportCode": "DAF",
        "AirportName": "Daup Airport",
        "City": "Daup",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1659",
        "AirportCode": "DAG",
        "AirportName": "Barstow-Daggett Airport",
        "City": "Daggett",
        "Country": "USA California"
    },
    {
        "id": "1660",
        "AirportCode": "DAH",
        "AirportName": "Dathina Airport",
        "City": "Dathina",
        "Country": "Yemen"
    },
    {
        "id": "1661",
        "AirportCode": "DAI",
        "AirportName": "Darjeeling Airport",
        "City": "Darjeeling",
        "Country": "India"
    },
    {
        "id": "1662",
        "AirportCode": "DAJ",
        "AirportName": "Dauan Island Airport",
        "City": "Dauan Island",
        "Country": "Australia"
    },
    {
        "id": "1663",
        "AirportCode": "DAK",
        "AirportName": "Dakhla Airport",
        "City": "Dakhla Oasis",
        "Country": "Egypt"
    },
    {
        "id": "1664",
        "AirportCode": "DAL",
        "AirportName": "Love Field",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "1665",
        "AirportCode": "DAM",
        "AirportName": "Damascus International Airport",
        "City": "Damascus",
        "Country": "Syria"
    },
    {
        "id": "1666",
        "AirportCode": "DAN",
        "AirportName": "Danville Municipal Airport",
        "City": "Danville",
        "Country": "USA Virginia"
    },
    {
        "id": "1667",
        "AirportCode": "DAO",
        "AirportName": "Dabo Airport",
        "City": "Dabo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1668",
        "AirportCode": "DAP",
        "AirportName": "Darchula Airport",
        "City": "Darchula",
        "Country": "Nepal"
    },
    {
        "id": "1669",
        "AirportCode": "DAR",
        "AirportName": "Dar Es Salaam International Airport",
        "City": "Dar Es Salaam",
        "Country": "Tanzania"
    },
    {
        "id": "1670",
        "AirportCode": "DAS",
        "AirportName": "Great Bear Lake Airport",
        "City": "Great Bear Lake",
        "Country": "Canada"
    },
    {
        "id": "1671",
        "AirportCode": "DAT",
        "AirportName": "Datong Airport",
        "City": "Datong",
        "Country": "China"
    },
    {
        "id": "1672",
        "AirportCode": "DAU",
        "AirportName": "Daru Airport",
        "City": "Daru",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1673",
        "AirportCode": "DAV",
        "AirportName": "Enrique Malek Airport",
        "City": "David",
        "Country": "Panama"
    },
    {
        "id": "1674",
        "AirportCode": "DAX",
        "AirportName": "Daxian Airport",
        "City": "Daxian",
        "Country": "China"
    },
    {
        "id": "1675",
        "AirportCode": "DAY",
        "AirportName": "James Cox Dayton International Airport",
        "City": "Dayton",
        "Country": "USA Ohio"
    },
    {
        "id": "1676",
        "AirportCode": "DAZ",
        "AirportName": "Darwaz Airport",
        "City": "Darwaz",
        "Country": "Afghanistan"
    },
    {
        "id": "1677",
        "AirportCode": "DBA",
        "AirportName": "Dalbandin Airport",
        "City": "Dalbandin",
        "Country": "Pakistan"
    },
    {
        "id": "1678",
        "AirportCode": "DBD",
        "AirportName": "Dhanbad Airport",
        "City": "Dhanbad",
        "Country": "India"
    },
    {
        "id": "1679",
        "AirportCode": "DBM",
        "AirportName": "Debra Marcos Airport",
        "City": "Debra Marcos",
        "Country": "Ethiopia"
    },
    {
        "id": "1680",
        "AirportCode": "DBN",
        "AirportName": "Dublin Municipal Airport",
        "City": "Dublin",
        "Country": "USA Georgia"
    },
    {
        "id": "1681",
        "AirportCode": "DBO",
        "AirportName": "Dubbo Airport",
        "City": "Dubbo",
        "Country": "Australia"
    },
    {
        "id": "1682",
        "AirportCode": "DBP",
        "AirportName": "Debepare Airport",
        "City": "Debepare",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1683",
        "AirportCode": "DBQ",
        "AirportName": "Dubuque Municipal Airport",
        "City": "Dubuque",
        "Country": "USA Iowa"
    },
    {
        "id": "1684",
        "AirportCode": "DBS",
        "AirportName": "Dubois Airport",
        "City": "Dubois",
        "Country": "USA Idaho"
    },
    {
        "id": "1685",
        "AirportCode": "DBT",
        "AirportName": "Debra Tabor Airport",
        "City": "Debra Tabor",
        "Country": "Ethiopia"
    },
    {
        "id": "1686",
        "AirportCode": "DBU",
        "AirportName": "Dambula Oya Tank Airport",
        "City": "Dambula",
        "Country": "Sri Lanka"
    },
    {
        "id": "1687",
        "AirportCode": "DBV",
        "AirportName": "Dubrovnik Airport",
        "City": "Dubrovnik",
        "Country": "Croatia"
    },
    {
        "id": "1688",
        "AirportCode": "DBY",
        "AirportName": "Dalby Airport",
        "City": "Dalby",
        "Country": "Australia"
    },
    {
        "id": "1689",
        "AirportCode": "DCA",
        "AirportName": "Ronald Reagan National Airport",
        "City": "Washington",
        "Country": "USA DC"
    },
    {
        "id": "1690",
        "AirportCode": "DCF",
        "AirportName": "Cane Field Airport",
        "City": "Dominica",
        "Country": "Dominica"
    },
    {
        "id": "1691",
        "AirportCode": "DCI",
        "AirportName": "Rafsu Decimomannu Airport",
        "City": "Decimomannu",
        "Country": "Italy"
    },
    {
        "id": "1692",
        "AirportCode": "DCM",
        "AirportName": "Mazamet Airport",
        "City": "Castres",
        "Country": "France"
    },
    {
        "id": "1693",
        "AirportCode": "DCR",
        "AirportName": "Decatur Hi-Way Airport",
        "City": "Decatur",
        "Country": "USA Indiana"
    },
    {
        "id": "1694",
        "AirportCode": "DCU",
        "AirportName": "Pyor Airport",
        "City": "Decatur",
        "Country": "USA Alabama"
    },
    {
        "id": "1695",
        "AirportCode": "DDC",
        "AirportName": "Dodge City Municipal Airport",
        "City": "Dodge City",
        "Country": "USA Kansas"
    },
    {
        "id": "1696",
        "AirportCode": "DDG",
        "AirportName": "Dandong Airport",
        "City": "Dandong",
        "Country": "China"
    },
    {
        "id": "1697",
        "AirportCode": "DDI",
        "AirportName": "Daydream Island Airport",
        "City": "Daydream Island",
        "Country": "Australia"
    },
    {
        "id": "1698",
        "AirportCode": "DDM",
        "AirportName": "Dodoima Airport",
        "City": "Dodoima",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1699",
        "AirportCode": "DDN",
        "AirportName": "Delta Downs Airport",
        "City": "Delta Downs",
        "Country": "Australia"
    },
    {
        "id": "1700",
        "AirportCode": "DDP",
        "AirportName": "Dorado Beach Airport",
        "City": "Dorado",
        "Country": "Puerto Rico"
    },
    {
        "id": "1701",
        "AirportCode": "DDU",
        "AirportName": "Dadu Airport",
        "City": "Dadu",
        "Country": "Pakistan"
    },
    {
        "id": "1702",
        "AirportCode": "DEA",
        "AirportName": "Dera Ghazi Khan Airport",
        "City": "Dera Ghazi Khan",
        "Country": "Pakistan"
    },
    {
        "id": "1703",
        "AirportCode": "DEB",
        "AirportName": "Debrecen Airport",
        "City": "Debrecen",
        "Country": "Hungary"
    },
    {
        "id": "1704",
        "AirportCode": "DEC",
        "AirportName": "Decatur Airport",
        "City": "Decatur",
        "Country": "USA Illinois"
    },
    {
        "id": "1705",
        "AirportCode": "DED",
        "AirportName": "Dehradun Airport",
        "City": "Dehra Dun",
        "Country": "India"
    },
    {
        "id": "1706",
        "AirportCode": "DEF",
        "AirportName": "Dezful Airport",
        "City": "Dezful",
        "Country": "Iran"
    },
    {
        "id": "1707",
        "AirportCode": "DEH",
        "AirportName": "Decorah Municipal Airport",
        "City": "Decorah",
        "Country": "USA Iowa"
    },
    {
        "id": "1708",
        "AirportCode": "DEI",
        "AirportName": "Denis Island Airport",
        "City": "Denis Island",
        "Country": "Seychelles"
    },
    {
        "id": "1709",
        "AirportCode": "DEL",
        "AirportName": "Indira Gandhi International Airport",
        "City": "Delhi",
        "Country": "India"
    },
    {
        "id": "1710",
        "AirportCode": "DEM",
        "AirportName": "Dembidollo Airport",
        "City": "Dembidollo",
        "Country": "Ethiopia"
    },
    {
        "id": "1711",
        "AirportCode": "DEN",
        "AirportName": "Denver International Airport",
        "City": "Denver",
        "Country": "USA Colorado"
    },
    {
        "id": "1712",
        "AirportCode": "DEO",
        "AirportName": "Hyatt Regency Heliport",
        "City": "Dearborn",
        "Country": "USA Michigan"
    },
    {
        "id": "1713",
        "AirportCode": "DEP",
        "AirportName": "Deparizo Airport",
        "City": "Deparizo",
        "Country": "India"
    },
    {
        "id": "1714",
        "AirportCode": "DER",
        "AirportName": "Derim Airport",
        "City": "Derim",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1715",
        "AirportCode": "DES",
        "AirportName": "Desroches Airport",
        "City": "Desroches",
        "Country": "Seychelles"
    },
    {
        "id": "1716",
        "AirportCode": "DET",
        "AirportName": "Detroit City Airport",
        "City": "Detroit",
        "Country": "USA Michigan"
    },
    {
        "id": "1717",
        "AirportCode": "DEZ",
        "AirportName": "Al Jafrah Airport",
        "City": "Deirezzor",
        "Country": "Syria"
    },
    {
        "id": "1718",
        "AirportCode": "DFI",
        "AirportName": "Defiance Memorial Airport",
        "City": "Defiance",
        "Country": "USA Ohio"
    },
    {
        "id": "1719",
        "AirportCode": "DFP",
        "AirportName": "Drumduff Airport",
        "City": "Drumduff",
        "Country": "Australia"
    },
    {
        "id": "1720",
        "AirportCode": "DFW",
        "AirportName": "Dallas/Ft. Worth International Airport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "1721",
        "AirportCode": "DGA",
        "AirportName": "Dangriga Airport",
        "City": "Dangriga",
        "Country": "Belize"
    },
    {
        "id": "1722",
        "AirportCode": "DGC",
        "AirportName": "Degahbur Airport",
        "City": "Degahbur",
        "Country": "Ethiopia"
    },
    {
        "id": "1723",
        "AirportCode": "DGD",
        "AirportName": "Dalgaranga Airport",
        "City": "Dalgaranga",
        "Country": "Australia"
    },
    {
        "id": "1724",
        "AirportCode": "DGE",
        "AirportName": "Mudgee Airport",
        "City": "Mudgee",
        "Country": "Australia"
    },
    {
        "id": "1725",
        "AirportCode": "DGF",
        "AirportName": "Douglas Lake Airport",
        "City": "Douglas Lake",
        "Country": "Canada"
    },
    {
        "id": "1726",
        "AirportCode": "DGL",
        "AirportName": "Douglas Municipal Airport",
        "City": "Douglas",
        "Country": "USA Arizona"
    },
    {
        "id": "1727",
        "AirportCode": "DGM",
        "AirportName": "Dongguan Airport",
        "City": "Dongguan",
        "Country": "China"
    },
    {
        "id": "1728",
        "AirportCode": "DGN",
        "AirportName": "Naval Air Field",
        "City": "Dahlgren",
        "Country": "USA Virginia"
    },
    {
        "id": "1729",
        "AirportCode": "DGO",
        "AirportName": "Guadalupe Victoria Airport",
        "City": "Durango",
        "Country": "Mexico"
    },
    {
        "id": "1730",
        "AirportCode": "DGP",
        "AirportName": "Daugavpils Airport",
        "City": "Daugavpils",
        "Country": "Latvia"
    },
    {
        "id": "1731",
        "AirportCode": "DGR",
        "AirportName": "Dargaville Airport",
        "City": "Dargaville",
        "Country": "New Zealand"
    },
    {
        "id": "1732",
        "AirportCode": "DGT",
        "AirportName": "Dumaguete Airport",
        "City": "Dumaguete",
        "Country": "Philippines"
    },
    {
        "id": "1733",
        "AirportCode": "DGU",
        "AirportName": "Dedougou Airport",
        "City": "Dedougou",
        "Country": "Burkina Faso"
    },
    {
        "id": "1734",
        "AirportCode": "DGW",
        "AirportName": "Converse County Airport",
        "City": "Douglas",
        "Country": "USA Wyoming"
    },
    {
        "id": "1735",
        "AirportCode": "DHA",
        "AirportName": "King Abdulaziz Ab",
        "City": "Dhahran",
        "Country": "Saudi Arabia"
    },
    {
        "id": "1736",
        "AirportCode": "DHD",
        "AirportName": "Durham Downs Airport",
        "City": "Durham Downs",
        "Country": "Australia"
    },
    {
        "id": "1737",
        "AirportCode": "DHF",
        "AirportName": "Al Dhafra Military Airport",
        "City": "Abu Dhabi",
        "Country": "UAE"
    },
    {
        "id": "1738",
        "AirportCode": "DHI",
        "AirportName": "Dhangarhi Airport",
        "City": "Dhangarhi",
        "Country": "Nepal"
    },
    {
        "id": "1739",
        "AirportCode": "DHL",
        "AirportName": "Dhala Airport",
        "City": "Dhala",
        "Country": "Yemen"
    },
    {
        "id": "1740",
        "AirportCode": "DHM",
        "AirportName": "Gaggal Airport",
        "City": "Dharamsala",
        "Country": "India"
    },
    {
        "id": "1741",
        "AirportCode": "DHN",
        "AirportName": "Dothan Airport",
        "City": "Dothan",
        "Country": "USA Alabama"
    },
    {
        "id": "1742",
        "AirportCode": "DHR",
        "AirportName": "De Kooy Airport",
        "City": "Den Helder",
        "Country": "Netherlands"
    },
    {
        "id": "1743",
        "AirportCode": "DHT",
        "AirportName": "Dalhart Airport",
        "City": "Dalhart",
        "Country": "USA Texas"
    },
    {
        "id": "1744",
        "AirportCode": "DIB",
        "AirportName": "Chabua Airport",
        "City": "Dibrugarh",
        "Country": "India"
    },
    {
        "id": "1745",
        "AirportCode": "DIC",
        "AirportName": "Dili Airport",
        "City": "Dili",
        "Country": "Congo, DR"
    },
    {
        "id": "1746",
        "AirportCode": "DIE",
        "AirportName": "Antsiranana/Arrachart Airport",
        "City": "Antsiranana",
        "Country": "Madagascar"
    },
    {
        "id": "1747",
        "AirportCode": "DIG",
        "AirportName": "Diqing Airport",
        "City": "Diqing",
        "Country": "China"
    },
    {
        "id": "1748",
        "AirportCode": "DIJ",
        "AirportName": "Dijon Airport",
        "City": "Dijon",
        "Country": "France"
    },
    {
        "id": "1749",
        "AirportCode": "DIK",
        "AirportName": "Dickinson Airport",
        "City": "Dickinson",
        "Country": "USA North Dakota"
    },
    {
        "id": "1750",
        "AirportCode": "DIL",
        "AirportName": "Comoro Airport",
        "City": "Dili",
        "Country": "East Timor"
    },
    {
        "id": "1751",
        "AirportCode": "DIN",
        "AirportName": "Dien Bien Airport",
        "City": "Dien Bien Phu",
        "Country": "Vietnam"
    },
    {
        "id": "1752",
        "AirportCode": "DIO",
        "AirportName": "Diomede Island Airport",
        "City": "Diomede Island",
        "Country": "USA Alaska"
    },
    {
        "id": "1753",
        "AirportCode": "DIP",
        "AirportName": "Diapaga Airport",
        "City": "Diapaga",
        "Country": "Burkina Faso"
    },
    {
        "id": "1754",
        "AirportCode": "DIQ",
        "AirportName": "Divinopolis Airport",
        "City": "Divinopolis",
        "Country": "Brazil"
    },
    {
        "id": "1755",
        "AirportCode": "DIR",
        "AirportName": "Aba Tenna D Yilma Airport",
        "City": "Dire Dawa",
        "Country": "Ethiopia"
    },
    {
        "id": "1756",
        "AirportCode": "DIS",
        "AirportName": "Loubomo Airport",
        "City": "Loubomo",
        "Country": "Congo"
    },
    {
        "id": "1757",
        "AirportCode": "DIU",
        "AirportName": "Diu Airport",
        "City": "Diu",
        "Country": "India"
    },
    {
        "id": "1758",
        "AirportCode": "DIY",
        "AirportName": "Diyarbakir Airport",
        "City": "Diyarbakir",
        "Country": "Turkey"
    },
    {
        "id": "1759",
        "AirportCode": "DJA",
        "AirportName": "Djougou Airport",
        "City": "Djougou",
        "Country": "Benin"
    },
    {
        "id": "1760",
        "AirportCode": "DJB",
        "AirportName": "Sultan Taha Syarifudn Airport",
        "City": "Jambi",
        "Country": "Indonesia"
    },
    {
        "id": "1761",
        "AirportCode": "DJE",
        "AirportName": "Melita Airport",
        "City": "Djerba",
        "Country": "Tunisia"
    },
    {
        "id": "1762",
        "AirportCode": "DJG",
        "AirportName": "Inedbirenne Airport",
        "City": "Djanet",
        "Country": "Algeria"
    },
    {
        "id": "1763",
        "AirportCode": "DJJ",
        "AirportName": "Sentani Airport",
        "City": "Jayapura",
        "Country": "Indonesia"
    },
    {
        "id": "1764",
        "AirportCode": "DJM",
        "AirportName": "Djambala Airport",
        "City": "Djambala",
        "Country": "Congo"
    },
    {
        "id": "1765",
        "AirportCode": "DJN",
        "AirportName": "Delta Junction Airport",
        "City": "Delta Junction",
        "Country": "USA Alaska"
    },
    {
        "id": "1766",
        "AirportCode": "DJU",
        "AirportName": "Djupivogur Airport",
        "City": "Djupivogur",
        "Country": "Iceland"
    },
    {
        "id": "1767",
        "AirportCode": "DKI",
        "AirportName": "Dunk Island Airport",
        "City": "Dunk Island",
        "Country": "Australia"
    },
    {
        "id": "1768",
        "AirportCode": "DKK",
        "AirportName": "Dunkirk Airport",
        "City": "Dunkirk",
        "Country": "USA New York"
    },
    {
        "id": "1769",
        "AirportCode": "DKR",
        "AirportName": "Yoff Airport",
        "City": "Dakar (DKR)",
        "Country": "Senegal"
    },
    {
        "id": "1770",
        "AirportCode": "DKS",
        "AirportName": "Dikson Airport",
        "City": "Dikson",
        "Country": "Russia"
    },
    {
        "id": "1771",
        "AirportCode": "DKV",
        "AirportName": "Docker River Airport",
        "City": "Docker River",
        "Country": "Australia"
    },
    {
        "id": "1772",
        "AirportCode": "DLA",
        "AirportName": "Douala Airport",
        "City": "Douala",
        "Country": "Cameroon"
    },
    {
        "id": "1773",
        "AirportCode": "DLC",
        "AirportName": "Dalian Airport",
        "City": "Dalian",
        "Country": "China"
    },
    {
        "id": "1774",
        "AirportCode": "DLD",
        "AirportName": "Dagali Airport",
        "City": "Geilo",
        "Country": "Norway"
    },
    {
        "id": "1775",
        "AirportCode": "DLE",
        "AirportName": "Tavaux Airport",
        "City": "Dole",
        "Country": "France"
    },
    {
        "id": "1776",
        "AirportCode": "DLF",
        "AirportName": "Laughlin Air Force Base",
        "City": "Del Rio",
        "Country": "USA Texas"
    },
    {
        "id": "1777",
        "AirportCode": "DLG",
        "AirportName": "Dillingham Municipal Airport",
        "City": "Dillingham",
        "Country": "USA Alaska"
    },
    {
        "id": "1778",
        "AirportCode": "DLH",
        "AirportName": "Duluth International Airport",
        "City": "Duluth",
        "Country": "USA Minnesota"
    },
    {
        "id": "1779",
        "AirportCode": "DLI",
        "AirportName": "Lienkhang Airport",
        "City": "Dalat",
        "Country": "Vietnam"
    },
    {
        "id": "1780",
        "AirportCode": "DLK",
        "AirportName": "Dulkaninna Airport",
        "City": "Dulkaninna",
        "Country": "Australia"
    },
    {
        "id": "1781",
        "AirportCode": "DLL",
        "AirportName": "Dillon Airport",
        "City": "Dillon",
        "Country": "USA South Carolina"
    },
    {
        "id": "1782",
        "AirportCode": "DLM",
        "AirportName": "Dalaman Airport",
        "City": "Dalaman",
        "Country": "Turkey"
    },
    {
        "id": "1783",
        "AirportCode": "DLN",
        "AirportName": "Dillon Airport",
        "City": "Dillon",
        "Country": "USA Montana"
    },
    {
        "id": "1784",
        "AirportCode": "DLO",
        "AirportName": "Dolomi Airport",
        "City": "Dolomi",
        "Country": "USA Alaska"
    },
    {
        "id": "1785",
        "AirportCode": "DLP",
        "AirportName": "Disneyland Paris Airport",
        "City": "Disneyland Paris",
        "Country": "France"
    },
    {
        "id": "1786",
        "AirportCode": "DLS",
        "AirportName": "The Dalles Airport",
        "City": "The Dalles",
        "Country": "USA Oregon"
    },
    {
        "id": "1787",
        "AirportCode": "DLU",
        "AirportName": "Dali Airport",
        "City": "Dali City",
        "Country": "China"
    },
    {
        "id": "1788",
        "AirportCode": "DLV",
        "AirportName": "Delissaville Airport",
        "City": "Delissaville",
        "Country": "Australia"
    },
    {
        "id": "1789",
        "AirportCode": "DLY",
        "AirportName": "Dillons Bay Airport",
        "City": "Dillons Bay",
        "Country": "Vanuatu"
    },
    {
        "id": "1790",
        "AirportCode": "DLZ",
        "AirportName": "Dalanzadgad Airport",
        "City": "Dalanzadgad",
        "Country": "Mongolia"
    },
    {
        "id": "1791",
        "AirportCode": "DMA",
        "AirportName": "Davis Monthan Air Force Base",
        "City": "Tucson",
        "Country": "USA Arizona"
    },
    {
        "id": "1792",
        "AirportCode": "DMB",
        "AirportName": "Dzhambyl Airport",
        "City": "Dzhambyl",
        "Country": "Kazakhstan"
    },
    {
        "id": "1793",
        "AirportCode": "DMD",
        "AirportName": "Doomadgee Airport",
        "City": "Doomadgee",
        "Country": "Australia"
    },
    {
        "id": "1794",
        "AirportCode": "DME",
        "AirportName": "Domodedovo Airport",
        "City": "Moscow",
        "Country": "Russia"
    },
    {
        "id": "1795",
        "AirportCode": "DMK",
        "AirportName": "Don Muang International Airport",
        "City": "Bangkok",
        "Country": "Thailand"
    },
    {
        "id": "1796",
        "AirportCode": "DMM",
        "AirportName": "King Fahad International Airport",
        "City": "Dammam",
        "Country": "Saudi Arabia"
    },
    {
        "id": "1797",
        "AirportCode": "DMN",
        "AirportName": "Deming Airport",
        "City": "Deming",
        "Country": "USA New Mexico"
    },
    {
        "id": "1798",
        "AirportCode": "DMO",
        "AirportName": "Sedalia Airport",
        "City": "Sedalia",
        "Country": "USA Missouri"
    },
    {
        "id": "1799",
        "AirportCode": "DMR",
        "AirportName": "Dhamar Airport",
        "City": "Dhamar",
        "Country": "Yemen"
    },
    {
        "id": "1800",
        "AirportCode": "DMT",
        "AirportName": "Diamantino Airport",
        "City": "Diamantino",
        "Country": "Brazil"
    },
    {
        "id": "1801",
        "AirportCode": "DMU",
        "AirportName": "Dimapur Airport",
        "City": "Dimapur",
        "Country": "India"
    },
    {
        "id": "1802",
        "AirportCode": "DNA",
        "AirportName": "Kadena Air Force Base",
        "City": "Okinawa",
        "Country": "Japan"
    },
    {
        "id": "1803",
        "AirportCode": "DNB",
        "AirportName": "Dunbar Airport",
        "City": "Dunbar",
        "Country": "Australia"
    },
    {
        "id": "1804",
        "AirportCode": "DND",
        "AirportName": "Dundee Airport",
        "City": "Dundee",
        "Country": "United Kingdom"
    },
    {
        "id": "1805",
        "AirportCode": "DNE",
        "AirportName": "Dallas North Airport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "1806",
        "AirportCode": "DNF",
        "AirportName": "Martuba Airport",
        "City": "Derna",
        "Country": "Libya"
    },
    {
        "id": "1807",
        "AirportCode": "DNG",
        "AirportName": "Doongan Airport",
        "City": "Doongan",
        "Country": "Australia"
    },
    {
        "id": "1808",
        "AirportCode": "DNH",
        "AirportName": "Dunhuang Airport",
        "City": "Dunhuang",
        "Country": "China"
    },
    {
        "id": "1809",
        "AirportCode": "DNI",
        "AirportName": "Wad Medani Airport",
        "City": "Wad Medani",
        "Country": "Sudan"
    },
    {
        "id": "1810",
        "AirportCode": "DNK",
        "AirportName": "Dnepropetrovsk Airport",
        "City": "Dnepropetrovsk",
        "Country": "Ukraine"
    },
    {
        "id": "1811",
        "AirportCode": "DNL",
        "AirportName": "Daniel Field",
        "City": "Augusta",
        "Country": "USA Georgia"
    },
    {
        "id": "1812",
        "AirportCode": "DNM",
        "AirportName": "Denham Airport",
        "City": "Denham",
        "Country": "Australia"
    },
    {
        "id": "1813",
        "AirportCode": "DNN",
        "AirportName": "Dalton Municipal Airport",
        "City": "Dalton",
        "Country": "USA Georgia"
    },
    {
        "id": "1814",
        "AirportCode": "DNO",
        "AirportName": "Dianopolis Airport",
        "City": "Dianopolis",
        "Country": "Brazil"
    },
    {
        "id": "1815",
        "AirportCode": "DNP",
        "AirportName": "Dang Airport",
        "City": "Dang",
        "Country": "Nepal"
    },
    {
        "id": "1816",
        "AirportCode": "DNQ",
        "AirportName": "Deniliquin Airport",
        "City": "Deniliquin",
        "Country": "Australia"
    },
    {
        "id": "1817",
        "AirportCode": "DNR",
        "AirportName": "Pleurtuit Airport",
        "City": "Dinard",
        "Country": "France"
    },
    {
        "id": "1818",
        "AirportCode": "DNS",
        "AirportName": "Denison Municipal Airport",
        "City": "Denison",
        "Country": "USA Iowa"
    },
    {
        "id": "1819",
        "AirportCode": "DNT",
        "AirportName": "Santa Ana Downtown Heliport",
        "City": "Santa Ana",
        "Country": "USA California"
    },
    {
        "id": "1820",
        "AirportCode": "DNU",
        "AirportName": "Dinangat Airport",
        "City": "Dinangat",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1821",
        "AirportCode": "DNV",
        "AirportName": "Vermilion County Airport",
        "City": "Danville",
        "Country": "USA Illinois"
    },
    {
        "id": "1822",
        "AirportCode": "DNX",
        "AirportName": "Galegu Airport",
        "City": "Dinder",
        "Country": "Sudan"
    },
    {
        "id": "1823",
        "AirportCode": "DNZ",
        "AirportName": "Cardak Airport",
        "City": "Denizli",
        "Country": "Turkey"
    },
    {
        "id": "1824",
        "AirportCode": "DOA",
        "AirportName": "Doany Airport",
        "City": "Doany",
        "Country": "Madagascar"
    },
    {
        "id": "1825",
        "AirportCode": "DOB",
        "AirportName": "Dobo Airport",
        "City": "Dobo",
        "Country": "Indonesia"
    },
    {
        "id": "1826",
        "AirportCode": "DOC",
        "AirportName": "Dornoch Airport",
        "City": "Dornoch",
        "Country": "United Kingdom"
    },
    {
        "id": "1827",
        "AirportCode": "DOD",
        "AirportName": "Dodoma Airport",
        "City": "Dodoma",
        "Country": "Tanzania"
    },
    {
        "id": "1828",
        "AirportCode": "DOE",
        "AirportName": "Djoemoe Airport",
        "City": "Djoemoe",
        "Country": "Suriname"
    },
    {
        "id": "1829",
        "AirportCode": "DOG",
        "AirportName": "Dongola Airport",
        "City": "Dongola",
        "Country": "Sudan"
    },
    {
        "id": "1830",
        "AirportCode": "DOH",
        "AirportName": "Doha Airport",
        "City": "Doha",
        "Country": "Qatar"
    },
    {
        "id": "1831",
        "AirportCode": "DOI",
        "AirportName": "Doini Airport",
        "City": "Doini",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1832",
        "AirportCode": "DOK",
        "AirportName": "Donetsk Airport",
        "City": "Donetsk",
        "Country": "Ukraine"
    },
    {
        "id": "1833",
        "AirportCode": "DOL",
        "AirportName": "Saint Gatien Airport",
        "City": "Deauville",
        "Country": "France"
    },
    {
        "id": "1834",
        "AirportCode": "DOM",
        "AirportName": "Melville Hall Airport",
        "City": "Dominica",
        "Country": "Dominica"
    },
    {
        "id": "1835",
        "AirportCode": "DON",
        "AirportName": "Dos Lagunas Airport",
        "City": "Dos Lagunas",
        "Country": "Guatemala"
    },
    {
        "id": "1836",
        "AirportCode": "DOO",
        "AirportName": "Dorobisoro Airport",
        "City": "Dorobisoro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1837",
        "AirportCode": "DOP",
        "AirportName": "Dolpa Airport",
        "City": "Dolpa",
        "Country": "Nepal"
    },
    {
        "id": "1838",
        "AirportCode": "DOR",
        "AirportName": "Dori Airport",
        "City": "Dori",
        "Country": "Burkina Faso"
    },
    {
        "id": "1839",
        "AirportCode": "DOS",
        "AirportName": "Dios Airport",
        "City": "Dios",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1840",
        "AirportCode": "DOU",
        "AirportName": "Dourados Airport",
        "City": "Dourados",
        "Country": "Brazil"
    },
    {
        "id": "1841",
        "AirportCode": "DOV",
        "AirportName": "Dover Air Force Base",
        "City": "Dover-Cheswold",
        "Country": "USA Delaware"
    },
    {
        "id": "1842",
        "AirportCode": "DOX",
        "AirportName": "Dongara Airport",
        "City": "Dongara",
        "Country": "Australia"
    },
    {
        "id": "1843",
        "AirportCode": "DOY",
        "AirportName": "Dongying Airport",
        "City": "Dongying",
        "Country": "China"
    },
    {
        "id": "1844",
        "AirportCode": "DPA",
        "AirportName": "Dupage County Airport",
        "City": "Chicago",
        "Country": "USA Illinois"
    },
    {
        "id": "1845",
        "AirportCode": "DPE",
        "AirportName": "Dieppe Airport",
        "City": "Dieppe",
        "Country": "France"
    },
    {
        "id": "1846",
        "AirportCode": "DPG",
        "AirportName": "Michael Army Air Field",
        "City": "Dugway",
        "Country": "USA Utah"
    },
    {
        "id": "1847",
        "AirportCode": "DPK",
        "AirportName": "Deer Park Airport",
        "City": "Deer Park",
        "Country": "USA New York"
    },
    {
        "id": "1848",
        "AirportCode": "DPL",
        "AirportName": "Dipolog Airport",
        "City": "Dipolog",
        "Country": "Philippines"
    },
    {
        "id": "1849",
        "AirportCode": "DPO",
        "AirportName": "Devonport Airport",
        "City": "Devonport",
        "Country": "Australia"
    },
    {
        "id": "1850",
        "AirportCode": "DPS",
        "AirportName": "Ngurah Rai Airport",
        "City": "Denpasar Bali",
        "Country": "Indonesia"
    },
    {
        "id": "1851",
        "AirportCode": "DPU",
        "AirportName": "Dumpu Airport",
        "City": "Dumpu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1852",
        "AirportCode": "DQA",
        "AirportName": "Daqing",
        "City": "Daqing Shi ",
        "Country": "China"
    },
    {
        "id": "1853",
        "AirportCode": "DRA",
        "AirportName": "Desert Rock Airport",
        "City": "Mercury",
        "Country": "USA Nevada"
    },
    {
        "id": "1854",
        "AirportCode": "DRB",
        "AirportName": "Derby Airport",
        "City": "Derby",
        "Country": "Australia"
    },
    {
        "id": "1855",
        "AirportCode": "DRD",
        "AirportName": "Dorunda Station Airport",
        "City": "Dorunda Station",
        "Country": "Australia"
    },
    {
        "id": "1856",
        "AirportCode": "DRE",
        "AirportName": "Drummond Island Airport",
        "City": "Drummond Island",
        "Country": "USA Michigan"
    },
    {
        "id": "1857",
        "AirportCode": "DRG",
        "AirportName": "Deering Airport",
        "City": "Deering",
        "Country": "USA Alaska"
    },
    {
        "id": "1858",
        "AirportCode": "DRH",
        "AirportName": "Dabra Airport",
        "City": "Dabra",
        "Country": "Indonesia"
    },
    {
        "id": "1859",
        "AirportCode": "DRI",
        "AirportName": "Beauregard Parish Airport",
        "City": "De Ridder",
        "Country": "USA Louisiana"
    },
    {
        "id": "1860",
        "AirportCode": "DRJ",
        "AirportName": "Drietabbetje Airport",
        "City": "Drietabbetje",
        "Country": "Suriname"
    },
    {
        "id": "1861",
        "AirportCode": "DRM",
        "AirportName": "Drama Airport",
        "City": "Drama",
        "Country": "Greece"
    },
    {
        "id": "1862",
        "AirportCode": "DRN",
        "AirportName": "Dirranbandi Airport",
        "City": "Dirranbandi",
        "Country": "Australia"
    },
    {
        "id": "1863",
        "AirportCode": "DRO",
        "AirportName": "La Plata Airport",
        "City": "Durango",
        "Country": "USA Colorado"
    },
    {
        "id": "1864",
        "AirportCode": "DRR",
        "AirportName": "Durrie Airport",
        "City": "Durrie",
        "Country": "Australia"
    },
    {
        "id": "1865",
        "AirportCode": "DRS",
        "AirportName": "Dresden Airport",
        "City": "Dresden",
        "Country": "Germany"
    },
    {
        "id": "1866",
        "AirportCode": "DRT",
        "AirportName": "Del Rio International Airport",
        "City": "Del Rio",
        "Country": "USA Texas"
    },
    {
        "id": "1867",
        "AirportCode": "DRU",
        "AirportName": "Drummond Airport",
        "City": "Drummond",
        "Country": "USA Montana"
    },
    {
        "id": "1868",
        "AirportCode": "DRW",
        "AirportName": "Darwin Airport",
        "City": "Darwin",
        "Country": "Australia"
    },
    {
        "id": "1869",
        "AirportCode": "DRY",
        "AirportName": "Drysdale River Airport",
        "City": "Drysdale River",
        "Country": "Australia"
    },
    {
        "id": "1870",
        "AirportCode": "DSA",
        "AirportName": "Doncaster Sheffield Airport",
        "City": "Doncaster",
        "Country": "United Kingdom"
    },
    {
        "id": "1871",
        "AirportCode": "DSC",
        "AirportName": "Dschang Airport",
        "City": "Dschang",
        "Country": "Cameroon"
    },
    {
        "id": "1872",
        "AirportCode": "DSD",
        "AirportName": "La Desirade Airport",
        "City": "La Desirade",
        "Country": "Guadeloupe"
    },
    {
        "id": "1873",
        "AirportCode": "DSE",
        "AirportName": "Combolcha Airport",
        "City": "Dessie",
        "Country": "Ethiopia"
    },
    {
        "id": "1874",
        "AirportCode": "DSG",
        "AirportName": "Dilasag Airport",
        "City": "Dilasag",
        "Country": "Philippines"
    },
    {
        "id": "1875",
        "AirportCode": "DSI",
        "AirportName": "Destin Airport",
        "City": "Destin",
        "Country": "USA Florida"
    },
    {
        "id": "1876",
        "AirportCode": "DSK",
        "AirportName": "Dera Ismail Khan Airport",
        "City": "Dera Ismail Khan",
        "Country": "Pakistan"
    },
    {
        "id": "1877",
        "AirportCode": "DSL",
        "AirportName": "Daru Airport",
        "City": "Daru",
        "Country": "Sierra Leone"
    },
    {
        "id": "1878",
        "AirportCode": "DSM",
        "AirportName": "Des Moines Airport",
        "City": "Des Moines",
        "Country": "USA Iowa"
    },
    {
        "id": "1879",
        "AirportCode": "DSN",
        "AirportName": "Dongsheng Airport",
        "City": "Dongsheng",
        "Country": "China"
    },
    {
        "id": "1880",
        "AirportCode": "DSV",
        "AirportName": "Dansville Airport",
        "City": "Dansville",
        "Country": "USA New York"
    },
    {
        "id": "1881",
        "AirportCode": "DTA",
        "AirportName": "Delta Airport",
        "City": "Delta",
        "Country": "USA Utah"
    },
    {
        "id": "1882",
        "AirportCode": "DTD",
        "AirportName": "Datadawai Airport",
        "City": "Datadawai",
        "Country": "Indonesia"
    },
    {
        "id": "1883",
        "AirportCode": "DTE",
        "AirportName": "Camarines Norte Airport",
        "City": "Daet",
        "Country": "Philippines"
    },
    {
        "id": "1884",
        "AirportCode": "DTH",
        "AirportName": "Death Valley Airport",
        "City": "Death Valley",
        "Country": "USA California"
    },
    {
        "id": "1885",
        "AirportCode": "DTL",
        "AirportName": "Detroit Lakes Municipal Airport",
        "City": "Detroit Lakes",
        "Country": "USA Minnesota"
    },
    {
        "id": "1886",
        "AirportCode": "DTM",
        "AirportName": "Dortmund Airport",
        "City": "Dortmund",
        "Country": "Germany"
    },
    {
        "id": "1887",
        "AirportCode": "DTN",
        "AirportName": "Shreveport Downtown Airport",
        "City": "Shreveport",
        "Country": "USA Louisiana"
    },
    {
        "id": "1888",
        "AirportCode": "DTR",
        "AirportName": "Decatur Island Airport",
        "City": "Decatur Island",
        "Country": "USA Washington"
    },
    {
        "id": "1889",
        "AirportCode": "DTW",
        "AirportName": "Wayne County Airport",
        "City": "Detroit",
        "Country": "USA Michigan"
    },
    {
        "id": "1890",
        "AirportCode": "DUA",
        "AirportName": "Eaker Airport",
        "City": "Durant",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1891",
        "AirportCode": "DUB",
        "AirportName": "Dublin Airport",
        "City": "Dublin",
        "Country": "Ireland"
    },
    {
        "id": "1892",
        "AirportCode": "DUC",
        "AirportName": "Halliburton Airport",
        "City": "Duncan",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1893",
        "AirportCode": "DUD",
        "AirportName": "Dunedin Airport",
        "City": "Dunedin",
        "Country": "New Zealand"
    },
    {
        "id": "1894",
        "AirportCode": "DUE",
        "AirportName": "Dundo Airport",
        "City": "Dundo",
        "Country": "Angola"
    },
    {
        "id": "1895",
        "AirportCode": "DUF",
        "AirportName": "Pine Island Airport",
        "City": "Duck",
        "Country": "USA North Carolina"
    },
    {
        "id": "1896",
        "AirportCode": "DUG",
        "AirportName": "Bisbee-Douglas International Airport",
        "City": "Douglas",
        "Country": "USA Arizona"
    },
    {
        "id": "1897",
        "AirportCode": "DUJ",
        "AirportName": "Jefferson County Airport",
        "City": "Dubois",
        "Country": "USA Philadelphia"
    },
    {
        "id": "1898",
        "AirportCode": "DUK",
        "AirportName": "Dukuduk Airport",
        "City": "Dukuduk",
        "Country": "South Africa"
    },
    {
        "id": "1899",
        "AirportCode": "DUM",
        "AirportName": "Pinang Kampai Airport",
        "City": "Dumai",
        "Country": "Indonesia"
    },
    {
        "id": "1900",
        "AirportCode": "DUN",
        "AirportName": "Dundas Airport",
        "City": "Dundas",
        "Country": "Greenland"
    },
    {
        "id": "1901",
        "AirportCode": "DUQ",
        "AirportName": "Duncan/Quam Airport",
        "City": "Duncan/Quam",
        "Country": "Canada"
    },
    {
        "id": "1902",
        "AirportCode": "DUR",
        "AirportName": "Louis Botha Airport",
        "City": "Durban",
        "Country": "South Africa"
    },
    {
        "id": "1903",
        "AirportCode": "DUS",
        "AirportName": "Dusseldorf Airport",
        "City": "Dusseldorf",
        "Country": "Germany"
    },
    {
        "id": "1904",
        "AirportCode": "DUT",
        "AirportName": "Emergency Field",
        "City": "Dutch Harbor",
        "Country": "USA Alaska"
    },
    {
        "id": "1905",
        "AirportCode": "DVA",
        "AirportName": "Deva Airport",
        "City": "Deva",
        "Country": "Romania"
    },
    {
        "id": "1906",
        "AirportCode": "DVK",
        "AirportName": "Diavik Airport",
        "City": "Diavik",
        "Country": "Canada"
    },
    {
        "id": "1907",
        "AirportCode": "DVL",
        "AirportName": "Devils Lake Airport",
        "City": "Devils Lake",
        "Country": "USA North Dakota"
    },
    {
        "id": "1908",
        "AirportCode": "DVN",
        "AirportName": "Davenport Airport",
        "City": "Davenport",
        "Country": "USA Iowa"
    },
    {
        "id": "1909",
        "AirportCode": "DVO",
        "AirportName": "Mati Airport",
        "City": "Davao",
        "Country": "Philippines"
    },
    {
        "id": "1910",
        "AirportCode": "DVP",
        "AirportName": "Davenport Downs Airport",
        "City": "Davenport Downs",
        "Country": "Australia"
    },
    {
        "id": "1911",
        "AirportCode": "DVR",
        "AirportName": "Daly River Airport",
        "City": "Daly River",
        "Country": "Australia"
    },
    {
        "id": "1912",
        "AirportCode": "DVT",
        "AirportName": "Phoenix-Deer Valley Airport",
        "City": "Phoenix",
        "Country": "USA Arizona"
    },
    {
        "id": "1913",
        "AirportCode": "DWA",
        "AirportName": "Dwamawa Airport",
        "City": "Dwamawa",
        "Country": "Malawi"
    },
    {
        "id": "1914",
        "AirportCode": "DWB",
        "AirportName": "Soalala Airport",
        "City": "Soalala",
        "Country": "Madagascar"
    },
    {
        "id": "1915",
        "AirportCode": "DWD",
        "AirportName": "Dawadmi Airport",
        "City": "Dawadmi",
        "Country": "Saudi Arabia"
    },
    {
        "id": "1916",
        "AirportCode": "DWF",
        "AirportName": "Wright Air Force Base",
        "City": "Dayton",
        "Country": "USA Ohio"
    },
    {
        "id": "1917",
        "AirportCode": "DWH",
        "AirportName": "David Wayne Hooks Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "1918",
        "AirportCode": "DWN",
        "AirportName": "Oklahoma City Downtown Airpark",
        "City": "Oklahoma City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "1919",
        "AirportCode": "DWS",
        "AirportName": "Walt Disney World Airport",
        "City": "Orlando",
        "Country": "USA Florida"
    },
    {
        "id": "1920",
        "AirportCode": "DXA",
        "AirportName": "Deux Alpes Airport",
        "City": "Deux Alpes",
        "Country": "France"
    },
    {
        "id": "1921",
        "AirportCode": "DXB",
        "AirportName": "Dubai International Airport",
        "City": "Dubai",
        "Country": "UAE"
    },
    {
        "id": "1922",
        "AirportCode": "DXD",
        "AirportName": "Dixie Airport",
        "City": "Dixie",
        "Country": "Australia"
    },
    {
        "id": "1923",
        "AirportCode": "DXR",
        "AirportName": "Danbury Airport",
        "City": "Danbury",
        "Country": "USA Connecticut"
    },
    {
        "id": "1924",
        "AirportCode": "DYA",
        "AirportName": "Dysart Airport",
        "City": "Dysart",
        "Country": "Australia"
    },
    {
        "id": "1925",
        "AirportCode": "DYG",
        "AirportName": "Dayong Airport",
        "City": "Dayong",
        "Country": "China"
    },
    {
        "id": "1926",
        "AirportCode": "DYL",
        "AirportName": "Doylestown Airport",
        "City": "Doylestown",
        "Country": "USA Philadelphia"
    },
    {
        "id": "1927",
        "AirportCode": "DYM",
        "AirportName": "Diamantina Lakes Airport",
        "City": "Diamantina Lakes",
        "Country": "Australia"
    },
    {
        "id": "1928",
        "AirportCode": "DYR",
        "AirportName": "Anadyr Airport",
        "City": "Anadyr",
        "Country": "Russia"
    },
    {
        "id": "1929",
        "AirportCode": "DYS",
        "AirportName": "Dyess Air Force Base",
        "City": "Abilene",
        "Country": "USA Texas"
    },
    {
        "id": "1930",
        "AirportCode": "DYU",
        "AirportName": "Dushanbe Airport",
        "City": "Dushanbe",
        "Country": "Tajikistan"
    },
    {
        "id": "1931",
        "AirportCode": "DYW",
        "AirportName": "Daly Waters Airport",
        "City": "Daly Waters",
        "Country": "Australia"
    },
    {
        "id": "1932",
        "AirportCode": "DZA",
        "AirportName": "Dzaoudzi Airport",
        "City": "Dzaoudzi",
        "Country": "Mayotte"
    },
    {
        "id": "1933",
        "AirportCode": "DZI",
        "AirportName": "Codazzi Airport",
        "City": "Codazzi",
        "Country": "Colombia"
    },
    {
        "id": "1934",
        "AirportCode": "DZN",
        "AirportName": "Zhezhazgan Airport",
        "City": "Zhezkazgan",
        "Country": "Kazakhstan"
    },
    {
        "id": "1935",
        "AirportCode": "DZO",
        "AirportName": "Durazno Airport",
        "City": "Durazno",
        "Country": "Uruguay"
    },
    {
        "id": "1936",
        "AirportCode": "DZU",
        "AirportName": "Dazu Airport",
        "City": "Dazu",
        "Country": "China"
    },
    {
        "id": "1937",
        "AirportCode": "EAA",
        "AirportName": "Eagle Airport",
        "City": "Eagle",
        "Country": "USA Alaska"
    },
    {
        "id": "1938",
        "AirportCode": "EAB",
        "AirportName": "Abbse Airport",
        "City": "Abbse",
        "Country": "Yemen"
    },
    {
        "id": "1939",
        "AirportCode": "EAE",
        "AirportName": "Emae Airport",
        "City": "Emae",
        "Country": "Vanuatu"
    },
    {
        "id": "1940",
        "AirportCode": "EAL",
        "AirportName": "Elenak Airport",
        "City": "Kwajalein Atoll",
        "Country": "Marshall Islands"
    },
    {
        "id": "1941",
        "AirportCode": "EAM",
        "AirportName": "Nejran Airport",
        "City": "Nejran",
        "Country": "Saudi Arabia"
    },
    {
        "id": "1942",
        "AirportCode": "EAN",
        "AirportName": "Phifer Field",
        "City": "Wheatland",
        "Country": "USA Wyoming"
    },
    {
        "id": "1943",
        "AirportCode": "EAR",
        "AirportName": "Kearney Airport",
        "City": "Kearney",
        "Country": "USA Nebraska"
    },
    {
        "id": "1944",
        "AirportCode": "EAS",
        "AirportName": "San Sebastian Airport",
        "City": "San Sebastian",
        "Country": "Spain"
    },
    {
        "id": "1945",
        "AirportCode": "EAT",
        "AirportName": "Pangborn Field",
        "City": "Wenatchee",
        "Country": "USA Washington"
    },
    {
        "id": "1946",
        "AirportCode": "EAU",
        "AirportName": "Eau Claire Airport",
        "City": "Eau Claire",
        "Country": "USA Wisconsin"
    },
    {
        "id": "1947",
        "AirportCode": "EBA",
        "AirportName": "Marina Di Campo Airport",
        "City": "Elba Island",
        "Country": "Italy"
    },
    {
        "id": "1948",
        "AirportCode": "EBB",
        "AirportName": "Entebbe Airport",
        "City": "Entebbe",
        "Country": "Uganda"
    },
    {
        "id": "1949",
        "AirportCode": "EBD",
        "AirportName": "El Obeid Airport",
        "City": "El Obeid",
        "Country": "Sudan"
    },
    {
        "id": "1950",
        "AirportCode": "EBG",
        "AirportName": "El Bagre Airport",
        "City": "El Bagre",
        "Country": "Colombia"
    },
    {
        "id": "1951",
        "AirportCode": "EBJ",
        "AirportName": "Esbjerg Airport",
        "City": "Esbjerg",
        "Country": "Denmark"
    },
    {
        "id": "1952",
        "AirportCode": "EBM",
        "AirportName": "El Borma Airport",
        "City": "El Borma",
        "Country": "Tunisia"
    },
    {
        "id": "1953",
        "AirportCode": "EBN",
        "AirportName": "Ebadon Airport",
        "City": "Ebadon",
        "Country": "Marshall Islands"
    },
    {
        "id": "1954",
        "AirportCode": "EBR",
        "AirportName": "Baton Rouge Downtown Airport",
        "City": "Baton Rouge",
        "Country": "USA Louisiana"
    },
    {
        "id": "1955",
        "AirportCode": "EBS",
        "AirportName": "Webster City Municipal Airport",
        "City": "Webster City",
        "Country": "USA Iowa"
    },
    {
        "id": "1956",
        "AirportCode": "EBU",
        "AirportName": "Boutheon Airport",
        "City": "Saint Etienne",
        "Country": "France"
    },
    {
        "id": "1957",
        "AirportCode": "EBW",
        "AirportName": "Ebolowa Airport",
        "City": "Ebolowa",
        "Country": "Cameroon"
    },
    {
        "id": "1958",
        "AirportCode": "ECA",
        "AirportName": "Emmet County Airport",
        "City": "East Tawas",
        "Country": "USA Michigan"
    },
    {
        "id": "1959",
        "AirportCode": "ECG",
        "AirportName": "Elizabeth City Airport",
        "City": "Elizabeth City",
        "Country": "USA North Carolina"
    },
    {
        "id": "1960",
        "AirportCode": "ECH",
        "AirportName": "Echuca Airport",
        "City": "Echuca",
        "Country": "Australia"
    },
    {
        "id": "1961",
        "AirportCode": "ECN",
        "AirportName": "Ercan Airport",
        "City": "Ercan",
        "Country": "Cyprus"
    },
    {
        "id": "1962",
        "AirportCode": "ECO",
        "AirportName": "El Encanto Airport",
        "City": "El Encanto",
        "Country": "Colombia"
    },
    {
        "id": "1963",
        "AirportCode": "ECR",
        "AirportName": "El Charco Airport",
        "City": "El Charco",
        "Country": "Colombia"
    },
    {
        "id": "1964",
        "AirportCode": "ECS",
        "AirportName": "Mondell Airport",
        "City": "Newcastle",
        "Country": "USA Wyoming"
    },
    {
        "id": "1965",
        "AirportCode": "EDA",
        "AirportName": "Edna Bay Airport",
        "City": "Edna Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "1966",
        "AirportCode": "EDB",
        "AirportName": "Eldebba Airport",
        "City": "Eldebba",
        "Country": "Sudan"
    },
    {
        "id": "1967",
        "AirportCode": "EDD",
        "AirportName": "Erlunda Airport",
        "City": "Erldunda",
        "Country": "Australia"
    },
    {
        "id": "1968",
        "AirportCode": "EDE",
        "AirportName": "Edenton Municipal Airport",
        "City": "Edenton",
        "Country": "USA North Carolina"
    },
    {
        "id": "1969",
        "AirportCode": "EDF",
        "AirportName": "Elmendorf Air Force Base",
        "City": "Anchorage",
        "Country": "USA Alaska"
    },
    {
        "id": "1970",
        "AirportCode": "EDG",
        "AirportName": "Weide Army Air Field",
        "City": "Edgewood Arsenal",
        "Country": "USA Maryland"
    },
    {
        "id": "1971",
        "AirportCode": "EDI",
        "AirportName": "Turnhouse Airport",
        "City": "Edinburgh",
        "Country": "United Kingdom"
    },
    {
        "id": "1972",
        "AirportCode": "EDK",
        "AirportName": "El Dorado Airport",
        "City": "El Dorado",
        "Country": "USA Kansas"
    },
    {
        "id": "1973",
        "AirportCode": "EDL",
        "AirportName": "Eldoret Airport",
        "City": "Eldoret",
        "Country": "Kenya"
    },
    {
        "id": "1974",
        "AirportCode": "EDM",
        "AirportName": "Les Ajoncs Airport",
        "City": "La Roche",
        "Country": "France"
    },
    {
        "id": "1975",
        "AirportCode": "EDO",
        "AirportName": "Edremit/Korfez Airport",
        "City": "Edremit/Korfez",
        "Country": "Turkey"
    },
    {
        "id": "1976",
        "AirportCode": "EDQ",
        "AirportName": "Erandique Airport",
        "City": "Erandique",
        "Country": "Honduras"
    },
    {
        "id": "1977",
        "AirportCode": "EDR",
        "AirportName": "Edward River Airport",
        "City": "Edward River",
        "Country": "Australia"
    },
    {
        "id": "1978",
        "AirportCode": "EDW",
        "AirportName": "Edwards Air Force Base",
        "City": "Edwards Air Force Base",
        "Country": "USA California"
    },
    {
        "id": "1979",
        "AirportCode": "EED",
        "AirportName": "Needles Airport",
        "City": "Needles",
        "Country": "USA California"
    },
    {
        "id": "1980",
        "AirportCode": "EEK",
        "AirportName": "Eek Airport",
        "City": "Eek",
        "Country": "USA Alaska"
    },
    {
        "id": "1981",
        "AirportCode": "EEN",
        "AirportName": "Dillant-Hopkins Airport",
        "City": "Keene",
        "Country": "USA New Hampshire"
    },
    {
        "id": "1982",
        "AirportCode": "EFD",
        "AirportName": "Ellington Field",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "1983",
        "AirportCode": "EFG",
        "AirportName": "Efogi Airport",
        "City": "Efogi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1984",
        "AirportCode": "EFK",
        "AirportName": "Newport Airport",
        "City": "Newport",
        "Country": "USA Vermont"
    },
    {
        "id": "1985",
        "AirportCode": "EFL",
        "AirportName": "Argostolion Airport",
        "City": "Kefallinia",
        "Country": "Greece"
    },
    {
        "id": "1986",
        "AirportCode": "EFW",
        "AirportName": "Jefferson Municipal Airport",
        "City": "Jefferson",
        "Country": "USA Iowa"
    },
    {
        "id": "1987",
        "AirportCode": "EGA",
        "AirportName": "Engati Airport",
        "City": "Engati",
        "Country": "Papua New Guinea"
    },
    {
        "id": "1988",
        "AirportCode": "EGC",
        "AirportName": "Roumanieres Airport",
        "City": "Bergerac",
        "Country": "France"
    },
    {
        "id": "1989",
        "AirportCode": "EGE",
        "AirportName": "Eagle County Airport",
        "City": "Vail",
        "Country": "USA Colorado"
    },
    {
        "id": "1990",
        "AirportCode": "EGI",
        "AirportName": "Duke Field",
        "City": "Valparaiso",
        "Country": "USA Florida"
    },
    {
        "id": "1991",
        "AirportCode": "EGL",
        "AirportName": "Neghelli Airport",
        "City": "Neghelli",
        "Country": "Ethiopia"
    },
    {
        "id": "1992",
        "AirportCode": "EGM",
        "AirportName": "Sege Airport",
        "City": "Sege",
        "Country": "Solomon Islands"
    },
    {
        "id": "1993",
        "AirportCode": "EGN",
        "AirportName": "Geneina Airport",
        "City": "Geneina",
        "Country": "Sudan"
    },
    {
        "id": "1994",
        "AirportCode": "EGO",
        "AirportName": "Belgorod Airport",
        "City": "Belgorod",
        "Country": "Russia"
    },
    {
        "id": "1995",
        "AirportCode": "EGP",
        "AirportName": "Maverick County Airport",
        "City": "Eagle Pass",
        "Country": "USA Texas"
    },
    {
        "id": "1996",
        "AirportCode": "EGS",
        "AirportName": "Egilsstadir Airport",
        "City": "Egilsstadir",
        "Country": "Iceland"
    },
    {
        "id": "1997",
        "AirportCode": "EGV",
        "AirportName": "Eagle River Airport",
        "City": "Eagle River",
        "Country": "USA Wisconsin"
    },
    {
        "id": "1998",
        "AirportCode": "EGX",
        "AirportName": "Egegik Airport",
        "City": "Egegik",
        "Country": "USA Alaska"
    },
    {
        "id": "1999",
        "AirportCode": "EHL",
        "AirportName": "El Bolson Airport",
        "City": "El Bolson",
        "Country": "Argentina"
    },
    {
        "id": "2000",
        "AirportCode": "EHM",
        "AirportName": "Cape Newenham Airport",
        "City": "Cape Newenham",
        "Country": "USA Alaska"
    },
    {
        "id": "2001",
        "AirportCode": "EHT",
        "AirportName": "Rentschler Airport",
        "City": "East Hartford",
        "Country": "USA Connecticut"
    },
    {
        "id": "2002",
        "AirportCode": "EIA",
        "AirportName": "Popondetta Airport",
        "City": "Eia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2003",
        "AirportCode": "EIB",
        "AirportName": "Eisenach Airport",
        "City": "Eisenach",
        "Country": "Germany"
    },
    {
        "id": "2004",
        "AirportCode": "EIE",
        "AirportName": "Eniseysk Airport",
        "City": "Eniseysk",
        "Country": "Russia"
    },
    {
        "id": "2005",
        "AirportCode": "EIH",
        "AirportName": "Einasleigh Airport",
        "City": "Einasleigh",
        "Country": "Australia"
    },
    {
        "id": "2006",
        "AirportCode": "EIN",
        "AirportName": "Eindhoven Airport",
        "City": "Eindhoven",
        "Country": "Netherlands"
    },
    {
        "id": "2007",
        "AirportCode": "EIS",
        "AirportName": "Island Airport",
        "City": "Beef Island",
        "Country": "British Virgin Islands"
    },
    {
        "id": "2008",
        "AirportCode": "EJA",
        "AirportName": "Variguies Airport",
        "City": "Barrancabermeja",
        "Country": "Colombia"
    },
    {
        "id": "2009",
        "AirportCode": "EJH",
        "AirportName": "Wedjh Airport",
        "City": "Wedjh",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2010",
        "AirportCode": "EJT",
        "AirportName": "Enijet Airport",
        "City": "Mili Atoll",
        "Country": "Marshall Islands"
    },
    {
        "id": "2011",
        "AirportCode": "EKA",
        "AirportName": "Murray Field",
        "City": "Eureka",
        "Country": "USA California"
    },
    {
        "id": "2012",
        "AirportCode": "EKB",
        "AirportName": "Ekibastuz Airport",
        "City": "Ekibastuz",
        "Country": "Kazakhstan"
    },
    {
        "id": "2013",
        "AirportCode": "EKD",
        "AirportName": "Elkedra Airport",
        "City": "Elkedra",
        "Country": "Australia"
    },
    {
        "id": "2014",
        "AirportCode": "EKE",
        "AirportName": "Ekereku Airport",
        "City": "Ekereku",
        "Country": "Guyana"
    },
    {
        "id": "2015",
        "AirportCode": "EKI",
        "AirportName": "Elkhart Municipal Airport",
        "City": "Elkhart",
        "Country": "USA Indiana"
    },
    {
        "id": "2016",
        "AirportCode": "EKN",
        "AirportName": "Elkins Airport",
        "City": "Elkins",
        "Country": "USA West Virginia"
    },
    {
        "id": "2017",
        "AirportCode": "EKO",
        "AirportName": "Elko Airport",
        "City": "Elko",
        "Country": "USA Nevada"
    },
    {
        "id": "2018",
        "AirportCode": "EKT",
        "AirportName": "Eskilstuna Airport",
        "City": "Eskilstuna",
        "Country": "Sweden"
    },
    {
        "id": "2019",
        "AirportCode": "EKX",
        "AirportName": "Elizabethtown Airport",
        "City": "Elizabethtown",
        "Country": "USA Kentucky"
    },
    {
        "id": "2020",
        "AirportCode": "ELA",
        "AirportName": "Eagle Lake Airport",
        "City": "Eagle Lake",
        "Country": "USA Texas"
    },
    {
        "id": "2021",
        "AirportCode": "ELB",
        "AirportName": "San Bernado Airport",
        "City": "El Banco",
        "Country": "Colombia"
    },
    {
        "id": "2022",
        "AirportCode": "ELC",
        "AirportName": "Elcho Island Airport",
        "City": "Elcho Island",
        "Country": "Australia"
    },
    {
        "id": "2023",
        "AirportCode": "ELD",
        "AirportName": "Goodwin Field",
        "City": "El Dorado",
        "Country": "USA Arkansas"
    },
    {
        "id": "2024",
        "AirportCode": "ELE",
        "AirportName": "El Real Airport",
        "City": "El Real",
        "Country": "Panama"
    },
    {
        "id": "2025",
        "AirportCode": "ELF",
        "AirportName": "El Fasher Airport",
        "City": "El Fasher",
        "Country": "Sudan"
    },
    {
        "id": "2026",
        "AirportCode": "ELG",
        "AirportName": "El Golea Airport",
        "City": "El Golea",
        "Country": "Algeria"
    },
    {
        "id": "2027",
        "AirportCode": "ELH",
        "AirportName": "North Eleuthera International Airport",
        "City": "North Eleuthera",
        "Country": "Bahamas"
    },
    {
        "id": "2028",
        "AirportCode": "ELI",
        "AirportName": "Elim Airport",
        "City": "Elim",
        "Country": "USA Alaska"
    },
    {
        "id": "2029",
        "AirportCode": "ELJ",
        "AirportName": "El Recreo Airport",
        "City": "El Recreo",
        "Country": "Colombia"
    },
    {
        "id": "2030",
        "AirportCode": "ELK",
        "AirportName": "Elk City Municipal Airport",
        "City": "Elk City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2031",
        "AirportCode": "ELL",
        "AirportName": "Ellisras Airport",
        "City": "Ellisras",
        "Country": "South Africa"
    },
    {
        "id": "2032",
        "AirportCode": "ELM",
        "AirportName": "Elmira Regional Airport",
        "City": "Elmira",
        "Country": "USA New York"
    },
    {
        "id": "2033",
        "AirportCode": "ELN",
        "AirportName": "Bowers Field",
        "City": "Ellensburg",
        "Country": "USA Washington"
    },
    {
        "id": "2034",
        "AirportCode": "ELO",
        "AirportName": "Eldorado Airport",
        "City": "Eldorado",
        "Country": "Argentina"
    },
    {
        "id": "2035",
        "AirportCode": "ELP",
        "AirportName": "El Paso International Airport",
        "City": "El Paso",
        "Country": "USA Texas"
    },
    {
        "id": "2036",
        "AirportCode": "ELQ",
        "AirportName": "Gassim Airport",
        "City": "Gassim",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2037",
        "AirportCode": "ELR",
        "AirportName": "Elelim, Irian Jaya Airport",
        "City": "Elelim, Irian Jaya",
        "Country": "Indonesia"
    },
    {
        "id": "2038",
        "AirportCode": "ELS",
        "AirportName": "East London Airport",
        "City": "East London",
        "Country": "South Africa"
    },
    {
        "id": "2039",
        "AirportCode": "ELT",
        "AirportName": "Tour Sinai City Airport",
        "City": "Tour Sinai City",
        "Country": "Egypt"
    },
    {
        "id": "2040",
        "AirportCode": "ELU",
        "AirportName": "Guemar Airport",
        "City": "El Oued",
        "Country": "Algeria"
    },
    {
        "id": "2041",
        "AirportCode": "ELV",
        "AirportName": "Elfin Cove Sea Plane Base",
        "City": "Elfin Cove",
        "Country": "USA Alaska"
    },
    {
        "id": "2042",
        "AirportCode": "ELX",
        "AirportName": "El Tigre Airport",
        "City": "El Tigre",
        "Country": "Venezuela"
    },
    {
        "id": "2043",
        "AirportCode": "ELY",
        "AirportName": "Yelland Airport",
        "City": "Ely",
        "Country": "USA Nevada"
    },
    {
        "id": "2044",
        "AirportCode": "ELZ",
        "AirportName": "Wellsville Municipal Airport",
        "City": "Wellsville",
        "Country": "USA New York"
    },
    {
        "id": "2045",
        "AirportCode": "EMA",
        "AirportName": "East Midlands Airport",
        "City": "Leicestershire",
        "Country": "United Kingdom"
    },
    {
        "id": "2046",
        "AirportCode": "EMB",
        "AirportName": "Embarkadero Airport",
        "City": "San Francisco",
        "Country": "USA California"
    },
    {
        "id": "2047",
        "AirportCode": "EMD",
        "AirportName": "Emerald Airport",
        "City": "Emerald",
        "Country": "Australia"
    },
    {
        "id": "2048",
        "AirportCode": "EME",
        "AirportName": "Emden Airport",
        "City": "Emden",
        "Country": "Germany"
    },
    {
        "id": "2049",
        "AirportCode": "EMG",
        "AirportName": "Empangeni Airport",
        "City": "Empangeni",
        "Country": "South Africa"
    },
    {
        "id": "2050",
        "AirportCode": "EMI",
        "AirportName": "Emirau Airport",
        "City": "Emirau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2051",
        "AirportCode": "EMK",
        "AirportName": "Emmonak Airport",
        "City": "Emmonak",
        "Country": "USA Alaska"
    },
    {
        "id": "2052",
        "AirportCode": "EML",
        "AirportName": "Emmen Airport",
        "City": "Emmen",
        "Country": "Switzerland"
    },
    {
        "id": "2053",
        "AirportCode": "EMM",
        "AirportName": "Kemmerer Airport",
        "City": "Kemmerer",
        "Country": "USA Wyoming"
    },
    {
        "id": "2054",
        "AirportCode": "EMN",
        "AirportName": "Nema Airport",
        "City": "Nema",
        "Country": "Mauritania"
    },
    {
        "id": "2055",
        "AirportCode": "EMO",
        "AirportName": "Emo Airport",
        "City": "Emo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2056",
        "AirportCode": "EMP",
        "AirportName": "Emporia Airport",
        "City": "Emporia",
        "Country": "USA Kansas"
    },
    {
        "id": "2057",
        "AirportCode": "EMS",
        "AirportName": "Embessa Airport",
        "City": "Embessa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2058",
        "AirportCode": "EMT",
        "AirportName": "El Monte Airport",
        "City": "El Monte",
        "Country": "USA California"
    },
    {
        "id": "2059",
        "AirportCode": "EMX",
        "AirportName": "El Maiten Airport",
        "City": "El Maiten",
        "Country": "Argentina"
    },
    {
        "id": "2060",
        "AirportCode": "EMY",
        "AirportName": "El Minya Airport",
        "City": "El Minya",
        "Country": "Egypt"
    },
    {
        "id": "2061",
        "AirportCode": "ENA",
        "AirportName": "Kenai Airport",
        "City": "Kenai",
        "Country": "USA Alaska"
    },
    {
        "id": "2062",
        "AirportCode": "ENB",
        "AirportName": "Eneabba Airport",
        "City": "Eneabba",
        "Country": "Australia"
    },
    {
        "id": "2063",
        "AirportCode": "ENC",
        "AirportName": "Essey Airport",
        "City": "Nancy",
        "Country": "France"
    },
    {
        "id": "2064",
        "AirportCode": "END",
        "AirportName": "Vance Air Force Base",
        "City": "Enid",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2065",
        "AirportCode": "ENE",
        "AirportName": "Ende Airport",
        "City": "Ende",
        "Country": "Indonesia"
    },
    {
        "id": "2066",
        "AirportCode": "ENF",
        "AirportName": "Enontekio Airport",
        "City": "Enontekio",
        "Country": "Finland"
    },
    {
        "id": "2067",
        "AirportCode": "ENH",
        "AirportName": "Enshi Airport",
        "City": "Enshi",
        "Country": "China"
    },
    {
        "id": "2068",
        "AirportCode": "ENI",
        "AirportName": "El Nido Airport",
        "City": "El Nido",
        "Country": "Philippines"
    },
    {
        "id": "2069",
        "AirportCode": "ENJ",
        "AirportName": "El Naranjo Airport",
        "City": "El Naranjo",
        "Country": "Guatemala"
    },
    {
        "id": "2070",
        "AirportCode": "ENK",
        "AirportName": "Saint Angelo Airport",
        "City": "Enniskillen",
        "Country": "United Kingdom"
    },
    {
        "id": "2071",
        "AirportCode": "ENL",
        "AirportName": "Centralia Municipal Airport",
        "City": "Centralia",
        "Country": "USA Illinois"
    },
    {
        "id": "2072",
        "AirportCode": "ENO",
        "AirportName": "Teniente Prim Alarcon Airport",
        "City": "Encarnacion",
        "Country": "Paraguay"
    },
    {
        "id": "2073",
        "AirportCode": "ENQ",
        "AirportName": "Coronel Enrique Soto Cano AB Airport",
        "City": "Coronel Enrique Soto Cano AB",
        "Country": "Honduras"
    },
    {
        "id": "2074",
        "AirportCode": "ENS",
        "AirportName": "Twente Airport",
        "City": "Enschede",
        "Country": "Netherlands"
    },
    {
        "id": "2075",
        "AirportCode": "ENU",
        "AirportName": "Enugu Airport",
        "City": "Enugu",
        "Country": "Nigeria"
    },
    {
        "id": "2076",
        "AirportCode": "ENV",
        "AirportName": "Wendover Airport",
        "City": "Wendover",
        "Country": "USA Utah"
    },
    {
        "id": "2077",
        "AirportCode": "ENW",
        "AirportName": "Kenosha Municipal Airport",
        "City": "Kenosha",
        "Country": "USA Wisconsin"
    },
    {
        "id": "2078",
        "AirportCode": "EOH",
        "AirportName": "Enrique Olaya Herrera Airport",
        "City": "Medellin",
        "Country": "Colombia"
    },
    {
        "id": "2079",
        "AirportCode": "EOI",
        "AirportName": "Eday Airport",
        "City": "Eday",
        "Country": "United Kingdom"
    },
    {
        "id": "2080",
        "AirportCode": "EOK",
        "AirportName": "Keokuk Airport",
        "City": "Keokuk",
        "Country": "USA Iowa"
    },
    {
        "id": "2081",
        "AirportCode": "EOR",
        "AirportName": "El Dorado Airporr",
        "City": "El Dorado",
        "Country": "Venezuela"
    },
    {
        "id": "2082",
        "AirportCode": "EOS",
        "AirportName": "Neosho Airport",
        "City": "Neosho",
        "Country": "USA Missouri"
    },
    {
        "id": "2083",
        "AirportCode": "EOZ",
        "AirportName": "Elorza Airport",
        "City": "Elorza",
        "Country": "Venezuela"
    },
    {
        "id": "2084",
        "AirportCode": "EPA",
        "AirportName": "El Palomar Airport",
        "City": "El Palomar",
        "Country": "Argentina"
    },
    {
        "id": "2085",
        "AirportCode": "EPG",
        "AirportName": "Browns Airport",
        "City": "Weeping Water",
        "Country": "USA Nebraska"
    },
    {
        "id": "2086",
        "AirportCode": "EPH",
        "AirportName": "Ephrata Airport",
        "City": "Ephrata",
        "Country": "USA Washington"
    },
    {
        "id": "2087",
        "AirportCode": "EPI",
        "AirportName": "Epi Airport",
        "City": "Epi",
        "Country": "Vanuatu"
    },
    {
        "id": "2088",
        "AirportCode": "EPK",
        "AirportName": "Episkopi Airport",
        "City": "Episkopi",
        "Country": "Cyprus"
    },
    {
        "id": "2089",
        "AirportCode": "EPL",
        "AirportName": "Mirecourt Airport",
        "City": "Epinal",
        "Country": "France"
    },
    {
        "id": "2090",
        "AirportCode": "EPN",
        "AirportName": "Epena Airport",
        "City": "Epena",
        "Country": "Congo"
    },
    {
        "id": "2091",
        "AirportCode": "EPR",
        "AirportName": "Esperance Airport",
        "City": "Esperance",
        "Country": "Australia"
    },
    {
        "id": "2092",
        "AirportCode": "EPS",
        "AirportName": "El Portillo Airport",
        "City": "El Portillo/Samana",
        "Country": "Dominican Republic"
    },
    {
        "id": "2093",
        "AirportCode": "EPT",
        "AirportName": "Eliptamin Airport",
        "City": "Eliptamin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2094",
        "AirportCode": "EPU",
        "AirportName": "Parnu Airport",
        "City": "Parnu",
        "Country": "Estonia"
    },
    {
        "id": "2095",
        "AirportCode": "EQS",
        "AirportName": "Esquel Airport",
        "City": "Esquel",
        "Country": "Argentina"
    },
    {
        "id": "2096",
        "AirportCode": "ERA",
        "AirportName": "Erigavo Airport",
        "City": "Erigavo",
        "Country": "Somalia"
    },
    {
        "id": "2097",
        "AirportCode": "ERB",
        "AirportName": "Ernabella Airport",
        "City": "Ernabella",
        "Country": "Australia"
    },
    {
        "id": "2098",
        "AirportCode": "ERC",
        "AirportName": "Erzincan Airport",
        "City": "Erzincan",
        "Country": "Turkey"
    },
    {
        "id": "2099",
        "AirportCode": "ERD",
        "AirportName": "Berdyansk Airport",
        "City": "Berdyansk",
        "Country": "Ukraine"
    },
    {
        "id": "2100",
        "AirportCode": "ERE",
        "AirportName": "Erave Airport",
        "City": "Erave",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2101",
        "AirportCode": "ERF",
        "AirportName": "Erfurt Airport",
        "City": "Erfurt",
        "Country": "Germany"
    },
    {
        "id": "2102",
        "AirportCode": "ERH",
        "AirportName": "Errachidia Airport",
        "City": "Errachidia",
        "Country": "Morocco"
    },
    {
        "id": "2103",
        "AirportCode": "ERI",
        "AirportName": "Erie International Airport",
        "City": "Erie",
        "Country": "USA Philadelphia"
    },
    {
        "id": "2104",
        "AirportCode": "ERM",
        "AirportName": "Comandante Kraemer Airport",
        "City": "Erechim",
        "Country": "Brazil"
    },
    {
        "id": "2105",
        "AirportCode": "ERN",
        "AirportName": "Eirunepe Airport",
        "City": "Eirunepe",
        "Country": "Brazil"
    },
    {
        "id": "2106",
        "AirportCode": "ERR",
        "AirportName": "Errol Airport",
        "City": "Errol",
        "Country": "USA New Hampshire"
    },
    {
        "id": "2107",
        "AirportCode": "ERS",
        "AirportName": "Eros Airport",
        "City": "Windhoek",
        "Country": "Namibia"
    },
    {
        "id": "2108",
        "AirportCode": "ERT",
        "AirportName": "Erdenet Airport",
        "City": "Erdenet",
        "Country": "Mongolia"
    },
    {
        "id": "2109",
        "AirportCode": "ERU",
        "AirportName": "Erume Airport",
        "City": "Erume",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2110",
        "AirportCode": "ERV",
        "AirportName": "Kerrville Airport",
        "City": "Kerrville",
        "Country": "USA Texas"
    },
    {
        "id": "2111",
        "AirportCode": "ERZ",
        "AirportName": "Erzurum Airport",
        "City": "Erzurum",
        "Country": "Turkey"
    },
    {
        "id": "2112",
        "AirportCode": "ESB",
        "AirportName": "Esenboga Airport",
        "City": "Ankara",
        "Country": "Turkey"
    },
    {
        "id": "2113",
        "AirportCode": "ESC",
        "AirportName": "Delta County Airport",
        "City": "Escanaba",
        "Country": "USA Michigan"
    },
    {
        "id": "2114",
        "AirportCode": "ESD",
        "AirportName": "Orcas Island Airport",
        "City": "Eastsound",
        "Country": "USA Washington"
    },
    {
        "id": "2115",
        "AirportCode": "ESE",
        "AirportName": "Ensenada Airport",
        "City": "Ensenada",
        "Country": "Mexico"
    },
    {
        "id": "2116",
        "AirportCode": "ESF",
        "AirportName": "Esler Field",
        "City": "Alexandria",
        "Country": "USA Louisiana"
    },
    {
        "id": "2117",
        "AirportCode": "ESG",
        "AirportName": "Mariscal Estigarribia Airport",
        "City": "Mariscal Estigarribia",
        "Country": "Paraguay"
    },
    {
        "id": "2118",
        "AirportCode": "ESH",
        "AirportName": "Shoreham Airport",
        "City": "Shoreham By Sea",
        "Country": "United Kingdom"
    },
    {
        "id": "2119",
        "AirportCode": "ESI",
        "AirportName": "Espinosa Airport",
        "City": "Espinosa",
        "Country": "Brazil"
    },
    {
        "id": "2120",
        "AirportCode": "ESK",
        "AirportName": "Eskisehir Airport",
        "City": "Eskisehir",
        "Country": "Turkey"
    },
    {
        "id": "2121",
        "AirportCode": "ESL",
        "AirportName": "Elista Airport",
        "City": "Elista",
        "Country": "Russia"
    },
    {
        "id": "2122",
        "AirportCode": "ESM",
        "AirportName": "Esmeraldas Airport",
        "City": "Esmeraldas",
        "Country": "Ecuador"
    },
    {
        "id": "2123",
        "AirportCode": "ESN",
        "AirportName": "Easton Airport",
        "City": "Easton",
        "Country": "USA Maryland"
    },
    {
        "id": "2124",
        "AirportCode": "ESO",
        "AirportName": "Espanola Airport",
        "City": "Espanola",
        "Country": "USA New Mexico"
    },
    {
        "id": "2125",
        "AirportCode": "ESP",
        "AirportName": "Birchwood-Pocono Airport",
        "City": "East Stroudsburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "2126",
        "AirportCode": "ESR",
        "AirportName": "El Salvador Airport",
        "City": "El Salvador",
        "Country": "Chile"
    },
    {
        "id": "2127",
        "AirportCode": "ESS",
        "AirportName": "Essen Airport",
        "City": "Essen",
        "Country": "Germany"
    },
    {
        "id": "2128",
        "AirportCode": "EST",
        "AirportName": "Estherville Municipal Airport",
        "City": "Estherville",
        "Country": "USA Iowa"
    },
    {
        "id": "2129",
        "AirportCode": "ESU",
        "AirportName": "Essaouira Airport",
        "City": "Essaouira",
        "Country": "Morocco"
    },
    {
        "id": "2130",
        "AirportCode": "ESW",
        "AirportName": "State Airport",
        "City": "Easton",
        "Country": "USA Washington"
    },
    {
        "id": "2131",
        "AirportCode": "ETB",
        "AirportName": "West Bend Airport",
        "City": "West Bend",
        "Country": "USA Wisconsin"
    },
    {
        "id": "2132",
        "AirportCode": "ETD",
        "AirportName": "Etadunna Airport",
        "City": "Etadunna",
        "Country": "Australia"
    },
    {
        "id": "2133",
        "AirportCode": "ETE",
        "AirportName": "Genda Wuha Airport",
        "City": "Genda Wuha",
        "Country": "Ethiopia"
    },
    {
        "id": "2134",
        "AirportCode": "ETH",
        "AirportName": "Elat Airport",
        "City": "Â ",
        "Country": "Â "
    },
    {
        "id": "2135",
        "AirportCode": "ETN",
        "AirportName": "Eastland Municipal Airport",
        "City": "Eastland",
        "Country": "USA Texas"
    },
    {
        "id": "2136",
        "AirportCode": "ETS",
        "AirportName": "Enterprise Municipal Airport",
        "City": "Enterprise",
        "Country": "USA Alabama"
    },
    {
        "id": "2137",
        "AirportCode": "ETZ",
        "AirportName": "Metz-Nancy-Lorraine Airport",
        "City": "Metz/Nancy",
        "Country": "France"
    },
    {
        "id": "2138",
        "AirportCode": "EUA",
        "AirportName": "Kaufana Airport",
        "City": "Eua",
        "Country": "Tonga"
    },
    {
        "id": "2139",
        "AirportCode": "EUC",
        "AirportName": "Eucla Airport",
        "City": "Eucla",
        "Country": "Australia"
    },
    {
        "id": "2140",
        "AirportCode": "EUE",
        "AirportName": "Eureka Airport",
        "City": "Eureka",
        "Country": "USA Nevada"
    },
    {
        "id": "2141",
        "AirportCode": "EUF",
        "AirportName": "Weedon Field",
        "City": "Eufaula",
        "Country": "USA Alabama"
    },
    {
        "id": "2142",
        "AirportCode": "EUG",
        "AirportName": "Eugene Airport",
        "City": "Eugene",
        "Country": "USA Oregon"
    },
    {
        "id": "2143",
        "AirportCode": "EUM",
        "AirportName": "Neumuenster Airport",
        "City": "Neumuenster",
        "Country": "Germany"
    },
    {
        "id": "2144",
        "AirportCode": "EUN",
        "AirportName": "Hassan I Airport",
        "City": "Laayoune",
        "Country": "Morocco"
    },
    {
        "id": "2145",
        "AirportCode": "EUQ",
        "AirportName": "Evelio Javier Airport",
        "City": "Antique",
        "Country": "Philippines"
    },
    {
        "id": "2146",
        "AirportCode": "EUX",
        "AirportName": "F D Roosevelt Airport",
        "City": "Saint Eustatius",
        "Country": "Netherlands Antilles"
    },
    {
        "id": "2147",
        "AirportCode": "EVA",
        "AirportName": "Evadale Landing Strip",
        "City": "Evadale",
        "Country": "USA Texas"
    },
    {
        "id": "2148",
        "AirportCode": "EVD",
        "AirportName": "Eva Downs Airport",
        "City": "Eva Downs",
        "Country": "Australia"
    },
    {
        "id": "2149",
        "AirportCode": "EVE",
        "AirportName": "Evenes Airport",
        "City": "Harstad-Narvik",
        "Country": "Norway"
    },
    {
        "id": "2150",
        "AirportCode": "EVG",
        "AirportName": "Sveg Airport",
        "City": "Sveg",
        "Country": "Sweden"
    },
    {
        "id": "2151",
        "AirportCode": "EVH",
        "AirportName": "Evans Head Airport",
        "City": "Evans Head",
        "Country": "Australia"
    },
    {
        "id": "2152",
        "AirportCode": "EVM",
        "AirportName": "Eveleth Airport",
        "City": "Eveleth",
        "Country": "USA Minnesota"
    },
    {
        "id": "2153",
        "AirportCode": "EVN",
        "AirportName": "Yerevan Airport",
        "City": "Yerevan",
        "Country": "Armenia"
    },
    {
        "id": "2154",
        "AirportCode": "EVV",
        "AirportName": "Dress Regional Airport",
        "City": "Evansville",
        "Country": "USA Indiana"
    },
    {
        "id": "2155",
        "AirportCode": "EVW",
        "AirportName": "Evanston Airport",
        "City": "Evanston",
        "Country": "USA Wyoming"
    },
    {
        "id": "2156",
        "AirportCode": "EVX",
        "AirportName": "Evreux Airport",
        "City": "Evreux",
        "Country": "France"
    },
    {
        "id": "2157",
        "AirportCode": "EWB",
        "AirportName": "New Bedford Airport",
        "City": "New Bedford",
        "Country": "USA Massachusetts"
    },
    {
        "id": "2158",
        "AirportCode": "EWD",
        "AirportName": "Wildman Lake Airport",
        "City": "Wildman Lake",
        "Country": "USA Alaska"
    },
    {
        "id": "2159",
        "AirportCode": "EWE",
        "AirportName": "Ewer Airport",
        "City": "Ewer",
        "Country": "Indonesia"
    },
    {
        "id": "2160",
        "AirportCode": "EWI",
        "AirportName": "Enarotali Airport",
        "City": "Enarotali",
        "Country": "Indonesia"
    },
    {
        "id": "2161",
        "AirportCode": "EWK",
        "AirportName": "Newton City-County Airport",
        "City": "Newton",
        "Country": "USA Kansas"
    },
    {
        "id": "2162",
        "AirportCode": "EWN",
        "AirportName": "Coastal Carolina Regional Airport",
        "City": "New Bern",
        "Country": "USA North Carolina"
    },
    {
        "id": "2163",
        "AirportCode": "EWO",
        "AirportName": "Ewo Airport",
        "City": "Ewo",
        "Country": "Congo"
    },
    {
        "id": "2164",
        "AirportCode": "EWR",
        "AirportName": "Newark Liberty International Airport",
        "City": "Newark",
        "Country": "USA New Jersey"
    },
    {
        "id": "2165",
        "AirportCode": "EWY",
        "AirportName": "Greenham Royal Air Force",
        "City": "Newbury",
        "Country": "United Kingdom"
    },
    {
        "id": "2166",
        "AirportCode": "EXI",
        "AirportName": "Excursion Inlet Sea Plane Base",
        "City": "Excursion Inlet",
        "Country": "USA Alaska"
    },
    {
        "id": "2167",
        "AirportCode": "EXM",
        "AirportName": "Exmouth Gulf Airport",
        "City": "Exmouth Gulf",
        "Country": "Australia"
    },
    {
        "id": "2168",
        "AirportCode": "EXT",
        "AirportName": "Exeter Airport",
        "City": "Exeter",
        "Country": "United Kingdom"
    },
    {
        "id": "2169",
        "AirportCode": "EYL",
        "AirportName": "Yelimane Airport",
        "City": "Yelimane",
        "Country": "Mali"
    },
    {
        "id": "2170",
        "AirportCode": "EYP",
        "AirportName": "El Yopal Airport",
        "City": "El Yopal",
        "Country": "Colombia"
    },
    {
        "id": "2171",
        "AirportCode": "EYR",
        "AirportName": "Yerington Airport",
        "City": "Yerington",
        "Country": "USA Nevada"
    },
    {
        "id": "2172",
        "AirportCode": "EYS",
        "AirportName": "Elive Springs Airport",
        "City": "Elive Springs",
        "Country": "Kenya"
    },
    {
        "id": "2173",
        "AirportCode": "EYW",
        "AirportName": "Key West International Airport",
        "City": "Key West",
        "Country": "USA Florida"
    },
    {
        "id": "2174",
        "AirportCode": "EZE",
        "AirportName": "Ministro Pistarini Airport",
        "City": "Buenos Aires",
        "Country": "Argentina"
    },
    {
        "id": "2175",
        "AirportCode": "EZS",
        "AirportName": "Elazig Airport",
        "City": "Elazig",
        "Country": "Turkey"
    },
    {
        "id": "2176",
        "AirportCode": "FAA",
        "AirportName": "Faranah Airport",
        "City": "Faranah",
        "Country": "Guinea"
    },
    {
        "id": "2177",
        "AirportCode": "FAB",
        "AirportName": "Farnborough Airport",
        "City": "Farnborough",
        "Country": "United Kingdom"
    },
    {
        "id": "2178",
        "AirportCode": "FAC",
        "AirportName": "Faaite Airport",
        "City": "Faaite",
        "Country": "French Polynesia"
    },
    {
        "id": "2179",
        "AirportCode": "FAE",
        "AirportName": "Vagar Airport",
        "City": "Faroe Islands",
        "Country": "Faroe Islands"
    },
    {
        "id": "2180",
        "AirportCode": "FAF",
        "AirportName": "Felker Army Air Field",
        "City": "Fort Eustis",
        "Country": "USA Virginia"
    },
    {
        "id": "2181",
        "AirportCode": "FAG",
        "AirportName": "Fagurholsmyri Airport",
        "City": "Fagurholsmyri",
        "Country": "Iceland"
    },
    {
        "id": "2182",
        "AirportCode": "FAH",
        "AirportName": "Farah Airport",
        "City": "Farah",
        "Country": "Afghanistan"
    },
    {
        "id": "2183",
        "AirportCode": "FAI",
        "AirportName": "Fairbanks International Airport",
        "City": "Fairbanks",
        "Country": "USA Alaska"
    },
    {
        "id": "2184",
        "AirportCode": "FAJ",
        "AirportName": "Fajardo Airport",
        "City": "Fajardo",
        "Country": "Puerto Rico"
    },
    {
        "id": "2185",
        "AirportCode": "FAL",
        "AirportName": "Roma Falcon State Airport",
        "City": "Roma Falcon",
        "Country": "USA Texas"
    },
    {
        "id": "2186",
        "AirportCode": "FAM",
        "AirportName": "Farmington Regional Airport",
        "City": "Farmington",
        "Country": "USA Missouri"
    },
    {
        "id": "2187",
        "AirportCode": "FAN",
        "AirportName": "Lista Airport",
        "City": "Farsund",
        "Country": "Norway"
    },
    {
        "id": "2188",
        "AirportCode": "FAO",
        "AirportName": "Faro Airport",
        "City": "Faro",
        "Country": "Portugal"
    },
    {
        "id": "2189",
        "AirportCode": "FAQ",
        "AirportName": "Freida River Airport",
        "City": "Freida River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2190",
        "AirportCode": "FAR",
        "AirportName": "Hector Field",
        "City": "Fargo",
        "Country": "USA North Dakota"
    },
    {
        "id": "2191",
        "AirportCode": "FAS",
        "AirportName": "Faskrudsfjordur Airport",
        "City": "Faskrudsfjordur",
        "Country": "Iceland"
    },
    {
        "id": "2192",
        "AirportCode": "FAT",
        "AirportName": "Fresno Air Terminal",
        "City": "Fresno",
        "Country": "USA California"
    },
    {
        "id": "2193",
        "AirportCode": "FAV",
        "AirportName": "Fakarava Airport",
        "City": "Fakarava",
        "Country": "French Polynesia"
    },
    {
        "id": "2194",
        "AirportCode": "FAY",
        "AirportName": "Fayetteville Municipal Airport",
        "City": "Fayetteville",
        "Country": "USA North Carolina"
    },
    {
        "id": "2195",
        "AirportCode": "FAZ",
        "AirportName": "Fasa Airport",
        "City": "Fasa",
        "Country": "Iran"
    },
    {
        "id": "2196",
        "AirportCode": "FBD",
        "AirportName": "Faizabad Airport",
        "City": "Faizabad",
        "Country": "Afghanistan"
    },
    {
        "id": "2197",
        "AirportCode": "FBE",
        "AirportName": "Francisco Beltrao Airport",
        "City": "Francisco Beltrao",
        "Country": "Brazil"
    },
    {
        "id": "2198",
        "AirportCode": "FBG",
        "AirportName": "Simmons Army Air Field",
        "City": "Fort Bragg",
        "Country": "USA North Carolina"
    },
    {
        "id": "2199",
        "AirportCode": "FBL",
        "AirportName": "Faribault Municipal Airport",
        "City": "Faribault",
        "Country": "USA Minnesota"
    },
    {
        "id": "2200",
        "AirportCode": "FBM",
        "AirportName": "Luano",
        "City": "Lubumbashi",
        "Country": "Congo, DR"
    },
    {
        "id": "2201",
        "AirportCode": "FBR",
        "AirportName": "Fort Bridger Airport",
        "City": "Fort Bridger",
        "Country": "USA Wyoming"
    },
    {
        "id": "2202",
        "AirportCode": "FBS",
        "AirportName": "Friday Harbor SPB",
        "City": "Friday Harbor",
        "Country": "USA Washington"
    },
    {
        "id": "2203",
        "AirportCode": "FBY",
        "AirportName": "Fairbury Municipal Airport",
        "City": "Fairbury",
        "Country": "USA Nebraska"
    },
    {
        "id": "2204",
        "AirportCode": "FCB",
        "AirportName": "Ficksburg Sentra Oes Airport",
        "City": "Ficksburg",
        "Country": "South Africa"
    },
    {
        "id": "2205",
        "AirportCode": "FCH",
        "AirportName": "Fresno-Chandler Airport",
        "City": "Fresno",
        "Country": "USA California"
    },
    {
        "id": "2206",
        "AirportCode": "FCM",
        "AirportName": "Flying Cloud Airport",
        "City": "Minneapolis",
        "Country": "USA Minnesota"
    },
    {
        "id": "2207",
        "AirportCode": "FCN",
        "AirportName": "Cuxhaven/Nordholz Airport",
        "City": "Cuxhaven",
        "Country": "Germany"
    },
    {
        "id": "2208",
        "AirportCode": "FCO",
        "AirportName": "Fiumicino Airport",
        "City": "Rome",
        "Country": "Italy"
    },
    {
        "id": "2209",
        "AirportCode": "FCS",
        "AirportName": "Butts Army Air Field",
        "City": "Colorado Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "2210",
        "AirportCode": "FCT",
        "AirportName": "Firing Center Army Air Field",
        "City": "Yakima",
        "Country": "USA Washington"
    },
    {
        "id": "2211",
        "AirportCode": "FCY",
        "AirportName": "Forrest City Municipal Airport",
        "City": "Forrest City",
        "Country": "USA Arkansas"
    },
    {
        "id": "2212",
        "AirportCode": "FDB",
        "AirportName": "Principe Da Beira Airport",
        "City": "Principe Da Beira",
        "Country": "Brazil"
    },
    {
        "id": "2213",
        "AirportCode": "FDE",
        "AirportName": "Bringeland Airport",
        "City": "Forde",
        "Country": "Norway"
    },
    {
        "id": "2214",
        "AirportCode": "FDF",
        "AirportName": "Lamentin Airport",
        "City": "Fort De France",
        "Country": "Martinique"
    },
    {
        "id": "2215",
        "AirportCode": "FDH",
        "AirportName": "Friedrichshafen Airport",
        "City": "Friedrichshafen",
        "Country": "Germany"
    },
    {
        "id": "2216",
        "AirportCode": "FDK",
        "AirportName": "Frederick Municipal Airport",
        "City": "Frederick",
        "Country": "USA Maryland"
    },
    {
        "id": "2217",
        "AirportCode": "FDR",
        "AirportName": "Frederick Municipal Airport",
        "City": "Frederick",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2218",
        "AirportCode": "FDU",
        "AirportName": "Bandundu Airport",
        "City": "Bandundu",
        "Country": "Congo, DR"
    },
    {
        "id": "2219",
        "AirportCode": "FDY",
        "AirportName": "Findlay Airport",
        "City": "Findlay",
        "Country": "USA Ohio"
    },
    {
        "id": "2220",
        "AirportCode": "FEA",
        "AirportName": "Fetlar Airport",
        "City": "Fetlar",
        "Country": "United Kingdom"
    },
    {
        "id": "2221",
        "AirportCode": "FEB",
        "AirportName": "Sanfebagar Airport",
        "City": "Sanfebagar",
        "Country": "Nepal"
    },
    {
        "id": "2222",
        "AirportCode": "FEC",
        "AirportName": "Feira De Santana Airport",
        "City": "Feira De Santana",
        "Country": "Brazil"
    },
    {
        "id": "2223",
        "AirportCode": "FEG",
        "AirportName": "Fergana Airport",
        "City": "Fergana",
        "Country": "Uzbekistan"
    },
    {
        "id": "2224",
        "AirportCode": "FEJ",
        "AirportName": "Feijo Airport",
        "City": "Feijo",
        "Country": "Brazil"
    },
    {
        "id": "2225",
        "AirportCode": "FEL",
        "AirportName": "Fuerstenfeldbruck Airport",
        "City": "Fuerstenfeldbruck",
        "Country": "Germany"
    },
    {
        "id": "2226",
        "AirportCode": "FEN",
        "AirportName": "Fernando De Noronha Airport",
        "City": "Fernando De Noronha",
        "Country": "Brazil"
    },
    {
        "id": "2227",
        "AirportCode": "FEP",
        "AirportName": "Albertus Airport",
        "City": "Freeport",
        "Country": "USA Illinois"
    },
    {
        "id": "2228",
        "AirportCode": "FES",
        "AirportName": "San Fernando Airport",
        "City": "San Fernando",
        "Country": "Spain"
    },
    {
        "id": "2229",
        "AirportCode": "FET",
        "AirportName": "Fremont Municipal Airport",
        "City": "Fremont",
        "Country": "USA Nebraska"
    },
    {
        "id": "2230",
        "AirportCode": "FEW",
        "AirportName": "Warren Air Force Base",
        "City": "Cheyenne",
        "Country": "USA Wyoming"
    },
    {
        "id": "2231",
        "AirportCode": "FEZ",
        "AirportName": "Sais Airport",
        "City": "Fez",
        "Country": "Morocco"
    },
    {
        "id": "2232",
        "AirportCode": "FFA",
        "AirportName": "First Flight Airport",
        "City": "Kill Devil Hills",
        "Country": "USA North Carolina"
    },
    {
        "id": "2233",
        "AirportCode": "FFD",
        "AirportName": "Royal Air Force Station",
        "City": "Fairford",
        "Country": "United Kingdom"
    },
    {
        "id": "2234",
        "AirportCode": "FFL",
        "AirportName": "Fairfield Municipal Airport",
        "City": "Fairfield",
        "Country": "USA Iowa"
    },
    {
        "id": "2235",
        "AirportCode": "FFM",
        "AirportName": "Fergus Falls Airport",
        "City": "Fergus Falls",
        "Country": "USA Minnesota"
    },
    {
        "id": "2236",
        "AirportCode": "FFO",
        "AirportName": "Patterson Air Force Base",
        "City": "Dayton",
        "Country": "USA Ohio"
    },
    {
        "id": "2237",
        "AirportCode": "FFT",
        "AirportName": "Capital City Airport",
        "City": "Frankfort",
        "Country": "USA Kentucky"
    },
    {
        "id": "2238",
        "AirportCode": "FFU",
        "AirportName": "Futaleufu Airport",
        "City": "Futaleufu",
        "Country": "Chile"
    },
    {
        "id": "2239",
        "AirportCode": "FGD",
        "AirportName": "Fderik Airport",
        "City": "Fderik",
        "Country": "Mauritania"
    },
    {
        "id": "2240",
        "AirportCode": "FGI",
        "AirportName": "Fagali I Airport",
        "City": "Apia",
        "Country": "Samoa"
    },
    {
        "id": "2241",
        "AirportCode": "FGL",
        "AirportName": "Fox Glacier Airport",
        "City": "Fox Glacier",
        "Country": "New Zealand"
    },
    {
        "id": "2242",
        "AirportCode": "FGR",
        "AirportName": "Fuengirola Airport",
        "City": "Fuengirola",
        "Country": "Spain"
    },
    {
        "id": "2243",
        "AirportCode": "FGU",
        "AirportName": "Fangatau Airport",
        "City": "Fangatau, Tuamoto Island",
        "Country": "French Polynesia"
    },
    {
        "id": "2244",
        "AirportCode": "FHU",
        "AirportName": "Fort Huachuca/Sierra Vista Municipal Airport",
        "City": "Fort Huachuca/Sierra Vista",
        "Country": "USA Arizona"
    },
    {
        "id": "2245",
        "AirportCode": "FHZ",
        "AirportName": "Fakahina Airport",
        "City": "Fakahina",
        "Country": "French Polynesia"
    },
    {
        "id": "2246",
        "AirportCode": "FID",
        "AirportName": "Elizabeth Field",
        "City": "Fishers Island",
        "Country": "USA New York"
    },
    {
        "id": "2247",
        "AirportCode": "FIE",
        "AirportName": "Fair Isle Airport",
        "City": "Fair Isle",
        "Country": "United Kingdom"
    },
    {
        "id": "2248",
        "AirportCode": "FIG",
        "AirportName": "Fria Airport",
        "City": "Fria",
        "Country": "Guinea"
    },
    {
        "id": "2249",
        "AirportCode": "FIK",
        "AirportName": "Finke Airport",
        "City": "Finke",
        "Country": "Australia"
    },
    {
        "id": "2250",
        "AirportCode": "FIL",
        "AirportName": "Fillmore Municipal Airport",
        "City": "Fillmore",
        "Country": "USA Utah"
    },
    {
        "id": "2251",
        "AirportCode": "FIN",
        "AirportName": "Finschhafen Airport",
        "City": "Finschhafen",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2252",
        "AirportCode": "FIZ",
        "AirportName": "Fitzroy Crossing Airport",
        "City": "Fitzroy Crossing",
        "Country": "Australia"
    },
    {
        "id": "2253",
        "AirportCode": "FJR",
        "AirportName": "Fujairah International Airport",
        "City": "Al-Fujairah",
        "Country": "UAE"
    },
    {
        "id": "2254",
        "AirportCode": "FKB",
        "AirportName": "Soellingen Airport",
        "City": "Karlsruhe/Baden Baden",
        "Country": "Germany"
    },
    {
        "id": "2255",
        "AirportCode": "FKH",
        "AirportName": "Sculthorp Royal Air Force",
        "City": "Fakenham",
        "Country": "United Kingdom"
    },
    {
        "id": "2256",
        "AirportCode": "FKI",
        "AirportName": "Kisangani Airport",
        "City": "Kisangani",
        "Country": "Congo, DR"
    },
    {
        "id": "2257",
        "AirportCode": "FKJ",
        "AirportName": "Fukui Airport",
        "City": "Fukui",
        "Country": "Japan"
    },
    {
        "id": "2258",
        "AirportCode": "FKL",
        "AirportName": "Chess-Lambertin Airport",
        "City": "Franklin",
        "Country": "USA Philadelphia"
    },
    {
        "id": "2259",
        "AirportCode": "FKN",
        "AirportName": "Franklin Municipal Airport",
        "City": "Franklin",
        "Country": "USA Virginia"
    },
    {
        "id": "2260",
        "AirportCode": "FKQ",
        "AirportName": "Fak Fak Airport",
        "City": "Fak Fak",
        "Country": "Indonesia"
    },
    {
        "id": "2261",
        "AirportCode": "FKS",
        "AirportName": "Fukushima Airport",
        "City": "Fukushima",
        "Country": "Japan"
    },
    {
        "id": "2262",
        "AirportCode": "FLA",
        "AirportName": "Capitolio Airport",
        "City": "Florencia",
        "Country": "Colombia"
    },
    {
        "id": "2263",
        "AirportCode": "FLB",
        "AirportName": "Cangapara Airport",
        "City": "Floriano",
        "Country": "Brazil"
    },
    {
        "id": "2264",
        "AirportCode": "FLC",
        "AirportName": "Falls Creek Airport",
        "City": "Falls Creek",
        "Country": "Australia"
    },
    {
        "id": "2265",
        "AirportCode": "FLD",
        "AirportName": "Fond Du Lac Airport",
        "City": "Fond Du Lac",
        "Country": "USA Wisconsin"
    },
    {
        "id": "2266",
        "AirportCode": "FLE",
        "AirportName": "Fort Lee Army Air Field",
        "City": "Petersburg",
        "Country": "USA Virginia"
    },
    {
        "id": "2267",
        "AirportCode": "FLF",
        "AirportName": "Schaferhaus Airport",
        "City": "Flensburg",
        "Country": "Germany"
    },
    {
        "id": "2268",
        "AirportCode": "FLG",
        "AirportName": "Pulliam Field",
        "City": "Grand Canyon",
        "Country": "USA Arizona"
    },
    {
        "id": "2269",
        "AirportCode": "FLH",
        "AirportName": "Flotta Airport",
        "City": "Flotta",
        "Country": "United Kingdom"
    },
    {
        "id": "2270",
        "AirportCode": "FLI",
        "AirportName": "Flateyri Airport",
        "City": "Flateyri",
        "Country": "Iceland"
    },
    {
        "id": "2271",
        "AirportCode": "FLL",
        "AirportName": "Ft. Lauderdale International Airport",
        "City": "Fort Lauderdale",
        "Country": "USA Florida"
    },
    {
        "id": "2272",
        "AirportCode": "FLM",
        "AirportName": "Filadelfia Airport",
        "City": "Filadelfia",
        "Country": "Paraguay"
    },
    {
        "id": "2273",
        "AirportCode": "FLN",
        "AirportName": "Hercilio Luz Airport",
        "City": "Florianopolis",
        "Country": "Brazil"
    },
    {
        "id": "2274",
        "AirportCode": "FLO",
        "AirportName": "Florence Airport",
        "City": "Florence",
        "Country": "USA South Carolina"
    },
    {
        "id": "2275",
        "AirportCode": "FLP",
        "AirportName": "Flippin Airport",
        "City": "Flippin",
        "Country": "USA Arkansas"
    },
    {
        "id": "2276",
        "AirportCode": "FLR",
        "AirportName": "Peretola Airport",
        "City": "Florence",
        "Country": "Italy"
    },
    {
        "id": "2277",
        "AirportCode": "FLS",
        "AirportName": "Flinders Island Airport",
        "City": "Flinders Island",
        "Country": "Australia"
    },
    {
        "id": "2279",
        "AirportCode": "FLV",
        "AirportName": "Sherman Army Air Field",
        "City": "Fort Leavenworth",
        "Country": "USA Kansas"
    },
    {
        "id": "2280",
        "AirportCode": "FLW",
        "AirportName": "Santa Cruz Airport",
        "City": "Flores Island (Azores)",
        "Country": "Azores"
    },
    {
        "id": "2281",
        "AirportCode": "FLX",
        "AirportName": "Fallon Municipal Airport",
        "City": "Fallon",
        "Country": "USA Nevada"
    },
    {
        "id": "2282",
        "AirportCode": "FLY",
        "AirportName": "Finley Airport",
        "City": "Finley",
        "Country": "Australia"
    },
    {
        "id": "2283",
        "AirportCode": "FMA",
        "AirportName": "El Pucu Airport",
        "City": "Formosa",
        "Country": "Argentina"
    },
    {
        "id": "2284",
        "AirportCode": "FMG",
        "AirportName": "Flamingo Airport",
        "City": "Flamingo",
        "Country": "Costa Rica"
    },
    {
        "id": "2285",
        "AirportCode": "FMH",
        "AirportName": "Otis Air Force Base",
        "City": "Falmouth",
        "Country": "USA Massachusetts"
    },
    {
        "id": "2286",
        "AirportCode": "FMI",
        "AirportName": "Kalemie Airport",
        "City": "Kalemie",
        "Country": "Congo, DR"
    },
    {
        "id": "2287",
        "AirportCode": "FMM",
        "AirportName": "Memmingen Airport",
        "City": "Memmingen",
        "Country": "Germany"
    },
    {
        "id": "2288",
        "AirportCode": "FMN",
        "AirportName": "Farmington Municipal Airport",
        "City": "Farmington",
        "Country": "USA New Mexico"
    },
    {
        "id": "2289",
        "AirportCode": "FMO",
        "AirportName": "Muenster Airport",
        "City": "Muenster",
        "Country": "Germany"
    },
    {
        "id": "2290",
        "AirportCode": "FMS",
        "AirportName": "Fort Madison Municipal Airport",
        "City": "Fort Madison",
        "Country": "USA Iowa"
    },
    {
        "id": "2291",
        "AirportCode": "FMY",
        "AirportName": "Page Field",
        "City": "Fort Myers",
        "Country": "USA Florida"
    },
    {
        "id": "2292",
        "AirportCode": "FNA",
        "AirportName": "Lungi International Airport",
        "City": "Freetown",
        "Country": "Sierra Leone"
    },
    {
        "id": "2293",
        "AirportCode": "FNB",
        "AirportName": "Neubrandenburg Airport",
        "City": "Neubrandenburg",
        "Country": "Germany"
    },
    {
        "id": "2294",
        "AirportCode": "FNC",
        "AirportName": "Madeira Airport",
        "City": "Funchal",
        "Country": "Portugal"
    },
    {
        "id": "2295",
        "AirportCode": "FNE",
        "AirportName": "Fane Airport",
        "City": "Fane",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2296",
        "AirportCode": "FNG",
        "AirportName": "Fada Ngourma Airport",
        "City": "Fada Ngourma",
        "Country": "Burkina Faso"
    },
    {
        "id": "2297",
        "AirportCode": "FNH",
        "AirportName": "Fincha Airport",
        "City": "Fincha",
        "Country": "Ethiopia"
    },
    {
        "id": "2298",
        "AirportCode": "FNI",
        "AirportName": "Garons Airport",
        "City": "Nimes",
        "Country": "France"
    },
    {
        "id": "2299",
        "AirportCode": "FNJ",
        "AirportName": "Sunan",
        "City": "Pyongyang",
        "Country": "Â "
    },
    {
        "id": "2300",
        "AirportCode": "FNL",
        "AirportName": "Fort Collins/Loveland Municipal Airport",
        "City": "Fort Collins/Loveland",
        "Country": "USA Colorado"
    },
    {
        "id": "2301",
        "AirportCode": "FNT",
        "AirportName": "Bishop Airport",
        "City": "Flint",
        "Country": "USA Michigan"
    },
    {
        "id": "2302",
        "AirportCode": "FOA",
        "AirportName": "Foula Airport",
        "City": "Foula",
        "Country": "United Kingdom"
    },
    {
        "id": "2303",
        "AirportCode": "FOB",
        "AirportName": "Fort Bragg Airport",
        "City": "Fort Bragg",
        "Country": "USA California"
    },
    {
        "id": "2304",
        "AirportCode": "FOC",
        "AirportName": "Fuzhou Airport",
        "City": "Fuzhou",
        "Country": "China"
    },
    {
        "id": "2305",
        "AirportCode": "FOD",
        "AirportName": "Fort Dodge Airport",
        "City": "Fort Dodge",
        "Country": "USA Iowa"
    },
    {
        "id": "2306",
        "AirportCode": "FOE",
        "AirportName": "Forbes Air Force Base",
        "City": "Topeka",
        "Country": "USA Kansas"
    },
    {
        "id": "2307",
        "AirportCode": "FOG",
        "AirportName": "Gino Lisa Airport",
        "City": "Foggia",
        "Country": "Italy"
    },
    {
        "id": "2308",
        "AirportCode": "FOK",
        "AirportName": "Suffolk County Airport",
        "City": "Westhampton",
        "Country": "USA New York"
    },
    {
        "id": "2309",
        "AirportCode": "FOM",
        "AirportName": "Foumban Airport",
        "City": "Foumban",
        "Country": "Cameroon"
    },
    {
        "id": "2310",
        "AirportCode": "FON",
        "AirportName": "Fortuna Airport",
        "City": "Fortuna",
        "Country": "Costa Rica"
    },
    {
        "id": "2311",
        "AirportCode": "FOO",
        "AirportName": "Numfor Airport",
        "City": "Numfor",
        "Country": "Indonesia"
    },
    {
        "id": "2312",
        "AirportCode": "FOP",
        "AirportName": "Morris Army Air Field",
        "City": "Forest Park",
        "Country": "USA Georgia"
    },
    {
        "id": "2313",
        "AirportCode": "FOR",
        "AirportName": "Pinto Martins Airport",
        "City": "Fortaleza",
        "Country": "Brazil"
    },
    {
        "id": "2314",
        "AirportCode": "FOS",
        "AirportName": "Forrest Airport",
        "City": "Forrest",
        "Country": "Australia"
    },
    {
        "id": "2315",
        "AirportCode": "FOT",
        "AirportName": "Forster Airport",
        "City": "Forster",
        "Country": "Australia"
    },
    {
        "id": "2316",
        "AirportCode": "FOU",
        "AirportName": "Fougamou Airport",
        "City": "Fougamou",
        "Country": "Gabon"
    },
    {
        "id": "2317",
        "AirportCode": "FOY",
        "AirportName": "Foya Airport",
        "City": "Foya",
        "Country": "Liberia"
    },
    {
        "id": "2318",
        "AirportCode": "FPO",
        "AirportName": "Grand Bahama International Airport",
        "City": "Freeport",
        "Country": "Bahamas"
    },
    {
        "id": "2319",
        "AirportCode": "FPR",
        "AirportName": "Saint Lucie County Airport",
        "City": "Fort Pierce",
        "Country": "USA Florida"
    },
    {
        "id": "2320",
        "AirportCode": "FPY",
        "AirportName": "Perry-Foley Airport",
        "City": "Perry",
        "Country": "USA Florida"
    },
    {
        "id": "2321",
        "AirportCode": "FRA",
        "AirportName": "Frankfurt International Airport",
        "City": "Frankfurt",
        "Country": "Germany"
    },
    {
        "id": "2322",
        "AirportCode": "FRB",
        "AirportName": "Forbes Airport",
        "City": "Forbes",
        "Country": "Australia"
    },
    {
        "id": "2323",
        "AirportCode": "FRC",
        "AirportName": "Franca Airport",
        "City": "Franca",
        "Country": "Brazil"
    },
    {
        "id": "2324",
        "AirportCode": "FRD",
        "AirportName": "Friday Harbor Airport",
        "City": "Friday Harbor",
        "Country": "USA Washington"
    },
    {
        "id": "2325",
        "AirportCode": "FRE",
        "AirportName": "Fera Island Airport",
        "City": "Fera Island",
        "Country": "Solomon Islands"
    },
    {
        "id": "2326",
        "AirportCode": "FRF",
        "AirportName": "Rhein-Main Air Force Base",
        "City": "Frankfurt",
        "Country": "Germany"
    },
    {
        "id": "2327",
        "AirportCode": "FRG",
        "AirportName": "Republic Field",
        "City": "Farmingdale",
        "Country": "USA New York"
    },
    {
        "id": "2328",
        "AirportCode": "FRH",
        "AirportName": "French Lick Municipal Airport",
        "City": "French Lick",
        "Country": "USA Indiana"
    },
    {
        "id": "2329",
        "AirportCode": "FRI",
        "AirportName": "Marshall Army Air Field",
        "City": "Fort Riley",
        "Country": "USA Kansas"
    },
    {
        "id": "2330",
        "AirportCode": "FRJ",
        "AirportName": "Frejus Airport",
        "City": "Frejus",
        "Country": "France"
    },
    {
        "id": "2331",
        "AirportCode": "FRK",
        "AirportName": "Fregate Island Airport",
        "City": "Fregate Island",
        "Country": "Seychelles"
    },
    {
        "id": "2332",
        "AirportCode": "FRL",
        "AirportName": "Luigi Ridolfi Airport",
        "City": "Forli",
        "Country": "Italy"
    },
    {
        "id": "2333",
        "AirportCode": "FRM",
        "AirportName": "Fairmont Airport",
        "City": "Fairmont",
        "Country": "USA Minnesota"
    },
    {
        "id": "2334",
        "AirportCode": "FRN",
        "AirportName": "Bryant Army Air Field",
        "City": "Fort Richardson",
        "Country": "USA Alaska"
    },
    {
        "id": "2335",
        "AirportCode": "FRO",
        "AirportName": "Flora Airport",
        "City": "Floro",
        "Country": "Norway"
    },
    {
        "id": "2336",
        "AirportCode": "FRQ",
        "AirportName": "Feramin Airport",
        "City": "Feramin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2337",
        "AirportCode": "FRR",
        "AirportName": "Warren County Airport",
        "City": "Front Royal",
        "Country": "USA Virginia"
    },
    {
        "id": "2338",
        "AirportCode": "FRS",
        "AirportName": "Santa Elena Airport",
        "City": "Flores",
        "Country": "Guatemala"
    },
    {
        "id": "2339",
        "AirportCode": "FRT",
        "AirportName": "Frutillar Airport",
        "City": "Frutillar",
        "Country": "Chile"
    },
    {
        "id": "2340",
        "AirportCode": "FRU",
        "AirportName": "Bishkek Airport",
        "City": "Bishkek",
        "Country": "Kyrgyzstan"
    },
    {
        "id": "2341",
        "AirportCode": "FRW",
        "AirportName": "Francistown Airport",
        "City": "Francistown",
        "Country": "Botswana"
    },
    {
        "id": "2342",
        "AirportCode": "FRY",
        "AirportName": "Fryeburg Airport",
        "City": "Fryeburg",
        "Country": "USA Maine"
    },
    {
        "id": "2343",
        "AirportCode": "FRZ",
        "AirportName": "Fritzlar Air Base",
        "City": "Fritzlar",
        "Country": "Germany"
    },
    {
        "id": "2344",
        "AirportCode": "FSC",
        "AirportName": "Sud Corse Airport",
        "City": "Figari",
        "Country": "France"
    },
    {
        "id": "2345",
        "AirportCode": "FSD",
        "AirportName": "Sioux Falls Regional Airport (Jo Foss Field)",
        "City": "Sioux Falls",
        "Country": "USA South Dakota"
    },
    {
        "id": "2346",
        "AirportCode": "FSI",
        "AirportName": "Post Aaf",
        "City": "Ft Sill",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2347",
        "AirportCode": "FSK",
        "AirportName": "Fort Scott Municipal Airport",
        "City": "Fort Scott",
        "Country": "USA Kansas"
    },
    {
        "id": "2348",
        "AirportCode": "FSL",
        "AirportName": "Fossil Downs Airport",
        "City": "Fossil Downs",
        "Country": "Australia"
    },
    {
        "id": "2349",
        "AirportCode": "FSM",
        "AirportName": "Fort Smith Municipal Airport",
        "City": "Fort Smith",
        "Country": "USA Arkansas"
    },
    {
        "id": "2350",
        "AirportCode": "FSN",
        "AirportName": "Haley AAF",
        "City": "Fort Sheridan",
        "Country": "USA Illinois"
    },
    {
        "id": "2351",
        "AirportCode": "FSP",
        "AirportName": "Saint Pierre Airport",
        "City": "Saint Pierre",
        "Country": "St Pierre et Miquelon"
    },
    {
        "id": "2352",
        "AirportCode": "FSS",
        "AirportName": "Kinloss Airport",
        "City": "Forres",
        "Country": "United Kingdom"
    },
    {
        "id": "2353",
        "AirportCode": "FST",
        "AirportName": "Pecos County Airport",
        "City": "Fort Stockton",
        "Country": "USA Texas"
    },
    {
        "id": "2354",
        "AirportCode": "FSU",
        "AirportName": "Fort Sumner Airport",
        "City": "Fort Sumner",
        "Country": "USA New Mexico"
    },
    {
        "id": "2355",
        "AirportCode": "FTA",
        "AirportName": "Futuna Airport",
        "City": "Futuna Island",
        "Country": "Vanuatu"
    },
    {
        "id": "2356",
        "AirportCode": "FTE",
        "AirportName": "El Calafate",
        "City": "El Calafate",
        "Country": "Argentina"
    },
    {
        "id": "2357",
        "AirportCode": "FTI",
        "AirportName": "Fituita Airport",
        "City": "Fituita",
        "Country": "Samoa"
    },
    {
        "id": "2358",
        "AirportCode": "FTK",
        "AirportName": "Godman Army Air Field",
        "City": "Fort Knox",
        "Country": "USA Kentucky"
    },
    {
        "id": "2359",
        "AirportCode": "FTU",
        "AirportName": "Marillac Airport",
        "City": "Fort Dauphin",
        "Country": "Madagascar"
    },
    {
        "id": "2360",
        "AirportCode": "FTW",
        "AirportName": "Meacham Field",
        "City": "Fort Worth",
        "Country": "USA Texas"
    },
    {
        "id": "2361",
        "AirportCode": "FTX",
        "AirportName": "Owando Airport",
        "City": "Owando",
        "Country": "Congo"
    },
    {
        "id": "2362",
        "AirportCode": "FTY",
        "AirportName": "Fulton County Airport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "2363",
        "AirportCode": "FUB",
        "AirportName": "Fulleborn Airport",
        "City": "Fulleborn",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2364",
        "AirportCode": "FUD",
        "AirportName": "Sui Fen He Airport",
        "City": "Sui Fen He",
        "Country": "China"
    },
    {
        "id": "2365",
        "AirportCode": "FUE",
        "AirportName": "Puerto del Rosario Airport",
        "City": "Puerto del Rosario",
        "Country": "Spain"
    },
    {
        "id": "2366",
        "AirportCode": "FUG",
        "AirportName": "Fuyang Airport",
        "City": "Fuyang",
        "Country": "China"
    },
    {
        "id": "2367",
        "AirportCode": "FUJ",
        "AirportName": "Fukue Airport",
        "City": "Fukue",
        "Country": "Japan"
    },
    {
        "id": "2368",
        "AirportCode": "FUK",
        "AirportName": "Fukuoka Airport",
        "City": "Fukuoka",
        "Country": "Japan"
    },
    {
        "id": "2369",
        "AirportCode": "FUL",
        "AirportName": "Fullerton Municipal Airport",
        "City": "Fullerton",
        "Country": "USA California"
    },
    {
        "id": "2370",
        "AirportCode": "FUM",
        "AirportName": "Fuma Airport",
        "City": "Fuma",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2371",
        "AirportCode": "FUN",
        "AirportName": "Funafuti Atol International Airport",
        "City": "Funafuti Atol",
        "Country": "Tuvalu"
    },
    {
        "id": "2372",
        "AirportCode": "FUO",
        "AirportName": "Fuoshan Airport",
        "City": "Fuoshan",
        "Country": "China"
    },
    {
        "id": "2373",
        "AirportCode": "FUT",
        "AirportName": "Futuna Island Airport",
        "City": "Futuna Island",
        "Country": "Wallis Futuna Islands"
    },
    {
        "id": "2374",
        "AirportCode": "FVL",
        "AirportName": "Flora Valley Airport",
        "City": "Flora Valley",
        "Country": "Australia"
    },
    {
        "id": "2375",
        "AirportCode": "FVR",
        "AirportName": "Forrest River Airport",
        "City": "Forrest River",
        "Country": "Australia"
    },
    {
        "id": "2376",
        "AirportCode": "FWA",
        "AirportName": "Fort Wayne Municipal Airport/Baer Field",
        "City": "Fort Wayne",
        "Country": "USA Indiana"
    },
    {
        "id": "2377",
        "AirportCode": "FWH",
        "AirportName": "Carswell Air Force Base",
        "City": "Fort Worth",
        "Country": "USA Texas"
    },
    {
        "id": "2378",
        "AirportCode": "FWM",
        "AirportName": "Fort WilliamHeliport",
        "City": "Fort William",
        "Country": "United Kingdom"
    },
    {
        "id": "2379",
        "AirportCode": "FXE",
        "AirportName": "Executive Airport",
        "City": "Fort Lauderdale",
        "Country": "USA Florida"
    },
    {
        "id": "2380",
        "AirportCode": "FXO",
        "AirportName": "Cuamba Airport",
        "City": "Cuamba",
        "Country": "Mozambique"
    },
    {
        "id": "2381",
        "AirportCode": "FXY",
        "AirportName": "Forest City Municipal Airport",
        "City": "Forest City",
        "Country": "USA Iowa"
    },
    {
        "id": "2382",
        "AirportCode": "FYM",
        "AirportName": "Fayetteville Municipal Airport",
        "City": "Fayetteville",
        "Country": "USA Tennessee"
    },
    {
        "id": "2383",
        "AirportCode": "FYN",
        "AirportName": "Fuyun Airport",
        "City": "Fuyun",
        "Country": "China"
    },
    {
        "id": "2384",
        "AirportCode": "FYT",
        "AirportName": "Faya Airport",
        "City": "Faya",
        "Country": "Mali"
    },
    {
        "id": "2385",
        "AirportCode": "FYU",
        "AirportName": "Fort Yukon Airport",
        "City": "Fort Yukon",
        "Country": "USA Alaska"
    },
    {
        "id": "2386",
        "AirportCode": "FYV",
        "AirportName": "Fayetteville Municipal Airport (Drake Field)",
        "City": "Fayetteville",
        "Country": "USA Arkansas"
    },
    {
        "id": "2387",
        "AirportCode": "FZO",
        "AirportName": "Filton Airport",
        "City": "Filton",
        "Country": "United Kingdom"
    },
    {
        "id": "2388",
        "AirportCode": "GAA",
        "AirportName": "Guamal Airport",
        "City": "Guamal",
        "Country": "Colombia"
    },
    {
        "id": "2389",
        "AirportCode": "GAB",
        "AirportName": "Gabbs Airport",
        "City": "Gabbs",
        "Country": "USA Nevada"
    },
    {
        "id": "2390",
        "AirportCode": "GAC",
        "AirportName": "Gracias Airport",
        "City": "Gracias",
        "Country": "Honduras"
    },
    {
        "id": "2391",
        "AirportCode": "GAD",
        "AirportName": "Gadsden Municipal Airport",
        "City": "Gadsden",
        "Country": "USA Alabama"
    },
    {
        "id": "2392",
        "AirportCode": "GAE",
        "AirportName": "Gabes Airport",
        "City": "Gabes",
        "Country": "Tunisia"
    },
    {
        "id": "2393",
        "AirportCode": "GAF",
        "AirportName": "Gafsa Airport",
        "City": "Gafsa",
        "Country": "Tunisia"
    },
    {
        "id": "2394",
        "AirportCode": "GAG",
        "AirportName": "Gage Airport",
        "City": "Gage",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2395",
        "AirportCode": "GAH",
        "AirportName": "Gayndah Airport",
        "City": "Gayndah",
        "Country": "Australia"
    },
    {
        "id": "2396",
        "AirportCode": "GAI",
        "AirportName": "Montgomery County Airport",
        "City": "Gaithersburg",
        "Country": "USA Maryland"
    },
    {
        "id": "2397",
        "AirportCode": "GAJ",
        "AirportName": "Junmachi Airport",
        "City": "Yamagata",
        "Country": "Japan"
    },
    {
        "id": "2398",
        "AirportCode": "GAL",
        "AirportName": "Galena Airport",
        "City": "Galena",
        "Country": "USA Alaska"
    },
    {
        "id": "2399",
        "AirportCode": "GAM",
        "AirportName": "Gambell Airport",
        "City": "Gambell",
        "Country": "USA Alaska"
    },
    {
        "id": "2400",
        "AirportCode": "GAN",
        "AirportName": "Gan/Seenu Airport",
        "City": "Gan Island",
        "Country": "Maldives"
    },
    {
        "id": "2401",
        "AirportCode": "GAO",
        "AirportName": "Los Canos Airport",
        "City": "Guantanamo",
        "Country": "Cuba"
    },
    {
        "id": "2402",
        "AirportCode": "GAP",
        "AirportName": "Gusap Airport",
        "City": "Gusap",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2403",
        "AirportCode": "GAQ",
        "AirportName": "Gao Airport",
        "City": "Gao",
        "Country": "Mali"
    },
    {
        "id": "2404",
        "AirportCode": "GAR",
        "AirportName": "Garaina Airport",
        "City": "Garaina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2405",
        "AirportCode": "GAS",
        "AirportName": "Garissa Airport",
        "City": "Garissa",
        "Country": "Kenya"
    },
    {
        "id": "2406",
        "AirportCode": "GAT",
        "AirportName": "Tallard Airport",
        "City": "Gap",
        "Country": "France"
    },
    {
        "id": "2407",
        "AirportCode": "GAU",
        "AirportName": "Borjhar Airport",
        "City": "Guwahati",
        "Country": "India"
    },
    {
        "id": "2408",
        "AirportCode": "GAV",
        "AirportName": "Gag Island Airport",
        "City": "Gag Island",
        "Country": "Indonesia"
    },
    {
        "id": "2409",
        "AirportCode": "GAW",
        "AirportName": "Gangaw Airport",
        "City": "Gangaw",
        "Country": "Burma"
    },
    {
        "id": "2410",
        "AirportCode": "GAX",
        "AirportName": "Gamba Airport",
        "City": "Gamba",
        "Country": "Gabon"
    },
    {
        "id": "2411",
        "AirportCode": "GAY",
        "AirportName": "Gaya Airport",
        "City": "Gaya",
        "Country": "India"
    },
    {
        "id": "2412",
        "AirportCode": "GAZ",
        "AirportName": "Guasopa Airport",
        "City": "Guasopa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2413",
        "AirportCode": "GBA",
        "AirportName": "Big Bay Airport",
        "City": "Big Bay",
        "Country": "Vanuatu"
    },
    {
        "id": "2414",
        "AirportCode": "GBB",
        "AirportName": "Gara-Djebilet Airport",
        "City": "Gara-Djebilet",
        "Country": "Algeria"
    },
    {
        "id": "2415",
        "AirportCode": "GBC",
        "AirportName": "Gasuke Airport",
        "City": "Gasuke",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2416",
        "AirportCode": "GBD",
        "AirportName": "Great Bend Airport",
        "City": "Great Bend",
        "Country": "USA Kansas"
    },
    {
        "id": "2417",
        "AirportCode": "GBE",
        "AirportName": "Sir Seretse Khama International Airport",
        "City": "Gaborone",
        "Country": "Botswana"
    },
    {
        "id": "2418",
        "AirportCode": "GBF",
        "AirportName": "Negarbo Airport",
        "City": "Negarbo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2419",
        "AirportCode": "GBG",
        "AirportName": "Galesburg Airport",
        "City": "Galesburg",
        "Country": "USA Illinois"
    },
    {
        "id": "2420",
        "AirportCode": "GBJ",
        "AirportName": "Les Bases Airport",
        "City": "Marie Galante",
        "Country": "Guadeloupe"
    },
    {
        "id": "2421",
        "AirportCode": "GBK",
        "AirportName": "Gbangbatok Airport",
        "City": "Gbangbatok",
        "Country": "Sierra Leone"
    },
    {
        "id": "2422",
        "AirportCode": "GBL",
        "AirportName": "Goulburn Island Airport",
        "City": "Goulburn Island",
        "Country": "Australia"
    },
    {
        "id": "2423",
        "AirportCode": "GBM",
        "AirportName": "Garbaharey Airport",
        "City": "Garbaharey",
        "Country": "Somalia"
    },
    {
        "id": "2424",
        "AirportCode": "GBN",
        "AirportName": "San Giovanni Rotondo Airport",
        "City": "San Giovanni Rotondo",
        "Country": "Italy"
    },
    {
        "id": "2425",
        "AirportCode": "GBO",
        "AirportName": "Baltimore Greenbelt T Airport",
        "City": "Baltimore",
        "Country": "USA Maryland"
    },
    {
        "id": "2426",
        "AirportCode": "GBP",
        "AirportName": "Gamboola Airport",
        "City": "Gamboola",
        "Country": "Australia"
    },
    {
        "id": "2427",
        "AirportCode": "GBQ",
        "AirportName": "Muharraq Airport",
        "City": "Muharraq",
        "Country": "Bahrain"
    },
    {
        "id": "2428",
        "AirportCode": "GBR",
        "AirportName": "Great Barrington Airport",
        "City": "Great Barrington",
        "Country": "USA Massachusetts"
    },
    {
        "id": "2429",
        "AirportCode": "GBS",
        "AirportName": "Port Fitzroy Airport",
        "City": "Port Fitzroy",
        "Country": "New Zealand"
    },
    {
        "id": "2430",
        "AirportCode": "GBT",
        "AirportName": "Gorgon Airport",
        "City": "Gorgon",
        "Country": "Iran"
    },
    {
        "id": "2431",
        "AirportCode": "GBU",
        "AirportName": "Khashm El Girba Airport",
        "City": "Khashm El Girba",
        "Country": "Sudan"
    },
    {
        "id": "2432",
        "AirportCode": "GBV",
        "AirportName": "Gibb River Airport",
        "City": "Gibb River",
        "Country": "Australia"
    },
    {
        "id": "2433",
        "AirportCode": "GBZ",
        "AirportName": "Great Barrier Island Airport",
        "City": "Great Barrier Island",
        "Country": "New Zealand"
    },
    {
        "id": "2434",
        "AirportCode": "GCA",
        "AirportName": "Guacamaya Airport",
        "City": "Guacamaya",
        "Country": "Colombia"
    },
    {
        "id": "2435",
        "AirportCode": "GCC",
        "AirportName": "Campbell County Airport",
        "City": "Gillette",
        "Country": "USA Wyoming"
    },
    {
        "id": "2436",
        "AirportCode": "GCH",
        "AirportName": "Gachsaran Airport",
        "City": "Gachsaran",
        "Country": "Iran"
    },
    {
        "id": "2437",
        "AirportCode": "GCI",
        "AirportName": "Guernsey Airport",
        "City": "Guernsey",
        "Country": "United Kingdom"
    },
    {
        "id": "2438",
        "AirportCode": "GCJ",
        "AirportName": "Johannesburg-Grand Central Airport",
        "City": "Johannesburg",
        "Country": "South Africa"
    },
    {
        "id": "2439",
        "AirportCode": "GCK",
        "AirportName": "Garden City Municipal Airport",
        "City": "Garden City",
        "Country": "USA Kansas"
    },
    {
        "id": "2440",
        "AirportCode": "GCM",
        "AirportName": "Owen Roberts International Airport",
        "City": "Grand Cayman Island",
        "Country": "Cayman Islands"
    },
    {
        "id": "2441",
        "AirportCode": "GCN",
        "AirportName": "National Park Airport",
        "City": "Grand Canyon",
        "Country": "USA Arizona"
    },
    {
        "id": "2442",
        "AirportCode": "GCV",
        "AirportName": "Gravatai Airport",
        "City": "Gravatai",
        "Country": "Brazil"
    },
    {
        "id": "2443",
        "AirportCode": "GCY",
        "AirportName": "Greenville Municipal Airport",
        "City": "Greenville",
        "Country": "USA Tennessee"
    },
    {
        "id": "2444",
        "AirportCode": "GDA",
        "AirportName": "Gounda Airport",
        "City": "Gounda",
        "Country": "Central African Republic"
    },
    {
        "id": "2445",
        "AirportCode": "GDC",
        "AirportName": "Donaldson Center Airport",
        "City": "Greenville",
        "Country": "USA South Carolina"
    },
    {
        "id": "2446",
        "AirportCode": "GDD",
        "AirportName": "Gordon Downs Airport",
        "City": "Gordon Downs",
        "Country": "Australia"
    },
    {
        "id": "2447",
        "AirportCode": "GDE",
        "AirportName": "Gode/Iddidole Airport",
        "City": "Gode/Iddidole",
        "Country": "Ethiopia"
    },
    {
        "id": "2448",
        "AirportCode": "GDG",
        "AirportName": "Magdagachi Airport",
        "City": "Magdagachi",
        "Country": "Russia"
    },
    {
        "id": "2449",
        "AirportCode": "GDI",
        "AirportName": "Gordil Airport",
        "City": "Gordil",
        "Country": "Central African Republic"
    },
    {
        "id": "2450",
        "AirportCode": "GDJ",
        "AirportName": "Gandajika Airport",
        "City": "Gandajika",
        "Country": "Congo, DR"
    },
    {
        "id": "2451",
        "AirportCode": "GDL",
        "AirportName": "Miguel Hidal Airport",
        "City": "Guadalajara",
        "Country": "Mexico"
    },
    {
        "id": "2452",
        "AirportCode": "GDM",
        "AirportName": "Gardner Municipal Airport",
        "City": "Gardner",
        "Country": "USA Massachusetts"
    },
    {
        "id": "2453",
        "AirportCode": "GDN",
        "AirportName": "Rebiechowo Airport",
        "City": "Gdansk",
        "Country": "Poland"
    },
    {
        "id": "2454",
        "AirportCode": "GDO",
        "AirportName": "Vare Maria Airport",
        "City": "Guasdualito",
        "Country": "Venezuela"
    },
    {
        "id": "2455",
        "AirportCode": "GDP",
        "AirportName": "Guadalupe Airport",
        "City": "Guadalupe",
        "Country": "Brazil"
    },
    {
        "id": "2456",
        "AirportCode": "GDQ",
        "AirportName": "Gondar Airport",
        "City": "Gondar",
        "Country": "Ethiopia"
    },
    {
        "id": "2457",
        "AirportCode": "GDT",
        "AirportName": "Grand Turk Island Airport",
        "City": "Grand Turk Island",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "2458",
        "AirportCode": "GDV",
        "AirportName": "Dawson Community Airport",
        "City": "Glendive",
        "Country": "USA Montana"
    },
    {
        "id": "2459",
        "AirportCode": "GDW",
        "AirportName": "Gladwin Airport",
        "City": "Gladwin",
        "Country": "USA Michigan"
    },
    {
        "id": "2460",
        "AirportCode": "GDX",
        "AirportName": "Magadan Airport",
        "City": "Magadan",
        "Country": "Russia"
    },
    {
        "id": "2461",
        "AirportCode": "GDZ",
        "AirportName": "Gelendzik Airport",
        "City": "Gelendzik",
        "Country": "Russia"
    },
    {
        "id": "2462",
        "AirportCode": "GEA",
        "AirportName": "Magenta Airport",
        "City": "Noumea",
        "Country": "New Caledonia"
    },
    {
        "id": "2463",
        "AirportCode": "GEB",
        "AirportName": "Gebe Airport",
        "City": "Gebe",
        "Country": "Indonesia"
    },
    {
        "id": "2464",
        "AirportCode": "GEC",
        "AirportName": "Gecitkale Airport",
        "City": "Gecitkale",
        "Country": "Cyprus"
    },
    {
        "id": "2465",
        "AirportCode": "GED",
        "AirportName": "Sussex County Airport",
        "City": "Georgetown",
        "Country": "USA Delaware"
    },
    {
        "id": "2466",
        "AirportCode": "GEE",
        "AirportName": "George Town Airport",
        "City": "George Town",
        "Country": "Australia"
    },
    {
        "id": "2467",
        "AirportCode": "GEG",
        "AirportName": "Spokane International Airport",
        "City": "Spokane",
        "Country": "USA Washington"
    },
    {
        "id": "2468",
        "AirportCode": "GEI",
        "AirportName": "Green Islands Airport",
        "City": "Green Islands",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2469",
        "AirportCode": "GEL",
        "AirportName": "Sepe Tiaraju Airport",
        "City": "Santo Angelo",
        "Country": "Brazil"
    },
    {
        "id": "2470",
        "AirportCode": "GEN",
        "AirportName": "Puente Genil Airport",
        "City": "Puente Genil",
        "Country": "Spain"
    },
    {
        "id": "2471",
        "AirportCode": "GEO",
        "AirportName": "Cheddi Jagan International Airport",
        "City": "Georgetown",
        "Country": "Guyana"
    },
    {
        "id": "2472",
        "AirportCode": "GER",
        "AirportName": "Rafael Cabrera Airport",
        "City": "Nueva Gerona",
        "Country": "Cuba"
    },
    {
        "id": "2473",
        "AirportCode": "GES",
        "AirportName": "Buayan Airport",
        "City": "General Santos",
        "Country": "Philippines"
    },
    {
        "id": "2474",
        "AirportCode": "GET",
        "AirportName": "Geraldton Airport",
        "City": "Geraldton",
        "Country": "Australia"
    },
    {
        "id": "2475",
        "AirportCode": "GEV",
        "AirportName": "Gallivare Airport",
        "City": "Gallivare",
        "Country": "Sweden"
    },
    {
        "id": "2476",
        "AirportCode": "GEW",
        "AirportName": "Gewoya Airport",
        "City": "Gewoya",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2477",
        "AirportCode": "GEX",
        "AirportName": "Geelong Airport",
        "City": "Geelong",
        "Country": "Australia"
    },
    {
        "id": "2478",
        "AirportCode": "GEY",
        "AirportName": "South Big Horn County Airport",
        "City": "Greybull",
        "Country": "USA Wyoming"
    },
    {
        "id": "2479",
        "AirportCode": "GFA",
        "AirportName": "Malmstrom Air Force Base",
        "City": "Great Falls",
        "Country": "USA Montana"
    },
    {
        "id": "2480",
        "AirportCode": "GFD",
        "AirportName": "Pope Field",
        "City": "Greenfield",
        "Country": "USA Indiana"
    },
    {
        "id": "2481",
        "AirportCode": "GFE",
        "AirportName": "Grenfell Airport",
        "City": "Grenfell",
        "Country": "Australia"
    },
    {
        "id": "2482",
        "AirportCode": "GFF",
        "AirportName": "Griffith Airport",
        "City": "Griffith",
        "Country": "Australia"
    },
    {
        "id": "2483",
        "AirportCode": "GFK",
        "AirportName": "Grand Forks Airport",
        "City": "Grand Forks",
        "Country": "USA North Dakota"
    },
    {
        "id": "2484",
        "AirportCode": "GFL",
        "AirportName": "Warren County Airport",
        "City": "Glens Falls",
        "Country": "USA New York"
    },
    {
        "id": "2485",
        "AirportCode": "GFN",
        "AirportName": "Grafton Airport",
        "City": "Grafton",
        "Country": "Australia"
    },
    {
        "id": "2486",
        "AirportCode": "GFO",
        "AirportName": "Bartica Airport",
        "City": "Bartica",
        "Country": "Guyana"
    },
    {
        "id": "2487",
        "AirportCode": "GFR",
        "AirportName": "Granville Airport",
        "City": "Granville",
        "Country": "France"
    },
    {
        "id": "2488",
        "AirportCode": "GFY",
        "AirportName": "Grootfontein Airport",
        "City": "Grootfontein",
        "Country": "Namibia"
    },
    {
        "id": "2489",
        "AirportCode": "GGD",
        "AirportName": "Gregory Downs Airport",
        "City": "Gregory Downs",
        "Country": "Australia"
    },
    {
        "id": "2490",
        "AirportCode": "GGE",
        "AirportName": "Georgetown Airport",
        "City": "Georgetown",
        "Country": "USA South Carolina"
    },
    {
        "id": "2491",
        "AirportCode": "GGG",
        "AirportName": "Gregg County Airport",
        "City": "Longview",
        "Country": "USA Texas"
    },
    {
        "id": "2492",
        "AirportCode": "GGL",
        "AirportName": "Gilgal Airport",
        "City": "Gilgal",
        "Country": "Colombia"
    },
    {
        "id": "2493",
        "AirportCode": "GGR",
        "AirportName": "Garoe Airport",
        "City": "Garoe",
        "Country": "Somalia"
    },
    {
        "id": "2494",
        "AirportCode": "GGS",
        "AirportName": "Gobernador Gregores Airport",
        "City": "Gobernador Gregores",
        "Country": "Argentina"
    },
    {
        "id": "2495",
        "AirportCode": "GGT",
        "AirportName": "Exuma International Airport",
        "City": "George Town",
        "Country": "Bahamas"
    },
    {
        "id": "2496",
        "AirportCode": "GGW",
        "AirportName": "Glasgow International Airport",
        "City": "Glasgow",
        "Country": "USA Montana"
    },
    {
        "id": "2497",
        "AirportCode": "GHA",
        "AirportName": "Noumerate Airport",
        "City": "Ghardaia",
        "Country": "Algeria"
    },
    {
        "id": "2498",
        "AirportCode": "GHD",
        "AirportName": "Ghimbi Airport",
        "City": "Ghimbi",
        "Country": "Ethiopia"
    },
    {
        "id": "2499",
        "AirportCode": "GHE",
        "AirportName": "Garachine Airport",
        "City": "Garachine",
        "Country": "Panama"
    },
    {
        "id": "2500",
        "AirportCode": "GHF",
        "AirportName": "Giebelstadt Airport",
        "City": "Giebelstadt",
        "Country": "Germany"
    },
    {
        "id": "2501",
        "AirportCode": "GHM",
        "AirportName": "Centerville Municipal Airport",
        "City": "Centerville",
        "Country": "USA Tennessee"
    },
    {
        "id": "2502",
        "AirportCode": "GHN",
        "AirportName": "Guanghan Airport",
        "City": "Guanghan",
        "Country": "China"
    },
    {
        "id": "2503",
        "AirportCode": "GHT",
        "AirportName": "Ghat Airport",
        "City": "Ghat",
        "Country": "Libya"
    },
    {
        "id": "2504",
        "AirportCode": "GHU",
        "AirportName": "Gualeguaychu Airport",
        "City": "Gualeguaychu",
        "Country": "Argentina"
    },
    {
        "id": "2505",
        "AirportCode": "GIB",
        "AirportName": "North Front Airport",
        "City": "Gibraltar",
        "Country": "Gibraltar"
    },
    {
        "id": "2506",
        "AirportCode": "GIC",
        "AirportName": "Boigu Island Airport",
        "City": "Boigu Island",
        "Country": "Australia"
    },
    {
        "id": "2507",
        "AirportCode": "GID",
        "AirportName": "Gitega Airport",
        "City": "Gitega",
        "Country": "Burundi"
    },
    {
        "id": "2508",
        "AirportCode": "GIF",
        "AirportName": "Gilbert Field Airport",
        "City": "Winter Haven",
        "Country": "USA Florida"
    },
    {
        "id": "2509",
        "AirportCode": "GIG",
        "AirportName": "Galeao Antonio Carlos Jobim International Airport",
        "City": "Rio De Janeiro",
        "Country": "Brazil"
    },
    {
        "id": "2510",
        "AirportCode": "GII",
        "AirportName": "Siguiri Airport",
        "City": "Siguiri",
        "Country": "Guinea"
    },
    {
        "id": "2511",
        "AirportCode": "GIL",
        "AirportName": "Gilgit Airport",
        "City": "Gilgit",
        "Country": "Pakistan"
    },
    {
        "id": "2512",
        "AirportCode": "GIM",
        "AirportName": "Miele Mimbale Airport",
        "City": "Miele Mimbale",
        "Country": "Gabon"
    },
    {
        "id": "2513",
        "AirportCode": "GIR",
        "AirportName": "Girardot Airport",
        "City": "Girardot",
        "Country": "Colombia"
    },
    {
        "id": "2514",
        "AirportCode": "GIS",
        "AirportName": "Gisborne Airport",
        "City": "Gisborne",
        "Country": "New Zealand"
    },
    {
        "id": "2515",
        "AirportCode": "GIT",
        "AirportName": "Geita Airport",
        "City": "Geita",
        "Country": "Tanzania"
    },
    {
        "id": "2516",
        "AirportCode": "GIY",
        "AirportName": "Giyani Airport",
        "City": "Giyani",
        "Country": "South Africa"
    },
    {
        "id": "2517",
        "AirportCode": "GIZ",
        "AirportName": "Jazan Airport",
        "City": "Jazan",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2518",
        "AirportCode": "GJA",
        "AirportName": "Guanaja Airport",
        "City": "Guanaja",
        "Country": "Honduras"
    },
    {
        "id": "2519",
        "AirportCode": "GJL",
        "AirportName": "Jijel Airport",
        "City": "Jijel",
        "Country": "Algeria"
    },
    {
        "id": "2520",
        "AirportCode": "GJM",
        "AirportName": "Guajara-Mirim Airport",
        "City": "Guajara-Mirim",
        "Country": "Brazil"
    },
    {
        "id": "2521",
        "AirportCode": "GJR",
        "AirportName": "Gjogur Airport",
        "City": "Gjogur",
        "Country": "Iceland"
    },
    {
        "id": "2522",
        "AirportCode": "GJT",
        "AirportName": "Grand Junction Regional Airport",
        "City": "Grand Junction",
        "Country": "USA Colorado"
    },
    {
        "id": "2523",
        "AirportCode": "GKA",
        "AirportName": "Goroka Airport",
        "City": "Goroka",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2524",
        "AirportCode": "GKE",
        "AirportName": "Geilenkirchen Airport",
        "City": "Geilenkirchen",
        "Country": "Germany"
    },
    {
        "id": "2525",
        "AirportCode": "GKH",
        "AirportName": "Gorkha Airport",
        "City": "Gorkha",
        "Country": "Nepal"
    },
    {
        "id": "2526",
        "AirportCode": "GKL",
        "AirportName": "Great Keppel Island Airport",
        "City": "Great Keppel Island",
        "Country": "Australia"
    },
    {
        "id": "2527",
        "AirportCode": "GKO",
        "AirportName": "Kongoboumba Airport",
        "City": "Kongoboumba",
        "Country": "Gabon"
    },
    {
        "id": "2528",
        "AirportCode": "GKT",
        "AirportName": "Gatlinburg Airport",
        "City": "Gatlinburg",
        "Country": "USA Tennessee"
    },
    {
        "id": "2529",
        "AirportCode": "GLA",
        "AirportName": "Glasgow International Airport",
        "City": "Glasgow",
        "Country": "United Kingdom"
    },
    {
        "id": "2530",
        "AirportCode": "GLC",
        "AirportName": "Geladi Airport",
        "City": "Geladi",
        "Country": "Ethiopia"
    },
    {
        "id": "2531",
        "AirportCode": "GLD",
        "AirportName": "Renner Field",
        "City": "Goodland",
        "Country": "USA Kansas"
    },
    {
        "id": "2532",
        "AirportCode": "GLE",
        "AirportName": "Gainesville Municipal Airport",
        "City": "Gainesville",
        "Country": "USA Texas"
    },
    {
        "id": "2533",
        "AirportCode": "GLF",
        "AirportName": "Golfito Airport",
        "City": "Golfito",
        "Country": "Costa Rica"
    },
    {
        "id": "2534",
        "AirportCode": "GLG",
        "AirportName": "Glengyle Airport",
        "City": "Glengyle",
        "Country": "Australia"
    },
    {
        "id": "2535",
        "AirportCode": "GLH",
        "AirportName": "Greenville Airport",
        "City": "Greenville",
        "Country": "USA Mississippi"
    },
    {
        "id": "2536",
        "AirportCode": "GLI",
        "AirportName": "Glen Innes Airport",
        "City": "Glen Innes",
        "Country": "Australia"
    },
    {
        "id": "2537",
        "AirportCode": "GLK",
        "AirportName": "Galcaio Airport",
        "City": "Galcaio",
        "Country": "Somalia"
    },
    {
        "id": "2538",
        "AirportCode": "GLL",
        "AirportName": "Klanten Airport",
        "City": "Gol",
        "Country": "Norway"
    },
    {
        "id": "2539",
        "AirportCode": "GLM",
        "AirportName": "Glenormiston Airport",
        "City": "Glenormiston",
        "Country": "Australia"
    },
    {
        "id": "2540",
        "AirportCode": "GLN",
        "AirportName": "Goulimime Airport",
        "City": "Goulimime",
        "Country": "Morocco"
    },
    {
        "id": "2541",
        "AirportCode": "GLO",
        "AirportName": "Gloucestershire Airport",
        "City": "Gloucester",
        "Country": "United Kingdom"
    },
    {
        "id": "2542",
        "AirportCode": "GLP",
        "AirportName": "Gulgubip Airport",
        "City": "Gulgubip",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2543",
        "AirportCode": "GLR",
        "AirportName": "Otsego County Airport",
        "City": "Gaylord",
        "Country": "USA Michigan"
    },
    {
        "id": "2544",
        "AirportCode": "GLS",
        "AirportName": "Scholes Field",
        "City": "Galveston",
        "Country": "USA Texas"
    },
    {
        "id": "2545",
        "AirportCode": "GLT",
        "AirportName": "Gladstone Airport",
        "City": "Gladstone",
        "Country": "Australia"
    },
    {
        "id": "2546",
        "AirportCode": "GLV",
        "AirportName": "Golovin Airport",
        "City": "Golovin",
        "Country": "USA Alaska"
    },
    {
        "id": "2547",
        "AirportCode": "GLW",
        "AirportName": "Glasgow Municipal Airport",
        "City": "Glasgow",
        "Country": "USA Kentucky"
    },
    {
        "id": "2548",
        "AirportCode": "GLX",
        "AirportName": "Galela Airport",
        "City": "Galela",
        "Country": "Indonesia"
    },
    {
        "id": "2549",
        "AirportCode": "GLY",
        "AirportName": "Goldsworthy Airport",
        "City": "Goldsworthy",
        "Country": "Australia"
    },
    {
        "id": "2550",
        "AirportCode": "GLZ",
        "AirportName": "Gilze-Rijen Airport",
        "City": "Breda",
        "Country": "Netherlands"
    },
    {
        "id": "2551",
        "AirportCode": "GMA",
        "AirportName": "Gemena Airport",
        "City": "Gemena",
        "Country": "Congo, DR"
    },
    {
        "id": "2552",
        "AirportCode": "GMB",
        "AirportName": "Gambela Airport",
        "City": "Gambela",
        "Country": "Ethiopia"
    },
    {
        "id": "2553",
        "AirportCode": "GMC",
        "AirportName": "Guerima Airport",
        "City": "Guerima",
        "Country": "Colombia"
    },
    {
        "id": "2554",
        "AirportCode": "GME",
        "AirportName": "Gomel Airport",
        "City": "Gomel",
        "Country": "Belarus"
    },
    {
        "id": "2555",
        "AirportCode": "GMI",
        "AirportName": "Gasmata Island Airport",
        "City": "Gasmata Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2556",
        "AirportCode": "GMM",
        "AirportName": "Gamboma Airport",
        "City": "Gamboma",
        "Country": "Congo"
    },
    {
        "id": "2557",
        "AirportCode": "GMN",
        "AirportName": "Greymouth Airport",
        "City": "Greymouth",
        "Country": "New Zealand"
    },
    {
        "id": "2558",
        "AirportCode": "GMP",
        "AirportName": "Gimpo International Airport",
        "City": "Seoul",
        "Country": "Korea"
    },
    {
        "id": "2559",
        "AirportCode": "GMR",
        "AirportName": "Gambier Island Airport",
        "City": "Gambier Island",
        "Country": "French Polynesia"
    },
    {
        "id": "2560",
        "AirportCode": "GMS",
        "AirportName": "Guimaraes Airport",
        "City": "Guimaraes",
        "Country": "Brazil"
    },
    {
        "id": "2561",
        "AirportCode": "GMU",
        "AirportName": "Greenville Downtown Airport",
        "City": "Greenville",
        "Country": "USA South Carolina"
    },
    {
        "id": "2562",
        "AirportCode": "GMV",
        "AirportName": "Monument Valley Airport",
        "City": "Monument Valley Gouldings",
        "Country": "USA Utah"
    },
    {
        "id": "2563",
        "AirportCode": "GMY",
        "AirportName": "Rheindahlen Airport",
        "City": "Rheindahlen",
        "Country": "Germany"
    },
    {
        "id": "2564",
        "AirportCode": "GMZ",
        "AirportName": "La Gomera Airport",
        "City": "San Sebastian de la Gomera",
        "Country": "Spain"
    },
    {
        "id": "2565",
        "AirportCode": "GNA",
        "AirportName": "Grodno Airport",
        "City": "Grodno",
        "Country": "Belarus"
    },
    {
        "id": "2566",
        "AirportCode": "GNB",
        "AirportName": "Grenoble-Isere Airport",
        "City": "Grenoble",
        "Country": "France"
    },
    {
        "id": "2567",
        "AirportCode": "GND",
        "AirportName": "Point Saline International Airport",
        "City": "Grenada",
        "Country": "Grenada"
    },
    {
        "id": "2568",
        "AirportCode": "GNE",
        "AirportName": "Ghent Airport",
        "City": "Ghent",
        "Country": "Belgium"
    },
    {
        "id": "2569",
        "AirportCode": "GNG",
        "AirportName": "Gooding Airport",
        "City": "Gooding",
        "Country": "USA Idaho"
    },
    {
        "id": "2570",
        "AirportCode": "GNI",
        "AirportName": "Green Island Airport",
        "City": "Green Island",
        "Country": "Taiwan"
    },
    {
        "id": "2571",
        "AirportCode": "GNM",
        "AirportName": "Guanambi Airport",
        "City": "Guanambi",
        "Country": "Brazil"
    },
    {
        "id": "2572",
        "AirportCode": "GNN",
        "AirportName": "Ghinnir Airport",
        "City": "Ghinnir",
        "Country": "Ethiopia"
    },
    {
        "id": "2573",
        "AirportCode": "GNR",
        "AirportName": "General Roca Airport",
        "City": "General Roca",
        "Country": "Argentina"
    },
    {
        "id": "2574",
        "AirportCode": "GNS",
        "AirportName": "Gunungsitoli Airport",
        "City": "Gunungsitoli",
        "Country": "Indonesia"
    },
    {
        "id": "2575",
        "AirportCode": "GNT",
        "AirportName": "Milan Airport",
        "City": "Grants",
        "Country": "USA New Mexico"
    },
    {
        "id": "2576",
        "AirportCode": "GNU",
        "AirportName": "Goodnews Bay Airport",
        "City": "Goodnews Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "2577",
        "AirportCode": "GNV",
        "AirportName": "Gainesville Regional Airport",
        "City": "Gainesville",
        "Country": "USA Florida"
    },
    {
        "id": "2578",
        "AirportCode": "GNY",
        "AirportName": "Granby-Grand Co",
        "City": "Granby",
        "Country": "USA Colorado"
    },
    {
        "id": "2579",
        "AirportCode": "GNZ",
        "AirportName": "Ghanzi Airport",
        "City": "Ghanzi",
        "Country": "Botswana"
    },
    {
        "id": "2580",
        "AirportCode": "GOA",
        "AirportName": "Cristoforo Colombo Airport",
        "City": "Genoa",
        "Country": "Italy"
    },
    {
        "id": "2581",
        "AirportCode": "GOB",
        "AirportName": "Goba Airport",
        "City": "Goba",
        "Country": "Ethiopia"
    },
    {
        "id": "2582",
        "AirportCode": "GOC",
        "AirportName": "Gora Airport",
        "City": "Gora",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2583",
        "AirportCode": "GOE",
        "AirportName": "Gonalia Airport",
        "City": "Gonalia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2584",
        "AirportCode": "GOF",
        "AirportName": "Goodfellow Air Force Base",
        "City": "San Angelo",
        "Country": "USA Texas"
    },
    {
        "id": "2585",
        "AirportCode": "GOG",
        "AirportName": "Gobabis Airport",
        "City": "Gobabis",
        "Country": "Namibia"
    },
    {
        "id": "2586",
        "AirportCode": "GOH",
        "AirportName": "Nuuk Airport",
        "City": "Nuuk",
        "Country": "Greenland"
    },
    {
        "id": "2587",
        "AirportCode": "GOI",
        "AirportName": "Dabolim Airport",
        "City": "Goa",
        "Country": "India"
    },
    {
        "id": "2588",
        "AirportCode": "GOJ",
        "AirportName": "Nizhniy Novgorod Airport",
        "City": "Nizhniy Novgorod",
        "Country": "Russia"
    },
    {
        "id": "2589",
        "AirportCode": "GOK",
        "AirportName": "Guthrie Airport",
        "City": "Guthrie",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2590",
        "AirportCode": "GOL",
        "AirportName": "State Airport",
        "City": "Gold Beach",
        "Country": "USA Oregon"
    },
    {
        "id": "2591",
        "AirportCode": "GOM",
        "AirportName": "Goma Airport",
        "City": "Goma",
        "Country": "Congo, DR"
    },
    {
        "id": "2592",
        "AirportCode": "GON",
        "AirportName": "Groton-New London Airport",
        "City": "New London",
        "Country": "USA Connecticut"
    },
    {
        "id": "2593",
        "AirportCode": "GOO",
        "AirportName": "Goondiwindi Airport",
        "City": "Goondiwindi",
        "Country": "Australia"
    },
    {
        "id": "2594",
        "AirportCode": "GOP",
        "AirportName": "Gorakhpur Airport",
        "City": "Gorakhpur",
        "Country": "India"
    },
    {
        "id": "2595",
        "AirportCode": "GOQ",
        "AirportName": "Golmud Airport",
        "City": "Golmud",
        "Country": "China"
    },
    {
        "id": "2596",
        "AirportCode": "GOR",
        "AirportName": "Gore Airport",
        "City": "Gore",
        "Country": "Ethiopia"
    },
    {
        "id": "2597",
        "AirportCode": "GOS",
        "AirportName": "Gosford Airport",
        "City": "Gosford",
        "Country": "Australia"
    },
    {
        "id": "2598",
        "AirportCode": "GOT",
        "AirportName": "Landvetter Airport",
        "City": "Gothenburg",
        "Country": "Sweden"
    },
    {
        "id": "2599",
        "AirportCode": "GOU",
        "AirportName": "Garoua Airport",
        "City": "Garoua",
        "Country": "Cameroon"
    },
    {
        "id": "2600",
        "AirportCode": "GOV",
        "AirportName": "Nhulunbuy Airport",
        "City": "Gove",
        "Country": "Australia"
    },
    {
        "id": "2601",
        "AirportCode": "GOY",
        "AirportName": "Amparai Airport",
        "City": "Gal Oya",
        "Country": "Sri Lanka"
    },
    {
        "id": "2602",
        "AirportCode": "GOZ",
        "AirportName": "Gorna Orechovitsa Airport",
        "City": "Gorna Orechovitsa",
        "Country": "Bulgaria"
    },
    {
        "id": "2603",
        "AirportCode": "GPA",
        "AirportName": "Araxos Airport",
        "City": "Patras",
        "Country": "Greece"
    },
    {
        "id": "2604",
        "AirportCode": "GPB",
        "AirportName": "Tancredo Thomaz Faria Airport",
        "City": "Guarapuava",
        "Country": "Brazil"
    },
    {
        "id": "2605",
        "AirportCode": "GPI",
        "AirportName": "Glacier Park International Airport",
        "City": "Kalispell",
        "Country": "USA Montana"
    },
    {
        "id": "2606",
        "AirportCode": "GPI",
        "AirportName": "Guapi Airport",
        "City": "Guapi",
        "Country": "Colombia"
    },
    {
        "id": "2607",
        "AirportCode": "GPL",
        "AirportName": "Guapiles Airport",
        "City": "Guapiles",
        "Country": "Costa Rica"
    },
    {
        "id": "2608",
        "AirportCode": "GPN",
        "AirportName": "Garden Point Airport",
        "City": "Garden Point",
        "Country": "Australia"
    },
    {
        "id": "2609",
        "AirportCode": "GPO",
        "AirportName": "General Pico Airport",
        "City": "General Pico",
        "Country": "Argentina"
    },
    {
        "id": "2610",
        "AirportCode": "GPS",
        "AirportName": "Baltra Airport",
        "City": "Galapagos Islands",
        "Country": "Ecuador"
    },
    {
        "id": "2611",
        "AirportCode": "GPT",
        "AirportName": "Biloxi Regional Airport",
        "City": "Gulfport",
        "Country": "USA Mississippi"
    },
    {
        "id": "2612",
        "AirportCode": "GPZ",
        "AirportName": "Grand Rapids Airport",
        "City": "Grand Rapids",
        "Country": "USA Minnesota"
    },
    {
        "id": "2613",
        "AirportCode": "GQJ",
        "AirportName": "Machrihanish Airport",
        "City": "Machrihanish",
        "Country": "United Kingdom"
    },
    {
        "id": "2614",
        "AirportCode": "GQQ",
        "AirportName": "Galion Airport",
        "City": "Galion",
        "Country": "USA Ohio"
    },
    {
        "id": "2615",
        "AirportCode": "GRA",
        "AirportName": "Gamarra Airport",
        "City": "Gamarra",
        "Country": "Colombia"
    },
    {
        "id": "2616",
        "AirportCode": "GRB",
        "AirportName": "Austin-Straubel Field",
        "City": "Green Bay",
        "Country": "USA Wisconsin"
    },
    {
        "id": "2617",
        "AirportCode": "GRC",
        "AirportName": "Grand Cess Airport",
        "City": "Grand Cess",
        "Country": "Liberia"
    },
    {
        "id": "2618",
        "AirportCode": "GRD",
        "AirportName": "Greenwood Airport",
        "City": "Greenwood",
        "Country": "USA South Carolina"
    },
    {
        "id": "2619",
        "AirportCode": "GRE",
        "AirportName": "Greenville Municipal Airport",
        "City": "Greenville",
        "Country": "USA Illinois"
    },
    {
        "id": "2620",
        "AirportCode": "GRF",
        "AirportName": "Gray Army Air Field",
        "City": "Tacoma",
        "Country": "USA Washington"
    },
    {
        "id": "2621",
        "AirportCode": "GRG",
        "AirportName": "Gardez Airport",
        "City": "Gardez",
        "Country": "Afghanistan"
    },
    {
        "id": "2622",
        "AirportCode": "GRH",
        "AirportName": "Garuahi Airport",
        "City": "Garuahi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2623",
        "AirportCode": "GRI",
        "AirportName": "Grand Island Airport",
        "City": "Grand Island",
        "Country": "USA Nebraska"
    },
    {
        "id": "2624",
        "AirportCode": "GRJ",
        "AirportName": "George Airport",
        "City": "George",
        "Country": "South Africa"
    },
    {
        "id": "2625",
        "AirportCode": "GRK",
        "AirportName": "Gray Army Air Field",
        "City": "Killeen",
        "Country": "USA Texas"
    },
    {
        "id": "2626",
        "AirportCode": "GRL",
        "AirportName": "Garasa Airport",
        "City": "Garasa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2627",
        "AirportCode": "GRM",
        "AirportName": "Devils Track Airport",
        "City": "Grand Marais",
        "Country": "USA Minnesota"
    },
    {
        "id": "2628",
        "AirportCode": "GRN",
        "AirportName": "Gordon Airport",
        "City": "Gordon",
        "Country": "USA Nebraska"
    },
    {
        "id": "2629",
        "AirportCode": "GRO",
        "AirportName": "Costa Brava Airport",
        "City": "Girona",
        "Country": "Spain"
    },
    {
        "id": "2630",
        "AirportCode": "GRP",
        "AirportName": "Gurupi Airport",
        "City": "Gurupi",
        "Country": "Brazil"
    },
    {
        "id": "2631",
        "AirportCode": "GRQ",
        "AirportName": "Eelde Airport",
        "City": "Groningen",
        "Country": "Netherlands"
    },
    {
        "id": "2632",
        "AirportCode": "GRR",
        "AirportName": "Gerald R. Ford International Airport",
        "City": "Grand Rapids",
        "Country": "USA Michigan"
    },
    {
        "id": "2633",
        "AirportCode": "GRS",
        "AirportName": "Baccarini Airport",
        "City": "Grosseto",
        "Country": "Italy"
    },
    {
        "id": "2634",
        "AirportCode": "GRT",
        "AirportName": "Gujrat Airport",
        "City": "Gujrat",
        "Country": "Pakistan"
    },
    {
        "id": "2635",
        "AirportCode": "GRU",
        "AirportName": "Guarulhos International Airport",
        "City": "Sao Paulo",
        "Country": "Brazil"
    },
    {
        "id": "2636",
        "AirportCode": "GRV",
        "AirportName": "Groznyy Airport",
        "City": "Groznyy",
        "Country": "Russia"
    },
    {
        "id": "2637",
        "AirportCode": "GRW",
        "AirportName": "Graciosa Island Airport",
        "City": "Graciosa Island (Azores)",
        "Country": "Portugal"
    },
    {
        "id": "2638",
        "AirportCode": "GRX",
        "AirportName": "Granada Airport",
        "City": "Granada",
        "Country": "Spain"
    },
    {
        "id": "2639",
        "AirportCode": "GRY",
        "AirportName": "Grimsey Airport",
        "City": "Grimsey",
        "Country": "Iceland"
    },
    {
        "id": "2640",
        "AirportCode": "GRZ",
        "AirportName": "Thalerhof Airport",
        "City": "Graz",
        "Country": "Austria"
    },
    {
        "id": "2641",
        "AirportCode": "GSA",
        "AirportName": "Long Pasia Airport",
        "City": "Long Pasia",
        "Country": "Malaysia"
    },
    {
        "id": "2642",
        "AirportCode": "GSB",
        "AirportName": "Seymour Johnson Air Force Base",
        "City": "Goldsboro",
        "Country": "USA North Carolina"
    },
    {
        "id": "2643",
        "AirportCode": "GSC",
        "AirportName": "Gascoyne Junction Airport",
        "City": "Gascoyne Junction",
        "Country": "Australia"
    },
    {
        "id": "2644",
        "AirportCode": "GSE",
        "AirportName": "Saeve Airport",
        "City": "Gothenburg",
        "Country": "Sweden"
    },
    {
        "id": "2645",
        "AirportCode": "GSH",
        "AirportName": "Goshen Airport",
        "City": "Goshen",
        "Country": "USA Indiana"
    },
    {
        "id": "2646",
        "AirportCode": "GSL",
        "AirportName": "Taltheilei Narrows Airport",
        "City": "Taltheilei Narrows",
        "Country": "Canada"
    },
    {
        "id": "2647",
        "AirportCode": "GSM",
        "AirportName": "Gheshm Airport",
        "City": "Gheshm",
        "Country": "Iran"
    },
    {
        "id": "2648",
        "AirportCode": "GSN",
        "AirportName": "Mount Gunson Airport",
        "City": "Mount Gunson",
        "Country": "Australia"
    },
    {
        "id": "2649",
        "AirportCode": "GSO",
        "AirportName": "Piedmont Triad International Airport",
        "City": "Greensboro/High Point",
        "Country": "USA North Carolina"
    },
    {
        "id": "2650",
        "AirportCode": "GSP",
        "AirportName": "Greenville-Spartanburg Airport",
        "City": "Greenville",
        "Country": "USA South Carolina"
    },
    {
        "id": "2651",
        "AirportCode": "GSQ",
        "AirportName": "Shark Elowainat Airport",
        "City": "Shark Elowainat",
        "Country": "Egypt"
    },
    {
        "id": "2652",
        "AirportCode": "GSR",
        "AirportName": "Gardo Airport",
        "City": "Gardo",
        "Country": "Somalia"
    },
    {
        "id": "2653",
        "AirportCode": "GSS",
        "AirportName": "Sabi Sabi Airport",
        "City": "Sabi Sabi",
        "Country": "South Africa"
    },
    {
        "id": "2654",
        "AirportCode": "GST",
        "AirportName": "Gustavus Airport",
        "City": "Gustavus",
        "Country": "USA Alaska"
    },
    {
        "id": "2655",
        "AirportCode": "GSU",
        "AirportName": "Gedaref Airport",
        "City": "Gedaref",
        "Country": "Sudan"
    },
    {
        "id": "2656",
        "AirportCode": "GSY",
        "AirportName": "Binbrook Airport",
        "City": "Grimsby",
        "Country": "United Kingdom"
    },
    {
        "id": "2657",
        "AirportCode": "GTA",
        "AirportName": "Gatokae Aerodrom",
        "City": "Gatokae",
        "Country": "Solomon Islands"
    },
    {
        "id": "2658",
        "AirportCode": "GTB",
        "AirportName": "Genting Airport",
        "City": "Genting",
        "Country": "Malaysia"
    },
    {
        "id": "2659",
        "AirportCode": "GTE",
        "AirportName": "Alyangula Airport",
        "City": "Groote Island",
        "Country": "Australia"
    },
    {
        "id": "2660",
        "AirportCode": "GTF",
        "AirportName": "Great Falls International Airport",
        "City": "Great Falls",
        "Country": "USA Montana"
    },
    {
        "id": "2661",
        "AirportCode": "GTG",
        "AirportName": "Grantsburg Municipal Airport",
        "City": "Grantsburg",
        "Country": "USA Wisconsin"
    },
    {
        "id": "2662",
        "AirportCode": "GTI",
        "AirportName": "Guettin Airport",
        "City": "Guettin",
        "Country": "Germany"
    },
    {
        "id": "2663",
        "AirportCode": "GTK",
        "AirportName": "Sungei Tekai Airport",
        "City": "Sungei Tekai",
        "Country": "Malaysia"
    },
    {
        "id": "2664",
        "AirportCode": "GTN",
        "AirportName": "Glentanner Airport",
        "City": "Mount Cook",
        "Country": "New Zealand"
    },
    {
        "id": "2665",
        "AirportCode": "GTO",
        "AirportName": "Tolotio Airport",
        "City": "Gorontalo",
        "Country": "Indonesia"
    },
    {
        "id": "2666",
        "AirportCode": "GTR",
        "AirportName": "Golden Triangle Regional Airport",
        "City": "Columbus",
        "Country": "USA Mississippi"
    },
    {
        "id": "2667",
        "AirportCode": "GTS",
        "AirportName": "Granites Airport",
        "City": "Granites",
        "Country": "Australia"
    },
    {
        "id": "2668",
        "AirportCode": "GTT",
        "AirportName": "Georgetown Airport",
        "City": "Georgetown",
        "Country": "Australia"
    },
    {
        "id": "2669",
        "AirportCode": "GTW",
        "AirportName": "Holesov Airport",
        "City": "Zlin",
        "Country": "Czech Republic"
    },
    {
        "id": "2670",
        "AirportCode": "GTY",
        "AirportName": "Gettysburg Airport",
        "City": "Gettysburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "2671",
        "AirportCode": "GUA",
        "AirportName": "La Aurora Airport",
        "City": "Guatemala City",
        "Country": "Guatemala"
    },
    {
        "id": "2672",
        "AirportCode": "GUB",
        "AirportName": "Guerrero Negro Airport",
        "City": "Guerrero Negro",
        "Country": "Mexico"
    },
    {
        "id": "2673",
        "AirportCode": "GUC",
        "AirportName": "Gunnison Airport",
        "City": "Gunnison",
        "Country": "USA Colorado"
    },
    {
        "id": "2674",
        "AirportCode": "GUD",
        "AirportName": "Goundam Airport",
        "City": "Goundam",
        "Country": "Mali"
    },
    {
        "id": "2675",
        "AirportCode": "GUE",
        "AirportName": "Guriaso Airport",
        "City": "Guriaso",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2676",
        "AirportCode": "GUF",
        "AirportName": "Edwards Airport",
        "City": "Gulf Shores",
        "Country": "USA Alabama"
    },
    {
        "id": "2677",
        "AirportCode": "GUG",
        "AirportName": "Guari Airport",
        "City": "Guari",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2678",
        "AirportCode": "GUH",
        "AirportName": "Gunnedah Airport",
        "City": "Gunnedah",
        "Country": "Australia"
    },
    {
        "id": "2679",
        "AirportCode": "GUI",
        "AirportName": "Guiria Airport",
        "City": "Guiria",
        "Country": "Venezuela"
    },
    {
        "id": "2680",
        "AirportCode": "GUJ",
        "AirportName": "Guaratingueta Airport",
        "City": "Guaratingueta",
        "Country": "Brazil"
    },
    {
        "id": "2681",
        "AirportCode": "GUL",
        "AirportName": "Goulburn Airport",
        "City": "Goulburn",
        "Country": "Australia"
    },
    {
        "id": "2682",
        "AirportCode": "GUM",
        "AirportName": "A.B. Won Pat International Airport",
        "City": "Guam",
        "Country": "Guam"
    },
    {
        "id": "2683",
        "AirportCode": "GUN",
        "AirportName": "Gunter Air Force Base",
        "City": "Montgomery",
        "Country": "USA Alabama"
    },
    {
        "id": "2684",
        "AirportCode": "GUO",
        "AirportName": "Gualaco Airport",
        "City": "Gualaco",
        "Country": "Honduras"
    },
    {
        "id": "2685",
        "AirportCode": "GUP",
        "AirportName": "Senator Clark Airport",
        "City": "Gallup",
        "Country": "USA New Mexico"
    },
    {
        "id": "2686",
        "AirportCode": "GUQ",
        "AirportName": "Guanare Airport",
        "City": "Guanare",
        "Country": "Venezuela"
    },
    {
        "id": "2687",
        "AirportCode": "GUR",
        "AirportName": "Gurney Airport",
        "City": "Alotau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2688",
        "AirportCode": "GUS",
        "AirportName": "Grissom Air Force Base",
        "City": "Peru",
        "Country": "USA Indiana"
    },
    {
        "id": "2689",
        "AirportCode": "GUT",
        "AirportName": "Guetersloh Airport",
        "City": "Guetersloh",
        "Country": "Germany"
    },
    {
        "id": "2690",
        "AirportCode": "GUU",
        "AirportName": "Grundarf Jordur Airport",
        "City": "Grundarf Jordur",
        "Country": "Iceland"
    },
    {
        "id": "2691",
        "AirportCode": "GUV",
        "AirportName": "Mougulu Airport",
        "City": "Mougulu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2692",
        "AirportCode": "GUW",
        "AirportName": "Atyrau Airport",
        "City": "Atyrau",
        "Country": "Kazakhstan"
    },
    {
        "id": "2693",
        "AirportCode": "GUX",
        "AirportName": "Guna Airport",
        "City": "Guna",
        "Country": "India"
    },
    {
        "id": "2694",
        "AirportCode": "GUY",
        "AirportName": "Guymon Airport",
        "City": "Guymon",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2695",
        "AirportCode": "GUZ",
        "AirportName": "Guarapari Airport",
        "City": "Guarapari",
        "Country": "Brazil"
    },
    {
        "id": "2696",
        "AirportCode": "GVA",
        "AirportName": "Geneve-Cointrin Airport",
        "City": "Geneva",
        "Country": "Switzerland"
    },
    {
        "id": "2697",
        "AirportCode": "GVE",
        "AirportName": "Gordonsville Municipal Airport",
        "City": "Gordonsville",
        "Country": "USA Virginia"
    },
    {
        "id": "2698",
        "AirportCode": "GVI",
        "AirportName": "Green River Airport",
        "City": "Green River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2699",
        "AirportCode": "GVL",
        "AirportName": "Lee Gilmer Memorial Airport",
        "City": "Gainesville",
        "Country": "USA Georgia"
    },
    {
        "id": "2700",
        "AirportCode": "GVP",
        "AirportName": "Greenvale Airport",
        "City": "Greenvale",
        "Country": "Australia"
    },
    {
        "id": "2701",
        "AirportCode": "GVR",
        "AirportName": "Governador Valadares Airport",
        "City": "Governador Valadares",
        "Country": "Brazil"
    },
    {
        "id": "2702",
        "AirportCode": "GVT",
        "AirportName": "Majors Field",
        "City": "Greenville",
        "Country": "USA Texas"
    },
    {
        "id": "2703",
        "AirportCode": "GVW",
        "AirportName": "Richards-Gebaur Airport",
        "City": "Grandview",
        "Country": "USA Missouri"
    },
    {
        "id": "2704",
        "AirportCode": "GVX",
        "AirportName": "Sandviken Airport",
        "City": "Gavle",
        "Country": "Sweden"
    },
    {
        "id": "2705",
        "AirportCode": "GWA",
        "AirportName": "Gwa Airport",
        "City": "Gwa",
        "Country": "Burma"
    },
    {
        "id": "2706",
        "AirportCode": "GWD",
        "AirportName": "Gwadar Airport",
        "City": "Gwadar",
        "Country": "Pakistan"
    },
    {
        "id": "2707",
        "AirportCode": "GWE",
        "AirportName": "Gweru Airport",
        "City": "Gweru",
        "Country": "Zimbabwe"
    },
    {
        "id": "2708",
        "AirportCode": "GWL",
        "AirportName": "Gwalior Airport",
        "City": "Gwalior",
        "Country": "India"
    },
    {
        "id": "2709",
        "AirportCode": "GWN",
        "AirportName": "Gnarowein Airport",
        "City": "Gnarowein",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2710",
        "AirportCode": "GWO",
        "AirportName": "Leflore Airport",
        "City": "Greenwood",
        "Country": "USA Mississippi"
    },
    {
        "id": "2711",
        "AirportCode": "GWS",
        "AirportName": "Glenwood Springs Municipal",
        "City": "Glenwood Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "2712",
        "AirportCode": "GWT",
        "AirportName": "Westerland - Sylt Airport",
        "City": "Westerland",
        "Country": "Germany"
    },
    {
        "id": "2713",
        "AirportCode": "GWV",
        "AirportName": "Glendale Airport",
        "City": "Glendale",
        "Country": "USA West Virginia"
    },
    {
        "id": "2714",
        "AirportCode": "GWW",
        "AirportName": "R.A.F. Gatow Airport",
        "City": "Berlin",
        "Country": "Germany"
    },
    {
        "id": "2715",
        "AirportCode": "GWY",
        "AirportName": "Carnmore Airport",
        "City": "Galway",
        "Country": "Ireland"
    },
    {
        "id": "2716",
        "AirportCode": "GXF",
        "AirportName": "Seiyun Airport",
        "City": "Seiyun",
        "Country": "Yemen"
    },
    {
        "id": "2717",
        "AirportCode": "GXG",
        "AirportName": "Negage Airport",
        "City": "Negage",
        "Country": "Angola"
    },
    {
        "id": "2718",
        "AirportCode": "GXH",
        "AirportName": "Mildenhall NAF",
        "City": "Mildenhall",
        "Country": "United Kingdom"
    },
    {
        "id": "2719",
        "AirportCode": "GXQ",
        "AirportName": "Ten. Vidal Airport",
        "City": "Coyhaique",
        "Country": "Chile"
    },
    {
        "id": "2720",
        "AirportCode": "GXX",
        "AirportName": "Yagoua Airport",
        "City": "Yagoua",
        "Country": "Cameroon"
    },
    {
        "id": "2721",
        "AirportCode": "GXY",
        "AirportName": "Weld County Airport",
        "City": "Greeley",
        "Country": "USA Colorado"
    },
    {
        "id": "2722",
        "AirportCode": "GYA",
        "AirportName": "Guayaramerin Airport",
        "City": "Guayaramerin",
        "Country": "Bolivia"
    },
    {
        "id": "2723",
        "AirportCode": "GYD",
        "AirportName": "Heydar Aliyev International Airport",
        "City": "Baku",
        "Country": "Azerbaijan"
    },
    {
        "id": "2724",
        "AirportCode": "GYE",
        "AirportName": "Simon Bolivar Airport",
        "City": "Guayaquil",
        "Country": "Ecuador"
    },
    {
        "id": "2725",
        "AirportCode": "GYI",
        "AirportName": "Gisenyi Airport",
        "City": "Gisenyi",
        "Country": "Rwanda"
    },
    {
        "id": "2726",
        "AirportCode": "GYL",
        "AirportName": "Argyle Airport",
        "City": "Argyle",
        "Country": "Australia"
    },
    {
        "id": "2727",
        "AirportCode": "GYM",
        "AirportName": "Gen Jose M Yanez Airport",
        "City": "Guaymas",
        "Country": "Mexico"
    },
    {
        "id": "2728",
        "AirportCode": "GYN",
        "AirportName": "Santa Genoveva Airport",
        "City": "Goiania",
        "Country": "Brazil"
    },
    {
        "id": "2729",
        "AirportCode": "GYP",
        "AirportName": "Gympie Airport",
        "City": "Gympie",
        "Country": "Australia"
    },
    {
        "id": "2730",
        "AirportCode": "GYR",
        "AirportName": "Litchfield Airport",
        "City": "Goodyear",
        "Country": "USA Arizona"
    },
    {
        "id": "2731",
        "AirportCode": "GYS",
        "AirportName": "Guang Yuan Airport",
        "City": "Guang Yuan",
        "Country": "China"
    },
    {
        "id": "2732",
        "AirportCode": "GYY",
        "AirportName": "Gary Regional Airport",
        "City": "Gary",
        "Country": "USA Indiana"
    },
    {
        "id": "2733",
        "AirportCode": "GZA",
        "AirportName": "Gaza International Airport",
        "City": "Gaza City",
        "Country": "Palestine"
    },
    {
        "id": "2734",
        "AirportCode": "GZI",
        "AirportName": "Ghazni Airport",
        "City": "Ghazni",
        "Country": "Afghanistan"
    },
    {
        "id": "2735",
        "AirportCode": "GZM",
        "AirportName": "Gozo Airport",
        "City": "Gozo",
        "Country": "Malta"
    },
    {
        "id": "2736",
        "AirportCode": "GZO",
        "AirportName": "Gizo Airport",
        "City": "Gizo",
        "Country": "Solomon Islands"
    },
    {
        "id": "2737",
        "AirportCode": "GZT",
        "AirportName": "Gaziantep Airport",
        "City": "Gaziantep",
        "Country": "Turkey"
    },
    {
        "id": "2738",
        "AirportCode": "GZW",
        "AirportName": "Ghazvin Airport",
        "City": "Ghazvin",
        "Country": "Iran"
    },
    {
        "id": "2739",
        "AirportCode": "HAA",
        "AirportName": "Hasvik Airport",
        "City": "Hasvik",
        "Country": "Norway"
    },
    {
        "id": "2740",
        "AirportCode": "HAB",
        "AirportName": "Marion County Airport",
        "City": "Hamilton",
        "Country": "USA Alabama"
    },
    {
        "id": "2741",
        "AirportCode": "HAC",
        "AirportName": "Hachijo Jima Airport",
        "City": "Hachijo Jima",
        "Country": "Japan"
    },
    {
        "id": "2742",
        "AirportCode": "HAD",
        "AirportName": "Halmstad Airport",
        "City": "Halmstad",
        "Country": "Sweden"
    },
    {
        "id": "2743",
        "AirportCode": "HAE",
        "AirportName": "Havasupai Airport",
        "City": "Havasupai",
        "Country": "USA Arizona"
    },
    {
        "id": "2744",
        "AirportCode": "HAF",
        "AirportName": "Half Moon Airport",
        "City": "Half Moon",
        "Country": "USA California"
    },
    {
        "id": "2745",
        "AirportCode": "HAH",
        "AirportName": "Prince Said Ibrahim In Airport",
        "City": "Moroni",
        "Country": "Comoros"
    },
    {
        "id": "2746",
        "AirportCode": "HAI",
        "AirportName": "Dr. Haines Airport",
        "City": "Three Rivers",
        "Country": "USA Michigan"
    },
    {
        "id": "2747",
        "AirportCode": "HAJ",
        "AirportName": "Hanover Airport",
        "City": "Hanover",
        "Country": "Germany"
    },
    {
        "id": "2748",
        "AirportCode": "HAK",
        "AirportName": "Haikou Airport",
        "City": "Haikou",
        "Country": "China"
    },
    {
        "id": "2749",
        "AirportCode": "HAL",
        "AirportName": "Halali Airport",
        "City": "Halali",
        "Country": "Namibia"
    },
    {
        "id": "2750",
        "AirportCode": "HAM",
        "AirportName": "Fuhlsbuettel Airport",
        "City": "Hamburg (HAM)",
        "Country": "Germany"
    },
    {
        "id": "2751",
        "AirportCode": "HAN",
        "AirportName": "Noi Bai International Airport",
        "City": "Hanoi",
        "Country": "Vietnam"
    },
    {
        "id": "2752",
        "AirportCode": "HAO",
        "AirportName": "Hamilton Airport",
        "City": "Hamilton",
        "Country": "USA Ohio"
    },
    {
        "id": "2753",
        "AirportCode": "HAP",
        "AirportName": "Long Island Airport",
        "City": "Long Island",
        "Country": "Australia"
    },
    {
        "id": "2754",
        "AirportCode": "HAQ",
        "AirportName": "Hanimaadhoo Airport",
        "City": "Hanimaadhoo",
        "Country": "Maldives"
    },
    {
        "id": "2755",
        "AirportCode": "HAR",
        "AirportName": "Harrisburg Skyport",
        "City": "Harrisburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "2756",
        "AirportCode": "HAS",
        "AirportName": "Hail Airport",
        "City": "Hail",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2757",
        "AirportCode": "HAT",
        "AirportName": "Heathlands Airport",
        "City": "Heathlands",
        "Country": "Australia"
    },
    {
        "id": "2758",
        "AirportCode": "HAU",
        "AirportName": "Haugesund Airport",
        "City": "Haugesund",
        "Country": "Norway"
    },
    {
        "id": "2759",
        "AirportCode": "HAV",
        "AirportName": "Jose Marti International Airport",
        "City": "Havana",
        "Country": "Cuba"
    },
    {
        "id": "2760",
        "AirportCode": "HAW",
        "AirportName": "Haverfordwest Airport",
        "City": "Haverfordwest",
        "Country": "United Kingdom"
    },
    {
        "id": "2761",
        "AirportCode": "HAX",
        "AirportName": "Hatbox Field",
        "City": "Muskogee",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2762",
        "AirportCode": "HAZ",
        "AirportName": "Hatzfeldthaven Airport",
        "City": "Hatzfeldthaven",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2763",
        "AirportCode": "HBA",
        "AirportName": "Hobart Airport",
        "City": "Hobart",
        "Country": "Australia"
    },
    {
        "id": "2764",
        "AirportCode": "HBB",
        "AirportName": "Industrial Airpark",
        "City": "Hobbs",
        "Country": "USA New Mexico"
    },
    {
        "id": "2765",
        "AirportCode": "HBD",
        "AirportName": "Habi Airport",
        "City": "Habi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2766",
        "AirportCode": "HBE",
        "AirportName": "Borg El Arab Airport",
        "City": "Alexandria",
        "Country": "Egypt"
    },
    {
        "id": "2767",
        "AirportCode": "HBG",
        "AirportName": "Bobby L. Chain Municipal Airport",
        "City": "Hattiesburg",
        "Country": "USA Mississippi"
    },
    {
        "id": "2768",
        "AirportCode": "HBN",
        "AirportName": "Phu-bon Airport",
        "City": "Phu-bon",
        "Country": "Vietnam"
    },
    {
        "id": "2769",
        "AirportCode": "HBO",
        "AirportName": "Humboldt Municipal Airport",
        "City": "Humboldt",
        "Country": "USA Nebraska"
    },
    {
        "id": "2770",
        "AirportCode": "HBR",
        "AirportName": "Hobart Airport",
        "City": "Hobart",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2771",
        "AirportCode": "HBT",
        "AirportName": "Hafr Albatin Airport",
        "City": "Hafr Albatin",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2772",
        "AirportCode": "HBX",
        "AirportName": "Hubli Airport",
        "City": "Hubli",
        "Country": "India"
    },
    {
        "id": "2773",
        "AirportCode": "HCA",
        "AirportName": "Howard County Airport",
        "City": "Big Spring",
        "Country": "USA Texas"
    },
    {
        "id": "2774",
        "AirportCode": "HCC",
        "AirportName": "Columbia County Airport",
        "City": "Hudson",
        "Country": "USA New York"
    },
    {
        "id": "2775",
        "AirportCode": "HCM",
        "AirportName": "Eli Airport",
        "City": "Eil",
        "Country": "Somalia"
    },
    {
        "id": "2776",
        "AirportCode": "HCN",
        "AirportName": "Hengchun Airport",
        "City": "Hengchun",
        "Country": "Taiwan"
    },
    {
        "id": "2777",
        "AirportCode": "HCQ",
        "AirportName": "Halls Creek Airport",
        "City": "Halls Creek",
        "Country": "Australia"
    },
    {
        "id": "2778",
        "AirportCode": "HCR",
        "AirportName": "Holy Cross Airport",
        "City": "Holy Cross",
        "Country": "USA Alaska"
    },
    {
        "id": "2779",
        "AirportCode": "HCS",
        "AirportName": "Randburg Heliport",
        "City": "Johannesburg",
        "Country": "South Africa"
    },
    {
        "id": "2780",
        "AirportCode": "HCW",
        "AirportName": "Cheraw Airport",
        "City": "Cheraw",
        "Country": "USA South Carolina"
    },
    {
        "id": "2781",
        "AirportCode": "HDB",
        "AirportName": "Heidelberg Airport",
        "City": "Heidelberg",
        "Country": "Germany"
    },
    {
        "id": "2782",
        "AirportCode": "HDD",
        "AirportName": "Hyderabad Airport",
        "City": "Hyderabad",
        "Country": "Pakistan"
    },
    {
        "id": "2783",
        "AirportCode": "HDE",
        "AirportName": "Brewster Field",
        "City": "Holdrege",
        "Country": "USA Nebraska"
    },
    {
        "id": "2784",
        "AirportCode": "HDF",
        "AirportName": "Heringsdorf Airport",
        "City": "Heringsdorf",
        "Country": "Germany"
    },
    {
        "id": "2785",
        "AirportCode": "HDH",
        "AirportName": "Dillingham Airfield",
        "City": "Oahu",
        "Country": "USA Hawaii"
    },
    {
        "id": "2786",
        "AirportCode": "HDM",
        "AirportName": "Hamadan Airport",
        "City": "Hamadan",
        "Country": "Iran"
    },
    {
        "id": "2787",
        "AirportCode": "HDN",
        "AirportName": "Yampa Valley Airport",
        "City": "Hayden",
        "Country": "USA Colorado"
    },
    {
        "id": "2788",
        "AirportCode": "HDR",
        "AirportName": "Havadarya Airport",
        "City": "Havadarya",
        "Country": "Iran"
    },
    {
        "id": "2789",
        "AirportCode": "HDS",
        "AirportName": "Hoedspruit Airport",
        "City": "Hoedspruit",
        "Country": "South Africa"
    },
    {
        "id": "2790",
        "AirportCode": "HDY",
        "AirportName": "Hat Yai Airport",
        "City": "Hat Yai",
        "Country": "Thailand"
    },
    {
        "id": "2791",
        "AirportCode": "HEA",
        "AirportName": "Herat Airport",
        "City": "Herat",
        "Country": "Afghanistan"
    },
    {
        "id": "2792",
        "AirportCode": "HEB",
        "AirportName": "Henzada Airport",
        "City": "Henzada",
        "Country": "Burma"
    },
    {
        "id": "2793",
        "AirportCode": "HEE",
        "AirportName": "Thompson-Robbins Airport",
        "City": "Helena",
        "Country": "USA Arkansas"
    },
    {
        "id": "2794",
        "AirportCode": "HEH",
        "AirportName": "Heho Airport",
        "City": "Heho",
        "Country": "Burma"
    },
    {
        "id": "2795",
        "AirportCode": "HEI",
        "AirportName": "Heide-Buesum Airport",
        "City": "Heide-Buesum",
        "Country": "Germany"
    },
    {
        "id": "2796",
        "AirportCode": "HEK",
        "AirportName": "Heihe Airport",
        "City": "Heihe",
        "Country": "China"
    },
    {
        "id": "2797",
        "AirportCode": "HEL",
        "AirportName": "Helsinki-Vantaa Airport",
        "City": "Helsinki",
        "Country": "Finland"
    },
    {
        "id": "2798",
        "AirportCode": "HEM",
        "AirportName": "Helsinki-Malmi Airport",
        "City": "Helsinki",
        "Country": "Finland"
    },
    {
        "id": "2799",
        "AirportCode": "HEN",
        "AirportName": "Hendon Airport",
        "City": "Hendon",
        "Country": "United Kingdom"
    },
    {
        "id": "2800",
        "AirportCode": "HEO",
        "AirportName": "Haelogo Airport",
        "City": "Haelogo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2801",
        "AirportCode": "HER",
        "AirportName": "N. Kazantzakis Airport",
        "City": "Heraklion",
        "Country": "Greece"
    },
    {
        "id": "2802",
        "AirportCode": "HES",
        "AirportName": "State Airport",
        "City": "Hermiston",
        "Country": "USA Oregon"
    },
    {
        "id": "2803",
        "AirportCode": "HET",
        "AirportName": "Hohhot Airport",
        "City": "Hohhot",
        "Country": "China"
    },
    {
        "id": "2804",
        "AirportCode": "HEV",
        "AirportName": "Huelva Airport",
        "City": "Huelva",
        "Country": "Spain"
    },
    {
        "id": "2805",
        "AirportCode": "HEW",
        "AirportName": "Athens Hellinikon Airport",
        "City": "Athens",
        "Country": "Greece"
    },
    {
        "id": "2806",
        "AirportCode": "HEX",
        "AirportName": "Herrera Airport",
        "City": "Santo Domingo",
        "Country": "Dominican Republic"
    },
    {
        "id": "2807",
        "AirportCode": "HEY",
        "AirportName": "Hanchey Army Heliport",
        "City": "Ozark",
        "Country": "USA Alabama"
    },
    {
        "id": "2808",
        "AirportCode": "HEZ",
        "AirportName": "Hardy-Anders Airport",
        "City": "Natchez",
        "Country": "USA Mississippi"
    },
    {
        "id": "2809",
        "AirportCode": "HFA",
        "AirportName": "Haifa Airport",
        "City": "Â ",
        "Country": "Â "
    },
    {
        "id": "2810",
        "AirportCode": "HFD",
        "AirportName": "Brainard Airport",
        "City": "Hartford",
        "Country": "USA Connecticut"
    },
    {
        "id": "2811",
        "AirportCode": "HFE",
        "AirportName": "Hefei Airport",
        "City": "Hefei",
        "Country": "China"
    },
    {
        "id": "2812",
        "AirportCode": "HFF",
        "AirportName": "Mackall Army Air Field",
        "City": "Hoffman",
        "Country": "USA North Carolina"
    },
    {
        "id": "2813",
        "AirportCode": "HFN",
        "AirportName": "Hornafjordur Airport",
        "City": "Hornafjordur",
        "Country": "Iceland"
    },
    {
        "id": "2814",
        "AirportCode": "HFS",
        "AirportName": "Hagfors Airport",
        "City": "Hagfors",
        "Country": "Sweden"
    },
    {
        "id": "2815",
        "AirportCode": "HFT",
        "AirportName": "Hammerfest Airport",
        "City": "Hammerfest",
        "Country": "Norway"
    },
    {
        "id": "2816",
        "AirportCode": "HGA",
        "AirportName": "Hargeisa Airport",
        "City": "Hargeisa",
        "Country": "Somalia"
    },
    {
        "id": "2817",
        "AirportCode": "HGD",
        "AirportName": "Hughenden Airport",
        "City": "Hughenden",
        "Country": "Australia"
    },
    {
        "id": "2818",
        "AirportCode": "HGH",
        "AirportName": "Hangzhou Airport",
        "City": "Hangzhou",
        "Country": "China"
    },
    {
        "id": "2819",
        "AirportCode": "HGL",
        "AirportName": "Helgoland Airport",
        "City": "Helgoland",
        "Country": "Germany"
    },
    {
        "id": "2820",
        "AirportCode": "HGN",
        "AirportName": "Mae Hong Son Airport",
        "City": "Mae Hong Son",
        "Country": "Thailand"
    },
    {
        "id": "2821",
        "AirportCode": "HGR",
        "AirportName": "Wash. County Regional Airport",
        "City": "Hagerstown",
        "Country": "USA Maryland"
    },
    {
        "id": "2822",
        "AirportCode": "HGS",
        "AirportName": "Hastings Airport",
        "City": "Freetown",
        "Country": "Sierra Leone"
    },
    {
        "id": "2823",
        "AirportCode": "HGT",
        "AirportName": "Hunter Army Air Field",
        "City": "Jolon",
        "Country": "USA California"
    },
    {
        "id": "2824",
        "AirportCode": "HGU",
        "AirportName": "Kagamuga Airport",
        "City": "Mount Hagen",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2825",
        "AirportCode": "HHA",
        "AirportName": "Changsha Huanghua Airport",
        "City": "Huanghua",
        "Country": "China"
    },
    {
        "id": "2826",
        "AirportCode": "HHE",
        "AirportName": "Hachinohe Airport",
        "City": "Hachinohe",
        "Country": "Japan"
    },
    {
        "id": "2827",
        "AirportCode": "HHH",
        "AirportName": "Hilton Head Airport",
        "City": "Hilton Head",
        "Country": "USA South Carolina"
    },
    {
        "id": "2828",
        "AirportCode": "HHI",
        "AirportName": "Wheeler Air Force Base",
        "City": "Wahiawa",
        "Country": "USA Hawaii"
    },
    {
        "id": "2829",
        "AirportCode": "HHN",
        "AirportName": "Hahn Airport",
        "City": "Frankfurt",
        "Country": "Germany"
    },
    {
        "id": "2830",
        "AirportCode": "HHP",
        "AirportName": "Hong Kong Heliport",
        "City": "Hong Kong",
        "Country": "Hong Kong"
    },
    {
        "id": "2831",
        "AirportCode": "HHQ",
        "AirportName": "Hua Hin Airport",
        "City": "Hua Hin",
        "Country": "Thailand"
    },
    {
        "id": "2832",
        "AirportCode": "HHR",
        "AirportName": "Hawthorne Airport",
        "City": "Hawthorne",
        "Country": "USA California"
    },
    {
        "id": "2833",
        "AirportCode": "HHZ",
        "AirportName": "Hikueru Airport",
        "City": "Hikueru",
        "Country": "French Polynesia"
    },
    {
        "id": "2834",
        "AirportCode": "HIB",
        "AirportName": "Chisholm Airport",
        "City": "Hibbing",
        "Country": "USA Minnesota"
    },
    {
        "id": "2835",
        "AirportCode": "HIC",
        "AirportName": "Iscor Heliport",
        "City": "Pretoria",
        "Country": "South Africa"
    },
    {
        "id": "2836",
        "AirportCode": "HID",
        "AirportName": "Horn Island Airport",
        "City": "Horn Island",
        "Country": "Australia"
    },
    {
        "id": "2837",
        "AirportCode": "HIE",
        "AirportName": "Whitefield Regional Airport",
        "City": "Whitefield",
        "Country": "USA New Hampshire"
    },
    {
        "id": "2838",
        "AirportCode": "HIF",
        "AirportName": "Hill Air Force Base",
        "City": "Ogden",
        "Country": "USA Utah"
    },
    {
        "id": "2839",
        "AirportCode": "HIG",
        "AirportName": "Highbury Airport",
        "City": "Highbury",
        "Country": "Australia"
    },
    {
        "id": "2840",
        "AirportCode": "HIH",
        "AirportName": "Hook Island Airport",
        "City": "Hook Island",
        "Country": "Australia"
    },
    {
        "id": "2841",
        "AirportCode": "HII",
        "AirportName": "Lake Havasu City Municipal Airport",
        "City": "Lake Havasu City",
        "Country": "USA Arizona"
    },
    {
        "id": "2842",
        "AirportCode": "HIJ",
        "AirportName": "Hiroshima International Airport",
        "City": "Hiroshima",
        "Country": "Japan"
    },
    {
        "id": "2843",
        "AirportCode": "HIK",
        "AirportName": "Hickam Air Force Base",
        "City": "Honolulu",
        "Country": "USA Hawaii"
    },
    {
        "id": "2844",
        "AirportCode": "HIL",
        "AirportName": "Shillavo Airport",
        "City": "Shillavo",
        "Country": "Ethiopia"
    },
    {
        "id": "2845",
        "AirportCode": "HIM",
        "AirportName": "Hingurakgoda Airport",
        "City": "Hingurakgoda",
        "Country": "Sri Lanka"
    },
    {
        "id": "2846",
        "AirportCode": "HIN",
        "AirportName": "Sacheon Airport",
        "City": "Jinju",
        "Country": "Korea"
    },
    {
        "id": "2847",
        "AirportCode": "HIO",
        "AirportName": "Portland Hillsboro Airport",
        "City": "Hillsboro",
        "Country": "USA Oregon"
    },
    {
        "id": "2848",
        "AirportCode": "HIP",
        "AirportName": "Headingly Airport",
        "City": "Headingly",
        "Country": "Australia"
    },
    {
        "id": "2849",
        "AirportCode": "HIR",
        "AirportName": "Henderson International Airport",
        "City": "Honiara",
        "Country": "Solomon Islands"
    },
    {
        "id": "2850",
        "AirportCode": "HIS",
        "AirportName": "Hayman Island Airport",
        "City": "Hayman Island",
        "Country": "Australia"
    },
    {
        "id": "2851",
        "AirportCode": "HIT",
        "AirportName": "Hivaro Airport",
        "City": "Hivaro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2852",
        "AirportCode": "HIW",
        "AirportName": "Hiroshima West Airport",
        "City": "Hiroshima",
        "Country": "Japan"
    },
    {
        "id": "2853",
        "AirportCode": "HIX",
        "AirportName": "Hiva Oa Airport",
        "City": "Hiva Oa",
        "Country": "French Polynesia"
    },
    {
        "id": "2854",
        "AirportCode": "HJR",
        "AirportName": "Khajuraho Airport",
        "City": "Khajuraho",
        "Country": "India"
    },
    {
        "id": "2855",
        "AirportCode": "HJT",
        "AirportName": "Khujirt Airport",
        "City": "Khujirt",
        "Country": "Mongolia"
    },
    {
        "id": "2856",
        "AirportCode": "HKA",
        "AirportName": "Blytheville Municipal Airport",
        "City": "Blytheville",
        "Country": "USA Arkansas"
    },
    {
        "id": "2857",
        "AirportCode": "HKB",
        "AirportName": "Healy Lake Airport",
        "City": "Healy Lake",
        "Country": "USA Alaska"
    },
    {
        "id": "2858",
        "AirportCode": "HKD",
        "AirportName": "Hakodate Airport",
        "City": "Hakodate",
        "Country": "Japan"
    },
    {
        "id": "2859",
        "AirportCode": "HKG",
        "AirportName": "Hong Kong International Airport",
        "City": "Hong Kong",
        "Country": "Hong Kong"
    },
    {
        "id": "2860",
        "AirportCode": "HKK",
        "AirportName": "Hokitika Airport",
        "City": "Hokitika",
        "Country": "New Zealand"
    },
    {
        "id": "2861",
        "AirportCode": "HKN",
        "AirportName": "Hoskins Airport",
        "City": "Hoskins",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2862",
        "AirportCode": "HKS",
        "AirportName": "Hawkins Field",
        "City": "Jackson",
        "Country": "USA Mississippi"
    },
    {
        "id": "2863",
        "AirportCode": "HKT",
        "AirportName": "Phuket International Airport",
        "City": "Phuket",
        "Country": "Thailand"
    },
    {
        "id": "2864",
        "AirportCode": "HKV",
        "AirportName": "Haskovo Airport",
        "City": "Haskovo",
        "Country": "Bulgaria"
    },
    {
        "id": "2865",
        "AirportCode": "HKY",
        "AirportName": "Hickory Airport",
        "City": "Hickory",
        "Country": "USA North Carolina"
    },
    {
        "id": "2866",
        "AirportCode": "HLA",
        "AirportName": "Lanseria Airport",
        "City": "Lanseria",
        "Country": "South Africa"
    },
    {
        "id": "2867",
        "AirportCode": "HLB",
        "AirportName": "Hillenbrand Airport",
        "City": "Batesville",
        "Country": "USA Indiana"
    },
    {
        "id": "2868",
        "AirportCode": "HLC",
        "AirportName": "Hill City Airport",
        "City": "Hill City",
        "Country": "USA Kansas"
    },
    {
        "id": "2869",
        "AirportCode": "HLD",
        "AirportName": "Hailar Airport",
        "City": "Hailar",
        "Country": "China"
    },
    {
        "id": "2870",
        "AirportCode": "HLF",
        "AirportName": "Hultsfred Airport",
        "City": "Hultsfred",
        "Country": "Sweden"
    },
    {
        "id": "2871",
        "AirportCode": "HLG",
        "AirportName": "Ohio County Airport",
        "City": "Wheeling",
        "Country": "USA West Virginia"
    },
    {
        "id": "2872",
        "AirportCode": "HLH",
        "AirportName": "Ulanhot Airport",
        "City": "Ulanhot",
        "Country": "China"
    },
    {
        "id": "2873",
        "AirportCode": "HLI",
        "AirportName": "Hollister Airport",
        "City": "Hollister",
        "Country": "USA California"
    },
    {
        "id": "2874",
        "AirportCode": "HLL",
        "AirportName": "Hillside Airport",
        "City": "Hillside",
        "Country": "Australia"
    },
    {
        "id": "2875",
        "AirportCode": "HLM",
        "AirportName": "Park Township Airport",
        "City": "Holland",
        "Country": "USA Michigan"
    },
    {
        "id": "2876",
        "AirportCode": "HLN",
        "AirportName": "Helena Airport",
        "City": "Helena",
        "Country": "USA Montana"
    },
    {
        "id": "2877",
        "AirportCode": "HLP",
        "AirportName": "Halim Perdana Kusuma Airport",
        "City": "Jakarta",
        "Country": "Indonesia"
    },
    {
        "id": "2878",
        "AirportCode": "HLR",
        "AirportName": "Fort Hood Army Air Field",
        "City": "Killeen",
        "Country": "USA Texas"
    },
    {
        "id": "2879",
        "AirportCode": "HLS",
        "AirportName": "St. Helens Airport",
        "City": "Saint Helens",
        "Country": "Australia"
    },
    {
        "id": "2880",
        "AirportCode": "HLT",
        "AirportName": "Hamilton Airport",
        "City": "Hamilton",
        "Country": "Australia"
    },
    {
        "id": "2881",
        "AirportCode": "HLU",
        "AirportName": "Houailou Airport",
        "City": "Houailou",
        "Country": "New Caledonia"
    },
    {
        "id": "2882",
        "AirportCode": "HLV",
        "AirportName": "Helenvale Airport",
        "City": "Helenvale",
        "Country": "Australia"
    },
    {
        "id": "2883",
        "AirportCode": "HLW",
        "AirportName": "Hluhluwe Airport",
        "City": "Hluhluwe",
        "Country": "South Africa"
    },
    {
        "id": "2884",
        "AirportCode": "HLY",
        "AirportName": "Holyhead Airport",
        "City": "Holyhead",
        "Country": "United Kingdom"
    },
    {
        "id": "2885",
        "AirportCode": "HLZ",
        "AirportName": "Hamilton Airport",
        "City": "Hamilton",
        "Country": "New Zealand"
    },
    {
        "id": "2886",
        "AirportCode": "HMA",
        "AirportName": "Khanty-Mansiysk",
        "City": "Khanty-Mansiysk",
        "Country": "Russia"
    },
    {
        "id": "2887",
        "AirportCode": "HME",
        "AirportName": "Oued Irara Airport",
        "City": "Hassi Messaoud",
        "Country": "Algeria"
    },
    {
        "id": "2888",
        "AirportCode": "HMG",
        "AirportName": "Hermannsburg Airport",
        "City": "Hermannsburg",
        "Country": "Australia"
    },
    {
        "id": "2889",
        "AirportCode": "HMI",
        "AirportName": "Hami Airport",
        "City": "Hami",
        "Country": "China"
    },
    {
        "id": "2890",
        "AirportCode": "HMJ",
        "AirportName": "Khmelnitskiy Airport",
        "City": "Khmelnitskiy",
        "Country": "Ukraine"
    },
    {
        "id": "2891",
        "AirportCode": "HMN",
        "AirportName": "Holloman Air Force Base",
        "City": "Alamogordo",
        "Country": "USA New Mexico"
    },
    {
        "id": "2892",
        "AirportCode": "HMO",
        "AirportName": "Gen Pesqueira Garcia Airport",
        "City": "Hermosillo",
        "Country": "Mexico"
    },
    {
        "id": "2893",
        "AirportCode": "HMR",
        "AirportName": "Hamar Airport",
        "City": "Hamar",
        "Country": "Norway"
    },
    {
        "id": "2894",
        "AirportCode": "HMT",
        "AirportName": "Ryan Field",
        "City": "Hemet",
        "Country": "USA California"
    },
    {
        "id": "2895",
        "AirportCode": "HMV",
        "AirportName": "Hemavan Airport",
        "City": "Hemavan",
        "Country": "Sweden"
    },
    {
        "id": "2896",
        "AirportCode": "HNA",
        "AirportName": "Hanamaki Airport",
        "City": "Hanamaki",
        "Country": "Japan"
    },
    {
        "id": "2897",
        "AirportCode": "HNB",
        "AirportName": "Huntingburg Municipal Airport",
        "City": "Huntingburg",
        "Country": "USA Indiana"
    },
    {
        "id": "2898",
        "AirportCode": "HNC",
        "AirportName": "Hatteras Airport",
        "City": "Hatteras",
        "Country": "USA North Carolina"
    },
    {
        "id": "2899",
        "AirportCode": "HND",
        "AirportName": "Haneda Airport",
        "City": "Tokyo",
        "Country": "Japan"
    },
    {
        "id": "2900",
        "AirportCode": "HNG",
        "AirportName": "Hienghene Airport",
        "City": "Hienghene",
        "Country": "New Caledonia"
    },
    {
        "id": "2901",
        "AirportCode": "HNH",
        "AirportName": "Hoonah Airport",
        "City": "Hoonah",
        "Country": "USA Alaska"
    },
    {
        "id": "2902",
        "AirportCode": "HNI",
        "AirportName": "Heiweni Airport",
        "City": "Heiweni",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2903",
        "AirportCode": "HNK",
        "AirportName": "Hinchinbrooke Island Airport",
        "City": "Hinchinbrooke Island",
        "Country": "Australia"
    },
    {
        "id": "2904",
        "AirportCode": "HNL",
        "AirportName": "Honolulu International Airport",
        "City": "Honolulu",
        "Country": "USA Hawaii"
    },
    {
        "id": "2905",
        "AirportCode": "HNM",
        "AirportName": "Hana Airport",
        "City": "Hana",
        "Country": "USA Hawaii"
    },
    {
        "id": "2906",
        "AirportCode": "HNN",
        "AirportName": "Honinabi Airport",
        "City": "Honinabi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2907",
        "AirportCode": "HNO",
        "AirportName": "Hercegnovi Airport",
        "City": "Hercegnovi",
        "Country": "Serbia"
    },
    {
        "id": "2908",
        "AirportCode": "HNS",
        "AirportName": "Haines Municipal Airport",
        "City": "Haines",
        "Country": "USA Alaska"
    },
    {
        "id": "2909",
        "AirportCode": "HNX",
        "AirportName": "Hanna Airport",
        "City": "Hanna",
        "Country": "USA Wyoming"
    },
    {
        "id": "2910",
        "AirportCode": "HNY",
        "AirportName": "Hengyang Airport",
        "City": "Hengyang",
        "Country": "China"
    },
    {
        "id": "2911",
        "AirportCode": "HOA",
        "AirportName": "Hola Airport",
        "City": "Hola",
        "Country": "Kenya"
    },
    {
        "id": "2912",
        "AirportCode": "HOB",
        "AirportName": "Lea County Airport",
        "City": "Hobbs",
        "Country": "USA New Mexico"
    },
    {
        "id": "2913",
        "AirportCode": "HOC",
        "AirportName": "Komako Airport",
        "City": "Komako",
        "Country": "Papua New Guinea"
    },
    {
        "id": "2914",
        "AirportCode": "HOD",
        "AirportName": "Hodeidah Airport",
        "City": "Hodeidah",
        "Country": "Yemen"
    },
    {
        "id": "2915",
        "AirportCode": "HOE",
        "AirportName": "Houeisay Airport",
        "City": "Houeisay",
        "Country": "Laos"
    },
    {
        "id": "2916",
        "AirportCode": "HOF",
        "AirportName": "Alahsa Airport",
        "City": "Alahsa",
        "Country": "Saudi Arabia"
    },
    {
        "id": "2917",
        "AirportCode": "HOG",
        "AirportName": "Frank Pais Airport",
        "City": "Holguin",
        "Country": "Cuba"
    },
    {
        "id": "2918",
        "AirportCode": "HOH",
        "AirportName": "Hohenems Airport",
        "City": "Hohenems",
        "Country": "Austria"
    },
    {
        "id": "2919",
        "AirportCode": "HOI",
        "AirportName": "Hao Island Airport",
        "City": "Hao Island",
        "Country": "French Polynesia"
    },
    {
        "id": "2920",
        "AirportCode": "HOK",
        "AirportName": "Hooker Creek Airport",
        "City": "Hooker Creek",
        "Country": "Australia"
    },
    {
        "id": "2921",
        "AirportCode": "HOM",
        "AirportName": "Homer Airport",
        "City": "Homer",
        "Country": "USA Alaska"
    },
    {
        "id": "2922",
        "AirportCode": "HON",
        "AirportName": "Howes Airport",
        "City": "Huron",
        "Country": "USA South Dakota"
    },
    {
        "id": "2923",
        "AirportCode": "HOO",
        "AirportName": "Nhon Co Airport",
        "City": "Quang Duc",
        "Country": "Vietnam"
    },
    {
        "id": "2924",
        "AirportCode": "HOP",
        "AirportName": "Campbell Army Air Field",
        "City": "Hopkinsville",
        "Country": "USA Kentucky"
    },
    {
        "id": "2925",
        "AirportCode": "HOQ",
        "AirportName": "Hof Airport",
        "City": "Hof",
        "Country": "Germany"
    },
    {
        "id": "2926",
        "AirportCode": "HOR",
        "AirportName": "Horta Airport",
        "City": "Horta (Azores)",
        "Country": "Azores"
    },
    {
        "id": "2927",
        "AirportCode": "HOS",
        "AirportName": "Oscar Reguera Airport",
        "City": "Chos Malal",
        "Country": "Argentina"
    },
    {
        "id": "2928",
        "AirportCode": "HOT",
        "AirportName": "Memorial Field",
        "City": "Hot Springs",
        "Country": "USA Arkansas"
    },
    {
        "id": "2929",
        "AirportCode": "HOU",
        "AirportName": "Hobby Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "2930",
        "AirportCode": "HOV",
        "AirportName": "Hovden Airport",
        "City": "Orsta-Volda",
        "Country": "Norway"
    },
    {
        "id": "2931",
        "AirportCode": "HOX",
        "AirportName": "HoMalin Airport",
        "City": "HoMalin",
        "Country": "Burma"
    },
    {
        "id": "2932",
        "AirportCode": "HOY",
        "AirportName": "Hoy Island Airport",
        "City": "Hoy Island",
        "Country": "United Kingdom"
    },
    {
        "id": "2933",
        "AirportCode": "HPB",
        "AirportName": "Hooper Bay Airport",
        "City": "Hooper Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "2934",
        "AirportCode": "HPE",
        "AirportName": "Hope Vale Airport",
        "City": "Hope Vale",
        "Country": "Australia"
    },
    {
        "id": "2935",
        "AirportCode": "HPH",
        "AirportName": "Catbi Airport",
        "City": "Haiphong",
        "Country": "Vietnam"
    },
    {
        "id": "2936",
        "AirportCode": "HPN",
        "AirportName": "Westchester County Airport",
        "City": "Westchester County",
        "Country": "USA New York"
    },
    {
        "id": "2937",
        "AirportCode": "HPR",
        "AirportName": "Pretoria Central Heliport",
        "City": "Pretoria",
        "Country": "South Africa"
    },
    {
        "id": "2938",
        "AirportCode": "HPT",
        "AirportName": "Hampton Municipal Airport",
        "City": "Hampton",
        "Country": "USA Iowa"
    },
    {
        "id": "2939",
        "AirportCode": "HPV",
        "AirportName": "Princeville Airport",
        "City": "Kauai Island",
        "Country": "USA Hawaii"
    },
    {
        "id": "2940",
        "AirportCode": "HPY",
        "AirportName": "Baytown Airport",
        "City": "Baytown",
        "Country": "USA Texas"
    },
    {
        "id": "2941",
        "AirportCode": "HQM",
        "AirportName": "Bowerman Airport",
        "City": "Hoquiam",
        "Country": "USA Washington"
    },
    {
        "id": "2942",
        "AirportCode": "HRA",
        "AirportName": "Mansehra Airport",
        "City": "Mansehra",
        "Country": "Pakistan"
    },
    {
        "id": "2943",
        "AirportCode": "HRB",
        "AirportName": "Harbin Airport",
        "City": "Harbin",
        "Country": "China"
    },
    {
        "id": "2944",
        "AirportCode": "HRC",
        "AirportName": "Zhairem Airport",
        "City": "Zhairem",
        "Country": "Kazakhstan"
    },
    {
        "id": "2945",
        "AirportCode": "HRE",
        "AirportName": "Harare Airport",
        "City": "Harare",
        "Country": "Zimbabwe"
    },
    {
        "id": "2946",
        "AirportCode": "HRG",
        "AirportName": "Hurghada Airport",
        "City": "Hurghada",
        "Country": "Egypt"
    },
    {
        "id": "2947",
        "AirportCode": "HRJ",
        "AirportName": "Chaurjhari Airport",
        "City": "Chaurjhari",
        "Country": "Nepal"
    },
    {
        "id": "2948",
        "AirportCode": "HRK",
        "AirportName": "Kharkov Airport",
        "City": "Kharkov",
        "Country": "Ukraine"
    },
    {
        "id": "2949",
        "AirportCode": "HRL",
        "AirportName": "Valley International Airport",
        "City": "Harlingen",
        "Country": "USA Texas"
    },
    {
        "id": "2950",
        "AirportCode": "HRN",
        "AirportName": "Heron Island Heliport",
        "City": "Heron Island",
        "Country": "Australia"
    },
    {
        "id": "2951",
        "AirportCode": "HRO",
        "AirportName": "Boone County Airport",
        "City": "Harrison",
        "Country": "USA Arkansas"
    },
    {
        "id": "2952",
        "AirportCode": "HRR",
        "AirportName": "Herrera Airport",
        "City": "Herrera",
        "Country": "Colombia"
    },
    {
        "id": "2953",
        "AirportCode": "HRS",
        "AirportName": "Harrismith Airport",
        "City": "Harrismith",
        "Country": "South Africa"
    },
    {
        "id": "2954",
        "AirportCode": "HRT",
        "AirportName": "Linton-On-Ouse Airport",
        "City": "Harrogate",
        "Country": "United Kingdom"
    },
    {
        "id": "2955",
        "AirportCode": "HRY",
        "AirportName": "Henbury Airport",
        "City": "Henbury",
        "Country": "Australia"
    },
    {
        "id": "2956",
        "AirportCode": "HRZ",
        "AirportName": "Horizontina Airport",
        "City": "Horizontina",
        "Country": "Brazil"
    },
    {
        "id": "2957",
        "AirportCode": "HSB",
        "AirportName": "Raleigh Airport",
        "City": "Harrisburg",
        "Country": "USA Illinois"
    },
    {
        "id": "2958",
        "AirportCode": "HSC",
        "AirportName": "Shaoguan Airport",
        "City": "Shaoguan",
        "Country": "China"
    },
    {
        "id": "2959",
        "AirportCode": "HSG",
        "AirportName": "Saga Airport",
        "City": "Saga",
        "Country": "Japan"
    },
    {
        "id": "2960",
        "AirportCode": "HSH",
        "AirportName": "Henderson Sky Harbor Airport",
        "City": "Las Vegas",
        "Country": "USA Nevada"
    },
    {
        "id": "2961",
        "AirportCode": "HSI",
        "AirportName": "Hastings Airport",
        "City": "Hastings",
        "Country": "USA Nebraska"
    },
    {
        "id": "2962",
        "AirportCode": "HSL",
        "AirportName": "Huslia Airport",
        "City": "Huslia",
        "Country": "USA Alaska"
    },
    {
        "id": "2963",
        "AirportCode": "HSM",
        "AirportName": "Horsham Airport",
        "City": "Horsham",
        "Country": "Australia"
    },
    {
        "id": "2964",
        "AirportCode": "HSN",
        "AirportName": "Zhoushan Airport",
        "City": "Zhoushan",
        "Country": "China"
    },
    {
        "id": "2965",
        "AirportCode": "HSP",
        "AirportName": "Ingalls Field",
        "City": "Hot Springs",
        "Country": "USA Virginia"
    },
    {
        "id": "2966",
        "AirportCode": "HSS",
        "AirportName": "Hissar Airport",
        "City": "Hissar",
        "Country": "India"
    },
    {
        "id": "2967",
        "AirportCode": "HST",
        "AirportName": "Homestead Air Force Base",
        "City": "Homestead",
        "Country": "USA Florida"
    },
    {
        "id": "2968",
        "AirportCode": "HSV",
        "AirportName": "Huntsville International Airport",
        "City": "Huntsville",
        "Country": "USA Alabama"
    },
    {
        "id": "2969",
        "AirportCode": "HSZ",
        "AirportName": "Hsinchu Airport",
        "City": "Hsinchu",
        "Country": "Taiwan"
    },
    {
        "id": "2970",
        "AirportCode": "HTA",
        "AirportName": "Chita Airport",
        "City": "Chita",
        "Country": "Russia"
    },
    {
        "id": "2971",
        "AirportCode": "HTB",
        "AirportName": "Terre-de-Bas Airport",
        "City": "Terre-de-Bas",
        "Country": "Guadeloupe"
    },
    {
        "id": "2972",
        "AirportCode": "HTF",
        "AirportName": "Hatfield Airport",
        "City": "Hatfield",
        "Country": "United Kingdom"
    },
    {
        "id": "2973",
        "AirportCode": "HTG",
        "AirportName": "Hatanga Airport",
        "City": "Hatanga",
        "Country": "Russia"
    },
    {
        "id": "2974",
        "AirportCode": "HTH",
        "AirportName": "Hawthorne Airport",
        "City": "Hawthorne",
        "Country": "USA Nevada"
    },
    {
        "id": "2975",
        "AirportCode": "HTI",
        "AirportName": "Hamilton Island Airport",
        "City": "Hamilton Island",
        "Country": "Australia"
    },
    {
        "id": "2976",
        "AirportCode": "HTL",
        "AirportName": "Roscommon County Airport",
        "City": "Houghton Lake",
        "Country": "USA Michigan"
    },
    {
        "id": "2977",
        "AirportCode": "HTM",
        "AirportName": "Khatgal Airport",
        "City": "Khatgal",
        "Country": "Mongolia"
    },
    {
        "id": "2978",
        "AirportCode": "HTN",
        "AirportName": "Hotan Airport",
        "City": "Hotan",
        "Country": "China"
    },
    {
        "id": "2979",
        "AirportCode": "HTO",
        "AirportName": "East Hampton Airport",
        "City": "East Hampton",
        "Country": "USA New York"
    },
    {
        "id": "2980",
        "AirportCode": "HTR",
        "AirportName": "Hateruma Airport",
        "City": "Hateruma",
        "Country": "Japan"
    },
    {
        "id": "2981",
        "AirportCode": "HTS",
        "AirportName": "Tri-State/Milton Airport",
        "City": "Huntington",
        "Country": "USA West Virginia"
    },
    {
        "id": "2982",
        "AirportCode": "HTU",
        "AirportName": "Hopetown Airport",
        "City": "Hopetown",
        "Country": "Australia"
    },
    {
        "id": "2983",
        "AirportCode": "HTV",
        "AirportName": "Huntsville Airport",
        "City": "Huntsville",
        "Country": "USA Texas"
    },
    {
        "id": "2984",
        "AirportCode": "HTW",
        "AirportName": "Huntington County Airport",
        "City": "Chesapeake",
        "Country": "USA Ohio"
    },
    {
        "id": "2985",
        "AirportCode": "HTZ",
        "AirportName": "Hato Corozal Airport",
        "City": "Hato Corozal",
        "Country": "Colombia"
    },
    {
        "id": "2986",
        "AirportCode": "HUA",
        "AirportName": "Redstone Army Air Field",
        "City": "Huntsville",
        "Country": "USA Alabama"
    },
    {
        "id": "2987",
        "AirportCode": "HUB",
        "AirportName": "Humbert River Airport",
        "City": "Humbert River",
        "Country": "Australia"
    },
    {
        "id": "2988",
        "AirportCode": "HUC",
        "AirportName": "Humacao Airport",
        "City": "Humacao",
        "Country": "Puerto Rico"
    },
    {
        "id": "2989",
        "AirportCode": "HUD",
        "AirportName": "Humboldt Airport",
        "City": "Humboldt",
        "Country": "USA Iowa"
    },
    {
        "id": "2990",
        "AirportCode": "HUE",
        "AirportName": "Humera Airport",
        "City": "Humera",
        "Country": "Ethiopia"
    },
    {
        "id": "2991",
        "AirportCode": "HUF",
        "AirportName": "Hulman Field",
        "City": "Terre Haute",
        "Country": "USA Indiana"
    },
    {
        "id": "2992",
        "AirportCode": "HUG",
        "AirportName": "Huehuetenango Airport",
        "City": "Huehuetenango",
        "Country": "Guatemala"
    },
    {
        "id": "2993",
        "AirportCode": "HUH",
        "AirportName": "Huahine Airport",
        "City": "Huahine",
        "Country": "French Polynesia"
    },
    {
        "id": "2994",
        "AirportCode": "HUI",
        "AirportName": "Phu Bai Airport",
        "City": "Hue",
        "Country": "Vietnam"
    },
    {
        "id": "2995",
        "AirportCode": "HUJ",
        "AirportName": "Hugo Airport",
        "City": "Hugo",
        "Country": "USA Oklahoma"
    },
    {
        "id": "2996",
        "AirportCode": "HUK",
        "AirportName": "Hukuntsi Airport",
        "City": "Hukuntsi",
        "Country": "Botswana"
    },
    {
        "id": "2997",
        "AirportCode": "HUL",
        "AirportName": "Houlton International Airport",
        "City": "Houlton",
        "Country": "USA Maine"
    },
    {
        "id": "2998",
        "AirportCode": "HUM",
        "AirportName": "Terrebonne Airport",
        "City": "Houma",
        "Country": "USA Louisiana"
    },
    {
        "id": "2999",
        "AirportCode": "HUN",
        "AirportName": "Hualien Airport",
        "City": "Hualien",
        "Country": "Taiwan"
    },
    {
        "id": "3000",
        "AirportCode": "HUQ",
        "AirportName": "Houn Airport",
        "City": "Houn",
        "Country": "Libya"
    },
    {
        "id": "3001",
        "AirportCode": "HUS",
        "AirportName": "Hughes Municipal Airport",
        "City": "Hughes",
        "Country": "USA Alaska"
    },
    {
        "id": "3002",
        "AirportCode": "HUT",
        "AirportName": "Hutchinson Airport",
        "City": "Hutchinson",
        "Country": "USA Kansas"
    },
    {
        "id": "3003",
        "AirportCode": "HUU",
        "AirportName": "Huanuco Airport",
        "City": "Huanuco",
        "Country": "Peru"
    },
    {
        "id": "3004",
        "AirportCode": "HUV",
        "AirportName": "Hudiksvall Airport",
        "City": "Hudiksvall",
        "Country": "Sweden"
    },
    {
        "id": "3005",
        "AirportCode": "HUX",
        "AirportName": "Huatulco Airport",
        "City": "Huatulco",
        "Country": "Mexico"
    },
    {
        "id": "3006",
        "AirportCode": "HUY",
        "AirportName": "Humberside Airport",
        "City": "Humberside",
        "Country": "United Kingdom"
    },
    {
        "id": "3007",
        "AirportCode": "HUZ",
        "AirportName": "Huizhou Airport",
        "City": "Huizhou",
        "Country": "China"
    },
    {
        "id": "3008",
        "AirportCode": "HVA",
        "AirportName": "Analalava Airport",
        "City": "Analalava",
        "Country": "Madagascar"
    },
    {
        "id": "3009",
        "AirportCode": "HVB",
        "AirportName": "Hervey Bay Airport",
        "City": "Hervey Bay",
        "Country": "Australia"
    },
    {
        "id": "3010",
        "AirportCode": "HVD",
        "AirportName": "Khovd Airport",
        "City": "Khovd",
        "Country": "Mongolia"
    },
    {
        "id": "3011",
        "AirportCode": "HVE",
        "AirportName": "Hanksville Intermediate Airport",
        "City": "Hanksville",
        "Country": "USA Utah"
    },
    {
        "id": "3012",
        "AirportCode": "HVG",
        "AirportName": "Valan Airport",
        "City": "Honningsvag",
        "Country": "Norway"
    },
    {
        "id": "3013",
        "AirportCode": "HVK",
        "AirportName": "Holmavik Airport",
        "City": "Holmavik",
        "Country": "Iceland"
    },
    {
        "id": "3014",
        "AirportCode": "HVM",
        "AirportName": "Hvammstangi Airport",
        "City": "Hvammstangi",
        "Country": "Iceland"
    },
    {
        "id": "3015",
        "AirportCode": "HVN",
        "AirportName": "New Haven Airport",
        "City": "New Haven",
        "Country": "USA Connecticut"
    },
    {
        "id": "3016",
        "AirportCode": "HVR",
        "AirportName": "Havre City County Airport",
        "City": "Havre",
        "Country": "USA Montana"
    },
    {
        "id": "3017",
        "AirportCode": "HVS",
        "AirportName": "Hartsville Municipal Airport",
        "City": "Hartsville",
        "Country": "USA South Carolina"
    },
    {
        "id": "3018",
        "AirportCode": "HWA",
        "AirportName": "Hawabango Airport",
        "City": "Hawabango",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3019",
        "AirportCode": "HWD",
        "AirportName": "Hayward Air Terminal",
        "City": "Hayward",
        "Country": "USA California"
    },
    {
        "id": "3020",
        "AirportCode": "HWK",
        "AirportName": "Wilpena Pound Airport",
        "City": "Hawker",
        "Country": "Australia"
    },
    {
        "id": "3021",
        "AirportCode": "HWN",
        "AirportName": "Hwange National Park Airport",
        "City": "Hwange National Park",
        "Country": "Zimbabwe"
    },
    {
        "id": "3022",
        "AirportCode": "HWO",
        "AirportName": "North Perry Airport",
        "City": "Hollywood",
        "Country": "USA Florida"
    },
    {
        "id": "3023",
        "AirportCode": "HXX",
        "AirportName": "Hay Airport",
        "City": "Hay",
        "Country": "Australia"
    },
    {
        "id": "3024",
        "AirportCode": "HYA",
        "AirportName": "Barnstable Airport",
        "City": "Hyannis",
        "Country": "USA Massachusetts"
    },
    {
        "id": "3025",
        "AirportCode": "HYC",
        "AirportName": "High Wycombe Airport",
        "City": "High Wycombe",
        "Country": "United Kingdom"
    },
    {
        "id": "3026",
        "AirportCode": "HYD",
        "AirportName": "Rajiv Gandhi International Airport",
        "City": "Hyderabad",
        "Country": "India"
    },
    {
        "id": "3027",
        "AirportCode": "HYF",
        "AirportName": "Hayfields Airport",
        "City": "Hayfields",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3028",
        "AirportCode": "HYG",
        "AirportName": "Hydaburg Sea Plane Base",
        "City": "Hydaburg",
        "Country": "USA Alaska"
    },
    {
        "id": "3029",
        "AirportCode": "HYL",
        "AirportName": "Hollis Sea Plane Base",
        "City": "Hollis",
        "Country": "USA Alaska"
    },
    {
        "id": "3030",
        "AirportCode": "HYN",
        "AirportName": "Huangyan Airport",
        "City": "Huangyan",
        "Country": "China"
    },
    {
        "id": "3031",
        "AirportCode": "HYR",
        "AirportName": "Hayward Municipal Airport",
        "City": "Hayward",
        "Country": "USA Wisconsin"
    },
    {
        "id": "3032",
        "AirportCode": "HYS",
        "AirportName": "Hays Municipal Airport",
        "City": "Hays",
        "Country": "USA Kansas"
    },
    {
        "id": "3033",
        "AirportCode": "HYV",
        "AirportName": "Hyvinkaa Airport",
        "City": "Hyvinkaa",
        "Country": "Finland"
    },
    {
        "id": "3034",
        "AirportCode": "HZB",
        "AirportName": "Merville/Calonne Airport",
        "City": "Hazebrouck",
        "Country": "France"
    },
    {
        "id": "3035",
        "AirportCode": "HZG",
        "AirportName": "Hanzhong Airport",
        "City": "Hanzhong",
        "Country": "China"
    },
    {
        "id": "3036",
        "AirportCode": "HZK",
        "AirportName": "Husavik Airport",
        "City": "Husavik",
        "Country": "Iceland"
    },
    {
        "id": "3037",
        "AirportCode": "HZL",
        "AirportName": "Hazleton Airport",
        "City": "Hazleton",
        "Country": "USA Philadelphia"
    },
    {
        "id": "3038",
        "AirportCode": "HZV",
        "AirportName": "Hazyview Airport",
        "City": "Hazyview",
        "Country": "South Africa"
    },
    {
        "id": "3039",
        "AirportCode": "IAA",
        "AirportName": "Igarka Airport",
        "City": "Igarka",
        "Country": "Russia"
    },
    {
        "id": "3040",
        "AirportCode": "IAB",
        "AirportName": "McConnell Air Force Base",
        "City": "Wichita",
        "Country": "USA Kansas"
    },
    {
        "id": "3041",
        "AirportCode": "IAD",
        "AirportName": "Dulles International Airport",
        "City": "Washington",
        "Country": "USA DC"
    },
    {
        "id": "3042",
        "AirportCode": "IAG",
        "AirportName": "Niagara Falls International Airport",
        "City": "Niagara Falls",
        "Country": "USA New York"
    },
    {
        "id": "3043",
        "AirportCode": "IAH",
        "AirportName": "George Bush Intercontinental Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3044",
        "AirportCode": "IAM",
        "AirportName": "In Amenas Airport",
        "City": "In Amenas",
        "Country": "Algeria"
    },
    {
        "id": "3045",
        "AirportCode": "IAN",
        "AirportName": "Bob Barker Memorial Airport",
        "City": "Kiana",
        "Country": "USA Alaska"
    },
    {
        "id": "3046",
        "AirportCode": "IAQ",
        "AirportName": "Bahregan Airport",
        "City": "Bahregan",
        "Country": "Iran"
    },
    {
        "id": "3047",
        "AirportCode": "IAR",
        "AirportName": "Yaroslavl Airport",
        "City": "Yaroslavl",
        "Country": "Russia"
    },
    {
        "id": "3048",
        "AirportCode": "IAS",
        "AirportName": "Iasi Airport",
        "City": "Iasi",
        "Country": "Romania"
    },
    {
        "id": "3049",
        "AirportCode": "IAU",
        "AirportName": "Iaura Airport",
        "City": "Iaura",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3050",
        "AirportCode": "IBA",
        "AirportName": "Ibadan Airport",
        "City": "Ibadan",
        "Country": "Nigeria"
    },
    {
        "id": "3051",
        "AirportCode": "IBE",
        "AirportName": "Ibague Airport",
        "City": "Ibague",
        "Country": "Colombia"
    },
    {
        "id": "3052",
        "AirportCode": "IBI",
        "AirportName": "Iboki Airport",
        "City": "Iboki",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3053",
        "AirportCode": "IBO",
        "AirportName": "Ibo Airport",
        "City": "Ibo",
        "Country": "Mozambique"
    },
    {
        "id": "3054",
        "AirportCode": "IBP",
        "AirportName": "Iberia Airport",
        "City": "Iberia",
        "Country": "Peru"
    },
    {
        "id": "3055",
        "AirportCode": "IBZ",
        "AirportName": "Ibiza Airport",
        "City": "Ibiza",
        "Country": "Spain"
    },
    {
        "id": "3056",
        "AirportCode": "ICA",
        "AirportName": "Icabaru Airport",
        "City": "Icabaru",
        "Country": "Venezuela"
    },
    {
        "id": "3057",
        "AirportCode": "ICI",
        "AirportName": "Cicia Airport",
        "City": "Cicia",
        "Country": "Fiji"
    },
    {
        "id": "3058",
        "AirportCode": "ICK",
        "AirportName": "Nieuw Nickerie Airport",
        "City": "Nieuw Nickerie",
        "Country": "Suriname"
    },
    {
        "id": "3059",
        "AirportCode": "ICL",
        "AirportName": "Clarinda Municipal Airport",
        "City": "Clarinda",
        "Country": "USA Iowa"
    },
    {
        "id": "3060",
        "AirportCode": "ICN",
        "AirportName": "Incheon International Airport",
        "City": "Seoul",
        "Country": "Korea"
    },
    {
        "id": "3061",
        "AirportCode": "ICO",
        "AirportName": "Sicogon Island Airport",
        "City": "Sicogon Island",
        "Country": "Philippines"
    },
    {
        "id": "3062",
        "AirportCode": "ICR",
        "AirportName": "Nicaro Airport",
        "City": "Nicaro",
        "Country": "Cuba"
    },
    {
        "id": "3063",
        "AirportCode": "ICT",
        "AirportName": "Mid-Continent Airport",
        "City": "Wichita",
        "Country": "USA Kansas"
    },
    {
        "id": "3064",
        "AirportCode": "IDA",
        "AirportName": "Fanning Field",
        "City": "Idaho Falls",
        "Country": "USA Idaho"
    },
    {
        "id": "3065",
        "AirportCode": "IDB",
        "AirportName": "Idre Airport",
        "City": "Idre",
        "Country": "Sweden"
    },
    {
        "id": "3066",
        "AirportCode": "IDF",
        "AirportName": "Idiofa Airport",
        "City": "Idiofa",
        "Country": "Congo, DR"
    },
    {
        "id": "3067",
        "AirportCode": "IDG",
        "AirportName": "Ida Grove Municipal Airport",
        "City": "Ida Grove",
        "Country": "USA Iowa"
    },
    {
        "id": "3068",
        "AirportCode": "IDI",
        "AirportName": "Indiana Airport",
        "City": "Indiana",
        "Country": "USA Philadelphia"
    },
    {
        "id": "3069",
        "AirportCode": "IDK",
        "AirportName": "Indulkana Airport",
        "City": "Indulkana",
        "Country": "Australia"
    },
    {
        "id": "3070",
        "AirportCode": "IDN",
        "AirportName": "Indagen Airport",
        "City": "Indagen",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3071",
        "AirportCode": "IDO",
        "AirportName": "Santa Isabel do Morro Airport",
        "City": "Santa Isabel do Morro",
        "Country": "Brazil"
    },
    {
        "id": "3072",
        "AirportCode": "IDP",
        "AirportName": "Independence Airport",
        "City": "Independence",
        "Country": "USA Kansas"
    },
    {
        "id": "3073",
        "AirportCode": "IDR",
        "AirportName": "Indore Airport",
        "City": "Indore",
        "Country": "India"
    },
    {
        "id": "3074",
        "AirportCode": "IEG",
        "AirportName": "Babimost Airport",
        "City": "Zielona Gora",
        "Country": "Poland"
    },
    {
        "id": "3075",
        "AirportCode": "IEJ",
        "AirportName": "Iejima Airport",
        "City": "Iejima",
        "Country": "Japan"
    },
    {
        "id": "3076",
        "AirportCode": "IES",
        "AirportName": "Riesa Airport",
        "City": "Riesa",
        "Country": "Germany"
    },
    {
        "id": "3077",
        "AirportCode": "IEV",
        "AirportName": "Kiev-Zhulhany Airport",
        "City": "Kiev",
        "Country": "Ukraine"
    },
    {
        "id": "3078",
        "AirportCode": "IFA",
        "AirportName": "Iowa Falls Airport",
        "City": "Iowa Falls",
        "Country": "USA Iowa"
    },
    {
        "id": "3079",
        "AirportCode": "IFF",
        "AirportName": "Iffley Airport",
        "City": "Iffley",
        "Country": "Australia"
    },
    {
        "id": "3080",
        "AirportCode": "IFH",
        "AirportName": "Hesa Airport",
        "City": "Hesa",
        "Country": "Iran"
    },
    {
        "id": "3081",
        "AirportCode": "IFJ",
        "AirportName": "Isafjordur Airport",
        "City": "Isafjordur",
        "Country": "Iceland"
    },
    {
        "id": "3082",
        "AirportCode": "IFL",
        "AirportName": "Innisfail Airport",
        "City": "Innisfail",
        "Country": "Australia"
    },
    {
        "id": "3083",
        "AirportCode": "IFN",
        "AirportName": "Isfahan Airport",
        "City": "Isfahan",
        "Country": "Iran"
    },
    {
        "id": "3084",
        "AirportCode": "IFO",
        "AirportName": "Ivano-Frankovsk Airport",
        "City": "Ivano-Frankovsk",
        "Country": "Ukraine"
    },
    {
        "id": "3085",
        "AirportCode": "IFP",
        "AirportName": "Laughlin Bullhead International Airport",
        "City": "Bullhead City",
        "Country": "USA Arizona"
    },
    {
        "id": "3086",
        "AirportCode": "IGA",
        "AirportName": "Inagua Airport",
        "City": "Inagua",
        "Country": "Bahamas"
    },
    {
        "id": "3087",
        "AirportCode": "IGB",
        "AirportName": "Ingeniero Jacobacci Airport",
        "City": "Ingeniero Jacobacci",
        "Country": "Argentina"
    },
    {
        "id": "3088",
        "AirportCode": "IGE",
        "AirportName": "Iguela Airport",
        "City": "Iguela",
        "Country": "Gabon"
    },
    {
        "id": "3089",
        "AirportCode": "IGG",
        "AirportName": "Igiugig Airport",
        "City": "Igiugig",
        "Country": "USA Alaska"
    },
    {
        "id": "3090",
        "AirportCode": "IGH",
        "AirportName": "Ingham Airport",
        "City": "Ingham",
        "Country": "Australia"
    },
    {
        "id": "3091",
        "AirportCode": "IGL",
        "AirportName": "Cigli Military Airport",
        "City": "Izmir",
        "Country": "Turkey"
    },
    {
        "id": "3092",
        "AirportCode": "IGM",
        "AirportName": "Kingman Airport",
        "City": "Kingman",
        "Country": "USA Arizona"
    },
    {
        "id": "3093",
        "AirportCode": "IGN",
        "AirportName": "Maria Cristina Airport",
        "City": "Iligan",
        "Country": "Philippines"
    },
    {
        "id": "3094",
        "AirportCode": "IGO",
        "AirportName": "Chigorodo Airport",
        "City": "Chigorodo",
        "Country": "Colombia"
    },
    {
        "id": "3095",
        "AirportCode": "IGR",
        "AirportName": "Cataratas Airport",
        "City": "Iguazu",
        "Country": "Argentina"
    },
    {
        "id": "3096",
        "AirportCode": "IGU",
        "AirportName": "Cataratas Airport",
        "City": "Iguassu Falls",
        "Country": "Brazil"
    },
    {
        "id": "3097",
        "AirportCode": "IHA",
        "AirportName": "Niihama Airport",
        "City": "Niihama",
        "Country": "Japan"
    },
    {
        "id": "3098",
        "AirportCode": "IHC",
        "AirportName": "Inhaca Airport",
        "City": "Inhaca",
        "Country": "Mozambique"
    },
    {
        "id": "3099",
        "AirportCode": "IHN",
        "AirportName": "Qishn Airport",
        "City": "Qishn",
        "Country": "Yemen"
    },
    {
        "id": "3100",
        "AirportCode": "IHO",
        "AirportName": "Ihosy Airport",
        "City": "Ihosy",
        "Country": "Madagascar"
    },
    {
        "id": "3101",
        "AirportCode": "IHR",
        "AirportName": "Iran Shahr Airport",
        "City": "Iran Shahr",
        "Country": "Iran"
    },
    {
        "id": "3102",
        "AirportCode": "IHU",
        "AirportName": "Ihu Airport",
        "City": "Ihu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3103",
        "AirportCode": "IIA",
        "AirportName": "Inishmaan Airport",
        "City": "Inishmaan",
        "Country": "Ireland"
    },
    {
        "id": "3104",
        "AirportCode": "IIL",
        "AirportName": "Ilaam Airport",
        "City": "Ilaam",
        "Country": "Iran"
    },
    {
        "id": "3105",
        "AirportCode": "IIN",
        "AirportName": "Nishinoomote Airport",
        "City": "Nishinoomote",
        "Country": "Japan"
    },
    {
        "id": "3106",
        "AirportCode": "IIS",
        "AirportName": "Nissan Island Airport",
        "City": "Nissan Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3107",
        "AirportCode": "IJK",
        "AirportName": "Izhevsk Airport",
        "City": "Izhevsk",
        "Country": "Russia"
    },
    {
        "id": "3108",
        "AirportCode": "IJU",
        "AirportName": "J. Batista Bos Filho Airport",
        "City": "Ijui",
        "Country": "Brazil"
    },
    {
        "id": "3109",
        "AirportCode": "IJX",
        "AirportName": "Jacksonville Municipal Airport",
        "City": "Jacksonville",
        "Country": "USA Illinois"
    },
    {
        "id": "3110",
        "AirportCode": "IKA",
        "AirportName": "Imam Khomeini Airport",
        "City": "Tehran",
        "Country": "Iran"
    },
    {
        "id": "3111",
        "AirportCode": "IKB",
        "AirportName": "Wilkes County Airport",
        "City": "Wilkesboro",
        "Country": "USA North Carolina"
    },
    {
        "id": "3112",
        "AirportCode": "IKI",
        "AirportName": "Iki Airport",
        "City": "Iki",
        "Country": "Japan"
    },
    {
        "id": "3113",
        "AirportCode": "IKK",
        "AirportName": "Greater Kankakee Airport",
        "City": "Kankakee",
        "Country": "USA Illinois"
    },
    {
        "id": "3114",
        "AirportCode": "IKL",
        "AirportName": "Ikela Airport",
        "City": "Ikela",
        "Country": "Congo, DR"
    },
    {
        "id": "3115",
        "AirportCode": "IKO",
        "AirportName": "Nikolski Air Force Station",
        "City": "Nikolski",
        "Country": "USA Alaska"
    },
    {
        "id": "3116",
        "AirportCode": "IKP",
        "AirportName": "Inkerman Airport",
        "City": "Inkerman",
        "Country": "Australia"
    },
    {
        "id": "3117",
        "AirportCode": "IKS",
        "AirportName": "Tiksi Airport",
        "City": "Tiksi",
        "Country": "Russia"
    },
    {
        "id": "3118",
        "AirportCode": "IKT",
        "AirportName": "Irkutsk Airport",
        "City": "Irkutsk",
        "Country": "Russia"
    },
    {
        "id": "3119",
        "AirportCode": "ILA",
        "AirportName": "Illaga Airport",
        "City": "Illaga",
        "Country": "Indonesia"
    },
    {
        "id": "3120",
        "AirportCode": "ILB",
        "AirportName": "Ilha Solteira Airport",
        "City": "Ilha Solteira",
        "Country": "Brazil"
    },
    {
        "id": "3121",
        "AirportCode": "ILE",
        "AirportName": "Killeen Municipal Airport",
        "City": "Killeen",
        "Country": "USA Texas"
    },
    {
        "id": "3122",
        "AirportCode": "ILF",
        "AirportName": "Ilford Airport",
        "City": "Ilford",
        "Country": "Canada"
    },
    {
        "id": "3123",
        "AirportCode": "ILG",
        "AirportName": "New Castle Airport",
        "City": "Wilmington",
        "Country": "USA Delaware"
    },
    {
        "id": "3124",
        "AirportCode": "ILI",
        "AirportName": "Iliamna Airport",
        "City": "Iliamna",
        "Country": "USA Alaska"
    },
    {
        "id": "3125",
        "AirportCode": "ILK",
        "AirportName": "Ilaka Airport",
        "City": "Ilaka",
        "Country": "Madagascar"
    },
    {
        "id": "3126",
        "AirportCode": "ILL",
        "AirportName": "Willmar Airport",
        "City": "Willmar",
        "Country": "USA Minnesota"
    },
    {
        "id": "3127",
        "AirportCode": "ILM",
        "AirportName": "Wilmington International Airport",
        "City": "Wilmington",
        "Country": "USA North Carolina"
    },
    {
        "id": "3128",
        "AirportCode": "ILN",
        "AirportName": "Clinton Field",
        "City": "Wilmington",
        "Country": "USA Ohio"
    },
    {
        "id": "3129",
        "AirportCode": "ILO",
        "AirportName": "Mandurriao Airport",
        "City": "Iloilo",
        "Country": "Philippines"
    },
    {
        "id": "3130",
        "AirportCode": "ILP",
        "AirportName": "Ile Des Pins Airport",
        "City": "Ile Des Pins",
        "Country": "New Caledonia"
    },
    {
        "id": "3131",
        "AirportCode": "ILQ",
        "AirportName": "Ilo Airport",
        "City": "Ilo",
        "Country": "Peru"
    },
    {
        "id": "3132",
        "AirportCode": "ILR",
        "AirportName": "Ilorin Airport",
        "City": "Ilorin",
        "Country": "Nigeria"
    },
    {
        "id": "3133",
        "AirportCode": "ILU",
        "AirportName": "Kilaguni Airport",
        "City": "Kilaguni",
        "Country": "Kenya"
    },
    {
        "id": "3134",
        "AirportCode": "ILY",
        "AirportName": "Glenegedale Airport",
        "City": "Islay",
        "Country": "United Kingdom"
    },
    {
        "id": "3135",
        "AirportCode": "ILZ",
        "AirportName": "Zilina Airport",
        "City": "Zilina",
        "Country": "Slovakia"
    },
    {
        "id": "3136",
        "AirportCode": "IMA",
        "AirportName": "Iamalele Airport",
        "City": "Iamalele",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3137",
        "AirportCode": "IMB",
        "AirportName": "Imbaimadai Airport",
        "City": "Imbaimadai",
        "Country": "Guyana"
    },
    {
        "id": "3138",
        "AirportCode": "IMD",
        "AirportName": "Imonda Airport",
        "City": "Imonda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3139",
        "AirportCode": "IMF",
        "AirportName": "Imphal Municipal Airport",
        "City": "Imphal",
        "Country": "India"
    },
    {
        "id": "3140",
        "AirportCode": "IMG",
        "AirportName": "Inhaminga Airport",
        "City": "Inhaminga",
        "Country": "Mozambique"
    },
    {
        "id": "3141",
        "AirportCode": "IMI",
        "AirportName": "Ine Island Airport",
        "City": "Ine Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "3142",
        "AirportCode": "IMK",
        "AirportName": "Simikot Airport",
        "City": "Simikot",
        "Country": "Nepal"
    },
    {
        "id": "3143",
        "AirportCode": "IML",
        "AirportName": "Imperial Airport",
        "City": "Imperial",
        "Country": "USA Nebraska"
    },
    {
        "id": "3144",
        "AirportCode": "IMM",
        "AirportName": "Immokalee Airport",
        "City": "Immokalee",
        "Country": "USA Florida"
    },
    {
        "id": "3145",
        "AirportCode": "IMN",
        "AirportName": "Imane Airport",
        "City": "Imane",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3146",
        "AirportCode": "IMO",
        "AirportName": "Zemio Airport",
        "City": "Zemio",
        "Country": "Central African Republic"
    },
    {
        "id": "3147",
        "AirportCode": "IMP",
        "AirportName": "Imperatriz Airport",
        "City": "Imperatriz",
        "Country": "Brazil"
    },
    {
        "id": "3148",
        "AirportCode": "IMT",
        "AirportName": "Ford Airport",
        "City": "Iron Mountain",
        "Country": "USA Michigan"
    },
    {
        "id": "3149",
        "AirportCode": "IMZ",
        "AirportName": "Nimroz Airport",
        "City": "Nimroz",
        "Country": "Afghanistan"
    },
    {
        "id": "3150",
        "AirportCode": "INA",
        "AirportName": "Inta Airport",
        "City": "Inta",
        "Country": "Russia"
    },
    {
        "id": "3151",
        "AirportCode": "INB",
        "AirportName": "Independence Airport",
        "City": "Independence",
        "Country": "Belize"
    },
    {
        "id": "3152",
        "AirportCode": "INC",
        "AirportName": "Yinchuan Airport",
        "City": "Yinchuan",
        "Country": "China"
    },
    {
        "id": "3153",
        "AirportCode": "IND",
        "AirportName": "Indianapolis International Airport",
        "City": "Indianapolis",
        "Country": "USA Indiana"
    },
    {
        "id": "3154",
        "AirportCode": "INE",
        "AirportName": "Chinde Airport",
        "City": "Chinde",
        "Country": "Mozambique"
    },
    {
        "id": "3155",
        "AirportCode": "INF",
        "AirportName": "In Guezzam Airport",
        "City": "In Guezzam",
        "Country": "Algeria"
    },
    {
        "id": "3156",
        "AirportCode": "ING",
        "AirportName": "Lago Argentino Airport",
        "City": "Lago Argentino",
        "Country": "Argentina"
    },
    {
        "id": "3157",
        "AirportCode": "INH",
        "AirportName": "Inhambane Airport",
        "City": "Inhambane",
        "Country": "Mozambique"
    },
    {
        "id": "3158",
        "AirportCode": "INI",
        "AirportName": "Nis Airport",
        "City": "Nis",
        "Country": "Serbia"
    },
    {
        "id": "3159",
        "AirportCode": "INJ",
        "AirportName": "Injune Airport",
        "City": "Injune",
        "Country": "Australia"
    },
    {
        "id": "3160",
        "AirportCode": "INK",
        "AirportName": "Wink Airport",
        "City": "Wink",
        "Country": "USA Texas"
    },
    {
        "id": "3161",
        "AirportCode": "INL",
        "AirportName": "Falls International Airport",
        "City": "International Falls",
        "Country": "USA Minnesota"
    },
    {
        "id": "3162",
        "AirportCode": "INM",
        "AirportName": "Innamincka Airport",
        "City": "Innamincka",
        "Country": "Australia"
    },
    {
        "id": "3163",
        "AirportCode": "INN",
        "AirportName": "Kranebitten Airport",
        "City": "Innsbruck (INN)",
        "Country": "Austria"
    },
    {
        "id": "3164",
        "AirportCode": "INO",
        "AirportName": "Inongo Airport",
        "City": "Inongo",
        "Country": "Congo, DR"
    },
    {
        "id": "3165",
        "AirportCode": "INQ",
        "AirportName": "Inisheer Airport",
        "City": "Inisheer",
        "Country": "Ireland"
    },
    {
        "id": "3166",
        "AirportCode": "INR",
        "AirportName": "Kincheloe Air Force Base",
        "City": "Sault Ste. Marie",
        "Country": "USA Michigan"
    },
    {
        "id": "3167",
        "AirportCode": "INS",
        "AirportName": "Af Aux Airport",
        "City": "Indian Springs",
        "Country": "USA Nevada"
    },
    {
        "id": "3168",
        "AirportCode": "INT",
        "AirportName": "Smith-Reynolds Airport",
        "City": "Winston-Salem",
        "Country": "USA North Carolina"
    },
    {
        "id": "3169",
        "AirportCode": "INU",
        "AirportName": "Nauru Island International Airport",
        "City": "Nauru Island",
        "Country": "Nauru"
    },
    {
        "id": "3170",
        "AirportCode": "INV",
        "AirportName": "Inverness Airport",
        "City": "Inverness",
        "Country": "United Kingdom"
    },
    {
        "id": "3171",
        "AirportCode": "INW",
        "AirportName": "Winslow Airport",
        "City": "Winslow",
        "Country": "USA Arizona"
    },
    {
        "id": "3172",
        "AirportCode": "INX",
        "AirportName": "Inanwatan Airport",
        "City": "Inanwatan",
        "Country": "Indonesia"
    },
    {
        "id": "3173",
        "AirportCode": "INY",
        "AirportName": "Inyati Airport",
        "City": "Inyati",
        "Country": "South Africa"
    },
    {
        "id": "3174",
        "AirportCode": "INZ",
        "AirportName": "In Salah Airport",
        "City": "In Salah",
        "Country": "Algeria"
    },
    {
        "id": "3175",
        "AirportCode": "IOA",
        "AirportName": "Ioannina Airport",
        "City": "Ioannina",
        "Country": "Greece"
    },
    {
        "id": "3176",
        "AirportCode": "IOK",
        "AirportName": "Iokea Airport",
        "City": "Iokea",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3177",
        "AirportCode": "IOM",
        "AirportName": "Ronaldsway Airport",
        "City": "Isle Of Man",
        "Country": "United Kingdom"
    },
    {
        "id": "3178",
        "AirportCode": "ION",
        "AirportName": "Impfondo Airport",
        "City": "Impfondo",
        "Country": "Congo"
    },
    {
        "id": "3179",
        "AirportCode": "IOP",
        "AirportName": "Ioma Airport",
        "City": "Ioma",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3180",
        "AirportCode": "IOR",
        "AirportName": "Kilronan Airport",
        "City": "Inishmore",
        "Country": "Ireland"
    },
    {
        "id": "3181",
        "AirportCode": "IOS",
        "AirportName": "Eduardo Gomes Airport",
        "City": "Ilheus",
        "Country": "Brazil"
    },
    {
        "id": "3182",
        "AirportCode": "IOU",
        "AirportName": "Ile Ouen Airport",
        "City": "Ile Ouen",
        "Country": "New Caledonia"
    },
    {
        "id": "3183",
        "AirportCode": "IOW",
        "AirportName": "Iowa City Airport",
        "City": "Iowa City",
        "Country": "USA Iowa"
    },
    {
        "id": "3184",
        "AirportCode": "IPA",
        "AirportName": "Ipota Airport",
        "City": "Ipota",
        "Country": "Vanuatu"
    },
    {
        "id": "3185",
        "AirportCode": "IPC",
        "AirportName": "Mataveri International Airport",
        "City": "Easter Island",
        "Country": "Chile"
    },
    {
        "id": "3186",
        "AirportCode": "IPE",
        "AirportName": "Ipil Airport",
        "City": "Ipil",
        "Country": "Philippines"
    },
    {
        "id": "3187",
        "AirportCode": "IPG",
        "AirportName": "Ipiranga Airport",
        "City": "Ipiranga",
        "Country": "Brazil"
    },
    {
        "id": "3188",
        "AirportCode": "IPH",
        "AirportName": "Ipoh Airport",
        "City": "Ipoh",
        "Country": "Malaysia"
    },
    {
        "id": "3189",
        "AirportCode": "IPI",
        "AirportName": "San Luis Airport",
        "City": "Ipiales",
        "Country": "Colombia"
    },
    {
        "id": "3190",
        "AirportCode": "IPL",
        "AirportName": "Imperial County Airport",
        "City": "Imperial",
        "Country": "USA California"
    },
    {
        "id": "3191",
        "AirportCode": "IPN",
        "AirportName": "Usiminas Airport",
        "City": "Ipatinga",
        "Country": "Brazil"
    },
    {
        "id": "3192",
        "AirportCode": "IPT",
        "AirportName": "Lycoming County Airport",
        "City": "Williamsport",
        "Country": "USA Philadelphia"
    },
    {
        "id": "3193",
        "AirportCode": "IPU",
        "AirportName": "Ipiau Airport",
        "City": "Ipiau",
        "Country": "Brazil"
    },
    {
        "id": "3194",
        "AirportCode": "IPW",
        "AirportName": "Ipswich Airport",
        "City": "Ipswich",
        "Country": "United Kingdom"
    },
    {
        "id": "3195",
        "AirportCode": "IQM",
        "AirportName": "Qiemo Airport",
        "City": "Qiemo",
        "Country": "China"
    },
    {
        "id": "3196",
        "AirportCode": "IQN",
        "AirportName": "Qingyang Airport",
        "City": "Qingyang",
        "Country": "China"
    },
    {
        "id": "3197",
        "AirportCode": "IQQ",
        "AirportName": "Cavancha Airport",
        "City": "Iquique",
        "Country": "Chile"
    },
    {
        "id": "3198",
        "AirportCode": "IQT",
        "AirportName": "C.F. Secada Airport",
        "City": "Iquitos",
        "Country": "Peru"
    },
    {
        "id": "3199",
        "AirportCode": "IRA",
        "AirportName": "Kirakira Airport",
        "City": "Kirakira",
        "Country": "Solomon Islands"
    },
    {
        "id": "3200",
        "AirportCode": "IRB",
        "AirportName": "Iraan Municipal Airport",
        "City": "Iraan",
        "Country": "USA Texas"
    },
    {
        "id": "3201",
        "AirportCode": "IRC",
        "AirportName": "Circle City Airport",
        "City": "Circle",
        "Country": "USA Alaska"
    },
    {
        "id": "3202",
        "AirportCode": "IRD",
        "AirportName": "Ishurdi Airport",
        "City": "Ishurdi",
        "Country": "Bangladesh"
    },
    {
        "id": "3203",
        "AirportCode": "IRE",
        "AirportName": "Irece Airport",
        "City": "Irece",
        "Country": "Brazil"
    },
    {
        "id": "3204",
        "AirportCode": "IRG",
        "AirportName": "Lockhart River Airport",
        "City": "Lockhart River",
        "Country": "Australia"
    },
    {
        "id": "3205",
        "AirportCode": "IRI",
        "AirportName": "Nduli Airport",
        "City": "Iringa",
        "Country": "Tanzania"
    },
    {
        "id": "3206",
        "AirportCode": "IRJ",
        "AirportName": "La Rioja Airport",
        "City": "La Rioja",
        "Country": "Argentina"
    },
    {
        "id": "3207",
        "AirportCode": "IRK",
        "AirportName": "Kirksville Municipal Airport",
        "City": "Kirksville",
        "Country": "USA Missouri"
    },
    {
        "id": "3208",
        "AirportCode": "IRN",
        "AirportName": "Iriona Airport",
        "City": "Iriona",
        "Country": "Honduras"
    },
    {
        "id": "3209",
        "AirportCode": "IRO",
        "AirportName": "Birao Airport",
        "City": "Birao",
        "Country": "Central African Republic"
    },
    {
        "id": "3210",
        "AirportCode": "IRP",
        "AirportName": "Matari",
        "City": "Isiro",
        "Country": "Congo, DR"
    },
    {
        "id": "3211",
        "AirportCode": "IRS",
        "AirportName": "Kirsch Municipal",
        "City": "Sturgis",
        "Country": "USA Michigan"
    },
    {
        "id": "3212",
        "AirportCode": "ISA",
        "AirportName": "Mount Isa Airport",
        "City": "Mount Isa",
        "Country": "Australia"
    },
    {
        "id": "3213",
        "AirportCode": "ISB",
        "AirportName": "Islamabad International Airport",
        "City": "Islamabad",
        "Country": "Pakistan"
    },
    {
        "id": "3214",
        "AirportCode": "ISD",
        "AirportName": "Iscuande Airport",
        "City": "Iscuande",
        "Country": "Colombia"
    },
    {
        "id": "3215",
        "AirportCode": "ISE",
        "AirportName": "Isparta Airport",
        "City": "Isparta",
        "Country": "Turkey"
    },
    {
        "id": "3216",
        "AirportCode": "ISG",
        "AirportName": "Ishigaki Airport",
        "City": "Ishigaki",
        "Country": "Japan"
    },
    {
        "id": "3217",
        "AirportCode": "ISH",
        "AirportName": "Ischia Airport",
        "City": "Ischia",
        "Country": "Italy"
    },
    {
        "id": "3218",
        "AirportCode": "ISI",
        "AirportName": "Isisford Airport",
        "City": "Isisford",
        "Country": "Australia"
    },
    {
        "id": "3219",
        "AirportCode": "ISJ",
        "AirportName": "Isla Mujeres Airport",
        "City": "Isla Mujeres",
        "Country": "Mexico"
    },
    {
        "id": "3220",
        "AirportCode": "ISK",
        "AirportName": "Gandhinagar Airport",
        "City": "Nasik",
        "Country": "India"
    },
    {
        "id": "3221",
        "AirportCode": "ISM",
        "AirportName": "Kissimmee Municipal Airport",
        "City": "Kissimmee",
        "Country": "USA Florida"
    },
    {
        "id": "3222",
        "AirportCode": "ISN",
        "AirportName": "Sloulin Field International Airport",
        "City": "Williston",
        "Country": "USA North Dakota"
    },
    {
        "id": "3223",
        "AirportCode": "ISO",
        "AirportName": "Stallings Field",
        "City": "Kinston",
        "Country": "USA North Carolina"
    },
    {
        "id": "3224",
        "AirportCode": "ISP",
        "AirportName": "Long Island MacArthur Airport",
        "City": "Islip",
        "Country": "USA New York"
    },
    {
        "id": "3225",
        "AirportCode": "ISQ",
        "AirportName": "Schoolcraft County Airport",
        "City": "Manistique",
        "Country": "USA Michigan"
    },
    {
        "id": "3226",
        "AirportCode": "ISS",
        "AirportName": "Wiscasset Airport",
        "City": "Wiscasset",
        "Country": "USA Maine"
    },
    {
        "id": "3227",
        "AirportCode": "IST",
        "AirportName": "Ataturk Airport",
        "City": "Istanbul",
        "Country": "Turkey"
    },
    {
        "id": "3228",
        "AirportCode": "ISW",
        "AirportName": "Alexander Field",
        "City": "Wisconsin Rapids",
        "Country": "USA Wisconsin"
    },
    {
        "id": "3229",
        "AirportCode": "ITA",
        "AirportName": "Itacoatiara Airport",
        "City": "Itacoatiara",
        "Country": "Brazil"
    },
    {
        "id": "3230",
        "AirportCode": "ITB",
        "AirportName": "Itaituba Airport",
        "City": "Itaituba",
        "Country": "Brazil"
    },
    {
        "id": "3231",
        "AirportCode": "ITE",
        "AirportName": "Itubera Airport",
        "City": "Itubera",
        "Country": "Brazil"
    },
    {
        "id": "3232",
        "AirportCode": "ITH",
        "AirportName": "Tompkins County Airport",
        "City": "Ithaca",
        "Country": "USA New York"
    },
    {
        "id": "3233",
        "AirportCode": "ITI",
        "AirportName": "Itambacuri Airport",
        "City": "Itambacuri",
        "Country": "Brazil"
    },
    {
        "id": "3234",
        "AirportCode": "ITK",
        "AirportName": "Itokama Airport",
        "City": "Itokama",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3235",
        "AirportCode": "ITM",
        "AirportName": "Itami Airport",
        "City": "Osaka",
        "Country": "Japan"
    },
    {
        "id": "3236",
        "AirportCode": "ITN",
        "AirportName": "Itabuna Airport",
        "City": "Itabuna",
        "Country": "Brazil"
    },
    {
        "id": "3237",
        "AirportCode": "ITO",
        "AirportName": "Hilo International Airport",
        "City": "Hilo",
        "Country": "USA Hawaii"
    },
    {
        "id": "3238",
        "AirportCode": "ITP",
        "AirportName": "Itaperuna Airport",
        "City": "Itaperuna",
        "Country": "Brazil"
    },
    {
        "id": "3239",
        "AirportCode": "ITQ",
        "AirportName": "Itaqui Airport",
        "City": "Itaqui",
        "Country": "Brazil"
    },
    {
        "id": "3240",
        "AirportCode": "ITR",
        "AirportName": "Itumbiara Airport",
        "City": "Itumbiara",
        "Country": "Brazil"
    },
    {
        "id": "3241",
        "AirportCode": "IUE",
        "AirportName": "Hanan Airport",
        "City": "Niue Island",
        "Country": "Niue"
    },
    {
        "id": "3242",
        "AirportCode": "IUL",
        "AirportName": "Ilu Airport",
        "City": "Ilu",
        "Country": "Indonesia"
    },
    {
        "id": "3243",
        "AirportCode": "IUM",
        "AirportName": "Summit Lake Airport",
        "City": "Summit Lake",
        "Country": "Canada"
    },
    {
        "id": "3244",
        "AirportCode": "IUS",
        "AirportName": "Inus Airport",
        "City": "Inus",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3245",
        "AirportCode": "IVA",
        "AirportName": "Ambanja Airport",
        "City": "Ambanja",
        "Country": "Madagascar"
    },
    {
        "id": "3246",
        "AirportCode": "IVC",
        "AirportName": "Invercargill Airport",
        "City": "Invercargill",
        "Country": "New Zealand"
    },
    {
        "id": "3247",
        "AirportCode": "IVG",
        "AirportName": "Ivangrad Airport",
        "City": "Ivangrad",
        "Country": "Serbia"
    },
    {
        "id": "3248",
        "AirportCode": "IVL",
        "AirportName": "Ivalo Airport",
        "City": "Ivalo",
        "Country": "Finland"
    },
    {
        "id": "3249",
        "AirportCode": "IVO",
        "AirportName": "Chivolo Airport",
        "City": "Chivolo",
        "Country": "Colombia"
    },
    {
        "id": "3250",
        "AirportCode": "IVR",
        "AirportName": "Inverell Airport",
        "City": "Inverell",
        "Country": "Australia"
    },
    {
        "id": "3251",
        "AirportCode": "IVW",
        "AirportName": "Inverway Airport",
        "City": "Inverway",
        "Country": "Australia"
    },
    {
        "id": "3252",
        "AirportCode": "IWA",
        "AirportName": "Ivanovo Airport",
        "City": "Ivanovo",
        "Country": "Russia"
    },
    {
        "id": "3253",
        "AirportCode": "IWD",
        "AirportName": "Gogebic County Airport",
        "City": "Ironwood",
        "Country": "USA Michigan"
    },
    {
        "id": "3254",
        "AirportCode": "IWJ",
        "AirportName": "Iwami Airport",
        "City": "Iwami",
        "Country": "Japan"
    },
    {
        "id": "3255",
        "AirportCode": "IWO",
        "AirportName": "Iwo Jima Air Base",
        "City": "Iwo Jima Vol",
        "Country": "Japan"
    },
    {
        "id": "3256",
        "AirportCode": "IWS",
        "AirportName": "West Houston Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3257",
        "AirportCode": "IXA",
        "AirportName": "Singerbhil Airport",
        "City": "Agartala",
        "Country": "India"
    },
    {
        "id": "3258",
        "AirportCode": "IXB",
        "AirportName": "Bagdogra Airport",
        "City": "Bagdogra",
        "Country": "India"
    },
    {
        "id": "3259",
        "AirportCode": "IXC",
        "AirportName": "Chandigarh Airport",
        "City": "Chandigarh",
        "Country": "India"
    },
    {
        "id": "3260",
        "AirportCode": "IXD",
        "AirportName": "Bamrauli Airport",
        "City": "Allahabad",
        "Country": "India"
    },
    {
        "id": "3261",
        "AirportCode": "IXE",
        "AirportName": "Bajpe Airport",
        "City": "Mangalore",
        "Country": "India"
    },
    {
        "id": "3262",
        "AirportCode": "IXG",
        "AirportName": "Sambre Airport",
        "City": "Belgaum",
        "Country": "India"
    },
    {
        "id": "3263",
        "AirportCode": "IXH",
        "AirportName": "Kailashahar Airport",
        "City": "Kailashahar",
        "Country": "India"
    },
    {
        "id": "3264",
        "AirportCode": "IXI",
        "AirportName": "Lilabari Airport",
        "City": "Lilabari",
        "Country": "India"
    },
    {
        "id": "3265",
        "AirportCode": "IXJ",
        "AirportName": "Satwari Airport",
        "City": "Jammu",
        "Country": "India"
    },
    {
        "id": "3266",
        "AirportCode": "IXK",
        "AirportName": "Keshod Airport",
        "City": "Keshod",
        "Country": "India"
    },
    {
        "id": "3267",
        "AirportCode": "IXL",
        "AirportName": "Leh Airport",
        "City": "Leh",
        "Country": "India"
    },
    {
        "id": "3268",
        "AirportCode": "IXM",
        "AirportName": "Madurai Airport",
        "City": "Madurai",
        "Country": "India"
    },
    {
        "id": "3269",
        "AirportCode": "IXN",
        "AirportName": "Khowai Airport",
        "City": "Khowai",
        "Country": "India"
    },
    {
        "id": "3270",
        "AirportCode": "IXP",
        "AirportName": "Pathankot Airport",
        "City": "Pathankot",
        "Country": "India"
    },
    {
        "id": "3271",
        "AirportCode": "IXQ",
        "AirportName": "Kamalpur Airport",
        "City": "Kamalpur",
        "Country": "India"
    },
    {
        "id": "3272",
        "AirportCode": "IXR",
        "AirportName": "Ranchi Airport",
        "City": "Ranchi",
        "Country": "India"
    },
    {
        "id": "3273",
        "AirportCode": "IXS",
        "AirportName": "Kumbhirgram Airport",
        "City": "Silchar",
        "Country": "India"
    },
    {
        "id": "3274",
        "AirportCode": "IXT",
        "AirportName": "Pasighat Airport",
        "City": "Pasighat",
        "Country": "India"
    },
    {
        "id": "3275",
        "AirportCode": "IXU",
        "AirportName": "Chikkalthana Airport",
        "City": "Aurangabad",
        "Country": "India"
    },
    {
        "id": "3276",
        "AirportCode": "IXV",
        "AirportName": "Along Airport",
        "City": "Along",
        "Country": "India"
    },
    {
        "id": "3277",
        "AirportCode": "IXW",
        "AirportName": "Sonari Airport",
        "City": "Jamshedpur",
        "Country": "India"
    },
    {
        "id": "3278",
        "AirportCode": "IXY",
        "AirportName": "Kandla Airport",
        "City": "Kandla",
        "Country": "India"
    },
    {
        "id": "3279",
        "AirportCode": "IXZ",
        "AirportName": "Port Blair Airport",
        "City": "Port Blair",
        "Country": "India"
    },
    {
        "id": "3280",
        "AirportCode": "IYK",
        "AirportName": "Kern County Airport",
        "City": "Inyokern",
        "Country": "USA California"
    },
    {
        "id": "3281",
        "AirportCode": "IZO",
        "AirportName": "Izumo Airport",
        "City": "Izumo",
        "Country": "Japan"
    },
    {
        "id": "3282",
        "AirportCode": "IZT",
        "AirportName": "Ixtepec Airport",
        "City": "Ixtepec",
        "Country": "Mexico"
    },
    {
        "id": "3283",
        "AirportCode": "JAA",
        "AirportName": "Jalalabad Airport",
        "City": "Jalalabad",
        "Country": "Afghanistan"
    },
    {
        "id": "3284",
        "AirportCode": "JAB",
        "AirportName": "Jabiru Airport",
        "City": "Jabiru",
        "Country": "Australia"
    },
    {
        "id": "3285",
        "AirportCode": "JAC",
        "AirportName": "Jackson Hole Airport",
        "City": "Jackson",
        "Country": "USA Wyoming"
    },
    {
        "id": "3286",
        "AirportCode": "JAD",
        "AirportName": "Jandakot Airport",
        "City": "Jandakot",
        "Country": "Australia"
    },
    {
        "id": "3287",
        "AirportCode": "JAE",
        "AirportName": "Technology Park Heliport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "3288",
        "AirportCode": "JAF",
        "AirportName": "Kankesanturai Airport",
        "City": "Jaffna",
        "Country": "Sri Lanka"
    },
    {
        "id": "3289",
        "AirportCode": "JAG",
        "AirportName": "Jacobabad Airport",
        "City": "Jacobabad",
        "Country": "Pakistan"
    },
    {
        "id": "3290",
        "AirportCode": "JAH",
        "AirportName": "Agora Helipad",
        "City": "Aubagne",
        "Country": "France"
    },
    {
        "id": "3291",
        "AirportCode": "JAI",
        "AirportName": "Sanganeer Airport",
        "City": "Jaipur",
        "Country": "India"
    },
    {
        "id": "3292",
        "AirportCode": "JAJ",
        "AirportName": "Perimeter Mall Heliport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "3293",
        "AirportCode": "JAK",
        "AirportName": "Jacmel Airport",
        "City": "Jacmel",
        "Country": "Haiti"
    },
    {
        "id": "3294",
        "AirportCode": "JAL",
        "AirportName": "Jalapa Airport",
        "City": "Jalapa",
        "Country": "Mexico"
    },
    {
        "id": "3295",
        "AirportCode": "JAM",
        "AirportName": "Jambol Airport",
        "City": "Jambol",
        "Country": "Bulgaria"
    },
    {
        "id": "3296",
        "AirportCode": "JAN",
        "AirportName": "Jackson International Airport",
        "City": "Jackson",
        "Country": "USA Mississippi"
    },
    {
        "id": "3297",
        "AirportCode": "JAO",
        "AirportName": "Beaver Ruin Heliport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "3298",
        "AirportCode": "JAP",
        "AirportName": "Punta Renes Airport",
        "City": "Punta Renes",
        "Country": "Costa Rica"
    },
    {
        "id": "3299",
        "AirportCode": "JAQ",
        "AirportName": "Jacquinot Bay Airport",
        "City": "Jacquinot Bay",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3300",
        "AirportCode": "JAR",
        "AirportName": "Jahrom Airport",
        "City": "Jahrom",
        "Country": "Iran"
    },
    {
        "id": "3301",
        "AirportCode": "JAS",
        "AirportName": "County Airport",
        "City": "Jasper",
        "Country": "USA Texas"
    },
    {
        "id": "3302",
        "AirportCode": "JAU",
        "AirportName": "Jauja Airport",
        "City": "Jauja",
        "Country": "Peru"
    },
    {
        "id": "3303",
        "AirportCode": "JAV",
        "AirportName": "Ilulissat Airport",
        "City": "Ilulissat",
        "Country": "Greenland"
    },
    {
        "id": "3304",
        "AirportCode": "JAX",
        "AirportName": "Jacksonville International Airport",
        "City": "Jacksonville",
        "Country": "USA Florida"
    },
    {
        "id": "3305",
        "AirportCode": "JBC",
        "AirportName": "Boston City Heliport",
        "City": "Boston",
        "Country": "USA Massachusetts"
    },
    {
        "id": "3306",
        "AirportCode": "JBK",
        "AirportName": "Berkeley Airport",
        "City": "Berkeley",
        "Country": "USA California"
    },
    {
        "id": "3307",
        "AirportCode": "JBP",
        "AirportName": "Commerce Bus. Plaza Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3308",
        "AirportCode": "JBR",
        "AirportName": "Jonesboro Airport",
        "City": "Jonesboro",
        "Country": "USA Arkansas"
    },
    {
        "id": "3309",
        "AirportCode": "JBS",
        "AirportName": "Hacienda Business Park Heliport",
        "City": "Pleasanton",
        "Country": "USA California"
    },
    {
        "id": "3310",
        "AirportCode": "JCA",
        "AirportName": "Croisette Heliport",
        "City": "Cannes",
        "Country": "France"
    },
    {
        "id": "3311",
        "AirportCode": "JCB",
        "AirportName": "Joacaba Airport",
        "City": "Joacaba",
        "Country": "Brazil"
    },
    {
        "id": "3312",
        "AirportCode": "JCC",
        "AirportName": "China Basin Heliport",
        "City": "San Francisco",
        "Country": "USA California"
    },
    {
        "id": "3313",
        "AirportCode": "JCD",
        "AirportName": "St. Croix Downtown Heliport",
        "City": "Saint Croix",
        "Country": "US Virgin Islands"
    },
    {
        "id": "3314",
        "AirportCode": "JCE",
        "AirportName": "Convention Center Heliport",
        "City": "Oakland",
        "Country": "USA California"
    },
    {
        "id": "3315",
        "AirportCode": "JCH",
        "AirportName": "Qasigiannguit Airport",
        "City": "Qasigiannguit",
        "Country": "Greenland"
    },
    {
        "id": "3316",
        "AirportCode": "JCI",
        "AirportName": "Johnson Industrial Airport",
        "City": "Kansas City",
        "Country": "USA Missouri"
    },
    {
        "id": "3317",
        "AirportCode": "JCJ",
        "AirportName": "Chuja Heliport",
        "City": "Jeju",
        "Country": "Korea"
    },
    {
        "id": "3318",
        "AirportCode": "JCK",
        "AirportName": "Julia Creek Airport",
        "City": "Julia Creek",
        "Country": "Australia"
    },
    {
        "id": "3319",
        "AirportCode": "JCM",
        "AirportName": "Jacobina Airport",
        "City": "Jacobina",
        "Country": "Brazil"
    },
    {
        "id": "3320",
        "AirportCode": "JCN",
        "AirportName": "Incheon Heliport",
        "City": "Incheon",
        "Country": "Korea"
    },
    {
        "id": "3321",
        "AirportCode": "JCO",
        "AirportName": "Comino Heliport",
        "City": "Comino",
        "Country": "Malta"
    },
    {
        "id": "3322",
        "AirportCode": "JCR",
        "AirportName": "Jacareacanga Airport",
        "City": "Jacareacanga",
        "Country": "Brazil"
    },
    {
        "id": "3323",
        "AirportCode": "JCT",
        "AirportName": "Kimble County Airport",
        "City": "Junction",
        "Country": "USA Texas"
    },
    {
        "id": "3324",
        "AirportCode": "JCU",
        "AirportName": "Ceuta Heliport",
        "City": "Ceuta",
        "Country": "Spain"
    },
    {
        "id": "3325",
        "AirportCode": "JCX",
        "AirportName": "Citicorp Plaza Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3326",
        "AirportCode": "JCY",
        "AirportName": "Johnson Airport",
        "City": "Johnson",
        "Country": "USA Texas"
    },
    {
        "id": "3327",
        "AirportCode": "JDA",
        "AirportName": "John Day Airport",
        "City": "John Day",
        "Country": "USA Oregon"
    },
    {
        "id": "3328",
        "AirportCode": "JDB",
        "AirportName": "Dallas Downtown Heliport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "3329",
        "AirportCode": "JDF",
        "AirportName": "Francisco De Assis Airport",
        "City": "Juiz De Fora",
        "Country": "Brazil"
    },
    {
        "id": "3330",
        "AirportCode": "JDH",
        "AirportName": "Jodhpur Airport",
        "City": "Jodhpur",
        "Country": "India"
    },
    {
        "id": "3331",
        "AirportCode": "JDM",
        "AirportName": "Miami Downtown Heliport",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "3332",
        "AirportCode": "JDN",
        "AirportName": "Jordan Airport",
        "City": "Jordan",
        "Country": "USA Montana"
    },
    {
        "id": "3333",
        "AirportCode": "JDO",
        "AirportName": "Regional Do Cariri Airport",
        "City": "Juazeiro Do Norte",
        "Country": "Brazil"
    },
    {
        "id": "3334",
        "AirportCode": "JDP",
        "AirportName": "Heliport De Paris",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "3335",
        "AirportCode": "JDT",
        "AirportName": "Minneapolis Downtown Heliport",
        "City": "Minneapolis",
        "Country": "USA Minnesota"
    },
    {
        "id": "3336",
        "AirportCode": "JDX",
        "AirportName": "Central Business District Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3337",
        "AirportCode": "JDY",
        "AirportName": "Downey Heliport",
        "City": "Downey",
        "Country": "USA California"
    },
    {
        "id": "3338",
        "AirportCode": "JDZ",
        "AirportName": "Jingdezhen Airport",
        "City": "Jingdezhen",
        "Country": "China"
    },
    {
        "id": "3339",
        "AirportCode": "JED",
        "AirportName": "King Abdulaziz International Airport",
        "City": "Jeddah",
        "Country": "Saudi Arabia"
    },
    {
        "id": "3340",
        "AirportCode": "JEE",
        "AirportName": "Jeremie Airport",
        "City": "Jeremie",
        "Country": "Haiti"
    },
    {
        "id": "3341",
        "AirportCode": "JEF",
        "AirportName": "Jefferson City Memorial Airport",
        "City": "Jefferson City",
        "Country": "USA Missouri"
    },
    {
        "id": "3342",
        "AirportCode": "JEG",
        "AirportName": "Aasiaat Airport",
        "City": "Aasiaat",
        "Country": "Greenland"
    },
    {
        "id": "3343",
        "AirportCode": "JEM",
        "AirportName": "Emeryville Heliport",
        "City": "Emeryville",
        "Country": "USA California"
    },
    {
        "id": "3344",
        "AirportCode": "JEQ",
        "AirportName": "Jequie Airport",
        "City": "Jequie",
        "Country": "Brazil"
    },
    {
        "id": "3345",
        "AirportCode": "JER",
        "AirportName": "States Airport",
        "City": "Jersey",
        "Country": "United Kingdom"
    },
    {
        "id": "3346",
        "AirportCode": "JEV",
        "AirportName": "Evry Heliport",
        "City": "Evry",
        "Country": "France"
    },
    {
        "id": "3347",
        "AirportCode": "JFK",
        "AirportName": "John F. Kennedy International Airport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "3348",
        "AirportCode": "JFM",
        "AirportName": "Fremantle Heliport",
        "City": "Fremantle",
        "Country": "Australia"
    },
    {
        "id": "3349",
        "AirportCode": "JFN",
        "AirportName": "Ashtabula Airport",
        "City": "Jefferson",
        "Country": "USA Ohio"
    },
    {
        "id": "3350",
        "AirportCode": "JFR",
        "AirportName": "Paamiut Airport",
        "City": "Paamiut",
        "Country": "Greenland"
    },
    {
        "id": "3351",
        "AirportCode": "JGA",
        "AirportName": "Govardhanpur Airport",
        "City": "Jamnagar",
        "Country": "India"
    },
    {
        "id": "3352",
        "AirportCode": "JGB",
        "AirportName": "Jagdalpur Airport",
        "City": "Jagdalpur",
        "Country": "India"
    },
    {
        "id": "3353",
        "AirportCode": "JGC",
        "AirportName": "Grand Canyon Heliport",
        "City": "Grand Canyon",
        "Country": "USA Arizona"
    },
    {
        "id": "3354",
        "AirportCode": "JGE",
        "AirportName": "Geoje Heliport",
        "City": "Geoje",
        "Country": "Korea"
    },
    {
        "id": "3355",
        "AirportCode": "JGL",
        "AirportName": "Galleria Heliport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "3356",
        "AirportCode": "JGN",
        "AirportName": "Jiayuguan Airport",
        "City": "Jiayuguan",
        "Country": "China"
    },
    {
        "id": "3357",
        "AirportCode": "JGO",
        "AirportName": "Qeqertarsuaq Airport",
        "City": "Qeqertarsuaq",
        "Country": "Greenland"
    },
    {
        "id": "3358",
        "AirportCode": "JGP",
        "AirportName": "Greenway Plaza Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3359",
        "AirportCode": "JGQ",
        "AirportName": "Transco Tower Galleria Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3360",
        "AirportCode": "JGR",
        "AirportName": "Gronnedal Heliport",
        "City": "Gronnedal",
        "Country": "Greenland"
    },
    {
        "id": "3361",
        "AirportCode": "JGS",
        "AirportName": "Ji An/Jing Gang Shan Airport",
        "City": "Ji an",
        "Country": "China"
    },
    {
        "id": "3362",
        "AirportCode": "JGX",
        "AirportName": "Glendale Heliport",
        "City": "Glendale",
        "Country": "USA California"
    },
    {
        "id": "3363",
        "AirportCode": "JHB",
        "AirportName": "Sultan Ismail International Airport",
        "City": "Johor Bharu",
        "Country": "Malaysia"
    },
    {
        "id": "3364",
        "AirportCode": "JHC",
        "AirportName": "Island Heliport",
        "City": "Garden City",
        "Country": "USA New York"
    },
    {
        "id": "3365",
        "AirportCode": "JHE",
        "AirportName": "Helsingborg Heliport",
        "City": "Angelholm/Helsingborg",
        "Country": "Sweden"
    },
    {
        "id": "3366",
        "AirportCode": "JHG",
        "AirportName": "Gasa Airport",
        "City": "Jinghong",
        "Country": "China"
    },
    {
        "id": "3367",
        "AirportCode": "JHM",
        "AirportName": "Kapalua Airport",
        "City": "Kapalua",
        "Country": "USA Hawaii"
    },
    {
        "id": "3368",
        "AirportCode": "JHQ",
        "AirportName": "Shute Harbour Heliport",
        "City": "Shute Harbour",
        "Country": "Australia"
    },
    {
        "id": "3369",
        "AirportCode": "JHS",
        "AirportName": "Sisimiut Airport",
        "City": "Sisimiut",
        "Country": "Greenland"
    },
    {
        "id": "3370",
        "AirportCode": "JHW",
        "AirportName": "Jamestown Airport",
        "City": "Jamestown",
        "Country": "USA New York"
    },
    {
        "id": "3371",
        "AirportCode": "JHY",
        "AirportName": "Hyatt Regency Heliport",
        "City": "Cambridge",
        "Country": "USA Massachusetts"
    },
    {
        "id": "3372",
        "AirportCode": "JIA",
        "AirportName": "Juina Airport",
        "City": "Juina",
        "Country": "Brazil"
    },
    {
        "id": "3373",
        "AirportCode": "JIB",
        "AirportName": "Ambouli Airport",
        "City": "Djibouti",
        "Country": "Djibouti"
    },
    {
        "id": "3374",
        "AirportCode": "JID",
        "AirportName": "City of Industry Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3375",
        "AirportCode": "JIJ",
        "AirportName": "Jigiga Airport",
        "City": "Jijiga",
        "Country": "Ethiopia"
    },
    {
        "id": "3376",
        "AirportCode": "JIK",
        "AirportName": "Ikaria Airport",
        "City": "Ikaria Island",
        "Country": "Greece"
    },
    {
        "id": "3377",
        "AirportCode": "JIL",
        "AirportName": "Jilin Airport",
        "City": "Jilin",
        "Country": "China"
    },
    {
        "id": "3378",
        "AirportCode": "JIM",
        "AirportName": "Jimma Airport",
        "City": "Jimma",
        "Country": "Ethiopia"
    },
    {
        "id": "3379",
        "AirportCode": "JIN",
        "AirportName": "Jinja Airport",
        "City": "Jinja",
        "Country": "Uganda"
    },
    {
        "id": "3380",
        "AirportCode": "JIO",
        "AirportName": "Ontario International Heliport",
        "City": "Ontario",
        "Country": "USA California"
    },
    {
        "id": "3381",
        "AirportCode": "JIP",
        "AirportName": "Jipijapa Airport",
        "City": "Jipijapa",
        "Country": "Ecuador"
    },
    {
        "id": "3382",
        "AirportCode": "JIR",
        "AirportName": "Jiri Airport",
        "City": "Jiri",
        "Country": "Nepal"
    },
    {
        "id": "3383",
        "AirportCode": "JIU",
        "AirportName": "Jiujiang Airport",
        "City": "Jiujiang",
        "Country": "China"
    },
    {
        "id": "3384",
        "AirportCode": "JIW",
        "AirportName": "Jiwani Airport",
        "City": "Jiwani",
        "Country": "Pakistan"
    },
    {
        "id": "3385",
        "AirportCode": "JJI",
        "AirportName": "Juanjui Airport",
        "City": "Juanjui",
        "Country": "Peru"
    },
    {
        "id": "3386",
        "AirportCode": "JJN",
        "AirportName": "Jinjiang Airport",
        "City": "Jinjiang",
        "Country": "China"
    },
    {
        "id": "3387",
        "AirportCode": "JJU",
        "AirportName": "Qaqortoq Heliport",
        "City": "Qaqortoq",
        "Country": "Greenland"
    },
    {
        "id": "3388",
        "AirportCode": "JKG",
        "AirportName": "Axamo Airport",
        "City": "Jonkoping",
        "Country": "Sweden"
    },
    {
        "id": "3389",
        "AirportCode": "JKH",
        "AirportName": "Chios Airport",
        "City": "Chios",
        "Country": "Greece"
    },
    {
        "id": "3390",
        "AirportCode": "JKR",
        "AirportName": "Janakpur Airport",
        "City": "Janakpur",
        "Country": "Nepal"
    },
    {
        "id": "3391",
        "AirportCode": "JKV",
        "AirportName": "Jacksonville Airport",
        "City": "Jacksonville",
        "Country": "USA Texas"
    },
    {
        "id": "3392",
        "AirportCode": "JLB",
        "AirportName": "Long Beach Heliport",
        "City": "Long Beach",
        "Country": "USA California"
    },
    {
        "id": "3393",
        "AirportCode": "JLD",
        "AirportName": "Landskrona Heliport",
        "City": "Landskrona",
        "Country": "Sweden"
    },
    {
        "id": "3394",
        "AirportCode": "JLH",
        "AirportName": "United States Army Heliport",
        "City": "Arlington Heights",
        "Country": "USA Illinois"
    },
    {
        "id": "3395",
        "AirportCode": "JLN",
        "AirportName": "Joplin Airport",
        "City": "Joplin",
        "Country": "USA Missouri"
    },
    {
        "id": "3396",
        "AirportCode": "JLO",
        "AirportName": "Jesolo Airport",
        "City": "Jesolo",
        "Country": "Italy"
    },
    {
        "id": "3397",
        "AirportCode": "JLP",
        "AirportName": "Juan Les Pins Airport",
        "City": "Juan Les Pins",
        "Country": "France"
    },
    {
        "id": "3398",
        "AirportCode": "JLR",
        "AirportName": "Jabalpur Airport",
        "City": "Jabalpur",
        "Country": "India"
    },
    {
        "id": "3399",
        "AirportCode": "JLS",
        "AirportName": "Jales Airport",
        "City": "Jales",
        "Country": "Brazil"
    },
    {
        "id": "3400",
        "AirportCode": "JLX",
        "AirportName": "Union Station Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3401",
        "AirportCode": "JMA",
        "AirportName": "Marriot Astrodome Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3402",
        "AirportCode": "JMC",
        "AirportName": "Marin County Airport",
        "City": "Sausalito",
        "Country": "USA California"
    },
    {
        "id": "3403",
        "AirportCode": "JMD",
        "AirportName": "Market Centre Heliport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "3404",
        "AirportCode": "JMH",
        "AirportName": "Schaumburg Marriott Heliport",
        "City": "Schaumburg",
        "Country": "USA Illinois"
    },
    {
        "id": "3405",
        "AirportCode": "JMK",
        "AirportName": "Mikonos Airport",
        "City": "Mikonos",
        "Country": "Greece"
    },
    {
        "id": "3406",
        "AirportCode": "JMM",
        "AirportName": "Malmo Harbour Heliport",
        "City": "Malmo",
        "Country": "Sweden"
    },
    {
        "id": "3407",
        "AirportCode": "JMN",
        "AirportName": "Mankato Municipal Heliport",
        "City": "Mankato",
        "Country": "USA Minnesota"
    },
    {
        "id": "3408",
        "AirportCode": "JMO",
        "AirportName": "Jomsom Airport",
        "City": "Jomsom",
        "Country": "Nepal"
    },
    {
        "id": "3409",
        "AirportCode": "JMS",
        "AirportName": "Jamestown Airport",
        "City": "Jamestown",
        "Country": "USA North Dakota"
    },
    {
        "id": "3410",
        "AirportCode": "JMU",
        "AirportName": "Jiamusi Airport",
        "City": "Jiamusi",
        "Country": "China"
    },
    {
        "id": "3411",
        "AirportCode": "JMY",
        "AirportName": "Mammy Yoko Heliport",
        "City": "Freetown",
        "Country": "Sierra Leone"
    },
    {
        "id": "3412",
        "AirportCode": "JNA",
        "AirportName": "Januaria Airport",
        "City": "Januaria",
        "Country": "Brazil"
    },
    {
        "id": "3413",
        "AirportCode": "JNB",
        "AirportName": "Johannesburg International Airport",
        "City": "Johannesburg",
        "Country": "South Africa"
    },
    {
        "id": "3414",
        "AirportCode": "JNG",
        "AirportName": "Jining Airport",
        "City": "Jining",
        "Country": "China"
    },
    {
        "id": "3415",
        "AirportCode": "JNH",
        "AirportName": "North Park Inn Heliport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "3416",
        "AirportCode": "JNI",
        "AirportName": "Junin Airport",
        "City": "Junin",
        "Country": "Argentina"
    },
    {
        "id": "3417",
        "AirportCode": "JNN",
        "AirportName": "Nanortalik Airport",
        "City": "Nanortalik",
        "Country": "Greenland"
    },
    {
        "id": "3418",
        "AirportCode": "JNP",
        "AirportName": "Newport Beach Heliport",
        "City": "Newport Beach",
        "Country": "USA California"
    },
    {
        "id": "3419",
        "AirportCode": "JNS",
        "AirportName": "Narsaq Heliport",
        "City": "Narsaq",
        "Country": "Greenland"
    },
    {
        "id": "3420",
        "AirportCode": "JNU",
        "AirportName": "Juneau International Airport",
        "City": "Juneau",
        "Country": "USA Alaska"
    },
    {
        "id": "3421",
        "AirportCode": "JNX",
        "AirportName": "Naxos Airport",
        "City": "Naxos, Cyclades Islands",
        "Country": "Greece"
    },
    {
        "id": "3422",
        "AirportCode": "JNZ",
        "AirportName": "Liaoning Province Airport",
        "City": "Jinzhou",
        "Country": "China"
    },
    {
        "id": "3423",
        "AirportCode": "JOC",
        "AirportName": "Centerport Heliport",
        "City": "Santa Ana",
        "Country": "USA California"
    },
    {
        "id": "3424",
        "AirportCode": "JOE",
        "AirportName": "Joensuu Airport",
        "City": "Joensuu",
        "Country": "Finland"
    },
    {
        "id": "3425",
        "AirportCode": "JOG",
        "AirportName": "Adisutjipto Airport",
        "City": "Yogyakarta",
        "Country": "Indonesia"
    },
    {
        "id": "3426",
        "AirportCode": "JOH",
        "AirportName": "Port St. Johns Airport",
        "City": "Port St. Johns",
        "Country": "South Africa"
    },
    {
        "id": "3427",
        "AirportCode": "JOI",
        "AirportName": "Cubatao Airport",
        "City": "Joinville",
        "Country": "Brazil"
    },
    {
        "id": "3428",
        "AirportCode": "JOK",
        "AirportName": "Joshkar-Ola Airport",
        "City": "Joshkar-Ola",
        "Country": "Russia"
    },
    {
        "id": "3429",
        "AirportCode": "JOL",
        "AirportName": "Jolo Airport",
        "City": "Jolo",
        "Country": "Philippines"
    },
    {
        "id": "3430",
        "AirportCode": "JOM",
        "AirportName": "Njombe Airport",
        "City": "Njombe",
        "Country": "Tanzania"
    },
    {
        "id": "3431",
        "AirportCode": "JON",
        "AirportName": "Josephstaal Airport",
        "City": "Johnston Island",
        "Country": "Johnston Island"
    },
    {
        "id": "3432",
        "AirportCode": "JOP",
        "AirportName": "Josephstaal Airport",
        "City": "Josephstaal",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3433",
        "AirportCode": "JOR",
        "AirportName": "The City Heliport",
        "City": "Orange",
        "Country": "USA California"
    },
    {
        "id": "3434",
        "AirportCode": "JOS",
        "AirportName": "Jos Airport",
        "City": "Jos",
        "Country": "Nigeria"
    },
    {
        "id": "3435",
        "AirportCode": "JOT",
        "AirportName": "Joliet Municipal Airport",
        "City": "Joliet",
        "Country": "USA Illinois"
    },
    {
        "id": "3436",
        "AirportCode": "JPA",
        "AirportName": "Castro Pinto Airport",
        "City": "Joao Pessoa",
        "Country": "Brazil"
    },
    {
        "id": "3437",
        "AirportCode": "JPD",
        "AirportName": "Pasadena Heliport",
        "City": "Pasadena",
        "Country": "USA California"
    },
    {
        "id": "3438",
        "AirportCode": "JPN",
        "AirportName": "Pentagon Army Airport",
        "City": "Washington",
        "Country": "USA DC"
    },
    {
        "id": "3439",
        "AirportCode": "JPR",
        "AirportName": "Ji-Parana Airport",
        "City": "Ji-Parana",
        "Country": "Brazil"
    },
    {
        "id": "3440",
        "AirportCode": "JPT",
        "AirportName": "Texas Park Ten Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3441",
        "AirportCode": "JPU",
        "AirportName": "La Defense Heliport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "3442",
        "AirportCode": "JQA",
        "AirportName": "Qaarsut Airport",
        "City": "Qaarsut",
        "Country": "Greenland"
    },
    {
        "id": "3443",
        "AirportCode": "JQE",
        "AirportName": "Jaque Airport",
        "City": "Jaque",
        "Country": "Panama"
    },
    {
        "id": "3444",
        "AirportCode": "JRA",
        "AirportName": "West 30th Street Heliport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "3445",
        "AirportCode": "JRB",
        "AirportName": "New York Downtown Manhattan Heliport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "3446",
        "AirportCode": "JRC",
        "AirportName": "Rochester Municipal Heliport",
        "City": "Rochester",
        "Country": "USA Minnesota"
    },
    {
        "id": "3447",
        "AirportCode": "JRD",
        "AirportName": "Riverside Heliport",
        "City": "Riverside",
        "Country": "USA California"
    },
    {
        "id": "3448",
        "AirportCode": "JRE",
        "AirportName": "East 60th Street Heliport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "3449",
        "AirportCode": "JRH",
        "AirportName": "Rowriah Airport",
        "City": "Jorhat",
        "Country": "India"
    },
    {
        "id": "3450",
        "AirportCode": "JRK",
        "AirportName": "Arsuk Airport",
        "City": "Arsuk",
        "Country": "Greenland"
    },
    {
        "id": "3451",
        "AirportCode": "JRN",
        "AirportName": "Juruena Airport",
        "City": "Juruena",
        "Country": "Brazil"
    },
    {
        "id": "3452",
        "AirportCode": "JRO",
        "AirportName": "Kilimanjaro Airport",
        "City": "Kilimanjaro",
        "Country": "Tanzania"
    },
    {
        "id": "3453",
        "AirportCode": "JRS",
        "AirportName": "Jerusalem Airport",
        "City": "Â ",
        "Country": "Â "
    },
    {
        "id": "3454",
        "AirportCode": "JSA",
        "AirportName": "Jaisalmer Airport",
        "City": "Jaisalmer",
        "Country": "India"
    },
    {
        "id": "3455",
        "AirportCode": "JSD",
        "AirportName": "Sikorsky Heliport",
        "City": "Stratford",
        "Country": "USA Connecticut"
    },
    {
        "id": "3456",
        "AirportCode": "JSG",
        "AirportName": "San Rafael Heliport",
        "City": "San Rafael",
        "Country": "USA California"
    },
    {
        "id": "3457",
        "AirportCode": "JSH",
        "AirportName": "Sitia Airport",
        "City": "Sitia",
        "Country": "Greece"
    },
    {
        "id": "3458",
        "AirportCode": "JSI",
        "AirportName": "Skiathos Airport",
        "City": "Skiathos",
        "Country": "Greece"
    },
    {
        "id": "3459",
        "AirportCode": "JSK",
        "AirportName": "St. Cloud Municipal Heliport",
        "City": "Saint Cloud",
        "Country": "USA Minnesota"
    },
    {
        "id": "3460",
        "AirportCode": "JSL",
        "AirportName": "Steel Pier Heliport",
        "City": "Atlantic City",
        "Country": "USA New Jersey"
    },
    {
        "id": "3461",
        "AirportCode": "JSM",
        "AirportName": "Jose San de Martin Airport",
        "City": "Jose San de Martin",
        "Country": "Argentina"
    },
    {
        "id": "3462",
        "AirportCode": "JSN",
        "AirportName": "Sherman Oaks Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3463",
        "AirportCode": "JSO",
        "AirportName": "Sodertalje Heliport",
        "City": "Sodertalje",
        "Country": "Sweden"
    },
    {
        "id": "3464",
        "AirportCode": "JSP",
        "AirportName": "Seogwipo Heliport",
        "City": "Jeju",
        "Country": "Korea"
    },
    {
        "id": "3465",
        "AirportCode": "JSR",
        "AirportName": "Jessore Airport",
        "City": "Jessore",
        "Country": "Bangladesh"
    },
    {
        "id": "3466",
        "AirportCode": "JSS",
        "AirportName": "Spetsai Island Airport",
        "City": "Spetsai Island",
        "Country": "Greece"
    },
    {
        "id": "3467",
        "AirportCode": "JST",
        "AirportName": "Cambria County Airport",
        "City": "Johnstown",
        "Country": "USA Philadelphia"
    },
    {
        "id": "3468",
        "AirportCode": "JSU",
        "AirportName": "Maniitsoq Heliport",
        "City": "Maniitsoq",
        "Country": "Greenland"
    },
    {
        "id": "3469",
        "AirportCode": "JSY",
        "AirportName": "Syros Island Airport",
        "City": "Syros Island",
        "Country": "Greece"
    },
    {
        "id": "3470",
        "AirportCode": "JTI",
        "AirportName": "Jatai Airport",
        "City": "Jatai",
        "Country": "Brazil"
    },
    {
        "id": "3471",
        "AirportCode": "JTO",
        "AirportName": "Thousand Oaks Heliport",
        "City": "Thousand Oaks",
        "Country": "USA California"
    },
    {
        "id": "3472",
        "AirportCode": "JTR",
        "AirportName": "Thira Airport",
        "City": "Thira",
        "Country": "Greece"
    },
    {
        "id": "3473",
        "AirportCode": "JTY",
        "AirportName": "Astypalaia Airport",
        "City": "Astypalaia Island",
        "Country": "Greece"
    },
    {
        "id": "3474",
        "AirportCode": "JUA",
        "AirportName": "Juara Airport",
        "City": "Juara",
        "Country": "Brazil"
    },
    {
        "id": "3475",
        "AirportCode": "JUB",
        "AirportName": "Juba Airport",
        "City": "Juba",
        "Country": "Sudan"
    },
    {
        "id": "3476",
        "AirportCode": "JUC",
        "AirportName": "Universal City Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3477",
        "AirportCode": "JUI",
        "AirportName": "Juist Airport",
        "City": "Juist",
        "Country": "Germany"
    },
    {
        "id": "3478",
        "AirportCode": "JUJ",
        "AirportName": "El Cadillal Airport",
        "City": "Jujuy",
        "Country": "Argentina"
    },
    {
        "id": "3479",
        "AirportCode": "JUL",
        "AirportName": "Juliaca Airport",
        "City": "Juliaca",
        "Country": "Peru"
    },
    {
        "id": "3480",
        "AirportCode": "JUM",
        "AirportName": "Jumla Airport",
        "City": "Jumla",
        "Country": "Nepal"
    },
    {
        "id": "3481",
        "AirportCode": "JUN",
        "AirportName": "Jundah Airport",
        "City": "Jundah",
        "Country": "Australia"
    },
    {
        "id": "3482",
        "AirportCode": "JUO",
        "AirportName": "Jurado Airport",
        "City": "Jurado",
        "Country": "Colombia"
    },
    {
        "id": "3483",
        "AirportCode": "JUP",
        "AirportName": "Cable Heliport",
        "City": "Upland",
        "Country": "USA California"
    },
    {
        "id": "3484",
        "AirportCode": "JUR",
        "AirportName": "Jurien Bay Airport",
        "City": "Jurien Bay",
        "Country": "Australia"
    },
    {
        "id": "3485",
        "AirportCode": "JUT",
        "AirportName": "Juticalpa Airport",
        "City": "Juticalpa",
        "Country": "Honduras"
    },
    {
        "id": "3486",
        "AirportCode": "JUV",
        "AirportName": "Upernavik Heliport",
        "City": "Upernavik",
        "Country": "Greenland"
    },
    {
        "id": "3487",
        "AirportCode": "JUZ",
        "AirportName": "Juzhou Airport",
        "City": "Juzhou, Zhejiang Province",
        "Country": "China"
    },
    {
        "id": "3488",
        "AirportCode": "JVA",
        "AirportName": "Ankavandra Airport",
        "City": "Ankavandra",
        "Country": "Madagascar"
    },
    {
        "id": "3489",
        "AirportCode": "JVI",
        "AirportName": "Kupper Airport",
        "City": "Manville",
        "Country": "USA New Jersey"
    },
    {
        "id": "3490",
        "AirportCode": "JVL",
        "AirportName": "Rock County Airport",
        "City": "Janesville",
        "Country": "USA Wisconsin"
    },
    {
        "id": "3491",
        "AirportCode": "JWA",
        "AirportName": "Jwaneng Airport",
        "City": "Jwaneng",
        "Country": "Botswana"
    },
    {
        "id": "3492",
        "AirportCode": "JWC",
        "AirportName": "Warner Center Business Plaza Heliport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "3493",
        "AirportCode": "JWH",
        "AirportName": "West Chase Hilton Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3494",
        "AirportCode": "JWL",
        "AirportName": "Woodlawns Heliport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "3495",
        "AirportCode": "JWN",
        "AirportName": "Zanjan Airport",
        "City": "Zanjan",
        "Country": "Iran"
    },
    {
        "id": "3496",
        "AirportCode": "JXN",
        "AirportName": "Reynolds Municipal Airport",
        "City": "Jackson",
        "Country": "USA Michigan"
    },
    {
        "id": "3497",
        "AirportCode": "JYR",
        "AirportName": "Jiroft Airport",
        "City": "Jiroft",
        "Country": "Iran"
    },
    {
        "id": "3498",
        "AirportCode": "JYV",
        "AirportName": "Jyvaskyla Airport",
        "City": "Jyvaskyla",
        "Country": "Finland"
    },
    {
        "id": "3499",
        "AirportCode": "JZH",
        "AirportName": "Jiu Zhai Huang Long Airport",
        "City": "Song Pan",
        "Country": "China"
    },
    {
        "id": "3500",
        "AirportCode": "KAA",
        "AirportName": "Kasama Airport",
        "City": "Kasama",
        "Country": "Zambia"
    },
    {
        "id": "3501",
        "AirportCode": "KAB",
        "AirportName": "Kariba Airport",
        "City": "Kariba",
        "Country": "Zimbabwe"
    },
    {
        "id": "3502",
        "AirportCode": "KAC",
        "AirportName": "Kameshli Airport",
        "City": "Kameshli",
        "Country": "Syria"
    },
    {
        "id": "3503",
        "AirportCode": "KAD",
        "AirportName": "Kaduna Airport",
        "City": "Kaduna",
        "Country": "Nigeria"
    },
    {
        "id": "3504",
        "AirportCode": "KAE",
        "AirportName": "Kake Sea Plane Base",
        "City": "Kake",
        "Country": "USA Alaska"
    },
    {
        "id": "3505",
        "AirportCode": "KAF",
        "AirportName": "Karato Airport",
        "City": "Karato",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3506",
        "AirportCode": "KAG",
        "AirportName": "Gangneung Airport",
        "City": "Gangneung",
        "Country": "Korea"
    },
    {
        "id": "3507",
        "AirportCode": "KAH",
        "AirportName": "Melbourne City Heliport",
        "City": "Melbourne",
        "Country": "Australia"
    },
    {
        "id": "3508",
        "AirportCode": "KAI",
        "AirportName": "Kaieteur Airport",
        "City": "Kaieteur",
        "Country": "Guyana"
    },
    {
        "id": "3509",
        "AirportCode": "KAJ",
        "AirportName": "Kajaani Airport",
        "City": "Kajaani",
        "Country": "Finland"
    },
    {
        "id": "3510",
        "AirportCode": "KAK",
        "AirportName": "Kar Airport",
        "City": "Kar",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3511",
        "AirportCode": "KAL",
        "AirportName": "Kaltag Airport",
        "City": "Kaltag",
        "Country": "USA Alaska"
    },
    {
        "id": "3512",
        "AirportCode": "KAM",
        "AirportName": "Kamaran Island Airport",
        "City": "Kamaran Island",
        "Country": "Yemen"
    },
    {
        "id": "3513",
        "AirportCode": "KAN",
        "AirportName": "Aminu Kano International Airport",
        "City": "Kano",
        "Country": "Nigeria"
    },
    {
        "id": "3514",
        "AirportCode": "KAO",
        "AirportName": "Kuusamo Airport",
        "City": "Kuusamo",
        "Country": "Finland"
    },
    {
        "id": "3515",
        "AirportCode": "KAP",
        "AirportName": "Kapanga Airport",
        "City": "Kapanga",
        "Country": "Congo, DR"
    },
    {
        "id": "3516",
        "AirportCode": "KAQ",
        "AirportName": "Kamulai Airport",
        "City": "Kamulai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3517",
        "AirportCode": "KAR",
        "AirportName": "Kamarang Airport",
        "City": "Kamarang",
        "Country": "Guyana"
    },
    {
        "id": "3518",
        "AirportCode": "KAS",
        "AirportName": "Karasburg Airport",
        "City": "Karasburg",
        "Country": "Namibia"
    },
    {
        "id": "3519",
        "AirportCode": "KAT",
        "AirportName": "Kaitaia Airport",
        "City": "Kaitaia",
        "Country": "New Zealand"
    },
    {
        "id": "3520",
        "AirportCode": "KAU",
        "AirportName": "Kauhava Airport",
        "City": "Kauhava",
        "Country": "Finland"
    },
    {
        "id": "3521",
        "AirportCode": "KAV",
        "AirportName": "Kavanayen Airport",
        "City": "Kavanayen",
        "Country": "Venezuela"
    },
    {
        "id": "3522",
        "AirportCode": "KAW",
        "AirportName": "Kawthaung Airport",
        "City": "Kawthaung",
        "Country": "Burma"
    },
    {
        "id": "3523",
        "AirportCode": "KAX",
        "AirportName": "Kalbarri Airport",
        "City": "Kalbarri",
        "Country": "Australia"
    },
    {
        "id": "3524",
        "AirportCode": "KAY",
        "AirportName": "Wakaya Island Airport",
        "City": "Wakaya Island",
        "Country": "Fiji"
    },
    {
        "id": "3525",
        "AirportCode": "KAZ",
        "AirportName": "Kau Airport",
        "City": "Kau",
        "Country": "Indonesia"
    },
    {
        "id": "3526",
        "AirportCode": "KBA",
        "AirportName": "Kabala Airport",
        "City": "Kabala",
        "Country": "Sierra Leone"
    },
    {
        "id": "3527",
        "AirportCode": "KBB",
        "AirportName": "Kirkimbie Airport",
        "City": "Kirkimbie",
        "Country": "Australia"
    },
    {
        "id": "3528",
        "AirportCode": "KBC",
        "AirportName": "Birch Creek Airport",
        "City": "Birch Creek",
        "Country": "USA Alaska"
    },
    {
        "id": "3529",
        "AirportCode": "KBD",
        "AirportName": "Kimberley Downs Airport",
        "City": "Kimberley Downs",
        "Country": "Australia"
    },
    {
        "id": "3530",
        "AirportCode": "KBE",
        "AirportName": "Hot Springs Sea Plane Base",
        "City": "Bell Island",
        "Country": "USA Alaska"
    },
    {
        "id": "3531",
        "AirportCode": "KBF",
        "AirportName": "Karubaga Airport",
        "City": "Karubaga",
        "Country": "Indonesia"
    },
    {
        "id": "3532",
        "AirportCode": "KBG",
        "AirportName": "Kabalega Falls Airport",
        "City": "Kabalega Falls",
        "Country": "Uganda"
    },
    {
        "id": "3533",
        "AirportCode": "KBH",
        "AirportName": "Kalat Airport",
        "City": "Kalat",
        "Country": "Pakistan"
    },
    {
        "id": "3534",
        "AirportCode": "KBI",
        "AirportName": "Kribi Airport",
        "City": "Kribi",
        "Country": "Cameroon"
    },
    {
        "id": "3535",
        "AirportCode": "KBJ",
        "AirportName": "King Canyon Airport",
        "City": "Kings Canyon",
        "Country": "Australia"
    },
    {
        "id": "3536",
        "AirportCode": "KBL",
        "AirportName": "Khwaja Rawash Airport",
        "City": "Kabul",
        "Country": "Afghanistan"
    },
    {
        "id": "3537",
        "AirportCode": "KBM",
        "AirportName": "Kabwum Airport",
        "City": "Kabwum",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3538",
        "AirportCode": "KBN",
        "AirportName": "Kabinda Airport",
        "City": "Kabinda",
        "Country": "Congo, DR"
    },
    {
        "id": "3539",
        "AirportCode": "KBO",
        "AirportName": "Kabalo Airport",
        "City": "Kabalo",
        "Country": "Congo, DR"
    },
    {
        "id": "3540",
        "AirportCode": "KBP",
        "AirportName": "Kiev-Borispol Airport",
        "City": "Kiev",
        "Country": "Ukraine"
    },
    {
        "id": "3541",
        "AirportCode": "KBQ",
        "AirportName": "Kasungu Airport",
        "City": "Kasungu",
        "Country": "Malawi"
    },
    {
        "id": "3542",
        "AirportCode": "KBR",
        "AirportName": "Pengkalan Chepa Airport",
        "City": "Kota Bharu",
        "Country": "Malaysia"
    },
    {
        "id": "3543",
        "AirportCode": "KBS",
        "AirportName": "Bo Airport",
        "City": "Bo",
        "Country": "Sierra Leone"
    },
    {
        "id": "3544",
        "AirportCode": "KBU",
        "AirportName": "Kotabaru Airport",
        "City": "Kotabaru",
        "Country": "Indonesia"
    },
    {
        "id": "3545",
        "AirportCode": "KBV",
        "AirportName": "Krabi Airport",
        "City": "Krabi",
        "Country": "Thailand"
    },
    {
        "id": "3546",
        "AirportCode": "KBX",
        "AirportName": "Kambuaya Airport",
        "City": "Kambuaya",
        "Country": "Indonesia"
    },
    {
        "id": "3547",
        "AirportCode": "KBY",
        "AirportName": "Streaky Bay Airport",
        "City": "Streaky Bay",
        "Country": "Australia"
    },
    {
        "id": "3548",
        "AirportCode": "KBZ",
        "AirportName": "Kaikoura Airport",
        "City": "Kaikoura",
        "Country": "New Zealand"
    },
    {
        "id": "3549",
        "AirportCode": "KCA",
        "AirportName": "Kuqa Airport",
        "City": "Kuqa",
        "Country": "China"
    },
    {
        "id": "3550",
        "AirportCode": "KCB",
        "AirportName": "Tepoe Airstrip",
        "City": "Kasikasima",
        "Country": "Suriname"
    },
    {
        "id": "3551",
        "AirportCode": "KCC",
        "AirportName": "Coffman Cove Sea Plane Base",
        "City": "Coffman Cove",
        "Country": "USA Alaska"
    },
    {
        "id": "3552",
        "AirportCode": "KCD",
        "AirportName": "Kamur Airport",
        "City": "Kamur",
        "Country": "Indonesia"
    },
    {
        "id": "3553",
        "AirportCode": "KCE",
        "AirportName": "Collinsville Airport",
        "City": "Collinsville",
        "Country": "Australia"
    },
    {
        "id": "3554",
        "AirportCode": "KCF",
        "AirportName": "Kadanwari Airport",
        "City": "Kadanwari",
        "Country": "Pakistan"
    },
    {
        "id": "3555",
        "AirportCode": "KCG",
        "AirportName": "Fisheries Airport",
        "City": "Chignik",
        "Country": "USA Alaska"
    },
    {
        "id": "3556",
        "AirportCode": "KCH",
        "AirportName": "Kuching Airport",
        "City": "Kuching",
        "Country": "Malaysia"
    },
    {
        "id": "3557",
        "AirportCode": "KCI",
        "AirportName": "Kon Airport",
        "City": "Kon",
        "Country": "Indonesia"
    },
    {
        "id": "3558",
        "AirportCode": "KCJ",
        "AirportName": "Komaio Airport",
        "City": "Komaio",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3559",
        "AirportCode": "KCK",
        "AirportName": "Fairfax Field",
        "City": "Kansas City",
        "Country": "USA Kansas"
    },
    {
        "id": "3560",
        "AirportCode": "KCL",
        "AirportName": "Lagoon Airport",
        "City": "Chignik",
        "Country": "USA Alaska"
    },
    {
        "id": "3561",
        "AirportCode": "KCM",
        "AirportName": "Kahramanmaras Airport",
        "City": "Kahramanmaras",
        "Country": "Turkey"
    },
    {
        "id": "3562",
        "AirportCode": "KCO",
        "AirportName": "Cengiz Topel Airport",
        "City": "Kocaeli",
        "Country": "Turkey"
    },
    {
        "id": "3563",
        "AirportCode": "KCP",
        "AirportName": "Kamenets-Podolskiy Airport",
        "City": "Kamenets-Podolskiy",
        "Country": "Ukraine"
    },
    {
        "id": "3564",
        "AirportCode": "KCQ",
        "AirportName": "Chignik Lake Airport",
        "City": "Chignik",
        "Country": "USA Alaska"
    },
    {
        "id": "3565",
        "AirportCode": "KCS",
        "AirportName": "Kings Creek Station Airport",
        "City": "Kings Creek Station",
        "Country": "Australia"
    },
    {
        "id": "3566",
        "AirportCode": "KCT",
        "AirportName": "Koggala Airport",
        "City": "Koggala",
        "Country": "Sri Lanka"
    },
    {
        "id": "3567",
        "AirportCode": "KCU",
        "AirportName": "Masindi Airport",
        "City": "Masindi",
        "Country": "Uganda"
    },
    {
        "id": "3568",
        "AirportCode": "KCZ",
        "AirportName": "Kochi Airport",
        "City": "Kochi",
        "Country": "Japan"
    },
    {
        "id": "3569",
        "AirportCode": "KDA",
        "AirportName": "Kolda Airport",
        "City": "Kolda",
        "Country": "Senegal"
    },
    {
        "id": "3570",
        "AirportCode": "KDB",
        "AirportName": "Kambalda Airport",
        "City": "Kambalda",
        "Country": "Australia"
    },
    {
        "id": "3571",
        "AirportCode": "KDC",
        "AirportName": "Kandi Airport",
        "City": "Kandi",
        "Country": "Benin"
    },
    {
        "id": "3572",
        "AirportCode": "KDD",
        "AirportName": "Khuzdar Airport",
        "City": "Khuzdar",
        "Country": "Pakistan"
    },
    {
        "id": "3573",
        "AirportCode": "KDE",
        "AirportName": "Koroba Airport",
        "City": "Koroba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3574",
        "AirportCode": "KDF",
        "AirportName": "Kouba Airport",
        "City": "Kouba",
        "Country": "Algeria"
    },
    {
        "id": "3575",
        "AirportCode": "KDH",
        "AirportName": "Kandahar Airport",
        "City": "Kandahar",
        "Country": "Afghanistan"
    },
    {
        "id": "3576",
        "AirportCode": "KDI",
        "AirportName": "Wolter Monginsidi Airport",
        "City": "Kendari",
        "Country": "Indonesia"
    },
    {
        "id": "3577",
        "AirportCode": "KDJ",
        "AirportName": "Ndjole Airport",
        "City": "Ndjole",
        "Country": "Gabon"
    },
    {
        "id": "3578",
        "AirportCode": "KDL",
        "AirportName": "Kardla Airport",
        "City": "Kardla",
        "Country": "Estonia"
    },
    {
        "id": "3579",
        "AirportCode": "KDM",
        "AirportName": "Kaadedhdhoo Airport",
        "City": "Kaadedhdhoo",
        "Country": "Maldives"
    },
    {
        "id": "3580",
        "AirportCode": "KDN",
        "AirportName": "Ndende Airport",
        "City": "Ndende",
        "Country": "Gabon"
    },
    {
        "id": "3581",
        "AirportCode": "KDO",
        "AirportName": "Kadhdhoo Airport",
        "City": "Kadhdhoo",
        "Country": "Maldives"
    },
    {
        "id": "3582",
        "AirportCode": "KDP",
        "AirportName": "Kandep Airport",
        "City": "Kandep",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3583",
        "AirportCode": "KDQ",
        "AirportName": "Kamberatoro Airport",
        "City": "Kamberatoro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3584",
        "AirportCode": "KDR",
        "AirportName": "Kardjali Airport",
        "City": "Kardjali",
        "Country": "Bulgaria"
    },
    {
        "id": "3585",
        "AirportCode": "KDR",
        "AirportName": "Kandrian",
        "City": "Kandrian",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3586",
        "AirportCode": "KDS",
        "AirportName": "Kamaran Downs Airport",
        "City": "Kamaran Downs",
        "Country": "Australia"
    },
    {
        "id": "3587",
        "AirportCode": "KDT",
        "AirportName": "Kamphangsaen Airport",
        "City": "Kamphangsaen",
        "Country": "Thailand"
    },
    {
        "id": "3588",
        "AirportCode": "KDU",
        "AirportName": "Skardu Airport",
        "City": "Skardu",
        "Country": "Pakistan"
    },
    {
        "id": "3589",
        "AirportCode": "KDV",
        "AirportName": "Kandavu Airport",
        "City": "Kandavu",
        "Country": "Fiji"
    },
    {
        "id": "3590",
        "AirportCode": "KDW",
        "AirportName": "Victoria Resevour Kandy Airport",
        "City": "Kandy",
        "Country": "Sri Lanka"
    },
    {
        "id": "3591",
        "AirportCode": "KDY",
        "AirportName": "Mahaweli Airport",
        "City": "Mahaweli",
        "Country": "Sri Lanka"
    },
    {
        "id": "3592",
        "AirportCode": "KEA",
        "AirportName": "Keisah Airport",
        "City": "Keisah",
        "Country": "Indonesia"
    },
    {
        "id": "3593",
        "AirportCode": "KEB",
        "AirportName": "English Bay Airport",
        "City": "English Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3594",
        "AirportCode": "KEC",
        "AirportName": "Kasenga Airport",
        "City": "Kasenga",
        "Country": "Congo, DR"
    },
    {
        "id": "3595",
        "AirportCode": "KED",
        "AirportName": "Kaedi Airport",
        "City": "Kaedi",
        "Country": "Mauritania"
    },
    {
        "id": "3596",
        "AirportCode": "KEE",
        "AirportName": "Kelle Airport",
        "City": "Kelle",
        "Country": "Congo"
    },
    {
        "id": "3597",
        "AirportCode": "KEF",
        "AirportName": "Keflavik International Airport",
        "City": "Reykjavik",
        "Country": "Iceland"
    },
    {
        "id": "3598",
        "AirportCode": "KEG",
        "AirportName": "Keglsugl Airport",
        "City": "Keglsugl",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3599",
        "AirportCode": "KEH",
        "AirportName": "Kenmore Air Harbor Airport",
        "City": "Kenmore Air Harbor",
        "Country": "USA Washington"
    },
    {
        "id": "3600",
        "AirportCode": "KEI",
        "AirportName": "Kepi Airport",
        "City": "Kepi",
        "Country": "Indonesia"
    },
    {
        "id": "3601",
        "AirportCode": "KEJ",
        "AirportName": "Kemerovo Airport",
        "City": "Kemerovo",
        "Country": "Russia"
    },
    {
        "id": "3602",
        "AirportCode": "KEK",
        "AirportName": "Ekwok Airport",
        "City": "Ekwok",
        "Country": "USA Alaska"
    },
    {
        "id": "3603",
        "AirportCode": "KEL",
        "AirportName": "Holtenau Airport",
        "City": "Kiel",
        "Country": "Germany"
    },
    {
        "id": "3604",
        "AirportCode": "KEM",
        "AirportName": "Kemi/Tornio Airport",
        "City": "Kemi/Tornio",
        "Country": "Finland"
    },
    {
        "id": "3605",
        "AirportCode": "KEN",
        "AirportName": "Kenema Airport",
        "City": "Kenema",
        "Country": "Sierra Leone"
    },
    {
        "id": "3606",
        "AirportCode": "KEP",
        "AirportName": "Nepalganj Airport",
        "City": "Nepalganj",
        "Country": "Nepal"
    },
    {
        "id": "3607",
        "AirportCode": "KEQ",
        "AirportName": "Kebar Airport",
        "City": "Kebar",
        "Country": "Indonesia"
    },
    {
        "id": "3608",
        "AirportCode": "KER",
        "AirportName": "Kerman Airport",
        "City": "Kerman",
        "Country": "Iran"
    },
    {
        "id": "3609",
        "AirportCode": "KES",
        "AirportName": "Kelsey Airport",
        "City": "Kelsey",
        "Country": "Canada"
    },
    {
        "id": "3610",
        "AirportCode": "KET",
        "AirportName": "Keng Tung Airport",
        "City": "Keng Tung",
        "Country": "Burma"
    },
    {
        "id": "3611",
        "AirportCode": "KEV",
        "AirportName": "Halli Airport",
        "City": "Kuorevesi",
        "Country": "Finland"
    },
    {
        "id": "3612",
        "AirportCode": "KEW",
        "AirportName": "Keewaywin Airport",
        "City": "Keewaywin",
        "Country": "Canada"
    },
    {
        "id": "3613",
        "AirportCode": "KEX",
        "AirportName": "Kanabea Airport",
        "City": "Kanabea",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3614",
        "AirportCode": "KEY",
        "AirportName": "Kericho Airport",
        "City": "Kericho",
        "Country": "Kenya"
    },
    {
        "id": "3615",
        "AirportCode": "KEZ",
        "AirportName": "Kelaniya River Airport",
        "City": "Kelaniya",
        "Country": "Sri Lanka"
    },
    {
        "id": "3616",
        "AirportCode": "KFA",
        "AirportName": "Kiffa Airport",
        "City": "Kiffa",
        "Country": "Mauritania"
    },
    {
        "id": "3617",
        "AirportCode": "KFG",
        "AirportName": "Kalkurung Airport",
        "City": "Kalkurung",
        "Country": "Australia"
    },
    {
        "id": "3618",
        "AirportCode": "KFP",
        "AirportName": "False Pass Airport",
        "City": "False Pass",
        "Country": "USA Alaska"
    },
    {
        "id": "3619",
        "AirportCode": "KFS",
        "AirportName": "Kastamonu Airport",
        "City": "Kastamonu",
        "Country": "Turkey"
    },
    {
        "id": "3620",
        "AirportCode": "KGA",
        "AirportName": "Kananga Airport",
        "City": "Kananga",
        "Country": "Congo, DR"
    },
    {
        "id": "3621",
        "AirportCode": "KGB",
        "AirportName": "Konge Airport",
        "City": "Konge",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3622",
        "AirportCode": "KGC",
        "AirportName": "Kingscote Airport",
        "City": "Kingscote",
        "Country": "Australia"
    },
    {
        "id": "3623",
        "AirportCode": "KGD",
        "AirportName": "Kaliningrad Airport",
        "City": "Kaliningrad",
        "Country": "Russia"
    },
    {
        "id": "3624",
        "AirportCode": "KGE",
        "AirportName": "Kagau Airport",
        "City": "Kagau",
        "Country": "Solomon Islands"
    },
    {
        "id": "3625",
        "AirportCode": "KGF",
        "AirportName": "Karaganda Airport",
        "City": "Karaganda",
        "Country": "Kazakhstan"
    },
    {
        "id": "3626",
        "AirportCode": "KGG",
        "AirportName": "Kedougou Airport",
        "City": "Kedougou",
        "Country": "Senegal"
    },
    {
        "id": "3627",
        "AirportCode": "KGH",
        "AirportName": "Yongai Airport",
        "City": "Yongai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3628",
        "AirportCode": "KGI",
        "AirportName": "Kalgoorlie Airport",
        "City": "Kalgoorlie",
        "Country": "Australia"
    },
    {
        "id": "3629",
        "AirportCode": "KGJ",
        "AirportName": "Karonga Airport",
        "City": "Karonga",
        "Country": "Malawi"
    },
    {
        "id": "3630",
        "AirportCode": "KGK",
        "AirportName": "New Koliganek Airport",
        "City": "New Koliganek",
        "Country": "USA Alaska"
    },
    {
        "id": "3631",
        "AirportCode": "KGL",
        "AirportName": "Gregoire Kayibanda Airport",
        "City": "Kigali",
        "Country": "Rwanda"
    },
    {
        "id": "3632",
        "AirportCode": "KGN",
        "AirportName": "Kasongo",
        "City": "Kasongo",
        "Country": "Congo, DR"
    },
    {
        "id": "3633",
        "AirportCode": "KGN",
        "AirportName": "Kasongo Lunda Airport",
        "City": "Kasongo Lunda",
        "Country": "Congo, DR"
    },
    {
        "id": "3634",
        "AirportCode": "KGO",
        "AirportName": "Kirovograd Airport",
        "City": "Kirovograd",
        "Country": "Ukraine"
    },
    {
        "id": "3635",
        "AirportCode": "KGP",
        "AirportName": "Kogalym International Airport",
        "City": "Kogalym",
        "Country": "Russia"
    },
    {
        "id": "3636",
        "AirportCode": "KGR",
        "AirportName": "Kulgera Airport",
        "City": "Kulgera",
        "Country": "Australia"
    },
    {
        "id": "3637",
        "AirportCode": "KGS",
        "AirportName": "Kos Airport",
        "City": "Kos",
        "Country": "Greece"
    },
    {
        "id": "3638",
        "AirportCode": "KGU",
        "AirportName": "Keningau Airport",
        "City": "Keningau",
        "Country": "Malaysia"
    },
    {
        "id": "3639",
        "AirportCode": "KGW",
        "AirportName": "Kagi Airport",
        "City": "Kagi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3640",
        "AirportCode": "KGX",
        "AirportName": "Grayling Airport",
        "City": "Grayling",
        "Country": "USA Alaska"
    },
    {
        "id": "3641",
        "AirportCode": "KGY",
        "AirportName": "Kingaroy Airport",
        "City": "Kingaroy",
        "Country": "Australia"
    },
    {
        "id": "3642",
        "AirportCode": "KHA",
        "AirportName": "Khaneh Airport",
        "City": "Khaneh",
        "Country": "Iran"
    },
    {
        "id": "3643",
        "AirportCode": "KHC",
        "AirportName": "Kerch Airport",
        "City": "Kerch",
        "Country": "Ukraine"
    },
    {
        "id": "3644",
        "AirportCode": "KHD",
        "AirportName": "Khorramabad Airport",
        "City": "Khorramabad",
        "Country": "Iran"
    },
    {
        "id": "3645",
        "AirportCode": "KHE",
        "AirportName": "Kherson Airport",
        "City": "Kherson",
        "Country": "Ukraine"
    },
    {
        "id": "3646",
        "AirportCode": "KHG",
        "AirportName": "Kashi Airport",
        "City": "Kashi",
        "Country": "China"
    },
    {
        "id": "3647",
        "AirportCode": "KHH",
        "AirportName": "Kaohsiung International Airport",
        "City": "Kaohsiung",
        "Country": "Taiwan"
    },
    {
        "id": "3648",
        "AirportCode": "KHI",
        "AirportName": "Quaid-E-Azam International Airport",
        "City": "Karachi",
        "Country": "Pakistan"
    },
    {
        "id": "3649",
        "AirportCode": "KHJ",
        "AirportName": "Kauhajoki Airport",
        "City": "Kauhajoki",
        "Country": "Finland"
    },
    {
        "id": "3650",
        "AirportCode": "KHK",
        "AirportName": "Khark Airport",
        "City": "Khark",
        "Country": "Iran"
    },
    {
        "id": "3651",
        "AirportCode": "KHL",
        "AirportName": "Khulna Airport",
        "City": "Khulna",
        "Country": "Bangladesh"
    },
    {
        "id": "3652",
        "AirportCode": "KHM",
        "AirportName": "Khamtis Airport",
        "City": "Khamtis",
        "Country": "Burma"
    },
    {
        "id": "3653",
        "AirportCode": "KHN",
        "AirportName": "Nanchang Airport",
        "City": "Nanchang",
        "Country": "China"
    },
    {
        "id": "3654",
        "AirportCode": "KHO",
        "AirportName": "Khoka Moya Airport",
        "City": "Khoka Moya",
        "Country": "South Africa"
    },
    {
        "id": "3655",
        "AirportCode": "KHR",
        "AirportName": "Kharkhorin Airport",
        "City": "Kharkhorin",
        "Country": "Mongolia"
    },
    {
        "id": "3656",
        "AirportCode": "KHS",
        "AirportName": "Khasab Airport",
        "City": "Khasab",
        "Country": "Oman"
    },
    {
        "id": "3657",
        "AirportCode": "KHT",
        "AirportName": "Khost Airport",
        "City": "Khost",
        "Country": "Afghanistan"
    },
    {
        "id": "3658",
        "AirportCode": "KHU",
        "AirportName": "Kremenchug Airport",
        "City": "Kremenchug",
        "Country": "Ukraine"
    },
    {
        "id": "3659",
        "AirportCode": "KHV",
        "AirportName": "Novyy Airport",
        "City": "Khabarovsk",
        "Country": "Russia"
    },
    {
        "id": "3660",
        "AirportCode": "KHW",
        "AirportName": "Khwai River Lodge Airport",
        "City": "Khwai River Lodge",
        "Country": "Botswana"
    },
    {
        "id": "3661",
        "AirportCode": "KHY",
        "AirportName": "Khoy Airport",
        "City": "Khoy",
        "Country": "Iran"
    },
    {
        "id": "3662",
        "AirportCode": "KHZ",
        "AirportName": "Kauehi Airport",
        "City": "Kauehi",
        "Country": "French Polynesia"
    },
    {
        "id": "3663",
        "AirportCode": "KIA",
        "AirportName": "Kaiapit Airport",
        "City": "Kaiapit",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3664",
        "AirportCode": "KIC",
        "AirportName": "Mesa Del Rey Airport",
        "City": "King City",
        "Country": "USA California"
    },
    {
        "id": "3665",
        "AirportCode": "KID",
        "AirportName": "Kristianstad Airport",
        "City": "Kristianstad",
        "Country": "Sweden"
    },
    {
        "id": "3666",
        "AirportCode": "KIE",
        "AirportName": "Aropa Airport",
        "City": "Kieta",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3667",
        "AirportCode": "KIF",
        "AirportName": "Kingfisher Lake Airport",
        "City": "Kingfisher Lake",
        "Country": "Canada"
    },
    {
        "id": "3668",
        "AirportCode": "KIG",
        "AirportName": "Koingnaas Airport",
        "City": "Koingnaas",
        "Country": "South Africa"
    },
    {
        "id": "3669",
        "AirportCode": "KIH",
        "AirportName": "Kish Island Airport",
        "City": "Kish Island",
        "Country": "Iran"
    },
    {
        "id": "3670",
        "AirportCode": "KII",
        "AirportName": "Kibuli Airport",
        "City": "Kibuli",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3671",
        "AirportCode": "KIJ",
        "AirportName": "Niigata Airport",
        "City": "Niigata",
        "Country": "Japan"
    },
    {
        "id": "3672",
        "AirportCode": "KIK",
        "AirportName": "Kirkuk Airport",
        "City": "Kirkuk",
        "Country": "Iraq"
    },
    {
        "id": "3673",
        "AirportCode": "KIL",
        "AirportName": "Kilwa Airport",
        "City": "Kilwa",
        "Country": "Congo, DR"
    },
    {
        "id": "3674",
        "AirportCode": "KIM",
        "AirportName": "Kimberley Airport",
        "City": "Kimberley",
        "Country": "South Africa"
    },
    {
        "id": "3675",
        "AirportCode": "KIN",
        "AirportName": "Norman Manley Airport",
        "City": "Kingston",
        "Country": "Jamaica"
    },
    {
        "id": "3676",
        "AirportCode": "KIO",
        "AirportName": "Kili Island Airport",
        "City": "Kili Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "3677",
        "AirportCode": "KIP",
        "AirportName": "Kickapoo Airport",
        "City": "Wichita Falls",
        "Country": "USA Texas"
    },
    {
        "id": "3678",
        "AirportCode": "KIQ",
        "AirportName": "Kira Airport",
        "City": "Kira",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3679",
        "AirportCode": "KIR",
        "AirportName": "Kerry County Airport",
        "City": "Kerry County",
        "Country": "Ireland"
    },
    {
        "id": "3680",
        "AirportCode": "KIS",
        "AirportName": "Kisumu Airport",
        "City": "Kisumu",
        "Country": "Kenya"
    },
    {
        "id": "3681",
        "AirportCode": "KIT",
        "AirportName": "Kithira Airport",
        "City": "Kithira",
        "Country": "Greece"
    },
    {
        "id": "3682",
        "AirportCode": "KIU",
        "AirportName": "Kiunga Airport",
        "City": "Kiunga",
        "Country": "Kenya"
    },
    {
        "id": "3683",
        "AirportCode": "KIV",
        "AirportName": "Chisinau Airport",
        "City": "Chisinau",
        "Country": "Moldova"
    },
    {
        "id": "3684",
        "AirportCode": "KIW",
        "AirportName": "Southdowns Airport",
        "City": "Kitwe",
        "Country": "Zambia"
    },
    {
        "id": "3685",
        "AirportCode": "KIX",
        "AirportName": "Kansai International Airport",
        "City": "Osaka",
        "Country": "Japan"
    },
    {
        "id": "3686",
        "AirportCode": "KIY",
        "AirportName": "Kilwa Airport",
        "City": "Kilwa",
        "Country": "Tanzania"
    },
    {
        "id": "3687",
        "AirportCode": "KIZ",
        "AirportName": "Kikinonda Airport",
        "City": "Kikinonda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3688",
        "AirportCode": "KJA",
        "AirportName": "Krasnojarsk Airport",
        "City": "Krasnojarsk",
        "Country": "Russia"
    },
    {
        "id": "3689",
        "AirportCode": "KJK",
        "AirportName": "Kortrijk Airport",
        "City": "Kortrijk",
        "Country": "Belgium"
    },
    {
        "id": "3690",
        "AirportCode": "KJP",
        "AirportName": "Kerama Airport",
        "City": "Kerama",
        "Country": "Japan"
    },
    {
        "id": "3691",
        "AirportCode": "KJU",
        "AirportName": "Kamiraba Airport",
        "City": "Kamiraba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3692",
        "AirportCode": "KKA",
        "AirportName": "Koyuk Airport",
        "City": "Koyuk",
        "Country": "USA Alaska"
    },
    {
        "id": "3693",
        "AirportCode": "KKB",
        "AirportName": "Kitui Bay Sea Plane Base",
        "City": "Kitui Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3694",
        "AirportCode": "KKC",
        "AirportName": "Khon Kaen Airport",
        "City": "Khon Kaen",
        "Country": "Thailand"
    },
    {
        "id": "3695",
        "AirportCode": "KKD",
        "AirportName": "Kokoda Airport",
        "City": "Kokoda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3696",
        "AirportCode": "KKE",
        "AirportName": "Kerikeri Airport",
        "City": "Kerikeri",
        "Country": "New Zealand"
    },
    {
        "id": "3697",
        "AirportCode": "KKG",
        "AirportName": "Konawaruk Airport",
        "City": "Konawaruk",
        "Country": "Guyana"
    },
    {
        "id": "3698",
        "AirportCode": "KKH",
        "AirportName": "Kongiganak Airport",
        "City": "Kongiganak",
        "Country": "USA Alaska"
    },
    {
        "id": "3699",
        "AirportCode": "KKI",
        "AirportName": "Akiachak Sea Plane Base",
        "City": "Akiachak",
        "Country": "USA Alaska"
    },
    {
        "id": "3700",
        "AirportCode": "KKJ",
        "AirportName": "Kokura Airport",
        "City": "Kita Kyushu",
        "Country": "Japan"
    },
    {
        "id": "3701",
        "AirportCode": "KKM",
        "AirportName": "Lop Buri Airport",
        "City": "Lop Buri",
        "Country": "Thailand"
    },
    {
        "id": "3702",
        "AirportCode": "KKN",
        "AirportName": "Hoeybuktmoen Airport",
        "City": "Kirkenes",
        "Country": "Norway"
    },
    {
        "id": "3703",
        "AirportCode": "KKO",
        "AirportName": "Kaikohe Airport",
        "City": "Kaikohe",
        "Country": "New Zealand"
    },
    {
        "id": "3704",
        "AirportCode": "KKP",
        "AirportName": "Koolburra Airport",
        "City": "Koolburra",
        "Country": "Australia"
    },
    {
        "id": "3705",
        "AirportCode": "KKR",
        "AirportName": "Kaukura Atoll Airport",
        "City": "Kaukura Atoll",
        "Country": "French Polynesia"
    },
    {
        "id": "3706",
        "AirportCode": "KKT",
        "AirportName": "Kentland Airport",
        "City": "Kentland",
        "Country": "USA Indiana"
    },
    {
        "id": "3707",
        "AirportCode": "KKU",
        "AirportName": "Ekuk Airport",
        "City": "Ekuk",
        "Country": "USA Alaska"
    },
    {
        "id": "3708",
        "AirportCode": "KKW",
        "AirportName": "Kikwit Airport",
        "City": "Kikwit",
        "Country": "Congo, DR"
    },
    {
        "id": "3709",
        "AirportCode": "KKX",
        "AirportName": "Kikaiga Shima Airport",
        "City": "Kikaiga Shima",
        "Country": "Japan"
    },
    {
        "id": "3710",
        "AirportCode": "KKY",
        "AirportName": "Kilkenny Airport",
        "City": "Kilkenny",
        "Country": "Ireland"
    },
    {
        "id": "3711",
        "AirportCode": "KKZ",
        "AirportName": "Koh Kong Airport",
        "City": "Koh Kong",
        "Country": "Cambodia"
    },
    {
        "id": "3712",
        "AirportCode": "KLB",
        "AirportName": "Kalabo Airport",
        "City": "Kalabo",
        "Country": "Zambia"
    },
    {
        "id": "3713",
        "AirportCode": "KLC",
        "AirportName": "Kaolack Airport",
        "City": "Kaolack",
        "Country": "Senegal"
    },
    {
        "id": "3714",
        "AirportCode": "KLD",
        "AirportName": "Migalovo Airport",
        "City": "Kalinin",
        "Country": "Russia"
    },
    {
        "id": "3715",
        "AirportCode": "KLE",
        "AirportName": "Kaele Airport",
        "City": "Kaele",
        "Country": "Cameroon"
    },
    {
        "id": "3716",
        "AirportCode": "KLF",
        "AirportName": "Kaluga Airport",
        "City": "Kaluga",
        "Country": "Russia"
    },
    {
        "id": "3717",
        "AirportCode": "KLG",
        "AirportName": "Kalskag Municipal Airport",
        "City": "Kalskag",
        "Country": "USA Alaska"
    },
    {
        "id": "3718",
        "AirportCode": "KLH",
        "AirportName": "Kolhapur Airport",
        "City": "Kolhapur",
        "Country": "India"
    },
    {
        "id": "3719",
        "AirportCode": "KLI",
        "AirportName": "Kotakoli",
        "City": "Kotakoli",
        "Country": "Congo, DR"
    },
    {
        "id": "3720",
        "AirportCode": "KLI",
        "AirportName": "Kota Koli Airport",
        "City": "Kota Koli",
        "Country": "Congo, DR"
    },
    {
        "id": "3721",
        "AirportCode": "KLJ",
        "AirportName": "Klaipeda Airport",
        "City": "Klaipeda",
        "Country": "Lithuania"
    },
    {
        "id": "3722",
        "AirportCode": "KLK",
        "AirportName": "Kalokol Airport",
        "City": "Kalokol",
        "Country": "Kenya"
    },
    {
        "id": "3723",
        "AirportCode": "KLL",
        "AirportName": "Levelock Airport",
        "City": "Levelock",
        "Country": "USA Alaska"
    },
    {
        "id": "3724",
        "AirportCode": "KLM",
        "AirportName": "Kalaleh Airport",
        "City": "Kalaleh",
        "Country": "Iran"
    },
    {
        "id": "3725",
        "AirportCode": "KLN",
        "AirportName": "Larsen Sea Plane Base",
        "City": "Larsen Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3726",
        "AirportCode": "KLO",
        "AirportName": "Kalibo Airport",
        "City": "Kalibo",
        "Country": "Philippines"
    },
    {
        "id": "3727",
        "AirportCode": "KLQ",
        "AirportName": "Keluang Airport",
        "City": "Keluang",
        "Country": "Indonesia"
    },
    {
        "id": "3728",
        "AirportCode": "KLR",
        "AirportName": "Kalmar Airport",
        "City": "Kalmar",
        "Country": "Sweden"
    },
    {
        "id": "3729",
        "AirportCode": "KLS",
        "AirportName": "Longview Airport",
        "City": "Kelso",
        "Country": "USA Washington"
    },
    {
        "id": "3730",
        "AirportCode": "KLT",
        "AirportName": "Kaiserslautern Airport",
        "City": "Kaiserslautern",
        "Country": "Germany"
    },
    {
        "id": "3731",
        "AirportCode": "KLU",
        "AirportName": "Klagenfurt Airport",
        "City": "Klagenfurt",
        "Country": "Austria"
    },
    {
        "id": "3732",
        "AirportCode": "KLV",
        "AirportName": "Karlovy Vary Airport",
        "City": "Karlovy Vary",
        "Country": "Czech Republic"
    },
    {
        "id": "3733",
        "AirportCode": "KLW",
        "AirportName": "Klawock Airport",
        "City": "Klawock",
        "Country": "USA Alaska"
    },
    {
        "id": "3734",
        "AirportCode": "KLX",
        "AirportName": "Kalamata Airport",
        "City": "Kalamata",
        "Country": "Greece"
    },
    {
        "id": "3735",
        "AirportCode": "KLY",
        "AirportName": "Kalima Airport",
        "City": "Kalima",
        "Country": "Congo, DR"
    },
    {
        "id": "3736",
        "AirportCode": "KLZ",
        "AirportName": "Kleinzee Airport",
        "City": "Kleinzee",
        "Country": "South Africa"
    },
    {
        "id": "3737",
        "AirportCode": "KMA",
        "AirportName": "Kerema Airport",
        "City": "Kerema",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3738",
        "AirportCode": "KMB",
        "AirportName": "Koinambe Airport",
        "City": "Koinambe",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3739",
        "AirportCode": "KMC",
        "AirportName": "King Khalid Military City Airport",
        "City": "King Khalid Military City",
        "Country": "Saudi Arabia"
    },
    {
        "id": "3740",
        "AirportCode": "KMD",
        "AirportName": "Mandji Airport",
        "City": "Mandji",
        "Country": "Gabon"
    },
    {
        "id": "3741",
        "AirportCode": "KME",
        "AirportName": "Kamembe Airport",
        "City": "Kamembe",
        "Country": "Rwanda"
    },
    {
        "id": "3742",
        "AirportCode": "KMF",
        "AirportName": "Kamina Airport",
        "City": "Kamina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3743",
        "AirportCode": "KMG",
        "AirportName": "Kunming Airport",
        "City": "Kunming",
        "Country": "China"
    },
    {
        "id": "3744",
        "AirportCode": "KMH",
        "AirportName": "Kuruman Airport",
        "City": "Kuruman",
        "Country": "South Africa"
    },
    {
        "id": "3745",
        "AirportCode": "KMI",
        "AirportName": "Miyazaki Airport",
        "City": "Miyazaki",
        "Country": "Japan"
    },
    {
        "id": "3746",
        "AirportCode": "KMJ",
        "AirportName": "Kumamoto Airport",
        "City": "Kumamoto",
        "Country": "Japan"
    },
    {
        "id": "3747",
        "AirportCode": "KMK",
        "AirportName": "Makabana Airport",
        "City": "Makabana",
        "Country": "Congo"
    },
    {
        "id": "3748",
        "AirportCode": "KML",
        "AirportName": "Kamileroi Airport",
        "City": "Kamileroi",
        "Country": "Australia"
    },
    {
        "id": "3749",
        "AirportCode": "KMM",
        "AirportName": "Kiman Airport",
        "City": "Kiman",
        "Country": "Indonesia"
    },
    {
        "id": "3750",
        "AirportCode": "KMN",
        "AirportName": "Kamina Airport",
        "City": "Kamina",
        "Country": "Congo, DR"
    },
    {
        "id": "3751",
        "AirportCode": "KMO",
        "AirportName": "Manokotak Sea Plane Base",
        "City": "Manokotak",
        "Country": "USA Alaska"
    },
    {
        "id": "3752",
        "AirportCode": "KMP",
        "AirportName": "J.G.H. Van Der Wath Airport",
        "City": "Keetmanshoop",
        "Country": "Namibia"
    },
    {
        "id": "3753",
        "AirportCode": "KMQ",
        "AirportName": "Komatsu Airport",
        "City": "Komatsu",
        "Country": "Japan"
    },
    {
        "id": "3754",
        "AirportCode": "KMR",
        "AirportName": "Karimui Airport",
        "City": "Karimui",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3755",
        "AirportCode": "KMS",
        "AirportName": "Kumasi Airport",
        "City": "Kumasi",
        "Country": "Ghana"
    },
    {
        "id": "3756",
        "AirportCode": "KMT",
        "AirportName": "Kampot Airport",
        "City": "Kampot",
        "Country": "Cambodia"
    },
    {
        "id": "3757",
        "AirportCode": "KMU",
        "AirportName": "Kismayu Airport",
        "City": "Kismayu",
        "Country": "Somalia"
    },
    {
        "id": "3758",
        "AirportCode": "KMV",
        "AirportName": "Kalemyo Airport",
        "City": "Kalemyo",
        "Country": "Burma"
    },
    {
        "id": "3759",
        "AirportCode": "KMW",
        "AirportName": "Kostroma Airport",
        "City": "Kostroma",
        "Country": "Russia"
    },
    {
        "id": "3760",
        "AirportCode": "KMX",
        "AirportName": "Khamis Mushait Airport",
        "City": "Khamis Mushait",
        "Country": "Saudi Arabia"
    },
    {
        "id": "3761",
        "AirportCode": "KMY",
        "AirportName": "Moser Bay Airport",
        "City": "Moser Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3762",
        "AirportCode": "KMZ",
        "AirportName": "Kaoma Airport",
        "City": "Kaoma",
        "Country": "Zambia"
    },
    {
        "id": "3763",
        "AirportCode": "KNA",
        "AirportName": "Vina del Mar Airport",
        "City": "Vina del Mar",
        "Country": "Chile"
    },
    {
        "id": "3764",
        "AirportCode": "KNB",
        "AirportName": "Kanab Airport",
        "City": "Kanab",
        "Country": "USA Utah"
    },
    {
        "id": "3765",
        "AirportCode": "KND",
        "AirportName": "Kindu Airport",
        "City": "Kindu",
        "Country": "Congo, DR"
    },
    {
        "id": "3766",
        "AirportCode": "KNE",
        "AirportName": "Kanainj Airport",
        "City": "Kanainj",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3767",
        "AirportCode": "KNF",
        "AirportName": "Marham Royal Air Force",
        "City": "Kings Lynn",
        "Country": "United Kingdom"
    },
    {
        "id": "3768",
        "AirportCode": "KNG",
        "AirportName": "Kaimana Airport",
        "City": "Kaimana",
        "Country": "Indonesia"
    },
    {
        "id": "3769",
        "AirportCode": "KNH",
        "AirportName": "Shang-Yi Airport",
        "City": "Kinmen",
        "Country": "Taiwan"
    },
    {
        "id": "3770",
        "AirportCode": "KNI",
        "AirportName": "Katanning Airport",
        "City": "Katanning",
        "Country": "Australia"
    },
    {
        "id": "3771",
        "AirportCode": "KNJ",
        "AirportName": "Kindamba Airport",
        "City": "Kindamba",
        "Country": "Congo"
    },
    {
        "id": "3772",
        "AirportCode": "KNK",
        "AirportName": "Kakhonak Airport",
        "City": "Kakhonak",
        "Country": "USA Alaska"
    },
    {
        "id": "3773",
        "AirportCode": "KNL",
        "AirportName": "Kelanoa Airport",
        "City": "Kelanoa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3774",
        "AirportCode": "KNM",
        "AirportName": "Kaniama Airport",
        "City": "Kaniama",
        "Country": "Congo, DR"
    },
    {
        "id": "3775",
        "AirportCode": "KNN",
        "AirportName": "Kankan Airport",
        "City": "Kankan",
        "Country": "Guinea"
    },
    {
        "id": "3776",
        "AirportCode": "KNO",
        "AirportName": "Knokke/Het Zoute Airport",
        "City": "Knokke/Het Zoute",
        "Country": "Belgium"
    },
    {
        "id": "3777",
        "AirportCode": "KNQ",
        "AirportName": "Kone Airport",
        "City": "Kone",
        "Country": "New Caledonia"
    },
    {
        "id": "3778",
        "AirportCode": "KNR",
        "AirportName": "Jam Airport",
        "City": "Kangan",
        "Country": "Iran"
    },
    {
        "id": "3779",
        "AirportCode": "KNS",
        "AirportName": "King Island Airport",
        "City": "King Island",
        "Country": "Australia"
    },
    {
        "id": "3780",
        "AirportCode": "KNT",
        "AirportName": "Kennett Municipal Airport",
        "City": "Kennett",
        "Country": "USA Missouri"
    },
    {
        "id": "3781",
        "AirportCode": "KNU",
        "AirportName": "Kanpur Airport",
        "City": "Kanpur",
        "Country": "India"
    },
    {
        "id": "3782",
        "AirportCode": "KNV",
        "AirportName": "Knights Inlet Airport",
        "City": "Knights Inlet",
        "Country": "Canada"
    },
    {
        "id": "3783",
        "AirportCode": "KNW",
        "AirportName": "New Stuyahok Airport",
        "City": "New Stuyahok",
        "Country": "USA Alaska"
    },
    {
        "id": "3784",
        "AirportCode": "KNX",
        "AirportName": "Kununurra Airport",
        "City": "Kununurra",
        "Country": "Australia"
    },
    {
        "id": "3785",
        "AirportCode": "KNY",
        "AirportName": "Kinoosao Airport",
        "City": "Kinoosao",
        "Country": "Canada"
    },
    {
        "id": "3786",
        "AirportCode": "KNZ",
        "AirportName": "Kenieba Airport",
        "City": "Kenieba",
        "Country": "Mali"
    },
    {
        "id": "3787",
        "AirportCode": "KOA",
        "AirportName": "Keahole Airport",
        "City": "Kona",
        "Country": "USA Hawaii"
    },
    {
        "id": "3788",
        "AirportCode": "KOB",
        "AirportName": "Koutaba Airport",
        "City": "Koutaba",
        "Country": "Cameroon"
    },
    {
        "id": "3789",
        "AirportCode": "KOC",
        "AirportName": "Koumac Airport",
        "City": "Koumac",
        "Country": "New Caledonia"
    },
    {
        "id": "3790",
        "AirportCode": "KOD",
        "AirportName": "Kotabangun Airport",
        "City": "Kotabangun",
        "Country": "Indonesia"
    },
    {
        "id": "3791",
        "AirportCode": "KOE",
        "AirportName": "Eltari Airport",
        "City": "Kupang",
        "Country": "Indonesia"
    },
    {
        "id": "3792",
        "AirportCode": "KOF",
        "AirportName": "Komatipoort Airport",
        "City": "Komatipoort",
        "Country": "South Africa"
    },
    {
        "id": "3793",
        "AirportCode": "KOG",
        "AirportName": "Khong Airport",
        "City": "Khong",
        "Country": "Laos"
    },
    {
        "id": "3794",
        "AirportCode": "KOH",
        "AirportName": "Koolatah Airport",
        "City": "Koolatah",
        "Country": "Australia"
    },
    {
        "id": "3795",
        "AirportCode": "KOI",
        "AirportName": "Kirkwall Airport",
        "City": "Kirkwall",
        "Country": "United Kingdom"
    },
    {
        "id": "3796",
        "AirportCode": "KOJ",
        "AirportName": "Kagoshima Airport",
        "City": "Kagoshima",
        "Country": "Japan"
    },
    {
        "id": "3797",
        "AirportCode": "KOK",
        "AirportName": "Kruunupyy Airport",
        "City": "Kokkola/Pietarsaari",
        "Country": "Finland"
    },
    {
        "id": "3798",
        "AirportCode": "KOL",
        "AirportName": "Koumala Airport",
        "City": "Koumala",
        "Country": "Central African Republic"
    },
    {
        "id": "3799",
        "AirportCode": "KOM",
        "AirportName": "Komo Airport",
        "City": "Komo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3800",
        "AirportCode": "KON",
        "AirportName": "Kontum Airport",
        "City": "Kontum",
        "Country": "Vietnam"
    },
    {
        "id": "3801",
        "AirportCode": "KOO",
        "AirportName": "Kongolo Airport",
        "City": "Kongolo",
        "Country": "Congo, DR"
    },
    {
        "id": "3802",
        "AirportCode": "KOP",
        "AirportName": "Kamphaeng Saen",
        "City": "Nakhon Pathon",
        "Country": "Thailand"
    },
    {
        "id": "3803",
        "AirportCode": "KOP",
        "AirportName": "Nakhon Phanom Airport",
        "City": "Nakhon Phanom",
        "Country": "Thailand"
    },
    {
        "id": "3804",
        "AirportCode": "KOQ",
        "AirportName": "Koethen Airport",
        "City": "Koethen",
        "Country": "Germany"
    },
    {
        "id": "3805",
        "AirportCode": "KOR",
        "AirportName": "Kokoro Airport",
        "City": "Kokoro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3806",
        "AirportCode": "KOS",
        "AirportName": "Sihanoukville Airport",
        "City": "Sihanoukville",
        "Country": "Cambodia"
    },
    {
        "id": "3807",
        "AirportCode": "KOT",
        "AirportName": "Kotlik Airport",
        "City": "Kotlik",
        "Country": "USA Alaska"
    },
    {
        "id": "3808",
        "AirportCode": "KOU",
        "AirportName": "Koulamoutou Airport",
        "City": "Koulamoutou",
        "Country": "Gabon"
    },
    {
        "id": "3809",
        "AirportCode": "KOV",
        "AirportName": "Kokshetau Airport",
        "City": "Kokshetau",
        "Country": "Kazakhstan"
    },
    {
        "id": "3810",
        "AirportCode": "KOW",
        "AirportName": "Ganzhou Airport",
        "City": "Ganzhou",
        "Country": "China"
    },
    {
        "id": "3811",
        "AirportCode": "KOX",
        "AirportName": "Kokonao Airport",
        "City": "Kokonao",
        "Country": "Indonesia"
    },
    {
        "id": "3812",
        "AirportCode": "KOY",
        "AirportName": "Olga Bay Sea Plane Base",
        "City": "Olga Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3813",
        "AirportCode": "KOZ",
        "AirportName": "Ouzinkie Sea Plane Base",
        "City": "Ouzinkie",
        "Country": "USA Alaska"
    },
    {
        "id": "3814",
        "AirportCode": "KPA",
        "AirportName": "Kopiago Airport",
        "City": "Kopiago",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3815",
        "AirportCode": "KPB",
        "AirportName": "Point Baker Sea Plane Base",
        "City": "Point Baker",
        "Country": "USA Alaska"
    },
    {
        "id": "3816",
        "AirportCode": "KPC",
        "AirportName": "Port Clarence Airport",
        "City": "Port Clarence",
        "Country": "USA Alaska"
    },
    {
        "id": "3817",
        "AirportCode": "KPD",
        "AirportName": "King Of Prussia Airport",
        "City": "King of Prussia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "3818",
        "AirportCode": "KPE",
        "AirportName": "Yapsei Airport",
        "City": "Yapsei",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3819",
        "AirportCode": "KPF",
        "AirportName": "Kondubol Airport",
        "City": "Kondubol",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3820",
        "AirportCode": "KPG",
        "AirportName": "Kurupung Airport",
        "City": "Kurupung",
        "Country": "Guyana"
    },
    {
        "id": "3821",
        "AirportCode": "KPI",
        "AirportName": "Kapit Airport",
        "City": "Kapit",
        "Country": "Malaysia"
    },
    {
        "id": "3822",
        "AirportCode": "KPL",
        "AirportName": "Kapal Airport",
        "City": "Kapal",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3823",
        "AirportCode": "KPM",
        "AirportName": "Kompiam Airport",
        "City": "Kompiam",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3824",
        "AirportCode": "KPN",
        "AirportName": "Kipnuk Sea Plane Base",
        "City": "Kipnuk",
        "Country": "USA Alaska"
    },
    {
        "id": "3825",
        "AirportCode": "KPO",
        "AirportName": "Pohang Airport",
        "City": "Pohang",
        "Country": "Korea"
    },
    {
        "id": "3826",
        "AirportCode": "KPP",
        "AirportName": "Kalpowar Airport",
        "City": "Kalpowar",
        "Country": "Australia"
    },
    {
        "id": "3827",
        "AirportCode": "KPR",
        "AirportName": "Port Williams Sea Plane Base",
        "City": "Port Williams",
        "Country": "USA Alaska"
    },
    {
        "id": "3828",
        "AirportCode": "KPS",
        "AirportName": "Kempsey Airport",
        "City": "Kempsey",
        "Country": "Australia"
    },
    {
        "id": "3829",
        "AirportCode": "KPT",
        "AirportName": "Jackpot Airport",
        "City": "Jackpot",
        "Country": "USA Nevada"
    },
    {
        "id": "3830",
        "AirportCode": "KPV",
        "AirportName": "Perryville Sea Plane Base",
        "City": "Perryville",
        "Country": "USA Alaska"
    },
    {
        "id": "3831",
        "AirportCode": "KPY",
        "AirportName": "Port Bailey Sea Plane Base",
        "City": "Port Bailey",
        "Country": "USA Alaska"
    },
    {
        "id": "3832",
        "AirportCode": "KQA",
        "AirportName": "Akutan Airport",
        "City": "Akutan",
        "Country": "USA Alaska"
    },
    {
        "id": "3833",
        "AirportCode": "KQB",
        "AirportName": "Koonibba Airport",
        "City": "Koonibba",
        "Country": "Australia"
    },
    {
        "id": "3834",
        "AirportCode": "KQL",
        "AirportName": "Kol Airport",
        "City": "Kol",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3835",
        "AirportCode": "KRA",
        "AirportName": "Kerang Airport",
        "City": "Kerang",
        "Country": "Australia"
    },
    {
        "id": "3836",
        "AirportCode": "KRB",
        "AirportName": "Karumba Airport",
        "City": "Karumba",
        "Country": "Australia"
    },
    {
        "id": "3837",
        "AirportCode": "KRC",
        "AirportName": "Kerinci Airport",
        "City": "Kerinci",
        "Country": "Indonesia"
    },
    {
        "id": "3838",
        "AirportCode": "KRD",
        "AirportName": "Kurundi Airport",
        "City": "Kurundi",
        "Country": "Australia"
    },
    {
        "id": "3839",
        "AirportCode": "KRE",
        "AirportName": "Kirundo Airport",
        "City": "Kirundo",
        "Country": "Burundi"
    },
    {
        "id": "3840",
        "AirportCode": "KRF",
        "AirportName": "Kramfors Airport",
        "City": "Kramfors",
        "Country": "Sweden"
    },
    {
        "id": "3841",
        "AirportCode": "KRG",
        "AirportName": "Karasabai Airport",
        "City": "Karasabai",
        "Country": "Guyana"
    },
    {
        "id": "3842",
        "AirportCode": "KRH",
        "AirportName": "Redhill",
        "City": "Redhill",
        "Country": "United Kingdom"
    },
    {
        "id": "3843",
        "AirportCode": "KRI",
        "AirportName": "Kikori Airport",
        "City": "Kikori",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3844",
        "AirportCode": "KRJ",
        "AirportName": "Karawari Airport",
        "City": "Karawari",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3845",
        "AirportCode": "KRK",
        "AirportName": "J. Paul II Balice International Airport",
        "City": "Krakow",
        "Country": "Poland"
    },
    {
        "id": "3846",
        "AirportCode": "KRL",
        "AirportName": "Korla Airport",
        "City": "Korla",
        "Country": "China"
    },
    {
        "id": "3847",
        "AirportCode": "KRM",
        "AirportName": "Karanambo Airport",
        "City": "Karanambo",
        "Country": "Guyana"
    },
    {
        "id": "3848",
        "AirportCode": "KRN",
        "AirportName": "Kiruna Airport",
        "City": "Kiruna",
        "Country": "Sweden"
    },
    {
        "id": "3849",
        "AirportCode": "KRO",
        "AirportName": "Kurgan Airport",
        "City": "Kurgan",
        "Country": "Russia"
    },
    {
        "id": "3850",
        "AirportCode": "KRP",
        "AirportName": "Karup Airport",
        "City": "Karup",
        "Country": "Denmark"
    },
    {
        "id": "3851",
        "AirportCode": "KRQ",
        "AirportName": "Kramatorsk Airport",
        "City": "Kramatorsk",
        "Country": "Ukraine"
    },
    {
        "id": "3852",
        "AirportCode": "KRR",
        "AirportName": "Krasnodar Airport",
        "City": "Krasnodar",
        "Country": "Russia"
    },
    {
        "id": "3853",
        "AirportCode": "KRS",
        "AirportName": "Kjevik Airport",
        "City": "Kristiansand",
        "Country": "Norway"
    },
    {
        "id": "3854",
        "AirportCode": "KRT",
        "AirportName": "Civil Airport",
        "City": "Khartoum (KRT)",
        "Country": "Sudan"
    },
    {
        "id": "3855",
        "AirportCode": "KRU",
        "AirportName": "Kerau Airport",
        "City": "Kerau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3856",
        "AirportCode": "KRV",
        "AirportName": "Kerio Valley Airport",
        "City": "Kerio Valley",
        "Country": "Kenya"
    },
    {
        "id": "3857",
        "AirportCode": "KRW",
        "AirportName": "Turkmanbashi Airport",
        "City": "Turkmanbashi",
        "Country": "Turkmenistan"
    },
    {
        "id": "3858",
        "AirportCode": "KRX",
        "AirportName": "Kar Kar Airport",
        "City": "Kar Kar",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3859",
        "AirportCode": "KRY",
        "AirportName": "Karamay Airport",
        "City": "Karamay",
        "Country": "China"
    },
    {
        "id": "3860",
        "AirportCode": "KRZ",
        "AirportName": "Kiri Airport",
        "City": "Kiri",
        "Country": "Congo, DR"
    },
    {
        "id": "3861",
        "AirportCode": "KSA",
        "AirportName": "Kosrae Airport",
        "City": "Kosrae, Caroline Islands",
        "Country": "Micronesia"
    },
    {
        "id": "3862",
        "AirportCode": "KSB",
        "AirportName": "Kasanombe Airport",
        "City": "Kasanombe",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3863",
        "AirportCode": "KSC",
        "AirportName": "Barca Airport",
        "City": "Kosice",
        "Country": "Slovakia"
    },
    {
        "id": "3864",
        "AirportCode": "KSD",
        "AirportName": "Karlstad Airport",
        "City": "Karlstad",
        "Country": "Sweden"
    },
    {
        "id": "3865",
        "AirportCode": "KSE",
        "AirportName": "Kasese Airport",
        "City": "Kasese",
        "Country": "Uganda"
    },
    {
        "id": "3866",
        "AirportCode": "KSF",
        "AirportName": "Kassel-Calden Airport",
        "City": "Kassel",
        "Country": "Germany"
    },
    {
        "id": "3867",
        "AirportCode": "KSG",
        "AirportName": "Kisengan Airport",
        "City": "Kisengan",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3868",
        "AirportCode": "KSH",
        "AirportName": "Kermanshah Airport",
        "City": "Kermanshah",
        "Country": "Iran"
    },
    {
        "id": "3869",
        "AirportCode": "KSI",
        "AirportName": "Kissidougou Airport",
        "City": "Kissidougou",
        "Country": "Guinea"
    },
    {
        "id": "3870",
        "AirportCode": "KSJ",
        "AirportName": "Kasos Island Airport",
        "City": "Kasos Island",
        "Country": "Greece"
    },
    {
        "id": "3871",
        "AirportCode": "KSK",
        "AirportName": "Karlskoga Airport",
        "City": "Karlskoga",
        "Country": "Sweden"
    },
    {
        "id": "3872",
        "AirportCode": "KSL",
        "AirportName": "Kassala Airport",
        "City": "Kassala",
        "Country": "Sudan"
    },
    {
        "id": "3873",
        "AirportCode": "KSN",
        "AirportName": "Kostanay Airport",
        "City": "Kostanay",
        "Country": "Kazakhstan"
    },
    {
        "id": "3874",
        "AirportCode": "KSO",
        "AirportName": "Aristoteles Airport",
        "City": "Kastoria",
        "Country": "Greece"
    },
    {
        "id": "3875",
        "AirportCode": "KSP",
        "AirportName": "Kosipe Airport",
        "City": "Kosipe",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3876",
        "AirportCode": "KSQ",
        "AirportName": "Karshi Airport",
        "City": "Karshi",
        "Country": "Uzbekistan"
    },
    {
        "id": "3877",
        "AirportCode": "KSR",
        "AirportName": "Sandy River Airpor",
        "City": "Sandy River",
        "Country": "USA Alaska"
    },
    {
        "id": "3878",
        "AirportCode": "KSS",
        "AirportName": "Sikasso Airport",
        "City": "Sikasso",
        "Country": "Mali"
    },
    {
        "id": "3879",
        "AirportCode": "KST",
        "AirportName": "Kosti Airport",
        "City": "Kosti",
        "Country": "Sudan"
    },
    {
        "id": "3880",
        "AirportCode": "KSU",
        "AirportName": "Kvernberget Airport",
        "City": "Kristiansund",
        "Country": "Norway"
    },
    {
        "id": "3881",
        "AirportCode": "KSV",
        "AirportName": "Springvale Airport",
        "City": "Springvale",
        "Country": "Australia"
    },
    {
        "id": "3882",
        "AirportCode": "KSX",
        "AirportName": "Yasuru Airport",
        "City": "Yasuru",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3883",
        "AirportCode": "KSY",
        "AirportName": "Kars Airport",
        "City": "Kars",
        "Country": "Turkey"
    },
    {
        "id": "3884",
        "AirportCode": "KSZ",
        "AirportName": "Kotlas Airport",
        "City": "Kotlas",
        "Country": "Russia"
    },
    {
        "id": "3885",
        "AirportCode": "KTA",
        "AirportName": "Karratha Airport",
        "City": "Karratha",
        "Country": "Australia"
    },
    {
        "id": "3886",
        "AirportCode": "KTB",
        "AirportName": "Thorne Bay Airport",
        "City": "Thorne Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3887",
        "AirportCode": "KTD",
        "AirportName": "Kitadaito Airport",
        "City": "Kitadaito",
        "Country": "Japan"
    },
    {
        "id": "3888",
        "AirportCode": "KTE",
        "AirportName": "Kerteh Airport",
        "City": "Kerteh",
        "Country": "Malaysia"
    },
    {
        "id": "3889",
        "AirportCode": "KTF",
        "AirportName": "Takaka Airport",
        "City": "Takaka",
        "Country": "New Zealand"
    },
    {
        "id": "3890",
        "AirportCode": "KTG",
        "AirportName": "Ketapang Airport",
        "City": "Ketapang",
        "Country": "Indonesia"
    },
    {
        "id": "3891",
        "AirportCode": "KTI",
        "AirportName": "Kratie Airport",
        "City": "Kratie",
        "Country": "Cambodia"
    },
    {
        "id": "3892",
        "AirportCode": "KTK",
        "AirportName": "Kanua Airport",
        "City": "Kanua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3893",
        "AirportCode": "KTL",
        "AirportName": "Kitale Airport",
        "City": "Kitale",
        "Country": "Kenya"
    },
    {
        "id": "3894",
        "AirportCode": "KTM",
        "AirportName": "Tribhuvan Airport",
        "City": "Kathmandu",
        "Country": "Nepal"
    },
    {
        "id": "3895",
        "AirportCode": "KTN",
        "AirportName": "Ketchikan International Airport",
        "City": "Ketchikan",
        "Country": "USA Alaska"
    },
    {
        "id": "3896",
        "AirportCode": "KTO",
        "AirportName": "Kato Airport",
        "City": "Kato",
        "Country": "Guyana"
    },
    {
        "id": "3897",
        "AirportCode": "KTP",
        "AirportName": "Tinson Airport",
        "City": "Kingston",
        "Country": "Jamaica"
    },
    {
        "id": "3898",
        "AirportCode": "KTQ",
        "AirportName": "Kitee Airport",
        "City": "Kitee",
        "Country": "Finland"
    },
    {
        "id": "3899",
        "AirportCode": "KTR",
        "AirportName": "Tindal Airport",
        "City": "Katherine",
        "Country": "Australia"
    },
    {
        "id": "3900",
        "AirportCode": "KTS",
        "AirportName": "Brevig Mission Airport",
        "City": "Teller Mission",
        "Country": "USA Alaska"
    },
    {
        "id": "3901",
        "AirportCode": "KTT",
        "AirportName": "Kittila Airport",
        "City": "Kittila",
        "Country": "Finland"
    },
    {
        "id": "3902",
        "AirportCode": "KTU",
        "AirportName": "Kota Airport",
        "City": "Kota",
        "Country": "India"
    },
    {
        "id": "3903",
        "AirportCode": "KTV",
        "AirportName": "Kamarata Airport",
        "City": "Kamarata",
        "Country": "Venezuela"
    },
    {
        "id": "3904",
        "AirportCode": "KTW",
        "AirportName": "Pyrzowice Airport",
        "City": "Katowice",
        "Country": "Poland"
    },
    {
        "id": "3905",
        "AirportCode": "KTX",
        "AirportName": "Koutiala Airport",
        "City": "Koutiala",
        "Country": "Mali"
    },
    {
        "id": "3906",
        "AirportCode": "KUA",
        "AirportName": "Kuantan Airport",
        "City": "Kuantan",
        "Country": "Malaysia"
    },
    {
        "id": "3907",
        "AirportCode": "KUC",
        "AirportName": "Kuria Airport",
        "City": "Kuria",
        "Country": "Kiribati"
    },
    {
        "id": "3908",
        "AirportCode": "KUD",
        "AirportName": "Kudat Airport",
        "City": "Kudat",
        "Country": "Malaysia"
    },
    {
        "id": "3909",
        "AirportCode": "KUF",
        "AirportName": "Samara Airport",
        "City": "Samara",
        "Country": "Russia"
    },
    {
        "id": "3910",
        "AirportCode": "KUG",
        "AirportName": "Kubin Island Airport",
        "City": "Kubin Island",
        "Country": "Australia"
    },
    {
        "id": "3911",
        "AirportCode": "KUH",
        "AirportName": "Kushiro Airport",
        "City": "Kushiro",
        "Country": "Japan"
    },
    {
        "id": "3912",
        "AirportCode": "KUI",
        "AirportName": "Kawau Island Airport",
        "City": "Kawau Island",
        "Country": "New Zealand"
    },
    {
        "id": "3913",
        "AirportCode": "KUJ",
        "AirportName": "Kushimoto Airport",
        "City": "Kushimoto",
        "Country": "Japan"
    },
    {
        "id": "3914",
        "AirportCode": "KUK",
        "AirportName": "Kasigluk Airport",
        "City": "Kasigluk",
        "Country": "USA Alaska"
    },
    {
        "id": "3915",
        "AirportCode": "KUL",
        "AirportName": "Lumpur International Airport",
        "City": "Kuala Lumpur (KUL)",
        "Country": "Malaysia"
    },
    {
        "id": "3916",
        "AirportCode": "KUM",
        "AirportName": "Yakushima Airport",
        "City": "Yakushima",
        "Country": "Japan"
    },
    {
        "id": "3917",
        "AirportCode": "KUN",
        "AirportName": "Kaunas Airport",
        "City": "Kaunas",
        "Country": "Lithuania"
    },
    {
        "id": "3918",
        "AirportCode": "KUO",
        "AirportName": "Kuopio Airport",
        "City": "Kuopio",
        "Country": "Finland"
    },
    {
        "id": "3919",
        "AirportCode": "KUP",
        "AirportName": "Kupiano Airport",
        "City": "Kupiano",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3920",
        "AirportCode": "KUQ",
        "AirportName": "Kuri Airport",
        "City": "Kuri",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3921",
        "AirportCode": "KUR",
        "AirportName": "Kuran-O-Munjan Airport",
        "City": "Kuran-O-Munjan",
        "Country": "Afghanistan"
    },
    {
        "id": "3922",
        "AirportCode": "KUS",
        "AirportName": "Kulusuk Airport",
        "City": "Kulusuk",
        "Country": "Greenland"
    },
    {
        "id": "3923",
        "AirportCode": "KUT",
        "AirportName": "Kutaisi Airport",
        "City": "Kutaisi",
        "Country": "Georgia"
    },
    {
        "id": "3924",
        "AirportCode": "KUU",
        "AirportName": "Bhuntar Airport",
        "City": "Kulu",
        "Country": "India"
    },
    {
        "id": "3925",
        "AirportCode": "KUV",
        "AirportName": "Gunsan Airport",
        "City": "Gunsan",
        "Country": "Korea"
    },
    {
        "id": "3926",
        "AirportCode": "KUX",
        "AirportName": "Kuyol Airport",
        "City": "Kuyol",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3927",
        "AirportCode": "KUY",
        "AirportName": "Kamusi Airport",
        "City": "Kamusi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3928",
        "AirportCode": "KUZ",
        "AirportName": "Gunsan Air Base",
        "City": "Gunsan",
        "Country": "Korea"
    },
    {
        "id": "3929",
        "AirportCode": "KVA",
        "AirportName": "Megas Alexandros Airport",
        "City": "Kavala",
        "Country": "Greece"
    },
    {
        "id": "3930",
        "AirportCode": "KVB",
        "AirportName": "Skovde Airport",
        "City": "Skovde",
        "Country": "Sweden"
    },
    {
        "id": "3931",
        "AirportCode": "KVC",
        "AirportName": "King Cove Airport",
        "City": "King Cove",
        "Country": "USA Alaska"
    },
    {
        "id": "3932",
        "AirportCode": "KVD",
        "AirportName": "Gyandzha Airport",
        "City": "Gyandzha",
        "Country": "Azerbaijan"
    },
    {
        "id": "3933",
        "AirportCode": "KVE",
        "AirportName": "Kitava Airport",
        "City": "Kitava",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3934",
        "AirportCode": "KVG",
        "AirportName": "Kavieng Airport",
        "City": "Kavieng",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3935",
        "AirportCode": "KVK",
        "AirportName": "Kirovsk Airport",
        "City": "Kirovsk",
        "Country": "Russia"
    },
    {
        "id": "3936",
        "AirportCode": "KVL",
        "AirportName": "Kivalina Airport",
        "City": "Kivalina",
        "Country": "USA Alaska"
    },
    {
        "id": "3937",
        "AirportCode": "KVU",
        "AirportName": "Korolevu Airport",
        "City": "Korolevu",
        "Country": "Fiji"
    },
    {
        "id": "3938",
        "AirportCode": "KVX",
        "AirportName": "Kirov Airport",
        "City": "Kirov",
        "Country": "Russia"
    },
    {
        "id": "3939",
        "AirportCode": "KWA",
        "AirportName": "Kwajalein Airport",
        "City": "Kwajalein",
        "Country": "Marshall Islands"
    },
    {
        "id": "3940",
        "AirportCode": "KWB",
        "AirportName": "Karimunjawa Airport",
        "City": "Karimunjawa",
        "Country": "Indonesia"
    },
    {
        "id": "3941",
        "AirportCode": "KWE",
        "AirportName": "Guiyang Airport",
        "City": "Guiyang",
        "Country": "China"
    },
    {
        "id": "3942",
        "AirportCode": "KWF",
        "AirportName": "Waterfall Sea Plane Base",
        "City": "Waterfall",
        "Country": "USA Alaska"
    },
    {
        "id": "3943",
        "AirportCode": "KWG",
        "AirportName": "Krivoy Rog Airport",
        "City": "Krivoy Rog",
        "Country": "Ukraine"
    },
    {
        "id": "3944",
        "AirportCode": "KWH",
        "AirportName": "Khwahan Airport",
        "City": "Khwahan",
        "Country": "Afghanistan"
    },
    {
        "id": "3945",
        "AirportCode": "KWI",
        "AirportName": "Kuwait International Airport",
        "City": "Kuwait",
        "Country": "Kuwait"
    },
    {
        "id": "3946",
        "AirportCode": "KWJ",
        "AirportName": "Gwangju Airport",
        "City": "Gwangju",
        "Country": "Korea"
    },
    {
        "id": "3947",
        "AirportCode": "KWK",
        "AirportName": "Kwigillingok Airport",
        "City": "Kwigillingok",
        "Country": "USA Alaska"
    },
    {
        "id": "3948",
        "AirportCode": "KWL",
        "AirportName": "Guilin Airport",
        "City": "Guilin",
        "Country": "China"
    },
    {
        "id": "3949",
        "AirportCode": "KWM",
        "AirportName": "Kowanyama Airport",
        "City": "Kowanyama",
        "Country": "Australia"
    },
    {
        "id": "3950",
        "AirportCode": "KWN",
        "AirportName": "Kwinhagak Airport",
        "City": "Quinhagak",
        "Country": "USA Alaska"
    },
    {
        "id": "3951",
        "AirportCode": "KWO",
        "AirportName": "Kawito Airport",
        "City": "Kawito",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3952",
        "AirportCode": "KWP",
        "AirportName": "Village Sea Plane Base",
        "City": "West Point",
        "Country": "USA Alaska"
    },
    {
        "id": "3953",
        "AirportCode": "KWT",
        "AirportName": "Kwethluk Airport",
        "City": "Kwethluk",
        "Country": "USA Alaska"
    },
    {
        "id": "3954",
        "AirportCode": "KWV",
        "AirportName": "Kurwina Airport",
        "City": "Kurwina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3955",
        "AirportCode": "KWX",
        "AirportName": "Kiwai Island Airport",
        "City": "Kiwai Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3956",
        "AirportCode": "KWY",
        "AirportName": "Kiwayu Airport",
        "City": "Kiwayu",
        "Country": "Kenya"
    },
    {
        "id": "3957",
        "AirportCode": "KWZ",
        "AirportName": "Kolwezi Airport",
        "City": "Kolwezi",
        "Country": "Congo, DR"
    },
    {
        "id": "3958",
        "AirportCode": "KXA",
        "AirportName": "Kasaan Sea Plane Base",
        "City": "Kasaan",
        "Country": "USA Alaska"
    },
    {
        "id": "3959",
        "AirportCode": "KXE",
        "AirportName": "Klerksdorp Airport",
        "City": "Klerksdorp",
        "Country": "South Africa"
    },
    {
        "id": "3960",
        "AirportCode": "KXF",
        "AirportName": "Koro Airport",
        "City": "Koro",
        "Country": "Fiji"
    },
    {
        "id": "3961",
        "AirportCode": "KXK",
        "AirportName": "Komsomolsk Na Amure Airport",
        "City": "Komsomolsk Na Amure",
        "Country": "Russia"
    },
    {
        "id": "3962",
        "AirportCode": "KXR",
        "AirportName": "Karoola Airport",
        "City": "Karoola",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3963",
        "AirportCode": "KXU",
        "AirportName": "Katiu Airport",
        "City": "Katiu",
        "Country": "French Polynesia"
    },
    {
        "id": "3964",
        "AirportCode": "KYA",
        "AirportName": "Konya Airport",
        "City": "Konya",
        "Country": "Turkey"
    },
    {
        "id": "3965",
        "AirportCode": "KYD",
        "AirportName": "Orchid Island Airport",
        "City": "Orchid Island",
        "Country": "Taiwan"
    },
    {
        "id": "3966",
        "AirportCode": "KYE",
        "AirportName": "Kleyate Airport",
        "City": "Tripoli (TIP)",
        "Country": "Liberia"
    },
    {
        "id": "3967",
        "AirportCode": "KYF",
        "AirportName": "Yeelirrie Airport",
        "City": "Yeelirrie",
        "Country": "Australia"
    },
    {
        "id": "3968",
        "AirportCode": "KYI",
        "AirportName": "Yalata Mission Airport",
        "City": "Yalata Mission",
        "Country": "Australia"
    },
    {
        "id": "3969",
        "AirportCode": "KYK",
        "AirportName": "Karluk Airport",
        "City": "Karluk",
        "Country": "USA Alaska"
    },
    {
        "id": "3970",
        "AirportCode": "KYL",
        "AirportName": "Port Largo Airport",
        "City": "Key Largo",
        "Country": "USA Florida"
    },
    {
        "id": "3971",
        "AirportCode": "KYN",
        "AirportName": "Milton Keynes Airport",
        "City": "Milton Keynes",
        "Country": "United Kingdom"
    },
    {
        "id": "3972",
        "AirportCode": "KYO",
        "AirportName": "Topp of Tampa Airport",
        "City": "Tampa",
        "Country": "USA Florida"
    },
    {
        "id": "3973",
        "AirportCode": "KYP",
        "AirportName": "Kyaukpyu Airport",
        "City": "Kyaukpyu",
        "Country": "Burma"
    },
    {
        "id": "3974",
        "AirportCode": "KYS",
        "AirportName": "Kayes Airport",
        "City": "Kayes",
        "Country": "Mali"
    },
    {
        "id": "3975",
        "AirportCode": "KYT",
        "AirportName": "Kyauktaw Airport",
        "City": "Kyauktaw",
        "Country": "Burma"
    },
    {
        "id": "3976",
        "AirportCode": "KYU",
        "AirportName": "Koyukuk Airport",
        "City": "Koyukuk",
        "Country": "USA Alaska"
    },
    {
        "id": "3977",
        "AirportCode": "KYX",
        "AirportName": "Yalumet Airport",
        "City": "Yalumet",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3978",
        "AirportCode": "KYZ",
        "AirportName": "Kyzyl Airport",
        "City": "Kyzyl",
        "Country": "Russia"
    },
    {
        "id": "3979",
        "AirportCode": "KZB",
        "AirportName": "Zachar Bay Sea Plane Base",
        "City": "Zachar Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "3980",
        "AirportCode": "KZC",
        "AirportName": "Kompong-Chhna Airport",
        "City": "Kompong-Chhna",
        "Country": "Cambodia"
    },
    {
        "id": "3981",
        "AirportCode": "KZD",
        "AirportName": "Krakor Airport",
        "City": "Krakor",
        "Country": "Cambodia"
    },
    {
        "id": "3982",
        "AirportCode": "KZF",
        "AirportName": "Kaintiba Airport",
        "City": "Kaintiba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3983",
        "AirportCode": "KZG",
        "AirportName": "Kitzingen Airport",
        "City": "Kitzingen",
        "Country": "Germany"
    },
    {
        "id": "3984",
        "AirportCode": "KZI",
        "AirportName": "Philippos Airport",
        "City": "Kozani",
        "Country": "Greece"
    },
    {
        "id": "3985",
        "AirportCode": "KZK",
        "AirportName": "Kompong Thom Airport",
        "City": "Kompong Thom",
        "Country": "Cambodia"
    },
    {
        "id": "3986",
        "AirportCode": "KZN",
        "AirportName": "Kazan Airport",
        "City": "Kazan",
        "Country": "Russia"
    },
    {
        "id": "3987",
        "AirportCode": "KZO",
        "AirportName": "Kzyl-Orda Airport",
        "City": "Kzyl-Orda",
        "Country": "Kazakhstan"
    },
    {
        "id": "3988",
        "AirportCode": "KZS",
        "AirportName": "Kastelorizo Airport",
        "City": "Kastelorizo",
        "Country": "Greece"
    },
    {
        "id": "3989",
        "AirportCode": "LAA",
        "AirportName": "Lamar Field",
        "City": "Lamar",
        "Country": "USA Colorado"
    },
    {
        "id": "3990",
        "AirportCode": "LAB",
        "AirportName": "Lablab Airport",
        "City": "Lablab",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3991",
        "AirportCode": "LAC",
        "AirportName": "Layang-Layang Airstrip",
        "City": "Pulau Layang-Layang",
        "Country": "Malaysia"
    },
    {
        "id": "3992",
        "AirportCode": "LAD",
        "AirportName": "4 de Fevereiro Airport",
        "City": "Luanda",
        "Country": "Angola"
    },
    {
        "id": "3993",
        "AirportCode": "LAE",
        "AirportName": "Nadzab Airport",
        "City": "Lae",
        "Country": "Papua New Guinea"
    },
    {
        "id": "3994",
        "AirportCode": "LAF",
        "AirportName": "Purdue University Airport",
        "City": "Lafayette",
        "Country": "USA Indiana"
    },
    {
        "id": "3995",
        "AirportCode": "LAG",
        "AirportName": "La Guaira Airport",
        "City": "La Guaira",
        "Country": "Venezuela"
    },
    {
        "id": "3996",
        "AirportCode": "LAH",
        "AirportName": "Labuha Airport",
        "City": "Labuha",
        "Country": "Indonesia"
    },
    {
        "id": "3997",
        "AirportCode": "LAI",
        "AirportName": "Servel Airport",
        "City": "Lannion",
        "Country": "France"
    },
    {
        "id": "3998",
        "AirportCode": "LAJ",
        "AirportName": "Lages Airport",
        "City": "Lages",
        "Country": "Brazil"
    },
    {
        "id": "3999",
        "AirportCode": "LAK",
        "AirportName": "Aklavik Airport",
        "City": "Aklavik",
        "Country": "Canada"
    },
    {
        "id": "4000",
        "AirportCode": "LAL",
        "AirportName": "Lakeland Municipal Airport",
        "City": "Lakeland",
        "Country": "USA Florida"
    },
    {
        "id": "4001",
        "AirportCode": "LAM",
        "AirportName": "Los Alamos Airport",
        "City": "Los Alamos",
        "Country": "USA New Mexico"
    },
    {
        "id": "4002",
        "AirportCode": "LAN",
        "AirportName": "Capital City Airport",
        "City": "Lansing",
        "Country": "USA Michigan"
    },
    {
        "id": "4003",
        "AirportCode": "LAO",
        "AirportName": "Laoag Airport",
        "City": "Laoag",
        "Country": "Philippines"
    },
    {
        "id": "4004",
        "AirportCode": "LAP",
        "AirportName": "Leon Airport",
        "City": "La Paz",
        "Country": "Mexico"
    },
    {
        "id": "4005",
        "AirportCode": "LAQ",
        "AirportName": "La Braq Airport",
        "City": "Beida",
        "Country": "Libya"
    },
    {
        "id": "4006",
        "AirportCode": "LAR",
        "AirportName": "General Brees Field",
        "City": "Laramie",
        "Country": "USA Wyoming"
    },
    {
        "id": "4007",
        "AirportCode": "LAS",
        "AirportName": "McCarran Airport ",
        "City": "Las Vegas ",
        "Country": "USA NV "
    },
    {
        "id": "4008",
        "AirportCode": "LAU",
        "AirportName": "Lamu Airport",
        "City": "Lamu",
        "Country": "Kenya"
    },
    {
        "id": "4009",
        "AirportCode": "LAW",
        "AirportName": "Lawton Municipal Airport",
        "City": "Lawton",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4010",
        "AirportCode": "LAX",
        "AirportName": "Los Angeles International Airport",
        "City": "Los Angeles All Airports",
        "Country": "USA California"
    },
    {
        "id": "4011",
        "AirportCode": "LAY",
        "AirportName": "Ladysmith Airport",
        "City": "Ladysmith",
        "Country": "South Africa"
    },
    {
        "id": "4012",
        "AirportCode": "LAZ",
        "AirportName": "Bom Jesus Da Lapa Airport",
        "City": "Bom Jesus Da Lapa",
        "Country": "Brazil"
    },
    {
        "id": "4013",
        "AirportCode": "LBA",
        "AirportName": "Leeds/Bradford Airport",
        "City": "Leeds",
        "Country": "United Kingdom"
    },
    {
        "id": "4014",
        "AirportCode": "LBB",
        "AirportName": "Lubbock International Airport",
        "City": "Lubbock",
        "Country": "USA Texas"
    },
    {
        "id": "4015",
        "AirportCode": "LBC",
        "AirportName": "Blankensee Airport",
        "City": "Hamburg (HAM)",
        "Country": "Germany"
    },
    {
        "id": "4016",
        "AirportCode": "LBD",
        "AirportName": "Khudzhand Airport",
        "City": "Khudzhand",
        "Country": "Tajikistan"
    },
    {
        "id": "4017",
        "AirportCode": "LBE",
        "AirportName": "Westmoreland County Airport",
        "City": "Latrobe",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4018",
        "AirportCode": "LBF",
        "AirportName": "Lee Bird Field",
        "City": "North Platte",
        "Country": "USA Nebraska"
    },
    {
        "id": "4019",
        "AirportCode": "LBG",
        "AirportName": "Le Bourget Airport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "4020",
        "AirportCode": "LBH",
        "AirportName": "Palm Beach Sea Plane Base",
        "City": "Sydney",
        "Country": "Australia"
    },
    {
        "id": "4021",
        "AirportCode": "LBI",
        "AirportName": "Le Sequestre Airport",
        "City": "Albi",
        "Country": "France"
    },
    {
        "id": "4022",
        "AirportCode": "LBJ",
        "AirportName": "Mutiara Airport",
        "City": "Labuan Bajo",
        "Country": "Indonesia"
    },
    {
        "id": "4023",
        "AirportCode": "LBK",
        "AirportName": "Liboi Airport",
        "City": "Liboi",
        "Country": "Kenya"
    },
    {
        "id": "4024",
        "AirportCode": "LBL",
        "AirportName": "Liberal Municipal Airport",
        "City": "Liberal",
        "Country": "USA Kansas"
    },
    {
        "id": "4025",
        "AirportCode": "LBM",
        "AirportName": "Luabo Airport",
        "City": "Luabo",
        "Country": "Mozambique"
    },
    {
        "id": "4026",
        "AirportCode": "LBN",
        "AirportName": "Lake Baringo Airport",
        "City": "Lake Baringo",
        "Country": "Kenya"
    },
    {
        "id": "4027",
        "AirportCode": "LBO",
        "AirportName": "Lusambo Airport",
        "City": "Lusambo",
        "Country": "Congo, DR"
    },
    {
        "id": "4028",
        "AirportCode": "LBP",
        "AirportName": "Long Banga Airfield",
        "City": "Long Banga",
        "Country": "Malaysia"
    },
    {
        "id": "4029",
        "AirportCode": "LBQ",
        "AirportName": "Lambarene Airport",
        "City": "Lambarene",
        "Country": "Gabon"
    },
    {
        "id": "4030",
        "AirportCode": "LBR",
        "AirportName": "Labrea Airport",
        "City": "Labrea",
        "Country": "Brazil"
    },
    {
        "id": "4031",
        "AirportCode": "LBS",
        "AirportName": "Labasa Airport",
        "City": "Labasa",
        "Country": "Fiji"
    },
    {
        "id": "4032",
        "AirportCode": "LBT",
        "AirportName": "Lumberton Airport",
        "City": "Lumberton",
        "Country": "USA North Carolina"
    },
    {
        "id": "4033",
        "AirportCode": "LBU",
        "AirportName": "Labuan Airport",
        "City": "Labuan",
        "Country": "Malaysia"
    },
    {
        "id": "4034",
        "AirportCode": "LBV",
        "AirportName": "Libreville Airport",
        "City": "Libreville",
        "Country": "Gabon"
    },
    {
        "id": "4035",
        "AirportCode": "LBW",
        "AirportName": "Long Bawan Airport",
        "City": "Long Bawan",
        "Country": "Indonesia"
    },
    {
        "id": "4036",
        "AirportCode": "LBX",
        "AirportName": "Lubang Airport",
        "City": "Lubang",
        "Country": "Philippines"
    },
    {
        "id": "4037",
        "AirportCode": "LBY",
        "AirportName": "Montoir Airport",
        "City": "La Baule",
        "Country": "France"
    },
    {
        "id": "4038",
        "AirportCode": "LCA",
        "AirportName": "Larnaca Airport",
        "City": "Larnaca",
        "Country": "Cyprus"
    },
    {
        "id": "4039",
        "AirportCode": "LCB",
        "AirportName": "Pontes-e-Lacerda Airport",
        "City": "Pontes-e-Lacerda",
        "Country": "Brazil"
    },
    {
        "id": "4040",
        "AirportCode": "LCC",
        "AirportName": "Galatina Airport",
        "City": "Lecce",
        "Country": "Italy"
    },
    {
        "id": "4041",
        "AirportCode": "LCD",
        "AirportName": "Louis Trichardt Airport",
        "City": "Louis Trichardt",
        "Country": "South Africa"
    },
    {
        "id": "4042",
        "AirportCode": "LCE",
        "AirportName": "Goloson International Airport",
        "City": "La Ceiba",
        "Country": "Honduras"
    },
    {
        "id": "4043",
        "AirportCode": "LCF",
        "AirportName": "Las Vegas Airport",
        "City": "Rio Dulce",
        "Country": "Guatemala"
    },
    {
        "id": "4044",
        "AirportCode": "LCG",
        "AirportName": "La Coruna Airport",
        "City": "La Coruna",
        "Country": "Spain"
    },
    {
        "id": "4045",
        "AirportCode": "LCH",
        "AirportName": "Lake Charles Municipal Airport",
        "City": "Lake Charles",
        "Country": "USA Louisiana"
    },
    {
        "id": "4046",
        "AirportCode": "LCI",
        "AirportName": "Laconia Municipal Airport",
        "City": "Laconia",
        "Country": "USA New Hampshire"
    },
    {
        "id": "4047",
        "AirportCode": "LCJ",
        "AirportName": "Lodz Lublinek Airport",
        "City": "Lodz",
        "Country": "Poland"
    },
    {
        "id": "4048",
        "AirportCode": "LCK",
        "AirportName": "Rickenbacker Airport",
        "City": "Columbus",
        "Country": "USA Ohio"
    },
    {
        "id": "4049",
        "AirportCode": "LCL",
        "AirportName": "La Coloma Airport",
        "City": "La Coloma",
        "Country": "Cuba"
    },
    {
        "id": "4050",
        "AirportCode": "LCM",
        "AirportName": "La Cumbre Airport",
        "City": "La Cumbre",
        "Country": "Argentina"
    },
    {
        "id": "4051",
        "AirportCode": "LCN",
        "AirportName": "Balcanoona Airport",
        "City": "Balcanoona",
        "Country": "Australia"
    },
    {
        "id": "4052",
        "AirportCode": "LCO",
        "AirportName": "Lague Airport",
        "City": "Lague",
        "Country": "Congo"
    },
    {
        "id": "4053",
        "AirportCode": "LCP",
        "AirportName": "Loncopue Airport",
        "City": "Loncopue",
        "Country": "Argentina"
    },
    {
        "id": "4054",
        "AirportCode": "LCR",
        "AirportName": "La Chorrera Airport",
        "City": "La Chorrera",
        "Country": "Colombia"
    },
    {
        "id": "4055",
        "AirportCode": "LCS",
        "AirportName": "Las Canas Airport",
        "City": "Las Canas",
        "Country": "Costa Rica"
    },
    {
        "id": "4056",
        "AirportCode": "LCV",
        "AirportName": "Lucca Airport",
        "City": "Lucca",
        "Country": "Italy"
    },
    {
        "id": "4057",
        "AirportCode": "LCX",
        "AirportName": "Liancheng Airport",
        "City": "Longyan",
        "Country": "China"
    },
    {
        "id": "4058",
        "AirportCode": "LCY",
        "AirportName": "London City Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "4059",
        "AirportCode": "LDA",
        "AirportName": "Malda Airport",
        "City": "Malda",
        "Country": "India"
    },
    {
        "id": "4060",
        "AirportCode": "LDB",
        "AirportName": "Londrina Airport",
        "City": "Londrina",
        "Country": "Brazil"
    },
    {
        "id": "4061",
        "AirportCode": "LDC",
        "AirportName": "Lindeman Island Airport",
        "City": "Lindeman Island",
        "Country": "Australia"
    },
    {
        "id": "4062",
        "AirportCode": "LDE",
        "AirportName": "Tarbes Ossun Lourdes Airport",
        "City": "Lourdes",
        "Country": "France"
    },
    {
        "id": "4063",
        "AirportCode": "LDG",
        "AirportName": "Leshukonskoye Airport",
        "City": "Leshukonskoye",
        "Country": "Russia"
    },
    {
        "id": "4064",
        "AirportCode": "LDH",
        "AirportName": "Lord Howe Island Airport",
        "City": "Lord Howe Island",
        "Country": "Australia"
    },
    {
        "id": "4065",
        "AirportCode": "LDI",
        "AirportName": "Kikwetu Airport",
        "City": "Lindi",
        "Country": "Tanzania"
    },
    {
        "id": "4066",
        "AirportCode": "LDJ",
        "AirportName": "Linden Airport",
        "City": "Linden",
        "Country": "USA New Jersey"
    },
    {
        "id": "4067",
        "AirportCode": "LDK",
        "AirportName": "Hovby Airport",
        "City": "Lidkoping",
        "Country": "Sweden"
    },
    {
        "id": "4068",
        "AirportCode": "LDM",
        "AirportName": "Mason Co",
        "City": "Ludington",
        "Country": "USA Michigan"
    },
    {
        "id": "4069",
        "AirportCode": "LDN",
        "AirportName": "Lamidanda Airport",
        "City": "Lamidanda",
        "Country": "Nepal"
    },
    {
        "id": "4070",
        "AirportCode": "LDO",
        "AirportName": "Ladouanie Airport",
        "City": "Ladouanie",
        "Country": "Suriname"
    },
    {
        "id": "4071",
        "AirportCode": "LDR",
        "AirportName": "Lodar Airport",
        "City": "Lodar",
        "Country": "Yemen"
    },
    {
        "id": "4072",
        "AirportCode": "LDU",
        "AirportName": "Lahad Datu Airport",
        "City": "Lahad Datu",
        "Country": "Malaysia"
    },
    {
        "id": "4073",
        "AirportCode": "LDV",
        "AirportName": "Landivisiau Airport",
        "City": "Landivisiau",
        "Country": "France"
    },
    {
        "id": "4074",
        "AirportCode": "LDW",
        "AirportName": "Lansdowne Airport",
        "City": "Lansdowne",
        "Country": "Australia"
    },
    {
        "id": "4075",
        "AirportCode": "LDX",
        "AirportName": "Saint Laurent du Maroni Airport",
        "City": "Saint Laurent du Maroni",
        "Country": "French Guiana"
    },
    {
        "id": "4076",
        "AirportCode": "LDY",
        "AirportName": "Eglinton Airport",
        "City": "London Derry",
        "Country": "United Kingdom"
    },
    {
        "id": "4077",
        "AirportCode": "LDZ",
        "AirportName": "Londolozi Airport",
        "City": "Londolozi",
        "Country": "South Africa"
    },
    {
        "id": "4078",
        "AirportCode": "LEA",
        "AirportName": "Learmonth Airport",
        "City": "Learmonth",
        "Country": "Australia"
    },
    {
        "id": "4079",
        "AirportCode": "LEB",
        "AirportName": "Lebanon Regional Airport",
        "City": "Lebanon",
        "Country": "USA New Hampshire"
    },
    {
        "id": "4080",
        "AirportCode": "LEC",
        "AirportName": "Chapada Diamantina Airport",
        "City": "Lencois",
        "Country": "Brazil"
    },
    {
        "id": "4081",
        "AirportCode": "LED",
        "AirportName": "Pulkovo Airport",
        "City": "Saint Petersburg",
        "Country": "Russia"
    },
    {
        "id": "4082",
        "AirportCode": "LEE",
        "AirportName": "Leesburg Airport",
        "City": "Leesburg",
        "Country": "USA Florida"
    },
    {
        "id": "4083",
        "AirportCode": "LEF",
        "AirportName": "Lebakeng Airport",
        "City": "Lebakeng",
        "Country": "Lesotho"
    },
    {
        "id": "4084",
        "AirportCode": "LEG",
        "AirportName": "Aleg Airport",
        "City": "Aleg",
        "Country": "Mauritania"
    },
    {
        "id": "4085",
        "AirportCode": "LEH",
        "AirportName": "Octeville Airport",
        "City": "Le Havre",
        "Country": "France"
    },
    {
        "id": "4086",
        "AirportCode": "LEI",
        "AirportName": "Almeria Airport",
        "City": "Almeria",
        "Country": "Spain"
    },
    {
        "id": "4087",
        "AirportCode": "LEJ",
        "AirportName": "Leipzig/Halle Airport",
        "City": "Leipzig/Halle",
        "Country": "Germany"
    },
    {
        "id": "4088",
        "AirportCode": "LEK",
        "AirportName": "Labe Airport",
        "City": "Labe",
        "Country": "Guinea"
    },
    {
        "id": "4089",
        "AirportCode": "LEL",
        "AirportName": "Lake Evella Airport",
        "City": "Lake Evella",
        "Country": "Australia"
    },
    {
        "id": "4090",
        "AirportCode": "LEM",
        "AirportName": "Lemmon Airport",
        "City": "Lemmon",
        "Country": "USA South Dakota"
    },
    {
        "id": "4091",
        "AirportCode": "LEN",
        "AirportName": "Leon Airport",
        "City": "Leon",
        "Country": "Spain"
    },
    {
        "id": "4092",
        "AirportCode": "LEO",
        "AirportName": "Leconi Airport",
        "City": "Leconi",
        "Country": "Gabon"
    },
    {
        "id": "4093",
        "AirportCode": "LEP",
        "AirportName": "Leopoldina Airport",
        "City": "Leopoldina",
        "Country": "Brazil"
    },
    {
        "id": "4094",
        "AirportCode": "LEQ",
        "AirportName": "Lands End Airport",
        "City": "Lands End",
        "Country": "United Kingdom"
    },
    {
        "id": "4095",
        "AirportCode": "LER",
        "AirportName": "Leinster Airport",
        "City": "Leinster",
        "Country": "Australia"
    },
    {
        "id": "4096",
        "AirportCode": "LES",
        "AirportName": "Lesobeng Airport",
        "City": "Lesobeng",
        "Country": "Lesotho"
    },
    {
        "id": "4097",
        "AirportCode": "LET",
        "AirportName": "Gen. A.V. Cobo Airport",
        "City": "Leticia",
        "Country": "Colombia"
    },
    {
        "id": "4098",
        "AirportCode": "LEU",
        "AirportName": "Aeroport De La Seu",
        "City": "Seo De Urgel",
        "Country": "Spain"
    },
    {
        "id": "4099",
        "AirportCode": "LEV",
        "AirportName": "Levuka Airfield",
        "City": "Bureta",
        "Country": "Fiji"
    },
    {
        "id": "4100",
        "AirportCode": "LEW",
        "AirportName": "Auburn Airport",
        "City": "Auburn",
        "Country": "USA Maine"
    },
    {
        "id": "4101",
        "AirportCode": "LEX",
        "AirportName": "Blue Grass Airport",
        "City": "Lexington",
        "Country": "USA Kentucky"
    },
    {
        "id": "4102",
        "AirportCode": "LEY",
        "AirportName": "Lelystad Airport",
        "City": "Lelystad",
        "Country": "Netherlands"
    },
    {
        "id": "4103",
        "AirportCode": "LEZ",
        "AirportName": "La Esperanza Airport",
        "City": "La Esperanza",
        "Country": "Honduras"
    },
    {
        "id": "4104",
        "AirportCode": "LFB",
        "AirportName": "Lumbo Airport",
        "City": "Nampula",
        "Country": "Mozambique"
    },
    {
        "id": "4105",
        "AirportCode": "LFI",
        "AirportName": "Langley Air Force Base",
        "City": "Hampton",
        "Country": "USA Virginia"
    },
    {
        "id": "4106",
        "AirportCode": "LFK",
        "AirportName": "Lufkin Angelina County Airport",
        "City": "Nacogdoches",
        "Country": "USA Texas"
    },
    {
        "id": "4107",
        "AirportCode": "LFM",
        "AirportName": "Lamerd Airport",
        "City": "Lamerd",
        "Country": "Iran"
    },
    {
        "id": "4108",
        "AirportCode": "LFN",
        "AirportName": "Franklin Airport",
        "City": "Louisburg",
        "Country": "USA North Carolina"
    },
    {
        "id": "4109",
        "AirportCode": "LFO",
        "AirportName": "Kelafo Airport",
        "City": "Kelafo/Callaf",
        "Country": "Ethiopia"
    },
    {
        "id": "4110",
        "AirportCode": "LFP",
        "AirportName": "Lakefield Airport",
        "City": "Lakefield",
        "Country": "Australia"
    },
    {
        "id": "4111",
        "AirportCode": "LFR",
        "AirportName": "La Fria Airport",
        "City": "La Fria",
        "Country": "Venezuela"
    },
    {
        "id": "4112",
        "AirportCode": "LFT",
        "AirportName": "Lafayette Regional Airport",
        "City": "Lafayette",
        "Country": "USA Louisiana"
    },
    {
        "id": "4113",
        "AirportCode": "LFW",
        "AirportName": "Lome Airport",
        "City": "Lome",
        "Country": "Togo"
    },
    {
        "id": "4114",
        "AirportCode": "LGA",
        "AirportName": "La Guardia Airport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "4115",
        "AirportCode": "LGB",
        "AirportName": "Long Beach Municipal Airport",
        "City": "Long Beach",
        "Country": "USA California"
    },
    {
        "id": "4116",
        "AirportCode": "LGC",
        "AirportName": "Calloway Airport",
        "City": "La Grange",
        "Country": "USA Georgia"
    },
    {
        "id": "4117",
        "AirportCode": "LGD",
        "AirportName": "La Grande Airport",
        "City": "La Grande",
        "Country": "USA Oregon"
    },
    {
        "id": "4118",
        "AirportCode": "LGE",
        "AirportName": "Lake Gregory Airport",
        "City": "Lake Gregory",
        "Country": "Australia"
    },
    {
        "id": "4119",
        "AirportCode": "LGF",
        "AirportName": "Laguna Army Air Field",
        "City": "Yuma",
        "Country": "USA Arizona"
    },
    {
        "id": "4120",
        "AirportCode": "LGG",
        "AirportName": "Bierset Airport",
        "City": "Liege",
        "Country": "Belgium"
    },
    {
        "id": "4121",
        "AirportCode": "LGH",
        "AirportName": "Leigh Creek Airport",
        "City": "Leigh Creek",
        "Country": "Australia"
    },
    {
        "id": "4122",
        "AirportCode": "LGI",
        "AirportName": "Deadmans Cay Airport",
        "City": "Deadmans Cay",
        "Country": "Bahamas"
    },
    {
        "id": "4123",
        "AirportCode": "LGK",
        "AirportName": "Langkawi Airport",
        "City": "Langkawi",
        "Country": "Malaysia"
    },
    {
        "id": "4124",
        "AirportCode": "LGL",
        "AirportName": "Long Lellang Airport",
        "City": "Long Lellang",
        "Country": "Malaysia"
    },
    {
        "id": "4125",
        "AirportCode": "LGM",
        "AirportName": "Laiagam Airport",
        "City": "Laiagam",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4126",
        "AirportCode": "LGN",
        "AirportName": "Linga Linga Airport",
        "City": "Linga Linga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4127",
        "AirportCode": "LGO",
        "AirportName": "Langeoog Airport",
        "City": "Langeoog",
        "Country": "Germany"
    },
    {
        "id": "4128",
        "AirportCode": "LGP",
        "AirportName": "Legaspi Airport",
        "City": "Legaspi",
        "Country": "Philippines"
    },
    {
        "id": "4129",
        "AirportCode": "LGQ",
        "AirportName": "Lago Agrio Airport",
        "City": "Lago Agrio",
        "Country": "Ecuador"
    },
    {
        "id": "4130",
        "AirportCode": "LGR",
        "AirportName": "Cochrane Airport",
        "City": "Cochrane",
        "Country": "Chile"
    },
    {
        "id": "4131",
        "AirportCode": "LGS",
        "AirportName": "Malargue Airport",
        "City": "Malargue",
        "Country": "Argentina"
    },
    {
        "id": "4132",
        "AirportCode": "LGT",
        "AirportName": "Las Gaviotas Airport",
        "City": "Las Gaviotas",
        "Country": "Colombia"
    },
    {
        "id": "4133",
        "AirportCode": "LGU",
        "AirportName": "Cache Airport",
        "City": "Logan",
        "Country": "USA Utah"
    },
    {
        "id": "4134",
        "AirportCode": "LGW",
        "AirportName": "Gatwick Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "4135",
        "AirportCode": "LGX",
        "AirportName": "Lugh Ganane Airport",
        "City": "Lugh Ganane",
        "Country": "Somalia"
    },
    {
        "id": "4136",
        "AirportCode": "LGY",
        "AirportName": "Lagunillas Airport",
        "City": "Lagunillas",
        "Country": "Venezuela"
    },
    {
        "id": "4137",
        "AirportCode": "LHA",
        "AirportName": "Lahr Airport",
        "City": "Lahr",
        "Country": "Germany"
    },
    {
        "id": "4138",
        "AirportCode": "LHE",
        "AirportName": "Lahore Airport",
        "City": "Lahore",
        "Country": "Pakistan"
    },
    {
        "id": "4139",
        "AirportCode": "LHG",
        "AirportName": "Lightning Ridge Airport",
        "City": "Lightning Ridge",
        "Country": "Australia"
    },
    {
        "id": "4140",
        "AirportCode": "LHI",
        "AirportName": "Lereh Airport",
        "City": "Lereh",
        "Country": "Indonesia"
    },
    {
        "id": "4141",
        "AirportCode": "LHK",
        "AirportName": "Guanghua Airport",
        "City": "Guanghua",
        "Country": "China"
    },
    {
        "id": "4142",
        "AirportCode": "LHN",
        "AirportName": "Lishan Airport",
        "City": "Lishan",
        "Country": "Taiwan"
    },
    {
        "id": "4143",
        "AirportCode": "LHP",
        "AirportName": "Lehu Airport",
        "City": "Lehu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4144",
        "AirportCode": "LHR",
        "AirportName": "Heathrow Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "4145",
        "AirportCode": "LHS",
        "AirportName": "Las Heras Airport",
        "City": "Las Heras",
        "Country": "Argentina"
    },
    {
        "id": "4146",
        "AirportCode": "LHU",
        "AirportName": "Lianshulu Airport",
        "City": "Caprivi",
        "Country": "Namibia"
    },
    {
        "id": "4147",
        "AirportCode": "LHV",
        "AirportName": "W T Piper Memorial Airport",
        "City": "Lock Haven",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4148",
        "AirportCode": "LHW",
        "AirportName": "Lanzhou Airport",
        "City": "Lanzhou",
        "Country": "China"
    },
    {
        "id": "4149",
        "AirportCode": "LIA",
        "AirportName": "Liangping Airport",
        "City": "Liangping",
        "Country": "China"
    },
    {
        "id": "4150",
        "AirportCode": "LIB",
        "AirportName": "Limbunya Airport",
        "City": "Limbunya",
        "Country": "Australia"
    },
    {
        "id": "4151",
        "AirportCode": "LIC",
        "AirportName": "Limon Municipal Airport",
        "City": "Limon",
        "Country": "USA Colorado"
    },
    {
        "id": "4152",
        "AirportCode": "LID",
        "AirportName": "Valkenburg Airport",
        "City": "Leiden",
        "Country": "Netherlands"
    },
    {
        "id": "4153",
        "AirportCode": "LIE",
        "AirportName": "Libenge Airport",
        "City": "Libenge",
        "Country": "Congo, DR"
    },
    {
        "id": "4154",
        "AirportCode": "LIF",
        "AirportName": "Lifou Airport",
        "City": "Lifou",
        "Country": "New Caledonia"
    },
    {
        "id": "4155",
        "AirportCode": "LIG",
        "AirportName": "Bellegarde Airport",
        "City": "Limoges",
        "Country": "France"
    },
    {
        "id": "4156",
        "AirportCode": "LIH",
        "AirportName": "Kauai Island Airport",
        "City": "Kauai Island",
        "Country": "USA Hawaii"
    },
    {
        "id": "4157",
        "AirportCode": "LII",
        "AirportName": "Mulia Airport",
        "City": "Mulia",
        "Country": "Indonesia"
    },
    {
        "id": "4158",
        "AirportCode": "LIK",
        "AirportName": "Likiep Airport",
        "City": "Likiep",
        "Country": "Marshall Islands"
    },
    {
        "id": "4159",
        "AirportCode": "LIL",
        "AirportName": "Lesquin Airport",
        "City": "Lille",
        "Country": "France"
    },
    {
        "id": "4160",
        "AirportCode": "LIM",
        "AirportName": "J Chavez International Airport",
        "City": "Lima (LIM)",
        "Country": "Peru"
    },
    {
        "id": "4161",
        "AirportCode": "LIN",
        "AirportName": "Linate Airport",
        "City": "Milan",
        "Country": "Italy"
    },
    {
        "id": "4162",
        "AirportCode": "LIO",
        "AirportName": "Limon Airport",
        "City": "Limon",
        "Country": "Costa Rica"
    },
    {
        "id": "4163",
        "AirportCode": "LIP",
        "AirportName": "Lins Airport",
        "City": "Lins",
        "Country": "Brazil"
    },
    {
        "id": "4164",
        "AirportCode": "LIQ",
        "AirportName": "Lisala Airport",
        "City": "Lisala",
        "Country": "Congo, DR"
    },
    {
        "id": "4165",
        "AirportCode": "LIR",
        "AirportName": "Liberia Airport",
        "City": "Liberia",
        "Country": "Costa Rica"
    },
    {
        "id": "4166",
        "AirportCode": "LIS",
        "AirportName": "Airport",
        "City": "Lisbon (LIS)",
        "Country": "Portugal"
    },
    {
        "id": "4167",
        "AirportCode": "LIT",
        "AirportName": "Little Rock Regional Airport",
        "City": "Little Rock",
        "Country": "USA Arkansas"
    },
    {
        "id": "4168",
        "AirportCode": "LIU",
        "AirportName": "Linosa Heliport",
        "City": "Linosa",
        "Country": "Italy"
    },
    {
        "id": "4169",
        "AirportCode": "LIW",
        "AirportName": "Loikaw Airport",
        "City": "Loikaw",
        "Country": "Burma"
    },
    {
        "id": "4170",
        "AirportCode": "LIX",
        "AirportName": "Likoma Airport",
        "City": "Likoma Island",
        "Country": "Malawi"
    },
    {
        "id": "4171",
        "AirportCode": "LIY",
        "AirportName": "Wright Army Air Field",
        "City": "Hinesville",
        "Country": "USA Georgia"
    },
    {
        "id": "4172",
        "AirportCode": "LIZ",
        "AirportName": "Loring Air Force Base",
        "City": "Limestone",
        "Country": "USA Maine"
    },
    {
        "id": "4173",
        "AirportCode": "LJA",
        "AirportName": "Lodja Airport",
        "City": "Lodja",
        "Country": "Congo, DR"
    },
    {
        "id": "4174",
        "AirportCode": "LJC",
        "AirportName": "Intercontinental Airport",
        "City": "Louisville",
        "Country": "USA Kentucky"
    },
    {
        "id": "4175",
        "AirportCode": "LJG",
        "AirportName": "Lijiang Airport",
        "City": "Lijiang",
        "Country": "China"
    },
    {
        "id": "4176",
        "AirportCode": "LJN",
        "AirportName": "Brazoria County Airport",
        "City": "Lake Jackson",
        "Country": "USA Texas"
    },
    {
        "id": "4177",
        "AirportCode": "LJU",
        "AirportName": "Brnik Airport",
        "City": "Ljubljana",
        "Country": "Slovenia"
    },
    {
        "id": "4178",
        "AirportCode": "LKA",
        "AirportName": "Larantuka Airport",
        "City": "Larantuka",
        "Country": "Indonesia"
    },
    {
        "id": "4179",
        "AirportCode": "LKB",
        "AirportName": "Lakeba Airport",
        "City": "Lakeba",
        "Country": "Fiji"
    },
    {
        "id": "4180",
        "AirportCode": "LKC",
        "AirportName": "Lekana Airport",
        "City": "Lekana",
        "Country": "Congo"
    },
    {
        "id": "4181",
        "AirportCode": "LKD",
        "AirportName": "Lakeland Downs Airport",
        "City": "Lakeland Downs",
        "Country": "Australia"
    },
    {
        "id": "4182",
        "AirportCode": "LKE",
        "AirportName": "Lake Union Sea Plane Base",
        "City": "Seattle",
        "Country": "USA Washington"
    },
    {
        "id": "4183",
        "AirportCode": "LKG",
        "AirportName": "Lokichoggio Airport",
        "City": "Lokichoggio",
        "Country": "Kenya"
    },
    {
        "id": "4184",
        "AirportCode": "LKH",
        "AirportName": "Long Akah Airport",
        "City": "Long Akah",
        "Country": "Malaysia"
    },
    {
        "id": "4185",
        "AirportCode": "LKI",
        "AirportName": "Lakeside United States Air Force",
        "City": "Duluth",
        "Country": "USA Minnesota"
    },
    {
        "id": "4186",
        "AirportCode": "LKL",
        "AirportName": "Banak Airport",
        "City": "Lakselv",
        "Country": "Norway"
    },
    {
        "id": "4187",
        "AirportCode": "LKN",
        "AirportName": "Leknes Airport",
        "City": "Leknes",
        "Country": "Norway"
    },
    {
        "id": "4188",
        "AirportCode": "LKO",
        "AirportName": "Amausi Airport",
        "City": "Lucknow",
        "Country": "India"
    },
    {
        "id": "4189",
        "AirportCode": "LKP",
        "AirportName": "Lake Placid Airport",
        "City": "Lake Placid",
        "Country": "USA New York"
    },
    {
        "id": "4190",
        "AirportCode": "LKR",
        "AirportName": "Las Khoreh Airport",
        "City": "Las Khoreh",
        "Country": "Somalia"
    },
    {
        "id": "4191",
        "AirportCode": "LKS",
        "AirportName": "Lakeside Airport",
        "City": "Lakeside",
        "Country": "USA Texas"
    },
    {
        "id": "4192",
        "AirportCode": "LKU",
        "AirportName": "Lake Rudolf Airport",
        "City": "Lake Rudolf",
        "Country": "Kenya"
    },
    {
        "id": "4193",
        "AirportCode": "LKV",
        "AirportName": "Lake County Airport",
        "City": "Lakeview",
        "Country": "USA Oregon"
    },
    {
        "id": "4194",
        "AirportCode": "LKY",
        "AirportName": "Lake Manyara Airport",
        "City": "Lake Manyara",
        "Country": "Tanzania"
    },
    {
        "id": "4195",
        "AirportCode": "LKZ",
        "AirportName": "Lakenheath RAF",
        "City": "Brandon",
        "Country": "United Kingdom"
    },
    {
        "id": "4196",
        "AirportCode": "LLA",
        "AirportName": "Kallax Airport",
        "City": "Lulea",
        "Country": "Sweden"
    },
    {
        "id": "4197",
        "AirportCode": "LLE",
        "AirportName": "Malelane Airport",
        "City": "Malelane",
        "Country": "South Africa"
    },
    {
        "id": "4198",
        "AirportCode": "LLF",
        "AirportName": "Ling Ling Airport",
        "City": "Ling Ling",
        "Country": "China"
    },
    {
        "id": "4199",
        "AirportCode": "LLG",
        "AirportName": "Chillagoe Airport",
        "City": "Chillagoe",
        "Country": "Australia"
    },
    {
        "id": "4200",
        "AirportCode": "LLH",
        "AirportName": "Las Limas Airport",
        "City": "Las Limas",
        "Country": "Honduras"
    },
    {
        "id": "4201",
        "AirportCode": "LLI",
        "AirportName": "Lalibela Airport",
        "City": "Lalibela",
        "Country": "Ethiopia"
    },
    {
        "id": "4202",
        "AirportCode": "LLL",
        "AirportName": "Lissadell Airport",
        "City": "Lissadell",
        "Country": "Australia"
    },
    {
        "id": "4203",
        "AirportCode": "LLM",
        "AirportName": "Long Lama Airport",
        "City": "Long Lama",
        "Country": "Malaysia"
    },
    {
        "id": "4204",
        "AirportCode": "LLN",
        "AirportName": "Kelila Airport",
        "City": "Kelila",
        "Country": "Indonesia"
    },
    {
        "id": "4205",
        "AirportCode": "LLP",
        "AirportName": "Linda Downs Airport",
        "City": "Linda Downs",
        "Country": "Australia"
    },
    {
        "id": "4206",
        "AirportCode": "LLS",
        "AirportName": "Las Lomitas Airport",
        "City": "Las Lomitas",
        "Country": "Argentina"
    },
    {
        "id": "4207",
        "AirportCode": "LLU",
        "AirportName": "Alluitsup Paa Airport",
        "City": "Alluitsup Paa",
        "Country": "Greenland"
    },
    {
        "id": "4208",
        "AirportCode": "LLW",
        "AirportName": "Lilongwe International Airport",
        "City": "Lilongwe",
        "Country": "Malawi"
    },
    {
        "id": "4209",
        "AirportCode": "LLX",
        "AirportName": "Lyndonville Airport",
        "City": "Lyndonville",
        "Country": "USA Vermont"
    },
    {
        "id": "4210",
        "AirportCode": "LLY",
        "AirportName": "Burlington County Airport",
        "City": "Mount Holly",
        "Country": "USA New Jersey"
    },
    {
        "id": "4211",
        "AirportCode": "LMA",
        "AirportName": "Lake Minchumina Airport",
        "City": "Lake Minchumina",
        "Country": "USA Alaska"
    },
    {
        "id": "4212",
        "AirportCode": "LMB",
        "AirportName": "Salima Airport",
        "City": "Salima",
        "Country": "Malawi"
    },
    {
        "id": "4213",
        "AirportCode": "LMC",
        "AirportName": "Lamacarena Airport",
        "City": "Lamacarena",
        "Country": "Colombia"
    },
    {
        "id": "4214",
        "AirportCode": "LMD",
        "AirportName": "Los Menucos Airport",
        "City": "Los Menucos",
        "Country": "Argentina"
    },
    {
        "id": "4215",
        "AirportCode": "LME",
        "AirportName": "Arnage Airport",
        "City": "Le Mans",
        "Country": "France"
    },
    {
        "id": "4216",
        "AirportCode": "LMG",
        "AirportName": "Lamassa Airport",
        "City": "Lamassa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4217",
        "AirportCode": "LMH",
        "AirportName": "Limon Airport",
        "City": "Limon",
        "Country": "Honduras"
    },
    {
        "id": "4218",
        "AirportCode": "LMI",
        "AirportName": "Lumi Airport",
        "City": "Lumi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4219",
        "AirportCode": "LML",
        "AirportName": "Lae Airport",
        "City": "Lae",
        "Country": "Marshall Islands"
    },
    {
        "id": "4220",
        "AirportCode": "LMM",
        "AirportName": "Federal Airport",
        "City": "Los Mochis",
        "Country": "Mexico"
    },
    {
        "id": "4221",
        "AirportCode": "LMN",
        "AirportName": "Limbang Airport",
        "City": "Limbang",
        "Country": "Malaysia"
    },
    {
        "id": "4222",
        "AirportCode": "LMO",
        "AirportName": "Royal Air Force Station",
        "City": "Lossiemouth",
        "Country": "United Kingdom"
    },
    {
        "id": "4223",
        "AirportCode": "LMP",
        "AirportName": "Lampedusa Airport",
        "City": "Lampedusa",
        "Country": "Italy"
    },
    {
        "id": "4224",
        "AirportCode": "LMQ",
        "AirportName": "Marsa el Brega Airport",
        "City": "Marsa el Brega",
        "Country": "Libya"
    },
    {
        "id": "4225",
        "AirportCode": "LMR",
        "AirportName": "Lima Acres Airport",
        "City": "Lima Acres",
        "Country": "South Africa"
    },
    {
        "id": "4226",
        "AirportCode": "LMS",
        "AirportName": "Winston County Airport",
        "City": "Louisville",
        "Country": "USA Mississippi"
    },
    {
        "id": "4227",
        "AirportCode": "LMT",
        "AirportName": "Kingsley Field",
        "City": "Klamath Falls",
        "Country": "USA Oregon"
    },
    {
        "id": "4228",
        "AirportCode": "LMX",
        "AirportName": "Lopez De Micay Airport",
        "City": "Lopez De Micay",
        "Country": "Colombia"
    },
    {
        "id": "4229",
        "AirportCode": "LMY",
        "AirportName": "Lake Murray Airport",
        "City": "Lake Murray",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4230",
        "AirportCode": "LMZ",
        "AirportName": "Palma Airport",
        "City": "Palma",
        "Country": "Mozambique"
    },
    {
        "id": "4231",
        "AirportCode": "LNA",
        "AirportName": "Palm Beach County Airport",
        "City": "West Palm Beach",
        "Country": "USA Florida"
    },
    {
        "id": "4232",
        "AirportCode": "LNB",
        "AirportName": "Lamen Bay Airport",
        "City": "Lamen Bay",
        "Country": "Vanuatu"
    },
    {
        "id": "4233",
        "AirportCode": "LNC",
        "AirportName": "Lengbati Airport",
        "City": "Lengbati",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4234",
        "AirportCode": "LND",
        "AirportName": "Hunt Field",
        "City": "Lander",
        "Country": "USA Wyoming"
    },
    {
        "id": "4235",
        "AirportCode": "LNE",
        "AirportName": "Lonorore Airport",
        "City": "Lonorore",
        "Country": "Vanuatu"
    },
    {
        "id": "4236",
        "AirportCode": "LNF",
        "AirportName": "Munbil Airport",
        "City": "Munbil",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4237",
        "AirportCode": "LNG",
        "AirportName": "Lese Airport",
        "City": "Lese",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4238",
        "AirportCode": "LNH",
        "AirportName": "Lake Nash Airport",
        "City": "Lake Nash",
        "Country": "Australia"
    },
    {
        "id": "4239",
        "AirportCode": "LNJ",
        "AirportName": "Lincang Airport",
        "City": "Lincang",
        "Country": "China"
    },
    {
        "id": "4240",
        "AirportCode": "LNK",
        "AirportName": "Lincoln Municipal Airport",
        "City": "Lincoln",
        "Country": "USA Nebraska"
    },
    {
        "id": "4241",
        "AirportCode": "LNM",
        "AirportName": "Langimar Airport",
        "City": "Langimar",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4242",
        "AirportCode": "LNN",
        "AirportName": "Lost Nation Airport",
        "City": "Willoughby",
        "Country": "USA Ohio"
    },
    {
        "id": "4243",
        "AirportCode": "LNO",
        "AirportName": "Leonora Airport",
        "City": "Leonora",
        "Country": "Australia"
    },
    {
        "id": "4244",
        "AirportCode": "LNP",
        "AirportName": "Wise Airport",
        "City": "Wise",
        "Country": "USA Virginia"
    },
    {
        "id": "4245",
        "AirportCode": "LNQ",
        "AirportName": "Loani Airport",
        "City": "Loani",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4246",
        "AirportCode": "LNR",
        "AirportName": "Tri County Airport",
        "City": "Lone Rock",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4247",
        "AirportCode": "LNS",
        "AirportName": "Lancaster Airport",
        "City": "Lancaster",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4248",
        "AirportCode": "LNV",
        "AirportName": "Lihir Island Airport",
        "City": "Lihir Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4249",
        "AirportCode": "LNX",
        "AirportName": "Smolensk Airport",
        "City": "Smolensk",
        "Country": "Russia"
    },
    {
        "id": "4250",
        "AirportCode": "LNY",
        "AirportName": "Lanai Airport",
        "City": "Lanai",
        "Country": "USA Hawaii"
    },
    {
        "id": "4251",
        "AirportCode": "LNZ",
        "AirportName": "Hoersching Airport",
        "City": "Linz",
        "Country": "Austria"
    },
    {
        "id": "4252",
        "AirportCode": "LOA",
        "AirportName": "Lorraine Airport",
        "City": "Lorraine",
        "Country": "Australia"
    },
    {
        "id": "4253",
        "AirportCode": "LOB",
        "AirportName": "Los Andes Airport",
        "City": "Los Andes",
        "Country": "Chile"
    },
    {
        "id": "4254",
        "AirportCode": "LOC",
        "AirportName": "Lock Airport",
        "City": "Lock",
        "Country": "Australia"
    },
    {
        "id": "4255",
        "AirportCode": "LOD",
        "AirportName": "Longana Airport",
        "City": "Longana",
        "Country": "Vanuatu"
    },
    {
        "id": "4256",
        "AirportCode": "LOE",
        "AirportName": "Loei Airport",
        "City": "Loei",
        "Country": "Thailand"
    },
    {
        "id": "4257",
        "AirportCode": "LOF",
        "AirportName": "Loen Airport",
        "City": "Loen",
        "Country": "Marshall Islands"
    },
    {
        "id": "4258",
        "AirportCode": "LOG",
        "AirportName": "Longview Airport",
        "City": "Longview",
        "Country": "USA Washington"
    },
    {
        "id": "4259",
        "AirportCode": "LOH",
        "AirportName": "Loja Airport",
        "City": "Loja",
        "Country": "Ecuador"
    },
    {
        "id": "4260",
        "AirportCode": "LOI",
        "AirportName": "Helmuth Baungartem Airport",
        "City": "Lontras",
        "Country": "Brazil"
    },
    {
        "id": "4261",
        "AirportCode": "LOK",
        "AirportName": "Lodwar Airport",
        "City": "Lodwar",
        "Country": "Kenya"
    },
    {
        "id": "4262",
        "AirportCode": "LOL",
        "AirportName": "Derby Field",
        "City": "Lovelock",
        "Country": "USA Nevada"
    },
    {
        "id": "4263",
        "AirportCode": "LOM",
        "AirportName": "Francisco P. V. y R. Airport",
        "City": "Lagos De Moreno",
        "Country": "Mexico"
    },
    {
        "id": "4264",
        "AirportCode": "LON",
        "AirportName": "London All Airports",
        "City": "London All Airports",
        "Country": "United Kingdom"
    },
    {
        "id": "4265",
        "AirportCode": "LOQ",
        "AirportName": "Lobatse Airport",
        "City": "Lobatse",
        "Country": "Botswana"
    },
    {
        "id": "4266",
        "AirportCode": "LOR",
        "AirportName": "Lowe Army Heliport",
        "City": "Ozark",
        "Country": "USA Alabama"
    },
    {
        "id": "4267",
        "AirportCode": "LOS",
        "AirportName": "Murtala Muhammed Airport",
        "City": "Lagos",
        "Country": "Nigeria"
    },
    {
        "id": "4268",
        "AirportCode": "LOT",
        "AirportName": "Lewis Lockport Airport",
        "City": "Lockport",
        "Country": "USA Illinois"
    },
    {
        "id": "4269",
        "AirportCode": "LOU",
        "AirportName": "Bowman Field",
        "City": "Louisville",
        "Country": "USA Kentucky"
    },
    {
        "id": "4270",
        "AirportCode": "LOV",
        "AirportName": "Monclova Airport",
        "City": "Monclova",
        "Country": "Mexico"
    },
    {
        "id": "4271",
        "AirportCode": "LOW",
        "AirportName": "Louisa Airport",
        "City": "Louisa",
        "Country": "USA Virginia"
    },
    {
        "id": "4272",
        "AirportCode": "LOY",
        "AirportName": "Loyangalani Airport",
        "City": "Loyangalani",
        "Country": "Kenya"
    },
    {
        "id": "4273",
        "AirportCode": "LOZ",
        "AirportName": "Corbin-London Airport",
        "City": "London",
        "Country": "USA Kentucky"
    },
    {
        "id": "4274",
        "AirportCode": "LPA",
        "AirportName": "Airport De Gran Canaria",
        "City": "Las Palmas",
        "Country": "Spain"
    },
    {
        "id": "4275",
        "AirportCode": "LPB",
        "AirportName": "El Alto Airport",
        "City": "La Paz",
        "Country": "Bolivia"
    },
    {
        "id": "4276",
        "AirportCode": "LPC",
        "AirportName": "Lompoc Airport",
        "City": "Lompoc",
        "Country": "USA California"
    },
    {
        "id": "4277",
        "AirportCode": "LPD",
        "AirportName": "La Pedrera Airport",
        "City": "La Pedrera",
        "Country": "Colombia"
    },
    {
        "id": "4278",
        "AirportCode": "LPE",
        "AirportName": "La Primavera Airport",
        "City": "La Primavera",
        "Country": "Colombia"
    },
    {
        "id": "4279",
        "AirportCode": "LPG",
        "AirportName": "La Plata Airport",
        "City": "La Plata",
        "Country": "Argentina"
    },
    {
        "id": "4280",
        "AirportCode": "LPH",
        "AirportName": "Lochgilphead Heliport",
        "City": "Lochgilphead",
        "Country": "United Kingdom"
    },
    {
        "id": "4281",
        "AirportCode": "LPI",
        "AirportName": "Linkoping Airport",
        "City": "Linkoping",
        "Country": "Sweden"
    },
    {
        "id": "4282",
        "AirportCode": "LPJ",
        "AirportName": "Pijiguaos Airport",
        "City": "Pijiguaos",
        "Country": "Venezuela"
    },
    {
        "id": "4283",
        "AirportCode": "LPK",
        "AirportName": "Lipetsk Airport",
        "City": "Lipetsk",
        "Country": "Russia"
    },
    {
        "id": "4284",
        "AirportCode": "LPL",
        "AirportName": "Liverpool John Lennon Airport",
        "City": "Liverpool",
        "Country": "United Kingdom"
    },
    {
        "id": "4285",
        "AirportCode": "LPM",
        "AirportName": "Lamap Airport",
        "City": "Lamap",
        "Country": "Vanuatu"
    },
    {
        "id": "4286",
        "AirportCode": "LPO",
        "AirportName": "Laporte Municipal Airport",
        "City": "Laporte",
        "Country": "USA Indiana"
    },
    {
        "id": "4287",
        "AirportCode": "LPP",
        "AirportName": "Lappeenranta Airport",
        "City": "Lappeenranta",
        "Country": "Finland"
    },
    {
        "id": "4288",
        "AirportCode": "LPQ",
        "AirportName": "Luang Prabang Airport",
        "City": "Luang Prabang",
        "Country": "Laos"
    },
    {
        "id": "4289",
        "AirportCode": "LPS",
        "AirportName": "Lopez Island Airport",
        "City": "Lopez Island",
        "Country": "USA Washington"
    },
    {
        "id": "4290",
        "AirportCode": "LPT",
        "AirportName": "Lampang Airport",
        "City": "Lampang",
        "Country": "Thailand"
    },
    {
        "id": "4291",
        "AirportCode": "LPU",
        "AirportName": "Long Apung Airport",
        "City": "Long Apung",
        "Country": "Indonesia"
    },
    {
        "id": "4292",
        "AirportCode": "LPX",
        "AirportName": "Liepaya Airport",
        "City": "Liepaya",
        "Country": "Latvia"
    },
    {
        "id": "4293",
        "AirportCode": "LPY",
        "AirportName": "Loudes Airport",
        "City": "Le Puy",
        "Country": "France"
    },
    {
        "id": "4294",
        "AirportCode": "LQK",
        "AirportName": "Pickens Airport",
        "City": "Pickens",
        "Country": "USA South Carolina"
    },
    {
        "id": "4295",
        "AirportCode": "LQM",
        "AirportName": "Puerto Leguizamo Airport",
        "City": "Puerto Leguizamo",
        "Country": "Colombia"
    },
    {
        "id": "4296",
        "AirportCode": "LQN",
        "AirportName": "Qala Nau Airport",
        "City": "Qala Nau",
        "Country": "Afghanistan"
    },
    {
        "id": "4297",
        "AirportCode": "LRA",
        "AirportName": "Larisa Airport",
        "City": "Larisa",
        "Country": "Greece"
    },
    {
        "id": "4298",
        "AirportCode": "LRB",
        "AirportName": "Leribe Airport",
        "City": "Leribe",
        "Country": "Lesotho"
    },
    {
        "id": "4299",
        "AirportCode": "LRC",
        "AirportName": "Laarbruch R.A.F. Airport",
        "City": "Laarbruch",
        "Country": "Germany"
    },
    {
        "id": "4300",
        "AirportCode": "LRD",
        "AirportName": "Laredo International Airport",
        "City": "Laredo",
        "Country": "USA Texas"
    },
    {
        "id": "4301",
        "AirportCode": "LRE",
        "AirportName": "Longreach Airport",
        "City": "Longreach",
        "Country": "Australia"
    },
    {
        "id": "4302",
        "AirportCode": "LRF",
        "AirportName": "Little Rock Air Force Base",
        "City": "Jacksonville",
        "Country": "USA Arkansas"
    },
    {
        "id": "4303",
        "AirportCode": "LRG",
        "AirportName": "Lora Lai Airport",
        "City": "Lora Lai",
        "Country": "Pakistan"
    },
    {
        "id": "4304",
        "AirportCode": "LRH",
        "AirportName": "Laleu Airport",
        "City": "La Rochelle",
        "Country": "France"
    },
    {
        "id": "4305",
        "AirportCode": "LRI",
        "AirportName": "Lorica Airport",
        "City": "Lorica",
        "Country": "Colombia"
    },
    {
        "id": "4306",
        "AirportCode": "LRJ",
        "AirportName": "Lemars Municipal Airport",
        "City": "Lemars",
        "Country": "USA Iowa"
    },
    {
        "id": "4307",
        "AirportCode": "LRL",
        "AirportName": "Niamtougou Airport",
        "City": "Niamtougou",
        "Country": "Togo"
    },
    {
        "id": "4308",
        "AirportCode": "LRM",
        "AirportName": "La Romana Airport",
        "City": "La Romana",
        "Country": "Dominican Republic"
    },
    {
        "id": "4309",
        "AirportCode": "LRN",
        "AirportName": "Larson Air Force Base",
        "City": "Moses Lake",
        "Country": "USA Washington"
    },
    {
        "id": "4310",
        "AirportCode": "LRO",
        "AirportName": "Sharpe Army Air Field",
        "City": "Lathrop",
        "Country": "USA California"
    },
    {
        "id": "4311",
        "AirportCode": "LRQ",
        "AirportName": "Laurie River Airport",
        "City": "Laurie River",
        "Country": "Canada"
    },
    {
        "id": "4312",
        "AirportCode": "LRR",
        "AirportName": "Lar Airport",
        "City": "Lar",
        "Country": "Iran"
    },
    {
        "id": "4313",
        "AirportCode": "LRS",
        "AirportName": "Leros Airport",
        "City": "Leros",
        "Country": "Greece"
    },
    {
        "id": "4314",
        "AirportCode": "LRT",
        "AirportName": "Lann Bihoue Airport",
        "City": "Lorient",
        "Country": "France"
    },
    {
        "id": "4315",
        "AirportCode": "LRU",
        "AirportName": "Las Cruces Municipal Airport",
        "City": "Las Cruces",
        "Country": "USA New Mexico"
    },
    {
        "id": "4316",
        "AirportCode": "LRV",
        "AirportName": "Los Roques Airport",
        "City": "Los Roques",
        "Country": "Venezuela"
    },
    {
        "id": "4317",
        "AirportCode": "LSA",
        "AirportName": "Losuia Airport",
        "City": "Losuia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4318",
        "AirportCode": "LSB",
        "AirportName": "Lordsburg Airport",
        "City": "Lordsburg",
        "Country": "USA New Mexico"
    },
    {
        "id": "4319",
        "AirportCode": "LSC",
        "AirportName": "La Florida Airport",
        "City": "La Serena",
        "Country": "Chile"
    },
    {
        "id": "4320",
        "AirportCode": "LSE",
        "AirportName": "La Crosse Municipal Airport",
        "City": "La Crosse",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4321",
        "AirportCode": "LSF",
        "AirportName": "Lawson Army Air Field",
        "City": "Fort Benning",
        "Country": "USA Georgia"
    },
    {
        "id": "4322",
        "AirportCode": "LSH",
        "AirportName": "Lashio Airport",
        "City": "Lashio",
        "Country": "Burma"
    },
    {
        "id": "4323",
        "AirportCode": "LSI",
        "AirportName": "Sumburgh Airport",
        "City": "Shetland Islands",
        "Country": "United Kingdom"
    },
    {
        "id": "4324",
        "AirportCode": "LSJ",
        "AirportName": "Long Island Airport",
        "City": "Long Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4325",
        "AirportCode": "LSK",
        "AirportName": "Lusk Municipal",
        "City": "Lusk",
        "Country": "USA Wyoming"
    },
    {
        "id": "4326",
        "AirportCode": "LSL",
        "AirportName": "Los Chiles Airport",
        "City": "Los Chiles",
        "Country": "Costa Rica"
    },
    {
        "id": "4327",
        "AirportCode": "LSM",
        "AirportName": "Lawas Airport",
        "City": "Long Semado",
        "Country": "Malaysia"
    },
    {
        "id": "4328",
        "AirportCode": "LSN",
        "AirportName": "Los Banos Airport",
        "City": "Los Banos",
        "Country": "USA California"
    },
    {
        "id": "4329",
        "AirportCode": "LSO",
        "AirportName": "Talmont Airport",
        "City": "Les Sables",
        "Country": "France"
    },
    {
        "id": "4330",
        "AirportCode": "LSP",
        "AirportName": "Josefa Camejo Airport",
        "City": "Las Piedras",
        "Country": "Venezuela"
    },
    {
        "id": "4331",
        "AirportCode": "LSQ",
        "AirportName": "Los Angeles Airport",
        "City": "Los Angeles",
        "Country": "Chile"
    },
    {
        "id": "4332",
        "AirportCode": "LSS",
        "AirportName": "Terre-de-Haut Airport",
        "City": "Terre-de-Haut",
        "Country": "Guadeloupe"
    },
    {
        "id": "4333",
        "AirportCode": "LST",
        "AirportName": "Launceston Airport",
        "City": "Launceston",
        "Country": "Australia"
    },
    {
        "id": "4334",
        "AirportCode": "LSU",
        "AirportName": "Long Sukang Airport",
        "City": "Long Sukang",
        "Country": "Malaysia"
    },
    {
        "id": "4335",
        "AirportCode": "LSV",
        "AirportName": "Nellis Air Force Base",
        "City": "Las Vegas",
        "Country": "USA Nevada"
    },
    {
        "id": "4336",
        "AirportCode": "LSW",
        "AirportName": "Lhoksumawe Airport",
        "City": "Lhoksumawe",
        "Country": "Indonesia"
    },
    {
        "id": "4337",
        "AirportCode": "LSX",
        "AirportName": "Lhok Sukon Airport",
        "City": "Lhok Sukon",
        "Country": "Indonesia"
    },
    {
        "id": "4338",
        "AirportCode": "LSY",
        "AirportName": "Lismore Airport",
        "City": "Lismore",
        "Country": "Australia"
    },
    {
        "id": "4339",
        "AirportCode": "LSZ",
        "AirportName": "Losinj Airport",
        "City": "Mali Losinj",
        "Country": "Croatia"
    },
    {
        "id": "4340",
        "AirportCode": "LTA",
        "AirportName": "Letaba Airport",
        "City": "Tzaneen",
        "Country": "South Africa"
    },
    {
        "id": "4341",
        "AirportCode": "LTB",
        "AirportName": "Latrobe Airport",
        "City": "Latrobe",
        "Country": "Australia"
    },
    {
        "id": "4342",
        "AirportCode": "LTC",
        "AirportName": "Lai Airport",
        "City": "Lai",
        "Country": "Mali"
    },
    {
        "id": "4343",
        "AirportCode": "LTD",
        "AirportName": "Ghadames Airport",
        "City": "Ghadames",
        "Country": "Libya"
    },
    {
        "id": "4344",
        "AirportCode": "LTF",
        "AirportName": "Leitre Airport",
        "City": "Leitre",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4345",
        "AirportCode": "LTG",
        "AirportName": "Langtang Airport",
        "City": "Langtang",
        "Country": "Nepal"
    },
    {
        "id": "4346",
        "AirportCode": "LTH",
        "AirportName": "Lathrop Wells Airport",
        "City": "Lathrop Wells",
        "Country": "USA Nevada"
    },
    {
        "id": "4347",
        "AirportCode": "LTI",
        "AirportName": "Altai Airport",
        "City": "Altai",
        "Country": "Mongolia"
    },
    {
        "id": "4348",
        "AirportCode": "LTK",
        "AirportName": "Latakia Airport",
        "City": "Latakia",
        "Country": "Syria"
    },
    {
        "id": "4349",
        "AirportCode": "LTL",
        "AirportName": "Lastourville Airport",
        "City": "Lastourville",
        "Country": "Gabon"
    },
    {
        "id": "4350",
        "AirportCode": "LTM",
        "AirportName": "Lethem Airport",
        "City": "Lethem",
        "Country": "Guyana"
    },
    {
        "id": "4351",
        "AirportCode": "LTN",
        "AirportName": "Luton Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "4352",
        "AirportCode": "LTO",
        "AirportName": "Loreto Airport",
        "City": "Loreto",
        "Country": "Mexico"
    },
    {
        "id": "4353",
        "AirportCode": "LTP",
        "AirportName": "Lyndhurst Airport",
        "City": "Lyndhurst",
        "Country": "Australia"
    },
    {
        "id": "4354",
        "AirportCode": "LTQ",
        "AirportName": "Le Touquet Airport",
        "City": "Le Touquet",
        "Country": "France"
    },
    {
        "id": "4355",
        "AirportCode": "LTR",
        "AirportName": "Letterkenny Airport",
        "City": "Letterkenny",
        "Country": "Ireland"
    },
    {
        "id": "4356",
        "AirportCode": "LTS",
        "AirportName": "Altus Air Force Base",
        "City": "Altus",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4357",
        "AirportCode": "LTT",
        "AirportName": "La Mole Airport",
        "City": "Saint Tropez",
        "Country": "France"
    },
    {
        "id": "4358",
        "AirportCode": "LTV",
        "AirportName": "Lotusvale Airport",
        "City": "Lotusvale",
        "Country": "Australia"
    },
    {
        "id": "4359",
        "AirportCode": "LTW",
        "AirportName": "Saint Marys County Airport",
        "City": "Leonardtown",
        "Country": "USA Maryland"
    },
    {
        "id": "4360",
        "AirportCode": "LTX",
        "AirportName": "Cotapaxi International Airport",
        "City": "Latacunga",
        "Country": "Ecuador"
    },
    {
        "id": "4361",
        "AirportCode": "LUA",
        "AirportName": "Lukla Airport",
        "City": "Lukla",
        "Country": "Nepal"
    },
    {
        "id": "4362",
        "AirportCode": "LUB",
        "AirportName": "Lumid Pau Airport",
        "City": "Lumid Pau",
        "Country": "Guyana"
    },
    {
        "id": "4363",
        "AirportCode": "LUC",
        "AirportName": "Laucala Island Airport",
        "City": "Laucala Island",
        "Country": "Fiji"
    },
    {
        "id": "4364",
        "AirportCode": "LUD",
        "AirportName": "Luderitz Airport",
        "City": "Luderitz",
        "Country": "Namibia"
    },
    {
        "id": "4365",
        "AirportCode": "LUF",
        "AirportName": "Luke Air Force Base",
        "City": "Phoenix",
        "Country": "USA Arizona"
    },
    {
        "id": "4366",
        "AirportCode": "LUG",
        "AirportName": "Lugano Airport",
        "City": "Lugano",
        "Country": "Switzerland"
    },
    {
        "id": "4367",
        "AirportCode": "LUH",
        "AirportName": "Ludhiana Airport",
        "City": "Ludhiana",
        "Country": "India"
    },
    {
        "id": "4368",
        "AirportCode": "LUI",
        "AirportName": "La Union Airport",
        "City": "La Union",
        "Country": "Honduras"
    },
    {
        "id": "4369",
        "AirportCode": "LUJ",
        "AirportName": "Lusikisiki Airport",
        "City": "Lusikisiki",
        "Country": "South Africa"
    },
    {
        "id": "4370",
        "AirportCode": "LUK",
        "AirportName": "Cincinnati Municipal Airport",
        "City": "Cincinnati",
        "Country": "USA Ohio"
    },
    {
        "id": "4371",
        "AirportCode": "LUL",
        "AirportName": "Hesler-Noble Field",
        "City": "Laurel",
        "Country": "USA Mississippi"
    },
    {
        "id": "4372",
        "AirportCode": "LUM",
        "AirportName": "Mangshi Airport",
        "City": "Luxi",
        "Country": "China"
    },
    {
        "id": "4373",
        "AirportCode": "LUN",
        "AirportName": "Lusaka Airport",
        "City": "Lusaka",
        "Country": "Zambia"
    },
    {
        "id": "4374",
        "AirportCode": "LUO",
        "AirportName": "Luena Airport",
        "City": "Luena",
        "Country": "Angola"
    },
    {
        "id": "4375",
        "AirportCode": "LUP",
        "AirportName": "Kalaupapa Airport",
        "City": "Kalaupapa",
        "Country": "USA Hawaii"
    },
    {
        "id": "4376",
        "AirportCode": "LUQ",
        "AirportName": "San Luis Airport",
        "City": "San Luis",
        "Country": "Argentina"
    },
    {
        "id": "4377",
        "AirportCode": "LUR",
        "AirportName": "Cape Lisburne Airport",
        "City": "Cape Lisburne",
        "Country": "USA Alaska"
    },
    {
        "id": "4378",
        "AirportCode": "LUS",
        "AirportName": "Lusanga Airport",
        "City": "Lusanga",
        "Country": "Congo, DR"
    },
    {
        "id": "4379",
        "AirportCode": "LUT",
        "AirportName": "Laura Station Airport",
        "City": "Laura Station",
        "Country": "Australia"
    },
    {
        "id": "4380",
        "AirportCode": "LUU",
        "AirportName": "Laura Airport",
        "City": "Laura",
        "Country": "Australia"
    },
    {
        "id": "4381",
        "AirportCode": "LUV",
        "AirportName": "Langgur Airport",
        "City": "Langgur",
        "Country": "Indonesia"
    },
    {
        "id": "4382",
        "AirportCode": "LUW",
        "AirportName": "Luwuk Airport",
        "City": "Luwuk",
        "Country": "Indonesia"
    },
    {
        "id": "4383",
        "AirportCode": "LUX",
        "AirportName": "Luxembourg Airport",
        "City": "Luxembourg (LUX)",
        "Country": "Luxembourg (LUX)"
    },
    {
        "id": "4384",
        "AirportCode": "LUY",
        "AirportName": "Lushoto Airport",
        "City": "Lushoto",
        "Country": "Tanzania"
    },
    {
        "id": "4385",
        "AirportCode": "LUZ",
        "AirportName": "Lushan Airport",
        "City": "Lushan",
        "Country": "China"
    },
    {
        "id": "4386",
        "AirportCode": "LVA",
        "AirportName": "Entrammes Airport",
        "City": "Laval",
        "Country": "France"
    },
    {
        "id": "4387",
        "AirportCode": "LVB",
        "AirportName": "Livramento Do Brumado",
        "City": "Livramento Do Brumado",
        "Country": "Brazil"
    },
    {
        "id": "4388",
        "AirportCode": "LVB",
        "AirportName": "Dos Galpoes Airport",
        "City": "Livramento",
        "Country": "Brazil"
    },
    {
        "id": "4389",
        "AirportCode": "LVD",
        "AirportName": "Lime Village Airport",
        "City": "Lime Village",
        "Country": "USA Alaska"
    },
    {
        "id": "4390",
        "AirportCode": "LVI",
        "AirportName": "Livingstone Airport",
        "City": "Livingstone",
        "Country": "Zambia"
    },
    {
        "id": "4391",
        "AirportCode": "LVK",
        "AirportName": "Livermore Airport",
        "City": "Livermore",
        "Country": "USA California"
    },
    {
        "id": "4392",
        "AirportCode": "LVL",
        "AirportName": "Lawrenceville Airport",
        "City": "Lawrenceville",
        "Country": "USA Virginia"
    },
    {
        "id": "4393",
        "AirportCode": "LVM",
        "AirportName": "Mission Field",
        "City": "Livingston",
        "Country": "USA Montana"
    },
    {
        "id": "4394",
        "AirportCode": "LVO",
        "AirportName": "Laverton Airport",
        "City": "Laverton",
        "Country": "Australia"
    },
    {
        "id": "4395",
        "AirportCode": "LVP",
        "AirportName": "Lavan Island Airport",
        "City": "Lavan",
        "Country": "Iran"
    },
    {
        "id": "4396",
        "AirportCode": "LVS",
        "AirportName": "Las Vegas Airport",
        "City": "Las Vegas",
        "Country": "USA New Mexico"
    },
    {
        "id": "4397",
        "AirportCode": "LWA",
        "AirportName": "Lwbak Airport",
        "City": "Lwbak",
        "Country": "Philippines"
    },
    {
        "id": "4398",
        "AirportCode": "LWB",
        "AirportName": "Greenbrier Valley Airport",
        "City": "Lewisburg",
        "Country": "USA West Virginia"
    },
    {
        "id": "4399",
        "AirportCode": "LWC",
        "AirportName": "Lawrence Airport",
        "City": "Lawrence",
        "Country": "USA Kansas"
    },
    {
        "id": "4400",
        "AirportCode": "LWE",
        "AirportName": "Lewoleba Airport",
        "City": "Lewoleba",
        "Country": "Indonesia"
    },
    {
        "id": "4401",
        "AirportCode": "LWH",
        "AirportName": "Lawn Hill Airport",
        "City": "Lawn Hill",
        "Country": "Australia"
    },
    {
        "id": "4402",
        "AirportCode": "LWI",
        "AirportName": "Lowai Airport",
        "City": "Lowai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4403",
        "AirportCode": "LWK",
        "AirportName": "Lerwick/Tingwall Airport",
        "City": "Shetland Islands",
        "Country": "United Kingdom"
    },
    {
        "id": "4404",
        "AirportCode": "LWL",
        "AirportName": "Harriet Field",
        "City": "Wells",
        "Country": "USA Nevada"
    },
    {
        "id": "4405",
        "AirportCode": "LWM",
        "AirportName": "Lawrence Airport",
        "City": "Lawrence",
        "Country": "USA Massachusetts"
    },
    {
        "id": "4406",
        "AirportCode": "LWN",
        "AirportName": "Leninakan Airport",
        "City": "Gyoumri",
        "Country": "Armenia"
    },
    {
        "id": "4407",
        "AirportCode": "LWO",
        "AirportName": "Snilow Airport",
        "City": "Lviv",
        "Country": "Ukraine"
    },
    {
        "id": "4408",
        "AirportCode": "LWR",
        "AirportName": "Leeuwarden Airport",
        "City": "Leeuwarden",
        "Country": "Netherlands"
    },
    {
        "id": "4409",
        "AirportCode": "LWS",
        "AirportName": "Nez Perce County Regional Airport",
        "City": "Lewiston",
        "Country": "USA Idaho"
    },
    {
        "id": "4410",
        "AirportCode": "LWT",
        "AirportName": "Lewistown Municipal Airport",
        "City": "Lewistown",
        "Country": "USA Montana"
    },
    {
        "id": "4411",
        "AirportCode": "LWV",
        "AirportName": "Lawrenceville Municipal Airport",
        "City": "Lawrenceville",
        "Country": "USA Illinois"
    },
    {
        "id": "4412",
        "AirportCode": "LWY",
        "AirportName": "Lawas Airport",
        "City": "Lawas",
        "Country": "Malaysia"
    },
    {
        "id": "4413",
        "AirportCode": "LXA",
        "AirportName": "Lhasa Airport",
        "City": "Lhasa",
        "Country": "China"
    },
    {
        "id": "4414",
        "AirportCode": "LXG",
        "AirportName": "Luang Namtha Airport",
        "City": "Luang Namtha",
        "Country": "Laos"
    },
    {
        "id": "4415",
        "AirportCode": "LXI",
        "AirportName": "Linxi Airport",
        "City": "Linxi",
        "Country": "China"
    },
    {
        "id": "4416",
        "AirportCode": "LXN",
        "AirportName": "Lexington Airport",
        "City": "Lexington",
        "Country": "USA Nebraska"
    },
    {
        "id": "4417",
        "AirportCode": "LXR",
        "AirportName": "Luxor Airport",
        "City": "Luxor",
        "Country": "Egypt"
    },
    {
        "id": "4418",
        "AirportCode": "LXS",
        "AirportName": "Limnos Airport",
        "City": "Limnos",
        "Country": "Greece"
    },
    {
        "id": "4419",
        "AirportCode": "LXU",
        "AirportName": "Lukulu Airport",
        "City": "Lukulu",
        "Country": "Zambia"
    },
    {
        "id": "4420",
        "AirportCode": "LXV",
        "AirportName": "Leadville Airport",
        "City": "Leadville",
        "Country": "USA Colorado"
    },
    {
        "id": "4421",
        "AirportCode": "LYA",
        "AirportName": "Luoyang Airport",
        "City": "Luoyang",
        "Country": "China"
    },
    {
        "id": "4422",
        "AirportCode": "LYB",
        "AirportName": "Little Cayman Airport",
        "City": "Little Cayman",
        "Country": "Cayman Islands"
    },
    {
        "id": "4423",
        "AirportCode": "LYC",
        "AirportName": "Lycksele Airport",
        "City": "Lycksele",
        "Country": "Sweden"
    },
    {
        "id": "4424",
        "AirportCode": "LYE",
        "AirportName": "Royal Air Force Station",
        "City": "Lyneham",
        "Country": "United Kingdom"
    },
    {
        "id": "4425",
        "AirportCode": "LYG",
        "AirportName": "Lianyungang Airport",
        "City": "Lianyungang",
        "Country": "China"
    },
    {
        "id": "4426",
        "AirportCode": "LYH",
        "AirportName": "Preston-Glenn Field",
        "City": "Lynchburg",
        "Country": "USA Virginia"
    },
    {
        "id": "4427",
        "AirportCode": "LYI",
        "AirportName": "Linyi Airport",
        "City": "Linyi",
        "Country": "China"
    },
    {
        "id": "4428",
        "AirportCode": "LYK",
        "AirportName": "Lunyuk Airport",
        "City": "Lunyuk",
        "Country": "Indonesia"
    },
    {
        "id": "4429",
        "AirportCode": "LYN",
        "AirportName": "Bron Airport",
        "City": "Lyon",
        "Country": "France"
    },
    {
        "id": "4430",
        "AirportCode": "LYO",
        "AirportName": "Rice County Municipal Airport",
        "City": "Lyons",
        "Country": "USA Kansas"
    },
    {
        "id": "4431",
        "AirportCode": "LYP",
        "AirportName": "Faisalabad Airport",
        "City": "Faisalabad",
        "Country": "Pakistan"
    },
    {
        "id": "4432",
        "AirportCode": "LYR",
        "AirportName": "Svalbard Airport",
        "City": "Longyearbyen",
        "Country": "Svalbard"
    },
    {
        "id": "4433",
        "AirportCode": "LYS",
        "AirportName": "Lyon Saint-Exupery International Airport",
        "City": "Lyon",
        "Country": "France"
    },
    {
        "id": "4434",
        "AirportCode": "LYT",
        "AirportName": "Lady Elliot Island Airport",
        "City": "Lady Elliot Island",
        "Country": "Australia"
    },
    {
        "id": "4435",
        "AirportCode": "LYU",
        "AirportName": "Ely Airport",
        "City": "Ely",
        "Country": "USA Minnesota"
    },
    {
        "id": "4436",
        "AirportCode": "LYX",
        "AirportName": "Lydd International Airport",
        "City": "Lydd",
        "Country": "United Kingdom"
    },
    {
        "id": "4437",
        "AirportCode": "LZA",
        "AirportName": "Luiza Airport",
        "City": "Luiza",
        "Country": "Congo, DR"
    },
    {
        "id": "4438",
        "AirportCode": "LZC",
        "AirportName": "Lazaro Cardenas Airport",
        "City": "Lazaro Cardenas",
        "Country": "Mexico"
    },
    {
        "id": "4439",
        "AirportCode": "LZD",
        "AirportName": "Lanzhoudong Airport",
        "City": "Lanzhou",
        "Country": "China"
    },
    {
        "id": "4440",
        "AirportCode": "LZH",
        "AirportName": "Liuzhou Airport",
        "City": "Liuzhou",
        "Country": "China"
    },
    {
        "id": "4441",
        "AirportCode": "LZI",
        "AirportName": "Luozi Airport",
        "City": "Luozi",
        "Country": "Congo, DR"
    },
    {
        "id": "4442",
        "AirportCode": "LZN",
        "AirportName": "Nangan Airport (Matsu Islands)",
        "City": "Nangan",
        "Country": "China"
    },
    {
        "id": "4443",
        "AirportCode": "LZO",
        "AirportName": "Luzhou Airport",
        "City": "Luzhou",
        "Country": "China"
    },
    {
        "id": "4444",
        "AirportCode": "LZR",
        "AirportName": "Lizard Island Airport",
        "City": "Lizard Island",
        "Country": "Australia"
    },
    {
        "id": "4445",
        "AirportCode": "MAA",
        "AirportName": "Chennai Airport",
        "City": "Chennai",
        "Country": "India"
    },
    {
        "id": "4446",
        "AirportCode": "MAB",
        "AirportName": "Maraba Airport",
        "City": "Maraba",
        "Country": "Brazil"
    },
    {
        "id": "4447",
        "AirportCode": "MAC",
        "AirportName": "Smart Airport",
        "City": "Macon",
        "Country": "USA Georgia"
    },
    {
        "id": "4448",
        "AirportCode": "MAD",
        "AirportName": "Barajas Airport",
        "City": "Madrid",
        "Country": "Spain"
    },
    {
        "id": "4449",
        "AirportCode": "MAE",
        "AirportName": "Madera Airport",
        "City": "Madera",
        "Country": "USA California"
    },
    {
        "id": "4450",
        "AirportCode": "MAF",
        "AirportName": "Midland International Airport",
        "City": "Midland/Odessa",
        "Country": "USA Texas"
    },
    {
        "id": "4451",
        "AirportCode": "MAG",
        "AirportName": "Madang Airport",
        "City": "Madang",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4452",
        "AirportCode": "MAH",
        "AirportName": "Mahon Airport",
        "City": "Menorca",
        "Country": "Spain"
    },
    {
        "id": "4453",
        "AirportCode": "MAI",
        "AirportName": "Mangochi Airport",
        "City": "Mangochi",
        "Country": "Malawi"
    },
    {
        "id": "4454",
        "AirportCode": "MAJ",
        "AirportName": "Amata Kabua International Airport",
        "City": "Majuro",
        "Country": "Marshall Islands"
    },
    {
        "id": "4455",
        "AirportCode": "MAK",
        "AirportName": "Malakal Airport",
        "City": "Malakal",
        "Country": "Sudan"
    },
    {
        "id": "4456",
        "AirportCode": "MAL",
        "AirportName": "Mangole Airport",
        "City": "Mangole",
        "Country": "Indonesia"
    },
    {
        "id": "4457",
        "AirportCode": "MAM",
        "AirportName": "Matamoros Airport",
        "City": "Matamoros",
        "Country": "Mexico"
    },
    {
        "id": "4458",
        "AirportCode": "MAN",
        "AirportName": "Manchester International Airport",
        "City": "Manchester (MAN)",
        "Country": "United Kingdom"
    },
    {
        "id": "4459",
        "AirportCode": "MAO",
        "AirportName": "Eduardo Gomes International Airport",
        "City": "Manaus",
        "Country": "Brazil"
    },
    {
        "id": "4460",
        "AirportCode": "MAP",
        "AirportName": "Mamai Airport",
        "City": "Mamai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4461",
        "AirportCode": "MAQ",
        "AirportName": "Mae Sot Airport",
        "City": "Mae Sot",
        "Country": "Thailand"
    },
    {
        "id": "4462",
        "AirportCode": "MAR",
        "AirportName": "La Chinita Airport",
        "City": "Maracaibo",
        "Country": "Venezuela"
    },
    {
        "id": "4463",
        "AirportCode": "MAS",
        "AirportName": "Momote Airport",
        "City": "Manus Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4464",
        "AirportCode": "MAT",
        "AirportName": "Matadi Airport",
        "City": "Matadi",
        "Country": "Congo, DR"
    },
    {
        "id": "4465",
        "AirportCode": "MAU",
        "AirportName": "Maupiti Airport",
        "City": "Maupiti",
        "Country": "French Polynesia"
    },
    {
        "id": "4466",
        "AirportCode": "MAV",
        "AirportName": "Maloelap Airport",
        "City": "Maloelap",
        "Country": "Marshall Islands"
    },
    {
        "id": "4467",
        "AirportCode": "MAW",
        "AirportName": "Malden Airport",
        "City": "Malden",
        "Country": "USA Missouri"
    },
    {
        "id": "4468",
        "AirportCode": "MAX",
        "AirportName": "Matam Airport",
        "City": "Matam",
        "Country": "Senegal"
    },
    {
        "id": "4469",
        "AirportCode": "MAY",
        "AirportName": "Mangrove Cay Airport",
        "City": "Mangrove Cay",
        "Country": "Bahamas"
    },
    {
        "id": "4470",
        "AirportCode": "MAZ",
        "AirportName": "Eugenio M. De Hostos Airport",
        "City": "Mayaguez",
        "Country": "Puerto Rico"
    },
    {
        "id": "4471",
        "AirportCode": "MBA",
        "AirportName": "Moi International Airport",
        "City": "Mombasa",
        "Country": "Kenya"
    },
    {
        "id": "4472",
        "AirportCode": "MBB",
        "AirportName": "Marble Bar Airport",
        "City": "Marble Bar",
        "Country": "Australia"
    },
    {
        "id": "4473",
        "AirportCode": "MBC",
        "AirportName": "Mbigou Airport",
        "City": "Mbigou",
        "Country": "Gabon"
    },
    {
        "id": "4474",
        "AirportCode": "MBD",
        "AirportName": "Mmabatho International Airport",
        "City": "Mmabatho",
        "Country": "South Africa"
    },
    {
        "id": "4475",
        "AirportCode": "MBE",
        "AirportName": "Monbetsu Airport",
        "City": "Monbetsu",
        "Country": "Japan"
    },
    {
        "id": "4476",
        "AirportCode": "MBF",
        "AirportName": "Mount Buffalo Airport",
        "City": "Mount Buffalo",
        "Country": "Australia"
    },
    {
        "id": "4477",
        "AirportCode": "MBG",
        "AirportName": "Mobridge Airport",
        "City": "Mobridge",
        "Country": "USA South Dakota"
    },
    {
        "id": "4478",
        "AirportCode": "MBH",
        "AirportName": "Maryborough Airport",
        "City": "Maryborough",
        "Country": "Australia"
    },
    {
        "id": "4479",
        "AirportCode": "MBI",
        "AirportName": "Mbeya Airport",
        "City": "Mbeya",
        "Country": "Tanzania"
    },
    {
        "id": "4480",
        "AirportCode": "MBJ",
        "AirportName": "Sangster International Airport",
        "City": "Montego Bay",
        "Country": "Jamaica"
    },
    {
        "id": "4481",
        "AirportCode": "MBK",
        "AirportName": "Matupa Airport",
        "City": "Matupa",
        "Country": "Brazil"
    },
    {
        "id": "4482",
        "AirportCode": "MBL",
        "AirportName": "Blacker Airport",
        "City": "Manistee",
        "Country": "USA Michigan"
    },
    {
        "id": "4483",
        "AirportCode": "MBM",
        "AirportName": "Mkambati Airport",
        "City": "Mkambati",
        "Country": "South Africa"
    },
    {
        "id": "4484",
        "AirportCode": "MBN",
        "AirportName": "Mt. Barnett Airport",
        "City": "Mount Barnett",
        "Country": "Australia"
    },
    {
        "id": "4485",
        "AirportCode": "MBO",
        "AirportName": "Mamburao Airport",
        "City": "Mamburao",
        "Country": "Philippines"
    },
    {
        "id": "4486",
        "AirportCode": "MBP",
        "AirportName": "Moyobamba Airport",
        "City": "Moyobamba",
        "Country": "Peru"
    },
    {
        "id": "4487",
        "AirportCode": "MBQ",
        "AirportName": "Mbarara Airport",
        "City": "Mbarara",
        "Country": "Uganda"
    },
    {
        "id": "4488",
        "AirportCode": "MBR",
        "AirportName": "Mbout Airport",
        "City": "Mbout",
        "Country": "Mauritania"
    },
    {
        "id": "4489",
        "AirportCode": "MBS",
        "AirportName": "Tri City Airport",
        "City": "Saginaw",
        "Country": "USA Michigan"
    },
    {
        "id": "4490",
        "AirportCode": "MBT",
        "AirportName": "Masbate Airport",
        "City": "Masbate",
        "Country": "Philippines"
    },
    {
        "id": "4491",
        "AirportCode": "MBU",
        "AirportName": "Mbambanakira Airport",
        "City": "Mbambanakira",
        "Country": "Solomon Islands"
    },
    {
        "id": "4492",
        "AirportCode": "MBV",
        "AirportName": "Masa Airport",
        "City": "Masa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4493",
        "AirportCode": "MBW",
        "AirportName": "Moorabbin Airport",
        "City": "Moorabbin",
        "Country": "Australia"
    },
    {
        "id": "4494",
        "AirportCode": "MBX",
        "AirportName": "Maribor Airport",
        "City": "Maribor",
        "Country": "Slovenia"
    },
    {
        "id": "4495",
        "AirportCode": "MBY",
        "AirportName": "Moberly Airport",
        "City": "Moberly",
        "Country": "USA Missouri"
    },
    {
        "id": "4496",
        "AirportCode": "MBZ",
        "AirportName": "Maues Airport",
        "City": "Maues",
        "Country": "Brazil"
    },
    {
        "id": "4497",
        "AirportCode": "MCA",
        "AirportName": "Macenta Airport",
        "City": "Macenta",
        "Country": "Guinea"
    },
    {
        "id": "4498",
        "AirportCode": "MCB",
        "AirportName": "Pike County Airport",
        "City": "Mc Comb",
        "Country": "USA Mississippi"
    },
    {
        "id": "4499",
        "AirportCode": "MCC",
        "AirportName": "McClellan Air Force Base",
        "City": "Sacramento",
        "Country": "USA California"
    },
    {
        "id": "4500",
        "AirportCode": "MCD",
        "AirportName": "Mackinac Island Airport",
        "City": "Mackinac Island",
        "Country": "USA Michigan"
    },
    {
        "id": "4501",
        "AirportCode": "MCE",
        "AirportName": "Merced Municipal Airport",
        "City": "Merced",
        "Country": "USA California"
    },
    {
        "id": "4502",
        "AirportCode": "MCF",
        "AirportName": "Mac Dill Air Force Base",
        "City": "Tampa",
        "Country": "USA Florida"
    },
    {
        "id": "4503",
        "AirportCode": "MCG",
        "AirportName": "Mc Grath Airport",
        "City": "McGrath",
        "Country": "USA Alaska"
    },
    {
        "id": "4504",
        "AirportCode": "MCH",
        "AirportName": "Machala Airport",
        "City": "Machala",
        "Country": "Ecuador"
    },
    {
        "id": "4505",
        "AirportCode": "MCI",
        "AirportName": "Kansas City International Airport",
        "City": "Kansas City",
        "Country": "USA Missouri"
    },
    {
        "id": "4506",
        "AirportCode": "MCJ",
        "AirportName": "Maicao Airport",
        "City": "Maicao",
        "Country": "Colombia"
    },
    {
        "id": "4507",
        "AirportCode": "MCK",
        "AirportName": "Mc Cook Airport",
        "City": "McCook",
        "Country": "USA Nebraska"
    },
    {
        "id": "4508",
        "AirportCode": "MCM",
        "AirportName": "Monte Carlo Heliport",
        "City": "Monte Carlo",
        "Country": "Monaco"
    },
    {
        "id": "4509",
        "AirportCode": "MCN",
        "AirportName": "Lewis B. Wilson Airport",
        "City": "Macon",
        "Country": "USA Georgia"
    },
    {
        "id": "4510",
        "AirportCode": "MCO",
        "AirportName": "Orlando International Airport",
        "City": "Orlando",
        "Country": "USA Florida"
    },
    {
        "id": "4511",
        "AirportCode": "MCP",
        "AirportName": "Macapa Internacional Airport",
        "City": "Macapa",
        "Country": "Brazil"
    },
    {
        "id": "4512",
        "AirportCode": "MCQ",
        "AirportName": "Miskolc Airport",
        "City": "Miskolc",
        "Country": "Hungary"
    },
    {
        "id": "4513",
        "AirportCode": "MCR",
        "AirportName": "Melchor De Menco Airport",
        "City": "Melchor De Menco",
        "Country": "Guatemala"
    },
    {
        "id": "4514",
        "AirportCode": "MCS",
        "AirportName": "Monte Caseros Airport",
        "City": "Monte Caseros",
        "Country": "Argentina"
    },
    {
        "id": "4515",
        "AirportCode": "MCT",
        "AirportName": "Muscat International Airport",
        "City": "Muscat",
        "Country": "Oman"
    },
    {
        "id": "4516",
        "AirportCode": "MCU",
        "AirportName": "Gueret (Lepaud) Airport",
        "City": "Montlucon",
        "Country": "France"
    },
    {
        "id": "4517",
        "AirportCode": "MCV",
        "AirportName": "Mcarthur River Airport",
        "City": "Mcarthur River",
        "Country": "Australia"
    },
    {
        "id": "4518",
        "AirportCode": "MCW",
        "AirportName": "Mason City Airport",
        "City": "Mason City",
        "Country": "USA Iowa"
    },
    {
        "id": "4519",
        "AirportCode": "MCX",
        "AirportName": "Makhachkala Airport",
        "City": "Makhachkala",
        "Country": "Russia"
    },
    {
        "id": "4520",
        "AirportCode": "MCY",
        "AirportName": "Maroochydore Airport",
        "City": "Sunshine Coast",
        "Country": "Australia"
    },
    {
        "id": "4521",
        "AirportCode": "MCZ",
        "AirportName": "Palmares Airport",
        "City": "Maceio",
        "Country": "Brazil"
    },
    {
        "id": "4522",
        "AirportCode": "MDA",
        "AirportName": "Martindale Army Air Field",
        "City": "San Antonio",
        "Country": "USA Texas"
    },
    {
        "id": "4523",
        "AirportCode": "MDB",
        "AirportName": "Melinda Airport",
        "City": "Melinda",
        "Country": "Belize"
    },
    {
        "id": "4524",
        "AirportCode": "MDC",
        "AirportName": "Samratulangi Airport",
        "City": "Manado",
        "Country": "Indonesia"
    },
    {
        "id": "4525",
        "AirportCode": "MDD",
        "AirportName": "Midland Airpark",
        "City": "Midland",
        "Country": "USA Texas"
    },
    {
        "id": "4526",
        "AirportCode": "MDE",
        "AirportName": "Jose Maria Cordova",
        "City": "Medellin",
        "Country": "Colombia"
    },
    {
        "id": "4527",
        "AirportCode": "MDF",
        "AirportName": "Mooreland Municipal",
        "City": "Mooreland",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4528",
        "AirportCode": "MDF",
        "AirportName": "Medford Airport",
        "City": "Medford",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4529",
        "AirportCode": "MDG",
        "AirportName": "Mudanjiang Airport",
        "City": "Mudanjiang",
        "Country": "China"
    },
    {
        "id": "4530",
        "AirportCode": "MDH",
        "AirportName": "Southern Illinois Airport",
        "City": "Carbondale",
        "Country": "USA Illinois"
    },
    {
        "id": "4531",
        "AirportCode": "MDI",
        "AirportName": "Makurdi Airport",
        "City": "Makurdi",
        "Country": "Nigeria"
    },
    {
        "id": "4532",
        "AirportCode": "MDJ",
        "AirportName": "Madras City-County Airport",
        "City": "Madras",
        "Country": "USA Oregon"
    },
    {
        "id": "4533",
        "AirportCode": "MDK",
        "AirportName": "Mbandaka Airport",
        "City": "Mbandaka",
        "Country": "Congo, DR"
    },
    {
        "id": "4534",
        "AirportCode": "MDL",
        "AirportName": "Annisaton Airport",
        "City": "Mandalay",
        "Country": "Burma"
    },
    {
        "id": "4535",
        "AirportCode": "MDM",
        "AirportName": "Munduku Airport",
        "City": "Munduku",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4536",
        "AirportCode": "MDN",
        "AirportName": "Jefferson Proving Ground",
        "City": "Madison",
        "Country": "USA Indiana"
    },
    {
        "id": "4537",
        "AirportCode": "MDP",
        "AirportName": "Mindiptana Airport",
        "City": "Mindiptana",
        "Country": "Indonesia"
    },
    {
        "id": "4538",
        "AirportCode": "MDQ",
        "AirportName": "Mar Del Plata Airport",
        "City": "Mar Del Plata",
        "Country": "Argentina"
    },
    {
        "id": "4539",
        "AirportCode": "MDS",
        "AirportName": "Middle Caicos Airport",
        "City": "Middle Caicos",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "4540",
        "AirportCode": "MDT",
        "AirportName": "Harrisburg International Airport",
        "City": "Harrisburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4541",
        "AirportCode": "MDU",
        "AirportName": "Mendi Airport",
        "City": "Mendi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4542",
        "AirportCode": "MDV",
        "AirportName": "Medouneu Airport",
        "City": "Medouneu",
        "Country": "Gabon"
    },
    {
        "id": "4543",
        "AirportCode": "MDW",
        "AirportName": "Midway Airport",
        "City": "Chicago",
        "Country": "USA Illinois"
    },
    {
        "id": "4544",
        "AirportCode": "MDX",
        "AirportName": "Mercedes Airport",
        "City": "Mercedes",
        "Country": "Argentina"
    },
    {
        "id": "4545",
        "AirportCode": "MDY",
        "AirportName": "Sand Island Field",
        "City": "Midway Island",
        "Country": "Midway Island"
    },
    {
        "id": "4546",
        "AirportCode": "MDZ",
        "AirportName": "El Plumerillo Airport",
        "City": "Mendoza",
        "Country": "Argentina"
    },
    {
        "id": "4547",
        "AirportCode": "MEA",
        "AirportName": "Macae Airport",
        "City": "Macae",
        "Country": "Brazil"
    },
    {
        "id": "4548",
        "AirportCode": "MEB",
        "AirportName": "Essendon Airport",
        "City": "Melbourne",
        "Country": "Australia"
    },
    {
        "id": "4549",
        "AirportCode": "MEC",
        "AirportName": "Manta Airport",
        "City": "Manta",
        "Country": "Ecuador"
    },
    {
        "id": "4550",
        "AirportCode": "MED",
        "AirportName": "Mohammad Bin Abdulaziz Airport",
        "City": "Madinah",
        "Country": "Saudi Arabia"
    },
    {
        "id": "4551",
        "AirportCode": "MEE",
        "AirportName": "Mare Airport",
        "City": "Mare",
        "Country": "New Caledonia"
    },
    {
        "id": "4552",
        "AirportCode": "MEF",
        "AirportName": "Melfi Airport",
        "City": "Melfi",
        "Country": "Mali"
    },
    {
        "id": "4553",
        "AirportCode": "MEG",
        "AirportName": "Malange Airport",
        "City": "Malange",
        "Country": "Angola"
    },
    {
        "id": "4554",
        "AirportCode": "MEH",
        "AirportName": "Mehamn Airport",
        "City": "Mehamn",
        "Country": "Norway"
    },
    {
        "id": "4555",
        "AirportCode": "MEI",
        "AirportName": "Key Field",
        "City": "Meridian",
        "Country": "USA Mississippi"
    },
    {
        "id": "4556",
        "AirportCode": "MEJ",
        "AirportName": "Meadville Airport",
        "City": "Meadville",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4557",
        "AirportCode": "MEK",
        "AirportName": "Meknes Airport",
        "City": "Meknes",
        "Country": "Morocco"
    },
    {
        "id": "4558",
        "AirportCode": "MEL",
        "AirportName": "Tullamarine Airport",
        "City": "Melbourne All Airports",
        "Country": "Australia"
    },
    {
        "id": "4559",
        "AirportCode": "MEM",
        "AirportName": "Memphis International Airport",
        "City": "Memphis",
        "Country": "USA Tennessee"
    },
    {
        "id": "4560",
        "AirportCode": "MEN",
        "AirportName": "Brenoux Airport",
        "City": "Mende",
        "Country": "France"
    },
    {
        "id": "4561",
        "AirportCode": "MEO",
        "AirportName": "Dare County Regional Airport",
        "City": "Manteo",
        "Country": "USA North Carolina"
    },
    {
        "id": "4562",
        "AirportCode": "MEP",
        "AirportName": "Mersing Airport",
        "City": "Mersing",
        "Country": "Malaysia"
    },
    {
        "id": "4563",
        "AirportCode": "MEQ",
        "AirportName": "Seunagan Airport",
        "City": "Meulaboh",
        "Country": "Indonesia"
    },
    {
        "id": "4564",
        "AirportCode": "MER",
        "AirportName": "Castle Air Force Base",
        "City": "Merced",
        "Country": "USA California"
    },
    {
        "id": "4565",
        "AirportCode": "MES",
        "AirportName": "Polania Airport",
        "City": "Medan",
        "Country": "Indonesia"
    },
    {
        "id": "4566",
        "AirportCode": "MET",
        "AirportName": "Moreton Airport",
        "City": "Moreton",
        "Country": "Australia"
    },
    {
        "id": "4567",
        "AirportCode": "MEU",
        "AirportName": "Monte Dourado Airport",
        "City": "Monte Dourado",
        "Country": "Brazil"
    },
    {
        "id": "4568",
        "AirportCode": "MEV",
        "AirportName": "Douglas County Airport",
        "City": "Minden",
        "Country": "USA Nevada"
    },
    {
        "id": "4569",
        "AirportCode": "MEW",
        "AirportName": "Mweka Airport",
        "City": "Mweka",
        "Country": "Congo, DR"
    },
    {
        "id": "4570",
        "AirportCode": "MEX",
        "AirportName": "Juarez International Airport",
        "City": "Mexico City",
        "Country": "Mexico"
    },
    {
        "id": "4571",
        "AirportCode": "MEY",
        "AirportName": "Meghauli Airport",
        "City": "Meghauli",
        "Country": "Nepal"
    },
    {
        "id": "4572",
        "AirportCode": "MEZ",
        "AirportName": "Messina Airport",
        "City": "Messina",
        "Country": "South Africa"
    },
    {
        "id": "4573",
        "AirportCode": "MFA",
        "AirportName": "Mafia Airport",
        "City": "Mafia",
        "Country": "Tanzania"
    },
    {
        "id": "4574",
        "AirportCode": "MFB",
        "AirportName": "Monfort Airport",
        "City": "Monfort",
        "Country": "Colombia"
    },
    {
        "id": "4575",
        "AirportCode": "MFC",
        "AirportName": "Mafeteng Airport",
        "City": "Mafeteng",
        "Country": "Lesotho"
    },
    {
        "id": "4576",
        "AirportCode": "MFD",
        "AirportName": "Lahm Municipal Airport",
        "City": "Mansfield",
        "Country": "USA Ohio"
    },
    {
        "id": "4577",
        "AirportCode": "MFE",
        "AirportName": "Miller International Airport",
        "City": "McAllen",
        "Country": "USA Texas"
    },
    {
        "id": "4578",
        "AirportCode": "MFF",
        "AirportName": "Moanda Airport",
        "City": "Moanda",
        "Country": "Gabon"
    },
    {
        "id": "4579",
        "AirportCode": "MFG",
        "AirportName": "Muzaffarabad Airport",
        "City": "Muzaffarabad",
        "Country": "Pakistan"
    },
    {
        "id": "4580",
        "AirportCode": "MFH",
        "AirportName": "Mesquite Airport",
        "City": "Mesquite",
        "Country": "USA Nevada"
    },
    {
        "id": "4581",
        "AirportCode": "MFI",
        "AirportName": "Marshfield Municipal Airport",
        "City": "Marshfield",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4582",
        "AirportCode": "MFJ",
        "AirportName": "Moala Airport",
        "City": "Moala",
        "Country": "Fiji"
    },
    {
        "id": "4583",
        "AirportCode": "MFK",
        "AirportName": "Matsu Airport",
        "City": "Matsu",
        "Country": "Taiwan"
    },
    {
        "id": "4584",
        "AirportCode": "MFL",
        "AirportName": "Mount Full Stop Airport",
        "City": "Mount Full Stop",
        "Country": "Australia"
    },
    {
        "id": "4585",
        "AirportCode": "MFM",
        "AirportName": "Macau Airport",
        "City": "Macau",
        "Country": "Macau"
    },
    {
        "id": "4586",
        "AirportCode": "MFN",
        "AirportName": "Milford Sound Airport",
        "City": "Milford Sound",
        "Country": "New Zealand"
    },
    {
        "id": "4587",
        "AirportCode": "MFO",
        "AirportName": "Manguna Airport",
        "City": "Manguna",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4588",
        "AirportCode": "MFP",
        "AirportName": "Manners Creek Airport",
        "City": "Manners Creek",
        "Country": "Australia"
    },
    {
        "id": "4589",
        "AirportCode": "MFQ",
        "AirportName": "Maradi Airport",
        "City": "Maradi",
        "Country": "Niger"
    },
    {
        "id": "4590",
        "AirportCode": "MFR",
        "AirportName": "Rogue Valley International-Medford Airport",
        "City": "Medford",
        "Country": "USA Oregon"
    },
    {
        "id": "4591",
        "AirportCode": "MFS",
        "AirportName": "Miraflores Airport",
        "City": "Miraflores",
        "Country": "Colombia"
    },
    {
        "id": "4592",
        "AirportCode": "MFT",
        "AirportName": "Machu Picchu Airport",
        "City": "Machu Picchu",
        "Country": "Peru"
    },
    {
        "id": "4593",
        "AirportCode": "MFU",
        "AirportName": "Mfuwe Airport",
        "City": "Mfuwe",
        "Country": "Zambia"
    },
    {
        "id": "4594",
        "AirportCode": "MFV",
        "AirportName": "Accomack County Airport",
        "City": "Melfa",
        "Country": "USA Virginia"
    },
    {
        "id": "4595",
        "AirportCode": "MFW",
        "AirportName": "Magaruque Airport",
        "City": "Magaruque",
        "Country": "Mozambique"
    },
    {
        "id": "4596",
        "AirportCode": "MFX",
        "AirportName": "Meribel Airport",
        "City": "Meribel",
        "Country": "France"
    },
    {
        "id": "4597",
        "AirportCode": "MFZ",
        "AirportName": "Mesalia Airport",
        "City": "Mesalia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4598",
        "AirportCode": "MGA",
        "AirportName": "Augusto C Sandino Airport",
        "City": "Managua",
        "Country": "Nicaragua"
    },
    {
        "id": "4599",
        "AirportCode": "MGB",
        "AirportName": "Mount Gambier Airport",
        "City": "Mount Gambier",
        "Country": "Australia"
    },
    {
        "id": "4600",
        "AirportCode": "MGC",
        "AirportName": "Michigan City Airport",
        "City": "Michigan City",
        "Country": "USA Indiana"
    },
    {
        "id": "4601",
        "AirportCode": "MGD",
        "AirportName": "Magdalena Airport",
        "City": "Magdalena",
        "Country": "Bolivia"
    },
    {
        "id": "4602",
        "AirportCode": "MGE",
        "AirportName": "Dobbins Air Force Base",
        "City": "Marietta",
        "Country": "USA Georgia"
    },
    {
        "id": "4603",
        "AirportCode": "MGF",
        "AirportName": "Regional De Maringa",
        "City": "Maringa",
        "Country": "Brazil"
    },
    {
        "id": "4604",
        "AirportCode": "MGG",
        "AirportName": "Margarima Airport",
        "City": "Margarima",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4605",
        "AirportCode": "MGH",
        "AirportName": "Margate Airport",
        "City": "Margate",
        "Country": "South Africa"
    },
    {
        "id": "4606",
        "AirportCode": "MGI",
        "AirportName": "Matagorda Air Force Base",
        "City": "Matagorda Island",
        "Country": "USA Texas"
    },
    {
        "id": "4607",
        "AirportCode": "MGJ",
        "AirportName": "Orange County Airport",
        "City": "Montgomery",
        "Country": "USA New York"
    },
    {
        "id": "4608",
        "AirportCode": "MGK",
        "AirportName": "Mong Ton Airport",
        "City": "Mong Ton",
        "Country": "Burma"
    },
    {
        "id": "4609",
        "AirportCode": "MGL",
        "AirportName": "Moenchen-Gl. DUS Exp Airport",
        "City": "Dusseldorf",
        "Country": "Germany"
    },
    {
        "id": "4610",
        "AirportCode": "MGM",
        "AirportName": "Dannelly Field",
        "City": "Montgomery",
        "Country": "USA Alabama"
    },
    {
        "id": "4611",
        "AirportCode": "MGN",
        "AirportName": "Baracoa Airport",
        "City": "Magangue",
        "Country": "Colombia"
    },
    {
        "id": "4612",
        "AirportCode": "MGO",
        "AirportName": "Manega Airport",
        "City": "Manega",
        "Country": "Gabon"
    },
    {
        "id": "4613",
        "AirportCode": "MGP",
        "AirportName": "Manga Airport",
        "City": "Manga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4614",
        "AirportCode": "MGQ",
        "AirportName": "Mogadishu International Airport",
        "City": "Mogadishu",
        "Country": "Somalia"
    },
    {
        "id": "4615",
        "AirportCode": "MGR",
        "AirportName": "Thomasville Airport",
        "City": "Moultrie",
        "Country": "USA Georgia"
    },
    {
        "id": "4616",
        "AirportCode": "MGS",
        "AirportName": "Mangaia Island Airport",
        "City": "Mangaia Island",
        "Country": "Cook Islands"
    },
    {
        "id": "4617",
        "AirportCode": "MGT",
        "AirportName": "Milingimbi Airport",
        "City": "Milingimbi",
        "Country": "Australia"
    },
    {
        "id": "4618",
        "AirportCode": "MGU",
        "AirportName": "Manaung",
        "City": "Manaung",
        "Country": "Burma"
    },
    {
        "id": "4619",
        "AirportCode": "MGV",
        "AirportName": "Margaret River Station Airport",
        "City": "Margaret River Station",
        "Country": "Australia"
    },
    {
        "id": "4620",
        "AirportCode": "MGW",
        "AirportName": "Morgantown Airport",
        "City": "Morgantown",
        "Country": "USA West Virginia"
    },
    {
        "id": "4621",
        "AirportCode": "MGX",
        "AirportName": "Moabi Airport",
        "City": "Moabi",
        "Country": "Gabon"
    },
    {
        "id": "4622",
        "AirportCode": "MGY",
        "AirportName": "Montgomery County Airport",
        "City": "Dayton",
        "Country": "USA Ohio"
    },
    {
        "id": "4623",
        "AirportCode": "MGZ",
        "AirportName": "Myeik Airport",
        "City": "Myeik",
        "Country": "Burma"
    },
    {
        "id": "4624",
        "AirportCode": "MHA",
        "AirportName": "Mahdia Airport",
        "City": "Mahdia",
        "Country": "Guyana"
    },
    {
        "id": "4625",
        "AirportCode": "MHB",
        "AirportName": "Mechanics Bay Airport",
        "City": "Auckland (AKL)",
        "Country": "New Zealand"
    },
    {
        "id": "4626",
        "AirportCode": "MHD",
        "AirportName": "Mashad Airport",
        "City": "Mashad",
        "Country": "Iran"
    },
    {
        "id": "4627",
        "AirportCode": "MHE",
        "AirportName": "Mitchell Municipal Airport",
        "City": "Mitchell",
        "Country": "USA South Dakota"
    },
    {
        "id": "4628",
        "AirportCode": "MHF",
        "AirportName": "Morichal Airport",
        "City": "Morichal",
        "Country": "Colombia"
    },
    {
        "id": "4629",
        "AirportCode": "MHG",
        "AirportName": "Mannheim Airport",
        "City": "Mannheim",
        "Country": "Germany"
    },
    {
        "id": "4630",
        "AirportCode": "MHH",
        "AirportName": "Marsh Harbour International Airport",
        "City": "Marsh Harbour",
        "Country": "Bahamas"
    },
    {
        "id": "4631",
        "AirportCode": "MHI",
        "AirportName": "Musha Airport",
        "City": "Musha",
        "Country": "Djibouti"
    },
    {
        "id": "4632",
        "AirportCode": "MHJ",
        "AirportName": "Misrak Gashamo Airport",
        "City": "Misrak Gashamo",
        "Country": "Ethiopia"
    },
    {
        "id": "4633",
        "AirportCode": "MHK",
        "AirportName": "Manhattan Municipal Airport",
        "City": "Manhattan",
        "Country": "USA Kansas"
    },
    {
        "id": "4634",
        "AirportCode": "MHL",
        "AirportName": "Memorial Municipal Airport",
        "City": "Marshall",
        "Country": "USA Missouri"
    },
    {
        "id": "4635",
        "AirportCode": "MHN",
        "AirportName": "Mullen Airport",
        "City": "Mullen",
        "Country": "USA Nebraska"
    },
    {
        "id": "4636",
        "AirportCode": "MHO",
        "AirportName": "Mount House Airport",
        "City": "Mount House",
        "Country": "Australia"
    },
    {
        "id": "4637",
        "AirportCode": "MHP",
        "AirportName": "Minsk International 1 Airport",
        "City": "Minsk",
        "Country": "Belarus"
    },
    {
        "id": "4638",
        "AirportCode": "MHQ",
        "AirportName": "Mariehamn Airport",
        "City": "Mariehamn",
        "Country": "Aland"
    },
    {
        "id": "4639",
        "AirportCode": "MHR",
        "AirportName": "Mather Air Force Base",
        "City": "Sacramento",
        "Country": "USA California"
    },
    {
        "id": "4640",
        "AirportCode": "MHS",
        "AirportName": "Rep Airport",
        "City": "Mount Shasta",
        "Country": "USA California"
    },
    {
        "id": "4641",
        "AirportCode": "MHT",
        "AirportName": "Manchester Municipal Airport",
        "City": "Manchester",
        "Country": "USA New Hampshire"
    },
    {
        "id": "4642",
        "AirportCode": "MHU",
        "AirportName": "Mount Hotham",
        "City": "Mount Hotham",
        "Country": "Australia"
    },
    {
        "id": "4643",
        "AirportCode": "MHV",
        "AirportName": "Kern County Airport",
        "City": "Mojave",
        "Country": "USA California"
    },
    {
        "id": "4644",
        "AirportCode": "MHW",
        "AirportName": "Monteagudo Airport",
        "City": "Monteagudo",
        "Country": "Bolivia"
    },
    {
        "id": "4645",
        "AirportCode": "MHX",
        "AirportName": "Manihiki Island Airport",
        "City": "Manihiki Island",
        "Country": "Cook Islands"
    },
    {
        "id": "4646",
        "AirportCode": "MHY",
        "AirportName": "Morehead Airport",
        "City": "Morehead",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4647",
        "AirportCode": "MHZ",
        "AirportName": "Mildenhall Airport",
        "City": "Mildenhall",
        "Country": "United Kingdom"
    },
    {
        "id": "4648",
        "AirportCode": "MIA",
        "AirportName": "Miami International Airport",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "4649",
        "AirportCode": "MIB",
        "AirportName": "Minot Air Force Base",
        "City": "Minot",
        "Country": "USA North Dakota"
    },
    {
        "id": "4650",
        "AirportCode": "MIC",
        "AirportName": "Crystal Airport",
        "City": "Minneapolis",
        "Country": "USA Minnesota"
    },
    {
        "id": "4651",
        "AirportCode": "MID",
        "AirportName": "Rejon Airport",
        "City": "Merida",
        "Country": "Mexico"
    },
    {
        "id": "4652",
        "AirportCode": "MIE",
        "AirportName": "Delaware County Airport",
        "City": "Muncie",
        "Country": "USA Indiana"
    },
    {
        "id": "4653",
        "AirportCode": "MIF",
        "AirportName": "Roy Hurd Memorial Airport",
        "City": "Monahans",
        "Country": "USA Texas"
    },
    {
        "id": "4654",
        "AirportCode": "MIG",
        "AirportName": "Mian Yang Airport",
        "City": "Mian Yang",
        "Country": "China"
    },
    {
        "id": "4655",
        "AirportCode": "MIH",
        "AirportName": "Mitchell Plateau Airport",
        "City": "Mitchell Plateau",
        "Country": "Australia"
    },
    {
        "id": "4656",
        "AirportCode": "MII",
        "AirportName": "Dr Gastao Vidigal Airport",
        "City": "Marilia",
        "Country": "Brazil"
    },
    {
        "id": "4657",
        "AirportCode": "MIJ",
        "AirportName": "Mili Island Airport",
        "City": "Mili Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "4658",
        "AirportCode": "MIK",
        "AirportName": "Mikkeli Airport",
        "City": "Mikkeli",
        "Country": "Finland"
    },
    {
        "id": "4659",
        "AirportCode": "MIM",
        "AirportName": "Merimbula Airport",
        "City": "Merimbula",
        "Country": "Australia"
    },
    {
        "id": "4660",
        "AirportCode": "MIN",
        "AirportName": "Minnipa Airport",
        "City": "Minnipa",
        "Country": "Australia"
    },
    {
        "id": "4661",
        "AirportCode": "MIO",
        "AirportName": "Miami Airport",
        "City": "Miami (MIA)",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4662",
        "AirportCode": "MIQ",
        "AirportName": "Millard Airport",
        "City": "Omaha",
        "Country": "USA Nebraska"
    },
    {
        "id": "4663",
        "AirportCode": "MIR",
        "AirportName": "Habib Bourguiba International Airport",
        "City": "Monastir",
        "Country": "Tunisia"
    },
    {
        "id": "4664",
        "AirportCode": "MIS",
        "AirportName": "Misima Island Airport",
        "City": "Misima Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4665",
        "AirportCode": "MIT",
        "AirportName": "Kern County Airport",
        "City": "Shafter",
        "Country": "USA California"
    },
    {
        "id": "4666",
        "AirportCode": "MIU",
        "AirportName": "Maiduguri Airport",
        "City": "Maiduguri",
        "Country": "Nigeria"
    },
    {
        "id": "4667",
        "AirportCode": "MIV",
        "AirportName": "Millville Airport",
        "City": "Millville",
        "Country": "USA New Jersey"
    },
    {
        "id": "4668",
        "AirportCode": "MIW",
        "AirportName": "Marshalltown Municipal Airport",
        "City": "Marshalltown",
        "Country": "USA Iowa"
    },
    {
        "id": "4669",
        "AirportCode": "MIX",
        "AirportName": "Miriti Airport",
        "City": "Miriti",
        "Country": "Colombia"
    },
    {
        "id": "4670",
        "AirportCode": "MIZ",
        "AirportName": "Mainoru Airport",
        "City": "Mainoru",
        "Country": "Australia"
    },
    {
        "id": "4671",
        "AirportCode": "MJA",
        "AirportName": "Manja Airport",
        "City": "Manja",
        "Country": "Madagascar"
    },
    {
        "id": "4672",
        "AirportCode": "MJB",
        "AirportName": "Mejit Island Airport",
        "City": "Mejit Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "4673",
        "AirportCode": "MJD",
        "AirportName": "Mohenjo Daro Airport",
        "City": "Mohenjo Daro",
        "Country": "Pakistan"
    },
    {
        "id": "4674",
        "AirportCode": "MJE",
        "AirportName": "Majkin Airport",
        "City": "Majkin",
        "Country": "Marshall Islands"
    },
    {
        "id": "4675",
        "AirportCode": "MJF",
        "AirportName": "Kjaerstad Airport",
        "City": "Mosjoen",
        "Country": "Norway"
    },
    {
        "id": "4676",
        "AirportCode": "MJG",
        "AirportName": "Mayajigua Airport",
        "City": "Mayajigua",
        "Country": "Cuba"
    },
    {
        "id": "4677",
        "AirportCode": "MJH",
        "AirportName": "Majma Airport",
        "City": "Majma",
        "Country": "Saudi Arabia"
    },
    {
        "id": "4678",
        "AirportCode": "MJI",
        "AirportName": "Mitiga, Tripoli Airport",
        "City": "Mitiga, Tripoli",
        "Country": "Libya"
    },
    {
        "id": "4679",
        "AirportCode": "MJJ",
        "AirportName": "Moki Airport",
        "City": "Moki",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4680",
        "AirportCode": "MJK",
        "AirportName": "Shark Bay Airport",
        "City": "Monkey Mia",
        "Country": "Australia"
    },
    {
        "id": "4681",
        "AirportCode": "MJL",
        "AirportName": "Mouila Airport",
        "City": "Mouila",
        "Country": "Gabon"
    },
    {
        "id": "4682",
        "AirportCode": "MJM",
        "AirportName": "Mbuji Mayi Airport",
        "City": "Mbuji Mayi",
        "Country": "Congo, DR"
    },
    {
        "id": "4683",
        "AirportCode": "MJN",
        "AirportName": "Amborovy Airport",
        "City": "Majunga",
        "Country": "Madagascar"
    },
    {
        "id": "4684",
        "AirportCode": "MJO",
        "AirportName": "Mount Etjo Lodge Airport",
        "City": "Mount Etjo Lodge",
        "Country": "Namibia"
    },
    {
        "id": "4685",
        "AirportCode": "MJP",
        "AirportName": "Manjimup Airport",
        "City": "Manjimup",
        "Country": "Australia"
    },
    {
        "id": "4686",
        "AirportCode": "MJQ",
        "AirportName": "Jackson Airport",
        "City": "Jackson",
        "Country": "USA Minnesota"
    },
    {
        "id": "4687",
        "AirportCode": "MJR",
        "AirportName": "Miramar Airport",
        "City": "Miramar",
        "Country": "Argentina"
    },
    {
        "id": "4688",
        "AirportCode": "MJT",
        "AirportName": "Mytilene Airport",
        "City": "Mytilene",
        "Country": "Greece"
    },
    {
        "id": "4689",
        "AirportCode": "MJU",
        "AirportName": "Mamuju Airport",
        "City": "Mamuju",
        "Country": "Indonesia"
    },
    {
        "id": "4690",
        "AirportCode": "MJV",
        "AirportName": "San Javier Airport",
        "City": "Murcia",
        "Country": "Spain"
    },
    {
        "id": "4691",
        "AirportCode": "MJW",
        "AirportName": "Mahenye Airfield",
        "City": "Mahenye",
        "Country": "Zimbabwe"
    },
    {
        "id": "4692",
        "AirportCode": "MJX",
        "AirportName": "Robert J. Miller Airport",
        "City": "Toms River",
        "Country": "USA New Jersey"
    },
    {
        "id": "4693",
        "AirportCode": "MJY",
        "AirportName": "Mangunjaya Airport",
        "City": "Mangunjaya",
        "Country": "Indonesia"
    },
    {
        "id": "4694",
        "AirportCode": "MJZ",
        "AirportName": "Mirnyj Airport",
        "City": "Mirnyj",
        "Country": "Russia"
    },
    {
        "id": "4695",
        "AirportCode": "MKA",
        "AirportName": "Marianske Lazne Airport",
        "City": "Marianske Lazne",
        "Country": "Czech Republic"
    },
    {
        "id": "4696",
        "AirportCode": "MKB",
        "AirportName": "Mekambo Airport",
        "City": "Mekambo",
        "Country": "Gabon"
    },
    {
        "id": "4697",
        "AirportCode": "MKC",
        "AirportName": "Kansas City Downtown Municipal Airport",
        "City": "Kansas City",
        "Country": "USA Missouri"
    },
    {
        "id": "4698",
        "AirportCode": "MKD",
        "AirportName": "Chagni Airport",
        "City": "Chagni",
        "Country": "Ethiopia"
    },
    {
        "id": "4699",
        "AirportCode": "MKE",
        "AirportName": "General Mitchell Airport",
        "City": "Milwaukee",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4700",
        "AirportCode": "MKF",
        "AirportName": "McKenna Army Air Field",
        "City": "Columbus",
        "Country": "USA Georgia"
    },
    {
        "id": "4701",
        "AirportCode": "MKG",
        "AirportName": "Muskegon Airport",
        "City": "Muskegon",
        "Country": "USA Michigan"
    },
    {
        "id": "4702",
        "AirportCode": "MKH",
        "AirportName": "Mokhotlong Airport",
        "City": "Mokhotlong",
        "Country": "Lesotho"
    },
    {
        "id": "4703",
        "AirportCode": "MKJ",
        "AirportName": "Makoua Airport",
        "City": "Makoua",
        "Country": "Congo"
    },
    {
        "id": "4704",
        "AirportCode": "MKK",
        "AirportName": "Molokai Airport",
        "City": "Hoolehua",
        "Country": "USA Hawaii"
    },
    {
        "id": "4705",
        "AirportCode": "MKL",
        "AirportName": "Mckellar Airport",
        "City": "Jackson",
        "Country": "USA Tennessee"
    },
    {
        "id": "4706",
        "AirportCode": "MKM",
        "AirportName": "Mukah Airport",
        "City": "Mukah",
        "Country": "Malaysia"
    },
    {
        "id": "4707",
        "AirportCode": "MKN",
        "AirportName": "Malekolon Airport",
        "City": "Malekolon",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4708",
        "AirportCode": "MKO",
        "AirportName": "Davis Field",
        "City": "Muskogee",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4709",
        "AirportCode": "MKP",
        "AirportName": "Makemo Airport",
        "City": "Makemo",
        "Country": "French Polynesia"
    },
    {
        "id": "4710",
        "AirportCode": "MKQ",
        "AirportName": "Mopah Airport",
        "City": "Merauke",
        "Country": "Indonesia"
    },
    {
        "id": "4711",
        "AirportCode": "MKR",
        "AirportName": "Meekatharra Airport",
        "City": "Meekatharra",
        "Country": "Australia"
    },
    {
        "id": "4712",
        "AirportCode": "MKS",
        "AirportName": "Mekane Selam Airport",
        "City": "Mekane Selam",
        "Country": "Ethiopia"
    },
    {
        "id": "4713",
        "AirportCode": "MKT",
        "AirportName": "Mankato Municipal Airport",
        "City": "Mankato",
        "Country": "USA Minnesota"
    },
    {
        "id": "4714",
        "AirportCode": "MKU",
        "AirportName": "Makokou Airport",
        "City": "Makokou",
        "Country": "Gabon"
    },
    {
        "id": "4715",
        "AirportCode": "MKV",
        "AirportName": "Mt. Cavenagh Airport",
        "City": "Mount Cavenagh",
        "Country": "Australia"
    },
    {
        "id": "4716",
        "AirportCode": "MKW",
        "AirportName": "Rendani Airport",
        "City": "Manokwari",
        "Country": "Indonesia"
    },
    {
        "id": "4717",
        "AirportCode": "MKX",
        "AirportName": "Mukalla Airport",
        "City": "Mukalla",
        "Country": "Yemen"
    },
    {
        "id": "4718",
        "AirportCode": "MKY",
        "AirportName": "Mackay Airport",
        "City": "Mackay",
        "Country": "Australia"
    },
    {
        "id": "4719",
        "AirportCode": "MKZ",
        "AirportName": "Batu Berendum Airport",
        "City": "Malacca",
        "Country": "Malaysia"
    },
    {
        "id": "4720",
        "AirportCode": "MLA",
        "AirportName": "Luqa Airport",
        "City": "Malta",
        "Country": "Malta"
    },
    {
        "id": "4721",
        "AirportCode": "MLB",
        "AirportName": "Melbourne International Airport",
        "City": "Melbourne",
        "Country": "USA Florida"
    },
    {
        "id": "4722",
        "AirportCode": "MLC",
        "AirportName": "Mc Alester Airport",
        "City": "Mc Alester",
        "Country": "USA Oklahoma"
    },
    {
        "id": "4723",
        "AirportCode": "MLD",
        "AirportName": "Malad City Airport",
        "City": "Malad City",
        "Country": "USA Idaho"
    },
    {
        "id": "4724",
        "AirportCode": "MLE",
        "AirportName": "Male International Airport",
        "City": "Male",
        "Country": "Maldives"
    },
    {
        "id": "4725",
        "AirportCode": "MLF",
        "AirportName": "Milford Airport",
        "City": "Milford",
        "Country": "USA Utah"
    },
    {
        "id": "4726",
        "AirportCode": "MLG",
        "AirportName": "Malang Airport",
        "City": "Malang",
        "Country": "Indonesia"
    },
    {
        "id": "4727",
        "AirportCode": "MLH",
        "AirportName": "EuroAirport French",
        "City": "Mulhouse, France/Basel",
        "Country": "Switzerland"
    },
    {
        "id": "4728",
        "AirportCode": "MLI",
        "AirportName": "Quad-City Airport",
        "City": "Moline",
        "Country": "USA Illinois"
    },
    {
        "id": "4729",
        "AirportCode": "MLJ",
        "AirportName": "Baldwin County Airport",
        "City": "Milledgeville",
        "Country": "USA Georgia"
    },
    {
        "id": "4730",
        "AirportCode": "MLK",
        "AirportName": "Malta",
        "City": "Malta",
        "Country": "USA Montana"
    },
    {
        "id": "4731",
        "AirportCode": "MLL",
        "AirportName": "Marshall Airport",
        "City": "Marshall",
        "Country": "USA Alaska"
    },
    {
        "id": "4732",
        "AirportCode": "MLM",
        "AirportName": "Morelia Airport",
        "City": "Morelia",
        "Country": "Mexico"
    },
    {
        "id": "4733",
        "AirportCode": "MLN",
        "AirportName": "Melilla Airport",
        "City": "Melilla",
        "Country": "Spain"
    },
    {
        "id": "4734",
        "AirportCode": "MLO",
        "AirportName": "Milos Airport",
        "City": "Milos",
        "Country": "Greece"
    },
    {
        "id": "4735",
        "AirportCode": "MLP",
        "AirportName": "Malabang Airport",
        "City": "Malabang",
        "Country": "Philippines"
    },
    {
        "id": "4736",
        "AirportCode": "MLQ",
        "AirportName": "Malalaua Airport",
        "City": "Malalaua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4737",
        "AirportCode": "MLR",
        "AirportName": "Millicent Airport",
        "City": "Millicent",
        "Country": "Australia"
    },
    {
        "id": "4738",
        "AirportCode": "MLS",
        "AirportName": "Miles City Municipal Airport",
        "City": "Miles City",
        "Country": "USA Montana"
    },
    {
        "id": "4739",
        "AirportCode": "MLT",
        "AirportName": "Millinocket Airport",
        "City": "Millinocket",
        "Country": "USA Maine"
    },
    {
        "id": "4740",
        "AirportCode": "MLU",
        "AirportName": "Monroe Regional Airport",
        "City": "Monroe",
        "Country": "USA Louisiana"
    },
    {
        "id": "4741",
        "AirportCode": "MLV",
        "AirportName": "Merluna Airport",
        "City": "Merluna",
        "Country": "Australia"
    },
    {
        "id": "4742",
        "AirportCode": "MLW",
        "AirportName": "Sprigg Payne Airport",
        "City": "Monrovia",
        "Country": "Liberia"
    },
    {
        "id": "4743",
        "AirportCode": "MLX",
        "AirportName": "Malatya Airport",
        "City": "Malatya",
        "Country": "Turkey"
    },
    {
        "id": "4744",
        "AirportCode": "MLY",
        "AirportName": "Manley Hot Springs Airport",
        "City": "Manley Hot Springs",
        "Country": "USA Alaska"
    },
    {
        "id": "4745",
        "AirportCode": "MLZ",
        "AirportName": "Melo Airport",
        "City": "Melo",
        "Country": "Uruguay"
    },
    {
        "id": "4746",
        "AirportCode": "MMB",
        "AirportName": "Memanbetsu Airport",
        "City": "Memanbetsu",
        "Country": "Japan"
    },
    {
        "id": "4747",
        "AirportCode": "MMC",
        "AirportName": "Ciudad Mante Airport",
        "City": "Ciudad Mante",
        "Country": "Mexico"
    },
    {
        "id": "4748",
        "AirportCode": "MMD",
        "AirportName": "Maridor Airport",
        "City": "Minami Daito",
        "Country": "Japan"
    },
    {
        "id": "4749",
        "AirportCode": "MME",
        "AirportName": "Durham Tees Valley Airport",
        "City": "Durham Tees Valley",
        "Country": "United Kingdom"
    },
    {
        "id": "4750",
        "AirportCode": "MMF",
        "AirportName": "Mamfe Airport",
        "City": "Mamfe",
        "Country": "Cameroon"
    },
    {
        "id": "4751",
        "AirportCode": "MMG",
        "AirportName": "Mount Magnet Airport",
        "City": "Mount Magnet",
        "Country": "Australia"
    },
    {
        "id": "4752",
        "AirportCode": "MMH",
        "AirportName": "Mammoth Lakes Airport",
        "City": "Mammoth Lakes",
        "Country": "USA California"
    },
    {
        "id": "4753",
        "AirportCode": "MMI",
        "AirportName": "McMinn County Airport",
        "City": "Athens",
        "Country": "USA Tennessee"
    },
    {
        "id": "4754",
        "AirportCode": "MMJ",
        "AirportName": "Matsumoto Airport",
        "City": "Matsumoto",
        "Country": "Japan"
    },
    {
        "id": "4755",
        "AirportCode": "MMK",
        "AirportName": "Murmansk Airport",
        "City": "Murmansk",
        "Country": "Russia"
    },
    {
        "id": "4756",
        "AirportCode": "MML",
        "AirportName": "Marshall Municipal Airport-Ryan Field",
        "City": "Marshall",
        "Country": "USA Minnesota"
    },
    {
        "id": "4757",
        "AirportCode": "MMM",
        "AirportName": "Middlemount Airport",
        "City": "Middlemount",
        "Country": "Australia"
    },
    {
        "id": "4758",
        "AirportCode": "MMN",
        "AirportName": "Minute Man Airfield",
        "City": "Stow",
        "Country": "USA Massachusetts"
    },
    {
        "id": "4759",
        "AirportCode": "MMO",
        "AirportName": "Vila Do Maio Airport",
        "City": "Maio",
        "Country": "Cape Verde"
    },
    {
        "id": "4760",
        "AirportCode": "MMP",
        "AirportName": "Mompos Airport",
        "City": "Mompos",
        "Country": "Colombia"
    },
    {
        "id": "4761",
        "AirportCode": "MMQ",
        "AirportName": "Mbala Airport",
        "City": "Mbala",
        "Country": "Zambia"
    },
    {
        "id": "4762",
        "AirportCode": "MMR",
        "AirportName": "Camp Maybry Army Heliport",
        "City": "Austin",
        "Country": "USA Texas"
    },
    {
        "id": "4763",
        "AirportCode": "MMS",
        "AirportName": "Selfs Airport",
        "City": "Marks",
        "Country": "USA Mississippi"
    },
    {
        "id": "4764",
        "AirportCode": "MMT",
        "AirportName": "Mc Entire Air National Guard Base",
        "City": "Columbia",
        "Country": "USA South Carolina"
    },
    {
        "id": "4765",
        "AirportCode": "MMU",
        "AirportName": "Morristown Municipal Airport",
        "City": "Morristown",
        "Country": "USA New Jersey"
    },
    {
        "id": "4766",
        "AirportCode": "MMW",
        "AirportName": "Moma Airport",
        "City": "Moma",
        "Country": "Mozambique"
    },
    {
        "id": "4767",
        "AirportCode": "MMX",
        "AirportName": "Sturup Airport",
        "City": "Malmo",
        "Country": "Sweden"
    },
    {
        "id": "4768",
        "AirportCode": "MMY",
        "AirportName": "Hirara Airport",
        "City": "Miyako",
        "Country": "Japan"
    },
    {
        "id": "4769",
        "AirportCode": "MMZ",
        "AirportName": "Maimana Airport",
        "City": "Maimana",
        "Country": "Afghanistan"
    },
    {
        "id": "4770",
        "AirportCode": "MNA",
        "AirportName": "Melangguane Airport",
        "City": "Melangguane",
        "Country": "Indonesia"
    },
    {
        "id": "4771",
        "AirportCode": "MNB",
        "AirportName": "Moanda Airport",
        "City": "Moanda",
        "Country": "Congo, DR"
    },
    {
        "id": "4772",
        "AirportCode": "MNC",
        "AirportName": "Nacala Airport",
        "City": "Nacala",
        "Country": "Mozambique"
    },
    {
        "id": "4773",
        "AirportCode": "MND",
        "AirportName": "Medina Airport",
        "City": "Medina",
        "Country": "Colombia"
    },
    {
        "id": "4774",
        "AirportCode": "MNE",
        "AirportName": "Mungerannie Airport",
        "City": "Mungerannie",
        "Country": "Australia"
    },
    {
        "id": "4775",
        "AirportCode": "MNF",
        "AirportName": "Mana Island Airstrip",
        "City": "Mana Island",
        "Country": "Fiji"
    },
    {
        "id": "4776",
        "AirportCode": "MNG",
        "AirportName": "Maningrida Airport",
        "City": "Maningrida",
        "Country": "Australia"
    },
    {
        "id": "4777",
        "AirportCode": "MNH",
        "AirportName": "Minneriya Airport",
        "City": "Minneriya",
        "Country": "Sri Lanka"
    },
    {
        "id": "4778",
        "AirportCode": "MNI",
        "AirportName": "Bramble Airport",
        "City": "Montserrat",
        "Country": "Montserrat"
    },
    {
        "id": "4779",
        "AirportCode": "MNJ",
        "AirportName": "Mananjary Airport",
        "City": "Mananjary",
        "Country": "Madagascar"
    },
    {
        "id": "4780",
        "AirportCode": "MNK",
        "AirportName": "Maiana Airport",
        "City": "Maiana",
        "Country": "Kiribati"
    },
    {
        "id": "4781",
        "AirportCode": "MNL",
        "AirportName": "Ninoy Aquino International Airport",
        "City": "Manila",
        "Country": "Philippines"
    },
    {
        "id": "4782",
        "AirportCode": "MNM",
        "AirportName": "Menominee Airport",
        "City": "Menominee",
        "Country": "USA Michigan"
    },
    {
        "id": "4783",
        "AirportCode": "MNN",
        "AirportName": "Marion Municipal Airport",
        "City": "Marion",
        "Country": "USA Ohio"
    },
    {
        "id": "4784",
        "AirportCode": "MNO",
        "AirportName": "Manono Airport",
        "City": "Manono",
        "Country": "Congo, DR"
    },
    {
        "id": "4785",
        "AirportCode": "MNQ",
        "AirportName": "Monto Airport",
        "City": "Monto",
        "Country": "Australia"
    },
    {
        "id": "4786",
        "AirportCode": "MNR",
        "AirportName": "Mongu Airport",
        "City": "Mongu",
        "Country": "Zambia"
    },
    {
        "id": "4787",
        "AirportCode": "MNS",
        "AirportName": "Mansa Airport",
        "City": "Mansa",
        "Country": "Zambia"
    },
    {
        "id": "4788",
        "AirportCode": "MNT",
        "AirportName": "Minto Airport",
        "City": "Minto",
        "Country": "USA Alaska"
    },
    {
        "id": "4789",
        "AirportCode": "MNU",
        "AirportName": "Maulmyine Airport",
        "City": "Maulmyine",
        "Country": "Burma"
    },
    {
        "id": "4790",
        "AirportCode": "MNV",
        "AirportName": "Mountain Valley Airport",
        "City": "Mountain Valley",
        "Country": "Australia"
    },
    {
        "id": "4791",
        "AirportCode": "MNW",
        "AirportName": "Macdonald Downs Airport",
        "City": "Macdonald Downs",
        "Country": "Australia"
    },
    {
        "id": "4792",
        "AirportCode": "MNX",
        "AirportName": "Manicore Airport",
        "City": "Manicore",
        "Country": "Brazil"
    },
    {
        "id": "4793",
        "AirportCode": "MNY",
        "AirportName": "Mono Airport",
        "City": "Mono",
        "Country": "Solomon Islands"
    },
    {
        "id": "4794",
        "AirportCode": "MNZ",
        "AirportName": "Manassas Airport",
        "City": "Manassas",
        "Country": "USA Virginia"
    },
    {
        "id": "4795",
        "AirportCode": "MOA",
        "AirportName": "Orestes Acosta Airport",
        "City": "Moa",
        "Country": "Cuba"
    },
    {
        "id": "4796",
        "AirportCode": "MOB",
        "AirportName": "Mobile Municipal Airport",
        "City": "Mobile",
        "Country": "USA Alabama"
    },
    {
        "id": "4797",
        "AirportCode": "MOC",
        "AirportName": "Montes Claros Airport",
        "City": "Montes Claros",
        "Country": "Brazil"
    },
    {
        "id": "4798",
        "AirportCode": "MOD",
        "AirportName": "Modesto Municipal Airport",
        "City": "Modesto",
        "Country": "USA California"
    },
    {
        "id": "4799",
        "AirportCode": "MOE",
        "AirportName": "Momeik Airport",
        "City": "Momeik",
        "Country": "Burma"
    },
    {
        "id": "4800",
        "AirportCode": "MOF",
        "AirportName": "Waioti Airport",
        "City": "Maumere",
        "Country": "Indonesia"
    },
    {
        "id": "4801",
        "AirportCode": "MOG",
        "AirportName": "Mong Hsat Airport",
        "City": "Mong Hsat",
        "Country": "Burma"
    },
    {
        "id": "4802",
        "AirportCode": "MOH",
        "AirportName": "Mohanbari Airport",
        "City": "Mohanbari",
        "Country": "India"
    },
    {
        "id": "4803",
        "AirportCode": "MOI",
        "AirportName": "Mitiaro Island Airport",
        "City": "Mitiaro Island",
        "Country": "Cook Islands"
    },
    {
        "id": "4804",
        "AirportCode": "MOJ",
        "AirportName": "Moengo Airport",
        "City": "Moengo",
        "Country": "Suriname"
    },
    {
        "id": "4805",
        "AirportCode": "MOL",
        "AirportName": "Aro Airport",
        "City": "Molde",
        "Country": "Norway"
    },
    {
        "id": "4806",
        "AirportCode": "MOM",
        "AirportName": "Moudjeria Airport",
        "City": "Moudjeria",
        "Country": "Mauritania"
    },
    {
        "id": "4807",
        "AirportCode": "MON",
        "AirportName": "Mount Cook Airport",
        "City": "Mount Cook",
        "Country": "New Zealand"
    },
    {
        "id": "4808",
        "AirportCode": "MOO",
        "AirportName": "Moomba Airport",
        "City": "Moomba",
        "Country": "Australia"
    },
    {
        "id": "4809",
        "AirportCode": "MOP",
        "AirportName": "Mount Pleasant Municipal Airport",
        "City": "Mount Pleasant",
        "Country": "USA Michigan"
    },
    {
        "id": "4810",
        "AirportCode": "MOQ",
        "AirportName": "Morondava Airport",
        "City": "Morondava",
        "Country": "Madagascar"
    },
    {
        "id": "4811",
        "AirportCode": "MOR",
        "AirportName": "Moore-Murrell Airport",
        "City": "Morristown",
        "Country": "USA Tennessee"
    },
    {
        "id": "4812",
        "AirportCode": "MOS",
        "AirportName": "Moses Point Intermediate Airport",
        "City": "Moses Point",
        "Country": "USA Alaska"
    },
    {
        "id": "4813",
        "AirportCode": "MOT",
        "AirportName": "Minot International Airport",
        "City": "Minot",
        "Country": "USA North Dakota"
    },
    {
        "id": "4814",
        "AirportCode": "MOU",
        "AirportName": "Mountain Village Airport",
        "City": "Mountain Village",
        "Country": "USA Alaska"
    },
    {
        "id": "4815",
        "AirportCode": "MOV",
        "AirportName": "Moranbah Airport",
        "City": "Moranbah",
        "Country": "Australia"
    },
    {
        "id": "4816",
        "AirportCode": "MOX",
        "AirportName": "Morris Municipal Airport",
        "City": "Morris",
        "Country": "USA Minnesota"
    },
    {
        "id": "4817",
        "AirportCode": "MOY",
        "AirportName": "Monterrey Airport",
        "City": "Monterrey",
        "Country": "Colombia"
    },
    {
        "id": "4818",
        "AirportCode": "MOZ",
        "AirportName": "Temae Airport",
        "City": "Moorea",
        "Country": "French Polynesia"
    },
    {
        "id": "4819",
        "AirportCode": "MPA",
        "AirportName": "Mpacha Airport",
        "City": "Mpacha",
        "Country": "Namibia"
    },
    {
        "id": "4820",
        "AirportCode": "MPB",
        "AirportName": "Miami Sea Plane Base",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "4821",
        "AirportCode": "MPC",
        "AirportName": "Muko-Muko Airport",
        "City": "Muko-Muko",
        "Country": "Indonesia"
    },
    {
        "id": "4822",
        "AirportCode": "MPD",
        "AirportName": "Mirpur Khas Airport",
        "City": "Mirpur Khas",
        "Country": "Pakistan"
    },
    {
        "id": "4823",
        "AirportCode": "MPE",
        "AirportName": "Madison Airport",
        "City": "Madison",
        "Country": "USA Connecticut"
    },
    {
        "id": "4824",
        "AirportCode": "MPF",
        "AirportName": "Mapoda Airport",
        "City": "Mapoda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4825",
        "AirportCode": "MPG",
        "AirportName": "Makini Airport",
        "City": "Makini",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4826",
        "AirportCode": "MPH",
        "AirportName": "Malay Airport",
        "City": "Caticlan",
        "Country": "Philippines"
    },
    {
        "id": "4827",
        "AirportCode": "MPI",
        "AirportName": "Mamitupo Airport",
        "City": "Mamitupo",
        "Country": "Panama"
    },
    {
        "id": "4828",
        "AirportCode": "MPJ",
        "AirportName": "Petit Jean Park Airport",
        "City": "Morrilton",
        "Country": "USA Arkansas"
    },
    {
        "id": "4829",
        "AirportCode": "MPK",
        "AirportName": "Mokpo Airport",
        "City": "Mokpo",
        "Country": "Korea"
    },
    {
        "id": "4830",
        "AirportCode": "MPL",
        "AirportName": "Mediterranee Airport",
        "City": "Montpellier",
        "Country": "France"
    },
    {
        "id": "4831",
        "AirportCode": "MPM",
        "AirportName": "Maputo International Airport",
        "City": "Maputo",
        "Country": "Mozambique"
    },
    {
        "id": "4832",
        "AirportCode": "MPN",
        "AirportName": "Mount Pleasant Airport",
        "City": "Mount Pleasant",
        "Country": "Falkland Islands"
    },
    {
        "id": "4833",
        "AirportCode": "MPO",
        "AirportName": "Mount Pocono Airport",
        "City": "Mount Pocono",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4834",
        "AirportCode": "MPP",
        "AirportName": "Mulatupo Airport",
        "City": "Mulatupo",
        "Country": "Panama"
    },
    {
        "id": "4835",
        "AirportCode": "MPQ",
        "AirportName": "Maan Airport",
        "City": "Maan",
        "Country": "Jordan"
    },
    {
        "id": "4836",
        "AirportCode": "MPR",
        "AirportName": "McPherson Airport",
        "City": "McPherson",
        "Country": "USA Kansas"
    },
    {
        "id": "4837",
        "AirportCode": "MPS",
        "AirportName": "Mount Pleasant Airport",
        "City": "Mount Pleasant",
        "Country": "USA Texas"
    },
    {
        "id": "4838",
        "AirportCode": "MPT",
        "AirportName": "Maliana Airport",
        "City": "Maliana",
        "Country": "Indonesia"
    },
    {
        "id": "4839",
        "AirportCode": "MPU",
        "AirportName": "Mapua Airport",
        "City": "Mapua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4840",
        "AirportCode": "MPV",
        "AirportName": "Edward F Knapp State Airport",
        "City": "Montpelier",
        "Country": "USA Vermont"
    },
    {
        "id": "4841",
        "AirportCode": "MPW",
        "AirportName": "Mariupol Airport",
        "City": "Mariupol",
        "Country": "Ukraine"
    },
    {
        "id": "4842",
        "AirportCode": "MPX",
        "AirportName": "Miyanmin Airport",
        "City": "Miyanmin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4843",
        "AirportCode": "MPY",
        "AirportName": "Maripasoula Airport",
        "City": "Maripasoula",
        "Country": "French Guiana"
    },
    {
        "id": "4844",
        "AirportCode": "MPZ",
        "AirportName": "Mount Pleasant Municipal Airport",
        "City": "Mount Pleasant",
        "Country": "USA Iowa"
    },
    {
        "id": "4845",
        "AirportCode": "MQA",
        "AirportName": "Mandora Airport",
        "City": "Mandora",
        "Country": "Australia"
    },
    {
        "id": "4846",
        "AirportCode": "MQB",
        "AirportName": "Macomb Municipal Airport",
        "City": "Macomb",
        "Country": "USA Illinois"
    },
    {
        "id": "4847",
        "AirportCode": "MQC",
        "AirportName": "Miquelon Airport",
        "City": "Miquelon",
        "Country": "St Pierre et Miquelon"
    },
    {
        "id": "4848",
        "AirportCode": "MQD",
        "AirportName": "Maquinchao Airport",
        "City": "Maquinchao",
        "Country": "Argentina"
    },
    {
        "id": "4849",
        "AirportCode": "MQE",
        "AirportName": "Marqua Airport",
        "City": "Marqua",
        "Country": "Australia"
    },
    {
        "id": "4850",
        "AirportCode": "MQF",
        "AirportName": "Magnitogorsk Airport",
        "City": "Magnitogorsk",
        "Country": "Russia"
    },
    {
        "id": "4851",
        "AirportCode": "MQG",
        "AirportName": "Midgard Airport",
        "City": "Midgard",
        "Country": "Namibia"
    },
    {
        "id": "4852",
        "AirportCode": "MQH",
        "AirportName": "Minacu Municipal Airport",
        "City": "Minacu",
        "Country": "Brazil"
    },
    {
        "id": "4853",
        "AirportCode": "MQI",
        "AirportName": "Quincy Airport",
        "City": "Quincy",
        "Country": "USA Massachusetts"
    },
    {
        "id": "4854",
        "AirportCode": "MQJ",
        "AirportName": "Merkez Airport",
        "City": "Balikesir",
        "Country": "Turkey"
    },
    {
        "id": "4855",
        "AirportCode": "MQK",
        "AirportName": "San Matias Airport",
        "City": "San Matias",
        "Country": "Bolivia"
    },
    {
        "id": "4856",
        "AirportCode": "MQL",
        "AirportName": "Mildura Airport",
        "City": "Mildura",
        "Country": "Australia"
    },
    {
        "id": "4857",
        "AirportCode": "MQM",
        "AirportName": "Mardin Airport",
        "City": "Mardin",
        "Country": "Turkey"
    },
    {
        "id": "4858",
        "AirportCode": "MQN",
        "AirportName": "Mo I Rana Airport",
        "City": "Mo I Rana",
        "Country": "Norway"
    },
    {
        "id": "4859",
        "AirportCode": "MQO",
        "AirportName": "Malam Airport",
        "City": "Malam",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4860",
        "AirportCode": "MQP",
        "AirportName": "Kruger Mpumalanga International Airport",
        "City": "Nelspruit",
        "Country": "South Africa"
    },
    {
        "id": "4861",
        "AirportCode": "MQQ",
        "AirportName": "Moundou Airport",
        "City": "Moundou",
        "Country": "Mali"
    },
    {
        "id": "4862",
        "AirportCode": "MQR",
        "AirportName": "Mosquera Airport",
        "City": "Mosquera",
        "Country": "Colombia"
    },
    {
        "id": "4863",
        "AirportCode": "MQS",
        "AirportName": "Mustique Airport",
        "City": "Mustique Island",
        "Country": "St Vincent"
    },
    {
        "id": "4864",
        "AirportCode": "MQT",
        "AirportName": "Sawyer International Airport",
        "City": "Marquette",
        "Country": "USA Michigan"
    },
    {
        "id": "4865",
        "AirportCode": "MQU",
        "AirportName": "Mariquita Airport",
        "City": "Mariquita",
        "Country": "Colombia"
    },
    {
        "id": "4866",
        "AirportCode": "MQV",
        "AirportName": "Mostaganem Airport",
        "City": "Mostaganem",
        "Country": "Algeria"
    },
    {
        "id": "4867",
        "AirportCode": "MQW",
        "AirportName": "Telfair-Wheeler Airport",
        "City": "McRae",
        "Country": "USA Georgia"
    },
    {
        "id": "4868",
        "AirportCode": "MQX",
        "AirportName": "Makale Airport",
        "City": "Makale",
        "Country": "Ethiopia"
    },
    {
        "id": "4869",
        "AirportCode": "MQY",
        "AirportName": "Smyrna Airport",
        "City": "Smyrna",
        "Country": "USA Tennessee"
    },
    {
        "id": "4870",
        "AirportCode": "MQZ",
        "AirportName": "Margaret River Airport",
        "City": "Margaret River",
        "Country": "Australia"
    },
    {
        "id": "4871",
        "AirportCode": "MRA",
        "AirportName": "Misurata Airport",
        "City": "Misurata",
        "Country": "Libya"
    },
    {
        "id": "4872",
        "AirportCode": "MRB",
        "AirportName": "Martinsburg Airport",
        "City": "Martinsburg",
        "Country": "USA West Virginia"
    },
    {
        "id": "4873",
        "AirportCode": "MRC",
        "AirportName": "Maury County Airport",
        "City": "Columbia",
        "Country": "USA Tennessee"
    },
    {
        "id": "4874",
        "AirportCode": "MRD",
        "AirportName": "A Carnevalli Airport",
        "City": "Merida",
        "Country": "Venezuela"
    },
    {
        "id": "4875",
        "AirportCode": "MRE",
        "AirportName": "Mara Lodges Airport",
        "City": "Mara Lodges",
        "Country": "Kenya"
    },
    {
        "id": "4876",
        "AirportCode": "MRF",
        "AirportName": "Marfa Municipal Airport",
        "City": "Marfa",
        "Country": "USA Texas"
    },
    {
        "id": "4877",
        "AirportCode": "MRG",
        "AirportName": "Mareeba Airport",
        "City": "Mareeba",
        "Country": "Australia"
    },
    {
        "id": "4878",
        "AirportCode": "MRH",
        "AirportName": "May River Airport",
        "City": "May River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4879",
        "AirportCode": "MRJ",
        "AirportName": "Marcala Airport",
        "City": "Marcala",
        "Country": "Honduras"
    },
    {
        "id": "4880",
        "AirportCode": "MRK",
        "AirportName": "Marco Island Airport",
        "City": "Marco Island",
        "Country": "USA Florida"
    },
    {
        "id": "4881",
        "AirportCode": "MRL",
        "AirportName": "Miners Lake Airport",
        "City": "Miners Lake",
        "Country": "Australia"
    },
    {
        "id": "4882",
        "AirportCode": "MRM",
        "AirportName": "Manari Airport",
        "City": "Manari",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4883",
        "AirportCode": "MRN",
        "AirportName": "Lenoir Airport",
        "City": "Morganton",
        "Country": "USA North Carolina"
    },
    {
        "id": "4884",
        "AirportCode": "MRO",
        "AirportName": "Masterton Airport",
        "City": "Masterton",
        "Country": "New Zealand"
    },
    {
        "id": "4885",
        "AirportCode": "MRP",
        "AirportName": "Marla Airport",
        "City": "Marla",
        "Country": "Australia"
    },
    {
        "id": "4886",
        "AirportCode": "MRQ",
        "AirportName": "Marinduque Airport",
        "City": "Marinduque",
        "Country": "Philippines"
    },
    {
        "id": "4887",
        "AirportCode": "MRR",
        "AirportName": "Macara Airport",
        "City": "Macara",
        "Country": "Ecuador"
    },
    {
        "id": "4888",
        "AirportCode": "MRS",
        "AirportName": "Marseille Airport",
        "City": "Marseille",
        "Country": "France"
    },
    {
        "id": "4889",
        "AirportCode": "MRT",
        "AirportName": "Moroak Airport",
        "City": "Moroak",
        "Country": "Australia"
    },
    {
        "id": "4890",
        "AirportCode": "MRV",
        "AirportName": "Mineralnye Vody Airport",
        "City": "Mineralnye Vody",
        "Country": "Russia"
    },
    {
        "id": "4891",
        "AirportCode": "MRW",
        "AirportName": "Maribo Airport",
        "City": "Maribo",
        "Country": "Denmark"
    },
    {
        "id": "4892",
        "AirportCode": "MRX",
        "AirportName": "Mahshahr Airport",
        "City": "Bandar Mahshahr",
        "Country": "Iran"
    },
    {
        "id": "4893",
        "AirportCode": "MRY",
        "AirportName": "Monterey Peninsula Airport",
        "City": "Monterey",
        "Country": "USA California"
    },
    {
        "id": "4894",
        "AirportCode": "MRZ",
        "AirportName": "Moree Airport",
        "City": "Moree",
        "Country": "Australia"
    },
    {
        "id": "4895",
        "AirportCode": "MSA",
        "AirportName": "Muskrat Dam Airport",
        "City": "Muskrat Dam",
        "Country": "Canada"
    },
    {
        "id": "4896",
        "AirportCode": "MSB",
        "AirportName": "Marigot Sea Plane Base",
        "City": "Saint Martin",
        "Country": "Guadeloupe"
    },
    {
        "id": "4897",
        "AirportCode": "MSC",
        "AirportName": "Falcon Field",
        "City": "Mesa",
        "Country": "USA Arizona"
    },
    {
        "id": "4898",
        "AirportCode": "MSD",
        "AirportName": "Mt. Pleasant Airport",
        "City": "Mount Pleasant",
        "Country": "USA Utah"
    },
    {
        "id": "4899",
        "AirportCode": "MSE",
        "AirportName": "Kent International Airport",
        "City": "Manston",
        "Country": "United Kingdom"
    },
    {
        "id": "4900",
        "AirportCode": "MSF",
        "AirportName": "Mount Swan Airport",
        "City": "Mount Swan",
        "Country": "Australia"
    },
    {
        "id": "4901",
        "AirportCode": "MSG",
        "AirportName": "Matsaile Airport",
        "City": "Matsaile",
        "Country": "Lesotho"
    },
    {
        "id": "4902",
        "AirportCode": "MSI",
        "AirportName": "Masalembo Airport",
        "City": "Masalembo",
        "Country": "Indonesia"
    },
    {
        "id": "4903",
        "AirportCode": "MSJ",
        "AirportName": "Misawa Airport",
        "City": "Misawa",
        "Country": "Japan"
    },
    {
        "id": "4904",
        "AirportCode": "MSL",
        "AirportName": "Muscle Shoals Airport",
        "City": "Muscle Shoals",
        "Country": "USA Alabama"
    },
    {
        "id": "4905",
        "AirportCode": "MSM",
        "AirportName": "Masi Manimba Airport",
        "City": "Masi Manimba",
        "Country": "Congo, DR"
    },
    {
        "id": "4906",
        "AirportCode": "MSN",
        "AirportName": "Dane County Regional Airport",
        "City": "Madison",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4907",
        "AirportCode": "MSO",
        "AirportName": "Johnson-Bell Field",
        "City": "Missoula",
        "Country": "USA Montana"
    },
    {
        "id": "4908",
        "AirportCode": "MSP",
        "AirportName": "St Paul International Airport",
        "City": "Minneapolis",
        "Country": "USA Minnesota"
    },
    {
        "id": "4909",
        "AirportCode": "MSQ",
        "AirportName": "Minsk International 2 Airport",
        "City": "Minsk",
        "Country": "Belarus"
    },
    {
        "id": "4910",
        "AirportCode": "MSR",
        "AirportName": "Mus Airport",
        "City": "Mus",
        "Country": "Turkey"
    },
    {
        "id": "4911",
        "AirportCode": "MSS",
        "AirportName": "Richards Field",
        "City": "Massena",
        "Country": "USA New York"
    },
    {
        "id": "4912",
        "AirportCode": "MST",
        "AirportName": "Maastricht/Aachen Airport",
        "City": "Maastricht",
        "Country": "Netherlands"
    },
    {
        "id": "4913",
        "AirportCode": "MSU",
        "AirportName": "Moshoeshoe International Airport",
        "City": "Maseru",
        "Country": "Lesotho"
    },
    {
        "id": "4914",
        "AirportCode": "MSV",
        "AirportName": "Sullivan County International Airport",
        "City": "Monticello",
        "Country": "USA New York"
    },
    {
        "id": "4915",
        "AirportCode": "MSW",
        "AirportName": "Massawa Airport",
        "City": "Massawa",
        "Country": "Eritrea"
    },
    {
        "id": "4916",
        "AirportCode": "MSX",
        "AirportName": "Mossendjo Airport",
        "City": "Mossendjo",
        "Country": "Congo"
    },
    {
        "id": "4917",
        "AirportCode": "MSY",
        "AirportName": "Louis Armstrong International Airport",
        "City": "New Orleans",
        "Country": "USA Louisiana"
    },
    {
        "id": "4918",
        "AirportCode": "MSZ",
        "AirportName": "Namibe Airport",
        "City": "Namibe",
        "Country": "Angola"
    },
    {
        "id": "4919",
        "AirportCode": "MTA",
        "AirportName": "Matamata Airport",
        "City": "Matamata",
        "Country": "New Zealand"
    },
    {
        "id": "4920",
        "AirportCode": "MTB",
        "AirportName": "Montel Libano Airport",
        "City": "Montel Libano",
        "Country": "Colombia"
    },
    {
        "id": "4921",
        "AirportCode": "MTC",
        "AirportName": "Selfridge Air National Guard Base",
        "City": "Mount Clemens",
        "Country": "USA Michigan"
    },
    {
        "id": "4922",
        "AirportCode": "MTD",
        "AirportName": "Mount Sanford Airport",
        "City": "Mount Sanford",
        "Country": "Australia"
    },
    {
        "id": "4923",
        "AirportCode": "MTE",
        "AirportName": "Monte Alegre Airport",
        "City": "Monte Alegre",
        "Country": "Brazil"
    },
    {
        "id": "4924",
        "AirportCode": "MTF",
        "AirportName": "Mizan Teferi Airport",
        "City": "Mizan Teferi",
        "Country": "Ethiopia"
    },
    {
        "id": "4925",
        "AirportCode": "MTG",
        "AirportName": "Mato Grosso Airport",
        "City": "Mato Grosso",
        "Country": "Brazil"
    },
    {
        "id": "4926",
        "AirportCode": "MTH",
        "AirportName": "Marathon Flight Strip",
        "City": "Marathon",
        "Country": "USA Florida"
    },
    {
        "id": "4927",
        "AirportCode": "MTI",
        "AirportName": "Mosteiros Airport",
        "City": "Mosteiros",
        "Country": "Cape Verde"
    },
    {
        "id": "4928",
        "AirportCode": "MTJ",
        "AirportName": "Montrose County Airport",
        "City": "Montrose",
        "Country": "USA Colorado"
    },
    {
        "id": "4929",
        "AirportCode": "MTK",
        "AirportName": "Makin Island Airport",
        "City": "Makin Island",
        "Country": "Kiribati"
    },
    {
        "id": "4930",
        "AirportCode": "MTL",
        "AirportName": "Maitland Airport",
        "City": "Maitland",
        "Country": "Australia"
    },
    {
        "id": "4931",
        "AirportCode": "MTM",
        "AirportName": "Metlakatla Sea Plane Base",
        "City": "Metlakatla",
        "Country": "USA Alaska"
    },
    {
        "id": "4932",
        "AirportCode": "MTN",
        "AirportName": "Glenn L. Martin Airport",
        "City": "Baltimore",
        "Country": "USA Maryland"
    },
    {
        "id": "4933",
        "AirportCode": "MTO",
        "AirportName": "Coles County Memorial Airport",
        "City": "Mattoon",
        "Country": "USA Illinois"
    },
    {
        "id": "4934",
        "AirportCode": "MTP",
        "AirportName": "Sky Portal Airport",
        "City": "Montauk Point",
        "Country": "USA New York"
    },
    {
        "id": "4935",
        "AirportCode": "MTQ",
        "AirportName": "Mitchell Airport",
        "City": "Mitchell",
        "Country": "Australia"
    },
    {
        "id": "4936",
        "AirportCode": "MTR",
        "AirportName": "S. Jeronimo Airport",
        "City": "Monteria",
        "Country": "Colombia"
    },
    {
        "id": "4937",
        "AirportCode": "MTS",
        "AirportName": "Matsapha International Airport",
        "City": "Manzini",
        "Country": "Swaziland"
    },
    {
        "id": "4938",
        "AirportCode": "MTT",
        "AirportName": "Minatitlan Airport",
        "City": "Minatitlan",
        "Country": "Mexico"
    },
    {
        "id": "4939",
        "AirportCode": "MTU",
        "AirportName": "Montepuez Airport",
        "City": "Montepuez",
        "Country": "Mozambique"
    },
    {
        "id": "4940",
        "AirportCode": "MTV",
        "AirportName": "Mota Lava Airport",
        "City": "Mota Lava",
        "Country": "Vanuatu"
    },
    {
        "id": "4941",
        "AirportCode": "MTW",
        "AirportName": "Manitowoc Municipal Airport",
        "City": "Manitowoc",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4942",
        "AirportCode": "MTY",
        "AirportName": "Gen Mariano Escobedo Airport",
        "City": "Monterrey",
        "Country": "Mexico"
    },
    {
        "id": "4943",
        "AirportCode": "MUA",
        "AirportName": "Munda Airport",
        "City": "Munda",
        "Country": "Solomon Islands"
    },
    {
        "id": "4944",
        "AirportCode": "MUB",
        "AirportName": "Maun Airport",
        "City": "Maun",
        "Country": "Botswana"
    },
    {
        "id": "4945",
        "AirportCode": "MUC",
        "AirportName": "Franz Josef Strauss Airport",
        "City": "Munich",
        "Country": "Germany"
    },
    {
        "id": "4946",
        "AirportCode": "MUD",
        "AirportName": "Mueda Airport",
        "City": "Mueda",
        "Country": "Mozambique"
    },
    {
        "id": "4947",
        "AirportCode": "MUE",
        "AirportName": "Kamuela Airport",
        "City": "Kamuela",
        "Country": "USA Hawaii"
    },
    {
        "id": "4948",
        "AirportCode": "MUF",
        "AirportName": "Muting Airport",
        "City": "Muting",
        "Country": "Indonesia"
    },
    {
        "id": "4949",
        "AirportCode": "MUG",
        "AirportName": "Mulege Airport",
        "City": "Mulege",
        "Country": "Mexico"
    },
    {
        "id": "4950",
        "AirportCode": "MUH",
        "AirportName": "Mersa Matruh Airport",
        "City": "Mersa Matruh",
        "Country": "Egypt"
    },
    {
        "id": "4951",
        "AirportCode": "MUI",
        "AirportName": "Muir Aaf",
        "City": "Ft Indiantown Gap",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4952",
        "AirportCode": "MUJ",
        "AirportName": "Mui Airport",
        "City": "Mui",
        "Country": "Ethiopia"
    },
    {
        "id": "4953",
        "AirportCode": "MUK",
        "AirportName": "Mauke Island Airport",
        "City": "Mauke Island",
        "Country": "Cook Islands"
    },
    {
        "id": "4954",
        "AirportCode": "MUL",
        "AirportName": "Spence Airport",
        "City": "Moultrie",
        "Country": "USA Georgia"
    },
    {
        "id": "4955",
        "AirportCode": "MUM",
        "AirportName": "Mumias Airport",
        "City": "Mumias",
        "Country": "Kenya"
    },
    {
        "id": "4956",
        "AirportCode": "MUN",
        "AirportName": "Quiriquire Airport",
        "City": "Maturin",
        "Country": "Venezuela"
    },
    {
        "id": "4957",
        "AirportCode": "MUO",
        "AirportName": "Mountain Home Air Force Base",
        "City": "Mountain Home",
        "Country": "USA Idaho"
    },
    {
        "id": "4958",
        "AirportCode": "MUP",
        "AirportName": "Mulga Park Airport",
        "City": "Mulga Park",
        "Country": "Australia"
    },
    {
        "id": "4959",
        "AirportCode": "MUQ",
        "AirportName": "Muccan Airport",
        "City": "Muccan",
        "Country": "Australia"
    },
    {
        "id": "4960",
        "AirportCode": "MUR",
        "AirportName": "Marudi Airport",
        "City": "Marudi",
        "Country": "Malaysia"
    },
    {
        "id": "4961",
        "AirportCode": "MUT",
        "AirportName": "Muscatine Airport",
        "City": "Muscatine",
        "Country": "USA Iowa"
    },
    {
        "id": "4962",
        "AirportCode": "MUU",
        "AirportName": "Mount Union Airport",
        "City": "Mount Union",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4963",
        "AirportCode": "MUV",
        "AirportName": "Mustin Alf Airport",
        "City": "Philadelphia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "4964",
        "AirportCode": "MUW",
        "AirportName": "Mascara Airport",
        "City": "Mascara",
        "Country": "Algeria"
    },
    {
        "id": "4965",
        "AirportCode": "MUX",
        "AirportName": "Multan Airport",
        "City": "Multan",
        "Country": "Pakistan"
    },
    {
        "id": "4966",
        "AirportCode": "MUY",
        "AirportName": "Mouyondzi Airport",
        "City": "Mouyondzi",
        "Country": "Congo"
    },
    {
        "id": "4967",
        "AirportCode": "MUZ",
        "AirportName": "Musoma Airport",
        "City": "Musoma",
        "Country": "Tanzania"
    },
    {
        "id": "4968",
        "AirportCode": "MVA",
        "AirportName": "Reykiahlid Airport",
        "City": "Myvatn",
        "Country": "Iceland"
    },
    {
        "id": "4969",
        "AirportCode": "MVB",
        "AirportName": "Franceville/Mvengue Airport",
        "City": "Franceville",
        "Country": "Gabon"
    },
    {
        "id": "4970",
        "AirportCode": "MVC",
        "AirportName": "Monroe County Airport",
        "City": "Monroeville",
        "Country": "USA Alabama"
    },
    {
        "id": "4971",
        "AirportCode": "MVD",
        "AirportName": "Carrasco Airport",
        "City": "Montevideo",
        "Country": "Uruguay"
    },
    {
        "id": "4972",
        "AirportCode": "MVE",
        "AirportName": "Montevideo Municipal Airport",
        "City": "Montevideo",
        "Country": "USA Minnesota"
    },
    {
        "id": "4973",
        "AirportCode": "MVF",
        "AirportName": "Dixsept Rosado Airport",
        "City": "Mossoro",
        "Country": "Brazil"
    },
    {
        "id": "4974",
        "AirportCode": "MVG",
        "AirportName": "Mevang Airport",
        "City": "Mevang",
        "Country": "Gabon"
    },
    {
        "id": "4975",
        "AirportCode": "MVH",
        "AirportName": "Macksville Airport",
        "City": "Macksville",
        "Country": "Australia"
    },
    {
        "id": "4976",
        "AirportCode": "MVI",
        "AirportName": "Manetai Airport",
        "City": "Manetai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "4977",
        "AirportCode": "MVJ",
        "AirportName": "Marlboro Airport",
        "City": "Mandeville",
        "Country": "Jamaica"
    },
    {
        "id": "4978",
        "AirportCode": "MVK",
        "AirportName": "Mulka Airport",
        "City": "Mulka",
        "Country": "Australia"
    },
    {
        "id": "4979",
        "AirportCode": "MVL",
        "AirportName": "Morrisville-Stowe Airport",
        "City": "Stowe",
        "Country": "USA Vermont"
    },
    {
        "id": "4980",
        "AirportCode": "MVM",
        "AirportName": "Monument Valley Airport",
        "City": "Kayenta",
        "Country": "USA Arizona"
    },
    {
        "id": "4981",
        "AirportCode": "MVN",
        "AirportName": "Mt Vernon-Outland Airport",
        "City": "Mount Vernon",
        "Country": "USA Illinois"
    },
    {
        "id": "4982",
        "AirportCode": "MVO",
        "AirportName": "Mongo Airport",
        "City": "Mongo",
        "Country": "Mali"
    },
    {
        "id": "4983",
        "AirportCode": "MVP",
        "AirportName": "Mitu Airport",
        "City": "Mitu",
        "Country": "Colombia"
    },
    {
        "id": "4984",
        "AirportCode": "MVQ",
        "AirportName": "Mogilev Airport",
        "City": "Mogilev",
        "Country": "Belarus"
    },
    {
        "id": "4985",
        "AirportCode": "MVR",
        "AirportName": "Salam Airport",
        "City": "Maroua",
        "Country": "Cameroon"
    },
    {
        "id": "4986",
        "AirportCode": "MVS",
        "AirportName": "Mucuri Airport",
        "City": "Mucuri",
        "Country": "Brazil"
    },
    {
        "id": "4987",
        "AirportCode": "MVT",
        "AirportName": "Mataiva Airport",
        "City": "Mataiva",
        "Country": "French Polynesia"
    },
    {
        "id": "4988",
        "AirportCode": "MVU",
        "AirportName": "Musgrave Airport",
        "City": "Musgrave",
        "Country": "Australia"
    },
    {
        "id": "4989",
        "AirportCode": "MVV",
        "AirportName": "Megeve Airport",
        "City": "Megeve",
        "Country": "France"
    },
    {
        "id": "4990",
        "AirportCode": "MVW",
        "AirportName": "Skagit Regional Airport",
        "City": "Mount Vernon",
        "Country": "USA Washington"
    },
    {
        "id": "4991",
        "AirportCode": "MVX",
        "AirportName": "Minvoul Airport",
        "City": "Minvoul",
        "Country": "Gabon"
    },
    {
        "id": "4992",
        "AirportCode": "MVZ",
        "AirportName": "Masvingo Airport",
        "City": "Masvingo",
        "Country": "Zimbabwe"
    },
    {
        "id": "4993",
        "AirportCode": "MWA",
        "AirportName": "Williamson County Airport",
        "City": "Marion",
        "Country": "USA Illinois"
    },
    {
        "id": "4994",
        "AirportCode": "MWB",
        "AirportName": "Morawa Airport",
        "City": "Morawa",
        "Country": "Australia"
    },
    {
        "id": "4995",
        "AirportCode": "MWC",
        "AirportName": "Lawrence J. Timmerman Airport",
        "City": "Milwaukee",
        "Country": "USA Wisconsin"
    },
    {
        "id": "4996",
        "AirportCode": "MWD",
        "AirportName": "Mianwali Airport",
        "City": "Mianwali",
        "Country": "Pakistan"
    },
    {
        "id": "4997",
        "AirportCode": "MWE",
        "AirportName": "Merowe Airport",
        "City": "Merowe",
        "Country": "Sudan"
    },
    {
        "id": "4998",
        "AirportCode": "MWF",
        "AirportName": "Maewo Airport",
        "City": "Maewo",
        "Country": "Vanuatu"
    },
    {
        "id": "4999",
        "AirportCode": "MWG",
        "AirportName": "Marawaka Airport",
        "City": "Marawaka",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5000",
        "AirportCode": "MWH",
        "AirportName": "Grant County Airport",
        "City": "Moses Lake",
        "Country": "USA Washington"
    },
    {
        "id": "5001",
        "AirportCode": "MWI",
        "AirportName": "Maramoni Airport",
        "City": "Maramoni",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5002",
        "AirportCode": "MWJ",
        "AirportName": "Matthews Ridge Airport",
        "City": "Matthews Ridge",
        "Country": "Guyana"
    },
    {
        "id": "5003",
        "AirportCode": "MWK",
        "AirportName": "Matak Airport",
        "City": "Matak",
        "Country": "Indonesia"
    },
    {
        "id": "5004",
        "AirportCode": "MWL",
        "AirportName": "Mineral Wells Airport",
        "City": "Mineral Wells",
        "Country": "USA Texas"
    },
    {
        "id": "5005",
        "AirportCode": "MWM",
        "AirportName": "Windom Municipal Airport",
        "City": "Windom",
        "Country": "USA Minnesota"
    },
    {
        "id": "5006",
        "AirportCode": "MWN",
        "AirportName": "Mwadui Airport",
        "City": "Mwadui",
        "Country": "Tanzania"
    },
    {
        "id": "5007",
        "AirportCode": "MWO",
        "AirportName": "Hook Field",
        "City": "Middletown",
        "Country": "USA Ohio"
    },
    {
        "id": "5008",
        "AirportCode": "MWP",
        "AirportName": "Mountain Airport",
        "City": "Mountain",
        "Country": "Nepal"
    },
    {
        "id": "5009",
        "AirportCode": "MWQ",
        "AirportName": "Magwe Airport",
        "City": "Magwe",
        "Country": "Burma"
    },
    {
        "id": "5010",
        "AirportCode": "MWS",
        "AirportName": "Mount Wilson Airport",
        "City": "Mount Wilson",
        "Country": "USA California"
    },
    {
        "id": "5011",
        "AirportCode": "MWT",
        "AirportName": "Moolawatana Airport",
        "City": "Moolawatana",
        "Country": "Australia"
    },
    {
        "id": "5012",
        "AirportCode": "MWU",
        "AirportName": "Mussau Airport",
        "City": "Mussau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5013",
        "AirportCode": "MWV",
        "AirportName": "Mundulkiri Airport",
        "City": "Mundulkiri",
        "Country": "Cambodia"
    },
    {
        "id": "5014",
        "AirportCode": "MWW",
        "AirportName": "Mouscron Airport",
        "City": "Mouscron",
        "Country": "Belgium"
    },
    {
        "id": "5015",
        "AirportCode": "MWY",
        "AirportName": "Miranda Downs Airport",
        "City": "Miranda Downs",
        "Country": "Australia"
    },
    {
        "id": "5016",
        "AirportCode": "MWZ",
        "AirportName": "Mwanza Airport",
        "City": "Mwanza",
        "Country": "Tanzania"
    },
    {
        "id": "5017",
        "AirportCode": "MXA",
        "AirportName": "Manila Municipal Airport",
        "City": "Manila",
        "Country": "USA Arkansas"
    },
    {
        "id": "5018",
        "AirportCode": "MXB",
        "AirportName": "Masamba Airport",
        "City": "Masamba",
        "Country": "Indonesia"
    },
    {
        "id": "5019",
        "AirportCode": "MXC",
        "AirportName": "San Juan County Airport",
        "City": "Monticello",
        "Country": "USA Utah"
    },
    {
        "id": "5020",
        "AirportCode": "MXD",
        "AirportName": "Marion Downs Airport",
        "City": "Marion Downs",
        "Country": "Australia"
    },
    {
        "id": "5021",
        "AirportCode": "MXE",
        "AirportName": "Maxton Airport",
        "City": "Maxton",
        "Country": "USA North Carolina"
    },
    {
        "id": "5022",
        "AirportCode": "MXF",
        "AirportName": "Maxwell Air Force Base",
        "City": "Montgomery",
        "Country": "USA Alabama"
    },
    {
        "id": "5023",
        "AirportCode": "MXG",
        "AirportName": "Marlborough Airport",
        "City": "Marlborough",
        "Country": "USA Massachusetts"
    },
    {
        "id": "5024",
        "AirportCode": "MXH",
        "AirportName": "Moro Airport",
        "City": "Moro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5025",
        "AirportCode": "MXI",
        "AirportName": "Mati Airport",
        "City": "Mati",
        "Country": "Philippines"
    },
    {
        "id": "5026",
        "AirportCode": "MXJ",
        "AirportName": "Minna Airport",
        "City": "Minna",
        "Country": "Nigeria"
    },
    {
        "id": "5027",
        "AirportCode": "MXK",
        "AirportName": "Mindik Airport",
        "City": "Mindik",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5028",
        "AirportCode": "MXL",
        "AirportName": "Mexicali Airport",
        "City": "Mexicali",
        "Country": "Mexico"
    },
    {
        "id": "5029",
        "AirportCode": "MXM",
        "AirportName": "Morombe Airport",
        "City": "Morombe",
        "Country": "Madagascar"
    },
    {
        "id": "5030",
        "AirportCode": "MXN",
        "AirportName": "Morlaix Airport",
        "City": "Morlaix",
        "Country": "France"
    },
    {
        "id": "5031",
        "AirportCode": "MXO",
        "AirportName": "Monticello Municipal Airport",
        "City": "Monticello",
        "Country": "USA Iowa"
    },
    {
        "id": "5032",
        "AirportCode": "MXP",
        "AirportName": "Malpensa Airport",
        "City": "Milan",
        "Country": "Italy"
    },
    {
        "id": "5033",
        "AirportCode": "MXQ",
        "AirportName": "Mitchell River Airport",
        "City": "Mitchell River",
        "Country": "Australia"
    },
    {
        "id": "5034",
        "AirportCode": "MXR",
        "AirportName": "Mirgorod Airport",
        "City": "Mirgorod",
        "Country": "Ukraine"
    },
    {
        "id": "5035",
        "AirportCode": "MXS",
        "AirportName": "Maota Savall Island Airport",
        "City": "Maota Savall Island",
        "Country": "Samoa"
    },
    {
        "id": "5036",
        "AirportCode": "MXT",
        "AirportName": "Maintirano Airport",
        "City": "Maintirano",
        "Country": "Madagascar"
    },
    {
        "id": "5037",
        "AirportCode": "MXU",
        "AirportName": "Mullewa Airport",
        "City": "Mullewa",
        "Country": "Australia"
    },
    {
        "id": "5038",
        "AirportCode": "MXV",
        "AirportName": "Moron Airport",
        "City": "Moron",
        "Country": "Mongolia"
    },
    {
        "id": "5039",
        "AirportCode": "MXW",
        "AirportName": "Mandalgobi Airport",
        "City": "Mandalgobi",
        "Country": "Mongolia"
    },
    {
        "id": "5040",
        "AirportCode": "MXX",
        "AirportName": "Mora Airport",
        "City": "Mora",
        "Country": "Sweden"
    },
    {
        "id": "5041",
        "AirportCode": "MXZ",
        "AirportName": "Mei Xian Airport",
        "City": "Mei Xian",
        "Country": "China"
    },
    {
        "id": "5042",
        "AirportCode": "MYA",
        "AirportName": "Moruya Airport",
        "City": "Moruya",
        "Country": "Australia"
    },
    {
        "id": "5043",
        "AirportCode": "MYB",
        "AirportName": "Mayoumba Airport",
        "City": "Mayoumba",
        "Country": "Gabon"
    },
    {
        "id": "5044",
        "AirportCode": "MYC",
        "AirportName": "Maracay Airport",
        "City": "Maracay",
        "Country": "Venezuela"
    },
    {
        "id": "5045",
        "AirportCode": "MYD",
        "AirportName": "Malindi Airport",
        "City": "Malindi",
        "Country": "Kenya"
    },
    {
        "id": "5046",
        "AirportCode": "MYE",
        "AirportName": "Miyake Jima Airport",
        "City": "Miyake Jima",
        "Country": "Japan"
    },
    {
        "id": "5047",
        "AirportCode": "MYF",
        "AirportName": "Montgomery Field",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "5048",
        "AirportCode": "MYG",
        "AirportName": "Mayaguana Airport",
        "City": "Mayaguana",
        "Country": "Bahamas"
    },
    {
        "id": "5049",
        "AirportCode": "MYH",
        "AirportName": "Marble Canyon Airport",
        "City": "Marble Canyon",
        "Country": "USA Arizona"
    },
    {
        "id": "5050",
        "AirportCode": "MYI",
        "AirportName": "Murray Island Airport",
        "City": "Murray Island",
        "Country": "Australia"
    },
    {
        "id": "5051",
        "AirportCode": "MYJ",
        "AirportName": "Matsuyama Airport",
        "City": "Matsuyama",
        "Country": "Japan"
    },
    {
        "id": "5052",
        "AirportCode": "MYL",
        "AirportName": "Mc Call Airport",
        "City": "Mc Call",
        "Country": "USA Idaho"
    },
    {
        "id": "5053",
        "AirportCode": "MYM",
        "AirportName": "Monkey Mountain Airport",
        "City": "Monkey Mountain",
        "Country": "Guyana"
    },
    {
        "id": "5054",
        "AirportCode": "MYN",
        "AirportName": "Mareb Airport",
        "City": "Mareb",
        "Country": "Yemen"
    },
    {
        "id": "5055",
        "AirportCode": "MYO",
        "AirportName": "Myroodah Airport",
        "City": "Myroodah",
        "Country": "Australia"
    },
    {
        "id": "5056",
        "AirportCode": "MYP",
        "AirportName": "Mary Airport",
        "City": "Mary",
        "Country": "Turkmenistan"
    },
    {
        "id": "5057",
        "AirportCode": "MYQ",
        "AirportName": "Mysore Airport",
        "City": "Mysore",
        "Country": "India"
    },
    {
        "id": "5058",
        "AirportCode": "MYR",
        "AirportName": "Myrtle Beach International Airport",
        "City": "Myrtle Beach",
        "Country": "USA South Carolina"
    },
    {
        "id": "5059",
        "AirportCode": "MYS",
        "AirportName": "Moyale Airport",
        "City": "Moyale",
        "Country": "Ethiopia"
    },
    {
        "id": "5060",
        "AirportCode": "MYT",
        "AirportName": "Myitkyina Airport",
        "City": "Myitkyina",
        "Country": "Burma"
    },
    {
        "id": "5061",
        "AirportCode": "MYU",
        "AirportName": "Ellis Field",
        "City": "Mekoryuk",
        "Country": "USA Alaska"
    },
    {
        "id": "5062",
        "AirportCode": "MYV",
        "AirportName": "Yuba County Airport",
        "City": "Marysville",
        "Country": "USA California"
    },
    {
        "id": "5063",
        "AirportCode": "MYW",
        "AirportName": "Mtwara Airport",
        "City": "Mtwara",
        "Country": "Tanzania"
    },
    {
        "id": "5064",
        "AirportCode": "MYX",
        "AirportName": "Menyamya Airport",
        "City": "Menyamya",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5065",
        "AirportCode": "MYY",
        "AirportName": "Miri Airport",
        "City": "Miri",
        "Country": "Malaysia"
    },
    {
        "id": "5066",
        "AirportCode": "MYZ",
        "AirportName": "Monkey Bay Airport",
        "City": "Monkey Bay",
        "Country": "Malawi"
    },
    {
        "id": "5067",
        "AirportCode": "MZA",
        "AirportName": "Muzaffarnagar Airport",
        "City": "Muzaffarnagar",
        "Country": "India"
    },
    {
        "id": "5068",
        "AirportCode": "MZB",
        "AirportName": "Mocimboa da Praia Airport",
        "City": "Mocimboa da Praia",
        "Country": "Mozambique"
    },
    {
        "id": "5069",
        "AirportCode": "MZC",
        "AirportName": "Mitzic Airport",
        "City": "Mitzic",
        "Country": "Gabon"
    },
    {
        "id": "5070",
        "AirportCode": "MZD",
        "AirportName": "Mendez Airport",
        "City": "Mendez",
        "Country": "Ecuador"
    },
    {
        "id": "5071",
        "AirportCode": "MZE",
        "AirportName": "Manatee Airport",
        "City": "Manatee",
        "Country": "Belize"
    },
    {
        "id": "5072",
        "AirportCode": "MZF",
        "AirportName": "Wild Coast Sun Airport",
        "City": "Mzamba",
        "Country": "South Africa"
    },
    {
        "id": "5073",
        "AirportCode": "MZG",
        "AirportName": "Makung Airport",
        "City": "Makung",
        "Country": "Taiwan"
    },
    {
        "id": "5074",
        "AirportCode": "MZH",
        "AirportName": "Merzifon Airport",
        "City": "Merzifon",
        "Country": "Turkey"
    },
    {
        "id": "5075",
        "AirportCode": "MZI",
        "AirportName": "Mopti Airport",
        "City": "Mopti",
        "Country": "Mali"
    },
    {
        "id": "5076",
        "AirportCode": "MZJ",
        "AirportName": "Marana Airport",
        "City": "Marana",
        "Country": "USA Arizona"
    },
    {
        "id": "5077",
        "AirportCode": "MZK",
        "AirportName": "Marakei Airport",
        "City": "Marakei",
        "Country": "Kiribati"
    },
    {
        "id": "5078",
        "AirportCode": "MZL",
        "AirportName": "Santaguida Airport",
        "City": "Manizales",
        "Country": "Colombia"
    },
    {
        "id": "5079",
        "AirportCode": "MZM",
        "AirportName": "Frescaty Airport",
        "City": "Metz",
        "Country": "France"
    },
    {
        "id": "5080",
        "AirportCode": "MZN",
        "AirportName": "Minj Airport",
        "City": "Minj",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5081",
        "AirportCode": "MZO",
        "AirportName": "Sierra Maestra Airport",
        "City": "Manzanillo",
        "Country": "Cuba"
    },
    {
        "id": "5082",
        "AirportCode": "MZP",
        "AirportName": "Motueka Airport",
        "City": "Motueka",
        "Country": "New Zealand"
    },
    {
        "id": "5083",
        "AirportCode": "MZQ",
        "AirportName": "Mkuze Airport",
        "City": "Mkuze",
        "Country": "South Africa"
    },
    {
        "id": "5084",
        "AirportCode": "MZR",
        "AirportName": "Mazar-I-Sharif Airport",
        "City": "Mazar-I-Sharif",
        "Country": "Afghanistan"
    },
    {
        "id": "5085",
        "AirportCode": "MZS",
        "AirportName": "Mostyn Airport",
        "City": "Mostyn",
        "Country": "Malaysia"
    },
    {
        "id": "5086",
        "AirportCode": "MZT",
        "AirportName": "Gen Rafael Buelna Airport",
        "City": "Mazatlan",
        "Country": "Mexico"
    },
    {
        "id": "5087",
        "AirportCode": "MZU",
        "AirportName": "Muzaffarpur Airport",
        "City": "Muzaffarpur",
        "Country": "India"
    },
    {
        "id": "5088",
        "AirportCode": "MZV",
        "AirportName": "Mulu Airport",
        "City": "Mulu",
        "Country": "Malaysia"
    },
    {
        "id": "5089",
        "AirportCode": "MZW",
        "AirportName": "Mechria Airport",
        "City": "Mechria",
        "Country": "Algeria"
    },
    {
        "id": "5090",
        "AirportCode": "MZX",
        "AirportName": "Mena Airport",
        "City": "Mena",
        "Country": "Ethiopia"
    },
    {
        "id": "5091",
        "AirportCode": "MZY",
        "AirportName": "Mossel Bay Airport",
        "City": "Mossel Bay",
        "Country": "South Africa"
    },
    {
        "id": "5092",
        "AirportCode": "MZZ",
        "AirportName": "Marion Airport",
        "City": "Marion",
        "Country": "USA Indiana"
    },
    {
        "id": "5093",
        "AirportCode": "NAA",
        "AirportName": "Narrabri Airport",
        "City": "Narrabri",
        "Country": "Australia"
    },
    {
        "id": "5094",
        "AirportCode": "NAB",
        "AirportName": "Albany Naval Air Station",
        "City": "Albany",
        "Country": "USA Georgia"
    },
    {
        "id": "5095",
        "AirportCode": "NAC",
        "AirportName": "Naracoorte Airport",
        "City": "Naracoorte",
        "Country": "Australia"
    },
    {
        "id": "5096",
        "AirportCode": "NAD",
        "AirportName": "Macanal Airport",
        "City": "Macanal",
        "Country": "Colombia"
    },
    {
        "id": "5097",
        "AirportCode": "NAE",
        "AirportName": "Natitingou Airport",
        "City": "Natitingou",
        "Country": "Benin"
    },
    {
        "id": "5098",
        "AirportCode": "NAF",
        "AirportName": "Banaina Airport",
        "City": "Banaina",
        "Country": "Indonesia"
    },
    {
        "id": "5099",
        "AirportCode": "NAG",
        "AirportName": "Sonegaon Airport",
        "City": "Nagpur",
        "Country": "India"
    },
    {
        "id": "5100",
        "AirportCode": "NAH",
        "AirportName": "Naha Airport",
        "City": "Naha",
        "Country": "Indonesia"
    },
    {
        "id": "5101",
        "AirportCode": "NAI",
        "AirportName": "Annai Airport",
        "City": "Annai",
        "Country": "Guyana"
    },
    {
        "id": "5102",
        "AirportCode": "NAJ",
        "AirportName": "Nakhichevan Airport",
        "City": "Nakhichevan",
        "Country": "Azerbaijan"
    },
    {
        "id": "5103",
        "AirportCode": "NAK",
        "AirportName": "Nakhon Ratchasima Airport",
        "City": "Nakhon Ratchasima",
        "Country": "Thailand"
    },
    {
        "id": "5104",
        "AirportCode": "NAL",
        "AirportName": "Nalchik Airport",
        "City": "Nalchik",
        "Country": "Russia"
    },
    {
        "id": "5105",
        "AirportCode": "NAM",
        "AirportName": "Namlea Airport",
        "City": "Namlea",
        "Country": "Indonesia"
    },
    {
        "id": "5106",
        "AirportCode": "NAN",
        "AirportName": "Nadi International Airport",
        "City": "Nadi",
        "Country": "Fiji"
    },
    {
        "id": "5107",
        "AirportCode": "NAO",
        "AirportName": "Nanchong Airport",
        "City": "Nanchong",
        "Country": "China"
    },
    {
        "id": "5108",
        "AirportCode": "NAP",
        "AirportName": "Naples Airport",
        "City": "Naples",
        "Country": "Italy"
    },
    {
        "id": "5109",
        "AirportCode": "NAQ",
        "AirportName": "Qaanaaq Airport",
        "City": "Qaanaaq",
        "Country": "Greenland"
    },
    {
        "id": "5110",
        "AirportCode": "NAR",
        "AirportName": "Nare Airport",
        "City": "Nare",
        "Country": "Colombia"
    },
    {
        "id": "5111",
        "AirportCode": "NAS",
        "AirportName": "Nassau International Airport",
        "City": "Nassau",
        "Country": "Bahamas"
    },
    {
        "id": "5112",
        "AirportCode": "NAT",
        "AirportName": "Augusto Severo Airport",
        "City": "Natal",
        "Country": "Brazil"
    },
    {
        "id": "5113",
        "AirportCode": "NAU",
        "AirportName": "Napuka Island Airport",
        "City": "Napuka Island",
        "Country": "French Polynesia"
    },
    {
        "id": "5114",
        "AirportCode": "NAV",
        "AirportName": "Nevsehir Airport",
        "City": "Nevsehir",
        "Country": "Turkey"
    },
    {
        "id": "5115",
        "AirportCode": "NAW",
        "AirportName": "Narathiwat Airport",
        "City": "Narathiwat",
        "Country": "Thailand"
    },
    {
        "id": "5116",
        "AirportCode": "NAX",
        "AirportName": "Barbers Point Airport",
        "City": "Barbers Point",
        "Country": "USA Hawaii"
    },
    {
        "id": "5117",
        "AirportCode": "NAY",
        "AirportName": "Nanyuan Airport",
        "City": "Beijing",
        "Country": "China"
    },
    {
        "id": "5118",
        "AirportCode": "NBA",
        "AirportName": "Nambaiyufa Airport",
        "City": "Nambaiyufa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5119",
        "AirportCode": "NBB",
        "AirportName": "Barrancominas Airport",
        "City": "Barrancominas",
        "Country": "Colombia"
    },
    {
        "id": "5120",
        "AirportCode": "NBC",
        "AirportName": "Naberevnye Chelny Airport",
        "City": "Naberevnye Chelny",
        "Country": "Russia"
    },
    {
        "id": "5121",
        "AirportCode": "NBG",
        "AirportName": "Naval Air Station/Alvin Callendar",
        "City": "New Orleans",
        "Country": "USA Louisiana"
    },
    {
        "id": "5122",
        "AirportCode": "NBH",
        "AirportName": "Nambucca Heads Airport",
        "City": "Nambucca Heads",
        "Country": "Australia"
    },
    {
        "id": "5123",
        "AirportCode": "NBL",
        "AirportName": "San Blas Airport",
        "City": "San Blas",
        "Country": "Panama"
    },
    {
        "id": "5124",
        "AirportCode": "NBO",
        "AirportName": "Jomo Kenyatta International Airport",
        "City": "Nairobi (NBO)",
        "Country": "Kenya"
    },
    {
        "id": "5125",
        "AirportCode": "NBP",
        "AirportName": "Battery Park City/North Cove Sea Plane Base",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "5126",
        "AirportCode": "NBR",
        "AirportName": "Nambour Airport",
        "City": "Nambour",
        "Country": "Australia"
    },
    {
        "id": "5127",
        "AirportCode": "NBU",
        "AirportName": "Glenwood Springs Naval Air Station",
        "City": "Glenview",
        "Country": "USA Illinois"
    },
    {
        "id": "5128",
        "AirportCode": "NBV",
        "AirportName": "Cana Brava Airport",
        "City": "Cana Brava",
        "Country": "Brazil"
    },
    {
        "id": "5129",
        "AirportCode": "NBW",
        "AirportName": "Guantanamo Naval Air Station",
        "City": "Guantanamo",
        "Country": "Cuba"
    },
    {
        "id": "5130",
        "AirportCode": "NBX",
        "AirportName": "Nabire Airport",
        "City": "Nabire",
        "Country": "Indonesia"
    },
    {
        "id": "5131",
        "AirportCode": "NCA",
        "AirportName": "North Caicos Airport",
        "City": "North Caicos",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "5132",
        "AirportCode": "NCG",
        "AirportName": "Nueva Casas Grandes Airport",
        "City": "Nueva Casas Grandes",
        "Country": "Mexico"
    },
    {
        "id": "5133",
        "AirportCode": "NCH",
        "AirportName": "Nachingwea Airport",
        "City": "Nachingwea",
        "Country": "Tanzania"
    },
    {
        "id": "5134",
        "AirportCode": "NCI",
        "AirportName": "Necocli Airport",
        "City": "Necocli",
        "Country": "Colombia"
    },
    {
        "id": "5135",
        "AirportCode": "NCL",
        "AirportName": "Newcastle Airport",
        "City": "Newcastle",
        "Country": "United Kingdom"
    },
    {
        "id": "5136",
        "AirportCode": "NCN",
        "AirportName": "New Chenega Airport",
        "City": "New Chenega",
        "Country": "USA Alaska"
    },
    {
        "id": "5137",
        "AirportCode": "NCO",
        "AirportName": "Naval Air Station",
        "City": "Quonset Point",
        "Country": "USA Rhode Island"
    },
    {
        "id": "5138",
        "AirportCode": "NCP",
        "AirportName": "Cubi Pt Naval Air Station",
        "City": "Luzon Island",
        "Country": "Philippines"
    },
    {
        "id": "5139",
        "AirportCode": "NCQ",
        "AirportName": "Atlanta Naval Air Station",
        "City": "Marietta",
        "Country": "USA Georgia"
    },
    {
        "id": "5140",
        "AirportCode": "NCR",
        "AirportName": "San Carlos Airport",
        "City": "San Carlos",
        "Country": "Nicaragua"
    },
    {
        "id": "5141",
        "AirportCode": "NCS",
        "AirportName": "Newcastle Airport",
        "City": "Newcastle",
        "Country": "South Africa"
    },
    {
        "id": "5142",
        "AirportCode": "NCT",
        "AirportName": "Guanacaste Airport",
        "City": "Nicoya",
        "Country": "Costa Rica"
    },
    {
        "id": "5143",
        "AirportCode": "NCU",
        "AirportName": "Nukus Airport",
        "City": "Nukus",
        "Country": "Uzbekistan"
    },
    {
        "id": "5144",
        "AirportCode": "NCY",
        "AirportName": "Annecy-Meythet Airport",
        "City": "Annecy",
        "Country": "France"
    },
    {
        "id": "5145",
        "AirportCode": "NDA",
        "AirportName": "Bandanaira Airport",
        "City": "Bandanaira",
        "Country": "Indonesia"
    },
    {
        "id": "5146",
        "AirportCode": "NDB",
        "AirportName": "Nouadhibou Airport",
        "City": "Nouadhibou",
        "Country": "Mauritania"
    },
    {
        "id": "5147",
        "AirportCode": "NDC",
        "AirportName": "Nanded Airport",
        "City": "Nanded",
        "Country": "India"
    },
    {
        "id": "5148",
        "AirportCode": "NDE",
        "AirportName": "Mandera Airport",
        "City": "Mandera",
        "Country": "Kenya"
    },
    {
        "id": "5149",
        "AirportCode": "NDG",
        "AirportName": "Qiqihar Airport",
        "City": "Qiqihar",
        "Country": "China"
    },
    {
        "id": "5150",
        "AirportCode": "NDI",
        "AirportName": "Namudi Airport",
        "City": "Namudi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5151",
        "AirportCode": "NDJ",
        "AirportName": "Ndjamena Airport",
        "City": "Ndjamena",
        "Country": "Chad / Tchad "
    },
    {
        "id": "5152",
        "AirportCode": "NDK",
        "AirportName": "Namdrik Island Airport",
        "City": "Namdrik Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "5153",
        "AirportCode": "NDL",
        "AirportName": "Ndele Airport",
        "City": "Ndele",
        "Country": "Central African Republic"
    },
    {
        "id": "5154",
        "AirportCode": "NDM",
        "AirportName": "Mendi Airport",
        "City": "Mendi",
        "Country": "Ethiopia"
    },
    {
        "id": "5155",
        "AirportCode": "NDN",
        "AirportName": "Nadunumu Airport",
        "City": "Nadunumu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5156",
        "AirportCode": "NDO",
        "AirportName": "La Palma Del Condado Airport",
        "City": "La Palma Del Condado",
        "Country": "Spain"
    },
    {
        "id": "5157",
        "AirportCode": "NDP",
        "AirportName": "Ellyson Naval Air Station",
        "City": "Pensacola",
        "Country": "USA Florida"
    },
    {
        "id": "5158",
        "AirportCode": "NDR",
        "AirportName": "Nador Airport",
        "City": "Nador",
        "Country": "Morocco"
    },
    {
        "id": "5159",
        "AirportCode": "NDS",
        "AirportName": "Sandstone Airport",
        "City": "Sandstone",
        "Country": "Australia"
    },
    {
        "id": "5160",
        "AirportCode": "NDU",
        "AirportName": "Rundu Airport",
        "City": "Rundu",
        "Country": "Namibia"
    },
    {
        "id": "5161",
        "AirportCode": "NDV",
        "AirportName": "United States Naval Heliport",
        "City": "Anacostia",
        "Country": "USA DC"
    },
    {
        "id": "5162",
        "AirportCode": "NDY",
        "AirportName": "Sanday Airport",
        "City": "Sanday",
        "Country": "United Kingdom"
    },
    {
        "id": "5163",
        "AirportCode": "NDZ",
        "AirportName": "Cuxhaven Airport",
        "City": "Nordholz-Spieka",
        "Country": "Germany"
    },
    {
        "id": "5164",
        "AirportCode": "NEA",
        "AirportName": "Naval Air Station",
        "City": "Glynco",
        "Country": "USA Georgia"
    },
    {
        "id": "5165",
        "AirportCode": "NEC",
        "AirportName": "Necochea Airport",
        "City": "Necochea",
        "Country": "Argentina"
    },
    {
        "id": "5166",
        "AirportCode": "NEF",
        "AirportName": "Neftekamsk Airport",
        "City": "Neftekamsk",
        "Country": "Russia"
    },
    {
        "id": "5167",
        "AirportCode": "NEG",
        "AirportName": "Negril Airport",
        "City": "Negril",
        "Country": "Jamaica"
    },
    {
        "id": "5168",
        "AirportCode": "NEJ",
        "AirportName": "Nejjo Airport",
        "City": "Nejjo",
        "Country": "Ethiopia"
    },
    {
        "id": "5169",
        "AirportCode": "NEK",
        "AirportName": "Nekemt Airport",
        "City": "Nekemt",
        "Country": "Ethiopia"
    },
    {
        "id": "5170",
        "AirportCode": "NEL",
        "AirportName": "Naec Airport",
        "City": "Lakehurst",
        "Country": "USA New Jersey"
    },
    {
        "id": "5171",
        "AirportCode": "NEN",
        "AirportName": "Olf Usn Airport",
        "City": "Whitehouse",
        "Country": "USA Florida"
    },
    {
        "id": "5172",
        "AirportCode": "NER",
        "AirportName": "Neryungri Airport",
        "City": "Neryungri",
        "Country": "Russia"
    },
    {
        "id": "5173",
        "AirportCode": "NES",
        "AirportName": "East 34th Street - Sea Plane Base",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "5174",
        "AirportCode": "NEU",
        "AirportName": "Sam Neua Airport",
        "City": "Sam Neua",
        "Country": "Laos"
    },
    {
        "id": "5175",
        "AirportCode": "NEV",
        "AirportName": "Newcastle Airport",
        "City": "Nevis",
        "Country": "St Kitts & Nevis"
    },
    {
        "id": "5176",
        "AirportCode": "NEW",
        "AirportName": "Lakefront Airport",
        "City": "New Orleans",
        "Country": "USA Louisiana"
    },
    {
        "id": "5177",
        "AirportCode": "NFB",
        "AirportName": "Detroit Naval Air Field",
        "City": "Mount Clemens",
        "Country": "USA Michigan"
    },
    {
        "id": "5178",
        "AirportCode": "NFG",
        "AirportName": "Nefteyugansk Airport",
        "City": "Nefteyugansk",
        "Country": "Russia"
    },
    {
        "id": "5179",
        "AirportCode": "NFL",
        "AirportName": "Naval Air Station",
        "City": "Fallon",
        "Country": "USA Nevada"
    },
    {
        "id": "5180",
        "AirportCode": "NFR",
        "AirportName": "Nafoora Airport",
        "City": "Nafoora",
        "Country": "Libya"
    },
    {
        "id": "5181",
        "AirportCode": "NGA",
        "AirportName": "Young Airport",
        "City": "Young",
        "Country": "Australia"
    },
    {
        "id": "5182",
        "AirportCode": "NGB",
        "AirportName": "Ningbo Airport",
        "City": "Ningbo",
        "Country": "China"
    },
    {
        "id": "5183",
        "AirportCode": "NGC",
        "AirportName": "North Rim Airport",
        "City": "Grand Canyon",
        "Country": "USA Arizona"
    },
    {
        "id": "5184",
        "AirportCode": "NGD",
        "AirportName": "Anegada Airport",
        "City": "Anegada",
        "Country": "British Virgin Islands"
    },
    {
        "id": "5185",
        "AirportCode": "NGE",
        "AirportName": "Ngaoundere Airport",
        "City": "Ngaoundere",
        "Country": "Cameroon"
    },
    {
        "id": "5186",
        "AirportCode": "NGI",
        "AirportName": "Ngau Island Airport",
        "City": "Ngau Island",
        "Country": "Fiji"
    },
    {
        "id": "5187",
        "AirportCode": "NGL",
        "AirportName": "Ngala Airfield",
        "City": "Ngala",
        "Country": "South Africa"
    },
    {
        "id": "5188",
        "AirportCode": "NGM",
        "AirportName": "Agana Naval Air Station",
        "City": "Guam",
        "Country": "Guam"
    },
    {
        "id": "5189",
        "AirportCode": "NGN",
        "AirportName": "Nargana Airport",
        "City": "Nargana",
        "Country": "Panama"
    },
    {
        "id": "5190",
        "AirportCode": "NGO",
        "AirportName": "Chubu Centrair International Airport",
        "City": "Nagoya",
        "Country": "Japan"
    },
    {
        "id": "5191",
        "AirportCode": "NGP",
        "AirportName": "Naval Air Station",
        "City": "Corpus Christi",
        "Country": "USA Texas"
    },
    {
        "id": "5192",
        "AirportCode": "NGR",
        "AirportName": "Ningerum Airport",
        "City": "Ningerum",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5193",
        "AirportCode": "NGS",
        "AirportName": "Nagasaki Airport",
        "City": "Nagasaki",
        "Country": "Japan"
    },
    {
        "id": "5194",
        "AirportCode": "NGU",
        "AirportName": "Naval Air Station Chambers",
        "City": "Norfolk",
        "Country": "USA Virginia"
    },
    {
        "id": "5195",
        "AirportCode": "NGW",
        "AirportName": "Cabaniss Field",
        "City": "Corpus Christi",
        "Country": "USA Texas"
    },
    {
        "id": "5196",
        "AirportCode": "NGX",
        "AirportName": "Manang Airport",
        "City": "Manang",
        "Country": "Nepal"
    },
    {
        "id": "5197",
        "AirportCode": "NGZ",
        "AirportName": "Naval Air Station",
        "City": "Alameda",
        "Country": "USA California"
    },
    {
        "id": "5198",
        "AirportCode": "NHA",
        "AirportName": "Nha Trang Airport",
        "City": "Nha Trang",
        "Country": "Vietnam"
    },
    {
        "id": "5199",
        "AirportCode": "NHD",
        "AirportName": "Minhad Military Airport",
        "City": "Minhad",
        "Country": "UAE"
    },
    {
        "id": "5200",
        "AirportCode": "NHF",
        "AirportName": "New Halfa Airport",
        "City": "New Halfa",
        "Country": "Sudan"
    },
    {
        "id": "5201",
        "AirportCode": "NHK",
        "AirportName": "Naval Air Station",
        "City": "Patuxent River",
        "Country": "USA Maryland"
    },
    {
        "id": "5202",
        "AirportCode": "NHS",
        "AirportName": "Nushki Airport",
        "City": "Nushki",
        "Country": "Pakistan"
    },
    {
        "id": "5203",
        "AirportCode": "NHT",
        "AirportName": "Northolt Airport",
        "City": "Northolt",
        "Country": "United Kingdom"
    },
    {
        "id": "5204",
        "AirportCode": "NHV",
        "AirportName": "Nuku Hiva Airport",
        "City": "Nuku Hiva",
        "Country": "French Polynesia"
    },
    {
        "id": "5205",
        "AirportCode": "NHX",
        "AirportName": "Barin Olf Osn Airport",
        "City": "Foley",
        "Country": "USA Alabama"
    },
    {
        "id": "5206",
        "AirportCode": "NHZ",
        "AirportName": "Naval Air Station",
        "City": "Brunswick",
        "Country": "USA Maine"
    },
    {
        "id": "5207",
        "AirportCode": "NIA",
        "AirportName": "Nimba Airport",
        "City": "Nimba",
        "Country": "Liberia"
    },
    {
        "id": "5208",
        "AirportCode": "NIB",
        "AirportName": "Nikolai Airport",
        "City": "Nikolai",
        "Country": "USA Alaska"
    },
    {
        "id": "5209",
        "AirportCode": "NIC",
        "AirportName": "Nicosia Airport",
        "City": "Nicosia",
        "Country": "Cyprus"
    },
    {
        "id": "5210",
        "AirportCode": "NIF",
        "AirportName": "Nifty Airport",
        "City": "Nifty",
        "Country": "Australia"
    },
    {
        "id": "5211",
        "AirportCode": "NIG",
        "AirportName": "Nikunau Airport",
        "City": "Nikunau",
        "Country": "Kiribati"
    },
    {
        "id": "5212",
        "AirportCode": "NIK",
        "AirportName": "Niokolo Koba Airport",
        "City": "Niokolo Koba",
        "Country": "Senegal"
    },
    {
        "id": "5213",
        "AirportCode": "NIM",
        "AirportName": "Niamey Airport",
        "City": "Niamey",
        "Country": "Niger"
    },
    {
        "id": "5214",
        "AirportCode": "NIO",
        "AirportName": "Nioki Airport",
        "City": "Nioki",
        "Country": "Congo, DR"
    },
    {
        "id": "5215",
        "AirportCode": "NIP",
        "AirportName": "Jacksonville Naval Air Station",
        "City": "Jacksonville",
        "Country": "USA Florida"
    },
    {
        "id": "5216",
        "AirportCode": "NIR",
        "AirportName": "Chase Field Naval Air Station",
        "City": "Beeville",
        "Country": "USA Texas"
    },
    {
        "id": "5217",
        "AirportCode": "NIS",
        "AirportName": "Simberi Island Airport",
        "City": "Simberi Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5218",
        "AirportCode": "NIT",
        "AirportName": "Niort Airport",
        "City": "Niort",
        "Country": "France"
    },
    {
        "id": "5219",
        "AirportCode": "NIX",
        "AirportName": "Nioro Airport",
        "City": "Nioro",
        "Country": "Mali"
    },
    {
        "id": "5220",
        "AirportCode": "NJA",
        "AirportName": "Naval Air Station",
        "City": "Atsugi",
        "Country": "Japan"
    },
    {
        "id": "5221",
        "AirportCode": "NJC",
        "AirportName": "Nizhnevartovsk Airport",
        "City": "Nizhnevartovsk",
        "Country": "Russia"
    },
    {
        "id": "5222",
        "AirportCode": "NJK",
        "AirportName": "Naval Air Field",
        "City": "El Centro",
        "Country": "USA California"
    },
    {
        "id": "5223",
        "AirportCode": "NKA",
        "AirportName": "Nkan Airport",
        "City": "Nkan",
        "Country": "Gabon"
    },
    {
        "id": "5224",
        "AirportCode": "NKB",
        "AirportName": "Noonkanbah Airport",
        "City": "Noonkanbah",
        "Country": "Australia"
    },
    {
        "id": "5225",
        "AirportCode": "NKC",
        "AirportName": "Nouakchott Airport",
        "City": "Nouakchott",
        "Country": "Mauritania"
    },
    {
        "id": "5226",
        "AirportCode": "NKD",
        "AirportName": "Sinak, Irian Jaya Airport",
        "City": "Sinak, Irian Jaya",
        "Country": "Indonesia"
    },
    {
        "id": "5227",
        "AirportCode": "NKG",
        "AirportName": "Nanking Airport",
        "City": "Nanking",
        "Country": "China"
    },
    {
        "id": "5228",
        "AirportCode": "NKI",
        "AirportName": "Naukiti Airport",
        "City": "Naukiti",
        "Country": "USA Alaska"
    },
    {
        "id": "5229",
        "AirportCode": "NKL",
        "AirportName": "Nkolo Airport",
        "City": "Nkolo",
        "Country": "Congo, DR"
    },
    {
        "id": "5230",
        "AirportCode": "NKM",
        "AirportName": "Nagoya/Komaki Airport",
        "City": "Nagoya",
        "Country": "Japan"
    },
    {
        "id": "5231",
        "AirportCode": "NKN",
        "AirportName": "Nankina Airport",
        "City": "Nankina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5232",
        "AirportCode": "NKO",
        "AirportName": "Ankokoambo Airport",
        "City": "Ankokoambo",
        "Country": "Madagascar"
    },
    {
        "id": "5233",
        "AirportCode": "NKS",
        "AirportName": "Nkongsamba Airport",
        "City": "Nkongsamba",
        "Country": "Cameroon"
    },
    {
        "id": "5234",
        "AirportCode": "NKU",
        "AirportName": "Nkaus Airport",
        "City": "Nkaus",
        "Country": "Lesotho"
    },
    {
        "id": "5235",
        "AirportCode": "NKX",
        "AirportName": "Miramar Naval Air Station",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "5236",
        "AirportCode": "NKY",
        "AirportName": "Nkayi Airport",
        "City": "Nkayi",
        "Country": "Congo"
    },
    {
        "id": "5237",
        "AirportCode": "NLA",
        "AirportName": "Ndola Airport",
        "City": "Ndola",
        "Country": "Zambia"
    },
    {
        "id": "5238",
        "AirportCode": "NLC",
        "AirportName": "Naval Air Station/Reeves Field",
        "City": "Lemoore",
        "Country": "USA California"
    },
    {
        "id": "5239",
        "AirportCode": "NLD",
        "AirportName": "Quetzalcoatl International Airport",
        "City": "Nuevo Laredo",
        "Country": "Mexico"
    },
    {
        "id": "5240",
        "AirportCode": "NLE",
        "AirportName": "Jerry Tyler Memorial Airport",
        "City": "Niles",
        "Country": "USA Michigan"
    },
    {
        "id": "5241",
        "AirportCode": "NLF",
        "AirportName": "Darnley Island Airport",
        "City": "Darnley Island",
        "Country": "Australia"
    },
    {
        "id": "5242",
        "AirportCode": "NLG",
        "AirportName": "Nelson Lagoon Airport",
        "City": "Nelson Lagoon",
        "Country": "USA Alaska"
    },
    {
        "id": "5243",
        "AirportCode": "NLK",
        "AirportName": "Norfolk Island Airport",
        "City": "Norfolk Island",
        "Country": "Norfolk Island"
    },
    {
        "id": "5244",
        "AirportCode": "NLL",
        "AirportName": "Nullagine Airport",
        "City": "Nullagine",
        "Country": "Australia"
    },
    {
        "id": "5245",
        "AirportCode": "NLP",
        "AirportName": "Nelspruit Airport",
        "City": "Nelspruit",
        "Country": "South Africa"
    },
    {
        "id": "5246",
        "AirportCode": "NLS",
        "AirportName": "Nicholson Airport",
        "City": "Nicholson",
        "Country": "Australia"
    },
    {
        "id": "5247",
        "AirportCode": "NLU",
        "AirportName": "Santa Lucia Airport",
        "City": "Mexico City",
        "Country": "Mexico"
    },
    {
        "id": "5248",
        "AirportCode": "NLV",
        "AirportName": "Nikolaev Airport",
        "City": "Nikolaev",
        "Country": "Ukraine"
    },
    {
        "id": "5249",
        "AirportCode": "NMA",
        "AirportName": "Namangan Airport",
        "City": "Namangan",
        "Country": "Uzbekistan"
    },
    {
        "id": "5250",
        "AirportCode": "NMB",
        "AirportName": "Daman Airport",
        "City": "Daman",
        "Country": "India"
    },
    {
        "id": "5251",
        "AirportCode": "NME",
        "AirportName": "Nightmute Airport",
        "City": "Nightmute",
        "Country": "USA Alaska"
    },
    {
        "id": "5252",
        "AirportCode": "NMG",
        "AirportName": "San Miguel Airport",
        "City": "San Miguel",
        "Country": "Panama"
    },
    {
        "id": "5253",
        "AirportCode": "NMN",
        "AirportName": "Nomane Airport",
        "City": "Nomane",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5254",
        "AirportCode": "NMP",
        "AirportName": "New Moon Airport",
        "City": "New Moon",
        "Country": "Australia"
    },
    {
        "id": "5255",
        "AirportCode": "NMR",
        "AirportName": "Nappamerry Airport",
        "City": "Nappamerry",
        "Country": "Australia"
    },
    {
        "id": "5256",
        "AirportCode": "NMS",
        "AirportName": "Namsang Airport",
        "City": "Namsang",
        "Country": "Burma"
    },
    {
        "id": "5257",
        "AirportCode": "NMT",
        "AirportName": "Namtu Airport",
        "City": "Namtu",
        "Country": "Burma"
    },
    {
        "id": "5258",
        "AirportCode": "NMU",
        "AirportName": "Namu Airport",
        "City": "Namu",
        "Country": "Marshall Islands"
    },
    {
        "id": "5259",
        "AirportCode": "NNA",
        "AirportName": "Naval Air Field",
        "City": "Kenitra",
        "Country": "Morocco"
    },
    {
        "id": "5260",
        "AirportCode": "NNB",
        "AirportName": "Santa Ana Airport",
        "City": "Santa Ana",
        "Country": "Solomon Islands"
    },
    {
        "id": "5261",
        "AirportCode": "NND",
        "AirportName": "Nangade Airport",
        "City": "Nangade",
        "Country": "Mozambique"
    },
    {
        "id": "5262",
        "AirportCode": "NNG",
        "AirportName": "Nanning Airport",
        "City": "Nanning",
        "Country": "China"
    },
    {
        "id": "5263",
        "AirportCode": "NNI",
        "AirportName": "Namutoni Airport",
        "City": "Namutoni",
        "Country": "Namibia"
    },
    {
        "id": "5264",
        "AirportCode": "NNL",
        "AirportName": "Nondalton Airport",
        "City": "Nondalton",
        "Country": "USA Alaska"
    },
    {
        "id": "5265",
        "AirportCode": "NNM",
        "AirportName": "Naryan-Mar Airport",
        "City": "Naryan-Mar",
        "Country": "Russia"
    },
    {
        "id": "5266",
        "AirportCode": "NNR",
        "AirportName": "Connemara Airport",
        "City": "Spiddal",
        "Country": "Ireland"
    },
    {
        "id": "5267",
        "AirportCode": "NNT",
        "AirportName": "Nan Airport",
        "City": "Nan",
        "Country": "Thailand"
    },
    {
        "id": "5268",
        "AirportCode": "NNU",
        "AirportName": "Nanuque Airport",
        "City": "Nanuque",
        "Country": "Brazil"
    },
    {
        "id": "5269",
        "AirportCode": "NNX",
        "AirportName": "Nunukan Airport",
        "City": "Nunukan",
        "Country": "Indonesia"
    },
    {
        "id": "5270",
        "AirportCode": "NNY",
        "AirportName": "Nanyang Airport",
        "City": "Nanyang",
        "Country": "China"
    },
    {
        "id": "5271",
        "AirportCode": "NOA",
        "AirportName": "Nowra Airport",
        "City": "Nowra",
        "Country": "Australia"
    },
    {
        "id": "5272",
        "AirportCode": "NOB",
        "AirportName": "Nosara Beach Airport",
        "City": "Nosara Beach",
        "Country": "Costa Rica"
    },
    {
        "id": "5273",
        "AirportCode": "NOC",
        "AirportName": "Ireland West Airport Knock",
        "City": "Knock",
        "Country": "Ireland"
    },
    {
        "id": "5274",
        "AirportCode": "NOD",
        "AirportName": "Norden Airport",
        "City": "Norden",
        "Country": "Germany"
    },
    {
        "id": "5275",
        "AirportCode": "NOE",
        "AirportName": "Norddeich Airport",
        "City": "Norddeich",
        "Country": "Germany"
    },
    {
        "id": "5276",
        "AirportCode": "NOG",
        "AirportName": "Nogales Airport",
        "City": "Nogales",
        "Country": "Mexico"
    },
    {
        "id": "5277",
        "AirportCode": "NOH",
        "AirportName": "Naval Air Station",
        "City": "Chicago",
        "Country": "USA Illinois"
    },
    {
        "id": "5278",
        "AirportCode": "NOI",
        "AirportName": "Novorossijsk Airport",
        "City": "Novorossijsk",
        "Country": "Russia"
    },
    {
        "id": "5279",
        "AirportCode": "NOJ",
        "AirportName": "Nojabrxsk Airport",
        "City": "Nojabrxsk",
        "Country": "Russia"
    },
    {
        "id": "5280",
        "AirportCode": "NOK",
        "AirportName": "Nova Xavantina Airport",
        "City": "Nova Xavantina",
        "Country": "Brazil"
    },
    {
        "id": "5281",
        "AirportCode": "NOM",
        "AirportName": "Nomad River Airport",
        "City": "Nomad River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5282",
        "AirportCode": "NON",
        "AirportName": "Nonouti Airport",
        "City": "Nonouti",
        "Country": "Kiribati"
    },
    {
        "id": "5283",
        "AirportCode": "NOO",
        "AirportName": "Naoro Airport",
        "City": "Naoro",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5284",
        "AirportCode": "NOP",
        "AirportName": "Mactan-Cebu International",
        "City": "Lapu-Lapu",
        "Country": "Philippines"
    },
    {
        "id": "5285",
        "AirportCode": "NOR",
        "AirportName": "Nordfjordur Airport",
        "City": "Nordfjordur",
        "Country": "Iceland"
    },
    {
        "id": "5286",
        "AirportCode": "NOS",
        "AirportName": "Fascene Airport",
        "City": "Nossi-be",
        "Country": "Madagascar"
    },
    {
        "id": "5287",
        "AirportCode": "NOT",
        "AirportName": "Novato Airport",
        "City": "Novato",
        "Country": "USA California"
    },
    {
        "id": "5288",
        "AirportCode": "NOU",
        "AirportName": "Tontouta Airport",
        "City": "Noumea",
        "Country": "New Caledonia"
    },
    {
        "id": "5289",
        "AirportCode": "NOV",
        "AirportName": "Huambo Airport",
        "City": "Huambo",
        "Country": "Angola"
    },
    {
        "id": "5290",
        "AirportCode": "NOZ",
        "AirportName": "Novokuznetsk Airport",
        "City": "Novokuznetsk",
        "Country": "Russia"
    },
    {
        "id": "5291",
        "AirportCode": "NPA",
        "AirportName": "Pensacola Naval Air Station",
        "City": "Pensacola",
        "Country": "USA Florida"
    },
    {
        "id": "5292",
        "AirportCode": "NPE",
        "AirportName": "Hawkes Bay Airport",
        "City": "Napier",
        "Country": "New Zealand"
    },
    {
        "id": "5293",
        "AirportCode": "NPG",
        "AirportName": "Nipa Airport",
        "City": "Nipa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5294",
        "AirportCode": "NPH",
        "AirportName": "Nephi Airport",
        "City": "Nephi",
        "Country": "USA Utah"
    },
    {
        "id": "5295",
        "AirportCode": "NPL",
        "AirportName": "New Plymouth Airport",
        "City": "New Plymouth",
        "Country": "New Zealand"
    },
    {
        "id": "5296",
        "AirportCode": "NPO",
        "AirportName": "Nangapinoh Airport",
        "City": "Nangapinoh",
        "Country": "Indonesia"
    },
    {
        "id": "5297",
        "AirportCode": "NPP",
        "AirportName": "Napperby Airport",
        "City": "Napperby",
        "Country": "Australia"
    },
    {
        "id": "5298",
        "AirportCode": "NPT",
        "AirportName": "State Airport",
        "City": "Newport",
        "Country": "USA Rhode Island"
    },
    {
        "id": "5299",
        "AirportCode": "NPU",
        "AirportName": "San Pedro Uraba Airport",
        "City": "San Pedro Uraba",
        "Country": "Colombia"
    },
    {
        "id": "5300",
        "AirportCode": "NQA",
        "AirportName": "Naval Air Station",
        "City": "Memphis",
        "Country": "USA Tennessee"
    },
    {
        "id": "5301",
        "AirportCode": "NQI",
        "AirportName": "Naval Air Station",
        "City": "Kingsville",
        "Country": "USA Texas"
    },
    {
        "id": "5302",
        "AirportCode": "NQL",
        "AirportName": "Niquelandia Airport",
        "City": "Niquelandia",
        "Country": "Brazil"
    },
    {
        "id": "5303",
        "AirportCode": "NQN",
        "AirportName": "Neuquen Airport",
        "City": "Neuquen",
        "Country": "Argentina"
    },
    {
        "id": "5304",
        "AirportCode": "NQT",
        "AirportName": "Nottingham Airport",
        "City": "Nottingham",
        "Country": "United Kingdom"
    },
    {
        "id": "5305",
        "AirportCode": "NQU",
        "AirportName": "Nuqui Airport",
        "City": "Nuqui",
        "Country": "Colombia"
    },
    {
        "id": "5306",
        "AirportCode": "NQX",
        "AirportName": "Naval Air Station",
        "City": "Key West",
        "Country": "USA Florida"
    },
    {
        "id": "5307",
        "AirportCode": "NQY",
        "AirportName": "St Mawgan Airport",
        "City": "Newquay",
        "Country": "United Kingdom"
    },
    {
        "id": "5308",
        "AirportCode": "NRA",
        "AirportName": "Narrandera Airport",
        "City": "Narrandera",
        "Country": "Australia"
    },
    {
        "id": "5309",
        "AirportCode": "NRB",
        "AirportName": "Mayport Naval Station",
        "City": "Mayport",
        "Country": "USA Florida"
    },
    {
        "id": "5310",
        "AirportCode": "NRC",
        "AirportName": "Aux Field",
        "City": "Crows Landing",
        "Country": "USA California"
    },
    {
        "id": "5311",
        "AirportCode": "NRD",
        "AirportName": "Norderney Airport",
        "City": "Norderney",
        "Country": "Germany"
    },
    {
        "id": "5312",
        "AirportCode": "NRE",
        "AirportName": "Namrole Airport",
        "City": "Namrole",
        "Country": "Indonesia"
    },
    {
        "id": "5313",
        "AirportCode": "NRG",
        "AirportName": "Narrogin Airport",
        "City": "Narrogin",
        "Country": "Australia"
    },
    {
        "id": "5314",
        "AirportCode": "NRI",
        "AirportName": "Grand Lake Regional",
        "City": "Afton",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5315",
        "AirportCode": "NRI",
        "AirportName": "Shangri-la Airport",
        "City": "Shangri-la",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5316",
        "AirportCode": "NRK",
        "AirportName": "Kungsangen Airport",
        "City": "Norrkoping",
        "Country": "Sweden"
    },
    {
        "id": "5317",
        "AirportCode": "NRL",
        "AirportName": "North Ronaldsay Airport",
        "City": "North Ronaldsay",
        "Country": "United Kingdom"
    },
    {
        "id": "5318",
        "AirportCode": "NRM",
        "AirportName": "Nara Airport",
        "City": "Nara",
        "Country": "Mali"
    },
    {
        "id": "5319",
        "AirportCode": "NRN",
        "AirportName": "Niederrhein Airport",
        "City": "Dusseldorf",
        "Country": "Germany"
    },
    {
        "id": "5320",
        "AirportCode": "NRR",
        "AirportName": "Roosevelt Naval Air Station",
        "City": "Roosevelt Roads",
        "Country": "Puerto Rico"
    },
    {
        "id": "5321",
        "AirportCode": "NRS",
        "AirportName": "Naval Air Station",
        "City": "Imperial Beach",
        "Country": "USA California"
    },
    {
        "id": "5322",
        "AirportCode": "NRT",
        "AirportName": "Narita Airport",
        "City": "Tokyo",
        "Country": "Japan"
    },
    {
        "id": "5323",
        "AirportCode": "NRV",
        "AirportName": "United States Coast Guard Shore Station",
        "City": "Guam",
        "Country": "Guam"
    },
    {
        "id": "5324",
        "AirportCode": "NRY",
        "AirportName": "Newry Airport",
        "City": "Newry",
        "Country": "Australia"
    },
    {
        "id": "5325",
        "AirportCode": "NSA",
        "AirportName": "Noosa Airport",
        "City": "Noosa",
        "Country": "Australia"
    },
    {
        "id": "5326",
        "AirportCode": "NSB",
        "AirportName": "North Sea Plane Base",
        "City": "Bimini",
        "Country": "Bahamas"
    },
    {
        "id": "5327",
        "AirportCode": "NSE",
        "AirportName": "Whiting Field Naval Air Station",
        "City": "Milton",
        "Country": "USA Florida"
    },
    {
        "id": "5328",
        "AirportCode": "NSF",
        "AirportName": "Andrews Navy Air Field",
        "City": "Camp Springs",
        "Country": "USA Maryland"
    },
    {
        "id": "5329",
        "AirportCode": "NSH",
        "AirportName": "Now Shahr Airport",
        "City": "Now Shahr",
        "Country": "Iran"
    },
    {
        "id": "5330",
        "AirportCode": "NSI",
        "AirportName": "Nsimalen Airport",
        "City": "Yaounde",
        "Country": "Cameroon"
    },
    {
        "id": "5331",
        "AirportCode": "NSM",
        "AirportName": "Norseman Airport",
        "City": "Norseman",
        "Country": "Australia"
    },
    {
        "id": "5332",
        "AirportCode": "NSN",
        "AirportName": "Nelson Airport",
        "City": "Nelson",
        "Country": "New Zealand"
    },
    {
        "id": "5333",
        "AirportCode": "NSO",
        "AirportName": "Scone Airport",
        "City": "Scone",
        "Country": "Australia"
    },
    {
        "id": "5334",
        "AirportCode": "NST",
        "AirportName": "Nakhon Si Thammarat Airport",
        "City": "Nakhon Si Thammarat",
        "Country": "Thailand"
    },
    {
        "id": "5335",
        "AirportCode": "NSV",
        "AirportName": "Noosaville Airport",
        "City": "Noosaville",
        "Country": "Australia"
    },
    {
        "id": "5336",
        "AirportCode": "NSX",
        "AirportName": "North Sound Virgin Gorda Hovercraft/Launch Point",
        "City": "North Sound Virgin Gorda",
        "Country": "British Virgin Islands"
    },
    {
        "id": "5337",
        "AirportCode": "NSY",
        "AirportName": "Sigonella Naval Air Field",
        "City": "Sigonella",
        "Country": "Italy"
    },
    {
        "id": "5338",
        "AirportCode": "NTA",
        "AirportName": "Natadola Airport",
        "City": "Natadola",
        "Country": "Fiji"
    },
    {
        "id": "5339",
        "AirportCode": "NTB",
        "AirportName": "Notodden Airport",
        "City": "Notodden",
        "Country": "Norway"
    },
    {
        "id": "5340",
        "AirportCode": "NTD",
        "AirportName": "Point Mugu Naval Air Station",
        "City": "Port Hueneme",
        "Country": "USA California"
    },
    {
        "id": "5341",
        "AirportCode": "NTE",
        "AirportName": "Nantes Atlantique Airport",
        "City": "Nantes",
        "Country": "France"
    },
    {
        "id": "5342",
        "AirportCode": "NTG",
        "AirportName": "Nantong Airport",
        "City": "Nantong",
        "Country": "China"
    },
    {
        "id": "5343",
        "AirportCode": "NTI",
        "AirportName": "Bintuni Airport",
        "City": "Bintuni",
        "Country": "Indonesia"
    },
    {
        "id": "5344",
        "AirportCode": "NTJ",
        "AirportName": "Manti-Ephraim Airport",
        "City": "Manti",
        "Country": "USA Utah"
    },
    {
        "id": "5345",
        "AirportCode": "NTL",
        "AirportName": "Williamtown Airport",
        "City": "Newcastle",
        "Country": "Australia"
    },
    {
        "id": "5346",
        "AirportCode": "NTM",
        "AirportName": "Miracena Do Norte Airport",
        "City": "Miracena Do Norte",
        "Country": "Brazil"
    },
    {
        "id": "5347",
        "AirportCode": "NTN",
        "AirportName": "Normanton Airport",
        "City": "Normanton",
        "Country": "Australia"
    },
    {
        "id": "5348",
        "AirportCode": "NTO",
        "AirportName": "Santo Antao Airport",
        "City": "Santo Antao",
        "Country": "Cape Verde"
    },
    {
        "id": "5349",
        "AirportCode": "NTQ",
        "AirportName": "Noto Airport",
        "City": "Wajima",
        "Country": "Japan"
    },
    {
        "id": "5350",
        "AirportCode": "NTR",
        "AirportName": "Aeropuerto Del Norte",
        "City": "Monterrey",
        "Country": "Mexico"
    },
    {
        "id": "5351",
        "AirportCode": "NTT",
        "AirportName": "Kuini Lavenia Airport",
        "City": "Niuatoputapu",
        "Country": "Tonga"
    },
    {
        "id": "5352",
        "AirportCode": "NTU",
        "AirportName": "Naval Air Station",
        "City": "Oceana",
        "Country": "USA Virginia"
    },
    {
        "id": "5353",
        "AirportCode": "NTX",
        "AirportName": "Natuna Ranai Airport",
        "City": "Natuna Ranai",
        "Country": "Indonesia"
    },
    {
        "id": "5354",
        "AirportCode": "NTY",
        "AirportName": "Pilansberg Airport",
        "City": "Sun City",
        "Country": "South Africa"
    },
    {
        "id": "5355",
        "AirportCode": "NUB",
        "AirportName": "Numbulwar Airport",
        "City": "Numbulwar",
        "Country": "Australia"
    },
    {
        "id": "5356",
        "AirportCode": "NUD",
        "AirportName": "En Nahud Airport",
        "City": "En Nahud",
        "Country": "Sudan"
    },
    {
        "id": "5357",
        "AirportCode": "NUE",
        "AirportName": "Metropolitan Area Airport",
        "City": "Nuremberg",
        "Country": "Germany"
    },
    {
        "id": "5358",
        "AirportCode": "NUG",
        "AirportName": "Nuguria Airport",
        "City": "Nuguria",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5359",
        "AirportCode": "NUH",
        "AirportName": "Nunchia Airport",
        "City": "Nunchia",
        "Country": "Colombia"
    },
    {
        "id": "5360",
        "AirportCode": "NUI",
        "AirportName": "Nuiqsut Airport",
        "City": "Nuiqsut",
        "Country": "USA Alaska"
    },
    {
        "id": "5361",
        "AirportCode": "NUJ",
        "AirportName": "Nojeh Airport",
        "City": "Nojeh",
        "Country": "Iran"
    },
    {
        "id": "5362",
        "AirportCode": "NUK",
        "AirportName": "Nukutavake Airport",
        "City": "Nukutavake, Tuamoto Island",
        "Country": "French Polynesia"
    },
    {
        "id": "5363",
        "AirportCode": "NUL",
        "AirportName": "Nulato Airport",
        "City": "Nulato",
        "Country": "USA Alaska"
    },
    {
        "id": "5364",
        "AirportCode": "NUN",
        "AirportName": "Saufley Naval Air Station",
        "City": "Pensacola",
        "Country": "USA Florida"
    },
    {
        "id": "5365",
        "AirportCode": "NUP",
        "AirportName": "Nunapitchuk Airport",
        "City": "Nunapitchuk",
        "Country": "USA Alaska"
    },
    {
        "id": "5366",
        "AirportCode": "NUQ",
        "AirportName": "Moffett Field",
        "City": "Mountain View",
        "Country": "USA California"
    },
    {
        "id": "5367",
        "AirportCode": "NUR",
        "AirportName": "Nullarbor Airport",
        "City": "Nullarbor",
        "Country": "Australia"
    },
    {
        "id": "5368",
        "AirportCode": "NUS",
        "AirportName": "Norsup Airport",
        "City": "Norsup",
        "Country": "Vanuatu"
    },
    {
        "id": "5369",
        "AirportCode": "NUT",
        "AirportName": "Nutuve Airport",
        "City": "Nutuve",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5370",
        "AirportCode": "NUU",
        "AirportName": "Nakuru Airport",
        "City": "Nakuru",
        "Country": "Kenya"
    },
    {
        "id": "5371",
        "AirportCode": "NUW",
        "AirportName": "Ault Field",
        "City": "Whidbey Island",
        "Country": "USA Washington"
    },
    {
        "id": "5372",
        "AirportCode": "NUX",
        "AirportName": "Novyj Urengoj Airport",
        "City": "Novyj Urengoj",
        "Country": "Russia"
    },
    {
        "id": "5373",
        "AirportCode": "NVA",
        "AirportName": "La Marguita Airport",
        "City": "Neiva",
        "Country": "Colombia"
    },
    {
        "id": "5374",
        "AirportCode": "NVD",
        "AirportName": "Nevada Airport",
        "City": "Nevada",
        "Country": "USA Missouri"
    },
    {
        "id": "5375",
        "AirportCode": "NVG",
        "AirportName": "Nueva Guinea Airport",
        "City": "Nueva Guinea",
        "Country": "Nicaragua"
    },
    {
        "id": "5376",
        "AirportCode": "NVI",
        "AirportName": "Navoi Airport",
        "City": "Navoi",
        "Country": "Uzbekistan"
    },
    {
        "id": "5377",
        "AirportCode": "NVK",
        "AirportName": "Framnes Airport",
        "City": "Narvik",
        "Country": "Norway"
    },
    {
        "id": "5378",
        "AirportCode": "NVP",
        "AirportName": "Novo Aripuana Airport",
        "City": "Novo Aripuana",
        "Country": "Brazil"
    },
    {
        "id": "5379",
        "AirportCode": "NVR",
        "AirportName": "Novgorod Airport",
        "City": "Novgorod",
        "Country": "Russia"
    },
    {
        "id": "5380",
        "AirportCode": "NVS",
        "AirportName": "Nevers Airport",
        "City": "Nevers",
        "Country": "France"
    },
    {
        "id": "5381",
        "AirportCode": "NVT",
        "AirportName": "Navegantes Airport",
        "City": "Navegantes",
        "Country": "Brazil"
    },
    {
        "id": "5382",
        "AirportCode": "NVY",
        "AirportName": "Neyveli Airport",
        "City": "Neyveli",
        "Country": "India"
    },
    {
        "id": "5383",
        "AirportCode": "NWA",
        "AirportName": "Moheli Airport",
        "City": "Moheli",
        "Country": "Comoros"
    },
    {
        "id": "5384",
        "AirportCode": "NWH",
        "AirportName": "Parlin Field Airport",
        "City": "Newport",
        "Country": "USA New Hampshire"
    },
    {
        "id": "5385",
        "AirportCode": "NWI",
        "AirportName": "Norwich Airport",
        "City": "Norwich",
        "Country": "United Kingdom"
    },
    {
        "id": "5386",
        "AirportCode": "NWP",
        "AirportName": "Naval Station",
        "City": "Argentia",
        "Country": "Canada"
    },
    {
        "id": "5387",
        "AirportCode": "NWS",
        "AirportName": "Pier 11-Wall St Sea Plane Base",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "5388",
        "AirportCode": "NWT",
        "AirportName": "Nowata Airport",
        "City": "Nowata",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5389",
        "AirportCode": "NWU",
        "AirportName": "Naval Air Station",
        "City": "Bermuda",
        "Country": "Bermuda"
    },
    {
        "id": "5390",
        "AirportCode": "NXX",
        "AirportName": "Willow Grove Naval Air Station",
        "City": "Willow Grove",
        "Country": "USA Philadelphia"
    },
    {
        "id": "5391",
        "AirportCode": "NYE",
        "AirportName": "Nyeri Airport",
        "City": "Nyeri",
        "Country": "Kenya"
    },
    {
        "id": "5392",
        "AirportCode": "NYG",
        "AirportName": "Quantico Naval Air Station",
        "City": "Quantico",
        "Country": "USA Virginia"
    },
    {
        "id": "5393",
        "AirportCode": "NYI",
        "AirportName": "Sunyani Airport",
        "City": "Sunyani",
        "Country": "Ghana"
    },
    {
        "id": "5394",
        "AirportCode": "NYK",
        "AirportName": "Nanyuki Airport",
        "City": "Nanyuki",
        "Country": "Kenya"
    },
    {
        "id": "5395",
        "AirportCode": "NYL",
        "AirportName": "Yuma International Airport",
        "City": "Yuma",
        "Country": "USA Arizona"
    },
    {
        "id": "5396",
        "AirportCode": "NYM",
        "AirportName": "Nadym Airport",
        "City": "Nadym",
        "Country": "Russia"
    },
    {
        "id": "5397",
        "AirportCode": "NYN",
        "AirportName": "Nyngan Airport",
        "City": "Nyngan",
        "Country": "Australia"
    },
    {
        "id": "5398",
        "AirportCode": "NYO",
        "AirportName": "Skavsta Airport",
        "City": "Stockholm",
        "Country": "Sweden"
    },
    {
        "id": "5399",
        "AirportCode": "NYU",
        "AirportName": "Nyaung-u Airport",
        "City": "Nyaung-u",
        "Country": "Burma"
    },
    {
        "id": "5400",
        "AirportCode": "NZC",
        "AirportName": "Cecil Field Naval Air Station",
        "City": "Jacksonville",
        "Country": "USA Florida"
    },
    {
        "id": "5401",
        "AirportCode": "NZE",
        "AirportName": "Nzerekore Airport",
        "City": "Nzerekore",
        "Country": "Guinea"
    },
    {
        "id": "5402",
        "AirportCode": "NZH",
        "AirportName": "Manzhouli Airport",
        "City": "Manzhouli",
        "Country": "China"
    },
    {
        "id": "5403",
        "AirportCode": "NZO",
        "AirportName": "Nzoia Airport",
        "City": "Nzoia",
        "Country": "Kenya"
    },
    {
        "id": "5404",
        "AirportCode": "NZW",
        "AirportName": "South Weymouth Airport",
        "City": "South Weymouth",
        "Country": "USA Massachusetts"
    },
    {
        "id": "5405",
        "AirportCode": "NZY",
        "AirportName": "North Island Naval Air Station",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "5406",
        "AirportCode": "OAG",
        "AirportName": "Springhill Airport",
        "City": "Orange",
        "Country": "Australia"
    },
    {
        "id": "5407",
        "AirportCode": "OAJ",
        "AirportName": "Albert J Ellis Airport",
        "City": "Jacksonville",
        "Country": "USA North Carolina"
    },
    {
        "id": "5408",
        "AirportCode": "OAK",
        "AirportName": "Metropolitan Oakland International Airport",
        "City": "Oakland",
        "Country": "USA California"
    },
    {
        "id": "5409",
        "AirportCode": "OAL",
        "AirportName": "Cacoal Airport",
        "City": "Cacoal",
        "Country": "Brazil"
    },
    {
        "id": "5410",
        "AirportCode": "OAM",
        "AirportName": "Oamaru Airport",
        "City": "Oamaru",
        "Country": "New Zealand"
    },
    {
        "id": "5411",
        "AirportCode": "OAN",
        "AirportName": "Olanchito Airport",
        "City": "Olanchito",
        "Country": "Honduras"
    },
    {
        "id": "5412",
        "AirportCode": "OAR",
        "AirportName": "Fritzche Army Air Field",
        "City": "Monterey",
        "Country": "USA California"
    },
    {
        "id": "5413",
        "AirportCode": "OAX",
        "AirportName": "Xoxocotlan Airport",
        "City": "Oaxaca",
        "Country": "Mexico"
    },
    {
        "id": "5414",
        "AirportCode": "OBA",
        "AirportName": "Oban Airport",
        "City": "Oban",
        "Country": "Australia"
    },
    {
        "id": "5415",
        "AirportCode": "OBC",
        "AirportName": "Obock Airport",
        "City": "Obock",
        "Country": "Djibouti"
    },
    {
        "id": "5416",
        "AirportCode": "OBD",
        "AirportName": "Obano Airport",
        "City": "Obano",
        "Country": "Indonesia"
    },
    {
        "id": "5417",
        "AirportCode": "OBE",
        "AirportName": "Okeechobee County Airport",
        "City": "Okeechobee",
        "Country": "USA Florida"
    },
    {
        "id": "5418",
        "AirportCode": "OBF",
        "AirportName": "Oberpfaffenhofen Airport",
        "City": "Oberpfaffenhofen",
        "Country": "Germany"
    },
    {
        "id": "5419",
        "AirportCode": "OBI",
        "AirportName": "Obidos Airport",
        "City": "Obidos",
        "Country": "Brazil"
    },
    {
        "id": "5420",
        "AirportCode": "OBK",
        "AirportName": "Sky Harbor Airport",
        "City": "Northbrook",
        "Country": "USA Illinois"
    },
    {
        "id": "5421",
        "AirportCode": "OBL",
        "AirportName": "Zoersel Airport",
        "City": "Zoersel",
        "Country": "Belgium"
    },
    {
        "id": "5422",
        "AirportCode": "OBM",
        "AirportName": "Morobe Airport",
        "City": "Morobe",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5423",
        "AirportCode": "OBN",
        "AirportName": "Connel Airport",
        "City": "Oban",
        "Country": "United Kingdom"
    },
    {
        "id": "5424",
        "AirportCode": "OBO",
        "AirportName": "Obihiro Airport",
        "City": "Obihiro",
        "Country": "Japan"
    },
    {
        "id": "5425",
        "AirportCode": "OBS",
        "AirportName": "Vals-Lanas Airport",
        "City": "Aubenas",
        "Country": "France"
    },
    {
        "id": "5426",
        "AirportCode": "OBT",
        "AirportName": "Oakland/Coliseum Station",
        "City": "Oakland",
        "Country": "USA Maryland"
    },
    {
        "id": "5427",
        "AirportCode": "OBU",
        "AirportName": "Kobuk/Wien Airport",
        "City": "Kobuk",
        "Country": "USA Alaska"
    },
    {
        "id": "5428",
        "AirportCode": "OBX",
        "AirportName": "Obo Airport",
        "City": "Obo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5429",
        "AirportCode": "OBY",
        "AirportName": "Ittoqqortoormiit Airport",
        "City": "Ittoqqortoormiit",
        "Country": "Greenland"
    },
    {
        "id": "5430",
        "AirportCode": "OCA",
        "AirportName": "Ocean Reef Airport",
        "City": "Ocean Reef",
        "Country": "USA Florida"
    },
    {
        "id": "5431",
        "AirportCode": "OCC",
        "AirportName": "Coca Airport",
        "City": "Coca",
        "Country": "Ecuador"
    },
    {
        "id": "5432",
        "AirportCode": "OCE",
        "AirportName": "Ocean City Municipal Airport",
        "City": "Ocean City",
        "Country": "USA Maryland"
    },
    {
        "id": "5433",
        "AirportCode": "OCF",
        "AirportName": "Taylor Field",
        "City": "Ocala",
        "Country": "USA Florida"
    },
    {
        "id": "5434",
        "AirportCode": "OCH",
        "AirportName": "Nacogdoches Airport",
        "City": "Nacogdoches",
        "Country": "USA Texas"
    },
    {
        "id": "5435",
        "AirportCode": "OCJ",
        "AirportName": "Boscobel Airport",
        "City": "Ocho Rios",
        "Country": "Jamaica"
    },
    {
        "id": "5436",
        "AirportCode": "OCN",
        "AirportName": "Oceanside Municipal Airport",
        "City": "Oceanside",
        "Country": "USA California"
    },
    {
        "id": "5437",
        "AirportCode": "OCV",
        "AirportName": "Aguasclaras Airport",
        "City": "Ocana",
        "Country": "Colombia"
    },
    {
        "id": "5438",
        "AirportCode": "OCW",
        "AirportName": "Warren Field",
        "City": "Washington",
        "Country": "USA North Carolina"
    },
    {
        "id": "5439",
        "AirportCode": "ODA",
        "AirportName": "Ouadda Airport",
        "City": "Ouadda",
        "Country": "Central African Republic"
    },
    {
        "id": "5440",
        "AirportCode": "ODB",
        "AirportName": "Cordoba Airport",
        "City": "Cordoba",
        "Country": "Spain"
    },
    {
        "id": "5441",
        "AirportCode": "ODD",
        "AirportName": "Oodnadatta Airport",
        "City": "Oodnadatta",
        "Country": "Australia"
    },
    {
        "id": "5442",
        "AirportCode": "ODE",
        "AirportName": "Beldringe Airport",
        "City": "Odense",
        "Country": "Denmark"
    },
    {
        "id": "5443",
        "AirportCode": "ODH",
        "AirportName": "Royal Air Force Station",
        "City": "Odiham",
        "Country": "United Kingdom"
    },
    {
        "id": "5444",
        "AirportCode": "ODJ",
        "AirportName": "Ouanda Djalle Airport",
        "City": "Ouanda Djalle",
        "Country": "Central African Republic"
    },
    {
        "id": "5445",
        "AirportCode": "ODL",
        "AirportName": "Guarapari",
        "City": "Guarapari",
        "Country": "Brazil"
    },
    {
        "id": "5446",
        "AirportCode": "ODL",
        "AirportName": "Cordillo Downs Airport",
        "City": "Cordillo Downs",
        "Country": "Australia"
    },
    {
        "id": "5447",
        "AirportCode": "ODM",
        "AirportName": "Metropolitan Area Airport",
        "City": "Oakland",
        "Country": "USA Maryland"
    },
    {
        "id": "5448",
        "AirportCode": "ODN",
        "AirportName": "Long Seridan Airport",
        "City": "Long Seridan",
        "Country": "Malaysia"
    },
    {
        "id": "5449",
        "AirportCode": "ODR",
        "AirportName": "Ord River Airport",
        "City": "Ord River",
        "Country": "Australia"
    },
    {
        "id": "5450",
        "AirportCode": "ODS",
        "AirportName": "Odessa-Central Airport",
        "City": "Odessa",
        "Country": "Ukraine"
    },
    {
        "id": "5451",
        "AirportCode": "ODW",
        "AirportName": "Oak Harbor Airport",
        "City": "Oak Harbor",
        "Country": "USA Washington"
    },
    {
        "id": "5452",
        "AirportCode": "ODY",
        "AirportName": "Oudomxay Airport",
        "City": "Oudomxay",
        "Country": "Laos"
    },
    {
        "id": "5453",
        "AirportCode": "OEA",
        "AirportName": "Oneal Airport",
        "City": "Vincennes",
        "Country": "USA Indiana"
    },
    {
        "id": "5454",
        "AirportCode": "OEC",
        "AirportName": "Ocussi Airport",
        "City": "Ocussi",
        "Country": "Indonesia"
    },
    {
        "id": "5455",
        "AirportCode": "OEL",
        "AirportName": "Orel Airport",
        "City": "Orel",
        "Country": "Russia"
    },
    {
        "id": "5456",
        "AirportCode": "OEM",
        "AirportName": "Vincent Fayks Airport",
        "City": "Paloemeu",
        "Country": "Suriname"
    },
    {
        "id": "5457",
        "AirportCode": "OEO",
        "AirportName": "Osceola Municipal Airport",
        "City": "Osceola",
        "Country": "USA Wisconsin"
    },
    {
        "id": "5458",
        "AirportCode": "OER",
        "AirportName": "Ornskoldsvik Airport",
        "City": "Ornskoldsvik",
        "Country": "Sweden"
    },
    {
        "id": "5459",
        "AirportCode": "OES",
        "AirportName": "San Antonio Oeste Airport",
        "City": "San Antonio Oeste",
        "Country": "Argentina"
    },
    {
        "id": "5460",
        "AirportCode": "OFF",
        "AirportName": "Offutt Afb",
        "City": "Omaha",
        "Country": "USA Nebraska"
    },
    {
        "id": "5461",
        "AirportCode": "OFJ",
        "AirportName": "Olafsfjordur Airport",
        "City": "Olafsfjordur",
        "Country": "Iceland"
    },
    {
        "id": "5462",
        "AirportCode": "OFK",
        "AirportName": "Stefan Field",
        "City": "Norfolk",
        "Country": "USA Nebraska"
    },
    {
        "id": "5463",
        "AirportCode": "OFU",
        "AirportName": "Airport",
        "City": "Ofu",
        "Country": "Samoa"
    },
    {
        "id": "5464",
        "AirportCode": "OGA",
        "AirportName": "Searle Field",
        "City": "Ogallala",
        "Country": "USA Nebraska"
    },
    {
        "id": "5465",
        "AirportCode": "OGB",
        "AirportName": "Orangeburg Municipal Airport",
        "City": "Orangeburg",
        "Country": "USA South Carolina"
    },
    {
        "id": "5466",
        "AirportCode": "OGD",
        "AirportName": "Ogden Municipal Airport",
        "City": "Ogden",
        "Country": "USA Utah"
    },
    {
        "id": "5467",
        "AirportCode": "OGE",
        "AirportName": "Ogeranang Airport",
        "City": "Ogeranang",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5468",
        "AirportCode": "OGG",
        "AirportName": "Kahului Airport",
        "City": "Kahului",
        "Country": "USA Hawaii"
    },
    {
        "id": "5469",
        "AirportCode": "OGL",
        "AirportName": "Ogle Airport",
        "City": "Ogle",
        "Country": "Guyana"
    },
    {
        "id": "5470",
        "AirportCode": "OGN",
        "AirportName": "Yonaguni Airport",
        "City": "Yonaguni",
        "Country": "Japan"
    },
    {
        "id": "5471",
        "AirportCode": "OGR",
        "AirportName": "Bongor Airport",
        "City": "Bongor",
        "Country": "Mali"
    },
    {
        "id": "5472",
        "AirportCode": "OGS",
        "AirportName": "Ogdensburg Airport",
        "City": "Ogdensburg",
        "Country": "USA New York"
    },
    {
        "id": "5473",
        "AirportCode": "OGX",
        "AirportName": "Ain Beida Airport",
        "City": "Ouargla",
        "Country": "Algeria"
    },
    {
        "id": "5474",
        "AirportCode": "OGZ",
        "AirportName": "Vladikavkaz Airport",
        "City": "Vladikavkaz",
        "Country": "Russia"
    },
    {
        "id": "5475",
        "AirportCode": "OHA",
        "AirportName": "Royal Air Force Base",
        "City": "Ohakea",
        "Country": "New Zealand"
    },
    {
        "id": "5476",
        "AirportCode": "OHD",
        "AirportName": "Ohrid Airport",
        "City": "Ohrid",
        "Country": "Macedonia"
    },
    {
        "id": "5477",
        "AirportCode": "OHI",
        "AirportName": "Oshakati Airport",
        "City": "Oshakati",
        "Country": "Namibia"
    },
    {
        "id": "5478",
        "AirportCode": "OHO",
        "AirportName": "Okhotsk Airport",
        "City": "Okhotsk",
        "Country": "Russia"
    },
    {
        "id": "5479",
        "AirportCode": "OHP",
        "AirportName": "Oban Heliport",
        "City": "Oban",
        "Country": "United Kingdom"
    },
    {
        "id": "5480",
        "AirportCode": "OHR",
        "AirportName": "Wyk Airport",
        "City": "Wyk",
        "Country": "Germany"
    },
    {
        "id": "5481",
        "AirportCode": "OHT",
        "AirportName": "Kohat Airport",
        "City": "Kohat",
        "Country": "Pakistan"
    },
    {
        "id": "5482",
        "AirportCode": "OIA",
        "AirportName": "Ourilandia Airport",
        "City": "Ourilandia",
        "Country": "Brazil"
    },
    {
        "id": "5483",
        "AirportCode": "OIC",
        "AirportName": "Eaton Airport",
        "City": "Norwich",
        "Country": "USA New York"
    },
    {
        "id": "5484",
        "AirportCode": "OIL",
        "AirportName": "Splane Memorial Airport",
        "City": "Oil City",
        "Country": "USA Philadelphia"
    },
    {
        "id": "5485",
        "AirportCode": "OIM",
        "AirportName": "Oshima Airport",
        "City": "Oshima",
        "Country": "Japan"
    },
    {
        "id": "5486",
        "AirportCode": "OIR",
        "AirportName": "Okushiri Airport",
        "City": "Okushiri",
        "Country": "Japan"
    },
    {
        "id": "5487",
        "AirportCode": "OIT",
        "AirportName": "Oita Airport",
        "City": "Oita",
        "Country": "Japan"
    },
    {
        "id": "5488",
        "AirportCode": "OJC",
        "AirportName": "Johnson Executive Airport",
        "City": "Kansas City",
        "Country": "USA Missouri"
    },
    {
        "id": "5489",
        "AirportCode": "OKA",
        "AirportName": "Naha Airport",
        "City": "Okinawa",
        "Country": "Japan"
    },
    {
        "id": "5490",
        "AirportCode": "OKB",
        "AirportName": "Fraser Island Airport",
        "City": "Orchid Beach",
        "Country": "Australia"
    },
    {
        "id": "5491",
        "AirportCode": "OKC",
        "AirportName": "Will Rogers Airport",
        "City": "Oklahoma City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5492",
        "AirportCode": "OKD",
        "AirportName": "Okadama Airport",
        "City": "Sapporo",
        "Country": "Japan"
    },
    {
        "id": "5493",
        "AirportCode": "OKE",
        "AirportName": "Okino Erabu Airport",
        "City": "Okino Erabu",
        "Country": "Japan"
    },
    {
        "id": "5494",
        "AirportCode": "OKF",
        "AirportName": "Okaukuejo Airport",
        "City": "Okaukuejo",
        "Country": "Namibia"
    },
    {
        "id": "5495",
        "AirportCode": "OKG",
        "AirportName": "Okoyo Airport",
        "City": "Okoyo",
        "Country": "Congo"
    },
    {
        "id": "5496",
        "AirportCode": "OKH",
        "AirportName": "Cottesmor Royal Air Force",
        "City": "Oakham",
        "Country": "United Kingdom"
    },
    {
        "id": "5497",
        "AirportCode": "OKI",
        "AirportName": "Oki Island Airport",
        "City": "Oki Island",
        "Country": "Japan"
    },
    {
        "id": "5498",
        "AirportCode": "OKJ",
        "AirportName": "Okayama Airport",
        "City": "Okayama",
        "Country": "Japan"
    },
    {
        "id": "5499",
        "AirportCode": "OKK",
        "AirportName": "Kokomo Airport",
        "City": "Kokomo",
        "Country": "USA Indiana"
    },
    {
        "id": "5500",
        "AirportCode": "OKL",
        "AirportName": "Oksibil Airport",
        "City": "Oksibil",
        "Country": "Indonesia"
    },
    {
        "id": "5501",
        "AirportCode": "OKM",
        "AirportName": "Okmulgee Airport",
        "City": "Okmulgee",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5502",
        "AirportCode": "OKN",
        "AirportName": "Okondja Airport",
        "City": "Okondja",
        "Country": "Gabon"
    },
    {
        "id": "5503",
        "AirportCode": "OKO",
        "AirportName": "Yokota Air Force Base",
        "City": "Tokyo",
        "Country": "Japan"
    },
    {
        "id": "5504",
        "AirportCode": "OKP",
        "AirportName": "Oksapmin Airport",
        "City": "Oksapmin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5505",
        "AirportCode": "OKQ",
        "AirportName": "Okaba Airport",
        "City": "Okaba",
        "Country": "Indonesia"
    },
    {
        "id": "5506",
        "AirportCode": "OKR",
        "AirportName": "Yorke Island Airport",
        "City": "Yorke Island",
        "Country": "Australia"
    },
    {
        "id": "5507",
        "AirportCode": "OKS",
        "AirportName": "Oshkosh Airport",
        "City": "Oshkosh",
        "Country": "USA Nebraska"
    },
    {
        "id": "5508",
        "AirportCode": "OKT",
        "AirportName": "Oktiabrskij Airport",
        "City": "Oktiabrskij",
        "Country": "Russia"
    },
    {
        "id": "5509",
        "AirportCode": "OKU",
        "AirportName": "Mokuti Lodge Airport",
        "City": "Mokuti Lodge",
        "Country": "Namibia"
    },
    {
        "id": "5510",
        "AirportCode": "OKV",
        "AirportName": "Okao Airport",
        "City": "Okao",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5511",
        "AirportCode": "OKY",
        "AirportName": "Oakey Airport",
        "City": "Oakey",
        "Country": "Australia"
    },
    {
        "id": "5512",
        "AirportCode": "OLA",
        "AirportName": "Orland Airport",
        "City": "Orland",
        "Country": "Norway"
    },
    {
        "id": "5513",
        "AirportCode": "OLB",
        "AirportName": "Costa Smeralda Airport",
        "City": "Olbia",
        "Country": "Italy"
    },
    {
        "id": "5514",
        "AirportCode": "OLD",
        "AirportName": "Old Town Airport",
        "City": "Old Town",
        "Country": "USA Maine"
    },
    {
        "id": "5515",
        "AirportCode": "OLE",
        "AirportName": "Olean Municipal Airport",
        "City": "Olean",
        "Country": "USA New York"
    },
    {
        "id": "5516",
        "AirportCode": "OLF",
        "AirportName": "Wolf Point International Airport",
        "City": "Wolf Point",
        "Country": "USA Montana"
    },
    {
        "id": "5517",
        "AirportCode": "OLH",
        "AirportName": "Old Harbor Sea Plane Base",
        "City": "Old Harbor",
        "Country": "USA Alaska"
    },
    {
        "id": "5518",
        "AirportCode": "OLI",
        "AirportName": "Rif Airport",
        "City": "Olafsvik",
        "Country": "Iceland"
    },
    {
        "id": "5519",
        "AirportCode": "OLJ",
        "AirportName": "West Cost Santo Airport",
        "City": "Olpoi",
        "Country": "Vanuatu"
    },
    {
        "id": "5520",
        "AirportCode": "OLK",
        "AirportName": "Fuerte Olimpo Airport",
        "City": "Fuerte Olimpo",
        "Country": "Paraguay"
    },
    {
        "id": "5521",
        "AirportCode": "OLM",
        "AirportName": "Olympia Airport",
        "City": "Olympia",
        "Country": "USA Washington"
    },
    {
        "id": "5522",
        "AirportCode": "OLO",
        "AirportName": "Olomouc Airport",
        "City": "Olomouc",
        "Country": "Czech Republic"
    },
    {
        "id": "5523",
        "AirportCode": "OLP",
        "AirportName": "Olympic Dam Airport",
        "City": "Olympic Dam",
        "Country": "Australia"
    },
    {
        "id": "5524",
        "AirportCode": "OLQ",
        "AirportName": "Olsobip Airport",
        "City": "Olsobip",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5525",
        "AirportCode": "OLS",
        "AirportName": "Nogales International Airport",
        "City": "Nogales",
        "Country": "USA Arizona"
    },
    {
        "id": "5526",
        "AirportCode": "OLU",
        "AirportName": "Columbus Airport",
        "City": "Columbus",
        "Country": "USA Nebraska"
    },
    {
        "id": "5527",
        "AirportCode": "OLV",
        "AirportName": "Olive Branch Airport",
        "City": "Olive Branch",
        "Country": "USA Mississippi"
    },
    {
        "id": "5528",
        "AirportCode": "OLY",
        "AirportName": "Olney Airport",
        "City": "Olney",
        "Country": "USA Illinois"
    },
    {
        "id": "5529",
        "AirportCode": "OMA",
        "AirportName": "Eppley Airfield",
        "City": "Omaha",
        "Country": "USA Nebraska"
    },
    {
        "id": "5530",
        "AirportCode": "OMB",
        "AirportName": "Omboue Airport",
        "City": "Omboue",
        "Country": "Gabon"
    },
    {
        "id": "5531",
        "AirportCode": "OMC",
        "AirportName": "Ormoc Airport",
        "City": "Ormoc",
        "Country": "Philippines"
    },
    {
        "id": "5532",
        "AirportCode": "OMD",
        "AirportName": "Oranjemund Airport",
        "City": "Oranjemund",
        "Country": "Namibia"
    },
    {
        "id": "5533",
        "AirportCode": "OME",
        "AirportName": "Nome Airport",
        "City": "Nome",
        "Country": "USA Alaska"
    },
    {
        "id": "5534",
        "AirportCode": "OMF",
        "AirportName": "King Hussein Airport",
        "City": "Mafraq",
        "Country": "Jordan"
    },
    {
        "id": "5535",
        "AirportCode": "OMG",
        "AirportName": "Omega Airport",
        "City": "Omega",
        "Country": "Namibia"
    },
    {
        "id": "5536",
        "AirportCode": "OMH",
        "AirportName": "Umieh Airport",
        "City": "Urmieh",
        "Country": "Iran"
    },
    {
        "id": "5537",
        "AirportCode": "OMI",
        "AirportName": "Omidieh Airport",
        "City": "Omidieh",
        "Country": "Iran"
    },
    {
        "id": "5538",
        "AirportCode": "OMJ",
        "AirportName": "Omura Airport",
        "City": "Omura",
        "Country": "Japan"
    },
    {
        "id": "5539",
        "AirportCode": "OMK",
        "AirportName": "Omak Municipal Airport",
        "City": "Omak",
        "Country": "USA Washington"
    },
    {
        "id": "5540",
        "AirportCode": "OML",
        "AirportName": "Omkalai Airport",
        "City": "Omkalai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5541",
        "AirportCode": "OMN",
        "AirportName": "Osmanabad Airport",
        "City": "Osmanabad",
        "Country": "India"
    },
    {
        "id": "5542",
        "AirportCode": "OMO",
        "AirportName": "Mostar Airport",
        "City": "Mostar",
        "Country": "Bosnia & Herzegovina"
    },
    {
        "id": "5543",
        "AirportCode": "OMR",
        "AirportName": "Oradea Airport",
        "City": "Oradea",
        "Country": "Romania"
    },
    {
        "id": "5544",
        "AirportCode": "OMS",
        "AirportName": "Omsk Airport",
        "City": "Omsk",
        "Country": "Russia"
    },
    {
        "id": "5545",
        "AirportCode": "OMY",
        "AirportName": "Oddor Meanche Airport",
        "City": "Oddor Meanche",
        "Country": "Cambodia"
    },
    {
        "id": "5546",
        "AirportCode": "ONA",
        "AirportName": "Winona Municipal Airport",
        "City": "Winona",
        "Country": "USA Minnesota"
    },
    {
        "id": "5547",
        "AirportCode": "ONB",
        "AirportName": "Ononge Airport",
        "City": "Ononge",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5548",
        "AirportCode": "OND",
        "AirportName": "Ondangwa Airport",
        "City": "Ondangwa",
        "Country": "Namibia"
    },
    {
        "id": "5549",
        "AirportCode": "ONG",
        "AirportName": "Mornington Airport",
        "City": "Mornington",
        "Country": "Australia"
    },
    {
        "id": "5550",
        "AirportCode": "ONH",
        "AirportName": "Oneonta Municipal Airport",
        "City": "Oneonta",
        "Country": "USA New York"
    },
    {
        "id": "5551",
        "AirportCode": "ONI",
        "AirportName": "Moanamani Airport",
        "City": "Moanamani",
        "Country": "Indonesia"
    },
    {
        "id": "5552",
        "AirportCode": "ONJ",
        "AirportName": "Odate Noshiro Airport",
        "City": "Odate Noshiro",
        "Country": "Japan"
    },
    {
        "id": "5553",
        "AirportCode": "ONM",
        "AirportName": "Socorro Airport",
        "City": "Socorro",
        "Country": "USA New Mexico"
    },
    {
        "id": "5554",
        "AirportCode": "ONO",
        "AirportName": "Ontario Airport",
        "City": "Ontario",
        "Country": "USA Oregon"
    },
    {
        "id": "5555",
        "AirportCode": "ONP",
        "AirportName": "Newport Airport",
        "City": "Newport",
        "Country": "USA Oregon"
    },
    {
        "id": "5556",
        "AirportCode": "ONQ",
        "AirportName": "Zonguldak Airport",
        "City": "Zonguldak",
        "Country": "Turkey"
    },
    {
        "id": "5557",
        "AirportCode": "ONR",
        "AirportName": "Monkira Airport",
        "City": "Monkira",
        "Country": "Australia"
    },
    {
        "id": "5558",
        "AirportCode": "ONS",
        "AirportName": "Onslow Airport",
        "City": "Onslow",
        "Country": "Australia"
    },
    {
        "id": "5559",
        "AirportCode": "ONT",
        "AirportName": "Ontario International Airport",
        "City": "Ontario",
        "Country": "USA California"
    },
    {
        "id": "5560",
        "AirportCode": "ONU",
        "AirportName": "Ono I Lau Airport",
        "City": "Ono I Lau",
        "Country": "Fiji"
    },
    {
        "id": "5561",
        "AirportCode": "ONX",
        "AirportName": "Colon Airport",
        "City": "Colon",
        "Country": "Panama"
    },
    {
        "id": "5562",
        "AirportCode": "ONY",
        "AirportName": "Olney Airport",
        "City": "Olney",
        "Country": "USA Texas"
    },
    {
        "id": "5563",
        "AirportCode": "OOA",
        "AirportName": "Oskaloosa Municipal Airport",
        "City": "Oskaloosa",
        "Country": "USA Iowa"
    },
    {
        "id": "5564",
        "AirportCode": "OOK",
        "AirportName": "Toksook Bay Airport",
        "City": "Toksook Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "5565",
        "AirportCode": "OOL",
        "AirportName": "Gold Coast Airport",
        "City": "Bilinga (Gold Coast)",
        "Country": "Australia"
    },
    {
        "id": "5566",
        "AirportCode": "OOM",
        "AirportName": "Cooma Airport",
        "City": "Cooma",
        "Country": "Australia"
    },
    {
        "id": "5567",
        "AirportCode": "OOR",
        "AirportName": "Mooraberree Airport",
        "City": "Mooraberree",
        "Country": "Australia"
    },
    {
        "id": "5568",
        "AirportCode": "OOT",
        "AirportName": "Onotoa Airport",
        "City": "Onotoa",
        "Country": "Kiribati"
    },
    {
        "id": "5569",
        "AirportCode": "OPA",
        "AirportName": "Kopasker Airport",
        "City": "Kopasker",
        "Country": "Iceland"
    },
    {
        "id": "5570",
        "AirportCode": "OPB",
        "AirportName": "Open Bay Airport",
        "City": "Open Bay",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5571",
        "AirportCode": "OPF",
        "AirportName": "Opa Locka",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "5572",
        "AirportCode": "OPI",
        "AirportName": "Oenpelli Airport",
        "City": "Oenpelli",
        "Country": "Australia"
    },
    {
        "id": "5573",
        "AirportCode": "OPL",
        "AirportName": "St Landry Parish Airport",
        "City": "Opelousas",
        "Country": "USA Louisiana"
    },
    {
        "id": "5574",
        "AirportCode": "OPO",
        "AirportName": "Porto Airport",
        "City": "Porto",
        "Country": "Portugal"
    },
    {
        "id": "5575",
        "AirportCode": "OPS",
        "AirportName": "Sinop Airport",
        "City": "Sinop",
        "Country": "Brazil"
    },
    {
        "id": "5576",
        "AirportCode": "OPU",
        "AirportName": "Balimo Airport",
        "City": "Balimo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5577",
        "AirportCode": "OPW",
        "AirportName": "Opuwa Airport",
        "City": "Opuwa",
        "Country": "Namibia"
    },
    {
        "id": "5578",
        "AirportCode": "ORA",
        "AirportName": "Oran Airport",
        "City": "Oran",
        "Country": "Argentina"
    },
    {
        "id": "5579",
        "AirportCode": "ORB",
        "AirportName": "Orebro-Bofors Airport",
        "City": "Orebro",
        "Country": "Sweden"
    },
    {
        "id": "5580",
        "AirportCode": "ORC",
        "AirportName": "Orocue Airport",
        "City": "Orocue",
        "Country": "Colombia"
    },
    {
        "id": "5581",
        "AirportCode": "ORE",
        "AirportName": "Orleans Airport",
        "City": "Orleans",
        "Country": "France"
    },
    {
        "id": "5582",
        "AirportCode": "ORF",
        "AirportName": "Norfolk International Airport",
        "City": "Norfolk",
        "Country": "USA Virginia"
    },
    {
        "id": "5583",
        "AirportCode": "ORG",
        "AirportName": "Zorg En Hoop Airport",
        "City": "Paramaribo",
        "Country": "Suriname"
    },
    {
        "id": "5584",
        "AirportCode": "ORH",
        "AirportName": "Worcester Airport",
        "City": "Worcester",
        "Country": "USA Massachusetts"
    },
    {
        "id": "5585",
        "AirportCode": "ORI",
        "AirportName": "Port Lions Sea Plane Base",
        "City": "Port Lions",
        "Country": "USA Alaska"
    },
    {
        "id": "5586",
        "AirportCode": "ORJ",
        "AirportName": "Orinduik Airport",
        "City": "Orinduik",
        "Country": "Guyana"
    },
    {
        "id": "5587",
        "AirportCode": "ORK",
        "AirportName": "Cork Airport",
        "City": "Cork",
        "Country": "Ireland"
    },
    {
        "id": "5588",
        "AirportCode": "ORL",
        "AirportName": "Herndon Airport",
        "City": "Orlando",
        "Country": "USA Florida"
    },
    {
        "id": "5589",
        "AirportCode": "ORM",
        "AirportName": "Northampton Airport",
        "City": "Northampton",
        "Country": "United Kingdom"
    },
    {
        "id": "5590",
        "AirportCode": "ORN",
        "AirportName": "Es Senia Airport",
        "City": "Oran",
        "Country": "Algeria"
    },
    {
        "id": "5591",
        "AirportCode": "ORO",
        "AirportName": "Yoro Airport",
        "City": "Yoro",
        "Country": "Honduras"
    },
    {
        "id": "5592",
        "AirportCode": "ORP",
        "AirportName": "Orapa Airport",
        "City": "Orapa",
        "Country": "Botswana"
    },
    {
        "id": "5593",
        "AirportCode": "ORQ",
        "AirportName": "Norwalk Heliport",
        "City": "Norwalk",
        "Country": "USA Connecticut"
    },
    {
        "id": "5594",
        "AirportCode": "ORR",
        "AirportName": "Yorktown Airport",
        "City": "Yorktown",
        "Country": "Australia"
    },
    {
        "id": "5595",
        "AirportCode": "ORS",
        "AirportName": "Waterport Airport",
        "City": "Orpheus",
        "Country": "Australia"
    },
    {
        "id": "5596",
        "AirportCode": "ORT",
        "AirportName": "Northway Airport",
        "City": "Northway",
        "Country": "USA Alaska"
    },
    {
        "id": "5597",
        "AirportCode": "ORU",
        "AirportName": "Oruro Airport",
        "City": "Oruro",
        "Country": "Bolivia"
    },
    {
        "id": "5598",
        "AirportCode": "ORV",
        "AirportName": "Curtis Memorial Airport",
        "City": "Noorvik",
        "Country": "USA Alaska"
    },
    {
        "id": "5599",
        "AirportCode": "ORW",
        "AirportName": "Ormara Airport",
        "City": "Ormara",
        "Country": "Pakistan"
    },
    {
        "id": "5600",
        "AirportCode": "ORX",
        "AirportName": "Oriximina Airport",
        "City": "Oriximina",
        "Country": "Brazil"
    },
    {
        "id": "5601",
        "AirportCode": "ORY",
        "AirportName": "Orly Airport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "5602",
        "AirportCode": "ORZ",
        "AirportName": "Orange Walk Airport",
        "City": "Orange Walk",
        "Country": "Belize"
    },
    {
        "id": "5603",
        "AirportCode": "OSB",
        "AirportName": "Osage Beach Airport",
        "City": "Osage Beach",
        "Country": "USA Missouri"
    },
    {
        "id": "5604",
        "AirportCode": "OSC",
        "AirportName": "Wurtsmith Air Force Base",
        "City": "Oscoda",
        "Country": "USA Michigan"
    },
    {
        "id": "5605",
        "AirportCode": "OSD",
        "AirportName": "Froesoe Airport",
        "City": "Ostersund",
        "Country": "Sweden"
    },
    {
        "id": "5606",
        "AirportCode": "OSE",
        "AirportName": "Omora Airport",
        "City": "Omora",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5607",
        "AirportCode": "OSG",
        "AirportName": "Ossima Airport",
        "City": "Ossima",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5608",
        "AirportCode": "OSH",
        "AirportName": "Wittman Field",
        "City": "Oshkosh",
        "Country": "USA Wisconsin"
    },
    {
        "id": "5609",
        "AirportCode": "OSI",
        "AirportName": "Osijek Airport",
        "City": "Osijek",
        "Country": "Croatia"
    },
    {
        "id": "5610",
        "AirportCode": "OSK",
        "AirportName": "Oskarshamn Airport",
        "City": "Oskarshamn",
        "Country": "Sweden"
    },
    {
        "id": "5611",
        "AirportCode": "OSL",
        "AirportName": "Oslo Airport",
        "City": "Oslo All Airport",
        "Country": "Norway"
    },
    {
        "id": "5612",
        "AirportCode": "OSM",
        "AirportName": "Mosul Airport",
        "City": "Mosul",
        "Country": "Iraq"
    },
    {
        "id": "5613",
        "AirportCode": "OSN",
        "AirportName": "Ab Airport",
        "City": "Osan",
        "Country": "Korea"
    },
    {
        "id": "5614",
        "AirportCode": "OSP",
        "AirportName": "Redzikowo Airport",
        "City": "Slupsk",
        "Country": "Poland"
    },
    {
        "id": "5615",
        "AirportCode": "OSR",
        "AirportName": "Mosnov Airport",
        "City": "Ostrava",
        "Country": "Czech Republic"
    },
    {
        "id": "5616",
        "AirportCode": "OSS",
        "AirportName": "Osh Airport",
        "City": "Osh",
        "Country": "Kyrgyzstan"
    },
    {
        "id": "5617",
        "AirportCode": "OST",
        "AirportName": "Ostend Airport",
        "City": "Ostend",
        "Country": "Belgium"
    },
    {
        "id": "5618",
        "AirportCode": "OSU",
        "AirportName": "Ohio State University Airport",
        "City": "Columbus",
        "Country": "USA Ohio"
    },
    {
        "id": "5619",
        "AirportCode": "OSW",
        "AirportName": "Orsk Airport",
        "City": "Orsk",
        "Country": "Russia"
    },
    {
        "id": "5620",
        "AirportCode": "OSX",
        "AirportName": "Attala County Airport",
        "City": "Kosciusko",
        "Country": "USA Mississippi"
    },
    {
        "id": "5621",
        "AirportCode": "OSY",
        "AirportName": "Namsos Airport",
        "City": "Namsos",
        "Country": "Norway"
    },
    {
        "id": "5622",
        "AirportCode": "OSZ",
        "AirportName": "Koszalin Airport",
        "City": "Koszalin",
        "Country": "Poland"
    },
    {
        "id": "5623",
        "AirportCode": "OTA",
        "AirportName": "Mota Airport",
        "City": "Mota",
        "Country": "Ethiopia"
    },
    {
        "id": "5624",
        "AirportCode": "OTC",
        "AirportName": "Bol Airport",
        "City": "Bol",
        "Country": "Mali"
    },
    {
        "id": "5625",
        "AirportCode": "OTD",
        "AirportName": "Contadora Airport",
        "City": "Contadora",
        "Country": "Panama"
    },
    {
        "id": "5626",
        "AirportCode": "OTG",
        "AirportName": "Worthington Airport",
        "City": "Worthington",
        "Country": "USA Minnesota"
    },
    {
        "id": "5627",
        "AirportCode": "OTH",
        "AirportName": "North Bend Airport",
        "City": "North Bend",
        "Country": "USA Oregon"
    },
    {
        "id": "5628",
        "AirportCode": "OTI",
        "AirportName": "Morotai Island Airport",
        "City": "Morotai Island",
        "Country": "Indonesia"
    },
    {
        "id": "5629",
        "AirportCode": "OTJ",
        "AirportName": "Otjiwarongo Airport",
        "City": "Otjiwarongo",
        "Country": "Namibia"
    },
    {
        "id": "5630",
        "AirportCode": "OTL",
        "AirportName": "Boutilimit Airport",
        "City": "Boutilimit",
        "Country": "Mauritania"
    },
    {
        "id": "5631",
        "AirportCode": "OTM",
        "AirportName": "Industrial Airport",
        "City": "Ottumwa",
        "Country": "USA Iowa"
    },
    {
        "id": "5632",
        "AirportCode": "OTN",
        "AirportName": "Green Airport",
        "City": "Oaktown",
        "Country": "USA Indiana"
    },
    {
        "id": "5633",
        "AirportCode": "OTO",
        "AirportName": "Vor Airport",
        "City": "Otto",
        "Country": "USA New Mexico"
    },
    {
        "id": "5634",
        "AirportCode": "OTP",
        "AirportName": "Otopeni International Airport",
        "City": "Bucharest",
        "Country": "Romania"
    },
    {
        "id": "5635",
        "AirportCode": "OTR",
        "AirportName": "Coto 47 Airport",
        "City": "Coto 47",
        "Country": "Costa Rica"
    },
    {
        "id": "5636",
        "AirportCode": "OTS",
        "AirportName": "Anacortes Airport",
        "City": "Anacortes",
        "Country": "USA Washington"
    },
    {
        "id": "5637",
        "AirportCode": "OTU",
        "AirportName": "Otu Airport",
        "City": "Otu",
        "Country": "Colombia"
    },
    {
        "id": "5638",
        "AirportCode": "OTY",
        "AirportName": "Oria Airport",
        "City": "Oria",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5639",
        "AirportCode": "OTZ",
        "AirportName": "Kotzebue Airport",
        "City": "Kotzebue",
        "Country": "USA Alaska"
    },
    {
        "id": "5640",
        "AirportCode": "OUA",
        "AirportName": "Ouagadougou Airport",
        "City": "Ouagadougou",
        "Country": "Burkina Faso"
    },
    {
        "id": "5641",
        "AirportCode": "OUD",
        "AirportName": "Les Angades Airport",
        "City": "Oujda",
        "Country": "Morocco"
    },
    {
        "id": "5642",
        "AirportCode": "OUE",
        "AirportName": "Ouesso Airport",
        "City": "Ouesso",
        "Country": "Congo"
    },
    {
        "id": "5643",
        "AirportCode": "OUG",
        "AirportName": "Ouahigouya Airport",
        "City": "Ouahigouya",
        "Country": "Burkina Faso"
    },
    {
        "id": "5644",
        "AirportCode": "OUH",
        "AirportName": "Oudtshoorn Airport",
        "City": "Oudtshoorn",
        "Country": "South Africa"
    },
    {
        "id": "5645",
        "AirportCode": "OUI",
        "AirportName": "Ban Houel Airport",
        "City": "Ban Houel",
        "Country": "Laos"
    },
    {
        "id": "5646",
        "AirportCode": "OUK",
        "AirportName": "Outer Skerries Airport",
        "City": "Outer Skerries",
        "Country": "United Kingdom"
    },
    {
        "id": "5647",
        "AirportCode": "OUL",
        "AirportName": "Oulu Airport",
        "City": "Oulu",
        "Country": "Finland"
    },
    {
        "id": "5648",
        "AirportCode": "OUM",
        "AirportName": "Oum Hadjer Airport",
        "City": "Oum Hadjer",
        "Country": "Mali"
    },
    {
        "id": "5649",
        "AirportCode": "OUN",
        "AirportName": "Max Westheimer Airport",
        "City": "Norman",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5650",
        "AirportCode": "OUR",
        "AirportName": "Batouri Airport",
        "City": "Batouri",
        "Country": "Cameroon"
    },
    {
        "id": "5651",
        "AirportCode": "OUS",
        "AirportName": "Ourinhos Airport",
        "City": "Ourinhos",
        "Country": "Brazil"
    },
    {
        "id": "5652",
        "AirportCode": "OUT",
        "AirportName": "Bousso Airport",
        "City": "Bousso",
        "Country": "Mali"
    },
    {
        "id": "5653",
        "AirportCode": "OUU",
        "AirportName": "Ouanga Airport",
        "City": "Ouanga",
        "Country": "Gabon"
    },
    {
        "id": "5654",
        "AirportCode": "OUZ",
        "AirportName": "Zouerate Airport",
        "City": "Zouerate",
        "Country": "Mauritania"
    },
    {
        "id": "5655",
        "AirportCode": "OVA",
        "AirportName": "Bekily Airport",
        "City": "Bekily",
        "Country": "Madagascar"
    },
    {
        "id": "5656",
        "AirportCode": "OVB",
        "AirportName": "Tolmachevo Airport",
        "City": "Novosibirsk",
        "Country": "Russia"
    },
    {
        "id": "5657",
        "AirportCode": "OVD",
        "AirportName": "Asturias Airport",
        "City": "Asturias",
        "Country": "Spain"
    },
    {
        "id": "5658",
        "AirportCode": "OVE",
        "AirportName": "Oroville Airport",
        "City": "Oroville",
        "Country": "USA California"
    },
    {
        "id": "5659",
        "AirportCode": "OVG",
        "AirportName": "Overberg (FAOB)",
        "City": "Overberg",
        "Country": "South Africa"
    },
    {
        "id": "5660",
        "AirportCode": "OVL",
        "AirportName": "Ovalle Airport",
        "City": "Ovalle",
        "Country": "Chile"
    },
    {
        "id": "5661",
        "AirportCode": "OVR",
        "AirportName": "Olavarria Airport",
        "City": "Olavarria",
        "Country": "Argentina"
    },
    {
        "id": "5662",
        "AirportCode": "OWA",
        "AirportName": "Owatonna Airport",
        "City": "Owatonna",
        "Country": "USA Minnesota"
    },
    {
        "id": "5663",
        "AirportCode": "OWB",
        "AirportName": "Daviess County Airport",
        "City": "Owensboro",
        "Country": "USA Kentucky"
    },
    {
        "id": "5664",
        "AirportCode": "OWD",
        "AirportName": "Norwood Memorial Airport",
        "City": "Norwood",
        "Country": "USA Massachusetts"
    },
    {
        "id": "5665",
        "AirportCode": "OWE",
        "AirportName": "Owendo Airport",
        "City": "Owendo",
        "Country": "Gabon"
    },
    {
        "id": "5666",
        "AirportCode": "OWK",
        "AirportName": "Central Maine Airport",
        "City": "Norridgewock",
        "Country": "USA Maine"
    },
    {
        "id": "5667",
        "AirportCode": "OXB",
        "AirportName": "Osvaldo Vieira Airport",
        "City": "Bissau",
        "Country": "Guinea-Bissau"
    },
    {
        "id": "5668",
        "AirportCode": "OXC",
        "AirportName": "Waterbury-Oxford Airport",
        "City": "Oxford",
        "Country": "USA Connecticut"
    },
    {
        "id": "5669",
        "AirportCode": "OXD",
        "AirportName": "Miami University Airport",
        "City": "Oxford",
        "Country": "USA Ohio"
    },
    {
        "id": "5670",
        "AirportCode": "OXF",
        "AirportName": "Kidlington Airport",
        "City": "Oxford",
        "Country": "United Kingdom"
    },
    {
        "id": "5671",
        "AirportCode": "OXO",
        "AirportName": "Orientos Airport",
        "City": "Orientos",
        "Country": "Australia"
    },
    {
        "id": "5672",
        "AirportCode": "OXR",
        "AirportName": "Oxnard Airport",
        "City": "Oxnard",
        "Country": "USA California"
    },
    {
        "id": "5673",
        "AirportCode": "OXY",
        "AirportName": "Morney Airport",
        "City": "Morney",
        "Country": "Australia"
    },
    {
        "id": "5674",
        "AirportCode": "OYA",
        "AirportName": "Goya Airport",
        "City": "Goya",
        "Country": "Argentina"
    },
    {
        "id": "5675",
        "AirportCode": "OYE",
        "AirportName": "Oyem Airport",
        "City": "Oyem",
        "Country": "Gabon"
    },
    {
        "id": "5676",
        "AirportCode": "OYG",
        "AirportName": "Moyo Airport",
        "City": "Moyo",
        "Country": "Uganda"
    },
    {
        "id": "5677",
        "AirportCode": "OYK",
        "AirportName": "Oiapoque Airport",
        "City": "Oiapoque",
        "Country": "Brazil"
    },
    {
        "id": "5678",
        "AirportCode": "OYL",
        "AirportName": "Moyale Airport",
        "City": "Moyale",
        "Country": "Kenya"
    },
    {
        "id": "5679",
        "AirportCode": "OYN",
        "AirportName": "Ouyen Airport",
        "City": "Ouyen",
        "Country": "Australia"
    },
    {
        "id": "5680",
        "AirportCode": "OYO",
        "AirportName": "Tres Arroyos Airport",
        "City": "Tres Arroyos",
        "Country": "Argentina"
    },
    {
        "id": "5681",
        "AirportCode": "OYP",
        "AirportName": "St. Georges de L/Oyapock Airport",
        "City": "Saint Georges de L/Oyapock",
        "Country": "French Guiana"
    },
    {
        "id": "5682",
        "AirportCode": "OYS",
        "AirportName": "Yosemite National Park Airport",
        "City": "Yosemite National Park",
        "Country": "USA California"
    },
    {
        "id": "5683",
        "AirportCode": "OZA",
        "AirportName": "Ozona Airport",
        "City": "Ozona",
        "Country": "USA Texas"
    },
    {
        "id": "5684",
        "AirportCode": "OZC",
        "AirportName": "Labo Airport",
        "City": "Ozamis City",
        "Country": "Philippines"
    },
    {
        "id": "5685",
        "AirportCode": "OZH",
        "AirportName": "Zaporozhye Airport",
        "City": "Zaporozhye",
        "Country": "Ukraine"
    },
    {
        "id": "5686",
        "AirportCode": "OZI",
        "AirportName": "Bobadilla Airport",
        "City": "Bobadilla",
        "Country": "Spain"
    },
    {
        "id": "5687",
        "AirportCode": "OZP",
        "AirportName": "Moron Ab",
        "City": "Seville",
        "Country": "Spain"
    },
    {
        "id": "5688",
        "AirportCode": "OZP",
        "AirportName": "Moron Airport",
        "City": "Moron",
        "Country": "Spain"
    },
    {
        "id": "5689",
        "AirportCode": "OZR",
        "AirportName": "Cairns Army Air Field",
        "City": "Ozark",
        "Country": "USA Alabama"
    },
    {
        "id": "5690",
        "AirportCode": "OZU",
        "AirportName": "Montilla Airport",
        "City": "Montilla",
        "Country": "Spain"
    },
    {
        "id": "5691",
        "AirportCode": "OZZ",
        "AirportName": "Ouarzazate Airport",
        "City": "Ouarzazate",
        "Country": "Morocco"
    },
    {
        "id": "5692",
        "AirportCode": "PAA",
        "AirportName": "Pa-an Airport",
        "City": "Pa-an",
        "Country": "Burma"
    },
    {
        "id": "5693",
        "AirportCode": "PAB",
        "AirportName": "Bilaspur Airport",
        "City": "Bilaspur",
        "Country": "India"
    },
    {
        "id": "5694",
        "AirportCode": "PAC",
        "AirportName": "Paitilla Airport",
        "City": "Panama City",
        "Country": "Panama"
    },
    {
        "id": "5695",
        "AirportCode": "PAD",
        "AirportName": "Paderborn Airport",
        "City": "Paderborn",
        "Country": "Germany"
    },
    {
        "id": "5696",
        "AirportCode": "PAE",
        "AirportName": "Snohomish County Airport",
        "City": "Everett",
        "Country": "USA Washington"
    },
    {
        "id": "5697",
        "AirportCode": "PAF",
        "AirportName": "Pakuba Airport",
        "City": "Pakuba",
        "Country": "Uganda"
    },
    {
        "id": "5698",
        "AirportCode": "PAG",
        "AirportName": "Pagadian Airport",
        "City": "Pagadian",
        "Country": "Philippines"
    },
    {
        "id": "5699",
        "AirportCode": "PAH",
        "AirportName": "Barkley Regional Airport",
        "City": "Paducah",
        "Country": "USA Kentucky"
    },
    {
        "id": "5700",
        "AirportCode": "PAI",
        "AirportName": "Pailin Airport",
        "City": "Pailin",
        "Country": "Cambodia"
    },
    {
        "id": "5701",
        "AirportCode": "PAJ",
        "AirportName": "Para Chinar Airport",
        "City": "Para Chinar",
        "Country": "Pakistan"
    },
    {
        "id": "5702",
        "AirportCode": "PAK",
        "AirportName": "Port Allen Airport",
        "City": "Hanapepe",
        "Country": "USA Hawaii"
    },
    {
        "id": "5703",
        "AirportCode": "PAL",
        "AirportName": "Palanquero Airport",
        "City": "Palanquero",
        "Country": "Colombia"
    },
    {
        "id": "5704",
        "AirportCode": "PAM",
        "AirportName": "Tyndall Air Force Base",
        "City": "Panama City",
        "Country": "USA Florida"
    },
    {
        "id": "5705",
        "AirportCode": "PAN",
        "AirportName": "Pattani Airport",
        "City": "Pattani",
        "Country": "Thailand"
    },
    {
        "id": "5706",
        "AirportCode": "PAO",
        "AirportName": "Palo Alto Airport",
        "City": "Palo Alto",
        "Country": "USA California"
    },
    {
        "id": "5707",
        "AirportCode": "PAP",
        "AirportName": "Mais Gate Airport",
        "City": "Port Au Prince",
        "Country": "Haiti"
    },
    {
        "id": "5708",
        "AirportCode": "PAQ",
        "AirportName": "Palmer Municipal Airport",
        "City": "Palmer",
        "Country": "USA Alaska"
    },
    {
        "id": "5709",
        "AirportCode": "PAR",
        "AirportName": "Paris All Airports",
        "City": "Paris All Airports",
        "Country": "France"
    },
    {
        "id": "5710",
        "AirportCode": "PAS",
        "AirportName": "Paros Airport",
        "City": "Paros",
        "Country": "Greece"
    },
    {
        "id": "5711",
        "AirportCode": "PAT",
        "AirportName": "Patna Airport",
        "City": "Patna",
        "Country": "India"
    },
    {
        "id": "5712",
        "AirportCode": "PAU",
        "AirportName": "Pauk Airport",
        "City": "Pauk",
        "Country": "Burma"
    },
    {
        "id": "5713",
        "AirportCode": "PAV",
        "AirportName": "Paulo Afonso Airport",
        "City": "Paulo Afonso",
        "Country": "Brazil"
    },
    {
        "id": "5714",
        "AirportCode": "PAW",
        "AirportName": "Pambwa Airport",
        "City": "Pambwa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5715",
        "AirportCode": "PAX",
        "AirportName": "Port De Paix Airport",
        "City": "Port De Paix",
        "Country": "Haiti"
    },
    {
        "id": "5716",
        "AirportCode": "PAY",
        "AirportName": "Pamol Airport",
        "City": "Pamol",
        "Country": "Malaysia"
    },
    {
        "id": "5717",
        "AirportCode": "PAZ",
        "AirportName": "Tajin Airport",
        "City": "Poza Rica",
        "Country": "Mexico"
    },
    {
        "id": "5718",
        "AirportCode": "PBB",
        "AirportName": "Parnaiba",
        "City": "Parnaiba",
        "Country": "Brazil"
    },
    {
        "id": "5719",
        "AirportCode": "PBB",
        "AirportName": "Paranaiba Airport",
        "City": "Paranaiba",
        "Country": "Brazil"
    },
    {
        "id": "5720",
        "AirportCode": "PBC",
        "AirportName": "Huejotsingo Airport",
        "City": "Puebla",
        "Country": "Mexico"
    },
    {
        "id": "5721",
        "AirportCode": "PBD",
        "AirportName": "Porbandar Airport",
        "City": "Porbandar",
        "Country": "India"
    },
    {
        "id": "5722",
        "AirportCode": "PBE",
        "AirportName": "Puerto Berrio Airport",
        "City": "Puerto Berrio",
        "Country": "Colombia"
    },
    {
        "id": "5723",
        "AirportCode": "PBF",
        "AirportName": "Grider Field",
        "City": "Pine Bluff",
        "Country": "USA Arkansas"
    },
    {
        "id": "5724",
        "AirportCode": "PBG",
        "AirportName": "Plattsburgh Air Force Base",
        "City": "Plattsburgh",
        "Country": "USA New York"
    },
    {
        "id": "5725",
        "AirportCode": "PBH",
        "AirportName": "Paro Airport",
        "City": "Paro",
        "Country": "Bhutan"
    },
    {
        "id": "5726",
        "AirportCode": "PBI",
        "AirportName": "West Palm Beach International Airport",
        "City": "West Palm Beach",
        "Country": "USA Florida"
    },
    {
        "id": "5727",
        "AirportCode": "PBJ",
        "AirportName": "Paama Airport",
        "City": "Paama",
        "Country": "Vanuatu"
    },
    {
        "id": "5728",
        "AirportCode": "PBL",
        "AirportName": "Puerto Cabello Airport",
        "City": "Puerto Cabello",
        "Country": "Venezuela"
    },
    {
        "id": "5729",
        "AirportCode": "PBM",
        "AirportName": "Zanderij International Airport",
        "City": "Paramaribo",
        "Country": "Suriname"
    },
    {
        "id": "5730",
        "AirportCode": "PBO",
        "AirportName": "Paraburdoo Airport",
        "City": "Paraburdoo",
        "Country": "Australia"
    },
    {
        "id": "5731",
        "AirportCode": "PBP",
        "AirportName": "Punta Islita Airport",
        "City": "Punta Islita",
        "Country": "Costa Rica"
    },
    {
        "id": "5732",
        "AirportCode": "PBQ",
        "AirportName": "Pimenta Bueno Airport",
        "City": "Pimenta Bueno",
        "Country": "Brazil"
    },
    {
        "id": "5733",
        "AirportCode": "PBR",
        "AirportName": "Puerto Barrios Airport",
        "City": "Puerto Barrios",
        "Country": "Guatemala"
    },
    {
        "id": "5734",
        "AirportCode": "PBS",
        "AirportName": "Patong Beach Airport",
        "City": "Patong Beach",
        "Country": "Thailand"
    },
    {
        "id": "5735",
        "AirportCode": "PBT",
        "AirportName": "Puerto Leda Airport",
        "City": "Puerto Leda",
        "Country": "Paraguay"
    },
    {
        "id": "5736",
        "AirportCode": "PBU",
        "AirportName": "Putao Airport",
        "City": "Putao",
        "Country": "Burma"
    },
    {
        "id": "5737",
        "AirportCode": "PBV",
        "AirportName": "Porto Dos Gauchos Airport",
        "City": "Porto Dos Gauchos",
        "Country": "Brazil"
    },
    {
        "id": "5738",
        "AirportCode": "PBX",
        "AirportName": "Porto Alegre Do Norte Airport",
        "City": "Porto Alegre Do Norte",
        "Country": "Brazil"
    },
    {
        "id": "5739",
        "AirportCode": "PBY",
        "AirportName": "Hamilton/Prosperine Airport",
        "City": "Peppers Palm Bay (Island Resort)",
        "Country": "Australia"
    },
    {
        "id": "5740",
        "AirportCode": "PBZ",
        "AirportName": "Plettenberg Airport",
        "City": "Plettenberg",
        "Country": "South Africa"
    },
    {
        "id": "5741",
        "AirportCode": "PCA",
        "AirportName": "Portage Creek Airport",
        "City": "Portage Creek",
        "Country": "USA Alaska"
    },
    {
        "id": "5742",
        "AirportCode": "PCB",
        "AirportName": "Pondok Cabe Airport",
        "City": "Pondok Cabe",
        "Country": "Indonesia"
    },
    {
        "id": "5743",
        "AirportCode": "PCC",
        "AirportName": "Puerto Rico Airport",
        "City": "Puerto Rico",
        "Country": "Colombia"
    },
    {
        "id": "5744",
        "AirportCode": "PCD",
        "AirportName": "Prairie Du Chien Municipal Airport",
        "City": "Prairie Du Chien",
        "Country": "USA Wisconsin"
    },
    {
        "id": "5745",
        "AirportCode": "PCE",
        "AirportName": "Painter Creek Airport",
        "City": "Painter Creek",
        "Country": "USA Alaska"
    },
    {
        "id": "5746",
        "AirportCode": "PCG",
        "AirportName": "Paso Caballos Airport",
        "City": "Paso Caballos",
        "Country": "Guatemala"
    },
    {
        "id": "5747",
        "AirportCode": "PCH",
        "AirportName": "Palacios Airport",
        "City": "Palacios",
        "Country": "Honduras"
    },
    {
        "id": "5748",
        "AirportCode": "PCJ",
        "AirportName": "Puerto La Victoria Airport",
        "City": "Puerto La Victoria",
        "Country": "Paraguay"
    },
    {
        "id": "5749",
        "AirportCode": "PCL",
        "AirportName": "Capitan Rolden Airport",
        "City": "Pucallpa",
        "Country": "Peru"
    },
    {
        "id": "5750",
        "AirportCode": "PCM",
        "AirportName": "Playa Del Carmen Airport",
        "City": "Playa Del Carmen",
        "Country": "Mexico"
    },
    {
        "id": "5751",
        "AirportCode": "PCN",
        "AirportName": "Koromiko Airport",
        "City": "Picton",
        "Country": "New Zealand"
    },
    {
        "id": "5752",
        "AirportCode": "PCO",
        "AirportName": "Punta Colorada Airport",
        "City": "Punta Colorada",
        "Country": "Mexico"
    },
    {
        "id": "5753",
        "AirportCode": "PCP",
        "AirportName": "Principe Airport",
        "City": "Principe",
        "Country": "Sao Tom et Principe"
    },
    {
        "id": "5754",
        "AirportCode": "PCQ",
        "AirportName": "Bounneua Airport",
        "City": "Phongsaly",
        "Country": "Laos"
    },
    {
        "id": "5755",
        "AirportCode": "PCR",
        "AirportName": "Puerto Carreno Airport",
        "City": "Puerto Carreno",
        "Country": "Colombia"
    },
    {
        "id": "5756",
        "AirportCode": "PCS",
        "AirportName": "Picos Airport",
        "City": "Picos",
        "Country": "Brazil"
    },
    {
        "id": "5757",
        "AirportCode": "PCT",
        "AirportName": "Princeton Airport",
        "City": "Princeton",
        "Country": "USA New Jersey"
    },
    {
        "id": "5758",
        "AirportCode": "PCU",
        "AirportName": "Pearl River County Airport",
        "City": "Picayune",
        "Country": "USA Mississippi"
    },
    {
        "id": "5759",
        "AirportCode": "PCV",
        "AirportName": "Punta Chivato Airport",
        "City": "Punta Chivato",
        "Country": "Mexico"
    },
    {
        "id": "5760",
        "AirportCode": "PDA",
        "AirportName": "Puerto Inirida Airport",
        "City": "Puerto Inirida",
        "Country": "Colombia"
    },
    {
        "id": "5761",
        "AirportCode": "PDB",
        "AirportName": "Pedro Bay Airport",
        "City": "Pedro Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "5762",
        "AirportCode": "PDC",
        "AirportName": "Mueo Airport",
        "City": "Mueo",
        "Country": "New Caledonia"
    },
    {
        "id": "5763",
        "AirportCode": "PDD",
        "AirportName": "Ponta De Ouro",
        "City": "Ponta De Ouro",
        "Country": "Mozambique"
    },
    {
        "id": "5764",
        "AirportCode": "PDE",
        "AirportName": "Pandie Pandie Airport",
        "City": "Pandie Pandie",
        "Country": "Australia"
    },
    {
        "id": "5765",
        "AirportCode": "PDF",
        "AirportName": "Prado Airport",
        "City": "Prado",
        "Country": "Brazil"
    },
    {
        "id": "5766",
        "AirportCode": "PDG",
        "AirportName": "Tabing Airport",
        "City": "Padang",
        "Country": "Indonesia"
    },
    {
        "id": "5767",
        "AirportCode": "PDI",
        "AirportName": "Pindiu Airport",
        "City": "Pindiu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5768",
        "AirportCode": "PDK",
        "AirportName": "Peachtree-Dekalb Airport",
        "City": "Atlanta",
        "Country": "USA Georgia"
    },
    {
        "id": "5769",
        "AirportCode": "PDL",
        "AirportName": "Nordela Airport",
        "City": "Ponta Delgada (Azores)",
        "Country": "Portugal"
    },
    {
        "id": "5770",
        "AirportCode": "PDN",
        "AirportName": "Parndana Airport",
        "City": "Parndana",
        "Country": "Australia"
    },
    {
        "id": "5771",
        "AirportCode": "PDO",
        "AirportName": "Pendopo Airport",
        "City": "Pendopo",
        "Country": "Indonesia"
    },
    {
        "id": "5772",
        "AirportCode": "PDP",
        "AirportName": "Punta Del Este Airport",
        "City": "Punta Del Este",
        "Country": "Uruguay"
    },
    {
        "id": "5773",
        "AirportCode": "PDR",
        "AirportName": "Presidente Dutra Municipal Airport",
        "City": "Presidente Dutra",
        "Country": "Brazil"
    },
    {
        "id": "5774",
        "AirportCode": "PDS",
        "AirportName": "Piedras Negras Airport",
        "City": "Piedras Negras",
        "Country": "Mexico"
    },
    {
        "id": "5775",
        "AirportCode": "PDT",
        "AirportName": "Eastern Oregon Regional Airport",
        "City": "Pendleton",
        "Country": "USA Oregon"
    },
    {
        "id": "5776",
        "AirportCode": "PDU",
        "AirportName": "Paysandu Airport",
        "City": "Paysandu",
        "Country": "Uruguay"
    },
    {
        "id": "5777",
        "AirportCode": "PDV",
        "AirportName": "Plovdiv Airport",
        "City": "Plovdiv",
        "Country": "Bulgaria"
    },
    {
        "id": "5778",
        "AirportCode": "PDX",
        "AirportName": "Portland International Airport",
        "City": "Portland",
        "Country": "USA Oregon"
    },
    {
        "id": "5779",
        "AirportCode": "PDZ",
        "AirportName": "Pedernales Airport",
        "City": "Pedernales",
        "Country": "Venezuela"
    },
    {
        "id": "5780",
        "AirportCode": "PEA",
        "AirportName": "Penneshaw Airport",
        "City": "Penneshaw",
        "Country": "Australia"
    },
    {
        "id": "5781",
        "AirportCode": "PEB",
        "AirportName": "Pebane Airport",
        "City": "Pebane",
        "Country": "Mozambique"
    },
    {
        "id": "5782",
        "AirportCode": "PEC",
        "AirportName": "Pelican Sea Plane Base",
        "City": "Pelican",
        "Country": "USA Alaska"
    },
    {
        "id": "5783",
        "AirportCode": "PED",
        "AirportName": "Pardubice Airport",
        "City": "Pardubice",
        "Country": "Czech Republic"
    },
    {
        "id": "5784",
        "AirportCode": "PEE",
        "AirportName": "Perm Airport",
        "City": "Perm",
        "Country": "Russia"
    },
    {
        "id": "5785",
        "AirportCode": "PEF",
        "AirportName": "Peenemuende Airport",
        "City": "Peenemuende",
        "Country": "Germany"
    },
    {
        "id": "5786",
        "AirportCode": "PEG",
        "AirportName": "Sant Egidio Airport",
        "City": "Perugia",
        "Country": "Italy"
    },
    {
        "id": "5787",
        "AirportCode": "PEH",
        "AirportName": "Pehuajo Airport",
        "City": "Pehuajo",
        "Country": "Argentina"
    },
    {
        "id": "5788",
        "AirportCode": "PEI",
        "AirportName": "Matecana Airport",
        "City": "Pereira",
        "Country": "Colombia"
    },
    {
        "id": "5789",
        "AirportCode": "PEJ",
        "AirportName": "Peschiei Airport",
        "City": "Peschiei",
        "Country": "Italy"
    },
    {
        "id": "5790",
        "AirportCode": "PEK",
        "AirportName": "Capital Airport",
        "City": "Beijing",
        "Country": "China"
    },
    {
        "id": "5791",
        "AirportCode": "PEL",
        "AirportName": "Pelaneng Airport",
        "City": "Pelaneng",
        "Country": "Lesotho"
    },
    {
        "id": "5792",
        "AirportCode": "PEM",
        "AirportName": "Puerto Maldonado Airport",
        "City": "Puerto Maldonado",
        "Country": "Peru"
    },
    {
        "id": "5793",
        "AirportCode": "PEN",
        "AirportName": "Penang International Airport",
        "City": "Penang",
        "Country": "Malaysia"
    },
    {
        "id": "5794",
        "AirportCode": "PEP",
        "AirportName": "Peppimenarti Airport",
        "City": "Peppimenarti",
        "Country": "Australia"
    },
    {
        "id": "5795",
        "AirportCode": "PEQ",
        "AirportName": "Pecos City Airport",
        "City": "Pecos City",
        "Country": "USA Texas"
    },
    {
        "id": "5796",
        "AirportCode": "PER",
        "AirportName": "Perth Airport",
        "City": "Perth",
        "Country": "Australia"
    },
    {
        "id": "5797",
        "AirportCode": "PES",
        "AirportName": "Petrozavodsk Airport",
        "City": "Petrozavodsk",
        "Country": "Russia"
    },
    {
        "id": "5798",
        "AirportCode": "PET",
        "AirportName": "Federal Airport",
        "City": "Pelotas",
        "Country": "Brazil"
    },
    {
        "id": "5799",
        "AirportCode": "PEU",
        "AirportName": "Puerto Lempira Airport",
        "City": "Puerto Lempira",
        "Country": "Honduras"
    },
    {
        "id": "5800",
        "AirportCode": "PEW",
        "AirportName": "Peshawar Airport",
        "City": "Peshawar",
        "Country": "Pakistan"
    },
    {
        "id": "5801",
        "AirportCode": "PEX",
        "AirportName": "Pechora Airport",
        "City": "Pechora",
        "Country": "Russia"
    },
    {
        "id": "5802",
        "AirportCode": "PEY",
        "AirportName": "Penong Airport",
        "City": "Penong",
        "Country": "Australia"
    },
    {
        "id": "5803",
        "AirportCode": "PEZ",
        "AirportName": "Penza Airport",
        "City": "Penza",
        "Country": "Russia"
    },
    {
        "id": "5804",
        "AirportCode": "PFB",
        "AirportName": "Passo Fundo Airport",
        "City": "Passo Fundo",
        "Country": "Brazil"
    },
    {
        "id": "5805",
        "AirportCode": "PFC",
        "AirportName": "State Airport",
        "City": "Pacific City",
        "Country": "USA Oregon"
    },
    {
        "id": "5806",
        "AirportCode": "PFJ",
        "AirportName": "Patrekstjord Airport",
        "City": "Patrekstjord",
        "Country": "Iceland"
    },
    {
        "id": "5807",
        "AirportCode": "PFN",
        "AirportName": "Bay County Airport",
        "City": "Panama City",
        "Country": "USA Florida"
    },
    {
        "id": "5808",
        "AirportCode": "PFO",
        "AirportName": "Paphos International Airport",
        "City": "Paphos",
        "Country": "Cyprus"
    },
    {
        "id": "5809",
        "AirportCode": "PFQ",
        "AirportName": "Parsabad Airport",
        "City": "Parsabad",
        "Country": "Iran"
    },
    {
        "id": "5810",
        "AirportCode": "PFR",
        "AirportName": "Ilebo Airport",
        "City": "Ilebo",
        "Country": "Congo, DR"
    },
    {
        "id": "5811",
        "AirportCode": "PGA",
        "AirportName": "Page Airport",
        "City": "Page",
        "Country": "USA Arizona"
    },
    {
        "id": "5812",
        "AirportCode": "PGB",
        "AirportName": "Panaoa Airport",
        "City": "Panaoa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5813",
        "AirportCode": "PGC",
        "AirportName": "Grant County Airport",
        "City": "Petersburg",
        "Country": "USA West Virginia"
    },
    {
        "id": "5814",
        "AirportCode": "PGD",
        "AirportName": "Charlotte County Airport",
        "City": "Punta Gorda",
        "Country": "USA Florida"
    },
    {
        "id": "5815",
        "AirportCode": "PGE",
        "AirportName": "Yegepa Airport",
        "City": "Yegepa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5816",
        "AirportCode": "PGF",
        "AirportName": "Llabanere Airport",
        "City": "Perpignan",
        "Country": "France"
    },
    {
        "id": "5817",
        "AirportCode": "PGG",
        "AirportName": "Progresso Airport",
        "City": "Progresso",
        "Country": "Brazil"
    },
    {
        "id": "5818",
        "AirportCode": "PGH",
        "AirportName": "Pantnagar Airport",
        "City": "Pantnagar",
        "Country": "India"
    },
    {
        "id": "5819",
        "AirportCode": "PGK",
        "AirportName": "Pangkalpinang Airport",
        "City": "Pangkalpinang",
        "Country": "Indonesia"
    },
    {
        "id": "5820",
        "AirportCode": "PGL",
        "AirportName": "Jackson County Airport",
        "City": "Pascagoula",
        "Country": "USA Mississippi"
    },
    {
        "id": "5821",
        "AirportCode": "PGM",
        "AirportName": "Port Graham Airport",
        "City": "Port Graham",
        "Country": "USA Alaska"
    },
    {
        "id": "5822",
        "AirportCode": "PGN",
        "AirportName": "Pangia Airport",
        "City": "Pangia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5823",
        "AirportCode": "PGO",
        "AirportName": "Stevens Field",
        "City": "Pagosa Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "5824",
        "AirportCode": "PGP",
        "AirportName": "Porto Alegre Airport",
        "City": "Porto Alegre",
        "Country": "Sao Tom et Principe"
    },
    {
        "id": "5825",
        "AirportCode": "PGR",
        "AirportName": "Paragould Municipal Airport",
        "City": "Paragould",
        "Country": "USA Arizona"
    },
    {
        "id": "5826",
        "AirportCode": "PGS",
        "AirportName": "Peach Springs Airport",
        "City": "Peach Springs",
        "Country": "USA Arizona"
    },
    {
        "id": "5827",
        "AirportCode": "PGV",
        "AirportName": "Pitt-Greenville Airport",
        "City": "Greenville",
        "Country": "USA North Carolina"
    },
    {
        "id": "5828",
        "AirportCode": "PGX",
        "AirportName": "Perigueux Airport",
        "City": "Perigueux",
        "Country": "France"
    },
    {
        "id": "5829",
        "AirportCode": "PHA",
        "AirportName": "Phan Rang Airport",
        "City": "Phan Rang",
        "Country": "Vietnam"
    },
    {
        "id": "5830",
        "AirportCode": "PHB",
        "AirportName": "Santos Dumont Airport",
        "City": "Parnaiba",
        "Country": "Brazil"
    },
    {
        "id": "5831",
        "AirportCode": "PHC",
        "AirportName": "Port Harcourt Airport",
        "City": "Port Harcourt",
        "Country": "Nigeria"
    },
    {
        "id": "5832",
        "AirportCode": "PHD",
        "AirportName": "Harry Clever Airport",
        "City": "New Philadelphia",
        "Country": "USA Ohio"
    },
    {
        "id": "5833",
        "AirportCode": "PHE",
        "AirportName": "Port Hedland Airport",
        "City": "Port Hedland",
        "Country": "Australia"
    },
    {
        "id": "5834",
        "AirportCode": "PHF",
        "AirportName": "Newport News/Williamsburg Airport",
        "City": "Newport News",
        "Country": "USA Virginia"
    },
    {
        "id": "5835",
        "AirportCode": "PHG",
        "AirportName": "Port Harcourt City Airport",
        "City": "Port Harcourt",
        "Country": "Nigeria"
    },
    {
        "id": "5836",
        "AirportCode": "PHH",
        "AirportName": "Phan Thiet Airport",
        "City": "Phan Thiet",
        "Country": "Vietnam"
    },
    {
        "id": "5837",
        "AirportCode": "PHI",
        "AirportName": "Pinheiro Airport",
        "City": "Pinheiro",
        "Country": "Brazil"
    },
    {
        "id": "5838",
        "AirportCode": "PHJ",
        "AirportName": "Port Hunter Airport",
        "City": "Port Hunter",
        "Country": "Australia"
    },
    {
        "id": "5839",
        "AirportCode": "PHK",
        "AirportName": "Palm Beach County Glades Airport",
        "City": "Pahokee",
        "Country": "USA Florida"
    },
    {
        "id": "5840",
        "AirportCode": "PHL",
        "AirportName": "Philadelphia International Airport",
        "City": "Philadelphia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "5841",
        "AirportCode": "PHM",
        "AirportName": "Boeblingen Airport",
        "City": "Boeblingen",
        "Country": "Germany"
    },
    {
        "id": "5842",
        "AirportCode": "PHN",
        "AirportName": "St Clair County International Airport",
        "City": "Port Huron",
        "Country": "USA Michigan"
    },
    {
        "id": "5843",
        "AirportCode": "PHO",
        "AirportName": "Point Hope Airport",
        "City": "Point Hope",
        "Country": "USA Alaska"
    },
    {
        "id": "5844",
        "AirportCode": "PHP",
        "AirportName": "Phillip Airport",
        "City": "Philip",
        "Country": "USA South Dakota"
    },
    {
        "id": "5845",
        "AirportCode": "PHR",
        "AirportName": "Pacific Harbor Airport",
        "City": "Pacific Harbor",
        "Country": "Fiji"
    },
    {
        "id": "5846",
        "AirportCode": "PHS",
        "AirportName": "Phitsanulok Airport",
        "City": "Phitsanulok",
        "Country": "Thailand"
    },
    {
        "id": "5847",
        "AirportCode": "PHT",
        "AirportName": "Henry County Airport",
        "City": "Paris",
        "Country": "USA Tennessee"
    },
    {
        "id": "5848",
        "AirportCode": "PHU",
        "AirportName": "Phu Vinh Airport",
        "City": "Phu Vinh",
        "Country": "Vietnam"
    },
    {
        "id": "5849",
        "AirportCode": "PHW",
        "AirportName": "Phalaborwa Airport",
        "City": "Phalaborwa",
        "Country": "South Africa"
    },
    {
        "id": "5850",
        "AirportCode": "PHX",
        "AirportName": "Sky Harbor International Airport",
        "City": "Phoenix",
        "Country": "USA Arizona"
    },
    {
        "id": "5851",
        "AirportCode": "PHY",
        "AirportName": "Phetchabun Airport",
        "City": "Phetchabun",
        "Country": "Thailand"
    },
    {
        "id": "5852",
        "AirportCode": "PHZ",
        "AirportName": "Phi Phi Island Airport",
        "City": "Phi Phi Island",
        "Country": "Thailand"
    },
    {
        "id": "5853",
        "AirportCode": "PIA",
        "AirportName": "Greater Peoria Airport",
        "City": "Peoria",
        "Country": "USA Illinois"
    },
    {
        "id": "5854",
        "AirportCode": "PIB",
        "AirportName": "Hattiesburg-Laurel Regional Airport",
        "City": "Laurel",
        "Country": "USA Mississippi"
    },
    {
        "id": "5855",
        "AirportCode": "PIC",
        "AirportName": "Pine Cay Airport",
        "City": "Pine Cay",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "5856",
        "AirportCode": "PID",
        "AirportName": "Paradise Island Airport",
        "City": "Nassau",
        "Country": "Bahamas"
    },
    {
        "id": "5857",
        "AirportCode": "PIE",
        "AirportName": "St. Petersburg International Airport",
        "City": "Saint Petersburg",
        "Country": "USA Florida"
    },
    {
        "id": "5858",
        "AirportCode": "PIF",
        "AirportName": "Pingtung Airport",
        "City": "Pingtung",
        "Country": "Taiwan"
    },
    {
        "id": "5859",
        "AirportCode": "PIG",
        "AirportName": "Pitinga Airport",
        "City": "Pitinga",
        "Country": "Brazil"
    },
    {
        "id": "5860",
        "AirportCode": "PIH",
        "AirportName": "Pocatello Airport",
        "City": "Pocatello",
        "Country": "USA Idaho"
    },
    {
        "id": "5861",
        "AirportCode": "PIK",
        "AirportName": "Prestwick Airport",
        "City": "Glasgow",
        "Country": "United Kingdom"
    },
    {
        "id": "5862",
        "AirportCode": "PIL",
        "AirportName": "Pilar Airport",
        "City": "Pilar",
        "Country": "Paraguay"
    },
    {
        "id": "5863",
        "AirportCode": "PIM",
        "AirportName": "Garden Harris County Airport",
        "City": "Pine Mountain",
        "Country": "USA Georgia"
    },
    {
        "id": "5864",
        "AirportCode": "PIN",
        "AirportName": "Parintins Airport",
        "City": "Parintins",
        "Country": "Brazil"
    },
    {
        "id": "5865",
        "AirportCode": "PIO",
        "AirportName": "Pisco Airport",
        "City": "Pisco",
        "Country": "Peru"
    },
    {
        "id": "5866",
        "AirportCode": "PIP",
        "AirportName": "Pilot Point Airport",
        "City": "Pilot Point",
        "Country": "USA Alaska"
    },
    {
        "id": "5867",
        "AirportCode": "PIQ",
        "AirportName": "Pipillipai Airport",
        "City": "Pipillipai",
        "Country": "Guyana"
    },
    {
        "id": "5868",
        "AirportCode": "PIR",
        "AirportName": "Pierre Airport",
        "City": "Pierre",
        "Country": "USA South Dakota"
    },
    {
        "id": "5869",
        "AirportCode": "PIS",
        "AirportName": "Biard Airport",
        "City": "Poitiers",
        "Country": "France"
    },
    {
        "id": "5870",
        "AirportCode": "PIT",
        "AirportName": "Pittsburgh International Airport",
        "City": "Pittsburgh",
        "Country": "USA Philadelphia"
    },
    {
        "id": "5871",
        "AirportCode": "PIU",
        "AirportName": "Piura Airport",
        "City": "Piura",
        "Country": "Peru"
    },
    {
        "id": "5872",
        "AirportCode": "PIV",
        "AirportName": "Pirapora Airport",
        "City": "Pirapora",
        "Country": "Brazil"
    },
    {
        "id": "5873",
        "AirportCode": "PIW",
        "AirportName": "Pikwitonei Airport",
        "City": "Pikwitonei",
        "Country": "Canada"
    },
    {
        "id": "5874",
        "AirportCode": "PIX",
        "AirportName": "Pico Island Airport",
        "City": "Pico Island (Azores)",
        "Country": "Portugal"
    },
    {
        "id": "5875",
        "AirportCode": "PIZ",
        "AirportName": "Dew Station Airport",
        "City": "Point Lay",
        "Country": "USA Alaska"
    },
    {
        "id": "5876",
        "AirportCode": "PJA",
        "AirportName": "Pajala Airport",
        "City": "Pajala",
        "Country": "Sweden"
    },
    {
        "id": "5877",
        "AirportCode": "PJB",
        "AirportName": "Payson Airport",
        "City": "Payson",
        "Country": "USA Arizona"
    },
    {
        "id": "5878",
        "AirportCode": "PJC",
        "AirportName": "Pedro Juan Caballero Airport",
        "City": "Pedro Juan Caballero",
        "Country": "Paraguay"
    },
    {
        "id": "5879",
        "AirportCode": "PJG",
        "AirportName": "Panjgur Airport",
        "City": "Panjgur",
        "Country": "Pakistan"
    },
    {
        "id": "5880",
        "AirportCode": "PJM",
        "AirportName": "Puerto Jimenez Airport",
        "City": "Puerto Jimenez",
        "Country": "Costa Rica"
    },
    {
        "id": "5881",
        "AirportCode": "PJZ",
        "AirportName": "Puerto Juarez Airport",
        "City": "Puerto Juarez",
        "Country": "Mexico"
    },
    {
        "id": "5882",
        "AirportCode": "PKA",
        "AirportName": "Napaskiak Sea Plane Base",
        "City": "Napaskiak",
        "Country": "USA Alaska"
    },
    {
        "id": "5883",
        "AirportCode": "PKB",
        "AirportName": "Wood County Airport",
        "City": "Parkersburg",
        "Country": "USA West Virginia"
    },
    {
        "id": "5884",
        "AirportCode": "PKC",
        "AirportName": "Petropavlovsk-Kamchatsky Airport",
        "City": "Petropavlovsk-Kamchatsky",
        "Country": "Russia"
    },
    {
        "id": "5885",
        "AirportCode": "PKD",
        "AirportName": "Park Rapids Airport",
        "City": "Park Rapids",
        "Country": "USA Minnesota"
    },
    {
        "id": "5886",
        "AirportCode": "PKE",
        "AirportName": "Parkes Airport",
        "City": "Parkes",
        "Country": "Australia"
    },
    {
        "id": "5887",
        "AirportCode": "PKF",
        "AirportName": "Park Falls Airport",
        "City": "Park Falls",
        "Country": "USA Wisconsin"
    },
    {
        "id": "5888",
        "AirportCode": "PKG",
        "AirportName": "Pangkor Airport",
        "City": "Pangkor",
        "Country": "Malaysia"
    },
    {
        "id": "5889",
        "AirportCode": "PKH",
        "AirportName": "Alexion Airport",
        "City": "Porto Kheli",
        "Country": "Greece"
    },
    {
        "id": "5890",
        "AirportCode": "PKJ",
        "AirportName": "Playa Grande Airport",
        "City": "Playa Grande",
        "Country": "Guatemala"
    },
    {
        "id": "5891",
        "AirportCode": "PKK",
        "AirportName": "Pakokku Airport",
        "City": "Pakokku",
        "Country": "Burma"
    },
    {
        "id": "5892",
        "AirportCode": "PKL",
        "AirportName": "Pakatoa Island Airport",
        "City": "Pakatoa Island",
        "Country": "New Zealand"
    },
    {
        "id": "5893",
        "AirportCode": "PKM",
        "AirportName": "Port Kaituma Airport",
        "City": "Port Kaituma",
        "Country": "Guyana"
    },
    {
        "id": "5894",
        "AirportCode": "PKN",
        "AirportName": "Pangkalanbuun Airport",
        "City": "Pangkalanbuun",
        "Country": "Indonesia"
    },
    {
        "id": "5895",
        "AirportCode": "PKO",
        "AirportName": "Parakou Airport",
        "City": "Parakou",
        "Country": "Benin"
    },
    {
        "id": "5896",
        "AirportCode": "PKP",
        "AirportName": "Puka Puka Airport",
        "City": "Puka Puka",
        "Country": "French Polynesia"
    },
    {
        "id": "5897",
        "AirportCode": "PKR",
        "AirportName": "Pokhara Airport",
        "City": "Pokhara",
        "Country": "Nepal"
    },
    {
        "id": "5898",
        "AirportCode": "PKS",
        "AirportName": "Paksane Airport",
        "City": "Paksane",
        "Country": "Laos"
    },
    {
        "id": "5899",
        "AirportCode": "PKT",
        "AirportName": "Port Keats Airport",
        "City": "Port Keats",
        "Country": "Australia"
    },
    {
        "id": "5900",
        "AirportCode": "PKU",
        "AirportName": "Simpang Tiga Airport",
        "City": "Pekanbaru",
        "Country": "Indonesia"
    },
    {
        "id": "5901",
        "AirportCode": "PKV",
        "AirportName": "Pskov Airport",
        "City": "Pskov",
        "Country": "Russia"
    },
    {
        "id": "5902",
        "AirportCode": "PKW",
        "AirportName": "Selebi-Phikwe Airport",
        "City": "Selebi-Phikwe",
        "Country": "Botswana"
    },
    {
        "id": "5903",
        "AirportCode": "PKY",
        "AirportName": "Palangkaraya Airport",
        "City": "Palangkaraya",
        "Country": "Indonesia"
    },
    {
        "id": "5904",
        "AirportCode": "PKZ",
        "AirportName": "Pakse Airport",
        "City": "Pakse",
        "Country": "Laos"
    },
    {
        "id": "5905",
        "AirportCode": "PLA",
        "AirportName": "Planadas Airport",
        "City": "Planadas",
        "Country": "Colombia"
    },
    {
        "id": "5906",
        "AirportCode": "PLB",
        "AirportName": "Clinton County Airport",
        "City": "Plattsburgh",
        "Country": "USA New York"
    },
    {
        "id": "5907",
        "AirportCode": "PLC",
        "AirportName": "Planeta Rica Airport",
        "City": "Planeta Rica",
        "Country": "Colombia"
    },
    {
        "id": "5908",
        "AirportCode": "PLD",
        "AirportName": "Playa Samara Airport",
        "City": "Playa Samara",
        "Country": "Costa Rica"
    },
    {
        "id": "5909",
        "AirportCode": "PLE",
        "AirportName": "Paiela Airport",
        "City": "Paiela",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5910",
        "AirportCode": "PLF",
        "AirportName": "Pala Airport",
        "City": "Pala",
        "Country": "Mali"
    },
    {
        "id": "5911",
        "AirportCode": "PLH",
        "AirportName": "Plymouth Airport",
        "City": "Plymouth",
        "Country": "United Kingdom"
    },
    {
        "id": "5912",
        "AirportCode": "PLI",
        "AirportName": "Palm Island Airport",
        "City": "Palm Island",
        "Country": "St Vincent"
    },
    {
        "id": "5913",
        "AirportCode": "PLJ",
        "AirportName": "Placencia Airport",
        "City": "Placencia",
        "Country": "Belize"
    },
    {
        "id": "5914",
        "AirportCode": "PLK",
        "AirportName": "M Graham Clark Airport",
        "City": "Branson/Point Lookout",
        "Country": "USA Missouri"
    },
    {
        "id": "5915",
        "AirportCode": "PLL",
        "AirportName": "Ponta Pelada Airport",
        "City": "Ponta Pelada",
        "Country": "Brazil"
    },
    {
        "id": "5916",
        "AirportCode": "PLM",
        "AirportName": "Mahmud Badaruddin Ii Airport",
        "City": "Palembang",
        "Country": "Indonesia"
    },
    {
        "id": "5917",
        "AirportCode": "PLN",
        "AirportName": "Emmet County Airport",
        "City": "Pellston",
        "Country": "USA Michigan"
    },
    {
        "id": "5918",
        "AirportCode": "PLO",
        "AirportName": "Port Lincoln Airport",
        "City": "Port Lincoln",
        "Country": "Australia"
    },
    {
        "id": "5919",
        "AirportCode": "PLP",
        "AirportName": "La Palma Airport",
        "City": "La Palma",
        "Country": "Panama"
    },
    {
        "id": "5920",
        "AirportCode": "PLQ",
        "AirportName": "Palanga International Airport",
        "City": "Palanga",
        "Country": "Lithuania"
    },
    {
        "id": "5921",
        "AirportCode": "PLR",
        "AirportName": "St. Clair County Airport",
        "City": "Pell City",
        "Country": "USA Alabama"
    },
    {
        "id": "5922",
        "AirportCode": "PLS",
        "AirportName": "Providenciales International Airport",
        "City": "Providenciales",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "5923",
        "AirportCode": "PLT",
        "AirportName": "Plato Airport",
        "City": "Plato",
        "Country": "Colombia"
    },
    {
        "id": "5924",
        "AirportCode": "PLU",
        "AirportName": "Pampulha Airport",
        "City": "Belo Horizonte",
        "Country": "Brazil"
    },
    {
        "id": "5925",
        "AirportCode": "PLV",
        "AirportName": "Poltava Airport",
        "City": "Poltava",
        "Country": "Ukraine"
    },
    {
        "id": "5926",
        "AirportCode": "PLW",
        "AirportName": "Mutiara Airport",
        "City": "Palu",
        "Country": "Indonesia"
    },
    {
        "id": "5927",
        "AirportCode": "PLX",
        "AirportName": "Semipalatinsk Airport",
        "City": "Semipalatinsk",
        "Country": "Kazakhstan"
    },
    {
        "id": "5928",
        "AirportCode": "PLY",
        "AirportName": "Plymouth Airport",
        "City": "Plymouth",
        "Country": "USA Indiana"
    },
    {
        "id": "5929",
        "AirportCode": "PLZ",
        "AirportName": "Port Elizabeth Airport",
        "City": "Port Elizabeth",
        "Country": "South Africa"
    },
    {
        "id": "5930",
        "AirportCode": "PMA",
        "AirportName": "Wawi Airport",
        "City": "Pemba",
        "Country": "Tanzania"
    },
    {
        "id": "5931",
        "AirportCode": "PMB",
        "AirportName": "Pembina Intermediate Airport",
        "City": "Pembina",
        "Country": "USA North Dakota"
    },
    {
        "id": "5932",
        "AirportCode": "PMC",
        "AirportName": "Tepual Airport",
        "City": "Puerto Montt",
        "Country": "Chile"
    },
    {
        "id": "5933",
        "AirportCode": "PMD",
        "AirportName": "Palmdale Air Force 42 Base",
        "City": "Palmdale",
        "Country": "USA California"
    },
    {
        "id": "5934",
        "AirportCode": "PME",
        "AirportName": "Portsmouth Airport",
        "City": "Portsmouth",
        "Country": "United Kingdom"
    },
    {
        "id": "5935",
        "AirportCode": "PMF",
        "AirportName": "Parma Airport",
        "City": "Milan",
        "Country": "Italy"
    },
    {
        "id": "5936",
        "AirportCode": "PMG",
        "AirportName": "Ponta Pora International Airport",
        "City": "Ponta Pora",
        "Country": "Brazil"
    },
    {
        "id": "5937",
        "AirportCode": "PMH",
        "AirportName": "Portsmouth Regional Airport",
        "City": "Portsmouth",
        "Country": "USA Ohio"
    },
    {
        "id": "5938",
        "AirportCode": "PMI",
        "AirportName": "Palma Mallorca Airport",
        "City": "Palma Mallorca",
        "Country": "Spain"
    },
    {
        "id": "5939",
        "AirportCode": "PMK",
        "AirportName": "Palm Island Airport",
        "City": "Palm Island",
        "Country": "Australia"
    },
    {
        "id": "5940",
        "AirportCode": "PML",
        "AirportName": "Port Moller Air Force Station",
        "City": "Port Moller",
        "Country": "USA Alaska"
    },
    {
        "id": "5941",
        "AirportCode": "PMM",
        "AirportName": "Phanom Sarakham Airport",
        "City": "Phanom Sarakham",
        "Country": "Thailand"
    },
    {
        "id": "5942",
        "AirportCode": "PMN",
        "AirportName": "Pumani Airport",
        "City": "Pumani",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5943",
        "AirportCode": "PMO",
        "AirportName": "Punta Raisi Airport",
        "City": "Palermo",
        "Country": "Italy"
    },
    {
        "id": "5944",
        "AirportCode": "PMP",
        "AirportName": "Pimaga Airport",
        "City": "Pimaga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5945",
        "AirportCode": "PMQ",
        "AirportName": "Perito Moreno Airport",
        "City": "Perito Moreno",
        "Country": "Argentina"
    },
    {
        "id": "5946",
        "AirportCode": "PMR",
        "AirportName": "Palmerston North Airport",
        "City": "Palmerston North",
        "Country": "New Zealand"
    },
    {
        "id": "5947",
        "AirportCode": "PMS",
        "AirportName": "Palmyra Airport",
        "City": "Palmyra",
        "Country": "Syria"
    },
    {
        "id": "5948",
        "AirportCode": "PMT",
        "AirportName": "Paramakotoi Airport",
        "City": "Paramakotoi",
        "Country": "Guyana"
    },
    {
        "id": "5949",
        "AirportCode": "PMV",
        "AirportName": "DelCaribe Gen S Marino Airport",
        "City": "Porlamar",
        "Country": "Venezuela"
    },
    {
        "id": "5950",
        "AirportCode": "PMW",
        "AirportName": "Palmas Airport",
        "City": "Palmas",
        "Country": "Brazil"
    },
    {
        "id": "5951",
        "AirportCode": "PMX",
        "AirportName": "Palmer Metropolitan Airport",
        "City": "Palmer",
        "Country": "USA Massachusetts"
    },
    {
        "id": "5952",
        "AirportCode": "PMY",
        "AirportName": "El Tehuelche Airport",
        "City": "Puerto Madryn",
        "Country": "Argentina"
    },
    {
        "id": "5953",
        "AirportCode": "PNA",
        "AirportName": "Pamplona Airport",
        "City": "Pamplona",
        "Country": "Spain"
    },
    {
        "id": "5954",
        "AirportCode": "PNB",
        "AirportName": "Porto Nacional Airport",
        "City": "Porto Nacional",
        "Country": "Brazil"
    },
    {
        "id": "5955",
        "AirportCode": "PNC",
        "AirportName": "Ponca City Airport",
        "City": "Ponca City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "5956",
        "AirportCode": "PND",
        "AirportName": "Punta Gorda Airport",
        "City": "Punta Gorda",
        "Country": "Belize"
    },
    {
        "id": "5957",
        "AirportCode": "PNE",
        "AirportName": "North Philadelphia Airport",
        "City": "Philadelphia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "5958",
        "AirportCode": "PNG",
        "AirportName": "Paranagua Municipal Airport",
        "City": "Paranagua",
        "Country": "Brazil"
    },
    {
        "id": "5959",
        "AirportCode": "PNH",
        "AirportName": "Pochentong Airport",
        "City": "Phnom Penh",
        "Country": "Cambodia"
    },
    {
        "id": "5960",
        "AirportCode": "PNI",
        "AirportName": "Pohnpei Airport",
        "City": "Pohnpei, Caroline Islands",
        "Country": "Micronesia"
    },
    {
        "id": "5961",
        "AirportCode": "PNJ",
        "AirportName": "Sha He Kou Airport",
        "City": "Peng Lai",
        "Country": "China"
    },
    {
        "id": "5962",
        "AirportCode": "PNK",
        "AirportName": "Supadio Airport",
        "City": "Pontianak",
        "Country": "Indonesia"
    },
    {
        "id": "5963",
        "AirportCode": "PNL",
        "AirportName": "Pantelleria Airport",
        "City": "Pantelleria",
        "Country": "Italy"
    },
    {
        "id": "5964",
        "AirportCode": "PNN",
        "AirportName": "Princeton Airport",
        "City": "Princeton",
        "Country": "USA Maine"
    },
    {
        "id": "5965",
        "AirportCode": "PNO",
        "AirportName": "Pinotepa Nacional Airport",
        "City": "Pinotepa Nacional",
        "Country": "Mexico"
    },
    {
        "id": "5966",
        "AirportCode": "PNP",
        "AirportName": "Girua Airport",
        "City": "Popondetta",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5967",
        "AirportCode": "PNQ",
        "AirportName": "Lohegaon Airport",
        "City": "Pune",
        "Country": "India"
    },
    {
        "id": "5968",
        "AirportCode": "PNR",
        "AirportName": "Pointe Noire Airport",
        "City": "Pointe Noire",
        "Country": "Congo"
    },
    {
        "id": "5969",
        "AirportCode": "PNS",
        "AirportName": "Pensacola Regional Airport",
        "City": "Pensacola",
        "Country": "USA Florida"
    },
    {
        "id": "5970",
        "AirportCode": "PNT",
        "AirportName": "Teniente J. Gallardo Airport",
        "City": "Puerto Natales",
        "Country": "Chile"
    },
    {
        "id": "5971",
        "AirportCode": "PNU",
        "AirportName": "Panguitch Airport",
        "City": "Panguitch",
        "Country": "USA Utah"
    },
    {
        "id": "5972",
        "AirportCode": "PNV",
        "AirportName": "Panevezys Airport",
        "City": "Panevezys",
        "Country": "Lithuania"
    },
    {
        "id": "5973",
        "AirportCode": "PNX",
        "AirportName": "Grayson County Airport",
        "City": "Sherman-Denison",
        "Country": "USA Texas"
    },
    {
        "id": "5974",
        "AirportCode": "PNY",
        "AirportName": "Pondicherry Airport",
        "City": "Pondicherry",
        "Country": "India"
    },
    {
        "id": "5975",
        "AirportCode": "PNZ",
        "AirportName": "Petrolina Internacional Airport",
        "City": "Petrolina",
        "Country": "Brazil"
    },
    {
        "id": "5976",
        "AirportCode": "POA",
        "AirportName": "Salgado Filho Airport",
        "City": "Porto Alegre",
        "Country": "Brazil"
    },
    {
        "id": "5977",
        "AirportCode": "POB",
        "AirportName": "Pope Air Force Base",
        "City": "Fayetteville",
        "Country": "USA North Carolina"
    },
    {
        "id": "5978",
        "AirportCode": "POC",
        "AirportName": "Brackett Field",
        "City": "La Verne",
        "Country": "USA California"
    },
    {
        "id": "5979",
        "AirportCode": "POD",
        "AirportName": "Podor Airport",
        "City": "Podor",
        "Country": "Senegal"
    },
    {
        "id": "5980",
        "AirportCode": "POE",
        "AirportName": "Polk Army Air Field",
        "City": "Fort Polk",
        "Country": "USA Louisiana"
    },
    {
        "id": "5981",
        "AirportCode": "POF",
        "AirportName": "Earl Fields Memorial Airport",
        "City": "Poplar Bluff",
        "Country": "USA Missouri"
    },
    {
        "id": "5982",
        "AirportCode": "POG",
        "AirportName": "Port Gentil Airport",
        "City": "Port Gentil",
        "Country": "Gabon"
    },
    {
        "id": "5983",
        "AirportCode": "POH",
        "AirportName": "Pocahontas Municipal Airport",
        "City": "Pocahontas",
        "Country": "USA Iowa"
    },
    {
        "id": "5984",
        "AirportCode": "POI",
        "AirportName": "Dyess Aaf",
        "City": "Roi-Namur",
        "Country": "Marshall Islands"
    },
    {
        "id": "5985",
        "AirportCode": "POI",
        "AirportName": "Potosi Airport",
        "City": "Potosi",
        "Country": "Bolivia"
    },
    {
        "id": "5986",
        "AirportCode": "POJ",
        "AirportName": "Patos De Minas Airport",
        "City": "Patos De Minas",
        "Country": "Brazil"
    },
    {
        "id": "5987",
        "AirportCode": "POL",
        "AirportName": "Pemba Airport",
        "City": "Pemba",
        "Country": "Mozambique"
    },
    {
        "id": "5988",
        "AirportCode": "POM",
        "AirportName": "Jackson Field",
        "City": "Port Moresby",
        "Country": "Papua New Guinea"
    },
    {
        "id": "5989",
        "AirportCode": "PON",
        "AirportName": "Poptun Airport",
        "City": "Poptun",
        "Country": "Guatemala"
    },
    {
        "id": "5990",
        "AirportCode": "POO",
        "AirportName": "Pocos De Caldas Airport",
        "City": "Pocos De Caldas",
        "Country": "Brazil"
    },
    {
        "id": "5991",
        "AirportCode": "POP",
        "AirportName": "La Union Airport",
        "City": "Puerto Plata",
        "Country": "Dominican Republic"
    },
    {
        "id": "5992",
        "AirportCode": "POR",
        "AirportName": "Pori Airport",
        "City": "Pori",
        "Country": "Finland"
    },
    {
        "id": "5993",
        "AirportCode": "POS",
        "AirportName": "Piarco Airport",
        "City": "Port Of Spain",
        "Country": "Trinidad & Tobago"
    },
    {
        "id": "5994",
        "AirportCode": "POT",
        "AirportName": "Ken Jones Airport",
        "City": "Port Antonio",
        "Country": "Jamaica"
    },
    {
        "id": "5995",
        "AirportCode": "POU",
        "AirportName": "Dutchess County Airport",
        "City": "Poughkeepsie",
        "Country": "USA New York"
    },
    {
        "id": "5996",
        "AirportCode": "POW",
        "AirportName": "Portoroz Airport",
        "City": "Portoroz",
        "Country": "Slovenia"
    },
    {
        "id": "5997",
        "AirportCode": "POX",
        "AirportName": "Cormeille En Vexin Airport",
        "City": "Pontoise",
        "Country": "France"
    },
    {
        "id": "5998",
        "AirportCode": "POY",
        "AirportName": "Powell/Lovell Airport",
        "City": "Powell/Lovell",
        "Country": "USA Wyoming"
    },
    {
        "id": "5999",
        "AirportCode": "POZ",
        "AirportName": "Lawica Airport",
        "City": "Poznan",
        "Country": "Poland"
    },
    {
        "id": "6000",
        "AirportCode": "PPA",
        "AirportName": "Perry Lefors Field",
        "City": "Pampa",
        "Country": "USA Texas"
    },
    {
        "id": "6001",
        "AirportCode": "PPB",
        "AirportName": "A. De Barros Airport",
        "City": "Presidente Prudente",
        "Country": "Brazil"
    },
    {
        "id": "6002",
        "AirportCode": "PPD",
        "AirportName": "Palmas Del Mar Air Strip",
        "City": "Humacao",
        "Country": "Puerto Rico"
    },
    {
        "id": "6003",
        "AirportCode": "PPE",
        "AirportName": "Puerto Penasco Airport",
        "City": "Puerto Penasco",
        "Country": "Mexico"
    },
    {
        "id": "6004",
        "AirportCode": "PPF",
        "AirportName": "Tri-City Airport",
        "City": "Parsons",
        "Country": "USA Kansas"
    },
    {
        "id": "6005",
        "AirportCode": "PPG",
        "AirportName": "Pago Pago International Airport",
        "City": "Pago Pago",
        "Country": "Samoa"
    },
    {
        "id": "6006",
        "AirportCode": "PPH",
        "AirportName": "Peraitepuy Airport",
        "City": "Peraitepuy",
        "Country": "Venezuela"
    },
    {
        "id": "6007",
        "AirportCode": "PPI",
        "AirportName": "Port Pirie Airport",
        "City": "Port Pirie",
        "Country": "Australia"
    },
    {
        "id": "6008",
        "AirportCode": "PPJ",
        "AirportName": "Pulau Panjang Airport",
        "City": "Pulau Panjang",
        "Country": "Indonesia"
    },
    {
        "id": "6009",
        "AirportCode": "PPK",
        "AirportName": "Petropavlovsk Airport",
        "City": "Petropavlovsk",
        "Country": "Kazakhstan"
    },
    {
        "id": "6010",
        "AirportCode": "PPL",
        "AirportName": "Phaplu Airport",
        "City": "Phaplu",
        "Country": "Nepal"
    },
    {
        "id": "6011",
        "AirportCode": "PPM",
        "AirportName": "Pompano Beach Airport",
        "City": "Pompano Beach",
        "Country": "USA Florida"
    },
    {
        "id": "6012",
        "AirportCode": "PPN",
        "AirportName": "Machangara Airport",
        "City": "Popayan",
        "Country": "Colombia"
    },
    {
        "id": "6013",
        "AirportCode": "PPP",
        "AirportName": "Whitsunday Coast Airport",
        "City": "Proserpine",
        "Country": "Australia"
    },
    {
        "id": "6014",
        "AirportCode": "PPQ",
        "AirportName": "Paraparaumu Airport",
        "City": "Paraparaumu",
        "Country": "New Zealand"
    },
    {
        "id": "6015",
        "AirportCode": "PPR",
        "AirportName": "Pasir Pangarayan Airport",
        "City": "Pasir Pangarayan",
        "Country": "Indonesia"
    },
    {
        "id": "6016",
        "AirportCode": "PPS",
        "AirportName": "Puerto Princesa Airport",
        "City": "Puerto Princesa",
        "Country": "Philippines"
    },
    {
        "id": "6017",
        "AirportCode": "PPT",
        "AirportName": "Faaa Airport",
        "City": "Papeete",
        "Country": "French Polynesia"
    },
    {
        "id": "6018",
        "AirportCode": "PPU",
        "AirportName": "Papun Airport",
        "City": "Papun",
        "Country": "Burma"
    },
    {
        "id": "6019",
        "AirportCode": "PPV",
        "AirportName": "Port Protection Airport",
        "City": "Port Protection",
        "Country": "USA Alaska"
    },
    {
        "id": "6020",
        "AirportCode": "PPW",
        "AirportName": "Papa Westray Airport",
        "City": "Papa Westray",
        "Country": "United Kingdom"
    },
    {
        "id": "6021",
        "AirportCode": "PPX",
        "AirportName": "Param Airport",
        "City": "Param",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6022",
        "AirportCode": "PPY",
        "AirportName": "Pouso Alegre Airport",
        "City": "Pouso Alegre",
        "Country": "Brazil"
    },
    {
        "id": "6023",
        "AirportCode": "PPZ",
        "AirportName": "Puerto Paez Airport",
        "City": "Puerto Paez",
        "Country": "Venezuela"
    },
    {
        "id": "6024",
        "AirportCode": "PQC",
        "AirportName": "Duong Dang Airport",
        "City": "Phu Quoc",
        "Country": "Vietnam"
    },
    {
        "id": "6025",
        "AirportCode": "PQI",
        "AirportName": "Presque Isle Municipal Airport",
        "City": "Presque Isle",
        "Country": "USA Maine"
    },
    {
        "id": "6026",
        "AirportCode": "PQM",
        "AirportName": "Palenque Airport",
        "City": "Palenque",
        "Country": "Mexico"
    },
    {
        "id": "6027",
        "AirportCode": "PQQ",
        "AirportName": "Port Macquarie Airport",
        "City": "Port Macquarie",
        "Country": "Australia"
    },
    {
        "id": "6028",
        "AirportCode": "PQS",
        "AirportName": "Pilot Station Airport",
        "City": "Pilot Station",
        "Country": "USA Alaska"
    },
    {
        "id": "6029",
        "AirportCode": "PRA",
        "AirportName": "Parana Airport",
        "City": "Parana",
        "Country": "Argentina"
    },
    {
        "id": "6030",
        "AirportCode": "PRB",
        "AirportName": "Paso Robles Airport",
        "City": "Paso Robles",
        "Country": "USA California"
    },
    {
        "id": "6031",
        "AirportCode": "PRC",
        "AirportName": "Prescott Airport",
        "City": "Prescott",
        "Country": "USA Arizona"
    },
    {
        "id": "6032",
        "AirportCode": "PRD",
        "AirportName": "Pardoo Airport",
        "City": "Pardoo",
        "Country": "Australia"
    },
    {
        "id": "6033",
        "AirportCode": "PRE",
        "AirportName": "Pore Airport",
        "City": "Pore",
        "Country": "Colombia"
    },
    {
        "id": "6034",
        "AirportCode": "PRG",
        "AirportName": "Prague-Ruzyne Airport",
        "City": "Prague (PRG)",
        "Country": "Czech Republic"
    },
    {
        "id": "6035",
        "AirportCode": "PRH",
        "AirportName": "Phrae Airport",
        "City": "Phrae",
        "Country": "Thailand"
    },
    {
        "id": "6036",
        "AirportCode": "PRI",
        "AirportName": "Praslin Island Airport",
        "City": "Praslin Island",
        "Country": "Seychelles"
    },
    {
        "id": "6037",
        "AirportCode": "PRJ",
        "AirportName": "Capri Airport",
        "City": "Capri",
        "Country": "Italy"
    },
    {
        "id": "6038",
        "AirportCode": "PRK",
        "AirportName": "Prieska Airport",
        "City": "Prieska",
        "Country": "South Africa"
    },
    {
        "id": "6039",
        "AirportCode": "PRM",
        "AirportName": "Portimao Airport",
        "City": "Portimao",
        "Country": "Portugal"
    },
    {
        "id": "6040",
        "AirportCode": "PRN",
        "AirportName": "Pristina Airport",
        "City": "Pristina",
        "Country": "Serbia"
    },
    {
        "id": "6041",
        "AirportCode": "PRO",
        "AirportName": "Perry Municipal Airport",
        "City": "Perry",
        "Country": "USA Iowa"
    },
    {
        "id": "6042",
        "AirportCode": "PRP",
        "AirportName": "Propriano Airport",
        "City": "Propriano",
        "Country": "France"
    },
    {
        "id": "6043",
        "AirportCode": "PRQ",
        "AirportName": "Pres. Roque Saenz Pena Airport",
        "City": "Pres. Roque Saenz Pena",
        "Country": "Argentina"
    },
    {
        "id": "6044",
        "AirportCode": "PRR",
        "AirportName": "Paruima Airport",
        "City": "Paruima",
        "Country": "Guyana"
    },
    {
        "id": "6045",
        "AirportCode": "PRS",
        "AirportName": "Parasi Airport",
        "City": "Parasi",
        "Country": "Solomon Islands"
    },
    {
        "id": "6046",
        "AirportCode": "PRU",
        "AirportName": "Prome Airport",
        "City": "Prome",
        "Country": "Burma"
    },
    {
        "id": "6047",
        "AirportCode": "PRV",
        "AirportName": "Prerov Airport",
        "City": "Prerov",
        "Country": "Czech Republic"
    },
    {
        "id": "6048",
        "AirportCode": "PRW",
        "AirportName": "Prentice Airport",
        "City": "Prentice",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6049",
        "AirportCode": "PRX",
        "AirportName": "Cox Field",
        "City": "Paris",
        "Country": "USA Texas"
    },
    {
        "id": "6050",
        "AirportCode": "PRY",
        "AirportName": "Wonderboom Airport",
        "City": "Pretoria",
        "Country": "South Africa"
    },
    {
        "id": "6051",
        "AirportCode": "PRZ",
        "AirportName": "Prineville Airport",
        "City": "Prineville",
        "Country": "USA Oregon"
    },
    {
        "id": "6052",
        "AirportCode": "PSA",
        "AirportName": "Gal Galilei Airport",
        "City": "Pisa",
        "Country": "Italy"
    },
    {
        "id": "6053",
        "AirportCode": "PSB",
        "AirportName": "Mid-State Airport",
        "City": "Philipsburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6054",
        "AirportCode": "PSC",
        "AirportName": "Tri-Cities Airport",
        "City": "Pasco",
        "Country": "USA Washington"
    },
    {
        "id": "6055",
        "AirportCode": "PSD",
        "AirportName": "Port Said Airport",
        "City": "Port Said",
        "Country": "Egypt"
    },
    {
        "id": "6056",
        "AirportCode": "PSE",
        "AirportName": "Mercedita Airport",
        "City": "Ponce",
        "Country": "Puerto Rico"
    },
    {
        "id": "6057",
        "AirportCode": "PSF",
        "AirportName": "Pittsfield Airport",
        "City": "Pittsfield",
        "Country": "USA Massachusetts"
    },
    {
        "id": "6058",
        "AirportCode": "PSG",
        "AirportName": "Petersburg Municipal Airport",
        "City": "Petersburg",
        "Country": "USA Alaska"
    },
    {
        "id": "6059",
        "AirportCode": "PSH",
        "AirportName": "St. Peter Airport",
        "City": "Saint Peter",
        "Country": "Germany"
    },
    {
        "id": "6060",
        "AirportCode": "PSI",
        "AirportName": "Pasni Airport",
        "City": "Pasni",
        "Country": "Pakistan"
    },
    {
        "id": "6061",
        "AirportCode": "PSJ",
        "AirportName": "Poso Airport",
        "City": "Poso",
        "Country": "Indonesia"
    },
    {
        "id": "6062",
        "AirportCode": "PSK",
        "AirportName": "New River Valley Airport",
        "City": "Dublin",
        "Country": "USA Virginia"
    },
    {
        "id": "6063",
        "AirportCode": "PSL",
        "AirportName": "Perth International Airport",
        "City": "Perth",
        "Country": "United Kingdom"
    },
    {
        "id": "6064",
        "AirportCode": "PSM",
        "AirportName": "Pease Air Force Base",
        "City": "Portsmouth",
        "Country": "USA New Hampshire"
    },
    {
        "id": "6065",
        "AirportCode": "PSN",
        "AirportName": "Palestine Airport",
        "City": "Palestine",
        "Country": "USA Texas"
    },
    {
        "id": "6066",
        "AirportCode": "PSO",
        "AirportName": "Cano Airport",
        "City": "Pasto",
        "Country": "Colombia"
    },
    {
        "id": "6067",
        "AirportCode": "PSP",
        "AirportName": "Palm Springs Municipal Airport",
        "City": "Palm Springs",
        "Country": "USA California"
    },
    {
        "id": "6068",
        "AirportCode": "PSQ",
        "AirportName": "Philadelphia Sea Plane Base",
        "City": "Philadelphia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6069",
        "AirportCode": "PSR",
        "AirportName": "Liberi Airport",
        "City": "Pescara",
        "Country": "Italy"
    },
    {
        "id": "6070",
        "AirportCode": "PSS",
        "AirportName": "Posadas Airport",
        "City": "Posadas",
        "Country": "Argentina"
    },
    {
        "id": "6071",
        "AirportCode": "PST",
        "AirportName": "Preston Airport",
        "City": "Preston",
        "Country": "Cuba"
    },
    {
        "id": "6072",
        "AirportCode": "PSU",
        "AirportName": "Putussibau Airport",
        "City": "Putussibau",
        "Country": "Indonesia"
    },
    {
        "id": "6073",
        "AirportCode": "PSV",
        "AirportName": "Papa Stour Airport",
        "City": "Papa Stour",
        "Country": "United Kingdom"
    },
    {
        "id": "6074",
        "AirportCode": "PSW",
        "AirportName": "Passos Airport",
        "City": "Passos",
        "Country": "Brazil"
    },
    {
        "id": "6075",
        "AirportCode": "PSX",
        "AirportName": "Palacios Airport",
        "City": "Palacios",
        "Country": "USA Texas"
    },
    {
        "id": "6076",
        "AirportCode": "PSY",
        "AirportName": "Port Stanley Airport",
        "City": "Port Stanley",
        "Country": "Falkland Islands"
    },
    {
        "id": "6077",
        "AirportCode": "PSZ",
        "AirportName": "Puerto Suarez Airport",
        "City": "Puerto Suarez",
        "Country": "Bolivia"
    },
    {
        "id": "6078",
        "AirportCode": "PTA",
        "AirportName": "Port Alsworth Airport",
        "City": "Port Alsworth",
        "Country": "USA Alaska"
    },
    {
        "id": "6079",
        "AirportCode": "PTB",
        "AirportName": "Petersburg Municipal Airport",
        "City": "Petersburg",
        "Country": "USA Virginia"
    },
    {
        "id": "6080",
        "AirportCode": "PTE",
        "AirportName": "Port Stephens Airport",
        "City": "Port Stephens",
        "Country": "Australia"
    },
    {
        "id": "6081",
        "AirportCode": "PTF",
        "AirportName": "Malololailai Airport",
        "City": "Malololailai",
        "Country": "Fiji"
    },
    {
        "id": "6082",
        "AirportCode": "PTG",
        "AirportName": "Polokwane Airport",
        "City": "Polokwane",
        "Country": "South Africa"
    },
    {
        "id": "6083",
        "AirportCode": "PTH",
        "AirportName": "Port Heiden Airport",
        "City": "Port Heiden",
        "Country": "USA Alaska"
    },
    {
        "id": "6084",
        "AirportCode": "PTI",
        "AirportName": "Port Douglas Airport",
        "City": "Port Douglas",
        "Country": "Australia"
    },
    {
        "id": "6085",
        "AirportCode": "PTJ",
        "AirportName": "Portland Airport",
        "City": "Portland",
        "Country": "Australia"
    },
    {
        "id": "6086",
        "AirportCode": "PTK",
        "AirportName": "Pontiac Airport",
        "City": "Pontiac",
        "Country": "USA Michigan"
    },
    {
        "id": "6087",
        "AirportCode": "PTM",
        "AirportName": "Palmarito Airport",
        "City": "Palmarito",
        "Country": "Venezuela"
    },
    {
        "id": "6088",
        "AirportCode": "PTN",
        "AirportName": "Patterson Municipal Heliport",
        "City": "Patterson",
        "Country": "USA Louisiana"
    },
    {
        "id": "6089",
        "AirportCode": "PTO",
        "AirportName": "Pato Branco Municipal Airport",
        "City": "Pato Branco",
        "Country": "Brazil"
    },
    {
        "id": "6090",
        "AirportCode": "PTP",
        "AirportName": "Le Raizet Airport",
        "City": "Pointe A Pitre",
        "Country": "Guadeloupe"
    },
    {
        "id": "6091",
        "AirportCode": "PTQ",
        "AirportName": "Porto de Moz Airport",
        "City": "Porto de Moz",
        "Country": "Brazil"
    },
    {
        "id": "6092",
        "AirportCode": "PTS",
        "AirportName": "Pittsburg Municipal Airport",
        "City": "Pittsburg",
        "Country": "USA Kansas"
    },
    {
        "id": "6093",
        "AirportCode": "PTT",
        "AirportName": "Pratt Airport",
        "City": "Pratt",
        "Country": "USA Kansas"
    },
    {
        "id": "6094",
        "AirportCode": "PTU",
        "AirportName": "Platinum Airport",
        "City": "Platinum",
        "Country": "USA Alaska"
    },
    {
        "id": "6095",
        "AirportCode": "PTV",
        "AirportName": "Porterville Airport",
        "City": "Porterville",
        "Country": "USA California"
    },
    {
        "id": "6096",
        "AirportCode": "PTW",
        "AirportName": "Pottstown/Limerick Airport",
        "City": "Pottstown",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6097",
        "AirportCode": "PTX",
        "AirportName": "Pitalito Airport",
        "City": "Pitalito",
        "Country": "Colombia"
    },
    {
        "id": "6098",
        "AirportCode": "PTY",
        "AirportName": "Tocumen International Airport",
        "City": "Panama City",
        "Country": "Panama"
    },
    {
        "id": "6099",
        "AirportCode": "PTZ",
        "AirportName": "Pastaza Airport",
        "City": "Pastaza",
        "Country": "Ecuador"
    },
    {
        "id": "6100",
        "AirportCode": "PUA",
        "AirportName": "Puas Airport",
        "City": "Puas",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6101",
        "AirportCode": "PUB",
        "AirportName": "Pueblo Memorial Airport",
        "City": "Pueblo",
        "Country": "USA Colorado"
    },
    {
        "id": "6102",
        "AirportCode": "PUC",
        "AirportName": "Carbon County Airport",
        "City": "Price",
        "Country": "USA Utah"
    },
    {
        "id": "6103",
        "AirportCode": "PUD",
        "AirportName": "Puerto Deseado Airport",
        "City": "Puerto Deseado",
        "Country": "Argentina"
    },
    {
        "id": "6104",
        "AirportCode": "PUE",
        "AirportName": "Puerto Obaldia Airport",
        "City": "Puerto Obaldia",
        "Country": "Panama"
    },
    {
        "id": "6105",
        "AirportCode": "PUF",
        "AirportName": "Uzein Airport",
        "City": "Pau",
        "Country": "France"
    },
    {
        "id": "6106",
        "AirportCode": "PUG",
        "AirportName": "Port Augusta Airport",
        "City": "Port Augusta",
        "Country": "Australia"
    },
    {
        "id": "6107",
        "AirportCode": "PUH",
        "AirportName": "Pochutla Airport",
        "City": "Pochutla",
        "Country": "Mexico"
    },
    {
        "id": "6108",
        "AirportCode": "PUI",
        "AirportName": "Pureni Airport",
        "City": "Pureni",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6109",
        "AirportCode": "PUJ",
        "AirportName": "Punta Cana Airport",
        "City": "Punta Cana",
        "Country": "Dominican Republic"
    },
    {
        "id": "6110",
        "AirportCode": "PUK",
        "AirportName": "Pukarua Airport",
        "City": "Pukarua, Tuamoto Island",
        "Country": "French Polynesia"
    },
    {
        "id": "6111",
        "AirportCode": "PUL",
        "AirportName": "Poulsbo Airport",
        "City": "Poulsbo",
        "Country": "USA Washington"
    },
    {
        "id": "6112",
        "AirportCode": "PUM",
        "AirportName": "Pomala Airport",
        "City": "Pomala",
        "Country": "Indonesia"
    },
    {
        "id": "6113",
        "AirportCode": "PUN",
        "AirportName": "Punia Airport",
        "City": "Punia",
        "Country": "Congo, DR"
    },
    {
        "id": "6114",
        "AirportCode": "PUO",
        "AirportName": "Prudhoe Bay Airport",
        "City": "Prudhoe Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "6115",
        "AirportCode": "PUP",
        "AirportName": "Po Airport",
        "City": "Po",
        "Country": "Burkina Faso"
    },
    {
        "id": "6116",
        "AirportCode": "PUQ",
        "AirportName": "Pres Ibanez Airport",
        "City": "Punta Arenas",
        "Country": "Chile"
    },
    {
        "id": "6117",
        "AirportCode": "PUR",
        "AirportName": "Puerto Rico Airport",
        "City": "Puerto Rico",
        "Country": "Bolivia"
    },
    {
        "id": "6118",
        "AirportCode": "PUS",
        "AirportName": "Gimhae International Airport",
        "City": "Busan",
        "Country": "Korea"
    },
    {
        "id": "6119",
        "AirportCode": "PUT",
        "AirportName": "Puttaprathi Airport",
        "City": "Puttaparthi",
        "Country": "India"
    },
    {
        "id": "6120",
        "AirportCode": "PUU",
        "AirportName": "Puerto Asis Airport",
        "City": "Puerto Asis",
        "Country": "Colombia"
    },
    {
        "id": "6121",
        "AirportCode": "PUV",
        "AirportName": "Poum Airport",
        "City": "Poum",
        "Country": "New Caledonia"
    },
    {
        "id": "6122",
        "AirportCode": "PUW",
        "AirportName": "Moscow Regional Airport",
        "City": "Pullman",
        "Country": "USA Washington"
    },
    {
        "id": "6123",
        "AirportCode": "PUX",
        "AirportName": "Puerto Varas Airport",
        "City": "Puerto Varas",
        "Country": "Chile"
    },
    {
        "id": "6124",
        "AirportCode": "PUY",
        "AirportName": "Pula Airport",
        "City": "Pula",
        "Country": "Croatia"
    },
    {
        "id": "6125",
        "AirportCode": "PUZ",
        "AirportName": "Puerto Cabezas Airport",
        "City": "Puerto Cabezas",
        "Country": "Nicaragua"
    },
    {
        "id": "6126",
        "AirportCode": "PVA",
        "AirportName": "Providencia Airport",
        "City": "Providencia",
        "Country": "Colombia"
    },
    {
        "id": "6127",
        "AirportCode": "PVC",
        "AirportName": "Provincetown Airport",
        "City": "Provincetown",
        "Country": "USA Massachusetts"
    },
    {
        "id": "6128",
        "AirportCode": "PVD",
        "AirportName": "Theodore Francis Green State Airport",
        "City": "Providence",
        "Country": "USA Rhode Island"
    },
    {
        "id": "6129",
        "AirportCode": "PVE",
        "AirportName": "El Porvenir Airport",
        "City": "El Porvenir",
        "Country": "Panama"
    },
    {
        "id": "6130",
        "AirportCode": "PVF",
        "AirportName": "Placerville Airport",
        "City": "Placerville",
        "Country": "USA California"
    },
    {
        "id": "6131",
        "AirportCode": "PVG",
        "AirportName": "Pu Dong Airport",
        "City": "Shanghai",
        "Country": "China"
    },
    {
        "id": "6132",
        "AirportCode": "PVH",
        "AirportName": "Belmonte Airport",
        "City": "Porto Velho",
        "Country": "Brazil"
    },
    {
        "id": "6133",
        "AirportCode": "PVI",
        "AirportName": "Paranavia Airport",
        "City": "Paranavia",
        "Country": "Brazil"
    },
    {
        "id": "6134",
        "AirportCode": "PVK",
        "AirportName": "Aktion Airport",
        "City": "Preveza",
        "Country": "Greece"
    },
    {
        "id": "6135",
        "AirportCode": "PVN",
        "AirportName": "Pleven Airport",
        "City": "Pleven",
        "Country": "Bulgaria"
    },
    {
        "id": "6136",
        "AirportCode": "PVO",
        "AirportName": "Portoviejo Airport",
        "City": "Portoviejo",
        "Country": "Ecuador"
    },
    {
        "id": "6137",
        "AirportCode": "PVR",
        "AirportName": "Ordaz",
        "City": "Puerto Vallarta",
        "Country": "Mexico"
    },
    {
        "id": "6138",
        "AirportCode": "PVS",
        "AirportName": "Provideniya Bay",
        "City": "Provideniya Bay",
        "Country": "Russia"
    },
    {
        "id": "6139",
        "AirportCode": "PVS",
        "AirportName": "Provideniya Airport",
        "City": "Provideniya",
        "Country": "Russia"
    },
    {
        "id": "6140",
        "AirportCode": "PVU",
        "AirportName": "Provo Airport",
        "City": "Provo",
        "Country": "USA Utah"
    },
    {
        "id": "6141",
        "AirportCode": "PVW",
        "AirportName": "Hale County Airport",
        "City": "Plainview",
        "Country": "USA Texas"
    },
    {
        "id": "6142",
        "AirportCode": "PVX",
        "AirportName": "Pope Vanoy Airport",
        "City": "Provedenia",
        "Country": "Russia"
    },
    {
        "id": "6143",
        "AirportCode": "PVZ",
        "AirportName": "Casement Airport",
        "City": "Painesville",
        "Country": "USA Ohio"
    },
    {
        "id": "6144",
        "AirportCode": "PWA",
        "AirportName": "Wiley Post Airport",
        "City": "Oklahoma City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "6145",
        "AirportCode": "PWD",
        "AirportName": "Sherwood Airport",
        "City": "Plentywood",
        "Country": "USA Montana"
    },
    {
        "id": "6146",
        "AirportCode": "PWE",
        "AirportName": "Pevek Airport",
        "City": "Pevek",
        "Country": "Russia"
    },
    {
        "id": "6147",
        "AirportCode": "PWI",
        "AirportName": "Beles Airport",
        "City": "Pawi",
        "Country": "Ethiopia"
    },
    {
        "id": "6148",
        "AirportCode": "PWK",
        "AirportName": "Pal-Waukee Airport",
        "City": "Chicago",
        "Country": "USA Illinois"
    },
    {
        "id": "6149",
        "AirportCode": "PWL",
        "AirportName": "Purwokerto Airport",
        "City": "Purwokerto",
        "Country": "Indonesia"
    },
    {
        "id": "6150",
        "AirportCode": "PWM",
        "AirportName": "Portland International Jetport",
        "City": "Portland",
        "Country": "USA Maine"
    },
    {
        "id": "6151",
        "AirportCode": "PWO",
        "AirportName": "Pweto Airport",
        "City": "Pweto",
        "Country": "Congo, DR"
    },
    {
        "id": "6152",
        "AirportCode": "PWQ",
        "AirportName": "Pavlodar Airport",
        "City": "Pavlodar",
        "Country": "Kazakhstan"
    },
    {
        "id": "6153",
        "AirportCode": "PWT",
        "AirportName": "Bremerton National Airport",
        "City": "Bremerton",
        "Country": "USA Washington"
    },
    {
        "id": "6154",
        "AirportCode": "PXL",
        "AirportName": "Polacca Airport",
        "City": "Polacca",
        "Country": "USA Arizona"
    },
    {
        "id": "6155",
        "AirportCode": "PXM",
        "AirportName": "Puerto Escondido Airport",
        "City": "Puerto Escondido",
        "Country": "Mexico"
    },
    {
        "id": "6156",
        "AirportCode": "PXO",
        "AirportName": "Porto Santo Airport",
        "City": "Porto Santo (Madeira)",
        "Country": "Portugal"
    },
    {
        "id": "6157",
        "AirportCode": "PXR",
        "AirportName": "Surin Airport",
        "City": "Surin",
        "Country": "Thailand"
    },
    {
        "id": "6158",
        "AirportCode": "PXS",
        "AirportName": "Puerto De Santa Maria Airport",
        "City": "Puerto De Santa Maria",
        "Country": "Spain"
    },
    {
        "id": "6159",
        "AirportCode": "PXU",
        "AirportName": "Pleiku Airport",
        "City": "Pleiku",
        "Country": "Vietnam"
    },
    {
        "id": "6160",
        "AirportCode": "PYA",
        "AirportName": "Puerto Boyaca Airport",
        "City": "Puerto Boyaca",
        "Country": "Colombia"
    },
    {
        "id": "6161",
        "AirportCode": "PYB",
        "AirportName": "Jeypore Airport",
        "City": "Jeypore",
        "Country": "India"
    },
    {
        "id": "6162",
        "AirportCode": "PYC",
        "AirportName": "Playon Chico Airport",
        "City": "Playon Chico",
        "Country": "Panama"
    },
    {
        "id": "6163",
        "AirportCode": "PYE",
        "AirportName": "Penrhyn Island Airport",
        "City": "Penrhyn Island",
        "Country": "Cook Islands"
    },
    {
        "id": "6164",
        "AirportCode": "PYH",
        "AirportName": "Puerto Ayacucho Airport",
        "City": "Puerto Ayacucho",
        "Country": "Venezuela"
    },
    {
        "id": "6165",
        "AirportCode": "PYJ",
        "AirportName": "Polyarnyj Airport",
        "City": "Polyarnyj",
        "Country": "Russia"
    },
    {
        "id": "6166",
        "AirportCode": "PYM",
        "AirportName": "Plymouth Airport",
        "City": "Plymouth",
        "Country": "USA Massachusetts"
    },
    {
        "id": "6167",
        "AirportCode": "PYN",
        "AirportName": "Payan Airport",
        "City": "Payan",
        "Country": "Colombia"
    },
    {
        "id": "6168",
        "AirportCode": "PYO",
        "AirportName": "Putumayo Airport",
        "City": "Putumayo",
        "Country": "Ecuador"
    },
    {
        "id": "6169",
        "AirportCode": "PYR",
        "AirportName": "Andravida Airport",
        "City": "Pyrgos",
        "Country": "Greece"
    },
    {
        "id": "6170",
        "AirportCode": "PYV",
        "AirportName": "Yaviza Airport",
        "City": "Yaviza",
        "Country": "Panama"
    },
    {
        "id": "6171",
        "AirportCode": "PYX",
        "AirportName": "Pattaya Airport",
        "City": "Pattaya",
        "Country": "Thailand"
    },
    {
        "id": "6172",
        "AirportCode": "PZA",
        "AirportName": "Casanare Airport",
        "City": "Paz De Ariporo",
        "Country": "Colombia"
    },
    {
        "id": "6173",
        "AirportCode": "PZB",
        "AirportName": "Pietermaritzburg Airport",
        "City": "Pietermaritzburg",
        "Country": "South Africa"
    },
    {
        "id": "6174",
        "AirportCode": "PZE",
        "AirportName": "Penzance Airport",
        "City": "Penzance",
        "Country": "United Kingdom"
    },
    {
        "id": "6175",
        "AirportCode": "PZH",
        "AirportName": "Zhob Airport",
        "City": "Zhob",
        "Country": "Pakistan"
    },
    {
        "id": "6176",
        "AirportCode": "PZI",
        "AirportName": "Pan Zhi Hua Bao An Ying",
        "City": "Pan Zhi Hua",
        "Country": "China"
    },
    {
        "id": "6177",
        "AirportCode": "PZK",
        "AirportName": "Puka Puka Island Airport",
        "City": "Puka Puka Island",
        "Country": "Cook Islands"
    },
    {
        "id": "6178",
        "AirportCode": "PZL",
        "AirportName": "Zulu Inyala Airport",
        "City": "Phinda",
        "Country": "South Africa"
    },
    {
        "id": "6179",
        "AirportCode": "PZO",
        "AirportName": "Puerto Ordaz Airport",
        "City": "Puerto Ordaz",
        "Country": "Venezuela"
    },
    {
        "id": "6180",
        "AirportCode": "PZU",
        "AirportName": "Port Sudan Airport",
        "City": "Port Sudan",
        "Country": "Sudan"
    },
    {
        "id": "6181",
        "AirportCode": "QAC",
        "AirportName": "Castro",
        "City": "Castro",
        "Country": "Brazil"
    },
    {
        "id": "6182",
        "AirportCode": "QAK",
        "AirportName": "Barbacena",
        "City": "Barbacena",
        "Country": "Brazil"
    },
    {
        "id": "6183",
        "AirportCode": "QBC",
        "AirportName": "Bella Coola Airport",
        "City": "Bella Coola",
        "Country": "Canada"
    },
    {
        "id": "6184",
        "AirportCode": "QBX",
        "AirportName": "Sobral",
        "City": "Sobral",
        "Country": "Brazil"
    },
    {
        "id": "6185",
        "AirportCode": "QCJ",
        "AirportName": "Botucatu",
        "City": "Botucatu",
        "Country": "Brazil"
    },
    {
        "id": "6186",
        "AirportCode": "QCN",
        "AirportName": "Canela",
        "City": "Canela",
        "Country": "Brazil"
    },
    {
        "id": "6187",
        "AirportCode": "QCP",
        "AirportName": "Currais Novos",
        "City": "Currais Novos",
        "Country": "Brazil"
    },
    {
        "id": "6188",
        "AirportCode": "QCR",
        "AirportName": "Curitibanos",
        "City": "Curitibanos",
        "Country": "Brazil"
    },
    {
        "id": "6189",
        "AirportCode": "QCU",
        "AirportName": "Heliport",
        "City": "Akunnaaq",
        "Country": "Greenland"
    },
    {
        "id": "6190",
        "AirportCode": "QDB",
        "AirportName": "Cachoeira Do Sul",
        "City": "Cachoeira Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "6191",
        "AirportCode": "QDC",
        "AirportName": "Dracena",
        "City": "Dracena",
        "Country": "Brazil"
    },
    {
        "id": "6192",
        "AirportCode": "QDF",
        "AirportName": "Conselheiro Lafaiete",
        "City": "Conselheiro Lafaiete",
        "Country": "Brazil"
    },
    {
        "id": "6193",
        "AirportCode": "QDK",
        "AirportName": "Dera Ghazi Khan",
        "City": "Dera Ghazi Khan",
        "Country": "Pakistan"
    },
    {
        "id": "6194",
        "AirportCode": "QDP",
        "AirportName": "Dom Pedrito",
        "City": "Dom Pedrito",
        "Country": "Brazil"
    },
    {
        "id": "6195",
        "AirportCode": "QDV",
        "AirportName": "Jundiai",
        "City": "Jundiai",
        "Country": "Brazil"
    },
    {
        "id": "6196",
        "AirportCode": "QFI",
        "AirportName": "Heliport",
        "City": "Iginniarfik",
        "Country": "Greenland"
    },
    {
        "id": "6197",
        "AirportCode": "QFK",
        "AirportName": "Selje Harbour",
        "City": "Selje",
        "Country": "Norway"
    },
    {
        "id": "6198",
        "AirportCode": "QFQ",
        "AirportName": "Maloy Harbour",
        "City": "Maloy",
        "Country": "Norway"
    },
    {
        "id": "6199",
        "AirportCode": "QFV",
        "AirportName": "Bergen Rail Station",
        "City": "Bergen",
        "Country": "Norway"
    },
    {
        "id": "6200",
        "AirportCode": "QGA",
        "AirportName": "Guaira",
        "City": "Guaira",
        "Country": "Brazil"
    },
    {
        "id": "6201",
        "AirportCode": "QGC",
        "AirportName": "Lencois Paulista",
        "City": "Lencois Paulista",
        "Country": "Brazil"
    },
    {
        "id": "6202",
        "AirportCode": "QGF",
        "AirportName": "Montenegro",
        "City": "Montenegro",
        "Country": "Brazil"
    },
    {
        "id": "6203",
        "AirportCode": "QGP",
        "AirportName": "Garanhuns",
        "City": "Garanhuns",
        "Country": "Brazil"
    },
    {
        "id": "6204",
        "AirportCode": "QGQ",
        "AirportName": "Attu Heliport",
        "City": "Attu",
        "Country": "Greenland"
    },
    {
        "id": "6205",
        "AirportCode": "QGU",
        "AirportName": "Gifu Aero",
        "City": "Gifu",
        "Country": "Japan"
    },
    {
        "id": "6206",
        "AirportCode": "QGV",
        "AirportName": "Neu Isenburg Airport",
        "City": "Frankfurt",
        "Country": "Germany"
    },
    {
        "id": "6207",
        "AirportCode": "QHB",
        "AirportName": "Piracicaba",
        "City": "Piracicaba",
        "Country": "Brazil"
    },
    {
        "id": "6208",
        "AirportCode": "QHN",
        "AirportName": "Taguatinga",
        "City": "Taguatinga",
        "Country": "Brazil"
    },
    {
        "id": "6209",
        "AirportCode": "QHV",
        "AirportName": "Novo Hamburgo",
        "City": "Novo Hamburgo",
        "Country": "Brazil"
    },
    {
        "id": "6210",
        "AirportCode": "QID",
        "AirportName": "Mello Viana",
        "City": "Tres Coracoes",
        "Country": "Brazil"
    },
    {
        "id": "6211",
        "AirportCode": "QIG",
        "AirportName": "Iguatu",
        "City": "Iguatu",
        "Country": "Brazil"
    },
    {
        "id": "6212",
        "AirportCode": "QIQ",
        "AirportName": "Rio Claro",
        "City": "Rio Claro",
        "Country": "Brazil"
    },
    {
        "id": "6213",
        "AirportCode": "QIT",
        "AirportName": "Itapetinga",
        "City": "Itapetinga",
        "Country": "Brazil"
    },
    {
        "id": "6214",
        "AirportCode": "QJE",
        "AirportName": "Heliport",
        "City": "Kitsissuarsuit",
        "Country": "Greenland"
    },
    {
        "id": "6215",
        "AirportCode": "QJI",
        "AirportName": "Heliport",
        "City": "Ikamiut",
        "Country": "Greenland"
    },
    {
        "id": "6216",
        "AirportCode": "QLA",
        "AirportName": "Lasham",
        "City": "Lasham",
        "Country": "United Kingdom"
    },
    {
        "id": "6217",
        "AirportCode": "QLX",
        "AirportName": "Lauterach Airport",
        "City": "Lauterach",
        "Country": "Austria"
    },
    {
        "id": "6218",
        "AirportCode": "QMF",
        "AirportName": "Mafra",
        "City": "Mafra",
        "Country": "Brazil"
    },
    {
        "id": "6219",
        "AirportCode": "QMK",
        "AirportName": "Heliport",
        "City": "Niaqornaarsuk",
        "Country": "Greenland"
    },
    {
        "id": "6220",
        "AirportCode": "QMM",
        "AirportName": "Marina Di Massa Airport",
        "City": "Marina Di Massa",
        "Country": "Italy"
    },
    {
        "id": "6221",
        "AirportCode": "QMQ",
        "AirportName": "Muzuq Airport",
        "City": "Murzuq",
        "Country": "Libya"
    },
    {
        "id": "6222",
        "AirportCode": "QMV",
        "AirportName": "Montvale Airport",
        "City": "Montvale",
        "Country": "USA New Jersey"
    },
    {
        "id": "6223",
        "AirportCode": "QNS",
        "AirportName": "Canoas Ab",
        "City": "Porto Alegre",
        "Country": "Brazil"
    },
    {
        "id": "6224",
        "AirportCode": "QNV",
        "AirportName": "Aeroclub",
        "City": "Nova Iguacu",
        "Country": "Brazil"
    },
    {
        "id": "6225",
        "AirportCode": "QNY",
        "AirportName": "Marine Air Terminal",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "6226",
        "AirportCode": "QOA",
        "AirportName": "Mococa",
        "City": "Mococa",
        "Country": "Brazil"
    },
    {
        "id": "6227",
        "AirportCode": "QOJ",
        "AirportName": "Sao Borja",
        "City": "Sao Borja",
        "Country": "Brazil"
    },
    {
        "id": "6228",
        "AirportCode": "QOX",
        "AirportName": "Memmingen Ab",
        "City": "Memmingen",
        "Country": "Germany"
    },
    {
        "id": "6229",
        "AirportCode": "QPK",
        "AirportName": "Strausberg",
        "City": "Strausberg",
        "Country": "Germany"
    },
    {
        "id": "6230",
        "AirportCode": "QPW",
        "AirportName": "Heliport",
        "City": "Kangaatsiaq",
        "Country": "Greenland"
    },
    {
        "id": "6231",
        "AirportCode": "QQM",
        "AirportName": "Manchester Piccadilly",
        "City": "Manchester (MAN)",
        "Country": "United Kingdom"
    },
    {
        "id": "6232",
        "AirportCode": "QQP",
        "AirportName": "London Paddington",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "6233",
        "AirportCode": "QRA",
        "AirportName": "Randgermiston Airport",
        "City": "Johannesburg",
        "Country": "South Africa"
    },
    {
        "id": "6234",
        "AirportCode": "QRC",
        "AirportName": "De La Independencia",
        "City": "Rancagua",
        "Country": "Chile"
    },
    {
        "id": "6235",
        "AirportCode": "QRE",
        "AirportName": "Carazinho",
        "City": "Carazinho",
        "Country": "Brazil"
    },
    {
        "id": "6236",
        "AirportCode": "QRF",
        "AirportName": "Bragado",
        "City": "Bragado",
        "Country": "Argentina"
    },
    {
        "id": "6237",
        "AirportCode": "QRH",
        "AirportName": "Metropolitan Area Airport",
        "City": "Rotterdam",
        "Country": "Netherlands"
    },
    {
        "id": "6238",
        "AirportCode": "QRM",
        "AirportName": "Narromine",
        "City": "Narromine",
        "Country": "Australia"
    },
    {
        "id": "6239",
        "AirportCode": "QRO",
        "AirportName": "Queretaro Airport",
        "City": "Queretaro",
        "Country": "Mexico"
    },
    {
        "id": "6240",
        "AirportCode": "QRR",
        "AirportName": "Warren",
        "City": "Warren",
        "Country": "Australia"
    },
    {
        "id": "6241",
        "AirportCode": "QRY",
        "AirportName": "Ikerasaarsuk Heliport",
        "City": "Ikerasaarsuk",
        "Country": "Greenland"
    },
    {
        "id": "6242",
        "AirportCode": "QRZ",
        "AirportName": "Resende",
        "City": "Resende",
        "Country": "Brazil"
    },
    {
        "id": "6243",
        "AirportCode": "QSF",
        "AirportName": "Setif Airport",
        "City": "Setif",
        "Country": "Algeria"
    },
    {
        "id": "6244",
        "AirportCode": "QSM",
        "AirportName": "Uetersen",
        "City": "Uetersen",
        "Country": "Germany"
    },
    {
        "id": "6245",
        "AirportCode": "QTL",
        "AirportName": "Caratinga",
        "City": "Caratinga",
        "Country": "Brazil"
    },
    {
        "id": "6246",
        "AirportCode": "QUB",
        "AirportName": "Ubari Airport",
        "City": "Ubari",
        "Country": "Libya"
    },
    {
        "id": "6247",
        "AirportCode": "QUF",
        "AirportName": "Pirita Harbour Airport",
        "City": "Tallinn",
        "Country": "Estonia"
    },
    {
        "id": "6248",
        "AirportCode": "QUG",
        "AirportName": "Goodwood",
        "City": "Chichester",
        "Country": "United Kingdom"
    },
    {
        "id": "6249",
        "AirportCode": "QUN",
        "AirportName": "A-306",
        "City": "Chunchon",
        "Country": "Korea"
    },
    {
        "id": "6250",
        "AirportCode": "QUP",
        "AirportName": "Saqqaq Heliport",
        "City": "Saqqaq",
        "Country": "Greenland"
    },
    {
        "id": "6251",
        "AirportCode": "QUT",
        "AirportName": "Utsunomiya Aero",
        "City": "Utsunomiya",
        "Country": "Japan"
    },
    {
        "id": "6252",
        "AirportCode": "QVB",
        "AirportName": "Uniao Da Vitoria",
        "City": "Uniao Da Vitoria",
        "Country": "Brazil"
    },
    {
        "id": "6253",
        "AirportCode": "QVP",
        "AirportName": "Avare-Arandu",
        "City": "Avare",
        "Country": "Brazil"
    },
    {
        "id": "6254",
        "AirportCode": "QWG",
        "AirportName": "Wilgrove Air Park",
        "City": "Charlotte",
        "Country": "USA North Carolina"
    },
    {
        "id": "6255",
        "AirportCode": "QXC",
        "AirportName": "Caxias",
        "City": "Caxias",
        "Country": "Brazil"
    },
    {
        "id": "6256",
        "AirportCode": "QXD",
        "AirportName": "Cachoeiro Do Itapemirim",
        "City": "Cachoeiro Do Itapemirim",
        "Country": "Brazil"
    },
    {
        "id": "6257",
        "AirportCode": "QYW",
        "AirportName": "Cannes Vieux Port Ferry Service",
        "City": "Cannes",
        "Country": "France"
    },
    {
        "id": "6258",
        "AirportCode": "QZN",
        "AirportName": "Relizane Airport",
        "City": "Relizane",
        "Country": "Algeria"
    },
    {
        "id": "6259",
        "AirportCode": "RAA",
        "AirportName": "Rakanda Airport",
        "City": "Rakanda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6260",
        "AirportCode": "RAB",
        "AirportName": "Tokua Airport",
        "City": "Rabaul",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6261",
        "AirportCode": "RAC",
        "AirportName": "Horlick Airport",
        "City": "Racine",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6262",
        "AirportCode": "RAD",
        "AirportName": "Road Town Airport",
        "City": "Tortola",
        "Country": "British Virgin Islands"
    },
    {
        "id": "6263",
        "AirportCode": "RAE",
        "AirportName": "Arar Airport",
        "City": "Arar",
        "Country": "Saudi Arabia"
    },
    {
        "id": "6264",
        "AirportCode": "RAF",
        "AirportName": "Ras An Naqb Airport",
        "City": "Ras An Naqb",
        "Country": "Egypt"
    },
    {
        "id": "6265",
        "AirportCode": "RAG",
        "AirportName": "Raglan Airport",
        "City": "Raglan",
        "Country": "New Zealand"
    },
    {
        "id": "6266",
        "AirportCode": "RAH",
        "AirportName": "Rafha Airport",
        "City": "Rafha",
        "Country": "Saudi Arabia"
    },
    {
        "id": "6267",
        "AirportCode": "RAI",
        "AirportName": "Francisco Mendes Airport",
        "City": "Praia",
        "Country": "Cape Verde"
    },
    {
        "id": "6268",
        "AirportCode": "RAJ",
        "AirportName": "Civil Airport",
        "City": "Rajkot",
        "Country": "India"
    },
    {
        "id": "6269",
        "AirportCode": "RAK",
        "AirportName": "Menara Airport",
        "City": "Marrakech",
        "Country": "Morocco"
    },
    {
        "id": "6270",
        "AirportCode": "RAL",
        "AirportName": "Riverside Municipal Airport",
        "City": "Riverside",
        "Country": "USA California"
    },
    {
        "id": "6271",
        "AirportCode": "RAM",
        "AirportName": "Ramingining Airport",
        "City": "Ramingining",
        "Country": "Australia"
    },
    {
        "id": "6272",
        "AirportCode": "RAN",
        "AirportName": "La Spreta Airport",
        "City": "Ravenna",
        "Country": "Italy"
    },
    {
        "id": "6273",
        "AirportCode": "RAO",
        "AirportName": "Leite Lopes Airport",
        "City": "Ribeirao Preto",
        "Country": "Brazil"
    },
    {
        "id": "6274",
        "AirportCode": "RAP",
        "AirportName": "Rapid City Regional Airport",
        "City": "Rapid City",
        "Country": "USA South Dakota"
    },
    {
        "id": "6275",
        "AirportCode": "RAQ",
        "AirportName": "Sugimanuru Airport",
        "City": "Raha",
        "Country": "Indonesia"
    },
    {
        "id": "6276",
        "AirportCode": "RAR",
        "AirportName": "Rarotonga Airport",
        "City": "Rarotonga",
        "Country": "Cook Islands"
    },
    {
        "id": "6277",
        "AirportCode": "RAS",
        "AirportName": "Rasht Airport",
        "City": "Rasht",
        "Country": "Iran"
    },
    {
        "id": "6278",
        "AirportCode": "RAT",
        "AirportName": "Raduzhnyi Airport",
        "City": "Raduzhnyi",
        "Country": "Russia"
    },
    {
        "id": "6279",
        "AirportCode": "RAU",
        "AirportName": "Rangpur Airport",
        "City": "Rangpur",
        "Country": "Bangladesh"
    },
    {
        "id": "6280",
        "AirportCode": "RAV",
        "AirportName": "Cravo Norte Airport",
        "City": "Cravo Norte",
        "Country": "Colombia"
    },
    {
        "id": "6281",
        "AirportCode": "RAW",
        "AirportName": "Arawa Airport",
        "City": "Arawa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6282",
        "AirportCode": "RAX",
        "AirportName": "Oram Airport",
        "City": "Oram",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6283",
        "AirportCode": "RAY",
        "AirportName": "Rothsay Heliport",
        "City": "Rothsay",
        "Country": "United Kingdom"
    },
    {
        "id": "6284",
        "AirportCode": "RAZ",
        "AirportName": "Rawala Kot Airport",
        "City": "Rawala Kot",
        "Country": "Pakistan"
    },
    {
        "id": "6285",
        "AirportCode": "RBA",
        "AirportName": "Sale Airport",
        "City": "Rabat",
        "Country": "Morocco"
    },
    {
        "id": "6286",
        "AirportCode": "RBB",
        "AirportName": "Borba Airport",
        "City": "Borba",
        "Country": "Brazil"
    },
    {
        "id": "6287",
        "AirportCode": "RBC",
        "AirportName": "Robinvale Airport",
        "City": "Robinvale",
        "Country": "Australia"
    },
    {
        "id": "6288",
        "AirportCode": "RBD",
        "AirportName": "Redbird Airport",
        "City": "Dallas",
        "Country": "USA Texas"
    },
    {
        "id": "6289",
        "AirportCode": "RBE",
        "AirportName": "Ratanakiri Airport",
        "City": "Ratanakiri",
        "Country": "Cambodia"
    },
    {
        "id": "6290",
        "AirportCode": "RBF",
        "AirportName": "Big Bear City Airport",
        "City": "Big Bear",
        "Country": "USA California"
    },
    {
        "id": "6291",
        "AirportCode": "RBG",
        "AirportName": "Roseburg Municipal Airport",
        "City": "Roseburg",
        "Country": "USA Oregon"
    },
    {
        "id": "6292",
        "AirportCode": "RBH",
        "AirportName": "Brooks Lodge Airport",
        "City": "Brooks Lodge",
        "Country": "USA Alaska"
    },
    {
        "id": "6293",
        "AirportCode": "RBI",
        "AirportName": "Rabi Airport",
        "City": "Rabi",
        "Country": "Fiji"
    },
    {
        "id": "6294",
        "AirportCode": "RBJ",
        "AirportName": "Rebun Airport",
        "City": "Rebun",
        "Country": "Japan"
    },
    {
        "id": "6295",
        "AirportCode": "RBK",
        "AirportName": "French Valley Airport",
        "City": "Rancho",
        "Country": "USA California"
    },
    {
        "id": "6296",
        "AirportCode": "RBL",
        "AirportName": "Red Bluff FSS",
        "City": "Red Bluff",
        "Country": "USA California"
    },
    {
        "id": "6297",
        "AirportCode": "RBM",
        "AirportName": "Wallmuhle Airport",
        "City": "Straubing",
        "Country": "Germany"
    },
    {
        "id": "6298",
        "AirportCode": "RBN",
        "AirportName": "Fort Jefferson Airport",
        "City": "Fort Jefferson",
        "Country": "USA Florida"
    },
    {
        "id": "6299",
        "AirportCode": "RBO",
        "AirportName": "Robore Airport",
        "City": "Robore",
        "Country": "Bolivia"
    },
    {
        "id": "6300",
        "AirportCode": "RBP",
        "AirportName": "Rabaraba Airport",
        "City": "Rabaraba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6301",
        "AirportCode": "RBQ",
        "AirportName": "Rurrenabaque Airport",
        "City": "Rurrenabaque",
        "Country": "Bolivia"
    },
    {
        "id": "6302",
        "AirportCode": "RBR",
        "AirportName": "Pres. Medici Airport",
        "City": "Rio Branco",
        "Country": "Brazil"
    },
    {
        "id": "6303",
        "AirportCode": "RBS",
        "AirportName": "Orbost Airport",
        "City": "Orbost",
        "Country": "Australia"
    },
    {
        "id": "6304",
        "AirportCode": "RBT",
        "AirportName": "Marsabit Airport",
        "City": "Marsabit",
        "Country": "Kenya"
    },
    {
        "id": "6305",
        "AirportCode": "RBU",
        "AirportName": "Roebourne Airport",
        "City": "Roebourne",
        "Country": "Australia"
    },
    {
        "id": "6306",
        "AirportCode": "RBV",
        "AirportName": "Ramata Airport",
        "City": "Ramata",
        "Country": "Solomon Islands"
    },
    {
        "id": "6307",
        "AirportCode": "RBW",
        "AirportName": "Walterboro Municipal Airport",
        "City": "Walterboro",
        "Country": "USA South Carolina"
    },
    {
        "id": "6308",
        "AirportCode": "RBY",
        "AirportName": "Ruby Airport",
        "City": "Ruby",
        "Country": "USA Alaska"
    },
    {
        "id": "6309",
        "AirportCode": "RCA",
        "AirportName": "Ellsworth Air Force Base",
        "City": "Rapid City",
        "Country": "USA South Dakota"
    },
    {
        "id": "6310",
        "AirportCode": "RCB",
        "AirportName": "Richards Bay Airport",
        "City": "Richards Bay",
        "Country": "South Africa"
    },
    {
        "id": "6311",
        "AirportCode": "RCE",
        "AirportName": "Roche Harbor Airport",
        "City": "Roche Harbor",
        "Country": "USA Washington"
    },
    {
        "id": "6312",
        "AirportCode": "RCH",
        "AirportName": "Riohacha Airport",
        "City": "Riohacha",
        "Country": "Colombia"
    },
    {
        "id": "6313",
        "AirportCode": "RCK",
        "AirportName": "Coffield Airport",
        "City": "Rockdale",
        "Country": "USA Texas"
    },
    {
        "id": "6314",
        "AirportCode": "RCL",
        "AirportName": "Redcliffe Airport",
        "City": "Redcliffe",
        "Country": "Vanuatu"
    },
    {
        "id": "6315",
        "AirportCode": "RCM",
        "AirportName": "Richmond Airport",
        "City": "Richmond",
        "Country": "Australia"
    },
    {
        "id": "6316",
        "AirportCode": "RCN",
        "AirportName": "American River Airport",
        "City": "American River",
        "Country": "Australia"
    },
    {
        "id": "6317",
        "AirportCode": "RCO",
        "AirportName": "Saint Agnant Airport",
        "City": "Rochefort",
        "Country": "France"
    },
    {
        "id": "6318",
        "AirportCode": "RCP",
        "AirportName": "Cinder Park Airport",
        "City": "Cinder Park",
        "Country": "USA Alaska"
    },
    {
        "id": "6319",
        "AirportCode": "RCQ",
        "AirportName": "Reconquista Airport",
        "City": "Reconquista",
        "Country": "Argentina"
    },
    {
        "id": "6320",
        "AirportCode": "RCR",
        "AirportName": "Fulton County Airport",
        "City": "Rochester",
        "Country": "USA Indiana"
    },
    {
        "id": "6321",
        "AirportCode": "RCS",
        "AirportName": "Rochester Airport",
        "City": "Rochester",
        "Country": "United Kingdom"
    },
    {
        "id": "6322",
        "AirportCode": "RCT",
        "AirportName": "Miller Field",
        "City": "Reed City",
        "Country": "USA Michigan"
    },
    {
        "id": "6323",
        "AirportCode": "RCU",
        "AirportName": "Rio Cuarto Airport",
        "City": "Rio Cuarto",
        "Country": "Argentina"
    },
    {
        "id": "6324",
        "AirportCode": "RDA",
        "AirportName": "Rockhampton Downs Airport",
        "City": "Rockhampton Downs",
        "Country": "Australia"
    },
    {
        "id": "6325",
        "AirportCode": "RDB",
        "AirportName": "Red Dog Airport",
        "City": "Red Dog",
        "Country": "USA Alaska"
    },
    {
        "id": "6326",
        "AirportCode": "RDC",
        "AirportName": "Redencao Airport",
        "City": "Redencao",
        "Country": "Brazil"
    },
    {
        "id": "6327",
        "AirportCode": "RDD",
        "AirportName": "Redding Airport",
        "City": "Redding",
        "Country": "USA California"
    },
    {
        "id": "6328",
        "AirportCode": "RDE",
        "AirportName": "Merdey Airport",
        "City": "Merdey",
        "Country": "Indonesia"
    },
    {
        "id": "6329",
        "AirportCode": "RDG",
        "AirportName": "Reading Municipal Airport/Spaatz Field",
        "City": "Reading",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6330",
        "AirportCode": "RDM",
        "AirportName": "Roberts Field",
        "City": "Redmond",
        "Country": "USA Oregon"
    },
    {
        "id": "6331",
        "AirportCode": "RDN",
        "AirportName": "LTS Pulau Redang Airport",
        "City": "Redang",
        "Country": "Malaysia"
    },
    {
        "id": "6332",
        "AirportCode": "RDR",
        "AirportName": "Grand Forks Air Force Base",
        "City": "Red River",
        "Country": "USA North Dakota"
    },
    {
        "id": "6333",
        "AirportCode": "RDS",
        "AirportName": "Rincon de Los Sauces Airport",
        "City": "Rincon de Los Sauces",
        "Country": "Argentina"
    },
    {
        "id": "6334",
        "AirportCode": "RDT",
        "AirportName": "Richard Toll Airport",
        "City": "Richard Toll",
        "Country": "Senegal"
    },
    {
        "id": "6335",
        "AirportCode": "RDU",
        "AirportName": "Raleigh/Durham Airport",
        "City": "Raleigh/Durham",
        "Country": "USA North Carolina"
    },
    {
        "id": "6336",
        "AirportCode": "RDV",
        "AirportName": "Red Devil Airport",
        "City": "Red Devil",
        "Country": "USA Alaska"
    },
    {
        "id": "6337",
        "AirportCode": "RDZ",
        "AirportName": "Marcillac Airport",
        "City": "Rodez",
        "Country": "France"
    },
    {
        "id": "6338",
        "AirportCode": "REA",
        "AirportName": "Reao Airport",
        "City": "Reao, Tuamoto Island",
        "Country": "French Polynesia"
    },
    {
        "id": "6339",
        "AirportCode": "REB",
        "AirportName": "Rechlin Airport",
        "City": "Rechlin",
        "Country": "Germany"
    },
    {
        "id": "6340",
        "AirportCode": "REC",
        "AirportName": "Guararapes International Airport",
        "City": "Recife",
        "Country": "Brazil"
    },
    {
        "id": "6341",
        "AirportCode": "RED",
        "AirportName": "Mifflin County Airport",
        "City": "Reedsville",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6342",
        "AirportCode": "REE",
        "AirportName": "Reese Air Force Base",
        "City": "Lubbock",
        "Country": "USA Texas"
    },
    {
        "id": "6343",
        "AirportCode": "REG",
        "AirportName": "Tito Menniti Airport",
        "City": "Reggio Calabria",
        "Country": "Italy"
    },
    {
        "id": "6344",
        "AirportCode": "REH",
        "AirportName": "Rehoboth Beach Airport",
        "City": "Rehoboth Beach",
        "Country": "USA Delaware"
    },
    {
        "id": "6345",
        "AirportCode": "REI",
        "AirportName": "Regina Airport",
        "City": "Regina",
        "Country": "French Guiana"
    },
    {
        "id": "6346",
        "AirportCode": "REL",
        "AirportName": "Trelew Airport",
        "City": "Trelew",
        "Country": "Argentina"
    },
    {
        "id": "6347",
        "AirportCode": "REN",
        "AirportName": "Orenburg Airport",
        "City": "Orenburg",
        "Country": "Russia"
    },
    {
        "id": "6348",
        "AirportCode": "REO",
        "AirportName": "State Airport",
        "City": "Rome",
        "Country": "USA Oregon"
    },
    {
        "id": "6349",
        "AirportCode": "REP",
        "AirportName": "Siem Reap Airport",
        "City": "Siem Reap",
        "Country": "Cambodia"
    },
    {
        "id": "6350",
        "AirportCode": "RER",
        "AirportName": "Base Aerea Del Sur Airport",
        "City": "Retalhuleu",
        "Country": "Guatemala"
    },
    {
        "id": "6351",
        "AirportCode": "RES",
        "AirportName": "Resistencia Airport",
        "City": "Resistencia",
        "Country": "Argentina"
    },
    {
        "id": "6352",
        "AirportCode": "RET",
        "AirportName": "Stolport Airport",
        "City": "Rost",
        "Country": "Norway"
    },
    {
        "id": "6353",
        "AirportCode": "REU",
        "AirportName": "Reus Airport",
        "City": "Reus",
        "Country": "Spain"
    },
    {
        "id": "6354",
        "AirportCode": "REW",
        "AirportName": "Rewa Airport",
        "City": "Rewa",
        "Country": "India"
    },
    {
        "id": "6355",
        "AirportCode": "REX",
        "AirportName": "Gen Lucio Blanco Airport",
        "City": "Reynosa",
        "Country": "Mexico"
    },
    {
        "id": "6356",
        "AirportCode": "REY",
        "AirportName": "Reyes Airport",
        "City": "Reyes",
        "Country": "Bolivia"
    },
    {
        "id": "6357",
        "AirportCode": "REZ",
        "AirportName": "Resende Airport",
        "City": "Resende",
        "Country": "Brazil"
    },
    {
        "id": "6358",
        "AirportCode": "RFA",
        "AirportName": "Rafai Airport",
        "City": "Rafai",
        "Country": "Central African Republic"
    },
    {
        "id": "6359",
        "AirportCode": "RFD",
        "AirportName": "Chicago/Rockford International Airport",
        "City": "Rockford",
        "Country": "USA Illinois"
    },
    {
        "id": "6360",
        "AirportCode": "RFG",
        "AirportName": "Rooke Field",
        "City": "Refugio",
        "Country": "USA Texas"
    },
    {
        "id": "6361",
        "AirportCode": "RFK",
        "AirportName": "Rollang Field",
        "City": "Anguilla",
        "Country": "USA Mississippi"
    },
    {
        "id": "6362",
        "AirportCode": "RFN",
        "AirportName": "Raufarhofn Airport",
        "City": "Raufarhofn",
        "Country": "Iceland"
    },
    {
        "id": "6363",
        "AirportCode": "RFP",
        "AirportName": "Raiatea Airport",
        "City": "Raiatea",
        "Country": "French Polynesia"
    },
    {
        "id": "6364",
        "AirportCode": "RFR",
        "AirportName": "Rio Frio Airport",
        "City": "Rio Frio",
        "Country": "Costa Rica"
    },
    {
        "id": "6365",
        "AirportCode": "RFS",
        "AirportName": "Rosita Airport",
        "City": "Rosita",
        "Country": "Nicaragua"
    },
    {
        "id": "6366",
        "AirportCode": "RGA",
        "AirportName": "Rio Grande Airport",
        "City": "Rio Grande",
        "Country": "Argentina"
    },
    {
        "id": "6367",
        "AirportCode": "RGE",
        "AirportName": "Porgera Airport",
        "City": "Porgera",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6368",
        "AirportCode": "RGH",
        "AirportName": "Balurghat Airport",
        "City": "Balurghat",
        "Country": "India"
    },
    {
        "id": "6369",
        "AirportCode": "RGI",
        "AirportName": "Rangiroa Airport",
        "City": "Rangiroa",
        "Country": "French Polynesia"
    },
    {
        "id": "6370",
        "AirportCode": "RGL",
        "AirportName": "Rio Gallegos Internacional Airport",
        "City": "Rio Gallegos",
        "Country": "Argentina"
    },
    {
        "id": "6371",
        "AirportCode": "RGN",
        "AirportName": "Mingaladon Airport",
        "City": "Rangoon",
        "Country": "Burma"
    },
    {
        "id": "6372",
        "AirportCode": "RGR",
        "AirportName": "Ranger Municipal Airport",
        "City": "Ranger",
        "Country": "USA Texas"
    },
    {
        "id": "6373",
        "AirportCode": "RGT",
        "AirportName": "Japura Airport",
        "City": "Rengat",
        "Country": "Indonesia"
    },
    {
        "id": "6374",
        "AirportCode": "RHA",
        "AirportName": "Reykholar Airport",
        "City": "Reykholar",
        "Country": "Iceland"
    },
    {
        "id": "6375",
        "AirportCode": "RHD",
        "AirportName": "Rio Hondo Airport",
        "City": "Rio Hondo",
        "Country": "Argentina"
    },
    {
        "id": "6376",
        "AirportCode": "RHE",
        "AirportName": "Reims Airport",
        "City": "Reims",
        "Country": "France"
    },
    {
        "id": "6377",
        "AirportCode": "RHG",
        "AirportName": "Ruhengeri Airport",
        "City": "Ruhengeri",
        "Country": "Rwanda"
    },
    {
        "id": "6378",
        "AirportCode": "RHI",
        "AirportName": "Oneida County Airport",
        "City": "Rhinelander",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6379",
        "AirportCode": "RHL",
        "AirportName": "Roy Hill Airport",
        "City": "Roy Hill",
        "Country": "Australia"
    },
    {
        "id": "6380",
        "AirportCode": "RHN",
        "AirportName": "Rosh Pina Airport",
        "City": "Rosh Pina",
        "Country": "Namibia"
    },
    {
        "id": "6381",
        "AirportCode": "RHO",
        "AirportName": "Diagoras Airport",
        "City": "Rhodes",
        "Country": "Greece"
    },
    {
        "id": "6382",
        "AirportCode": "RHP",
        "AirportName": "Ramechhap Airport",
        "City": "Ramechhap",
        "Country": "Nepal"
    },
    {
        "id": "6383",
        "AirportCode": "RHV",
        "AirportName": "Reid-Hillview Airport",
        "City": "San Jose",
        "Country": "USA California"
    },
    {
        "id": "6384",
        "AirportCode": "RIA",
        "AirportName": "Base Aerea Airport",
        "City": "Santa Maria",
        "Country": "Brazil"
    },
    {
        "id": "6385",
        "AirportCode": "RIB",
        "AirportName": "Gen Buech Airport",
        "City": "Riberalta",
        "Country": "Bolivia"
    },
    {
        "id": "6386",
        "AirportCode": "RIC",
        "AirportName": "Richmond International Airport (Byrd Field)",
        "City": "Richmond",
        "Country": "USA Virginia"
    },
    {
        "id": "6387",
        "AirportCode": "RID",
        "AirportName": "Richmond Airport",
        "City": "Richmond",
        "Country": "USA Indiana"
    },
    {
        "id": "6388",
        "AirportCode": "RIE",
        "AirportName": "Rice Lake Airport",
        "City": "Rice Lake",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6389",
        "AirportCode": "RIF",
        "AirportName": "Reynolds Airport",
        "City": "Richfield",
        "Country": "USA Utah"
    },
    {
        "id": "6390",
        "AirportCode": "RIG",
        "AirportName": "Rio Grande Airport",
        "City": "Rio Grande",
        "Country": "Brazil"
    },
    {
        "id": "6391",
        "AirportCode": "RIJ",
        "AirportName": "Rioja Airport",
        "City": "Rioja",
        "Country": "Peru"
    },
    {
        "id": "6392",
        "AirportCode": "RIK",
        "AirportName": "Carrillo Airport",
        "City": "Carrillo",
        "Country": "Costa Rica"
    },
    {
        "id": "6393",
        "AirportCode": "RIL",
        "AirportName": "Garfield County Airport",
        "City": "Rifle",
        "Country": "USA Colorado"
    },
    {
        "id": "6394",
        "AirportCode": "RIM",
        "AirportName": "Rodriguez De Mendoza Airport",
        "City": "Rodriguez De Mendoza",
        "Country": "Peru"
    },
    {
        "id": "6395",
        "AirportCode": "RIN",
        "AirportName": "Ringi Cove Airport",
        "City": "Ringi Cove",
        "Country": "Solomon Islands"
    },
    {
        "id": "6396",
        "AirportCode": "RIR",
        "AirportName": "Riverside Fla-Bob Airport",
        "City": "Riverside",
        "Country": "USA California"
    },
    {
        "id": "6397",
        "AirportCode": "RIS",
        "AirportName": "Rishiri Airport",
        "City": "Rishiri",
        "Country": "Japan"
    },
    {
        "id": "6398",
        "AirportCode": "RIT",
        "AirportName": "Rio Tigre Airport",
        "City": "Rio Tigre",
        "Country": "Panama"
    },
    {
        "id": "6399",
        "AirportCode": "RIV",
        "AirportName": "March Air Force Base",
        "City": "Riverside",
        "Country": "USA California"
    },
    {
        "id": "6400",
        "AirportCode": "RIW",
        "AirportName": "Riverton Airport",
        "City": "Riverton",
        "Country": "USA Wyoming"
    },
    {
        "id": "6401",
        "AirportCode": "RIX",
        "AirportName": "Riga Airport",
        "City": "Riga (RIX)",
        "Country": "Latvia"
    },
    {
        "id": "6402",
        "AirportCode": "RIY",
        "AirportName": "Riyan Airport",
        "City": "Riyan",
        "Country": "Yemen"
    },
    {
        "id": "6403",
        "AirportCode": "RIZ",
        "AirportName": "Rio Alzucar Airport",
        "City": "Rio Alzucar",
        "Country": "Panama"
    },
    {
        "id": "6404",
        "AirportCode": "RJA",
        "AirportName": "Rajahmundry Airport",
        "City": "Rajahmundry",
        "Country": "India"
    },
    {
        "id": "6405",
        "AirportCode": "RJB",
        "AirportName": "Rajbiraj Airport",
        "City": "Rajbiraj",
        "Country": "Nepal"
    },
    {
        "id": "6406",
        "AirportCode": "RJH",
        "AirportName": "Rajshahi Airport",
        "City": "Rajshahi",
        "Country": "Bangladesh"
    },
    {
        "id": "6407",
        "AirportCode": "RJI",
        "AirportName": "Rajouri Airport",
        "City": "Rajouri",
        "Country": "India"
    },
    {
        "id": "6408",
        "AirportCode": "RJK",
        "AirportName": "Rijeka Airport",
        "City": "Rijeka",
        "Country": "Croatia"
    },
    {
        "id": "6409",
        "AirportCode": "RJL",
        "AirportName": "Agoncillo Airport",
        "City": "Logrono",
        "Country": "Spain"
    },
    {
        "id": "6410",
        "AirportCode": "RJN",
        "AirportName": "Rafsanjan Airport",
        "City": "Rafsanjan",
        "Country": "Iran"
    },
    {
        "id": "6411",
        "AirportCode": "RKC",
        "AirportName": "Yreka Airport",
        "City": "Yreka",
        "Country": "USA California"
    },
    {
        "id": "6412",
        "AirportCode": "RKD",
        "AirportName": "Knox County Regional Airport",
        "City": "Rockland",
        "Country": "USA Maine"
    },
    {
        "id": "6413",
        "AirportCode": "RKE",
        "AirportName": "Roskilde Airport",
        "City": "Copenhagen",
        "Country": "Denmark"
    },
    {
        "id": "6414",
        "AirportCode": "RKH",
        "AirportName": "Rock Hill Airport",
        "City": "Rock Hill",
        "Country": "USA South Carolina"
    },
    {
        "id": "6415",
        "AirportCode": "RKI",
        "AirportName": "Rokot Airport",
        "City": "Rokot",
        "Country": "Indonesia"
    },
    {
        "id": "6416",
        "AirportCode": "RKO",
        "AirportName": "Sipora Airport",
        "City": "Sipora",
        "Country": "Indonesia"
    },
    {
        "id": "6417",
        "AirportCode": "RKP",
        "AirportName": "Aransas County Airport",
        "City": "Rockport",
        "Country": "USA Texas"
    },
    {
        "id": "6418",
        "AirportCode": "RKR",
        "AirportName": "Robert S. Kerr Airport",
        "City": "Poteau",
        "Country": "USA Oklahoma"
    },
    {
        "id": "6419",
        "AirportCode": "RKS",
        "AirportName": "Sweetwater County Airport",
        "City": "Rock Springs",
        "Country": "USA Wyoming"
    },
    {
        "id": "6420",
        "AirportCode": "RKU",
        "AirportName": "Kairuku Airport",
        "City": "Yule Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6421",
        "AirportCode": "RKV",
        "AirportName": "Reykjavik Domestic Airport",
        "City": "Reykjavik",
        "Country": "Iceland"
    },
    {
        "id": "6422",
        "AirportCode": "RKW",
        "AirportName": "Rockwood Municipal Airport",
        "City": "Rockwood",
        "Country": "USA Tennessee"
    },
    {
        "id": "6423",
        "AirportCode": "RKY",
        "AirportName": "Rokeby Airport",
        "City": "Rokeby",
        "Country": "Australia"
    },
    {
        "id": "6424",
        "AirportCode": "RLA",
        "AirportName": "National Airport",
        "City": "Rolla",
        "Country": "USA Missouri"
    },
    {
        "id": "6425",
        "AirportCode": "RLD",
        "AirportName": "Richland Airport",
        "City": "Richland",
        "Country": "USA Washington"
    },
    {
        "id": "6426",
        "AirportCode": "RLG",
        "AirportName": "Laage Airport",
        "City": "Rostock-Laage",
        "Country": "Germany"
    },
    {
        "id": "6427",
        "AirportCode": "RLI",
        "AirportName": "Reilly Army Heliport",
        "City": "Anniston",
        "Country": "USA Alabama"
    },
    {
        "id": "6428",
        "AirportCode": "RLO",
        "AirportName": "Aeropuerto Internacional Valle del Mercosur S.A.",
        "City": "Merlo",
        "Country": "Argentina"
    },
    {
        "id": "6429",
        "AirportCode": "RLP",
        "AirportName": "Rosella Plains Airport",
        "City": "Rosella Plains",
        "Country": "Australia"
    },
    {
        "id": "6430",
        "AirportCode": "RLT",
        "AirportName": "Arlit Airport",
        "City": "Arlit",
        "Country": "Niger"
    },
    {
        "id": "6431",
        "AirportCode": "RMA",
        "AirportName": "Roma Airport",
        "City": "Roma",
        "Country": "Australia"
    },
    {
        "id": "6432",
        "AirportCode": "RMC",
        "AirportName": "Machesney Airport",
        "City": "Rockford",
        "Country": "USA Illinois"
    },
    {
        "id": "6433",
        "AirportCode": "RMD",
        "AirportName": "Ramagundam Airport",
        "City": "Ramagundam",
        "Country": "India"
    },
    {
        "id": "6434",
        "AirportCode": "RME",
        "AirportName": "Griffiss Air Force Base",
        "City": "Rome",
        "Country": "USA New York"
    },
    {
        "id": "6435",
        "AirportCode": "RMF",
        "AirportName": "Marsa Alam Airport",
        "City": "Marsa Alam",
        "Country": "Egypt"
    },
    {
        "id": "6436",
        "AirportCode": "RMG",
        "AirportName": "Richard B Russell Airport",
        "City": "Rome",
        "Country": "USA Georgia"
    },
    {
        "id": "6437",
        "AirportCode": "RMI",
        "AirportName": "Miramare Airport",
        "City": "Rimini",
        "Country": "Italy"
    },
    {
        "id": "6438",
        "AirportCode": "RMK",
        "AirportName": "Renmark Airport",
        "City": "Renmark",
        "Country": "Australia"
    },
    {
        "id": "6439",
        "AirportCode": "RML",
        "AirportName": "Ratmalana Airport",
        "City": "Colombo",
        "Country": "Sri Lanka"
    },
    {
        "id": "6440",
        "AirportCode": "RMN",
        "AirportName": "Rumginae Airport",
        "City": "Rumginae",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6441",
        "AirportCode": "RMP",
        "AirportName": "Rampart Airport",
        "City": "Rampart",
        "Country": "USA Alaska"
    },
    {
        "id": "6442",
        "AirportCode": "RMQ",
        "AirportName": "Taichung Airport",
        "City": "Shalu",
        "Country": "Taiwan"
    },
    {
        "id": "6443",
        "AirportCode": "RMS",
        "AirportName": "Ramstein Airport",
        "City": "Ramstein",
        "Country": "Germany"
    },
    {
        "id": "6444",
        "AirportCode": "RNA",
        "AirportName": "Ulawa Airport",
        "City": "Arona",
        "Country": "Solomon Islands"
    },
    {
        "id": "6445",
        "AirportCode": "RNB",
        "AirportName": "Kallinge Airport",
        "City": "Ronneby",
        "Country": "Sweden"
    },
    {
        "id": "6446",
        "AirportCode": "RNC",
        "AirportName": "Warren County Airport",
        "City": "McMinnville",
        "Country": "USA Tennessee"
    },
    {
        "id": "6447",
        "AirportCode": "RND",
        "AirportName": "Randolph Air Force Base",
        "City": "San Antonio",
        "Country": "USA Texas"
    },
    {
        "id": "6448",
        "AirportCode": "RNE",
        "AirportName": "Renaison Airport",
        "City": "Roanne",
        "Country": "France"
    },
    {
        "id": "6449",
        "AirportCode": "RNG",
        "AirportName": "Rangely Airport",
        "City": "Rangely",
        "Country": "USA Colorado"
    },
    {
        "id": "6450",
        "AirportCode": "RNH",
        "AirportName": "New Richmond Municipal Airport",
        "City": "New Richmond",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6451",
        "AirportCode": "RNI",
        "AirportName": "Corn Island Airport",
        "City": "Corn Island",
        "Country": "Nicaragua"
    },
    {
        "id": "6452",
        "AirportCode": "RNJ",
        "AirportName": "Yoronjima Airport",
        "City": "Yoronjima",
        "Country": "Japan"
    },
    {
        "id": "6453",
        "AirportCode": "RNL",
        "AirportName": "Rennell Airport",
        "City": "Rennell",
        "Country": "Solomon Islands"
    },
    {
        "id": "6454",
        "AirportCode": "RNN",
        "AirportName": "Bornholm Airport",
        "City": "Bornholm",
        "Country": "Denmark"
    },
    {
        "id": "6455",
        "AirportCode": "RNO",
        "AirportName": "Reno/Tahoe International Airport",
        "City": "Reno",
        "Country": "USA Nevada"
    },
    {
        "id": "6456",
        "AirportCode": "RNP",
        "AirportName": "Rongelap Airport",
        "City": "Rongelap",
        "Country": "Marshall Islands"
    },
    {
        "id": "6457",
        "AirportCode": "RNR",
        "AirportName": "Robinson River Airport",
        "City": "Robinson River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6458",
        "AirportCode": "RNS",
        "AirportName": "St Jacques Airport",
        "City": "Rennes",
        "Country": "France"
    },
    {
        "id": "6459",
        "AirportCode": "RNT",
        "AirportName": "Renton Airport",
        "City": "Renton",
        "Country": "USA Washington"
    },
    {
        "id": "6460",
        "AirportCode": "RNU",
        "AirportName": "Ranau Airport",
        "City": "Ranau",
        "Country": "Malaysia"
    },
    {
        "id": "6461",
        "AirportCode": "RNZ",
        "AirportName": "Rensselaer Airport",
        "City": "Rensselaer",
        "Country": "USA Indiana"
    },
    {
        "id": "6462",
        "AirportCode": "ROA",
        "AirportName": "Roanoke Regional Airport",
        "City": "Roanoke",
        "Country": "USA Virginia"
    },
    {
        "id": "6463",
        "AirportCode": "ROB",
        "AirportName": "Roberts International Airport",
        "City": "Monrovia",
        "Country": "Liberia"
    },
    {
        "id": "6464",
        "AirportCode": "ROC",
        "AirportName": "Greater Rochester International Airport",
        "City": "Rochester",
        "Country": "USA New York"
    },
    {
        "id": "6465",
        "AirportCode": "ROD",
        "AirportName": "Robertson Airport",
        "City": "Robertson",
        "Country": "South Africa"
    },
    {
        "id": "6466",
        "AirportCode": "ROG",
        "AirportName": "Rogers Airport",
        "City": "Rogers",
        "Country": "USA Arkansas"
    },
    {
        "id": "6467",
        "AirportCode": "ROH",
        "AirportName": "Robinhood Airport",
        "City": "Robinhood",
        "Country": "Australia"
    },
    {
        "id": "6468",
        "AirportCode": "ROI",
        "AirportName": "Roi Et Airport",
        "City": "Roi Et",
        "Country": "Thailand"
    },
    {
        "id": "6469",
        "AirportCode": "ROK",
        "AirportName": "Rockhampton Airport",
        "City": "Rockhampton",
        "Country": "Australia"
    },
    {
        "id": "6470",
        "AirportCode": "ROL",
        "AirportName": "Roosevelt Airport",
        "City": "Roosevelt",
        "Country": "USA Utah"
    },
    {
        "id": "6471",
        "AirportCode": "RON",
        "AirportName": "Rondon Airport",
        "City": "Rondon",
        "Country": "Colombia"
    },
    {
        "id": "6472",
        "AirportCode": "ROO",
        "AirportName": "Rondonopolis Airport",
        "City": "Rondonopolis",
        "Country": "Brazil"
    },
    {
        "id": "6473",
        "AirportCode": "ROP",
        "AirportName": "Rota Airport",
        "City": "Rota",
        "Country": "Northern Mariana Islands"
    },
    {
        "id": "6474",
        "AirportCode": "ROR",
        "AirportName": "Airai Airport",
        "City": "Koror",
        "Country": "Palau"
    },
    {
        "id": "6475",
        "AirportCode": "ROS",
        "AirportName": "Fisherton Airport",
        "City": "Rosario",
        "Country": "Argentina"
    },
    {
        "id": "6476",
        "AirportCode": "ROT",
        "AirportName": "Rotorua Airport",
        "City": "Rotorua",
        "Country": "New Zealand"
    },
    {
        "id": "6477",
        "AirportCode": "ROU",
        "AirportName": "Rousse Airport",
        "City": "Rousse",
        "Country": "Bulgaria"
    },
    {
        "id": "6478",
        "AirportCode": "ROV",
        "AirportName": "Rostov Airport",
        "City": "Rostov",
        "Country": "Russia"
    },
    {
        "id": "6479",
        "AirportCode": "ROW",
        "AirportName": "Industrial Airport",
        "City": "Roswell",
        "Country": "USA New Mexico"
    },
    {
        "id": "6480",
        "AirportCode": "ROX",
        "AirportName": "Roseau Municipal Airport",
        "City": "Roseau",
        "Country": "USA Minnesota"
    },
    {
        "id": "6481",
        "AirportCode": "ROY",
        "AirportName": "Rio Mayo Airport",
        "City": "Rio Mayo",
        "Country": "Argentina"
    },
    {
        "id": "6482",
        "AirportCode": "RPA",
        "AirportName": "Rolpa Airport",
        "City": "Rolpa",
        "Country": "Nepal"
    },
    {
        "id": "6483",
        "AirportCode": "RPB",
        "AirportName": "Roper Bar Airport",
        "City": "Roper Bar",
        "Country": "Australia"
    },
    {
        "id": "6484",
        "AirportCode": "RPM",
        "AirportName": "Ngukurr Airport",
        "City": "Ngukurr",
        "Country": "Australia"
    },
    {
        "id": "6485",
        "AirportCode": "RPR",
        "AirportName": "Raipur Airport",
        "City": "Raipur",
        "Country": "India"
    },
    {
        "id": "6486",
        "AirportCode": "RPV",
        "AirportName": "Roper Valley Airport",
        "City": "Roper Valley",
        "Country": "Australia"
    },
    {
        "id": "6487",
        "AirportCode": "RPX",
        "AirportName": "Roundup Airport",
        "City": "Roundup",
        "Country": "USA Montana"
    },
    {
        "id": "6488",
        "AirportCode": "RRA",
        "AirportName": "Ronda Airport",
        "City": "Ronda",
        "Country": "Spain"
    },
    {
        "id": "6489",
        "AirportCode": "RRE",
        "AirportName": "Marree Airport",
        "City": "Marree",
        "Country": "Australia"
    },
    {
        "id": "6490",
        "AirportCode": "RRK",
        "AirportName": "Rourkela Airport",
        "City": "Rourkela",
        "Country": "India"
    },
    {
        "id": "6491",
        "AirportCode": "RRL",
        "AirportName": "Merrill Municipal Airport",
        "City": "Merrill",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6492",
        "AirportCode": "RRM",
        "AirportName": "Marromeu Airport",
        "City": "Marromeu",
        "Country": "Mozambique"
    },
    {
        "id": "6493",
        "AirportCode": "RRN",
        "AirportName": "Serra Do Norte Airport",
        "City": "Serra Do Norte",
        "Country": "Brazil"
    },
    {
        "id": "6494",
        "AirportCode": "RRO",
        "AirportName": "Sorrento Airport",
        "City": "Sorrento",
        "Country": "Italy"
    },
    {
        "id": "6495",
        "AirportCode": "RRS",
        "AirportName": "Roros Airport",
        "City": "Roros",
        "Country": "Norway"
    },
    {
        "id": "6496",
        "AirportCode": "RRT",
        "AirportName": "Warroad Airport",
        "City": "Warroad",
        "Country": "USA Minnesota"
    },
    {
        "id": "6497",
        "AirportCode": "RRV",
        "AirportName": "Robinson River Airport",
        "City": "Robinson River",
        "Country": "Australia"
    },
    {
        "id": "6498",
        "AirportCode": "RSA",
        "AirportName": "Santa Rosa Airport",
        "City": "Santa Rosa",
        "Country": "Argentina"
    },
    {
        "id": "6499",
        "AirportCode": "RSB",
        "AirportName": "Roseberth Airport",
        "City": "Roseberth",
        "Country": "Australia"
    },
    {
        "id": "6500",
        "AirportCode": "RSD",
        "AirportName": "South Eleuthera Airport",
        "City": "Rock Sound",
        "Country": "Bahamas"
    },
    {
        "id": "6501",
        "AirportCode": "RSE",
        "AirportName": "Au-Rose Bay Airport",
        "City": "Sydney",
        "Country": "Australia"
    },
    {
        "id": "6502",
        "AirportCode": "RSG",
        "AirportName": "Serra Pelada Airport",
        "City": "Serra Pelada",
        "Country": "Brazil"
    },
    {
        "id": "6503",
        "AirportCode": "RSH",
        "AirportName": "Russian Sea Plane Base",
        "City": "Russian Mission",
        "Country": "USA Alaska"
    },
    {
        "id": "6504",
        "AirportCode": "RSI",
        "AirportName": "Rio Sidra Airport",
        "City": "Rio Sidra",
        "Country": "Panama"
    },
    {
        "id": "6505",
        "AirportCode": "RSJ",
        "AirportName": "Rosario Sea Plane Base",
        "City": "Rosario",
        "Country": "USA Washington"
    },
    {
        "id": "6506",
        "AirportCode": "RSK",
        "AirportName": "Ransiki Airport",
        "City": "Ransiki",
        "Country": "Indonesia"
    },
    {
        "id": "6507",
        "AirportCode": "RSL",
        "AirportName": "Russell Airport",
        "City": "Russell",
        "Country": "USA Kansas"
    },
    {
        "id": "6508",
        "AirportCode": "RSN",
        "AirportName": "Ruston Airport",
        "City": "Ruston",
        "Country": "USA Louisiana"
    },
    {
        "id": "6509",
        "AirportCode": "RSS",
        "AirportName": "Roseires Airport",
        "City": "Roseires",
        "Country": "Sudan"
    },
    {
        "id": "6510",
        "AirportCode": "RST",
        "AirportName": "Rochester Municipal Airport",
        "City": "Rochester",
        "Country": "USA Minnesota"
    },
    {
        "id": "6511",
        "AirportCode": "RSU",
        "AirportName": "Yeosu Airport",
        "City": "Yeosu",
        "Country": "Korea"
    },
    {
        "id": "6512",
        "AirportCode": "RSW",
        "AirportName": "Southwest Florida International Airport",
        "City": "Fort Myers",
        "Country": "USA Florida"
    },
    {
        "id": "6513",
        "AirportCode": "RSX",
        "AirportName": "Rouses Point Airport",
        "City": "Rouses Point",
        "Country": "USA New York"
    },
    {
        "id": "6514",
        "AirportCode": "RTA",
        "AirportName": "Rotuma Island Airport",
        "City": "Rotuma Island",
        "Country": "Fiji"
    },
    {
        "id": "6515",
        "AirportCode": "RTB",
        "AirportName": "Roatan Airport",
        "City": "Roatan",
        "Country": "Honduras"
    },
    {
        "id": "6516",
        "AirportCode": "RTC",
        "AirportName": "Ratnagiri Airport",
        "City": "Ratnagiri",
        "Country": "India"
    },
    {
        "id": "6517",
        "AirportCode": "RTD",
        "AirportName": "Rotunda Airport",
        "City": "Rotunda",
        "Country": "USA Florida"
    },
    {
        "id": "6518",
        "AirportCode": "RTG",
        "AirportName": "Ruteng Airport",
        "City": "Ruteng",
        "Country": "Indonesia"
    },
    {
        "id": "6519",
        "AirportCode": "RTI",
        "AirportName": "Roti Airport",
        "City": "Roti",
        "Country": "Indonesia"
    },
    {
        "id": "6520",
        "AirportCode": "RTL",
        "AirportName": "Spirit Lake Airport",
        "City": "Spirit Lake",
        "Country": "USA Iowa"
    },
    {
        "id": "6521",
        "AirportCode": "RTM",
        "AirportName": "Rotterdam Airport",
        "City": "Rotterdam",
        "Country": "Netherlands"
    },
    {
        "id": "6522",
        "AirportCode": "RTN",
        "AirportName": "Crews Field",
        "City": "Raton",
        "Country": "USA New Mexico"
    },
    {
        "id": "6523",
        "AirportCode": "RTP",
        "AirportName": "Rutland Plains Airport",
        "City": "Rutland Plains",
        "Country": "Australia"
    },
    {
        "id": "6524",
        "AirportCode": "RTS",
        "AirportName": "Rottnest Island Airport",
        "City": "Rottnest Island",
        "Country": "Australia"
    },
    {
        "id": "6525",
        "AirportCode": "RTW",
        "AirportName": "Saratov Airport",
        "City": "Saratov",
        "Country": "Russia"
    },
    {
        "id": "6526",
        "AirportCode": "RTY",
        "AirportName": "Merty Airport",
        "City": "Merty",
        "Country": "Australia"
    },
    {
        "id": "6527",
        "AirportCode": "RUA",
        "AirportName": "Arua Airport",
        "City": "Arua",
        "Country": "Uganda"
    },
    {
        "id": "6528",
        "AirportCode": "RUF",
        "AirportName": "Yuruf, Irian Jaya Airport",
        "City": "Yuruf, Irian Jaya",
        "Country": "Indonesia"
    },
    {
        "id": "6529",
        "AirportCode": "RUG",
        "AirportName": "Rugao Airport",
        "City": "Rugao",
        "Country": "China"
    },
    {
        "id": "6530",
        "AirportCode": "RUH",
        "AirportName": "King Khaled International Airport",
        "City": "Riyadh",
        "Country": "Saudi Arabia"
    },
    {
        "id": "6531",
        "AirportCode": "RUI",
        "AirportName": "Ruidoso Municipal Airport",
        "City": "Ruidoso",
        "Country": "USA New Mexico"
    },
    {
        "id": "6532",
        "AirportCode": "RUK",
        "AirportName": "Rukumkot Airport",
        "City": "Rukumkot",
        "Country": "Nepal"
    },
    {
        "id": "6533",
        "AirportCode": "RUM",
        "AirportName": "Rumjatar Airport",
        "City": "Rumjatar",
        "Country": "Nepal"
    },
    {
        "id": "6534",
        "AirportCode": "RUN",
        "AirportName": "Gillot Airport",
        "City": "Saint Denis de la Reunion",
        "Country": "Reunion"
    },
    {
        "id": "6535",
        "AirportCode": "RUP",
        "AirportName": "Rupsi Airport",
        "City": "Rupsi",
        "Country": "India"
    },
    {
        "id": "6536",
        "AirportCode": "RUR",
        "AirportName": "Rurutu Airport",
        "City": "Rurutu",
        "Country": "French Polynesia"
    },
    {
        "id": "6537",
        "AirportCode": "RUS",
        "AirportName": "Marau Sound Airport",
        "City": "Marau Sound",
        "Country": "Solomon Islands"
    },
    {
        "id": "6538",
        "AirportCode": "RUT",
        "AirportName": "Rutland Airport",
        "City": "Rutland",
        "Country": "USA Vermont"
    },
    {
        "id": "6539",
        "AirportCode": "RUU",
        "AirportName": "Ruti Airport",
        "City": "Ruti",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6540",
        "AirportCode": "RUV",
        "AirportName": "Rubelsanto Airport",
        "City": "Rubelsanto",
        "Country": "Guatemala"
    },
    {
        "id": "6541",
        "AirportCode": "RUY",
        "AirportName": "Copan Airport",
        "City": "Copan",
        "Country": "Honduras"
    },
    {
        "id": "6542",
        "AirportCode": "RVA",
        "AirportName": "Farafangana Airport",
        "City": "Farafangana",
        "Country": "Madagascar"
    },
    {
        "id": "6543",
        "AirportCode": "RVC",
        "AirportName": "Rivercess Airport",
        "City": "Rivercess",
        "Country": "Liberia"
    },
    {
        "id": "6544",
        "AirportCode": "RVD",
        "AirportName": "Rio Verde Airport",
        "City": "Rio Verde",
        "Country": "Brazil"
    },
    {
        "id": "6545",
        "AirportCode": "RVE",
        "AirportName": "Saravena Airport",
        "City": "Saravena",
        "Country": "Colombia"
    },
    {
        "id": "6546",
        "AirportCode": "RVH",
        "AirportName": "Rzhevka Airport",
        "City": "Saint Petersburg",
        "Country": "Russia"
    },
    {
        "id": "6547",
        "AirportCode": "RVK",
        "AirportName": "Ryumsjoen Airport",
        "City": "Roervik",
        "Country": "Norway"
    },
    {
        "id": "6548",
        "AirportCode": "RVN",
        "AirportName": "Rovaniemi Airport",
        "City": "Rovaniemi",
        "Country": "Finland"
    },
    {
        "id": "6549",
        "AirportCode": "RVO",
        "AirportName": "Reivilo Airport",
        "City": "Reivilo",
        "Country": "South Africa"
    },
    {
        "id": "6550",
        "AirportCode": "RVR",
        "AirportName": "Green River Airport",
        "City": "Green River",
        "Country": "USA Utah"
    },
    {
        "id": "6551",
        "AirportCode": "RVS",
        "AirportName": "R. Lloyd Jones Airport",
        "City": "Tulsa",
        "Country": "USA Oklahoma"
    },
    {
        "id": "6552",
        "AirportCode": "RVT",
        "AirportName": "Ravensthorpe Airport",
        "City": "Ravensthorpe",
        "Country": "Australia"
    },
    {
        "id": "6553",
        "AirportCode": "RVV",
        "AirportName": "Raivavae Airport",
        "City": "Rairua",
        "Country": "French Polynesia"
    },
    {
        "id": "6554",
        "AirportCode": "RVY",
        "AirportName": "Rivera Airport",
        "City": "Rivera",
        "Country": "Uruguay"
    },
    {
        "id": "6555",
        "AirportCode": "RWF",
        "AirportName": "Redwood Falls Municipal Airport",
        "City": "Redwood Falls",
        "Country": "USA Minnesota"
    },
    {
        "id": "6556",
        "AirportCode": "RWI",
        "AirportName": "Rocky Mount-Wilson Airport",
        "City": "Rocky Mount/Wilson",
        "Country": "USA North Carolina"
    },
    {
        "id": "6557",
        "AirportCode": "RWL",
        "AirportName": "Rawlins Airport",
        "City": "Rawlins",
        "Country": "USA Wyoming"
    },
    {
        "id": "6558",
        "AirportCode": "RWN",
        "AirportName": "Rovno Airport",
        "City": "Rovno",
        "Country": "Ukraine"
    },
    {
        "id": "6559",
        "AirportCode": "RWS",
        "AirportName": "Sumare Airport",
        "City": "Sumare",
        "Country": "Brazil"
    },
    {
        "id": "6560",
        "AirportCode": "RXA",
        "AirportName": "Raudha Airport",
        "City": "Raudha",
        "Country": "Yemen"
    },
    {
        "id": "6561",
        "AirportCode": "RXS",
        "AirportName": "Roxas City Airport",
        "City": "Roxas City",
        "Country": "Philippines"
    },
    {
        "id": "6562",
        "AirportCode": "RYB",
        "AirportName": "Rybinsk Airport",
        "City": "Rybinsk",
        "Country": "Russia"
    },
    {
        "id": "6563",
        "AirportCode": "RYG",
        "AirportName": "Moss-Rygge Airport",
        "City": "Rygge",
        "Country": "Norway"
    },
    {
        "id": "6564",
        "AirportCode": "RYG",
        "AirportName": "Rygge Ab",
        "City": "Rygge",
        "Country": "Norway"
    },
    {
        "id": "6565",
        "AirportCode": "RYK",
        "AirportName": "Rahim Yar Khan Airport",
        "City": "Rahim Yar Khan",
        "Country": "Pakistan"
    },
    {
        "id": "6566",
        "AirportCode": "RYN",
        "AirportName": "Medis Airport",
        "City": "Royan",
        "Country": "France"
    },
    {
        "id": "6567",
        "AirportCode": "RYO",
        "AirportName": "Rio Turbio Airport",
        "City": "Rio Turbio",
        "Country": "Argentina"
    },
    {
        "id": "6568",
        "AirportCode": "RZA",
        "AirportName": "Santa Cruz Airport",
        "City": "Santa Cruz",
        "Country": "Argentina"
    },
    {
        "id": "6569",
        "AirportCode": "RZE",
        "AirportName": "Jasionka Airport",
        "City": "Rzeszow",
        "Country": "Poland"
    },
    {
        "id": "6570",
        "AirportCode": "RZH",
        "AirportName": "Quartz Hill Airport",
        "City": "Lancaster",
        "Country": "USA California"
    },
    {
        "id": "6571",
        "AirportCode": "RZN",
        "AirportName": "Ryazan Airport",
        "City": "Ryazan",
        "Country": "Russia"
    },
    {
        "id": "6572",
        "AirportCode": "RZP",
        "AirportName": "CLR Airport",
        "City": "Taytay Sandoval",
        "Country": "Philippines"
    },
    {
        "id": "6573",
        "AirportCode": "RZR",
        "AirportName": "Ramsar Airport",
        "City": "Ramsar",
        "Country": "Iran"
    },
    {
        "id": "6574",
        "AirportCode": "RZS",
        "AirportName": "Sawan Airport",
        "City": "Sawan",
        "Country": "Pakistan"
    },
    {
        "id": "6575",
        "AirportCode": "RZY",
        "AirportName": "Rezayieh Airport",
        "City": "Rezayieh",
        "Country": "Iran"
    },
    {
        "id": "6576",
        "AirportCode": "RZZ",
        "AirportName": "Halifax County Airport",
        "City": "Roanoke Rapids",
        "Country": "USA North Carolina"
    },
    {
        "id": "6577",
        "AirportCode": "SAA",
        "AirportName": "Shively Airport",
        "City": "Saratoga",
        "Country": "USA Wyoming"
    },
    {
        "id": "6578",
        "AirportCode": "SAB",
        "AirportName": "J. Yrausquin Airport",
        "City": "Saba",
        "Country": "Netherlands Antilles"
    },
    {
        "id": "6579",
        "AirportCode": "SAC",
        "AirportName": "Executive Airport",
        "City": "Sacramento",
        "Country": "USA California"
    },
    {
        "id": "6580",
        "AirportCode": "SAD",
        "AirportName": "Safford Airport",
        "City": "Safford",
        "Country": "USA Arizona"
    },
    {
        "id": "6581",
        "AirportCode": "SAE",
        "AirportName": "Sangir Airport",
        "City": "Sangir",
        "Country": "Indonesia"
    },
    {
        "id": "6582",
        "AirportCode": "SAF",
        "AirportName": "Santa Fe Airport",
        "City": "Santa Fe",
        "Country": "USA New Mexico"
    },
    {
        "id": "6583",
        "AirportCode": "SAI",
        "AirportName": "San Marino Airport",
        "City": "San Marino",
        "Country": "San Marino"
    },
    {
        "id": "6584",
        "AirportCode": "SAJ",
        "AirportName": "Sirajganj Airport",
        "City": "Sirajganj",
        "Country": "Bangladesh"
    },
    {
        "id": "6585",
        "AirportCode": "SAK",
        "AirportName": "Saudarkrokur Airport",
        "City": "Saudarkrokur",
        "Country": "Iceland"
    },
    {
        "id": "6586",
        "AirportCode": "SAL",
        "AirportName": "Comalapa International Airport",
        "City": "San Salvador",
        "Country": "El Salvador"
    },
    {
        "id": "6587",
        "AirportCode": "SAL",
        "AirportName": "Comalapa International Airport",
        "City": "San Salvador",
        "Country": "El Salvador"
    },
    {
        "id": "6588",
        "AirportCode": "SAM",
        "AirportName": "Salamo Airport",
        "City": "Salamo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6589",
        "AirportCode": "SAN",
        "AirportName": "San Diego International Airport",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "6590",
        "AirportCode": "SAP",
        "AirportName": "Ramon Villeda Morales Airport",
        "City": "San Pedro Sula",
        "Country": "Honduras"
    },
    {
        "id": "6591",
        "AirportCode": "SAQ",
        "AirportName": "San Andros Airport",
        "City": "San Andros",
        "Country": "Bahamas"
    },
    {
        "id": "6592",
        "AirportCode": "SAR",
        "AirportName": "Sparta Community Airport",
        "City": "Sparta",
        "Country": "USA Illinois"
    },
    {
        "id": "6593",
        "AirportCode": "SAS",
        "AirportName": "Salton City Airport",
        "City": "Salton City",
        "Country": "USA California"
    },
    {
        "id": "6594",
        "AirportCode": "SAT",
        "AirportName": "San Antonio International Airport",
        "City": "San Antonio",
        "Country": "USA Texas"
    },
    {
        "id": "6595",
        "AirportCode": "SAU",
        "AirportName": "Sawu Airport",
        "City": "Sawu",
        "Country": "Indonesia"
    },
    {
        "id": "6596",
        "AirportCode": "SAV",
        "AirportName": "Savannah/Hilton Head Airport",
        "City": "Savannah",
        "Country": "USA Georgia"
    },
    {
        "id": "6597",
        "AirportCode": "SAW",
        "AirportName": "Sawyer International",
        "City": "Marquette",
        "Country": "USA Michigan"
    },
    {
        "id": "6598",
        "AirportCode": "SAW",
        "AirportName": "Sabiha Gokcen",
        "City": "Sabiha Gokcen",
        "Country": "Turkey"
    },
    {
        "id": "6599",
        "AirportCode": "SAX",
        "AirportName": "Sambu Airport",
        "City": "Sambu",
        "Country": "Panama"
    },
    {
        "id": "6600",
        "AirportCode": "SAY",
        "AirportName": "Siena Airport",
        "City": "Siena",
        "Country": "Italy"
    },
    {
        "id": "6601",
        "AirportCode": "SAZ",
        "AirportName": "Sasstown Airport",
        "City": "Sasstown",
        "Country": "Liberia"
    },
    {
        "id": "6602",
        "AirportCode": "SBA",
        "AirportName": "Santa Barbara Municipal Airport",
        "City": "Santa Barbara",
        "Country": "USA California"
    },
    {
        "id": "6603",
        "AirportCode": "SBB",
        "AirportName": "Santa Barbara Ba Airport",
        "City": "Santa Barbara Ba",
        "Country": "Venezuela"
    },
    {
        "id": "6604",
        "AirportCode": "SBC",
        "AirportName": "Selbang Airport",
        "City": "Selbang",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6605",
        "AirportCode": "SBD",
        "AirportName": "Norton Air Force Base",
        "City": "San Bernardino",
        "Country": "USA California"
    },
    {
        "id": "6606",
        "AirportCode": "SBE",
        "AirportName": "Suabi Airport",
        "City": "Suabi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6607",
        "AirportCode": "SBF",
        "AirportName": "Sardeh Band Airport",
        "City": "Sardeh Band",
        "Country": "Afghanistan"
    },
    {
        "id": "6608",
        "AirportCode": "SBG",
        "AirportName": "Sabang Airport",
        "City": "Sabang",
        "Country": "Indonesia"
    },
    {
        "id": "6609",
        "AirportCode": "SBH",
        "AirportName": "St. Barthelemy Airport",
        "City": "Saint Barthelemy",
        "Country": "Guadeloupe"
    },
    {
        "id": "6610",
        "AirportCode": "SBI",
        "AirportName": "Sambailo Airport",
        "City": "Koundara",
        "Country": "Guinea"
    },
    {
        "id": "6611",
        "AirportCode": "SBJ",
        "AirportName": "Sao Mateus Airport",
        "City": "Sao Mateus",
        "Country": "Brazil"
    },
    {
        "id": "6612",
        "AirportCode": "SBK",
        "AirportName": "Tremuson Airport",
        "City": "Saint Brieuc",
        "Country": "France"
    },
    {
        "id": "6613",
        "AirportCode": "SBL",
        "AirportName": "Yacuma Airport",
        "City": "Santa Ana",
        "Country": "Bolivia"
    },
    {
        "id": "6614",
        "AirportCode": "SBM",
        "AirportName": "Sheboygan Memorial Airport",
        "City": "Sheboygan",
        "Country": "USA Wisconsin"
    },
    {
        "id": "6615",
        "AirportCode": "SBN",
        "AirportName": "South Bend Regional",
        "City": "South Bend",
        "Country": "USA Indiana"
    },
    {
        "id": "6616",
        "AirportCode": "SBO",
        "AirportName": "Salina Airport",
        "City": "Salina",
        "Country": "USA Utah"
    },
    {
        "id": "6617",
        "AirportCode": "SBP",
        "AirportName": "County Airport",
        "City": "San Luis Obispo",
        "Country": "USA California"
    },
    {
        "id": "6618",
        "AirportCode": "SBQ",
        "AirportName": "Sibi Airport",
        "City": "Sibi",
        "Country": "Pakistan"
    },
    {
        "id": "6619",
        "AirportCode": "SBR",
        "AirportName": "Saibai Island Airport",
        "City": "Saibai Island",
        "Country": "Australia"
    },
    {
        "id": "6620",
        "AirportCode": "SBS",
        "AirportName": "Steamboat Springs Airport",
        "City": "Steamboat Springs",
        "Country": "USA Colorado"
    },
    {
        "id": "6621",
        "AirportCode": "SBT",
        "AirportName": "Tri-City Airport",
        "City": "San Bernardino",
        "Country": "USA California"
    },
    {
        "id": "6622",
        "AirportCode": "SBU",
        "AirportName": "Springbok Airport",
        "City": "Springbok",
        "Country": "South Africa"
    },
    {
        "id": "6623",
        "AirportCode": "SBV",
        "AirportName": "Sabah Airport",
        "City": "Sabah",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6624",
        "AirportCode": "SBW",
        "AirportName": "Sibu Airport",
        "City": "Sibu",
        "Country": "Malaysia"
    },
    {
        "id": "6625",
        "AirportCode": "SBX",
        "AirportName": "Shelby Airport",
        "City": "Shelby",
        "Country": "USA Montana"
    },
    {
        "id": "6626",
        "AirportCode": "SBY",
        "AirportName": "Wicomico County Airport",
        "City": "Salisbury-Ocean City",
        "Country": "USA Maryland"
    },
    {
        "id": "6627",
        "AirportCode": "SBZ",
        "AirportName": "Sibiu Airport",
        "City": "Sibiu",
        "Country": "Romania"
    },
    {
        "id": "6628",
        "AirportCode": "SCA",
        "AirportName": "Santa Catalina Airport",
        "City": "Santa Catalina",
        "Country": "Colombia"
    },
    {
        "id": "6629",
        "AirportCode": "SCB",
        "AirportName": "State Airport",
        "City": "Scribner",
        "Country": "USA Nebraska"
    },
    {
        "id": "6630",
        "AirportCode": "SCC",
        "AirportName": "Prudhoe Bay/Deadhorse Airport",
        "City": "Prudhoe Bay/Deadhorse",
        "Country": "USA Alaska"
    },
    {
        "id": "6631",
        "AirportCode": "SCD",
        "AirportName": "Sulaco Airport",
        "City": "Sulaco",
        "Country": "Honduras"
    },
    {
        "id": "6632",
        "AirportCode": "SCE",
        "AirportName": "University Park Airport",
        "City": "State College",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6633",
        "AirportCode": "SCF",
        "AirportName": "Scottsdale Municipal Airport",
        "City": "Phoenix",
        "Country": "USA Arizona"
    },
    {
        "id": "6634",
        "AirportCode": "SCG",
        "AirportName": "Spring Creek Airport",
        "City": "Spring Creek",
        "Country": "Australia"
    },
    {
        "id": "6635",
        "AirportCode": "SCH",
        "AirportName": "County Airport",
        "City": "Schenectady",
        "Country": "USA New York"
    },
    {
        "id": "6636",
        "AirportCode": "SCI",
        "AirportName": "San Cristobal Airport",
        "City": "San Cristobal",
        "Country": "Venezuela"
    },
    {
        "id": "6637",
        "AirportCode": "SCJ",
        "AirportName": "Smith Cove Airport",
        "City": "Smith Cove",
        "Country": "USA Alaska"
    },
    {
        "id": "6638",
        "AirportCode": "SCK",
        "AirportName": "Stockton Airport",
        "City": "Stockton",
        "Country": "USA California"
    },
    {
        "id": "6639",
        "AirportCode": "SCL",
        "AirportName": "Arturo Merino Benitez Airport",
        "City": "Santiago",
        "Country": "Chile"
    },
    {
        "id": "6640",
        "AirportCode": "SCM",
        "AirportName": "Scammon Bay Sea Plane Base",
        "City": "Scammon Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "6641",
        "AirportCode": "SCN",
        "AirportName": "Ensheim Airport",
        "City": "Saarbruecken",
        "Country": "Germany"
    },
    {
        "id": "6642",
        "AirportCode": "SCO",
        "AirportName": "Aktau Airport",
        "City": "Aktau",
        "Country": "Kazakhstan"
    },
    {
        "id": "6643",
        "AirportCode": "SCP",
        "AirportName": "Saint Crepin Airport",
        "City": "Saint Crepin",
        "Country": "France"
    },
    {
        "id": "6644",
        "AirportCode": "SCQ",
        "AirportName": "Santiago De Compostela Airport",
        "City": "Santiago De Compostela",
        "Country": "Spain"
    },
    {
        "id": "6645",
        "AirportCode": "SCR",
        "AirportName": "Scranton Municipal Airport",
        "City": "Scranton",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6646",
        "AirportCode": "SCS",
        "AirportName": "Scatsta Airport",
        "City": "Shetland Islands",
        "Country": "United Kingdom"
    },
    {
        "id": "6647",
        "AirportCode": "SCT",
        "AirportName": "Socotra Airport",
        "City": "Socotra",
        "Country": "Yemen"
    },
    {
        "id": "6648",
        "AirportCode": "SCU",
        "AirportName": "Antonio Maceo Airport",
        "City": "Santiago",
        "Country": "Cuba"
    },
    {
        "id": "6649",
        "AirportCode": "SCV",
        "AirportName": "Salcea Airport",
        "City": "Suceava",
        "Country": "Romania"
    },
    {
        "id": "6650",
        "AirportCode": "SCW",
        "AirportName": "Syktyvkar Airport",
        "City": "Syktyvkar",
        "Country": "Russia"
    },
    {
        "id": "6651",
        "AirportCode": "SCX",
        "AirportName": "Salina Cruz Airport",
        "City": "Salina Cruz",
        "Country": "Mexico"
    },
    {
        "id": "6652",
        "AirportCode": "SCY",
        "AirportName": "San Cristobal Airport",
        "City": "San Cristobal",
        "Country": "Ecuador"
    },
    {
        "id": "6653",
        "AirportCode": "SCZ",
        "AirportName": "Santa Cruz Island Airport",
        "City": "Santa Cruz Island",
        "Country": "Solomon Islands"
    },
    {
        "id": "6654",
        "AirportCode": "SDA",
        "AirportName": "Baghdad International Airport",
        "City": "Baghdad",
        "Country": "Iraq"
    },
    {
        "id": "6655",
        "AirportCode": "SDB",
        "AirportName": "Langebaanweg Airport",
        "City": "Saldanha Bay",
        "Country": "South Africa"
    },
    {
        "id": "6656",
        "AirportCode": "SDC",
        "AirportName": "Sandcreek Airport",
        "City": "Sandcreek",
        "Country": "Guyana"
    },
    {
        "id": "6657",
        "AirportCode": "SDD",
        "AirportName": "Lubango Airport",
        "City": "Lubango",
        "Country": "Angola"
    },
    {
        "id": "6658",
        "AirportCode": "SDE",
        "AirportName": "Santiago Del Estero Airport",
        "City": "Santiago Del Estero",
        "Country": "Argentina"
    },
    {
        "id": "6659",
        "AirportCode": "SDF",
        "AirportName": "Louisville International Airport",
        "City": "Louisville",
        "Country": "USA Kentucky"
    },
    {
        "id": "6660",
        "AirportCode": "SDG",
        "AirportName": "Sanandaj Airport",
        "City": "Sanandaj",
        "Country": "Iran"
    },
    {
        "id": "6661",
        "AirportCode": "SDH",
        "AirportName": "Santa Rosa De Copan Airport",
        "City": "Santa Rosa De Copan",
        "Country": "Honduras"
    },
    {
        "id": "6662",
        "AirportCode": "SDI",
        "AirportName": "Saidor Airport",
        "City": "Saidor",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6663",
        "AirportCode": "SDJ",
        "AirportName": "Sendai Airport",
        "City": "Sendai",
        "Country": "Japan"
    },
    {
        "id": "6664",
        "AirportCode": "SDK",
        "AirportName": "Sandakan Airport",
        "City": "Sandakan",
        "Country": "Malaysia"
    },
    {
        "id": "6665",
        "AirportCode": "SDL",
        "AirportName": "Sundsvall/Harnosand Airport",
        "City": "Sundsvall",
        "Country": "Sweden"
    },
    {
        "id": "6666",
        "AirportCode": "SDM",
        "AirportName": "Brown Field Municipal Airport",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "6667",
        "AirportCode": "SDN",
        "AirportName": "Sandane Airport",
        "City": "Sandane",
        "Country": "Norway"
    },
    {
        "id": "6668",
        "AirportCode": "SDO",
        "AirportName": "Ryotsu Sado Island Airport",
        "City": "Ryotsu Sado Island",
        "Country": "Japan"
    },
    {
        "id": "6669",
        "AirportCode": "SDP",
        "AirportName": "Sand Point Municipal Airport",
        "City": "Sand Point",
        "Country": "USA Alaska"
    },
    {
        "id": "6670",
        "AirportCode": "SDQ",
        "AirportName": "Las Americas Airport",
        "City": "Santo Domingo",
        "Country": "Dominican Republic"
    },
    {
        "id": "6671",
        "AirportCode": "SDR",
        "AirportName": "Santander Airport",
        "City": "Santander",
        "Country": "Spain"
    },
    {
        "id": "6672",
        "AirportCode": "SDS",
        "AirportName": "Sado Shima Airport",
        "City": "Sado Shima",
        "Country": "Japan"
    },
    {
        "id": "6673",
        "AirportCode": "SDT",
        "AirportName": "Saidu Sharif Airport",
        "City": "Saidu Sharif",
        "Country": "Pakistan"
    },
    {
        "id": "6674",
        "AirportCode": "SDU",
        "AirportName": "Santos Dumont Airport",
        "City": "Rio De Janeiro",
        "Country": "Brazil"
    },
    {
        "id": "6675",
        "AirportCode": "SDW",
        "AirportName": "Sandwip Airport",
        "City": "Sandwip",
        "Country": "Bangladesh"
    },
    {
        "id": "6676",
        "AirportCode": "SDX",
        "AirportName": "Sedona Airport",
        "City": "Sedona",
        "Country": "USA Arizona"
    },
    {
        "id": "6677",
        "AirportCode": "SDY",
        "AirportName": "Richland Municipal Airport",
        "City": "Sidney",
        "Country": "USA Montana"
    },
    {
        "id": "6678",
        "AirportCode": "SEA",
        "AirportName": "Seattle/Tacoma International Airport",
        "City": "Seattle",
        "Country": "USA Washington"
    },
    {
        "id": "6679",
        "AirportCode": "SEB",
        "AirportName": "Sebha Airport",
        "City": "Sebha",
        "Country": "Libya"
    },
    {
        "id": "6680",
        "AirportCode": "SEC",
        "AirportName": "Serre Chevalier Airport",
        "City": "Serre Chevalier",
        "Country": "France"
    },
    {
        "id": "6681",
        "AirportCode": "SEE",
        "AirportName": "Gillespie Field",
        "City": "San Diego",
        "Country": "USA California"
    },
    {
        "id": "6682",
        "AirportCode": "SEF",
        "AirportName": "Sebring Air Terminal",
        "City": "Sebring",
        "Country": "USA Florida"
    },
    {
        "id": "6683",
        "AirportCode": "SEG",
        "AirportName": "Penn Valley Airport",
        "City": "Selinsgrove",
        "Country": "USA Philadelphia"
    },
    {
        "id": "6684",
        "AirportCode": "SEH",
        "AirportName": "Senggeh Airport",
        "City": "Senggeh",
        "Country": "Indonesia"
    },
    {
        "id": "6685",
        "AirportCode": "SEI",
        "AirportName": "Senhor Do Bonfim Airport",
        "City": "Senhor Do Bonfim",
        "Country": "Brazil"
    },
    {
        "id": "6686",
        "AirportCode": "SEJ",
        "AirportName": "Seydisfjordur Airport",
        "City": "Seydisfjordur",
        "Country": "Iceland"
    },
    {
        "id": "6687",
        "AirportCode": "SEK",
        "AirportName": "Ksar Es Souk Airport",
        "City": "Ksar Es Souk",
        "Country": "Morocco"
    },
    {
        "id": "6688",
        "AirportCode": "SEM",
        "AirportName": "Craig Air Force Base",
        "City": "Selma",
        "Country": "USA Alabama"
    },
    {
        "id": "6689",
        "AirportCode": "SEN",
        "AirportName": "Southend Municipal Airport",
        "City": "Southend",
        "Country": "United Kingdom"
    },
    {
        "id": "6690",
        "AirportCode": "SEP",
        "AirportName": "Clark Field",
        "City": "Stephenville",
        "Country": "USA Texas"
    },
    {
        "id": "6691",
        "AirportCode": "SEQ",
        "AirportName": "Sungaipinang Airport",
        "City": "Sungaipinang",
        "Country": "Indonesia"
    },
    {
        "id": "6692",
        "AirportCode": "SER",
        "AirportName": "Freeman Municipal Airport",
        "City": "Seymour",
        "Country": "USA Indiana"
    },
    {
        "id": "6693",
        "AirportCode": "SES",
        "AirportName": "Selfield Airport",
        "City": "Selma",
        "Country": "USA Alabama"
    },
    {
        "id": "6694",
        "AirportCode": "SET",
        "AirportName": "San Esteban Airport",
        "City": "San Esteban",
        "Country": "Honduras"
    },
    {
        "id": "6695",
        "AirportCode": "SEU",
        "AirportName": "Seronera Airport",
        "City": "Seronera",
        "Country": "Tanzania"
    },
    {
        "id": "6696",
        "AirportCode": "SEV",
        "AirportName": "Severodoneck Airport",
        "City": "Severodoneck",
        "Country": "Ukraine"
    },
    {
        "id": "6697",
        "AirportCode": "SEW",
        "AirportName": "Siwa Airport",
        "City": "Siwa",
        "Country": "Egypt"
    },
    {
        "id": "6698",
        "AirportCode": "SEX",
        "AirportName": "Sembach Airport",
        "City": "Sembach",
        "Country": "Germany"
    },
    {
        "id": "6699",
        "AirportCode": "SEY",
        "AirportName": "Selibaby Airport",
        "City": "Selibaby",
        "Country": "Mauritania"
    },
    {
        "id": "6700",
        "AirportCode": "SEZ",
        "AirportName": "Seychelles International Airport",
        "City": "Mahe Island",
        "Country": "Seychelles"
    },
    {
        "id": "6701",
        "AirportCode": "SFA",
        "AirportName": "Sfax El Maou Airport",
        "City": "Sfax",
        "Country": "Tunisia"
    },
    {
        "id": "6702",
        "AirportCode": "SFB",
        "AirportName": "Orlando Sanford International Airport",
        "City": "Orlando",
        "Country": "USA Florida"
    },
    {
        "id": "6703",
        "AirportCode": "SFC",
        "AirportName": "Saint Francois Airport",
        "City": "Saint Francois",
        "Country": "Guadeloupe"
    },
    {
        "id": "6704",
        "AirportCode": "SFD",
        "AirportName": "Las Flecheras Airport",
        "City": "San Fernando De Apure",
        "Country": "Venezuela"
    },
    {
        "id": "6705",
        "AirportCode": "SFE",
        "AirportName": "San Fernando Airport",
        "City": "San Fernando",
        "Country": "Philippines"
    },
    {
        "id": "6706",
        "AirportCode": "SFF",
        "AirportName": "Felts Field",
        "City": "Spokane",
        "Country": "USA Washington"
    },
    {
        "id": "6707",
        "AirportCode": "SFG",
        "AirportName": "Esperance Airport",
        "City": "Saint Martin",
        "Country": "Guadeloupe"
    },
    {
        "id": "6708",
        "AirportCode": "SFH",
        "AirportName": "San Felipe Airport",
        "City": "San Felipe",
        "Country": "Mexico"
    },
    {
        "id": "6709",
        "AirportCode": "SFI",
        "AirportName": "Safi Airport",
        "City": "Safi",
        "Country": "Morocco"
    },
    {
        "id": "6710",
        "AirportCode": "SFJ",
        "AirportName": "Kangerlussuaq Airport",
        "City": "Kangerlussuaq",
        "Country": "Greenland"
    },
    {
        "id": "6711",
        "AirportCode": "SFK",
        "AirportName": "Soure Airport",
        "City": "Soure",
        "Country": "Brazil"
    },
    {
        "id": "6712",
        "AirportCode": "SFL",
        "AirportName": "Sao Filipe Airport",
        "City": "Sao Filipe",
        "Country": "Cape Verde"
    },
    {
        "id": "6713",
        "AirportCode": "SFM",
        "AirportName": "Sanford Airport",
        "City": "Sanford",
        "Country": "USA Maine"
    },
    {
        "id": "6714",
        "AirportCode": "SFN",
        "AirportName": "Santa Fe Airport",
        "City": "Santa Fe",
        "Country": "Argentina"
    },
    {
        "id": "6715",
        "AirportCode": "SFO",
        "AirportName": "SFO International Airport",
        "City": "San Francisco",
        "Country": "USA California"
    },
    {
        "id": "6716",
        "AirportCode": "SFP",
        "AirportName": "Surfers Paradise Airport",
        "City": "Surfers Paradise",
        "Country": "Australia"
    },
    {
        "id": "6717",
        "AirportCode": "SFQ",
        "AirportName": "Sanliurfa Airport",
        "City": "Sanliurfa",
        "Country": "Turkey"
    },
    {
        "id": "6718",
        "AirportCode": "SFR",
        "AirportName": "San Fernando Airport",
        "City": "San Fernando",
        "Country": "USA California"
    },
    {
        "id": "6719",
        "AirportCode": "SFS",
        "AirportName": "Subic Bay International Airport",
        "City": "Subic Bay",
        "Country": "Philippines"
    },
    {
        "id": "6720",
        "AirportCode": "SFT",
        "AirportName": "Skelleftea Airport",
        "City": "Skelleftea",
        "Country": "Sweden"
    },
    {
        "id": "6721",
        "AirportCode": "SFU",
        "AirportName": "Safia Airport",
        "City": "Safia",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6722",
        "AirportCode": "SFV",
        "AirportName": "Santa Fe Do Sul Airport",
        "City": "Santa Fe Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "6723",
        "AirportCode": "SFW",
        "AirportName": "Santa Fe Airport",
        "City": "Santa Fe",
        "Country": "Panama"
    },
    {
        "id": "6724",
        "AirportCode": "SFX",
        "AirportName": "San Felix Airport",
        "City": "San Felix",
        "Country": "Venezuela"
    },
    {
        "id": "6725",
        "AirportCode": "SFZ",
        "AirportName": "North Central Airport",
        "City": "Smithfield",
        "Country": "USA Rhode Island"
    },
    {
        "id": "6726",
        "AirportCode": "SGA",
        "AirportName": "Sheghnan Airport",
        "City": "Sheghnan",
        "Country": "Afghanistan"
    },
    {
        "id": "6727",
        "AirportCode": "SGB",
        "AirportName": "Singaua Airport",
        "City": "Singaua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6728",
        "AirportCode": "SGC",
        "AirportName": "Surgut Airport",
        "City": "Surgut",
        "Country": "Russia"
    },
    {
        "id": "6729",
        "AirportCode": "SGD",
        "AirportName": "Sonderborg Airport",
        "City": "Sonderborg",
        "Country": "Denmark"
    },
    {
        "id": "6730",
        "AirportCode": "SGE",
        "AirportName": "Siegerland Airport",
        "City": "Siegen",
        "Country": "Germany"
    },
    {
        "id": "6731",
        "AirportCode": "SGF",
        "AirportName": "Springfield-Branson Regional Airport",
        "City": "Springfield",
        "Country": "USA Missouri"
    },
    {
        "id": "6732",
        "AirportCode": "SGG",
        "AirportName": "Simanggang Airport",
        "City": "Simanggang",
        "Country": "Malaysia"
    },
    {
        "id": "6733",
        "AirportCode": "SGH",
        "AirportName": "Springfield Airport",
        "City": "Springfield",
        "Country": "USA Ohio"
    },
    {
        "id": "6734",
        "AirportCode": "SGI",
        "AirportName": "Sargodha Airport",
        "City": "Sargodha",
        "Country": "Pakistan"
    },
    {
        "id": "6735",
        "AirportCode": "SGJ",
        "AirportName": "Sagarai Airport",
        "City": "Sagarai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6736",
        "AirportCode": "SGK",
        "AirportName": "Sangapi Airport",
        "City": "Sangapi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6737",
        "AirportCode": "SGL",
        "AirportName": "Sangley Point NAS",
        "City": "Manila",
        "Country": "Philippines"
    },
    {
        "id": "6738",
        "AirportCode": "SGL",
        "AirportName": "Aeropuerto Los Pozos",
        "City": "Los Pozos",
        "Country": "Colombia"
    },
    {
        "id": "6739",
        "AirportCode": "SGM",
        "AirportName": "San Ignacio Airport",
        "City": "San Ignacio",
        "Country": "Mexico"
    },
    {
        "id": "6740",
        "AirportCode": "SGN",
        "AirportName": "Ho Chi Minh City Airport",
        "City": "Ho Chi Minh City",
        "Country": "Vietnam"
    },
    {
        "id": "6741",
        "AirportCode": "SGO",
        "AirportName": "St. George Airport",
        "City": "Saint George",
        "Country": "Australia"
    },
    {
        "id": "6742",
        "AirportCode": "SGP",
        "AirportName": "Shay Gap Airport",
        "City": "Shay Gap",
        "Country": "Australia"
    },
    {
        "id": "6743",
        "AirportCode": "SGQ",
        "AirportName": "Sanggata Airport",
        "City": "Sanggata",
        "Country": "Indonesia"
    },
    {
        "id": "6744",
        "AirportCode": "SGR",
        "AirportName": "Sugar Land Municipal Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "6745",
        "AirportCode": "SGS",
        "AirportName": "Sanga Sanga Airport",
        "City": "Sanga Sanga",
        "Country": "Philippines"
    },
    {
        "id": "6746",
        "AirportCode": "SGT",
        "AirportName": "Stuttgart Airport",
        "City": "Stuttgart",
        "Country": "USA Arkansas"
    },
    {
        "id": "6747",
        "AirportCode": "SGU",
        "AirportName": "Saint George Municipal Airport",
        "City": "Saint George",
        "Country": "USA Utah"
    },
    {
        "id": "6748",
        "AirportCode": "SGV",
        "AirportName": "Sierra Grande Airport",
        "City": "Sierra Grande",
        "Country": "Argentina"
    },
    {
        "id": "6749",
        "AirportCode": "SGX",
        "AirportName": "Songea Airport",
        "City": "Songea",
        "Country": "Tanzania"
    },
    {
        "id": "6750",
        "AirportCode": "SGY",
        "AirportName": "Skagway Municipal Airport",
        "City": "Skagway",
        "Country": "USA Alaska"
    },
    {
        "id": "6751",
        "AirportCode": "SGZ",
        "AirportName": "Songkhla Airport",
        "City": "Songkhla",
        "Country": "Thailand"
    },
    {
        "id": "6752",
        "AirportCode": "SHA",
        "AirportName": "Hongqiao Airport",
        "City": "Shanghai",
        "Country": "China"
    },
    {
        "id": "6753",
        "AirportCode": "SHB",
        "AirportName": "Nakashibetsu Airport",
        "City": "Nakashibetsu",
        "Country": "Japan"
    },
    {
        "id": "6754",
        "AirportCode": "SHC",
        "AirportName": "Indaselassie Airport",
        "City": "Indaselassie",
        "Country": "Ethiopia"
    },
    {
        "id": "6755",
        "AirportCode": "SHD",
        "AirportName": "Shenandoah Valley Airport",
        "City": "Staunton",
        "Country": "USA Virginia"
    },
    {
        "id": "6756",
        "AirportCode": "SHE",
        "AirportName": "Shenyang Airport",
        "City": "Shenyang",
        "Country": "China"
    },
    {
        "id": "6757",
        "AirportCode": "SHF",
        "AirportName": "Shanhaiguan Airport",
        "City": "Shanhaiguan",
        "Country": "China"
    },
    {
        "id": "6758",
        "AirportCode": "SHG",
        "AirportName": "Shungnak Airport",
        "City": "Shungnak",
        "Country": "USA Alaska"
    },
    {
        "id": "6759",
        "AirportCode": "SHH",
        "AirportName": "Shishmaref Airport",
        "City": "Shishmaref",
        "Country": "USA Alaska"
    },
    {
        "id": "6760",
        "AirportCode": "SHI",
        "AirportName": "Shimojishima Airport",
        "City": "Shimojishima",
        "Country": "Japan"
    },
    {
        "id": "6761",
        "AirportCode": "SHJ",
        "AirportName": "Sharjah Airport",
        "City": "Sharjah",
        "Country": "UAE"
    },
    {
        "id": "6762",
        "AirportCode": "SHK",
        "AirportName": "Sehonghong Airport",
        "City": "Sehonghong",
        "Country": "Lesotho"
    },
    {
        "id": "6763",
        "AirportCode": "SHL",
        "AirportName": "Shillong Airport",
        "City": "Shillong",
        "Country": "India"
    },
    {
        "id": "6764",
        "AirportCode": "SHM",
        "AirportName": "Shirahama Airport",
        "City": "Shirahama",
        "Country": "Japan"
    },
    {
        "id": "6765",
        "AirportCode": "SHN",
        "AirportName": "Sanderson Field",
        "City": "Shelton",
        "Country": "USA Washington"
    },
    {
        "id": "6766",
        "AirportCode": "SHO",
        "AirportName": "Seolak Airport",
        "City": "Sokcho",
        "Country": "Korea"
    },
    {
        "id": "6767",
        "AirportCode": "SHP",
        "AirportName": "Qinhuangdao Airport",
        "City": "Qinhuangdao",
        "Country": "China"
    },
    {
        "id": "6768",
        "AirportCode": "SHQ",
        "AirportName": "Southport Airport",
        "City": "Southport",
        "Country": "Australia"
    },
    {
        "id": "6769",
        "AirportCode": "SHR",
        "AirportName": "Sheridan Airport",
        "City": "Sheridan",
        "Country": "USA Wyoming"
    },
    {
        "id": "6770",
        "AirportCode": "SHS",
        "AirportName": "Shashi Airport",
        "City": "Shashi",
        "Country": "China"
    },
    {
        "id": "6771",
        "AirportCode": "SHT",
        "AirportName": "Shepparton Airport",
        "City": "Shepparton",
        "Country": "Australia"
    },
    {
        "id": "6772",
        "AirportCode": "SHU",
        "AirportName": "Smith Point Airport",
        "City": "Smith Point",
        "Country": "Australia"
    },
    {
        "id": "6773",
        "AirportCode": "SHV",
        "AirportName": "Shreveport Regional Airport",
        "City": "Shreveport",
        "Country": "USA Louisiana"
    },
    {
        "id": "6774",
        "AirportCode": "SHW",
        "AirportName": "Sharurah Airport",
        "City": "Sharurah",
        "Country": "Saudi Arabia"
    },
    {
        "id": "6775",
        "AirportCode": "SHX",
        "AirportName": "Shageluk Airport",
        "City": "Shageluk",
        "Country": "USA Alaska"
    },
    {
        "id": "6776",
        "AirportCode": "SHY",
        "AirportName": "Shinyanga Airport",
        "City": "Shinyanga",
        "Country": "Tanzania"
    },
    {
        "id": "6777",
        "AirportCode": "SHZ",
        "AirportName": "Seshutes Airport",
        "City": "Seshutes",
        "Country": "Lesotho"
    },
    {
        "id": "6778",
        "AirportCode": "SIA",
        "AirportName": "Xiguan Airport",
        "City": "Xian",
        "Country": "China"
    },
    {
        "id": "6779",
        "AirportCode": "SIB",
        "AirportName": "Sibiti Airport",
        "City": "Sibiti",
        "Country": "Congo"
    },
    {
        "id": "6780",
        "AirportCode": "SIC",
        "AirportName": "Sinop Airport",
        "City": "Sinop",
        "Country": "Turkey"
    },
    {
        "id": "6781",
        "AirportCode": "SID",
        "AirportName": "Amilcar Cabral International Airport",
        "City": "Sal",
        "Country": "Cape Verde"
    },
    {
        "id": "6782",
        "AirportCode": "SIE",
        "AirportName": "Sines Airport",
        "City": "Sines",
        "Country": "Portugal"
    },
    {
        "id": "6783",
        "AirportCode": "SIF",
        "AirportName": "Simara Airport",
        "City": "Simara",
        "Country": "Nepal"
    },
    {
        "id": "6784",
        "AirportCode": "SIG",
        "AirportName": "Isla Grande Airport",
        "City": "San Juan",
        "Country": "Puerto Rico"
    },
    {
        "id": "6785",
        "AirportCode": "SIH",
        "AirportName": "Silgarhi Doti Airport",
        "City": "Silgarhi Doti",
        "Country": "Nepal"
    },
    {
        "id": "6786",
        "AirportCode": "SII",
        "AirportName": "Sidi Ifni Airport",
        "City": "Sidi Ifni",
        "Country": "Morocco"
    },
    {
        "id": "6787",
        "AirportCode": "SIJ",
        "AirportName": "Siglufjordur Airport",
        "City": "Siglufjordur",
        "Country": "Iceland"
    },
    {
        "id": "6788",
        "AirportCode": "SIK",
        "AirportName": "Sikeston Memorial Airport",
        "City": "Sikeston",
        "Country": "USA Missouri"
    },
    {
        "id": "6789",
        "AirportCode": "SIL",
        "AirportName": "Sila Airport",
        "City": "Sila",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6790",
        "AirportCode": "SIM",
        "AirportName": "Simbai Airport",
        "City": "Simbai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6791",
        "AirportCode": "SIN",
        "AirportName": "Changi Airport",
        "City": "Singapore",
        "Country": "Singapore"
    },
    {
        "id": "6792",
        "AirportCode": "SIO",
        "AirportName": "Smithton Airport",
        "City": "Smithton",
        "Country": "Australia"
    },
    {
        "id": "6793",
        "AirportCode": "SIP",
        "AirportName": "Simferopol Airport",
        "City": "Simferopol",
        "Country": "Ukraine"
    },
    {
        "id": "6794",
        "AirportCode": "SIQ",
        "AirportName": "Dabo Airport",
        "City": "Singkep",
        "Country": "Indonesia"
    },
    {
        "id": "6795",
        "AirportCode": "SIR",
        "AirportName": "Sion Airport",
        "City": "Sion",
        "Country": "Switzerland"
    },
    {
        "id": "6796",
        "AirportCode": "SIS",
        "AirportName": "Sishen Airport",
        "City": "Sishen",
        "Country": "South Africa"
    },
    {
        "id": "6797",
        "AirportCode": "SIT",
        "AirportName": "Sitka Airport",
        "City": "Sitka",
        "Country": "USA Alaska"
    },
    {
        "id": "6798",
        "AirportCode": "SIU",
        "AirportName": "Siuna Airport",
        "City": "Siuna",
        "Country": "Nicaragua"
    },
    {
        "id": "6799",
        "AirportCode": "SIV",
        "AirportName": "Sullivan County Airport",
        "City": "Sullivan",
        "Country": "USA Indiana"
    },
    {
        "id": "6800",
        "AirportCode": "SIX",
        "AirportName": "Singleton Airport",
        "City": "Singleton",
        "Country": "Australia"
    },
    {
        "id": "6801",
        "AirportCode": "SIY",
        "AirportName": "Siskiyou County Airport",
        "City": "Montague",
        "Country": "USA California"
    },
    {
        "id": "6802",
        "AirportCode": "SIZ",
        "AirportName": "Sissano Airport",
        "City": "Sissano",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6803",
        "AirportCode": "SJA",
        "AirportName": "San Juan Airport",
        "City": "San Juan",
        "Country": "Peru"
    },
    {
        "id": "6804",
        "AirportCode": "SJB",
        "AirportName": "San Joaquin Airport",
        "City": "San Joaquin",
        "Country": "Bolivia"
    },
    {
        "id": "6805",
        "AirportCode": "SJC",
        "AirportName": "Norman Y. Mineta San Jose International Airport",
        "City": "San Jose",
        "Country": "USA California"
    },
    {
        "id": "6806",
        "AirportCode": "SJD",
        "AirportName": "Los Cabos Airport",
        "City": "San Jose Cabo",
        "Country": "Mexico"
    },
    {
        "id": "6807",
        "AirportCode": "SJE",
        "AirportName": "San Jose Del Gua Airport",
        "City": "San Jose Del Gua",
        "Country": "Colombia"
    },
    {
        "id": "6808",
        "AirportCode": "SJF",
        "AirportName": "St. John Island Airport",
        "City": "Saint John Island",
        "Country": "US Virgin Islands"
    },
    {
        "id": "6809",
        "AirportCode": "SJG",
        "AirportName": "San Pedro Jagua Airport",
        "City": "San Pedro Jagua",
        "Country": "Colombia"
    },
    {
        "id": "6810",
        "AirportCode": "SJH",
        "AirportName": "San Juan Del Cesar Airport",
        "City": "San Juan Del Cesar",
        "Country": "Colombia"
    },
    {
        "id": "6811",
        "AirportCode": "SJI",
        "AirportName": "McGuire Field",
        "City": "San Jose",
        "Country": "Philippines"
    },
    {
        "id": "6812",
        "AirportCode": "SJJ",
        "AirportName": "Butmir Airport",
        "City": "Sarajevo",
        "Country": "Bosnia & Herzegovina"
    },
    {
        "id": "6813",
        "AirportCode": "SJK",
        "AirportName": "Sao Jose dos Campos Airport",
        "City": "Sao Jose dos Campos",
        "Country": "Brazil"
    },
    {
        "id": "6814",
        "AirportCode": "SJL",
        "AirportName": "Sao Gabriel Da Cachoeira",
        "City": "Sao Gabriel Da Cachoeira",
        "Country": "Brazil"
    },
    {
        "id": "6815",
        "AirportCode": "SJL",
        "AirportName": "Da Cachoeira Airport",
        "City": "Sao Gabriel",
        "Country": "Brazil"
    },
    {
        "id": "6816",
        "AirportCode": "SJM",
        "AirportName": "San Juan Airport",
        "City": "San Juan",
        "Country": "Dominican Republic"
    },
    {
        "id": "6817",
        "AirportCode": "SJN",
        "AirportName": "Saint Johns Municipal Airport",
        "City": "Saint Johns",
        "Country": "USA Arizona"
    },
    {
        "id": "6818",
        "AirportCode": "SJO",
        "AirportName": "Juan Santamaria International Airport",
        "City": "San Jose",
        "Country": "Costa Rica"
    },
    {
        "id": "6819",
        "AirportCode": "SJP",
        "AirportName": "Sao Jose Do Rio Preto Airport",
        "City": "Sao Jose Do Rio Preto",
        "Country": "Brazil"
    },
    {
        "id": "6820",
        "AirportCode": "SJQ",
        "AirportName": "Sesheke Airport",
        "City": "Sesheke",
        "Country": "Zambia"
    },
    {
        "id": "6821",
        "AirportCode": "SJR",
        "AirportName": "San Juan De Uraba Airport",
        "City": "San Juan De Uraba",
        "Country": "Colombia"
    },
    {
        "id": "6822",
        "AirportCode": "SJS",
        "AirportName": "San Jose Airport",
        "City": "San Jose",
        "Country": "Bolivia"
    },
    {
        "id": "6823",
        "AirportCode": "SJT",
        "AirportName": "Mathis Field",
        "City": "San Angelo",
        "Country": "USA Texas"
    },
    {
        "id": "6824",
        "AirportCode": "SJV",
        "AirportName": "San Javier Airport",
        "City": "San Javier",
        "Country": "Bolivia"
    },
    {
        "id": "6825",
        "AirportCode": "SJW",
        "AirportName": "Daguocun Airport",
        "City": "Shijiazhuang",
        "Country": "China"
    },
    {
        "id": "6826",
        "AirportCode": "SJX",
        "AirportName": "Sartaneja Airport",
        "City": "Sartaneja",
        "Country": "Belize"
    },
    {
        "id": "6827",
        "AirportCode": "SJY",
        "AirportName": "Ilmajoki Airport",
        "City": "Seinajoki",
        "Country": "Finland"
    },
    {
        "id": "6828",
        "AirportCode": "SJZ",
        "AirportName": "Sao Jorge Island Airport",
        "City": "Sao Jorge Island",
        "Country": "Portugal"
    },
    {
        "id": "6829",
        "AirportCode": "SKA",
        "AirportName": "Fairchild Air Force Base",
        "City": "Spokane",
        "Country": "USA Washington"
    },
    {
        "id": "6830",
        "AirportCode": "SKB",
        "AirportName": "Golden Rock Airport",
        "City": "Saint Kitts",
        "Country": "St Kitts & Nevis"
    },
    {
        "id": "6831",
        "AirportCode": "SKC",
        "AirportName": "Suki Airport",
        "City": "Suki",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6832",
        "AirportCode": "SKD",
        "AirportName": "Samarkand Airport",
        "City": "Samarkand",
        "Country": "Uzbekistan"
    },
    {
        "id": "6833",
        "AirportCode": "SKE",
        "AirportName": "Skien Airport",
        "City": "Skien",
        "Country": "Norway"
    },
    {
        "id": "6834",
        "AirportCode": "SKF",
        "AirportName": "Kelly Air Force Base",
        "City": "San Antonio",
        "Country": "USA Texas"
    },
    {
        "id": "6835",
        "AirportCode": "SKG",
        "AirportName": "Makedonia Airport",
        "City": "Thessaloniki",
        "Country": "Greece"
    },
    {
        "id": "6836",
        "AirportCode": "SKH",
        "AirportName": "Surkhet Airport",
        "City": "Surkhet",
        "Country": "Nepal"
    },
    {
        "id": "6837",
        "AirportCode": "SKI",
        "AirportName": "Skikda Airport",
        "City": "Skikda",
        "Country": "Algeria"
    },
    {
        "id": "6838",
        "AirportCode": "SKK",
        "AirportName": "Shaktoolik Airport",
        "City": "Shaktoolik",
        "Country": "USA Alaska"
    },
    {
        "id": "6839",
        "AirportCode": "SKL",
        "AirportName": "Broadford Airport",
        "City": "Isle of Skye",
        "Country": "United Kingdom"
    },
    {
        "id": "6840",
        "AirportCode": "SKM",
        "AirportName": "Skeldon Airport",
        "City": "Skeldon",
        "Country": "Guyana"
    },
    {
        "id": "6841",
        "AirportCode": "SKN",
        "AirportName": "Skagen Airport",
        "City": "Stokmarknes",
        "Country": "Norway"
    },
    {
        "id": "6842",
        "AirportCode": "SKO",
        "AirportName": "Sokoto Airport",
        "City": "Sokoto",
        "Country": "Nigeria"
    },
    {
        "id": "6843",
        "AirportCode": "SKP",
        "AirportName": "Skopje Airport",
        "City": "Skopje",
        "Country": "Macedonia"
    },
    {
        "id": "6844",
        "AirportCode": "SKQ",
        "AirportName": "Sekakes Airport",
        "City": "Sekakes",
        "Country": "Lesotho"
    },
    {
        "id": "6845",
        "AirportCode": "SKR",
        "AirportName": "Shakiso Airport",
        "City": "Shakiso",
        "Country": "Ethiopia"
    },
    {
        "id": "6846",
        "AirportCode": "SKS",
        "AirportName": "Vojens Airport",
        "City": "Vojens",
        "Country": "Denmark"
    },
    {
        "id": "6847",
        "AirportCode": "SKT",
        "AirportName": "Sialkot Airport",
        "City": "Sialkot",
        "Country": "Pakistan"
    },
    {
        "id": "6848",
        "AirportCode": "SKU",
        "AirportName": "Skiros Airport",
        "City": "Skiros",
        "Country": "Greece"
    },
    {
        "id": "6849",
        "AirportCode": "SKV",
        "AirportName": "Mount Sinai Airport",
        "City": "Santa Katarina",
        "Country": "Egypt"
    },
    {
        "id": "6850",
        "AirportCode": "SKX",
        "AirportName": "Saransk Airport",
        "City": "Saransk",
        "Country": "Russia"
    },
    {
        "id": "6851",
        "AirportCode": "SKY",
        "AirportName": "Griffin Sandusky Airport",
        "City": "Sandusky",
        "Country": "USA Ohio"
    },
    {
        "id": "6852",
        "AirportCode": "SKZ",
        "AirportName": "Sukkur Airport",
        "City": "Sukkur",
        "Country": "Pakistan"
    },
    {
        "id": "6853",
        "AirportCode": "SLA",
        "AirportName": "Gen Belgrano Airport",
        "City": "Salta",
        "Country": "Argentina"
    },
    {
        "id": "6854",
        "AirportCode": "SLB",
        "AirportName": "Storm Lake Municipal Airport",
        "City": "Storm Lake",
        "Country": "USA Iowa"
    },
    {
        "id": "6855",
        "AirportCode": "SLC",
        "AirportName": "Salt Lake City International Airport",
        "City": "Salt Lake City",
        "Country": "USA Utah"
    },
    {
        "id": "6856",
        "AirportCode": "SLD",
        "AirportName": "Sliac Airport",
        "City": "Sliac",
        "Country": "Slovakia"
    },
    {
        "id": "6857",
        "AirportCode": "SLE",
        "AirportName": "McNary Field",
        "City": "Salem",
        "Country": "USA Oregon"
    },
    {
        "id": "6858",
        "AirportCode": "SLF",
        "AirportName": "Sulayel Airport",
        "City": "Sulayel",
        "Country": "Saudi Arabia"
    },
    {
        "id": "6859",
        "AirportCode": "SLG",
        "AirportName": "Smith Field",
        "City": "Siloam Springs",
        "Country": "USA Arkansas"
    },
    {
        "id": "6860",
        "AirportCode": "SLH",
        "AirportName": "Sola Airport",
        "City": "Sola",
        "Country": "Vanuatu"
    },
    {
        "id": "6861",
        "AirportCode": "SLI",
        "AirportName": "Solwezi Airport",
        "City": "Solwezi",
        "Country": "Zambia"
    },
    {
        "id": "6862",
        "AirportCode": "SLJ",
        "AirportName": "Stellar Air Park",
        "City": "Chandler",
        "Country": "USA Arizona"
    },
    {
        "id": "6863",
        "AirportCode": "SLK",
        "AirportName": "Adirondack Airport",
        "City": "Saranac Lake",
        "Country": "USA New York"
    },
    {
        "id": "6864",
        "AirportCode": "SLL",
        "AirportName": "Salalah Airport",
        "City": "Salalah",
        "Country": "Oman"
    },
    {
        "id": "6865",
        "AirportCode": "SLM",
        "AirportName": "Matacan Airport",
        "City": "Salamanca",
        "Country": "Spain"
    },
    {
        "id": "6866",
        "AirportCode": "SLN",
        "AirportName": "Salina Airport",
        "City": "Salina",
        "Country": "USA Kansas"
    },
    {
        "id": "6867",
        "AirportCode": "SLO",
        "AirportName": "Leckrone Airport",
        "City": "Salem",
        "Country": "USA Illinois"
    },
    {
        "id": "6868",
        "AirportCode": "SLP",
        "AirportName": "San Luis Potosi Airport",
        "City": "San Luis Potosi",
        "Country": "Mexico"
    },
    {
        "id": "6869",
        "AirportCode": "SLQ",
        "AirportName": "Sleetmute Airport",
        "City": "Sleetmute",
        "Country": "USA Alaska"
    },
    {
        "id": "6870",
        "AirportCode": "SLR",
        "AirportName": "Sulphur Springs Airport",
        "City": "Sulphur Springs",
        "Country": "USA Texas"
    },
    {
        "id": "6871",
        "AirportCode": "SLS",
        "AirportName": "Silistra Airport",
        "City": "Silistra",
        "Country": "Bulgaria"
    },
    {
        "id": "6872",
        "AirportCode": "SLT",
        "AirportName": "Salida Airport",
        "City": "Salida",
        "Country": "USA Colorado"
    },
    {
        "id": "6873",
        "AirportCode": "SLU",
        "AirportName": "Vigie Field",
        "City": "Saint Lucia",
        "Country": "St Lucia"
    },
    {
        "id": "6874",
        "AirportCode": "SLV",
        "AirportName": "Simla Airport",
        "City": "Simla",
        "Country": "India"
    },
    {
        "id": "6875",
        "AirportCode": "SLW",
        "AirportName": "Saltillo Airport",
        "City": "Saltillo",
        "Country": "Mexico"
    },
    {
        "id": "6876",
        "AirportCode": "SLX",
        "AirportName": "Salt Cay Airport",
        "City": "Salt Cay",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "6877",
        "AirportCode": "SLY",
        "AirportName": "Salehard Airport",
        "City": "Salekhard",
        "Country": "Russia"
    },
    {
        "id": "6878",
        "AirportCode": "SLZ",
        "AirportName": "Mal. Cunha MaMalio Airport",
        "City": "Sao Luiz",
        "Country": "Brazil"
    },
    {
        "id": "6879",
        "AirportCode": "SMA",
        "AirportName": "Vila Do Porto Airport",
        "City": "Santa Maria (Azores)",
        "Country": "Azores"
    },
    {
        "id": "6880",
        "AirportCode": "SMB",
        "AirportName": "Cerro Sombrero Airport",
        "City": "Cerro Sombrero",
        "Country": "Chile"
    },
    {
        "id": "6881",
        "AirportCode": "SMC",
        "AirportName": "Santa Maria Airport",
        "City": "Santa Maria",
        "Country": "Colombia"
    },
    {
        "id": "6882",
        "AirportCode": "SMD",
        "AirportName": "Smith Field",
        "City": "Fort Wayne",
        "Country": "USA Indiana"
    },
    {
        "id": "6883",
        "AirportCode": "SME",
        "AirportName": "Pulaski County Airport",
        "City": "Somerset",
        "Country": "USA Kentucky"
    },
    {
        "id": "6884",
        "AirportCode": "SMF",
        "AirportName": "Sacramento International Airport",
        "City": "Sacramento",
        "Country": "USA California"
    },
    {
        "id": "6885",
        "AirportCode": "SMG",
        "AirportName": "Santa Maria Airport",
        "City": "Santa Maria",
        "Country": "Peru"
    },
    {
        "id": "6886",
        "AirportCode": "SMH",
        "AirportName": "Sapmanga Airport",
        "City": "Sapmanga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6887",
        "AirportCode": "SMI",
        "AirportName": "Samos Airport",
        "City": "Samos",
        "Country": "Greece"
    },
    {
        "id": "6888",
        "AirportCode": "SMJ",
        "AirportName": "Sim Airport",
        "City": "Sim",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6889",
        "AirportCode": "SMK",
        "AirportName": "St. Michael Airport",
        "City": "Saint Michael",
        "Country": "USA Alaska"
    },
    {
        "id": "6890",
        "AirportCode": "SML",
        "AirportName": "Estate Airstrip",
        "City": "Stella Maris",
        "Country": "Bahamas"
    },
    {
        "id": "6891",
        "AirportCode": "SMM",
        "AirportName": "Semporna Airport",
        "City": "Semporna",
        "Country": "Malaysia"
    },
    {
        "id": "6892",
        "AirportCode": "SMN",
        "AirportName": "Salmon Airport",
        "City": "Salmon",
        "Country": "USA Idaho"
    },
    {
        "id": "6893",
        "AirportCode": "SMO",
        "AirportName": "Santa Monica Airport",
        "City": "Santa Monica",
        "Country": "USA California"
    },
    {
        "id": "6894",
        "AirportCode": "SMP",
        "AirportName": "Stockholm Airport",
        "City": "Stockholm",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6895",
        "AirportCode": "SMQ",
        "AirportName": "Sampit Airport",
        "City": "Sampit",
        "Country": "Indonesia"
    },
    {
        "id": "6896",
        "AirportCode": "SMR",
        "AirportName": "Simon Bolivar Airport",
        "City": "Santa Marta",
        "Country": "Colombia"
    },
    {
        "id": "6897",
        "AirportCode": "SMS",
        "AirportName": "Sainte Marie Airport",
        "City": "Sainte Marie",
        "Country": "Madagascar"
    },
    {
        "id": "6898",
        "AirportCode": "SMT",
        "AirportName": "Sun Moon Lake Airport",
        "City": "Sun Moon Lake",
        "Country": "Taiwan"
    },
    {
        "id": "6899",
        "AirportCode": "SMV",
        "AirportName": "Samedan Airport",
        "City": "Saint Moritz",
        "Country": "Switzerland"
    },
    {
        "id": "6900",
        "AirportCode": "SMW",
        "AirportName": "Smara Airport",
        "City": "Smara",
        "Country": "Morocco"
    },
    {
        "id": "6901",
        "AirportCode": "SMX",
        "AirportName": "Santa Maria Public Airport",
        "City": "Santa Maria",
        "Country": "USA California"
    },
    {
        "id": "6902",
        "AirportCode": "SMY",
        "AirportName": "Simenti Airport",
        "City": "Simenti",
        "Country": "Senegal"
    },
    {
        "id": "6903",
        "AirportCode": "SMZ",
        "AirportName": "Stoelmanseiland Airport",
        "City": "Stoelmanseiland",
        "Country": "Suriname"
    },
    {
        "id": "6904",
        "AirportCode": "SNA",
        "AirportName": "John Wayne Airport",
        "City": "Santa Ana",
        "Country": "USA California"
    },
    {
        "id": "6905",
        "AirportCode": "SNB",
        "AirportName": "Snake Bay Airport",
        "City": "Snake Bay",
        "Country": "Australia"
    },
    {
        "id": "6906",
        "AirportCode": "SNC",
        "AirportName": "Salinas Airport",
        "City": "Salinas",
        "Country": "Ecuador"
    },
    {
        "id": "6907",
        "AirportCode": "SND",
        "AirportName": "Seno Airport",
        "City": "Seno",
        "Country": "Laos"
    },
    {
        "id": "6908",
        "AirportCode": "SNE",
        "AirportName": "Preguica Airport",
        "City": "Sao Nicolau",
        "Country": "Cape Verde"
    },
    {
        "id": "6909",
        "AirportCode": "SNF",
        "AirportName": "San Felipe Airport",
        "City": "San Felipe",
        "Country": "Venezuela"
    },
    {
        "id": "6910",
        "AirportCode": "SNG",
        "AirportName": "San Ignacio De Velasco Airport",
        "City": "San Ignacio De Velasco",
        "Country": "Bolivia"
    },
    {
        "id": "6911",
        "AirportCode": "SNH",
        "AirportName": "Stanthorpe Airport",
        "City": "Stanthorpe",
        "Country": "Australia"
    },
    {
        "id": "6912",
        "AirportCode": "SNI",
        "AirportName": "R. E. Murray Airport",
        "City": "Sinoe",
        "Country": "Liberia"
    },
    {
        "id": "6913",
        "AirportCode": "SNJ",
        "AirportName": "San Julian Airport",
        "City": "San Julian",
        "Country": "Cuba"
    },
    {
        "id": "6914",
        "AirportCode": "SNK",
        "AirportName": "Winston Field",
        "City": "Snyder",
        "Country": "USA Texas"
    },
    {
        "id": "6915",
        "AirportCode": "SNL",
        "AirportName": "Shawnee Municipal Airport",
        "City": "Shawnee",
        "Country": "USA Oklahoma"
    },
    {
        "id": "6916",
        "AirportCode": "SNM",
        "AirportName": "San Ignacio De Moxos Airport",
        "City": "San Ignacio De Moxos",
        "Country": "Bolivia"
    },
    {
        "id": "6917",
        "AirportCode": "SNN",
        "AirportName": "Shannon Airport",
        "City": "Shannon",
        "Country": "Ireland"
    },
    {
        "id": "6918",
        "AirportCode": "SNO",
        "AirportName": "Sakon Nakhon Airport",
        "City": "Sakon Nakhon",
        "Country": "Thailand"
    },
    {
        "id": "6919",
        "AirportCode": "SNP",
        "AirportName": "Saint Paul Island Airport",
        "City": "Saint Paul Island",
        "Country": "USA Alaska"
    },
    {
        "id": "6920",
        "AirportCode": "SNQ",
        "AirportName": "San Quintin Airport",
        "City": "San Quintin",
        "Country": "Mexico"
    },
    {
        "id": "6921",
        "AirportCode": "SNR",
        "AirportName": "Montoir Airport",
        "City": "Saint Nazaire",
        "Country": "France"
    },
    {
        "id": "6922",
        "AirportCode": "SNS",
        "AirportName": "Salinas Airport",
        "City": "Salinas",
        "Country": "USA California"
    },
    {
        "id": "6923",
        "AirportCode": "SNT",
        "AirportName": "Sabana De Torres Airport",
        "City": "Sabana De Torres",
        "Country": "Colombia"
    },
    {
        "id": "6924",
        "AirportCode": "SNU",
        "AirportName": "Santa Clara Airport",
        "City": "Santa Clara",
        "Country": "Cuba"
    },
    {
        "id": "6925",
        "AirportCode": "SNV",
        "AirportName": "Santa Elena Airport",
        "City": "Santa Elena",
        "Country": "Venezuela"
    },
    {
        "id": "6926",
        "AirportCode": "SNW",
        "AirportName": "Thandwe Airport",
        "City": "Thandwe",
        "Country": "Burma"
    },
    {
        "id": "6927",
        "AirportCode": "SNX",
        "AirportName": "Sabana De Mar Airport",
        "City": "Sabana De Mar",
        "Country": "Dominican Republic"
    },
    {
        "id": "6928",
        "AirportCode": "SNY",
        "AirportName": "Sidney Airport",
        "City": "Sidney",
        "Country": "USA Nebraska"
    },
    {
        "id": "6929",
        "AirportCode": "SNZ",
        "AirportName": "Santa Cruz Ab",
        "City": "Rio De Janeiro",
        "Country": "Brazil"
    },
    {
        "id": "6930",
        "AirportCode": "SNZ",
        "AirportName": "Santa Cruz Airport",
        "City": "Santa Cruz",
        "Country": "Brazil"
    },
    {
        "id": "6931",
        "AirportCode": "SOA",
        "AirportName": "Soc Trang Airport",
        "City": "Soc Trang",
        "Country": "Vietnam"
    },
    {
        "id": "6932",
        "AirportCode": "SOB",
        "AirportName": "Saarmelleek/Balaton Airport",
        "City": "Saarmelleek",
        "Country": "Hungary"
    },
    {
        "id": "6933",
        "AirportCode": "SOC",
        "AirportName": "Adi Sumarmo Airport",
        "City": "Solo City",
        "Country": "Indonesia"
    },
    {
        "id": "6934",
        "AirportCode": "SOD",
        "AirportName": "Sorocaba Airport",
        "City": "Sorocaba",
        "Country": "Brazil"
    },
    {
        "id": "6935",
        "AirportCode": "SOE",
        "AirportName": "Souanke Airport",
        "City": "Souanke",
        "Country": "Congo"
    },
    {
        "id": "6936",
        "AirportCode": "SOF",
        "AirportName": "Sofia Airport",
        "City": "Sofia",
        "Country": "Bulgaria"
    },
    {
        "id": "6937",
        "AirportCode": "SOG",
        "AirportName": "Haukasen Airport",
        "City": "Sogndal",
        "Country": "Norway"
    },
    {
        "id": "6938",
        "AirportCode": "SOH",
        "AirportName": "Solita Airport",
        "City": "Solita",
        "Country": "Colombia"
    },
    {
        "id": "6939",
        "AirportCode": "SOI",
        "AirportName": "South Molle Island Airport",
        "City": "South Molle Island",
        "Country": "Australia"
    },
    {
        "id": "6940",
        "AirportCode": "SOJ",
        "AirportName": "Sorkjosen Airport",
        "City": "Sorkjosen",
        "Country": "Norway"
    },
    {
        "id": "6941",
        "AirportCode": "SOK",
        "AirportName": "Semongkong Airport",
        "City": "Semongkong",
        "Country": "Lesotho"
    },
    {
        "id": "6942",
        "AirportCode": "SOM",
        "AirportName": "El Tigre Airport",
        "City": "San Tome",
        "Country": "Venezuela"
    },
    {
        "id": "6943",
        "AirportCode": "SON",
        "AirportName": "Pekoa Airport",
        "City": "Espiritu Santo",
        "Country": "Vanuatu"
    },
    {
        "id": "6944",
        "AirportCode": "SOO",
        "AirportName": "Soderhamn Airport",
        "City": "Soderhamn",
        "Country": "Sweden"
    },
    {
        "id": "6945",
        "AirportCode": "SOP",
        "AirportName": "Pinehurst-S. Pines Airport",
        "City": "Southern Pines",
        "Country": "USA North Carolina"
    },
    {
        "id": "6946",
        "AirportCode": "SOQ",
        "AirportName": "Jefman Airport",
        "City": "Sorong",
        "Country": "Indonesia"
    },
    {
        "id": "6947",
        "AirportCode": "SOR",
        "AirportName": "Al Thaurah Airport",
        "City": "Al Thaurah",
        "Country": "Syria"
    },
    {
        "id": "6948",
        "AirportCode": "SOT",
        "AirportName": "Sodankyla Airport",
        "City": "Sodankyla",
        "Country": "Finland"
    },
    {
        "id": "6949",
        "AirportCode": "SOU",
        "AirportName": "Southampton Airport",
        "City": "Southampton",
        "Country": "United Kingdom"
    },
    {
        "id": "6950",
        "AirportCode": "SOV",
        "AirportName": "Seldovia Airport",
        "City": "Seldovia",
        "Country": "USA Alaska"
    },
    {
        "id": "6951",
        "AirportCode": "SOW",
        "AirportName": "Show Low Airport",
        "City": "Show Low",
        "Country": "USA Arizona"
    },
    {
        "id": "6952",
        "AirportCode": "SOX",
        "AirportName": "Sogamoso Airport",
        "City": "Sogamoso",
        "Country": "Colombia"
    },
    {
        "id": "6953",
        "AirportCode": "SOY",
        "AirportName": "Stronsay Airport",
        "City": "Stronsay",
        "Country": "United Kingdom"
    },
    {
        "id": "6954",
        "AirportCode": "SOZ",
        "AirportName": "Solenzara Airport",
        "City": "Solenzara",
        "Country": "France"
    },
    {
        "id": "6955",
        "AirportCode": "SPA",
        "AirportName": "Spartanburg Downtown Memorial Airport",
        "City": "Spartanburg",
        "Country": "USA South Carolina"
    },
    {
        "id": "6956",
        "AirportCode": "SPB",
        "AirportName": "St. Thomas Sea Plane Base",
        "City": "Saint Thomas",
        "Country": "US Virgin Islands"
    },
    {
        "id": "6957",
        "AirportCode": "SPC",
        "AirportName": "La Palma Airport",
        "City": "Santa Cruz De La Palma",
        "Country": "Spain"
    },
    {
        "id": "6958",
        "AirportCode": "SPD",
        "AirportName": "Saidpur Airport",
        "City": "Saidpur",
        "Country": "Bangladesh"
    },
    {
        "id": "6959",
        "AirportCode": "SPE",
        "AirportName": "Sepulot Airport",
        "City": "Sepulot",
        "Country": "Malaysia"
    },
    {
        "id": "6960",
        "AirportCode": "SPF",
        "AirportName": "Black Hills Airport",
        "City": "Spearfish",
        "Country": "USA South Dakota"
    },
    {
        "id": "6961",
        "AirportCode": "SPG",
        "AirportName": "Whitted Airport",
        "City": "Saint Petersburg",
        "Country": "USA Florida"
    },
    {
        "id": "6962",
        "AirportCode": "SPH",
        "AirportName": "Sopu Airport",
        "City": "Sopu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6963",
        "AirportCode": "SPI",
        "AirportName": "Capital Airport",
        "City": "Springfield",
        "Country": "USA Illinois"
    },
    {
        "id": "6964",
        "AirportCode": "SPJ",
        "AirportName": "Sparta Airport",
        "City": "Sparta",
        "Country": "Greece"
    },
    {
        "id": "6965",
        "AirportCode": "SPM",
        "AirportName": "Spangdahlem Ab",
        "City": "Spangdahlem",
        "Country": "Germany"
    },
    {
        "id": "6966",
        "AirportCode": "SPN",
        "AirportName": "Saipan International Airport",
        "City": "Saipan",
        "Country": "Northern Mariana Islands"
    },
    {
        "id": "6967",
        "AirportCode": "SPO",
        "AirportName": "San Pablo Airport",
        "City": "San Pablo",
        "Country": "Spain"
    },
    {
        "id": "6968",
        "AirportCode": "SPP",
        "AirportName": "Menongue Airport",
        "City": "Menongue",
        "Country": "Angola"
    },
    {
        "id": "6969",
        "AirportCode": "SPQ",
        "AirportName": "Catalina Sea Plane Base",
        "City": "San Pedro",
        "Country": "USA California"
    },
    {
        "id": "6970",
        "AirportCode": "SPR",
        "AirportName": "San Pedro Airport",
        "City": "San Pedro",
        "Country": "Belize"
    },
    {
        "id": "6971",
        "AirportCode": "SPS",
        "AirportName": "Sheppard Air Force Base",
        "City": "Wichita Falls",
        "Country": "USA Texas"
    },
    {
        "id": "6972",
        "AirportCode": "SPT",
        "AirportName": "Sipitang Airport",
        "City": "Sipitang",
        "Country": "Malaysia"
    },
    {
        "id": "6973",
        "AirportCode": "SPU",
        "AirportName": "Split Airport",
        "City": "Split",
        "Country": "Croatia"
    },
    {
        "id": "6974",
        "AirportCode": "SPV",
        "AirportName": "Sepik Plains Airport",
        "City": "Sepik Plains",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6975",
        "AirportCode": "SPW",
        "AirportName": "Spencer Municipal Airport",
        "City": "Spencer",
        "Country": "USA Iowa"
    },
    {
        "id": "6976",
        "AirportCode": "SPX",
        "AirportName": "Spaceland Airport",
        "City": "Houston",
        "Country": "USA Texas"
    },
    {
        "id": "6977",
        "AirportCode": "SPZ",
        "AirportName": "Springdale Municipal Airport",
        "City": "Springdale",
        "Country": "USA Arkansas"
    },
    {
        "id": "6978",
        "AirportCode": "SQA",
        "AirportName": "Santa Ynez Airport",
        "City": "Santa Ynez",
        "Country": "USA California"
    },
    {
        "id": "6979",
        "AirportCode": "SQB",
        "AirportName": "Santa Ana Airport",
        "City": "Santa Ana",
        "Country": "Colombia"
    },
    {
        "id": "6980",
        "AirportCode": "SQC",
        "AirportName": "Southern Cross Airport",
        "City": "Southern Cross",
        "Country": "Australia"
    },
    {
        "id": "6981",
        "AirportCode": "SQE",
        "AirportName": "San Luis De Pale Airport",
        "City": "San Luis De Pale",
        "Country": "Colombia"
    },
    {
        "id": "6982",
        "AirportCode": "SQF",
        "AirportName": "Solano Airport",
        "City": "Solano",
        "Country": "Colombia"
    },
    {
        "id": "6983",
        "AirportCode": "SQG",
        "AirportName": "Sintang Airport",
        "City": "Sintang",
        "Country": "Indonesia"
    },
    {
        "id": "6984",
        "AirportCode": "SQH",
        "AirportName": "Na-San Airport",
        "City": "Son-La",
        "Country": "Vietnam"
    },
    {
        "id": "6985",
        "AirportCode": "SQI",
        "AirportName": "Whiteside County Airport",
        "City": "Sterling",
        "Country": "USA Illinois"
    },
    {
        "id": "6986",
        "AirportCode": "SQJ",
        "AirportName": "Shehdi Airport",
        "City": "Shehdi",
        "Country": "Ethiopia"
    },
    {
        "id": "6987",
        "AirportCode": "SQK",
        "AirportName": "Sidi Barani Airport",
        "City": "Sidi Barani",
        "Country": "Egypt"
    },
    {
        "id": "6988",
        "AirportCode": "SQL",
        "AirportName": "San Carlos Airport",
        "City": "San Carlos",
        "Country": "USA California"
    },
    {
        "id": "6989",
        "AirportCode": "SQM",
        "AirportName": "Sao Miguel Araguaia Airport",
        "City": "Sao Miguel Araguaia",
        "Country": "Brazil"
    },
    {
        "id": "6990",
        "AirportCode": "SQN",
        "AirportName": "Sanana Airport",
        "City": "Sanana",
        "Country": "Indonesia"
    },
    {
        "id": "6991",
        "AirportCode": "SQO",
        "AirportName": "Gunnarn Airport",
        "City": "Storuman",
        "Country": "Sweden"
    },
    {
        "id": "6992",
        "AirportCode": "SQP",
        "AirportName": "Starcke Airport",
        "City": "Starcke",
        "Country": "Australia"
    },
    {
        "id": "6993",
        "AirportCode": "SQQ",
        "AirportName": "Siauliai Airport",
        "City": "Siauliai",
        "Country": "Lithuania"
    },
    {
        "id": "6994",
        "AirportCode": "SQR",
        "AirportName": "Soroako Airport",
        "City": "Soroako",
        "Country": "Indonesia"
    },
    {
        "id": "6995",
        "AirportCode": "SQS",
        "AirportName": "Matthew Spain Airport",
        "City": "San Ignacio",
        "Country": "Belize"
    },
    {
        "id": "6996",
        "AirportCode": "SQT",
        "AirportName": "China Straits Airstrip",
        "City": "Samarai Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "6997",
        "AirportCode": "SQU",
        "AirportName": "Saposoa Airport",
        "City": "Saposoa",
        "Country": "Peru"
    },
    {
        "id": "6998",
        "AirportCode": "SQV",
        "AirportName": "Sequim Valley Airport",
        "City": "Sequim",
        "Country": "USA Washington"
    },
    {
        "id": "6999",
        "AirportCode": "SQW",
        "AirportName": "Skive Airport",
        "City": "Skive",
        "Country": "Denmark"
    },
    {
        "id": "7000",
        "AirportCode": "SQX",
        "AirportName": "Sao Miguel Do Oeste Airport",
        "City": "Sao Miguel Do Oeste",
        "Country": "Brazil"
    },
    {
        "id": "7001",
        "AirportCode": "SQY",
        "AirportName": "Sao Lourenco Do Sul Airport",
        "City": "Sao Lourenco Do Sul",
        "Country": "Brazil"
    },
    {
        "id": "7002",
        "AirportCode": "SQZ",
        "AirportName": "Royal Air Force Station",
        "City": "Scampton",
        "Country": "United Kingdom"
    },
    {
        "id": "7003",
        "AirportCode": "SRA",
        "AirportName": "Santa Rosa Airport",
        "City": "Santa Rosa",
        "Country": "Brazil"
    },
    {
        "id": "7004",
        "AirportCode": "SRB",
        "AirportName": "Santa Rosa Airport",
        "City": "Santa Rosa",
        "Country": "Bolivia"
    },
    {
        "id": "7005",
        "AirportCode": "SRC",
        "AirportName": "Searcy Airport",
        "City": "Searcy",
        "Country": "USA Arkansas"
    },
    {
        "id": "7006",
        "AirportCode": "SRD",
        "AirportName": "San Ramon Airport",
        "City": "San Ramon",
        "Country": "Bolivia"
    },
    {
        "id": "7007",
        "AirportCode": "SRE",
        "AirportName": "Sucre Airport",
        "City": "Sucre",
        "Country": "Bolivia"
    },
    {
        "id": "7008",
        "AirportCode": "SRF",
        "AirportName": "Hamilton Field",
        "City": "San Rafael",
        "Country": "USA California"
    },
    {
        "id": "7009",
        "AirportCode": "SRG",
        "AirportName": "Achmad Uani Airport",
        "City": "Semarang",
        "Country": "Indonesia"
    },
    {
        "id": "7010",
        "AirportCode": "SRH",
        "AirportName": "Sarh Airport",
        "City": "Sarh",
        "Country": "Mali"
    },
    {
        "id": "7011",
        "AirportCode": "SRI",
        "AirportName": "Samarinda Airport",
        "City": "Samarinda",
        "Country": "Indonesia"
    },
    {
        "id": "7012",
        "AirportCode": "SRJ",
        "AirportName": "Capitan G Q Guardia Airport",
        "City": "San Borja",
        "Country": "Bolivia"
    },
    {
        "id": "7013",
        "AirportCode": "SRK",
        "AirportName": "Sierra Leone Airport",
        "City": "Sierra Leone",
        "Country": "Sierra Leone"
    },
    {
        "id": "7014",
        "AirportCode": "SRL",
        "AirportName": "Santa Rosalia Airport",
        "City": "Santa Rosalia",
        "Country": "Mexico"
    },
    {
        "id": "7015",
        "AirportCode": "SRM",
        "AirportName": "Sandringham Airport",
        "City": "Sandringham",
        "Country": "Australia"
    },
    {
        "id": "7016",
        "AirportCode": "SRN",
        "AirportName": "Strahan Airport",
        "City": "Strahan",
        "Country": "Australia"
    },
    {
        "id": "7017",
        "AirportCode": "SRO",
        "AirportName": "Santana Ramos Airport",
        "City": "Santana Ramos",
        "Country": "Colombia"
    },
    {
        "id": "7018",
        "AirportCode": "SRP",
        "AirportName": "Stord Airport",
        "City": "Stord",
        "Country": "Norway"
    },
    {
        "id": "7019",
        "AirportCode": "SRQ",
        "AirportName": "Bradenton Airport",
        "City": "Sarasota",
        "Country": "USA Florida"
    },
    {
        "id": "7020",
        "AirportCode": "SRS",
        "AirportName": "San Marcos Airport",
        "City": "San Marcos",
        "Country": "Colombia"
    },
    {
        "id": "7021",
        "AirportCode": "SRT",
        "AirportName": "Soroti Airport",
        "City": "Soroti",
        "Country": "Uganda"
    },
    {
        "id": "7022",
        "AirportCode": "SRU",
        "AirportName": "Santa Cruz Skypark",
        "City": "Santa Cruz",
        "Country": "USA California"
    },
    {
        "id": "7023",
        "AirportCode": "SRV",
        "AirportName": "Stony River Airport",
        "City": "Stony River",
        "Country": "USA Alaska"
    },
    {
        "id": "7024",
        "AirportCode": "SRW",
        "AirportName": "Rowan County Airport",
        "City": "Salisbury",
        "Country": "USA North Carolina"
    },
    {
        "id": "7025",
        "AirportCode": "SRX",
        "AirportName": "Sert Airport",
        "City": "Sert",
        "Country": "Libya"
    },
    {
        "id": "7026",
        "AirportCode": "SRY",
        "AirportName": "Dashte Naz Airport",
        "City": "Sary",
        "Country": "Iran"
    },
    {
        "id": "7027",
        "AirportCode": "SRZ",
        "AirportName": "El Trompillo Airport",
        "City": "Santa Cruz",
        "Country": "Bolivia"
    },
    {
        "id": "7028",
        "AirportCode": "SSA",
        "AirportName": "Airport Luis E. Magalhaes",
        "City": "Salvador",
        "Country": "Brazil"
    },
    {
        "id": "7029",
        "AirportCode": "SSB",
        "AirportName": "St. Croix Sea Plane Base",
        "City": "Saint Croix",
        "Country": "US Virgin Islands"
    },
    {
        "id": "7030",
        "AirportCode": "SSC",
        "AirportName": "Shaw Air Force Base",
        "City": "Sumter",
        "Country": "USA South Carolina"
    },
    {
        "id": "7031",
        "AirportCode": "SSD",
        "AirportName": "Victor Lafon",
        "City": "San Felipe",
        "Country": "Chile"
    },
    {
        "id": "7032",
        "AirportCode": "SSD",
        "AirportName": "San Felipe Airport",
        "City": "San Felipe",
        "Country": "Colombia"
    },
    {
        "id": "7033",
        "AirportCode": "SSE",
        "AirportName": "Sholapur Airport",
        "City": "Sholapur",
        "Country": "India"
    },
    {
        "id": "7034",
        "AirportCode": "SSF",
        "AirportName": "Stinson Municipal Airport",
        "City": "San Antonio",
        "Country": "USA Texas"
    },
    {
        "id": "7035",
        "AirportCode": "SSG",
        "AirportName": "Santa Isabel Airport",
        "City": "Malabo",
        "Country": "Equatorial Guinea"
    },
    {
        "id": "7036",
        "AirportCode": "SSH",
        "AirportName": "Ophira Airport",
        "City": "Sharm el Sheikh",
        "Country": "Egypt"
    },
    {
        "id": "7037",
        "AirportCode": "SSI",
        "AirportName": "McKinnon Airport",
        "City": "Brunswick",
        "Country": "USA Georgia"
    },
    {
        "id": "7038",
        "AirportCode": "SSJ",
        "AirportName": "Stokka Airport",
        "City": "Sandnessjoen",
        "Country": "Norway"
    },
    {
        "id": "7039",
        "AirportCode": "SSK",
        "AirportName": "Sturt Creek Airport",
        "City": "Sturt Creek",
        "Country": "Australia"
    },
    {
        "id": "7040",
        "AirportCode": "SSL",
        "AirportName": "Santa Rosalia Airport",
        "City": "Santa Rosalia",
        "Country": "Colombia"
    },
    {
        "id": "7041",
        "AirportCode": "SSN",
        "AirportName": "Seoul Ab Airport",
        "City": "Seoul",
        "Country": "Korea"
    },
    {
        "id": "7042",
        "AirportCode": "SSO",
        "AirportName": "Sao Lourenzo Airport",
        "City": "Sao Lourenzo",
        "Country": "Brazil"
    },
    {
        "id": "7043",
        "AirportCode": "SSP",
        "AirportName": "Silver Planes Airport",
        "City": "Silver Planes",
        "Country": "Australia"
    },
    {
        "id": "7044",
        "AirportCode": "SSQ",
        "AirportName": "La Sarre Airport",
        "City": "La Sarre",
        "Country": "Canada"
    },
    {
        "id": "7045",
        "AirportCode": "SSR",
        "AirportName": "Sara Airport",
        "City": "Sara",
        "Country": "Vanuatu"
    },
    {
        "id": "7046",
        "AirportCode": "SSS",
        "AirportName": "Siassi Airport",
        "City": "Siassi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7047",
        "AirportCode": "SST",
        "AirportName": "Santa Teresita Airport",
        "City": "Santa Teresita",
        "Country": "Argentina"
    },
    {
        "id": "7048",
        "AirportCode": "SSU",
        "AirportName": "Greenbrier Airport",
        "City": "White Sulphur Springs",
        "Country": "USA West Virginia"
    },
    {
        "id": "7049",
        "AirportCode": "SSV",
        "AirportName": "Siasi Airport",
        "City": "Siasi",
        "Country": "Philippines"
    },
    {
        "id": "7050",
        "AirportCode": "SSW",
        "AirportName": "Stuart Island Airport",
        "City": "Stuart Island",
        "Country": "USA Washington"
    },
    {
        "id": "7051",
        "AirportCode": "SSX",
        "AirportName": "Samsun Airport",
        "City": "Samsun",
        "Country": "Turkey"
    },
    {
        "id": "7052",
        "AirportCode": "SSZ",
        "AirportName": "Santos Airport",
        "City": "Santos",
        "Country": "Brazil"
    },
    {
        "id": "7053",
        "AirportCode": "STA",
        "AirportName": "Stauning Airport",
        "City": "Stauning",
        "Country": "Denmark"
    },
    {
        "id": "7054",
        "AirportCode": "STB",
        "AirportName": "L Delicias Airport",
        "City": "Santa Barbara Ed",
        "Country": "Venezuela"
    },
    {
        "id": "7055",
        "AirportCode": "STC",
        "AirportName": "St. Cloud Municipal Airport",
        "City": "Saint Cloud",
        "Country": "USA Minnesota"
    },
    {
        "id": "7056",
        "AirportCode": "STD",
        "AirportName": "Mayo Guerrero Airport",
        "City": "Santo Domingo",
        "Country": "Venezuela"
    },
    {
        "id": "7057",
        "AirportCode": "STE",
        "AirportName": "Stevens Point Airport",
        "City": "Stevens Point",
        "Country": "USA Wisconsin"
    },
    {
        "id": "7058",
        "AirportCode": "STF",
        "AirportName": "Stephen Island",
        "City": "Stephen Island",
        "Country": "Australia"
    },
    {
        "id": "7059",
        "AirportCode": "STG",
        "AirportName": "St. George Island Airport",
        "City": "Saint George Island",
        "Country": "USA Alaska"
    },
    {
        "id": "7060",
        "AirportCode": "STH",
        "AirportName": "Strathmore Airport",
        "City": "Strathmore",
        "Country": "Australia"
    },
    {
        "id": "7061",
        "AirportCode": "STI",
        "AirportName": "Santiago Municipal Airport",
        "City": "Santiago",
        "Country": "Dominican Republic"
    },
    {
        "id": "7062",
        "AirportCode": "STJ",
        "AirportName": "Rosecrans Memorial Airport",
        "City": "Saint Joseph",
        "Country": "USA Missouri"
    },
    {
        "id": "7063",
        "AirportCode": "STK",
        "AirportName": "Crosson Field",
        "City": "Sterling",
        "Country": "USA Colorado"
    },
    {
        "id": "7064",
        "AirportCode": "STL",
        "AirportName": "Lambert-St. Louis International Airport",
        "City": "Saint Louis",
        "Country": "USA Missouri"
    },
    {
        "id": "7065",
        "AirportCode": "STM",
        "AirportName": "Eduardo Gomes Airport",
        "City": "Santarem",
        "Country": "Brazil"
    },
    {
        "id": "7066",
        "AirportCode": "STN",
        "AirportName": "Stansted Airport",
        "City": "London",
        "Country": "United Kingdom"
    },
    {
        "id": "7067",
        "AirportCode": "STP",
        "AirportName": "St. Paul Downtown Airport",
        "City": "Saint Paul",
        "Country": "USA Minnesota"
    },
    {
        "id": "7068",
        "AirportCode": "STQ",
        "AirportName": "St. Marys Airport",
        "City": "Sainte Marys",
        "Country": "USA Philadelphia"
    },
    {
        "id": "7069",
        "AirportCode": "STR",
        "AirportName": "Echterdingen Airport",
        "City": "Stuttgart",
        "Country": "Germany"
    },
    {
        "id": "7070",
        "AirportCode": "STS",
        "AirportName": "Sonoma County Airport",
        "City": "Santa Rosa",
        "Country": "USA California"
    },
    {
        "id": "7071",
        "AirportCode": "STT",
        "AirportName": "Cyril E. King Airport",
        "City": "Saint Thomas",
        "Country": "US Virgin Islands"
    },
    {
        "id": "7072",
        "AirportCode": "STU",
        "AirportName": "Santa Cruz Airport",
        "City": "Santa Cruz",
        "Country": "Belize"
    },
    {
        "id": "7073",
        "AirportCode": "STV",
        "AirportName": "Surat Gujarat Airport",
        "City": "Surat Gujarat",
        "Country": "India"
    },
    {
        "id": "7074",
        "AirportCode": "STW",
        "AirportName": "Stavropol Airport",
        "City": "Stavropol",
        "Country": "Russia"
    },
    {
        "id": "7075",
        "AirportCode": "STX",
        "AirportName": "Alexander Hamilton Airport",
        "City": "Saint Croix",
        "Country": "US Virgin Islands"
    },
    {
        "id": "7076",
        "AirportCode": "STY",
        "AirportName": "Salto Airport",
        "City": "Salto",
        "Country": "Uruguay"
    },
    {
        "id": "7077",
        "AirportCode": "STZ",
        "AirportName": "Confresa Airport",
        "City": "Santa Teresinha",
        "Country": "Brazil"
    },
    {
        "id": "7078",
        "AirportCode": "SUA",
        "AirportName": "Witham Field",
        "City": "Stuart",
        "Country": "USA Florida"
    },
    {
        "id": "7079",
        "AirportCode": "SUB",
        "AirportName": "Juanda Airport",
        "City": "Surabaya",
        "Country": "Indonesia"
    },
    {
        "id": "7080",
        "AirportCode": "SUC",
        "AirportName": "Schloredt Airport",
        "City": "Sundance",
        "Country": "USA Wyoming"
    },
    {
        "id": "7081",
        "AirportCode": "SUD",
        "AirportName": "Stroud Airport",
        "City": "Stroud",
        "Country": "USA Oklahoma"
    },
    {
        "id": "7082",
        "AirportCode": "SUE",
        "AirportName": "Door County Airport",
        "City": "Sturgeon Bay",
        "Country": "USA Wisconsin"
    },
    {
        "id": "7083",
        "AirportCode": "SUF",
        "AirportName": "S Eufemia Airport",
        "City": "Lamezia-Terme",
        "Country": "Italy"
    },
    {
        "id": "7084",
        "AirportCode": "SUG",
        "AirportName": "Surigao Airport",
        "City": "Surigao",
        "Country": "Philippines"
    },
    {
        "id": "7085",
        "AirportCode": "SUI",
        "AirportName": "Babusheri Airport",
        "City": "Sukhumi",
        "Country": "Georgia"
    },
    {
        "id": "7086",
        "AirportCode": "SUJ",
        "AirportName": "Satu Mare Airport",
        "City": "Satu Mare",
        "Country": "Romania"
    },
    {
        "id": "7087",
        "AirportCode": "SUK",
        "AirportName": "Samcheok Airport",
        "City": "Samcheok",
        "Country": "Korea"
    },
    {
        "id": "7088",
        "AirportCode": "SUL",
        "AirportName": "Sui Airport",
        "City": "Sui",
        "Country": "Pakistan"
    },
    {
        "id": "7089",
        "AirportCode": "SUM",
        "AirportName": "Sumter Municipal Airport",
        "City": "Sumter",
        "Country": "USA South Carolina"
    },
    {
        "id": "7090",
        "AirportCode": "SUN",
        "AirportName": "Sun Valley Airport",
        "City": "Sun Valley",
        "Country": "USA Idaho"
    },
    {
        "id": "7091",
        "AirportCode": "SUO",
        "AirportName": "Sun River Airport",
        "City": "Sun River",
        "Country": "USA Oregon"
    },
    {
        "id": "7092",
        "AirportCode": "SUP",
        "AirportName": "Trunojoyo Airport",
        "City": "Sumenep",
        "Country": "Indonesia"
    },
    {
        "id": "7093",
        "AirportCode": "SUQ",
        "AirportName": "Sucua Airport",
        "City": "Sucua",
        "Country": "Ecuador"
    },
    {
        "id": "7094",
        "AirportCode": "SUR",
        "AirportName": "Summer Beaver Airport",
        "City": "Summer Beaver",
        "Country": "Canada"
    },
    {
        "id": "7095",
        "AirportCode": "SUS",
        "AirportName": "Spirit of St. Louis Airport",
        "City": "Saint Louis",
        "Country": "USA Missouri"
    },
    {
        "id": "7096",
        "AirportCode": "SUT",
        "AirportName": "Sumbawanga Airport",
        "City": "Sumbawanga",
        "Country": "Tanzania"
    },
    {
        "id": "7097",
        "AirportCode": "SUU",
        "AirportName": "Travis Air Force Base",
        "City": "Fairfield",
        "Country": "USA California"
    },
    {
        "id": "7098",
        "AirportCode": "SUV",
        "AirportName": "Nausori Airport",
        "City": "Suva",
        "Country": "Fiji"
    },
    {
        "id": "7099",
        "AirportCode": "SUW",
        "AirportName": "Richard I Bong Airport",
        "City": "Superior",
        "Country": "USA Wisconsin"
    },
    {
        "id": "7100",
        "AirportCode": "SUX",
        "AirportName": "Sioux Gateway Airport",
        "City": "Sioux City",
        "Country": "USA Iowa"
    },
    {
        "id": "7101",
        "AirportCode": "SUY",
        "AirportName": "Sudureyri Airport",
        "City": "Sudureyri",
        "Country": "Iceland"
    },
    {
        "id": "7102",
        "AirportCode": "SUZ",
        "AirportName": "Suria Airport",
        "City": "Suria",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7103",
        "AirportCode": "SVA",
        "AirportName": "Savoonga Airport",
        "City": "Savoonga",
        "Country": "USA Alaska"
    },
    {
        "id": "7104",
        "AirportCode": "SVB",
        "AirportName": "Sambava Airport",
        "City": "Sambava",
        "Country": "Madagascar"
    },
    {
        "id": "7105",
        "AirportCode": "SVC",
        "AirportName": "Grant County Airport",
        "City": "Silver City",
        "Country": "USA New Mexico"
    },
    {
        "id": "7106",
        "AirportCode": "SVD",
        "AirportName": "E. T. Joshua",
        "City": "Saint Vincent",
        "Country": "St Vincent"
    },
    {
        "id": "7107",
        "AirportCode": "SVE",
        "AirportName": "Susanville Airport",
        "City": "Susanville",
        "Country": "USA California"
    },
    {
        "id": "7108",
        "AirportCode": "SVF",
        "AirportName": "Save Airport",
        "City": "Save",
        "Country": "Benin"
    },
    {
        "id": "7109",
        "AirportCode": "SVG",
        "AirportName": "Sola Airport",
        "City": "Stavanger",
        "Country": "Norway"
    },
    {
        "id": "7110",
        "AirportCode": "SVH",
        "AirportName": "Statesville Municipal Airport",
        "City": "Statesville",
        "Country": "USA North Carolina"
    },
    {
        "id": "7111",
        "AirportCode": "SVI",
        "AirportName": "San Vicente Airport",
        "City": "San Vicente",
        "Country": "Colombia"
    },
    {
        "id": "7112",
        "AirportCode": "SVJ",
        "AirportName": "Helle Airport",
        "City": "Svolvaer",
        "Country": "Norway"
    },
    {
        "id": "7113",
        "AirportCode": "SVK",
        "AirportName": "Silver Creek Airport",
        "City": "Silver Creek",
        "Country": "Belize"
    },
    {
        "id": "7114",
        "AirportCode": "SVL",
        "AirportName": "Savonlinna Airport",
        "City": "Savonlinna",
        "Country": "Finland"
    },
    {
        "id": "7115",
        "AirportCode": "SVN",
        "AirportName": "Hunter Air Force Base",
        "City": "Savannah",
        "Country": "USA Georgia"
    },
    {
        "id": "7116",
        "AirportCode": "SVO",
        "AirportName": "Sheremetyevo Airport",
        "City": "Moscow",
        "Country": "Russia"
    },
    {
        "id": "7117",
        "AirportCode": "SVQ",
        "AirportName": "Sevilla Airport",
        "City": "Sevilla",
        "Country": "Spain"
    },
    {
        "id": "7118",
        "AirportCode": "SVR",
        "AirportName": "Svay Rieng Airport",
        "City": "Svay Rieng",
        "Country": "Cambodia"
    },
    {
        "id": "7119",
        "AirportCode": "SVS",
        "AirportName": "Stevens Village Airport",
        "City": "Stevens Village",
        "Country": "USA Alaska"
    },
    {
        "id": "7120",
        "AirportCode": "SVT",
        "AirportName": "Savuti Airport",
        "City": "Savuti",
        "Country": "Botswana"
    },
    {
        "id": "7121",
        "AirportCode": "SVU",
        "AirportName": "Savusavu Airport",
        "City": "Savusavu",
        "Country": "Fiji"
    },
    {
        "id": "7122",
        "AirportCode": "SVV",
        "AirportName": "San Salvador De Airport",
        "City": "San Salvador De",
        "Country": "Venezuela"
    },
    {
        "id": "7123",
        "AirportCode": "SVX",
        "AirportName": "Ekaterinburg Airport",
        "City": "Ekaterinburg",
        "Country": "Russia"
    },
    {
        "id": "7124",
        "AirportCode": "SVZ",
        "AirportName": "San Antonio Airport",
        "City": "San Antonio",
        "Country": "Venezuela"
    },
    {
        "id": "7125",
        "AirportCode": "SWA",
        "AirportName": "Shantou Airport",
        "City": "Shantou",
        "Country": "China"
    },
    {
        "id": "7126",
        "AirportCode": "SWB",
        "AirportName": "Shaw River Airport",
        "City": "Shaw River",
        "Country": "Australia"
    },
    {
        "id": "7127",
        "AirportCode": "SWC",
        "AirportName": "Stawell Airport",
        "City": "Stawell",
        "Country": "Australia"
    },
    {
        "id": "7128",
        "AirportCode": "SWD",
        "AirportName": "Seward Airport",
        "City": "Seward",
        "Country": "USA Alaska"
    },
    {
        "id": "7129",
        "AirportCode": "SWE",
        "AirportName": "Siwea Airport",
        "City": "Siwea",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7130",
        "AirportCode": "SWF",
        "AirportName": "Stewart Airport",
        "City": "Newburgh",
        "Country": "USA New York"
    },
    {
        "id": "7131",
        "AirportCode": "SWG",
        "AirportName": "Satwag Airport",
        "City": "Satwag",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7132",
        "AirportCode": "SWH",
        "AirportName": "Swan Hill Airport",
        "City": "Swan Hill",
        "Country": "Australia"
    },
    {
        "id": "7133",
        "AirportCode": "SWI",
        "AirportName": "Swindon Airport",
        "City": "Swindon",
        "Country": "United Kingdom"
    },
    {
        "id": "7134",
        "AirportCode": "SWJ",
        "AirportName": "South West Bay Airport",
        "City": "South West Bay",
        "Country": "Vanuatu"
    },
    {
        "id": "7135",
        "AirportCode": "SWK",
        "AirportName": "Segrate Airport",
        "City": "Milan",
        "Country": "Italy"
    },
    {
        "id": "7136",
        "AirportCode": "SWM",
        "AirportName": "Suia-Missu Airport",
        "City": "Suia-Missu",
        "Country": "Brazil"
    },
    {
        "id": "7137",
        "AirportCode": "SWN",
        "AirportName": "Sahiwal Airport",
        "City": "Sahiwal",
        "Country": "Pakistan"
    },
    {
        "id": "7138",
        "AirportCode": "SWO",
        "AirportName": "Searcy Field",
        "City": "Stillwater",
        "Country": "USA Oklahoma"
    },
    {
        "id": "7139",
        "AirportCode": "SWP",
        "AirportName": "Swakopmund Airport",
        "City": "Swakopmund",
        "Country": "Namibia"
    },
    {
        "id": "7140",
        "AirportCode": "SWQ",
        "AirportName": "Brang Bidji Airport",
        "City": "Sumbawa Besar",
        "Country": "Indonesia"
    },
    {
        "id": "7141",
        "AirportCode": "SWR",
        "AirportName": "Silur Airport",
        "City": "Silur",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7142",
        "AirportCode": "SWS",
        "AirportName": "Fairwood Comm Airport",
        "City": "Swansea",
        "Country": "United Kingdom"
    },
    {
        "id": "7143",
        "AirportCode": "SWT",
        "AirportName": "Strzhewoi Airport",
        "City": "Strzhewoi",
        "Country": "Russia"
    },
    {
        "id": "7144",
        "AirportCode": "SWU",
        "AirportName": "Suwon Airport",
        "City": "Suwon",
        "Country": "Korea"
    },
    {
        "id": "7145",
        "AirportCode": "SWV",
        "AirportName": "Shikarpur Airport",
        "City": "Shikarpur",
        "Country": "Pakistan"
    },
    {
        "id": "7146",
        "AirportCode": "SWW",
        "AirportName": "Sweetwater Airport",
        "City": "Sweetwater",
        "Country": "USA Texas"
    },
    {
        "id": "7147",
        "AirportCode": "SWX",
        "AirportName": "Shakawe Airport",
        "City": "Shakawe",
        "Country": "Botswana"
    },
    {
        "id": "7148",
        "AirportCode": "SWY",
        "AirportName": "Sitiawan Airport",
        "City": "Sitiawan",
        "Country": "Malaysia"
    },
    {
        "id": "7149",
        "AirportCode": "SWZ",
        "AirportName": "Sydney West Airport",
        "City": "Sydney",
        "Country": "Australia"
    },
    {
        "id": "7150",
        "AirportCode": "SXA",
        "AirportName": "Sialum Airport",
        "City": "Sialum",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7151",
        "AirportCode": "SXB",
        "AirportName": "Entzheim Airport",
        "City": "Strasbourg",
        "Country": "France"
    },
    {
        "id": "7152",
        "AirportCode": "SXC",
        "AirportName": "Avalon Vor/WP Airport",
        "City": "Catalina Island",
        "Country": "USA California"
    },
    {
        "id": "7153",
        "AirportCode": "SXD",
        "AirportName": "Sophia Antipolis Airport",
        "City": "Sophia Antipolis",
        "Country": "France"
    },
    {
        "id": "7154",
        "AirportCode": "SXE",
        "AirportName": "Sale Airport",
        "City": "Sale",
        "Country": "Australia"
    },
    {
        "id": "7155",
        "AirportCode": "SXF",
        "AirportName": "Schoenefeld Airport",
        "City": "Berlin",
        "Country": "Germany"
    },
    {
        "id": "7156",
        "AirportCode": "SXG",
        "AirportName": "Senanga Airport",
        "City": "Senanga",
        "Country": "Zambia"
    },
    {
        "id": "7157",
        "AirportCode": "SXH",
        "AirportName": "Sehulea Airport",
        "City": "Sehulea",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7158",
        "AirportCode": "SXI",
        "AirportName": "Sirri Island Airport",
        "City": "Sirri Island",
        "Country": "Iran"
    },
    {
        "id": "7159",
        "AirportCode": "SXJ",
        "AirportName": "Shanshan Airport",
        "City": "Shanshan",
        "Country": "China"
    },
    {
        "id": "7160",
        "AirportCode": "SXK",
        "AirportName": "Saumlaki Airport",
        "City": "Saumlaki",
        "Country": "Indonesia"
    },
    {
        "id": "7161",
        "AirportCode": "SXL",
        "AirportName": "Collooney Airport",
        "City": "Sligo",
        "Country": "Ireland"
    },
    {
        "id": "7162",
        "AirportCode": "SXM",
        "AirportName": "Princ. Juliana Airport",
        "City": "Saint Maarten",
        "Country": "Netherlands Antilles"
    },
    {
        "id": "7163",
        "AirportCode": "SXN",
        "AirportName": "Suapan Airport",
        "City": "Suapan",
        "Country": "Botswana"
    },
    {
        "id": "7164",
        "AirportCode": "SXO",
        "AirportName": "Sao Felix Do Araguaia Airport",
        "City": "Sao Felix Do Araguaia",
        "Country": "Brazil"
    },
    {
        "id": "7165",
        "AirportCode": "SXP",
        "AirportName": "Sheldon Sea Plane Base",
        "City": "Sheldon Point",
        "Country": "USA Alaska"
    },
    {
        "id": "7166",
        "AirportCode": "SXQ",
        "AirportName": "Soldotna Airport",
        "City": "Soldotna",
        "Country": "USA Alaska"
    },
    {
        "id": "7167",
        "AirportCode": "SXR",
        "AirportName": "Srinagar Airport",
        "City": "Srinagar",
        "Country": "India"
    },
    {
        "id": "7168",
        "AirportCode": "SXS",
        "AirportName": "Sahabat 16 Airport",
        "City": "Sahabat 16",
        "Country": "Malaysia"
    },
    {
        "id": "7169",
        "AirportCode": "SXT",
        "AirportName": "Taman Negara Airport",
        "City": "Taman Negara",
        "Country": "Malaysia"
    },
    {
        "id": "7170",
        "AirportCode": "SXU",
        "AirportName": "Soddu Airport",
        "City": "Soddu",
        "Country": "Ethiopia"
    },
    {
        "id": "7171",
        "AirportCode": "SXV",
        "AirportName": "Salem Airport",
        "City": "Salem",
        "Country": "India"
    },
    {
        "id": "7172",
        "AirportCode": "SXW",
        "AirportName": "Sauren Airport",
        "City": "Sauren",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7173",
        "AirportCode": "SXX",
        "AirportName": "Sao Felix Do Xingu Airport",
        "City": "Sao Felix Do Xingu",
        "Country": "Brazil"
    },
    {
        "id": "7174",
        "AirportCode": "SXY",
        "AirportName": "Sidney Airport",
        "City": "Sidney",
        "Country": "USA New York"
    },
    {
        "id": "7175",
        "AirportCode": "SXZ",
        "AirportName": "Siirt Airport",
        "City": "Siirt",
        "Country": "Turkey"
    },
    {
        "id": "7176",
        "AirportCode": "SYB",
        "AirportName": "Seal Bay Airport",
        "City": "Seal Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "7177",
        "AirportCode": "SYC",
        "AirportName": "Shiringayoc O Hda Mejia",
        "City": "Leon Velarde",
        "Country": "Peru"
    },
    {
        "id": "7178",
        "AirportCode": "SYD",
        "AirportName": "Kingsford Smith Airport",
        "City": "Sydney",
        "Country": "Australia"
    },
    {
        "id": "7179",
        "AirportCode": "SYE",
        "AirportName": "Sadah Airport",
        "City": "Sadah",
        "Country": "Yemen"
    },
    {
        "id": "7180",
        "AirportCode": "SYF",
        "AirportName": "Silva Bay Airport",
        "City": "Silva Bay",
        "Country": "Canada"
    },
    {
        "id": "7181",
        "AirportCode": "SYG",
        "AirportName": "Spitsberg Airport",
        "City": "Svalbard",
        "Country": "Norway"
    },
    {
        "id": "7182",
        "AirportCode": "SYI",
        "AirportName": "Bomar Field",
        "City": "Shelbyville",
        "Country": "USA Tennessee"
    },
    {
        "id": "7183",
        "AirportCode": "SYJ",
        "AirportName": "Sirjan Airport",
        "City": "Sirjan",
        "Country": "Iran"
    },
    {
        "id": "7184",
        "AirportCode": "SYK",
        "AirportName": "Stykkisholmur Airport",
        "City": "Stykkisholmur",
        "Country": "Iceland"
    },
    {
        "id": "7185",
        "AirportCode": "SYL",
        "AirportName": "Roberts Air Force Base",
        "City": "San Miguel",
        "Country": "USA California"
    },
    {
        "id": "7186",
        "AirportCode": "SYM",
        "AirportName": "Simao Airport",
        "City": "Simao",
        "Country": "China"
    },
    {
        "id": "7187",
        "AirportCode": "SYN",
        "AirportName": "Carleton Airport",
        "City": "Stanton",
        "Country": "USA Minnesota"
    },
    {
        "id": "7188",
        "AirportCode": "SYO",
        "AirportName": "Shonai Airport",
        "City": "Shonai",
        "Country": "Japan"
    },
    {
        "id": "7189",
        "AirportCode": "SYP",
        "AirportName": "Santiago Airport",
        "City": "Santiago",
        "Country": "Panama"
    },
    {
        "id": "7190",
        "AirportCode": "SYQ",
        "AirportName": "Tobias Bolanos International Airport",
        "City": "San Jose",
        "Country": "Costa Rica"
    },
    {
        "id": "7191",
        "AirportCode": "SYR",
        "AirportName": "Hancock International Airport",
        "City": "Syracuse",
        "Country": "USA New York"
    },
    {
        "id": "7192",
        "AirportCode": "SYS",
        "AirportName": "Yeosu Airport",
        "City": "Suncheon",
        "Country": "Korea"
    },
    {
        "id": "7193",
        "AirportCode": "SYT",
        "AirportName": "Charolais Bourgogne Sud Airport",
        "City": "Saint Yan",
        "Country": "France"
    },
    {
        "id": "7194",
        "AirportCode": "SYU",
        "AirportName": "Warraber Island Airport",
        "City": "Sue Island",
        "Country": "Australia"
    },
    {
        "id": "7195",
        "AirportCode": "SYV",
        "AirportName": "Sylvester Airport",
        "City": "Sylvester",
        "Country": "USA Georgia"
    },
    {
        "id": "7196",
        "AirportCode": "SYW",
        "AirportName": "Sehwen Sharif Airport",
        "City": "Sehwen Sharif",
        "Country": "Pakistan"
    },
    {
        "id": "7197",
        "AirportCode": "SYX",
        "AirportName": "Sanya Airport",
        "City": "Sanya",
        "Country": "China"
    },
    {
        "id": "7198",
        "AirportCode": "SYY",
        "AirportName": "Stornoway Airport",
        "City": "Stornoway, Outer Stat Hebrides",
        "Country": "United Kingdom"
    },
    {
        "id": "7199",
        "AirportCode": "SYZ",
        "AirportName": "Shiraz Airport",
        "City": "Shiraz",
        "Country": "Iran"
    },
    {
        "id": "7200",
        "AirportCode": "SZA",
        "AirportName": "Soyo Airport",
        "City": "Soyo",
        "Country": "Angola"
    },
    {
        "id": "7201",
        "AirportCode": "SZB",
        "AirportName": "Sultan Abdul Aziz Shah Airport",
        "City": "Kuala Lumpur (KUL)",
        "Country": "Malaysia"
    },
    {
        "id": "7202",
        "AirportCode": "SZC",
        "AirportName": "Guanacaste Airport",
        "City": "Santa Cruz",
        "Country": "Costa Rica"
    },
    {
        "id": "7203",
        "AirportCode": "SZD",
        "AirportName": "Sheffield City Airport",
        "City": "Sheffield",
        "Country": "United Kingdom"
    },
    {
        "id": "7204",
        "AirportCode": "SZE",
        "AirportName": "Semera Airport",
        "City": "Semera",
        "Country": "Ethiopia"
    },
    {
        "id": "7205",
        "AirportCode": "SZF",
        "AirportName": "Carsamba Airport",
        "City": "Samsun",
        "Country": "Turkey"
    },
    {
        "id": "7206",
        "AirportCode": "SZG",
        "AirportName": "W. A. Mozart Airport",
        "City": "Salzburg",
        "Country": "Austria"
    },
    {
        "id": "7207",
        "AirportCode": "SZH",
        "AirportName": "Senipah Airport",
        "City": "Senipah",
        "Country": "Indonesia"
    },
    {
        "id": "7208",
        "AirportCode": "SZI",
        "AirportName": "Zaisan Airport",
        "City": "Zaisan",
        "Country": "Kazakhstan"
    },
    {
        "id": "7209",
        "AirportCode": "SZJ",
        "AirportName": "Siguanea Airport",
        "City": "Siguanea",
        "Country": "Cuba"
    },
    {
        "id": "7210",
        "AirportCode": "SZK",
        "AirportName": "Skukuza Airport",
        "City": "Skukuza",
        "Country": "South Africa"
    },
    {
        "id": "7211",
        "AirportCode": "SZL",
        "AirportName": "Whiteman Air Force Base",
        "City": "Warrensburg",
        "Country": "USA Missouri"
    },
    {
        "id": "7212",
        "AirportCode": "SZM",
        "AirportName": "Sesriem Airport",
        "City": "Sesriem",
        "Country": "Namibia"
    },
    {
        "id": "7213",
        "AirportCode": "SZN",
        "AirportName": "Santa Cruz Island Airport",
        "City": "Santa Barbara",
        "Country": "USA California"
    },
    {
        "id": "7214",
        "AirportCode": "SZO",
        "AirportName": "Shanzhou Airport",
        "City": "Shanzhou",
        "Country": "China"
    },
    {
        "id": "7215",
        "AirportCode": "SZP",
        "AirportName": "Santa Paula Airport",
        "City": "Santa Paula",
        "Country": "USA California"
    },
    {
        "id": "7216",
        "AirportCode": "SZQ",
        "AirportName": "Saenz Pena Airport",
        "City": "Saenz Pena",
        "Country": "Argentina"
    },
    {
        "id": "7217",
        "AirportCode": "SZR",
        "AirportName": "Stara Zagora Airport",
        "City": "Stara Zagora",
        "Country": "Bulgaria"
    },
    {
        "id": "7218",
        "AirportCode": "SZS",
        "AirportName": "Stewart Island Airport",
        "City": "Stewart Island",
        "Country": "New Zealand"
    },
    {
        "id": "7219",
        "AirportCode": "SZT",
        "AirportName": "San Cristobal de las Casas Airport",
        "City": "San Cristobal de las Casas",
        "Country": "Mexico"
    },
    {
        "id": "7220",
        "AirportCode": "SZU",
        "AirportName": "Segou Airport",
        "City": "Segou",
        "Country": "Mali"
    },
    {
        "id": "7221",
        "AirportCode": "SZV",
        "AirportName": "Suzhou Airport",
        "City": "Suzhou",
        "Country": "China"
    },
    {
        "id": "7222",
        "AirportCode": "SZW",
        "AirportName": "Parchim Airport",
        "City": "Schwerin",
        "Country": "Germany"
    },
    {
        "id": "7223",
        "AirportCode": "SZX",
        "AirportName": "Shenzhen Airport",
        "City": "Shenzhen",
        "Country": "China"
    },
    {
        "id": "7224",
        "AirportCode": "SZY",
        "AirportName": "Mazury Airport",
        "City": "Szymany",
        "Country": "Poland"
    },
    {
        "id": "7225",
        "AirportCode": "SZZ",
        "AirportName": "Goleniow Airport",
        "City": "Szczecin",
        "Country": "Poland"
    },
    {
        "id": "7226",
        "AirportCode": "TAB",
        "AirportName": "Tobago Airport",
        "City": "Tobago",
        "Country": "Trinidad & Tobago"
    },
    {
        "id": "7227",
        "AirportCode": "TAC",
        "AirportName": "D. Z. Romualdez Airport",
        "City": "Tacloban",
        "Country": "Philippines"
    },
    {
        "id": "7228",
        "AirportCode": "TAD",
        "AirportName": "Las Animas Airport",
        "City": "Trinidad",
        "Country": "USA Colorado"
    },
    {
        "id": "7229",
        "AirportCode": "TAE",
        "AirportName": "Daegu Airport",
        "City": "Daegu",
        "Country": "Korea"
    },
    {
        "id": "7230",
        "AirportCode": "TAF",
        "AirportName": "Tafaraoui Airport",
        "City": "Oran",
        "Country": "Algeria"
    },
    {
        "id": "7231",
        "AirportCode": "TAG",
        "AirportName": "Tagbilaran Airport",
        "City": "Tagbilaran",
        "Country": "Philippines"
    },
    {
        "id": "7232",
        "AirportCode": "TAH",
        "AirportName": "Tanna Airport",
        "City": "Tanna",
        "Country": "Vanuatu"
    },
    {
        "id": "7233",
        "AirportCode": "TAI",
        "AirportName": "Al Janad Airport",
        "City": "Taiz",
        "Country": "Yemen"
    },
    {
        "id": "7234",
        "AirportCode": "TAJ",
        "AirportName": "Tadji Airport",
        "City": "Aitape",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7235",
        "AirportCode": "TAK",
        "AirportName": "Takamatsu Airport",
        "City": "Takamatsu",
        "Country": "Japan"
    },
    {
        "id": "7236",
        "AirportCode": "TAL",
        "AirportName": "Ralph Calhoun Airport",
        "City": "Tanana",
        "Country": "USA Alaska"
    },
    {
        "id": "7237",
        "AirportCode": "TAM",
        "AirportName": "Gen F Javier Mina Airport",
        "City": "Tampico",
        "Country": "Mexico"
    },
    {
        "id": "7238",
        "AirportCode": "TAN",
        "AirportName": "Tangalooma Airport",
        "City": "Tangalooma",
        "Country": "Australia"
    },
    {
        "id": "7239",
        "AirportCode": "TAO",
        "AirportName": "Qingdao Airport",
        "City": "Qingdao",
        "Country": "China"
    },
    {
        "id": "7240",
        "AirportCode": "TAP",
        "AirportName": "Tapachula International Airport",
        "City": "Tapachula",
        "Country": "Mexico"
    },
    {
        "id": "7241",
        "AirportCode": "TAQ",
        "AirportName": "Tarcoola Airport",
        "City": "Tarcoola",
        "Country": "Australia"
    },
    {
        "id": "7242",
        "AirportCode": "TAR",
        "AirportName": "M. A. Grottag Airport",
        "City": "Taranto",
        "Country": "Italy"
    },
    {
        "id": "7243",
        "AirportCode": "TAS",
        "AirportName": "Vostochny Airport",
        "City": "Tashkent",
        "Country": "Uzbekistan"
    },
    {
        "id": "7244",
        "AirportCode": "TAT",
        "AirportName": "Poprad/Tatry Airport",
        "City": "Poprad/Tatry",
        "Country": "Slovakia"
    },
    {
        "id": "7245",
        "AirportCode": "TAU",
        "AirportName": "Tauramena Airport",
        "City": "Tauramena",
        "Country": "Colombia"
    },
    {
        "id": "7246",
        "AirportCode": "TAV",
        "AirportName": "Tau Airport",
        "City": "Tau",
        "Country": "Samoa"
    },
    {
        "id": "7247",
        "AirportCode": "TAW",
        "AirportName": "Tacuarembo Airport",
        "City": "Tacuarembo",
        "Country": "Uruguay"
    },
    {
        "id": "7248",
        "AirportCode": "TAX",
        "AirportName": "Taliabu Airport",
        "City": "Taliabu",
        "Country": "Indonesia"
    },
    {
        "id": "7249",
        "AirportCode": "TAY",
        "AirportName": "Tartu Airport",
        "City": "Tartu",
        "Country": "Estonia"
    },
    {
        "id": "7250",
        "AirportCode": "TAZ",
        "AirportName": "Dashoguz Airport",
        "City": "Dashoguz",
        "Country": "Turkmenistan"
    },
    {
        "id": "7251",
        "AirportCode": "TBA",
        "AirportName": "Tabibuga Airport",
        "City": "Tabibuga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7252",
        "AirportCode": "TBB",
        "AirportName": "Tuy Hoa Airport",
        "City": "Tuy Hoa",
        "Country": "Vietnam"
    },
    {
        "id": "7253",
        "AirportCode": "TBC",
        "AirportName": "Tuba City Airport",
        "City": "Tuba City",
        "Country": "USA Arizona"
    },
    {
        "id": "7254",
        "AirportCode": "TBD",
        "AirportName": "Timbiqui Airport",
        "City": "Timbiqui",
        "Country": "Colombia"
    },
    {
        "id": "7255",
        "AirportCode": "TBE",
        "AirportName": "Timbunke Airport",
        "City": "Timbunke",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7256",
        "AirportCode": "TBF",
        "AirportName": "Tabiteuea Airport",
        "City": "Tabiteuea",
        "Country": "Kiribati"
    },
    {
        "id": "7257",
        "AirportCode": "TBG",
        "AirportName": "Tabubil Airport",
        "City": "Tabubil",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7258",
        "AirportCode": "TBH",
        "AirportName": "Tablas Airport",
        "City": "Tablas",
        "Country": "Philippines"
    },
    {
        "id": "7259",
        "AirportCode": "TBI",
        "AirportName": "The Bight Airport",
        "City": "The Bight",
        "Country": "Bahamas"
    },
    {
        "id": "7260",
        "AirportCode": "TBJ",
        "AirportName": "Tabarka Airport",
        "City": "Tabarka",
        "Country": "Tunisia"
    },
    {
        "id": "7261",
        "AirportCode": "TBK",
        "AirportName": "Timber Creek Airport",
        "City": "Timber Creek",
        "Country": "Australia"
    },
    {
        "id": "7262",
        "AirportCode": "TBL",
        "AirportName": "Tableland Airport",
        "City": "Tableland",
        "Country": "Australia"
    },
    {
        "id": "7263",
        "AirportCode": "TBM",
        "AirportName": "Tumbang Samba Airport",
        "City": "Tumbang Samba",
        "Country": "Indonesia"
    },
    {
        "id": "7264",
        "AirportCode": "TBN",
        "AirportName": "Forney Army Air Field",
        "City": "Fort Leonard Wood",
        "Country": "USA Missouri"
    },
    {
        "id": "7265",
        "AirportCode": "TBO",
        "AirportName": "Tabora Airport",
        "City": "Tabora",
        "Country": "Tanzania"
    },
    {
        "id": "7266",
        "AirportCode": "TBP",
        "AirportName": "Tumbes Airport",
        "City": "Tumbes",
        "Country": "Peru"
    },
    {
        "id": "7267",
        "AirportCode": "TBR",
        "AirportName": "Statesboro Municipal Airport",
        "City": "Statesboro",
        "Country": "USA Georgia"
    },
    {
        "id": "7268",
        "AirportCode": "TBS",
        "AirportName": "Novo Alexeyevka Airport",
        "City": "Tbilisi",
        "Country": "Georgia"
    },
    {
        "id": "7269",
        "AirportCode": "TBT",
        "AirportName": "Tabatinga Internacional Airport",
        "City": "Tabatinga",
        "Country": "Brazil"
    },
    {
        "id": "7270",
        "AirportCode": "TBV",
        "AirportName": "Tabal Airport",
        "City": "Tabal",
        "Country": "Marshall Islands"
    },
    {
        "id": "7271",
        "AirportCode": "TBW",
        "AirportName": "Tambov Airport",
        "City": "Tambov",
        "Country": "Russia"
    },
    {
        "id": "7272",
        "AirportCode": "TBY",
        "AirportName": "Tsabong Airport",
        "City": "Tsabong",
        "Country": "Botswana"
    },
    {
        "id": "7273",
        "AirportCode": "TBZ",
        "AirportName": "Tabriz Airport",
        "City": "Tabriz",
        "Country": "Iran"
    },
    {
        "id": "7274",
        "AirportCode": "TCA",
        "AirportName": "Tennant Creek Airport",
        "City": "Tennant Creek",
        "Country": "Australia"
    },
    {
        "id": "7275",
        "AirportCode": "TCB",
        "AirportName": "Treasure Cay Airport",
        "City": "Treasure Cay",
        "Country": "Bahamas"
    },
    {
        "id": "7276",
        "AirportCode": "TCC",
        "AirportName": "Tucumcari Airport",
        "City": "Tucumcari",
        "Country": "USA New Mexico"
    },
    {
        "id": "7277",
        "AirportCode": "TCD",
        "AirportName": "Tarapaca Airport",
        "City": "Tarapaca",
        "Country": "Colombia"
    },
    {
        "id": "7278",
        "AirportCode": "TCE",
        "AirportName": "Tulcea Airport",
        "City": "Tulcea",
        "Country": "Romania"
    },
    {
        "id": "7279",
        "AirportCode": "TCF",
        "AirportName": "Tocoa Airport",
        "City": "Tocoa",
        "Country": "Honduras"
    },
    {
        "id": "7280",
        "AirportCode": "TCG",
        "AirportName": "Tacheng Airport",
        "City": "Tacheng",
        "Country": "China"
    },
    {
        "id": "7281",
        "AirportCode": "TCH",
        "AirportName": "Tchibanga Airport",
        "City": "Tchibanga",
        "Country": "Gabon"
    },
    {
        "id": "7282",
        "AirportCode": "TCJ",
        "AirportName": "Torembi Airport",
        "City": "Torembi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7283",
        "AirportCode": "TCK",
        "AirportName": "Tinboli Airport",
        "City": "Tinboli",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7284",
        "AirportCode": "TCL",
        "AirportName": "Van De Graaf Airport",
        "City": "Tuscaloosa",
        "Country": "USA Alabama"
    },
    {
        "id": "7285",
        "AirportCode": "TCM",
        "AirportName": "McChord Air Force Base",
        "City": "Tacoma",
        "Country": "USA Washington"
    },
    {
        "id": "7286",
        "AirportCode": "TCN",
        "AirportName": "Tehuacan Airport",
        "City": "Tehuacan",
        "Country": "Mexico"
    },
    {
        "id": "7287",
        "AirportCode": "TCO",
        "AirportName": "La Florida Airport",
        "City": "Tumaco",
        "Country": "Colombia"
    },
    {
        "id": "7288",
        "AirportCode": "TCP",
        "AirportName": "Taba International Airport",
        "City": "Taba",
        "Country": "Egypt"
    },
    {
        "id": "7289",
        "AirportCode": "TCQ",
        "AirportName": "Tacna Airport",
        "City": "Tacna",
        "Country": "Peru"
    },
    {
        "id": "7290",
        "AirportCode": "TCR",
        "AirportName": "Tuticorin Airport",
        "City": "Tuticorin",
        "Country": "India"
    },
    {
        "id": "7291",
        "AirportCode": "TCS",
        "AirportName": "Truth Or Consequences Municipal Airport",
        "City": "Truth Or Consequences",
        "Country": "USA New Mexico"
    },
    {
        "id": "7292",
        "AirportCode": "TCT",
        "AirportName": "Takotna Airport",
        "City": "Takotna",
        "Country": "USA Alaska"
    },
    {
        "id": "7293",
        "AirportCode": "TCU",
        "AirportName": "Thaba Nchu Airport",
        "City": "Thaba Nchu",
        "Country": "South Africa"
    },
    {
        "id": "7294",
        "AirportCode": "TCV",
        "AirportName": "Tete Airport",
        "City": "Tete",
        "Country": "Mozambique"
    },
    {
        "id": "7295",
        "AirportCode": "TCW",
        "AirportName": "Tocumwal Airport",
        "City": "Tocumwal",
        "Country": "Australia"
    },
    {
        "id": "7296",
        "AirportCode": "TCX",
        "AirportName": "Tabas Airport",
        "City": "Tabas",
        "Country": "Iran"
    },
    {
        "id": "7297",
        "AirportCode": "TCY",
        "AirportName": "Terrace Bay Airport",
        "City": "Terrace Bay",
        "Country": "Namibia"
    },
    {
        "id": "7298",
        "AirportCode": "TDA",
        "AirportName": "Trinidad Airport",
        "City": "Trinidad",
        "Country": "Colombia"
    },
    {
        "id": "7299",
        "AirportCode": "TDB",
        "AirportName": "Tetabedi Airport",
        "City": "Tetabedi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7300",
        "AirportCode": "TDD",
        "AirportName": "Trinidad Airport",
        "City": "Trinidad",
        "Country": "Bolivia"
    },
    {
        "id": "7301",
        "AirportCode": "TDG",
        "AirportName": "Tandag Airport",
        "City": "Tandag",
        "Country": "Philippines"
    },
    {
        "id": "7302",
        "AirportCode": "TDJ",
        "AirportName": "Tadjoura Airport",
        "City": "Tadjoura",
        "Country": "Djibouti"
    },
    {
        "id": "7303",
        "AirportCode": "TDK",
        "AirportName": "Taldy-Kurgan Airport",
        "City": "Taldy-Kurgan",
        "Country": "Kazakhstan"
    },
    {
        "id": "7304",
        "AirportCode": "TDL",
        "AirportName": "Tandil Airport",
        "City": "Tandil",
        "Country": "Argentina"
    },
    {
        "id": "7305",
        "AirportCode": "TDN",
        "AirportName": "Theda Station Airport",
        "City": "Theda",
        "Country": "Australia"
    },
    {
        "id": "7306",
        "AirportCode": "TDO",
        "AirportName": "Winlock Airport",
        "City": "Toledo",
        "Country": "USA Washington"
    },
    {
        "id": "7307",
        "AirportCode": "TDR",
        "AirportName": "Theodore Airport",
        "City": "Theodore",
        "Country": "Australia"
    },
    {
        "id": "7308",
        "AirportCode": "TDT",
        "AirportName": "Tanda Tula Airport",
        "City": "Tanda Tula",
        "Country": "South Africa"
    },
    {
        "id": "7309",
        "AirportCode": "TDV",
        "AirportName": "Tanandava Airport",
        "City": "Tanandava",
        "Country": "Madagascar"
    },
    {
        "id": "7310",
        "AirportCode": "TDW",
        "AirportName": "Tradewind Airport",
        "City": "Amarillo",
        "Country": "USA Texas"
    },
    {
        "id": "7311",
        "AirportCode": "TDX",
        "AirportName": "Trat Airport",
        "City": "Trat",
        "Country": "Thailand"
    },
    {
        "id": "7312",
        "AirportCode": "TDZ",
        "AirportName": "Toledo Airport",
        "City": "Toledo",
        "Country": "USA Ohio"
    },
    {
        "id": "7313",
        "AirportCode": "TEA",
        "AirportName": "Tela Airport",
        "City": "Tela",
        "Country": "Honduras"
    },
    {
        "id": "7314",
        "AirportCode": "TEB",
        "AirportName": "Teterboro Airport",
        "City": "Teterboro",
        "Country": "USA New Jersey"
    },
    {
        "id": "7315",
        "AirportCode": "TEC",
        "AirportName": "Telemaco Borba Airport",
        "City": "Telemaco Borba",
        "Country": "Brazil"
    },
    {
        "id": "7316",
        "AirportCode": "TED",
        "AirportName": "Thisted Airport",
        "City": "Thisted",
        "Country": "Denmark"
    },
    {
        "id": "7317",
        "AirportCode": "TEE",
        "AirportName": "Tebessa Airport",
        "City": "Tebessa",
        "Country": "Algeria"
    },
    {
        "id": "7318",
        "AirportCode": "TEF",
        "AirportName": "Telfer Airport",
        "City": "Telfer",
        "Country": "Australia"
    },
    {
        "id": "7319",
        "AirportCode": "TEG",
        "AirportName": "Tenkodogo Airport",
        "City": "Tenkodogo",
        "Country": "Burkina Faso"
    },
    {
        "id": "7320",
        "AirportCode": "TEH",
        "AirportName": "Tetlin Airport",
        "City": "Tetlin",
        "Country": "USA Alaska"
    },
    {
        "id": "7321",
        "AirportCode": "TEI",
        "AirportName": "Tezu Airport",
        "City": "Tezu",
        "Country": "India"
    },
    {
        "id": "7322",
        "AirportCode": "TEK",
        "AirportName": "Tatitlek Airport",
        "City": "Tatitlek",
        "Country": "USA Alaska"
    },
    {
        "id": "7323",
        "AirportCode": "TEL",
        "AirportName": "Telupid Airport",
        "City": "Telupid",
        "Country": "Malaysia"
    },
    {
        "id": "7324",
        "AirportCode": "TEM",
        "AirportName": "Temora Airport",
        "City": "Temora",
        "Country": "Australia"
    },
    {
        "id": "7325",
        "AirportCode": "TEN",
        "AirportName": "Tongren Airport",
        "City": "Tongren",
        "Country": "China"
    },
    {
        "id": "7326",
        "AirportCode": "TEO",
        "AirportName": "Terapo Airport",
        "City": "Terapo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7327",
        "AirportCode": "TEP",
        "AirportName": "Teptep Airport",
        "City": "Teptep",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7328",
        "AirportCode": "TEQ",
        "AirportName": "Corlu Airport",
        "City": "Tekirdag",
        "Country": "Turkey"
    },
    {
        "id": "7329",
        "AirportCode": "TER",
        "AirportName": "Lajes Airport",
        "City": "Terceira",
        "Country": "Portugal"
    },
    {
        "id": "7330",
        "AirportCode": "TES",
        "AirportName": "Tessenei Airport",
        "City": "Tessenei",
        "Country": "Eritrea"
    },
    {
        "id": "7331",
        "AirportCode": "TET",
        "AirportName": "Matundo Airport",
        "City": "Tete",
        "Country": "Mozambique"
    },
    {
        "id": "7332",
        "AirportCode": "TEU",
        "AirportName": "Manapouri Airport",
        "City": "Te Anau",
        "Country": "New Zealand"
    },
    {
        "id": "7333",
        "AirportCode": "TEW",
        "AirportName": "Tohid Airport",
        "City": "Tohid",
        "Country": "Iran"
    },
    {
        "id": "7334",
        "AirportCode": "TEX",
        "AirportName": "Telluride Airport",
        "City": "Telluride",
        "Country": "USA Colorado"
    },
    {
        "id": "7335",
        "AirportCode": "TEY",
        "AirportName": "Thingeyri Airport",
        "City": "Thingeyri",
        "Country": "Iceland"
    },
    {
        "id": "7336",
        "AirportCode": "TEZ",
        "AirportName": "Salonibari Airport",
        "City": "Tezpur",
        "Country": "India"
    },
    {
        "id": "7337",
        "AirportCode": "TFB",
        "AirportName": "Tifalmin Airport",
        "City": "Tifalmin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7338",
        "AirportCode": "TFF",
        "AirportName": "Tefe Airport",
        "City": "Tefe",
        "Country": "Brazil"
    },
    {
        "id": "7339",
        "AirportCode": "TFI",
        "AirportName": "Tufi Airport",
        "City": "Tufi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7340",
        "AirportCode": "TFL",
        "AirportName": "Teofilo Otoni Airport",
        "City": "Teofilo Otoni",
        "Country": "Brazil"
    },
    {
        "id": "7341",
        "AirportCode": "TFM",
        "AirportName": "Telefomin Airport",
        "City": "Telefomin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7342",
        "AirportCode": "TFN",
        "AirportName": "Norte Los Rodeos Airport",
        "City": "Tenerife",
        "Country": "Spain"
    },
    {
        "id": "7343",
        "AirportCode": "TFR",
        "AirportName": "Ramadan Airport",
        "City": "Ramadan",
        "Country": "Egypt"
    },
    {
        "id": "7344",
        "AirportCode": "TFS",
        "AirportName": "Sur Reina Sofia Airport",
        "City": "Tenerife",
        "Country": "Spain"
    },
    {
        "id": "7345",
        "AirportCode": "TFT",
        "AirportName": "Taftan Airport",
        "City": "Taftan",
        "Country": "Pakistan"
    },
    {
        "id": "7346",
        "AirportCode": "TFY",
        "AirportName": "Tarfaya Airport",
        "City": "Tarfaya",
        "Country": "Morocco"
    },
    {
        "id": "7347",
        "AirportCode": "TGB",
        "AirportName": "Tagbita Airport",
        "City": "Tagbita",
        "Country": "Philippines"
    },
    {
        "id": "7348",
        "AirportCode": "TGD",
        "AirportName": "Golubovci Airport",
        "City": "Podgorica",
        "Country": "Serbia"
    },
    {
        "id": "7349",
        "AirportCode": "TGE",
        "AirportName": "Sharpe Field",
        "City": "Tuskegee",
        "Country": "USA Alabama"
    },
    {
        "id": "7350",
        "AirportCode": "TGF",
        "AirportName": "Tignes Airport",
        "City": "Tignes",
        "Country": "France"
    },
    {
        "id": "7351",
        "AirportCode": "TGG",
        "AirportName": "Sultan Mahmood Airport",
        "City": "Kuala Terengganu",
        "Country": "Malaysia"
    },
    {
        "id": "7352",
        "AirportCode": "TGH",
        "AirportName": "Tongoa Airport",
        "City": "Tongoa",
        "Country": "Vanuatu"
    },
    {
        "id": "7353",
        "AirportCode": "TGI",
        "AirportName": "Tingo Maria Airport",
        "City": "Tingo Maria",
        "Country": "Peru"
    },
    {
        "id": "7354",
        "AirportCode": "TGJ",
        "AirportName": "Tiga Airport",
        "City": "Tiga",
        "Country": "New Caledonia"
    },
    {
        "id": "7355",
        "AirportCode": "TGL",
        "AirportName": "Tagula Airport",
        "City": "Tagula",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7356",
        "AirportCode": "TGM",
        "AirportName": "Tirgu Mures Airport",
        "City": "Tirgu Mures",
        "Country": "Romania"
    },
    {
        "id": "7357",
        "AirportCode": "TGN",
        "AirportName": "La Trobe Regional Airport",
        "City": "Traralgon",
        "Country": "Australia"
    },
    {
        "id": "7358",
        "AirportCode": "TGO",
        "AirportName": "Tongliao Airport",
        "City": "Tongliao",
        "Country": "China"
    },
    {
        "id": "7359",
        "AirportCode": "TGQ",
        "AirportName": "Tangara Da Serra Airport",
        "City": "Tangara da Serra",
        "Country": "Brazil"
    },
    {
        "id": "7360",
        "AirportCode": "TGR",
        "AirportName": "Touggourt Airport",
        "City": "Touggourt",
        "Country": "Algeria"
    },
    {
        "id": "7361",
        "AirportCode": "TGS",
        "AirportName": "Chokwe Airport",
        "City": "Chokwe",
        "Country": "Mozambique"
    },
    {
        "id": "7362",
        "AirportCode": "TGT",
        "AirportName": "Tanga Airport",
        "City": "Tanga",
        "Country": "Tanzania"
    },
    {
        "id": "7363",
        "AirportCode": "TGU",
        "AirportName": "Toncontin Airport",
        "City": "Tegucigalpa",
        "Country": "Honduras"
    },
    {
        "id": "7364",
        "AirportCode": "TGV",
        "AirportName": "Targovishte Airport",
        "City": "Targovishte",
        "Country": "Bulgaria"
    },
    {
        "id": "7365",
        "AirportCode": "TGZ",
        "AirportName": "Llano San Juan Airport",
        "City": "Tuxtla Gutierrez",
        "Country": "Mexico"
    },
    {
        "id": "7366",
        "AirportCode": "THA",
        "AirportName": "Northern Airport",
        "City": "Tullahoma",
        "Country": "USA Tennessee"
    },
    {
        "id": "7367",
        "AirportCode": "THB",
        "AirportName": "Thaba-Tseka Airport",
        "City": "Thaba-Tseka",
        "Country": "Lesotho"
    },
    {
        "id": "7368",
        "AirportCode": "THC",
        "AirportName": "Tchien Airport",
        "City": "Tchien",
        "Country": "Liberia"
    },
    {
        "id": "7369",
        "AirportCode": "THE",
        "AirportName": "Teresina Airport",
        "City": "Teresina",
        "Country": "Brazil"
    },
    {
        "id": "7370",
        "AirportCode": "THF",
        "AirportName": "Tempelhof Airport",
        "City": "Berlin",
        "Country": "Germany"
    },
    {
        "id": "7371",
        "AirportCode": "THG",
        "AirportName": "Thangool Airport",
        "City": "Thangool",
        "Country": "Australia"
    },
    {
        "id": "7372",
        "AirportCode": "THH",
        "AirportName": "Taharoa Airport",
        "City": "Taharoa",
        "Country": "New Zealand"
    },
    {
        "id": "7373",
        "AirportCode": "THI",
        "AirportName": "Tichitt Airport",
        "City": "Tichitt",
        "Country": "Mauritania"
    },
    {
        "id": "7374",
        "AirportCode": "THK",
        "AirportName": "Thakhek Airport",
        "City": "Thakhek",
        "Country": "Laos"
    },
    {
        "id": "7375",
        "AirportCode": "THL",
        "AirportName": "Tachilek Airport",
        "City": "Tachilek",
        "Country": "Burma"
    },
    {
        "id": "7376",
        "AirportCode": "THM",
        "AirportName": "Thompsonfield Airport",
        "City": "Thompsonfield",
        "Country": "USA Missouri"
    },
    {
        "id": "7377",
        "AirportCode": "THN",
        "AirportName": "Trollhattan Airport",
        "City": "Trollhattan",
        "Country": "Sweden"
    },
    {
        "id": "7378",
        "AirportCode": "THO",
        "AirportName": "Thorshofn Airport",
        "City": "Thorshofn",
        "Country": "Iceland"
    },
    {
        "id": "7379",
        "AirportCode": "THP",
        "AirportName": "Hot Springs Airport",
        "City": "Thermopolis",
        "Country": "USA Wyoming"
    },
    {
        "id": "7380",
        "AirportCode": "THR",
        "AirportName": "Mehrabad Airport",
        "City": "Tehran",
        "Country": "Iran"
    },
    {
        "id": "7381",
        "AirportCode": "THS",
        "AirportName": "Sukhothai Airport",
        "City": "Sukhothai",
        "Country": "Thailand"
    },
    {
        "id": "7382",
        "AirportCode": "THT",
        "AirportName": "Tamchakett Airport",
        "City": "Tamchakett",
        "Country": "Mauritania"
    },
    {
        "id": "7383",
        "AirportCode": "THU",
        "AirportName": "Pituffik Airport",
        "City": "Pituffik",
        "Country": "Greenland"
    },
    {
        "id": "7384",
        "AirportCode": "THV",
        "AirportName": "York Airport",
        "City": "York",
        "Country": "USA Philadelphia"
    },
    {
        "id": "7385",
        "AirportCode": "THY",
        "AirportName": "Thohoyandou Airport",
        "City": "Thohoyandou",
        "Country": "South Africa"
    },
    {
        "id": "7386",
        "AirportCode": "THZ",
        "AirportName": "Tahoua Airport",
        "City": "Tahoua",
        "Country": "Niger"
    },
    {
        "id": "7387",
        "AirportCode": "TIA",
        "AirportName": "Rinas Airport",
        "City": "Tirana",
        "Country": "Albania"
    },
    {
        "id": "7388",
        "AirportCode": "TIB",
        "AirportName": "Tibu Airport",
        "City": "Tibu",
        "Country": "Colombia"
    },
    {
        "id": "7389",
        "AirportCode": "TIC",
        "AirportName": "Tinak Airport",
        "City": "Tinak",
        "Country": "Marshall Islands"
    },
    {
        "id": "7390",
        "AirportCode": "TID",
        "AirportName": "Bouchekif Airport",
        "City": "Tiaret",
        "Country": "Algeria"
    },
    {
        "id": "7391",
        "AirportCode": "TIE",
        "AirportName": "Tippi Airport",
        "City": "Tippi",
        "Country": "Ethiopia"
    },
    {
        "id": "7392",
        "AirportCode": "TIF",
        "AirportName": "Taif Airport",
        "City": "Taif",
        "Country": "Saudi Arabia"
    },
    {
        "id": "7393",
        "AirportCode": "TIG",
        "AirportName": "Tingwon Airport",
        "City": "Tingwon",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7394",
        "AirportCode": "TIH",
        "AirportName": "Tikehau Atoll Airport",
        "City": "Tikehau Atoll",
        "Country": "French Polynesia"
    },
    {
        "id": "7395",
        "AirportCode": "TII",
        "AirportName": "Tirinkot Airport",
        "City": "Tirinkot",
        "Country": "Afghanistan"
    },
    {
        "id": "7396",
        "AirportCode": "TIJ",
        "AirportName": "Rodriguez Airport",
        "City": "Tijuana",
        "Country": "Mexico"
    },
    {
        "id": "7397",
        "AirportCode": "TIK",
        "AirportName": "Tinker Air Force Base",
        "City": "Oklahoma City",
        "Country": "USA Oklahoma"
    },
    {
        "id": "7398",
        "AirportCode": "TIM",
        "AirportName": "Timika Airport",
        "City": "Tembagapura",
        "Country": "Indonesia"
    },
    {
        "id": "7399",
        "AirportCode": "TIN",
        "AirportName": "Tindouf Airport",
        "City": "Tindouf",
        "Country": "Algeria"
    },
    {
        "id": "7400",
        "AirportCode": "TIO",
        "AirportName": "Tilin Airport",
        "City": "Tilin",
        "Country": "Burma"
    },
    {
        "id": "7401",
        "AirportCode": "TIP",
        "AirportName": "Tripoli International Airport",
        "City": "Tripoli (TIP)",
        "Country": "Libya"
    },
    {
        "id": "7402",
        "AirportCode": "TIQ",
        "AirportName": "Tinian Airport",
        "City": "Tinian",
        "Country": "Northern Mariana Islands"
    },
    {
        "id": "7403",
        "AirportCode": "TIR",
        "AirportName": "Tirupati Airport",
        "City": "Tirupati",
        "Country": "India"
    },
    {
        "id": "7404",
        "AirportCode": "TIS",
        "AirportName": "Thursday Island Airport",
        "City": "Thursday Island",
        "Country": "Australia"
    },
    {
        "id": "7405",
        "AirportCode": "TIU",
        "AirportName": "Timaru Airport",
        "City": "Timaru",
        "Country": "New Zealand"
    },
    {
        "id": "7406",
        "AirportCode": "TIV",
        "AirportName": "Tivat Airport",
        "City": "Tivat",
        "Country": "Serbia"
    },
    {
        "id": "7407",
        "AirportCode": "TIW",
        "AirportName": "Industrial Airport",
        "City": "Tacoma",
        "Country": "USA Washington"
    },
    {
        "id": "7408",
        "AirportCode": "TIX",
        "AirportName": "Space Center Executive Airport",
        "City": "Titusville",
        "Country": "USA Florida"
    },
    {
        "id": "7409",
        "AirportCode": "TIY",
        "AirportName": "Tidjikja Airport",
        "City": "Tidjikja",
        "Country": "Mauritania"
    },
    {
        "id": "7410",
        "AirportCode": "TIZ",
        "AirportName": "Tari Airport",
        "City": "Tari",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7411",
        "AirportCode": "TJA",
        "AirportName": "Tarija Airport",
        "City": "Tarija",
        "Country": "Bolivia"
    },
    {
        "id": "7412",
        "AirportCode": "TJB",
        "AirportName": "Tanjung Balai Airport",
        "City": "Tanjung Balai",
        "Country": "Indonesia"
    },
    {
        "id": "7413",
        "AirportCode": "TJC",
        "AirportName": "Ticantiki Airport",
        "City": "Ticantiki",
        "Country": "Panama"
    },
    {
        "id": "7414",
        "AirportCode": "TJG",
        "AirportName": "Tanjung Warukin Airport",
        "City": "Tanjung Warukin",
        "Country": "Indonesia"
    },
    {
        "id": "7415",
        "AirportCode": "TJH",
        "AirportName": "Tajima Airport",
        "City": "Toyooka",
        "Country": "Japan"
    },
    {
        "id": "7416",
        "AirportCode": "TJI",
        "AirportName": "Capiro Airport",
        "City": "Trujillo",
        "Country": "Honduras"
    },
    {
        "id": "7417",
        "AirportCode": "TJK",
        "AirportName": "Tokat Airport",
        "City": "Tokat",
        "Country": "Turkey"
    },
    {
        "id": "7418",
        "AirportCode": "TJM",
        "AirportName": "Tyumen Airport",
        "City": "Tyumen",
        "Country": "Russia"
    },
    {
        "id": "7419",
        "AirportCode": "TJN",
        "AirportName": "Takume Airport",
        "City": "Takume",
        "Country": "French Polynesia"
    },
    {
        "id": "7420",
        "AirportCode": "TJQ",
        "AirportName": "Bulutumbang Airport",
        "City": "Tanjung Pandan",
        "Country": "Indonesia"
    },
    {
        "id": "7421",
        "AirportCode": "TJS",
        "AirportName": "Tanjung Selor Airport",
        "City": "Tanjung Selor",
        "Country": "Indonesia"
    },
    {
        "id": "7422",
        "AirportCode": "TJV",
        "AirportName": "Thanjavur Airport",
        "City": "Thanjavur",
        "Country": "India"
    },
    {
        "id": "7423",
        "AirportCode": "TKA",
        "AirportName": "Talkeetna Airport",
        "City": "Talkeetna",
        "Country": "USA Alaska"
    },
    {
        "id": "7424",
        "AirportCode": "TKB",
        "AirportName": "Tekadu Airport",
        "City": "Tekadu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7425",
        "AirportCode": "TKC",
        "AirportName": "Tiko Airport",
        "City": "Tiko",
        "Country": "Cameroon"
    },
    {
        "id": "7426",
        "AirportCode": "TKD",
        "AirportName": "Takoradi Airport",
        "City": "Takoradi",
        "Country": "Ghana"
    },
    {
        "id": "7427",
        "AirportCode": "TKE",
        "AirportName": "Tenakee Springs Sea Plane Base",
        "City": "Tenakee Springs",
        "Country": "USA Alaska"
    },
    {
        "id": "7428",
        "AirportCode": "TKF",
        "AirportName": "Truckee Airport",
        "City": "Truckee",
        "Country": "USA California"
    },
    {
        "id": "7429",
        "AirportCode": "TKG",
        "AirportName": "Branti Airport",
        "City": "Bandar Lampung",
        "Country": "Indonesia"
    },
    {
        "id": "7430",
        "AirportCode": "TKH",
        "AirportName": "Takhli Airport",
        "City": "Takhli",
        "Country": "Thailand"
    },
    {
        "id": "7431",
        "AirportCode": "TKJ",
        "AirportName": "Tok Airport",
        "City": "Tok",
        "Country": "USA Alaska"
    },
    {
        "id": "7432",
        "AirportCode": "TKK",
        "AirportName": "Truk Airport",
        "City": "Truk, Caroline Islands",
        "Country": "Micronesia"
    },
    {
        "id": "7433",
        "AirportCode": "TKL",
        "AirportName": "Taku Sea Plane Base",
        "City": "Taku Lodge",
        "Country": "USA Alaska"
    },
    {
        "id": "7434",
        "AirportCode": "TKM",
        "AirportName": "El Peten Airport",
        "City": "Tikal",
        "Country": "Guatemala"
    },
    {
        "id": "7435",
        "AirportCode": "TKN",
        "AirportName": "Tokunoshima Airport",
        "City": "Tokunoshima",
        "Country": "Japan"
    },
    {
        "id": "7436",
        "AirportCode": "TKO",
        "AirportName": "Tlokoeng Airport",
        "City": "Tlokoeng",
        "Country": "Lesotho"
    },
    {
        "id": "7437",
        "AirportCode": "TKP",
        "AirportName": "Takapoto Airport",
        "City": "Takapoto",
        "Country": "French Polynesia"
    },
    {
        "id": "7438",
        "AirportCode": "TKQ",
        "AirportName": "Kigoma Airport",
        "City": "Kigoma",
        "Country": "Tanzania"
    },
    {
        "id": "7439",
        "AirportCode": "TKR",
        "AirportName": "Thakurgaon Airport",
        "City": "Thakurgaon",
        "Country": "Bangladesh"
    },
    {
        "id": "7440",
        "AirportCode": "TKS",
        "AirportName": "Tokushima Airport",
        "City": "Tokushima",
        "Country": "Japan"
    },
    {
        "id": "7441",
        "AirportCode": "TKT",
        "AirportName": "Tak Airport",
        "City": "Tak",
        "Country": "Thailand"
    },
    {
        "id": "7442",
        "AirportCode": "TKU",
        "AirportName": "Turku Airport",
        "City": "Turku",
        "Country": "Finland"
    },
    {
        "id": "7443",
        "AirportCode": "TKV",
        "AirportName": "Tuamoto Airport",
        "City": "Tatakoto",
        "Country": "French Polynesia"
    },
    {
        "id": "7444",
        "AirportCode": "TKW",
        "AirportName": "Tekin Airport",
        "City": "Tekin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7445",
        "AirportCode": "TKX",
        "AirportName": "Takaroa Airport",
        "City": "Takaroa",
        "Country": "French Polynesia"
    },
    {
        "id": "7446",
        "AirportCode": "TKY",
        "AirportName": "Turkey Creek Airport",
        "City": "Turkey Creek",
        "Country": "Australia"
    },
    {
        "id": "7447",
        "AirportCode": "TKZ",
        "AirportName": "Tokoroa Airport",
        "City": "Tokoroa",
        "Country": "New Zealand"
    },
    {
        "id": "7448",
        "AirportCode": "TLA",
        "AirportName": "Teller Airport",
        "City": "Teller",
        "Country": "USA Alaska"
    },
    {
        "id": "7449",
        "AirportCode": "TLB",
        "AirportName": "Tarbela Airport",
        "City": "Tarbela",
        "Country": "Pakistan"
    },
    {
        "id": "7450",
        "AirportCode": "TLC",
        "AirportName": "Toluca Airport",
        "City": "Toluca",
        "Country": "Mexico"
    },
    {
        "id": "7451",
        "AirportCode": "TLD",
        "AirportName": "Limpopo Valley Airfield",
        "City": "Tuli Block",
        "Country": "Botswana"
    },
    {
        "id": "7452",
        "AirportCode": "TLE",
        "AirportName": "Tulear Airport",
        "City": "Tulear",
        "Country": "Madagascar"
    },
    {
        "id": "7453",
        "AirportCode": "TLH",
        "AirportName": "Tallahassee Regional Airport",
        "City": "Tallahassee",
        "Country": "USA Florida"
    },
    {
        "id": "7454",
        "AirportCode": "TLI",
        "AirportName": "Tolitoli Airport",
        "City": "Tolitoli",
        "Country": "Indonesia"
    },
    {
        "id": "7455",
        "AirportCode": "TLJ",
        "AirportName": "Tatalina Air Force Station",
        "City": "Tatalina",
        "Country": "USA Alaska"
    },
    {
        "id": "7456",
        "AirportCode": "TLK",
        "AirportName": "Talknafjordur Airport",
        "City": "Talknafjordur",
        "Country": "Iceland"
    },
    {
        "id": "7457",
        "AirportCode": "TLL",
        "AirportName": "Ulemiste Airport",
        "City": "Tallinn",
        "Country": "Estonia"
    },
    {
        "id": "7458",
        "AirportCode": "TLM",
        "AirportName": "Zenata Airport",
        "City": "Tlemcen",
        "Country": "Algeria"
    },
    {
        "id": "7459",
        "AirportCode": "TLN",
        "AirportName": "Hyeres Airport",
        "City": "Toulon",
        "Country": "France"
    },
    {
        "id": "7460",
        "AirportCode": "TLO",
        "AirportName": "Tol Airport",
        "City": "Tol",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7461",
        "AirportCode": "TLP",
        "AirportName": "Tumolbil Airport",
        "City": "Tumolbil",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7462",
        "AirportCode": "TLR",
        "AirportName": "Tulare Airport",
        "City": "Tulare",
        "Country": "USA California"
    },
    {
        "id": "7463",
        "AirportCode": "TLS",
        "AirportName": "Blagnac Airport",
        "City": "Toulouse",
        "Country": "France"
    },
    {
        "id": "7464",
        "AirportCode": "TLT",
        "AirportName": "Tuluksak Airport",
        "City": "Tuluksak",
        "Country": "USA Alaska"
    },
    {
        "id": "7465",
        "AirportCode": "TLU",
        "AirportName": "Tolu Airport",
        "City": "Tolu",
        "Country": "Colombia"
    },
    {
        "id": "7466",
        "AirportCode": "TLW",
        "AirportName": "Talasea Airport",
        "City": "Talasea",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7467",
        "AirportCode": "TLX",
        "AirportName": "Talca Airport",
        "City": "Talca",
        "Country": "Chile"
    },
    {
        "id": "7468",
        "AirportCode": "TLZ",
        "AirportName": "Catalao Airport",
        "City": "Catalao",
        "Country": "Brazil"
    },
    {
        "id": "7469",
        "AirportCode": "TMA",
        "AirportName": "Henry Tift Myers Airport",
        "City": "Tifton",
        "Country": "USA Georgia"
    },
    {
        "id": "7470",
        "AirportCode": "TMB",
        "AirportName": "Tamiami Airport",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "7471",
        "AirportCode": "TMC",
        "AirportName": "Tambolaka Airport",
        "City": "Tambolaka",
        "Country": "Indonesia"
    },
    {
        "id": "7472",
        "AirportCode": "TMD",
        "AirportName": "Timbedra Airport",
        "City": "Timbedra",
        "Country": "Mauritania"
    },
    {
        "id": "7473",
        "AirportCode": "TME",
        "AirportName": "Tame Airport",
        "City": "Tame",
        "Country": "Colombia"
    },
    {
        "id": "7474",
        "AirportCode": "TMG",
        "AirportName": "Tomanggong Airport",
        "City": "Tomanggong",
        "Country": "Malaysia"
    },
    {
        "id": "7475",
        "AirportCode": "TMH",
        "AirportName": "Tanahmerah Airport",
        "City": "Tanahmerah",
        "Country": "Indonesia"
    },
    {
        "id": "7476",
        "AirportCode": "TMI",
        "AirportName": "Tumling Tar Airport",
        "City": "Tumling Tar",
        "Country": "Nepal"
    },
    {
        "id": "7477",
        "AirportCode": "TMJ",
        "AirportName": "Termez Airport",
        "City": "Termez",
        "Country": "Uzbekistan"
    },
    {
        "id": "7478",
        "AirportCode": "TMK",
        "AirportName": "Tamky Airport",
        "City": "Tamky",
        "Country": "Vietnam"
    },
    {
        "id": "7479",
        "AirportCode": "TML",
        "AirportName": "Tamale Airport",
        "City": "Tamale",
        "Country": "Ghana"
    },
    {
        "id": "7480",
        "AirportCode": "TMM",
        "AirportName": "Tamatave Airport",
        "City": "Tamatave",
        "Country": "Madagascar"
    },
    {
        "id": "7481",
        "AirportCode": "TMN",
        "AirportName": "Tamana Island Airport",
        "City": "Tamana Island",
        "Country": "Kiribati"
    },
    {
        "id": "7482",
        "AirportCode": "TMO",
        "AirportName": "Tumeremo Airport",
        "City": "Tumeremo",
        "Country": "Venezuela"
    },
    {
        "id": "7483",
        "AirportCode": "TMP",
        "AirportName": "Tampere-Pirkkala Airport",
        "City": "Tampere",
        "Country": "Finland"
    },
    {
        "id": "7484",
        "AirportCode": "TMQ",
        "AirportName": "Tambao Airport",
        "City": "Tambao",
        "Country": "Burkina Faso"
    },
    {
        "id": "7485",
        "AirportCode": "TMR",
        "AirportName": "Aguemar Airport",
        "City": "Tamenghest",
        "Country": "Algeria"
    },
    {
        "id": "7486",
        "AirportCode": "TMS",
        "AirportName": "Sao Tome Island Airport",
        "City": "Sao Tome Island",
        "Country": "Sao Tom et Principe"
    },
    {
        "id": "7487",
        "AirportCode": "TMT",
        "AirportName": "Trombetas Airport",
        "City": "Trombetas",
        "Country": "Brazil"
    },
    {
        "id": "7488",
        "AirportCode": "TMU",
        "AirportName": "Tambor Airport",
        "City": "Tambor",
        "Country": "Costa Rica"
    },
    {
        "id": "7489",
        "AirportCode": "TMW",
        "AirportName": "Tamworth Airport",
        "City": "Tamworth",
        "Country": "Australia"
    },
    {
        "id": "7490",
        "AirportCode": "TMX",
        "AirportName": "Timimoun Airport",
        "City": "Timimoun",
        "Country": "Algeria"
    },
    {
        "id": "7491",
        "AirportCode": "TMY",
        "AirportName": "Tiom Airport",
        "City": "Tiom",
        "Country": "Indonesia"
    },
    {
        "id": "7492",
        "AirportCode": "TMZ",
        "AirportName": "Thames Airport",
        "City": "Thames",
        "Country": "New Zealand"
    },
    {
        "id": "7493",
        "AirportCode": "TNA",
        "AirportName": "Jinan Airport",
        "City": "Jinan",
        "Country": "China"
    },
    {
        "id": "7494",
        "AirportCode": "TNB",
        "AirportName": "Tanahgrogot Airport",
        "City": "Tanahgrogot",
        "Country": "Indonesia"
    },
    {
        "id": "7495",
        "AirportCode": "TNC",
        "AirportName": "Tin City Air Force Station",
        "City": "Tin City",
        "Country": "USA Alaska"
    },
    {
        "id": "7496",
        "AirportCode": "TND",
        "AirportName": "Trinidad Airport",
        "City": "Trinidad",
        "Country": "Cuba"
    },
    {
        "id": "7497",
        "AirportCode": "TNE",
        "AirportName": "Tanegashima Airport",
        "City": "Tanegashima",
        "Country": "Japan"
    },
    {
        "id": "7498",
        "AirportCode": "TNF",
        "AirportName": "Toussus-le-Noble Airport",
        "City": "Toussus-le-Noble",
        "Country": "France"
    },
    {
        "id": "7499",
        "AirportCode": "TNG",
        "AirportName": "Boukhalef Airport",
        "City": "Tangier",
        "Country": "Morocco"
    },
    {
        "id": "7500",
        "AirportCode": "TNH",
        "AirportName": "Tonghua Liuhe Airport",
        "City": "Tonghua",
        "Country": "China"
    },
    {
        "id": "7501",
        "AirportCode": "TNI",
        "AirportName": "Satna Airport",
        "City": "Satna",
        "Country": "India"
    },
    {
        "id": "7502",
        "AirportCode": "TNJ",
        "AirportName": "Kidjang Airport",
        "City": "Tanjung Pinang",
        "Country": "Indonesia"
    },
    {
        "id": "7503",
        "AirportCode": "TNK",
        "AirportName": "Tununak Airport",
        "City": "Tununak",
        "Country": "USA Alaska"
    },
    {
        "id": "7504",
        "AirportCode": "TNL",
        "AirportName": "Ternopol Airport",
        "City": "Ternopol",
        "Country": "Ukraine"
    },
    {
        "id": "7505",
        "AirportCode": "TNN",
        "AirportName": "Tainan Airport",
        "City": "Tainan",
        "Country": "Taiwan"
    },
    {
        "id": "7506",
        "AirportCode": "TNO",
        "AirportName": "Tamarindo Airport",
        "City": "Tamarindo",
        "Country": "Costa Rica"
    },
    {
        "id": "7507",
        "AirportCode": "TNP",
        "AirportName": "Twentynine Palms Airport",
        "City": "Twentynine Palms",
        "Country": "USA California"
    },
    {
        "id": "7508",
        "AirportCode": "TNQ",
        "AirportName": "Washington Island Airport",
        "City": "Teraina",
        "Country": "Kiribati"
    },
    {
        "id": "7509",
        "AirportCode": "TNR",
        "AirportName": "Antananarivo Airport",
        "City": "Antananarivo",
        "Country": "Madagascar"
    },
    {
        "id": "7510",
        "AirportCode": "TNS",
        "AirportName": "Tungsten Airport",
        "City": "Tungsten",
        "Country": "Canada"
    },
    {
        "id": "7511",
        "AirportCode": "TNT",
        "AirportName": "Dade Collier Airport",
        "City": "Miami (MIA)",
        "Country": "USA Florida"
    },
    {
        "id": "7512",
        "AirportCode": "TNU",
        "AirportName": "Newton Municipal Airport",
        "City": "Newton",
        "Country": "USA Iowa"
    },
    {
        "id": "7513",
        "AirportCode": "TNV",
        "AirportName": "Fanning Island Airport",
        "City": "Tabuaeran",
        "Country": "Kiribati"
    },
    {
        "id": "7514",
        "AirportCode": "TNX",
        "AirportName": "Stung Treng Airport",
        "City": "Stung Treng",
        "Country": "Cambodia"
    },
    {
        "id": "7515",
        "AirportCode": "TNZ",
        "AirportName": "Tosontsengel Airport",
        "City": "Tosontsengel",
        "Country": "Mongolia"
    },
    {
        "id": "7516",
        "AirportCode": "TOA",
        "AirportName": "Torrance Airport",
        "City": "Torrance",
        "Country": "USA California"
    },
    {
        "id": "7517",
        "AirportCode": "TOB",
        "AirportName": "Tobruk Airport",
        "City": "Tobruk",
        "Country": "Libya"
    },
    {
        "id": "7518",
        "AirportCode": "TOC",
        "AirportName": "Toccoa Airport",
        "City": "Toccoa",
        "Country": "USA Georgia"
    },
    {
        "id": "7519",
        "AirportCode": "TOD",
        "AirportName": "Tioman Airport",
        "City": "Tioman",
        "Country": "Malaysia"
    },
    {
        "id": "7520",
        "AirportCode": "TOE",
        "AirportName": "Tozeur Airport",
        "City": "Tozeur",
        "Country": "Tunisia"
    },
    {
        "id": "7521",
        "AirportCode": "TOF",
        "AirportName": "Tomsk Airport",
        "City": "Tomsk",
        "Country": "Russia"
    },
    {
        "id": "7522",
        "AirportCode": "TOG",
        "AirportName": "Togiak Village Airport",
        "City": "Togiak Village",
        "Country": "USA Alaska"
    },
    {
        "id": "7523",
        "AirportCode": "TOH",
        "AirportName": "Torres Airstrip",
        "City": "Torres",
        "Country": "Vanuatu"
    },
    {
        "id": "7524",
        "AirportCode": "TOI",
        "AirportName": "Troy Municipal Airport",
        "City": "Troy",
        "Country": "USA Alabama"
    },
    {
        "id": "7525",
        "AirportCode": "TOJ",
        "AirportName": "Torrejon Air Force Base",
        "City": "Madrid",
        "Country": "Spain"
    },
    {
        "id": "7526",
        "AirportCode": "TOK",
        "AirportName": "Torokina Airport",
        "City": "Torokina",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7527",
        "AirportCode": "TOL",
        "AirportName": "Express Airport",
        "City": "Toledo",
        "Country": "USA Ohio"
    },
    {
        "id": "7528",
        "AirportCode": "TOM",
        "AirportName": "Tombouctou Airport",
        "City": "Tombouctou",
        "Country": "Mali"
    },
    {
        "id": "7529",
        "AirportCode": "TON",
        "AirportName": "Tonu Airport",
        "City": "Tonu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7530",
        "AirportCode": "TOO",
        "AirportName": "San Vito Airport",
        "City": "San Vito",
        "Country": "Costa Rica"
    },
    {
        "id": "7531",
        "AirportCode": "TOP",
        "AirportName": "Philip Billard Airport",
        "City": "Topeka",
        "Country": "USA Kansas"
    },
    {
        "id": "7532",
        "AirportCode": "TOQ",
        "AirportName": "Barriles Airport",
        "City": "Tocopilla",
        "Country": "Chile"
    },
    {
        "id": "7533",
        "AirportCode": "TOR",
        "AirportName": "Torrington Municipal Airport",
        "City": "Torrington",
        "Country": "USA Wyoming"
    },
    {
        "id": "7534",
        "AirportCode": "TOS",
        "AirportName": "Tromso/Langnes Airport",
        "City": "Tromso",
        "Country": "Norway"
    },
    {
        "id": "7535",
        "AirportCode": "TOT",
        "AirportName": "Coronie Airport",
        "City": "Totness",
        "Country": "Suriname"
    },
    {
        "id": "7536",
        "AirportCode": "TOU",
        "AirportName": "Touho Airport",
        "City": "Touho",
        "Country": "New Caledonia"
    },
    {
        "id": "7537",
        "AirportCode": "TOV",
        "AirportName": "West End Sea Plane Base",
        "City": "Tortola",
        "Country": "British Virgin Islands"
    },
    {
        "id": "7538",
        "AirportCode": "TOW",
        "AirportName": "Toledo Airport",
        "City": "Toledo",
        "Country": "Brazil"
    },
    {
        "id": "7539",
        "AirportCode": "TOX",
        "AirportName": "Tobolsk Airport",
        "City": "Tobolsk",
        "Country": "Russia"
    },
    {
        "id": "7540",
        "AirportCode": "TOY",
        "AirportName": "Toyama Airport",
        "City": "Toyama",
        "Country": "Japan"
    },
    {
        "id": "7541",
        "AirportCode": "TPA",
        "AirportName": "Tampa International Airport",
        "City": "Tampa",
        "Country": "USA Florida"
    },
    {
        "id": "7542",
        "AirportCode": "TPC",
        "AirportName": "Tarapoa Airport",
        "City": "Tarapoa",
        "Country": "Ecuador"
    },
    {
        "id": "7543",
        "AirportCode": "TPE",
        "AirportName": "Taiwan Taoyuan International Airport",
        "City": "Taipei",
        "Country": "Taiwan"
    },
    {
        "id": "7544",
        "AirportCode": "TPG",
        "AirportName": "Taiping Airport",
        "City": "Taiping",
        "Country": "Malaysia"
    },
    {
        "id": "7545",
        "AirportCode": "TPH",
        "AirportName": "Tonopah Airport",
        "City": "Tonopah",
        "Country": "USA Nevada"
    },
    {
        "id": "7546",
        "AirportCode": "TPI",
        "AirportName": "Tapini Airport",
        "City": "Tapini",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7547",
        "AirportCode": "TPJ",
        "AirportName": "Taplejung Airport",
        "City": "Taplejung",
        "Country": "Nepal"
    },
    {
        "id": "7548",
        "AirportCode": "TPK",
        "AirportName": "Tapaktuan Airport",
        "City": "Tapaktuan",
        "Country": "Indonesia"
    },
    {
        "id": "7549",
        "AirportCode": "TPL",
        "AirportName": "Draughon-Miller Airport",
        "City": "Temple",
        "Country": "USA Texas"
    },
    {
        "id": "7550",
        "AirportCode": "TPN",
        "AirportName": "Tiputini Airport",
        "City": "Tiputini",
        "Country": "Ecuador"
    },
    {
        "id": "7551",
        "AirportCode": "TPP",
        "AirportName": "Tarapoto Airport",
        "City": "Tarapoto",
        "Country": "Peru"
    },
    {
        "id": "7552",
        "AirportCode": "TPQ",
        "AirportName": "Tepic Airport",
        "City": "Tepic",
        "Country": "Mexico"
    },
    {
        "id": "7553",
        "AirportCode": "TPR",
        "AirportName": "Tom Price Airport",
        "City": "Tom Price",
        "Country": "Australia"
    },
    {
        "id": "7554",
        "AirportCode": "TPS",
        "AirportName": "Birgi Airport",
        "City": "Trapani",
        "Country": "Italy"
    },
    {
        "id": "7555",
        "AirportCode": "TPT",
        "AirportName": "Tapeta Airport",
        "City": "Tapeta",
        "Country": "Liberia"
    },
    {
        "id": "7556",
        "AirportCode": "TPU",
        "AirportName": "Tikapur Airport",
        "City": "Tikapur",
        "Country": "Nepal"
    },
    {
        "id": "7557",
        "AirportCode": "TPX",
        "AirportName": "Tupai Airport",
        "City": "Tupai",
        "Country": "French Polynesia"
    },
    {
        "id": "7558",
        "AirportCode": "TQN",
        "AirportName": "Taluquan Airport",
        "City": "Taluquan",
        "Country": "Afghanistan"
    },
    {
        "id": "7559",
        "AirportCode": "TQR",
        "AirportName": "San Domino Island Airport",
        "City": "San Domino Island",
        "Country": "Italy"
    },
    {
        "id": "7560",
        "AirportCode": "TQS",
        "AirportName": "Tres Esquinas Airport",
        "City": "Tres Esquinas",
        "Country": "Colombia"
    },
    {
        "id": "7561",
        "AirportCode": "TRA",
        "AirportName": "Tarama Airport",
        "City": "Taramajima",
        "Country": "Japan"
    },
    {
        "id": "7562",
        "AirportCode": "TRB",
        "AirportName": "Gonzalo Airport",
        "City": "Turbo",
        "Country": "Colombia"
    },
    {
        "id": "7563",
        "AirportCode": "TRC",
        "AirportName": "Torreon Airport",
        "City": "Torreon",
        "Country": "Mexico"
    },
    {
        "id": "7564",
        "AirportCode": "TRD",
        "AirportName": "Vaernes Airport",
        "City": "Trondheim",
        "Country": "Norway"
    },
    {
        "id": "7565",
        "AirportCode": "TRE",
        "AirportName": "Tiree Airport",
        "City": "Tiree, Inner Hebrides",
        "Country": "United Kingdom"
    },
    {
        "id": "7566",
        "AirportCode": "TRF",
        "AirportName": "Sandefjord Airport",
        "City": "Oslo",
        "Country": "Norway"
    },
    {
        "id": "7567",
        "AirportCode": "TRG",
        "AirportName": "Tauranga Airport",
        "City": "Tauranga",
        "Country": "New Zealand"
    },
    {
        "id": "7568",
        "AirportCode": "TRH",
        "AirportName": "Trona Airport",
        "City": "Trona",
        "Country": "USA California"
    },
    {
        "id": "7569",
        "AirportCode": "TRI",
        "AirportName": "Tri-Cities Regional",
        "City": "Bristol, VA/Johnson City/Kingsport",
        "Country": "USA Tennessee"
    },
    {
        "id": "7570",
        "AirportCode": "TRJ",
        "AirportName": "Tarakbits Airport",
        "City": "Tarakbits",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7571",
        "AirportCode": "TRK",
        "AirportName": "Tarakan Airport",
        "City": "Tarakan",
        "Country": "Indonesia"
    },
    {
        "id": "7572",
        "AirportCode": "TRL",
        "AirportName": "Terrell Field",
        "City": "Terrell",
        "Country": "USA Texas"
    },
    {
        "id": "7573",
        "AirportCode": "TRM",
        "AirportName": "Thermal Airport",
        "City": "Thermal",
        "Country": "USA California"
    },
    {
        "id": "7574",
        "AirportCode": "TRN",
        "AirportName": "Citta Di Torino Airport",
        "City": "Turin",
        "Country": "Italy"
    },
    {
        "id": "7575",
        "AirportCode": "TRO",
        "AirportName": "Taree Airport",
        "City": "Taree",
        "Country": "Australia"
    },
    {
        "id": "7576",
        "AirportCode": "TRQ",
        "AirportName": "Tarauaca Airport",
        "City": "Tarauaca",
        "Country": "Brazil"
    },
    {
        "id": "7577",
        "AirportCode": "TRR",
        "AirportName": "China Bay Airport",
        "City": "Trincomalee",
        "Country": "Sri Lanka"
    },
    {
        "id": "7578",
        "AirportCode": "TRS",
        "AirportName": "Dei Legionari Airport",
        "City": "Trieste",
        "Country": "Italy"
    },
    {
        "id": "7579",
        "AirportCode": "TRT",
        "AirportName": "Tremonton Airport",
        "City": "Tremonton",
        "Country": "USA Utah"
    },
    {
        "id": "7580",
        "AirportCode": "TRU",
        "AirportName": "Trujillo Airport",
        "City": "Trujillo",
        "Country": "Peru"
    },
    {
        "id": "7581",
        "AirportCode": "TRV",
        "AirportName": "Trivandrum International Airport",
        "City": "Thiruvananthapuram",
        "Country": "India"
    },
    {
        "id": "7582",
        "AirportCode": "TRW",
        "AirportName": "Bonriki Airport",
        "City": "Tarawa",
        "Country": "Kiribati"
    },
    {
        "id": "7583",
        "AirportCode": "TRX",
        "AirportName": "Trenton Memorial Airport",
        "City": "Trenton",
        "Country": "USA Missouri"
    },
    {
        "id": "7584",
        "AirportCode": "TRY",
        "AirportName": "Tororo Airport",
        "City": "Tororo",
        "Country": "Uganda"
    },
    {
        "id": "7585",
        "AirportCode": "TRZ",
        "AirportName": "Civil Airport",
        "City": "Tiruchirappalli",
        "Country": "India"
    },
    {
        "id": "7586",
        "AirportCode": "TSA",
        "AirportName": "Sung Shan Airport",
        "City": "Taipei",
        "Country": "Taiwan"
    },
    {
        "id": "7587",
        "AirportCode": "TSB",
        "AirportName": "Tsumeb Airport",
        "City": "Tsumeb",
        "Country": "Namibia"
    },
    {
        "id": "7588",
        "AirportCode": "TSC",
        "AirportName": "Taisha Airport",
        "City": "Taisha",
        "Country": "Ecuador"
    },
    {
        "id": "7589",
        "AirportCode": "TSD",
        "AirportName": "Tshipise Airport",
        "City": "Tshipise",
        "Country": "South Africa"
    },
    {
        "id": "7590",
        "AirportCode": "TSE",
        "AirportName": "Astana Airport",
        "City": "Astana",
        "Country": "Kazakhstan"
    },
    {
        "id": "7591",
        "AirportCode": "TSF",
        "AirportName": "Treviso Airport",
        "City": "Venice",
        "Country": "Italy"
    },
    {
        "id": "7592",
        "AirportCode": "TSH",
        "AirportName": "Tshikapa Airport",
        "City": "Tshikapa",
        "Country": "Congo, DR"
    },
    {
        "id": "7593",
        "AirportCode": "TSI",
        "AirportName": "Tsili Tsili Airport",
        "City": "Tsili Tsili",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7594",
        "AirportCode": "TSJ",
        "AirportName": "Tsushima Airport",
        "City": "Tsushima",
        "Country": "Japan"
    },
    {
        "id": "7595",
        "AirportCode": "TSK",
        "AirportName": "Taskul Airport",
        "City": "Taskul",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7596",
        "AirportCode": "TSL",
        "AirportName": "Tamuin Airport",
        "City": "Tamuin",
        "Country": "Mexico"
    },
    {
        "id": "7597",
        "AirportCode": "TSM",
        "AirportName": "Taos Airport",
        "City": "Taos",
        "Country": "USA New Mexico"
    },
    {
        "id": "7598",
        "AirportCode": "TSN",
        "AirportName": "Tianjin Airport",
        "City": "Tianjin",
        "Country": "China"
    },
    {
        "id": "7599",
        "AirportCode": "TSO",
        "AirportName": "Tresco Airport",
        "City": "Isles Of Scilly",
        "Country": "United Kingdom"
    },
    {
        "id": "7600",
        "AirportCode": "TSP",
        "AirportName": "Kern County Airport",
        "City": "Tehachapi",
        "Country": "USA California"
    },
    {
        "id": "7601",
        "AirportCode": "TSQ",
        "AirportName": "Torres Airport",
        "City": "Torres",
        "Country": "Brazil"
    },
    {
        "id": "7602",
        "AirportCode": "TSR",
        "AirportName": "Timisoara Airport",
        "City": "Timisoara",
        "Country": "Romania"
    },
    {
        "id": "7603",
        "AirportCode": "TSS",
        "AirportName": "East 34th Street Heliport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "7604",
        "AirportCode": "TST",
        "AirportName": "Trang Airport",
        "City": "Trang",
        "Country": "Thailand"
    },
    {
        "id": "7605",
        "AirportCode": "TSU",
        "AirportName": "Tabiteuea South Airport",
        "City": "Tabiteuea South",
        "Country": "Kiribati"
    },
    {
        "id": "7606",
        "AirportCode": "TSV",
        "AirportName": "Townsville Airport",
        "City": "Townsville",
        "Country": "Australia"
    },
    {
        "id": "7607",
        "AirportCode": "TSW",
        "AirportName": "Tsewi Airport",
        "City": "Tsewi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7608",
        "AirportCode": "TSX",
        "AirportName": "Tanjung Santan Airport",
        "City": "Tanjung Santan",
        "Country": "Indonesia"
    },
    {
        "id": "7609",
        "AirportCode": "TSY",
        "AirportName": "Tasikmalaya Airport",
        "City": "Tasikmalaya",
        "Country": "Indonesia"
    },
    {
        "id": "7610",
        "AirportCode": "TSZ",
        "AirportName": "Tsetserleg Airport",
        "City": "Tsetserleg",
        "Country": "Mongolia"
    },
    {
        "id": "7611",
        "AirportCode": "TTA",
        "AirportName": "Tan Tan Airport",
        "City": "Tan Tan",
        "Country": "Morocco"
    },
    {
        "id": "7612",
        "AirportCode": "TTB",
        "AirportName": "Arbatax Airport",
        "City": "Tortoli",
        "Country": "Italy"
    },
    {
        "id": "7613",
        "AirportCode": "TTC",
        "AirportName": "Taltal Airport",
        "City": "Taltal",
        "Country": "Chile"
    },
    {
        "id": "7614",
        "AirportCode": "TTD",
        "AirportName": "Troutdale Airport",
        "City": "Troutdale",
        "Country": "USA Oregon"
    },
    {
        "id": "7615",
        "AirportCode": "TTE",
        "AirportName": "Babullah Airport",
        "City": "Ternate",
        "Country": "Indonesia"
    },
    {
        "id": "7616",
        "AirportCode": "TTG",
        "AirportName": "Tartagal Airport",
        "City": "Tartagal",
        "Country": "Argentina"
    },
    {
        "id": "7617",
        "AirportCode": "TTI",
        "AirportName": "Tetiaroa Island Airport",
        "City": "Tetiaroa Island",
        "Country": "French Polynesia"
    },
    {
        "id": "7618",
        "AirportCode": "TTJ",
        "AirportName": "Tottori Airport",
        "City": "Tottori",
        "Country": "Japan"
    },
    {
        "id": "7619",
        "AirportCode": "TTK",
        "AirportName": "Tottenham Hale Station Airport",
        "City": "Tottenham Hale Station",
        "Country": "United Kingdom"
    },
    {
        "id": "7620",
        "AirportCode": "TTL",
        "AirportName": "Turtle Island Airport",
        "City": "Turtle Island",
        "Country": "Fiji"
    },
    {
        "id": "7621",
        "AirportCode": "TTM",
        "AirportName": "Tablon De Tamara Airport",
        "City": "Tablon De Tamara",
        "Country": "Colombia"
    },
    {
        "id": "7622",
        "AirportCode": "TTN",
        "AirportName": "Mercer County Airport",
        "City": "Trenton",
        "Country": "USA New Jersey"
    },
    {
        "id": "7623",
        "AirportCode": "TTO",
        "AirportName": "Britton Municipal Airport",
        "City": "Britton",
        "Country": "USA South Dakota"
    },
    {
        "id": "7624",
        "AirportCode": "TTQ",
        "AirportName": "Tortuquero Airport",
        "City": "Tortuquero",
        "Country": "Costa Rica"
    },
    {
        "id": "7625",
        "AirportCode": "TTR",
        "AirportName": "Tana Toraja Airport",
        "City": "Tana Toraja",
        "Country": "Indonesia"
    },
    {
        "id": "7626",
        "AirportCode": "TTS",
        "AirportName": "Tsaratanana Airport",
        "City": "Tsaratanana",
        "Country": "Madagascar"
    },
    {
        "id": "7627",
        "AirportCode": "TTT",
        "AirportName": "Taitung Airport",
        "City": "Taitung",
        "Country": "Taiwan"
    },
    {
        "id": "7628",
        "AirportCode": "TTU",
        "AirportName": "Sania Ramel Airport",
        "City": "Tetuan",
        "Country": "Morocco"
    },
    {
        "id": "7629",
        "AirportCode": "TUA",
        "AirportName": "Tulcan Airport",
        "City": "Tulcan",
        "Country": "Ecuador"
    },
    {
        "id": "7630",
        "AirportCode": "TUB",
        "AirportName": "Tubuai Island Airport",
        "City": "Tubuai Island",
        "Country": "French Polynesia"
    },
    {
        "id": "7631",
        "AirportCode": "TUC",
        "AirportName": "Benj Matienzo Airport",
        "City": "Tucuman",
        "Country": "Argentina"
    },
    {
        "id": "7632",
        "AirportCode": "TUD",
        "AirportName": "Tambacounda Airport",
        "City": "Tambacounda",
        "Country": "Senegal"
    },
    {
        "id": "7633",
        "AirportCode": "TUE",
        "AirportName": "Tupile Airport",
        "City": "Tupile",
        "Country": "Panama"
    },
    {
        "id": "7634",
        "AirportCode": "TUF",
        "AirportName": "St Symphorien Airport",
        "City": "Tours",
        "Country": "France"
    },
    {
        "id": "7635",
        "AirportCode": "TUG",
        "AirportName": "Tuguegarao Airport",
        "City": "Tuguegarao",
        "Country": "Philippines"
    },
    {
        "id": "7636",
        "AirportCode": "TUH",
        "AirportName": "Arnold Air Force Base",
        "City": "Tullahoma",
        "Country": "USA Tennessee"
    },
    {
        "id": "7637",
        "AirportCode": "TUI",
        "AirportName": "Turaif Airport",
        "City": "Turaif",
        "Country": "Saudi Arabia"
    },
    {
        "id": "7638",
        "AirportCode": "TUJ",
        "AirportName": "Tum Airport",
        "City": "Tum",
        "Country": "Ethiopia"
    },
    {
        "id": "7639",
        "AirportCode": "TUK",
        "AirportName": "Turbat Airport",
        "City": "Turbat",
        "Country": "Pakistan"
    },
    {
        "id": "7640",
        "AirportCode": "TUL",
        "AirportName": "Tulsa International Airport",
        "City": "Tulsa",
        "Country": "USA Oklahoma"
    },
    {
        "id": "7641",
        "AirportCode": "TUM",
        "AirportName": "Tumut Airport",
        "City": "Tumut",
        "Country": "Australia"
    },
    {
        "id": "7642",
        "AirportCode": "TUN",
        "AirportName": "Carthage Airport",
        "City": "Tunis",
        "Country": "Tunisia"
    },
    {
        "id": "7643",
        "AirportCode": "TUO",
        "AirportName": "Taupo Airport",
        "City": "Taupo",
        "Country": "New Zealand"
    },
    {
        "id": "7644",
        "AirportCode": "TUP",
        "AirportName": "Lemons Municipal Airport",
        "City": "Tupelo",
        "Country": "USA Mississippi"
    },
    {
        "id": "7645",
        "AirportCode": "TUQ",
        "AirportName": "Tougan Airport",
        "City": "Tougan",
        "Country": "Burkina Faso"
    },
    {
        "id": "7646",
        "AirportCode": "TUR",
        "AirportName": "Tucurui Airport",
        "City": "Tucurui",
        "Country": "Brazil"
    },
    {
        "id": "7647",
        "AirportCode": "TUS",
        "AirportName": "Tucson International Airport",
        "City": "Tucson",
        "Country": "USA Arizona"
    },
    {
        "id": "7648",
        "AirportCode": "TUT",
        "AirportName": "Tauta Airport",
        "City": "Tauta",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7649",
        "AirportCode": "TUU",
        "AirportName": "Tabuk Airport",
        "City": "Tabuk",
        "Country": "Saudi Arabia"
    },
    {
        "id": "7650",
        "AirportCode": "TUV",
        "AirportName": "Tucupita Airport",
        "City": "Tucupita",
        "Country": "Venezuela"
    },
    {
        "id": "7651",
        "AirportCode": "TUW",
        "AirportName": "Tubala Airport",
        "City": "Tubala",
        "Country": "Panama"
    },
    {
        "id": "7652",
        "AirportCode": "TUX",
        "AirportName": "Tumbler Ridge Airport",
        "City": "Tumbler Ridge",
        "Country": "Canada"
    },
    {
        "id": "7653",
        "AirportCode": "TUY",
        "AirportName": "Tulum Airport",
        "City": "Tulum",
        "Country": "Mexico"
    },
    {
        "id": "7654",
        "AirportCode": "TUZ",
        "AirportName": "Tucuma Airport",
        "City": "Tucuma",
        "Country": "Brazil"
    },
    {
        "id": "7655",
        "AirportCode": "TVA",
        "AirportName": "Morafenobe Airport",
        "City": "Morafenobe",
        "Country": "Madagascar"
    },
    {
        "id": "7656",
        "AirportCode": "TVC",
        "AirportName": "Traverse City Airport",
        "City": "Traverse City",
        "Country": "USA Michigan"
    },
    {
        "id": "7657",
        "AirportCode": "TVF",
        "AirportName": "Thief River Falls Regional Airport",
        "City": "Thief River Falls",
        "Country": "USA Minnesota"
    },
    {
        "id": "7658",
        "AirportCode": "TVI",
        "AirportName": "Thomasville Municipal Airport",
        "City": "Thomasville",
        "Country": "USA Georgia"
    },
    {
        "id": "7659",
        "AirportCode": "TVL",
        "AirportName": "South Lake Tahoe Airport",
        "City": "South Lake Tahoe",
        "Country": "USA California"
    },
    {
        "id": "7660",
        "AirportCode": "TVU",
        "AirportName": "Matei Airport",
        "City": "Taveuni",
        "Country": "Fiji"
    },
    {
        "id": "7661",
        "AirportCode": "TVY",
        "AirportName": "Dawe Airport",
        "City": "Dawe",
        "Country": "Burma"
    },
    {
        "id": "7662",
        "AirportCode": "TWA",
        "AirportName": "Twin Hills Airport",
        "City": "Twin Hills",
        "Country": "USA Alaska"
    },
    {
        "id": "7663",
        "AirportCode": "TWB",
        "AirportName": "Toowoomba Airport",
        "City": "Toowoomba",
        "Country": "Australia"
    },
    {
        "id": "7664",
        "AirportCode": "TWD",
        "AirportName": "Port Townsend Airport",
        "City": "Port Townsend",
        "Country": "USA Washington"
    },
    {
        "id": "7665",
        "AirportCode": "TWF",
        "AirportName": "Twin Falls City County Airport",
        "City": "Twin Falls",
        "Country": "USA Idaho"
    },
    {
        "id": "7666",
        "AirportCode": "TWH",
        "AirportName": "Two Harbors Airport",
        "City": "Catalina Island",
        "Country": "USA California"
    },
    {
        "id": "7667",
        "AirportCode": "TWN",
        "AirportName": "Tewantin Airport",
        "City": "Tewantin",
        "Country": "Australia"
    },
    {
        "id": "7668",
        "AirportCode": "TWP",
        "AirportName": "Torwood Airport",
        "City": "Torwood",
        "Country": "Australia"
    },
    {
        "id": "7669",
        "AirportCode": "TWT",
        "AirportName": "Tawitawi Airport",
        "City": "Tawitawi",
        "Country": "Philippines"
    },
    {
        "id": "7670",
        "AirportCode": "TWU",
        "AirportName": "Tawau Airport",
        "City": "Tawau",
        "Country": "Malaysia"
    },
    {
        "id": "7671",
        "AirportCode": "TWY",
        "AirportName": "Tawa Airport",
        "City": "Tawa",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7672",
        "AirportCode": "TWZ",
        "AirportName": "Pukaki/Twizel Airport",
        "City": "Mount Cook",
        "Country": "New Zealand"
    },
    {
        "id": "7673",
        "AirportCode": "TXF",
        "AirportName": "Teixeira de Freitas Airport",
        "City": "Teixeira de Freitas",
        "Country": "Brazil"
    },
    {
        "id": "7674",
        "AirportCode": "TXG",
        "AirportName": "Taichung Airport",
        "City": "Taichung",
        "Country": "Taiwan"
    },
    {
        "id": "7675",
        "AirportCode": "TXK",
        "AirportName": "Texarkana Municipal Airport",
        "City": "Texarkana",
        "Country": "USA Arkansas"
    },
    {
        "id": "7676",
        "AirportCode": "TXL",
        "AirportName": "Tegel Airport",
        "City": "Berlin",
        "Country": "Germany"
    },
    {
        "id": "7677",
        "AirportCode": "TXM",
        "AirportName": "Teminabuan Airport",
        "City": "Teminabuan",
        "Country": "Indonesia"
    },
    {
        "id": "7678",
        "AirportCode": "TXN",
        "AirportName": "Tunxi Airport",
        "City": "Tunxi",
        "Country": "China"
    },
    {
        "id": "7679",
        "AirportCode": "TXR",
        "AirportName": "Tanbar Airport",
        "City": "Tanbar",
        "Country": "Australia"
    },
    {
        "id": "7680",
        "AirportCode": "TYA",
        "AirportName": "Tula Airport",
        "City": "Tula",
        "Country": "Russia"
    },
    {
        "id": "7681",
        "AirportCode": "TYB",
        "AirportName": "Tibooburra Airport",
        "City": "Tibooburra",
        "Country": "Australia"
    },
    {
        "id": "7682",
        "AirportCode": "TYD",
        "AirportName": "Tynda Airport",
        "City": "Tynda",
        "Country": "Russia"
    },
    {
        "id": "7683",
        "AirportCode": "TYF",
        "AirportName": "Torsby Airport",
        "City": "Torsby",
        "Country": "Sweden"
    },
    {
        "id": "7684",
        "AirportCode": "TYG",
        "AirportName": "Thylungra Airport",
        "City": "Thylungra",
        "Country": "Australia"
    },
    {
        "id": "7685",
        "AirportCode": "TYL",
        "AirportName": "Talara Airport",
        "City": "Talara",
        "Country": "Peru"
    },
    {
        "id": "7686",
        "AirportCode": "TYN",
        "AirportName": "Taiyuan Airport",
        "City": "Taiyuan",
        "Country": "China"
    },
    {
        "id": "7687",
        "AirportCode": "TYP",
        "AirportName": "Tobermorey Airport",
        "City": "Tobermorey",
        "Country": "Australia"
    },
    {
        "id": "7688",
        "AirportCode": "TYR",
        "AirportName": "Pounds Field",
        "City": "Tyler",
        "Country": "USA Texas"
    },
    {
        "id": "7689",
        "AirportCode": "TYS",
        "AirportName": "Mc Ghee Tyson Airport",
        "City": "Knoxville",
        "Country": "USA Tennessee"
    },
    {
        "id": "7690",
        "AirportCode": "TYT",
        "AirportName": "Treinta-y-Tres Airport",
        "City": "Treinta-y-Tres",
        "Country": "Uruguay"
    },
    {
        "id": "7691",
        "AirportCode": "TYZ",
        "AirportName": "Taylor Airport",
        "City": "Taylor",
        "Country": "USA Arizona"
    },
    {
        "id": "7692",
        "AirportCode": "TZA",
        "AirportName": "Belize City Municipal Airport",
        "City": "Belize City",
        "Country": "Belize"
    },
    {
        "id": "7693",
        "AirportCode": "TZL",
        "AirportName": "Tuzla International Airport",
        "City": "Tuzla",
        "Country": "Bosnia & Herzegovina"
    },
    {
        "id": "7694",
        "AirportCode": "TZM",
        "AirportName": "Tizimin Airport",
        "City": "Tizimin",
        "Country": "Mexico"
    },
    {
        "id": "7695",
        "AirportCode": "TZN",
        "AirportName": "South Andros Airport",
        "City": "South Andros",
        "Country": "Bahamas"
    },
    {
        "id": "7696",
        "AirportCode": "TZX",
        "AirportName": "Trabzon Airport",
        "City": "Trabzon",
        "Country": "Turkey"
    },
    {
        "id": "7697",
        "AirportCode": "UAB",
        "AirportName": "Adana-Incirlik Airbase",
        "City": "Adana",
        "Country": "Turkey"
    },
    {
        "id": "7698",
        "AirportCode": "UAC",
        "AirportName": "San Luis Rio Colorado Airport",
        "City": "San Luis Rio Colorado",
        "Country": "Mexico"
    },
    {
        "id": "7699",
        "AirportCode": "UAE",
        "AirportName": "Mount Aue Airport",
        "City": "Mount Aue",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7700",
        "AirportCode": "UAH",
        "AirportName": "Ua Huka Airport",
        "City": "Ua Huka",
        "Country": "French Polynesia"
    },
    {
        "id": "7701",
        "AirportCode": "UAI",
        "AirportName": "Suai Airport",
        "City": "Suai",
        "Country": "East Timor"
    },
    {
        "id": "7702",
        "AirportCode": "UAK",
        "AirportName": "Narsarsuaq Airport",
        "City": "Narsarsuaq",
        "Country": "Greenland"
    },
    {
        "id": "7703",
        "AirportCode": "UAM",
        "AirportName": "Anderson Air Force Base",
        "City": "Guam",
        "Country": "Guam"
    },
    {
        "id": "7704",
        "AirportCode": "UAP",
        "AirportName": "Ua Pou Airport",
        "City": "Ua Pou, Marquesas Island",
        "Country": "French Polynesia"
    },
    {
        "id": "7705",
        "AirportCode": "UAQ",
        "AirportName": "San Juan Airport",
        "City": "San Juan",
        "Country": "Argentina"
    },
    {
        "id": "7706",
        "AirportCode": "UAS",
        "AirportName": "Samburu Airport",
        "City": "Samburu",
        "Country": "Kenya"
    },
    {
        "id": "7707",
        "AirportCode": "UAX",
        "AirportName": "Uaxactun Airport",
        "City": "Uaxactun",
        "Country": "Guatemala"
    },
    {
        "id": "7708",
        "AirportCode": "UBA",
        "AirportName": "Uberaba Airport",
        "City": "Uberaba",
        "Country": "Brazil"
    },
    {
        "id": "7709",
        "AirportCode": "UBB",
        "AirportName": "Mabuiag Island Airport",
        "City": "Mabuiag Island",
        "Country": "Australia"
    },
    {
        "id": "7710",
        "AirportCode": "UBI",
        "AirportName": "Buin Airport",
        "City": "Buin",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7711",
        "AirportCode": "UBJ",
        "AirportName": "Ube Airport",
        "City": "Ube",
        "Country": "Japan"
    },
    {
        "id": "7712",
        "AirportCode": "UBP",
        "AirportName": "Muang Ubon Airport",
        "City": "Ubon Ratchathani",
        "Country": "Thailand"
    },
    {
        "id": "7713",
        "AirportCode": "UBR",
        "AirportName": "Ubrub, Irian Jaya Airport",
        "City": "Ubrub, Irian Jaya",
        "Country": "Indonesia"
    },
    {
        "id": "7714",
        "AirportCode": "UBS",
        "AirportName": "Lowndes County Airport",
        "City": "Columbus",
        "Country": "USA Mississippi"
    },
    {
        "id": "7715",
        "AirportCode": "UBT",
        "AirportName": "Ubatuba Airport",
        "City": "Ubatuba",
        "Country": "Brazil"
    },
    {
        "id": "7716",
        "AirportCode": "UBU",
        "AirportName": "Kalumburu Airport",
        "City": "Kalumburu",
        "Country": "Australia"
    },
    {
        "id": "7717",
        "AirportCode": "UCA",
        "AirportName": "Oneida County Airport",
        "City": "Utica",
        "Country": "USA New York"
    },
    {
        "id": "7718",
        "AirportCode": "UCC",
        "AirportName": "Yucca Flat Airport",
        "City": "Yucca Flat",
        "Country": "USA Nevada"
    },
    {
        "id": "7719",
        "AirportCode": "UCE",
        "AirportName": "Eunice Airport",
        "City": "Eunice",
        "Country": "USA Louisiana"
    },
    {
        "id": "7720",
        "AirportCode": "UCK",
        "AirportName": "Lutsk Airport",
        "City": "Lutsk",
        "Country": "Ukraine"
    },
    {
        "id": "7721",
        "AirportCode": "UCN",
        "AirportName": "Buchanan Airport",
        "City": "Buchanan",
        "Country": "Liberia"
    },
    {
        "id": "7722",
        "AirportCode": "UCT",
        "AirportName": "Ukhta Airport",
        "City": "Ukhta",
        "Country": "Russia"
    },
    {
        "id": "7723",
        "AirportCode": "UCY",
        "AirportName": "Everett-Stewart Airport",
        "City": "Union City",
        "Country": "USA Tennessee"
    },
    {
        "id": "7724",
        "AirportCode": "UDA",
        "AirportName": "Undarra Airport",
        "City": "Undarra",
        "Country": "Australia"
    },
    {
        "id": "7725",
        "AirportCode": "UDD",
        "AirportName": "Bermuda Dunes Airport",
        "City": "Palm Springs",
        "Country": "USA California"
    },
    {
        "id": "7726",
        "AirportCode": "UDE",
        "AirportName": "Volkel Airport",
        "City": "Uden",
        "Country": "Netherlands"
    },
    {
        "id": "7727",
        "AirportCode": "UDI",
        "AirportName": "Eduardo Gomes Airport",
        "City": "Uberlandia",
        "Country": "Brazil"
    },
    {
        "id": "7728",
        "AirportCode": "UDJ",
        "AirportName": "Uzhgorod Airport",
        "City": "Uzhgorod",
        "Country": "Ukraine"
    },
    {
        "id": "7729",
        "AirportCode": "UDN",
        "AirportName": "Udine Airfield",
        "City": "Udine",
        "Country": "Italy"
    },
    {
        "id": "7730",
        "AirportCode": "UDO",
        "AirportName": "Udomxay Airport",
        "City": "Udomxay",
        "Country": "Laos"
    },
    {
        "id": "7731",
        "AirportCode": "UDR",
        "AirportName": "Dabok Airport",
        "City": "Udaipur",
        "Country": "India"
    },
    {
        "id": "7732",
        "AirportCode": "UEE",
        "AirportName": "Queenstown Airport",
        "City": "Queenstown",
        "Country": "Australia"
    },
    {
        "id": "7733",
        "AirportCode": "UEL",
        "AirportName": "Quelimane Airport",
        "City": "Quelimane",
        "Country": "Mozambique"
    },
    {
        "id": "7734",
        "AirportCode": "UEO",
        "AirportName": "Kume-jima Airport",
        "City": "Kume-jima",
        "Country": "Japan"
    },
    {
        "id": "7735",
        "AirportCode": "UER",
        "AirportName": "Puertollano Airport",
        "City": "Puertollano",
        "Country": "Spain"
    },
    {
        "id": "7736",
        "AirportCode": "UES",
        "AirportName": "Waukesha Airport",
        "City": "Waukesha",
        "Country": "USA Wisconsin"
    },
    {
        "id": "7737",
        "AirportCode": "UET",
        "AirportName": "Quetta Airport",
        "City": "Quetta",
        "Country": "Pakistan"
    },
    {
        "id": "7738",
        "AirportCode": "UFA",
        "AirportName": "Ufa Airport",
        "City": "Ufa",
        "Country": "Russia"
    },
    {
        "id": "7739",
        "AirportCode": "UGA",
        "AirportName": "Bulgan Airport",
        "City": "Bulgan",
        "Country": "Mongolia"
    },
    {
        "id": "7740",
        "AirportCode": "UGB",
        "AirportName": "Ugashik Bay Airport",
        "City": "Pilot Point",
        "Country": "USA Alaska"
    },
    {
        "id": "7741",
        "AirportCode": "UGC",
        "AirportName": "Urgench Airport",
        "City": "Urgench",
        "Country": "Uzbekistan"
    },
    {
        "id": "7742",
        "AirportCode": "UGI",
        "AirportName": "Uganik Airport",
        "City": "Uganik",
        "Country": "USA Alaska"
    },
    {
        "id": "7743",
        "AirportCode": "UGN",
        "AirportName": "Waukegan Memorial Airport",
        "City": "Waukegan",
        "Country": "USA Illinois"
    },
    {
        "id": "7744",
        "AirportCode": "UGT",
        "AirportName": "Umnugobitour Airport",
        "City": "Umnugobitour",
        "Country": "Mongolia"
    },
    {
        "id": "7745",
        "AirportCode": "UGU",
        "AirportName": "Zugapa, Irian Jaya Airport",
        "City": "Zugapa, Irian Jaya",
        "Country": "Indonesia"
    },
    {
        "id": "7746",
        "AirportCode": "UHE",
        "AirportName": "Uherske Hradiste Airport",
        "City": "Uherske Hradiste",
        "Country": "Czech Republic"
    },
    {
        "id": "7747",
        "AirportCode": "UHF",
        "AirportName": "Upper Heyford RAF Station",
        "City": "Upper Heyford",
        "Country": "United Kingdom"
    },
    {
        "id": "7748",
        "AirportCode": "UIB",
        "AirportName": "Quibdo Airport",
        "City": "Quibdo",
        "Country": "Colombia"
    },
    {
        "id": "7749",
        "AirportCode": "UIH",
        "AirportName": "Qui Nhon Airport",
        "City": "Qui Nhon",
        "Country": "Vietnam"
    },
    {
        "id": "7750",
        "AirportCode": "UII",
        "AirportName": "Utila Airport",
        "City": "Utila",
        "Country": "Honduras"
    },
    {
        "id": "7751",
        "AirportCode": "UIK",
        "AirportName": "Ust-Ilimsk Airport",
        "City": "Ust-Ilimsk",
        "Country": "Russia"
    },
    {
        "id": "7752",
        "AirportCode": "UIL",
        "AirportName": "Quillayute State Airport",
        "City": "Quillayute",
        "Country": "USA Washington"
    },
    {
        "id": "7753",
        "AirportCode": "UIN",
        "AirportName": "Quincy Municipal Airport",
        "City": "Quincy",
        "Country": "USA Illinois"
    },
    {
        "id": "7754",
        "AirportCode": "UIO",
        "AirportName": "Mariscal Sucre Airport",
        "City": "Quito",
        "Country": "Ecuador"
    },
    {
        "id": "7755",
        "AirportCode": "UIP",
        "AirportName": "Pluguffan Airport",
        "City": "Quimper",
        "Country": "France"
    },
    {
        "id": "7756",
        "AirportCode": "UIQ",
        "AirportName": "Quine Hill Airport",
        "City": "Quine Hill",
        "Country": "Vanuatu"
    },
    {
        "id": "7757",
        "AirportCode": "UIR",
        "AirportName": "Quirindi Airport",
        "City": "Quirindi",
        "Country": "Australia"
    },
    {
        "id": "7758",
        "AirportCode": "UIZ",
        "AirportName": "Berz-Macomb Airport",
        "City": "Utica",
        "Country": "USA Michigan"
    },
    {
        "id": "7759",
        "AirportCode": "UJE",
        "AirportName": "Ujae Island Airport",
        "City": "Ujae Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "7760",
        "AirportCode": "UKA",
        "AirportName": "Ukunda Airport",
        "City": "Ukunda",
        "Country": "Kenya"
    },
    {
        "id": "7761",
        "AirportCode": "UKB",
        "AirportName": "Kobe Airport",
        "City": "Kobe",
        "Country": "Japan"
    },
    {
        "id": "7762",
        "AirportCode": "UKI",
        "AirportName": "Ukiah Airport",
        "City": "Ukiah",
        "Country": "USA California"
    },
    {
        "id": "7763",
        "AirportCode": "UKK",
        "AirportName": "Ust-Kamenogorsk Airport",
        "City": "Ust-Kamenogorsk",
        "Country": "Kazakhstan"
    },
    {
        "id": "7764",
        "AirportCode": "UKN",
        "AirportName": "Waukon Municipal Airport",
        "City": "Waukon",
        "Country": "USA Iowa"
    },
    {
        "id": "7765",
        "AirportCode": "UKR",
        "AirportName": "Mukeiras Airport",
        "City": "Mukeiras",
        "Country": "Yemen"
    },
    {
        "id": "7766",
        "AirportCode": "UKS",
        "AirportName": "Belbek Airport",
        "City": "Sevastopol",
        "Country": "Ukraine"
    },
    {
        "id": "7767",
        "AirportCode": "UKT",
        "AirportName": "Upper Bucks Airport",
        "City": "Quakertown",
        "Country": "USA Philadelphia"
    },
    {
        "id": "7768",
        "AirportCode": "UKU",
        "AirportName": "Nuku Airport",
        "City": "Nuku",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7769",
        "AirportCode": "UKX",
        "AirportName": "Ust-Kut Airport",
        "City": "Ust-Kut",
        "Country": "Russia"
    },
    {
        "id": "7770",
        "AirportCode": "UKY",
        "AirportName": "Kyoto Airport",
        "City": "Kyoto",
        "Country": "Japan"
    },
    {
        "id": "7771",
        "AirportCode": "ULA",
        "AirportName": "San Julian Airport",
        "City": "San Julian",
        "Country": "Argentina"
    },
    {
        "id": "7772",
        "AirportCode": "ULB",
        "AirportName": "Ulei Airport",
        "City": "Ulei",
        "Country": "Vanuatu"
    },
    {
        "id": "7773",
        "AirportCode": "ULC",
        "AirportName": "Los Cerrillos Airport",
        "City": "Santiago",
        "Country": "Chile"
    },
    {
        "id": "7774",
        "AirportCode": "ULD",
        "AirportName": "Ulundi Airport",
        "City": "Ulundi",
        "Country": "South Africa"
    },
    {
        "id": "7775",
        "AirportCode": "ULE",
        "AirportName": "Sule Airport",
        "City": "Sule",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7776",
        "AirportCode": "ULG",
        "AirportName": "Ulgit Airport",
        "City": "Ulgit",
        "Country": "Mongolia"
    },
    {
        "id": "7777",
        "AirportCode": "ULL",
        "AirportName": "Mull Airport",
        "City": "Mull",
        "Country": "United Kingdom"
    },
    {
        "id": "7778",
        "AirportCode": "ULM",
        "AirportName": "New Ulm Airport",
        "City": "New Ulm",
        "Country": "USA Minnesota"
    },
    {
        "id": "7779",
        "AirportCode": "ULN",
        "AirportName": "Buyant Uhaa Airport",
        "City": "Ulaanbaatar",
        "Country": "Mongolia"
    },
    {
        "id": "7780",
        "AirportCode": "ULO",
        "AirportName": "Ulaangom Airport",
        "City": "Ulaangom",
        "Country": "Mongolia"
    },
    {
        "id": "7781",
        "AirportCode": "ULP",
        "AirportName": "Quilpie Airport",
        "City": "Quilpie",
        "Country": "Australia"
    },
    {
        "id": "7782",
        "AirportCode": "ULQ",
        "AirportName": "Farfan Airport",
        "City": "Tulua",
        "Country": "Colombia"
    },
    {
        "id": "7783",
        "AirportCode": "ULS",
        "AirportName": "Mulatos Airport",
        "City": "Mulatos",
        "Country": "Colombia"
    },
    {
        "id": "7784",
        "AirportCode": "ULU",
        "AirportName": "Gulu Airport",
        "City": "Gulu",
        "Country": "Uganda"
    },
    {
        "id": "7785",
        "AirportCode": "ULX",
        "AirportName": "Ulusaba Airport",
        "City": "Ulusaba",
        "Country": "South Africa"
    },
    {
        "id": "7786",
        "AirportCode": "ULY",
        "AirportName": "Ulyanovsk Airport",
        "City": "Ulyanovsk",
        "Country": "Russia"
    },
    {
        "id": "7787",
        "AirportCode": "ULZ",
        "AirportName": "Uliastai Airport",
        "City": "Uliastai",
        "Country": "Mongolia"
    },
    {
        "id": "7788",
        "AirportCode": "UMC",
        "AirportName": "Umba Airport",
        "City": "Umba",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7789",
        "AirportCode": "UMD",
        "AirportName": "Umanak Airport",
        "City": "Uummannaq",
        "Country": "Greenland"
    },
    {
        "id": "7790",
        "AirportCode": "UME",
        "AirportName": "Umea Airport",
        "City": "Umea",
        "Country": "Sweden"
    },
    {
        "id": "7791",
        "AirportCode": "UMI",
        "AirportName": "Quincemil Airport",
        "City": "Quincemil",
        "Country": "Peru"
    },
    {
        "id": "7792",
        "AirportCode": "UMR",
        "AirportName": "Woomera Airport",
        "City": "Woomera",
        "Country": "Australia"
    },
    {
        "id": "7793",
        "AirportCode": "UMU",
        "AirportName": "Ernesto Geisel Airport",
        "City": "Umuarama",
        "Country": "Brazil"
    },
    {
        "id": "7794",
        "AirportCode": "UMY",
        "AirportName": "Sumy Airport",
        "City": "Sumy",
        "Country": "Ukraine"
    },
    {
        "id": "7795",
        "AirportCode": "UNA",
        "AirportName": "Una Airport",
        "City": "Una",
        "Country": "Brazil"
    },
    {
        "id": "7796",
        "AirportCode": "UNC",
        "AirportName": "Unguia Airport",
        "City": "Unguia",
        "Country": "Colombia"
    },
    {
        "id": "7797",
        "AirportCode": "UND",
        "AirportName": "Kunduz Airport",
        "City": "Kunduz",
        "Country": "Afghanistan"
    },
    {
        "id": "7798",
        "AirportCode": "UNE",
        "AirportName": "Qachas Airport",
        "City": "Qachas",
        "Country": "Lesotho"
    },
    {
        "id": "7799",
        "AirportCode": "UNG",
        "AirportName": "Kiunga Airport",
        "City": "Kiunga",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7800",
        "AirportCode": "UNI",
        "AirportName": "Union Island Airport",
        "City": "Union Island",
        "Country": "St Vincent"
    },
    {
        "id": "7801",
        "AirportCode": "UNK",
        "AirportName": "Unalakleet Airport",
        "City": "Unalakleet",
        "Country": "USA Alaska"
    },
    {
        "id": "7802",
        "AirportCode": "UNN",
        "AirportName": "Ranong Airport",
        "City": "Ranong",
        "Country": "Thailand"
    },
    {
        "id": "7803",
        "AirportCode": "UNR",
        "AirportName": "Underkhaan Airport",
        "City": "Underkhaan",
        "Country": "Mongolia"
    },
    {
        "id": "7804",
        "AirportCode": "UNT",
        "AirportName": "Baltasound Airport",
        "City": "Unst Shetland Is State",
        "Country": "United Kingdom"
    },
    {
        "id": "7805",
        "AirportCode": "UNU",
        "AirportName": "Dodge County Airport",
        "City": "Juneau",
        "Country": "USA Wisconsin"
    },
    {
        "id": "7806",
        "AirportCode": "UNV",
        "AirportName": "University Park",
        "City": "State College",
        "Country": "USA Philadelphia"
    },
    {
        "id": "7807",
        "AirportCode": "UOL",
        "AirportName": "Buol Airport",
        "City": "Buol",
        "Country": "Indonesia"
    },
    {
        "id": "7808",
        "AirportCode": "UON",
        "AirportName": "Muong Sai Airport",
        "City": "Muong Sai",
        "Country": "Laos"
    },
    {
        "id": "7809",
        "AirportCode": "UOS",
        "AirportName": "Franklin County Airport",
        "City": "Sewanee",
        "Country": "USA Tennessee"
    },
    {
        "id": "7810",
        "AirportCode": "UOX",
        "AirportName": "University-Oxford Airport",
        "City": "Oxford",
        "Country": "USA Mississippi"
    },
    {
        "id": "7811",
        "AirportCode": "UPA",
        "AirportName": "Punta Alegre Airport",
        "City": "Punta Alegre",
        "Country": "Cuba"
    },
    {
        "id": "7812",
        "AirportCode": "UPB",
        "AirportName": "Playa Baracoa Airport",
        "City": "Havana",
        "Country": "Cuba"
    },
    {
        "id": "7813",
        "AirportCode": "UPC",
        "AirportName": "Puerto La Cruz Airport",
        "City": "Puerto La Cruz",
        "Country": "Venezuela"
    },
    {
        "id": "7814",
        "AirportCode": "UPF",
        "AirportName": "Pforheim Airport",
        "City": "Pforheim",
        "Country": "Germany"
    },
    {
        "id": "7815",
        "AirportCode": "UPG",
        "AirportName": "Hasanudin Airport",
        "City": "Ujung Pandang",
        "Country": "Indonesia"
    },
    {
        "id": "7816",
        "AirportCode": "UPL",
        "AirportName": "Upala Airport",
        "City": "Upala",
        "Country": "Costa Rica"
    },
    {
        "id": "7817",
        "AirportCode": "UPN",
        "AirportName": "Uruapan Airport",
        "City": "Uruapan",
        "Country": "Mexico"
    },
    {
        "id": "7818",
        "AirportCode": "UPP",
        "AirportName": "Upolu Point Airport",
        "City": "Upolu Point",
        "Country": "USA Hawaii"
    },
    {
        "id": "7819",
        "AirportCode": "UPR",
        "AirportName": "Upiara Airport",
        "City": "Upiara",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7820",
        "AirportCode": "UPV",
        "AirportName": "Upavon Airport",
        "City": "Upavon",
        "Country": "United Kingdom"
    },
    {
        "id": "7821",
        "AirportCode": "URA",
        "AirportName": "Uralsk Airport",
        "City": "Uralsk",
        "Country": "Kazakhstan"
    },
    {
        "id": "7822",
        "AirportCode": "URB",
        "AirportName": "Ernesto Pochler Airport",
        "City": "Urubupunga",
        "Country": "Brazil"
    },
    {
        "id": "7823",
        "AirportCode": "URC",
        "AirportName": "Urumqi Airport",
        "City": "Urumqi",
        "Country": "China"
    },
    {
        "id": "7824",
        "AirportCode": "URD",
        "AirportName": "Burg Feuerstein Airport",
        "City": "Burg Feuerstein",
        "Country": "Germany"
    },
    {
        "id": "7825",
        "AirportCode": "URE",
        "AirportName": "Kuressaare Airport",
        "City": "Kuressaare",
        "Country": "Estonia"
    },
    {
        "id": "7826",
        "AirportCode": "URG",
        "AirportName": "Ruben Berta Airport",
        "City": "Uruguaiana",
        "Country": "Brazil"
    },
    {
        "id": "7827",
        "AirportCode": "URI",
        "AirportName": "Uribe Airport",
        "City": "Uribe",
        "Country": "Colombia"
    },
    {
        "id": "7828",
        "AirportCode": "URJ",
        "AirportName": "Uraj Airport",
        "City": "Uraj",
        "Country": "Russia"
    },
    {
        "id": "7829",
        "AirportCode": "URM",
        "AirportName": "Uriman Airport",
        "City": "Uriman",
        "Country": "Venezuela"
    },
    {
        "id": "7830",
        "AirportCode": "URN",
        "AirportName": "Urgoon Airport",
        "City": "Urgoon",
        "Country": "Afghanistan"
    },
    {
        "id": "7831",
        "AirportCode": "URO",
        "AirportName": "Boos Airport",
        "City": "Rouen",
        "Country": "France"
    },
    {
        "id": "7832",
        "AirportCode": "URR",
        "AirportName": "Urrao Airport",
        "City": "Urrao",
        "Country": "Colombia"
    },
    {
        "id": "7833",
        "AirportCode": "URS",
        "AirportName": "Kursk Airport",
        "City": "Kursk",
        "Country": "Russia"
    },
    {
        "id": "7834",
        "AirportCode": "URT",
        "AirportName": "Surat Thani Airport",
        "City": "Surat Thani",
        "Country": "Thailand"
    },
    {
        "id": "7835",
        "AirportCode": "URU",
        "AirportName": "Oro Province Airport",
        "City": "Uroubi",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7836",
        "AirportCode": "URY",
        "AirportName": "Gurayat Airport",
        "City": "Gurayat",
        "Country": "Saudi Arabia"
    },
    {
        "id": "7837",
        "AirportCode": "URZ",
        "AirportName": "Uruzgan Airport",
        "City": "Uruzgan",
        "Country": "Afghanistan"
    },
    {
        "id": "7838",
        "AirportCode": "USH",
        "AirportName": "Islas Malvinas Airport",
        "City": "Ushuaia",
        "Country": "Argentina"
    },
    {
        "id": "7839",
        "AirportCode": "USI",
        "AirportName": "Mabaruma Airport",
        "City": "Mabaruma",
        "Country": "Guyana"
    },
    {
        "id": "7840",
        "AirportCode": "USK",
        "AirportName": "Usinsk Airport",
        "City": "Usinsk",
        "Country": "Russia"
    },
    {
        "id": "7841",
        "AirportCode": "USL",
        "AirportName": "Useless Loop Airport",
        "City": "Useless Loop",
        "Country": "Australia"
    },
    {
        "id": "7842",
        "AirportCode": "USM",
        "AirportName": "Koh Samui Airport",
        "City": "Koh Samui",
        "Country": "Thailand"
    },
    {
        "id": "7843",
        "AirportCode": "USN",
        "AirportName": "Ulsan Airport",
        "City": "Ulsan",
        "Country": "Korea"
    },
    {
        "id": "7844",
        "AirportCode": "USO",
        "AirportName": "Usino Airport",
        "City": "Usino",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7845",
        "AirportCode": "USQ",
        "AirportName": "Usak Airport",
        "City": "Usak",
        "Country": "Turkey"
    },
    {
        "id": "7846",
        "AirportCode": "USS",
        "AirportName": "Sancti Spiritus Airport",
        "City": "Sancti Spiritus",
        "Country": "Cuba"
    },
    {
        "id": "7847",
        "AirportCode": "UST",
        "AirportName": "St. Augustine Airport",
        "City": "Saint Augustine",
        "Country": "USA Florida"
    },
    {
        "id": "7848",
        "AirportCode": "USU",
        "AirportName": "Busuanga Airport",
        "City": "Busuanga",
        "Country": "Philippines"
    },
    {
        "id": "7849",
        "AirportCode": "UTA",
        "AirportName": "Mutare Airport",
        "City": "Mutare",
        "Country": "Zimbabwe"
    },
    {
        "id": "7850",
        "AirportCode": "UTB",
        "AirportName": "Muttaburra Airport",
        "City": "Muttaburra",
        "Country": "Australia"
    },
    {
        "id": "7851",
        "AirportCode": "UTC",
        "AirportName": "Soesterberg Airport",
        "City": "Utrecht",
        "Country": "Netherlands"
    },
    {
        "id": "7852",
        "AirportCode": "UTD",
        "AirportName": "Nutwood Downs Airport",
        "City": "Nutwood Downs",
        "Country": "Australia"
    },
    {
        "id": "7853",
        "AirportCode": "UTE",
        "AirportName": "Butterworth Airport",
        "City": "Butterworth",
        "Country": "South Africa"
    },
    {
        "id": "7854",
        "AirportCode": "UTG",
        "AirportName": "Quthing Airport",
        "City": "Quthing",
        "Country": "Lesotho"
    },
    {
        "id": "7855",
        "AirportCode": "UTH",
        "AirportName": "Udon Thani Airport",
        "City": "Udon Thani",
        "Country": "Thailand"
    },
    {
        "id": "7856",
        "AirportCode": "UTI",
        "AirportName": "Utti Airport",
        "City": "Kouvola",
        "Country": "Finland"
    },
    {
        "id": "7857",
        "AirportCode": "UTK",
        "AirportName": "Utirik Island Airport",
        "City": "Utirik Island",
        "Country": "Marshall Islands"
    },
    {
        "id": "7858",
        "AirportCode": "UTL",
        "AirportName": "Torremolinos Airport",
        "City": "Torremolinos",
        "Country": "Spain"
    },
    {
        "id": "7859",
        "AirportCode": "UTM",
        "AirportName": "Tunica Municipal Airport",
        "City": "Tunica",
        "Country": "USA Mississippi"
    },
    {
        "id": "7860",
        "AirportCode": "UTN",
        "AirportName": "Upington Airport",
        "City": "Upington",
        "Country": "South Africa"
    },
    {
        "id": "7861",
        "AirportCode": "UTP",
        "AirportName": "Utapao Airport",
        "City": "Utapao",
        "Country": "Thailand"
    },
    {
        "id": "7862",
        "AirportCode": "UTR",
        "AirportName": "Uttaradit Airport",
        "City": "Uttaradit",
        "Country": "Thailand"
    },
    {
        "id": "7863",
        "AirportCode": "UTT",
        "AirportName": "Umtata Airport",
        "City": "Umtata",
        "Country": "South Africa"
    },
    {
        "id": "7864",
        "AirportCode": "UTU",
        "AirportName": "Ustupo Airport",
        "City": "Ustupo",
        "Country": "Panama"
    },
    {
        "id": "7865",
        "AirportCode": "UTW",
        "AirportName": "Queenstown Airport",
        "City": "Queenstown",
        "Country": "South Africa"
    },
    {
        "id": "7866",
        "AirportCode": "UUA",
        "AirportName": "Bugulma Airport",
        "City": "Bugulma",
        "Country": "Russia"
    },
    {
        "id": "7867",
        "AirportCode": "UUD",
        "AirportName": "Ulan-Ude Airport",
        "City": "Ulan-Ude",
        "Country": "Russia"
    },
    {
        "id": "7868",
        "AirportCode": "UUN",
        "AirportName": "Baruun-Urt Airport",
        "City": "Baruun-Urt",
        "Country": "Mongolia"
    },
    {
        "id": "7869",
        "AirportCode": "UUS",
        "AirportName": "Yuzhno-Sakhalinsk Airport",
        "City": "Yuzhno-Sakhalinsk",
        "Country": "Russia"
    },
    {
        "id": "7870",
        "AirportCode": "UUU",
        "AirportName": "Manumu Airport",
        "City": "Manumu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7871",
        "AirportCode": "UVA",
        "AirportName": "Garner Field",
        "City": "Uvalde",
        "Country": "USA Texas"
    },
    {
        "id": "7872",
        "AirportCode": "UVE",
        "AirportName": "Ouvea Airport",
        "City": "Ouvea",
        "Country": "New Caledonia"
    },
    {
        "id": "7873",
        "AirportCode": "UVF",
        "AirportName": "Hewanorra Airport",
        "City": "Saint Lucia",
        "Country": "St Lucia"
    },
    {
        "id": "7874",
        "AirportCode": "UVL",
        "AirportName": "Kharga Airport",
        "City": "New Valley",
        "Country": "Egypt"
    },
    {
        "id": "7875",
        "AirportCode": "UVO",
        "AirportName": "Uvol Airport",
        "City": "Uvol",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7876",
        "AirportCode": "UWA",
        "AirportName": "Ware Airport",
        "City": "Ware",
        "Country": "USA Massachusetts"
    },
    {
        "id": "7877",
        "AirportCode": "UYL",
        "AirportName": "Nyala Airport",
        "City": "Nyala",
        "Country": "Sudan"
    },
    {
        "id": "7878",
        "AirportCode": "UYN",
        "AirportName": "Yulin Airport",
        "City": "Yulin",
        "Country": "China"
    },
    {
        "id": "7879",
        "AirportCode": "UZC",
        "AirportName": "Ponikve Airport",
        "City": "Uzice",
        "Country": "Serbia"
    },
    {
        "id": "7880",
        "AirportCode": "UZH",
        "AirportName": "Unayzah Airport",
        "City": "Unayzah",
        "Country": "Saudi Arabia"
    },
    {
        "id": "7881",
        "AirportCode": "UZU",
        "AirportName": "Curuzu Cuatia Airport",
        "City": "Curuzu Cuatia",
        "Country": "Argentina"
    },
    {
        "id": "7882",
        "AirportCode": "VAA",
        "AirportName": "Vaasa Airport",
        "City": "Vaasa",
        "Country": "Finland"
    },
    {
        "id": "7883",
        "AirportCode": "VAB",
        "AirportName": "Yavarate Airport",
        "City": "Yavarate",
        "Country": "Colombia"
    },
    {
        "id": "7884",
        "AirportCode": "VAC",
        "AirportName": "Varrelbusch Airport",
        "City": "Varrelbusch",
        "Country": "Germany"
    },
    {
        "id": "7885",
        "AirportCode": "VAD",
        "AirportName": "Moody Air Force Base",
        "City": "Valdosta",
        "Country": "USA Georgia"
    },
    {
        "id": "7886",
        "AirportCode": "VAF",
        "AirportName": "Chabeuil Airport",
        "City": "Valence",
        "Country": "France"
    },
    {
        "id": "7887",
        "AirportCode": "VAG",
        "AirportName": "Maj. Brig. Trompowsky Airport",
        "City": "Varginha",
        "Country": "Brazil"
    },
    {
        "id": "7888",
        "AirportCode": "VAH",
        "AirportName": "Vallegrande Airport",
        "City": "Vallegrande",
        "Country": "Bolivia"
    },
    {
        "id": "7889",
        "AirportCode": "VAI",
        "AirportName": "Vanimo Airport",
        "City": "Vanimo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7890",
        "AirportCode": "VAK",
        "AirportName": "Chevak Airport",
        "City": "Chevak",
        "Country": "USA Alaska"
    },
    {
        "id": "7891",
        "AirportCode": "VAL",
        "AirportName": "Valenca Airport",
        "City": "Valenca",
        "Country": "Brazil"
    },
    {
        "id": "7892",
        "AirportCode": "VAN",
        "AirportName": "Van Airport",
        "City": "Van",
        "Country": "Turkey"
    },
    {
        "id": "7893",
        "AirportCode": "VAO",
        "AirportName": "Suavanao Airstrip",
        "City": "Suavanao",
        "Country": "Solomon Islands"
    },
    {
        "id": "7894",
        "AirportCode": "VAP",
        "AirportName": "Valparaiso Airport",
        "City": "Valparaiso",
        "Country": "Chile"
    },
    {
        "id": "7895",
        "AirportCode": "VAR",
        "AirportName": "Varna Airport",
        "City": "Varna",
        "Country": "Bulgaria"
    },
    {
        "id": "7896",
        "AirportCode": "VAS",
        "AirportName": "Sivas Airport",
        "City": "Sivas",
        "Country": "Turkey"
    },
    {
        "id": "7897",
        "AirportCode": "VAT",
        "AirportName": "Vatomandry Airport",
        "City": "Vatomandry",
        "Country": "Madagascar"
    },
    {
        "id": "7898",
        "AirportCode": "VAU",
        "AirportName": "Vatukoula Airport",
        "City": "Vatukoula",
        "Country": "Fiji"
    },
    {
        "id": "7899",
        "AirportCode": "VAW",
        "AirportName": "Vardoe Airport",
        "City": "Vardoe",
        "Country": "Norway"
    },
    {
        "id": "7900",
        "AirportCode": "VBG",
        "AirportName": "Vandenburg Air Force Base",
        "City": "Lompoc",
        "Country": "USA California"
    },
    {
        "id": "7901",
        "AirportCode": "VBS",
        "AirportName": "Montichiari Airport",
        "City": "Brescia",
        "Country": "Italy"
    },
    {
        "id": "7902",
        "AirportCode": "VBV",
        "AirportName": "Vanuabalavu Airport",
        "City": "Vanuabalavu",
        "Country": "Fiji"
    },
    {
        "id": "7903",
        "AirportCode": "VBY",
        "AirportName": "Visby Airport",
        "City": "Visby",
        "Country": "Sweden"
    },
    {
        "id": "7904",
        "AirportCode": "VCA",
        "AirportName": "Can Tho Airport",
        "City": "Can Tho",
        "Country": "Vietnam"
    },
    {
        "id": "7905",
        "AirportCode": "VCC",
        "AirportName": "Limbe Airport",
        "City": "Limbe",
        "Country": "Cameroon"
    },
    {
        "id": "7906",
        "AirportCode": "VCD",
        "AirportName": "Victoria River Downs Airport",
        "City": "Victoria River Downs",
        "Country": "Australia"
    },
    {
        "id": "7907",
        "AirportCode": "VCE",
        "AirportName": "Marco Polo Airport",
        "City": "Venice",
        "Country": "Italy"
    },
    {
        "id": "7908",
        "AirportCode": "VCF",
        "AirportName": "Valcheta Airport",
        "City": "Valcheta",
        "Country": "Argentina"
    },
    {
        "id": "7909",
        "AirportCode": "VCH",
        "AirportName": "ViMaliero Airport",
        "City": "ViMaliero",
        "Country": "Uruguay"
    },
    {
        "id": "7910",
        "AirportCode": "VCL",
        "AirportName": "Chulai Airport",
        "City": "Tamky-Chulai Airport",
        "Country": "Vietnam"
    },
    {
        "id": "7911",
        "AirportCode": "VCP",
        "AirportName": "Viracopos Airport",
        "City": "Sao Paulo",
        "Country": "Brazil"
    },
    {
        "id": "7912",
        "AirportCode": "VCR",
        "AirportName": "Carora Airport",
        "City": "Carora",
        "Country": "Venezuela"
    },
    {
        "id": "7913",
        "AirportCode": "VCS",
        "AirportName": "Coong Airport",
        "City": "Con Dao",
        "Country": "Vietnam"
    },
    {
        "id": "7914",
        "AirportCode": "VCT",
        "AirportName": "County-Foster Airport",
        "City": "Victoria",
        "Country": "USA Texas"
    },
    {
        "id": "7915",
        "AirportCode": "VCV",
        "AirportName": "George Air Force Base",
        "City": "Victorville",
        "Country": "USA California"
    },
    {
        "id": "7916",
        "AirportCode": "VDB",
        "AirportName": "Valdres Airport",
        "City": "Fagernes",
        "Country": "Norway"
    },
    {
        "id": "7917",
        "AirportCode": "VDC",
        "AirportName": "Vitoria Da Conquista Airport",
        "City": "Vitoria Da Conquista",
        "Country": "Brazil"
    },
    {
        "id": "7918",
        "AirportCode": "VDD",
        "AirportName": "Vienna Danubepier Airport",
        "City": "Vienna",
        "Country": "Austria"
    },
    {
        "id": "7919",
        "AirportCode": "VDE",
        "AirportName": "Hierro Airport",
        "City": "Valverde",
        "Country": "Spain"
    },
    {
        "id": "7920",
        "AirportCode": "VDF",
        "AirportName": "Vandenberg",
        "City": "Tampa",
        "Country": "USA Florida"
    },
    {
        "id": "7921",
        "AirportCode": "VDI",
        "AirportName": "Vidalia Municipal Airport",
        "City": "Vidalia",
        "Country": "USA Georgia"
    },
    {
        "id": "7922",
        "AirportCode": "VDM",
        "AirportName": "Viedma Airport",
        "City": "Viedma",
        "Country": "Argentina"
    },
    {
        "id": "7923",
        "AirportCode": "VDP",
        "AirportName": "Valle de la Pascua Airport",
        "City": "Valle de la Pascua",
        "Country": "Venezuela"
    },
    {
        "id": "7924",
        "AirportCode": "VDR",
        "AirportName": "Villa Dolores Airport",
        "City": "Villa Dolores",
        "Country": "Argentina"
    },
    {
        "id": "7925",
        "AirportCode": "VDS",
        "AirportName": "Vadso Airport",
        "City": "Vadso",
        "Country": "Norway"
    },
    {
        "id": "7926",
        "AirportCode": "VDZ",
        "AirportName": "Valdez Municipal Airport",
        "City": "Valdez",
        "Country": "USA Alaska"
    },
    {
        "id": "7927",
        "AirportCode": "VEE",
        "AirportName": "Venetie Airport",
        "City": "Venetie",
        "Country": "USA Alaska"
    },
    {
        "id": "7928",
        "AirportCode": "VEG",
        "AirportName": "Maikwak Airport",
        "City": "Maikwak",
        "Country": "Guyana"
    },
    {
        "id": "7929",
        "AirportCode": "VEJ",
        "AirportName": "Vejle Airport",
        "City": "Vejle",
        "Country": "Denmark"
    },
    {
        "id": "7930",
        "AirportCode": "VEL",
        "AirportName": "Vernal Airport",
        "City": "Vernal",
        "Country": "USA Utah"
    },
    {
        "id": "7931",
        "AirportCode": "VER",
        "AirportName": "Las Bajadas Airport",
        "City": "Veracruz",
        "Country": "Mexico"
    },
    {
        "id": "7932",
        "AirportCode": "VEX",
        "AirportName": "Tioga Municipal Airport",
        "City": "Tioga",
        "Country": "USA North Dakota"
    },
    {
        "id": "7933",
        "AirportCode": "VEY",
        "AirportName": "Vestmannaeyjar Airport",
        "City": "Vestmannaeyjar",
        "Country": "Iceland"
    },
    {
        "id": "7934",
        "AirportCode": "VFA",
        "AirportName": "Victoria Falls Airport",
        "City": "Victoria Falls",
        "Country": "Zimbabwe"
    },
    {
        "id": "7935",
        "AirportCode": "VGA",
        "AirportName": "Vijayawada Airport",
        "City": "Vijayawada",
        "Country": "India"
    },
    {
        "id": "7936",
        "AirportCode": "VGD",
        "AirportName": "Vologda Airport",
        "City": "Vologda",
        "Country": "Russia"
    },
    {
        "id": "7937",
        "AirportCode": "VGO",
        "AirportName": "Vigo Airport",
        "City": "Vigo",
        "Country": "Spain"
    },
    {
        "id": "7938",
        "AirportCode": "VGS",
        "AirportName": "General Villegas Airport",
        "City": "General Villegas",
        "Country": "Argentina"
    },
    {
        "id": "7939",
        "AirportCode": "VGT",
        "AirportName": "North Air Terminal",
        "City": "Las Vegas",
        "Country": "USA Nevada"
    },
    {
        "id": "7940",
        "AirportCode": "VGZ",
        "AirportName": "Villagarzon Airport",
        "City": "Villagarzon",
        "Country": "Colombia"
    },
    {
        "id": "7941",
        "AirportCode": "VHC",
        "AirportName": "Saurimo Airport",
        "City": "Saurimo",
        "Country": "Angola"
    },
    {
        "id": "7942",
        "AirportCode": "VHM",
        "AirportName": "Vilhelmina Airport",
        "City": "Vilhelmina",
        "Country": "Sweden"
    },
    {
        "id": "7943",
        "AirportCode": "VHN",
        "AirportName": "Culberson County Airport",
        "City": "Van Horn",
        "Country": "USA Texas"
    },
    {
        "id": "7944",
        "AirportCode": "VHO",
        "AirportName": "Vila Coutinho Airport",
        "City": "Vila Coutinho",
        "Country": "Mozambique"
    },
    {
        "id": "7945",
        "AirportCode": "VHY",
        "AirportName": "Charmeil Airport",
        "City": "Vichy",
        "Country": "France"
    },
    {
        "id": "7946",
        "AirportCode": "VHZ",
        "AirportName": "Vahitahi Airport",
        "City": "Vahitahi",
        "Country": "French Polynesia"
    },
    {
        "id": "7947",
        "AirportCode": "VIA",
        "AirportName": "Videira Airport",
        "City": "Videira",
        "Country": "Brazil"
    },
    {
        "id": "7948",
        "AirportCode": "VIB",
        "AirportName": "Villa Constitucion Airport",
        "City": "Villa Constitucion",
        "Country": "Mexico"
    },
    {
        "id": "7949",
        "AirportCode": "VIC",
        "AirportName": "Vicenza Airport",
        "City": "Vicenza",
        "Country": "Italy"
    },
    {
        "id": "7950",
        "AirportCode": "VID",
        "AirportName": "Vidin Airport",
        "City": "Vidin",
        "Country": "Bulgaria"
    },
    {
        "id": "7951",
        "AirportCode": "VIE",
        "AirportName": "Vienna International Airport",
        "City": "Vienna",
        "Country": "Austria"
    },
    {
        "id": "7952",
        "AirportCode": "VIF",
        "AirportName": "Vieste Airport",
        "City": "Vieste",
        "Country": "Italy"
    },
    {
        "id": "7953",
        "AirportCode": "VIG",
        "AirportName": "El Vigia Airport",
        "City": "El Vigia",
        "Country": "Venezuela"
    },
    {
        "id": "7954",
        "AirportCode": "VIH",
        "AirportName": "Rolla National Airport",
        "City": "Vichy",
        "Country": "USA Missouri"
    },
    {
        "id": "7955",
        "AirportCode": "VII",
        "AirportName": "Vinh City Airport",
        "City": "Vinh City",
        "Country": "Vietnam"
    },
    {
        "id": "7956",
        "AirportCode": "VIJ",
        "AirportName": "Virgin Gorda Airport",
        "City": "Virgin Gorda",
        "Country": "British Virgin Islands"
    },
    {
        "id": "7957",
        "AirportCode": "VIL",
        "AirportName": "Dakhla Airport",
        "City": "Dakhla",
        "Country": "Morocco"
    },
    {
        "id": "7958",
        "AirportCode": "VIN",
        "AirportName": "Vinnica Airport",
        "City": "Vinnica",
        "Country": "Ukraine"
    },
    {
        "id": "7959",
        "AirportCode": "VIQ",
        "AirportName": "Viqueque Airport",
        "City": "Viqueque",
        "Country": "Indonesia"
    },
    {
        "id": "7960",
        "AirportCode": "VIR",
        "AirportName": "Virginia Airport",
        "City": "Durban",
        "Country": "South Africa"
    },
    {
        "id": "7961",
        "AirportCode": "VIS",
        "AirportName": "Visalia Airport",
        "City": "Visalia",
        "Country": "USA California"
    },
    {
        "id": "7962",
        "AirportCode": "VIT",
        "AirportName": "Vitoria Airport",
        "City": "Vitoria",
        "Country": "Spain"
    },
    {
        "id": "7963",
        "AirportCode": "VIV",
        "AirportName": "Vivigani Airport",
        "City": "Vivigani",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7964",
        "AirportCode": "VIX",
        "AirportName": "Eurico Sales Airport",
        "City": "Vitoria",
        "Country": "Brazil"
    },
    {
        "id": "7965",
        "AirportCode": "VIY",
        "AirportName": "Villa Coublay Airport",
        "City": "Villa Coublay",
        "Country": "France"
    },
    {
        "id": "7966",
        "AirportCode": "VJB",
        "AirportName": "Xai Xai Airport",
        "City": "Xai Xai",
        "Country": "Mozambique"
    },
    {
        "id": "7967",
        "AirportCode": "VJI",
        "AirportName": "Virginia Highlands Airport",
        "City": "Abingdon",
        "Country": "USA Virginia"
    },
    {
        "id": "7968",
        "AirportCode": "VJQ",
        "AirportName": "Gurue Airport",
        "City": "Gurue",
        "Country": "Mozambique"
    },
    {
        "id": "7969",
        "AirportCode": "VKG",
        "AirportName": "Rach Gia Airport",
        "City": "Rach Gia",
        "Country": "Vietnam"
    },
    {
        "id": "7970",
        "AirportCode": "VKO",
        "AirportName": "Vnukovo Airport",
        "City": "Moscow",
        "Country": "Russia"
    },
    {
        "id": "7971",
        "AirportCode": "VKS",
        "AirportName": "Vicksburg Airport",
        "City": "Vicksburg",
        "Country": "USA Mississippi"
    },
    {
        "id": "7972",
        "AirportCode": "VKT",
        "AirportName": "Vorkuta Airport",
        "City": "Vorkuta",
        "Country": "Russia"
    },
    {
        "id": "7973",
        "AirportCode": "VLA",
        "AirportName": "Vandalia Airport",
        "City": "Vandalia",
        "Country": "USA Illinois"
    },
    {
        "id": "7974",
        "AirportCode": "VLC",
        "AirportName": "Valencia Airport",
        "City": "Valencia (VLC)",
        "Country": "Spain"
    },
    {
        "id": "7975",
        "AirportCode": "VLD",
        "AirportName": "Valdosta Regional Airport",
        "City": "Valdosta",
        "Country": "USA Georgia"
    },
    {
        "id": "7976",
        "AirportCode": "VLE",
        "AirportName": "J. T. Robidoux Airport",
        "City": "Valle",
        "Country": "USA Arizona"
    },
    {
        "id": "7977",
        "AirportCode": "VLG",
        "AirportName": "Villa Gesell Airport",
        "City": "Villa Gesell",
        "Country": "Argentina"
    },
    {
        "id": "7978",
        "AirportCode": "VLI",
        "AirportName": "Bauerfield Airport",
        "City": "Port Vila",
        "Country": "Vanuatu"
    },
    {
        "id": "7979",
        "AirportCode": "VLK",
        "AirportName": "Volgodonsk Airport",
        "City": "Volgodonsk",
        "Country": "Russia"
    },
    {
        "id": "7980",
        "AirportCode": "VLL",
        "AirportName": "Valladolid Airport",
        "City": "Valladolid",
        "Country": "Spain"
    },
    {
        "id": "7981",
        "AirportCode": "VLM",
        "AirportName": "Villamontes Airport",
        "City": "Villamontes",
        "Country": "Bolivia"
    },
    {
        "id": "7982",
        "AirportCode": "VLN",
        "AirportName": "Valencia Airport",
        "City": "Valencia (VLC)",
        "Country": "Venezuela"
    },
    {
        "id": "7983",
        "AirportCode": "VLO",
        "AirportName": "Stolport Airport",
        "City": "Vallejo",
        "Country": "USA California"
    },
    {
        "id": "7984",
        "AirportCode": "VLP",
        "AirportName": "Vila Rica Municipal Airport",
        "City": "Vila Rica",
        "Country": "Brazil"
    },
    {
        "id": "7985",
        "AirportCode": "VLR",
        "AirportName": "Vallenar Airport",
        "City": "Vallenar",
        "Country": "Chile"
    },
    {
        "id": "7986",
        "AirportCode": "VLS",
        "AirportName": "Valesdir Airport",
        "City": "Valesdir",
        "Country": "Vanuatu"
    },
    {
        "id": "7987",
        "AirportCode": "VLU",
        "AirportName": "Velikiye Luki Airport",
        "City": "Velikiye Luki",
        "Country": "Russia"
    },
    {
        "id": "7988",
        "AirportCode": "VLV",
        "AirportName": "Carvajal Airport",
        "City": "Valera",
        "Country": "Venezuela"
    },
    {
        "id": "7989",
        "AirportCode": "VME",
        "AirportName": "Villa Mercedes Airport",
        "City": "Villa Mercedes",
        "Country": "Argentina"
    },
    {
        "id": "7990",
        "AirportCode": "VMI",
        "AirportName": "Vallemi Airport",
        "City": "Vallemi",
        "Country": "Paraguay"
    },
    {
        "id": "7991",
        "AirportCode": "VMU",
        "AirportName": "Baimuru Airport",
        "City": "Baimuru",
        "Country": "Papua New Guinea"
    },
    {
        "id": "7992",
        "AirportCode": "VNA",
        "AirportName": "Saravane Airport",
        "City": "Saravane",
        "Country": "Laos"
    },
    {
        "id": "7993",
        "AirportCode": "VNC",
        "AirportName": "Venice Airport",
        "City": "Venice",
        "Country": "USA Florida"
    },
    {
        "id": "7994",
        "AirportCode": "VND",
        "AirportName": "Vangaindrano Airport",
        "City": "Vangaindrano",
        "Country": "Madagascar"
    },
    {
        "id": "7995",
        "AirportCode": "VNE",
        "AirportName": "Meucon Airport",
        "City": "Vannes",
        "Country": "France"
    },
    {
        "id": "7996",
        "AirportCode": "VNG",
        "AirportName": "Viengxay Airport",
        "City": "Viengxay",
        "Country": "Laos"
    },
    {
        "id": "7997",
        "AirportCode": "VNO",
        "AirportName": "Vilnius Airport",
        "City": "Vilnius",
        "Country": "Lithuania"
    },
    {
        "id": "7998",
        "AirportCode": "VNR",
        "AirportName": "Vanrook Airport",
        "City": "Vanrook",
        "Country": "Australia"
    },
    {
        "id": "7999",
        "AirportCode": "VNS",
        "AirportName": "Varanasi Airport",
        "City": "Varanasi",
        "Country": "India"
    },
    {
        "id": "8000",
        "AirportCode": "VNX",
        "AirportName": "Vilanculos Airport",
        "City": "Vilanculos",
        "Country": "Mozambique"
    },
    {
        "id": "8001",
        "AirportCode": "VNY",
        "AirportName": "Van Nuys Airport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "8002",
        "AirportCode": "VOG",
        "AirportName": "Volgograd Airport",
        "City": "Volgograd",
        "Country": "Russia"
    },
    {
        "id": "8003",
        "AirportCode": "VOH",
        "AirportName": "Vohemar Airport",
        "City": "Vohemar",
        "Country": "Madagascar"
    },
    {
        "id": "8004",
        "AirportCode": "VOI",
        "AirportName": "Voinjama Airport",
        "City": "Voinjama",
        "Country": "Liberia"
    },
    {
        "id": "8005",
        "AirportCode": "VOK",
        "AirportName": "Volk Field",
        "City": "Camp Douglas",
        "Country": "USA Wisconsin"
    },
    {
        "id": "8006",
        "AirportCode": "VOL",
        "AirportName": "Nea Anchialos Airport",
        "City": "Volos",
        "Country": "Greece"
    },
    {
        "id": "8007",
        "AirportCode": "VOT",
        "AirportName": "Votuporanga Airport",
        "City": "Votuporanga",
        "Country": "Brazil"
    },
    {
        "id": "8008",
        "AirportCode": "VOZ",
        "AirportName": "Voronezh Airport",
        "City": "Voronezh",
        "Country": "Russia"
    },
    {
        "id": "8009",
        "AirportCode": "VPE",
        "AirportName": "Ongiva Airport",
        "City": "Ongiva",
        "Country": "Angola"
    },
    {
        "id": "8010",
        "AirportCode": "VPN",
        "AirportName": "Vopnafjordur Airport",
        "City": "Vopnafjordur",
        "Country": "Iceland"
    },
    {
        "id": "8011",
        "AirportCode": "VPS",
        "AirportName": "Ft. Walton Beach Airport",
        "City": "Valparaiso",
        "Country": "USA Florida"
    },
    {
        "id": "8012",
        "AirportCode": "VPY",
        "AirportName": "Chimoio Airport",
        "City": "Chimoio",
        "Country": "Mozambique"
    },
    {
        "id": "8013",
        "AirportCode": "VPZ",
        "AirportName": "Porter County Airport",
        "City": "Valparaiso",
        "Country": "USA Indiana"
    },
    {
        "id": "8014",
        "AirportCode": "VQS",
        "AirportName": "Vieques Airport",
        "City": "Vieques",
        "Country": "Puerto Rico"
    },
    {
        "id": "8015",
        "AirportCode": "VRA",
        "AirportName": "Juan Gualberto Gomez Airport",
        "City": "Varadero",
        "Country": "Cuba"
    },
    {
        "id": "8016",
        "AirportCode": "VRB",
        "AirportName": "Vero Beach Municipal Airport",
        "City": "Vero Beach",
        "Country": "USA Florida"
    },
    {
        "id": "8017",
        "AirportCode": "VRC",
        "AirportName": "Virac Airport",
        "City": "Virac",
        "Country": "Philippines"
    },
    {
        "id": "8018",
        "AirportCode": "VRE",
        "AirportName": "Vredendal Airport",
        "City": "Vredendal",
        "Country": "South Africa"
    },
    {
        "id": "8019",
        "AirportCode": "VRK",
        "AirportName": "Varkaus Airport",
        "City": "Varkaus",
        "Country": "Finland"
    },
    {
        "id": "8020",
        "AirportCode": "VRL",
        "AirportName": "Vila Real Airport",
        "City": "Vila Real",
        "Country": "Portugal"
    },
    {
        "id": "8021",
        "AirportCode": "VRN",
        "AirportName": "Verona Airport",
        "City": "Verona",
        "Country": "Italy"
    },
    {
        "id": "8022",
        "AirportCode": "VRO",
        "AirportName": "Kawama Airport",
        "City": "Matanzas",
        "Country": "Cuba"
    },
    {
        "id": "8023",
        "AirportCode": "VRS",
        "AirportName": "Versailles Airport",
        "City": "Versailles",
        "Country": "USA Missouri"
    },
    {
        "id": "8024",
        "AirportCode": "VRU",
        "AirportName": "Vryburg Airport",
        "City": "Vryburg",
        "Country": "South Africa"
    },
    {
        "id": "8025",
        "AirportCode": "VRY",
        "AirportName": "Stolport Airport",
        "City": "Vaeroy",
        "Country": "Norway"
    },
    {
        "id": "8026",
        "AirportCode": "VSA",
        "AirportName": "Capitan Carlos Perez Airport",
        "City": "Villahermosa",
        "Country": "Mexico"
    },
    {
        "id": "8027",
        "AirportCode": "VSE",
        "AirportName": "Viseu Airport",
        "City": "Viseu",
        "Country": "Portugal"
    },
    {
        "id": "8028",
        "AirportCode": "VSF",
        "AirportName": "State Airport",
        "City": "Springfield",
        "Country": "USA Vermont"
    },
    {
        "id": "8029",
        "AirportCode": "VSG",
        "AirportName": "Lugansk Airport",
        "City": "Lugansk",
        "Country": "Ukraine"
    },
    {
        "id": "8030",
        "AirportCode": "VSO",
        "AirportName": "Phuoc-long Airport",
        "City": "Phuoc-long",
        "Country": "Vietnam"
    },
    {
        "id": "8031",
        "AirportCode": "VST",
        "AirportName": "Vasteras/Hasslo Airport",
        "City": "Stockholm",
        "Country": "Sweden"
    },
    {
        "id": "8032",
        "AirportCode": "VTA",
        "AirportName": "Victoria Airport",
        "City": "Victoria",
        "Country": "Honduras"
    },
    {
        "id": "8033",
        "AirportCode": "VTB",
        "AirportName": "Vitebsk Airport",
        "City": "Vitebsk",
        "Country": "Belarus"
    },
    {
        "id": "8034",
        "AirportCode": "VTE",
        "AirportName": "Wattay",
        "City": "Vientiane",
        "Country": "Laos"
    },
    {
        "id": "8035",
        "AirportCode": "VTF",
        "AirportName": "Vatulele Airport",
        "City": "Vatulele",
        "Country": "Fiji"
    },
    {
        "id": "8036",
        "AirportCode": "VTG",
        "AirportName": "Vung Tau Airport",
        "City": "Vung Tau",
        "Country": "Vietnam"
    },
    {
        "id": "8037",
        "AirportCode": "VTL",
        "AirportName": "Vittel Airport",
        "City": "Vittel",
        "Country": "France"
    },
    {
        "id": "8038",
        "AirportCode": "VTN",
        "AirportName": "Miller Field",
        "City": "Valentine",
        "Country": "USA Nebraska"
    },
    {
        "id": "8039",
        "AirportCode": "VTU",
        "AirportName": "Las Tunas Airport",
        "City": "Las Tunas",
        "Country": "Cuba"
    },
    {
        "id": "8040",
        "AirportCode": "VTZ",
        "AirportName": "Vishakhapatnam Airport",
        "City": "Vishakhapatnam",
        "Country": "India"
    },
    {
        "id": "8041",
        "AirportCode": "VUP",
        "AirportName": "Valledupar Airport",
        "City": "Valledupar",
        "Country": "Colombia"
    },
    {
        "id": "8042",
        "AirportCode": "VUS",
        "AirportName": "Velikij Ustyug Airport",
        "City": "Velikij Ustyug",
        "Country": "Russia"
    },
    {
        "id": "8043",
        "AirportCode": "VUU",
        "AirportName": "MVUU Camp",
        "City": "Blantyre",
        "Country": "Malawi"
    },
    {
        "id": "8044",
        "AirportCode": "VVB",
        "AirportName": "Mahanoro Airport",
        "City": "Mahanoro",
        "Country": "Madagascar"
    },
    {
        "id": "8045",
        "AirportCode": "VVC",
        "AirportName": "La Vanguardia Airport",
        "City": "Villavicencio",
        "Country": "Colombia"
    },
    {
        "id": "8046",
        "AirportCode": "VVI",
        "AirportName": "Viru Viru International Airport",
        "City": "Santa Cruz",
        "Country": "Bolivia"
    },
    {
        "id": "8047",
        "AirportCode": "VVK",
        "AirportName": "Vastervik Airport",
        "City": "Vastervik",
        "Country": "Sweden"
    },
    {
        "id": "8048",
        "AirportCode": "VVO",
        "AirportName": "Vladivostok Airport",
        "City": "Vladivostok",
        "Country": "Russia"
    },
    {
        "id": "8049",
        "AirportCode": "VVZ",
        "AirportName": "Illizi Airport",
        "City": "Illizi",
        "Country": "Algeria"
    },
    {
        "id": "8050",
        "AirportCode": "VXC",
        "AirportName": "Lichinga Airport",
        "City": "Lichinga",
        "Country": "Mozambique"
    },
    {
        "id": "8051",
        "AirportCode": "VXE",
        "AirportName": "San Pedro Airport",
        "City": "Sao Vicente",
        "Country": "Cape Verde"
    },
    {
        "id": "8052",
        "AirportCode": "VXO",
        "AirportName": "Vaxjo Airport",
        "City": "Vaxjo",
        "Country": "Sweden"
    },
    {
        "id": "8053",
        "AirportCode": "VYD",
        "AirportName": "Vryheid Airport",
        "City": "Vryheid",
        "Country": "South Africa"
    },
    {
        "id": "8054",
        "AirportCode": "VYS",
        "AirportName": "Illinois Valley Regional Airport",
        "City": "Peru",
        "Country": "USA Illinois"
    },
    {
        "id": "8055",
        "AirportCode": "WAA",
        "AirportName": "Wales Airport",
        "City": "Wales",
        "Country": "USA Alaska"
    },
    {
        "id": "8056",
        "AirportCode": "WAB",
        "AirportName": "Wabag Airport",
        "City": "Wabag",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8057",
        "AirportCode": "WAC",
        "AirportName": "Waca Airport",
        "City": "Waca",
        "Country": "Ethiopia"
    },
    {
        "id": "8058",
        "AirportCode": "WAD",
        "AirportName": "Andriamena Airport",
        "City": "Andriamena",
        "Country": "Madagascar"
    },
    {
        "id": "8059",
        "AirportCode": "WAE",
        "AirportName": "Wadi Ad Dawasir Airport",
        "City": "Wadi Ad Dawasir",
        "Country": "Saudi Arabia"
    },
    {
        "id": "8060",
        "AirportCode": "WAF",
        "AirportName": "Wana Airport",
        "City": "Wana",
        "Country": "Pakistan"
    },
    {
        "id": "8061",
        "AirportCode": "WAG",
        "AirportName": "Wanganui Airport",
        "City": "Wanganui",
        "Country": "New Zealand"
    },
    {
        "id": "8062",
        "AirportCode": "WAH",
        "AirportName": "Wahpeton Airport",
        "City": "Wahpeton",
        "Country": "USA North Dakota"
    },
    {
        "id": "8063",
        "AirportCode": "WAI",
        "AirportName": "Antsohihy Airport",
        "City": "Antsohihy",
        "Country": "Madagascar"
    },
    {
        "id": "8064",
        "AirportCode": "WAJ",
        "AirportName": "Wawoi Falls Airport",
        "City": "Wawoi Falls",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8065",
        "AirportCode": "WAK",
        "AirportName": "Ankazoabo Airport",
        "City": "Ankazoabo",
        "Country": "Madagascar"
    },
    {
        "id": "8066",
        "AirportCode": "WAL",
        "AirportName": "Wallops Flight Center",
        "City": "Chincoteague",
        "Country": "USA Virginia"
    },
    {
        "id": "8067",
        "AirportCode": "WAM",
        "AirportName": "Ambatondrazaka Airport",
        "City": "Ambatondrazaka",
        "Country": "Madagascar"
    },
    {
        "id": "8068",
        "AirportCode": "WAN",
        "AirportName": "Waverney Airport",
        "City": "Waverney",
        "Country": "Australia"
    },
    {
        "id": "8069",
        "AirportCode": "WAO",
        "AirportName": "Wabo Airport",
        "City": "Wabo",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8070",
        "AirportCode": "WAP",
        "AirportName": "Alto Palena Airport",
        "City": "Alto Palena",
        "Country": "Chile"
    },
    {
        "id": "8071",
        "AirportCode": "WAQ",
        "AirportName": "Antsalova Airport",
        "City": "Antsalova",
        "Country": "Madagascar"
    },
    {
        "id": "8072",
        "AirportCode": "WAR",
        "AirportName": "Waris Airport",
        "City": "Waris",
        "Country": "Indonesia"
    },
    {
        "id": "8073",
        "AirportCode": "WAT",
        "AirportName": "Waterford Airport",
        "City": "Waterford",
        "Country": "Ireland"
    },
    {
        "id": "8074",
        "AirportCode": "WAU",
        "AirportName": "Wauchope Airport",
        "City": "Wauchope",
        "Country": "Australia"
    },
    {
        "id": "8075",
        "AirportCode": "WAV",
        "AirportName": "Kalkgurung Airport",
        "City": "Wave Hill",
        "Country": "Australia"
    },
    {
        "id": "8076",
        "AirportCode": "WAW",
        "AirportName": "Frederic Chopin Airport",
        "City": "Warsaw",
        "Country": "Poland"
    },
    {
        "id": "8077",
        "AirportCode": "WAX",
        "AirportName": "Zwara Airport",
        "City": "Zwara",
        "Country": "Libya"
    },
    {
        "id": "8078",
        "AirportCode": "WAY",
        "AirportName": "Green County Airport",
        "City": "Waynesburg",
        "Country": "USA Philadelphia"
    },
    {
        "id": "8079",
        "AirportCode": "WAZ",
        "AirportName": "Warwick Airport",
        "City": "Warwick",
        "Country": "Australia"
    },
    {
        "id": "8080",
        "AirportCode": "WBA",
        "AirportName": "Wahai Airport",
        "City": "Wahai",
        "Country": "Indonesia"
    },
    {
        "id": "8081",
        "AirportCode": "WBB",
        "AirportName": "Stebbins Airport",
        "City": "Stebbins",
        "Country": "USA Alaska"
    },
    {
        "id": "8082",
        "AirportCode": "WBC",
        "AirportName": "Wapolu Airport",
        "City": "Wapolu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8083",
        "AirportCode": "WBD",
        "AirportName": "Befandriana Airport",
        "City": "Befandriana",
        "Country": "Madagascar"
    },
    {
        "id": "8084",
        "AirportCode": "WBE",
        "AirportName": "Bealanana Airport",
        "City": "Bealanana",
        "Country": "Madagascar"
    },
    {
        "id": "8085",
        "AirportCode": "WBG",
        "AirportName": "Schleswig Airport",
        "City": "Schleswig",
        "Country": "Germany"
    },
    {
        "id": "8086",
        "AirportCode": "WBI",
        "AirportName": "Broker Inn Airport",
        "City": "Boulder",
        "Country": "USA Colorado"
    },
    {
        "id": "8087",
        "AirportCode": "WBM",
        "AirportName": "Wapenamanda Airport",
        "City": "Wapenamanda",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8088",
        "AirportCode": "WBN",
        "AirportName": "Cummings Park Airport",
        "City": "Woburn",
        "Country": "USA Massachusetts"
    },
    {
        "id": "8089",
        "AirportCode": "WBO",
        "AirportName": "Beroroha Airport",
        "City": "Beroroha",
        "Country": "Madagascar"
    },
    {
        "id": "8090",
        "AirportCode": "WBQ",
        "AirportName": "Beaver Airport",
        "City": "Beaver",
        "Country": "USA Alaska"
    },
    {
        "id": "8091",
        "AirportCode": "WBR",
        "AirportName": "Big Rapids Airport",
        "City": "Big Rapids",
        "Country": "USA Michigan"
    },
    {
        "id": "8092",
        "AirportCode": "WBU",
        "AirportName": "Boulder Municipal Airport",
        "City": "Boulder",
        "Country": "USA Colorado"
    },
    {
        "id": "8093",
        "AirportCode": "WBW",
        "AirportName": "Wyoming Valle Airport",
        "City": "Wilkes-Barre",
        "Country": "USA Philadelphia"
    },
    {
        "id": "8094",
        "AirportCode": "WCA",
        "AirportName": "Gamboa Airport",
        "City": "Castro",
        "Country": "Chile"
    },
    {
        "id": "8095",
        "AirportCode": "WCH",
        "AirportName": "Chaiten Airport",
        "City": "Chaiten",
        "Country": "Chile"
    },
    {
        "id": "8096",
        "AirportCode": "WDA",
        "AirportName": "Wadi Ain Airport",
        "City": "Wadi Ain",
        "Country": "Yemen"
    },
    {
        "id": "8097",
        "AirportCode": "WDB",
        "AirportName": "Deep Bay Airport",
        "City": "Deep Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "8098",
        "AirportCode": "WDG",
        "AirportName": "Enid Woodring Municipal Airport",
        "City": "Enid",
        "Country": "USA Oklahoma"
    },
    {
        "id": "8099",
        "AirportCode": "WDH",
        "AirportName": "Hosea Kutako International Airport",
        "City": "Windhoek",
        "Country": "Namibia"
    },
    {
        "id": "8100",
        "AirportCode": "WDI",
        "AirportName": "Wondai Airport",
        "City": "Wondai",
        "Country": "Australia"
    },
    {
        "id": "8101",
        "AirportCode": "WDN",
        "AirportName": "Waldron Island Airport",
        "City": "Waldron Island",
        "Country": "USA Washington"
    },
    {
        "id": "8102",
        "AirportCode": "WDR",
        "AirportName": "Winder Airport",
        "City": "Winder",
        "Country": "USA Georgia"
    },
    {
        "id": "8103",
        "AirportCode": "WEA",
        "AirportName": "Parker County Airport",
        "City": "Weatherford",
        "Country": "USA Texas"
    },
    {
        "id": "8104",
        "AirportCode": "WED",
        "AirportName": "Wedau Airport",
        "City": "Wedau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8105",
        "AirportCode": "WEF",
        "AirportName": "Weifang Airport",
        "City": "Weifang",
        "Country": "China"
    },
    {
        "id": "8106",
        "AirportCode": "WEH",
        "AirportName": "Weihai Airport",
        "City": "Weihai",
        "Country": "China"
    },
    {
        "id": "8107",
        "AirportCode": "WEI",
        "AirportName": "Weipa Airport",
        "City": "Weipa",
        "Country": "Australia"
    },
    {
        "id": "8108",
        "AirportCode": "WEL",
        "AirportName": "Welkom Airport",
        "City": "Welkom",
        "Country": "South Africa"
    },
    {
        "id": "8109",
        "AirportCode": "WEM",
        "AirportName": "West Malling Airport",
        "City": "West Malling",
        "Country": "United Kingdom"
    },
    {
        "id": "8110",
        "AirportCode": "WEP",
        "AirportName": "Weam Airport",
        "City": "Weam",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8111",
        "AirportCode": "WES",
        "AirportName": "Weasua Airport",
        "City": "Weasua",
        "Country": "Liberia"
    },
    {
        "id": "8112",
        "AirportCode": "WET",
        "AirportName": "Wagethe Airport",
        "City": "Wagethe",
        "Country": "Indonesia"
    },
    {
        "id": "8113",
        "AirportCode": "WEW",
        "AirportName": "Wee Waa Airport",
        "City": "Wee Waa",
        "Country": "Australia"
    },
    {
        "id": "8114",
        "AirportCode": "WEX",
        "AirportName": "Castlebridge Airport",
        "City": "Wexford",
        "Country": "Ireland"
    },
    {
        "id": "8115",
        "AirportCode": "WFD",
        "AirportName": "Woodford Airport",
        "City": "Woodford",
        "Country": "United Kingdom"
    },
    {
        "id": "8116",
        "AirportCode": "WFI",
        "AirportName": "Fianarantsoa Airport",
        "City": "Fianarantsoa",
        "Country": "Madagascar"
    },
    {
        "id": "8117",
        "AirportCode": "WFK",
        "AirportName": "Frenchville Airport",
        "City": "Frenchville",
        "Country": "USA Maine"
    },
    {
        "id": "8118",
        "AirportCode": "WGA",
        "AirportName": "Forrest Hill Airport",
        "City": "Wagga Wagga",
        "Country": "Australia"
    },
    {
        "id": "8119",
        "AirportCode": "WGB",
        "AirportName": "Bahawalnagar Airport",
        "City": "Bahawalnagar",
        "Country": "Pakistan"
    },
    {
        "id": "8120",
        "AirportCode": "WGC",
        "AirportName": "Warangal Airport",
        "City": "Warangal",
        "Country": "India"
    },
    {
        "id": "8121",
        "AirportCode": "WGE",
        "AirportName": "Walgett Airport",
        "City": "Walgett",
        "Country": "Australia"
    },
    {
        "id": "8122",
        "AirportCode": "WGL",
        "AirportName": "Off-Line Point",
        "City": "Isle Balta",
        "Country": "Ecuador"
    },
    {
        "id": "8123",
        "AirportCode": "WGN",
        "AirportName": "Waitangi Airport",
        "City": "Waitangi",
        "Country": "New Zealand"
    },
    {
        "id": "8124",
        "AirportCode": "WGO",
        "AirportName": "Winchester Municipal Airport",
        "City": "Winchester",
        "Country": "USA Virginia"
    },
    {
        "id": "8125",
        "AirportCode": "WGP",
        "AirportName": "Waingapu Airport",
        "City": "Waingapu",
        "Country": "Indonesia"
    },
    {
        "id": "8126",
        "AirportCode": "WGT",
        "AirportName": "Wangaratta Airport",
        "City": "Wangaratta",
        "Country": "Australia"
    },
    {
        "id": "8127",
        "AirportCode": "WGU",
        "AirportName": "Wagau Airport",
        "City": "Wagau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8128",
        "AirportCode": "WGY",
        "AirportName": "Wagny Airport",
        "City": "Wagny",
        "Country": "Gabon"
    },
    {
        "id": "8129",
        "AirportCode": "WHD",
        "AirportName": "Hyder Sea Plane Base",
        "City": "Hyder",
        "Country": "USA Alaska"
    },
    {
        "id": "8130",
        "AirportCode": "WHF",
        "AirportName": "Wadi Halfa Airport",
        "City": "Wadi Halfa",
        "Country": "Sudan"
    },
    {
        "id": "8131",
        "AirportCode": "WHH",
        "AirportName": "Hiltons Har H Airport",
        "City": "Boulder",
        "Country": "USA Colorado"
    },
    {
        "id": "8132",
        "AirportCode": "WHK",
        "AirportName": "Whakatane Airport",
        "City": "Whakatane",
        "Country": "New Zealand"
    },
    {
        "id": "8133",
        "AirportCode": "WHL",
        "AirportName": "Welshpool Airport",
        "City": "Welshpool",
        "Country": "Australia"
    },
    {
        "id": "8134",
        "AirportCode": "WHO",
        "AirportName": "Franz Josef Airport",
        "City": "Franz Josef",
        "Country": "New Zealand"
    },
    {
        "id": "8135",
        "AirportCode": "WHP",
        "AirportName": "Whiteman Airport",
        "City": "Los Angeles",
        "Country": "USA California"
    },
    {
        "id": "8136",
        "AirportCode": "WHS",
        "AirportName": "Whalsay Airport",
        "City": "Whalsay",
        "Country": "United Kingdom"
    },
    {
        "id": "8137",
        "AirportCode": "WHT",
        "AirportName": "Wharton Airport",
        "City": "Wharton",
        "Country": "USA Texas"
    },
    {
        "id": "8138",
        "AirportCode": "WHU",
        "AirportName": "Wuhu Airport",
        "City": "Wuhu",
        "Country": "China"
    },
    {
        "id": "8139",
        "AirportCode": "WIC",
        "AirportName": "Wick Airport",
        "City": "Wick",
        "Country": "United Kingdom"
    },
    {
        "id": "8140",
        "AirportCode": "WID",
        "AirportName": "Wildenrath Airport",
        "City": "Wildenrath",
        "Country": "Germany"
    },
    {
        "id": "8141",
        "AirportCode": "WIE",
        "AirportName": "Wiesbaden Air Base",
        "City": "Wiesbaden",
        "Country": "Germany"
    },
    {
        "id": "8142",
        "AirportCode": "WIK",
        "AirportName": "Surfdale Airport",
        "City": "Surfdale",
        "Country": "New Zealand"
    },
    {
        "id": "8143",
        "AirportCode": "WIL",
        "AirportName": "Wilson Airport",
        "City": "Nairobi (NBO)",
        "Country": "Kenya"
    },
    {
        "id": "8144",
        "AirportCode": "WIN",
        "AirportName": "Winton Airport",
        "City": "Winton",
        "Country": "Australia"
    },
    {
        "id": "8145",
        "AirportCode": "WIO",
        "AirportName": "Wilcannia Airport",
        "City": "Wilcannia",
        "Country": "Australia"
    },
    {
        "id": "8146",
        "AirportCode": "WIR",
        "AirportName": "Wairoa Airport",
        "City": "Wairoa",
        "Country": "New Zealand"
    },
    {
        "id": "8147",
        "AirportCode": "WIT",
        "AirportName": "Wittenoom Airport",
        "City": "Wittenoom",
        "Country": "Australia"
    },
    {
        "id": "8148",
        "AirportCode": "WIU",
        "AirportName": "Witu Airport",
        "City": "Witu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8149",
        "AirportCode": "WJA",
        "AirportName": "Woja Airport",
        "City": "Woja",
        "Country": "Marshall Islands"
    },
    {
        "id": "8150",
        "AirportCode": "WJF",
        "AirportName": "William J Fox Airport",
        "City": "Lancaster",
        "Country": "USA California"
    },
    {
        "id": "8151",
        "AirportCode": "WJR",
        "AirportName": "Wajir Airport",
        "City": "Wajir",
        "Country": "Kenya"
    },
    {
        "id": "8152",
        "AirportCode": "WJU",
        "AirportName": "Wonju Airport",
        "City": "Wonju",
        "Country": "Korea"
    },
    {
        "id": "8153",
        "AirportCode": "WKA",
        "AirportName": "Wanaka Airport",
        "City": "Wanaka",
        "Country": "New Zealand"
    },
    {
        "id": "8154",
        "AirportCode": "WKB",
        "AirportName": "Warracknabeal Airport",
        "City": "Warracknabeal",
        "Country": "Australia"
    },
    {
        "id": "8155",
        "AirportCode": "WKF",
        "AirportName": "Waterkloof (FAWK)",
        "City": "Waterkloof",
        "Country": "South Africa"
    },
    {
        "id": "8156",
        "AirportCode": "WKI",
        "AirportName": "Hwange Airport",
        "City": "Hwange",
        "Country": "Zimbabwe"
    },
    {
        "id": "8157",
        "AirportCode": "WKJ",
        "AirportName": "Hokkaido Airport",
        "City": "Wakkanai",
        "Country": "Japan"
    },
    {
        "id": "8158",
        "AirportCode": "WKK",
        "AirportName": "Aleknagik Airport",
        "City": "Aleknagik",
        "Country": "USA Alaska"
    },
    {
        "id": "8159",
        "AirportCode": "WKL",
        "AirportName": "Waikoloa Airport",
        "City": "Waikoloa",
        "Country": "USA Hawaii"
    },
    {
        "id": "8160",
        "AirportCode": "WKN",
        "AirportName": "Wakunai Airport",
        "City": "Wakunai",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8161",
        "AirportCode": "WLA",
        "AirportName": "Wallal Downs Airport",
        "City": "Wallal Downs",
        "Country": "Australia"
    },
    {
        "id": "8162",
        "AirportCode": "WLC",
        "AirportName": "Walcha Airport",
        "City": "Walcha",
        "Country": "Australia"
    },
    {
        "id": "8163",
        "AirportCode": "WLD",
        "AirportName": "Arkansas City Airport",
        "City": "Winfield",
        "Country": "USA Kansas"
    },
    {
        "id": "8164",
        "AirportCode": "WLG",
        "AirportName": "Wellington International Airport",
        "City": "Wellington",
        "Country": "New Zealand"
    },
    {
        "id": "8165",
        "AirportCode": "WLH",
        "AirportName": "Walaha Airport",
        "City": "Walaha",
        "Country": "Vanuatu"
    },
    {
        "id": "8166",
        "AirportCode": "WLK",
        "AirportName": "Selawik Airport",
        "City": "Selawik",
        "Country": "USA Alaska"
    },
    {
        "id": "8167",
        "AirportCode": "WLL",
        "AirportName": "Wollogorand Airport",
        "City": "Wollogorand",
        "Country": "Australia"
    },
    {
        "id": "8168",
        "AirportCode": "WLM",
        "AirportName": "Waltham Airport",
        "City": "Waltham",
        "Country": "USA Massachusetts"
    },
    {
        "id": "8169",
        "AirportCode": "WLO",
        "AirportName": "Waterloo Airport",
        "City": "Waterloo",
        "Country": "Australia"
    },
    {
        "id": "8170",
        "AirportCode": "WLS",
        "AirportName": "Wallis Island Airport",
        "City": "Wallis Island",
        "Country": "Wallis Futuna Islands"
    },
    {
        "id": "8171",
        "AirportCode": "WLW",
        "AirportName": "Glenn County Airport",
        "City": "Willows",
        "Country": "USA California"
    },
    {
        "id": "8172",
        "AirportCode": "WMA",
        "AirportName": "Mandritsara Airport",
        "City": "Mandritsara",
        "Country": "Madagascar"
    },
    {
        "id": "8173",
        "AirportCode": "WMB",
        "AirportName": "Warrnambool Airport",
        "City": "Warrnambool",
        "Country": "Australia"
    },
    {
        "id": "8174",
        "AirportCode": "WMC",
        "AirportName": "Winnemucca Airport",
        "City": "Winnemucca",
        "Country": "USA Nevada"
    },
    {
        "id": "8175",
        "AirportCode": "WMD",
        "AirportName": "Mandabe Airport",
        "City": "Mandabe",
        "Country": "Madagascar"
    },
    {
        "id": "8176",
        "AirportCode": "WME",
        "AirportName": "Mount Keith Airport",
        "City": "Mount Keith",
        "Country": "Australia"
    },
    {
        "id": "8177",
        "AirportCode": "WMH",
        "AirportName": "Mountain Home Airport",
        "City": "Mountain Home",
        "Country": "USA Arkansas"
    },
    {
        "id": "8178",
        "AirportCode": "WMK",
        "AirportName": "Meyers Chuck Sea Plane Base",
        "City": "Meyers Chuck",
        "Country": "USA Alaska"
    },
    {
        "id": "8179",
        "AirportCode": "WML",
        "AirportName": "Malaimbandy Airport",
        "City": "Malaimbandy",
        "Country": "Madagascar"
    },
    {
        "id": "8180",
        "AirportCode": "WMN",
        "AirportName": "Maroantsetra Airport",
        "City": "Maroantsetra",
        "Country": "Madagascar"
    },
    {
        "id": "8181",
        "AirportCode": "WMO",
        "AirportName": "White Mountain Airport",
        "City": "White Mountain",
        "Country": "USA Alaska"
    },
    {
        "id": "8182",
        "AirportCode": "WMP",
        "AirportName": "Mampikony Airport",
        "City": "Mampikony",
        "Country": "Madagascar"
    },
    {
        "id": "8183",
        "AirportCode": "WMR",
        "AirportName": "Mananara Airport",
        "City": "Mananara",
        "Country": "Madagascar"
    },
    {
        "id": "8184",
        "AirportCode": "WMV",
        "AirportName": "Madirovalo Airport",
        "City": "Madirovalo",
        "Country": "Madagascar"
    },
    {
        "id": "8185",
        "AirportCode": "WMX",
        "AirportName": "Wamena Airport",
        "City": "Wamena",
        "Country": "Indonesia"
    },
    {
        "id": "8186",
        "AirportCode": "WNA",
        "AirportName": "Napakiak Sea Plane Base",
        "City": "Napakiak",
        "Country": "USA Alaska"
    },
    {
        "id": "8187",
        "AirportCode": "WND",
        "AirportName": "Windarra Airport",
        "City": "Windarra",
        "Country": "Australia"
    },
    {
        "id": "8188",
        "AirportCode": "WNE",
        "AirportName": "Wora Na Ye Airport",
        "City": "Wora Na Ye",
        "Country": "Ghana"
    },
    {
        "id": "8189",
        "AirportCode": "WNN",
        "AirportName": "Wunnummin Lake Airport",
        "City": "Wunnummin Lake",
        "Country": "Canada"
    },
    {
        "id": "8190",
        "AirportCode": "WNP",
        "AirportName": "Naga Airport",
        "City": "Naga",
        "Country": "Philippines"
    },
    {
        "id": "8191",
        "AirportCode": "WNR",
        "AirportName": "Windorah Airport",
        "City": "Windorah",
        "Country": "Australia"
    },
    {
        "id": "8192",
        "AirportCode": "WNS",
        "AirportName": "Nawabshah Airport",
        "City": "Nawabshah",
        "Country": "Pakistan"
    },
    {
        "id": "8193",
        "AirportCode": "WNU",
        "AirportName": "Wanuma Airport",
        "City": "Wanuma",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8194",
        "AirportCode": "WNZ",
        "AirportName": "Wenzhou Airport",
        "City": "Wenzhou",
        "Country": "China"
    },
    {
        "id": "8195",
        "AirportCode": "WOA",
        "AirportName": "Wonenara Airport",
        "City": "Wonenara",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8196",
        "AirportCode": "WOB",
        "AirportName": "Woodbridge RAF",
        "City": "Suttonheath",
        "Country": "United Kingdom"
    },
    {
        "id": "8197",
        "AirportCode": "WOE",
        "AirportName": "Woensdrecht Ab Airport",
        "City": "Woensdrecht Ab",
        "Country": "Netherlands"
    },
    {
        "id": "8198",
        "AirportCode": "WOG",
        "AirportName": "Woodgreen Airport",
        "City": "Woodgreen",
        "Country": "Australia"
    },
    {
        "id": "8199",
        "AirportCode": "WOI",
        "AirportName": "Wologissi Airport",
        "City": "Wologissi",
        "Country": "Liberia"
    },
    {
        "id": "8200",
        "AirportCode": "WOK",
        "AirportName": "Wonken Airport",
        "City": "Wonken",
        "Country": "Venezuela"
    },
    {
        "id": "8201",
        "AirportCode": "WOL",
        "AirportName": "Wollongong Airport",
        "City": "Wollongong",
        "Country": "Australia"
    },
    {
        "id": "8202",
        "AirportCode": "WON",
        "AirportName": "Wondoola Airport",
        "City": "Wondoola",
        "Country": "Australia"
    },
    {
        "id": "8203",
        "AirportCode": "WOR",
        "AirportName": "Moramba Airport",
        "City": "Ankorefo",
        "Country": "Madagascar"
    },
    {
        "id": "8204",
        "AirportCode": "WOT",
        "AirportName": "Wonan Airport",
        "City": "Wonan",
        "Country": "Taiwan"
    },
    {
        "id": "8205",
        "AirportCode": "WOW",
        "AirportName": "Willow Airport",
        "City": "Willow",
        "Country": "USA Alaska"
    },
    {
        "id": "8206",
        "AirportCode": "WPA",
        "AirportName": "Puerto Aisen Airport",
        "City": "Puerto Aisen",
        "Country": "Chile"
    },
    {
        "id": "8207",
        "AirportCode": "WPB",
        "AirportName": "Port Berge Airport",
        "City": "Port Berge",
        "Country": "Madagascar"
    },
    {
        "id": "8208",
        "AirportCode": "WPC",
        "AirportName": "Pincher Creek Airport",
        "City": "Pincher Creek",
        "Country": "Canada"
    },
    {
        "id": "8209",
        "AirportCode": "WPK",
        "AirportName": "Wrotham Park Airport",
        "City": "Wrotham Park",
        "Country": "Australia"
    },
    {
        "id": "8210",
        "AirportCode": "WPL",
        "AirportName": "Powell Lake Airport",
        "City": "Powell Lake",
        "Country": "Canada"
    },
    {
        "id": "8211",
        "AirportCode": "WPM",
        "AirportName": "Wipim Airport",
        "City": "Wipim",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8212",
        "AirportCode": "WPO",
        "AirportName": "North Fork Valley Airport",
        "City": "Paonia",
        "Country": "USA Colorado"
    },
    {
        "id": "8213",
        "AirportCode": "WPR",
        "AirportName": "Porvenir Airport",
        "City": "Porvenir",
        "Country": "Chile"
    },
    {
        "id": "8214",
        "AirportCode": "WPU",
        "AirportName": "Puerto Williams Airport",
        "City": "Puerto Williams",
        "Country": "Chile"
    },
    {
        "id": "8215",
        "AirportCode": "WRA",
        "AirportName": "Warder Airport",
        "City": "Warder",
        "Country": "Ethiopia"
    },
    {
        "id": "8216",
        "AirportCode": "WRB",
        "AirportName": "Robins Air Force Base",
        "City": "Macon",
        "Country": "USA Georgia"
    },
    {
        "id": "8217",
        "AirportCode": "WRE",
        "AirportName": "Whangarei Airport",
        "City": "Whangarei",
        "Country": "New Zealand"
    },
    {
        "id": "8218",
        "AirportCode": "WRG",
        "AirportName": "Wrangell Sea Plane Base",
        "City": "Wrangell",
        "Country": "USA Alaska"
    },
    {
        "id": "8219",
        "AirportCode": "WRI",
        "AirportName": "McGuire Air Force Base",
        "City": "Fort Dix",
        "Country": "USA New Jersey"
    },
    {
        "id": "8220",
        "AirportCode": "WRL",
        "AirportName": "Worland Airport",
        "City": "Worland",
        "Country": "USA Wyoming"
    },
    {
        "id": "8221",
        "AirportCode": "WRO",
        "AirportName": "Strachowice Airport",
        "City": "Wroclaw",
        "Country": "Poland"
    },
    {
        "id": "8222",
        "AirportCode": "WRW",
        "AirportName": "Warrawagine Airport",
        "City": "Warrawagine",
        "Country": "Australia"
    },
    {
        "id": "8223",
        "AirportCode": "WRY",
        "AirportName": "Westray Airport",
        "City": "Westray",
        "Country": "United Kingdom"
    },
    {
        "id": "8224",
        "AirportCode": "WRZ",
        "AirportName": "Weerawila Airport",
        "City": "Weerawila",
        "Country": "Sri Lanka"
    },
    {
        "id": "8225",
        "AirportCode": "WSA",
        "AirportName": "Wasua Airport",
        "City": "Wasua",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8226",
        "AirportCode": "WSD",
        "AirportName": "Condron Army Air Field",
        "City": "White Sands",
        "Country": "USA New Mexico"
    },
    {
        "id": "8227",
        "AirportCode": "WSG",
        "AirportName": "County Airport",
        "City": "Washington",
        "Country": "USA Philadelphia"
    },
    {
        "id": "8228",
        "AirportCode": "WSH",
        "AirportName": "Brookhaven Airport",
        "City": "Shirley",
        "Country": "USA New York"
    },
    {
        "id": "8229",
        "AirportCode": "WSN",
        "AirportName": "South Naknek Airport",
        "City": "South Naknek",
        "Country": "USA Alaska"
    },
    {
        "id": "8230",
        "AirportCode": "WSO",
        "AirportName": "Washabo Airport",
        "City": "Washabo",
        "Country": "Suriname"
    },
    {
        "id": "8231",
        "AirportCode": "WSP",
        "AirportName": "Waspam Airport",
        "City": "Waspam",
        "Country": "Nicaragua"
    },
    {
        "id": "8232",
        "AirportCode": "WSR",
        "AirportName": "Wasior Airport",
        "City": "Wasior",
        "Country": "Indonesia"
    },
    {
        "id": "8233",
        "AirportCode": "WST",
        "AirportName": "Westerly State Airport",
        "City": "Westerly",
        "Country": "USA Rhode Island"
    },
    {
        "id": "8234",
        "AirportCode": "WSU",
        "AirportName": "Wasu Airport",
        "City": "Wasu",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8235",
        "AirportCode": "WSX",
        "AirportName": "Westsound Airport",
        "City": "Westsound",
        "Country": "USA Washington"
    },
    {
        "id": "8236",
        "AirportCode": "WSY",
        "AirportName": "Whitsunday Airstrip",
        "City": "Airlie Beach",
        "Country": "Australia"
    },
    {
        "id": "8237",
        "AirportCode": "WSZ",
        "AirportName": "Westport Airport",
        "City": "Westport",
        "Country": "New Zealand"
    },
    {
        "id": "8238",
        "AirportCode": "WTA",
        "AirportName": "Tambohorano Airport",
        "City": "Tambohorano",
        "Country": "Madagascar"
    },
    {
        "id": "8239",
        "AirportCode": "WTC",
        "AirportName": "World Trade Center Airport",
        "City": "New York",
        "Country": "USA New York"
    },
    {
        "id": "8240",
        "AirportCode": "WTE",
        "AirportName": "Wotje Airport",
        "City": "Wotje",
        "Country": "Marshall Islands"
    },
    {
        "id": "8241",
        "AirportCode": "WTK",
        "AirportName": "Noatak Airport",
        "City": "Noatak",
        "Country": "USA Alaska"
    },
    {
        "id": "8242",
        "AirportCode": "WTL",
        "AirportName": "Tuntutuliak Airport",
        "City": "Tuntutuliak",
        "Country": "USA Alaska"
    },
    {
        "id": "8243",
        "AirportCode": "WTN",
        "AirportName": "Royal Air Force Station",
        "City": "Waddington",
        "Country": "United Kingdom"
    },
    {
        "id": "8244",
        "AirportCode": "WTO",
        "AirportName": "Wotho Airport",
        "City": "Wotho",
        "Country": "Marshall Islands"
    },
    {
        "id": "8245",
        "AirportCode": "WTP",
        "AirportName": "Woitape Airport",
        "City": "Woitape",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8246",
        "AirportCode": "WTR",
        "AirportName": "White River Airport",
        "City": "White River",
        "Country": "USA Arizona"
    },
    {
        "id": "8247",
        "AirportCode": "WTS",
        "AirportName": "Tsiroanomandidy Airport",
        "City": "Tsiroanomandidy",
        "Country": "Madagascar"
    },
    {
        "id": "8248",
        "AirportCode": "WTT",
        "AirportName": "Wantoat Airport",
        "City": "Wantoat",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8249",
        "AirportCode": "WTZ",
        "AirportName": "Whitianga Airport",
        "City": "Whitianga",
        "Country": "New Zealand"
    },
    {
        "id": "8250",
        "AirportCode": "WUA",
        "AirportName": "Wu Hai Airport",
        "City": "Wu Hai",
        "Country": "China"
    },
    {
        "id": "8251",
        "AirportCode": "WUD",
        "AirportName": "Wudinna Airport",
        "City": "Wudinna",
        "Country": "Australia"
    },
    {
        "id": "8252",
        "AirportCode": "WUG",
        "AirportName": "Wau Airport",
        "City": "Wau",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8253",
        "AirportCode": "WUH",
        "AirportName": "Wuhan Airport",
        "City": "Wuhan",
        "Country": "China"
    },
    {
        "id": "8254",
        "AirportCode": "WUM",
        "AirportName": "West New Britain Airport",
        "City": "Wasum",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8255",
        "AirportCode": "WUN",
        "AirportName": "Wiluna Airport",
        "City": "Wiluna",
        "Country": "Australia"
    },
    {
        "id": "8256",
        "AirportCode": "WUS",
        "AirportName": "Fujian Province Airport",
        "City": "Wuyishan",
        "Country": "China"
    },
    {
        "id": "8257",
        "AirportCode": "WUU",
        "AirportName": "Wau Airport",
        "City": "Wau",
        "Country": "Sudan"
    },
    {
        "id": "8258",
        "AirportCode": "WUV",
        "AirportName": "Wuvulu Island Airport",
        "City": "Wuvulu Island",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8259",
        "AirportCode": "WUX",
        "AirportName": "Wuxi Airport",
        "City": "Wuxi",
        "Country": "China"
    },
    {
        "id": "8260",
        "AirportCode": "WUZ",
        "AirportName": "Changzhoudao Airport",
        "City": "Wuzhou",
        "Country": "China"
    },
    {
        "id": "8261",
        "AirportCode": "WVB",
        "AirportName": "Rooikop Airport",
        "City": "Walvis Bay",
        "Country": "Namibia"
    },
    {
        "id": "8262",
        "AirportCode": "WVI",
        "AirportName": "Watsonville Airport",
        "City": "Watsonville",
        "Country": "USA California"
    },
    {
        "id": "8263",
        "AirportCode": "WVK",
        "AirportName": "Manakara Airport",
        "City": "Manakara",
        "Country": "Madagascar"
    },
    {
        "id": "8264",
        "AirportCode": "WVL",
        "AirportName": "Robert Lafleur Airport",
        "City": "Waterville",
        "Country": "USA Maine"
    },
    {
        "id": "8265",
        "AirportCode": "WVN",
        "AirportName": "Wilhelmshaven Airport",
        "City": "Wilhelmshaven",
        "Country": "Germany"
    },
    {
        "id": "8266",
        "AirportCode": "WWA",
        "AirportName": "Wasilla Airport",
        "City": "Wasilla",
        "Country": "USA Alaska"
    },
    {
        "id": "8267",
        "AirportCode": "WWD",
        "AirportName": "Cape May County Airport",
        "City": "Wildwood",
        "Country": "USA New Jersey"
    },
    {
        "id": "8268",
        "AirportCode": "WWI",
        "AirportName": "Woodie Woodie Airport",
        "City": "Woodie Woodie",
        "Country": "Australia"
    },
    {
        "id": "8269",
        "AirportCode": "WWK",
        "AirportName": "Boram Airport",
        "City": "Wewak",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8270",
        "AirportCode": "WWP",
        "AirportName": "Whale Pass Airport",
        "City": "Whale Pass",
        "Country": "USA Alaska"
    },
    {
        "id": "8271",
        "AirportCode": "WWR",
        "AirportName": "West Woodward Airport",
        "City": "Woodward",
        "Country": "USA Oklahoma"
    },
    {
        "id": "8272",
        "AirportCode": "WWT",
        "AirportName": "Newtok Airport",
        "City": "Newtok",
        "Country": "USA Alaska"
    },
    {
        "id": "8273",
        "AirportCode": "WWY",
        "AirportName": "West Wyalong Airport",
        "City": "West Wyalong",
        "Country": "Australia"
    },
    {
        "id": "8274",
        "AirportCode": "WXF",
        "AirportName": "Wether Field Royal Air Force",
        "City": "Braintree",
        "Country": "United Kingdom"
    },
    {
        "id": "8275",
        "AirportCode": "WXN",
        "AirportName": "Wanxian Airport",
        "City": "Wanxian",
        "Country": "China"
    },
    {
        "id": "8276",
        "AirportCode": "WYA",
        "AirportName": "Whyalla Airport",
        "City": "Whyalla",
        "Country": "Australia"
    },
    {
        "id": "8277",
        "AirportCode": "WYB",
        "AirportName": "Yes Bay Sea Plane Base",
        "City": "Yes Bay",
        "Country": "USA Alaska"
    },
    {
        "id": "8278",
        "AirportCode": "WYE",
        "AirportName": "Yengema Airport",
        "City": "Yengema",
        "Country": "Sierra Leone"
    },
    {
        "id": "8279",
        "AirportCode": "WYN",
        "AirportName": "Wyndham Airport",
        "City": "Wyndham",
        "Country": "Australia"
    },
    {
        "id": "8280",
        "AirportCode": "WYS",
        "AirportName": "Yellowstone Airport",
        "City": "West Yellowstone",
        "Country": "USA Montana"
    },
    {
        "id": "8281",
        "AirportCode": "XAL",
        "AirportName": "Alamos Airport",
        "City": "Alamos",
        "Country": "Mexico"
    },
    {
        "id": "8282",
        "AirportCode": "XAP",
        "AirportName": "Chapeco Airport",
        "City": "Chapeco",
        "Country": "Brazil"
    },
    {
        "id": "8283",
        "AirportCode": "XAR",
        "AirportName": "Aribinda Airport",
        "City": "Aribinda",
        "Country": "Burkina Faso"
    },
    {
        "id": "8284",
        "AirportCode": "XAU",
        "AirportName": "Saul Airport",
        "City": "Saul",
        "Country": "French Guiana"
    },
    {
        "id": "8285",
        "AirportCode": "XAY",
        "AirportName": "Xayabury Airport",
        "City": "Xayabury",
        "Country": "Laos"
    },
    {
        "id": "8286",
        "AirportCode": "XBB",
        "AirportName": "Blubber Bay Airport",
        "City": "Blubber Bay",
        "Country": "Canada"
    },
    {
        "id": "8287",
        "AirportCode": "XBE",
        "AirportName": "Bearskin Lake Airport",
        "City": "Bearskin Lake",
        "Country": "Canada"
    },
    {
        "id": "8288",
        "AirportCode": "XBG",
        "AirportName": "Bogande Airport",
        "City": "Bogande",
        "Country": "Burkina Faso"
    },
    {
        "id": "8289",
        "AirportCode": "XBJ",
        "AirportName": "Birjand Airport",
        "City": "Birjand",
        "Country": "Iran"
    },
    {
        "id": "8290",
        "AirportCode": "XBL",
        "AirportName": "Buno Bedelle Airport",
        "City": "Buno Bedelle",
        "Country": "Ethiopia"
    },
    {
        "id": "8291",
        "AirportCode": "XBN",
        "AirportName": "Biniguni Airport",
        "City": "Biniguni",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8292",
        "AirportCode": "XBO",
        "AirportName": "Boulsa Airport",
        "City": "Boulsa",
        "Country": "Burkina Faso"
    },
    {
        "id": "8293",
        "AirportCode": "XBR",
        "AirportName": "Brockville Airport",
        "City": "Brockville",
        "Country": "Canada"
    },
    {
        "id": "8294",
        "AirportCode": "XCH",
        "AirportName": "Christmas Island Airport",
        "City": "Christmas Island",
        "Country": "Christmas Island"
    },
    {
        "id": "8295",
        "AirportCode": "XCL",
        "AirportName": "Cluff Lake Airport",
        "City": "Cluff Lake",
        "Country": "Canada"
    },
    {
        "id": "8296",
        "AirportCode": "XCM",
        "AirportName": "Chatham Airport",
        "City": "Chatham",
        "Country": "Canada"
    },
    {
        "id": "8297",
        "AirportCode": "XCN",
        "AirportName": "Coron Airport",
        "City": "Coron",
        "Country": "Philippines"
    },
    {
        "id": "8298",
        "AirportCode": "XCO",
        "AirportName": "Colac Airport",
        "City": "Colac",
        "Country": "Australia"
    },
    {
        "id": "8299",
        "AirportCode": "XCR",
        "AirportName": "Paris Vatry Airport",
        "City": "ChÃ¢lons en Champagne ",
        "Country": "France"
    },
    {
        "id": "8300",
        "AirportCode": "XDE",
        "AirportName": "Diebougou Airport",
        "City": "Diebougou",
        "Country": "Burkina Faso"
    },
    {
        "id": "8301",
        "AirportCode": "XDJ",
        "AirportName": "Djibo Airport",
        "City": "Djibo",
        "Country": "Burkina Faso"
    },
    {
        "id": "8302",
        "AirportCode": "XEN",
        "AirportName": "Xingcheng Airport",
        "City": "Xingcheng",
        "Country": "China"
    },
    {
        "id": "8303",
        "AirportCode": "XEO",
        "AirportName": "Oquatsut Harbour Airport",
        "City": "Oquatsut",
        "Country": "Greenland"
    },
    {
        "id": "8304",
        "AirportCode": "XEQ",
        "AirportName": "Tasiuasaq Harbour Airport",
        "City": "Tasiuasaq",
        "Country": "Greenland"
    },
    {
        "id": "8305",
        "AirportCode": "XES",
        "AirportName": "Lake Geneva Municipal Airport",
        "City": "Lake Geneva",
        "Country": "USA Wisconsin"
    },
    {
        "id": "8306",
        "AirportCode": "XEX",
        "AirportName": "Aerogare des Invalides",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "8307",
        "AirportCode": "XFN",
        "AirportName": "Xiangfan Airport",
        "City": "Xiangfan",
        "Country": "China"
    },
    {
        "id": "8308",
        "AirportCode": "XFW",
        "AirportName": "Finkenwerder Airport",
        "City": "Finkenwerder",
        "Country": "Germany"
    },
    {
        "id": "8309",
        "AirportCode": "XFZ",
        "AirportName": "Charny Airport",
        "City": "Quebec",
        "Country": "Canada"
    },
    {
        "id": "8310",
        "AirportCode": "XGA",
        "AirportName": "Gaoua Airport",
        "City": "Gaoua",
        "Country": "Burkina Faso"
    },
    {
        "id": "8311",
        "AirportCode": "XGB",
        "AirportName": "Gare Montparnasse Airport",
        "City": "Paris",
        "Country": "France"
    },
    {
        "id": "8312",
        "AirportCode": "XGG",
        "AirportName": "Gorum-Gorum Airport",
        "City": "Gorom-Gorom",
        "Country": "Burkina Faso"
    },
    {
        "id": "8313",
        "AirportCode": "XGL",
        "AirportName": "Granville Lake Airport",
        "City": "Granville Lake",
        "Country": "Canada"
    },
    {
        "id": "8314",
        "AirportCode": "XGR",
        "AirportName": "Kangiqsualujjuaq Airport",
        "City": "Kangiqsualujjuaq",
        "Country": "Canada"
    },
    {
        "id": "8315",
        "AirportCode": "XIC",
        "AirportName": "Xichang Airport",
        "City": "Xichang",
        "Country": "China"
    },
    {
        "id": "8316",
        "AirportCode": "XIE",
        "AirportName": "Xienglom Airport",
        "City": "Xienglom",
        "Country": "Laos"
    },
    {
        "id": "8317",
        "AirportCode": "XIG",
        "AirportName": "Xinguara Municipal Airport",
        "City": "Xinguara",
        "Country": "Brazil"
    },
    {
        "id": "8318",
        "AirportCode": "XIL",
        "AirportName": "Xilinhot Airport",
        "City": "Xilinhot",
        "Country": "China"
    },
    {
        "id": "8319",
        "AirportCode": "XIN",
        "AirportName": "Xingning Airport",
        "City": "Xingning",
        "Country": "China"
    },
    {
        "id": "8320",
        "AirportCode": "XIQ",
        "AirportName": "Ilimanaq Harbour Airport",
        "City": "Ilimanaq",
        "Country": "Greenland"
    },
    {
        "id": "8321",
        "AirportCode": "XIY",
        "AirportName": "Xianyang Airport",
        "City": "Xian",
        "Country": "China"
    },
    {
        "id": "8322",
        "AirportCode": "XKA",
        "AirportName": "Kantchari Airport",
        "City": "Kantchari",
        "Country": "Burkina Faso"
    },
    {
        "id": "8323",
        "AirportCode": "XKH",
        "AirportName": "Xieng Khouang Airport",
        "City": "Xieng Khouang",
        "Country": "Laos"
    },
    {
        "id": "8324",
        "AirportCode": "XKO",
        "AirportName": "Kemano Airport",
        "City": "Kemano",
        "Country": "Canada"
    },
    {
        "id": "8325",
        "AirportCode": "XKS",
        "AirportName": "Kasabonika Airport",
        "City": "Kasabonika",
        "Country": "Canada"
    },
    {
        "id": "8326",
        "AirportCode": "XKY",
        "AirportName": "Kaya Airport",
        "City": "Kaya",
        "Country": "Burkina Faso"
    },
    {
        "id": "8327",
        "AirportCode": "XLB",
        "AirportName": "Lac Brochet Airport",
        "City": "Lac Brochet",
        "Country": "Canada"
    },
    {
        "id": "8328",
        "AirportCode": "XLF",
        "AirportName": "Leaf Bay Airport",
        "City": "Leaf Bay",
        "Country": "Canada"
    },
    {
        "id": "8329",
        "AirportCode": "XLO",
        "AirportName": "Long Xuyen Airport",
        "City": "Long Xuyen",
        "Country": "Vietnam"
    },
    {
        "id": "8330",
        "AirportCode": "XLS",
        "AirportName": "St. Louis Airport",
        "City": "Saint Louis",
        "Country": "Senegal"
    },
    {
        "id": "8331",
        "AirportCode": "XLU",
        "AirportName": "Leo Airport",
        "City": "Leo",
        "Country": "Burkina Faso"
    },
    {
        "id": "8332",
        "AirportCode": "XLW",
        "AirportName": "Lemwerder Airport",
        "City": "Lemwerder",
        "Country": "Germany"
    },
    {
        "id": "8333",
        "AirportCode": "XMA",
        "AirportName": "Maramag Airport",
        "City": "Maramag",
        "Country": "Philippines"
    },
    {
        "id": "8334",
        "AirportCode": "XMC",
        "AirportName": "Mallacoota Airport",
        "City": "Mallacoota",
        "Country": "Australia"
    },
    {
        "id": "8335",
        "AirportCode": "XMD",
        "AirportName": "Madison Airport",
        "City": "Madison",
        "Country": "USA South Dakota"
    },
    {
        "id": "8336",
        "AirportCode": "XMG",
        "AirportName": "Mahendranagar Airport",
        "City": "Mahendranagar",
        "Country": "Nepal"
    },
    {
        "id": "8337",
        "AirportCode": "XMH",
        "AirportName": "Manihi Airport",
        "City": "Manihi",
        "Country": "French Polynesia"
    },
    {
        "id": "8338",
        "AirportCode": "XMI",
        "AirportName": "Masasi Airport",
        "City": "Masasi",
        "Country": "Tanzania"
    },
    {
        "id": "8339",
        "AirportCode": "XML",
        "AirportName": "Minlaton Airport",
        "City": "Minlaton",
        "Country": "Australia"
    },
    {
        "id": "8340",
        "AirportCode": "XMN",
        "AirportName": "Xiamen Airport",
        "City": "Xiamen",
        "Country": "China"
    },
    {
        "id": "8341",
        "AirportCode": "XMP",
        "AirportName": "Macmillan Pass Airport",
        "City": "Macmillan Pass",
        "Country": "Canada"
    },
    {
        "id": "8342",
        "AirportCode": "XMS",
        "AirportName": "Macas Airport",
        "City": "Macas",
        "Country": "Ecuador"
    },
    {
        "id": "8343",
        "AirportCode": "XMY",
        "AirportName": "Yam Island Airport",
        "City": "Yam Island",
        "Country": "Australia"
    },
    {
        "id": "8344",
        "AirportCode": "XNA",
        "AirportName": "Northwest Arkansas Regional Airport",
        "City": "Bentonville",
        "Country": "USA Arkansas"
    },
    {
        "id": "8345",
        "AirportCode": "XNG",
        "AirportName": "Quang Ngai Airport",
        "City": "Quang Ngai",
        "Country": "Vietnam"
    },
    {
        "id": "8346",
        "AirportCode": "XNN",
        "AirportName": "Xining Airport",
        "City": "Xining",
        "Country": "China"
    },
    {
        "id": "8347",
        "AirportCode": "XNT",
        "AirportName": "Xingtai Airport",
        "City": "Xingtai",
        "Country": "China"
    },
    {
        "id": "8348",
        "AirportCode": "XNU",
        "AirportName": "Nouna Airport",
        "City": "Nouna",
        "Country": "Burkina Faso"
    },
    {
        "id": "8349",
        "AirportCode": "XPA",
        "AirportName": "Pama Airport",
        "City": "Pama",
        "Country": "Burkina Faso"
    },
    {
        "id": "8350",
        "AirportCode": "XPD",
        "AirportName": "San Pedro",
        "City": "San Pedro",
        "Country": "Argentina"
    },
    {
        "id": "8351",
        "AirportCode": "XPK",
        "AirportName": "Pukatawagan Airport",
        "City": "Pukatawagan",
        "Country": "Canada"
    },
    {
        "id": "8352",
        "AirportCode": "XPL",
        "AirportName": "Palmerola Air Base",
        "City": "Comayagua",
        "Country": "Honduras"
    },
    {
        "id": "8353",
        "AirportCode": "XPP",
        "AirportName": "Poplar River Airport",
        "City": "Poplar River",
        "Country": "Canada"
    },
    {
        "id": "8354",
        "AirportCode": "XPR",
        "AirportName": "Pine Ridge Airport",
        "City": "Pine Ridge",
        "Country": "USA South Dakota"
    },
    {
        "id": "8355",
        "AirportCode": "XPZ",
        "AirportName": "Saint Tropez Harbour Ferry Service",
        "City": "Saint Tropez",
        "Country": "France"
    },
    {
        "id": "8356",
        "AirportCode": "XQP",
        "AirportName": "Quepos Airport",
        "City": "Quepos",
        "Country": "Costa Rica"
    },
    {
        "id": "8357",
        "AirportCode": "XQU",
        "AirportName": "Qualicum Airport",
        "City": "Qualicum",
        "Country": "Canada"
    },
    {
        "id": "8358",
        "AirportCode": "XRH",
        "AirportName": "Richmond Air Force Base",
        "City": "Richmond",
        "Country": "Australia"
    },
    {
        "id": "8359",
        "AirportCode": "XRR",
        "AirportName": "Ross River Airport",
        "City": "Ross River",
        "Country": "Canada"
    },
    {
        "id": "8360",
        "AirportCode": "XRY",
        "AirportName": "La Parra Airport",
        "City": "Jerez De La Frontera",
        "Country": "Spain"
    },
    {
        "id": "8361",
        "AirportCode": "XSA",
        "AirportName": "AFC Airport",
        "City": "Sinoe",
        "Country": "Liberia"
    },
    {
        "id": "8362",
        "AirportCode": "XSC",
        "AirportName": "South Caicos International Airport",
        "City": "South Caicos",
        "Country": "Turks & Caicos Islands"
    },
    {
        "id": "8363",
        "AirportCode": "XSD",
        "AirportName": "Test Range Airport",
        "City": "Tonopah",
        "Country": "USA Nevada"
    },
    {
        "id": "8364",
        "AirportCode": "XSE",
        "AirportName": "Sebba Airport",
        "City": "Sebba",
        "Country": "Burkina Faso"
    },
    {
        "id": "8365",
        "AirportCode": "XSI",
        "AirportName": "South Indian Lake Airport",
        "City": "South Indian Lake",
        "Country": "Canada"
    },
    {
        "id": "8366",
        "AirportCode": "XSM",
        "AirportName": "St Marys Airport",
        "City": "Saint Marys",
        "Country": "USA Maryland"
    },
    {
        "id": "8367",
        "AirportCode": "XSO",
        "AirportName": "Siocon Airport",
        "City": "Siocon",
        "Country": "Philippines"
    },
    {
        "id": "8368",
        "AirportCode": "XSP",
        "AirportName": "Seletar Airport",
        "City": "Singapore",
        "Country": "Singapore"
    },
    {
        "id": "8369",
        "AirportCode": "XTG",
        "AirportName": "Thargomindah Airport",
        "City": "Thargomindah",
        "Country": "Australia"
    },
    {
        "id": "8370",
        "AirportCode": "XTL",
        "AirportName": "Tadoule Lake Airport",
        "City": "Tadoule Lake",
        "Country": "Canada"
    },
    {
        "id": "8371",
        "AirportCode": "XTO",
        "AirportName": "Taroom Airport",
        "City": "Taroom",
        "Country": "Australia"
    },
    {
        "id": "8372",
        "AirportCode": "XTR",
        "AirportName": "Tara Airport",
        "City": "Tara",
        "Country": "Australia"
    },
    {
        "id": "8373",
        "AirportCode": "XUZ",
        "AirportName": "Xuzhou Airport",
        "City": "Xuzhou",
        "Country": "China"
    },
    {
        "id": "8374",
        "AirportCode": "XVL",
        "AirportName": "Vin Long Airport",
        "City": "Vinh Long",
        "Country": "Vietnam"
    },
    {
        "id": "8375",
        "AirportCode": "XYA",
        "AirportName": "Yandina Airport",
        "City": "Yandina",
        "Country": "Solomon Islands"
    },
    {
        "id": "8376",
        "AirportCode": "XYE",
        "AirportName": "Ye Airport",
        "City": "Ye",
        "Country": "Burma"
    },
    {
        "id": "8377",
        "AirportCode": "XYR",
        "AirportName": "Yellow River Airport",
        "City": "Yellow River",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8378",
        "AirportCode": "XYT",
        "AirportName": "Montaudran Airport",
        "City": "Toulouse",
        "Country": "France"
    },
    {
        "id": "8379",
        "AirportCode": "XZA",
        "AirportName": "Zabre Airport",
        "City": "Zabre",
        "Country": "Burkina Faso"
    },
    {
        "id": "8380",
        "AirportCode": "XZD",
        "AirportName": "Kongsvinger Airport",
        "City": "Kongsvinger",
        "Country": "Norway"
    },
    {
        "id": "8381",
        "AirportCode": "YAA",
        "AirportName": "Anahim Lake Airport",
        "City": "Anahim Lake",
        "Country": "Canada"
    },
    {
        "id": "8382",
        "AirportCode": "YAB",
        "AirportName": "Arctic Bay Airport",
        "City": "Arctic Bay",
        "Country": "Canada"
    },
    {
        "id": "8383",
        "AirportCode": "YAC",
        "AirportName": "Cat Lake Airport",
        "City": "Cat Lake",
        "Country": "Canada"
    },
    {
        "id": "8384",
        "AirportCode": "YAD",
        "AirportName": "Moose Lake Airport",
        "City": "Moose Lake",
        "Country": "Canada"
    },
    {
        "id": "8385",
        "AirportCode": "YAE",
        "AirportName": "Alta Lake Airport",
        "City": "Alta Lake",
        "Country": "Canada"
    },
    {
        "id": "8386",
        "AirportCode": "YAF",
        "AirportName": "Asbestos Hill Airport",
        "City": "Asbestos Hill",
        "Country": "Canada"
    },
    {
        "id": "8387",
        "AirportCode": "YAG",
        "AirportName": "Fort Frances Municipal Airport",
        "City": "Fort Frances",
        "Country": "Canada"
    },
    {
        "id": "8388",
        "AirportCode": "YAH",
        "AirportName": "Lagrande 4 Airport",
        "City": "Lagrande 4",
        "Country": "Canada"
    },
    {
        "id": "8389",
        "AirportCode": "YAI",
        "AirportName": "Chillan Airport",
        "City": "Chillan",
        "Country": "Chile"
    },
    {
        "id": "8390",
        "AirportCode": "YAJ",
        "AirportName": "Lyall Harbour Airport",
        "City": "Lyall Harbour",
        "Country": "Canada"
    },
    {
        "id": "8391",
        "AirportCode": "YAK",
        "AirportName": "Yakutat Airport",
        "City": "Yakutat",
        "Country": "USA Alaska"
    },
    {
        "id": "8392",
        "AirportCode": "YAL",
        "AirportName": "Alert Bay Airport",
        "City": "Alert Bay",
        "Country": "Canada"
    },
    {
        "id": "8393",
        "AirportCode": "YAM",
        "AirportName": "Sault Ste. Marie Airport",
        "City": "Sault Ste. Marie",
        "Country": "Canada"
    },
    {
        "id": "8394",
        "AirportCode": "YAN",
        "AirportName": "Yangambi Airport",
        "City": "Yangambi",
        "Country": "Congo, DR"
    },
    {
        "id": "8395",
        "AirportCode": "YAO",
        "AirportName": "Yaounde Airport",
        "City": "Yaounde",
        "Country": "Cameroon"
    },
    {
        "id": "8396",
        "AirportCode": "YAP",
        "AirportName": "Yap Airport",
        "City": "Yap, Caroline Islands",
        "Country": "Micronesia"
    },
    {
        "id": "8397",
        "AirportCode": "YAQ",
        "AirportName": "Maple Bay Airport",
        "City": "Maple Bay",
        "Country": "Canada"
    },
    {
        "id": "8398",
        "AirportCode": "YAR",
        "AirportName": "Lagrande 3 Airport",
        "City": "Lagrande 3",
        "Country": "Canada"
    },
    {
        "id": "8399",
        "AirportCode": "YAS",
        "AirportName": "Yasawa Island Airport",
        "City": "Yasawa Island",
        "Country": "Fiji"
    },
    {
        "id": "8400",
        "AirportCode": "YAT",
        "AirportName": "Attawapiskat Airport",
        "City": "Attawapiskat",
        "Country": "Canada"
    },
    {
        "id": "8401",
        "AirportCode": "YAU",
        "AirportName": "Kattiniq/Donaldson Lake Airport",
        "City": "Kattiniq/Donaldson Lake",
        "Country": "Canada"
    },
    {
        "id": "8402",
        "AirportCode": "YAW",
        "AirportName": "Shearwater Airport",
        "City": "Halifax",
        "Country": "Canada"
    },
    {
        "id": "8403",
        "AirportCode": "YAX",
        "AirportName": "Angling Lake Airport",
        "City": "Angling Lake",
        "Country": "Canada"
    },
    {
        "id": "8404",
        "AirportCode": "YAY",
        "AirportName": "St. Anthony Airport",
        "City": "Saint Anthony",
        "Country": "Canada"
    },
    {
        "id": "8405",
        "AirportCode": "YAZ",
        "AirportName": "Tofino Airport",
        "City": "Tofino",
        "Country": "Canada"
    },
    {
        "id": "8406",
        "AirportCode": "YBA",
        "AirportName": "Banff Airport",
        "City": "Banff",
        "Country": "Canada"
    },
    {
        "id": "8407",
        "AirportCode": "YBB",
        "AirportName": "Townsite Airport",
        "City": "Pelly Bay",
        "Country": "Canada"
    },
    {
        "id": "8408",
        "AirportCode": "YBC",
        "AirportName": "Baie Comeau Airport",
        "City": "Baie Comeau",
        "Country": "Canada"
    },
    {
        "id": "8409",
        "AirportCode": "YBD",
        "AirportName": "New Westminister Airport",
        "City": "New Westminster",
        "Country": "Canada"
    },
    {
        "id": "8410",
        "AirportCode": "YBE",
        "AirportName": "Uranium City Airport",
        "City": "Uranium City",
        "Country": "Canada"
    },
    {
        "id": "8411",
        "AirportCode": "YBF",
        "AirportName": "Bamfield Airport",
        "City": "Bamfield",
        "Country": "Canada"
    },
    {
        "id": "8412",
        "AirportCode": "YBG",
        "AirportName": "Bagotville Airport",
        "City": "Bagotville",
        "Country": "Canada"
    },
    {
        "id": "8413",
        "AirportCode": "YBH",
        "AirportName": "Bull Harbour Airport",
        "City": "Bull Harbour",
        "Country": "Canada"
    },
    {
        "id": "8414",
        "AirportCode": "YBI",
        "AirportName": "Black Tickle Airport",
        "City": "Black Tickle",
        "Country": "Canada"
    },
    {
        "id": "8415",
        "AirportCode": "YBJ",
        "AirportName": "Baie Johan Beetz Airport",
        "City": "Baie Johan Beetz",
        "Country": "Canada"
    },
    {
        "id": "8416",
        "AirportCode": "YBK",
        "AirportName": "Baker Lake Airport",
        "City": "Baker Lake",
        "Country": "Canada"
    },
    {
        "id": "8417",
        "AirportCode": "YBL",
        "AirportName": "Campbell River Airport",
        "City": "Campbell River",
        "Country": "Canada"
    },
    {
        "id": "8418",
        "AirportCode": "YBM",
        "AirportName": "Bronson Creek Airport",
        "City": "Bronson Creek",
        "Country": "Canada"
    },
    {
        "id": "8419",
        "AirportCode": "YBN",
        "AirportName": "Borden Airport",
        "City": "Borden",
        "Country": "Canada"
    },
    {
        "id": "8420",
        "AirportCode": "YBO",
        "AirportName": "Bobquinn Lake Airport",
        "City": "Bobquinn Lake",
        "Country": "Canada"
    },
    {
        "id": "8421",
        "AirportCode": "YBP",
        "AirportName": "Yibin Airport",
        "City": "Yibin",
        "Country": "China"
    },
    {
        "id": "8422",
        "AirportCode": "YBQ",
        "AirportName": "Telegraph Harbour Airport",
        "City": "Telegraph Harbour",
        "Country": "Canada"
    },
    {
        "id": "8423",
        "AirportCode": "YBR",
        "AirportName": "Brandon Airport",
        "City": "Brandon",
        "Country": "Canada"
    },
    {
        "id": "8424",
        "AirportCode": "YBS",
        "AirportName": "Musselwhite Airport",
        "City": "Opapamiska Lake",
        "Country": "Canada"
    },
    {
        "id": "8425",
        "AirportCode": "YBT",
        "AirportName": "Brochet Airport",
        "City": "Brochet",
        "Country": "Canada"
    },
    {
        "id": "8426",
        "AirportCode": "YBV",
        "AirportName": "Berens River Airport",
        "City": "Berens River",
        "Country": "Canada"
    },
    {
        "id": "8427",
        "AirportCode": "YBW",
        "AirportName": "Bedwell Harbor Airport",
        "City": "Bedwell Harbor",
        "Country": "Canada"
    },
    {
        "id": "8428",
        "AirportCode": "YBX",
        "AirportName": "Blanc Sablon Airport",
        "City": "Blanc Sablon",
        "Country": "Canada"
    },
    {
        "id": "8429",
        "AirportCode": "YBY",
        "AirportName": "Bonnyville Airport",
        "City": "Bonnyville",
        "Country": "Canada"
    },
    {
        "id": "8430",
        "AirportCode": "YCA",
        "AirportName": "Courtenay Airport",
        "City": "Courtenay",
        "Country": "Canada"
    },
    {
        "id": "8431",
        "AirportCode": "YCB",
        "AirportName": "Cambridge Bay Airport",
        "City": "Cambridge Bay",
        "Country": "Canada"
    },
    {
        "id": "8432",
        "AirportCode": "YCC",
        "AirportName": "Cornwall Regional Airport",
        "City": "Cornwall",
        "Country": "Canada"
    },
    {
        "id": "8433",
        "AirportCode": "YCD",
        "AirportName": "Nanaimo Airport",
        "City": "Nanaimo",
        "Country": "Canada"
    },
    {
        "id": "8434",
        "AirportCode": "YCE",
        "AirportName": "Centralia Airport",
        "City": "Centralia",
        "Country": "Canada"
    },
    {
        "id": "8435",
        "AirportCode": "YCF",
        "AirportName": "Cortes Bay Airport",
        "City": "Cortes Bay",
        "Country": "Canada"
    },
    {
        "id": "8436",
        "AirportCode": "YCG",
        "AirportName": "Castlegar Airport",
        "City": "Castlegar",
        "Country": "Canada"
    },
    {
        "id": "8437",
        "AirportCode": "YCH",
        "AirportName": "Miramichi Airport",
        "City": "Miramichi",
        "Country": "Canada"
    },
    {
        "id": "8438",
        "AirportCode": "YCI",
        "AirportName": "Caribou Island Airport",
        "City": "Caribou Island",
        "Country": "Canada"
    },
    {
        "id": "8439",
        "AirportCode": "YCJ",
        "AirportName": "Cape Saint James Airport",
        "City": "Cape Saint James",
        "Country": "Canada"
    },
    {
        "id": "8440",
        "AirportCode": "YCK",
        "AirportName": "Colville Lake Airport",
        "City": "Colville Lake",
        "Country": "Canada"
    },
    {
        "id": "8441",
        "AirportCode": "YCL",
        "AirportName": "Charlo Airport",
        "City": "Charlo",
        "Country": "Canada"
    },
    {
        "id": "8442",
        "AirportCode": "YCM",
        "AirportName": "St. Catharines Airport",
        "City": "Saint Catharines",
        "Country": "Canada"
    },
    {
        "id": "8443",
        "AirportCode": "YCN",
        "AirportName": "Cochrane Airport",
        "City": "Cochrane",
        "Country": "Canada"
    },
    {
        "id": "8444",
        "AirportCode": "YCO",
        "AirportName": "Kugluktuk Airport",
        "City": "Kugluktuk/Coppermine",
        "Country": "Canada"
    },
    {
        "id": "8445",
        "AirportCode": "YCP",
        "AirportName": "Co-Op Point Airport",
        "City": "Co-Op Point",
        "Country": "Canada"
    },
    {
        "id": "8446",
        "AirportCode": "YCQ",
        "AirportName": "Chetwynd Airport",
        "City": "Chetwynd",
        "Country": "Canada"
    },
    {
        "id": "8447",
        "AirportCode": "YCR",
        "AirportName": "Cross Lake Airport",
        "City": "Cross Lake",
        "Country": "Canada"
    },
    {
        "id": "8448",
        "AirportCode": "YCS",
        "AirportName": "Chesterfield Inlet Airport",
        "City": "Chesterfield Inlet",
        "Country": "Canada"
    },
    {
        "id": "8449",
        "AirportCode": "YCT",
        "AirportName": "Coronation Airport",
        "City": "Coronation",
        "Country": "Canada"
    },
    {
        "id": "8450",
        "AirportCode": "YCV",
        "AirportName": "Cartierville Airport",
        "City": "Cartierville",
        "Country": "Canada"
    },
    {
        "id": "8451",
        "AirportCode": "YCW",
        "AirportName": "Chilliwack Airport",
        "City": "Chilliwack",
        "Country": "Canada"
    },
    {
        "id": "8452",
        "AirportCode": "YCX",
        "AirportName": "Gagetown Airport",
        "City": "Gagetown",
        "Country": "Canada"
    },
    {
        "id": "8453",
        "AirportCode": "YCY",
        "AirportName": "Clyde River Airport",
        "City": "Clyde River",
        "Country": "Canada"
    },
    {
        "id": "8454",
        "AirportCode": "YCZ",
        "AirportName": "Fairmount Springs Airport",
        "City": "Fairmount Springs",
        "Country": "Canada"
    },
    {
        "id": "8455",
        "AirportCode": "YDA",
        "AirportName": "Dawson City Airport",
        "City": "Dawson City",
        "Country": "Canada"
    },
    {
        "id": "8456",
        "AirportCode": "YDB",
        "AirportName": "Burwash Landings Airport",
        "City": "Burwash Landings",
        "Country": "Canada"
    },
    {
        "id": "8457",
        "AirportCode": "YDC",
        "AirportName": "Industrial Airport",
        "City": "Drayton Valley",
        "Country": "Canada"
    },
    {
        "id": "8458",
        "AirportCode": "YDE",
        "AirportName": "Paradise River Airport",
        "City": "Paradise River",
        "Country": "Canada"
    },
    {
        "id": "8459",
        "AirportCode": "YDF",
        "AirportName": "Deer Lake Airport",
        "City": "Deer Lake",
        "Country": "Canada"
    },
    {
        "id": "8460",
        "AirportCode": "YDG",
        "AirportName": "Digby Airport",
        "City": "Digby",
        "Country": "Canada"
    },
    {
        "id": "8461",
        "AirportCode": "YDH",
        "AirportName": "Daniels Harbour Airport",
        "City": "Daniels Harbour",
        "Country": "Canada"
    },
    {
        "id": "8462",
        "AirportCode": "YDI",
        "AirportName": "Davis Inlet Airport",
        "City": "Davis Inlet",
        "Country": "Canada"
    },
    {
        "id": "8463",
        "AirportCode": "YDJ",
        "AirportName": "Hatchet Lake Airport",
        "City": "Hatchet Lake",
        "Country": "Canada"
    },
    {
        "id": "8464",
        "AirportCode": "YDK",
        "AirportName": "Main Duck Island Airport",
        "City": "Main Duck Island",
        "Country": "Canada"
    },
    {
        "id": "8465",
        "AirportCode": "YDL",
        "AirportName": "Dease Lake Airport",
        "City": "Dease Lake",
        "Country": "Canada"
    },
    {
        "id": "8466",
        "AirportCode": "YDN",
        "AirportName": "Dauphin Airport",
        "City": "Dauphin",
        "Country": "Canada"
    },
    {
        "id": "8467",
        "AirportCode": "YDO",
        "AirportName": "St Methode Airport",
        "City": "Dolbeau",
        "Country": "Canada"
    },
    {
        "id": "8468",
        "AirportCode": "YDP",
        "AirportName": "Nain Airport",
        "City": "Nain",
        "Country": "Canada"
    },
    {
        "id": "8469",
        "AirportCode": "YDQ",
        "AirportName": "Dawson Creek Airport",
        "City": "Dawson Creek",
        "Country": "Canada"
    },
    {
        "id": "8470",
        "AirportCode": "YDR",
        "AirportName": "Broadview Airport",
        "City": "Broadview",
        "Country": "Canada"
    },
    {
        "id": "8471",
        "AirportCode": "YDS",
        "AirportName": "Desolation Sound Airport",
        "City": "Desolation Sound",
        "Country": "Canada"
    },
    {
        "id": "8472",
        "AirportCode": "YDT",
        "AirportName": "Boundary Bay Airport",
        "City": "Vancouver",
        "Country": "Canada"
    },
    {
        "id": "8473",
        "AirportCode": "YDU",
        "AirportName": "Kasba Lake Airport",
        "City": "Kasba Lake",
        "Country": "Canada"
    },
    {
        "id": "8474",
        "AirportCode": "YDV",
        "AirportName": "Bloodvein Airport",
        "City": "Bloodvein",
        "Country": "Canada"
    },
    {
        "id": "8475",
        "AirportCode": "YDW",
        "AirportName": "Obre Lake Airport",
        "City": "Obre Lake",
        "Country": "Canada"
    },
    {
        "id": "8476",
        "AirportCode": "YDX",
        "AirportName": "Doc Creek Airport",
        "City": "Doc Creek",
        "Country": "Canada"
    },
    {
        "id": "8477",
        "AirportCode": "YEC",
        "AirportName": "Yecheon Airport",
        "City": "Yecheon",
        "Country": "Korea"
    },
    {
        "id": "8478",
        "AirportCode": "YED",
        "AirportName": "Namao Field",
        "City": "Edmonton",
        "Country": "Canada"
    },
    {
        "id": "8479",
        "AirportCode": "YEG",
        "AirportName": "Edmonton International Airport",
        "City": "Edmonton",
        "Country": "Canada"
    },
    {
        "id": "8480",
        "AirportCode": "YEI",
        "AirportName": "Yenisehir Airport",
        "City": "Bursa",
        "Country": "Turkey"
    },
    {
        "id": "8481",
        "AirportCode": "YEK",
        "AirportName": "Arviat Airport",
        "City": "Arviat",
        "Country": "Canada"
    },
    {
        "id": "8482",
        "AirportCode": "YEL",
        "AirportName": "Elliot Lake Airport",
        "City": "Elliot Lake",
        "Country": "Canada"
    },
    {
        "id": "8483",
        "AirportCode": "YEM",
        "AirportName": "East Manitoulin Airport",
        "City": "Manitowaning",
        "Country": "Canada"
    },
    {
        "id": "8484",
        "AirportCode": "YEN",
        "AirportName": "Estevan Airport",
        "City": "Estevan",
        "Country": "Canada"
    },
    {
        "id": "8485",
        "AirportCode": "YEO",
        "AirportName": "Yeovilton Airport",
        "City": "Yeovilton",
        "Country": "United Kingdom"
    },
    {
        "id": "8486",
        "AirportCode": "YEP",
        "AirportName": "Estevan Point Airport",
        "City": "Estevan Point",
        "Country": "Canada"
    },
    {
        "id": "8487",
        "AirportCode": "YEQ",
        "AirportName": "Yenkis Airport",
        "City": "Yenkis",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8488",
        "AirportCode": "YER",
        "AirportName": "Fort Severn Airport",
        "City": "Fort Severn",
        "Country": "Canada"
    },
    {
        "id": "8489",
        "AirportCode": "YES",
        "AirportName": "Yasouj Airport",
        "City": "Yasouj",
        "Country": "Iran"
    },
    {
        "id": "8490",
        "AirportCode": "YET",
        "AirportName": "Edson Airport",
        "City": "Edson",
        "Country": "Canada"
    },
    {
        "id": "8491",
        "AirportCode": "YEU",
        "AirportName": "Eureka Airport",
        "City": "Eureka",
        "Country": "Canada"
    },
    {
        "id": "8492",
        "AirportCode": "YEV",
        "AirportName": "Inuvik/Mike Zubko Airport",
        "City": "Inuvik",
        "Country": "Canada"
    },
    {
        "id": "8493",
        "AirportCode": "YEY",
        "AirportName": "Amos Airport",
        "City": "Amos",
        "Country": "Canada"
    },
    {
        "id": "8494",
        "AirportCode": "YFA",
        "AirportName": "Fort Albany Airport",
        "City": "Fort Albany",
        "Country": "Canada"
    },
    {
        "id": "8495",
        "AirportCode": "YFB",
        "AirportName": "Iqaluit Airport",
        "City": "Iqaluit",
        "Country": "Canada"
    },
    {
        "id": "8496",
        "AirportCode": "YFC",
        "AirportName": "Fredericton Airport",
        "City": "Fredericton",
        "Country": "Canada"
    },
    {
        "id": "8497",
        "AirportCode": "YFE",
        "AirportName": "Forestville Airport",
        "City": "Forestville",
        "Country": "Canada"
    },
    {
        "id": "8498",
        "AirportCode": "YFG",
        "AirportName": "Fontanges Airport",
        "City": "Fontanges",
        "Country": "Canada"
    },
    {
        "id": "8499",
        "AirportCode": "YFH",
        "AirportName": "Fort Hope Airport",
        "City": "Fort Hope",
        "Country": "Canada"
    },
    {
        "id": "8500",
        "AirportCode": "YFJ",
        "AirportName": "Snare Lake Airport",
        "City": "Snare Lake",
        "Country": "Canada"
    },
    {
        "id": "8501",
        "AirportCode": "YFK",
        "AirportName": "Kitchener/Waterloo Regional Airport",
        "City": "Breslau",
        "Country": "Canada"
    },
    {
        "id": "8502",
        "AirportCode": "YFL",
        "AirportName": "Fort Reliance Airport",
        "City": "Fort Reliance",
        "Country": "Canada"
    },
    {
        "id": "8503",
        "AirportCode": "YFO",
        "AirportName": "Flin Flon Airport",
        "City": "Flin Flon",
        "Country": "Canada"
    },
    {
        "id": "8504",
        "AirportCode": "YFR",
        "AirportName": "Fort Resolution Airport",
        "City": "Fort Resolution",
        "Country": "Canada"
    },
    {
        "id": "8505",
        "AirportCode": "YFS",
        "AirportName": "Fort Simpson Airport",
        "City": "Fort Simpson",
        "Country": "Canada"
    },
    {
        "id": "8506",
        "AirportCode": "YFX",
        "AirportName": "Fox Harbour Airport",
        "City": "Fox Harbour",
        "Country": "Canada"
    },
    {
        "id": "8507",
        "AirportCode": "YGA",
        "AirportName": "Gagnon Airport",
        "City": "Gagnon",
        "Country": "Canada"
    },
    {
        "id": "8508",
        "AirportCode": "YGB",
        "AirportName": "Gillies Bay Airport",
        "City": "Gillies Bay",
        "Country": "Canada"
    },
    {
        "id": "8509",
        "AirportCode": "YGC",
        "AirportName": "Grande Cache Airport",
        "City": "Grande Cache",
        "Country": "Canada"
    },
    {
        "id": "8510",
        "AirportCode": "YGE",
        "AirportName": "Gorge Harbor Airport",
        "City": "Gorge Harbor",
        "Country": "Canada"
    },
    {
        "id": "8511",
        "AirportCode": "YGG",
        "AirportName": "Ganges Harbor Airport",
        "City": "Ganges Harbor",
        "Country": "Canada"
    },
    {
        "id": "8512",
        "AirportCode": "YGH",
        "AirportName": "Fort Good Hope Airport",
        "City": "Fort Good Hope",
        "Country": "Canada"
    },
    {
        "id": "8513",
        "AirportCode": "YGJ",
        "AirportName": "Miho Airport",
        "City": "Yonago",
        "Country": "Japan"
    },
    {
        "id": "8514",
        "AirportCode": "YGK",
        "AirportName": "Kingston Airport",
        "City": "Kingston",
        "Country": "Canada"
    },
    {
        "id": "8515",
        "AirportCode": "YGL",
        "AirportName": "La Grande Airport",
        "City": "La Grande",
        "Country": "Canada"
    },
    {
        "id": "8516",
        "AirportCode": "YGM",
        "AirportName": "Gimli Airport",
        "City": "Gimli",
        "Country": "Canada"
    },
    {
        "id": "8517",
        "AirportCode": "YGN",
        "AirportName": "Greenway Sound Airport",
        "City": "Greenway Sound",
        "Country": "Canada"
    },
    {
        "id": "8518",
        "AirportCode": "YGO",
        "AirportName": "Gods Narrows Airport",
        "City": "Gods Narrows",
        "Country": "Canada"
    },
    {
        "id": "8519",
        "AirportCode": "YGP",
        "AirportName": "Metropolitan Area Airport",
        "City": "Gaspe",
        "Country": "Canada"
    },
    {
        "id": "8520",
        "AirportCode": "YGQ",
        "AirportName": "Geraldton Airport",
        "City": "Geraldton",
        "Country": "Canada"
    },
    {
        "id": "8521",
        "AirportCode": "YGR",
        "AirportName": "Iles De La Madeleine Airport",
        "City": "Iles De La Madeleine",
        "Country": "Canada"
    },
    {
        "id": "8522",
        "AirportCode": "YGS",
        "AirportName": "Germansen Airport",
        "City": "Germansen",
        "Country": "Canada"
    },
    {
        "id": "8523",
        "AirportCode": "YGT",
        "AirportName": "Igloolik Airport",
        "City": "Igloolik",
        "Country": "Canada"
    },
    {
        "id": "8524",
        "AirportCode": "YGV",
        "AirportName": "Havre St. Pierre Airport",
        "City": "Havre St. Pierre",
        "Country": "Canada"
    },
    {
        "id": "8525",
        "AirportCode": "YGW",
        "AirportName": "Kuujjuarapik Airport",
        "City": "Kuujjuarapik",
        "Country": "Canada"
    },
    {
        "id": "8526",
        "AirportCode": "YGX",
        "AirportName": "Gillam Airport",
        "City": "Gillam",
        "Country": "Canada"
    },
    {
        "id": "8527",
        "AirportCode": "YGY",
        "AirportName": "Deception Bay Airport",
        "City": "Deception Bay",
        "Country": "Canada"
    },
    {
        "id": "8528",
        "AirportCode": "YGZ",
        "AirportName": "Grise Fiord Airport",
        "City": "Grise Fiord",
        "Country": "Canada"
    },
    {
        "id": "8529",
        "AirportCode": "YHA",
        "AirportName": "Port Hope Simpson Airport",
        "City": "Port Hope Simpson",
        "Country": "Canada"
    },
    {
        "id": "8530",
        "AirportCode": "YHB",
        "AirportName": "Hudson Bay Airport",
        "City": "Hudson Bay",
        "Country": "Canada"
    },
    {
        "id": "8531",
        "AirportCode": "YHC",
        "AirportName": "Hakai Pass Airport",
        "City": "Hakai Pass",
        "Country": "Canada"
    },
    {
        "id": "8532",
        "AirportCode": "YHD",
        "AirportName": "Dryden Municipal Airport",
        "City": "Dryden",
        "Country": "Canada"
    },
    {
        "id": "8533",
        "AirportCode": "YHE",
        "AirportName": "Hope Airport",
        "City": "Hope",
        "Country": "Canada"
    },
    {
        "id": "8534",
        "AirportCode": "YHF",
        "AirportName": "Hearst Airport",
        "City": "Hearst",
        "Country": "Canada"
    },
    {
        "id": "8535",
        "AirportCode": "YHG",
        "AirportName": "Charlottetown Airport",
        "City": "Charlottetown",
        "Country": "Canada"
    },
    {
        "id": "8536",
        "AirportCode": "YHH",
        "AirportName": "Harbor Sea Plane Base",
        "City": "Campbell River",
        "Country": "Canada"
    },
    {
        "id": "8537",
        "AirportCode": "YHI",
        "AirportName": "Holman Airport",
        "City": "Holman",
        "Country": "Canada"
    },
    {
        "id": "8538",
        "AirportCode": "YHK",
        "AirportName": "Gjoa Haven Airport",
        "City": "Gjoa Haven",
        "Country": "Canada"
    },
    {
        "id": "8539",
        "AirportCode": "YHM",
        "AirportName": "Hamilton Airport",
        "City": "Hamilton",
        "Country": "Canada"
    },
    {
        "id": "8540",
        "AirportCode": "YHN",
        "AirportName": "Hornepayne Airport",
        "City": "Hornepayne",
        "Country": "Canada"
    },
    {
        "id": "8541",
        "AirportCode": "YHO",
        "AirportName": "Hopedale Airport",
        "City": "Hopedale",
        "Country": "Canada"
    },
    {
        "id": "8542",
        "AirportCode": "YHP",
        "AirportName": "Poplar Hill Airport",
        "City": "Poplar Hill",
        "Country": "Canada"
    },
    {
        "id": "8543",
        "AirportCode": "YHR",
        "AirportName": "Chevery Airport",
        "City": "Chevery",
        "Country": "Canada"
    },
    {
        "id": "8544",
        "AirportCode": "YHS",
        "AirportName": "Sechelt Airport",
        "City": "Sechelt",
        "Country": "Canada"
    },
    {
        "id": "8545",
        "AirportCode": "YHT",
        "AirportName": "Haines Junction Airport",
        "City": "Haines Junction",
        "Country": "Canada"
    },
    {
        "id": "8546",
        "AirportCode": "YHU",
        "AirportName": "St. Hubert Airport",
        "City": "Montreal",
        "Country": "Canada"
    },
    {
        "id": "8547",
        "AirportCode": "YHY",
        "AirportName": "Hay River Airport",
        "City": "Hay River",
        "Country": "Canada"
    },
    {
        "id": "8548",
        "AirportCode": "YHZ",
        "AirportName": "Halifax International Airport",
        "City": "Halifax",
        "Country": "Canada"
    },
    {
        "id": "8549",
        "AirportCode": "YIB",
        "AirportName": "Atikokan Airport",
        "City": "Atikokan",
        "Country": "Canada"
    },
    {
        "id": "8550",
        "AirportCode": "YIF",
        "AirportName": "Pakuashipi Airport",
        "City": "Pakuashipi",
        "Country": "Canada"
    },
    {
        "id": "8551",
        "AirportCode": "YIG",
        "AirportName": "Big Bay Marina Airport",
        "City": "Big Bay Marina",
        "Country": "Canada"
    },
    {
        "id": "8552",
        "AirportCode": "YIH",
        "AirportName": "Yichang Airport",
        "City": "Yichang",
        "Country": "China"
    },
    {
        "id": "8553",
        "AirportCode": "YIK",
        "AirportName": "Ivujivik Airport",
        "City": "Ivujivik",
        "Country": "Canada"
    },
    {
        "id": "8554",
        "AirportCode": "YIN",
        "AirportName": "Yining Airport",
        "City": "Yining",
        "Country": "China"
    },
    {
        "id": "8555",
        "AirportCode": "YIO",
        "AirportName": "Pond Inlet Airport",
        "City": "Pond Inlet",
        "Country": "Canada"
    },
    {
        "id": "8556",
        "AirportCode": "YIP",
        "AirportName": "Willow Run Airport",
        "City": "Detroit",
        "Country": "USA Michigan"
    },
    {
        "id": "8557",
        "AirportCode": "YIV",
        "AirportName": "Island Lake/Garden Hill Airport",
        "City": "Island Lake/Garden Hill",
        "Country": "Canada"
    },
    {
        "id": "8558",
        "AirportCode": "YIW",
        "AirportName": "Yiwu Airport",
        "City": "Yiwu",
        "Country": "China"
    },
    {
        "id": "8559",
        "AirportCode": "YJA",
        "AirportName": "Metropolitan Area Airport",
        "City": "Jasper",
        "Country": "Canada"
    },
    {
        "id": "8560",
        "AirportCode": "YJF",
        "AirportName": "Fort Liard Airport",
        "City": "Fort Liard",
        "Country": "Canada"
    },
    {
        "id": "8561",
        "AirportCode": "YJN",
        "AirportName": "Saint Jean Airport",
        "City": "Saint Jean",
        "Country": "Canada"
    },
    {
        "id": "8562",
        "AirportCode": "YJO",
        "AirportName": "Johnny Mountain Airport",
        "City": "Johnny Mountain",
        "Country": "Canada"
    },
    {
        "id": "8563",
        "AirportCode": "YJP",
        "AirportName": "Jasper-Hinton Airport",
        "City": "Jasper-Hinton",
        "Country": "Canada"
    },
    {
        "id": "8564",
        "AirportCode": "YJT",
        "AirportName": "Stephenville Airport",
        "City": "Stephenville",
        "Country": "Canada"
    },
    {
        "id": "8565",
        "AirportCode": "YKA",
        "AirportName": "Kamloops Airport",
        "City": "Kamloops",
        "Country": "Canada"
    },
    {
        "id": "8566",
        "AirportCode": "YKC",
        "AirportName": "Collins Bay Airport",
        "City": "Collins Bay",
        "Country": "Canada"
    },
    {
        "id": "8567",
        "AirportCode": "YKD",
        "AirportName": "Township Airport",
        "City": "Kincardine",
        "Country": "Canada"
    },
    {
        "id": "8568",
        "AirportCode": "YKE",
        "AirportName": "Knee Lake Airport",
        "City": "Knee Lake",
        "Country": "Canada"
    },
    {
        "id": "8569",
        "AirportCode": "YKF",
        "AirportName": "Kitchener-Waterloo Regional Airport",
        "City": "Kitchener",
        "Country": "Canada"
    },
    {
        "id": "8570",
        "AirportCode": "YKG",
        "AirportName": "Kangirsuk Airport",
        "City": "Kangirsuk",
        "Country": "Canada"
    },
    {
        "id": "8571",
        "AirportCode": "YKJ",
        "AirportName": "Key Lake Airport",
        "City": "Key Lake",
        "Country": "Canada"
    },
    {
        "id": "8572",
        "AirportCode": "YKK",
        "AirportName": "Kitkatla Airport",
        "City": "Kitkatla",
        "Country": "Canada"
    },
    {
        "id": "8573",
        "AirportCode": "YKL",
        "AirportName": "Schefferville Airport",
        "City": "Schefferville",
        "Country": "Canada"
    },
    {
        "id": "8574",
        "AirportCode": "YKM",
        "AirportName": "Yakima Air Terminal",
        "City": "Yakima",
        "Country": "USA Washington"
    },
    {
        "id": "8575",
        "AirportCode": "YKN",
        "AirportName": "Chan Gurney Airport",
        "City": "Yankton",
        "Country": "USA South Dakota"
    },
    {
        "id": "8576",
        "AirportCode": "YKQ",
        "AirportName": "Waskaganish Airport",
        "City": "Waskaganish",
        "Country": "Canada"
    },
    {
        "id": "8577",
        "AirportCode": "YKS",
        "AirportName": "Yakutsk Airport",
        "City": "Yakutsk",
        "Country": "Russia"
    },
    {
        "id": "8578",
        "AirportCode": "YKT",
        "AirportName": "Klemtu Airport",
        "City": "Klemtu",
        "Country": "Canada"
    },
    {
        "id": "8579",
        "AirportCode": "YKU",
        "AirportName": "Chisasibi Airport",
        "City": "Chisasibi",
        "Country": "Canada"
    },
    {
        "id": "8580",
        "AirportCode": "YKX",
        "AirportName": "Kirkland Lake Airport",
        "City": "Kirkland Lake",
        "Country": "Canada"
    },
    {
        "id": "8581",
        "AirportCode": "YKY",
        "AirportName": "Kindersley Airport",
        "City": "Kindersley",
        "Country": "Canada"
    },
    {
        "id": "8582",
        "AirportCode": "YKZ",
        "AirportName": "Buttonville Airport",
        "City": "Toronto",
        "Country": "Canada"
    },
    {
        "id": "8583",
        "AirportCode": "YLA",
        "AirportName": "Langara Airport",
        "City": "Langara",
        "Country": "Canada"
    },
    {
        "id": "8584",
        "AirportCode": "YLB",
        "AirportName": "Lac Biche Airport",
        "City": "Lac Biche",
        "Country": "Canada"
    },
    {
        "id": "8585",
        "AirportCode": "YLC",
        "AirportName": "Kimmirut Airport",
        "City": "Kimmirut/Lake Harbour",
        "Country": "Canada"
    },
    {
        "id": "8586",
        "AirportCode": "YLD",
        "AirportName": "Chapleau Airport",
        "City": "Chapleau",
        "Country": "Canada"
    },
    {
        "id": "8587",
        "AirportCode": "YLE",
        "AirportName": "Wha Ti Airport",
        "City": "Wha Ti/Lac La Martre",
        "Country": "Canada"
    },
    {
        "id": "8588",
        "AirportCode": "YLF",
        "AirportName": "Laforges Airport",
        "City": "Laforges",
        "Country": "Canada"
    },
    {
        "id": "8589",
        "AirportCode": "YLG",
        "AirportName": "Yalgoo Airport",
        "City": "Yalgoo",
        "Country": "Australia"
    },
    {
        "id": "8590",
        "AirportCode": "YLH",
        "AirportName": "Lansdowne House Airport",
        "City": "Lansdowne House",
        "Country": "Canada"
    },
    {
        "id": "8591",
        "AirportCode": "YLI",
        "AirportName": "Ylivieska Airport",
        "City": "Ylivieska",
        "Country": "Finland"
    },
    {
        "id": "8592",
        "AirportCode": "YLJ",
        "AirportName": "Meadow Lake Airport",
        "City": "Meadow Lake",
        "Country": "Canada"
    },
    {
        "id": "8593",
        "AirportCode": "YLL",
        "AirportName": "Lloydminster Airport",
        "City": "Lloydminster",
        "Country": "Canada"
    },
    {
        "id": "8594",
        "AirportCode": "YLM",
        "AirportName": "Clinton Creek Airport",
        "City": "Clinton Creek",
        "Country": "Canada"
    },
    {
        "id": "8595",
        "AirportCode": "YLN",
        "AirportName": "Yilan Airport",
        "City": "Yilan",
        "Country": "China"
    },
    {
        "id": "8596",
        "AirportCode": "YLO",
        "AirportName": "Shilo Airport",
        "City": "Shilo",
        "Country": "Canada"
    },
    {
        "id": "8597",
        "AirportCode": "YLP",
        "AirportName": "Mingan Airport",
        "City": "Mingan",
        "Country": "Canada"
    },
    {
        "id": "8598",
        "AirportCode": "YLQ",
        "AirportName": "La Tuque Airport",
        "City": "La Tuque",
        "Country": "Canada"
    },
    {
        "id": "8599",
        "AirportCode": "YLR",
        "AirportName": "Leaf Rapids Airport",
        "City": "Leaf Rapids",
        "Country": "Canada"
    },
    {
        "id": "8600",
        "AirportCode": "YLS",
        "AirportName": "Lebel-Sur-Quevillon Airport",
        "City": "Lebel-Sur-Quevillon",
        "Country": "Canada"
    },
    {
        "id": "8601",
        "AirportCode": "YLT",
        "AirportName": "Alert Airport",
        "City": "Alert",
        "Country": "Canada"
    },
    {
        "id": "8602",
        "AirportCode": "YLW",
        "AirportName": "Kelowna Airport",
        "City": "Kelowna",
        "Country": "Canada"
    },
    {
        "id": "8603",
        "AirportCode": "YLY",
        "AirportName": "Langley Regional Airport",
        "City": "Langley",
        "Country": "Canada"
    },
    {
        "id": "8604",
        "AirportCode": "YMA",
        "AirportName": "Mayo Airport",
        "City": "Mayo",
        "Country": "Canada"
    },
    {
        "id": "8605",
        "AirportCode": "YMB",
        "AirportName": "Merritt Airport",
        "City": "Merritt",
        "Country": "Canada"
    },
    {
        "id": "8606",
        "AirportCode": "YMC",
        "AirportName": "Maricourt Airstrip",
        "City": "Maricourt",
        "Country": "Canada"
    },
    {
        "id": "8607",
        "AirportCode": "YMD",
        "AirportName": "Mould Bay Airport",
        "City": "Mould Bay",
        "Country": "Canada"
    },
    {
        "id": "8608",
        "AirportCode": "YME",
        "AirportName": "Matane Airport",
        "City": "Matane",
        "Country": "Canada"
    },
    {
        "id": "8609",
        "AirportCode": "YMF",
        "AirportName": "Montagne Harbor Airport",
        "City": "Montagne Harbor",
        "Country": "Canada"
    },
    {
        "id": "8610",
        "AirportCode": "YMG",
        "AirportName": "Manitouwadge Airport",
        "City": "Manitouwadge",
        "Country": "Canada"
    },
    {
        "id": "8611",
        "AirportCode": "YMI",
        "AirportName": "Minaki Airport",
        "City": "Minaki",
        "Country": "Canada"
    },
    {
        "id": "8612",
        "AirportCode": "YMJ",
        "AirportName": "Moose Jaw Airport",
        "City": "Moose Jaw",
        "Country": "Canada"
    },
    {
        "id": "8613",
        "AirportCode": "YML",
        "AirportName": "Charlevoix Airport",
        "City": "Murray Bay",
        "Country": "Canada"
    },
    {
        "id": "8614",
        "AirportCode": "YMM",
        "AirportName": "Fort McMurray Airport",
        "City": "Fort McMurray",
        "Country": "Canada"
    },
    {
        "id": "8615",
        "AirportCode": "YMN",
        "AirportName": "Makkovik Airport",
        "City": "Makkovik",
        "Country": "Canada"
    },
    {
        "id": "8616",
        "AirportCode": "YMO",
        "AirportName": "Moosonee Airport",
        "City": "Moosonee",
        "Country": "Canada"
    },
    {
        "id": "8617",
        "AirportCode": "YMP",
        "AirportName": "Port McNeil Airport",
        "City": "Port McNeil",
        "Country": "Canada"
    },
    {
        "id": "8618",
        "AirportCode": "YMR",
        "AirportName": "Merry Island Airport",
        "City": "Merry Island",
        "Country": "Canada"
    },
    {
        "id": "8619",
        "AirportCode": "YMS",
        "AirportName": "Yurimaguas Airport",
        "City": "Yurimaguas",
        "Country": "Peru"
    },
    {
        "id": "8620",
        "AirportCode": "YMT",
        "AirportName": "Chibougamau Airport",
        "City": "Chibougamau",
        "Country": "Canada"
    },
    {
        "id": "8621",
        "AirportCode": "YMW",
        "AirportName": "Maniwaki Airport",
        "City": "Maniwaki",
        "Country": "Canada"
    },
    {
        "id": "8622",
        "AirportCode": "YMX",
        "AirportName": "Mirabel International Airport",
        "City": "Montreal",
        "Country": "Canada"
    },
    {
        "id": "8623",
        "AirportCode": "YNA",
        "AirportName": "Natashquan Airport",
        "City": "Natashquan",
        "Country": "Canada"
    },
    {
        "id": "8624",
        "AirportCode": "YNB",
        "AirportName": "Yanbu Airport",
        "City": "Yanbu",
        "Country": "Saudi Arabia"
    },
    {
        "id": "8625",
        "AirportCode": "YNC",
        "AirportName": "Wemindji Airport",
        "City": "Wemindji",
        "Country": "Canada"
    },
    {
        "id": "8626",
        "AirportCode": "YND",
        "AirportName": "Gatineau Airport",
        "City": "Gatineau",
        "Country": "Canada"
    },
    {
        "id": "8627",
        "AirportCode": "YNE",
        "AirportName": "Norway House Airport",
        "City": "Norway House",
        "Country": "Canada"
    },
    {
        "id": "8628",
        "AirportCode": "YNF",
        "AirportName": "Deer Lake/Stephenville Airport",
        "City": "Corner Brook",
        "Country": "Canada"
    },
    {
        "id": "8629",
        "AirportCode": "YNG",
        "AirportName": "Youngstown Airport",
        "City": "Youngstown",
        "Country": "USA Ohio"
    },
    {
        "id": "8630",
        "AirportCode": "YNI",
        "AirportName": "Nitchequon Airport",
        "City": "Nitchequon",
        "Country": "Canada"
    },
    {
        "id": "8631",
        "AirportCode": "YNJ",
        "AirportName": "Yanji Airport",
        "City": "Yanji",
        "Country": "China"
    },
    {
        "id": "8632",
        "AirportCode": "YNK",
        "AirportName": "Nootka Sound Airport",
        "City": "Nootka Sound",
        "Country": "Canada"
    },
    {
        "id": "8633",
        "AirportCode": "YNL",
        "AirportName": "Points North Landing Airport",
        "City": "Points North Landing",
        "Country": "Canada"
    },
    {
        "id": "8634",
        "AirportCode": "YNM",
        "AirportName": "Matagami Airport",
        "City": "Matagami",
        "Country": "Canada"
    },
    {
        "id": "8635",
        "AirportCode": "YNN",
        "AirportName": "Yandi Airport",
        "City": "Yandicoogina",
        "Country": "Australia"
    },
    {
        "id": "8636",
        "AirportCode": "YNO",
        "AirportName": "North Spirit Lake Airport",
        "City": "North Spirit Lake",
        "Country": "Canada"
    },
    {
        "id": "8637",
        "AirportCode": "YNP",
        "AirportName": "Natuashish Airport",
        "City": "Natuashish",
        "Country": "Canada"
    },
    {
        "id": "8638",
        "AirportCode": "YNR",
        "AirportName": "Arnes Airport",
        "City": "Arnes",
        "Country": "Canada"
    },
    {
        "id": "8639",
        "AirportCode": "YNS",
        "AirportName": "Nemiscau Airport",
        "City": "Nemiscau",
        "Country": "Canada"
    },
    {
        "id": "8640",
        "AirportCode": "YNT",
        "AirportName": "Laishan Airport",
        "City": "Yantai",
        "Country": "China"
    },
    {
        "id": "8641",
        "AirportCode": "YNY",
        "AirportName": "Yangyang Airport",
        "City": "Yangyang",
        "Country": "Korea"
    },
    {
        "id": "8642",
        "AirportCode": "YNZ",
        "AirportName": "Yancheng Airport",
        "City": "Yancheng",
        "Country": "China"
    },
    {
        "id": "8643",
        "AirportCode": "YOA",
        "AirportName": "Ekati Airport",
        "City": "Ekati",
        "Country": "Canada"
    },
    {
        "id": "8644",
        "AirportCode": "YOC",
        "AirportName": "Old Crow Airport",
        "City": "Old Crow",
        "Country": "Canada"
    },
    {
        "id": "8645",
        "AirportCode": "YOD",
        "AirportName": "Cold Lake Airport",
        "City": "Cold Lake",
        "Country": "Canada"
    },
    {
        "id": "8646",
        "AirportCode": "YOE",
        "AirportName": "Falher Airport",
        "City": "Falher",
        "Country": "Canada"
    },
    {
        "id": "8647",
        "AirportCode": "YOG",
        "AirportName": "Ogoki Airport",
        "City": "Ogoki",
        "Country": "Canada"
    },
    {
        "id": "8648",
        "AirportCode": "YOH",
        "AirportName": "Oxford House Airport",
        "City": "Oxford House",
        "Country": "Canada"
    },
    {
        "id": "8649",
        "AirportCode": "YOJ",
        "AirportName": "Footner Lake Airport",
        "City": "High Level",
        "Country": "Canada"
    },
    {
        "id": "8650",
        "AirportCode": "YOK",
        "AirportName": "Yokohoma Airport",
        "City": "Yokohoma",
        "Country": "Japan"
    },
    {
        "id": "8651",
        "AirportCode": "YOL",
        "AirportName": "Yola Airport",
        "City": "Yola",
        "Country": "Nigeria"
    },
    {
        "id": "8652",
        "AirportCode": "YOO",
        "AirportName": "Oshawa Municipal Airport",
        "City": "Oshawa",
        "Country": "Canada"
    },
    {
        "id": "8653",
        "AirportCode": "YOP",
        "AirportName": "Rainbow Lake Airport",
        "City": "Rainbow Lake",
        "Country": "Canada"
    },
    {
        "id": "8654",
        "AirportCode": "YOS",
        "AirportName": "Billy Bishop Regional Airport",
        "City": "Owen Sound",
        "Country": "Canada"
    },
    {
        "id": "8655",
        "AirportCode": "YOW",
        "AirportName": "Ottawa/Macdonald-Cartier International Airport",
        "City": "Ottawa",
        "Country": "Canada"
    },
    {
        "id": "8656",
        "AirportCode": "YOY",
        "AirportName": "Valcartier Airport",
        "City": "Valcartier",
        "Country": "Canada"
    },
    {
        "id": "8657",
        "AirportCode": "YPA",
        "AirportName": "Prince Albert Airport",
        "City": "Prince Albert",
        "Country": "Canada"
    },
    {
        "id": "8658",
        "AirportCode": "YPB",
        "AirportName": "Port Alberni Airport",
        "City": "Port Alberni",
        "Country": "Canada"
    },
    {
        "id": "8659",
        "AirportCode": "YPC",
        "AirportName": "Paulatuk Airport",
        "City": "Paulatuk",
        "Country": "Canada"
    },
    {
        "id": "8660",
        "AirportCode": "YPD",
        "AirportName": "Parry Sound Airport",
        "City": "Parry Sound",
        "Country": "Canada"
    },
    {
        "id": "8661",
        "AirportCode": "YPE",
        "AirportName": "Peace River Airport",
        "City": "Peace River",
        "Country": "Canada"
    },
    {
        "id": "8662",
        "AirportCode": "YPF",
        "AirportName": "Esquimalt Airport",
        "City": "Esquimalt",
        "Country": "Canada"
    },
    {
        "id": "8663",
        "AirportCode": "YPG",
        "AirportName": "Portage La Prarie Airport",
        "City": "Portage La Prarie",
        "Country": "Canada"
    },
    {
        "id": "8664",
        "AirportCode": "YPH",
        "AirportName": "Inukjuak Airport",
        "City": "Inukjuak",
        "Country": "Canada"
    },
    {
        "id": "8665",
        "AirportCode": "YPI",
        "AirportName": "Port Simpson Airport",
        "City": "Port Simpson",
        "Country": "Canada"
    },
    {
        "id": "8666",
        "AirportCode": "YPJ",
        "AirportName": "Aupaluk Airport",
        "City": "Aupaluk",
        "Country": "Canada"
    },
    {
        "id": "8667",
        "AirportCode": "YPL",
        "AirportName": "Pickle Lake Airport",
        "City": "Pickle Lake",
        "Country": "Canada"
    },
    {
        "id": "8668",
        "AirportCode": "YPM",
        "AirportName": "Pikangikum Airport",
        "City": "Pikangikum",
        "Country": "Canada"
    },
    {
        "id": "8669",
        "AirportCode": "YPN",
        "AirportName": "Port Menier Airport",
        "City": "Port Menier",
        "Country": "Canada"
    },
    {
        "id": "8670",
        "AirportCode": "YPO",
        "AirportName": "Peawanuk Airport",
        "City": "Peawanuk",
        "Country": "Canada"
    },
    {
        "id": "8671",
        "AirportCode": "YPP",
        "AirportName": "Pine Point Airport",
        "City": "Pine Point",
        "Country": "Canada"
    },
    {
        "id": "8672",
        "AirportCode": "YPQ",
        "AirportName": "Peterborough Airport",
        "City": "Peterborough",
        "Country": "Canada"
    },
    {
        "id": "8673",
        "AirportCode": "YPR",
        "AirportName": "Digby Island Airport",
        "City": "Prince Rupert",
        "Country": "Canada"
    },
    {
        "id": "8674",
        "AirportCode": "YPS",
        "AirportName": "Port Hawkesbury Airport",
        "City": "Port Hawkesbury",
        "Country": "Canada"
    },
    {
        "id": "8675",
        "AirportCode": "YPT",
        "AirportName": "Pender Harbor Airport",
        "City": "Pender Harbor",
        "Country": "Canada"
    },
    {
        "id": "8676",
        "AirportCode": "YPW",
        "AirportName": "Powell River Airport",
        "City": "Powell River",
        "Country": "Canada"
    },
    {
        "id": "8677",
        "AirportCode": "YPX",
        "AirportName": "Puvirnituq Airport",
        "City": "Povungnituk",
        "Country": "Canada"
    },
    {
        "id": "8678",
        "AirportCode": "YPY",
        "AirportName": "Fort Chipewyan Airport",
        "City": "Fort Chipewyan",
        "Country": "Canada"
    },
    {
        "id": "8679",
        "AirportCode": "YPZ",
        "AirportName": "Burns Lake Airport",
        "City": "Burns Lake",
        "Country": "Canada"
    },
    {
        "id": "8680",
        "AirportCode": "YQA",
        "AirportName": "Muskoka Airport",
        "City": "Muskoka",
        "Country": "Canada"
    },
    {
        "id": "8681",
        "AirportCode": "YQB",
        "AirportName": "Quebec Airport",
        "City": "Quebec",
        "Country": "Canada"
    },
    {
        "id": "8682",
        "AirportCode": "YQC",
        "AirportName": "Quaqtaq Airport",
        "City": "Quaqtaq",
        "Country": "Canada"
    },
    {
        "id": "8683",
        "AirportCode": "YQD",
        "AirportName": "The Pas Airport",
        "City": "The Pas",
        "Country": "Canada"
    },
    {
        "id": "8684",
        "AirportCode": "YQE",
        "AirportName": "Kimberley Airport",
        "City": "Kimberley",
        "Country": "Canada"
    },
    {
        "id": "8685",
        "AirportCode": "YQF",
        "AirportName": "Red Deer Airport",
        "City": "Red Deer",
        "Country": "Canada"
    },
    {
        "id": "8686",
        "AirportCode": "YQG",
        "AirportName": "Windsor Airport",
        "City": "Windsor",
        "Country": "Canada"
    },
    {
        "id": "8687",
        "AirportCode": "YQH",
        "AirportName": "Watson Lake Airport",
        "City": "Watson Lake",
        "Country": "Canada"
    },
    {
        "id": "8688",
        "AirportCode": "YQI",
        "AirportName": "Yarmouth Airport",
        "City": "Yarmouth",
        "Country": "Canada"
    },
    {
        "id": "8689",
        "AirportCode": "YQK",
        "AirportName": "Kenora Airport",
        "City": "Kenora",
        "Country": "Canada"
    },
    {
        "id": "8690",
        "AirportCode": "YQL",
        "AirportName": "Lethbridge Airport",
        "City": "Lethbridge",
        "Country": "Canada"
    },
    {
        "id": "8691",
        "AirportCode": "YQM",
        "AirportName": "Metropolitan Area",
        "City": "Moncton",
        "Country": "Canada"
    },
    {
        "id": "8692",
        "AirportCode": "YQN",
        "AirportName": "Nakina Airport",
        "City": "Nakina",
        "Country": "Canada"
    },
    {
        "id": "8693",
        "AirportCode": "YQQ",
        "AirportName": "Comox Airport",
        "City": "Comox",
        "Country": "Canada"
    },
    {
        "id": "8694",
        "AirportCode": "YQR",
        "AirportName": "Regina Airport",
        "City": "Regina",
        "Country": "Canada"
    },
    {
        "id": "8695",
        "AirportCode": "YQS",
        "AirportName": "Pembroke Area Municipal Airport",
        "City": "Saint Thomas",
        "Country": "Canada"
    },
    {
        "id": "8696",
        "AirportCode": "YQT",
        "AirportName": "Thunder Bay Airport",
        "City": "Thunder Bay",
        "Country": "Canada"
    },
    {
        "id": "8697",
        "AirportCode": "YQU",
        "AirportName": "Grande Prairie Airport",
        "City": "Grande Prairie",
        "Country": "Canada"
    },
    {
        "id": "8698",
        "AirportCode": "YQV",
        "AirportName": "Yorkton Airport",
        "City": "Yorkton",
        "Country": "Canada"
    },
    {
        "id": "8699",
        "AirportCode": "YQW",
        "AirportName": "North Battleford Airport",
        "City": "North Battleford",
        "Country": "Canada"
    },
    {
        "id": "8700",
        "AirportCode": "YQX",
        "AirportName": "Gander Airport",
        "City": "Gander",
        "Country": "Canada"
    },
    {
        "id": "8701",
        "AirportCode": "YQY",
        "AirportName": "Sydney Airport",
        "City": "Sydney",
        "Country": "Canada"
    },
    {
        "id": "8702",
        "AirportCode": "YQZ",
        "AirportName": "Quesnel Airport",
        "City": "Quesnel",
        "Country": "Canada"
    },
    {
        "id": "8703",
        "AirportCode": "YR6",
        "AirportName": "Null",
        "City": "Campbell River",
        "Country": "Canada"
    },
    {
        "id": "8704",
        "AirportCode": "YRA",
        "AirportName": "Rae Lakes Airport",
        "City": "Rae Lakes",
        "Country": "Canada"
    },
    {
        "id": "8705",
        "AirportCode": "YRB",
        "AirportName": "Resolute Airport",
        "City": "Resolute",
        "Country": "Canada"
    },
    {
        "id": "8706",
        "AirportCode": "YRD",
        "AirportName": "Dean River Airport",
        "City": "Dean River",
        "Country": "Canada"
    },
    {
        "id": "8707",
        "AirportCode": "YRE",
        "AirportName": "Resolution Island Airport",
        "City": "Resolution Island",
        "Country": "Canada"
    },
    {
        "id": "8708",
        "AirportCode": "YRF",
        "AirportName": "Cartwright Airport",
        "City": "Cartwright",
        "Country": "Canada"
    },
    {
        "id": "8709",
        "AirportCode": "YRG",
        "AirportName": "Rigolet Airport",
        "City": "Rigolet",
        "Country": "Canada"
    },
    {
        "id": "8710",
        "AirportCode": "YRI",
        "AirportName": "Riviere-du-Loup Airport",
        "City": "Riviere-du-Loup",
        "Country": "Canada"
    },
    {
        "id": "8711",
        "AirportCode": "YRJ",
        "AirportName": "Roberval Airport",
        "City": "Roberval",
        "Country": "Canada"
    },
    {
        "id": "8712",
        "AirportCode": "YRL",
        "AirportName": "Red Lake Airport",
        "City": "Red Lake",
        "Country": "Canada"
    },
    {
        "id": "8713",
        "AirportCode": "YRM",
        "AirportName": "Rocky Mountain House Airport",
        "City": "Rocky Mountain House",
        "Country": "Canada"
    },
    {
        "id": "8714",
        "AirportCode": "YRN",
        "AirportName": "Rivers Inlet Airport",
        "City": "Rivers Inlet",
        "Country": "Canada"
    },
    {
        "id": "8715",
        "AirportCode": "YRO",
        "AirportName": "Rockcliffe St. Airport",
        "City": "Ottawa",
        "Country": "Canada"
    },
    {
        "id": "8716",
        "AirportCode": "YRQ",
        "AirportName": "Trois-Rivieres Airport",
        "City": "Trois-Rivieres",
        "Country": "Canada"
    },
    {
        "id": "8717",
        "AirportCode": "YRR",
        "AirportName": "Stuart Island Airport",
        "City": "Stuart Island",
        "Country": "Canada"
    },
    {
        "id": "8718",
        "AirportCode": "YRS",
        "AirportName": "Red Sucker Lake Airport",
        "City": "Red Sucker Lake",
        "Country": "Canada"
    },
    {
        "id": "8719",
        "AirportCode": "YRT",
        "AirportName": "Rankin Inlet Airport",
        "City": "Rankin Inlet",
        "Country": "Canada"
    },
    {
        "id": "8720",
        "AirportCode": "YRV",
        "AirportName": "Revelstoke Airport",
        "City": "Revelstoke",
        "Country": "Canada"
    },
    {
        "id": "8721",
        "AirportCode": "YSA",
        "AirportName": "Sable Island Airport",
        "City": "Sable Island",
        "Country": "Canada"
    },
    {
        "id": "8722",
        "AirportCode": "YSB",
        "AirportName": "Sudbury Airport",
        "City": "Sudbury",
        "Country": "Canada"
    },
    {
        "id": "8723",
        "AirportCode": "YSC",
        "AirportName": "Sherbrooke Airport",
        "City": "Sherbrooke",
        "Country": "Canada"
    },
    {
        "id": "8724",
        "AirportCode": "YSD",
        "AirportName": "Suffield Airport",
        "City": "Suffield",
        "Country": "Canada"
    },
    {
        "id": "8725",
        "AirportCode": "YSE",
        "AirportName": "Squamish Airport",
        "City": "Squamish",
        "Country": "Canada"
    },
    {
        "id": "8726",
        "AirportCode": "YSF",
        "AirportName": "Stony Rapids Airport",
        "City": "Stony Rapids",
        "Country": "Canada"
    },
    {
        "id": "8727",
        "AirportCode": "YSG",
        "AirportName": "Lutselke Airport",
        "City": "Lutselke/Snowdrift",
        "Country": "Canada"
    },
    {
        "id": "8728",
        "AirportCode": "YSH",
        "AirportName": "Smith Falls Airport",
        "City": "Smith Falls",
        "Country": "Canada"
    },
    {
        "id": "8729",
        "AirportCode": "YSI",
        "AirportName": "Sans Souci Airport",
        "City": "Sans Souci",
        "Country": "Canada"
    },
    {
        "id": "8730",
        "AirportCode": "YSJ",
        "AirportName": "Saint John Airport",
        "City": "Saint John",
        "Country": "Canada"
    },
    {
        "id": "8731",
        "AirportCode": "YSK",
        "AirportName": "Sanikiluaq Airport",
        "City": "Sanikiluaq",
        "Country": "Canada"
    },
    {
        "id": "8732",
        "AirportCode": "YSL",
        "AirportName": "Edmunston Airport",
        "City": "Saint Leonard",
        "Country": "Canada"
    },
    {
        "id": "8733",
        "AirportCode": "YSM",
        "AirportName": "Fort Smith Airport",
        "City": "Fort Smith",
        "Country": "Canada"
    },
    {
        "id": "8734",
        "AirportCode": "YSN",
        "AirportName": "Salmon Arm Airport",
        "City": "Salmon Arm",
        "Country": "Canada"
    },
    {
        "id": "8735",
        "AirportCode": "YSO",
        "AirportName": "Postville Airport",
        "City": "Postville",
        "Country": "Canada"
    },
    {
        "id": "8736",
        "AirportCode": "YSP",
        "AirportName": "Marathon Airport",
        "City": "Marathon",
        "Country": "Canada"
    },
    {
        "id": "8737",
        "AirportCode": "YSR",
        "AirportName": "Nanisivik Airport",
        "City": "Nanisivik",
        "Country": "Canada"
    },
    {
        "id": "8738",
        "AirportCode": "YST",
        "AirportName": "Ste. Therese Point Airport",
        "City": "Sainte Therese Point",
        "Country": "Canada"
    },
    {
        "id": "8739",
        "AirportCode": "YSU",
        "AirportName": "Summerside Airport",
        "City": "Summerside",
        "Country": "Canada"
    },
    {
        "id": "8740",
        "AirportCode": "YSV",
        "AirportName": "Saglek Airport",
        "City": "Saglek",
        "Country": "Canada"
    },
    {
        "id": "8741",
        "AirportCode": "YSX",
        "AirportName": "Shearwater Airport",
        "City": "Shearwater",
        "Country": "Canada"
    },
    {
        "id": "8742",
        "AirportCode": "YSY",
        "AirportName": "Sachs Harbour Airport",
        "City": "Sachs Harbour",
        "Country": "Canada"
    },
    {
        "id": "8743",
        "AirportCode": "YSZ",
        "AirportName": "Squirrel Cove Airport",
        "City": "Squirrel Cove",
        "Country": "Canada"
    },
    {
        "id": "8744",
        "AirportCode": "YTA",
        "AirportName": "Pembroke And Area Airport",
        "City": "Pembroke",
        "Country": "Canada"
    },
    {
        "id": "8745",
        "AirportCode": "YTB",
        "AirportName": "Hartley Bay Airport",
        "City": "Hartley Bay",
        "Country": "Canada"
    },
    {
        "id": "8746",
        "AirportCode": "YTC",
        "AirportName": "Sturdee Airport",
        "City": "Sturdee",
        "Country": "Canada"
    },
    {
        "id": "8747",
        "AirportCode": "YTD",
        "AirportName": "Thicket Portage Airport",
        "City": "Thicket Portage",
        "Country": "Canada"
    },
    {
        "id": "8748",
        "AirportCode": "YTE",
        "AirportName": "Cape Dorset Airport",
        "City": "Cape Dorset",
        "Country": "Canada"
    },
    {
        "id": "8749",
        "AirportCode": "YTF",
        "AirportName": "Alma Airport",
        "City": "Alma",
        "Country": "Canada"
    },
    {
        "id": "8750",
        "AirportCode": "YTG",
        "AirportName": "Sullivan Bay Airport",
        "City": "Sullivan Bay",
        "Country": "Canada"
    },
    {
        "id": "8751",
        "AirportCode": "YTH",
        "AirportName": "Thompson Airport",
        "City": "Thompson",
        "Country": "Canada"
    },
    {
        "id": "8752",
        "AirportCode": "YTJ",
        "AirportName": "Terrace Bay Airport",
        "City": "Terrace Bay",
        "Country": "Canada"
    },
    {
        "id": "8753",
        "AirportCode": "YTK",
        "AirportName": "Tulugak Airport",
        "City": "Tulugak",
        "Country": "Canada"
    },
    {
        "id": "8754",
        "AirportCode": "YTL",
        "AirportName": "Big Trout Airport",
        "City": "Big Trout",
        "Country": "Canada"
    },
    {
        "id": "8755",
        "AirportCode": "YTM",
        "AirportName": "La Macaza Airport",
        "City": "Mont Tremblant",
        "Country": "Canada"
    },
    {
        "id": "8756",
        "AirportCode": "YTN",
        "AirportName": "Riviere Au Tonnerre Airport",
        "City": "Riviere Au Tonnerre",
        "Country": "Canada"
    },
    {
        "id": "8757",
        "AirportCode": "YTP",
        "AirportName": "Tofino Sea Plane Base",
        "City": "Tofino",
        "Country": "Canada"
    },
    {
        "id": "8758",
        "AirportCode": "YTQ",
        "AirportName": "Tasiujuaq Airport",
        "City": "Tasiujuaq",
        "Country": "Canada"
    },
    {
        "id": "8759",
        "AirportCode": "YTR",
        "AirportName": "Trenton Airport",
        "City": "Trenton",
        "Country": "Canada"
    },
    {
        "id": "8760",
        "AirportCode": "YTS",
        "AirportName": "Timmins Airport",
        "City": "Timmins",
        "Country": "Canada"
    },
    {
        "id": "8761",
        "AirportCode": "YTT",
        "AirportName": "Tisdale Airport",
        "City": "Tisdale",
        "Country": "Canada"
    },
    {
        "id": "8762",
        "AirportCode": "YTU",
        "AirportName": "Tasu Airport",
        "City": "Tasu",
        "Country": "Canada"
    },
    {
        "id": "8763",
        "AirportCode": "YTX",
        "AirportName": "Telegraph Creek Airport",
        "City": "Telegraph Creek",
        "Country": "Canada"
    },
    {
        "id": "8764",
        "AirportCode": "YTZ",
        "AirportName": "Toronto City Centre Airport",
        "City": "Toronto",
        "Country": "Canada"
    },
    {
        "id": "8765",
        "AirportCode": "YUA",
        "AirportName": "Yuanmou Airport",
        "City": "Yuanmou",
        "Country": "China"
    },
    {
        "id": "8766",
        "AirportCode": "YUB",
        "AirportName": "Tuktoyaktuk Airport",
        "City": "Tuktoyaktuk",
        "Country": "Canada"
    },
    {
        "id": "8767",
        "AirportCode": "YUD",
        "AirportName": "Umiujag Airport",
        "City": "Umiujag",
        "Country": "Canada"
    },
    {
        "id": "8768",
        "AirportCode": "YUE",
        "AirportName": "Yuendumu Airport",
        "City": "Yuendumu",
        "Country": "Australia"
    },
    {
        "id": "8769",
        "AirportCode": "YUF",
        "AirportName": "Dewline Site Airport",
        "City": "Pelly Bay",
        "Country": "Canada"
    },
    {
        "id": "8770",
        "AirportCode": "YUL",
        "AirportName": "Pierre Elliott Trudeau International Airport",
        "City": "Montreal",
        "Country": "Canada"
    },
    {
        "id": "8771",
        "AirportCode": "YUT",
        "AirportName": "Repulse Bay Airport",
        "City": "Repulse Bay",
        "Country": "Canada"
    },
    {
        "id": "8772",
        "AirportCode": "YUX",
        "AirportName": "Hall Beach Airport",
        "City": "Hall Beach",
        "Country": "Canada"
    },
    {
        "id": "8773",
        "AirportCode": "YUY",
        "AirportName": "Rouyn Airport",
        "City": "Rouyn",
        "Country": "Canada"
    },
    {
        "id": "8774",
        "AirportCode": "YVA",
        "AirportName": "Iconi Airport",
        "City": "Moroni",
        "Country": "Comoros"
    },
    {
        "id": "8775",
        "AirportCode": "YVB",
        "AirportName": "Bonaventure Airport",
        "City": "Bonaventure",
        "Country": "Canada"
    },
    {
        "id": "8776",
        "AirportCode": "YVC",
        "AirportName": "La Ronge Airport",
        "City": "La Ronge",
        "Country": "Canada"
    },
    {
        "id": "8777",
        "AirportCode": "YVD",
        "AirportName": "Yeva Airport",
        "City": "Yeva",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8778",
        "AirportCode": "YVE",
        "AirportName": "Vernon Airport",
        "City": "Vernon",
        "Country": "Canada"
    },
    {
        "id": "8779",
        "AirportCode": "YVG",
        "AirportName": "Vermilion Airport",
        "City": "Vermilion",
        "Country": "Canada"
    },
    {
        "id": "8780",
        "AirportCode": "YVM",
        "AirportName": "Broughton Island Airport",
        "City": "Broughton Island",
        "Country": "Canada"
    },
    {
        "id": "8781",
        "AirportCode": "YVP",
        "AirportName": "Kuujjuaq Airport",
        "City": "Kuujjuaq",
        "Country": "Canada"
    },
    {
        "id": "8782",
        "AirportCode": "YVQ",
        "AirportName": "Norman Wells Airport",
        "City": "Norman Wells",
        "Country": "Canada"
    },
    {
        "id": "8783",
        "AirportCode": "YVR",
        "AirportName": "Vancouver International Airport",
        "City": "Vancouver",
        "Country": "Canada"
    },
    {
        "id": "8784",
        "AirportCode": "YVT",
        "AirportName": "Buffalo Narrows Airport",
        "City": "Buffalo Narrows",
        "Country": "Canada"
    },
    {
        "id": "8785",
        "AirportCode": "YVV",
        "AirportName": "Wiarton Airport",
        "City": "Wiarton",
        "Country": "Canada"
    },
    {
        "id": "8786",
        "AirportCode": "YVZ",
        "AirportName": "Deer Lake Airport",
        "City": "Deer Lake",
        "Country": "Canada"
    },
    {
        "id": "8787",
        "AirportCode": "YWA",
        "AirportName": "Petawawa Airport",
        "City": "Petawawa",
        "Country": "Canada"
    },
    {
        "id": "8788",
        "AirportCode": "YWB",
        "AirportName": "Kangiqsujuaq Airport",
        "City": "Kangiqsujuaq",
        "Country": "Canada"
    },
    {
        "id": "8789",
        "AirportCode": "YWF",
        "AirportName": "Halifax Downtown Waterfront Heliport",
        "City": "Halifax",
        "Country": "Canada"
    },
    {
        "id": "8790",
        "AirportCode": "YWG",
        "AirportName": "Winnipeg James Armstrong Richardson International Airport",
        "City": "Winnipeg",
        "Country": "Canada"
    },
    {
        "id": "8791",
        "AirportCode": "YWH",
        "AirportName": "Inner Harbour Airport",
        "City": "Victoria",
        "Country": "Canada"
    },
    {
        "id": "8792",
        "AirportCode": "YWJ",
        "AirportName": "Deline Airport",
        "City": "Deline",
        "Country": "Canada"
    },
    {
        "id": "8793",
        "AirportCode": "YWK",
        "AirportName": "Wabush Airport",
        "City": "Wabush",
        "Country": "Canada"
    },
    {
        "id": "8794",
        "AirportCode": "YWL",
        "AirportName": "Williams Lake Airport",
        "City": "Williams Lake",
        "Country": "Canada"
    },
    {
        "id": "8795",
        "AirportCode": "YWM",
        "AirportName": "Williams Harbour Airport",
        "City": "Williams Harbour",
        "Country": "Canada"
    },
    {
        "id": "8796",
        "AirportCode": "YWN",
        "AirportName": "Winisk Airport",
        "City": "Winisk",
        "Country": "Canada"
    },
    {
        "id": "8797",
        "AirportCode": "YWO",
        "AirportName": "Lupin Airport",
        "City": "Lupin",
        "Country": "Canada"
    },
    {
        "id": "8798",
        "AirportCode": "YWP",
        "AirportName": "Webequie Airport",
        "City": "Webequie",
        "Country": "Canada"
    },
    {
        "id": "8799",
        "AirportCode": "YWQ",
        "AirportName": "Chute-Des-Passes Airport",
        "City": "Chute-Des-Passes",
        "Country": "Canada"
    },
    {
        "id": "8800",
        "AirportCode": "YWR",
        "AirportName": "White River Airport",
        "City": "White River",
        "Country": "Canada"
    },
    {
        "id": "8801",
        "AirportCode": "YWS",
        "AirportName": "Whistler Airport",
        "City": "Whistler",
        "Country": "Canada"
    },
    {
        "id": "8802",
        "AirportCode": "YWY",
        "AirportName": "Wrigley Airport",
        "City": "Wrigley",
        "Country": "Canada"
    },
    {
        "id": "8803",
        "AirportCode": "YXC",
        "AirportName": "Cranbrook Airport",
        "City": "Cranbrook",
        "Country": "Canada"
    },
    {
        "id": "8804",
        "AirportCode": "YXD",
        "AirportName": "Edmonton Municipal Airport",
        "City": "Edmonton",
        "Country": "Canada"
    },
    {
        "id": "8805",
        "AirportCode": "YXE",
        "AirportName": "Saskatoon Airport",
        "City": "Saskatoon",
        "Country": "Canada"
    },
    {
        "id": "8806",
        "AirportCode": "YXF",
        "AirportName": "Snake River Airport",
        "City": "Snake River",
        "Country": "Canada"
    },
    {
        "id": "8807",
        "AirportCode": "YXH",
        "AirportName": "Medicine Hat Airport",
        "City": "Medicine Hat",
        "Country": "Canada"
    },
    {
        "id": "8808",
        "AirportCode": "YXI",
        "AirportName": "Killaloe Airport",
        "City": "Killaloe",
        "Country": "Canada"
    },
    {
        "id": "8809",
        "AirportCode": "YXJ",
        "AirportName": "Fort St. John Airport",
        "City": "Fort St. John",
        "Country": "Canada"
    },
    {
        "id": "8810",
        "AirportCode": "YXK",
        "AirportName": "Rimouski Airport",
        "City": "Rimouski",
        "Country": "Canada"
    },
    {
        "id": "8811",
        "AirportCode": "YXL",
        "AirportName": "Sioux Lookout Airport",
        "City": "Sioux Lookout",
        "Country": "Canada"
    },
    {
        "id": "8812",
        "AirportCode": "YXN",
        "AirportName": "Whale Cove Airport",
        "City": "Whale Cove",
        "Country": "Canada"
    },
    {
        "id": "8813",
        "AirportCode": "YXP",
        "AirportName": "Pangnirtung Airport",
        "City": "Pangnirtung",
        "Country": "Canada"
    },
    {
        "id": "8814",
        "AirportCode": "YXQ",
        "AirportName": "Beaver Creek Airport",
        "City": "Beaver Creek",
        "Country": "Canada"
    },
    {
        "id": "8815",
        "AirportCode": "YXR",
        "AirportName": "Earlton Airport",
        "City": "Earlton",
        "Country": "Canada"
    },
    {
        "id": "8816",
        "AirportCode": "YXS",
        "AirportName": "Prince George Airport",
        "City": "Prince George",
        "Country": "Canada"
    },
    {
        "id": "8817",
        "AirportCode": "YXT",
        "AirportName": "Terrace Airport",
        "City": "Terrace",
        "Country": "Canada"
    },
    {
        "id": "8818",
        "AirportCode": "YXU",
        "AirportName": "Metropolitan Area Airport",
        "City": "London",
        "Country": "Canada"
    },
    {
        "id": "8819",
        "AirportCode": "YXX",
        "AirportName": "Abbotsford Airport",
        "City": "Abbotsford",
        "Country": "Canada"
    },
    {
        "id": "8820",
        "AirportCode": "YXY",
        "AirportName": "Whitehorse Airport",
        "City": "Whitehorse",
        "Country": "Canada"
    },
    {
        "id": "8821",
        "AirportCode": "YXZ",
        "AirportName": "Wawa Airport",
        "City": "Wawa",
        "Country": "Canada"
    },
    {
        "id": "8822",
        "AirportCode": "YYA",
        "AirportName": "Big Bay Yacht Club Airport",
        "City": "Big Bay Yacht Club",
        "Country": "Canada"
    },
    {
        "id": "8823",
        "AirportCode": "YYB",
        "AirportName": "North Bay Airport",
        "City": "North Bay",
        "Country": "Canada"
    },
    {
        "id": "8824",
        "AirportCode": "YYC",
        "AirportName": "Calgary International Airport",
        "City": "Calgary",
        "Country": "Canada"
    },
    {
        "id": "8825",
        "AirportCode": "YYD",
        "AirportName": "Smithers Airport",
        "City": "Smithers",
        "Country": "Canada"
    },
    {
        "id": "8826",
        "AirportCode": "YYE",
        "AirportName": "Fort Nelson Airport",
        "City": "Fort Nelson",
        "Country": "Canada"
    },
    {
        "id": "8827",
        "AirportCode": "YYF",
        "AirportName": "Penticton Airport",
        "City": "Penticton",
        "Country": "Canada"
    },
    {
        "id": "8828",
        "AirportCode": "YYG",
        "AirportName": "Charlottetown Airport",
        "City": "Charlottetown",
        "Country": "Canada"
    },
    {
        "id": "8829",
        "AirportCode": "YYH",
        "AirportName": "Taloyoak Airport",
        "City": "Taloyoak",
        "Country": "Canada"
    },
    {
        "id": "8830",
        "AirportCode": "YYI",
        "AirportName": "Rivers Airport",
        "City": "Rivers",
        "Country": "Canada"
    },
    {
        "id": "8831",
        "AirportCode": "YYJ",
        "AirportName": "Victoria International Airport",
        "City": "Victoria",
        "Country": "Canada"
    },
    {
        "id": "8832",
        "AirportCode": "YYL",
        "AirportName": "Lynn Lake Airport",
        "City": "Lynn Lake",
        "Country": "Canada"
    },
    {
        "id": "8833",
        "AirportCode": "YYM",
        "AirportName": "Cowley Airport",
        "City": "Cowley",
        "Country": "Canada"
    },
    {
        "id": "8834",
        "AirportCode": "YYN",
        "AirportName": "Swift Current Airport",
        "City": "Swift Current",
        "Country": "Canada"
    },
    {
        "id": "8835",
        "AirportCode": "YYQ",
        "AirportName": "Metropolitan Area Airport",
        "City": "Churchill",
        "Country": "Canada"
    },
    {
        "id": "8836",
        "AirportCode": "YYR",
        "AirportName": "Goose Bay Airport",
        "City": "Goose Bay",
        "Country": "Canada"
    },
    {
        "id": "8837",
        "AirportCode": "YYU",
        "AirportName": "Kapuskasing Airport",
        "City": "Kapuskasing",
        "Country": "Canada"
    },
    {
        "id": "8838",
        "AirportCode": "YYW",
        "AirportName": "Armstrong Airport",
        "City": "Armstrong",
        "Country": "Canada"
    },
    {
        "id": "8839",
        "AirportCode": "YYY",
        "AirportName": "Mont Joli Airport",
        "City": "Mont Joli",
        "Country": "Canada"
    },
    {
        "id": "8840",
        "AirportCode": "YYZ",
        "AirportName": "Pearson International Airport",
        "City": "Toronto",
        "Country": "Canada"
    },
    {
        "id": "8841",
        "AirportCode": "YZA",
        "AirportName": "Ashcroft Airport",
        "City": "Ashcroft",
        "Country": "Canada"
    },
    {
        "id": "8842",
        "AirportCode": "YZC",
        "AirportName": "Beatton River Airport",
        "City": "Beatton River",
        "Country": "Canada"
    },
    {
        "id": "8843",
        "AirportCode": "YZE",
        "AirportName": "Gore Bay Airport",
        "City": "Gore Bay",
        "Country": "Canada"
    },
    {
        "id": "8844",
        "AirportCode": "YZF",
        "AirportName": "Yellowknife Airport",
        "City": "Yellowknife",
        "Country": "Canada"
    },
    {
        "id": "8845",
        "AirportCode": "YZG",
        "AirportName": "Salluit Airport",
        "City": "Salluit",
        "Country": "Canada"
    },
    {
        "id": "8846",
        "AirportCode": "YZH",
        "AirportName": "Slave Lake Airport",
        "City": "Slave Lake",
        "Country": "Canada"
    },
    {
        "id": "8847",
        "AirportCode": "YZM",
        "AirportName": "Buchans Airport",
        "City": "Buchans",
        "Country": "Canada"
    },
    {
        "id": "8848",
        "AirportCode": "YZP",
        "AirportName": "Sandspit Airport",
        "City": "Sandspit",
        "Country": "Canada"
    },
    {
        "id": "8849",
        "AirportCode": "YZR",
        "AirportName": "Sarnia Airport",
        "City": "Sarnia",
        "Country": "Canada"
    },
    {
        "id": "8850",
        "AirportCode": "YZS",
        "AirportName": "Coral Harbour Airport",
        "City": "Coral Harbour",
        "Country": "Canada"
    },
    {
        "id": "8851",
        "AirportCode": "YZT",
        "AirportName": "Port Hardy Airport",
        "City": "Port Hardy",
        "Country": "Canada"
    },
    {
        "id": "8852",
        "AirportCode": "YZU",
        "AirportName": "Whitecourt Airport",
        "City": "Whitecourt",
        "Country": "Canada"
    },
    {
        "id": "8853",
        "AirportCode": "YZV",
        "AirportName": "Sept-Iles Airport",
        "City": "Sept-Iles",
        "Country": "Canada"
    },
    {
        "id": "8854",
        "AirportCode": "YZW",
        "AirportName": "Teslin Airport",
        "City": "Teslin",
        "Country": "Canada"
    },
    {
        "id": "8855",
        "AirportCode": "YZX",
        "AirportName": "Greenwood Airport",
        "City": "Greenwood",
        "Country": "Canada"
    },
    {
        "id": "8856",
        "AirportCode": "ZAA",
        "AirportName": "Alice Arm Airport",
        "City": "Alice Arm",
        "Country": "Canada"
    },
    {
        "id": "8857",
        "AirportCode": "ZAC",
        "AirportName": "York Landing Airport",
        "City": "York Landing",
        "Country": "Canada"
    },
    {
        "id": "8858",
        "AirportCode": "ZAD",
        "AirportName": "Zadar Airport",
        "City": "Zadar",
        "Country": "Croatia"
    },
    {
        "id": "8859",
        "AirportCode": "ZAG",
        "AirportName": "Pleso Airport",
        "City": "Zagreb",
        "Country": "Croatia"
    },
    {
        "id": "8860",
        "AirportCode": "ZAH",
        "AirportName": "Zahedan Airport",
        "City": "Zahedan",
        "Country": "Iran"
    },
    {
        "id": "8861",
        "AirportCode": "ZAJ",
        "AirportName": "Zaranj Airport",
        "City": "Zaranj",
        "Country": "Afghanistan"
    },
    {
        "id": "8862",
        "AirportCode": "ZAL",
        "AirportName": "Pichoy Airport",
        "City": "Valdivia",
        "Country": "Chile"
    },
    {
        "id": "8863",
        "AirportCode": "ZAM",
        "AirportName": "Zamboanga Airport",
        "City": "Zamboanga",
        "Country": "Philippines"
    },
    {
        "id": "8864",
        "AirportCode": "ZAO",
        "AirportName": "Laberandie Airport",
        "City": "Cahors",
        "Country": "France"
    },
    {
        "id": "8865",
        "AirportCode": "ZAR",
        "AirportName": "Zaria Airport",
        "City": "Zaria",
        "Country": "Nigeria"
    },
    {
        "id": "8866",
        "AirportCode": "ZAT",
        "AirportName": "Zhaotong Airport",
        "City": "Zhaotong",
        "Country": "China"
    },
    {
        "id": "8867",
        "AirportCode": "ZAZ",
        "AirportName": "Zaragoza Airport",
        "City": "Zaragoza",
        "Country": "Spain"
    },
    {
        "id": "8868",
        "AirportCode": "ZBE",
        "AirportName": "Dolni Benesov Airport",
        "City": "Zabreh",
        "Country": "Czech Republic"
    },
    {
        "id": "8869",
        "AirportCode": "ZBF",
        "AirportName": "Bathurst Airport",
        "City": "Bathurst",
        "Country": "Canada"
    },
    {
        "id": "8870",
        "AirportCode": "ZBK",
        "AirportName": "Zabljak Airport",
        "City": "Zabljak",
        "Country": "Serbia"
    },
    {
        "id": "8871",
        "AirportCode": "ZBL",
        "AirportName": "Biloela Airport",
        "City": "Biloela",
        "Country": "Australia"
    },
    {
        "id": "8872",
        "AirportCode": "ZBM",
        "AirportName": "Bromont Airport",
        "City": "Bromont",
        "Country": "Canada"
    },
    {
        "id": "8873",
        "AirportCode": "ZBO",
        "AirportName": "Bowen Airport",
        "City": "Bowen",
        "Country": "Australia"
    },
    {
        "id": "8874",
        "AirportCode": "ZBR",
        "AirportName": "Chah-Bahar Airport",
        "City": "Chah-Bahar",
        "Country": "Iran"
    },
    {
        "id": "8875",
        "AirportCode": "ZBY",
        "AirportName": "Sayaboury Airport",
        "City": "Sayaboury",
        "Country": "Laos"
    },
    {
        "id": "8876",
        "AirportCode": "ZCL",
        "AirportName": "La Calera Airport",
        "City": "Zacatecas",
        "Country": "Mexico"
    },
    {
        "id": "8877",
        "AirportCode": "ZCO",
        "AirportName": "Temuco Airport",
        "City": "Temuco",
        "Country": "Chile"
    },
    {
        "id": "8878",
        "AirportCode": "ZDN",
        "AirportName": "Brno Bus Service",
        "City": "Brno",
        "Country": "Czech Republic"
    },
    {
        "id": "8879",
        "AirportCode": "ZEC",
        "AirportName": "Secunda Airport",
        "City": "Secunda",
        "Country": "South Africa"
    },
    {
        "id": "8880",
        "AirportCode": "ZEG",
        "AirportName": "Senggo Airport",
        "City": "Senggo",
        "Country": "Indonesia"
    },
    {
        "id": "8881",
        "AirportCode": "ZEL",
        "AirportName": "Bella Bella Airport",
        "City": "Bella Bella",
        "Country": "Canada"
    },
    {
        "id": "8882",
        "AirportCode": "ZEM",
        "AirportName": "East Main Airport",
        "City": "East Main",
        "Country": "Canada"
    },
    {
        "id": "8883",
        "AirportCode": "ZEN",
        "AirportName": "Zenag Airport",
        "City": "Zenag",
        "Country": "Papua New Guinea"
    },
    {
        "id": "8884",
        "AirportCode": "ZER",
        "AirportName": "Zero Airport",
        "City": "Zero",
        "Country": "India"
    },
    {
        "id": "8885",
        "AirportCode": "ZFA",
        "AirportName": "Faro Airport",
        "City": "Faro",
        "Country": "Canada"
    },
    {
        "id": "8886",
        "AirportCode": "ZFB",
        "AirportName": "Old Fort Bay Airport",
        "City": "Old Fort Bay",
        "Country": "Canada"
    },
    {
        "id": "8887",
        "AirportCode": "ZFD",
        "AirportName": "Fond Du Lac Airport",
        "City": "Fond Du Lac",
        "Country": "Canada"
    },
    {
        "id": "8888",
        "AirportCode": "ZFL",
        "AirportName": "South Trout Lake Airport",
        "City": "South Trout Lake",
        "Country": "Canada"
    },
    {
        "id": "8889",
        "AirportCode": "ZFM",
        "AirportName": "Fort Mcpherson Airport",
        "City": "Fort McPherson",
        "Country": "Canada"
    },
    {
        "id": "8890",
        "AirportCode": "ZFN",
        "AirportName": "Tulita Airport",
        "City": "Tulita/Fort Norman",
        "Country": "Canada"
    },
    {
        "id": "8891",
        "AirportCode": "ZFV",
        "AirportName": "Philadelphia Rail",
        "City": "Philadelphia",
        "Country": "USA Philadelphia"
    },
    {
        "id": "8892",
        "AirportCode": "ZFW",
        "AirportName": "Fairview Airport",
        "City": "Fairview",
        "Country": "Canada"
    },
    {
        "id": "8893",
        "AirportCode": "ZGC",
        "AirportName": "Zhongchuan Airport",
        "City": "Lanzhou",
        "Country": "China"
    },
    {
        "id": "8894",
        "AirportCode": "ZGF",
        "AirportName": "Grand Forks Airport",
        "City": "Grand Forks",
        "Country": "Canada"
    },
    {
        "id": "8895",
        "AirportCode": "ZGI",
        "AirportName": "Gods River Airport",
        "City": "Gods River",
        "Country": "Canada"
    },
    {
        "id": "8896",
        "AirportCode": "ZGL",
        "AirportName": "South Galway Airport",
        "City": "South Galway",
        "Country": "Australia"
    },
    {
        "id": "8897",
        "AirportCode": "ZGM",
        "AirportName": "Ngoma Airport",
        "City": "Ngoma",
        "Country": "Zambia"
    },
    {
        "id": "8898",
        "AirportCode": "ZGR",
        "AirportName": "Little Grand Rapids Airport",
        "City": "Little Grand Rapids",
        "Country": "Canada"
    },
    {
        "id": "8899",
        "AirportCode": "ZGS",
        "AirportName": "Gethsemani Airport",
        "City": "Gethsemani",
        "Country": "Canada"
    },
    {
        "id": "8900",
        "AirportCode": "ZGU",
        "AirportName": "Gaua Airport",
        "City": "Gaua",
        "Country": "Vanuatu"
    },
    {
        "id": "8901",
        "AirportCode": "ZHA",
        "AirportName": "Zhanjiang Airport",
        "City": "Zhanjiang",
        "Country": "China"
    },
    {
        "id": "8902",
        "AirportCode": "ZHM",
        "AirportName": "Shamshernagar Airport",
        "City": "Shamshernagar",
        "Country": "Bangladesh"
    },
    {
        "id": "8903",
        "AirportCode": "ZHP",
        "AirportName": "High Prairie Airport",
        "City": "High Prairie",
        "Country": "Canada"
    },
    {
        "id": "8904",
        "AirportCode": "ZIC",
        "AirportName": "Victoria Airport",
        "City": "Victoria",
        "Country": "Chile"
    },
    {
        "id": "8905",
        "AirportCode": "ZIG",
        "AirportName": "Ziguinchor Airport",
        "City": "Ziguinchor",
        "Country": "Senegal"
    },
    {
        "id": "8906",
        "AirportCode": "ZIH",
        "AirportName": "Ixtapa/Zihuatanejo Internacional Airport",
        "City": "Ixtapa/Zihuatanejo",
        "Country": "Mexico"
    },
    {
        "id": "8907",
        "AirportCode": "ZJG",
        "AirportName": "Jenpeg Airport",
        "City": "Jenpeg",
        "Country": "Canada"
    },
    {
        "id": "8908",
        "AirportCode": "ZJN",
        "AirportName": "Swan River Airport",
        "City": "Swan River",
        "Country": "Canada"
    },
    {
        "id": "8909",
        "AirportCode": "ZKB",
        "AirportName": "Kasaba Bay Airport",
        "City": "Kasaba Bay",
        "Country": "Zambia"
    },
    {
        "id": "8910",
        "AirportCode": "ZKE",
        "AirportName": "Kaschechewan Airport",
        "City": "Kaschechewan",
        "Country": "Canada"
    },
    {
        "id": "8911",
        "AirportCode": "ZKG",
        "AirportName": "Kegaska Airport",
        "City": "Kegaska",
        "Country": "Canada"
    },
    {
        "id": "8912",
        "AirportCode": "ZKL",
        "AirportName": "Steenkool Airport",
        "City": "Steenkool",
        "Country": "Indonesia"
    },
    {
        "id": "8913",
        "AirportCode": "ZKM",
        "AirportName": "Sette Cama Airport",
        "City": "Sette Cama",
        "Country": "Gabon"
    },
    {
        "id": "8914",
        "AirportCode": "ZKP",
        "AirportName": "Kasompe Airport",
        "City": "Kasompe",
        "Country": "Zambia"
    },
    {
        "id": "8915",
        "AirportCode": "ZLG",
        "AirportName": "El Gouera Airport",
        "City": "El Gouera",
        "Country": "Mauritania"
    },
    {
        "id": "8916",
        "AirportCode": "ZLO",
        "AirportName": "Manzanillo Airport",
        "City": "Manzanillo",
        "Country": "Mexico"
    },
    {
        "id": "8917",
        "AirportCode": "ZLR",
        "AirportName": "Linares",
        "City": "Linares",
        "Country": "Chile"
    },
    {
        "id": "8918",
        "AirportCode": "ZLT",
        "AirportName": "La Tabatiere Airport",
        "City": "La Tabatiere",
        "Country": "Canada"
    },
    {
        "id": "8919",
        "AirportCode": "ZMD",
        "AirportName": "Sena Madureira Airport",
        "City": "Sena Madureira",
        "Country": "Brazil"
    },
    {
        "id": "8920",
        "AirportCode": "ZMH",
        "AirportName": "108 Mile Ranch Airport",
        "City": "108 Mile Ranch",
        "Country": "Canada"
    },
    {
        "id": "8921",
        "AirportCode": "ZMM",
        "AirportName": "Zamora Airport",
        "City": "Zamora",
        "Country": "Mexico"
    },
    {
        "id": "8922",
        "AirportCode": "ZMT",
        "AirportName": "Masset Airport",
        "City": "Masset",
        "Country": "Canada"
    },
    {
        "id": "8923",
        "AirportCode": "ZNA",
        "AirportName": "Harbour Airport",
        "City": "Nanaimo",
        "Country": "Canada"
    },
    {
        "id": "8924",
        "AirportCode": "ZND",
        "AirportName": "Zinder Airport",
        "City": "Zinder",
        "Country": "Niger"
    },
    {
        "id": "8925",
        "AirportCode": "ZNE",
        "AirportName": "Newman Airport",
        "City": "Newman",
        "Country": "Australia"
    },
    {
        "id": "8926",
        "AirportCode": "ZNG",
        "AirportName": "Negginan Airport",
        "City": "Negginan",
        "Country": "Canada"
    },
    {
        "id": "8927",
        "AirportCode": "ZNU",
        "AirportName": "Namu Airport",
        "City": "Namu",
        "Country": "Canada"
    },
    {
        "id": "8928",
        "AirportCode": "ZNZ",
        "AirportName": "Kisauni Airport",
        "City": "Zanzibar",
        "Country": "Tanzania"
    },
    {
        "id": "8929",
        "AirportCode": "ZOF",
        "AirportName": "Ocean Falls Airport",
        "City": "Ocean Falls",
        "Country": "Canada"
    },
    {
        "id": "8930",
        "AirportCode": "ZOS",
        "AirportName": "Canal Balo Airport",
        "City": "Osorno",
        "Country": "Chile"
    },
    {
        "id": "8931",
        "AirportCode": "ZPB",
        "AirportName": "Sachigo Lake Airport",
        "City": "Sachigo Lake",
        "Country": "Canada"
    },
    {
        "id": "8932",
        "AirportCode": "ZPC",
        "AirportName": "Pucon Airport",
        "City": "Pucon",
        "Country": "Chile"
    },
    {
        "id": "8933",
        "AirportCode": "ZPH",
        "AirportName": "Zephyrhills Airport",
        "City": "Zephyrhills",
        "Country": "USA Florida"
    },
    {
        "id": "8934",
        "AirportCode": "ZPO",
        "AirportName": "Pine House Airport",
        "City": "Pine House",
        "Country": "Canada"
    },
    {
        "id": "8935",
        "AirportCode": "ZQN",
        "AirportName": "Frankton Airport",
        "City": "Queenstown",
        "Country": "New Zealand"
    },
    {
        "id": "8936",
        "AirportCode": "ZQS",
        "AirportName": "Queen Charlotte Island Airport",
        "City": "Queen Charlotte Island",
        "Country": "Canada"
    },
    {
        "id": "8937",
        "AirportCode": "ZRH",
        "AirportName": "Zurich Airport",
        "City": "Zurich",
        "Country": "Switzerland"
    },
    {
        "id": "8938",
        "AirportCode": "ZRI",
        "AirportName": "Serui Airport",
        "City": "Serui",
        "Country": "Indonesia"
    },
    {
        "id": "8939",
        "AirportCode": "ZRJ",
        "AirportName": "Round Lake Airport",
        "City": "Round Lake",
        "Country": "Canada"
    },
    {
        "id": "8940",
        "AirportCode": "ZRM",
        "AirportName": "Sarmi Airport",
        "City": "Sarmi",
        "Country": "Indonesia"
    },
    {
        "id": "8941",
        "AirportCode": "ZSA",
        "AirportName": "San Salvador Airport",
        "City": "San Salvador",
        "Country": "Bahamas"
    },
    {
        "id": "8942",
        "AirportCode": "ZSE",
        "AirportName": "St. Pierre de la Reunion Airport",
        "City": "Saint Pierre de la Reunion",
        "Country": "Reunion"
    },
    {
        "id": "8943",
        "AirportCode": "ZSJ",
        "AirportName": "Sandy Lake Airport",
        "City": "Sandy Lake",
        "Country": "Canada"
    },
    {
        "id": "8944",
        "AirportCode": "ZSP",
        "AirportName": "St. Paul Airport",
        "City": "Saint Paul",
        "Country": "Canada"
    },
    {
        "id": "8945",
        "AirportCode": "ZST",
        "AirportName": "Stewart Airport",
        "City": "Stewart",
        "Country": "Canada"
    },
    {
        "id": "8946",
        "AirportCode": "ZSW",
        "AirportName": "Seal Cove Airport",
        "City": "Prince Rupert",
        "Country": "Canada"
    },
    {
        "id": "8947",
        "AirportCode": "ZTA",
        "AirportName": "Tureira Airport",
        "City": "Tureira",
        "Country": "French Polynesia"
    },
    {
        "id": "8948",
        "AirportCode": "ZTB",
        "AirportName": "Tete-A-La Baleine Airport",
        "City": "Tete-A-La Baleine",
        "Country": "Canada"
    },
    {
        "id": "8949",
        "AirportCode": "ZTH",
        "AirportName": "Zakinthos Airport",
        "City": "Zakinthos",
        "Country": "Greece"
    },
    {
        "id": "8950",
        "AirportCode": "ZTM",
        "AirportName": "Shamattawa Airport",
        "City": "Shamattawa",
        "Country": "Canada"
    },
    {
        "id": "8951",
        "AirportCode": "ZTR",
        "AirportName": "Zhitomir Airport",
        "City": "Zhitomir",
        "Country": "Ukraine"
    },
    {
        "id": "8952",
        "AirportCode": "ZTS",
        "AirportName": "Tahsis Airport",
        "City": "Tahsis",
        "Country": "Canada"
    },
    {
        "id": "8953",
        "AirportCode": "ZUC",
        "AirportName": "High Prairie Airport",
        "City": "High Prairie",
        "Country": "Canada"
    },
    {
        "id": "8954",
        "AirportCode": "ZUD",
        "AirportName": "Ancud Airport",
        "City": "Ancud",
        "Country": "Chile"
    },
    {
        "id": "8955",
        "AirportCode": "ZUH",
        "AirportName": "Zhuhai Airport",
        "City": "Zhuhai",
        "Country": "China"
    },
    {
        "id": "8956",
        "AirportCode": "ZUL",
        "AirportName": "Zilfi Airport",
        "City": "Zilfi",
        "Country": "Saudi Arabia"
    },
    {
        "id": "8957",
        "AirportCode": "ZUM",
        "AirportName": "Churchill Falls Airport",
        "City": "Churchill Falls",
        "Country": "Canada"
    },
    {
        "id": "8958",
        "AirportCode": "ZVA",
        "AirportName": "Miandrivazo Airport",
        "City": "Miandrivazo",
        "Country": "Madagascar"
    },
    {
        "id": "8959",
        "AirportCode": "ZVG",
        "AirportName": "Springvale Airport",
        "City": "Springvale",
        "Country": "Australia"
    },
    {
        "id": "8960",
        "AirportCode": "ZVK",
        "AirportName": "Savannakhet Airport",
        "City": "Savannakhet",
        "Country": "Laos"
    },
    {
        "id": "8961",
        "AirportCode": "ZWA",
        "AirportName": "Andapa Airport",
        "City": "Andapa",
        "Country": "Madagascar"
    },
    {
        "id": "8962",
        "AirportCode": "ZWL",
        "AirportName": "Wollaston Lake Airport",
        "City": "Wollaston Lake",
        "Country": "Canada"
    },
    {
        "id": "8963",
        "AirportCode": "ZWN",
        "AirportName": "Wittenberge Railway Service",
        "City": "Wittenberge",
        "Country": "Germany"
    },
    {
        "id": "8964",
        "AirportCode": "ZXQ",
        "AirportName": "Solstad Airport",
        "City": "Solstad",
        "Country": "Norway"
    },
    {
        "id": "8965",
        "AirportCode": "ZXT",
        "AirportName": "Zabrat Airport",
        "City": "Baku",
        "Country": "Azerbaijan"
    },
    {
        "id": "8966",
        "AirportCode": "ZYI",
        "AirportName": "Zunyi Airport",
        "City": "Zunyi",
        "Country": "China"
    },
    {
        "id": "8967",
        "AirportCode": "ZYL",
        "AirportName": "Civil Airport",
        "City": "Sylhet",
        "Country": "Bangladesh"
    },
    {
        "id": "8968",
        "AirportCode": "ZYR",
        "AirportName": "Brussels Midi",
        "City": "Brussels",
        "Country": "Belgium"
    },
    {
        "id": "8969",
        "AirportCode": "ZZU",
        "AirportName": "Mzuzu Airport",
        "City": "Mzuzu",
        "Country": "Malawi"
    },
    {
        "id": "8970",
        "AirportCode": "ZZV",
        "AirportName": "Zanesville Airport",
        "City": "Zanesville",
        "Country": "USA Ohio"
    },
    {
        "id": "8971",
        "AirportCode": "XNB",
        "AirportName": "Abu Dhabi International Airport",
        "City": "Abu Dhabi",
        "Country": "UAE"
    },
    {
        "id": "8972",
        "AirportCode": "NYC",
        "AirportName": "New York All airports",
        "City": "New York All airports",
        "Country": "USA"
    },
    {
        "id": "8973",
        "AirportCode": "STO",
        "AirportName": "Stockholm All Airports",
        "City": "Stockholm All Airports",
        "Country": "Sweden"
    },
    {
        "id": "8974",
        "AirportCode": "MIL",
        "AirportName": "Milan All Airports",
        "City": "Milan All Airports",
        "Country": "Italy"
    },
    {
        "id": "8975",
        "AirportCode": "OSA",
        "AirportName": "Osaka All Airports",
        "City": "Osaka All Airports",
        "Country": "Japan"
    },
    {
        "id": "8976",
        "AirportCode": "MOW",
        "AirportName": "Moscow All Airports",
        "City": "Moscow All Airports",
        "Country": "Russia"
    },
    {
        "id": "8977",
        "AirportCode": "CHI",
        "AirportName": "Chicago All Airports",
        "City": "Chicago All Airports",
        "Country": "USA"
    },
    {
        "id": "8978",
        "AirportCode": "BUE",
        "AirportName": "Buenos Aires All Airports",
        "City": "Buenos Aires All Airports",
        "Country": "Argentina"
    },
    {
        "id": "8979",
        "AirportCode": "WAS",
        "AirportName": "Washington All Airports",
        "City": "Washington All Airports",
        "Country": "USA"
    },
    {
        "id": "8980",
        "AirportCode": "ROM",
        "AirportName": "Rome All Airports",
        "City": "Rome All Airports",
        "Country": "Italy"
    },
    {
        "id": "8981",
        "AirportCode": "BER",
        "AirportName": "Berlin All Airports",
        "City": "Berlin All Airports",
        "Country": "Berlin All Airports"
    },
    {
        "id": "8982",
        "AirportCode": "JKT",
        "AirportName": "Jakarta All Airports",
        "City": "Jakarta All Airports",
        "Country": "Indonesia"
    },
    {
        "id": "8983",
        "AirportCode": "SAO",
        "AirportName": "Sao Paulo All Airports",
        "City": "Sao Paulo All Airports",
        "Country": "Brazil"
    },
    {
        "id": "8984",
        "AirportCode": "RIO",
        "AirportName": "Rio De Janeiro All Airports",
        "City": "Rio De Janeiro All Airports",
        "Country": "Brazil"
    },
    {
        "id": "8985",
        "AirportCode": "BJS",
        "AirportName": "Beijing All Airports",
        "City": "Beijing All Airports",
        "Country": "China"
    },
    {
        "id": "8986",
        "AirportCode": "BUH",
        "AirportName": "Bucharest All Airports",
        "City": "Bucharest All Airports",
        "Country": "Romania"
    },
    {
        "id": "8987",
        "AirportCode": "SEL",
        "AirportName": "Seoul All Airports",
        "City": "Seoul All Airports",
        "Country": "Korea"
    },
    {
        "id": "8988",
        "AirportCode": "YTO",
        "AirportName": "Toronto All Airports",
        "City": "Toronto All Airports",
        "Country": "Canada"
    },
    {
        "id": "8989",
        "AirportCode": "TYO",
        "AirportName": "Tokyo All Airports",
        "City": "Tokyo All Airports",
        "Country": "Japan"
    },
    {
        "id": "8990",
        "AirportCode": "REK",
        "AirportName": "Reykjavik All Airports",
        "City": "Reykjavik All Airports",
        "Country": "Iceland"
    },
    {
        "id": "8991",
        "AirportCode": "YMQ",
        "AirportName": "Montreal All Airports",
        "City": "Montreal All Airports",
        "Country": "Canada"
    },
    {
        "id": "8992",
        "AirportCode": "NCE",
        "AirportName": "Nice",
        "City": "Nice",
        "Country": "France"
    },
    {
        "id": "8993",
        "AirportCode": "TLV",
        "AirportName": "Ben Gurion Airport",
        "City": "Ben Gurion",
        "Country": "Israel"
    },
    {
        "id": "8994",
        "AirportCode": "EBL",
        "AirportName": "Erbil International Airport",
        "City": "Erbil",
        "Country": "Iraq"
    },
    {
        "id": "8995",
        "AirportCode": "MRU",
        "AirportName": "Sir Seewoosagur Ramgoolam International Airport",
        "City": "Plaine Magnien",
        "Country": "Mauritius"
    },
    {
        "id": "8996",
        "AirportCode": "RRG",
        "AirportName": "Sir Gaëtan Duval Airport",
        "City": "Plaine Corail",
        "Country": "Mauritius"
    },
    {
        "id": "8997",
        "AirportCode": "ORD",
        "AirportName": "O'Hare International Airport",
        "City": "Chicago",
        "Country": "USA"
    },
    {
        "id": "8998",
        "AirportCode": "ABJ",
        "AirportName": "Port Bouet Airport",
        "City": "Abidjan",
        "Country": "Ivory Coast"
    },
    {
        "id": "8999",
        "AirportCode": "NJF",
        "AirportName": "Al Najaf International Airport",
        "City": "Najaf",
        "Country": "Iraq"
    },
    {
        "id": "9000",
        "AirportCode": "ISU",
        "AirportName": "Sulaimaniyah International Airport",
        "City": "Sulaimaniyah",
        "Country": "Iraq"
    }
]