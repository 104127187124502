import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from "react-bootstrap";
import ModifyCarSearch from './ModifyCarSearch';

import Header from '../Header/Header'
import Footer from "../Footer/Footer";
import { useLocation } from 'react-router-dom';
import CarResults from "./CarResults";
import CarLeftFilter from "./CarLeftFilter";
import styles from './CarResults.module.css';
import { useCarContext } from '../../context/Car/carContext';
import { useCarFilterContext } from '../../context/Car/carFilterContext';


function CarList() {
 const location = useLocation();
  const { inputs, pickup_date, dropoff_date, pickup_id } = location.state

  const { getCarSearch, carResults, isLoading, isError } = useCarContext();

  const { filter_cars, all_cars } = useCarFilterContext();

  console.log("object",pickup_id)    


  const getCarRusults = async () => {
    //console.log("object",event)    
    let json = {
      // "user_id": "TrawexAPI2023",
      // "user_password": "TrawexAPI2023PWD",
      // "access": "Test",
      // "ip_address": "103.114.211.169",
      "pickup_id": inputs.pickuplocationvalue,
      "dropoff_id": inputs.dropofflocationvalue,
      "pickup_date": pickup_date,
      "pickup_time": inputs.pickupTime,
      "dropoff_date":  dropoff_date,
      "dropoff_time": inputs.dropoffTime,
      "driver_age": inputs.driverAge,
      "country_res": inputs.country,
      "currency": "USD"
    }
    console.log("car search engine", json)
    await getCarSearch(json);
   
  }
  useEffect(() => {
    getCarRusults()
  },[])



  console.log("results", filter_cars[0])
  return (
    <>
     <Header/>
      <div className={styles.resultContent}>
        <ModifyCarSearch  modifyData={inputs}   pickupdate={pickup_date} dropoffdate={dropoff_date}/>
          <div className='container'>
            <Row>
              <Col lg={3}>
                <CarLeftFilter filterCarData={carResults?.data} />
              </Col>
              <Col lg={9}>
                  <CarResults  filterCarData={filter_cars[0]} />             
              </Col>
            </Row>
        </div>
       </div>
      <Footer/>
    </>
  )
}

export default CarList
